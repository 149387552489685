import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, touch } from 'redux-form';
import { normalize, crmValidator } from 'helpers';
import lodash from 'lodash'

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";

import { LuckydrawRewardActions } from 'actions';
import { luckydrawRewardConstants } from '../../constants';
import i18n from 'i18n';
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

const FORM_NAME = 'LUCKYDRAWREWARD_FORM';
const form_validate = {
    "name_en": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "name_th": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "type": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "order": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const normalizeRewardMaxLength = (value) => {
    return value.substr(0, 50);
}
export const validMimeType = "image/jpeg, image/png";
export const validateWinTwiceJackpotSelect = values => {
    if (values === undefined || values.length === 0) {
        return "กรุณาเลือก"
     }
}
const validateImageFormat = imageFile => {
    if (imageFile && !validMimeType.includes(imageFile.type)) {
       return "warning_messages.only_img_file_type_is_jpg_or_png";
    }
}
const validateImageSize = imageFile => {
    if (imageFile) {
      if (imageFile.size > (1048576 * 2)) {
        return "warning_messages.campaign_picture_file_size_must_be_less_than_2mb";
      }
    }
}
const validateRequiredImage = (imageFile) => { console.log('imageFile ===',imageFile)
    if (imageFile === null || imageFile === undefined || imageFile === "") {
       return "warning_messages.reward_picture_is_required"
    }
}
class LuckydrawRewardForm extends React.Component {
    handleSubmit = (values) => {
        const { dispatch, rewardImagePreview } = this.props
        var reqParams = {
            "name_en": values.name_en,
            "name_th": values.name_th,
            "type": values.type,
            "win_twice": values.win_twice,
            "order": parseInt(values.order),
            "description": values.description,
            "file": lodash.get(values, 'file') ? lodash.get(values, 'file') : rewardImagePreview,
            "showOnCampignAndEarnRule": values.showOnCampignAndEarnRule,
        }

        if (this.props.formMode === "add") {
            dispatch(LuckydrawRewardActions.addLuckydrawReward(reqParams));
        } else {
            reqParams._id = values._id
            dispatch(LuckydrawRewardActions.editLuckydrawReward(reqParams));
        }
    }

    render() {
        const { classes, formOpen, formMode, handleSubmit,LUCKYDRAWREWARD_FORM,dispatch, rewardImagePreview } = this.props
        
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }


        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>{formMode === 'add' ? 'Add Reward' : (readOnly === true ? 'Reward Info' : 'Edit Reward')}</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                {formMode === "edit" &&
                                    <Field
                                        name="_id"
                                        component="input"
                                        type="hidden"
                                        disabled={true}
                                    />
                                }
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="name_en"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Reward Name (EN)"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={normalizeRewardMaxLength}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="name_th"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Reward Name (TH)"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={normalizeRewardMaxLength}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                            <Field
                                name="description"
                                component={CustomInput}
                                type="text"
                                labelText={i18n.t("label.reward_description")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                disabled={readOnly}
                            />
                            </GridItem>
                            <GridItem xs={12} sm={9} md={9}>
                                <Field
                                    name="file"
                                    component={ImageUpload}
                                    type="text"
                                    labelText={i18n.t("label.reward_picture")}
                                    textLeft={true}
                                    height={150}
                                    showRatioGuide={true}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let reader = new FileReader();
                                        let file = e.target.files[0];
                                        reader.onloadend = () => {
                                            dispatch({ type: luckydrawRewardConstants.SET_FORM_PREVIEW_IMAGE_REWARD, rewardImagePreview: reader.result })
                                        };
                                        try {
                                            if (!validMimeType.includes(file.type)) {
                                                dispatch(touch(FORM_NAME, 'file'));
                                            }
                                            reader.readAsDataURL(file);
                                            dispatch(change(FORM_NAME, 'file', file))
                                        } catch (error) {
                                            dispatch({ type: luckydrawRewardConstants.SET_FORM_PREVIEW_IMAGE_REWARD, rewardImagePreview: "default" })
                                            dispatch(change(FORM_NAME, 'file', ''))
                                        }
                                    }}
                                    imagePreviewUrl={rewardImagePreview}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={
                                        formMode === "add"
                                            ? [
                                                validateImageFormat,
                                                validateRequiredImage,
                                                validateImageSize,
                                            ]
                                            : [
                                                validateImageFormat,
                                                validateImageSize,
                                            ]
                                    }
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                            <Field
                                 name="type"
                                 component={CustomSelect}
                                 type="text"
                                 labelText="Reward Type"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 optionsList={[
                                    {
                                       value: "Jackpot",
                                       label: "Jackpot"
                                    },
                                    {
                                       value: "Regular",
                                       label: "Regular"
                                    }
                                 ]}
                                 onChange={(e) => {
                                    let value = e.target.value;
                                    if(value === 'Regular'){
                                        dispatch(change('LUCKYDRAWREWARD_FORM', 'win_twice', ''))
                                    }
                                 }}
                                 disabled={readOnly}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                            <Field
                                 name="win_twice"
                                 component={CustomSelect}
                                 type="text"
                                 labelText="Win twice (for Jackpot only)"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 optionsList={[
                                    {
                                       value: "yes",
                                       label: "Yes"
                                    },
                                    {
                                       value: "no",
                                       label: "No"
                                    }
                                 ]}
                                disabled={readOnly || !(lodash.get(LUCKYDRAWREWARD_FORM.values, 'type') === 'Jackpot') }
                                validate={(lodash.get(LUCKYDRAWREWARD_FORM.values, 'type') === 'Jackpot') ? [validateWinTwiceJackpotSelect]: null}
                              />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="order"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Order"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={normalize.onlyNumber}
                                />
                            </GridItem>
                            </GridContainer>
                            <span style={{ marginTop: '10px'}}>Show on Campaign and Earn rule</span>
                            <GridContainer>
                                <GridItem xs={12} sm={8} md={8} lg={8} style={{ marginTop: '10px',  }}>
                                    <Field
                                        id="show-on-radio"
                                        name="showOnCampignAndEarnRule"
                                        component={CustomRadio}
                                        disabled={readOnly}
                                        radioLists={[
                                            {
                                                value: "yes",
                                                label: <span style={{ fontWeight: '300', marginRight: '20px'}}>Yes</span>,
                                            }
                                        ]}
                                        onChange={this.handleChange}
                                    />  
                                    <Field
                                        id="show-on-radio"
                                        name="showOnCampignAndEarnRule"
                                        component={CustomRadio}
                                        disabled={readOnly}
                                        radioLists={[
                                            {
                                                value: "no",
                                                label: <span style={{ fontWeight: '300'}}>No</span>,
                                            }
                                        ]}
                                        onChange={this.handleChange}
                                        style={{fontWeight: '300'}}
                                    />        
                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>

                        {readOnly === false &&
                            <Button
                                style={{ marginRight: "10px" }}
                                color="primary"
                                type="submit">
                                Save
                            </Button>
                        }

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: luckydrawRewardConstants.CLOSE_FORM })
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode, LuckydrawRewaredList, rewardImagePreview} = state.luckydraw_reward;
    const { LUCKYDRAWREWARD_FORM } = state.form
    return {
        formOpen, formMode, LUCKYDRAWREWARD_FORM, LuckydrawRewaredList, rewardImagePreview
    };
}

const connectedComponent = connect(mapStateToProps)(LuckydrawRewardForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))

import React from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash';
// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
// core components
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import { winnerActions } from 'actions';
import { plugins, crmValidator } from 'helpers';
import { winnerConstants } from '../../constants';

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const moduleName = 'luckyDraw[luckydrawWinnerManagement]';

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(11)": {
                    textAlign: "right"
                }
            },
            body: {
                "&:nth-child(22)": {
                    textAlign: "right"
                }
            }
        }
    }
});

class TableWinnerList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: "",
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "created_at",
                sortDir: "desc",
                keyword: ""
            },
            csvData: [],
            refreshTable: true
        };
        this.csvLink = React.createRef()
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableFilter } = this.props
        if (this.state.refreshTable) {
            tableFilter.skip = null
            tableFilter.sortBy = "createdAt"
            tableFilter.sortDir = "desc"
            tableFilter.keyword = ""
            tableFilter.search = {}
            this.setState({
                refreshTable: false
            })
        }

        let reqParams = tableFilter;

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                reqParams.skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                reqParams.limit = 10
            }
            reqParams.page = skip;
            this.setState({
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams.skip = skip;
            reqParams.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams.keyword = keyword;
            } else {
                reqParams.keyword = ""
            }
        } else {
            reqParams = tableFilter

        }
        dispatch(winnerActions.getTableDatas(reqParams));
        this.setState({
            isLoading: true,
            reqParams: reqParams
        });
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas;
        return data[rowIndex];
    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: winnerConstants.CLEAR_PAGE_STATE });
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: winnerConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    componentDidMount() {
        this.setState({
            refreshTable: true
        })

        this.getData();
    }

    openEditWinnerDetail = (rowIndex) => {
        const { dispatch, tableDatas } = this.props;
        let rowData = tableDatas[rowIndex]
        dispatch({ type: winnerConstants.OPEN_FORM_EDIT, formInitialValue: { ...rowData } })
    }

    render() {
        const { formInitialValue, tableDatas, tableKey, visiblePersonalData, showLoader, tableTotal, tableFilter } = this.props;
        const { rowsPerPage } = this.state;
        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "displayId", options: { filter: false, display: "excluded" } },
            {
                name: "order", label: "Order", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.order !== undefined || rowData.order != null) {
                            return (rowData.order);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "period.announceDate", label: "Announce Date", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        if (rowData.period.announceDate !== undefined || rowData.period.announceDate != null) {
                            return (plugins.datetimeFormat(rowData.period.announceDate, "fulldatetime"));
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "mobileNo", label: "Mobile No.", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        if (rowData.mobileNo !== undefined || rowData.mobileNo != null) {
                            return (rowData.mobileNo);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "code", label: "Code", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        if (rowData.code !== undefined || rowData.code != null) {
                            return (rowData.code);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "display", label: "Display", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        if (rowData.display !== undefined || rowData.display != null) {
                            return (rowData.display);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "period.rewardLuckyDraw.type", label: "Reward Type", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        if (rowData.period.rewardLuckyDraw.type !== undefined || rowData.period.rewardLuckyDraw.type != null) {
                            return (rowData.period.rewardLuckyDraw.type);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "period.rewardLuckyDraw.name_en", label: "Reference Name (EN)", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        if (rowData.period.rewardLuckyDraw.name_en !== undefined || rowData.period.rewardLuckyDraw.name_en != null) {
                            return (rowData.period.rewardLuckyDraw.name_en);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "winnerType", label: "Winner Type", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        if (rowData.winnerType !== undefined || rowData.winnerType != null) {
                            return (rowData.winnerType);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "status", label: "Status", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        if (rowData.status !== undefined || rowData.status != null) {
                            return (rowData.status);
                        } else {
                            return "";
                        }
                    }
                }
            },
        ];

        let options = {
            serverSide: true,
            count: (tableTotal ? tableTotal : 0),
            page: tableFilter.page ? tableFilter.page : 0,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }
                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }
                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        return (
            <div id="tableWinnerList">
                <CustomSweetAlert />
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        data={tableDatas}
                        columns={columns}
                        options={options}
                    />
                    {showLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, showLoader, tableTotal, tableFilter } = state.winner;
    const { visiblePersonalData, merchantSetting } = state.authentication;

    return {
        formInitialValue, tableReload, tableReset, visiblePersonalData, tableDatas, tableKey, showLoader, merchantSetting, tableTotal, tableFilter
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableWinnerList));  

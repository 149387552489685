import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import _ from 'lodash';

export const ocrReportServices = {
    getOcrReport,
    getOcrReportCSV,
    getOcrReportDailyCSV
}

function getOcrReport(params) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/ocr`, params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
       if (response.data.statusCode.toString() === "200") {
          return { status: true, data: response.data.data, message: response.data.message };
       } else if (response.data.message === "UNAUTHORIZE") {
          history.push('/auth/login')
          return { status: false, data: {}, message: response.data.message, unAlert: true };
       } else {
          return { status: false, data: {}, message: response.data.message };
       }
    })
    .catch(e => {
       return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

 return tmp;
}

function getOcrReportCSV(params) {
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/ocr/export`, params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
   .then(response => {
      if (response.data.statusCode.toString() === "200") {
         const urlDownload = _.get(response.data, "data.url")
         if(urlDownload){
            window.location.assign(urlDownload)
         }
         return { status: true, data: {}, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
         history.push('/auth/login')
         return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
         return { status: false, data: {}, message: response.data.message };
      }
   })
   .catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
   });

return tmp;
}

function getOcrReportDailyCSV(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/daily-ocr/${params}/export`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
   .then(response => {
      if (response.data.statusCode.toString() === "200") {
         const urlDownload = _.get(response.data, "data.url")
         if(urlDownload){
            window.location.assign(urlDownload)
         }
         return { status: true, data: {}, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
         history.push('/auth/login')
         return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
         return { status: false, data: {}, message: response.data.message };
      }
   })
   .catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
   });

return tmp;
}
import React from "react";
import { Field, touch, change } from 'redux-form';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// import { campaign_v2Constants } from '../../../constants';
import { earnSpecialsConstants } from '../../../constants';
import { normalize } from 'helpers'

import i18n from 'i18n';

import * as FormValidator from '../validate/FormValidator';

function FormRewardPoint({ ...props }) {
    const { dispatch, formMode, rewardImagePreview } = props.props
    const { FORM_NAME } = props

    let { readOnly } = props.props

    if (formMode === `add`) {
        readOnly = false
    } else if (formMode === `edit` && !readOnly) {
        readOnly = false
    } else {
        readOnly = true
    }

    // console.log('reward readOnly :',readOnly);
    // console.log('FORM_NAME :',FORM_NAME)

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Field
                                name="rewardDetailSetName"
                                component={CustomInput}
                                type="text"
                                labelText={i18n.t("label.reward_name")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                disabled={readOnly}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Field
                                name="rewardDetailDescription"
                                component={CustomInput}
                                type="text"
                                labelText={i18n.t("label.reward_description")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                disabled={readOnly}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Field
                                name="rewardPicture"
                                component={ImageUpload}
                                type="text"
                                labelText={i18n.t("label.reward_picture")}
                                textLeft={true}
                                height={190}
                                showRatioGuide={true}
                                onChange={(e) => {
                                    e.preventDefault();
                                    let reader = new FileReader();
                                    let file = e.target.files[0];
                                    reader.onloadend = () => {
                                        dispatch({ type: earnSpecialsConstants.SET_FORM_PREVIEW_IMAGE_REWARD, rewardImagePreview: reader.result })
                                    };
                                    try {
                                        if (!FormValidator.validMimeType.includes(file.type)) {
                                            dispatch(touch(FORM_NAME, 'rewardPicture'));
                                        }

                                        // console.log('1234');
                                        reader.readAsDataURL(file);
                                        // console.log('file :',file);
                                        dispatch(change(FORM_NAME, 'rewardPicture', file))
                                    } catch (error) {
                                        dispatch({ type: earnSpecialsConstants.SET_FORM_PREVIEW_IMAGE_REWARD, rewardImagePreview: "default" })
                                        dispatch(change(FORM_NAME, 'rewardPicture', ''))
                                        console.log('error:',error);
                                    }
                                }}
                                imagePreviewUrl={rewardImagePreview}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                validate={[FormValidator.validateImageFormat]}
                                disabled={readOnly}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Field
                                name="rewardDetailPointEarn"
                                component={CustomInput}
                                type="text"
                                labelText={i18n.t("label.reward_point_earn")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                disabled={readOnly}
                                normalize={normalize.onlyNumber}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </>
    );
}

export default FormRewardPoint;

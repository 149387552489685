import React from "react";
import { Field, touch, change } from 'redux-form';

import qs from 'qs'
import ReactAsyncSelect from 'react-select/async'
import lodash from 'lodash';
import { components, createFilter } from 'react-select';
import red from '@material-ui/core/colors/red';
import debounce from "debounce-promise";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import RichEditor from "components/RichEditor/RichEditor.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { listServices } from '../../../services'
import { campaign_v2Constants } from '../../../constants';
import { crmValidator, normalize } from 'helpers'

import i18n from 'i18n';

import * as FormValidator from '../validate/FormValidator';

const customReactSelectStyles = {
   control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "none",
      borderBottom: "solid 1px #D2D2D2",
      boxShadow: "none",
      "&:hover": {
         borderColor: "#f4b340"
       }
   }),
   container: (provided, state) => ({
      ...provided,
      paddingTop: "22px"
   })
}
const MenuList = props => {
   return (
       <React.Fragment>
           <GridContainer style={{
               borderTopLeftRadius: "6px",
               borderTopRightRadius: "6px",
               borderBottom: "1px solid #aaa",
               backgroundColor: "rgb(255, 255, 255)",
               width: "100%",
               margin: "0px",
               padding: "8px 0px"
           }}>
               <GridItem xs={4}>SKU ID</GridItem>
               <GridItem xs={8}>Product Name</GridItem>
           </GridContainer>
           <components.MenuList {...props}>
               {props.children}
           </components.MenuList>
       </React.Fragment>
   );
};
const renderFieldError = (field) => (
   <div className="input-row">
       {field.meta.touched && field.meta.error &&
           <span className="error" style={{ color: red[500], fontSize: "0.75rem" }}>{i18n.t(field.meta.error)}</span>}
   </div>
)

function FromQRCoupon({ ...props }) {
   const { classes, dispatch, formMode, rewardImagePreview, FORM_CAMPAIGN_V2 } = props.props
   const { FORM_NAME } = props

   let { readOnly } = props.props

   if (formMode === `add`) {
      readOnly = false
   } else if (formMode === `edit` && !readOnly) {
      readOnly = false
   } else {
      readOnly = true
   }

const loadProductOptionsPromise = (inputValue) => {
      return new Promise(async (resolve, reject) => {
          let { formValues } = props.props
          let formValuesConditions = lodash.get(formValues, 'conditions')
          let selectedSKU = []
   
          lodash.forEach(formValuesConditions, function (value) {
              let orCondition = lodash.get(value, 'in')
              lodash.forEach(orCondition, function (cond) {
                  let selected = lodash.get(cond, 'id')
                  selectedSKU.push(selected)
              })
          })
   
          let reqParams = {
              skip: 0,
              limit: 1000,
              sortBy: "name",
              sortDir: "asc",
              keyword: inputValue,
              keywordFilterList: ["name", "skuId"]
          }
          let productList = await listServices.getListsProduct(qs.stringify(reqParams))
          let formattedProductList = []
          if (lodash.has(productList, 'data.products')) {
              productList.data.products.map((value) => {
                  let productsku = lodash.get(value, 'skuId')
                  if ((lodash.indexOf(selectedSKU, productsku) === -1) && productsku) {
                      formattedProductList.push({
                          value: productsku,
                          label: lodash.get(value, 'name')
                      })
                  }
                  return formattedProductList
              })
          }
          resolve(formattedProductList)
      });
};
const loadDelays = 800
const loadProduct = inputValue => loadProductOptionsPromise(inputValue)
         var loadProductOptionsDelay = debounce(loadProduct, loadDelays, {
            leading: true
         })

const handleChangePrivilegeType = () => {
   let { FORM_CAMPAIGN_V2 } = props.props
   if(lodash.get(FORM_CAMPAIGN_V2, 'values.rewardDetail.privilegeType') === 'discount'){
      lodash.set(FORM_CAMPAIGN_V2,"values.rewardDetail.quantity", "")
      lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.quantity.touched", false)
      lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.quantity.visited", false)

      lodash.set(FORM_CAMPAIGN_V2,"values.rewardDetail.skuId", "")
      lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.skuId.touched", false)
      lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.skuId.visited", false)

      lodash.set(FORM_CAMPAIGN_V2,"values.rewardDetail.totalPrice", "")
      lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.totalPrice.touched", false)
      lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.totalPrice.visited", false)

      if(lodash.get(FORM_CAMPAIGN_V2, 'values.rewardDetail.discountType') === 'amount'){
         lodash.set(FORM_CAMPAIGN_V2,"values.rewardDetail.couponCodeDiscountPercentage", "")  
         lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.couponCodeDiscountPercentage.touched", false)
         lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.couponCodeDiscountPercentage.visited", false) 
      }else{
         lodash.set(FORM_CAMPAIGN_V2,"values.rewardDetail.couponCodeDiscountAmount", "")
         lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.couponCodeDiscountAmount.touched", false)
         lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.couponCodeDiscountAmount.visited", false)   
      }  
   }else{
         lodash.set(FORM_CAMPAIGN_V2,"values.rewardDetail.couponCodeDiscountAmount", "")
         lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.couponCodeDiscountAmount.touched", false)
         lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.couponCodeDiscountAmount.visited", false)
         lodash.set(FORM_CAMPAIGN_V2,"values.rewardDetail.couponCodeDiscountPercentage", "")   
         lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.couponCodeDiscountPercentage.touched", false)
         lodash.set(FORM_CAMPAIGN_V2,"fields.rewardDetail.couponCodeDiscountPercentage.visited", false) 
   }
}
   const renderCustomOption = (props) => {
      //    const { classes } = props
         delete props.innerProps.onMouseMove;
         delete props.innerProps.onMouseOver;
   
         return (
               <components.Option {...props} className={classes.optionHover}>
                  <GridContainer>
                     <GridItem xs={4}>{lodash.get(props, 'data.value')}</GridItem>
                     <GridItem xs={8}>{lodash.get(props, 'data.label')}</GridItem>
                  </GridContainer>
               </components.Option>
         );
   };

   return (
      <>
         <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
               <GridContainer>

                  <GridItem xs={12} sm={6} md={6}>
                     <Field
                        name="rewardDetail[setName]"
                        component={CustomInput}
                        type="text"
                        labelText={i18n.t("label.reward_name")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        disabled={readOnly}
                     />
                  </GridItem>
                  <GridItem sm={6} md={6}></GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                     <Field
                        name="rewardDetail[description]"
                        component={CustomInput}
                        type="text"
                        labelText={i18n.t("label.reward_description")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        disabled={readOnly}
                     />
                  </GridItem>

               </GridContainer>

               <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Field
                            name="rewardDetail[termAndCondition]"
                            component={RichEditor}
                            labelText={i18n.t("label.reward_term_and_condition")}
                        />
                    </GridItem>

               </GridContainer>

               <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                     <Field
                        name="rewardPicture"
                        component={ImageUpload}
                        type="text"
                        labelText={i18n.t("label.reward_picture")}
                        textLeft={true}
                        height={190}
                        showRatioGuide={true}
                        onChange={(e) => {
                           e.preventDefault();
                           let reader = new FileReader();
                           let file = e.target.files[0];
                           reader.onloadend = () => {
                              dispatch({ type: campaign_v2Constants.SET_FORM_PREVIEW_IMAGE_REWARD, rewardImagePreview: reader.result })
                           };
                           try {
                              if (!FormValidator.validMimeType.includes(file.type)) {
                                 dispatch(touch(FORM_NAME, 'rewardPicture'));
                              }
                              reader.readAsDataURL(file);
                              dispatch(change(FORM_NAME, 'rewardPicture', file))
                           } catch (error) {
                              dispatch({ type: campaign_v2Constants.SET_FORM_PREVIEW_IMAGE_REWARD, rewardImagePreview: "default" })
                              dispatch(change(FORM_NAME, 'rewardPicture', ''))
                           }
                        }}
                        imagePreviewUrl={rewardImagePreview}
                        formControlProps={{
                           fullWidth: true
                        }}
                        validate={(formMode === "add" ? [FormValidator.validateImageFormat, FormValidator.validateRequiredImage] : [FormValidator.validateImageFormat])}
                        disabled={readOnly}
                     />
                  </GridItem>
               </GridContainer>

               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <h6><b>{i18n.t("label.reward_qr_privilege")}</b></h6>
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                     <Field
                        name="rewardDetail[privilegeType]"
                        component={CustomSelect}
                        type="text"
                        labelText={i18n.t("label.privilege_type")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        disabled={readOnly || formMode === "edit"}
                        optionsList={[
                           { value: "discount", label: "Discount" },
                           { value: "sku", label: "Product SKU" },
                        ]}
                        validate={FormValidator.validateRequired}
                        onChange={(e) => { handleChangePrivilegeType() }}
                     />
                  </GridItem>
                  { lodash.get(FORM_CAMPAIGN_V2, 'values.rewardDetail[privilegeType]') === 'discount' ? <>
                     <GridItem xs={12} sm={12} md={12}>
                        <Field
                           id="discount-radio"
                           name="rewardDetail[discountType]"
                           component={CustomRadio}
                           radioLists={[
                                 {
                                    value: "amount",
                                    label: <span style={{ fontWeight: '300'}}>{i18n.t("label.reward_qr_discount_amount")}</span>,
                                    disabled: readOnly || formMode === "edit"
                                 }
                           ]}
                           onChange={(e) => { handleChangePrivilegeType() }}
                        />  
                     </GridItem>
                        { lodash.get(FORM_CAMPAIGN_V2, 'values.rewardDetail[discountType]') === 'amount' ?
                           <GridItem xs={12} sm={6} md={6}>
                              <Field
                              name="rewardDetail[couponCodeDiscountAmount]"
                              component={CustomInput}
                              type="text"
                              labelText={i18n.t("label.reward_qr_discount_amount_field")}
                              formControlProps={{
                                 fullWidth: true
                              }}
                              disabled={readOnly || formMode === "edit"}
                              normalize={normalize.onlyFloatingTwo}
                              validate={lodash.get(FORM_CAMPAIGN_V2, 'values.rewardDetail[discountType]') === 'amount' ? [FormValidator.validateRequired, FormValidator.validateIsZero] : null}
                              />
                           </GridItem>
                        : null }
                        <GridItem xs={12} sm={12} md={12}>
                           <Field
                              id="discount-radio"
                              name="rewardDetail[discountType]"
                              component={CustomRadio}
                              radioLists={[
                                    {
                                       value: "percentage",
                                       label: <span style={{ fontWeight: '300'}}>{i18n.t("label.reward_qr_discount_percentage")}</span>,
                                       disabled: readOnly || formMode === "edit"
                                    }
                              ]}
                              onChange={(e) => { handleChangePrivilegeType() }}
                           />   
                        </GridItem>     
                        { lodash.get(FORM_CAMPAIGN_V2, 'values.rewardDetail[discountType]') === 'percentage' ?
                           <GridItem xs={12} sm={6} md={6}>
                              <Field
                              name="rewardDetail[couponCodeDiscountPercentage]"
                              component={CustomInput}
                              type="text"
                              labelText={i18n.t("label.reward_qr_discount_amount_field")}
                              formControlProps={{
                                 fullWidth: true
                              }}
                              disabled={readOnly || formMode === "edit"}
                              normalize={normalize.numberMoreThanZero}
                              validate={lodash.get(FORM_CAMPAIGN_V2, 'values.rewardDetail[discountType]') === 'percentage' ? [FormValidator.validateRequired, FormValidator.validateIsZero] : null}
                              />
                           </GridItem>
                        : null }
                  </> : (lodash.get(FORM_CAMPAIGN_V2, 'values.rewardDetail[privilegeType]') === 'sku' ?
                  <>
                  <GridItem xs={12} sm={9} md={9}></GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                     <ReactAsyncSelect
                        styles={customReactSelectStyles}
                        value={ lodash.get(FORM_CAMPAIGN_V2.values, 'rewardDetail[skuId]') ? { label: lodash.get(FORM_CAMPAIGN_V2.values, 'rewardDetail[skuId]'), value: lodash.get(FORM_CAMPAIGN_V2.values, 'rewardDetail[skuId]')} : ""}
                        name="rewardDetail[skuId]"
                        placeholder="SKU"
                        loadOptions={inputValue => loadProductOptionsDelay(inputValue)}
                        onChange={(value) => {
                           if(value != null){
                              const skuId = value.value ? value.value : ""
                              dispatch(change(FORM_NAME, 'rewardDetail[skuId]', skuId))
                           }else{
                              dispatch(change(FORM_NAME, 'rewardDetail[skuId]', ""))
                           }
                        }}
                        isDisabled={readOnly || formMode === "edit"}
                        isClearable={true}
                        menuListType="sku"
                        components={{ MenuList, Option: renderCustomOption.bind(this) }}
                        noOptionsMessage={({ inputValue }) => {
                           if (inputValue.length === 0) {
                              return (<span>Please enter keyword(s) to search product.</span>)
                           } else {
                              return (<span>Not found</span>)
                           }
                        }}
                        filterOption={createFilter({ignoreAccents: false})}
                     />
                     <Field
                       component={renderFieldError}
                       type="input"
                       name="rewardDetail[skuId]"
                       validate={FormValidator.validateRequired}
                     />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                     <Field
                        name="rewardDetail[quantity]"
                        component={CustomInput}
                        type="text"
                        labelText={i18n.t("label.quantity")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        disabled={readOnly || formMode === "edit"}
                        normalize={normalize.numberMoreThanZero}
                        validate={FormValidator.validateRequired}
                        />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                     <Field
                        name="rewardDetail[totalPrice]"
                        component={CustomInput}
                        type="text"
                        labelText={i18n.t("label.totalPrice")+' (If free, please enter 0)'}
                        formControlProps={{
                           fullWidth: true
                        }}
                        disabled={readOnly || formMode === "edit"}
                        normalize={normalize.onlyFloatingTwo}
                        validate={FormValidator.validateRequired}
                     />
                  </GridItem> 
                  </>
                  : null )}
               </GridContainer>

               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <h6><b>{i18n.t("label.reward_qr_expire")}</b></h6>
                  </GridItem>
                  <GridItem xs={6} sm={3} md={3}>
                     <Field
                     name="rewardDetail[couponCodeExpire][hours]"
                     component={CustomInput}
                     type="text"
                     labelText={i18n.t("label.reward_qr_expire_hours")}
                     formControlProps={{
                        fullWidth: true
                     }}
                     disabled={readOnly}
                     normalize={normalize.onlyNumber}
                     />
                  </GridItem>

                  <GridItem xs={6} sm={3} md={3}>
                     <Field
                     name="rewardDetail[couponCodeExpire][minutes]"
                     component={CustomInput}
                     type="text"
                     labelText={i18n.t("label.reward_qr_expire_minutes")}
                     formControlProps={{
                        fullWidth: true
                     }}
                     disabled={readOnly}
                     normalize={normalize.onlyNumber}
                     />
                  </GridItem>
                  <GridItem sm={6} md={6}></GridItem>
               </GridContainer>               

               <GridContainer>

                  <GridItem xs={12} sm={6} md={6}>
                     <Field
                     name="rewardDetail[couponCodeURL]"
                     component={CustomInput}
                     type="text"
                     labelText={i18n.t("label.reward_qr_url")}
                     formControlProps={{
                        fullWidth: true
                     }}
                     disabled={readOnly}
                     />
                  </GridItem>
                  <GridItem sm={6} md={6}></GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                     <Field
                     name="rewardDetail[SMSSuggestFriend]"
                     component={CustomInput}
                     type="text"
                     labelText={i18n.t("label.reward_qr_sms_suggest_friend")}
                     formControlProps={{
                        fullWidth: true
                     }}
                     disabled={readOnly}
                     />
                  </GridItem>

                </GridContainer>

            </GridItem>
         </GridContainer>
      </>
   );
}

export default FromQRCoupon;

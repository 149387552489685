import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// page components
import Badge from "components/Badge/Badge.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Button from 'components/CustomButtons/Button'

import { ReferenceCodeConstants, swalConstants } from '../../constants';
import { ReferenceCodeActions } from 'actions';
import { plugins, crmValidator } from 'helpers';

import copy from 'clipboard-copy'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import i18n from 'i18n';
import FormReferenceCode from "./FormReferenceCode";

const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
   },
   actionDiv: {
      display: "inline-flex"
   }
};

const moduleName = 'reference_code';

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

const renderBadgeStatus = (status) => {
   let BadgeColor
   switch (status) {
      case "Active": BadgeColor = "success"; break;
      case "Expired": BadgeColor = "danger"; break;
      default: BadgeColor = "gray";
   }
   return (<Badge color={BadgeColor}>{status}</Badge>)
}

class TableReferenceCodeList extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         readOnly: true,
         isLoading: false,
         page: 0,
         rowsPerPage: 10,
         count: 100,
         data: [],
         reqParams: ""
      };

      this.tableRef = React.createRef();
      this.delayedCallback = lodash.debounce(this.getData, 500)

   }

   componentWillMount() {
      console.log('componentWillMount')
      const { dispatch } = this.props;
      dispatch({ type: ReferenceCodeConstants.CLEAR_PAGE_STATE })
   }

   componentDidMount() {
      console.log('componentDidMount')
      this.getData();
   }

   componentDidUpdate() {
      const { tableReload, tableReset, dispatch } = this.props;
      if (tableReload || tableReset) {
         this.getData();
         dispatch({ type: ReferenceCodeConstants.TABLE_RELOAD_SUCCESS })
      }
   }

   getData = (skip, limit, sortBy, sortDir, keyword) => {
      const { dispatch, tableReload } = this.props

      let reqParams = "";

      if (!tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            skip = 0
         }

         if (crmValidator.isEmptyValue(limit)) {
            limit = 10
         }

         this.setState({
            page: skip,
            rowsPerPage: limit
         })
         skip = parseInt(limit) * parseInt(skip)

         reqParams += '&skip=' + skip
         reqParams += '&limit=' + limit

         if (!crmValidator.isEmptyValue(sortBy)) {
            reqParams += '&sortBy=' + sortBy
         }

         if (!crmValidator.isEmptyValue(sortDir)) {
            reqParams += '&sortDir=' + sortDir
         }

         if (!crmValidator.isEmptyValue(keyword)) {
            reqParams += '&keyword=' + keyword
         }
      } else {
         reqParams = this.state.reqParams
      }

      dispatch(ReferenceCodeActions.getListsReferenceCode(reqParams));

      this.setState({
         isLoading: true,
         reqParams: reqParams
      });

   }

   openAddReferenceCode = () => {
      const { dispatch } = this.props;
      dispatch({ 
         type: ReferenceCodeConstants.OPEN_ADD_REFERENCE, formInitialValue: {
            status: 'Active'
         } 
      }); //OPEN_ADD_CAMPAIGN
   }

   openEdit(rowIndex) {
      const { dispatch } = this.props;
      const rowData = this.getRowData(rowIndex)
      dispatch({ type: ReferenceCodeConstants.OPEN_EDIT_REFERENCE, formInitialValue: { ...rowData } })
  }


   getRowData(rowIndex = 0) {
      const { tableDatas } = this.props;
      const data = tableDatas.refs;
      return data[rowIndex];
   }

   displayId(val) {
      if (val) {
         return `CP${val.toString().padStart(5, '0')}`
      }
   }

   render() {
      const { classes, dispatch, formInitialValue, tableDatas, tableKey } = this.props;
      const { rowsPerPage, page } = this.state;

      let columns = [
         { name: "_id", options: { display: "excluded" } },
         { name: "displayId", options: { display: "excluded" } },
         {
            label: i18n.t("label.no"),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta, updateValue) => {

                  const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                  const rowIndex = rowSatrt + tableMeta.rowIndex;

                  return rowIndex + 1;
               }
            }
         },
         { name: "campaignName", label: "campaign Name" },
         { name: "ref_code", label: "Reference code" },
         { name: "ref_name", label: "Reference name" },
         
         
      ];

      if (plugins.isHasPermission(moduleName, 'read')) {
         columns.push({
            label: i18n.t("label.action"), options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  const { campaignUrl } = this.props.tableDatas
                  return (
                     <div className={classes.actionDiv}>
                        {plugins.isHasPermission(moduleName, 'update') &&
                           <TableActionButton
                              actionType="edit"
                              onClick={(e) => {
                                 this.openEdit(tableMeta.rowIndex)
                              }}
                           />
                        }
                        {plugins.isHasPermission(moduleName, 'delete') &&
                           <TableActionButton
                              actionType="delete"
                              onClick={(e) => {
                                 const rowData = this.getRowData(tableMeta.rowIndex);
                                 dispatch({
                                    type: swalConstants.SHOW, data: {
                                       type: "warning",
                                       title: i18n.t("warning_messages.are_you_sure_delete_reference_code"),
                                       message: `"${rowData.ref_code}"`,
                                       onConfirm: function () {
                                          let reqParams = {
                                             _id: rowData._id
                                          }
                                          dispatch({ type: swalConstants.HIDE })
                                          dispatch(ReferenceCodeActions.deleteReferenceCode(reqParams));
                                       },
                                       onCancel: function () {
                                          dispatch({ type: swalConstants.HIDE })
                                       },
                                       showCancel: true,
                                       confirmBtnText: i18n.t("delete"),
                                       cancelBtnText: i18n.t("cancel")
                                    }
                                 })
                              }} />
                        }
                     </div>
                  )
               }
            }
         })
      }

      let options = {
         serverSide: true,
         count: tableDatas.total,
         page: page,
         rowsPerPage: rowsPerPage,
         selectableRows: "none",
         print: false,
         download: false,
         filter: false,
         responsive: "scroll",
         rowsPerPageOptions: [1, 5, 10, 20],
         onTableChange: (action, tableState) => {

            let skip, limit, sortBy, sortDir, keyword = ""

            if (tableState.page !== null) {
               skip = tableState.page
            }

            if (tableState.rowsPerPage !== null) {
               limit = tableState.rowsPerPage
            }

            if (tableState.activeColumn !== null) {
               sortBy = tableState.columns[tableState.activeColumn].name
            }
            if (tableState.announceText !== null) {
               sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
            }
            if (tableState.searchText !== null || action === 'search') {
               keyword = tableState.searchText
               this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
            }
            let actionList = ['changePage', 'sort', 'changeRowsPerPage']
            if (actionList.indexOf(action) !== -1) {
               console.log('page')
               this.getData(skip, limit, sortBy, sortDir, keyword)
            }
         },
      };

      if (plugins.isHasPermission(moduleName, 'create')) {
         options.customToolbar = () => {
            return (
               <CustomToolbar handleClick={this.openAddReferenceCode} title={i18n.t("label.add_new")} />
            );
         }
      }

      return (
         <div id="tableCampaignList">
            <CustomSweetAlert />
            {/* <ReferenceCodeForm
               initialValues={formInitialValue}
               readOnly={!plugins.isHasPermission(moduleName, 'view')}
            /> */}
            <FormReferenceCode
                  readOnly={!plugins.isHasPermission(moduleName, 'update')}
                  initialValues={formInitialValue} />
            <MuiThemeProvider theme={THEME}>

               <MUIDataTable
                  key={tableKey}
                  title={"REFERENCE CODE"}
                  data={tableDatas.refs}
                  // data={tableDatas.refcode}
                  columns={columns}
                  options={options}
               />

            </MuiThemeProvider>
            <NotificationContainer />
         </div>
      )
   }
}

function mapStateToProps(state) {
   const { formInitialValue, tableReload, tableReset, campaignData, tableDatas, tableKey } = state.reference_code;
   const { permission, superAdmin } = state.authentication;
   return {
      formInitialValue, tableReload, tableReset, campaignData, tableDatas, tableKey, permission, superAdmin
   };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableReferenceCodeList));
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CampaignSelect from "components/CustomSelect/CampaignSelect.jsx";

import { ReferenceCodeConstants } from '../../constants'
import { ReferenceCodeActions } from 'actions';

import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import moment from 'moment';


const FORM_NAME = 'REFERENCECODE_FORM'
const form_validate = {
    "ref_code": {
        rules: {
            required: true,
            noSpecialCharacters: true,
            number: true,
            maxLength: 50
        },
        messages: {
            required: 'กรุณากรอก',
            noSpecialCharacters: 'ไม่สามารถใช้อักขระพิเศษในชื่อ Reference Code ได้',
            number: 'กรุณากรอกข้อมูลที่เป็นตัวเลข',
            maxLength: 'ไม่สามารถใช้ชื่อ Reference Code ได้มากกว่า 50 ตัวอักษร'
        }
    },
    "campaignId": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือกแคมเปญ'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const normalizeTagMaxLength = (value) => {
    return value.substr(0, 50);
}

class FormReferenceCode extends React.Component {
    
    handleSubmit = (values) => {
        const { dispatch } = this.props 
        var reqParams = { 
            "campaignId": values.campaignId,
            "ref_code": values.ref_code,
            "ref_name": values.ref_name
        }

        if (this.props.formMode === "add") {
            dispatch(ReferenceCodeActions.addNewReferenceCode(reqParams));
        } else {
            reqParams._id = values._id
            dispatch(ReferenceCodeActions.editReferenceCode(reqParams));
        }

    }
    render() {
        const { classes, formOpen, formMode, handleSubmit, REFERENCECODE_FORM, initialValues, formCampaignlist } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        let startDateMinDate = new Date().setHours(0, 0, 0, 0)
        if(initialValues.startDate){
            startDateMinDate = moment(initialValues.startDate)
        }

        let endDateMinDate = startDateMinDate;
        if(lodash.has(REFERENCECODE_FORM, 'values.startDate')){
            if(REFERENCECODE_FORM.values.startDate){
                endDateMinDate = moment(REFERENCECODE_FORM.values.startDate)
            }
        }

        let selectCampaignllist = [...formCampaignlist];

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>{formMode === 'add' ? 'Add Reference Code' : (readOnly === true ? 'Reference Code Info' : 'Edit Reference Code')}</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                {formMode === "edit" && 
                                    <Field
                                        name="_id"
                                        component="input"
                                        type="hidden"
                                        disabled={true}
                                    />
                                }
                                <Field
                                     name="campaignId"
                                     component={CampaignSelect}
                                     type="text"
                                     labelText="Campaign name"
                                     formControlProps={{
                                         fullWidth: true
                                     }}
                                    optionsList={selectCampaignllist}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="ref_code"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Reference Code"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={normalizeTagMaxLength}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="ref_name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Reference Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={normalizeTagMaxLength}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        {readOnly === false &&
                            <Button
                                style={{ marginRight: "10px" }}
                                color="primary"
                                type="submit">
                                Save
                            </Button>
                        }
                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: ReferenceCodeConstants.CLOSE_FORM_REFERENCE })
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode,formCampaignlist } = state.reference_code;
    const { REFERENCECODE_FORM } = state.form
    return {
        formOpen, formMode, REFERENCECODE_FORM, formCampaignlist
    };
}

const connectedComponent = connect(mapStateToProps)(FormReferenceCode);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))
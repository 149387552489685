import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { merchantConstants } from '../../constants';
import { merchantActions } from 'actions';


const FORM_NAME = "FORM_MERCHANT";
const form_validate = {
   "merchantNameEN": {
      rules: {
         required: true ,
      },
      messages: {
         required: 'This field is required.',
      },
      alert: "Merchant name (Eng) is required."
   },
   "merchantNameTH": {
      rules: {
         required: true
      },
      messages: {
         required: 'This field is required.'
      },
      alert: "Merchant name (Thai) is required."
   },
   "contactPerson": {
      rules: {
         required: true
      },
      messages: {
         required: 'This field is required.'
      },
      alert: "กรุณากรอก..."
   },
   "contactTel": {
      rules: {
         required: true,
         isMobilePhone: true,
         maxLength: 10,
      },
      messages: {
         required: 'This field is required.',
         isMobilePhone: 'Incorrect Tel. format',
         maxLength: 'Incorrect Tel. format'
      },
      alert: "Contact tel. is required."
   },
   "contactEmail": {
      rules: {
         required: true,
         isEmail: true
      },
      messages: {
         required: 'This field is required.',
         isEmail: 'Incorrect Email format'
      },
      alert: "Contact Email. is required."
   },
   "dateFrom": {
      rules: {
         required: true
      },
      messages: {
         required: 'This field is required.'
      },
      alert: "Contact Date is required."
   },
   "dateTo": {
      rules: {
         required: true
      },
      messages: {
         required: 'This field is required.'
      },
      alert: "Contact Date is required."
   },
   "merchantCode": {
      rules: {
         required: true
      },
      messages: {
         required: 'This field is required.'
      },
      alert: "Merchant Code is required."
   },

   "industry": {
      rules: {
         required: true
      },
      messages: {
         required: 'This field is required.'
      },
      alert: "Industry is required."
   },
   "password": {
      rules: {
         required: true,
         minLength: 8,
         passwordStrength: true
      },
      messages: {
         required: 'This field is required.',
         minLength: 'รหัสผ่านต้องมีมากกว่า 8 ตัวขึ้นไป',
         passwordStrength: 'รหัสผ่านต้องประกอบด้วยตัวพิมพ์ใหญ่ (A-Z), ตัวพิมพ์เล็ก (a-z), ตัวเลข (0-9) และตัวอักขระพิเศษ (!,@,#,$,%,^,&,*)'
      },
      alert: "Password is required."
   },
   "passwordConfirmation": {
      rules: {
         required: true,
         equalTo: 'password'
      },
      messages: {
         required: 'This field is required.',
         equalTo: 'กรุณากรอกรหัสผ่านให้ตรงกัน'
      },
      alert: "Confirm Password is required."
   },
   "frontEndUrl": {
      rules: {
         required: true,
         isURL: true
      },
      messages: {
         required: 'This field is required.',
         isURL: 'Invalid URL'
      },
      alert: "URL is required."
   },
   "campaignUrl": {
      rules: {
         isURL: true
      },
      messages: {
         isURL: 'Invalid URL',
      },
   },
   "couponUrl": {
      rules: {
         isURL: true
      },
      messages: {
         isURL: 'Invalid URL',
      },
   },
   "winnerUrl": {
      rules: {
         isURL: true
      },
      messages: {
         isURL: 'Invalid URL',
      },
   },
   "merchantFrontEndUrl": {
      rules: {
         isSlugUrl:true,
         maxLength: 50,
      },
      messages: {
         isSlugUrl: 'Format  Merchant Name (Url) maximum 50 characters contain a number , lowercase letter , uppercase letter and special character( -, _ )',
         maxLength: 'Merchant Name (Url) is maximum 50 characters'
      }
   }
};

const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
   },
   cardFooter: {
      padding: "15px",
      textAlign: "right",
      paddingTop: "0",
      margin: "0"
   },
   labelHorizontalCenter: {
      float: "right",
      color: "rgba(0, 0, 0, 0.26)",
      cursor: "pointer",
      display: "inline-flex",
      fontSize: "14px",
      lineHeight: "1.428571429",
      fontWeight: "400",
      paddingTop: "39px",
      marginRight: "0",
   }
};

const validate = (values) => {
   const errors = crmValidator.validateByRulesList(form_validate, values);
   return errors
}

class MerchantForm extends React.Component {
   constructor(props) {
      super(props);
      this.state = {

      }
   }

   handleSubmit = (values) => {
      const { dispatch } = this.props;
      if (this.props.formMode === "add") {
         dispatch(merchantActions.addMerchant(values));
      } else {
         dispatch(merchantActions.updateMerchant(values));
      }
   }

   handleClose = (values) => {
      const { dispatch } = this.props;
      dispatch({ type: merchantConstants.CLOSE_FORM });
   }

   render() {
      const { formOpen, formMode, classes, handleSubmit } = this.props;
      let { readOnly } = this.props

      if (formMode === `add`) {
         readOnly = false
      } else if (formMode === `edit` && !readOnly) {
         readOnly = false
      } else {
         readOnly = true
      }

      const displayCard = formOpen ? { padding: "15px"} : { display: "none" }

      return (
         <div style={displayCard}>
            <CardHeader icon>
               <h4 className={classes.cardIconTitle} style={{marginLeft: "5px"}}>
                  <b>{formMode === 'add' ? 'Create Merchant' : (readOnly ? 'Merchant Info' : 'Edit Merchant')}</b>
               </h4>
            </CardHeader>
            <CardBody>
               <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                  <GridContainer>
                     <Field
                        name="merchantId"
                        component="input"
                        type="hidden"
                     />
                     <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                           Merchant name (Eng) :
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={12} md={6}>
                        <Field
                           name="merchantNameEN"
                           component={CustomInput}
                           type="text"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                           Merchant name (Thai) :
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={9} md={6}>
                        <Field
                           name="merchantNameTH"
                           component={CustomInput}
                           type="text"
                           // labelText="Merchant Name"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>

                  <GridContainer>
                     <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                           Contact Person :
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={9} md={6}>
                        <Field
                           name="contactPerson"
                           component={CustomInput}
                           type="text"
                           // labelText="Merchant Name"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                           Contact Tel. :
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={9} md={6}>
                        <Field
                           name="contactTel"
                           component={CustomInput}
                           type="text"
                           // labelText="Merchant Name"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                           Contact Email :
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={9} md={6}>
                        <Field
                           name="contactEmail"
                           component={CustomInput}
                           type="text"
                           // labelText="Merchant Name"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly || formMode === 'edit'}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                           Contract Date :
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={12} md={3}>
                        <Field
                           name="dateFrom"
                           component={CustomDatePicker}
                           type="text"
                           dateFormat="DD-MMM-YY"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly || formMode === 'edit'}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={12} md={1}>
                        <FormLabel className={classes.labelHorizontalCenter}>
                           To
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={12} md={3}>
                        <Field
                           name="dateTo"
                           component={CustomDatePicker}
                           type="text"
                           dateFormat="DD-MMM-YY"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly || formMode === 'edit'}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                           Merchant code :
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={12} md={3}>
                        <Field
                           name="merchantCode"
                           component={CustomInput}
                           type="text"
                           // labelText="Merchant Name"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly || formMode === 'edit'}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                           Merchant Name (Url) :
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={12} md={3}>
                        <Field
                           name="merchantFrontEndUrl"
                           component={CustomInput}
                           type="text"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly || formMode === 'edit'}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                           Industry :
                        </FormLabel>
                     </GridItem>
                     <GridItem xs={12} sm={12} md={3}>
                        <Field
                           name="industry"
                           component={CustomSelect}
                           type="text"
                           formControlProps={{
                              fullWidth: true
                           }}
                           optionsList={[
                              {
                                 value: "clothes",
                                 label: "Clothes"
                              },
                              {
                                 value: "food",
                                 label: "Food"
                              },
                              {
                                 value: "snack",
                                 label: "Snack"
                              },
                              {
                                 value: "bakery",
                                 label: "Bakery"
                              },
                              {
                                 value: "restaurant",
                                 label: "Restaurant"
                              },
                              {
                                 value: "cafe",
                                 label: "Cafe"
                              }
                           ]}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>

                  {formMode !== 'edit' ?
                     <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                           <FormLabel className={classes.labelHorizontal}>
                              Password :
                        </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                           <Field
                              name="password"
                              component={CustomInput}
                              type="password"
                              // labelText="Password"
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                           <FormLabel className={classes.labelHorizontal}>
                              Confirm Password :
                        </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                           <Field
                              name="passwordConfirmation"
                              component={CustomInput}
                              type="password"
                              // labelText="Confirm Password"
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                        </GridItem>
                     </GridContainer>
                     : null}

                     <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                           <FormLabel className={classes.labelHorizontal}>
                              URL :
                           </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                           <Field
                              name="frontEndUrl"
                              component={CustomInput}
                              type="text"
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                        </GridItem>
                     </GridContainer>
                     
                     <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                           <FormLabel className={classes.labelHorizontal}>
                              Campaign URL :
                           </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                           <Field
                              name="campaignUrl"
                              component={CustomInput}
                              type="text"
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                        </GridItem>
                     </GridContainer>

                     <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                           <FormLabel className={classes.labelHorizontal}>
                           Coupon URL :
                           </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                           <Field
                              name="couponUrl"
                              component={CustomInput}
                              type="text"
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                        </GridItem>
                     </GridContainer>

                     <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                           <FormLabel className={classes.labelHorizontal}>
                           Winner URL :
                           </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                           <Field
                              name="winnerUrl"
                              component={CustomInput}
                              type="text"
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                        </GridItem>
                     </GridContainer>

                     {/* <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                           <FormLabel className={classes.labelHorizontal}>
                              SMS Sender :
                        </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                           <Field
                              name="SMSSenderName"
                              component={CustomInput}
                              type="text"
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                        </GridItem>
                     </GridContainer>

                     <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                           <FormLabel className={classes.labelHorizontal}>
                              SMS Message :
                        </FormLabel>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                           <Field
                              name="SMSMessageTemplate"
                              component={CustomInput}
                              type="text"
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                           <div style={{color: "#555", fontSize: "13px"}}>Supported Key: [reward_name], [reward_expire_at], [coupon_url]</div>
                        </GridItem>
                     </GridContainer> */}

                     <GridContainer>
                        <GridItem xs={12} sm={12} md={12} style={{marginTop: "20px"}}>
                           <Button
                              type="submit"
                              color="primary">
                              Submit
                           </Button>
                           <Button
                              type="button"
                              onClick={this.handleClose}
                              style={{marginLeft: "10px"}}
                              color="white">
                              Cancel
                           </Button>
                        </GridItem>
                     </GridContainer>
               </form>
            </CardBody>
         </div>
      )
   }
}

function mapStateToProps(state) {
   const { formOpen, formMode } = state.merchant;
   const { FORM_MERCHANT } = state.form;
   return {
      formOpen, formMode, FORM_MERCHANT
   };
}

const connectMerchantForm = connect(mapStateToProps)(MerchantForm);

export default reduxForm({
   form: FORM_NAME,
   validate: validate,
   enableReinitialize: true,
   onSubmitFail: (errors, dispatch, props) => {
      crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
   }
})(withStyles({ ...style, ...regularFormsStyle })(connectMerchantForm))
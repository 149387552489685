import React from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change, untouch, getFormSyncErrors } from 'redux-form';
import { plugins, crmValidator, alert as customAlert } from 'helpers';
import moment from "moment";
import i18n from 'i18n';
import lodash from 'lodash'

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import MuiButton from '@material-ui/core/Button';
// import Collapse from '@material-ui/core/Collapse';
// import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ConditionBox from './components/ConditionBox'
import ConditionPurchaseAmountPerBill from './components/ConditionPurchaseAmountPerBill.jsx'
import ConditionPurchaseProduct from './components/ConditionPurchaseProduct.jsx'
import ConditionPaymentChannel from './components/ConditionPaymentChannel.jsx'
import ConditionPromotionCode from './components/ConditionPromotionCode.jsx'
import AdditionalShop from './components/AdditionalShop.jsx'
import ConditionDateTime from './components/ConditionDateTime.jsx'
import QuotaPromotion from './components/QuotaPromotion.jsx'
import RewardBox from './components/RewardBox'
import RewardFreeProduct from './components/RewardFreeProduct'
import RewardDiscount from './components/RewardDiscount'

import CustomSweetAlert from 'components/SweetAlert/CustomSweetAlert'
import { promotionConstants } from '../../constants'
import { promotionActions } from "actions/promotion.actions";
import * as FormValidator from "../Promotion/validate/FormValidator"
import { listActions, saleProductActions } from 'actions';

const FORM_NAME = "promotion_form";
const moduleName = 'promotion'

const style = {
    ...modalStyle,
    cardBody: {
        padding: "15px"
    },
    cardHeader: {
        marginLeft: "0px",
        color: "#333"
    },
    formFooter: {
        textAlign: 'right'
    },
    btnSubmit: {
        marginRight: "20px"
    },
    conditionBlock: {
        padding: "10px",
        marginLeft: "0px",
        border: "dashed 1px #ccc"
    },
    tdBordered: {
        border: "solid 1px #ddd"
    },
    subHeader: {
        fontWeight: 900,
        fontSize: "16px",
        marginTop: "20px"
    }
}

const form_validate = {
    "promotionName": {
        rules: {
            required: true,
        },
        messages: {
            required: 'Promotion Name is required',
        },
        alert: "Promotion Name is required"
    },
    "status": {
        rules: {
            required: true,
        },
        messages: {
            required: 'Status is required',
        },
        alert: "Status is required"
    },
    "startDate": {
        rules: {
            required: true,
        },
        messages: {
            required: 'Start Date is required',
        },
        alert: "Start Date is required"
    },
    "hasCondition": {
        rules: {
            required: true
        },
        messages: {
            required: 'Condition Rule is required',
        },
        alert: "Condition Rule is required"
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const validateConditionTypes = (values) => {
    if (lodash.size(values) === 0) {
        return "Condition type is required"
    }
}

const validateRewardTypes = (values) => {
    if (lodash.size(values) === 0) {
        return "Reward type is required"
    }
}

const validateStartDate = (value, allValues) => {
    if (value && lodash.has(allValues, 'endDate')) {
        if (moment(value) >= moment(lodash.get(allValues, 'endDate'))) {
            return "Start Date must be before End date"
        }
    }
}

const validateEndDate = (value, allValues) => {
    if (value && lodash.has(allValues, 'startDate')) {
        if (moment(value) <= moment(lodash.get(allValues, 'startDate'))) {
            return "End Date must be after Start date"
        }
    }
}

const onInsertReferenceCode = (keyword) => { 
//     let regex = new RegExp(/[ก-๎ !"#$%&'()*+,/:;<=>?@[\]\\^`{|}~]/g);
//     const chars = keyword.split('');  
//     for(let i=0; i<chars.length; i++){
//        if(regex.test(chars[i])) { 
//         chars.splice(i, 1); 
//        }
//     }
//     keyword = chars.join(''); 
//     return keyword;
}

class PromotionForm extends React.Component {

    constructor(props) {
        super(props)
        this.promotionFormScollRef = React.createRef()
    }

    componentDidMount() {
        const { dispatch } = this.props;
        this.promotionFormScollRef.scrollIntoView()
        dispatch(listActions.getCategoryList('promotion'));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: promotionConstants.CLOSE_FORM_PROMOTION })
        dispatch({ type: promotionConstants.CLOSE_CONDITION_SHOP })
        dispatch({ type: promotionConstants.CLOSE_CONDITION_TIME })
    }

    onStartDateFocus = () => {
        const { formValues, dispatch } = this.props;
        if (!lodash.get(formValues, 'startDate')) {
            dispatch(change(FORM_NAME, 'startDate', moment({ hour: 0, minute: 0, seconds: 0 })))
        }
    }

    onEndDateFocus = () => {
        const { formValues, dispatch } = this.props;
        let startDate = lodash.get(formValues, 'startDate')

        if (!lodash.get(formValues, 'endDate')) {
            if(startDate) {
                dispatch(change(FORM_NAME, 'endDate', moment(startDate).hour(23).minute(59).second(59)))
            } else {
                dispatch(change(FORM_NAME, 'endDate', moment({ hour: 23, minute: 59, seconds: 59 })));
            }
        }
    }

    handleSubmit(values) {
        const { dispatch, formMode, allShopListSelected, formInitialValue } = this.props

        //Todo: format AdditionalShop
        const shopList = lodash.map(allShopListSelected, (value, index) => {
            return {
                "shopId": value.shopId || "",
                "shopName": value.shopName || "",
                "shopRegionId": value.shopRegionId || "",
                "_id": value._id || "",
            }
        })

        let dateListSelectDate = [];
        const date = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
        date.map(d => {
            if (lodash.get(values, 'selectedDate.' + d)) {
                dateListSelectDate.push(d)
            }
            return null;
        })

        let reqBody = {
            "_id": lodash.get(values, '_id'),
            "promotionName": lodash.get(values, 'promotionName'),
            "categoryId": lodash.get(values, 'categoryId'),
            "status": lodash.get(values, 'status'),
            "description": lodash.get(values, 'description'),
            "startDate": lodash.get(values, 'startDate'),
            "endDate": lodash.get(values, 'endDate'),
            "conditions": lodash.get(values, 'conditions') ? lodash.get(values, 'conditions') : [],
            "rewards": lodash.get(values, 'rewards'),
            "shopCondition": {
                shopList
            },
            "specific_date_time": null,
            "referenceCode": lodash.get(values, 'referenceCode') ? lodash.get(values, 'referenceCode') : "",
            "quota": {
                "promotionPerPromotion": lodash.get(values, 'quota.promotionPerPromotion') ? parseInt(lodash.get(values, 'quota.promotionPerPromotion')) : "",
                "promotionPerPurchase": lodash.get(values, 'quota.promotionPerPurchase') ? parseInt(lodash.get(values, 'quota.promotionPerPurchase')) : "",
            }
        }
        
        if(lodash.get(values, 'quotaPromotionType') === "quotaPerMonth"){
            reqBody = {
                ...reqBody,
                quota: {
                    ...reqBody.quota,
                    promotionPerMonth: lodash.get(values, 'quota.promotionPerMonth') ? parseInt(lodash.get(values, 'quota.promotionPerMonth')) : "",
                }
            }
        }else if(lodash.get(values, 'quotaPromotionType') === "quotaPerDay"){
            reqBody = {
                ...reqBody,
                quota: {
                    ...reqBody.quota,
                    promotionPerDayofWeek: {
                        "monday": lodash.get(values, 'quota.promotionPerDayofWeek.monday') ? parseInt(lodash.get(values, 'quota.promotionPerDayofWeek.monday')) : "",
                        "tuesday": lodash.get(values, 'quota.promotionPerDayofWeek.tuesday') ? parseInt(lodash.get(values, 'quota.promotionPerDayofWeek.tuesday')) : "",
                        "wednesday": lodash.get(values, 'quota.promotionPerDayofWeek.wednesday') ? parseInt(lodash.get(values, 'quota.promotionPerDayofWeek.wednesday')) : "",
                        "thursday": lodash.get(values, 'quota.promotionPerDayofWeek.thursday') ? parseInt(lodash.get(values, 'quota.promotionPerDayofWeek.thursday')) : "",
                        "friday": lodash.get(values, 'quota.promotionPerDayofWeek.friday') ? parseInt(lodash.get(values, 'quota.promotionPerDayofWeek.friday')) : "",
                        "saturday": lodash.get(values, 'quota.promotionPerDayofWeek.saturday') ? parseInt(lodash.get(values, 'quota.promotionPerDayofWeek.saturday')) : "",
                        "sunday": lodash.get(values, 'quota.promotionPerDayofWeek.sunday') ? parseInt(lodash.get(values, 'quota.promotionPerDayofWeek.sunday')) : "",
                    }
                }
            }
        }

        if (lodash.get(values, 'customCheckboxDate')) {
            reqBody = {
                ...reqBody,
                specific_date_time: {
                    day: {
                        type: lodash.get(values, 'customRadioParticipatedDay') === 'specific' ? "selected_day" : "everyday",
                        date_list: [],
                        selected: '',

                    },
                }
            }
            if (lodash.get(values, 'customRadioParticipatedDay') === 'specific') {
                reqBody = {
                    ...reqBody,
                    specific_date_time: {
                        day: {
                            ...reqBody.specific_date_time.day,
                            date_list: lodash.get(values, 'customRadioSelectedDay') === 'dayOfWeek' ? dateListSelectDate : lodash.get(values, 'date'),
                            selected: lodash.get(values, 'customRadioSelectedDay') === 'dayOfWeek' ? "day_of_week" : "date",
                        }
                    }
                }
            }
        }
        if (lodash.get(values, 'customCheckboxSpecificTime')) {
            let strStartTime = lodash.get(values, 'startTime') ? lodash.get(values, 'startTime') : formInitialValue.specific_date_time.time.startTime
            let strEndTime = lodash.get(values, 'endTime') ? lodash.get(values, 'endTime') : formInitialValue.specific_date_time.time.endTime
            let parsedStartTime = moment(strStartTime, "HH:mm:ss");
            let parsedEndTime = moment(strEndTime, "HH:mm:ss");
            if (parsedStartTime < parsedEndTime) { // start < end time
                reqBody = {
                    ...reqBody,
                    specific_date_time: {
                        ...reqBody.specific_date_time,
                        time: {
                            startTime: parsedStartTime._i,
                            endTime: parsedEndTime._i
                        }
                    }
                }
            } else { //start > end time
                return customAlert(dispatch, 'error', i18n.t(`promotion.warning_messages.start_time_must_be_less_than_end_time`))
            }
        }
        if (formMode === 'add') {
            dispatch(promotionActions.addPromotion(reqBody))
        } else {
            dispatch(promotionActions.updatePromotion(reqBody))
        }
    }

    getConditionInitialValue = (conditionType) => {
        const { formValues } = this.props
        let conditions = lodash.get(formValues, 'conditions')
        let condValues = lodash.find(conditions, { type: conditionType })
        if (conditionType === 'purchase_product') {
            if (!condValues) {
                condValues = {
                    "collectType": "product_qty",
                    "operator": "AND",
                    "conditions": [{
                        "operator": "OR",
                        "type": "sku"
                    }]
                }
            } else {
                if (lodash.has(condValues, 'conditions') === false) {
                    lodash.set(condValues, 'conditions', lodash.get(condValues, 'and'))
                }
                if (lodash.has(condValues, 'operator') === false) {
                    lodash.set(condValues, 'operator', 'AND')
                }
            }

        }
        return condValues
    }

    isHasConditionValue = (conditionType) => {
        const { formValues } = this.props
        let conditionsValue = lodash.get(formValues, 'conditions')
        let conditionTypeValue = lodash.find(conditionsValue, { type: conditionType })
        if (conditionTypeValue) {
            return true
        }
        return false
    }

    isHasRewardValue = (rewardType) => {
        const { formValues } = this.props
        let rewards = lodash.get(formValues, 'rewards')
        let rewardTypesValue = lodash.find(rewards, { type: rewardType })
        if (rewardTypesValue) {
            return true
        }
        return false
    }

    getRewardInitialValue = (rewardType) => {
        const { formValues } = this.props
        let rewards = lodash.get(formValues, 'rewards')
        let rewardValue = lodash.find(rewards, { type: rewardType })
        if ((rewardType === "free_product" || rewardType === "special_price") && !rewardValue) {
            rewardValue = {
                "operator": "AND",
                "conditions": [{
                    "operator": "OR",
                    "type": "sku"
                }]
            }
        } else if ((rewardType === "discount")) {
            if (!rewardValue) {
                rewardValue = {
                    "discount_type": "amount",
                    "discount_apply_for": "whole_bill"
                }
            } else {
                let newValue = lodash.get(rewardValue, 'conditions[0]')
                let newValueType = lodash.get(newValue, 'type')
                if (['sku', 'category', 'saleProduct'].includes(newValueType)) {
                    lodash.set(newValue, 'discount_apply_for', "skuOrCategory")
                    lodash.set(newValue, 'specificType', newValueType)
                    lodash.set(newValue, 'initialSelected', lodash.get(newValue, 'in'))
                } else {
                    lodash.set(newValue, 'discount_apply_for', "whole_bill")
                }
                rewardValue = newValue
            }
        }
        return rewardValue
    }

    renderConditionTypeBtn = ({ ...props }) => {
        let type = lodash.get(props, 'input.value')
        const { dispatch, formValues } = this.props
        let conditionsValue = lodash.get(formValues, 'conditions')
        if (type === "purchase_amount_per_bill") {
            let hasValue = lodash.find(conditionsValue, { type: 'purchase_amount_per_bill' })
            hasValue = lodash.get(hasValue, 'value')
            return (
                <MuiButton onClick={() => {
                    dispatch({ type: promotionConstants.OPEN_PURCHASE_AMOUNT_CONDITION })
                }}>{hasValue ? "EDIT" : "ADD"}</MuiButton>
            )
        } else {
            return (<></>)
        }
    }

    render() {
        const {
            classes,
            formMode,
            formValues,
            handleSubmit,
            purchaseAmountPerbillOpen,
            purchaseProductConditionOpen,
            paymentChannelConditionOpen,
            promoCodeConditionOpen,
            rewardModalOpen,
            dispatch,
            formInitialValue
        } = this.props
        let { categoryList,readOnly } = this.props

        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && plugins.isHasPermission(moduleName, 'update')) {
            readOnly = false
        } else {
            readOnly = true
        }

        return (
            <Card className={classes.cardBody}>
                <div ref={(ref) => this.promotionFormScollRef = ref}></div>
                <CardBody>
                    <h4 className={classes.cardHeader}>
                        <b>{formMode === "add" ? "Add Promotion" : (readOnly) ? "Promotion Detail" : "Edit Promotion"}</b>
                    </h4>
                    {purchaseAmountPerbillOpen &&
                        <ConditionPurchaseAmountPerBill initialValues={{ ...this.getConditionInitialValue('purchase_amount_per_bill') }} readOnly={readOnly} />
                    }
                    {purchaseProductConditionOpen &&
                        <ConditionPurchaseProduct initialValues={{ ...this.getConditionInitialValue('purchase_product') }} readOnly={readOnly} />
                    }
                    {paymentChannelConditionOpen &&
                        <ConditionPaymentChannel initialValues={{ ...this.getConditionInitialValue('payment_channel') }} readOnly={readOnly} />
                    }
                    {promoCodeConditionOpen &&
                        <ConditionPromotionCode initialValues={{ ...this.getConditionInitialValue('promotion_code') }} readOnly={readOnly} />
                    }
                    {(rewardModalOpen === "free_product") &&
                        <RewardFreeProduct initialValues={{ ...this.getRewardInitialValue('free_product') }} readOnly={readOnly} />
                    }
                    {(rewardModalOpen === "special_price") &&
                        <RewardFreeProduct initialValues={{ ...this.getRewardInitialValue('special_price') }} readOnly={readOnly} />
                    }
                    {(rewardModalOpen === "discount") &&
                        <RewardDiscount initialValues={{ ...this.getRewardInitialValue('discount') }} readOnly={readOnly} />
                    }
                    <CustomSweetAlert />
                    <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                        <GridContainer>
                            <GridItem xs={4}>
                                <Field
                                    name="_id"
                                    component="input"
                                    type="hidden"
                                    disabled={true}
                                />
                                <Field
                                    name="promotionName"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Promotion Name *"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={4}>
                                <Field
                                    name="categoryId"
                                    component={CustomSelect}
                                    labelText={"Category"}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    optionsList={categoryList.map(value => {
                                        return {
                                            value: value._id,
                                            label: value.name
                                        }
                                    })}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={4}>
                                <Field
                                    name="status"
                                    component={CustomSelect}
                                    type="text"
                                    labelText={"Status *"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            value: "Active",
                                            label: "Active"
                                        },
                                        {
                                            value: "Inactive",
                                            label: "Inactive"
                                        }
                                    ]}
                                    disabled={readOnly}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12}>
                                <Field
                                    name="description"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Description"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={4}>
                                <Field
                                    name="startDate"
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={"Start Date *"}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm:ss"
                                    maxDate={lodash.get(formValues, 'endDate')}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    validate={[validateStartDate]}
                                    onPickerFocus={this.onStartDateFocus.bind(this)}
                                />
                            </GridItem>
                            <GridItem xs={4}>
                                <Field
                                    name="endDate"
                                    component={CustomDatePicker}
                                    minDate={lodash.get(formValues, 'startDate')}
                                    type="text"
                                    labelText={"End Date"}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm:ss"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[validateEndDate]}
                                    disabled={readOnly}
                                    onPickerFocus={this.onEndDateFocus.bind(this)}
                                />
                            </GridItem>
                            <GridItem xs={4}>
                                <Field
                                    id="reference-code-input"
                                    name="referenceCode"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Reference Code"}
                                    maxLength={50}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    normalize={(value) => {
                                        // return crmValidator.handleKeyPress(onInsertReferenceCode(value), FormValidator.form_validate.referenceCode);
                                        return crmValidator.handleKeyPress(value, FormValidator.form_validate.referenceCode);

                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                        </GridContainer>
                        
                        <QuotaPromotion readOnly={!plugins.isHasPermission(moduleName, 'update')} />

                        <GridContainer className={classes.conditionBlock} style={{ width: "100%" }}>
                            <GridItem xs={12}>
                                <h4><b>Condition to get reward</b></h4>
                                <GridContainer style={{ padding: "10px" }}>
                                    <GridItem xs={12}>
                                        <Field
                                            name="hasCondition"
                                            component={CustomRadio}
                                            onChange={(e) => {
                                                if (lodash.get(e, 'target.value') === 'false') {
                                                    dispatch(change(FORM_NAME, 'conditionTypes', null))
                                                    dispatch(untouch(FORM_NAME, 'conditionTypes'))

                                                    dispatch(change(FORM_NAME, 'conditions', null))
                                                    dispatch(untouch(FORM_NAME, 'conditions'))
                                                }
                                            }}
                                            radioLists={[
                                                {
                                                    value: 'false',
                                                    label: "No Condition",
                                                    disabled: readOnly
                                                },
                                                {
                                                    value: 'true',
                                                    label: "With Condition",
                                                    disabled: readOnly
                                                }
                                            ]}
                                        />

                                    </GridItem>
                                    {(lodash.get(formValues, 'hasCondition') === 'true') &&
                                        <>
                                            <GridItem xs={12} style={{ padding: "10px" }}>
                                                <div style={{ marginLeft: "35px" }}>
                                                    <span style={{ marginRight: "20px" }}>{"Condition Type"}</span>
                                                    <Field
                                                        name="conditionTypes"
                                                        component={CustomSelect}
                                                        multiple={true}
                                                        type="text"
                                                        labelText={"Select Condition"}
                                                        formControlProps={{
                                                            fullWidth: false,
                                                            style: {
                                                                minWidth: "362px",
                                                                top: "-30px"
                                                            }
                                                        }}
                                                        showtick={true}
                                                        disabled={readOnly}
                                                        key={lodash.get(formValues, 'conditionTypesKey')}
                                                        optionsList={[
                                                            {
                                                                value: "purchase_product",
                                                                label: "Purchase Product",
                                                                disabled: this.isHasConditionValue('purchase_product')
                                                            },
                                                            {
                                                                value: "purchase_amount_per_bill",
                                                                label: "Purchase Amount per Bill",
                                                                disabled: this.isHasConditionValue('purchase_amount_per_bill')
                                                            },
                                                            {
                                                                value: "payment_channel",
                                                                label: "Payment Channel",
                                                                disabled: this.isHasConditionValue('payment_channel')
                                                            },
                                                            {
                                                                value: "promotion_code",
                                                                label: "Promotion Code",
                                                                disabled: this.isHasConditionValue('promotion_code')
                                                            }
                                                        ]}
                                                        validate={[validateConditionTypes]}
                                                    />
                                                </div>
                                            </GridItem>
                                            <GridItem xs={6} style={{ marginLeft: "35px" }}>
                                                <ConditionBox readOnly={!plugins.isHasPermission(moduleName, 'update')} />
                                            </GridItem>

                                        </>
                                    }
                                </GridContainer>
                            </GridItem>

                        </GridContainer>

                        <GridContainer className={classes.conditionBlock} style={{ width: "100%" }}>
                            <GridItem xs={12} style={{ padding: "10px" }}>
                                <h4><b>Reward</b></h4>
                                <div style={{ marginLeft: "35px" }}>
                                    <span style={{ marginRight: "20px" }}>{"Reward Type *"}</span>
                                    <Field
                                        name="rewardTypes"
                                        component={CustomSelect}
                                        multiple={true}
                                        type="text"
                                        labelText={"Select Reward"}
                                        formControlProps={{
                                            fullWidth: false,
                                            style: {
                                                minWidth: "362px",
                                                top: "-30px"
                                            }
                                        }}
                                        // key={`${lodash.size(lodash.get(formValues, 'rewardTypes'))}`}
                                        showtick={true}
                                        disabled={readOnly}
                                        optionsList={[
                                            {
                                                value: "free_product",
                                                label: "Free Product",
                                                disabled: this.isHasRewardValue('free_product')
                                            },
                                            {
                                                value: "discount",
                                                label: "Discount",
                                                disabled: this.isHasRewardValue('discount')
                                            },
                                            {
                                                value: "special_price",
                                                label: "Buy product with special price",
                                                disabled: this.isHasRewardValue('special_price')
                                            }
                                        ]}
                                        validate={[validateRewardTypes]}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={6} style={{ marginLeft: "35px" }}>
                                <RewardBox readOnly={!plugins.isHasPermission(moduleName, 'update')} />
                            </GridItem>
                        </GridContainer>

                        <GridContainer className={classes.conditionBlock} style={{ width: "100%" }}>
                            <GridItem xs={12} style={{ padding: "10px" }}>
                                <h4><b>Additional Conditions</b></h4>
                                <div style={{ marginLeft: "10px" }}>
                                    <h5 className={classes.subHeader} onClick={() => {
                                        if (!lodash.get(this.props, 'additionalConditionShopShow')) {
                                            dispatch({ type: 'OPEN_CONDITION_SHOP' })
                                        } else {
                                            dispatch({ type: 'CLOSE_CONDITION_SHOP' })
                                        }
                                    }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <b style={{ paddingRight: "6px" }}>Participate Shop in Promotion</b>
                                        <span
                                        style={{
                                            fontSize: '17px',
                                            position: "absolute",
                                            transition: "all 150ms",
                                            transform: (!lodash.get(this.props, 'additionalConditionShopShow'))?"rotate(0deg)":"rotate(180deg)"
                                        }}> ▾</span>
                                    </h5>
                                    <div style={
                                        lodash.get(this.props,'additionalConditionShopShow') === true ? { display:'block'}:{display:'none'}
                                    }>
                                        <AdditionalShop formName={FORM_NAME} readOnly={readOnly} />
                                    </div>
                                   
                                </div>
                                <div style={{ marginLeft: "10px" }}>
                                    <h5 className={classes.subHeader} onClick={() => {
                                        if (!lodash.get(this.props, 'additionalConditionTimeShow')) {
                                            dispatch({ type: 'OPEN_CONDITION_TIME' })
                                        } else {
                                            dispatch({ type: 'CLOSE_CONDITION_TIME' })
                                        }
                                    }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <b style={{ paddingRight: "6px" }}>Participate Day And Time in Promotion</b>
                                        <span
                                        style={{
                                            fontSize: '17px',
                                            position: "absolute",
                                            transition: "all 150ms",
                                            transform: (!lodash.get(this.props, 'additionalConditionTimeShow'))?"rotate(0deg)":"rotate(180deg)"
                                        }}> ▾</span>
                                    </h5>
                                    {lodash.get(this.props, 'additionalConditionTimeShow') === true ?
                                        <ConditionDateTime initialValues={formInitialValue} />
                                        : null}
                                </div>
                            </GridItem>
                        </GridContainer>

                        <GridContainer className={classes.formFooter}>
                            <GridItem xs={12}>
                                {(plugins.isHasPermission(moduleName, 'update') || plugins.isHasPermission(moduleName, 'create')) &&
                                    <Button className={classes.btnSubmit} color="primary" type="submit">Submit</Button>
                                }
                                <Button color="white" type="button" onClick={() => {
                                    // history.push('/promotion')
                                    dispatch({ type: 'CLOSE_FORM_PROMOTION' })
                                    dispatch({ type: 'CLOSE_CONDITION_SHOP' })
                                    dispatch({ type: 'CLOSE_CONDITION_TIME' })
                                }}>Cancel</Button>
                            </GridItem>
                        </GridContainer>

                    </form>
                </CardBody>
            </Card>
        )
    }
}


function mapStateToProps(state) {
    const {
        formOpen,
        formMode,
        purchaseAmountPerbillOpen,
        purchaseProductConditionOpen,
        paymentChannelConditionOpen,
        productConditionIndex,
        promoCodeConditionOpen,
        rewardModalOpen,
        allShopListSelected,
        formInitialValue,
        additionalConditionShopShow,
        additionalConditionTimeShow,
    } = state.promotion;
    const { categoryList } = state.list
    const formValues = getFormValues(FORM_NAME)(state)
    const formSyncErrors = getFormSyncErrors(FORM_NAME)(state)

    return {
        formOpen,
        formMode,
        formValues,
        categoryList,
        purchaseAmountPerbillOpen,
        purchaseProductConditionOpen,
        paymentChannelConditionOpen,
        productConditionIndex,
        promoCodeConditionOpen,
        rewardModalOpen,
        allShopListSelected,
        formInitialValue,
        formSyncErrors,
        additionalConditionShopShow,
        additionalConditionTimeShow,
    };
}

const connectedForm = connect(mapStateToProps)(PromotionForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(style)(connectedForm))

export const pointAdjustmentConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_PAGE_STATE',
    OPEN_ADD_POINT_ADJUSTMENT: 'OPEN_ADD_POINT_ADJUSTMENT',
    CLOSE_FORM_POINT_ADJUSTMENT: 'CLOSE_FORM_POINT_ADJUSTMENT',
    ADD_POINT_ADJUSTMENT_SUCCESS: 'ADD_POINT_ADJUSTMENT_SUCCESS',

    GET_USER_BY_PHONE_NUMBER: 'GET_USER_BY_PHONE_NUMBER',
    GET_LIST_TABLE_POINT_ADJUSTMENT: 'GET_LIST_TABLE_POINT_ADJUSTMENT',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
    REJECT_APPROVE_POINT_ADJUSTMENT_SUCCESS: 'REJECT_APPROVE_POINT_ADJUSTMENT_SUCCESS',
    SHOW_LOADER_POINT_ADJUSTMENT: 'SHOW_LOADER_POINT_ADJUSTMENT',
    HIDE_LOADER_POINT_ADJUSTMENT: 'HIDE_LOADER_POINT_ADJUSTMENT',

    OPEN_APPROVAL_POPUP: 'OPEN_APPROVAL_POPUP',
    OPEN_REJECTION_POPUP: 'OPEN_REJECTION_POPUP',
    CLOSE_APPROVAL_POPUP: 'CLOSE_APPROVAL_POPUP',
    CLOSE_REJECTION_POPUP: 'CLOSE_REJECTION_POPUP',
    OPEN_APPROVAL_SUCCESS_POPUP: 'OPEN_APPROVAL_SUCCESS_POPUP',
    CLOSE_APPROVAL_SUCCESS_POPUP: 'CLOSE_APPROVAL_SUCCESS_POPUP',
    OPEN_CONFIRMATION_CREATE_ADJUSTMENT_POPUP: 'OPEN_CONFIRMATION_CREATE_ADJUSTMENT_POPUP',
    CLOSE_CONFIRMATION_CREATE_ADJUSTMENT_POPUP: 'CLOSE_CONFIRMATION_CREATE_ADJUSTMENT_POPUP',

    CLEAR_POINT_ADJUSTMENT_FILTER: 'CLEAR_POINT_ADJUSTMENT_FILTER',
    POINT_ADJUSTMENT_TABLE_RESET: 'POINT_ADJUSTMENT_REPORT_TABLE_RESET',
    SET_FILTER_SEARCH: 'SET_FILTER_SEARCH',
};
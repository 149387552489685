import React from "react";
import { connect } from 'react-redux';
import { change, Field, getFormValues } from 'redux-form';
import _ from 'lodash';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { promotionConstants, swalConstants } from "../../../constants"
import red from '@material-ui/core/colors/red';
import { plugins, crmValidator, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import * as FormValidator from "../validate/FormValidator"

const moduleName = 'promotion'

const style = {}
const THEME = createMuiTheme(style);

const onInsertQuota = (keyword) => {
    let regex
    if(parseInt(keyword) < 10){
        regex = new RegExp(/[-.0]/g);
    }else{
        regex = new RegExp(/[-.]/g);
    }

    const chars = keyword.split('');
    const char = chars.pop();
    if (regex.test(char)) {
        keyword = chars.join(''); 
    }
    return keyword;
}

const QuotaPromotion = ({ ...props }) => {
    const { formMode, formValues, dispatch } = props
    const { FORM_NAME } = props

    let { readOnly } = props

    if (formMode === `add`) {
        readOnly = false
    } else if (formMode === `edit` && !readOnly) {
        readOnly = false
    } else {
        readOnly = true
    }

    

    return (
        <>
            <h4><b>Quota Per Promotion</b></h4>
            <GridContainer>
                <GridItem xs={4}>
                    <Field
                        id="quota-promotion-promotion"
                        name="quota[promotionPerPromotion]"
                        component={CustomInput}
                        type="number"
                        min="1"
                        labelText={"Quota per promotion"}
                        formControlProps={{
                            fullWidth: true
                        }}
                        validate={FormValidator.validateIsZero}
                        normalize={(value) => {
                            return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber);
                        }}
                        disabled={readOnly}
                    />
                </GridItem>
                <GridItem xs={2}></GridItem>
                <GridItem xs={4}>
                    <Field
                        id="quota-promotion-purchase"
                        name="quota[promotionPerPurchase]"
                        component={CustomInput}
                        type="number"
                        min="1"
                        labelText={"Quota per purchase"}
                        formControlProps={{
                            fullWidth: true
                        }}
                        validate={[FormValidator.validateIsZero, FormValidator.validateQuota]}
                        normalize={(value) => {
                            return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber);
                        }}
                        
                        disabled={readOnly}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={4}>
                    <Field
                        id="quota-promotion-type"
                        name="quotaPromotionType"
                        component={CustomSelect}
                        type="text"
                        labelText={i18n.t("label.day_or_month")}
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                        optionsList={[
                            {
                                value: "pleaseSelect",
                                label: "Please Select",
                            },
                            {
                                value: "quotaPerDay",
                                label: "Quota per day",
                            },{
                                value: "quotaPerMonth",
                                label: "Quota per month",
                            }
                        ]}
                    />
                </GridItem>
            </GridContainer>
                {(formValues.quotaPromotionType === "quotaPerMonth") ?
                <GridContainer>
                    <GridItem xs={12} sm={6} md={3}>
                        <Field
                            id="quota-promotion-month"
                            name="quota[promotionPerMonth]"
                            component={CustomInput}
                            type="number"
                            labelText={i18n.t("label.quota")}
                            formControlProps={{
                                fullWidth: true
                            }}
                            min="1"
                            validate={[FormValidator.validateIsZero, FormValidator.validateMaxPerMonth, FormValidator.validateRequiredMonth]}
                            normalize={(value) => {
                                return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber)
                            }}
                            disabled={readOnly || _.isEmpty(formValues.quotaPromotionType) ? true : false}
                        />
                    </GridItem>
                </GridContainer>
                : null}
            
            {(formValues.quotaPromotionType === "quotaPerDay") ?
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <GridContainer>
                            <GridItem xs={4} sm={4} md={4}>
                                <Field
                                    id="quota-promotion-monday"
                                    name="quota[promotionPerDayofWeek][monday]"
                                    component={CustomInput}
                                    labelText={i18n.t("label.monday")}
                                    type="number"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[FormValidator.validateIsZero, FormValidator.validateMaxPerDay, FormValidator.validateRequiredDay]}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber)
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <Field
                                    id="quota-promotion-tuesday"
                                    name="quota[promotionPerDayofWeek][tuesday]"
                                    component={CustomInput}
                                    labelText={i18n.t("label.tuesday")}
                                    type="number"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[FormValidator.validateIsZero, FormValidator.validateMaxPerDay, FormValidator.validateRequiredDay]}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber)
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <Field
                                    id="quota-promotion-wednesday"
                                    name="quota[promotionPerDayofWeek][wednesday]"
                                    component={CustomInput}
                                    labelText={i18n.t("label.wednesday")}
                                    type="number"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[FormValidator.validateIsZero, FormValidator.validateMaxPerDay, FormValidator.validateRequiredDay]}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber)
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <Field
                                    id="quota-promotion-thursday"
                                    name="quota[promotionPerDayofWeek][thursday]"
                                    component={CustomInput}
                                    labelText={i18n.t("label.thursday")}
                                    type="number"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[FormValidator.validateIsZero, FormValidator.validateMaxPerDay, FormValidator.validateRequiredDay]}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber)
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <Field
                                    id="quota-promotion-friday"
                                    name="quota[promotionPerDayofWeek][friday]"
                                    component={CustomInput}
                                    labelText={i18n.t("label.friday")}
                                    type="number"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[FormValidator.validateIsZero, FormValidator.validateMaxPerDay, FormValidator.validateRequiredDay]}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber)
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <Field
                                    id="quota-promotion-saturday"
                                    name="quota[promotionPerDayofWeek][saturday]"
                                    component={CustomInput}
                                    labelText={i18n.t("label.saturday")}
                                    type="number"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[FormValidator.validateIsZero, FormValidator.validateMaxPerDay, FormValidator.validateRequiredDay]}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber)
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <Field
                                    id="quota-promotion-sunday"
                                    name="quota[promotionPerDayofWeek][sunday]"
                                    component={CustomInput}
                                    labelText={i18n.t("label.sunday")}
                                    type="number"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[FormValidator.validateIsZero, FormValidator.validateMaxPerDay, FormValidator.validateRequiredDay]}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, FormValidator.form_validate.quotaNumber)
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>

                        </GridContainer>
                    </GridItem>
                </GridContainer>
                : null}
        </>
    )
}

function mapStateToProps(state) {
    const { formOpen, formMode } = state.promotion;
    const formValues = getFormValues("promotion_form")(state)
    return {
        formOpen, formMode, formValues
    };
}
const connectedForm = connect(mapStateToProps)(QuotaPromotion);
export default withStyles(style)(connectedForm)
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import lodash from 'lodash'
import { crmValidator, plugins } from 'helpers';

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputUpload from 'components/CustomUpload/InputUpload.jsx';
import CustomSelect from "components/CustomSelect/CustomSelect";
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { whitelistAction } from 'actions';
import { whitelistConstants } from '../../constants'

const FORM_NAME = 'FORM_UPLOAD_WHITELIST'
const form_validate = {
    "campaignId": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "type": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "file": {
        rules: {
            required: true,
            fileSize: 2097152
        },
        messages: {
            required: 'Please select file',
            fileSize: 'Selected File size must be less than 2MB'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class WhitelistUploadForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            campaignId:'',
            type:'',
        }
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props
        //console.log(values,this.props)
        var reqParams = {
            "campaignId": values.campaignId,
            "type": values.type,
            "file": values.file
        }
        dispatch(whitelistAction.uploadWhitelist(reqParams));
    }

    // downloadTemplate() {
    //     const { FORM_IMPORT_FILE } = this.props
    //     let templateType = lodash.get(FORM_IMPORT_FILE, 'values.importType')

    //     let fileName = `Template_${templateType}.csv`
    //     if (templateType === 'purchase_transaction') {
    //         this.genTemplatePurchaseTransaction(fileName)
    //     }else if(templateType === 'create_product'){
    //         this.genTemplateProduct(fileName)
    //     }
    // }

    closeForm() {
        const { dispatch } = this.props;
        dispatch({ type: whitelistConstants.CLOSE_FORM_UPLOAD_WHITELIST });
    }

    render() {
        const { handleSubmit, formImportOpen, classes, whitelistContainer } = this.props

        let campaignAllList = [];

        if (lodash.has(whitelistContainer, 'campaignList')) {
            lodash.forEach(whitelistContainer.campaignList, function (value, key) {
                
                campaignAllList.push({
                    value: value._id,
                    label: value.name,
                    id: value._id,
                })

            })
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formImportOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{ position: "relative" }}
                >
                    <h4 className={classes.modalTitle}><b>Import File</b></h4>
                    <IconButton aria-label="close" id="btn_close_form_import" className={classes.closeButton} onClick={this.closeForm.bind(this)} style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px"
                    }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Field
                                name="campaignId"
                                component={CustomSelect}
                                type="text"
                                labelText="Campaign"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={campaignAllList}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Field
                                name="type"
                                component={CustomSelect}
                                type="text"
                                labelText="Type"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    { value: "Email", label: "Email" },
                                    { value: "Mobile", label: "Mobile" },
                                    { value: "LineID", label: "Line ID" },
                                    { value: "FacebookID", label: "Facebook ID" }
                                ]}
                            />
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                            {formImportOpen &&
                            <GridItem xs={12} sm={6} md={6}>
                                <div style={{ marginTop: '5px' }}>
                                    <Field
                                        name="file"
                                        component={InputUpload}
                                        type="file"
                                        label="File Import"
                                        accept=".csv"
                                        id="btn_select_import_file"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </div>
                                <div style={{ fontSize: '12px', marginTop: "10px" }}>Maximum 500 rows</div>
                            </GridItem>
                            }
                    </GridContainer>
                </DialogContent >
                <DialogActions
                    className={classes.modalFooter}>
                        <Button 
                            style={{ marginRight: "10px" }}
                            type="submit" 
                            color="primary" 
                            id="btn_submit_import"
                        >
                            Submit
                        </Button>

                        <Button color="white" onClick={this.closeForm.bind(this)}>
                            Cancel
                        </Button>
                </DialogActions>
                </form>
            </Dialog >
        )
    }
}


function mapStateToProps(state) {
    const { formImportOpen, whitelistContainer } = state.whitelist
    const { FORM_UPLOAD_WHITELIST } = state.form
    return {
        formImportOpen, whitelistContainer, FORM_UPLOAD_WHITELIST
    };
}

const connectedComponent = connect(mapStateToProps)(WhitelistUploadForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {

    }
})(withStyles({modalStyle})(connectedComponent))
import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import { Field,reduxForm , reset } from 'redux-form';
import lodash from 'lodash';
import moment from 'moment'
import i18n from 'i18n';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import defaultImage from "assets/img/image_placeholder.jpg";

import BannerForm from "views/Banner/BannerForm.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";



import { listActions } from 'actions';
import { bannerConstants, swalConstants } from '../../constants';
import { bannerActions } from 'actions';
import { plugins, crmValidator } from 'helpers';

const FORM_NAME = 'BANNER_FILTER_FORM'
const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
   },
   actionDiv: {
      display: "inline-flex"
   }
};

const CustomMTablestyles = {
   ...MTablestyles,
   overrides: {
      ...MTablestyles.overrides,
      MUIDataTableHeadCell: {
         fixedHeader: {
            "&:nth-child(1)": {
               paddingRight: "0px"
            },
            "&:nth-child(2)": {
               paddingRight: "0px"
            },
            "&:nth-child(3)": {
               paddingRight: "0px"
            }
         }
      }
   }
}
const moduleName = 'banner'

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(CustomMTablestyles);

const renderBadgeStatus = (status, endDate) => {
   let BadgeColor
   if (endDate) {
      status = (moment(endDate).isBefore(moment()) ? "Expired" : status)
   }

   switch (status) {
      case "Active": BadgeColor = "success"; break;
      case "Expired": BadgeColor = "danger"; break;
      default: BadgeColor = "gray";
   }
   return (<Badge color={BadgeColor}>{status}</Badge>)
}
class BannerList extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         readOnly: true,
         isLoading: false,
         page: 0,
         rowsPerPage: 10,
         count: 100,
         data: [],
         reqParams: ""
      };

      this.delayedCallback = lodash.debounce(this.getData, 500)

   }

   getData = (skip, limit, sortBy, sortDir, keyword) => {
      const { dispatch, tableReload } = this.props

      let reqParams = "";

      if (!tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            skip = 0
         }

         if (crmValidator.isEmptyValue(limit)) {
            limit = 10
         }

         this.setState({
            page: skip,
            rowsPerPage: limit
         })
         skip = parseInt(limit) * parseInt(skip)

         reqParams += '&skip=' + skip
         reqParams += '&limit=' + limit

         if (!crmValidator.isEmptyValue(sortBy)) {
            reqParams += '&sortBy=' + sortBy
         }

         if (!crmValidator.isEmptyValue(sortDir)) {
            reqParams += '&sortDir=' + sortDir
         }

         if (!crmValidator.isEmptyValue(keyword)) {
            reqParams += '&keyword=' + encodeURIComponent(keyword)
         }
      } else {
         reqParams = this.state.reqParams
      }

      dispatch(bannerActions.getTableDatas(reqParams));

      this.setState({
         isLoading: true,
         reqParams: reqParams
      });

   }

   getRowData(rowIndex = 0) {
      const { tableDatas } = this.props;
      const data = tableDatas.banners;
      return data[rowIndex];
   }

   componentWillMount() {
      const { dispatch } = this.props;
      dispatch({ type: bannerConstants.CLEAR_PAGE_STATE });
   }

   componentDidUpdate() {
      const { tableReload, tableReset, dispatch } = this.props;
      if (tableReload || tableReset) {
         this.getData();
         dispatch({ type: bannerConstants.TABLE_RELOAD_SUCCESS })
      }
   }

   componentDidMount() {
      const { dispatch } = this.props;
      dispatch(listActions.getCategoryList('banner'))
      this.getData();
   }

   openFormAdd = () => {
      const { dispatch } = this.props;

      dispatch({
         type: bannerConstants.OPEN_FORM_ADD, formInitialValue: {
            status: "Active",
            type: "image"
         }
      })
   }
   handleCategorySubmit(values) {
      const { reqParams} = this.state
      const { dispatch } = this.props
      const { category } = values
      this.setState({
         categorySelected:category
      })

      let _reqParams = reqParams.split('&category[]=')['0']
      if(!crmValidator.isEmptyValue(category)){
         for(let item of category){
            _reqParams = `${_reqParams}&category[]=${item}`
         }
      }
      dispatch(bannerActions.getTableDatas(_reqParams));
    }

   openFormEdit(index) {
      const { dispatch, tableDatas } = this.props;
      const rowData = tableDatas.banners[index]

      let formInitData = {
         _id: rowData._id,
         displayId: rowData.displayId,
         name: rowData.name,
         title: rowData.title,
         startDate: rowData.startDate,
         endDate: rowData.endDate,
         priority: rowData.priority,
         status: rowData.status,
         ...rowData
      }

      dispatch({ type: bannerConstants.OPEN_FORM_EDIT, data: formInitData, bannerImagePreview: rowData.filePath });
   }

   clearFilter = () =>{
      const { dispatch } = this.props
      dispatch(reset(FORM_NAME))
      this.setState({
         categorySelected:[]
      })
    }
    categoryIdConvertToName(_id){
      const { categoryList } = this.props
      let categoryObj = lodash.find(categoryList,{"_id":_id})
      let categoryName = lodash.get(categoryObj,'name',_id)
      return categoryObj ? categoryName : ""
   }




   render() {
      const { classes, dispatch ,handleSubmit ,categoryList, formInitialValue, tableDatas, tableKey, showLoader } = this.props;
      const { rowsPerPage, page } = this.state;

      let columns = [
         { name: "_id", options: { filter: false, display: "excluded" } },
         { name: "displayId", options: { filter: false, display: "excluded" } },
         {
            label: "No.",
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta, updateValue) => {

                  const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                  const rowIndex = rowSatrt + tableMeta.rowIndex;

                  return rowIndex + 1;
               }
            }
         },
         {
            name: "bannerId", label: "Banner ID", options: {
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = this.getRowData(tableMeta.rowIndex)
                  return rowData.displayId
               }
            }
         },
         {
            name: "priority", label: "Priority", options: {
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = this.getRowData(tableMeta.rowIndex)
                  return rowData.priority || rowData.priority === 0 ? rowData.priority : ""
               }
            }
         },
         { name: "name", label: "Banner Name" },
         {
            name: "image", label: "Banner Pic", options: {
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = this.getRowData(tableMeta.rowIndex)
                  return (
                     <div className="banner-img-thumbnail-container">
                        <img src={rowData.thumbnailFilePath ? rowData.thumbnailFilePath : defaultImage} alt="banner" onError={(e) => { e.target.onError = null; e.target.src = defaultImage }} />
                     </div>
                  )
               }
            }
         },
         {
            name: "type", label: "Type", options: {
               customBodyRender: (value) => {
                  if (value) {
                     return value.replace(/^\w/, c => c.toUpperCase());
                  } else {
                     return null
                  }
               }
            }
         },
         {
            name: "categoryId", label: "Category", options: {
               filter: false,
               customBodyRender: (value, tableMeta, updateValue) => {
                  const renderCategory = (category,index)=>{
                     let categoryName = this.categoryIdConvertToName(category)
                     return ( <div key={index} className="text-nowrap">{categoryName}</div>)
                  }
                  const isArrayValue = lodash.isArray(value)
                  return (
                        <>
                        { isArrayValue && (<div>{value.map(renderCategory)}</div>) }
                        {!isArrayValue && <span>{value}</span>}
                        </>
                  )
               } 
            }
         },
         {
            name: "startDate", label: "Start Date", options: {
               customBodyRender: (value, tableMeta) => {
                  const rowData = this.getRowData(tableMeta.rowIndex)
                  return (
                     rowData.startDate ? <span className="text-nowrap">{plugins.datetimeFormat(rowData.startDate, "datetime")}</span> : ""
                  )
               }
            }
         },
         {
            name: "endDate", label: "End Date", options: {
               customBodyRender: (value, tableMeta) => {
                  const rowData = this.getRowData(tableMeta.rowIndex)
                  return (
                     rowData.endDate ? <span className="text-nowrap">{plugins.datetimeFormat(rowData.endDate, "datetime")}</span> : ""
                  )
               }
            }
         },
         {
            name: "status", label: "Status", options: {
               customBodyRender: (value, tableMeta) => {
                  const rowData = this.getRowData(tableMeta.rowIndex)
                  const { endDate } = rowData
                  return renderBadgeStatus(value, endDate)
               }
            }
         },
      ];

      if (plugins.isHasPermission(moduleName, 'read')) {
         columns.push({
            label: "Action", options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                     <div className={classes.actionDiv}>
                        {
                           plugins.isHasPermission(moduleName, 'update') &&
                           <TableActionButton
                              actionType="edit"
                              onClick={(e) => {
                                 this.openFormEdit(tableMeta.rowIndex)
                              }}
                           />
                        }
                        {plugins.isHasPermission(moduleName, 'delete') &&
                           <TableActionButton
                              actionType="delete"
                              onClick={(e) => {
                                 dispatch({
                                    type: swalConstants.SHOW, data: {
                                       type: "warning",
                                       title: i18n.t("warning_messages.are_you_sure_delete_banner"),
                                       message: `${this.getRowData(tableMeta.rowIndex).name}`,
                                       onConfirm: function () {
                                          let reqParams = {
                                             _id: tableMeta.rowData[0]
                                          }
                                          dispatch({ type: swalConstants.HIDE })
                                          dispatch(bannerActions.deleteBanner(reqParams));
                                       },
                                       onCancel: function () {
                                          dispatch({ type: swalConstants.HIDE })
                                       },
                                       showCancel: true,
                                       confirmBtnText: i18n.t("delete"),
                                       cancelBtnText: i18n.t("cancel")
                                    }
                                 })
                              }} />
                        }
                     </div>
                  )
               }
            }
         })
      }

      let options = {
         serverSide: true,
         count: tableDatas.total,
         page: page,
         rowsPerPage: rowsPerPage,
         selectableRows: "none",
         print: false,
         download: false,
         filter: false,
         responsive: "scroll",
         rowsPerPageOptions: [5, 10, 20],
         onTableChange: (action, tableState) => {

            let skip, limit, sortBy, sortDir, keyword = ""

            if (tableState.page !== null) {
               skip = tableState.page
            }

            if (tableState.rowsPerPage !== null) {
               limit = tableState.rowsPerPage
            }

            if (tableState.activeColumn !== null) {
               sortBy = tableState.columns[tableState.activeColumn].name
            }
            if (tableState.announceText !== null) {
               sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
            }
            if (tableState.searchText !== null || action === 'search') {
               keyword = tableState.searchText
               this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
            }
            let actionList = ['changePage', 'sort', 'changeRowsPerPage']
            if (actionList.indexOf(action) !== -1) {
               this.getData(skip, limit, sortBy, sortDir, keyword)
            }
         },
      };

      if (plugins.isHasPermission(moduleName, 'create')) {
         options.customToolbar = () => {
            return (
               <CustomToolbar handleClick={this.openFormAdd} title="Add New" />
            );
         }
      }
    
      return (
         <div id="tableBannerList">
            <CustomSweetAlert />
            <BannerForm
               initialValues={formInitialValue}
               readOnly={!plugins.isHasPermission(moduleName, 'update')}
            />
             <div style={{ padding : '30px'}}>
               <form
                  name={FORM_NAME}
                  onSubmit={handleSubmit(this.handleCategorySubmit.bind(this))}
               >
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={6} lg={6}>
                     <Field
                        id={`${FORM_NAME}_category`}
                        name="category"
                        component={TagsMultiSelect}
                        labelText={'Category'}
                        formControlProps={{
                           fullWidth: true,
                        }}
                        showtick
                        optionsList={categoryList}
                     />
                     </GridItem>
                     <GridItem xs={12} sm={6} md={6} lg={6}>
                     <Button
                        id={`${FORM_NAME}-button-clear`}
                        onClick={this.clearFilter}
                        style={{
                           marginTop: "25px",
                           marginRight: "10px",
                        }}
                        size="sm"
                        color="white"
                     >
                        Clear
                     </Button>
                     <Button
                        id={`${FORM_NAME}-button-submit`}
                        type="submit"
                        style={{ marginRight: "10px", marginTop: "25px" }}
                        size="sm"
                        color="primary"
                     >
                        Submit
                     </Button>
                     </GridItem>
                  </GridContainer>
               </form>
            </div>

            <MuiThemeProvider theme={THEME}>

               <MUIDataTable
                  key={tableKey}
                  title={"Banner list"}
                  data={tableDatas.banners}
                  columns={columns}
                  options={options}
               />
               {showLoader && <TableLoaderSpinner />}
            </MuiThemeProvider>
         </div>
      )
   }
}

function mapStateToProps(state) {
   const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, showLoader } = state.banner;
   // const { permission, superAdmin } = state.authentication;
   const { categoryList } = state.list
   return {
      formInitialValue, tableReload, tableReset, tableDatas, tableKey, showLoader,categoryList
   };
}

const connectedComponent =   connect(mapStateToProps)(withStyles(pageStyle)(BannerList));

export default reduxForm({
   form: FORM_NAME,
 })(withStyles(style)(connectedComponent))

// export default connect(mapStateToProps)(withStyles(pageStyle)(BannerList));

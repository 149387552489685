import { saleProductConstants } from '../constants';
import { saleProductServices } from '../services';
import { loader, alert as customAlert } from '../helpers';

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const saleProductActions = {
    getListsSaleProduct,
    deleteSaleProduct,
    updateSaleProduct,
    addSaleProduct,
    getSaleProductDetail
}

function getListsSaleProduct(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductServices.getListsSaleProduct(params)
            .then(
                response => {
                    if (response.status) {
                        response.data.product_sales = response.data.product_sales.map((item, index) => {
                            if (!item.product || item.product.length <= 0) return item
                            const defualtIndex = item.product.findIndex((product) => product.isDefault === true)
                            if (defualtIndex >= 0) {
                                return {
                                    ...item,
                                    price: item.product[defualtIndex].price || null,
                                    sale_price: item.product[defualtIndex].salesPrice || null,
                                    weight: item.product[defualtIndex].weightKg || null,
                                }
                            }
                            else {
                                return {
                                    ...item,
                                    price: item.product[0].price || null,
                                    sale_price: item.product[0].salesPrice || null,
                                    weight: item.product[0].weightKg || null,
                                }
                            }
                        })
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: saleProductConstants.TABLE_GET_LIST_SALEPRODUCT, tmp } }
}

function addSaleProduct(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductServices.addSaleProduct(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: saleProductConstants.ADD_SALEPRODUCT_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.sale_product_was_created'));
                        } else {
                            if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function deleteSaleProduct(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductServices.deleteSaleProduct(params)
            .then(
                tmp => {
                    if (tmp.status) {
                        dispatch({ type: saleProductConstants.DELETE_SALEPRODUCT_SUCCESS });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.sale_product_was_deleted'));
                    } else {
                        if (!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function updateSaleProduct(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductServices.updateSaleProduct(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({ type: saleProductConstants.EDIT_SALEPRODUCT_SUCCESS });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.sale_product_was_updated'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getSaleProductDetail(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductServices.getSaleProductDetail(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) {
        return { type: saleProductConstants.OPEN_FORM_EDIT_SALEPRODUCT, tmp }
    }
}


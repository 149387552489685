import React from "react";
import { Field, reduxForm, reset, change } from "redux-form";
import { connect } from "react-redux";
import lodash from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import TableCollectReport from "./TableCollectReport.jsx";

import { luckyDrawReportActions, campaignActions } from "actions";
import { crmValidator } from "helpers";
import { plugins } from 'helpers';

const style = {};
const form_validate = {
    phoneNumber: {
        rules: {
            number: true
        },
        messages: {
            required: "กรุณากรอก"
        }
    }
};

const FORM_NAME = "FORM_FILTER_COLLECT_TRANSACTION";

class CollectTransactionReport extends React.Component {
    handleSubmit(values) {
        const { dispatch, collectReport } = this.props;
        const { tableFilter } = collectReport
        let _tableFilter = {
            page: tableFilter.page,
            skip: tableFilter.skip,
            limit: tableFilter.limit,
            sortBy: tableFilter.sortBy,
            sortDir: tableFilter.sortDir,
            keyword: "",
            search: {}
        }
        let search = { ...values };
        if(lodash.get(values, 'campaignId')==='all'){
            delete search.campaignId;
        }
        if(lodash.get(values, 'channel')==='all'){
            delete search.channel;
        }
        if(lodash.get(values, 'productId')==='all'){
            delete search.productId;
        }
        if(lodash.get(values, 'status')==='all'){
            delete search.status;
        }
        if (lodash.size(search) > 0) {
            _tableFilter.search = search;
        }
        const resetTable = true
        dispatch(luckyDrawReportActions.getCollectTransactionList(_tableFilter, resetTable));
    }

    componentDidMount() {
        const { dispatch, collectReport } = this.props;
        const { tableFilter } = collectReport;
        this.getProductList();
        this.getCampaignList()
        dispatch(luckyDrawReportActions.getCollectTransactionList(tableFilter));
        dispatch(luckyDrawReportActions.getLuckyDrawRewardSetList());
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(reset(FORM_NAME))
        dispatch({ type: "CLEAR_COLLECT_FILTER" })
    }

    getProductList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active";
        dispatch(luckyDrawReportActions.getProductList(reqParams));
    }

    getCampaignList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"

        dispatch(luckyDrawReportActions.getCampaignList(reqParams))
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch(reset(FORM_NAME))
        dispatch(change(FORM_NAME, "startDate", ""));
        dispatch(change(FORM_NAME, "endDate", ""));
        dispatch({ type: "CLEAR_COLLECT_FILTER" })
    };

    render() {
        const {
            FORM_FILTER_COLLECT_TRANSACTION,
            // merchantSetting,
            handleSubmit,
            collectReport,
            package_features,
            luckyDrawRewardSet,
        } = this.props;
        let startMaxDate = new Date();
        // let filterAgeOptions = merchantSetting.ageRangeList;

        if (lodash.has(FORM_FILTER_COLLECT_TRANSACTION, "values.endDate")) {
            startMaxDate = FORM_FILTER_COLLECT_TRANSACTION.values.endDate;
        }

        let endMinDate;
        if (lodash.has(FORM_FILTER_COLLECT_TRANSACTION, "values.startDate")) {
            endMinDate = FORM_FILTER_COLLECT_TRANSACTION.values.startDate;
        }

        let productList = [];
        productList.push({
            value: "all",
            label: "All"
        });
        if (lodash.has(collectReport, "productList")) {
            lodash.forEach(collectReport.productList, function (value, key) {
                productList.push({
                    value: value._id,
                    label: value.name
                });
            });
        }
        let campaignList = [];
        campaignList.push({
            value: "all",
            label: "All"
        })
        if (lodash.has(collectReport, 'campaignList')) {
            lodash.forEach(collectReport.campaignList, function (value, key) {
                campaignList.push({
                    value: value._id,
                    label: value.name
                })
            })
        }
        let collectTypesList = [];
        collectTypesList.push({
            value: "all",
            label: "All"
        },{
            value: "code",
            label: "Code"
        },{
            value: "campaign",
            label: "Campaign"
        })
        let setLuckyDrawValue = [];
        lodash.forEach(luckyDrawRewardSet, function (value, key) {
            if (!value.useInCampaignId) {
            let sel_value = value._id;
            let sel_label = value.name_th;
                setLuckyDrawValue.push({
                value: sel_value,
                label: sel_label,
                });
            }
        });
        return (
            <div>

                <form
                    name={FORM_NAME}
                    onSubmit={handleSubmit(this.handleSubmit.bind(this))}
                >
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="startDate"
                                component={CustomDatePicker}
                                type="text"
                                maxDate={startMaxDate}
                                labelText="Start Date"
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="endDate"
                                minDate={endMinDate}
                                maxDate={new Date()}
                                component={CustomDatePicker}
                                type="text"
                                labelText="End Date"
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="firstName"
                                component={CustomInput}
                                type="text"
                                labelText="Firstname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="lastName"
                                component={CustomInput}
                                type="text"
                                labelText="Lastname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="phoneNumber"
                                component={CustomInput}
                                type="text"
                                labelText="Mobile No."
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={value => {
                                    return crmValidator.handleKeyPress(
                                        value,
                                        form_validate.phoneNumber
                                    );
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="luckyDrawRewardId"
                                component={CustomSelect}
                                type="text"
                                labelText="Reward"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                novalidate
                                optionsList={setLuckyDrawValue}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="channel"
                                component={CustomSelect}
                                type="text"
                                labelText="Collect Type"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={collectTypesList}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="campaignId"
                                component={CustomSelect}
                                type="text"
                                labelText="Campaign"
                                novalidate
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={campaignList}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="productId"
                                component={CustomSelect}
                                type="text"
                                labelText="Product"
                                novalidate
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={productList}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="status"
                                component={CustomSelect}
                                type="text"
                                labelText="Status"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    { value: "all", label: "All" },
                                    { value: "1", label: "Success" },
                                    { value: "0", label: "Failed" },
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={3} lg={3}>
                            <Button
                                id="button-clear"
                                onClick={this.clearFilter}
                                style={{ marginRight: "10px", marginTop: "25px" }}
                                size="sm"
                                color="white"
                            >
                                Clear
                            </Button>
                            <Button
                                type="submit"
                                id="button-submit"
                                style={{ marginTop: "25px" }}
                                size="sm"
                                color="primary"
                            >
                                Submit
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
                <TableCollectReport />

            </div>
        );
    }
}

function mapStateToProps(state) {
    const { FORM_FILTER_COLLECT_TRANSACTION } = state.form;
    const { collectReport, luckyDrawRewardSet } = state.luckyDrawReport;
    const { merchantSetting, package_features } = state.authentication;

    return {
        FORM_FILTER_COLLECT_TRANSACTION,
        collectReport,
        merchantSetting,
        package_features,
        luckyDrawRewardSet,
    };
}

const connectedComponent = connect(mapStateToProps)(CollectTransactionReport);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent));

import { listConstants } from "../constants"
const initialState = {
  categoryList: [],
  listProductGroup: [],
  listBrand: [],
  formPromotionlist: [],
}

export function list(state = initialState, action) {
  switch (action.type) {
    case listConstants.INIT_LIST_CONSTANT: {
      return initialState
    }
    case listConstants.GET_CAMPAIGN_CATEGORY_LIST: {
      return {
        ...state,
        categoryList: action.data,
      }
    }
    case listConstants.GET_CAMPAIGN_PROMOTION_LIST: {
      return {
        ...state,
        formPromotionlist: action.data,
      }
    }
    case listConstants.GET_CAMPAIGN_PRODUCT_GROUP: {
      return {
        ...state,
        listProductGroup: action.data,
      }
    }
    case listConstants.GET_ฺBRAND: {
      return {
        ...state,
        listBrand: action.data,
      }
    }
    default:
      return state
  }
}

import { dataFilteringTemplateServices } from '../services';
import { dataFilteringTemplateConstants } from '../constants';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const dataFilteringTemplateAction = {
   getDataFilteringTemplateList,
   addNewDataFilteringTemplate,
   editDataFilteringTemplate,
   deleteDataFilteringTemplate,
   rerunDataFilteringTemplate,
   importMember,
   getTableConfig
};

function getDataFilteringTemplateList(params) {
   return dispatch => {
      dispatch({ type: dataFilteringTemplateConstants.SHOW_LOADER })
      const defaultData = {
         data: [],
         total: 0
      }
      dataFilteringTemplateServices.getDataFilteringTemplateList(params)
         .then(
            response => {
               try {
                  if (response.status) {
                     dispatch(success(response.data));
                  } else {
                     dispatch(success(defaultData));
                  }
               } catch (e) {
                  dispatch(success(defaultData));
               }
            },
            error => {
               dispatch(success(defaultData));
            }
         ).then(() => {
            dispatch({ type: dataFilteringTemplateConstants.HIDE_LOADER })
         })
   };

   function success(tmp) { return { type: dataFilteringTemplateConstants.GET_DATAFILTERRING_TEMPLATE_SUCCESS, tmp: tmp } }
}

function addNewDataFilteringTemplate(params) {
   return dispatch => {
      loader(dispatch, true)
      dataFilteringTemplateServices.addNewDataFilteringTemplate(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({
                     type: dataFilteringTemplateConstants.ADD_NEW_DATAFILTERRING_TEMPLATE_SUCCESS
                  });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.dataFilteringTemplate_was_created'));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function editDataFilteringTemplate(params) {
   return dispatch => {
      loader(dispatch, true)
      dataFilteringTemplateServices.editDataFilteringTemplate(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.dataFilteringTemplate_was_updated'));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: dataFilteringTemplateConstants.EDIT_DATAFILTERRING_TEMPLATE_SUCCESS, tmp } }
}

function deleteDataFilteringTemplate(params) {
   return dispatch => {
      loader(dispatch, true)
      dataFilteringTemplateServices.deleteDataFilteringTemplate(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({
                     type: dataFilteringTemplateConstants.DELETE_DATAFILTERRING_TEMPLATE_SUCCESS
                  });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.dataFilteringTemplate_was_deleted"))
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function rerunDataFilteringTemplate(params) {
   return dispatch => {
      loader(dispatch, true)
      dataFilteringTemplateServices.rerunDataFilteringTemplate(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({
                     type: dataFilteringTemplateConstants.RERUN_DATAFILTERRING_TEMPLATE_SUCCESS
                  });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.dataFilteringTemplate_was_rerun"))
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function importMember(params) {
   return dispatch => {
      loader(dispatch, true)
      dataFilteringTemplateServices.importMember(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({
                     type: dataFilteringTemplateConstants.SHOW_DATAFILTERRING_TEMPLATE_IMPORT_MEMBER_RESULT,
                     data: response.data
                  });
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   }
}

function getTableConfig(params) {
   return dispatch => {
      dataFilteringTemplateServices.getTableConfig(params)
         .then(
            response => {
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     dispatch(success(response.data));
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: dataFilteringTemplateConstants.GET_TEMPLATE_CONFIG_LIST_SUCCESS, data }
   }
}

export const memberConstants = {
   OPEN_FORM_ADD: 'OPEN_ADD_FORM_MEMBER',
   OPEN_FORM_EDIT: 'OPEN_EDIT_FORM_MEMBER',
   CLOSE_FORM_MEMBER: 'CLOSE_FORM_MEMBER',

   WARN_DELETE_MEMBER: 'WARN_DELETE_MEMBER',
   RELOAD_TABLE_MEMBERLIST: 'RELOAD_TABLE_MEMBERLIST',
   TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
   TABLE_RESET: 'TABLE_RESET',
   TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',

   ADD_NEW_MEMBER_SUCCESS: 'ADD_NEW_MEMBER_SUCCESS',
   ADD_NEW_MEMBER_FAILURE: 'ADD_NEW_MEMBER_FAILURE',

   EDIT_MEMBER_SUCCESS: 'EDIT_MEMBER_SUCCESS',
   EDIT_MEMBER_FAILURE: 'EDIT_MEMBER_FAILURE',

   DELETE_MEMBER_REQUEST: 'DELETE_MEMBER_REQUEST',
   DELETE_MEMBER_SUCCESS: 'DELETE_MEMBER_SUCCESS',
   DELETE_MEMBER_FAILURE: 'DELETE_MEMBER_FAILURE',

   GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
   GET_STATISTICS_FAILURE: 'GET_STATISTICS_FAILURE',

   HIDE_ALERT: 'HIDE_ALERT',
   
   MEMBER_LIST_CLEAR_STATE: 'MEMBER_LIST_CLEAR_STATE',

   CLEAR_PAGE_STATE: 'MEMBER_CLEAR_PAGE_STATE',
   TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_MEMBERS',
   
   DELETE_FORM_MEMBER_TAGLIST: 'DELETE_FORM_MEMBER_TAGLIST',
   GET_MEMBER_TAGLIST_SUCCESS: 'GET_MEMBER_TAGLIST_SUCCESS',
   GET_MEMBER_TAGLIST_REQUEST: 'GET_MEMBER_TAGLIST_REQUEST',

   GET_MEMBER_LEVEL_LIST_SUCCESS: 'GET_MEMBER_LEVEL_LIST_SUCCESS',
   GET_MEMBER_LEVEL_LIST_REQUEST: 'GET_MEMBER_LEVEL_LIST_REQUEST',

   SHOW_LOADER_MEMBER: 'SHOW_LOADER_MEMBER',
   HIDE_LOADER_MEMBER: 'HIDE_LOADER_MEMBER',


   OPEN_MEMBER_USER_AGENT_DETAIL:"OPEN_MEMBER_USER_AGENT_DETAIL",
   CLOSE_MEMBER_USER_AGENT_DETAIL:"CLOSE_MEMBER_USER_AGENT_DETAIL",
};

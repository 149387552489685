import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay'
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";

import routes from "routes.js";
import ErrorPage from "views/Pages/ErrorPage.jsx";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";

import register from "assets/img/register.jpeg";
import login from "assets/img/login.jpeg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import pricing from "assets/img/bg-pricing.jpeg";

import { LoginPage } from "views/Pages/LoginPage.jsx";
import { LoginBypass } from "views/Pages/LoginBypass.jsx";
import { SboBypass } from "views/Pages/SboBypass";

class Pages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }
  getRoutes = groutes => {
    return groutes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBgImage = () => {
    if (window.location.pathname.indexOf("/auth/register-page") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/auth/login-page") !== -1) {
      return login;
    } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
      return pricing;
    } else if (
      window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
    ) {
      return lock;
    } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
      return error;
    }
  };
  getActiveRoute = routes => {
    let activeRoute = "EGG-CRM";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  render() {
    const { classes, loader, ...rest } = this.props;
    return (
      <LoadingOverlay
        active={loader}
        spinner
        text='LOADING'
        styles={{
          overlay: (base) => ({
            ...base,
            'z-index': '9999'
          })
        }}
      >
        <div>
          <AuthNavbar brandText={this.getActiveRoute(routes)} {...rest} />
          
          <div className={classes.wrapper} ref="wrapper">
            <div
              className={classes.fullPage}
              style={{ backgroundImage: "url(" + this.getBgImage() + ")" }}
            >
              <Switch>
                <Route path="/auth/" exact component={LoginPage} />
                <Route path="/auth/login" component={LoginPage} />
                <Route path="/auth/login-bypass" component={LoginBypass} />
                <Route path="/auth/passlogin" component={SboBypass} />
                <Route path="*" component={ErrorPage} />
              </Switch>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { loader } = state.loader;
  return {
      loader
  };
}

const connectApp = connect(mapStateToProps)(withStyles(pagesStyle)(Pages));
export { connectApp as AuthLayout }

import { shopConstants } from "../constants"
import { loader, alert as customAlert } from "helpers"
import i18n from "i18n"
import { shopServices } from "services"

const defaultFailedText = "api_messages.SOMETHING_WENT_WRONG"

export const shopActions = {
  addShopList,
  getShopLists,
  updateShopList,
  getRegionOptionList,
  deleteShopList,
}

function getShopLists(params) {
  return (dispatch) => {
    dispatch({ type: shopConstants.SHOW_TABLE_LOADER })
    shopServices
      .getShopLists(params)
      .then(
        (response) => {
          if (response.status) {
            dispatch(success(response.data))
          } else {
            if (!response.unAlert)
              customAlert(
                dispatch,
                "error",
                i18n.t(`api_messages.${response.message.toString()}`)
              )
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        dispatch({ type: shopConstants.HIDE_TABLE_LOADER })
      })
  }

  function success(data) {
    return { type: shopConstants.TABLE_GET_LIST_DATA, data }
  }
}

function getRegionOptionList() {
  return (dispatch) => {
    shopServices.getRegionLists().then(
      (response) => {
        if (response.status) {
          dispatch(success(response.data))
        } else {
          if (!response.unAlert)
            customAlert(
              dispatch,
              "error",
              i18n.t(`api_messages.${response.message.toString()}`)
            )
        }
      },
      (error) => {
        customAlert(dispatch, "error", i18n.t(defaultFailedText))
      }
    )
  }

  function success(data) {
    return { type: shopConstants.GET_SHOP_REGION_LIST, data }
  }
}

function addShopList(params) {
  return (dispatch) => {
    loader(dispatch, true)
    shopServices
      .addShopList(params)
      .then(
        (response) => {
          try {
            if (response.status) {
              dispatch({
                type: shopConstants.ADD_NEW_SHOP_SUCCESS,
              })
              customAlert(
                dispatch,
                "success",
                i18n.t("warning_messages.shop_was_created")
              )
            } else {
              if (!response.unAlert)
                customAlert(
                  dispatch,
                  "error",
                  i18n.t(`api_messages.${response.message.toString()}`)
                )
            }
          } catch (e) {
            customAlert(dispatch, "error", i18n.t(defaultFailedText))
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false)
      })
  }
}

function updateShopList(params) {
  return (dispatch) => {
    loader(dispatch, true)
    shopServices
      .updateShopList(params)
      .then(
        (response) => {
          try {
            if (response.status) {
              dispatch({ type: shopConstants.EDIT_SHOP_SUCCESS })
              customAlert(
                dispatch,
                "success",
                i18n.t("warning_messages.shop_was_updated")
              )
            } else {
              if (!response.unAlert)
                customAlert(
                  dispatch,
                  "error",
                  i18n.t(`api_messages.${response.message.toString()}`)
                )
            }
          } catch (e) {
            customAlert(dispatch, "error", i18n.t(defaultFailedText))
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false)
      })
  }
}

function deleteShopList(params) {
  return (dispatch) => {
    loader(dispatch, true)
    shopServices
      .deleteShopList(params)
      .then(
        (response) => {
          try {
            if (response.status) {
              dispatch({ type: shopConstants.DELETE_SHOP_LIST_SUCCESS })
              customAlert(
                dispatch,
                "success",
                i18n.t("warning_messages.shop_was_deleted")
              )
            } else {
              if (!response.unAlert)
                customAlert(
                  dispatch,
                  "error",
                  i18n.t(`api_messages.${response.message.toString()}`)
                )
            }
          } catch (e) {
            customAlert(dispatch, "error", i18n.t(defaultFailedText))
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false)
      })
  }
}

import { missionConstants } from '../constants';
import { missionServices, uploadWinnerServices } from '../services';
import { loader, alert as customAlert } from '../helpers';
import lodash from 'lodash'
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const missionActions = {
    getListsMission,
    deleteMission,
    updateMission,
    addMission,
    getMissionDetail,
    getCampaignList,
    getLuckyDrawRewardSetList
}

function getListsMission(params) {
    return dispatch => {
        loader(dispatch, true)
        missionServices.getListsMission(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: missionConstants.TABLE_GET_LIST_MISSION, tmp }}
}

function addMission(params) {
    return dispatch => {
        loader(dispatch, true)
        missionServices.addMission(params)
            .then(
                response => { 
                    try {
                        if (response.status) {
                            dispatch({ type: missionConstants.ADD_MISSION_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.mission_was_created'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function deleteMission(params) {
    return dispatch => {
        loader(dispatch, true)
        missionServices.deleteMission(params)
           .then(
              tmp => {
                 if (tmp.status) {
                    dispatch({ type: missionConstants.DELETE_MISSION_SUCCESS });
                    customAlert(dispatch, 'success', i18n.t('warning_messages.mission_was_deleted'));
                 } else {
                    if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
                 }
              },
              error => {
                 customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
           ).then(() => {
              loader(dispatch, false)
           })
     };
}

function updateMission(params) {
    return dispatch => {
        loader(dispatch, true)
        missionServices.updateMission(params)
           .then(
              response => {
                 if (response.status) {
                    dispatch({ type: missionConstants.EDIT_MISSION_SUCCESS });
                    customAlert(dispatch, 'success', i18n.t('warning_messages.mission_was_updated'));
                 } else {
                    if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                 }
              },
              error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
           ).then(() => {
              loader(dispatch, false)
           })
     };
}

function getMissionDetail(params) {
    return dispatch => {
        loader(dispatch, true)
        missionServices.getMissionDetail(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) { return { type: missionConstants.OPEN_FORM_EDIT , tmp }}
}

function getCampaignList(params) {
    return dispatch => {
        missionServices.getCampaignsList(params)
        .then(
          response => {
            if (response.status) {
              if (lodash.has(response, "data.campaigns")) {
                dispatch(success(response.data.campaigns));
              }
            } else {
              if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
            }
          },
          error => {
            customAlert(dispatch, 'error', i18n.t(defaultFailedText))
          }
        )
    };
  
    function success(data) {
      return { type: missionConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data };
    }
}

function getLuckyDrawRewardSetList(){
    let params = { showOnCampignAndEarnRule: "yes" }
    let strParams = ''
    if (typeof params === 'object') {
        strParams = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    else {
        strParams = params
    }
    return dispatch => {
        loader(dispatch, true)
        uploadWinnerServices.getRewardList(strParams)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: missionConstants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS, data }}
}
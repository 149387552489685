export const luckydrawRewardConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_PAGE_STATE',

    OPEN_ADD: 'OPEN_ADD_LUCKYDRAW_REWARD',
    OPEN_EDIT: 'OPEN_EDIT_LUCKYDRAW_REWARD',
    CLOSE_FORM: 'CLOSE_FORM_LUCKYDRAW_REWARD',
    GET_LUCKYDRAW_REWARD_SUCCESS: 'GET_LUCKYDRAW_REWARD_SUCCESS' ,

    ADD_NEW_LUCKYDRAW_REWARD_SUCCESS: 'ADD_NEW_LUCKYDRAW_REWARD_SUCCESS',
    EDIT_LUCKYDRAW_REWARD_SUCCESS: 'EDIT_LUCKYDRAW_REWARD_SUCCESS',

    SHOW_LOADER: 'SHOW_TABLE_LUCKYDRAW_REWARD_LOADER_SPINNER',
    HIDE_LOADER: 'HIDE_TABLE_LUCKYDRAW_REWARD_LOADER_SPINNER',

    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    DELETE_LUCKYDRAW_REWARD_SUCCESS: 'DELETE_LUCKYDRAW_REWARD_SUCCESS',

    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
    TABLE_RESET: 'TABLE_RESET',
    SET_FORM_PREVIEW_IMAGE_REWARD: 'SET_FORM_PREVIEW_IMAGE_REWARD',
 };
 

import { treemapConstants } from '../constants';
const initialState = {
    formOpen: false,
    formMode: 'add',
    submitForm: false,
    formInitialValue: {},
    tableReload: false,
    tableReset: false,
    tableDatas: [],
    tableKey: 0,
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "id",
        sortDir: "asc",
        keyword: "",
        search: {}
    },
    showLoader: false,
    formVisualOpen: false,
    formCampaignOpen: false,
    visualization: [],
    campaignListUse: [],
    segment:{},
    campaignList: [],
    campaignListTotal:0
};
// treemap
export function treemap(state = initialState, action) {
    switch (action.type) {
        case treemapConstants.GET_TREEMAP_LIST_SUCCESS:

            let tempSeries = [];
            action.data.map((val)=>{
                tempSeries.push({
                    "x": val.name +' '+ val.value,
                    "y": val.value
                })
            })

            // console.log('action.data :',action.data)

            let treemapData = {
                options: {
                    plotOptions: {
                        treemap: {
                        distributed: true
                        }
                    }
                },
                chart: {
                  width: 600,
                  type: "treemap",
                },
                series: []
            }

            if(tempSeries.length>0){
                treemapData.series = [{
                    data:tempSeries
                }]
            }

            console.log('treemapData :',treemapData);

            return {
                ...state,
                treemapList: treemapData
            }
        case treemapConstants.CLEAR_PAGE_STATE:
            return initialState;
        case treemapConstants.OPEN_FORM_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue
            }
        case treemapConstants.CLOSE_FORM_TREEMAP:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                
                }
            }
        case treemapConstants.SET_FILTER:
            return {
                ...state,
                tableFilter: action.tableFilter
            }
        case treemapConstants.TABLE_GET_LIST_DATAS:
            return {
                ...state,
                ...state.treemap,
                tableFilter: action.tableFilter,
                tableDatas: (action.data ? action.data : []),
                tableTotal: (action.total ? action.total : 0)
            }
        case treemapConstants.TABLE_RELOAD_SUCCESS:
        case treemapConstants.RELOAD_TABLE_MEMBERLIST:
        case treemapConstants.TABLE_RESET:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case treemapConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReset: false
            }
        case treemapConstants.EDIT_TREEMAP_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
                submitForm: true
            }
        case treemapConstants.SHOW_LOADER_TREEMAP:
            return {
                ...state,
                showLoader: true
            }
        case treemapConstants.HIDE_LOADER_TREEMAP:
            return {
                ...state,
                showLoader: false
            }
        case treemapConstants.GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                treemapList: {
                    ...state.treemapList,
                    // shippingCompanyList: action.data
                }
            }
        case treemapConstants.TABLE_GET_LIST_DATAS_RESET:
            return {
                ...state,
                ...state.treemapList,
                // treemapList: {
                //     ...state.treemapList,
                //     tableKey: Date.now()
                // }
            }
        case treemapConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                treemapList: {
                    ...state.treemapList,
                    campaignList: action.data.campaigns
                }
            }
        case treemapConstants.GET_DROPDOWN_REFERENCE_LIST_SUCCESS:
            return {
                ...state,
                treemapList: {
                    ...state.treemapList,
                    referenceList: action.data.refs,
                },
            }
        case treemapConstants.GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS:
            return {
                ...state,
                treemapList: {
                    ...state.treemapList,
                    announceDateList: action.data.announceDates,
                },
            }
        case treemapConstants.GET_DROPDOWN_REWARD_LIST_SUCCESS:
            return {
                ...state,
                treemapList: {
                    ...state.treemapList,
                    rewardList: action.data.rewards,
                },
            }
        case treemapConstants.OPEN_FORM_VISUALIZATION:
            return {
                ...state,
                formVisualOpen: true,
                visualization: action.data.visualization,

            }
        case treemapConstants.CLOSE_FORM_VISUALIZATION:
            return {
                ...state,
                formVisualOpen: false,
            }
        case treemapConstants.OPEN_FORM_CAMPAIGNS:
            return {
                ...state,
                formCampaignOpen: true,
            
                campaignListUse: action.data[0] && action.data[0].campaign ? action.data[0].campaign : []
            }
        case treemapConstants.SET_SEGMENT_IN_OPEN_FORM_CAMPAIGNS:
            return {
                ...state,
                segment: action.data
            }
        case treemapConstants.CLOSE_FORM_CAMPAIGNS:
            return {
                ...state,
                formCampaignOpen: false,
            }
        //ADDED_CAMPAIGN_SUCCESS
        case treemapConstants.ADDED_CAMPAIGN_SUCCESS:
            return {
                ...state,
                formCampaignOpen: false,
            }
        case treemapConstants.GET_CAMPAIGN_LIST_SUCCESS:
            let campaign = [];
            action.data.campaigns.map((val)=>{
                campaign.push({
                    "campaignId": val._id,
                    "campaignName": val.name,
                    "status" : val.displayStatus
                })
            })
           
            return {
                ...state,
                campaignList: campaign, //action.data.campaigns,
                campaignListTotal: action.data.total
            }
        case treemapConstants.ADD_NEW_CAMPAIGN_USE:
            return {
                ...state,
                campaignListUse: action.data,
            }
        default:
            return state
    }
}
import React from "react";
import { Field, touch, change } from 'redux-form';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { crmValidator } from 'helpers';

import { campaign_v2Constants } from '../../../constants';

import i18n from 'i18n';
import lodash from 'lodash';

import * as FormValidator from '../validate/FormValidator';
const validate = (values) => {
   const errors = crmValidator.validateByRulesList({ ...FormValidator.form_validate, ...FormValidator.validate_[values.type], ...FormValidator.reward_validate, ...FormValidator.detail_validate }, values);
   return errors
}
function FromGift({ ...props }) {
   const { dispatch, formMode, rewardImagePreview } = props.props
   const { FORM_NAME } = props

   let { readOnly } = props.props

   if (formMode === `add`) {
      readOnly = false
   } else if (formMode === `edit` && !readOnly) {
      readOnly = false
   } else {
      readOnly = true
   }

   const getGiftReceiveCond = () => {
      const { package_features } = props.props
      let result = []
      if (lodash.indexOf(package_features, 'campaign_reward[gift_pickup]') >= 0) {
         result.push({
            value: "pickup",
            label: "Pickup"
         })
      }
      if (lodash.indexOf(package_features, 'campaign_reward[gift_delivery]') >= 0) {
         result.push({
            value: "shipping",
            label: "Delivery"
         })
      }
      return result
   }

   return (
      <>
         <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <Field
                        name="rewardDetail[setName]"
                        component={CustomInput}
                        type="text"
                        labelText={i18n.t("label.reward_name")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        disabled={readOnly}
                     />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                     <Field
                        name="rewardDetail[descriptionGift]"
                        component={CustomInput}
                        type="text"
                        labelText={i18n.t("label.reward_description")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        disabled={readOnly}
                     />
                  </GridItem>
               </GridContainer>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <Field
                        name="rewardPicture"
                        component={ImageUpload}
                        type="text"
                        labelText={i18n.t("label.reward_picture")}
                        textLeft={true}
                        height={190}
                        showRatioGuide={true}
                        onChange={(e) => {
                           e.preventDefault();
                           let reader = new FileReader();
                           let file = e.target.files[0];
                           reader.onloadend = () => {
                              dispatch({ type: campaign_v2Constants.SET_FORM_PREVIEW_IMAGE_REWARD, rewardImagePreview: reader.result })
                           };
                           try {
                              if (!FormValidator.validMimeType.includes(file.type)) {
                                 dispatch(touch(FORM_NAME, 'rewardPicture'));
                              }
                              reader.readAsDataURL(file);
                              dispatch(change(FORM_NAME, 'rewardPicture', file))
                           } catch (error) {
                              dispatch({ type: campaign_v2Constants.SET_FORM_PREVIEW_IMAGE_REWARD, rewardImagePreview: "default" })
                              dispatch(change(FORM_NAME, 'rewardPicture', ''))
                           }
                        }}
                        imagePreviewUrl={rewardImagePreview}
                        formControlProps={{
                           fullWidth: true
                        }}
                        validate={[FormValidator.validateImageFormat]}
                        disabled={readOnly}
                     />
                  </GridItem>
               </GridContainer>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                     <Field
                        name="giftReceiveCondition"
                        component={CustomSelect}
                        type="text"
                        labelText={i18n.t("label.receiving_condition")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        optionsList={getGiftReceiveCond()}
                        disabled={readOnly || formMode === "edit"}
                     />
                  </GridItem>
                  
               </GridContainer>
            </GridItem>
         </GridContainer>
      </>
   );
}

export default FromGift;

import { searchCodeConstants } from '../constants';
import _ from 'lodash'

const initialState = {
    formOpen: false,
    isLoading: false,
    formInitialValue: {},
    searchDatas: {
        data:[] 
    }
};

export function search_code(state = initialState, action) {
    switch (action.type) {
        case searchCodeConstants.CLEAR_PAGE_STATE:
            return initialState;
        case searchCodeConstants.GET_CODE_SUCCESS:
            return {
                ...state,
                searchDatas: action.data,
                isLoading: false,
                formOpen: true
            }
        default:
            return state
    }
}
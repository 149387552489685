import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import RewardForm from "./RewardForm.jsx";

import { tmn_rewardConstants } from '../../constants';
import { tmn_rewardActions } from 'actions';
import { plugins, crmValidator } from 'helpers';

const style = {
    actionDiv: {
        display: "inline-block"
    },
    upperCase: {
        textTransfrom: 'uppercase'
    }
};

const moduleName = 'true_money[reward]'

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "Active": BadgeColor = "success"; break;
        case "Expired": BadgeColor = "danger"; break;
        default: BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}
class TableTmnRewardtList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        };

        this.delayedCallback = lodash.debounce(this.getData, 500)

    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: tmn_rewardConstants.CLEAR_PAGE_STATE });
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: tmn_rewardConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(tmn_rewardActions.getRewardLists(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    openFormAdd = () => {
        const { dispatch } = this.props;
        let initialValues = {}
        dispatch({ type: tmn_rewardConstants.OPEN_FORM_ADD, data: initialValues });
    }

    openFormEdit(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.result[index]
        dispatch(tmn_rewardActions.getRewardById(rowData._id))
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.result;
        return data[rowIndex];
     }

    render() {
        const { classes, formInitialValue, tableDatas, tableKey, tableLoader } = this.props;

        const { rowsPerPage, page } = this.state;

        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            { name: "name", label: "Reward Name" },
            {
                label: "Available/Budget", options: {
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex);
                        return `${rowData.available} / ${rowData.budget}`
                     }
                }
            },
            {
                name: "status", label: "Status", options: {
                    // filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return renderBadgeStatus(value)
                    }
                }
            },

        ];

        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex);
                        return ( (rowData.status === 'Active') && <div className={classes.actionDiv}>
                                {
                                    plugins.isHasPermission(moduleName, 'update') && 
                                    <TableActionButton
                                        actionType="edit"
                                        onClick={(e) => {
                                            this.openFormEdit(tableMeta.rowIndex)
                                        }} 
                                    />
                                }
                            </div>
                        )
                    }
                }
            })
        }

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        if (plugins.isHasPermission(moduleName, 'create')) {
            options.customToolbar = () => {
                return (
                    <CustomToolbar id="add_new_reward" handleClick={this.openFormAdd} title="Add New" />
                );
            }
        }

        return (
            <div id="TableTmnRewardtList">
                <CustomSweetAlert />
                <RewardForm
                    initialValues={formInitialValue}
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                />

                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        title={"Reward lists"}
                        data={tableDatas.result}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner />}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader } = state.tmn_reward;
    // const { permission, superAdmin } = state.authentication;
    return {
        formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableTmnRewardtList));

import { configConstants } from '../constants';
import axios from 'axios';
import { history } from 'helpers';
import lodash from 'lodash';

export const treemapServices = {
    // editShipment,
    getTableDatas,
    // getaddresslabelDetail,
    getCampaignsList,
    getListCampaigns,
    getReferenceList,
    getAnnounceDateList,
    getRewardList,
    exportRFM,

    getTreeMap,
    visualizationData,
    rfmAddedCampaign,
    getListRFMCampaigns
};

function getTreeMap(params) {
    // console.log('params service getReferenceList', params);
    let req_headers = {
        'Content-Type': 'application/json',
        'Charset': 'utf-8',
        'Authorization': localStorage.getItem('token')
    }
    let tmp = axios.get(configConstants.API_DATAFILTERING + `/rfm/getTreemap?${params}`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getTableDatas(params) {
    var req_headers = {
        'Content-Type': 'application/json',
        'Charset': 'utf-8',
        'Authorization': localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_DATAFILTERING + '/rfm/search', params, { headers: req_headers })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

function getCampaignsList(params) {
    let req_headers = {
        'Content-Type': 'application/json',
        'Charset': 'utf-8',
        'Authorization': localStorage.getItem('token')
    }
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns?rewardType=couponCode`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getReferenceList(params) {
    let req_headers = {
        'Content-Type': 'application/json',
        'Charset': 'utf-8',
        'Authorization': localStorage.getItem('token')
    }
    let tmp = axios.get(configConstants.API_REFERENEC_CODE + `/reference-code?${params}`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getAnnounceDateList() {
    let req_headers = {
        'Content-Type': 'application/json',
        'Charset': 'utf-8',
        'Authorization': localStorage.getItem('token')
    }
    let tmp = axios.get(configConstants.API_LUCKYDRAW + `/list/announce_date`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getRewardList(params) {
    let req_headers = {
        'Content-Type': 'application/json',
        'Charset': 'utf-8',
        'Authorization': localStorage.getItem('token')
    }
    let tmp = axios.get(configConstants.API_LUCKYDRAW + `/list/reward-name`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function exportRFM(params) {
    var req_headers = {
        'Content-Type': 'application/json',
        'Charset': 'utf-8',
        'Authorization': localStorage.getItem('token')
    }

    var tmp = axios.get(configConstants.API_DATAFILTERING + '/rfm/export?segment=' + params, { headers: req_headers })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                const urlDownload = lodash.get(response.data, "data.url")
                if (urlDownload) {
                    window.location.assign(urlDownload)
                }
                return { status: true, data: {}, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

// function visualizationData(params) {
//     var req_headers = {
//         'Content-Type': 'application/json',
//         'Charset': 'utf-8',
//         'Authorization': localStorage.getItem('token')
//     }
//     console.log('visualizationData params : ',params);
//     // var tmp = axios.post(configConstants.API_LUCKYDRAW + '/winner/search', params, { headers: req_headers })
//     var tmp = axios.post(configConstants.API_DATAFILTERING + '/data-filtering/result/visualization', params, { headers: req_headers })
//         .then(response => {
//             console.log('getLists response', response);
//             if (response.data.statusCode.toString() === "200") {
//                 return { status: true, data: response.data.data, message: response.data.message };
//             } else if (response.data.message === "UNAUTHORIZE") {
//                 history.push('/auth/login')
//                 return { status: false, data: {}, message: response.data.message, unAlert: true };
//             } else {
//                 return { status: false, data: {}, message: response.data.message };
//             }
//         })
//         .catch(e => {
//             return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
//         });
//     return tmp;
// }

    function visualizationData(params) {
        let req_headers = {
            "Content-Type": "application/json",
            "Charset": "utf-8",
            "Authorization": localStorage.getItem('token')
        }
        
        // let tmp = axios.post(configConstants.API_DATAFILTERING + `/result/visualization`, params, {
        //     headers: req_headers
        // })
        var tmp = axios.post(configConstants.API_DATAFILTERING + '/data-filtering/result/visualization', params, { headers: req_headers })
            .then(response => {
                if (response.data.statusCode.toString() === "200") {
                    // return response.data.data
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: {},
                        message: response.data.message
                    };
                }
            })
            .catch(e => {
                return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
            });
     
        return tmp;
    }
    
    function getListCampaigns(params) {
        let req_headers = {
            'Content-Type': 'application/json',
            'Charset': 'utf-8',
            'Authorization': localStorage.getItem('token')
        }
        
        let tmp = axios.get(configConstants.API_BACKEND_CRM + `/cms/campaigns/v2?${params}`, {
            headers: req_headers
        })
            .then(response => {
                if (response.data.statusCode.toString() === "200") {
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: {},
                        message: response.data.message
                    };
                }
            })
            .catch(e => {
                return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
            });
    
        return tmp;
    }

    //getListRFMCampaigns
    function getListRFMCampaigns(params) {
        var req_headers = {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        var tmp = axios.post(configConstants.API_DATAFILTERING + '/rfm/searchcampaign', params, {
            headers: req_headers
        })
        .then(response => {
                if (response.data.statusCode.toString() === "200") {
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: {},
                        message: response.data.message
                    };
                }
            })
            .catch(e => {
                return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
            });
    
        return tmp;
    }

    //rfmAddedCampaign
    function rfmAddedCampaign(params) {
        var req_headers = {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        var tmp = axios.post(configConstants.API_DATAFILTERING + '/rfm/campaign', params, {
            headers: req_headers
        })
            .then(response => {
                if (response.data.statusCode.toString() === "200") {
                    
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                    
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: {},
                        message: response.data.message
                    };
                }
            });

        return tmp;
    }
export const shopConstants = {
    CLEAR_PAGE_STATE:"CLEAR_SHOP_PAGE_STATE",

    OPEN_ADD_FORM_SHOP: 'OPEN_ADD_FORM_SHOP',
    OPEN_EDIT_FORM_SHOP: 'OPEN_EDIT_FORM_SHOP',
    CLOSE_FORM_SHOP: 'CLOSE_FORM_SHOP',

    ADD_NEW_SHOP_SUCCESS:"ADD_NEW_SHOP_SUCCESS",
    EDIT_SHOP_SUCCESS:"EDIT_SHOP_SUCCESS",

    TABLE_RELOAD_SUCCESS:"SHOP_TABLE_RELOAD_SUCCESS",
    TABLE_RESET:"SHOP_TABLE_RESET",
    TABLE_RESET_SUCCESS:"SHOP_TABLE_RESET_SUCCESS",

    TABLE_GET_LIST_DATA:"TABLE_GET_SHOP_LIST",
    SHOW_TABLE_LOADER:"SHOP_SHOW_TABLE_LOADER",
    HIDE_TABLE_LOADER:"SHOP_HIDE_TABLE_LOADER",
    GET_SHOP_REGION_LIST:"GET_SHOP_REGION_LIST",

    DELETE_SHOP_LIST_SUCCESS:"DELETE_SHOP_LIST_SUCCESS",
};


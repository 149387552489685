import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';

export const adminServices = {
   addNewAdmin,
   editAdmin,
   deleteAdmin,
   getListsAdmin,
   getListsRole
};

const authFailedRedirectPath = "/auth/login";

function addNewAdmin(params) {
   var tmp = axios.post(configConstants.API_BACKEND_CRM + '/admins', params, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push(authFailedRedirectPath)
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      }).catch(e => {
         // history.push(authFailedRedirectPath)
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      })

   return tmp;
}

function editAdmin(params) {
   var tmp = axios.put(configConstants.API_BACKEND_CRM + `/admins/${params._id}`, params, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push(authFailedRedirectPath)
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      }).catch(e => {
         // history.push(authFailedRedirectPath)
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      })

   return tmp;
}

function deleteAdmin(params) {
   var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/admins/${params._id}`, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push(authFailedRedirectPath)
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      }).catch(e => {
         // history.push(authFailedRedirectPath)
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      })

   return tmp;
}

function getListsAdmin(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/admins?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push(authFailedRedirectPath)
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      }).catch(e => {
         // history.push(authFailedRedirectPath)
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getListsRole(urlParams) {
   if (urlParams !== undefined) {
      urlParams = "?" + urlParams
   } else {
      urlParams = ""
   }

   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/roles${urlParams}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push(authFailedRedirectPath)
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      }).catch(e => {
         // history.push(authFailedRedirectPath)
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}
export const contentManagementConstants = {
    OPEN_FORM_ADD: 'OPEN_FORM_ADD_CONTENT_MANAGEMENT',
    OPEN_FORM_EDIT: 'OPEN_EDIT_FORM_CONTENT_MANAGEMENT',
    CLOSE_FORM_CONTENT_MANAGEMENT: 'CLOSE_FORM_CONTENT_MANAGEMENT',
    SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT: 'SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT',
    SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT_DESKTOP: 'SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT_DESKTOP',

    ADD_CONTENT_SUCCESS: 'ADD_CONTENT_SUCCESS',

    EDIT_CONTENT_MANAGEMENT_SUCCESS: 'EDIT_CONTENT_MANAGEMENT_SUCCESS',
    EDIT_CONTENT_MANAGEMENT_FAILURE: 'EDIT_CONTENT_MANAGEMENT_FAILURE',

    DELETE_CONTENT_SUCCESS: 'DELETE_CONTENT_SUCCESS',

    GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
    GET_STATISTICS_FAILURE: 'GET_STATISTICS_FAILURE',
    GET_CONTENT_MANAGEMENT_DATA_SUCCESS: 'GET_CONTENT_MANAGEMENT_DATA_SUCCESS',
    TABLE_GET_LIST_CONTENT_MANAGEMENT: 'TABLE_GET_LIST_CONTENT_MANAGEMENT',

    HIDE_ALERT: 'HIDE_ALERT',
    SHOW_LOADER_CONTENT_MANAGEMENT: 'SHOW_LOADER_CONTENT_MANAGEMENT',
    HIDE_LOADER_CONTENT_MANAGEMENT: 'HIDE_LOADER_CONTENT_MANAGEMENT',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
 };
import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
export const listServices = {
    getCategoryList,
    getListsProduct,
    getListProductGroup,
    getListBrand
}

function getCategoryList(type, queryStringParams) {
    let reqUrl = configConstants.API_BACKEND_CRM + `/list/category?type=${type}`
    if (queryStringParams) {
        reqUrl = `${reqUrl}&${queryStringParams}`
    }
    let tmp = axios.get(reqUrl, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return { status: false, data: {}, message: response.data.message };
        }
    });
    return tmp;
}

function getListProductGroup(params) {
    let reqUrl = configConstants.API_BACKEND_CRM + `/sales-page/product-group/list?${params}`
    let tmp = axios.get(reqUrl, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return { status: false, data: {}, message: response.data.message };
        }
    });
    return tmp;
}

function getListBrand(params) {
    let reqUrl = configConstants.API_BACKEND_CRM + `/sales-page/brands?${params}`
    let tmp = axios.get(reqUrl, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return { status: false, data: {}, message: response.data.message };
        }
    });
    return tmp;
}

function getListsProduct(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/products?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

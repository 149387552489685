import { reset } from 'redux-form'
import lodash from 'lodash';

import { whitelistServices } from '../services';
import { whitelistConstants } from '../constants';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const whitelistAction = {
   getCampaignList,
   getWhitelistList,
   addWhitelist,
   editWhitelist,
   uploadWhitelist,
   saveUploadWhitelist,
   deleteWhitelist,
   // importMember
};

function getCampaignList(params) {
   return dispatch => {
      whitelistServices.getCampaignsList(params)
         .then(
            response => {
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     dispatch(success(response.data));
                  }
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: whitelistConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data }
   }
}

function getWhitelistList(params, tableReset = false) {
   return dispatch => {
      dispatch({ type: whitelistConstants.SHOW_LOADER })

      whitelistServices.getWhitelistList(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: whitelistConstants.GET_WHITELIST_SUCCESS, data: response.data.data, total: response.data.total, tableFilter: params });
                  // if (tableReset) {
                  //    dispatch({ type: whitelistConstants.TABLE_GET_LIST_DATAS_RESET });
                  // }
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: whitelistConstants.HIDE_LOADER })
         })
   };
}

function addWhitelist(params) {
   // console.log('addNewWhitelist action');
   // console.log('params :', params);
   return dispatch => {
      loader(dispatch, true)

      whitelistServices.addWhitelist(params)
         .then(
            response => {
               //console.log(response)
               if (response.status) {
                  dispatch({
                     type: whitelistConstants.ADD_NEW_WHITELIST_SUCCESS
                  });

                  customAlert(dispatch, 'success', i18n.t('warning_messages.whitelist_was_created'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
               }
            },
            error => {
               //console.log(error)
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   //function success(tmp) { return { type: whitelistConstants.ADD_NEW_WHITELIST_SUCCESS, tmp } }
}

function editWhitelist(params) {
   return dispatch => {
      loader(dispatch, true)

      whitelistServices.editWhitelist(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.whitelist_was_updated'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: whitelistConstants.EDIT_WHITELIST_SUCCESS, tmp } }
}

function deleteWhitelist(params) {
   //console.log('action',params);
   return dispatch => {
      loader(dispatch, true)
      whitelistServices.deleteWhitelist(params)
      .then(
         (response) => {
            //console.log(response);
            if (response.status) {
               dispatch({ type: whitelistConstants.DELETE_WHITELIST_SUCCESS })
               customAlert(
                     dispatch,
                     "success",
                     i18n.t("warning_messages.whitelist_was_deleted")
               )
            } else {
               if (!response.unAlert)
                     customAlert(
                        dispatch,
                        "error",
                        i18n.t(`api_messages.${response.message.toString()}`)
                     )
            }
         },
         (error) => {
             customAlert(dispatch, "error", i18n.t(defaultFailedText))
         }
      )
      .then(() => {
         loader(dispatch, false)
      })

   };
}

function uploadWhitelist(params) {
   return dispatch => {
      loader(dispatch, true)
      whitelistServices.uploadWhitelist(params)
         .then(
            response => {
               if (response.status) {
                  //console.log(response.data)
                  dispatch({
                     type: whitelistConstants.SHOW_UPLOAD_WHITELIST_RESULT,
                     data: response.data
                  });
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   }
}

function saveUploadWhitelist(params) {
   return dispatch => {
      loader(dispatch, true)
      whitelistServices.saveUploadWhitelist(params)
         .then(
            response => {
               if (response.status) {
                  //console.log(response.data)
                  dispatch({
                     type: whitelistConstants.OPEN_SAVE_UPLOAD_WHITELIST_RESULT,
                     data: response.data
                  });
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   }
}
import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { saleProductActions } from "actions";
import Badge from "components/Badge/Badge.jsx";
// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx";
import TableLoaderSpinner from "components/Table/TableLoaderSpinner";

// page components
import ProductForm from "./ProductForm";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import { saleProductConstants, swalConstants } from "../../../constants";
import { crmValidator } from "helpers";
import i18n from "i18n";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { Field, reduxForm, change } from "redux-form";

import { store } from 'helpers';

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import Button from "components/CustomButtons/Button.jsx";

import { brandAction } from "actions"

const FORM_NAME = "formFilterProduct"
const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  actionDiv: {
    display: "inline-flex",
  },
};

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme({
  ...MTablestyles,
  overrides: {
    ...MTablestyles.overrides,
    MuiTableCell: {
      head: {
        color: "#666666",
        padding: "4px 24px 4px 15px",
        "&:nth-child(7)": {
          textAlign: "center",
        },
      },
      body: {
        color: "#666666",
        padding: "4px 15px",
        "&:nth-child(14)": {
          textAlign: "center",
          color: "#ffffff",
        },
      },
    },
  },
});

class TableProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      searchFilter: {},
      reqParams: "",
    };
    this.delayedCallback = lodash.debounce(this.getData, 500);
  }

  componentDidMount() {
    const {
        dispatch
    } = this.props

    dispatch(brandAction.getBrandLists(''))
    this.getData();
}
renderBadgeStatus = (status) => {
  let BadgeColor
  if (status === undefined) {
    status = "Active"; 
  }
  switch (status) {
     case "Active": BadgeColor = "success"; break;
     case "Inactive": BadgeColor = "danger"; break;
     default: BadgeColor = "gray";
  }
  return (<Badge color={BadgeColor}>{status}</Badge>)
}

  componentDidUpdate() {
    const { tableReload, tableReset, dispatch } = this.props;
    if (tableReload || tableReset) {
      this.getData();
      dispatch({ type: saleProductConstants.TABLE_RELOAD_SUCCESS });
    }
  }

  getRowData(rowIndex = 0) {
    const { tableData } = this.props;
    const data = tableData.product_sales;
    return data[rowIndex];
  }

  getListBrands = (brands) => {
    if (brands !== undefined) {
       return brands.map((brand) => {
          return {
             value: brand._id,
             label: brand.name
          }
       })
    }
    return []
  }

  clearFilter = () => {
    const { dispatch } = this.props;
    dispatch(change(FORM_NAME, "createDateFrom", ""));
    dispatch(change(FORM_NAME, "createDateTo", ""));
    dispatch(change(FORM_NAME, "orderId", ""));
    dispatch(change(FORM_NAME, "customerName", ""));
    dispatch(change(FORM_NAME, "customerPhoneNumber", ""));
    dispatch(change(FORM_NAME, "paymentStatus", ""));
    dispatch(change(FORM_NAME, "shipmentStatus", ""));
    dispatch(change(FORM_NAME, "shipmentDelivery", ""));
    dispatch(change(FORM_NAME, "brandId", ""));

    this.setState({
      filter: {
        page: 0,
        rowsPerPage: 10,
        skip: 0,
        limit: 10,
        sortBy: "created_at",
        sortDir: "desc",
        keyword: "",
      }
    })
  };

  handleSubmit(values) {
    let searchFilter = {}

    if (lodash.size(values) > 0) {
      if (lodash.has(values, "brandId")) {
        lodash.set(searchFilter, "brandId", values.brandId)
      }
    }

    this.setState({
        searchFilter
    }, function () {
        this.getData(0,
            this.state.limit,
            this.state.sortBy,
            this.state.sortDir,
            this.state.keyword
        )
    })
}

  getData = (skip, limit, sortBy, sortDir, keyword) => {
    const { dispatch, tableReload, tableData } = this.props;

    let tableFilter = this.state.searchFilter

    if (!tableReload) {
      if (crmValidator.isEmptyValue(skip)) {
        skip = 0;
      }

      if (crmValidator.isEmptyValue(limit)) {
        limit = 10;
      }

      this.setState({
        page: skip,
        rowsPerPage: limit,
      });
      skip = parseInt(limit) * parseInt(skip);
      if (tableData.total !== 0 && tableData.total === skip) {
        skip = skip - limit;
        this.setState({
          page: this.state.page - 1,
        });
      }
      if (skip >= tableData.total) {
        skip = 0;
        this.setState({
          page: 0,
        });
      }
      this.setState({
        reqParams: {
          skip: skip,
          limit: limit,
        },
      });
      lodash.set(tableFilter, "skip", skip)
      lodash.set(tableFilter, "limit", limit)

      if (!crmValidator.isEmptyValue(sortBy)) {
        if (sortBy === "startDateEndDate") {
          sortBy = "startDate";
        }
        lodash.set(tableFilter, "sortBy", sortBy)
      }

      if (!crmValidator.isEmptyValue(sortDir)) {
        lodash.set(tableFilter, "sortDir", sortDir)
      }

      if (!crmValidator.isEmptyValue(keyword)) {
        lodash.set(tableFilter, "keyword", keyword)
      }
    }

    const reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');
    dispatch(saleProductActions.getListsSaleProduct(reqParams));
  };

  openFormAdd = () => {
    const { dispatch } = this.props;
    dispatch({
      type: saleProductConstants.OPEN_FORM_ADD,
      formInitialValue: { status: "Active" },
    });
  };

  openFormEdit(index) {
    const { dispatch } = this.props;
    const tmp = this.getRowData(index);
    dispatch(success(tmp));
    function success(tmp) {
      return { type: saleProductConstants.OPEN_FORM_EDIT_SALEPRODUCT, tmp }
    }
  }
  render() {
    const {
      classes,
      tableKey,
      tableData,
      formInitialValue,
      dispatch,
      showLoader,
      handleSubmit,
    } = this.props;
    const { page, rowsPerPage } = this.state;

    const storeData = store.getState().authentication
    const isBrandAdminRole = lodash.get(storeData, 'user.isBrandAdminRole', false)
    const superAdmin = !isBrandAdminRole || lodash.get(storeData, 'superAdmin', false)


    let columns = [
      { name: "_id", options: { display: "excluded" } },
      superAdmin ? {
        name: "brandName", label: "brand Name", options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                const rowData = this.getRowData(tableMeta.rowIndex)

                const brandId = rowData.brandId || ''
                if (!brandId) {
                    return ''
                }

                const findBrand = (this.props.brands || []).find(brand => brand._id === brandId)
                if (findBrand) {
                    return findBrand.name
                }

                return ''
            }
        }
    } : null,
      { name: "name", label: "Product Name" },
      { name: "categoryName", label: "Product Catagory" },
      { name: "price", label: "Price"  ,options: {
        customBodyRender: (value) => {
          return parseFloat(value).toFixed(2); 
        }
      }},
      { name: "sale_price", label: "Sales price"  ,options: {
        customBodyRender: (value) => {
          return parseFloat(value).toFixed(2); 
        }
      }},
      { name: "weight", label: "Weight (kg)" ,options: {
        customBodyRender: (value) => {
          return parseFloat(value).toFixed(2); 
        }
      } },
      {
        name: "status", label: "Status", options: {
           filter: false,
           customBodyRender: (value, tableMeta) => {
              const rowData = this.getRowData(tableMeta.rowIndex);
              return this.renderBadgeStatus(rowData.status)
           }
        }
     },
      {
        label: "Action",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            return (
              <div className={classes.actionDiv}>
                <TableActionButton
                  id="btn_edit"
                  actionType="edit"
                  onClick={() => {
                    this.openFormEdit(tableMeta.rowIndex);
                  }}
                />

                <TableActionButton
                  id="btn_delete"
                  actionType="delete"
                  onClick={() => {
                    dispatch({
                      type: swalConstants.SHOW,
                      data: {
                        type: "warning",
                        title: i18n.t(
                          "warning_messages.are_you_sure_delete_sale_product"
                        ),
                        message: `${this.getRowData(tableMeta.rowIndex).name}`,
                        onConfirm: function() {
                          let reqParams = {
                            _id: tableMeta.rowData[0],
                          };
                          dispatch({ type: swalConstants.HIDE });
                          dispatch(
                            saleProductActions.deleteSaleProduct(
                              reqParams
                            )
                          );
                        },
                        onCancel: function() {
                          dispatch({ type: swalConstants.HIDE });
                        },
                        showCancel: true,
                        confirmBtnText: i18n.t("delete"),
                        cancelBtnText: i18n.t("cancel"),
                      },
                    });
                  }}
                />
              </div>
            );
          },
        },
      },
    ];

    columns = columns.filter(c => c)

    let options = {
      serverSide: true,
      count: tableData.total,
      page: page,
      rowsPerPage: rowsPerPage,
      selectableRows: "none",
      print: false,
      download: false,
      filter: false,
      responsive: "scroll",
      rowsPerPageOptions: [5, 10, 20],
      onTableChange: (action, tableState) => {
        let skip,
          limit,
          sortBy,
          sortDir,
          keyword = "";

        if (tableState.page !== null) {
          skip = tableState.page;
        }

        if (tableState.rowsPerPage !== null) {
          limit = tableState.rowsPerPage;
        }

        if (tableState.activeColumn !== null) {
          sortBy = tableState.columns[tableState.activeColumn].name;
        }
        if (tableState.announceText !== null) {
          sortDir =
            tableState.announceText.search("descending") !== -1
              ? "desc"
              : "asc";
        }
        if (tableState.searchText !== null || action === "search") {
          keyword = tableState.searchText;
          this.delayedCallback(skip, limit, sortBy, sortDir, keyword);
        }
        let actionList = ["changePage", "sort", "changeRowsPerPage"];
        if (actionList.indexOf(action) !== -1) {
          this.getData(skip, limit, sortBy, sortDir, keyword);
        }
      },
      customToolbar: () => {
        return (
          <CustomToolbar
            handleClick={this.openFormAdd.bind(this)}
            title="Add New"
            id="btn_add"
          />
        );
      },
    };
    return (
      <div id="tableContentManagementList">
        <CustomSweetAlert />
        <ProductForm initialValues={formInitialValue} />

        {!isBrandAdminRole &&
        <>
        <h4>
            <b>PRODUCT LIST</b>
        </h4>

        <form
          name={FORM_NAME}
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <GridContainer>
              { !isBrandAdminRole && <GridItem xs={6} md={3} lg={3}>
                  <Field
                      name="brandId"
                      component={CustomSelect}
                      type="text"
                      labelText={"Brand"}
                      formControlProps={{
                          fullWidth: true
                      }}
                      optionsList={[
                          {
                              value: "",
                              label: "All"
                          },
                          ...this.getListBrands(this.props.brands)
                      ]}
                  />
              </GridItem>
              }

          </GridContainer>

          <GridContainer>
              <GridItem xs={12} sm={4} md={4} lg={3}>
                  <Button
                  onClick={this.clearFilter}
                  style={{
                      marginTop: "25px"
                  }}
                  color="white"
                  >
                  {i18n.t("clear")}
                  </Button>
                  &nbsp;  &nbsp;  &nbsp;
                  <Button
                      type="submit"
                      style={{
                          marginTop: "25px"
                      }}
                      color="primary"
                      >
                      {i18n.t("submit")}
                  </Button>
              </GridItem>
          </GridContainer>
      </form>
        </>
        }
        <MuiThemeProvider theme={THEME}>
          <MUIDataTable
            key={tableKey}
            title={`${isBrandAdminRole ? 'Product List' : ''}`}
            data={tableData.product_sales}
            columns={columns}
            options={options}
          />
          {showLoader && <TableLoaderSpinner />}
        </MuiThemeProvider>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    tableKey,
    tableData,
    formInitialValue,
    tableReset,
    showLoader,
    tableFilter,
    tableReload,
  } = state.saleProduct;
  const { permission, superAdmin, visiblePersonalData } = state.authentication;
  const { tableData: { brands } } = state.brand;
  return {
    permission,
    superAdmin,
    visiblePersonalData,
    tableKey,
    tableData,
    formInitialValue,
    tableReset,
    showLoader,
    tableFilter,
    tableReload,
    brands
  };
}

const connectContainer = connect(mapStateToProps)(
  withStyles(pageStyle)(TableProduct)
);

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(withStyles(style)(connectContainer));

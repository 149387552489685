import { configConstants } from '../constants';
import axios from 'axios';
export const loaderServices = {
    getEnvConfig
}

function getEnvConfig() {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + '/public/config')
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                // history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: 'SOMETHING_WENT_WRONG' };
        });
    return tmp;
}
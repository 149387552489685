import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, arrayPush, getFormValues, reset } from 'redux-form';
import { crmValidator, normalize } from 'helpers';
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { newconditionAction } from "actions"

import { newcondition } from "../../constants"

const FORM_NAME = "FormConfirm"

const style = {
  ...modalStyle
}

const form_validate = {
  templateName: {
    rules: {
      required: true,
    },
    messages: {
      required: 'Template Name is required',
    }
  }
}

const validate = (values) => {
  const errors = crmValidator.validateByRulesList(form_validate, values);
  return errors
}

class Confirm extends React.Component {

  handleSubmitTemplate(value) {
    const { dispatch, result, resetform } = this.props
  
    var reqParams = {
      value,
      result
    }
    
    dispatch(newconditionAction.addNewCondition(reqParams))
    resetform()
  }

  render() {
    const { classes, dispatch, formTemplateNewOpen, handleSubmit, readOnly, result } = this.props

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={formTemplateNewOpen}
        keepMounted
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        fullWidth={true}
        maxWidth="xs"
        disableRestoreFocus={true}
        scroll="body">
        <DialogTitle
          disableTypography
          className={classes.modalHeader}
          style={{ paddingBottom: "0px" }}
        >
          <h4 className={classes.modalTitle}><b>Save as Template</b></h4>
        </DialogTitle>
        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmitTemplate.bind(this))}>
          {/* onSubmit={handleSubmit(this.handleSubmit.bind(this))}> */}
          <DialogContent
            id="template-name-dialog"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  name="templateName"
                  component={CustomInput}
                  type="text"
                  labelText={"Template Name"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled={readOnly}
                // normalize={normalize.onlyFloatingTwo}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter}>
            {!readOnly &&
              <Button
                id={`promotion_purchase_amount_condition_btn_submit`}
                type="submit"
                style={{ marginRight: "10px" }}
                color="primary">
                Submit
                        </Button>
            }
            <Button
              id={`promotion_purchase_amount_condition_btn_cancel`}
              type="button"
              onClick={() => {
                dispatch({ type: newcondition.CLOSE_FORM_TEMPLATE_NAME });
              }}
              color="white">
              Cancel
                    </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}
function mapStateToProps(state) {
  const { formTemplateNewOpen } = state.df_newcondition
  return {
    formTemplateNewOpen
  };
}

const connectedComponent = connect(mapStateToProps)(Confirm);

export default reduxForm({
  form: FORM_NAME,
  validate: validate,
  enableReinitialize: false,
})(withStyles(style)(connectedComponent))
import { productConstants } from '../constants';
import { productServices, listServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import lodash from 'lodash';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const productActions = {
   addProduct,
   updateProduct,
   deleteProduct,
   getListsProduct,
   getProductById,
   getCategoryList
};

function getListsProduct(params) {
   return dispatch => {
      dispatch({ type: productConstants.SHOW_TABLE_LOADER })
      productServices.getListsProduct(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: productConstants.HIDE_TABLE_LOADER })
         })
   };

   function success(tmp) { return { type: productConstants.TABLE_GET_LIST_PRODUCTS, tmp }}
}

function addProduct(params) {
   return dispatch => {
      loader(dispatch, true)
      productServices.apiAddProduct(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: productConstants.ADD_NEW_PRODUCT_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.product_was_created'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function updateProduct(params) {
   return dispatch => {
      loader(dispatch, true)
      productServices.apiUpdateProduct(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: productConstants.EDIT_PRODUCT_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.product_was_updated'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function deleteProduct(params) {
   return dispatch => {
      loader(dispatch, true)
      productServices.apiDeleteProduct(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch({ type: productConstants.DELETE_PRODUCT_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.product_was_deleted'));
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function getProductById(params) {
   return dispatch => {
      loader(dispatch, true)
      productServices.getProductById(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch(success(tmp));
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: productConstants.OPEN_EDIT_PRODUCT, data : tmp.data , productImagePreview: tmp.data.filePath , onEarnrule : tmp.onEarnrule } }
}

function getCategoryList(){
   return dispatch => {
       loader(dispatch, true)
       listServices.getCategoryList('product')
           .then(
               response => {
                   if (response.status) {
                        const categoryList = lodash.has(response.data,'data') ? lodash.get(response.data,'data') : []
                        dispatch(success(categoryList))
                   }
               },
               error => {
                   return [];
               }
           ).then(() => {
               loader(dispatch, false)
           })
   };
   function success(data) { return { type: productConstants.GET_CATEGORY_LIST_SUCCESS, data }}
}
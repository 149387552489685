import React from 'react'
import { connect } from 'react-redux';
import lodash from 'lodash';
import _ from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { Field, reduxForm, change, touch, reset } from "redux-form"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import ConfigFieldMultiSelect from "components/CustomSelect/ConfigFieldMultiSelect.jsx"
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Button from 'components/CustomButtons/Button'

import { dfConfigActions } from 'actions';
import i18n from 'i18n';
import FormList from './FormList'
const FORM_NAME = "datafilteringList"
const style = {
    customCardContentClass: {
       paddingLeft: "0",
       paddingRight: "0"
    },
    cardIconTitle: {
       ...cardTitle,
       marginTop: "15px",
       marginBottom: "0px"
    },
    actionDiv: {
       display: "inline-flex"
    },
    btnClearFilter: {
        marginTop: "20px",
        width: "100%",
    },
};

const moduleName = 'datafilteringList';

const pageStyle = { ...style, ...sweetAlertStyle };

class TableList extends React.Component {
  
    constructor(props) {
        super(props);
  
        this.state = {
           readOnly: false,
           isLoading: false,
           page: 0,
           rowsPerPage: 10,
           count: 100,
           data: [],
           reqParams: "",
           tableTemplateList:[],
           tbNameSelected:[],
           dataloop : [],
           mode: 'edit',
           isShowAll: false
        };
    }

    async componentDidMount(){
        // const { dispatch } = this.props;
        // console.log('componentDidMount')
        // dispatch( await dfConfigActions.getTableConfig()); 
    }

    searchTableTemplateSubmit(values){
        const { reqParams} = this.state
        const { dispatch } = this.props
        const { dbtabletemplate } = values
        let keywords = []
        keywords = { "keyword" : dbtabletemplate}
        // dispatch(reset(FORM_NAME));
        if(dbtabletemplate != "All"){
        dispatch(dfConfigActions.searchTableTemplateList(keywords));
        }else{
            dispatch(  dfConfigActions.getTableConfig()); 
        }
    }

    handleToggle = async (newValue) => {
        const { dispatch, fieldsList } = this.props;
        this.setState({ 
            isShowAll: newValue
        })
        lodash.forEach(fieldsList, function (value, mkey) {
            // lodash.forEach(value.value, function(fieldval,fieldkey){
                _.map(value, (val,key)=> {
                dispatch(change(FORM_NAME, `tableName[${mkey}][${val.fieldName}][status]`, newValue))
                // dispatch(change(FORM_NAME, `tableName[${value.name}]fields[${fieldval.fieldName}][status]`, false))
            })
        })
    }
    handleSubmit = (values) => {
        const { dispatch,tableTemplateList,tableConfigId, fieldsList } = this.props;
        let tables = [];
        let tablesField = [];
        
        lodash.forEach(values.tableName, function (dbvalue, dbkey) {
            lodash.forEach(dbvalue, function (fieldVal, fieldkey) {
                let status = 'hide'
                if(fieldVal.status === true){
                    status = 'show'
                }
                
                tablesField.push({
                    fieldName : fieldkey,
                    displayName : fieldVal.displayName,
                    status : status,
                    type : fieldVal.type
                })
            })
            
            tables.push({
                tableName: dbkey,
                fields : tablesField
            })    
            tablesField = []   
        })
        if (tableConfigId) {
            dispatch(dfConfigActions.editTableConfig(tables,tableConfigId))
        }else{
            
            dispatch(dfConfigActions.addTableConfig(tables))
        }
    }
    clearFilter =  () => {
        const { dispatch } = this.props;
        dispatch(reset(FORM_NAME));
        dispatch(dfConfigActions.getTableConfig()); 
        this.setState ({
            isShowAll: false
         });
    }
   
  
    render() {
      const { formInitialValue,handleSubmit,tableTemplateList, fieldsList, dispatch } = this.props;
      const { isShowAll } =  this.state;
      var fieldsArray = [];
        lodash.forEach(fieldsList, function (value, mkey) {
           
            fieldsArray.push(
                <div key={mkey}>
                <h1>{mkey}</h1>
                    <GridContainer>
                        <GridItem xs={6} sm={4} md={4}>
                            <h6><b>{i18n.t("datafiltering.table_template")}</b></h6>
                        </GridItem>
                        <GridItem xs={6} sm={4} md={4}>
                            <h6><b>{i18n.t("datafiltering.display_name")}</b></h6>
                        </GridItem>
                        <GridItem xs={6} sm={4} md={4}>
                            <h6><b>{i18n.t("datafiltering.show")}</b></h6>
                            
                        </GridItem>
                    </GridContainer>
                    <FormList
						// props={this.props}
                        dispatch={dispatch}
                        setNameValue={mkey}
                        setNamekey={mkey}
						setValue={value}
						FORM_NAME={FORM_NAME}
                        isShowAll={isShowAll}
					/>
                </div>
            )
        })

        return (
            <div id="dataFilteringList">
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <legend><b>{i18n.t("datafiltering.table_template")}</b></legend>
                        <form
                            name={`${FORM_NAME}_config`} 
                            onSubmit={handleSubmit(this.searchTableTemplateSubmit.bind(this))}
                        >
                            <GridContainer>
                                <GridItem xs={6} sm={4} md={4}>
                                    <Field
                                        id={`${FORM_NAME}_dbTemplate`}
                                        name="dbtabletemplate"
                                        component={ConfigFieldMultiSelect}
                                        labelText={i18n.t("datafiltering.table_template")}
                                        formControlProps={{
                                        fullWidth: true,
                                        }}
                                        onChange={(e)=>{
                                            e.preventDefault();
                                            let selectedValue = e.target.value
                                            if(lodash.last(selectedValue) === 'All' || selectedValue.length === 0 ){
                                                dispatch(change(FORM_NAME, 'dbtabletemplate', ['All']))
                                            } else {
                                                if(lodash.indexOf(selectedValue, 'All') >= 0) {
                                                    selectedValue.shift();
                                                    dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                                } else {
                                                    dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                                }
                                            }
                                        }}
                                        showtick
                                        optionsList={tableTemplateList}
                                    />
                                </GridItem>

                                <GridItem>
                                    <Button xs={6} sm={4} md={4}
                                        id={`${FORM_NAME}-button-submit`}
                                        type="submit"
                                        style={{ marginRight: "10px", marginTop: "25px" }}
                                        size="sm"
                                        color="primary"
                                    >
                                        Search
                                    </Button>
                                </GridItem>
                                
                            </GridContainer>
                        </form>
                        
                        <form name={FORM_NAME}
                            onSubmit={handleSubmit(this.handleSubmit)}
                        >
                         
                            <GridContainer >
                                <GridItem xs={6} sm={4} md={4}>
                                    
                                </GridItem>
                                <GridItem xs={6} sm={4} md={4}>
                                    
                                </GridItem>
                                <GridItem xs={6} sm={4} md={4}>
                                    <Field
                                        style={{ }}
                                        name={`selectAll_status`}
                                        component={CustomCheckbox}
                                        id={`selectAll_statuss`}
                                        labelText="Select All"
                                        // checked={isCheckDefault}
                                        onChange={(e,newValue) => {
                                            this.handleToggle(newValue)
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                           {fieldsArray} 
                            
                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4} lg={3}>
                                   
                                    <Button
                                        onClick={this.clearFilter}
                                        style={{
                                            marginTop: "25px"
                                        }}
                                        color="white"
                                        >
                                        CANCEL
                                    </Button>
                                    &nbsp;  &nbsp;  &nbsp;
                                    <Button
                                    type="submit"
                                    style={{
                                        marginTop: "25px"
                                    }}
                                    color="primary">
                                        Submit
                                    </Button>
                                </GridItem>

                            </GridContainer>
                        </form> 
                    </GridItem>
                </GridContainer>
            
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    const {  formInitialValue,tableTemplateList,newdatabaseFieldsList, formMode,tableConfigId } = state.tableTemplateList;
    const { permission, superAdmin } = state.authentication;
    // const { tableTemplateList } = state.list
    return {
        formInitialValue,permission, superAdmin, tableTemplateList, tableConfigId, fieldsList :newdatabaseFieldsList, formMode
    };
 }
 
 const connectedComponent =   connect(mapStateToProps)(withStyles(pageStyle)(TableList));
 
 export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: false
  })(withStyles(style)(connectedComponent))


import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// core components
// import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx";

import _ from "lodash";

import { store } from 'helpers';

function AdminNavbar({ ...props }) {

    const { classes, color, sectionName } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color
    });
    const sidebarMinimize = classes.sidebarMinimize

    const storeData = store.getState().authentication
    const brand = _.get(storeData, 'user.brand', null)

    const brandName = brand?.name || ''
    const brandImage = brand?.filePath || ''

    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Hidden smDown implementation="css">
                    <div className={sidebarMinimize}>
                        {props.miniActive ? (
                            <Button
                                justIcon
                                round
                                color="white"
                                onClick={props.sidebarMinimize}
                            >
                                <ViewList className={classes.sidebarMiniIcon} />
                            </Button>
                        ) : (
                                <Button
                                    justIcon
                                    round
                                    color="white"
                                    onClick={props.sidebarMinimize}
                                >
                                    <MoreVert className={classes.sidebarMiniIcon} />
                                </Button>
                            )}
                    </div>
                </Hidden>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                    <Button href="#" className={classes.title} color="transparent">
                        <Hidden mdUp implementation="css">
                            <Button
                                className={classes.appResponsive}
                                color="transparent"
                                justIcon
                                aria-label="open drawer"
                                onClick={props.handleDrawerToggle}
                            >
                                <Menu />
                            </Button>
                        </Hidden>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>{sectionName}</div>
                            {brandName && brandImage && (
                                <>
                                    <img src={brandImage} style={{ width: '50px', height: '50px',marginRight:"10px", marginLeft: "10px" }} />
                                    <span style={{ marginRight: '10px' }}>{brandName}</span>          
                                </>
                            )}
                        </div>
                    </Button>
                </div>
                <Hidden smDown implementation="css">
                    {/* <AdminNavbarLinks rtlActive={false} /> */}
                </Hidden>
                {/* <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden> */}
            </Toolbar>
        </AppBar>
    );
}

AdminNavbar.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    sectionName: PropTypes.string
};

export default withStyles(adminNavbarStyle)(AdminNavbar);

import { merchantConstants } from '../constants';
import { merchantServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const merchantActions = {
   addMerchant,
   updateMerchant,
   deleteMerchant,
   getListsMerchant,
   getMerchantById,
   addBudgetToMerchant,
   getMerchantBudgetLists
};

function getListsMerchant(params) {
   return dispatch => {
      dispatch({ type: merchantConstants.SHOW_TABLE_LOADER })
      merchantServices.getListsMerchant(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: merchantConstants.HIDE_TABLE_LOADER })
         })
   };

   function success(tmp) { return { type: merchantConstants.TABLE_GET_LIST, tmp } }
}

function addMerchant(params) {
   return dispatch => {
      loader(dispatch, true)
      merchantServices.apiAddMerchant(params)
         .then(
            response => {
               if (response.status) {
                  
                  merchantServices.createSubsciption(lodash.get(response, 'data._id'), lodash.get(response, 'data.nameEN')).then(()=>{
                     dispatch({ type: merchantConstants.ADD_NEW_SUCCESS });
                     customAlert(dispatch, 'success', i18n.t('warning_messages.merchant_was_created'));
                     loader(dispatch, false)
                  })
                  
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                  loader(dispatch, false)
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
               loader(dispatch, false)
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };
}

function updateMerchant(params) {
   return dispatch => {
      loader(dispatch, true)
      merchantServices.apiUpdateMerchant(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: merchantConstants.EDIT_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.merchant_was_updated'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function deleteMerchant(params) {
   return dispatch => {
      loader(dispatch, true)
      merchantServices.apiDeleteMerchant(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch({ type: merchantConstants.DELETE_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.merchant_was_deleted'));
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function getMerchantById(params) {
   return dispatch => {
      loader(dispatch, true)
      merchantServices.getMerchantById(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch(success(tmp.data));
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: merchantConstants.OPEN_MANAGEMENT_EDIT, data: data } }
}

function addBudgetToMerchant(merchantId, values) {
   return dispatch => {
      loader(dispatch, true)
      merchantServices.addMerchantBudget(merchantId, values)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: merchantConstants.ADD_BUDGET_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.created_successfully'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function getMerchantBudgetLists(merchantId, params) {
   return dispatch => {
      loader(dispatch, true)
      merchantServices.getMerchantBudget(merchantId, params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })

         function success(tmp) { return { type: merchantConstants.GET_BUDGET_SUCCESS, tmp } }
   };
   
}

import React, { Component } from 'react';
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/image';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/textcolor';
import 'tinymce/icons/default';
import { Editor } from '@tinymce/tinymce-react';
import FormHelperText from "@material-ui/core/FormHelperText";
import '../../assets/skins/ui/oxide/skin.css'

import i18n from 'i18n';

global.tinymce = tinymce;

export default class EditorContainer extends Component {

   render() {
      const { input, labelText, meta: { touched, error } } = this.props
      const labelClass = {
         fontSize: "14px",
         marginTop: "10px",
         marginBottom: "10px",
         color: "#AAA",
         fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
         fontWeight: "400",
         opacity: "1",
         lineHeight: "1.42857"
      }
      const labelClassError = {
         fontSize: "14px",
         marginTop: "10px",
         marginBottom: "10px",
         fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
         fontWeight: "400",
         opacity: "1",
         lineHeight: "1.42857"
      }
      
      return (
         <>
            {(touched && error) ?
               <div style={labelClassError} className="labelRootError">{labelText}</div> :
               <div style={labelClass}>{labelText}</div>
            }
            <Editor
               value={input.value}
               onEditorChange={input.onChange}
               init={{
                  menubar: "file edit view insert",
                  plugins: [
                     'advlist lists link image preview anchor textcolor',
                  ],
                  toolbar: 'undo redo | fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat ',
                  removeformat: [
                     { selector: 'font', remove: 'all' },
                  ],
                  entity_encoding: "named",
                  inline_styles: false
               }}
            />
            {touched && error ?
               <FormHelperText id={input.id + "-text"} className="labelRootError">
                  {i18n.t(error)}
               </FormHelperText>
               : null}
         </>
      );

   }
}

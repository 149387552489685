import React, { useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";

import { getFormValues } from "redux-form";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Loader from "react-loader-spinner";
import lodash, { debounce, set } from "lodash";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { businessWebsiteConstants } from '../../constants/business_website.constants'
import { businessWebsiteAction } from 'actions';
import "./BusinessThemePreview.css";

function BusinessThemePreview({ ...props }) {
  const [name, setName] = React.useState(props.businessName);
  const [isLoading, setLoading] = React.useState(true);
  const [isOpen, setOpen] = React.useState(false);
  const [previewType, setPreviewType] = React.useState('');
  const [logo, setLogo] = React.useState(
    lodash.get(props, "businessLogoPreview")
  );
  const [cover, setCover] = React.useState(
    lodash.get(props, "businessCoverPreview")
  );
  const [themeColor, setTheme] = React.useState(
    lodash.get(props, "businessThemeColor")
  );
  const TermAndCondRef = useRef(null)

  const businessNameHandler = useCallback(
    debounce((val) => {
      setLoading(true);
      setTimeout(() => {
        setName(val);
        setLoading(false);
      }, 500);
    }, 1000),
    []
  );

  useEffect(() => {
    if (
      lodash.get(props, "businessLogoPreview") !== logo ||
      lodash.get(props, "businessCoverPreview") !== cover ||
      lodash.get(props, "businessThemeColor") !== themeColor
    ) {
      setLoading(true);
    }
    setTimeout(() => {
      setLoading(false);
      setLogo(lodash.get(props, "businessLogoPreview"));
      setCover(lodash.get(props, "businessCoverPreview"));
      setTheme(lodash.get(props, "businessThemeColor"));
    }, 300);
  }, [
    props.businessFormValues,
    props.businessLogoPreview,
    props.businessCoverPreview,
    props.businessThemeColor,
  ]);

  useEffect(() => {
    if (props.businessName !== name) businessNameHandler(props.businessName);
  }, [props.businessName]);

  const Spinner = (props) => {
    return (
      <div
        className="loader-spinner"
        style={{
          textAlign: "center",
          position: "absolute",
          top: "0px",
          height: "100%",
          width: "calc(100% + 21px)",
          backgroundColor: "#ffffffcf",
          zIndex: "700",
          display: "table",
          marginLeft: "-8px",
        }}
      >
        <Loader type="TailSpin" color="orange" height={50} width={50} />
      </div>
    );
  };

  useEffect(()=>{
    if(props.isOpen){
      setOpen(true)
    }
  },[props.businessPrivacyAndTc,props.isOpen])


const getPreview =(type)=>{
const { dispatch } = props
setPreviewType(type)
dispatch(businessWebsiteAction.getPrivacyNoticeAndTc(type))
}


const closePreview =(type)=>{
  const { dispatch } = props
  setOpen(false)
  setTimeout(()=>{
    dispatch({type : businessWebsiteConstants.CLOSE_PRIVACY_NOTICE_AND_TC})
  },500)
 
}
  

  const PopupDetail = () => {
 
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={isOpen} >
        <DialogTitle style={{width: '600px'}}>
          <GridContainer>
            <GridItem xs={10} sm={10} md={10}>
              <h4 style={{ fontWeight: "700", fontSize: "1.0rem" }}>
                {previewType === 'consent' ?  "เงื่อนไขการให้บริการ" : "นโยบายความเป็นส่วนตัว"}
              </h4>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  closePreview();
                }}
                style={{marginLeft: '15px'}}
              >
                <CloseIcon />
              </IconButton>
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ alignSelf: "flex-end" ,  marginTop: "-2vh"}}>
           
              <p className="welcome-lable2">{lodash.get(props,'businessPrivacyAndTc')?  <div ref={TermAndCondRef} dangerouslySetInnerHTML={{ __html: lodash.get(props, 'businessPrivacyAndTc') }} />: ""}</p>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      {PopupDetail()}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <>
            <div className="container tc-section">
              <div className="iphone-x">
                <div className="screen">
                  {isLoading && Spinner()}
                  <div className="notch">
                    <div className="sound" />
                    <div className="camera" />
                  </div>
                  <div className="time">9:19 AM</div>
                  <i className="fas fa-battery-three-quarters" />
                  <section className="cover">
                    <img className="cover-img" src={cover} />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div>
                          <img className="store-lable-img" src={logo} />
                          <div className="store-lable-text ">{name}</div>
                        </div>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <div className="point-bar">
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <div className="top-profile-bar">
                                <GridContainer>
                                  <GridItem xs={12} sm={7} md={7}>
                                    <p className="welcome-lable">
                                      {"สวัสดี คุณปณิตา"}
                                    </p>
                                  </GridItem>
                                  <GridItem xs={12} sm={5} md={5}>
                                    <p className="point-lable">
                                      335{" "}
                                      <font className="point-text">พอยท์</font>
                                    </p>
                                  </GridItem>
                                </GridContainer>
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <div
                                className="bottom"
                                style={
                                  lodash.isUndefined(themeColor) === false && {
                                    backgroundColor: `${themeColor.primary}`,
                                  }
                                }
                              >
                                <GridContainer>
                                  <GridItem xs={2} sm={2} md={2}>
                                    <svg
                                      className="barcodeIcon"
                                      width="21"
                                      height="17"
                                      viewBox="0 0 24 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M5.7 0H0.3C0.135 0 0 0.135 0 0.3V6C0 6.165 0.135 6.3 0.3 6.3H0.9C1.065 6.3 1.2 6.165 1.2 6V1.2H5.7C5.865 1.2 6 1.065 6 0.9V0.3C6 0.135 5.865 0 5.7 0ZM5.7 18H1.2V13.2C1.2 13.035 1.065 12.9 0.9 12.9H0.3C0.135 12.9 0 13.035 0 13.2V18.9C0 19.065 0.135 19.2 0.3 19.2H5.7C5.865 19.2 6 19.065 6 18.9V18.3C6 18.135 5.865 18 5.7 18ZM23.7 0H18.3C18.135 0 18 0.135 18 0.3V0.9C18 1.065 18.135 1.2 18.3 1.2H22.8V6C22.8 6.165 22.935 6.3 23.1 6.3H23.7C23.865 6.3 24 6.165 24 6V0.3C24 0.135 23.865 0 23.7 0ZM23.7 12.9H23.1C22.935 12.9 22.8 13.035 22.8 13.2V18H18.3C18.135 18 18 18.135 18 18.3V18.9C18 19.065 18.135 19.2 18.3 19.2H23.7C23.865 19.2 24 19.065 24 18.9V13.2C24 13.035 23.865 12.9 23.7 12.9ZM5.7 3.6H3.9C3.735 3.6 3.6 3.735 3.6 3.9V15.3C3.6 15.465 3.735 15.6 3.9 15.6H5.7C5.865 15.6 6 15.465 6 15.3V3.9C6 3.735 5.865 3.6 5.7 3.6ZM18.3 15.6H20.1C20.265 15.6 20.4 15.465 20.4 15.3V3.9C20.4 3.735 20.265 3.6 20.1 3.6H18.3C18.135 3.6 18 3.735 18 3.9V15.3C18 15.465 18.135 15.6 18.3 15.6ZM15.3 3.6H13.5C13.335 3.6 13.2 3.735 13.2 3.9V15.3C13.2 15.465 13.335 15.6 13.5 15.6H15.3C15.465 15.6 15.6 15.465 15.6 15.3V3.9C15.6 3.735 15.465 3.6 15.3 3.6ZM8.1 3.6H7.5C7.335 3.6 7.2 3.735 7.2 3.9V15.3C7.2 15.465 7.335 15.6 7.5 15.6H8.1C8.265 15.6 8.4 15.465 8.4 15.3V3.9C8.4 3.735 8.265 3.6 8.1 3.6ZM10.5 3.6H9.9C9.735 3.6 9.6 3.735 9.6 3.9V15.3C9.6 15.465 9.735 15.6 9.9 15.6H10.5C10.665 15.6 10.8 15.465 10.8 15.3V3.9C10.8 3.735 10.665 3.6 10.5 3.6Z"
                                        fill={`${themeColor.fontIconPrimary}`}
                                      />
                                    </svg>
                                  </GridItem>
                                  <GridItem xs={7} sm={7} md={7}>
                                    <div className="bottom-text-show-member-position">
                                      <font
                                        className="showMemberText"
                                        color={`${themeColor.fontIconPrimary}`}
                                      >
                                        แสดงบัตรสมาชิกของฉัน
                                      </font>
                                    </div>
                                  </GridItem>
                                  <GridItem xs={1} sm={1} md={1}>
                                    <svg
                                      className="showMemberIcon"
                                      width="6"
                                      height="12"
                                      viewBox="0 0 8 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.772004 0.200618L0.153285 0.824505C0.00684766 0.972173 0.00684766 1.21161 0.153285 1.35931L5.80013 7.06718L0.153285 12.7751C0.00684766 12.9227 0.00684766 13.1622 0.153285 13.3099L0.772004 13.9338C0.918441 14.0814 1.15588 14.0814 1.30235 13.9338L7.84654 7.3346C7.99297 7.18693 7.99297 6.9475 7.84654 6.7998L1.30235 0.200618C1.15588 0.0529189 0.918441 0.0529189 0.772004 0.200618Z"
                                        fill={`${themeColor.fontIconPrimary}`}
                                      />
                                    </svg>
                                  </GridItem>
                                </GridContainer>
                              </div>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </section>

                  <GridContainer>
                    <div className="top-nav">
                      <GridItem xs={12} sm={12} md={12}>
                        <font className="textHeader">ของรางวัล</font>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className="flex-container">
                          <div>
                            <font
                              className="filterLebelYellow"
                              style={
                                lodash.isUndefined(themeColor) === false && {
                                  backgroundColor: `${themeColor.primary}`,
                                  border: `1px solid ${themeColor.primary}`,
                                  color: `${themeColor.fontIconPrimary}`,
                                }
                              }
                            >
                              ดูทั้งหมด
                            </font>
                          </div>
                          <div>
                            <font className="filterLebelGrey">แลกฟรี</font>
                          </div>
                          <div>
                            <font className="filterLebelGrey">ใช้พอยท์แลก</font>
                          </div>
                        </div>
                      </GridItem>

                      <div className="flex-flex-container-card ">
                        <div className="cardCampaing">
                          <img
                            className="campaignImg"
                            src="/assets/img/reward-01 3.png"
                          />
                          <div>
                            <div className="camTextDetail">
                              โทรศัพท์ iPhone 11{" "}
                              <font className="camTextDetail2">500</font>
                              <font className="camTextDetail3">พอยท์</font>
                            </div>
                          </div>
                          <div className="line" />
                          <font className="camTextDetail4">สิทธิ์เต็มแล้ว</font>
                        </div>

                        <div className="tabComponent">
                          <div className="flex-container">
                            <div className="tab-icon-text">
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.0003 4.82641L2.4125 11.5177C2.35625 11.5684 2.28437 11.592 2.22257 11.634V18.0556C2.22257 18.2029 2.2811 18.3442 2.38529 18.4484C2.48947 18.5526 2.63078 18.6111 2.77812 18.6111H7.22257C7.36991 18.6111 7.51122 18.5526 7.6154 18.4484C7.71959 18.3442 7.77812 18.2029 7.77812 18.0556V13.6111C7.77812 13.4638 7.83665 13.3225 7.94084 13.2183C8.04503 13.1141 8.18634 13.0556 8.33368 13.0556H11.667C11.8144 13.0556 11.9557 13.1141 12.0598 13.2183C12.164 13.3225 12.2226 13.4638 12.2226 13.6111V18.0556C12.2226 18.2029 12.2811 18.3442 12.3853 18.4484C12.4895 18.5526 12.6308 18.6111 12.7781 18.6111H17.2226C17.3699 18.6111 17.5112 18.5526 17.6154 18.4484C17.7196 18.3442 17.7781 18.2029 17.7781 18.0556V11.6354C17.7191 11.5952 17.649 11.5722 17.5955 11.5243L10.0003 4.82641ZM19.816 9.03752L10.9292 1.1903C10.6748 0.960333 10.3441 0.833008 10.0012 0.833008C9.65831 0.833008 9.32762 0.960333 9.07326 1.1903L0.184373 9.03752C0.130075 9.08628 0.0859148 9.14526 0.0544159 9.21109C0.0229169 9.27691 0.00469617 9.3483 0.000794575 9.42118C-0.00310702 9.49405 0.00738698 9.56698 0.0316771 9.63579C0.0559672 9.70461 0.0935774 9.76797 0.142359 9.82224L0.885762 10.6493C0.93452 10.7036 0.993498 10.7478 1.05933 10.7793C1.12516 10.8108 1.19655 10.829 1.26942 10.8329C1.34229 10.8368 1.41522 10.8263 1.48404 10.802C1.55285 10.7777 1.61621 10.7401 1.67048 10.6913L9.63298 3.66773C9.73448 3.57819 9.86517 3.52878 10.0005 3.52878C10.1359 3.52878 10.2666 3.57819 10.3681 3.66773L18.3306 10.691C18.3848 10.7398 18.4482 10.7774 18.517 10.8017C18.5858 10.826 18.6587 10.8365 18.7316 10.8326C18.8045 10.8287 18.8759 10.8104 18.9417 10.7789C19.0075 10.7474 19.0665 10.7033 19.1153 10.649L19.8583 9.8219C19.9567 9.71225 20.0075 9.56803 19.9996 9.42094C19.9916 9.27385 19.9256 9.13593 19.816 9.03752Z"
                                  fill={`${themeColor.primary}`}
                                />
                              </svg>
                              <div className="tab-text">หน้าหลัก</div>
                            </div>
                            <div className="tab-icon-text">
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 20 19"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5898 5.89967H18.125C19.1602 5.89967 20 6.75072 20 7.79967V11.5997C20 11.948 19.7188 12.233 19.375 12.233H18.75V17.933C18.75 18.982 17.9102 19.833 16.875 19.833H3.125C2.08984 19.833 1.25 18.982 1.25 17.933V12.233H0.625C0.28125 12.233 0 11.948 0 11.5997V7.79967C0 6.75072 0.839844 5.89967 1.875 5.89967H3.41016C3.03906 5.36926 2.8125 4.71613 2.8125 3.99967C2.8125 2.25405 4.11719 0.833008 5.72266 0.833008C7.375 0.833008 8.33203 1.83842 10 4.62509C11.668 1.83842 12.625 0.833008 14.2773 0.833008C15.8828 0.833008 17.1875 2.25405 17.1875 3.99967C17.1875 4.71613 16.9609 5.36926 16.5898 5.89967ZM14.2773 2.09967C13.3672 2.09967 12.7227 2.45988 10.7891 5.75717L10.7031 5.89967H14.2773C15.1914 5.89967 15.9375 5.04863 15.9375 3.99967C15.9375 2.95072 15.1914 2.09967 14.2773 2.09967ZM5.72266 2.09967C6.63672 2.09967 7.27734 2.45988 9.21094 5.75717L9.29297 5.89967H5.71875C4.80469 5.89967 4.05859 5.04863 4.05859 3.99967C4.0625 2.95072 4.80859 2.09967 5.72266 2.09967ZM3.125 18.5663H7.5V12.233H2.5V17.933C2.5 18.2813 2.78125 18.5663 3.125 18.5663ZM7.5 10.9663H1.25V7.79967C1.25 7.45134 1.53125 7.16634 1.875 7.16634H7.5V10.9663ZM8.75 7.16634V18.5663H11.25V7.16634H8.75ZM17.5 17.933C17.5 18.2813 17.2188 18.5663 16.875 18.5663H12.5V12.233H17.5V17.933ZM12.5 10.9663H18.75V7.79967C18.75 7.45134 18.4688 7.16634 18.125 7.16634H12.5V10.9663Z"
                                  fill="#292929"
                                />
                              </svg>

                              <div className="tab-text">รางวัลของฉัน</div>
                            </div>
                            <div className="tab-icon-text">
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.8335 10C12.6741 10 14.9764 7.76172 14.9764 5C14.9764 2.23828 12.6741 0 9.8335 0C6.99287 0 4.69064 2.23828 4.69064 5C4.69064 7.76172 6.99287 10 9.8335 10ZM12.0989 11.505C12.5375 11.3701 12.928 11.25 13.4335 11.25C16.4147 11.25 18.8335 13.6016 18.8335 16.5V18.125C18.8335 19.1602 17.9697 20 16.9049 20H2.76207C1.69734 20 0.833496 19.1602 0.833496 18.125V16.5C0.833496 13.6016 3.25225 11.25 6.2335 11.25C6.74127 11.25 7.13223 11.3704 7.57101 11.5054C8.13215 11.6782 8.77151 11.875 9.8335 11.875C10.8963 11.875 11.5371 11.6779 12.0989 11.505ZM16.9049 18.75C17.2585 18.75 17.5478 18.4688 17.5478 18.125V16.5C17.5478 14.293 15.7036 12.5 13.4335 12.5C13.131 12.5 12.8286 12.5922 12.456 12.7058C11.8585 12.8879 11.0807 13.125 9.8335 13.125C8.58332 13.125 7.806 12.8877 7.20925 12.7056C6.83747 12.5921 6.53578 12.5 6.2335 12.5C3.96341 12.5 2.11921 14.293 2.11921 16.5V18.125C2.11921 18.4688 2.4085 18.75 2.76207 18.75H16.9049ZM13.6906 5C13.6906 2.93359 11.9589 1.25 9.8335 1.25C7.70805 1.25 5.97635 2.93359 5.97635 5C5.97635 7.06641 7.70805 8.75 9.8335 8.75C11.9589 8.75 13.6906 7.06641 13.6906 5Z"
                                  fill="#292929"
                                />
                              </svg>

                              <div className="tab-text">ข้อมูลของฉัน</div>
                            </div>
                            <div className="tab-icon-text">
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 20 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.5898 5.89967H18.125C19.1602 5.89967 20 6.75072 20 7.79967V11.5997C20 11.948 19.7188 12.233 19.375 12.233H18.75V17.933C18.75 18.982 17.9102 19.833 16.875 19.833H3.125C2.08984 19.833 1.25 18.982 1.25 17.933V12.233H0.625C0.28125 12.233 0 11.948 0 11.5997V7.79967C0 6.75072 0.839844 5.89967 1.875 5.89967H3.41016C3.03906 5.36926 2.8125 4.71613 2.8125 3.99967C2.8125 2.25405 4.11719 0.833008 5.72266 0.833008C7.375 0.833008 8.33203 1.83842 10 4.62509C11.668 1.83842 12.625 0.833008 14.2773 0.833008C15.8828 0.833008 17.1875 2.25405 17.1875 3.99967C17.1875 4.71613 16.9609 5.36926 16.5898 5.89967ZM14.2773 2.09967C13.3672 2.09967 12.7227 2.45988 10.7891 5.75717L10.7031 5.89967H14.2773C15.1914 5.89967 15.9375 5.04863 15.9375 3.99967C15.9375 2.95072 15.1914 2.09967 14.2773 2.09967ZM5.72266 2.09967C6.63672 2.09967 7.27734 2.45988 9.21094 5.75717L9.29297 5.89967H5.71875C4.80469 5.89967 4.05859 5.04863 4.05859 3.99967C4.0625 2.95072 4.80859 2.09967 5.72266 2.09967ZM3.125 18.5663H7.5V12.233H2.5V17.933C2.5 18.2813 2.78125 18.5663 3.125 18.5663ZM7.5 10.9663H1.25V7.79967C1.25 7.45134 1.53125 7.16634 1.875 7.16634H7.5V10.9663ZM8.75 7.16634V18.5663H11.25V7.16634H8.75ZM17.5 17.933C17.5 18.2813 17.2188 18.5663 16.875 18.5663H12.5V12.233H17.5V17.933ZM12.5 10.9663H18.75V7.79967C18.75 7.45134 18.4688 7.16634 18.125 7.16634H12.5V10.9663Z"
                                  fill="#292929"
                                />
                              </svg>

                              <div className="tab-text">อื่นๆ</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </GridContainer>
                </div>
              </div>
            </div>
            <div className="tc-section">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4 style={{ fontWeight: "500", fontSize: "1.0rem" }}>
                    {"For customers to accept before registration"}
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ padding: "20px 0 0 0" }}>
                    <div className="flex-container">
                      <h4 style={{ fontWeight: "500", fontSize: "1.0rem" }}>
                        {"Term & Condition"}
                      </h4>
                      <Button
                        id="tc-button"
                        className="button-tc"
                        color={"white"}
                        size="sm"
                        onClick={() => {
                          getPreview('consent')
                        }}
                      >
                        {"Preview"}
                      </Button>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ padding: "20px 0 0 0" }}>
                    <div className="flex-container">
                      <h4
                        style={{
                          fontWeight: "500",
                          fontSize: "1.0rem",
                          paddingRight: "2.6vh",
                        }}
                      >
                        {"Privacy Notice"}
                      </h4>
                      <Button
                        id="pv-button"
                        className="button-tv"
                        color={"white"}
                        size="sm"
                        onClick={() => {
                          getPreview('privacy-notice')
                        }}
                      >
                        {"Preview"}
                      </Button>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{ padding: "20px 0 0 0" }}>
                    <div className="flex-container">
                      <h4
                        style={{
                          fontWeight: "450",
                          fontSize: "0.85rem",
                          paddingRight: "3.5vh",
                          color: "#f4b340",
                        }}
                      >
                        {
                          "If you would like to edit your Term & Condition or Privacy Notice, please contact by mail. Mail : egg-so@ascendcorp.com"
                        }
                      </h4>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </>
        </GridItem>
      </GridContainer>
    </>
  );
}

function mapStateToProps(state) {
  const businessFormValues = getFormValues("BUSINESS_INFORMATION")(state);
  const businessName = lodash.get(businessFormValues, "businessName");
  const {businessPrivacyAndTc,isOpen} = state.business_website

  return {
    businessFormValues,
    businessName,
    businessPrivacyAndTc,
    isOpen
  };
}

const connectedForm = connect(mapStateToProps)(BusinessThemePreview);

export default connectedForm;

import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import MUIDataTable from "mui-datatables";
import { plugins, crmValidator, loader } from "helpers";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import moment from 'moment'
import { summaryReportActions } from 'actions'
import { summaryReportServices } from 'services'
import { CSVLink } from "react-csv";
import qs from 'querystring'

const style = {};
const THEME = createMuiTheme(MTablestyles);

class TableEarnSummaryReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "reportDate",
                sortDir: "desc",
            },
            csvData: [],
            csvFileName: ""
        };
        this.csvLink = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 800);
    }

    getData(skip, limit, sortBy, sortDir) {
        const { dispatch, tableReload, summaryEarn } = this.props;
        let { tableFilter } = summaryEarn;

        let newFilter = tableFilter;

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                newFilter.skip = 0;
            }

            if (crmValidator.isEmptyValue(limit)) {
                newFilter.limit = 10;
            }
            newFilter.page = skip;
            this.setState({
                rowsPerPage: limit,
            });
            skip = parseInt(limit) * parseInt(skip);

            newFilter.skip = skip;
            newFilter.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                newFilter.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                newFilter.sortDir = sortDir;
            }

        } else {
            newFilter = this.state.newFilter;
        }

        dispatch(summaryReportActions.getEarnSummaryReport(newFilter));

        this.setState({
            isLoading: true,
            filter: newFilter,
        });
    }

    exportCSV() {
        const { summaryEarn, dispatch } = this.props;
        let { tableFilter, tableTotal } = summaryEarn;
        tableFilter.skip = 0;
        tableFilter.limit = tableTotal ? tableTotal : 100000;

        loader(dispatch, true);
        summaryReportServices
            .getEarnSummaryReport(qs.stringify(tableFilter))
            .then((response) => {
                loader(dispatch, false);
                let responseData = lodash.get(response, 'data.data')
                let totalRecord = lodash.get(response, 'data.total')
                let sumTotal = lodash.get(response, 'data.sum')

                if(responseData){
                    let reportDateFrom = lodash.get(tableFilter, 'startDate')
                    let reportDateTo = lodash.get(tableFilter, 'endDate')
                    if(!reportDateTo){
                        reportDateTo = moment().add(-1, 'days')
                    }
                    reportDateTo = moment(reportDateTo).format('DD MMM YY')


                    if(!reportDateFrom){
                        reportDateFrom = moment(reportDateTo).add(-(parseInt(totalRecord)-1), 'days')
                    }
                    reportDateFrom = moment(reportDateFrom).format('DD MMM YY')

                    let fileName = `SummaryEarn(${reportDateFrom} - ${reportDateTo})`

                    responseData.push({
                        reportDate: "Total",
                        success: lodash.get(sumTotal, 'success'),
                        unSuccess: lodash.get(sumTotal, 'unSuccess'),
                        total: lodash.get(sumTotal, 'total')
                    })

                    this.setState({ csvData: responseData, csvFileName: fileName }, () => {
                        this.csvLink.current.link.click()
                    })
                }
            })
            .catch((e) => {
                this.setState({ csvData: [] });
            });
    }

    render() {
        const { summaryEarn } = this.props;
        const { tableData, tableTotal, tableFilter } = summaryEarn;
        const { rowsPerPage } = this.state
        const columns = [
            {
                name: "reportDate",
                label: "Date",
                options: {
                    filter: false,
                    customBodyRender: (value) => moment(value).format("DD-MMM-YY")
                },
            },
            {
                name: "success",
                label: "Success",
                options: {
                    filter: false,
                    customBodyRender: (value) => plugins.numberFormat(value)
                }
            },
            {
                name: "unSuccess",
                label: "Unsuccess",
                options: {
                    filter: false,
                    customBodyRender: (value) => plugins.numberFormat(value)
                }
            },
            {
                name: "total",
                label: "Total",
                options: {
                    filter: false,
                    customBodyRender: (value) => plugins.numberFormat(value)
                }
            }
        ];

        const options = {
            serverSide: true,
            search: false,
            count: tableTotal ? tableTotal : 0,
            page: tableFilter.page,
            rowsPerPage: rowsPerPage,
            fixedHeader: true,
            selectableRows: "none",
            print: false,
            customToolbar: () => {
                return (
                    <>
                        {plugins.isHasPermission("report[summary_earn]", "export") && plugins.isHasPackageFeatures('export') &&(
                            <Tooltip title="Export CSV">
                                <IconButton id="button-earnSummaryTable-exportCSV" className="" onClick={this.exportCSV.bind(this)}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [1, 5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip,
                    limit,
                    sortBy,
                    sortDir

                if (tableState.page !== null) {
                    skip = tableState.page;
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage;
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name;
                }
                if (tableState.announceText !== null) {
                    sortDir =
                        tableState.announceText.search("descending") !== -1
                            ? "desc"
                            : "asc";
                }

                let actionList = ["changePage", "sort", "changeRowsPerPage"];
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir);
                }
            },
        };

        return (
            <div id='tableEarnSummaryReport'>
                <CSVLink
                    headers={[
                        { label: "Date", key: "reportDate" },
                        { label: "Success", key: "success" },
                        { label: "Unsuccess", key: "unSuccess" },
                        { label: "Total", key: "total" },
                    ]}
                    data={this.state.csvData}
                    filename={this.state.csvFileName}
                    className="btn btn-primary"
                    target="_blank"
                    ref={this.csvLink}
                    style={{display: "none"}}
                />

                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={summaryEarn.tableKey}
                        data={tableData ? tableData : []}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { visiblePersonalData, merchantSetting } = state.authentication;
    const { summaryEarn } = state.summaryReport;
    return {
        visiblePersonalData,
        merchantSetting,
        summaryEarn,
    };
}

export default connect(mapStateToProps)(
    withStyles(style)(TableEarnSummaryReport)
);

import React from "react";
import { Field, change } from 'redux-form';
import lodash from 'lodash';
import i18n from 'i18n';

import { Table, TableBody, TableCell, TableHead, TableRow, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx"
import Button from "components/CustomButtons/Button.jsx";

import * as FormValidator from "../validate/FormValidator"

import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import { crmValidator, normalize } from 'helpers'

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            body: {
                padding: '4px 12px 4px 12px',
                "&:nth-child(18)": {
                    textAlign: "center"
                }
            }
        }
    }
});

const validateLuckyChance = (value, allValues, props, name) => {
    let fieldName = name.replace('[luckyChance]', '[reward]')
    
    if (lodash.get(allValues, fieldName) && !value) {
        return "Lucky chance is required"
    }
}

function FormMissionPrivilegeType1({ ...props }) {
    const { classes, dispatch, formMode, FORM_MISSION, campaignList, rewardList } = props.props

    let { readOnly } = props.props
    if (formMode === `add`) {
        readOnly = false
    } else if (formMode === `edit` && !readOnly) {
        readOnly = false
    } else {
        readOnly = true
    }

    let pointDisable = lodash.get(FORM_MISSION.values, "missionPrivilege", []).indexOf('point') == -1
    let rewardCampaignDisable = lodash.get(FORM_MISSION.values, "missionPrivilege", []).indexOf('reward') == -1
    let luckyChanceDisable = lodash.get(FORM_MISSION.values, "missionPrivilege", []).indexOf('luckyChance') == -1

    let campaignListSelect = [];
    if (lodash.has(props.props, 'campaignList')) {
        lodash.forEach(campaignList, function (value, key) {
            campaignListSelect.push({
                value: value._id,
                label: value.name
            })
        })
    }

    let rewardLuckyDrawListSelect = [];
    if (lodash.has(props.props, 'rewardList')) {
        lodash.forEach(rewardList, function (value, key) {
            rewardLuckyDrawListSelect.push({
                value: value._id,
                label: value.name_en
            })
        })
    }

    const renderAddSingleProduct = () => {

        let tmp_row = []
        for (let n = 1; n <= 31; n++) {
            tmp_row.push(
                <TableRow key={n}>
                    <TableCell className={classes.tableCell} style={{ width: '120px' }}>
                        Day {n}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Field
                            name={`achieveList[${n}][point]`}
                            component={CustomInput}
                            type="number"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={pointDisable}
                            // validate={(lodash.get(FORM_MISSION.values.selectedRuleProduct, 'single') && index === 0) ? [FormValidator.validateIsZeroTypeProductQuantity] : [FormValidator.validateIsZeroTypeProductQuantityMore]}
                            normalize={normalize.onlyNumber}
                        />
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>
                        <Field
                            id={`achieveList[${n}][campaignId]`}
                            name={`achieveList[${n}][campaignId]`}
                            disabled={rewardCampaignDisable}
                            labelText="Select Campaign"
                            component={CustomSelect}
                            formControlProps={{
                                fullWidth: true
                            }}
                            optionsList={campaignListSelect}
                        />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Field
                            id={`achieveList[${n}][reward]`}
                            name={`achieveList[${n}][reward]`}
                            disabled={luckyChanceDisable}
                            labelText="Select Reward"
                            component={CustomSelect}
                            formControlProps={{
                                fullWidth: true
                            }}
                            optionsList={rewardLuckyDrawListSelect}
                        />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Field
                            name={`achieveList[${n}][luckyChance]`}
                            component={CustomInput}
                            type="number"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={luckyChanceDisable}
                            // normalize={(value) => {
                            //     return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                            // }}
                            normalize={normalize.onlyNumber}
                            validate={[validateLuckyChance]}
                        />
                    </TableCell> */}
                </TableRow>
            );
        }

        return tmp_row
    }

    const setAllData = (field) => {
        const { dispatch, FORM_MISSION } = props.props;
        let value = ""
        if (field === 'point') {
            value = lodash.get(FORM_MISSION.values, "set_point")
        } else if (field === 'campaignId') {
            value = lodash.get(FORM_MISSION.values, "set_reward")
        } else if (field === 'reward') {
            value = lodash.get(FORM_MISSION.values, "set_lucky_draw")
        } else if (field === 'luckyChance') {
            value = lodash.get(FORM_MISSION.values, "set_chance")
        }
        for (let n = 1; n <= 31; n++) {
            dispatch(change("FORM_MISSION", `achieveList[${n}][${field}]`, value))
        }
    }

    return (
        <>
            <GridContainer style={{ paddingRight: '35px' }}>
                <GridItem xs={12} style={{ margin: "10px" }}>
                    <h4><b>Reward redemption</b></h4>
                </GridItem>
                <GridItem xs={12} style={{ margin: "10px" }}>
                    <Field
                        id="reward-redemption"
                        name="rewardRedemption"
                        component={CustomRadio}
                        inline={true}
                        radioLists={[
                            {
                                value: "0",
                                label: 'Manually',
                                disabled: readOnly
                            },
                            {
                                value: "1",
                                label: 'Automatically',
                                disabled: readOnly
                            }
                        ]}
                    />
                </GridItem>
            </GridContainer>
             <GridContainer >{/* style={{ marginBottom: "-16px" }}> */}
                <GridItem xs={3} sm={3} md={6}>
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <FormLabel className={classes.labelHorizontal}>
                        Set point for all
                        </FormLabel>
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <Field
                        name="set_point"
                        component={CustomInput}
                        type="text"
                        // labelText="Merchant Name"
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={pointDisable}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={2} style={{ textAlign: "left" }}>
                    <Button className={classes.registerButton} type="button" color="primary" style={{ top: "12px" }} disabled={pointDisable} onClick={() => { setAllData('point') }}>
                        {i18n.t("set")}
                    </Button>
                </GridItem>
            </GridContainer>
            {/* <GridContainer style={{ marginBottom: "-16px" }}>
                <GridItem xs={3} sm={3} md={6}>
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <FormLabel className={classes.labelHorizontal}>
                        Set reward for all
                        </FormLabel>
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <Field
                        name={`set_reward`}
                        disabled={rewardCampaignDisable}
                        component={CustomSelect}
                        formControlProps={{
                            fullWidth: true
                        }}
                        labelText="Select Campaign"
                        optionsList={campaignListSelect}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={2} style={{ textAlign: "left" }}>
                    <Button className={classes.registerButton} type="button" color="primary" style={{ top: "12px" }} disabled={rewardCampaignDisable} onClick={() => { setAllData('campaignId') }}>
                        {i18n.t("set")}
                    </Button>
                </GridItem>
            </GridContainer>
            <GridContainer style={{ marginBottom: "-16px" }}>
                <GridItem xs={3} sm={3} md={6}>
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <FormLabel className={classes.labelHorizontal}>
                        Set lucky draw for all
                    </FormLabel>
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <Field
                        name={`set_lucky_draw`}
                        disabled={luckyChanceDisable}
                        component={CustomSelect}
                        formControlProps={{
                            fullWidth: true
                        }}
                        labelText="Select Reward"
                        optionsList={rewardLuckyDrawListSelect}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={2} style={{ textAlign: "left" }}>
                    <Button className={classes.registerButton} type="button" color="primary" style={{ top: "12px" }} disabled={luckyChanceDisable} onClick={() => { setAllData('reward') }}>
                        {i18n.t("set")}
                    </Button>
                </GridItem>
            </GridContainer>
            <GridContainer style={{ marginBottom: "-5px" }}>
                <GridItem xs={3} sm={3} md={6}>
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <FormLabel className={classes.labelHorizontal}>
                        Set chance for all
                    </FormLabel>
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                    <Field
                        name="set_chance"
                        component={CustomInput}
                        type="text"
                        // labelText="Merchant Name"
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={luckyChanceDisable}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={2} style={{ textAlign: "left" }}>
                    <Button className={classes.registerButton} type="button" color="primary" style={{ top: "12px" }} disabled={luckyChanceDisable} onClick={() => { setAllData('luckyChance') }}>
                        {i18n.t("set")}
                    </Button>
                </GridItem>
            </GridContainer> */}

            <GridContainer style={{ paddingRight: '35px' }}>
                <GridItem xs={12} sm={12} md={12} className={classes.tableSelectProduct}>
                    <MuiThemeProvider theme={THEME}>
                        <Table>
                            <TableHead>
                                <TableRow style={{ backgroundColor: "#cccc" }}>
                                    <TableCell className={classes.tableHead}>Day</TableCell>
                                    <TableCell className={classes.tableHead} style={{textAlign: 'center'}}>Point</TableCell>
                                    {/* <TableCell className={classes.tableHead}>Reward</TableCell>
                                    <TableCell className={classes.tableHead}>Lucky Draw Reward</TableCell>
                                    <TableCell className={classes.tableHead}>Lucky Draw Chance</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ border: "solid 1px #eee" }}>
                                {renderAddSingleProduct()}
                            </TableBody>
                        </Table>
                    </MuiThemeProvider>
                </GridItem>
            </GridContainer>
        </>
    );
}

export default FormMissionPrivilegeType1;

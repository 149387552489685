import _ from 'lodash';
import { rfmConfigConstants } from '../constants';
const initialState = {
    rfmConfig: [],
    databaseFieldsList: [],
    showLoader: false,
    formOpen: false,
    formMode: 'add',
    isLoading: false,
    formInitialValue: {},
    openExample: false,
}

export function rfmConfig(state = initialState, action) {
    switch (action.type) {
        case rfmConfigConstants.INIT_LIST_CONSTANT: {
            return initialState
        }
        case rfmConfigConstants.SET_TABLE_TEMPLATE_LIST: {
            return {
                ...state,
                dbtabletemplate: ['USER']
            }
        }
        case rfmConfigConstants.GET_TABLE_TEMPLATE_LIST: {
            let dataInitial = {}
            let dbTemplateAllList = []
            // let databaseFieldsList = []
            // dbTemplateAllList.push({
            //     _id: "All",
            //     name: "All"
            // })
            if (_.has(action.data, 'filtering')) {
                _.forEach(action.data.filtering, function (value, key) {
                    dbTemplateAllList.push({
                        _id: value.tableName,
                        // name: value.tableName,
                        label: value.tableName,
                        value: {
                            data: value.tableName,
                            fields: value.fields
                        }
                    })
                    // databaseFieldsList.push({
                    //     _id: value.tableName,
                    //     label: value.tableName,
                    //     value: value.fields
                    // })
                })

            }
            return {
                ...state,
                rfmConfig: dbTemplateAllList,
                // databaseFieldsList: databaseFieldsList
            }
        }
        case rfmConfigConstants.GET_RFM_CONFIG_LIST: {

            return {
                ...state,
                formMode: 'edit',
                databaseFieldsList: action.data[0],
                formInitialValue: {
                    "start_date": action.data[0].period.start_date,
                    "type": action.data[0].period.operator,
                    "recencyTable": {
                        "data": action.data[0].recency.table,
                    },
                    "recencyColumn": action.data[0].recency.column,

                    "frequencyTable": {
                        "data": action.data[0].frequency.table,
                    },
                    "frequencyColumn": action.data[0].frequency.column,

                    "monetaryTable": {
                        "data": action.data[0].monetary.table,
                    },
                    "monetaryColumn": action.data[0].monetary.column,
                }
            }
        }
        case rfmConfigConstants.ADD_RFM_CONFIG_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formMode: 'edit',
                databaseFieldsList: action.data
            }
        }
        case rfmConfigConstants.CHECK_TABLE_TEMPLATE_CONFIG: {
            return {
                ...state,
                formOpen: false,
                formMode: 'edit',
                // formInitialValue: {}
            }
        }
        case rfmConfigConstants.EDIT_NEW_DF_CONFIG_SUCCESS: {
            let databaseFieldsList = []
            if (_.has(action.data, 'filtering')) {
                _.forEach(action.data.filtering, function (value, key) {
                    databaseFieldsList.push({
                        _id: value.tableName,
                        name: value.tableName,
                        value: value.fields
                    })
                })

            }
            return {
                ...state,
                formMode: 'edit',
                databaseFieldsList: databaseFieldsList
            }
        }
        case rfmConfigConstants.SEARCH_FIELD_TEMPLATE_LIST: {

            let databaseFieldsList = []

            if (_.has(action.data, 'filtering')) {
                _.forEach(action.data.filtering, function (value, key) {

                    databaseFieldsList.push({
                        _id: value.tableName,
                        name: value.tableName,
                        value: value.fields
                    })
                })

            }
            return {
                ...state,
                databaseFieldsList: databaseFieldsList
            }
        }
        case rfmConfigConstants.OPEN_RECENCY_DETAIL:
            return {
                ...state,
                openExample: true
            }
        default:
            return state
    }
}

export const campaign_v2Constants = {
   OPEN_ADD_CAMPAIGN: 'OPEN_ADD_CAMPAIGN_V2',
   OPEN_ADD_CAMPAIGN_NEW: 'OPEN_ADD_CAMPAIGN_NEW_V2',
   OPEN_EDIT_CAMPAIGN: 'OPEN_EDIT_CAMPAIGN_V2',
   CLOSE_FORM_CAMPAIGN: 'CLOSE_FORM_CAMPAIGN_V2',
   ADD_NEW_CAMPAIGN_SUCCESS: 'ADD_NEW_CAMPAIGN_SUCCESS_V2',
   EDIT_CAMPAIGN_SUCCESS: 'EDIT_CAMPAIGN_SUCCESS_V2',
   TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS_V2',
   TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS_V2',
   SET_FORM_PREVIEW_IMAGE_CAMPAIGN: 'SET_FORM_PREVIEW_IMAGE_CAMPAIGN_V2',
   SET_FORM_PREVIEW_IMAGE_REWARD: 'SET_FORM_PREVIEW_IMAGE_REWARD_V2',
   DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS_V2',
   GET_CAMPAIGN_DATA_SUCCESS: 'GET_CAMPAIGN_DATA_SUCCESS_V2',
   CLEAR_PAGE_STATE: 'CAMPAIGN_CLEAR_PAGE_STATE_V2',
   TABLE_GET_LIST_CAMPAIGNS: 'TABLE_GET_LIST_CAMPAIGNS_V2',
   GET_TRD_PARTY_CODE_SET_SUCCESS: 'GET_TRD_PARTY_CODE_SET_SUCCESS',
   GET_TRUE_MONEY_REWARD_SET_SUCCESS:'GET_TRUE_MONEY_REWARD_SET_SUCCESS',
   GET_CATEGORY_LIST:"GET_CAMPAIGN_CATEGORY_LIST",
   GET_CATEGORY_LIST_SUCCESS:"GET_CAMPAIGN_CATEGORY_LIST_SUCCESS",
   GET_LUCKY_DRAW_REWARD_SET_SUCCESS:"GET_LUCKY_DRAW_REWARD_SET_SUCCESS",
};
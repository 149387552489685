import { saleProductGroupConstants } from '../constants';
import { saleProductGroupServices } from '../services';
import { loader, alert as customAlert } from '../helpers';

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const saleProductGroupActions = {
    getListsSaleProductGroup,
    deleteSaleProductGroup,
    updateSaleProductGroup,
    addSaleProductGroup,
    getSaleProductGroupDetail
}

function getListsSaleProductGroup(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductGroupServices.getListsSaleProductGroup(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: saleProductGroupConstants.TABLE_GET_LIST_SALEPRODUCTGROUP, tmp }}
}

function addSaleProductGroup(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductGroupServices.addSaleProductGroup(params)
            .then(
                response => { 
                    try {
                        if (response.status) {
                            dispatch({ type: saleProductGroupConstants.ADD_SALEPRODUCTGROUP_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.sale_product_group_was_created'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function deleteSaleProductGroup(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductGroupServices.deleteSaleProductGroup(params)
           .then(
              tmp => {
                 if (tmp.status) {
                    dispatch({ type: saleProductGroupConstants.DELETE_SALEPRODUCTGROUP_SUCCESS });
                    customAlert(dispatch, 'success', i18n.t('warning_messages.sale_product_group_was_deleted'));
                 } else {
                    if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
                 }
              },
              error => {
                 customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
           ).then(() => {
              loader(dispatch, false)
           })
     };
}

function updateSaleProductGroup(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductGroupServices.updateSaleProductGroup(params)
           .then(
              response => {
                 if (response.status) {
                    dispatch({ type: saleProductGroupConstants.EDIT_SALEPRODUCTGROUP_SUCCESS });
                    customAlert(dispatch, 'success', i18n.t('warning_messages.sale_product_group_was_updated'));
                 } else {
                    if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                 }
              },
              error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
           ).then(() => {
              loader(dispatch, false)
           })
     };
}

function getSaleProductGroupDetail(params) {
    return dispatch => {
        loader(dispatch, true)
        saleProductGroupServices.getSaleProductGroupDetail(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) {
        console.log('getSaleProductGroupDetail tmp :',tmp);
         return { type: saleProductGroupConstants.OPEN_FORM_EDIT_SALEPRODUCTGROUP , tmp }
    }
}



import { blacklistConstants } from '../constants';
import lodash from 'lodash'

const initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: new Date(),
    tableReload: false,
    tableReset: false,
    showLoader: false,
    tableDatas: [],
    formImportOpen: false,
    formImportInitialValue: {},
    showImportResult: false,
    importResult: [],
    formSaveImportOpen: false,
    saveImportResult: [],
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "createdAt",
        sortDir: "desc",
        keyword: "",
        search: {}
    },
};

export function blacklist(state = initialState, action) {
    switch (action.type) {
        case blacklistConstants.CLEAR_PAGE_STATE:
            return initialState;
        case blacklistConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case blacklistConstants.OPEN_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue
            }
        case blacklistConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formMode: 'add',
                formInitialValue: {}
            }
        case blacklistConstants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case blacklistConstants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case blacklistConstants.GET_BLACKLIST_SUCCESS:
            return {
                ...state,
                tableFilter: action.tableFilter,
                tableDatas: (action.data ? action.data : []),
                tableTotal: (action.total ? action.total : 0)
            }
        case blacklistConstants.ADD_NEW_BLACKLIST_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                formInitialValue: {}
            }
        case blacklistConstants.EDIT_BLACKLIST_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case blacklistConstants.TABLE_RELOAD_SUCCESS:
        case blacklistConstants.TABLE_RESET:
            return {
                ...state,
                tableReset: false,
                tableReload: false
            }
        case blacklistConstants.DELETE_BLACKLIST_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case blacklistConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                blacklistContainer: {
                    ...state.blacklistContainer,
                    campaignList: action.data.campaigns
                }
            }
        case blacklistConstants.OPEN_FORM_UPLOAD_BLACKLIST:
            return {
                ...state,
                formImportOpen: true,
                formImportInitialValue: (action.formImportInitialValue ? action.formImportInitialValue : {})
            }
        case blacklistConstants.CLOSE_FORM_UPLOAD_BLACKLIST:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {}
            }
        case blacklistConstants.SHOW_UPLOAD_BLACKLIST_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: true,
                importResult: action.data
            }
        case blacklistConstants.HIDE_UPLOAD_BLACKLIST_RESULT:
            return {
                ...state,
                showImportResult: false,
                importResult: {}
            }
        case blacklistConstants.OPEN_SAVE_UPLOAD_BLACKLIST_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: false,
                importResult: [],
                formSaveImportOpen: true,
                saveImportResult: action.data
            }
        case blacklistConstants.CLOSE_SAVE_UPLOAD_BLACKLIST_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: false,
                importResult: [],
                formSaveImportOpen: false,
                saveImportResult: [],
                tableReset: false,
                tableReload: true
            }
        default:
            return state
    }
}

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { reset } from 'redux-form';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { newconditionAction } from "actions"
import NewconditionForm from "./NewconditionForm";

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const datafiltering = [
    {
        "tableName": "USER",
        "fields": [
            {
                "fieldName": "oid",
                "displayName": "oidUser",
                "status": "show",
                "type": "string",
                "name": "oid",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "point",
                "displayName": "คะแนน",
                "status": "show",
                "type": "int",
                "name": "point",
                "operator": "=|>|>=|<|<=|<>|between"
            },
            {
                "fieldName": "taglist",
                "displayName": "taglist",
                "status": "hide",
                "type": "string",
                "name": "taglist",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "firstname",
                "displayName": "firstname",
                "status": "hide",
                "type": "string",
                "name": "firstname",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "lastname",
                "displayName": "lastname",
                "status": "hide",
                "type": "string",
                "name": "lastname",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "email",
                "displayName": "email",
                "status": "hide",
                "type": "string",
                "name": "email",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "phonenumber",
                "displayName": "phonenumber",
                "status": "hide",
                "type": "string",
                "name": "phonenumber",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            }
        ]
    },
    {
        "tableName": "EARNS",
        "fields": [
            {
                "fieldName": "oid",
                "displayName": "oidEarns",
                "status": "show",
                "type": "string",
                "name": "oid",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "productcode",
                "displayName": "productcode",
                "status": "hide",
                "type": "string",
                "name": "productcode",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "product_name",
                "displayName": "product_name",
                "status": "hide",
                "type": "string",
                "name": "product_name",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "rulecode",
                "displayName": "rulecode",
                "status": "hide",
                "type": "string",
                "name": "rulecode",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            }
        ]
    }
]
class NewconditionContainer extends React.Component {
    componentWillMount() {
        const { dispatch } = this.props
        dispatch(newconditionAction.getTableConfig())

    }
    render() {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <NewconditionForm />
                </GridItem>
            </GridContainer>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen } = state.df_newcondition;
    return {
        formOpen
    };
}
export default connect(mapStateToProps)(withStyles(style)(NewconditionContainer));

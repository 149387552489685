import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import Datetime from "react-datetime";

import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconClose from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import customDatePickerStyle from "assets/jss/material-dashboard-pro-react/components/customDatePickerStyle.jsx";

import i18n from 'i18n';

function generateLabelClasses(props) {
  const { classes, meta: { touched, error }, success, disabled } = props;
  return classNames({
    [" " + classes.labelRootError]: (touched && error),
    [" " + classes.labelRootSuccess]: success && !error,
    [" " + classes.labelRootDisabled]: disabled
  });
}

function generateUnderlineClasses(props) {
  const { classes, meta: { touched, error }, white, success } = props;
  return classNames({
    [classes.underlineError]: (touched && error),
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
}

function CustomDatePicker({ ...props }) {
  const {
    input,
    classes,
    formControlProps,
    labelText,
    id,
    type,
    disabled,
    labelProps,
    meta: { touched, error },
    white,
    inputRootCustomClasses,
    success,
    timeFormat=false,
    dateFormat="DD-MM-YYYY",
    onPickerFocus=()=>{
      return null
    }
  } = props;

  let { minDate, maxDate } = props
  
  const labelClasses = generateLabelClasses(props)
  const underlineClasses = generateUnderlineClasses(props)
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });

  const formatDateTime = () => {
    if(timeFormat && dateFormat){ 
      return dateFormat + " " + timeFormat;
    }else if(!dateFormat && timeFormat){
      return "" + timeFormat;
    }else{
      return dateFormat;
    }
  }

  var selectableDate = function( current ){
    minDate = (minDate === undefined) ? moment().add(-100,'years') : minDate

    return ( 
      ( minDate === undefined || minDate === "" || moment(current) >= moment(minDate) || minDate === null) && 
      ( maxDate === undefined || maxDate === "" || moment(current) <= moment(maxDate) || maxDate === null) 
    );
  };

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames({
      [formControlProps.className]: true,
      [classes.formControl]: true,
      [classes.disabled]: disabled
    });
  } else {
    formControlClasses = classNames({
      [classes.formControl]: true,
      [classes.disabled]: disabled
    });
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });
  var inputValue = function(value){ 
    if(value === undefined || value === "" || value === null){
        return "";
    }else{
      if( moment(value).isBefore(minDate) && minDate !== undefined && minDate !== "" ){
        return moment(minDate).format(formatDateTime());
      }
      else if(!dateFormat && timeFormat){ 
        return moment(value, [moment.ISO_8601, timeFormat]).format(formatDateTime())
      }
      else{  
        return moment(value).format(formatDateTime());
      }
    }
  };
  var renderYear = function( props, year ){
    if(year <= moment(maxDate).format('YYYY')){
        return <td {...props}>{year}</td>;
    }else{
        return null;
    }
  }

  const renderInput = function( props ){
    function clear(){
      props.onChange({target: {value: ''}});
    }
    return (
        <>
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Input {...props} 
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
          }} 
          id={id}
          style={{width:"100%"}}
          disabled={disabled}
          autoComplete="off"
          onKeyDown={(e) => {
            e.preventDefault()
          }}
          endAdornment={disabled ? null : <InputAdornment position="end" onClick={clear}><IconButton size="small"><IconClose style={{color:"#aaa", fontSize:"16px"}}/></IconButton></InputAdornment>}/>
        </>
        
    );
  }

  return (
    <FormControl {...formControlProps} className={formControlClasses} disabled={disabled}>
      <Datetime
        { ...input }
        renderInput={renderInput}
        value={inputValue(input.value)}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        isValidDate={selectableDate}
        renderYear={renderYear}
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        type={type}
        disabled={disabled}
        viewMode="days"
        inputProps={{
          disabled: disabled,
          onKeyUp: function(e){
            let date = moment(e.target.value, [moment.ISO_8601, timeFormat]);
            if(date.isValid() === false){
              e.target.value = ""
            }
          },
          onFocus: onPickerFocus
        }}
      />
      {(touched && error) ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {i18n.t(error)}
        </FormHelperText>
        ) : null}
    </FormControl>
  );
}

CustomDatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node
};

export default withStyles({ ...customDatePickerStyle })(CustomDatePicker);

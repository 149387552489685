import { luckyDrawReportConstants } from "../constants";
import { luckyDrawReportServices, uploadWinnerServices } from "../services";
import { loader, alert as customAlert } from "../helpers";
import lodash from "lodash";
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const luckyDrawReportActions = {
  getProductList,
  getCampaignList,
  getCollectTransactionList,
  getLuckyDrawDowloadList,
  getCollectTransactionById,
  getLuckyDrawRewardSetList,
};

function getProductList(params) {
  return dispatch => {
    // loader(dispatch, true)
    luckyDrawReportServices
      .getListsProduct(params)
      .then(
        response => {
          if (response.status) {
            if (lodash.has(response, "data.products")) {
              dispatch(success(response.data.products));
            }
          } else {
            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
  };

  function success(data) {
    return { type: luckyDrawReportConstants.GET_DROPDOWN_PRODUCT_LIST_SUCCESS, data };
  }
}

function getCampaignList(params) {
  return dispatch => {
    // loader(dispatch, true)
    luckyDrawReportServices
      .getCampaignsList(params)
      .then(
        response => {
          if (response.status) {
            if (lodash.has(response, "data.campaigns")) {
              dispatch(success(response.data.campaigns));
            }
          } else {
            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
  };

  function success(data) {
    return { type: luckyDrawReportConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data };
  }
}

function getCollectTransactionList(params, tableReset = false) {
  return dispatch => {
    loader(dispatch, true);
    luckyDrawReportServices
      .getCollectTransactionList(params)
      .then(
        response => {
          if (response.status) {
            dispatch({
              type: luckyDrawReportConstants.GET_COLLECT_TRANSACTON_SUCCESS,
              data: response.data.data,
              total: response.data.total,
              tableFilter: params
            });
            if (tableReset) {
              dispatch({ type: luckyDrawReportConstants.COLLECT_TRANSACTON_TABLE_RESET });
            }
          } else {
            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false);
      });
  };
}

function getLuckyDrawDowloadList(params, tableReset = false) {
  let strParams = ''

  if (typeof params === 'object') {
    strParams = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  }
  else {
    strParams = params
  }

  return dispatch => {
    loader(dispatch, true);
    luckyDrawReportServices
      .getLuckyDrawDowloadList(strParams)
      .then(
        response => {
          if (response.status === true) {
            if (response.data) {
              dispatch({
                type: luckyDrawReportConstants.GET_LUCKYDRAW_DOWLOAD_SUCCESS,
                data: response.data.data,
                total: response.data.total,
                tableFilter: params
              });
              if (tableReset) {
                dispatch({ type: luckyDrawReportConstants.LUCKYDRAW_DOWLOAD_RESET });
              }
            } else {
              if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
            }
          } else {
            dispatch({
              type: luckyDrawReportConstants.GET_LUCKYDRAW_DOWLOAD_SUCCESS,
              data: [],
              total: 0,
              tableFilter: params
            });
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false);
      });
  };
}

function getCollectTransactionById(_id) {
  return dispatch => {
    loader(dispatch, true)
    luckyDrawReportServices.getCollectTransactionById(_id)
    .then(
      response => {
        if (response.status) {
          dispatch({ type: "OPEN_EARN_REPORT_DETAIL", data: lodash.get(response, 'data') });
        }
      },
      error => {
        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
      }
    )
    .then(() => {
      loader(dispatch, false);
    });
  }
}

function getLuckyDrawRewardSetList(){
  let params = { showOnCampignAndEarnRule: "yes" }
  let strParams = ''
  if (typeof params === 'object') {
    strParams = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  }
  else {
    strParams = params
  }
  return dispatch => {
      loader(dispatch, true)
      uploadWinnerServices.getRewardList(strParams)
          .then(
              response => {
                  if (response.status) {
                      dispatch(success(response.data))
                  }
              },
              error => {
                  return [];
              }
          ).then(() => {
              loader(dispatch, false)
          })
  };
  function success(data) { return { type: luckyDrawReportConstants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS, data }}
}


import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ColorSelect from "components/CustomSelect/ColorSelect";
import Button from "components/CustomButtons/Button.jsx";
import RichEditor from "components/RichEditor/RichEditor.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import { crmValidator } from 'helpers';
import { memberLevelConstants } from '../../constants'
import { memberLevelAction } from 'actions';
import i18n from 'i18n';

const FORM_NAME = 'MEMBER_LEVEL_FORM'
const form_validate = {
    "name": {
        rules: {
            required: true,
            maxLength: 50,
            noSpecialCharacters: true
        },
        messages: {
            required: "warning_messages.name_is_required",
            maxLength: "รูปแบบ name ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง",
            noSpecialCharacters: "Cannot put spacial character"

        }
    },
    "color": {
        rules: {
            required: true
        },
        messages: {
            required: "warning_messages.color_is_required",
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const normalizeTagMaxLength = (value) => {
    return value.substr(0, 50);
}

class LevelForm extends React.Component {
    handleSubmit = (values) => {
        const { dispatch } = this.props 
        var reqParams = { 
            "name": values.name,
            "color": values.color,
            "description": values.description ? btoa(encodeURIComponent(values.description)) : "",
        }
        if (this.props.formMode === "add") {
            dispatch(memberLevelAction.addNewData(reqParams));
        } else {
            reqParams._id = values._id
            dispatch(memberLevelAction.editData(reqParams));
        }

    }
    render() {
        const { classes, formOpen, formMode, handleSubmit } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>{formMode === 'add' ? i18n.t("member_level.add_member_level") : (readOnly === true ? i18n.t("member_level.edit_member_level") : i18n.t("member_level.member_level_info"))}</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                {formMode === "edit" && 
                                    <Field
                                        name="_id"
                                        component="input"
                                        type="hidden"
                                        disabled={true}
                                    />
                                }
                                <Field
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText={i18n.t("label.name")}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={normalizeTagMaxLength}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="color"
                                    component={ColorSelect}
                                    type="text"
                                    labelText={i18n.t("label.color")}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                            <Field
                                name="description"
                                component={RichEditor}
                                type="text"
                                labelText="Description"
                                formControlProps={{
                                fullWidth: true
                                }}
                                disabled={readOnly}
                            />
                            </GridItem>
                        </GridContainer>

                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        
                        {readOnly === false &&
                            <Button
                                style={{ marginRight: "10px" }}
                                color="primary"
                                type="submit">
                                {i18n.t('save')}
                            </Button>
                        }

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: memberLevelConstants.CLOSE_FORM })
                        }}>
                            {i18n.t('cancel')}
                        </Button>

                    </DialogActions>

                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode } = state.memberLevel;
    const { MEMBER_LEVEL_FORM } = state.form
    return {
        formOpen, formMode, MEMBER_LEVEL_FORM
    };
}

const connectedComponent = connect(mapStateToProps)(LevelForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))
import { promotionConstants } from '../constants';
import { promotionServices, productServices, categoryServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const promotionActions = {
    addPromotion,
    updatePromotion,
    getTablePromotionDatas,
    deletePromotion,
    getListsProduct,
    getListsCategory,
    getPromotionById,
    getRegionList,
    getAllShopList,
    getTablePromotionReportDatas,
    getNamePromotion,
};

function addPromotion(params) {
    return dispatch => {
        loader(dispatch, true)
        promotionServices.addPromotion(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: promotionConstants.ADD_PROMOTION_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.promotion_was_created'));
                        } else {
                            let resmessage = `${lodash.get(response, 'message')}`
                            if (!response.unAlert) {
                                if (resmessage === "BAD_REQUEST" || resmessage === "CREATE_PROMOTION_FAILED") {
                                    let resData = lodash.join(lodash.get(response, 'data'), ',')
                                    if(resData === "[referenceCode] is duplicated"){
                                        resData = 'Duplicated Reference Code'
                                        customAlert(dispatch, 'error', "", false, false, resData)
                                    }else{
                                        customAlert(dispatch, 'error', resmessage, false, false, resData)  
                                    }
                                }
                            } else {
                                customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                            }
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }

}

function updatePromotion(params) {
    return dispatch => {
        loader(dispatch, true)
        promotionServices.updatePromotion(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: promotionConstants.EDIT_PROMOTION_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.promotion_was_updated'));
                        } else {
                            let resmessage = `${lodash.get(response, 'message')}`
                            if (!response.unAlert) {
                                if (resmessage === "BAD_REQUEST" || resmessage === "UPDATE_PROMOTION_FAILED") {
                                    let resData = lodash.join(lodash.get(response, 'data'), ',')
                                    if(resData === "[referenceCode] is duplicated"){
                                        resData = 'Duplicated Reference Code'
                                        customAlert(dispatch, 'error', "", false, false, resData)
                                    }else{
                                        customAlert(dispatch, 'error', resmessage, false, false, resData)  
                                    }
                                }
                            } else {
                                customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                            }
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getPromotionById(params) {
    return dispatch => {
        loader(dispatch, true)
        promotionServices.getPromotionById(params)
            .then(
                response => {
                    if (response.status) {
                        let conditions = lodash.get(response, 'data.conditions')
                        let conditionTypes = []
                        lodash.forEach(conditions, (value) => {
                            conditionTypes.push(lodash.get(value, 'type'))
                        })
                        lodash.uniq(conditionTypes)

                        let rewards = lodash.get(response, 'data.rewards')
                        let rewardTypes = []
                        lodash.forEach(rewards, (value) => {
                            rewardTypes.push(lodash.get(value, 'type'))
                        })
                        lodash.uniq(rewardTypes)

                        const allShopList = lodash.get(response,"data.shopCondition.shopList",[])
                        let regionSelected = lodash.map(allShopList,(value)=>{
                            return value.shopRegionId
                        })
                        regionSelected = lodash.uniq(regionSelected)

                        if(lodash.get(response,"data.shopCondition.shopList",[])){
                            dispatch({ type: 'OPEN_CONDITION_SHOP'})
                        }
                        let formInitialValues = {
                            ...response.data,
                            hasCondition: (lodash.size(conditions) > 0) ? "true" : "false",
                            conditionTypes: conditionTypes,
                            rewardTypes: rewardTypes,
                            regionSelected:regionSelected,
                            allShopListSelected:allShopList
                        }
                        let specificDateTime = lodash.get(response, 'data.specific_date_time')
                        if(lodash.get(specificDateTime,'day.type') === 'everyday' || lodash.size(lodash.get(specificDateTime,'day.date_list')) > 0){
                            dispatch({ type: 'OPEN_CONDITION_TIME'})
                            formInitialValues.customCheckboxDate = true
                        }else{
                            dispatch({ type: 'CLOSE_CONDITION_TIME'})
                            formInitialValues.customCheckboxDate = false
                        }

                        let spDateTimeType = lodash.get(specificDateTime,'day.type')
                        if(spDateTimeType === 'selected_day') {
                            formInitialValues.customRadioParticipatedDay = 'specific'
                        } else if (spDateTimeType === 'everyday') {
                            formInitialValues.customRadioParticipatedDay = 'everyday'
                        }
                        formInitialValues.customRadioSelectedDay = lodash.get(specificDateTime,'day.selected') === 'date' ? 'selectedDate' : (lodash.get(specificDateTime,'day.selected') === 'day_of_week' ? 'dayOfWeek' : "")

                        if((lodash.get(specificDateTime,'day.selected') === 'day_of_week')){
                            formInitialValues = {
                                ...formInitialValues,
                                "selectedDate":  {
                                    "mon": lodash.includes(specificDateTime.day.date_list,'mon'),
                                    "tue": lodash.includes(specificDateTime.day.date_list,'tue'),
                                    "wed": lodash.includes(specificDateTime.day.date_list,'wed'),
                                    "thu": lodash.includes(specificDateTime.day.date_list,'thu'),
                                    "fri": lodash.includes(specificDateTime.day.date_list,'fri'),
                                    "sat": lodash.includes(specificDateTime.day.date_list,'sat'),
                                    "sun": lodash.includes(specificDateTime.day.date_list,'sun'),
                                    }
                            }
                        }
                        else if(lodash.get(specificDateTime,'day.selected') === 'date'){
                            formInitialValues.date = lodash.get(specificDateTime,'day.date_list')
                        }
                        if(lodash.get(specificDateTime,'time.startTime') || lodash.get(specificDateTime,'time.endTime')){
                            dispatch({ type: 'OPEN_CONDITION_TIME'})
                            formInitialValues.customCheckboxSpecificTime = true
                            formInitialValues.startTime = lodash.get(specificDateTime,'time.startTime')
                            formInitialValues.endTime = lodash.get(specificDateTime,'time.endTime')
                        }else{
                            dispatch({ type: 'CLOSE_CONDITION_TIME'})
                        }
                        dispatch(success(formInitialValues));

                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(data) { return { type: promotionConstants.OPEN_EDIT_PROMOTION, data: data } }
}

function getTablePromotionDatas(params) {
    return dispatch => {
        loader(dispatch, true)
        promotionServices.getTablePromotionDatas(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(data) { return { type: promotionConstants.TABLE_GET_LIST_PROMOTION, data } }
}

function deletePromotion(params) {
    return dispatch => {
        loader(dispatch, true)
        promotionServices.deletePromotion(params)
            .then(
                tmp => {
                    if (tmp.status) {
                        dispatch({ type: promotionConstants.DELETE_PROMOTION_SUCCESS });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.promotion_was_deleted'));
                    } else {
                        if (!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getListsProduct(params) {
    return dispatch => {
        loader(dispatch, true)
        productServices.getListsProduct(params)
            .then(
                response => {
                    if (response.status) {
                        if(lodash.has(response, 'data.products')) {
                            let formattedData = []
                            response.data.products.map(pdData => {
                                formattedData.push({
                                    value: lodash.get(pdData, 'skuId'),
                                    label: lodash.get(pdData, 'name')
                                })
                            })
                            dispatch(success(formattedData));
                        }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: promotionConstants.GET_LIST_PRODUCT, tmp } }
}

function getListsCategory(params) {
    return dispatch => {
        loader(dispatch, true)
        categoryServices.getListsCategory(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: promotionConstants.GET_LIST_CATEGORY, tmp } }
}

function getRegionList() {
    return (dispatch) => {
        promotionServices.getRegionLists().then(
        (response) => {
          if (response.status) {
            dispatch(success(response.data.shopRegions))
          } else {
            if (!response.unAlert)
              customAlert(
                dispatch,
                "error",
                i18n.t(`api_messages.${response.message.toString()}`)
              )
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
    }
  
    function success(data) {
      return { type: promotionConstants.PROMOTION_GET_REGION_LIST, data }
    }
}

function getAllShopList(params, shopListSelected) {
    return (dispatch) => {
        promotionServices.getShopListsByRegion(params).then(
        (response) => {
          if (response.status) {
              const isArrayFormat = Array.isArray(response.data)
              let formatData = []
              if(isArrayFormat){
                 formatData = lodash.map(response.data,((data,index)=>{
                    return data.shopLists
                  }))
                  formatData = lodash.flatten(formatData)
              }else{
                formatData=[...lodash.get(response.data, "shopLists")] 
              }
              //remove shopList Has Selected
              let shopListRemovedSelected = lodash.filter(formatData,(data,index)=>{
                  return !lodash.find(shopListSelected,{_id:data._id})
              })
              shopListRemovedSelected = shopListRemovedSelected.map(data=>{
                  return {
                      ...data,
                      shopRegionId:data.shopRegionId?data.shopRegionId:'not_specified'
                  }
              })
              
            const formData = {
                shopListSelected,
                shopList:shopListRemovedSelected
            }
            dispatch(success(formData))
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
    }
   
  
    function success(data) {
      return { type: promotionConstants.PROMOTION_GET_SHOP_LIST, data }
    }
}

function getTablePromotionReportDatas(params, tableReset = false){
    return dispatch => {
        loader(dispatch, true);
        promotionServices.getTablePromotionReportDatas(params)
            .then(
                response => {
                        if (response.status) {
                            dispatch({
                                type: promotionConstants.GET_TABLE_LIST_PROMOTION_REPORT,
                                data: response.data.data,
                                total: response.data.total,
                                tableFilter: params
                              });
                              if (tableReset) {
                                dispatch({ type: promotionConstants.PROMOTION_REPORT_TABLE_RESET });
                              }
                        } else {
                            if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false);
            })
    };
}

function getNamePromotion() {
    return dispatch => {
        loader(dispatch, true)
        promotionServices.getPromotionLists()
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(data) { return { type: promotionConstants.GET_LIST_NAME_PROMOTION, data } }
}
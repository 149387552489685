import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import _ from 'lodash'

import withStyles from "@material-ui/core/styles/withStyles";
import NoEncryptionOutlinedIcon from '@material-ui/icons/NoEncryptionOutlined';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import errorPageStyles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.jsx";

import { authActions } from 'actions';

class LoginBypass extends React.Component {
   constructor(props) {
      super(props);
      // we use this to make the card to appear after the page has been rendered

      this.props.dispatch(authActions.logout());
   }

   componentDidMount() {
      let hash = _.get(this.props.location, 'hash')
      hash = hash.split('=')

      let parameters = this.props.location.search.substring(1)

      let redirect = ""
      let source = ""
      if (parameters !== "") {
         parameters = JSON.parse('{"' + decodeURI(parameters).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
         redirect = _.get(parameters, 'redirect', '')
         source = _.get(parameters, 'source', '')
      }

      let token = (hash[0] === '#token_') ? hash[1] : ""

      this.props.dispatch(authActions.LoginBypass(token, redirect, source));

   }

   render() {
      const { classes, isFail } = this.props;
      return (
         <div className={classes.contentCenter}>
            {isFail ?
               <GridContainer>
                  <GridItem md={12}>
                     <h1 className={classes.title}>
                        <NoEncryptionOutlinedIcon style={{ width: "10em", height: "10em" }} />
                     </h1>
                     <h2 className={classes.subTitle}>Error 401: Unauthorized</h2>
                     <h4 className={classes.description}>You are not authorized access the site.</h4>
                  </GridItem>
               </GridContainer>
               : null}
         </div>
      );
      // }

   }
}

LoginBypass.propTypes = {
   classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
   const { isFail } = state.authentication;
   return {
      isFail
   };
}

const connectApp = connect(mapStateToProps)(withStyles(errorPageStyles)(LoginBypass));

export { connectApp as LoginBypass }

import validator from 'validator';
import moment from 'moment';
import lodash, { isObject } from 'lodash';
import { reset, untouch, change } from 'redux-form';
import { store, alert } from './';
import i18n from 'i18n';

export const crmValidator = {
    validateByRulesList,
    focusFirstInvalid,
    isRuleValid,
    isEmptyValue,
    resetForm,
    alertFirstInvalid,
    handleKeyPress,
    validateRequiredImage,
    validateImageFormat,
    convertObjectKey,
    alertFirstInvalidByErrorObject
}

function resetForm(FORM_NAME, dispatch, pristine = true) {
    if (pristine) {
        var reduxStore = store.getState();
        var formMeta = reduxStore.form[FORM_NAME];
        var registeredFields = formMeta.registeredFields;
        if (typeof (registeredFields) === 'object') {
            for (const [input_name] of Object.entries(registeredFields)) {
                dispatch(untouch(FORM_NAME, input_name));
                dispatch(change(FORM_NAME, input_name, ""));
            }
        } else {
            return;

        }
    } else {
        dispatch(reset(FORM_NAME));

    }

}

function isRuleValid(rule, condition, input_value, form_values = undefined) {
    if (rule === 'required' && condition !== false) {
        if (typeof input_value === "string") {
            input_value = input_value.trim()
        }

        if (typeof input_value === "object") {
            return (lodash.size(input_value) > 0);
        } else {
            return !(input_value === undefined || input_value === '' || input_value === null);
        }
    } else {
        if (rule === "requiredLinkout" && (condition !== undefined)) {
            let showLinkOut = lodash.get(form_values, 'showLinkOut')
            return !((input_value === undefined || input_value === '' || input_value === null) && showLinkOut);

        } else if (isEmptyValue(input_value) && rule !== "requiredLinkout" && rule !== "requiredRelateTo" && rule !== "equalTo") {
            return true;

        } else if (rule === 'isEngLetter' && condition !== undefined) {
            let regex = /^[A-Za-z0-9, ]+$/g;
            return regex.test(input_value)

        } else if (rule === 'isThai' && condition !== undefined) {
            let regex = /^[0-9ก-๎, ]+$/g;
            return regex.test(input_value)

        } else if (rule === 'number' && condition !== undefined) {
            let regex = /^\d+$/;
            return regex.test(input_value);

        } else if (rule === 'maxLength' && condition !== undefined) {
            return input_value.length <= parseInt(condition);

        } else if (rule === 'minLength' && condition !== undefined) {
            return input_value.length >= parseInt(condition);

        } else if (rule === 'noSpecialCharacters' && condition !== undefined) {
            let special_character_regex = new RegExp(/^[A-Za-z0-9ก-๙ ]+$/g);
            return special_character_regex.test(input_value);

        } else if (rule === 'referenceCodeCharacters' && condition !== undefined) {
            let regex = new RegExp(/^[A-Za-z0-9.-_]+$/g);
            return regex.test(input_value);

        } else if (rule === 'isMobilePhone') {
            //https://th.wikipedia.org/wiki/หมายเลขโทรศัพท์ในประเทศไทย 
            let thai_mobile_regex = new RegExp(/^0[6,8,9][0-9]{8}/g); //begin with 06, 08, 09 follow by number 8 char
            return validator.isMobilePhone(input_value, ['th-TH']) && thai_mobile_regex.test(input_value);

        } else if (rule === 'isEmail' && condition !== false) {
            return validator.isEmail(input_value);

        } else if (rule === 'isInt' && condition !== false) {
            return validator.isInt(input_value);

        } else if (rule === 'isFloat' && condition !== false) {
            return validator.isFloat(input_value);

        } else if (rule === 'equalTo') {
            return input_value === form_values[condition];

        } else if (rule === 'maxDate' && (condition !== false) && (condition !== undefined)) {
            return moment(input_value) < moment(condition);

        } else if (rule === "thaiID" && (condition !== false) && (condition !== undefined)) {
            return checkThaiID(input_value);

        } else if (rule === "isAllLetter" && (condition !== false) && (condition !== undefined)) {
            var letters = /^[A-Za-zก-๎]+$/;
            input_value = input_value.toString()
            if (input_value !== null && input_value !== "") {
                input_value = input_value.replace(/\s/g, "");
                return input_value.match(letters) != null;

            } else {
                return true;

            }

        } else if (rule === "passwordStrength" && (condition !== false) && (condition !== undefined)) {
            // 1 Upper, 1 lower, 1 specialchar and 1 number and morethan 8 char
            var regex_pwd_str = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/g);
            return regex_pwd_str.test(input_value);

        } else if (rule === "min" && (condition !== undefined)) {
            try {
                return (parseFloat(input_value) >= parseFloat(condition))
            } catch (e) {
                return true;
            }

        } else if (rule === "fileSize" && (condition !== undefined)) {
            try {
                if (input_value.length > 0) {
                    return (parseInt(input_value[0].size) <= parseInt(condition))
                } else {
                    return true;
                }
            } catch (e) {
                return true
            }
        } else if (rule === "isURL" && (condition !== undefined)) {
            return validator.isURL(input_value, {
                protocols: ['http', 'https'],
                require_protocol: true
            })

        } else if (rule === "requiredRelateTo" && condition !== undefined) {
            let anotherFieldValue = lodash.get(form_values, condition)
            if (!anotherFieldValue) {
                return true
            }
            return (anotherFieldValue && input_value)
        } else if (rule === 'version' && condition !== undefined) {
            let regex = /[0-9.]/;
            return regex.test(input_value);

        }
        else if (rule === 'sameDateTime' && condition !== undefined) {
            let startDate = lodash.get(form_values, 'startDate')
            let endDate = lodash.get(form_values, 'endDate')

            if (moment(startDate).isSame(endDate)) {
                return false
            } else {
                return true
            }
        }
        else if (rule === 'requireStartDate' && condition !== undefined) {
            let startDate = lodash.get(form_values, 'startDate')
            if (lodash.isUndefined(startDate)) {
                return false
            } else {
                return true
            }

        } else if (rule === 'isSlugUrl' && condition !== undefined) {
            let lettersAllow = /^[A-Za-z0-9-_]+$/;
            return input_value.match(lettersAllow) != null
        }
        else {
            return true;

        }
    }

}

function checkThaiID(id) {
    if (id) {
        id = id.replace(/-/g, "");
    } else {
        return false;

    }

    if (id.length !== 13) {
        return false;

    }
    for (var i = 0, sum = 0; i < 12; i++) {
        sum += parseFloat(id.charAt(i)) * (13 - i);
    }
    if ((11 - sum % 11) % 10 !== parseFloat(id.charAt(12))) {
        return false;

    }
    return true;

}

function validateByRulesList(rules_list, form_values) {
    let errors = {};
    if (typeof (rules_list) === 'object') {
        for (const [input_name, validate_condition] of Object.entries(rules_list)) {
            let rules = validate_condition.rules;
            let input_value = lodash.get(form_values, input_name);

            if (typeof rules === 'object') {
                for (const [rule_key, rule_condition] of Object.entries(rules)) {
                    if (!isRuleValid(rule_key, rule_condition, input_value, form_values)) {
                        let message = validate_condition.messages[rule_key];
                        if (message === undefined) {
                            message = 'invalid_data';
                        }

                        lodash.set(errors, input_name, message);
                        break;

                    }

                }
            }
        }
        return errors;

    } else {
        return {};

    }

}

function focusFirstInvalid(formName, errors) {
    let element
    let elementName = Object.keys(errors)[0];
    element = document.querySelector(`form[name=${formName}] input[name=${elementName}]`);
    element.focus();

}

async function convertObjectKey(obj, mock, ck) {
    ck = ck || "";
    mock = mock || {}
    obj = obj || {}

    await Object.entries(obj).forEach(([key, val]) => {
        let keyName = (ck === "") ? key : ck + `[${key}]`
        if (typeof val === 'object') {
            convertObjectKey(val, mock, keyName)
        } else {
            Object.assign(mock, {
                [keyName]: val
            });
        }
    })

    return mock;
}

function alertFirstInvalidByErrorObject(errors) {
    const defaultTxt = 'invalid_data'

    for (const key in errors) {
        if (isObject(errors[key])) {
            alertFirstInvalidByErrorObject(errors[key])
        } else {
            alert(store.dispatch, 'error', i18n.t(`${errors[key] || defaultTxt}`));
            return false
        }
    }
}

async function alertFirstInvalid(FORM_NAME, errors, validate, message) {
    const defaultTxt = 'invalid_data'
    let globalStore = store.getState();
    let formMeta = globalStore.form[FORM_NAME];

    for (const key of Object.keys(validate)) {
        if (isEmptyValue(lodash.get(formMeta.values, key)) && formMeta.registeredFields[key] !== undefined && (validate[key].rules.required === true || validate[key].rules.requiredLinkout === true)) {
            alert(store.dispatch, 'error', i18n.t(`${validate[key].alert === undefined ? defaultTxt : validate[key].alert}`));
            return false
        } else if (formMeta.values === undefined) {
            alert(store.dispatch, 'error', i18n.t(`${message === undefined ? defaultTxt : message}`));
            return false
        } else {
            continue;
        }
    }

    const messageErr = await convertObjectKey(errors)
    const regQuota = new RegExp(/^quota\[.*/g)
    for (const keyErr of Object.keys(messageErr)) {
        if (formMeta.registeredFields[keyErr] !== undefined && regQuota.test(keyErr)) {
            alert(store.dispatch, 'error', i18n.t(`Please check quota value`));
            return false
        }

        if (formMeta.registeredFields[keyErr] !== undefined) {
            let promotionCodeValidator = [
                'purchase_product_validator',
                'purchase_amount_per_bill_validator',
                'payment_channel_validator',
                'promotion_code_validator',
                'free_product_validator',
                'discount_validator',
                'special_price_validator',
                'skuOrCategory_validator'
            ]
            if (lodash.indexOf(promotionCodeValidator, keyErr) >= 0) {
                alert(store.dispatch, 'error', messageErr[keyErr]);

            } else {
                alert(store.dispatch, 'error', i18n.t(`${messageErr[keyErr] || defaultTxt}`));
            }
            console.log('isError --> 2')
            return false
        } else {
            continue;
        }

    }

}

function isEmptyValue(value) {
    return (value === undefined) || (value === "") || (value === null) ||
        (lodash.isEmpty(value) && !validator.isDecimal(`${value}`))
}

function handleKeyPress(value, rule_condition, currentData) {
    if (!isEmptyValue(value) && lodash.has(rule_condition, 'rules')) {
        if (rule_condition.rules.number) {
            if (!validator.isInt(value)) {
                value = value.replace(/[^\d]/g, '')
            }

            if (rule_condition.rules.isInteger) {
                value = parseInt(value)
            }
            value = isNaN(value) ? "" : value
        }

        if (rule_condition.rules.decimal) {
            let decimalLength = value.toString().split(".")[1]
            decimalLength = decimalLength ? decimalLength.length : 0
            let decimalDigits = lodash.get(rule_condition, 'rules.decimalDigits') || 2
            if (!validator.isDecimal(value + '0') || decimalLength > decimalDigits) {
                value = currentData
            }
            if (value) {
                value = value.toString().split('.')
                value[0] = parseInt(value[0])
                value = isNaN(value.join('.')) ? "" : value.join('.')
            }
        }

        if (rule_condition.rules.maxLength) {
            if (value.length > parseInt(rule_condition.rules.maxLength)) {
                value = value.substr(0, rule_condition.rules.maxLength);
            }
        }

        // if (rule_condition.rules.noSpecialCharacters) {
        //    let special_character_regex = new RegExp(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/g);
        //    if (!special_character_regex.test(value)) {
        //       // value = value.replace(/[&\/\\@#,+()$~%.'":*!?<>{}]/g, '')
        //       value = value.replace(/[^a-zA-Z0-9 ]/g, "");
        //    }
        // }

        if (rule_condition.rules.noSpecialCharacters) {
            let special_character_regex = new RegExp(/[ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]/g);
            if (special_character_regex.test(value)) {
                value = value.replace(/[ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]/g, "");
            }
        }

        if (rule_condition.rules.version) {
            let version_regex = new RegExp(/[a-zA-zก-๙ !"#$%&'()*+,/:;<=>?@[\]\\^_`{|}~-]/g);
            if (version_regex.test(value)) {
                value = value.replace(/[a-zA-zก-๙ !"#$%&'()*+,/:;<=>?@[\]\\^_`{|}~-]/g, "")
            }

        }

        if (rule_condition.rules.referenceCodeCharacters) {
            let reference_code_character_regex = new RegExp(/[ ก-๙!"#$%&'()*+,/:;<=>?@[\]\\^`{|}~]/g);
            if (reference_code_character_regex.test(value)) {
                value = value.replace(/[ ก-๙!"#$%&'()*+,/:;<=>?@[\]\\^`{|}~]/g, "");
            }
        }

    }

    return value

}

function validateRequiredImage(imageFile) {
    if (imageFile === null || imageFile === undefined || imageFile === "") {
        return i18n.t('warning_messages.please_upload_file')
    }
}

function validateImageFormat(imageFile, validMimeType) {
    if (imageFile && !validMimeType.includes(imageFile.type)) {
        return i18n.t('warning_messages.invalid_file_extention');
    }
}
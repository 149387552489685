import React from "react";
import { connect } from 'react-redux';
import { change } from 'redux-form';
import i18n from 'i18n';

import MUIDataTable from "mui-datatables";
import lodash from 'lodash';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import PieChartIcon from '@material-ui/icons/PieChart';

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import { dataFilteringResultConstants, swalConstants } from '../../constants';
import { dataFilteringResultAction } from '../../actions'
import { plugins, crmValidator } from 'helpers';

import FormDataFilteringCondition from 'views/DataFilteringResult/FormPopup/FormCondition';
import FormDataFilteringAssignCampaign from 'views/DataFilteringResult/FormPopup/FormAssignCampaign';
import FormDataFilteringAssignTag from 'views/DataFilteringResult/FormPopup/FormAssignTag';
import FormDataFilteringUnAssign from 'views/DataFilteringResult/FormPopup/FormUnAssign';
import FormDataFilteringVisualization from 'views/DataFilteringResult/FormPopup/FormVisualization';

import TableLoaderSpinner from 'components/Table/TableLoaderSpinner';
import Badge from "components/Badge/Badge.jsx";

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const CustomMTablestyles = {
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MUIDataTableHeadCell: {
            fixedHeader: {
                "&:nth-child(1)": {
                    width: "50%"
                },
            }
        }
    }
}

const moduleName = 'dataFilteringResult';

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "Active": BadgeColor = "success"; break;
        case "Expired": BadgeColor = "danger"; break;
        default: BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}

const pageStyle = { ...style, ...sweetAlertStyle };
const THEME = createMuiTheme(CustomMTablestyles);

class TableDataFilteringResultList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loaderSpiner: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        }
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(dataFilteringResultAction.getDataFilteringResultList())
    }


    openConditionForm(rowIndex) {
        const { dispatch } = this.props;
        const rowData = this.getRowData(rowIndex)

        console.log('rowData :',rowData);
        dispatch(dataFilteringResultAction.getDataFilteringConditionById(rowData.conditionId))
        // dispatch({ type: dataFilteringTemplateConstants.OPEN_INFO, formInitialValue: { ...rowData } })
    }

    openAssignCampaignForm(rowIndex) {
        const { dispatch } = this.props;
        const rowData = this.getRowData(rowIndex)

        const params = {
            conditionId:rowData.conditionId,
            resultId:rowData._id,
        }

        console.log('params :',params);

        dispatch({ type: dataFilteringResultConstants.OPEN_FORM_ASSIGN_CAMPAIGN, formInitialValue: { ...params } })
    }

    openAssignTagForm(rowIndex) {
        const { dispatch } = this.props;
        const rowData = this.getRowData(rowIndex)

        const params = {
            conditionId:rowData.conditionId,
            resultId:rowData._id,
        }

        dispatch({ type: dataFilteringResultConstants.OPEN_FORM_ASSIGN_TAG, formInitialValue: { ...params } })
    }

    openUnAssignForm(rowIndex) {
        const { dispatch } = this.props;
        const rowData = this.getRowData(rowIndex)
        dispatch(dataFilteringResultAction.getDataFilteringFromDataPlatformByResultId(rowData._id))
    }

    openVisualForm(rowIndex) {
        const { dispatch } = this.props;
        const rowData = this.getRowData(rowIndex)

        const params = {
            filename:rowData.report,
            path:"data-filltering-config"
        }

        dispatch(dataFilteringResultAction.getDataFilteringFromDataPlatformByFilename(params))
        // console.log('rowData :',rowData);
        // dispatch({ type: dataFilteringResultConstants.OPEN_FORM_VISUALIZATION})
    }

    tableColumn() {
        const { classes, dispatch } = this.props;

        let columns = [
            { name: "_id", label: '_id', options: { filter: false, display: "excluded" } },
            { name: "report", label: "Report" },
            { name: "requestedby", label: "Requested by" },
            { name: "requestedDate", label: "Requested date" },
            { name: "expiredAt", label: "Expired at" },
            { name: "fileSize", label: "File size" },
            { name: "recorde", label: "Records" },
            // { name: "unassign", label: "Unassign" },
        ]

        columns.push({
            label: "Report Condition", options: {
               filter: false,
               sort: false,
               customBodyRender: (value,tableMeta) => {
                  const rowData =this.getRowData(tableMeta.rowIndex)
                  return (
                     <div className={classes.actionDiv}>
                        <IconButton color="primary">
                            <SearchIcon 
                                onClick={(e) => {
                                    this.openConditionForm(tableMeta.rowIndex)
                                }}
                            />
                        </IconButton>
                     </div>
                  )
               }
            }
        })

        columns.push({
            label: "Campaign", options: {
               filter: false,
               sort: false,
               customBodyRender: (value,tableMeta) => {
                  return (
                     <div className={classes.actionDiv}>
                        <Button
                            type="button"
                            style={{ marginRight: "10px" }}
                            onClick={(e) => {
                                this.openAssignCampaignForm(tableMeta.rowIndex)
                            }}
                            color="primary">
                            Assign
                        </Button>
                     </div>
                  )
               }
            }
        })
        
        columns.push({
            label: "Tag", options: {
               filter: false,
               sort: false,
               customBodyRender: (value,tableMeta) => {
                  return (
                     <div className={classes.actionDiv}>
                        <Button
                            type="button"
                            style={{ marginRight: "10px" }}
                            onClick={(e) => {
                                this.openAssignTagForm(tableMeta.rowIndex)
                            }}
                            color="primary">
                            Assign
                        </Button>
                     </div>
                  )
               }
            }
        })

        columns.push({
            label: "Unassign", options: {
               filter: false,
               sort: false,
               customBodyRender: (value,tableMeta) => {
                  return (
                     <div className={classes.actionDiv}>
                        <Button
                            type="button"
                            style={{ marginRight: "10px" }}
                            onClick={(e) => {
                                this.openUnAssignForm(tableMeta.rowIndex)
                            }}
                        >
                            Unassign
                        </Button>
                     </div>
                  )
               }
            }
        })

        columns.push({
            label: "Visuallization", options: {
               filter: false,
               sort: false,
               customBodyRender: (value,tableMeta) => {
                  return (
                     <div className={classes.actionDiv}>
                        <IconButton color="primary"
                        onClick={(e) => {
                            this.openVisualForm(tableMeta.rowIndex)
                        }}>
                            <PieChartIcon />
                        </IconButton>
                     </div>
                  )
               }
            }
        })

        columns.push({
            label: "Delete", options: {
               filter: false,
               sort: false,
               customBodyRender: (value,tableMeta) => {
                  return (
                     <div className={classes.actionDiv}>
                        <TableActionButton
                            actionType="delete"
                            onClick={(e) => {
                                const rowData = this.getRowData(tableMeta.rowIndex);
                                dispatch({
                                type: swalConstants.SHOW, data: {
                                    type: "warning",
                                    title: "are you sure delete ?",
                                    message: `"${rowData.report}"`,
                                    onConfirm: function () {
                                        let reqParams = {
                                            resultId: rowData._id,
                                            conditionId: rowData.conditionId,
                                            reportName: rowData.report
                                        }
                                        dispatch({ type: swalConstants.HIDE })
                                        dispatch(dataFilteringResultAction.deleteDataFilteringResult(reqParams));
                                    },
                                    onCancel: function () {
                                        dispatch({ type: swalConstants.HIDE })
                                    },
                                    showCancel: true,
                                    confirmBtnText: i18n.t("delete"),
                                    cancelBtnText: i18n.t("cancel")
                                }
                                })
                            }} 
                        />
                     </div>
                  )
               }
            }
        })

        return columns
    }

    tableOptions() {
        const { tableDatas } = this.props;
        const _this = this
        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: _this.state.page,
            rowsPerPage: _this.state.rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    _this.getData(skip, limit, sortBy, sortDir, keyword)
                }

            }
        }
        
        return options
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(dataFilteringResultAction.getDataFilteringResultList(reqParams));

        this.setState({
            reqParams: reqParams
        });

    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.dataFilteringResult;
        if (lodash.size(data) > 0) {
            return data[rowIndex];
        } else {
            return null
        }
    }


    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: dataFilteringResultConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    render() {
        const { tableDatas, showLoader, tableKey, formInitialValue } = this.props;

        console.log('tableDatas :',tableDatas);
        return (
            <div id="TableDataFilteringResultList">
                <CustomSweetAlert />

                <FormDataFilteringCondition
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                    initialValues={formInitialValue} />

                <FormDataFilteringAssignCampaign
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                    initialValues={formInitialValue} />

                <FormDataFilteringAssignTag
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                    initialValues={formInitialValue} />

                <FormDataFilteringUnAssign
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                    initialValues={formInitialValue} />

                <FormDataFilteringVisualization
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                />
                    
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        title={"DataFiltering Result"}
                        data={tableDatas.dataFilteringResult}
                        columns={this.tableColumn()}
                        options={this.tableOptions()}
                    />
                    {showLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { tableDatas, tableReload, showLoader, tableKey, tableReset, formInitialValue } = state.dataFilteringResult;
    // const { permission, superAdmin } = state.authentication;
    return {
        tableDatas, tableReload, showLoader, tableKey, tableReset, formInitialValue
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableDataFilteringResultList));


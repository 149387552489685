import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableLevelList from "views/MemberLevel/TableLevelList"
import NavPills from "components/NavPills/NavPills.jsx";
import AutoLevel from "views/MemberLevel/AutoLevel.jsx"
import { plugins } from 'helpers';

const pageStyle = {}

class LevelContainer extends React.Component {
 
setTab = () => {
  let  tabLists = []
  if(plugins.isHasPermission("member_level[member_level_list]", 'read')){
    tabLists.push({
        id: "table-member-level-list",
        tabButton: "Memberlevel List",
        tabContent: <TableLevelList />
    })
  }
  if(plugins.isHasPermission("member_level[automatic_shifting_level]", 'read')){
    tabLists.push({
        id: "auto-member-level-setting",
        tabButton: "Automatic Shifting Level",
        tabContent: <AutoLevel />
    })
  }
    return tabLists
}
    render() {
        return (
            <div>
                <Card>
                    <CardBody>
                        <CustomSweetAlert />
                        {/* <TableLevelList /> */}
                        <NavPills
                            color="warning"
                            tabBtnType="square"
                            tabs={this.setTab()}
                        />
                    </CardBody>
                </Card>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { formOpen } = state.tag;
    return {
        formOpen
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(LevelContainer));
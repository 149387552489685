import React from "react"
import { connect } from "react-redux"
import { Field, reduxForm, getFormValues, change, touch } from "redux-form"

import i18n from "i18n"
import lodash from "lodash"

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles"

// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Typography from "@material-ui/core/Typography"

// core components
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx"
import CustomSelect from "components/CustomSelect/CustomSelect.jsx"

import { crmValidator, store, alert as customAlert } from "helpers"
import * as formValidate from "./validate/FormValidator"
import { shopConstants } from "../../constants"
import { normalize } from "helpers"
import { shopActions, brandAction } from "actions"

import GoogleMapReact from 'google-map-react';
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox"

const FORM_NAME = "FORM_SHOP_LIST"

const validate = (values) => {
  const errors = crmValidator.validateByRulesList(
    formValidate.FORM_VALIDATE,
    values
  )
  return errors
}

const Marker = ({ text }) => (
  <img src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png" width="40" height="40" />
);

const defaultProps = {
  center: {
    lat: 13.7621129,
    lng: 100.4940718
  },
  zoom: 11
};

class shopListForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      marker: {
        lat: null,
        lng: null
      }
    }
  }

  handleSubmit = (values) => {
    if (this.state.marker.lat && this.state.marker.lng)  {
      values.lat = this.state.marker.lat
      values.lng = this.state.marker.lng
    }

    const { dispatch, formMode } = this.props
    if (formMode === "add") {
      const regionId =
        lodash.get(values, "shopRegionId") === "notSpecified"
          ? ""
          : lodash.get(values, "shopRegionId")

      const params = {
        shopName: lodash.get(values, "shopName"),
        address: lodash.get(values, "address"),
        phoneNumber: lodash.get(values, "phoneNumber"),
        shopRemark: lodash.get(values, "shopRemark"),
        subDistrict: lodash.get(values, "subDistrict"),
        district: lodash.get(values, "district"),
        province: lodash.get(values, "province"),
        zipcode: lodash.get(values, "zipcode"),
        shopRegionId: regionId,
        status: lodash.get(values, "status"),
        shopId: lodash.get(values, "shopId"),
        brandId: lodash.get(values, "brandId"),
        openTime: lodash.get(values, "openTime"),
        isPickUp: lodash.get(values, "isPickUp"),
        closingTime: lodash.get(values, "closingTime"),
        lat: lodash.get(values, "lat"),
        lng: lodash.get(values, "lng"),
      }
      dispatch(shopActions.addShopList(params))
    } else {
      const regionId =
        lodash.get(values, "shopRegionId") === "notSpecified"
          ? ""
          : lodash.get(values, "shopRegionId")
      const params = {
        ...values,
        shopRegionId: regionId,
      }
      dispatch(shopActions.updateShopList(params))
    }
  }

 getListBrands = (brands) => {
  if (brands !== undefined) {
     return brands.map((brand) => {
        return {
           value: brand._id,
           label: brand.name
        }
     })
  }
  return []
}

componentDidMount () {
  const {
    dispatch
  } = this.props
  dispatch(brandAction.getBrandLists(''))
}

  render() {
    const {
      formOpen,
      formMode,
      formValues,
      classes,
      dispatch,
      handleSubmit,
      regionOptionList,
      FORM_SHOP_LIST
    } = this.props
    const statusOptionList = [
      { value: "Active", label: "Active" },
      { value: "Inactive", label: "Inactive" },
    ]
    const onClickMap = (e) => {
      formValues.lat = e.lat
      formValues.lng = e.lng
      this.setState({
        marker: Object.assign(this.state.marker, {
          lat: e.lat,
          lng: e.lng
        }
      )})
    }

    let _regionOptionList = lodash.map(regionOptionList, (data) => {
      return {
        label: data.shopRegionName,
        value: data._id,
      }
    })

    _regionOptionList = [
      { value: "notSpecified", label: "Not Specified" },
      ..._regionOptionList,
    ]

    let { readOnly } = this.props
    if (formMode === `add`) {
      readOnly = false
    } else if (formMode === `edit` && !readOnly) {
      readOnly = false
    } else {
      readOnly = true
    }
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={formOpen}
        keepMounted
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        fullWidth={true}
        maxWidth="sm"
        disableRestoreFocus={true}
        scroll="body"
      >
        <DialogTitle disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>
            <b>
              {formMode === "add"
                ? i18n.t("shop._add")
                : readOnly
                ? i18n.t("shop._info")
                : i18n.t("shop._edit")}
            </b>
          </h4>
        </DialogTitle>
        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
          <DialogContent
            id="shop-form-dialog"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {formMode === "edit" ? (
                  <Field name="_id" component="input" type="hidden" />
                ) : null}
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Field
                  id={`${FORM_NAME}_shopId`}
                  name="shopId"
                  component={CustomInput}
                  type="text"
                  labelText={i18n.t("label.shop_id")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                  normalize={normalize.onlyUpperCaseAndNumber}
                />
              </GridItem>
              <GridItem xs={12} sm={8} md={8}>
                <Field
                  id={`${FORM_NAME}_shopName`}
                  name="shopName"
                  component={CustomInput}
                  type="text"
                  labelText={i18n.t("label.shop_name")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Field
                  id={`${FORM_NAME}_phoneNumber`}
                  name="phoneNumber"
                  component={CustomInput}
                  type="text"
                  labelText="เบอร์ติดต่อ"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                  normalize={normalize.onlyUpperCaseAndNumber}
                />
              </GridItem>
              <GridItem xs={12} sm={8} md={8}>
                <Field
                  id={`${FORM_NAME}_shopRemark`}
                  name="shopRemark"
                  component={CustomInput}
                  type="text"
                  labelText="ข้อมูลเพิ่มเติม"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                />
              </GridItem>
            </GridContainer>

            <Typography
              style={{ marginTop: "10px" }}
              variant="body2"
              gutterBottom
            >
              Shop Location
            </Typography>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Field
                  id={`${FORM_NAME}_address`}
                  name="address"
                  component={CustomInput}
                  type="text"
                  labelText={i18n.t("label.address")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={6} sm={3} md={3}>
                <Field
                  id={`${FORM_NAME}_subDistrict`}
                  name="subDistrict"
                  component={CustomInput}
                  type="text"
                  labelText={i18n.t("label.sub_district")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={6} sm={3} md={3}>
                <Field
                  id={`${FORM_NAME}_district`}
                  name="district"
                  component={CustomInput}
                  type="text"
                  labelText={i18n.t("label.district")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={6} sm={3} md={3}>
                <Field
                  id={`${FORM_NAME}_province`}
                  name="province"
                  component={CustomInput}
                  type="text"
                  labelText={i18n.t("label.province")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={6} sm={3} md={3}>
                <Field
                  id={`${FORM_NAME}_zipcode`}
                  name="zipcode"
                  component={CustomInput}
                  type="text"
                  labelText={i18n.t("label.zip_code")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                  normalize={normalize.onlyNumberTypeString}
                />
              </GridItem>
            </GridContainer>

            <Typography
              style={{ marginTop: "10px" }}
              variant="body2"
              gutterBottom
            >
              Shop Status / Brand
            </Typography>

            <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
                <Field
                  id={`${FORM_NAME}_shopRegionId`}
                  name="shopRegionId"
                  component={CustomSelect}
                  type="text"
                  labelText={i18n.t("label.shop_region")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                  optionsList={_regionOptionList}
                />
              </GridItem>

              <GridItem xs={6} sm={4} md={4}>
                <Field
                  id={`${FORM_NAME}_status`}
                  name="status"
                  component={CustomSelect}
                  type="text"
                  labelText={i18n.t("label.status")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                  optionsList={statusOptionList}
                />
              </GridItem>

              <GridItem xs={12} sm={4} md={4}>
                <Field
                  id={`${FORM_NAME}_brand`}
                  name="brandId"
                  component={CustomSelect}
                  type="text"
                  labelText={i18n.t("label.brand")}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={readOnly}
                  optionsList={this.getListBrands(this.props.brands)}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Field
                  id="checkbox-ispickup"
                  name="isPickUp"
                  component={CustomCheckbox}
                  disabled={readOnly}
                  labelText={
                    <span style={{ fontSize: "16px", fontWeight: "300" }}>
                      PickUp
                    </span>
                  }
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  id={`${FORM_NAME}_openTime`}
                  name="openTime"
                  component={CustomInput}
                  className={classes.timeInput}
                  type="time"
                  labelText={"open time"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  id={`${FORM_NAME}_closingTime`}
                  name="closingTime"
                  component={CustomInput}
                  className={classes.timeInput}
                  type="time"
                  labelText={"closing time"}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  id={`${FORM_NAME}_lat`}
                  name="lat"
                  component={CustomInput}
                  type="text"
                  labelText={"latitude"}
                  onChange={(value) => {
                    formValues.lat = value.target.value;
                    this.setState({
                      marker: Object.assign(this.state.marker, {
                        lat: value.target.value,
                      }),
                    });
                  }}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  id={`${FORM_NAME}_lng`}
                  name="lng"
                  component={CustomInput}
                  type="type"
                  labelText={"longitude"}
                  onChange={(value) => {
                    formValues.lng = value.target.value;
                    this.setState({
                      marker: Object.assign(this.state.marker, {
                        lng: value.target.value,
                      }),
                    });
                  }}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <div style={{ height: "200px", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                    libraries: ["places"],
                  }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                  onClick={onClickMap}
                >
                  {formValues.lat && formValues.lng && (
                    <Marker lat={formValues.lat} lng={formValues.lng} />
                  )}
                </GoogleMapReact>
              </div>
            </GridContainer>
          </DialogContent>

          <DialogActions className={classes.modalFooter}>
            {!readOnly && (
              <Button
                id={`${FORM_NAME}_btn_submit`}
                type="submit"
                style={{ marginRight: "10px" }}
                color="primary"
              >
                {i18n.t("submit")}
              </Button>
            )}
            <Button
              id={`${FORM_NAME}_btn_cancel`}
              type="button"
              onClick={() => {
                dispatch({ type: shopConstants.CLOSE_FORM_SHOP });
              }}
              color="white"
            >
              {i18n.t("cancel")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const style = {
  ...modalStyle,
  timeInput: {
    width: '200px'
  }
}

function mapStateToProps(state) {
  const { formOpen, formMode, regionOptionList, formInitialValue } = state.shop
  const { FORM_SHOP_LIST } = state.form
  const { tableData: { brands } } = state.brand;

  const formValues = getFormValues(FORM_NAME)(state)

  return {
    formOpen,
    formMode,
    formValues,
    formInitialValue,
    regionOptionList,
    brands,
    FORM_SHOP_LIST,
  }
}

const connectShopListForm = connect(mapStateToProps)(shopListForm)

export default reduxForm({
  form: FORM_NAME,
  validate: validate,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, props) => {
    crmValidator.alertFirstInvalid(
      FORM_NAME,
      errors,
      formValidate.FORM_VALIDATE
    )
  },
})(withStyles(style)(connectShopListForm))

import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, reduxForm, reset } from "redux-form"
import lodash from "lodash"

import withStyles from "@material-ui/core/styles/withStyles"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import CustomSelect from "components/CustomSelect/CustomSelect.jsx"
import Button from "components/CustomButtons/Button.jsx"
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx"

import { trueMoneyReportActions } from "actions"
import TableTrueMoneyTransaction from "./TableTrueMoneyTransaction"

const FORM_NAME = "FORM_FILTER_TRUEMONEY_TRANSACTION_REPORT"
// const form_validate = {}

const style = {}

class TrueMoneyTransactionReport extends Component {
  handleSubmit(values) {
    const { dispatch, transactionReport } = this.props;
    const { tableFilter } = transactionReport
    let _tableFilter = {
        page: tableFilter.page,
        skip: tableFilter.skip,
        limit: tableFilter.limit,
        sortBy: tableFilter.sortBy,
        sortDir: tableFilter.sortDir,
    }
    _tableFilter = {
      ..._tableFilter,
      ...values
    }
    const resetTable = true
    dispatch(trueMoneyReportActions.getTrueMoneyTransactionReport(_tableFilter, resetTable));
  }

  componentDidMount() {
    const { dispatch, transactionReport } = this.props
    const { tableFilter} = transactionReport
    this.getCampaignList()
    dispatch(
      trueMoneyReportActions.getTrueMoneyTransactionReport(tableFilter, false)
    )
  }

  componentWillUnmount(){
    const { dispatch } = this.props;
    dispatch(reset(FORM_NAME))
    dispatch({ type: "CLEAR_TRUEMONEY_TRANSACTION_FILTER" })
  }

  getCampaignList(keyword) {
    const { dispatch } = this.props
    let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"
    if (keyword) {
      reqParams += `&keyword=${keyword}`
    }
    dispatch(trueMoneyReportActions.getTrueMoneyCampaignList(reqParams))
  }



  render() {

    const clearFilter = ()=>{
      const { dispatch } = this.props;
      dispatch(reset(FORM_NAME))
      dispatch({ type: "CLEAR_TRUEMONEY_TRANSACTION_FILTER" })
  
    };

    const {
      FORM_FILTER_TRUEMONEY_TRANSACTION_REPORT,
      // merchantSetting,
      handleSubmit,
      transactionReport,
    } = this.props
    const { campaignList } = transactionReport

    let startMaxDate = new Date();
    // let filterAgeOptions = merchantSetting.ageRangeList;

    if (lodash.has(FORM_FILTER_TRUEMONEY_TRANSACTION_REPORT, "values.endDate")) {
      startMaxDate = FORM_FILTER_TRUEMONEY_TRANSACTION_REPORT.values.endDate;
    }

    let endMinDate;
    if (lodash.has(FORM_FILTER_TRUEMONEY_TRANSACTION_REPORT, "values.startDate")) {
      endMinDate = FORM_FILTER_TRUEMONEY_TRANSACTION_REPORT.values.startDate;
    }


    let  campaignOptionList = [{
      value: "all",
      label: "All",
    },]

    if (lodash.has(transactionReport, "campaignList")) {
      campaignOptionList = [...campaignOptionList,...lodash.map(campaignList, (value) => {
        return {
          value: value._id,
          label: value.name,
        }
      })
    ]
    }

    return (
      <div>
        <form name={FORM_NAME}
         onSubmit={handleSubmit(this.handleSubmit.bind(this))}
         >
          <GridContainer>
            <GridItem xs={6} sm={6} md={4} lg={4}>
              <Field
                name="startDate"
                component={CustomDatePicker}
                type="text"
                maxDate={startMaxDate}
                labelText="Start Date"
                dateFormat="DD-MMM-YY"
                timeFormat="HH:mm:ss"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={4} lg={4}>
              <Field
                name="endDate"
                minDate={endMinDate}
                maxDate={new Date()}
                component={CustomDatePicker}
                type="text"
                labelText="End Date"
                dateFormat="DD-MMM-YY"
                timeFormat="HH:mm:ss"
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={4} lg={4}>
              <Field
                name="campaignId"
                component={CustomSelect}
                type="text"
                labelText="Campaign"
                formControlProps={{
                  fullWidth: true,
                }}
                novalidate
                optionsList={campaignOptionList}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={4} lg={4}>
              <Field
                name="actionType"
                component={CustomSelect}
                type="text"
                labelText="Action Type"
                formControlProps={{
                  fullWidth: true,
                }}
                novalidate
                optionsList={[
                  {
                    label: "All",
                    value: "all",
                  },
                  {
                    label: "Verify",
                    value: "verify",
                  },
                  {
                    label: "Topup",
                    value: "notify",
                  },
                ]}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={4} lg={4}>
              <Field
                name="status"
                component={CustomSelect}
                type="text"
                labelText="Status"
                formControlProps={{
                  fullWidth: true,
                }}
                novalidate
                optionsList={[
                  {
                    label: "All",
                    value: "all",
                  },
                  {
                    label: "Success",
                    value: "true",
                  },
                  {
                    label: "Failed",
                    value: "false",
                  },
                ]}
              />
            </GridItem>

            <GridItem xs={6} sm={6} md={4} lg={4}>
              <Button
                onClick={clearFilter}
                style={{
                  marginTop: "25px",
                }}
                size="sm"
                color="white"
              >
                Clear
              </Button>
              <Button
                type="submit"
                style={{
                  marginRight: "10px",
                  marginTop: "25px",
                  marginLeft: "21px",
                }}
                size="sm"
                color="primary"
              >
                Submit
              </Button>
            </GridItem>
          </GridContainer>
        </form>
        <TableTrueMoneyTransaction />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { FORM_FILTER_TRUEMONEY_TRANSACTION_REPORT } = state.form
  const { transactionReport } = state.tmnReport
  return {
    FORM_FILTER_TRUEMONEY_TRANSACTION_REPORT,
    transactionReport,
  }
}

const connectedComponent = connect(mapStateToProps)(TrueMoneyTransactionReport)

export default reduxForm({
  form: FORM_NAME,
})(withStyles(style)(connectedComponent))

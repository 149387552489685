import React from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash';
import i18n from 'i18n';
import { change } from 'redux-form';
// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// core components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
// import ShipmentForm from "views/Shipment/ShipmentForm.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner';
import BlacklistForm from 'views/Blacklist/BlacklistForm.jsx';
import BlacklistUploadForm from 'views/Blacklist/BlacklistUploadForm.jsx';

import { blacklistAction } from 'actions';
import { plugins, loader, crmValidator, alert as customAlert } from 'helpers';
import { blacklistConstants, swalConstants } from '../../constants';
import { blacklistServices } from 'services';


const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const moduleName = 'blackList';

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(11)": {
                    textAlign: "right"
                }
            },
            body: {
                "&:nth-child(22)": {
                    textAlign: "right"
                }
            }
        }
    }
});

class TableBlacklistList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            readOnly: true,
            loaderSpiner: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: "",
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "created_at",
                sortDir: "desc",
                keyword: ""
            },
            refreshTable: true
        }
        this.openUploadForm = this.openUploadForm.bind(this)
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        //console.log(this.props);
        const { dispatch, tableReload, tableFilter } = this.props
        if (this.state.refreshTable) {
            tableFilter.skip = null
            tableFilter.sortBy = "createdAt"
            tableFilter.sortDir = "desc"
            tableFilter.keyword = ""
            tableFilter.search = {}
            this.setState({
                refreshTable: false
            })
        }

        let reqParams = tableFilter;

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                reqParams.skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                reqParams.limit = 10
            }
            reqParams.page = skip;
            this.setState({
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams.skip = skip;
            reqParams.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams.keyword = keyword;
            } else {
                reqParams.keyword = ""
            }
        } else {
            reqParams = tableFilter;
        }

        dispatch(blacklistAction.getBlacklistList(reqParams));
        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: blacklistConstants.CLEAR_PAGE_STATE });
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas;
        return data[rowIndex];
    }

    componentDidUpdate() {
        //console.log('componentDidUpdate tableWhitelistList');
        const { tableReload, tableReset, dispatch } = this.props;
        //console.log(tableReload, tableReset)
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: blacklistConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    componentDidMount() {
        this.setState({
            refreshTable: true
        })

        this.getData();
    }

    openFormEdit(rowIndex) {
        const { dispatch } = this.props;
        const rowData = this.getRowData(rowIndex)
        dispatch({ type: blacklistConstants.OPEN_EDIT, formInitialValue: { ...rowData } })
    }

    openFromAdd() {
        const { dispatch } = this.props;
        dispatch({ type: blacklistConstants.OPEN_ADD, formInitialValue: { status: 'Active' } })
    }

    openUploadForm() {
        const { dispatch } = this.props;
        // dispatch(change('FORM_UPLOAD_WHITELIST', 'campaignId', undefined));
        // dispatch(change('FORM_UPLOAD_WHITELIST', 'type', undefined));
        dispatch(change('FORM_UPLOAD_BLACKLIST', 'file', undefined));

        dispatch({ type: blacklistConstants.OPEN_FORM_UPLOAD_BLACKLIST, formImportInitialValue: { status: 'Active' } });
    }

    // openUploadForm() {
    //     //console.log(this.props);
    //     const { dispatch } = this.props;
    //     dispatch({ type: blacklistConstants.OPEN_FORM_UPLOAD_BLACKLIST })
    // }

    handleClickDelete(rowIndex) {
        const { dispatch } = this.props
        let rowData = this.getRowData(rowIndex)
        dispatch({
            type: swalConstants.SHOW,
            data: {
                type: "warning",
                title: i18n.t("warning_messages.are_you_sure_delete_blacklist"),
                message: `${rowData.value}`,
                onConfirm: function () {
                    let reqParams = {
                        _id: rowData._id,
                    }
                    dispatch({ type: swalConstants.HIDE })
                    dispatch(blacklistAction.deleteBlacklist(reqParams))
                },
                onCancel: function () {
                    dispatch({ type: swalConstants.HIDE })
                },
                showCancel: true,
                confirmBtnText: i18n.t("delete"),
                cancelBtnText: i18n.t("cancel"),
            },
        })
    }



    render() {
        const { classes, dispatch, formInitialValue, tableDatas, tableKey, visiblePersonalData, showLoader, tableTotal, tableFilter, formImportOpen, tableReload, formImportInitialValue } = this.props;
        const { rowsPerPage } = this.state;

        let columns = [
            { name: "_id", label: 'Id', options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            { name: "campaignName", label: "Campaign Name" },
            { name: "type", label: "Type" },
            { name: "value", label: "Value" },
        ]

        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return (
                            <div className={classes.actionDiv}>

                                {plugins.isHasPermission(moduleName, 'update') &&
                                    <TableActionButton
                                        actionType="edit"
                                        onClick={(e) => {
                                            this.openFormEdit(tableMeta.rowIndex)
                                        }}
                                    />
                                }
                                {plugins.isHasPermission(moduleName, "delete") && (
                                    <TableActionButton
                                        actionType="delete"
                                        onClick={() => this.handleClickDelete(tableMeta.rowIndex)}
                                    />
                                )}

                            </div>
                        )
                    }
                }
            })
        }

        let options = {
            serverSide: true,
            count: (tableTotal ? tableTotal : 0),
            page: tableFilter.page ? tableFilter.page : 0,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        // if (plugins.isHasPermission(moduleName, 'create')) {
        //     options.customToolbar = () => {
        //         return (
        //             <CustomToolbar handleClick={this.openUploadForm} id="btn_open_form_import" title="Import File" importIcon />
        //         );
        //     }
        // }


        if (plugins.isHasPermission(moduleName, 'create')) {
            options.customToolbar = () => {
                return (
                    <>
                        <CustomToolbar handleClick={this.openUploadForm} id="btn_open_form_import" title="Import File" importIcon />
                        <CustomToolbar handleClick={this.openFromAdd.bind(this)} title={i18n.t("label.add_new")} />
                    </>
                );
            }
        }


        return (
            <div id="TableBlacklistList">
                <CustomSweetAlert />
                <BlacklistForm
                    initialValues={formInitialValue}
                    readOnly={!plugins.isHasPermission(moduleName, 'read')}
                />
                <BlacklistUploadForm initialValues={formImportInitialValue} />
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        data={tableDatas}
                        columns={columns}
                        options={options}
                    />
                    {showLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, showLoader, tableTotal, tableFilter, formImportInitialValue, formImportOpen } = state.blacklist;
    const { visiblePersonalData, merchantSetting } = state.authentication;

    return {
        formInitialValue, tableReload, tableReset, visiblePersonalData, tableDatas, tableKey, showLoader, merchantSetting, tableTotal, tableFilter, formImportInitialValue, formImportOpen
    };
}
export default connect(mapStateToProps)(withStyles(pageStyle)(TableBlacklistList));  

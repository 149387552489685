import React from "react";
import { Field, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import lodash from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { trueMoneyReportActions } from "actions"
import TableTrueMoneySummary from './TableTrueMoneySummary';


import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import { orange, grey, lightGreen } from "@material-ui/core/colors";
import { plugins } from "helpers";
import CountTo from 'react-count-to';

const FORM_NAME = "FORM_FILTER_TRUEMONEY_SUMMARY_REPORT";

const style = {};

class TrueMoneySummaryReward extends React.Component {
    componentDidMount() {
        const { dispatch, summaryReward } = this.props;
        const { tableFilter} = summaryReward

        dispatch(trueMoneyReportActions.getTrueMoneySummaryBudget(tableFilter));
        dispatch(trueMoneyReportActions.getTrueMoneySummaryRewardList(tableFilter, false))
    }


    componentWillUnmount() {
        this.clearFilter();
        
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch(reset(FORM_NAME));
        dispatch({ type: "CLEAR_TRUEMONEY_SUMMARY_REPORT_FILTER" });
    };

    handleSubmit(values) {
        const { dispatch, summaryReward } = this.props;
        const { tableFilter } = summaryReward
        let _tableFilter = {
        page: tableFilter.page,
        skip: tableFilter.skip,
        limit: tableFilter.limit,
        sortBy: tableFilter.sortBy,
        sortDir: tableFilter.sortDir,
    }
        _tableFilter = {
          ..._tableFilter,
          ...values
        }
        const resetTable = true
        dispatch(trueMoneyReportActions.getTrueMoneySummaryBudget(_tableFilter));
        dispatch(trueMoneyReportActions.getTrueMoneySummaryRewardList(_tableFilter, resetTable))

    }

    render() {
        const {
            FORM_FILTER_TRUEMONEY_SUMMARY_REPORT,
            summaryReward,
            handleSubmit
        } = this.props;

        
        let startMaxDate = new Date();
        if (lodash.has(FORM_FILTER_TRUEMONEY_SUMMARY_REPORT, "values.endDate")) {
            startMaxDate = FORM_FILTER_TRUEMONEY_SUMMARY_REPORT.values.endDate;
        }

        let endMinDate;
        if (lodash.has(FORM_FILTER_TRUEMONEY_SUMMARY_REPORT, "values.startDate")) {
            endMinDate = FORM_FILTER_TRUEMONEY_SUMMARY_REPORT.values.startDate;
        }


        return (
            <>
                <div>
                    <form
                        name={FORM_NAME}
                        onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={4} lg={4}>
                                <Field
                                    name='startDate'
                                    component={CustomDatePicker}
                                    type='text'
                                    maxDate={startMaxDate}
                                    labelText='Transaction Start Date'
                                    dateFormat='DD-MMM-YY'
                                    timeFormat="HH:mm:ss"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4} lg={4}>
                                <Field
                                    name='endDate'
                                    minDate={endMinDate}
                                    maxDate={new Date()}
                                    component={CustomDatePicker}
                                    type='text'
                                    labelText='Transaction End Date'
                                    dateFormat='DD-MMM-YY'
                                    timeFormat="HH:mm:ss"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4} lg={4}>
                                <Button
                                    id='button-earn-summary-report-clear'
                                    onClick={this.clearFilter}
                                    style={{
                                        marginTop: "25px",
                                    }}
                                    size='sm'
                                    color='white'>
                                    Clear
                                </Button>
                                <Button
                                    type='submit'
                                    id='button-earn-summary-report-submit'
                                    style={{
                                        marginRight: "10px",
                                        marginTop: "25px",
                                        marginLeft: "15px",
                                    }}
                                    size='sm'
                                    color='primary'>
                                    Submit
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </div>
                <GridContainer style={{ marginTop: "30px" }}>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                        <div style={{ padding: "0px 25px",height:"100%" }}>
                            <TotalCard color="success" label="Reward Budget" total={lodash.get(summaryReward, 'topBarTotal.budget')} />
                        </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                        <div style={{ padding: "0px 25px",height:"100%" }}>
                            <TotalCard color="unsuccess" label="Total Money Verify" total={lodash.get(summaryReward, 'topBarTotal.verify')} />
                        </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                        <div style={{ padding: "0px 25px",height:"100%" }}>
                            <TotalCard color="total" label="Total Money Topup" total={lodash.get(summaryReward, 'topBarTotal.topup')}/>
                        </div>
                    </GridItem>

                </GridContainer>
                <div>
                    <TableTrueMoneySummary />
                </div>
            </>
        );
    }
}

const TotalCard = (props) => {
    const { color, label, total } = props
    let bgColor, borderColor = ""
    switch (color) {
        case "success": {
            bgColor = orange[200]
            borderColor = orange[500]
            break;
        }
        case "unsuccess": {
            bgColor = grey[200]
            borderColor = grey[500]
            break;
        }
        default: {
            bgColor = lightGreen[200]
            borderColor = lightGreen[500]
            break;
        }
    }
    return (
        <div className="text-center" style={{
            backgroundColor: bgColor,
            border: "solid 2px" + borderColor,
            borderRadius: "5px",
            }}>
            <h4 style={{ margin: "10px", fontWeight: 500 }}>{label}</h4>
            <h3 style={{ fontWeight: 900 }}>
                <CountTo to={total?total:0} speed={1000}>{fnAfterCountto}</CountTo>
            </h3>
        </div>
    )
}

const fnAfterCountto = value => <span>{plugins.numberFormat(value)}</span>;

function mapStateToProps(state) {
    const { FORM_FILTER_TRUEMONEY_SUMMARY_REPORT } = state.form;
    const { summaryReward } = state.tmnReport
    return {
        FORM_FILTER_TRUEMONEY_SUMMARY_REPORT,
        summaryReward
    };
}

const connectedComponent = connect(mapStateToProps)(TrueMoneySummaryReward);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent));

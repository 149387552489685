import moment from 'moment';
import { reportConstants } from "../constants";

const initialState = {
  formInitialValue: {},
  formOpen: false,
  formMode: 'edit',
  tableReset: false,
  tableReload: false,
  insight: {
    tableReset: false,
    tableReload: false,
    ageRangeMode: false,
    tableTotal: 0,
    setting: false,
    ageRangeFilterOption: []
  },
  earnReport: {
    tableKey: Date.now(),
    tableData: [],
    tableTotal: 0,
    purchaseDetail:[],
    modalOpen: false,
    tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "created_at",
      sortDir: "desc",
      keyword: "",
      search: {
        productId: "all",
        startDate: moment().startOf('day'), 
        endDate: moment().set('hour',23).set('minute',59).set('second',59)
      }
    },
    productList: [],
    earnRuleList: [],
    earnDetail: {
      open: false,
    },
    earnDetailImages: {
      open: false,
    }
  },
  burnReport: {
    tableKey: Date.now(),
    tableData: [],
    tableTotal: 0,
    tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "created_at",
      sortDir: "desc",
      keyword: "",
      search: {
        startDate: moment().startOf('day'), 
        endDate: moment().set('hour',23).set('minute',59).set('second',59)
      }
    },
    productList: []
  },
  luckyDraw: {
    tableKey: Date.now(),
    tableData: [],
    tableTotal: 0,
    tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "transactionDate",
      sortDir: "desc",
      keyword: "",
    },
    productList: []
  },
  userAgentDetail:{
    isOpen:false,
    userAgent:{},
    userChannel:"",
  }
};

export function report(state = initialState, action) {
  switch (action.type) {
    case 'REPORT_REDUCER_INIT':
      return initialState
    case 'CLEAR_EARNREPORT_FILTER':
      return {
        ...state,
        earnReport: {
          ...state.earnReport,
          tableFilter: initialState.earnReport.tableFilter
        }
      }
    case 'CLEAR_BURNREPORT_FILTER':
      return {
        ...state,
        burnReport: {
          ...state.burnReport,
          tableFilter: initialState.burnReport.tableFilter
        }
      }
    case 'CLEAR_LUCKYDRAW_FILTER':
      return {
        ...state,
        luckyDraw: {
          ...state.luckyDraw,
          tableFilter: initialState.luckyDraw.tableFilter
        }
      }
    case reportConstants.INSIGHT_TABLE_RELOAD:
      return {
        ...state,
        insight: {
          ...state.insight,
          tableReload: true
        }
      };
    case reportConstants.INSIGHT_TABLE_RELOAD_SUCCESS:
      return {
        ...state,
        insight: {
          ...state.insight,
          tableReload: false
        }
      };
    case reportConstants.INSIGHT_TABLE_RESET:
      return {
        ...state,
        insight: {
          ...state.insight,
          tableReset: true
        }
      };
    case reportConstants.INSIGHT_TABLE_RESET_SUCCESS:
      return {
        ...state,
        insight: {
          ...state.insight,
          tableReset: false
        }
      };
    case reportConstants.GET_MEMBER_REPORT_SUCCESS:
      return {
        ...state,
        insight: {
          ...state.insight,
          ageRangeFilterOption: action.ageFilter,
          ageRangeMode: action.ageRangeMode,
          tableTotal: action.tableTotal,
          setting: action.setting
        }
      };
    case reportConstants.GET_DROPDOWN_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        earnReport: {
          ...state.earnReport,
          productList: action.data
        }
      };
    case reportConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        burnReport: {
          ...state.burnReport,
          campaignList: action.data
        }
      };
    case reportConstants.GET_EARN_RULES_DATA_SUCCESS:
      return {
        ...state,
        earnReport: {
          ...state.earnReport,
          earnRuleList: action.data
        }
      };
    case reportConstants.GET_EARN_TRANSACTON_SUCCESS:
      return {
        ...state,
        earnReport: {
          ...state.earnReport,
          modalOpen:false,
          tableFilter: action.tableFilter,
          tableData: action.data ? action.data : [],
          tableTotal: action.total ? action.total : 0
        }
      };
    case reportConstants.EARN_TRANSACTON_TABLE_RESET:
      return {
        ...state,
        earnReport: {
          ...state.earnReport,
          tableKey: Date.now()
        }
      };

    case reportConstants.GET_BURN_TRANSACTON_SUCCESS:
      return {
        ...state,
        burnReport: {
          ...state.burnReport,
          tableFilter: action.tableFilter,
          tableData: action.data ? action.data : [],
          tableTotal: action.total ? action.total : 0
        }
      };
    case reportConstants.BURN_TRANSACTON_TABLE_RESET:
      return {
        ...state,
        burnReport: {
          ...state.burnReport,
          tableKey: Date.now()
        }
      };
    case reportConstants.LUCKYDRAW_DOWLOAD_RESET:
      return {
        ...state,
        luckyDraw: {
          ...state.luckyDraw,
          tableKey: Date.now()
        }
      };
    case reportConstants.GET_LUCKYDRAW_DOWLOAD_SUCCESS:
      return {
        ...state,
        luckyDraw: {
          ...state.luckyDraw,
          tableFilter: action.tableFilter,
          tableData: action.data ? action.data : [],
          tableTotal: action.total ? action.total : 0
        }
      };
    case reportConstants.OPEN_EARN_REPORT_DETAIL:
    return {
      ...state,
      earnReport: {
        ...state.earnReport,
        earnDetail: {
          open: true,
          data: action.data? action.data: []
        }
      }
    };
    case reportConstants.CLOSE_EARN_REPORT_DETAIL:
      return {
        ...state,
        earnReport: {
          ...state.earnReport,
          earnDetail: {
            open: false,
          }
        }
    };

    case reportConstants.OPEN_EARN_REPORT_EDIT:
      return {
          ...state,
          formOpen: true,
          formMode: 'edit',
          formInitialValue: action.data
    };

    case reportConstants.CLOSE_EARN_REPORT_EDIT:
      return {
        ...state,
        formOpen: false,
        formMode: 'edit',
        formInitialValue: {},
    };

    case reportConstants.OPEN_EARN_REPORT_EDIT_VITAMILK:
      return {
          ...state,
          formOpenVitamilk: true,
          formMode: 'edit',
          formInitialValue: action.data
    };

    case reportConstants.CLOSE_EARN_REPORT_EDIT_VITAMILK:
      return {
        ...state,
        formOpenVitamilk: false,
        formMode: 'edit',
        formInitialValue: {},
    };

    case reportConstants.EDIT_EARN_REPORT_SUCCESS:
      return {
          ...state,
          formOpen: false,
          formMode: 'edit',
          formInitialValue: {},
          tableReload: true
    };

    case reportConstants.TABLE_EARN_REPORT_RELOAD_SUCCESS:
      return {
        ...state,
        tableReload: false
    };
    case reportConstants.OPEN_EARN_REPORT_IMAGES:
      return {
        ...state,
        earnReport: {
          ...state.earnReport,
          earnDetailImages: {
            open: true,
            data: action.data? action.data: []
          }
        }
    };
    case reportConstants.CLOSE_EARN_REPORT_IMAGES:
    return {
      ...state,
      earnReport: {
        ...state.earnReport,
        earnDetailImages: {
          open: false,
        }
      }
    };
    case reportConstants.OPEN_REPORT_USER_AGENT_DETAIL:
      return {
        ...state,
        userAgentDetail: {
            ...state.userAgentDetail,
            isOpen: true,
            userAgent:action.data.userAgent || "",
            userChannel:action.data.userChannel || "-",
        }
      };
      case reportConstants.CLOSE_REPORT_USER_AGENT_DETAIL:
      return {
        ...state,
          userAgentDetail: {
            ...state.userAgentDetail,
            isOpen: false,
          }
      };
    default:
      return state;
  }

}

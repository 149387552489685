import { luckydrawDashboardServices } from '../services'
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'
import moment from 'moment'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const luckydrawDashboardActions = {
    getluckydrawDashboardSetting,
    // saveWinnerConfigSetting,
    // editWinnerConfigSetting
};

function getluckydrawDashboardSetting() {
    return dispatch => {
        loader(dispatch, true)
        luckydrawDashboardServices.getluckydrawDashboardSetting()
            .then(
                response => {
                    console.log('getluckydrawDashboardSetting action response', response);
                    if (response.status) {

                        let formInitialValues = {
                            url : "",
                            width : "",
                            height : ""
                        }

                        formInitialValues = {
                            ...lodash.get(response, 'data.luckydrawDashboard'),
                        }

                        dispatch({ type: 'GET_LUCKYDRAW_DASHBOARD_SETTING_SUCCESS', data: formInitialValues })
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

// function saveWinnerConfigSetting(params) {
//     return dispatch => {
//         loader(dispatch, true)
//         // console.log('saveWinnerConfigSetting params', params);
//         winnerConfigServices.saveWinnerConfigSetting(params)
//             .then(
//                 response => {
//                     // console.log('saveWinnerConfigSetting response', response);
//                     if (response.status) {
//                         customAlert(dispatch, 'success', "SUCCESS");
//                         dispatch(getWinnerConfigSetting())
//                     } else {
//                         if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
//                     }
//                 },
//                 error => {
//                     customAlert(dispatch, 'error', i18n.t(defaultFailedText))
//                 }
//             ).then(() => {
//                 loader(dispatch, false)
//             })
//     };
// }

// function editWinnerConfigSetting(params) {
//     return dispatch => {
//         loader(dispatch, true)
//         winnerConfigServices.editWinnerConfigSetting(params)
//             .then(
//                 response => {
//                     if (response.status) {
//                         customAlert(dispatch, 'success', "SUCCESS");
//                         dispatch(getWinnerConfigSetting())
//                     } else {
//                         if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
//                     }
//                 },
//                 error => {
//                     customAlert(dispatch, 'error', i18n.t(defaultFailedText))
//                 }
//             ).then(() => {
//                 loader(dispatch, false)
//             })
//     };
// }
import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import lodash from "lodash";
import moment from "moment";
import i18n from 'i18n';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// core components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import MemberForm from "views/Member/FormMember.jsx";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";

import Badge from "components/Badge/Badge.jsx";
import { memberActions } from "actions";
import { reportServices } from 'services';
import { plugins, crmValidator , loader, alert as customAlert } from "helpers";
import { memberConstants, swalConstants } from "../../constants";
import MemberUserAgentDetail from './MemberUserAgentDetail';

import Gender from "assets/data/genders.json";

const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
   },
   actionDiv: {
      display: "inline-flex"
   }
};

const pageStyle = {
   ...style
};

const THEME = createMuiTheme({
   ...MTablestyles,
   overrides: {
      ...MTablestyles.overrides,
      MuiTableCell: {
         head: {
            "&:nth-child(11)": {
               textAlign: "left"
            }
         },
         body: {
            "&:nth-child(20)": {
               textAlign: "center"
            },
            "&:nth-child(18)": {
               textAlign: "center"
            },
            "&:nth-child(22)": {
               textAlign: "center"
            }
         }
      }
   }
});

const moduleName = "member";

const thaiPhoneFormat = str => {
   str = str
      .replace(/-/g, "")
      .replace(/\s/g, "");
   return str;
};

class TableMemberList extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         readOnly: true,
         page: 0,
         rowsPerPage: 10,
         count: 100,
         data: [],
         reqParams: ""
      };

      this.delayedCallback = lodash.debounce(this.getData, 800);
   }

   openUserAgentDetail(rowData) {
      const { dispatch } = this.props;
      dispatch({ type: memberConstants.OPEN_MEMBER_USER_AGENT_DETAIL, data: rowData })
   }

   getData = (skip, limit, sortBy, sortDir, keyword) => {
      const { dispatch, tableReload, seturlParameters, parameters } = this.props;
      let tableFilter = parameters

      if (!tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            skip = 0;
         }

         if (crmValidator.isEmptyValue(limit)) {
            limit = 10;
         }

         // this.setState({ page: skip, rowsPerPage: limit });
         lodash.set(tableFilter, "page", skip)
         lodash.set(tableFilter, "rowsPerPage", limit)
         skip = parseInt(limit) * parseInt(skip);

         lodash.set(tableFilter, "skip", skip)
         lodash.set(tableFilter, "limit", limit)

         if (!crmValidator.isEmptyValue(sortBy)) {
            lodash.set(tableFilter, "sortBy", sortBy)
         }

         if (!crmValidator.isEmptyValue(sortDir)) {
            lodash.set(tableFilter, "sortDir", sortDir)
         }

         if (!crmValidator.isEmptyValue(keyword)) {
            lodash.set(tableFilter, "keyword", encodeURIComponent(keyword))
         } else {
            lodash.set(tableFilter, "keyword", "")
         }
      }

      let reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');

      seturlParameters(reqParams, tableFilter)

      dispatch(memberActions.getTableDatas(reqParams));
   };

   getRowData(rowIndex = 0) {
      const { tableDatas } = this.props;
      const data = tableDatas.users;
      return data[rowIndex];
   }

   componentWillMount() {
      const { dispatch } = this.props;
      dispatch({ type: memberConstants.CLEAR_PAGE_STATE });
      dispatch(memberActions.getActiveMemberLevelList())
   }

   componentDidUpdate() {
      const { tableReload, tableReset, dispatch } = this.props;
      if (tableReload || tableReset) {
         this.getData();
         dispatch({ type: memberConstants.TABLE_RELOAD_SUCCESS });
      }
   }

   componentDidMount() {
      this.getData();
   }

   openFormAdd = () => {
      const { dispatch } = this.props;
      const birthdayDisplayMode = this.getDisplayBirthDayMode();
      const formInitialValue = {
         birthdayDisplayMode: birthdayDisplayMode
      }
      dispatch(memberActions.checkMemberLimit(formInitialValue))

      // dispatch({
      //    type: memberConstants.OPEN_FORM_ADD,
      //    formInitialValue: {
      //       birthdayDisplayMode: birthdayDisplayMode
      //    }
      // });
   };

   openFormEdit(index) {
      const { dispatch, tableDatas } = this.props;
      let rowData = tableDatas.users[index];
      const birthdayDisplayMode = this.getDisplayBirthDayMode();
      dispatch({
         type: memberConstants.OPEN_FORM_EDIT,
         formInitialValue: {
            ...rowData,
            birthdayDisplayMode: birthdayDisplayMode,
            ageRange: lodash.has(rowData, "ageRange.min") && lodash.has(rowData, "ageRange.max")
               ? `${rowData.ageRange.min}_${rowData.ageRange.max}`
               : null
         }
      });
   }

   getDisplayBirthDayMode() {
      //get before render
      const { merchantSetting } = this.props;

      return (lodash.get(merchantSetting, "displayYears") === "BE") ? "BE" : "AD";
   }

   getShowMemberLevel(id) {
      const { formMemberLevellist, } = this.props;

      var set = "";
      var findData = lodash.find(formMemberLevellist, { _id: id });
      if (findData) {
         set = (
            <Badge
               customStyle={{
                  backgroundColor: findData.color
               }}>
               {findData.name}
            </Badge>
         );
      }
      return set;
   }

   getShowVerificationBadge(status) {
      let BadgeColor
      switch (status) {
         case "approve": BadgeColor = "success"; break;
         case "reject": BadgeColor = "danger"; break;
         default: BadgeColor = "gray";
      }
      return (<Badge color={BadgeColor}>{status}</Badge>)
   }

   exportCSV() {
      const { total, dispatch,parameters,keyword } = this.props;
      let tableFilter = parameters

      lodash.set(tableFilter, "page", 0)
      lodash.set(tableFilter, "rowsPerPage", 10)
      lodash.set(tableFilter, "skip", 0)
      lodash.set(tableFilter, "limit", 10)
      lodash.set(tableFilter, "sortBy", "created_at")
      lodash.set(tableFilter, "sortDir", "desc")

      let reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');
      // console.log('reqParams :',reqParams);
  
      if (total > 30000) {
        customAlert(dispatch, 'error', "Can not export data more than 30,000 rows")
      } else {
        loader(dispatch, true)
        reportServices.downloadMemberReport(reqParams)
          .then(response => {
            loader(dispatch, false)
          })
          .catch(e => {
            customAlert(dispatch, 'error', "api_messages.SOMETHING_WENT_WRONG")
            loader(dispatch, false)
          });
      }
   }

   render() {
      const {
         classes,
         dispatch,
         formInitialValue,
         tableDatas,
         tableKey,
         visiblePersonalData,
         parameters,
         merchantSetting
      } = this.props;
      const { rowsPerPage, page } = parameters;

      let birthDayFieldName = "birthday";
      let birthDayFieldLabel = i18n.t("label.birthday");
      let ageRangeMode = lodash.get(merchantSetting, 'ageRangeMember') || false;

      if (ageRangeMode) {
         birthDayFieldName = "displayAgeRange";
         birthDayFieldLabel = i18n.t("label.age_range");
      }

      let columns = [
         {
            name: "_id",
            options: {
               filter: false,
               display: "excluded"
            }
         }, {
            name: "displayId",
            options: {
               filter: false,
               display: "excluded"
            }
         }, {
            name: "created_at",
            label: i18n.t("label.date_time"),
            options: {
               customBodyRender: value => {
                  return moment(value).format('DD-MMM-YYYY')
               }
            }
         }, {
            name: "memberId",
            label: i18n.t("label.member_id"),
            options: {
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = this.getRowData(tableMeta.rowIndex);
                  return rowData.displayId;
               }
            }
         },{
            name: "firstName",
            label: "Name",
            options: {
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = this.getRowData(tableMeta.rowIndex);
                  return rowData.lastName === undefined || rowData.lastName === null
                     ? rowData.firstName
                     : rowData.firstName + " " + rowData.lastName;
               }
            }
         }, {
            name: "gender",
            label: i18n.t("label.gender"),
            options: {
               customBodyRender: (value, tableMeta) => {
                  const rowData = this.getRowData(tableMeta.rowIndex);
                  let gender = lodash.find(Gender, function(o) { return o.value === rowData.sex || o.value === "N"; })
                  return i18n.t(`gender.${gender.value}`) 
               }
            }
         }, {
            name: birthDayFieldName,
            label: birthDayFieldLabel,
            options: {
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = this.getRowData(tableMeta.rowIndex);
                  if (ageRangeMode) {
                     return plugins.ageRangeFormat(rowData);
                  } else {
                     if (this.getDisplayBirthDayMode() === "BE") {
                        return plugins.datetimeFormatBE(rowData.birthday);
                     } else {
                        return plugins.datetimeFormat(rowData.birthday, "date");
                     }
                  }
               }
            }
         }, {
            name: "phoneNumber",
            label: i18n.t("label.mobile_no"),
            options: {
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = this.getRowData(tableMeta.rowIndex);
                  return rowData.phoneNumber ? thaiPhoneFormat(rowData.phoneNumber) : ''
               }
            }
         },
         {
            name: "email",
            label: i18n.t("label.email")
         },
         {
            name: "province",
            label: i18n.t("label.province")
         },
         {
            name: "point",
            label: i18n.t("label.current_point")
         }, {
            name: "accumulatePoint",
            label: i18n.t("label.accum_point")
         }, {
            name: "accumulatePurchase",
            label: i18n.t("label.accum_purchase")
         }, {
            name: "memberLevel",
            label: i18n.t("label.memberLevel"),
            options: {
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = this.getRowData(tableMeta.rowIndex);
                  if (rowData.memberLevel) {
                     return this.getShowMemberLevel(value);
                  } else {
                     return "";
                  }
               }
            }
         }, {
            name: "shopId",
            label: i18n.t("label.shop_id")
         }, {
            name: "lastModifiedDate",
            label: i18n.t("label.last_modified_date")
         },
         {
            name: "verificationStatus",
            label: i18n.t("label.verification_status"),
            options: {
               customBodyRender: (value) => {
                  if (value) {
                     return this.getShowVerificationBadge(value);
                  } else {
                     return "";
                  }
               }
            }
         }
      ];

      if (plugins.isHasPermission(moduleName, 'read') && visiblePersonalData) {
         columns.push({
            label: "Action",
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowIndex = tableMeta.rowIndex
                  let rowData = this.getRowData(rowIndex)
                  const isUserAgent = lodash.has(rowData, "registerUserAgent") || lodash.has(rowData, "lastLoginUserAgent")
                  return (
                     <div className={classes.actionDiv}>
                        {plugins.isHasPermission(moduleName, 'update') &&
                           <TableActionButton
                              actionType="edit"
                              onClick={e => {
                                 this.openFormEdit(tableMeta.rowIndex);
                              }}
                           />
                        }
                        {plugins.isHasPermission(moduleName, 'delete') && (
                           <TableActionButton
                              actionType="delete"
                              onClick={e => {
                                 dispatch({
                                    type: swalConstants.SHOW,
                                    data: {
                                       type: "warning",
                                       title: i18n.t("warning_messages.are_you_sure_delete_member"),
                                       message: `${this.getRowData(tableMeta.rowIndex).firstName}`,
                                       onConfirm: function () {
                                          let reqParams = {
                                             _id: tableMeta.rowData[0]
                                          };
                                          dispatch({ type: swalConstants.HIDE });
                                          dispatch(memberActions.deleteMember(reqParams));
                                       },
                                       onCancel: function () {
                                          dispatch({ type: swalConstants.HIDE });
                                       },
                                       showCancel: true,
                                       confirmBtnText: i18n.t("delete"),
                                       cancelBtnText: i18n.t("cancel")
                                    }
                                 });
                              }} />)}

                        {isUserAgent && (
                           <IconButton color="primary" onClick={() => this.openUserAgentDetail(rowData)}>
                              <InfoOutlinedIcon />
                           </IconButton>
                        )
                        }

                     </div>
                  );
               }
            }
         });
      }

      let options = {
         serverSide: true,
         count: tableDatas.total,
         page: page,
         rowsPerPage: rowsPerPage,
         selectableRows: "none",
         print: false,
         customToolbar: () => {
            return (
               <>
                  {plugins.isHasPermission(moduleName, 'export') && plugins.isHasPackageFeatures('export') &&(
                        <Tooltip title='Export CSV'>
                           <IconButton
                              id='button-memberTable-exportCsv'
                              className=''
                              onClick={this.exportCSV.bind(this)}>
                              <CloudDownload />
                           </IconButton>
                        </Tooltip>
                  )}

                  {plugins.isHasPermission(moduleName, 'create') && visiblePersonalData &&(
                     <CustomToolbar handleClick={this.openFormAdd} title="Add New" />
                  )}
               </>
            );
         },
         download: false,
         filter: false,
         responsive: "scroll",
         rowsPerPageOptions: [
            5, 10, 20
         ],
         onTableChange: (action, tableState) => {
            let skip,
               limit,
               sortBy,
               sortDir,
               keyword = "";

            if (tableState.page !== null) {
               skip = tableState.page;
            }

            if (tableState.rowsPerPage !== null) {
               limit = tableState.rowsPerPage;
            }

            if (tableState.activeColumn !== null) {
               sortBy = tableState.columns[tableState.activeColumn].name;
            }
            if (tableState.announceText !== null) {
               sortDir = tableState
                  .announceText
                  .search("descending") !== -1
                  ? "desc"
                  : "asc";
            }
            if (action === "search") {
               keyword = (tableState.searchText !== null) ? tableState.searchText : "";
               this.delayedCallback(skip, limit, sortBy, sortDir, keyword);
            }
            let actionList = ["changePage", "sort", "changeRowsPerPage"];
            if (actionList.indexOf(action) !== -1) {
               this.getData(skip, limit, sortBy, sortDir, keyword);
            }
         }
      };

      // if (plugins.isHasPermission(moduleName, 'create') && visiblePersonalData) {
      //    options.customToolbar = () => {
      //       return <CustomToolbar handleClick={this.openFormAdd} title="Add New" />;
      //    };
      // }


      return (
         <div id="tableMemberList">
            <CustomSweetAlert />
            <MemberUserAgentDetail />
            <MemberForm
               merchantSetting={merchantSetting}
               initialValues={formInitialValue}
               readOnly={!plugins.isHasPermission(moduleName, 'update')}
            />
            <MuiThemeProvider theme={THEME}>
               <MUIDataTable
                  key={tableKey}
                  title={""}
                  data={tableDatas.users}
                  columns={columns}
                  options={options} />
            </MuiThemeProvider>
         </div>
      );
   }
}

function mapStateToProps(state) {
   const {
      formInitialValue,
      tableReload,
      tableReset,
      tableDatas,
      tableKey,
      formMemberLevellist,
      total,
   } = state.member;

   const { merchantSetting, visiblePersonalData } = state.authentication;
   const { memberLevelList } = state.memberLevel;

   return {
      formInitialValue,
      tableReload,
      visiblePersonalData,
      tableReset,
      tableDatas,
      tableKey,
      formMemberLevellist,
      merchantSetting,
      memberLevelList,
      total
   };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableMemberList));

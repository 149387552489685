import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';

export const expirationServices = {
  getTableExpirationDatas,
  getHistoryExpirationById,
}

function getTableExpirationDatas(reqParams) {
  let tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/point-expiration?${reqParams}`, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    })

  return tmp;
}

function getHistoryExpirationById(reqParams) {
  let tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/point-expiration-history?${reqParams}`, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    })

  return tmp;
}
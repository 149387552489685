import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import lodash from 'lodash'

import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { red } from '@material-ui/core/colors';

const pageStyle = {
    ...modalStyle,
    modalBody: {
        ...modalStyle.modalBody,
        fontSize: "15px",
        fontWeight: "400"
    },
    highLight: {
        color: red["500"],
        fontWeight: "700",
        textDecoration: "underline"
    },
    textCenter: {
        textAlign: "center"
    }
}

class DialogExampleCalcPoint extends React.Component {
    render() {
        const { classes, openExample } = this.props
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={openExample}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth={"sm"}
                disableRestoreFocus={true}
                scroll="body">
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><b>{"Example"}</b></h4>
                    <IconButton aria-label="close" id="btn_close_example" onClick={()=>{
                        const { dispatch } = this.props
                        dispatch({ type: "CLOSE_EXAMPLE_POINT_EXPIRATION" })
                    }} className={classes.closeButton} style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px"
                    }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.modalBody}>
                    <p>
                        If each point is valid for <span className={classes.highLight}>&nbsp;6&nbsp;</span> months form <span className={classes.highLight}>&nbsp;month&nbsp;</span> that point was received
                    </p>
                    <p>That Means ...</p>
                    <p>
                        <ul>
                            <li>
                                All points received in Jan 2020 will expire at the end of last day of Jul 2020
                            </li>
                            <li>
                                All points received in Feb 2020 will expire at the end of last day of Aug 2020
                            </li>
                        </ul>
                    </p>
                    <p className={classes.textCenter}>..............................................................................</p>
                    <p></p>
                    <p>
                        If each point is valid for <span className={classes.highLight}>&nbsp;6&nbsp;</span> months form <span className={classes.highLight}>&nbsp;year&nbsp;</span> that point was received
                    </p>
                    <p>That Means ...</p>
                    <p>
                        <ul>
                            <li>
                                All points received in 2020 will expire at the end of last day of Jun 2021
                            </li>
                            <li>
                                All points received in 2021 will expire at the end of last day of Jun 2022
                            </li>
                        </ul>
                    </p>
                </DialogContent>
            </Dialog>
        )
    }
}

DialogExampleCalcPoint = withStyles(pageStyle)(DialogExampleCalcPoint)
const selector = formValueSelector('point_expiration_setting')

DialogExampleCalcPoint = connect(state => {
    const condition = selector(state, 'condition')
    const openExample = lodash.get(state, 'point.openExample')
    return {
        condition, openExample
    }
})(DialogExampleCalcPoint)

export default DialogExampleCalcPoint
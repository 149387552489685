import { pointByPurchaseConstants } from '../constants';
import lodash from 'lodash'
import moment from 'moment';

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableData: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false,
    showLoader: false,
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "created_at",
        sortDir: "desc",
        keyword: "",
        search: {
            startDate: moment().startOf('day'), 
            endDate: moment().set('hour',23).set('minute',59).set('second',59),
            purchaseStartDate: moment().startOf('day'),
            purchaseEndDate:  moment().set('hour',23).set('minute',59).set('second',59),
        }
    },
    updateByList: {},
    searchData: {
        data: [],
    }
}

export function point_by_purchase(state = initialState, action) {
    switch (action.type) {
        case pointByPurchaseConstants.CLEAR_PAGE_STATE:
            return initialState;
        case pointByPurchaseConstants.OPEN_ADD_POINT_BY_PURCHASE:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: {
                    ...action.data,
                },
                searchData: {
                    data: [],
                }
            };
        case pointByPurchaseConstants.CLOSE_FORM_POINT_BY_PURCHASE:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
            }
        case pointByPurchaseConstants.ADD_POINT_BY_PURCHASE_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case pointByPurchaseConstants.GET_USER_BY_PHONE_NUMBER:
            return {
                ...state,
                searchData: {
                    data: lodash.get(action, 'data') ? [lodash.get(action, 'data')]: [],
                },
                tableReload: false,
                tableReset: false,
                adjustmentDetail: {
                    ...state.adjustmentDetail,
                    currentPoint: lodash.get(action,'data.point') ? lodash.get(action,'data.point'): 0,
                },

            }
        case pointByPurchaseConstants.GET_LIST_TABLE_POINT_BY_PURCHASE:
            return {
                ...state,
                tableData: {
                    data: action.data,
                    total: action.total,
                },
                tableReload: false,
                tableReset: false,
                tableFilter: action.tableFilter,
            }
        case pointByPurchaseConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case pointByPurchaseConstants.SHOW_LOADER_POINT_BY_PURCHASE:
            return {
                ...state,
                showLoader: true
            }
        case pointByPurchaseConstants.HIDE_LOADER_POINT_BY_PURCHASE:
            return {
                ...state,
                showLoader: false
            }
        case pointByPurchaseConstants.POINT_BY_PURCHASE_TABLE_RESET:{
            return {
                ...state,
                tableKey: Date.now()
            }
        }
        case pointByPurchaseConstants.SET_FILTER_SEARCH: {
            return {
                ...state,
                tableFilter: action.search,
            }
        }
        case pointByPurchaseConstants.CLEAR_FILTER_SEARCH: {
            return {
                ...state,
                tableFilter: initialState.tableFilter
            }
        }
        case pointByPurchaseConstants.GET_ADMIN_LIST: {
            let list = []
            action.data.admins.map( x =>{
                list.push({
                    value: x._id,
                    label: x.name
                })
            })
            return {
                ...state,
                updateByList: list
            }
        }
        case pointByPurchaseConstants.VOID_POINT_BY_PURCHASE_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        default:
            return state;
    }
}
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, touch } from 'redux-form';
import { crmValidator } from 'helpers';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import { store, normalize, alert as customAlert } from 'helpers';

import { productConstants } from '../../constants';
import { productActions } from '../../actions';

const validateRequiredImage = imageFile => {
    if (imageFile === null || imageFile === undefined || imageFile === "") {
        return "Product Pic is required"
    }
}
const NotValidateRequiredImage = imageFile => {
    return "";
}
const validMimeType = "image/jpeg, image/png";
const validateImageFormat = imageFile => {
    if (imageFile) {
        if (!validMimeType.includes(imageFile.type)) {
            return `รองรับนามสกุลไฟล์ jpeg หรือ png เท่านั้น `;
        }
    }
}
const validateImageSize = imageFile => {
    if (imageFile) {
        if (imageFile.size > (1048576 * 2)) {
            return `ขนาดไฟล์รูปต้องไม่เกิน 2MB`
        }
    }
}

const FORM_NAME = "FORM_PRODUCT";
const form_validate = {
    "prefix": {
        rules: {
            minLength: 2,
            maxLength: 2,
            noSpecialCharacters: true
        },
        messages: {
            minLength: 'รูปแบบรหัส prefix ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง',
            maxLength: 'รูปแบบรหัส prefix ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง',
            noSpecialCharacters: 'รูปแบบรหัส prefix ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง'
        },
        alert: "กรุณากรอก prefix"
    },
    "skuId": {
        rules: {
            required: true,
            noSpecialCharacters: true
        },
        messages: {
            required: "SKU ID is required",
            noSpecialCharacters: 'รูปแบบรหัส SKU ID ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง'
        },
        alert: "SKU ID is required"
    },
    "name": {
        rules: {
            required: true
        },
        messages: {
            required: 'Product Name is required'
        },
        alert: "Product Name is required"
    },
    "status": {
        rules: {
            required: true
        },
        messages: {
            required: 'Status Name is required'
        },
        alert: "Status Name is required"
    } ,
};

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class ProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidUpdate(prevProps) {
        const { formOpen, dispatch } = this.props;
  
        if (formOpen === true && (prevProps.formOpen === false)) {
           dispatch(productActions.getCategoryList());
        }
     }

    handleSubmit = (values) => {
        const { dispatch } = this.props;
        if (this.props.formMode === "add") {
            dispatch(productActions.addProduct(values));
        } else {
            dispatch(productActions.updateProduct(values));
        }
    }

    render() {
        const { formOpen, formMode, classes, dispatch, handleSubmit, productImagePreview, categoryList } = this.props;
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Product' : (readOnly ? 'Product Info' : 'Edit Product')}</b></h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="product-form-dialog"
                        className={classes.modalBody}
                    >
                        {formMode === 'edit' ?
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        name="productId"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Product ID"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled
                                    />
                                </GridItem>
                            </GridContainer>
                            : null}

                        <GridContainer>
                            <Field
                                name="productId"
                                component="input"
                                type="hidden"
                            />
                            <GridItem xs={12} sm={3} md={3}>
                                <Field
                                    id={`${FORM_NAME}_prefix`}
                                    name="prefix"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Prefix"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    normalize={(value) => {
                                        return normalize.productPrefix(value)
                                    }}
                                    disabled={readOnly || (formMode === "edit")}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={3} md={9}>
                                <Field
                                    id={`${FORM_NAME}_skuId`}
                                    name="skuId"
                                    component={CustomInput}
                                    type="text"
                                    labelText="SKU ID"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    normalize={(value) => {
                                        return normalize.productSku(value)
                                    }}
                                    disabled={readOnly || (formMode === "edit")}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id={`${FORM_NAME}_productName`}
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Product Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id={`${FORM_NAME}_categoryId`}
                                    name='categoryId'
                                    component={TagsMultiSelect}
                                    labelText="Category"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    showtick
                                    optionsList={categoryList}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id={`${FORM_NAME}_description`}
                                    name="description"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Description"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id={`${FORM_NAME}_productImage`}
                                    name="productImage"
                                    component={ImageUpload}
                                    type="text"
                                    labelText="Product Pic"
                                    textCenter={true}
                                    width={(1140 / 2.5)}
                                    height={(400 / 2.5)}
                                    showRatioGuide={true}
                                    recommendWidth={1140}
                                    recommendHeight={400}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let reader = new FileReader();
                                        let file = e.target.files[0];
                                        reader.onloadend = () => {
                                            dispatch({ type: productConstants.SET_FORM_PREVIEW_IMAGE, productImagePreview: reader.result })
                                        };
                                        try {
                                            if (!validMimeType.includes(file.type)) {
                                                dispatch(touch(FORM_NAME, 'productImage'));
                                            }
                                            reader.readAsDataURL(file);
                                            dispatch(change(FORM_NAME, 'productImage', file))
                                        } catch (error) {
                                            dispatch({ type: productConstants.SET_FORM_PREVIEW_IMAGE, productImagePreview: "default" })
                                            dispatch(change(FORM_NAME, 'productImage', ''))
                                        }
                                    }}
                                    imagePreviewUrl={productImagePreview}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[
                                        validateImageFormat,
                                        (formMode === "add" ? validateRequiredImage : NotValidateRequiredImage),
                                        validateImageSize
                                    ]}
                                    disabled={readOnly}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id={`${FORM_NAME}_status`}
                                    name="status"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Status"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            id:'select_box_active',
                                            value: "Active",
                                            label: "Active"
                                        },
                                        {
                                            id:'select_box_inactive',
                                            value: "Inactive",
                                            label: "Inactive"
                                        }
                                    ]}
                                    
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>

                    <DialogActions
                        className={classes.modalFooter}>
                        {!readOnly ?
                            <Button
                                id={`${FORM_NAME}_btn_submit`}
                                type="submit"
                                style={{ marginRight: "10px" }}
                                color="primary">
                                Submit
                            </Button>
                            : ''
                        }
                        <Button
                            id={`${FORM_NAME}_btn_cancel`}
                            type="button"
                            onClick={() => {
                                dispatch({ type: productConstants.CLOSE_FORM_PRODUCT });
                            }}
                            color="white">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode, productImagePreview, campaignData , onEarnrule, categoryList} = state.product;
    const { FORM_PRODUCT } = state.form;
    return {
        formOpen, formMode, productImagePreview, FORM_PRODUCT, campaignData , onEarnrule, categoryList
    };
}

const connectProductForm = connect(mapStateToProps)(ProductForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);

        let globalStore = store.getState();
        let formMeta = globalStore.form[FORM_NAME];
        if (Object.keys(errors).length === 1) {
            if (errors.productImage && !formMeta.values.productImage) {
                customAlert(dispatch, 'error', 'Product Pic is required')
            }
        }

    }
})(withStyles(modalStyle)(connectProductForm))
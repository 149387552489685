import { configConstants } from '../constants';
import axios from 'axios';

import { history, setHeaders } from 'helpers';

export const searchCodeServices = {
    searchCode,
};

function searchCode(params) {
    let tmp = axios.post(configConstants.API_BACKEND_CRM + `/code-cms/check-code`, params, {
       headers: setHeaders({ 'Charset': 'utf-8' })
    })
       .then(response => {
          if (response.data.statusCode.toString() === "200") {
             return {
                status: true,
                data: response.data,
                message: response.message
             };
          } else if (response.data.message === "UNAUTHORIZE") {
             history.push('/auth/login')
             return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
             return {
                status: false,
                data: {},
                message: response.data.message
             };
          }
       })
       .catch(e => {
          return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
       });
 
    return tmp;
}


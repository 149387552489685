import React from 'react';
import { CirclePicker } from 'react-color';
import { businessWebsiteConstants } from "../../constants"
import * as ColorSet from "./ColorSet";
import lodash from "lodash";
import equal from 'fast-deep-equal'

class BusinessColorPalette extends React.Component {
  constructor(props) {
    const { dispatch, businessThemeColor } = props.props;
    super(props);
    this.state = { 
      background: businessThemeColor.primary,
     }
  }

  handleChangeComplete = (color) => {
    const { dispatch, businessThemeColor } = this.props.props;
    this.setState({ background: color.hex});
    let indextColor = lodash.findIndex(ColorSet.colorSet, {"primary" : lodash.get(color, 'hex') ? `${color.hex}`.toUpperCase() : businessThemeColor.primary })
    dispatch({ type: businessWebsiteConstants.SET_BUSINESS_COLOR_THEME, businessThemeColor: ColorSet.colorSet[indextColor] });
  };

  componentDidUpdate(prevProps) {
    if(!equal(this.props.props.businessThemeColor, prevProps.props.businessThemeColor)){
      this.setColorPalette(this.props.props.businessThemeColor.primary);
    }
  } 

  setColorPalette(colorCode) {
    const { businessThemeColor } = this.props.props;
    this.setState({ background: lodash.get(colorCode) ? `${colorCode}`.toUpperCase() : businessThemeColor.primary });
  }
  
  render(){
    var colorsPicker = []
    ColorSet.colorSet.map((e) => {
      colorsPicker.push(e.primary)
    })

    return (
      <div>
        <CirclePicker 
          color={ this.state.background }
          onChange={ this.handleChangeComplete }
          colors={colorsPicker}
          // width - String, Pixel value for picker width. Default 252px
          // circleSize - Number, Value for circle size. Default 28
          // circleSpacing - Number, Value for spacing between circles. Default 14
          // onSwatchHover - An event handler for onMouseOver on the <Swatch>s within this component. Gives the args (color, event)
        />
      </div>
    );}
}

export default BusinessColorPalette;
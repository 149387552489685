import { memberConstants } from '../constants';
import lodash from 'lodash'
import moment from 'moment';
const initialState = {
   formOpen: false,
   formMode: 'add',
   formTaglist: [],
   formMemberLevellist: [],
   formInitialValue: {
      startDate: moment().startOf('day'),
      endDate: moment().set('hour', 23).set('minute', 59).set('second', 59)
   },

   tableReload: false,
   sweetAlert: null,
   tableReset: false,
   tableDatas: {
      roles: [],
      total: 0
   },
   total: 0,
   tableKey: 0,
   showLoader: false,
   setting: {},
   userAgentDetail: {
      isOpen: false,
      registerChannel: "",
      registerUserAgent: {},
      lastLoginUserAgent: {},
      lastLoginChannel: "",
   }
};

export function member(state = initialState, action) {
   switch (action.type) {
      case memberConstants.CLEAR_PAGE_STATE:
         return initialState;
      case memberConstants.OPEN_FORM_ADD:
         return {
            ...state,
            formOpen: true,
            formMode: 'add',
            formTaglist: [],
            formInitialValue: {
               password: "P@ssw0rd",
               passwordConfirmation: "P@ssw0rd"
            }
         }
      case memberConstants.OPEN_FORM_EDIT:
         let formInitialValue = {
            ...action.formInitialValue,
            tagList: lodash.map(action.formInitialValue.tagList, '_id'),
            memberLevelList: lodash.map(action.formInitialValue.memberLevelList, '_id')
         }
         formInitialValue.gender = formInitialValue.sex
         return {
            ...state,
            formOpen: true,
            formMode: 'edit',
            formInitialValue: formInitialValue
         }
      case memberConstants.CLOSE_FORM_MEMBER:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {
            }
         }
      case memberConstants.TABLE_GET_LIST_DATAS:
         return {
            ...state,
            tableDatas: action.tmp,
            total: action.tmp.total,
            tableReload: false,
            tableReset: false,
         }
      case memberConstants.RELOAD_TABLE_MEMBERLIST:
      case memberConstants.DELETE_MEMBER_SUCCESS:
         return {
            ...state,
            tableReload: true
         }
      case memberConstants.TABLE_RELOAD_SUCCESS:
      case memberConstants.TABLE_RESET:
         return {
            ...state,
            tableReload: false,
            tableReset: false,
         }
      case memberConstants.TABLE_RESET_SUCCESS:
         return {
            ...state,
            tableReset: false
         }
      case memberConstants.ADD_NEW_MEMBER_SUCCESS:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            tableReload: false,
            tableReset: true,
            tableKey: new Date(),
         }
      case memberConstants.EDIT_MEMBER_SUCCESS:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            tableReset: false,
            tableReload: true
         }
      case memberConstants.DELETE_FORM_MEMBER_TAGLIST:
         return {
            ...state,
            formTaglist: lodash.remove(state.formTaglist, function (e) {
               return e._id !== action.tagId
            })
         }
      case memberConstants.GET_MEMBER_TAGLIST_REQUEST:
         return {
            ...state,
            formTaglist: []
         }
      case memberConstants.GET_MEMBER_TAGLIST_SUCCESS:
         return {
            ...state,
            formTaglist: action.tmp
         }
      case memberConstants.GET_MEMBER_LEVEL_LIST_REQUEST:
         return {
            ...state,
            formMemberLevellist: []

         }
      case memberConstants.GET_MEMBER_LEVEL_LIST_SUCCESS:
         return {
            ...state,
            formMemberLevellist: action.tmp
         }
      case memberConstants.SHOW_LOADER_MEMBER:
         return {
            ...state,
            showLoader: true
         }
      case memberConstants.HIDE_LOADER_MEMBER:
         return {
            ...state,
            showLoader: false
         }
      case memberConstants.OPEN_MEMBER_USER_AGENT_DETAIL:
         return {
            ...state,
            userAgentDetail: {
               ...state.userAgentDetail,
               isOpen: true,
               registerChannel: action.data.registerChannel || "",
               registerUserAgent: action.data.registerUserAgent || {},
               lastLoginUserAgent: action.data.lastLoginUserAgent || {},
               lastLoginChannel: action.data.lastLoginChannel || "",
            }
         }
      case memberConstants.CLOSE_MEMBER_USER_AGENT_DETAIL:
         return {
            ...state,
            userAgentDetail: {
               ...state.userAgentDetail,
               isOpen: false
            }
         }
      default:
         return state
   }
}
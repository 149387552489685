
import React from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import lodash from 'lodash';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import TableShipmentList from "views/Shipment/TableShipmentList.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { shipmentConstants } from '../../constants';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { shipmentActions } from 'actions';
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { plugins, loader, alert as customAlert } from 'helpers';
import { CSVLink } from "react-csv";

// import { shipmentServices } from 'services';

const style = {
  reportHeaderContainer: {
    margin: "0px",
    width: "calc(100% - 10px)",
    padding: "0px",
  },
  btnClearFilter: {
    marginRight: "10px",
    marginTop: "25px"
  },
  textCenter: {
    textAlign: "center"
  },
  mt20: {
    marginTop: "20px"
  }
};

const pageStyle = { ...style };
const FORM_NAME = "formFilterShipment";

class ShipmentReport extends React.Component {

  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      csvData: [],
      campaignList: [],
      rewardList: [],
      campaignAllList: [],
      rewardAllList: [],
      cnt: 0
    };
    this.csvLink = React.createRef();
  }

  // exportCSV() {
  //   const { dispatch, tableTotal, formFilterShipment } = this.props;
  //   let tableFilter = {
  //     page: 0,
  //     skip: 0,
  //     limit: 10,
  //     sortBy: "created_at",
  //     sortDir: "desc",
  //     keyword: "",
  //     search: {}
  //   }
  //   tableFilter.search = {
  //     "startRedemptionDate": (formFilterShipment.values === undefined || formFilterShipment.values.startRedemptionDate === undefined) ? "" : formFilterShipment.values.startRedemptionDate,
  //     "endRedemptionDate": (formFilterShipment.values === undefined || formFilterShipment.values.endRedemptionDate === undefined) ? "" : formFilterShipment.values.endRedemptionDate,
  //     "campaign": (formFilterShipment.values === undefined || formFilterShipment.values.campaign === undefined || formFilterShipment.values.campaign === 'all') ? "" : formFilterShipment.values.campaign,
  //     "rewardName": (formFilterShipment.values === undefined || formFilterShipment.values.rewardName === undefined || formFilterShipment.values.rewardName === 'all') ? "" : formFilterShipment.values.rewardName,
  //     "status": (formFilterShipment.values === undefined || formFilterShipment.values.status === undefined) ? "" : formFilterShipment.values.status,
  //     "startShippingDate": (formFilterShipment.values === undefined || formFilterShipment.values.startShippingDate === undefined) ? "" : formFilterShipment.values.startShippingDate,
  //     "endShippingDate": (formFilterShipment.values === undefined || formFilterShipment.values.endShippingDate === undefined) ? "" : formFilterShipment.values.endShippingDate,
  //     "shippingCompany": (formFilterShipment.values === undefined || formFilterShipment.values.shippingCompany === "All") ? "" : formFilterShipment.values.shippingCompany,
  //     "trackingNo": (formFilterShipment.values === undefined || formFilterShipment.values.trackingNo === undefined) ? "" : formFilterShipment.values.trackingNo
  //   }
  //   tableFilter.skip = 0;
  //   tableFilter.limit = (tableTotal ? tableTotal : 100000);
  //   if (tableTotal > 30000) {
  //     customAlert(dispatch, 'error', "Can not export data more than 30,000 rows")
  //   } else {
  //     loader(dispatch, true)
  //     shipmentServices.exportShipment(tableFilter)
  //       .then(response => {
  //         loader(dispatch, false)
  //       })
  //       .catch(e => {
  //         customAlert(dispatch, 'error', "SOMETHING_WENT_WRONG")
  //         loader(dispatch, false)
  //       });
  //   }
  // }

  handleSubmit(values) {
    const { dispatch, formFilterShipment } = this.props;
    let tableFilter = {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "createdAt",
      sortDir: "desc",
      keyword: "",
      search: {}
    }

    tableFilter.search = {
      "startRedemptionDate": (formFilterShipment.values === undefined || formFilterShipment.values.startRedemptionDate === undefined) ? "" : formFilterShipment.values.startRedemptionDate,
      "endRedemptionDate": (formFilterShipment.values === undefined || formFilterShipment.values.endRedemptionDate === undefined) ? "" : formFilterShipment.values.endRedemptionDate,
      "campaign": (formFilterShipment.values === undefined || formFilterShipment.values.campaign === undefined || formFilterShipment.values.campaign === 'all') ? "" : formFilterShipment.values.campaign,
      "rewardName": (formFilterShipment.values === undefined || formFilterShipment.values.rewardName === undefined || formFilterShipment.values.rewardName === 'all') ? "" : formFilterShipment.values.rewardName,
      "status": (formFilterShipment.values === undefined || formFilterShipment.values.status === undefined) ? "" : formFilterShipment.values.status,
      "startShippingDate": (formFilterShipment.values === undefined || formFilterShipment.values.startShippingDate === undefined) ? "" : formFilterShipment.values.startShippingDate,
      "endShippingDate": (formFilterShipment.values === undefined || formFilterShipment.values.endShippingDate === undefined) ? "" : formFilterShipment.values.endShippingDate,
      "shippingCompany": (formFilterShipment.values === undefined || formFilterShipment.values.shippingCompany === "All") ? "" : formFilterShipment.values.shippingCompany,
      "trackingNo": (formFilterShipment.values === undefined || formFilterShipment.values.trackingNo === undefined) ? "" : formFilterShipment.values.trackingNo
    }
    const resetTable = true
    dispatch(shipmentActions.getTableDatas(tableFilter, resetTable));
  }

  componentDidMount() {
    this.getCampaignList()
  }

  getCampaignList() {
    const { dispatch } = this.props;
    let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"

    dispatch(shipmentActions.getCampaignList(reqParams))
  }

  clearFilter = () => {
    const { dispatch } = this.props;
    dispatch(change(FORM_NAME, 'campaign', ''));
    dispatch(change(FORM_NAME, 'rewardName', ''));
    dispatch(change(FORM_NAME, 'status', ''));
    dispatch(change(FORM_NAME, 'shippingCompany', ''));
    dispatch(change(FORM_NAME, 'trackingNo', ''));
    dispatch(change(FORM_NAME, 'startRedemptionDate', ''));
    dispatch(change(FORM_NAME, 'endRedemptionDate', ''));
    dispatch(change(FORM_NAME, 'startShippingDate', ''));
    dispatch(change(FORM_NAME, 'endShippingDate', ''));

    let tableFilter = {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "createdAt",
      sortDir: "desc",
      keyword: "",
      search: {}
    }
    const resetTable = true
    dispatch(shipmentActions.getTableDatas(tableFilter, resetTable));
  }

  addresslabel = () => {

    const { dispatch, formFilterShipment } = this.props
    var qryParams = {
      "startRedemptionDate": (formFilterShipment.values === undefined || formFilterShipment.values.startRedemptionDate === undefined) ? "" : formFilterShipment.values.startRedemptionDate.toISOString(),
      "endRedemptionDate": (formFilterShipment.values === undefined || formFilterShipment.values.endRedemptionDate === undefined) ? "" : formFilterShipment.values.endRedemptionDate.toISOString(),
      "campaign": (formFilterShipment.values === undefined || formFilterShipment.values.campaign === undefined || formFilterShipment.values.campaign === 'all') ? "" : formFilterShipment.values.campaign,
      "rewardName": (formFilterShipment.values === undefined || formFilterShipment.values.rewardName === undefined || formFilterShipment.values.rewardName === 'all') ? "" : formFilterShipment.values.rewardName,
      "status": (formFilterShipment.values === undefined || formFilterShipment.values.status === undefined) ? "" : formFilterShipment.values.status,
      "startShippingDate": (formFilterShipment.values === undefined || formFilterShipment.values.startShippingDate === undefined) ? "" : formFilterShipment.values.startShippingDate.toISOString(),
      "endShippingDate": (formFilterShipment.values === undefined || formFilterShipment.values.endShippingDate === undefined) ? "" : formFilterShipment.values.endShippingDate.toISOString(),
      "shippingCompany": (formFilterShipment.values === undefined || formFilterShipment.values.shippingCompany === undefined || formFilterShipment.values.shippingCompany === "All") ? "" : formFilterShipment.values.shippingCompany,
      "trackingNo": (formFilterShipment.values === undefined || formFilterShipment.values.trackingNo === undefined) ? "" : formFilterShipment.values.trackingNo,
    }

    dispatch(shipmentActions.getaddresslabelDetail(qryParams));
  }

  render() {
    const { classes, formFilterShipment, handleSubmit, shippingReport, merchantSetting, visiblePersonalData } = this.props;
    let shippingList = [];
    shippingList.push(
      {
        value: "All",
        label: "All"
      },
      {
        value: "Aden",
        label: "Aden"
      },
      {
        value: "Alpha Fast",
        label: "Alpha Fast"
      },
      {
        value: "CJ Logistics",
        label: "CJ Logistics"
      },
      {
        value: "Deliveree",
        label: "Deliveree"
      },
      {
        value: "DHL",
        label: "DHL"
      },
      {
        value: "FedEX",
        label: "FedEX"
      },
      {
        value: "Flash Express",
        label: "Flash Express"
      },
      {
        value: "IT Transport",
        label: "IT Transport"
      },
      {
        value: "J&T Express",
        label: "J&T Express"
      },
      {
        value: "Kerry Express",
        label: "Kerry Express"
      },
      {
        value: "LALAMOVE",
        label: "LALAMOVE"
      },
      {
        value: "MrSpeedy",
        label: "MrSpeedy"
      },
      {
        value: "NINJA VAN",
        label: "Ninja Van"
      },
      {
        value: "SCG Yamato Express",
        label: "SCG Yamato Express"
      },
      {
        value: "SendIT",
        label: "SendIT"
      },
      {
        value: "Thailand Post",
        label: "Thailand Post"
      },
      {
        value: "TNT",
        label: "TNT"
      },
      {
        value: "361 Express",
        label: "361 Express"
      },
      {
        value: "Others",
        label: "Others"
      },

    )
    let startMaxDateRedemption;
    if (lodash.get(formFilterShipment, 'values.endRedemptionDate')) {
      startMaxDateRedemption = formFilterShipment.values.endRedemptionDate;
    } else {
      startMaxDateRedemption = new Date();
    }

    let endMinDateRedemption;
    if (lodash.get(formFilterShipment, 'values.startRedemptionDate')) {
      endMinDateRedemption = formFilterShipment.values.startRedemptionDate;
    }

    let startMaxDateShipped;
    if (lodash.get(formFilterShipment, 'values.endShippingDate')) {
      startMaxDateShipped = formFilterShipment.values.endShippingDate;
    } else {
      startMaxDateShipped = new Date();
    }

    let endMinDateShipped;
    if (lodash.get(formFilterShipment, 'values.startShippingDate')) {
      endMinDateShipped = formFilterShipment.values.startShippingDate;
    }

    let campaignAllList = [];
    campaignAllList.push({
      value: "all",
      label: "All"
    })

    let rewardAllList = [];
    rewardAllList.push({
      value: "all",
      label: "All"
    })

    if (lodash.has(shippingReport, 'campaignList')) {
      lodash.forEach(shippingReport.campaignList, function (value, key) {
        campaignAllList.push({
          value: value.campaignName,
          label: value.campaignName,
          id: value._id,
          reward: value.rewardName
        })
        if (!Array.isArray(value.rewardName)) {
          rewardAllList.push({
            value: value.rewardName,
            label: value.rewardName,
            id: value._id,
            campaign: value.campaignName
          })
        } else {
          lodash.forEach(value.rewardName, function (valueReward, key) { //It ues when rewardName is array
            rewardAllList.push({
              value: valueReward,
              label: valueReward,
              id: value._id,
              campaign: value.campaignName
            })
          })
        }
      })
    }



    return (
      <>
        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>

          <CSVLink
            headers={[
              { label: "First name", key: "member.firstName" },
              { label: "Last name", key: "member.lastName" },
              { label: "Redemption Date", key: "transaction.date" },
              { label: "Campaign", key: "campaign.name" },
              { label: "Reward Name", key: "reward.name" },
              { label: "Receiver First name", key: "address.firstName" },
              { label: "Receiver Last name", key: "address.lastName" },
              { label: "Phone Number", key: "member.phone" },
              { label: "Address", key: "address.address" },
              { label: "SubDistrict", key: "address.subDistrict" },
              { label: "District", key: "address.district" },
              { label: "Province", key: "address.province" },
              { label: "Zipcode", key: "address.zipcode" },
              { label: "Status", key: "status" },
              { label: "Shipped Date", key: "shippedDate" },
              { label: "Shipping Company", key: "shipping.displayName" },
              { label: "Tracking No.", key: "trackingNo" }
            ]}
            data={this.state.csvData}
            filename="ShipmentReport.csv"
            style={{ display: "none" }}
            ref={this.csvLink}
            target="_blank"
          />
          {/* {plugins.isHasPermission("shipment", 'export') && plugins.isHasPackageFeatures('export') &&
            <Button onClick={this.exportCSV.bind(this)}
              style={{
                backgroundColor: "#02A536",
                marginTop: "20px",
                width: "160px",
                float: "right",
                marginRight: "10px"
              }}
              id='btn_export'
            >
              Export File .csv
            </Button>
          } */}

          {/* {
            (plugins.isHasPermission("shipment", 'export') && visiblePersonalData && merchantSetting.shippingLabel) &&
            <Button onClick={this.addresslabel}
              style={{
                backgroundColor: "#0C9AFC",
                marginTop: "20px",
                width: "160px",
                float: "right",
                marginRight: "10px"
              }}
              id='btn_address_label_report'
            >
              Address Label
            </Button>
          } */}

          <GridContainer className={classes.reportHeaderContainer}>
            <GridItem xs={6} sm={2}>
              <h6><b>Redemption Date</b></h6>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.reportHeaderContainer}>
            <GridItem xs={6} sm={2}>
              <Field
                id='filter_start_redemption_date'
                name="startRedemptionDate"
                component={CustomDatePicker}
                type="text"
                maxDate={startMaxDateRedemption}
                labelText="Start Date"
                dateFormat="DD-MMM-YY"
                timeFormat="HH:mm"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={2}>
              <Field
                id='filter_end_redemption_date'
                name="endRedemptionDate"
                minDate={endMinDateRedemption}
                maxDate={new Date()}
                component={CustomDatePicker}
                type="text"
                labelText="End Date"
                dateFormat="DD-MMM-YY"
                timeFormat="HH:mm"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={3}>
              <Field
                name="campaign"
                component={CustomSelect}
                type="text"
                labelText="Campaign"
                formControlProps={{
                  fullWidth: true
                }}
                optionsList={campaignAllList}
              />
            </GridItem>
            <GridItem xs={6} sm={3}>
              <Field
                name="rewardName"
                component={CustomSelect}
                type="text"
                labelText="Reward Name"
                formControlProps={{
                  fullWidth: true
                }}
                optionsList={rewardAllList}
              />
            </GridItem>
            {/* <GridItem xs={6} sm={3}>
              <Field
                name="campaign"
                component={CustomInput}
                type="text"
                labelText="Campaign"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem> */}
            {/* <GridItem xs={6} sm={3}>
              <Field
                name="rewardName"
                component={CustomInput}
                type="text"
                labelText="Reward Name"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem> */}
            <GridItem xs={6} sm={2}>
              <Field
                name="status"
                component={CustomSelect}
                type="text"
                labelText="Status"
                formControlProps={{
                  fullWidth: true
                }}
                optionsList={[
                  { value: "WAITING", label: "Waiting for shipment" },
                  { value: "SHIPPED", label: "Shipped" },
                  { value: "CANCELLED", label: "Cancelled" }
                ]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.reportHeaderContainer}>
            <GridItem xs={6} sm={2}>
              <h6><b>Shipped Date</b></h6>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.reportHeaderContainer}>
            <GridItem xs={6} sm={2}>
              <Field
                id='filter_start_shipping_date'
                name="startShippingDate"
                component={CustomDatePicker}
                type="text"
                maxDate={startMaxDateShipped}
                labelText="Start Date"
                dateFormat="DD-MMM-YY"
                timeFormat="HH:mm"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={2}>
              <Field
                id='filter_end_shipping_date'
                name="endShippingDate"
                minDate={endMinDateShipped}
                maxDate={new Date()}
                component={CustomDatePicker}
                type="text"
                labelText="End Date"
                dateFormat="DD-MMM-YY"
                timeFormat="HH:mm"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={4}>
              <Field
                name="shippingCompany"
                component={CustomSelect}
                type="text"
                labelText="Shipping Company"
                formControlProps={{
                  fullWidth: true
                }}
                optionsList={shippingList}
              />
            </GridItem>
            <GridItem xs={6} sm={4}>
              <Field
                name="trackingNo"
                component={CustomInput}
                type="text"
                labelText="Tracking No."
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={3}>
              <Button
                type="submit"
                style={{ marginRight: "10px", marginTop: "25px" }}
                size="sm"
                color="primary"
                id='btn_submit'>
                Submit
                    </Button>
              <Button color="white" className={classes.btnClearFilter} onClick={this.clearFilter} size="sm" id='btn_cancel'>
                Clear
            </Button>
            </GridItem>

          </GridContainer>
        </form>
        <GridContainer className={classes.mt20}>
          <GridItem xs={12}>
            <TableShipmentList showAction={false} showSearch={false} />
          </GridItem>
        </GridContainer>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { sweetAlert } = state.sweetAlert;
  const { permission, superAdmin, merchantSetting, visiblePersonalData } = state.authentication;
  const filterValues = getFormValues(FORM_NAME)(state)
  const { formFilterShipment } = state.form;
  const { tableDatas, tableTotal, submitForm, shippingReport } = state.shipment;
  return {
    sweetAlert, permission, superAdmin, filterValues, tableTotal, tableDatas, formFilterShipment, submitForm, shippingReport, merchantSetting, visiblePersonalData
  };
}
const connectFilterShipmentForm = connect(mapStateToProps)(ShipmentReport);

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  onChange: (values, dispatch, props, previousValues) => {
    if (values !== previousValues) {
      dispatch({ type: shipmentConstants.TABLE_RESET });
    }
  }
})(withStyles(pageStyle)(connectFilterShipmentForm))
import React from 'react';
import { connect } from 'react-redux';
import { Field,reduxForm } from 'redux-form';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';

// core components

const style = {
  reportHeaderContainer: {
    margin: "0px",
    width: "calc(100% - 10px)",
    padding: "0px",
  },
  btnClearFilter: {
    marginRight: "10px",
    marginTop: "25px"
  },
  textCenter: {
    textAlign: "center"
  },
  mt20: {
    marginTop: "20px"
  }
};

const pageStyle = { ...style };
const FORM_NAME = "formSearchCodeResult";

class SearchCodeResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: ''
        };
    }

    render() {
        const { classes, formOpen, searchDatas } = this.props
        const displayCard = (formOpen) ? {} : {display: "none" }

        let detailList;
        let displayCardDetail = {};

        if(searchDatas.data.detail){
          detailList = Object.keys(searchDatas.data.detail).map(key => 
            <li key={key}><b>{key.replace("_", " ")}  :</b> {searchDatas.data.detail[key]}</li>
          )  

          if(detailList.length == 0){
            displayCardDetail = { display: "none" };
          }
        }

        return (
            <Card>
              <CardBody style={displayCard}>
                <form name={FORM_NAME}>
                  <GridContainer>
                        <GridItem xs={6} sm={3}>
                            <h6><b>Result</b></h6>
                        </GridItem>
                  </GridContainer>

                  <GridContainer>
                      <GridItem xs={6} sm={3}>
                        <Field
                            name="result"
                            component={CustomInput}
                            type="text"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={true}
                        />
                      </GridItem>
                      <GridItem xs={1} sm={1}>
                      <Tooltip title=
                      {
                        <React.Fragment>
                          <ul>
                            <li><b>{'Available: '}</b> {'exist and not redeem'}</li>
                            <li><b>{'Redeemed: '}</b> {'exist but redeemed'}</li>
                            <li><b>{'Not found: '}</b> {'not exist'}</li>
                          </ul>
                        </React.Fragment>
                      }
                      placement="right"
                      >
                          <IconButton className="">
                            <InfoIcon />
                          </IconButton>
                      </Tooltip>
                      </GridItem>
                  </GridContainer>

                  <div style={displayCardDetail}>
                    <GridContainer>
                          <GridItem xs={6} sm={6}>
                              <h6><b>Detail</b></h6>
                          </GridItem>
                    </GridContainer>

                    <GridContainer>
                          <GridItem xs={6} sm={6}>
                            <ul>
                              {detailList}
                            </ul>
                          </GridItem>
                    </GridContainer>
                  </div>
                </form>
              </CardBody>
            </Card>
        )
    }

}

const connectSearchCodeResult = connect(mapStateToProps)(SearchCodeResult);

function mapStateToProps(state) {
  const { formOpen, formInitialValue, searchDatas } = state.search_code;
  return {
      formOpen, formInitialValue, searchDatas
  };
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  onChange: (values, dispatch, props, previousValues) => {
    // if (values !== previousValues) {
    //   dispatch({ type: SearchCodeContainer.TABLE_RESET });
    // }
  }
})(withStyles(pageStyle)(connectSearchCodeResult));

import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';

export const memberServices = {
   addNewMember,
   editMember,
   deleteMember,
   getListsMember,
   getStatisticsMember,
   getActiveTagList,
   getActiveMemberLevelList,
   getMemberReport,
   checkMemberLimit
   // checkPermission
};

function addNewMember(params) {
   var tmp = axios.post(configConstants.API_BACKEND_CRM + '/users', params, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

function editMember(params) {
   var tmp = axios.put(configConstants.API_BACKEND_CRM + `/users/${params._id}`, params, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

function deleteMember(params) {
   var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/users/${params._id}`, { headers: setHeaders() })
      .then(response => {
         if (response.status.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

// function checkPermission(moduleMethod){
//    var req_headers = {
//       "Content-Type": "application/json",
//       "Authorization": localStorage.getItem('token')
//    }
//    var tmp = axios.delete( configConstants.API_BACKEND_CRM + `/admins/auth/role-check?moduleName=${moduleName}&moduleMethod=${moduleMethod}`, {headers: req_headers})
//       .then(response => {
//          if(response.status.toString() === "200"){
//             return {status: true, data: response.data.data, message: response.data.message};
//          }else{
//             return {status: false, data:{}, message: response.data.message};
//          }
//       });

//    return tmp;
// }

function getListsMember(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/users?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getStatisticsMember() {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/statistics/user`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: 'สัญญาณอินเตอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง' };
      });

   return tmp;
}

function getActiveTagList() {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/users/tag/list`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: e.message };
      });

   return tmp;
}

function getActiveMemberLevelList() {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/users/member-level/list`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {

            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: e.message };
      });

   return tmp;
}

function getMemberReport(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/members?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function checkMemberLimit() {
   let tmp = axios.get(configConstants.API_BACKEND_CRM + `/users/check/member-limit`, {
      headers: setHeaders()
   }).then(response => {
      if (response.data.statusCode.toString() === "200") {
         return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
         history.push('/auth/login')
         return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
         return { status: false, data: {}, message: response.data.message };
      }
   });
   return tmp;
}
import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import withStyles from "@material-ui/core/styles/withStyles"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import NavPills from "components/NavPills/NavPills.jsx"
import { plugins } from "helpers"
import TrueMoneyTransactionReport from "./TrueMoneyTransactionReport.jsx"
import TrueMoneySummaryReward from "./TruemoneySummary.jsx"
const style = {}

class TrueMoneyReportContainer extends React.Component {
  setTab = () => {
    let set = []
    const startDate = moment().startOf('month')
    const endDate = moment().set('hour',23).set('minute',59).set('second',59)
    if (plugins.isHasPermission("report[tmn_transaction]", "read")) {
      set.push({
        tabButton: "TRANSACTION",
        tabContent: (
          <TrueMoneyTransactionReport initialValues={{ startDate:startDate , endDate:endDate }} />
          )
      })
    }

    if (plugins.isHasPermission("report[tmn_summary]", "read")) {
      set.push({
        tabButton: "TRUE MONEY CAMPAIGN",
        tabContent: (
          <TrueMoneySummaryReward initialValues={{ startDate:startDate , endDate:endDate }} />
          )
      })
    }

    return set
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch({ type: "TRUEMONEY_REPORT_REDUCER_INIT" })
  }

  render() {
    return (
      <Card>
        <CardBody>
          <NavPills color="warning" tabBtnType="square" tabs={this.setTab()} />
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  const { tableReset } = state.member
  const { user, superAdmin, permission } = state.authentication
  return {
    tableReset,
    user,
    superAdmin,
    permission,
  }
}
const connectReportContainer = connect(mapStateToProps)(
  TrueMoneyReportContainer
)

export default withStyles(style)(connectReportContainer)

import React, { useEffect, useRef } from "react";
import { connect } from 'react-redux';
import moment from 'moment'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import PointByPurchaseReport from "./PointByPurchaseReport.jsx";
import PointByPurchaseForm from "views/PointByPurchase/PointByPurchaseForm.jsx";
const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

function PointByPurchaseContainer({ ...props }) {
    const { formOpen, formInitialValue } = props
    const displayCard = formOpen ? {display: "none" } : {}
    return (
        <GridContainer> 
            <GridItem xs={12}>
                {formOpen &&
                    <PointByPurchaseForm initialValues={formInitialValue} />
                }
                <Card style={displayCard}>
                    <CardBody>
                        <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>POINT BY PURCHASE</h4>
                        <CustomSweetAlert />
                        <GridContainer>
                            <GridItem xs={12}>
                                <PointByPurchaseReport initialValues={{ 
                                    startDate: moment().startOf('day'), 
                                    endDate: moment().set('hour',23).set('minute',59).set('second',59), 
                                    purchaseStartDate: moment().startOf('day'),
                                    purchaseEndDate:  moment().set('hour',23).set('minute',59).set('second',59), }} />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}
function mapStateToProps(state) {
    const { formInitialValue, formOpen } = state.point_by_purchase;
    return {
      formInitialValue, formOpen
    };
  }  
export default connect(mapStateToProps)(withStyles(style)(PointByPurchaseContainer));

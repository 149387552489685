import React from "react";
import { connect } from 'react-redux';
import { change, Field, getFormValues } from 'redux-form';
import lodash from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import Button from '@material-ui/core/Button';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { promotionConstants, swalConstants } from "../../../constants"
import red from '@material-ui/core/colors/red';
import { plugins } from 'helpers';
const moduleName = 'promotion'

const style = {
    overrides: {
        MuiTableRow: {
            root: {
                height: "30px"
            }
        }
    },
    tdBordered: {
        border: "solid 1px #ddd",
        height: "30px"
    }
}

const THEME = createMuiTheme(style);

const getTypeName = (typeName) => {
    let displayName
    if (typeName === "purchase_product") {
        displayName = "Purchase Product"
    } else if (typeName === "purchase_amount_per_bill") {
        displayName = "Purchase Amount per Bill"
    } else if (typeName === "payment_channel") {
        displayName = "Payment Channel"
    } else if (typeName === "promotion_code") {
        displayName = "Promotion Code"
    }
    return displayName
}

const renderFieldText = ({ ...props }) => {
    const { meta: { error, touched } } = props
    return (
        <div style={{ color: red[500] }}>{(touched && error) ? error : ""}</div>
    )
}

const validateConditionType = (value, allValues, props, name) => {
    let conditionsValue = lodash.get(allValues, 'conditions')
    let conditionTypes = lodash.get(allValues, 'conditionTypes')
    //* name = is FieldName must be `${xxx}_validator` *
    //${xxx}_validator is used for (redux-from fieldlevel-validation), it's a hidden field input for check values of promotion-conditions

    if ((lodash.indexOf(conditionTypes, 'purchase_amount_per_bill') > -1) && name === 'purchase_amount_per_bill_validator') {
        let findPerbillCond = lodash.find(conditionsValue, function (o) { return o.per_bill > 0 })
        if (!findPerbillCond) {
            return "Purchase Amount per Bill is required"
        }
    } else if ((lodash.indexOf(conditionTypes, 'purchase_product') > -1) && name === 'purchase_product_validator') {
        let findPurchaseProductCond = lodash.find(conditionsValue, function (o) { return lodash.size(lodash.get(o, 'conditions')) > 0 })
        if (!findPurchaseProductCond) {
            return "Purchase Product is required"
        }
    } else if ((lodash.indexOf(conditionTypes, 'payment_channel') > -1) && name === 'payment_channel_validator') {
        let findPaymentChCond = lodash.find(conditionsValue, function (o) { return lodash.size(lodash.get(o, 'paymentChannel')) > 0 })
        if (!findPaymentChCond) {
            return "Payment Channel is required"
        }
    } else if ((lodash.indexOf(conditionTypes, 'promotion_code') > -1) && name === 'promotion_code_validator') {
        let findPromoCond = lodash.find(conditionsValue, function (o) { return lodash.get(o, 'promotionCode') })
        if (!findPromoCond) {
            return "Promotion Code is required"
        }
    }
}

const ConditionBox = ({ ...props }) => {
    const { classes, formValues, dispatch, readOnly } = props
    let { conditionTypes = [] } = formValues

    const openModalCondition = (conditionType) => {
        switch (conditionType) {
            case 'purchase_product': {
                dispatch({ type: promotionConstants.OPEN_PURCHASE_PRODUCT_CONDITION })
                break
            }
            case 'purchase_amount_per_bill': {
                dispatch({ type: promotionConstants.OPEN_PURCHASE_AMOUNT_CONDITION })
                break
            }
            case 'promotion_code': {
                dispatch({ type: promotionConstants.OPEN_PROMOCODE_CONDITION })
                break
            }
            case 'payment_channel': {
                dispatch({ type: promotionConstants.OPEN_PAYMENT_CHANNEL_CONDITION })
                break
            }
            default: {
                return false
            }
        }
    }

    const deleteCondition = (typeName) => {
        let conditions = lodash.get(formValues, 'conditions')
        lodash.remove(conditions, function (n) {
            return n.type === typeName
        });

        lodash.remove(conditionTypes, function (n) {
            return n === typeName
        });

        dispatch(change('promotion_form', 'conditionTypes', conditionTypes))
        dispatch(change('promotion_form', 'conditionTypesKey', lodash.size(conditionTypes)))
        dispatch(change('promotion_form', 'conditions', conditions))
    }

    const getButtonText = (typeName) => {
        let returnText = "ADD"
        let conditions = lodash.get(formValues, 'conditions')
        let conditionTypeValue = lodash.find(conditions, { type: typeName })
        if (conditionTypeValue) {
            if (plugins.isHasPermission(moduleName, 'update')) {
                returnText = "EDIT"
            } else {
                returnText = "VIEW"
            }
        }
        return returnText
    }

    if (lodash.size(conditionTypes) > 0) {
        return (
            <MuiThemeProvider theme={THEME}>
                <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                        <TableRow>
                            <TableCell key="condition_header" className={classes.tdBordered} align="center"><b>Condition Type</b></TableCell>
                            <TableCell key="condition_header_action" className={classes.tdBordered}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {conditionTypes.map((typeName, index) => {
                            return (
                                <React.Fragment key={`PromotionCondTableCell_${index}`}>
                                    { (index > 0) && (
                                        <TableRow key={`tablecell_and_${index}`}>
                                            <TableCell colSpan="2" className={classes.tdBordered}><b>AND</b></TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow key={`tablecell_${typeName}_${index}`}>
                                        <TableCell className={classes.tdBordered} key={`tablecell_${typeName}_${index}_0`}>
                                            {getTypeName(typeName)}
                                            <Field
                                                name={`${typeName}_validator`}
                                                validate={[validateConditionType]}
                                                component={renderFieldText}
                                            />
                                        </TableCell>
                                        <TableCell
                                            className={classes.tdBordered}
                                            size="sm"
                                            key={`tablecell_${typeName}_${index}_1`}
                                            align="center">
                                            <Button
                                                style={{ backgroundColor: "#eee" }}
                                                onClick={() => {
                                                openModalCondition(typeName)
                                            }}>{getButtonText(typeName)}</Button>

                                            {!readOnly &&
                                                <TableActionButton
                                                    actionType="delete"
                                                    onClick={(e) => {
                                                        dispatch({
                                                            type: swalConstants.SHOW, data: {
                                                                type: "warning",
                                                                title: "Are you sure you want to delete this condition?",
                                                                message: `"${getTypeName(typeName)}"`,
                                                                onConfirm: function () {
                                                                    deleteCondition(typeName)
                                                                    dispatch({ type: swalConstants.HIDE })
                                                                },
                                                                onCancel: function () {
                                                                    dispatch({ type: swalConstants.HIDE })
                                                                },
                                                                showCancel: true,
                                                                confirmBtnText: "Delete",
                                                                cancelBtnText: "Cancel"
                                                            }
                                                        })
                                                    }}
                                                />
                                            }
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </MuiThemeProvider>
        )
    } else {
        return null
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode } = state.promotion;
    const formValues = getFormValues("promotion_form")(state)
    return {
        formOpen, formMode, formValues
    };
}
const connectedForm = connect(mapStateToProps)(ConditionBox);
export default withStyles(style)(connectedForm)

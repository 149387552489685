import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from "@material-ui/core/FormHelperText";
import withStyles from "@material-ui/core/styles/withStyles";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import i18n from 'i18n';

function CustomRadio({ ...props }) {
   const {
      input,
      classes,
      meta,
      radioLists,
      inline,
      rest,
      id,
   } = props;

   const success = (meta !== undefined && meta.touched && meta.valid);
   const error = (meta !== undefined && meta.touched && meta.invalid);
   const errorLabel = error ? " " + classes.labelRootError : ""

   const helpTextClasses = classNames({
      [classes.labelRootError]: error,
      [classes.labelRootSuccess]: success && !error
  });

  const formStyle = inline ? { display: "block" } : {}

   return (
      <>
         <FormControl>
            <RadioGroup {...input} {...rest} style={formStyle}>
            {radioLists.map((obj, index) => {
               return <FormControlLabel 
                  key={index}
                  classes={{
                     label: classes.label + errorLabel
                  }} 
                  style={obj.style}
                  value={obj.value} 
                  control={<Radio />} 
                  label={obj.label} 
                  disabled={obj.disabled} 
                  id={id + "-"+obj.value }
               />
            })}
            </RadioGroup>
            {(meta !== undefined && meta.touched && meta.invalid) === true ? (
                <FormHelperText id={id + "-radio"} className={helpTextClasses}>
                    {i18n.t(meta.error)}
                </FormHelperText>
            ) : null}
         </FormControl>
      </>
   );
}

CustomRadio.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
};

export default withStyles(customCheckboxRadioSwitch)(CustomRadio);

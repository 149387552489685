import _ from 'lodash';
import { dfconfigConstants } from '../constants';
const initialState = {
  tableTemplateList: [],
  databaseFieldsList: [],
  showLoader: false,
  formOpen: false,
  formMode: 'add',
  isLoading: false,
  tableConfigId: '',
  formInitialValue: {
    dbtabletemplate : ['All'],
  },
    
}

export function tableTemplateList(state = initialState, action) {
  switch (action.type) {
    case dfconfigConstants.INIT_LIST_CONSTANT: {
      return initialState
    }
    case dfconfigConstants.SET_TABLE_TEMPLATE_LIST:{
      return {
        ...state,
        dbtabletemplate: ['USER']
      }
    }
    case dfconfigConstants.GET_TABLE_TEMPLATE_LIST: {
      let dataInitial = {}
      let dbTemplateAllList = []
      let databaseFieldsList = []
        dbTemplateAllList.push({
            _id: "All",
            name: "All"
        })
          if (_.has(action.data, 'filtering')) {
            _.forEach(action.data.filtering, function (value, key) {
                dbTemplateAllList.push({
                    _id: value.tableName,
                    name: value.tableName,
                    value: value.fields
                })
                databaseFieldsList.push({
                    _id: value.tableName,
                    name: value.tableName,
                    value: value.fields
                })
            })
            
        }
      return {
        ...state,
        tableTemplateList: dbTemplateAllList,
        databaseFieldsList: databaseFieldsList
      }
    }
    case dfconfigConstants.ADD_NEW_DF_CONFIG_SUCCESS: {
      let tableConfigId = ''
      if (_.has(action.data, 'tableConfigId')) {
        tableConfigId = action.data.tableConfigId;
      }
      return {
          ...state,
          tableConfigId: tableConfigId,
          formOpen: false,
          formMode: 'edit',
      }
    }
    case dfconfigConstants.CHECK_TABLE_TEMPLATE_CONFIG: {
      return {
          ...state,
          formOpen: false,
          formMode: 'edit',
          // formInitialValue: {}
      }
    }
    case dfconfigConstants.EDIT_NEW_DF_CONFIG_SUCCESS: {
      let databaseFieldsList = []        
          if (_.has(action.data, 'filtering')) {
            _.forEach(action.data.filtering, function (value, key) {
                databaseFieldsList.push({
                    _id: value.tableName,
                    name: value.tableName,
                    value: value.fields
                })
            })
            
        }
      return {
        ...state,
        formMode: 'edit',
        // databaseFieldsList: databaseFieldsList
      }
    }
    case dfconfigConstants.GET_FIELD_TEMPLATE_LIST: {
      let dbTemplateAllList = []
      let databaseFieldsList = []
      let NewdatabaseFieldsList = {}
      let newfield = {}
      let tableConfigId = ''
      dbTemplateAllList.push({
            _id: "All",
            name: "All"
        })
        if (_.has(action.data, 'tableConfigId')) {
          tableConfigId = action.data.tableConfigId;
        }
          if (_.has(action.data, 'filtering')) {
            _.forEach(action.data.filtering, function (value, key) {
              dbTemplateAllList.push({
                    _id: value.tableName,
                    name: value.tableName,
                    value: value.fields
                })
                let dbName = value.tableName
                let fieldName = value.fields
                databaseFieldsList.push({
                    _id: value.tableName,
                    name: value.tableName,
                    fields: value.fields
                })

                _.forEach(fieldName, function (fieldValue, fieldkey) {
                  let field = fieldValue.fieldName
                  newfield[field] = fieldValue
                })
                NewdatabaseFieldsList[dbName] = newfield
                newfield = {}
                
            })
            
        }
      return {
        ...state,
        formMode: 'edit',
        tableConfigId: tableConfigId,
        tableTemplateList: dbTemplateAllList,
        databaseFieldsList: databaseFieldsList,
        newdatabaseFieldsList: NewdatabaseFieldsList
      }
    }
    case dfconfigConstants.SEARCH_FIELD_TEMPLATE_LIST: {
      
      let NewdatabaseFieldsList = {}
      let newfield = {}
      if (_.has(action.data, 'filtering')) {
        _.forEach(action.data.filtering, function (value, key) {
          
            let dbName = value.tableName
            let fieldName = value.fields
            _.forEach(fieldName, function (fieldValue, fieldkey) {
              let field = fieldValue.fieldName
              newfield[field] = fieldValue
            })
            NewdatabaseFieldsList[dbName] = newfield
            newfield = {}
            
        })
        
    }
      return {
        ...state,
        newdatabaseFieldsList: NewdatabaseFieldsList
      }
    }
    default:
      return state
  }
}

import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

//page components
import TableMerchantList from "views/Merchant/TableMerchantList.jsx";
import MerchantManagement from "views/Merchant/MerchantManagement.jsx";

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const pageStyle = { ...style, ...sweetAlertStyle };

class MerchantContainer extends React.Component {
  render() {
    const { formInitialValue, formOpen } = this.props;
    const displayCard = formOpen ? {display: "none" } : {}
   
    return (
      <div>
          <MerchantManagement
            initialValues={formInitialValue}
            readOnly={false}
          />
          <Card style={displayCard}>
            <CardBody>
              {/* <CustomSweetAlert /> */}
              <GridContainer>
                <GridItem xs={12}>
                  <TableMerchantList />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
      </div>
    )
  }

}

function mapStateToProps(state) {
  const { formInitialValue, formOpen } = state.merchant;
  return {
    formInitialValue, formOpen
  };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(MerchantContainer));


export const FORM_VALIDATE = {
  "shopId": {
    rules: {
      required: true,
    },
    messages: {
      required: "warning_messages.shop_id_is_request",
    },
    alert: "warning_messages.shop_id_is_request",
  },
  "shopName": {
    rules: {
      required: true,
    },
    messages: {
      required: "warning_messages.shop_name_is_request",
    },
    alert: "warning_messages.shop_name_is_request",
  },
  "status": {
    rules: {
      required: true,
    },
    messages: {
      required: "warning_messages.status_is_required",
    },
    alert: "warning_messages.status_is_required",
  },
  "startDate" : {
    rules: {
        sameDateTime : true
    },
    messages: {
        sameDateTime: 'Can not select the same value as EndDate'
    },
    alert: 'Can not select the same value as EndDate'
  },
  "endDate" : {
    rules: {
        sameDateTime : true,
        requireStartDate : true
    },
    messages: {
        requireStartDate : "Please select start date",
        sameDateTime: 'Can not select the same value as StartDate'
    },
    alert: 'Can not select the same value as StartDate'
  }
}


import React, { Component } from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, change } from "redux-form";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { promotionConstants } from "../../../constants";
import { promotionActions } from "actions";

const styles = (theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto",
    },
    tableHead: {
        backgroundColor: "#eee",
        position: "sticky",
        top: 0,
        padding: "0px 10px",
        zIndex: 10,
    },
    tableCell: {
        padding: "0px 10px",
    },
    tableCellLoader: {
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    tableHover: {
        transitionDuration: "0.3s",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#efefef",
        },
    },
    tableSelectShops: {
        maxHeight: "300px",
        minHeight: "300px",
        overflow: "auto",
        marginBottom: "10px",
        border: "solid 1px #eee",
    },
    actionButton: {
        margin: "0 0 0 5px",
        padding: "5px",
        "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
            marginRight: "0px",
        },
    },
    noPaddingTop: {
        "& > div": {
            paddingTop: "0px",
        },
    },
    textUpper: {
        textTransform: "uppercase",
    },
    haveNoData: {
        color: "#777",
        fontStyle: "italic",
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    tableError: {
        border: "solid 2px red",
    },
});

export class AdditionalShop extends Component {
    constructor(props) {
        super(props);
        this.onSearchShop = lodash.debounce((searchBox, keyword) => {
            this.searchShopList(searchBox, keyword);
        }, 800);
        this.onGetAllShop = lodash.debounce((shopSelected) => {
            this.getAllShopList(shopSelected);
        }, 800);
        this.state = {
            shopListSearch: null,
            selectedSearch: null,
        };
    }

    componentDidMount() {
        const { dispatch, formMode, regionSelected, formName } = this.props;
        this.getRegionList();
        if (formMode === "edit") {
            if (lodash.isEmpty(regionSelected) === false) {
                for (let regionId of regionSelected) {
                    dispatch(change(formName, `region-${regionId}`, true));
                }
            }
            this.onGetAllShop(regionSelected);
            //   dispatch(
            //     change(
            //       "FORM_EARNRULES",
            //       "checkBoxRegionSelected",
            //       regionSelected
            //     )
            //   )
        }
    }

    componentWillUnmount() {
        const { dispatch, regionSelected } = this.props;
        dispatch({ type: promotionConstants.PROMOTION_CLEAR_SHOP_LIST });
        for (let regionId of regionSelected) {
            //   dispatch(change("FORM_EARNRULES", `region-${regionId}`, false))
        }
    }

    componentDidUpdate() { }

    getRegionList() {
        const { dispatch } = this.props;
        dispatch(promotionActions.getRegionList());
    }

    handleRegionSelect(event) {
        const key = event.target.id;
        const IsChecked = event.target.checked;
        let data = {
            shopRegionId: lodash.replace(key, "checkbox-region-", ""),
            isSelected: IsChecked,
        };
        this.filterShopListByRegion(data);
    }

    filterShopListByRegion(data) {
        const { regionSelected, dispatch } = this.props;
        const { shopRegionId, isSelected } = data;
        let _regionSelected = [...regionSelected];
        if (isSelected) {
            _regionSelected = [..._regionSelected, shopRegionId];
        } else {
            _regionSelected = lodash.filter(_regionSelected, (data) => {
                return data !== shopRegionId;
            });
        }
        dispatch({
            type: promotionConstants.PROMOTION_SELECTED_REGION,
            data: _regionSelected,
        });
        // dispatch(
        //   change(
        //     "FORM_EARNRULES",
        //     "checkBoxRegionSelected",
        //     _regionSelected
        //   )
        // )
        const { shopListSearch, selectedSearch } = this.state;
        if (shopListSearch !== null) {
            shopListSearch.value = "";
        }
        if (selectedSearch !== null) {
            selectedSearch.value = "";
        }
        this.onGetAllShop(_regionSelected);
    }

    getAllShopList(regionSelected) {
        const { dispatch, allShopListSelected } = this.props;
        const selectedFilterData = lodash.filter(
            allShopListSelected,
            (shopSelectedData) => {
                return regionSelected.includes(shopSelectedData.shopRegionId);
            }
        );

        const reverseRegion = lodash.cloneDeep(regionSelected);

        lodash.reverse(reverseRegion);
        let params = "";
        for (let regionIndex in reverseRegion) {
            if (+regionIndex + 1 === reverseRegion.length) {
                params = `${params}region=${reverseRegion[regionIndex]}`;
            } else {
                params = `${params}region=${reverseRegion[regionIndex]}&`;
            }
        }
        dispatch(promotionActions.getAllShopList(params, selectedFilterData));
    }

    renderShopList() {
        const { classes, shopList = [], allShopList, readOnly = true } = this.props;
        const html = (
            <>
                {shopList &&
                    shopList.map((data, index) => {
                        const regionName = lodash.get(
                            data,
                            "shopRegion.shopRegionName",
                            "Not Specified"
                        );
                        return (
                            <TableRow
                                key={index}
                                className={classes.tableHover}
                                onClick={() => {
                                    if (!readOnly) {
                                        this.selectShopItem(data);
                                    }
                                }}
                                id={"shop-" + data._id}
                            >
                                <TableCell className={classes.tableCell}>
                                    {`${data.shopName} (${data.shopId}) `}
                                </TableCell>
                            </TableRow>
                        );
                    })}
            </>
        );
        // const notFoundSearch = (
        //   <TableRow
        //   key={'shop-notFound'}
        //   id={'shop-notFound'}>
        //   <TableCell style={{textAlign: 'center'}} className={classes.tableCell}>
        //    Not Found
        //   </TableCell>
        // </TableRow>
        // )

        return html;
    }

    renderSelectedShopList() {
        const {
            classes,
            shopListSelected = [],
            allShopListSelected,
            readOnly = true,
        } = this.props;
        const html = (
            <>
                {shopListSelected &&
                    shopListSelected.map((data, index) => {
                        const regionName = lodash.get(
                            data,
                            "shopRegion.shopRegionName",
                            "Not Specified"
                        );
                        return (
                            <TableRow
                                key={index}
                                className={classes.tableHover}
                                onClick={() => {
                                    if (!readOnly) {
                                        this.unSelectShopItem(data);
                                    }
                                }}
                                id={"shopSelected-" + data._id}
                            >
                                <TableCell className={classes.tableCell}>
                                    {`${data.shopName} (${data.shopId})`}
                                </TableCell>
                            </TableRow>
                        );
                    })}
            </>
        );

        // const notFoundSearch = (
        //   <TableRow
        //   key={'shop-notFound'}
        //   id={'shop-notFound'}>
        //   <TableCell style={{textAlign: 'center'}} className={classes.tableCell}>
        //    Not Found
        //   </TableCell>
        // </TableRow>
        // )

        return html;
    }

    selectShopItem(data) {
        const { dispatch, shopList, allShopList } = this.props;
        const { _id } = data;
        const filterShop = (shopData, shopId) => {
            return lodash.filter(shopData, (shopListData) => {
                return shopListData._id !== shopId;
            });
        };

        const shopListFilter = filterShop(shopList, _id);
        const allShopListFilter = filterShop(allShopList, _id);

        const shopListSelected = lodash.filter(shopList, (data) => {
            return data._id === _id;
        });

        const selectData = {
            shopList: shopListFilter,
            shopListSelected: shopListSelected,
            allShopList: allShopListFilter,
        };
        dispatch({
            type: promotionConstants.PROMOTION_SELECT_SHOP,
            data: selectData,
        });
        // dispatch(
        // //   change("FORM_EARNRULES", "shopCondition[shopList]", shopListSelected)
        // )
    }

    unSelectShopItem(data) {
        const {
            dispatch,
            regionSelected,
            allShopListSelected,
            shopListSelected,
        } = this.props;
        const { _id } = data;
        const filterShop = (shopData, shopId) => {
            return lodash.filter(shopData, (shopListData) => {
                return shopListData._id !== shopId;
            });
        };
        const shopListSelectFilter = filterShop(shopListSelected, _id);
        const allShopListSelectFilter = filterShop(allShopListSelected, _id);

        const shopRegionId = lodash.get(data, "shopRegionId") || "not_specified";
        const isRegionSelected =
            lodash.indexOf(regionSelected, shopRegionId) >= 0 ? true : false;
        let shopListUnselected = [];
        if (isRegionSelected) {
            shopListUnselected = lodash.filter(shopListSelected, (shopListData) => {
                return shopListData._id === _id;
            });
        }

        const selectData = {
            shopList: shopListUnselected,
            shopListSelected: shopListSelectFilter,
            allShopListSelected: allShopListSelectFilter,
        };
        dispatch({
            type: promotionConstants.PROMOTION_UNSELECT_SHOP,
            data: selectData,
        });
        // dispatch(
        //   change(
        //     "FORM_EARNRULES",
        //     "shopCondition[shopList]",
        //     allShopListSelectFilter
        //   )
        // )
    }

    searchShopList(searchBox, keyword) {
        const { dispatch, allShopList, allShopListSelected } = this.props;
        keyword = keyword.toLowerCase();
        if (searchBox === "shopList") {
            const shopListResult = allShopList.filter((data) => {
                return (
                    data.shopName.toLowerCase().includes(keyword) ||
                    data.shopId.toLowerCase().includes(keyword)
                );
            });
            dispatch({
                type: promotionConstants.PROMOTION_SEARCH_SHOP_LIST,
                data: shopListResult,
            });
        }

        if (searchBox === "shopListSelected") {
            const shopListSelectedResult = allShopListSelected.filter((data) => {
                return (
                    data.shopName.toLowerCase().includes(keyword) ||
                    data.shopId.toLowerCase().includes(keyword)
                );
            });
            dispatch({
                type: promotionConstants.PROMOTION_SEARCH_SHOP_SELECTED,
                data: shopListSelectedResult,
            });
        }
    }

    selectAllShopList(selectMode) {
        const {
            dispatch,
            shopListSelected,
            shopList,
            regionSelected,
            allShopList,
            allShopListSelected,
        } = this.props;
        if (selectMode === "selectAll") {
            const differenceShopList = lodash.differenceBy(
                allShopList,
                shopList,
                (value) => {
                    return value._id;
                }
            );
            dispatch({
                type: promotionConstants.PROMOTION_SELECT_ALL_SHOP_LIST,
                data: {
                    shopList,
                    differenceShopList,
                },
            });
            //   dispatch(change("FORM_EARNRULES", "shopCondition[shopList]", allShopList))
        }

        if (selectMode === "unSelectAll") {
            const differenceShopListSelected = lodash.differenceBy(
                allShopListSelected,
                shopListSelected,
                (value) => {
                    return value._id;
                }
            );

            //    const _shopListSelected =  lodash.filter(shopListSelected, (selectShopData)=>{
            //       return regionSelected.includes(selectShopData.shopRegionId)
            //     })
            dispatch({
                type: promotionConstants.PROMOTION_UNSELECT_ALL_SHOP_LIST,
                data: {
                    differenceShopListSelected,
                    shopListSelected,
                },
            });
            //   dispatch(change("FORM_EARNRULES", "shopCondition[shopList]", []))
        }
    }

    render() {
        const {
            readOnly = true,
            formMode,
            classes,
            regionList = [],
            shopList,
            shopListSelected,
            allShopList,
            allShopListSelected,
        } = this.props;

        const _regionList = [
            ...regionList,
            { _id: "not_specified", shopRegionName: "Not Specified" },
        ];
        return (
            <div style={{padding: "0px 20px"}}>
                <br />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        Please Select Regions
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    {_regionList &&
                        _regionList.map((data, index) => {
                            return (
                                <GridItem xs={3} sm={3} md={3} key={index}>
                                    <Field
                                        id={"checkbox-region-" + data._id}
                                        name={"region-" + data._id}
                                        notLabel={true}
                                        component={CustomCheckbox}
                                        disabled={readOnly}
                                        labelText={data.shopRegionName}
                                        onChange={(event) => {
                                            this.handleRegionSelect(event);
                                        }}
                                    />
                                </GridItem>
                            );
                        })}
                </GridContainer>

                <GridContainer style={{ marginTop: 20 }}>
                    <p
                        style={{
                            paddingLeft: "16px",
                            color: "#fdb912",
                            fontWeight: "900",
                        }}
                    >
                        * Add the shops for this promotion by selecting on the right-hand
                        side. If any shops are not selected, this promotion will be applied
                        to all shops.
                    </p>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            placeholder="Search by shop name"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            input={{
                                onChange: (e) => {
                                    this.onSearchShop("shopList", e.currentTarget.value);
                                    this.setState({ shopListSearch: e.currentTarget });
                                },
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search style={{ color: "#ccc" }} />
                                </InputAdornment>
                            }
                            name="searchProduct"
                        />
                        <div className={classes.tableSelectShops}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>
                                            <Button
                                                onClick={() => {
                                                    if (!readOnly) {
                                                        this.selectAllShopList("selectAll");
                                                    }
                                                }}
                                                fullWidth={true}
                                            >
                                                <ArrowForwardIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ border: "solid 1px #eee" }}>
                                    {this.renderShopList()}
                                </TableBody>
                            </Table>
                        </div>
                        <Typography variant="body1" display="block">
                            Showing {shopList.length} of {allShopList.length}
                        </Typography>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                            placeholder="Search by shop name"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            input={{
                                onChange: (e) => {
                                    this.onSearchShop("shopListSelected", e.currentTarget.value);
                                    this.setState({ selectedSearch: e.currentTarget });
                                },
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search style={{ color: "#ccc" }} />
                                </InputAdornment>
                            }
                            name="searchProduct"
                        />
                        <div className={classes.tableSelectShops}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>
                                            <Button
                                                onClick={() => {
                                                    if (!readOnly) {
                                                        this.selectAllShopList("unSelectAll");
                                                    }
                                                }}
                                                fullWidth={true}
                                            >
                                                <ArrowBackIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{ border: "solid 1px #eee" }}>
                                    {this.renderSelectedShopList()}
                                </TableBody>
                            </Table>
                        </div>
                        <Typography variant="body1" display="block">
                            Showing {shopListSelected.length} of {allShopListSelected.length}
                        </Typography>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        formOpen,
        formMode,
        regionList,
        shopList,
        allShopList,
        shopListSelected,
        allShopListSelected,
        regionSelected,
    } = state.promotion;
    return {
        formOpen,
        formMode,
        regionList,
        shopList,
        allShopList,
        shopListSelected,
        regionSelected,
        allShopListSelected,
    };
};
const connectAdditionalShop = connect(mapStateToProps)(AdditionalShop);
export default withStyles(styles)(connectAdditionalShop);

import React from "react";
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import lodash from 'lodash';
import moment from 'moment'

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import TablePromotionReport from "./TablePromotionReport.jsx";
import { promotionActions } from 'actions';

const style = {}

const FORM_NAME = 'FORM_FILTER_PROMOTION';

class PromotionReport extends React.Component {

    handleSubmit(values) {
        const { dispatch, tableFilter } = this.props;
        let _tableFilter = {
            page: 0,
            skip: 0,
            limit: tableFilter.limit,
            keyword: "",
            search: {}
        }
        let search = { ...values };
        if (lodash.size(search) > 0) {
          _tableFilter.search = search;
        }
        const resetTable = true;
        dispatch(promotionActions.getTablePromotionReportDatas(_tableFilter, resetTable));
      }

    clearFilter = () => {
        const { dispatch, tableFilter } = this.props;
        dispatch({ type: "CLEAR_PROMOTION_FILTER" })
        dispatch(reset(FORM_NAME))
        let newTableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "created_at",
            sortDir: "desc",
            keyword: "",
            search: {
                startDate: moment().startOf('day'), 
                endDate: moment().set('hour',23).set('minute',59).set('second',59)
            }
        }
        dispatch(promotionActions.getTablePromotionReportDatas(newTableFilter));
    }

    componentDidMount() {
        const { tableFilter, dispatch } = this.props; 
        dispatch(promotionActions.getTablePromotionReportDatas(tableFilter));
        dispatch(promotionActions.getNamePromotion());
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: "CLEAR_PROMOTION_FILTER" })
        dispatch(reset(FORM_NAME))
    }

    render() {
        const { handleSubmit, allPromotionList, FORM_FILTER_PROMOTION } = this.props;

        var promotionNameList = [];
        lodash.each(allPromotionList.promotions, function(currentObject){
            promotionNameList.push({
                value: currentObject.promotionName,
                label: currentObject.promotionName, 
                id: currentObject._id,
            })
        })

        let startMaxDateRedemption = new Date();
        if (lodash.get(FORM_FILTER_PROMOTION, 'values.endDate')) {
            startMaxDateRedemption = FORM_FILTER_PROMOTION.values.endDate;
        }

        let endMinDateRedemption;
        if (lodash.get(FORM_FILTER_PROMOTION, 'values.startDate')) {
            endMinDateRedemption = FORM_FILTER_PROMOTION.values.startDate;
        }
        return (
            <div>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="start-date-input"
                                name="startDate"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Reserve Start Date"
                                maxDate={startMaxDateRedemption}
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="end-date-input"
                                name="endDate"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Reserve End Date"
                                minDate={endMinDateRedemption}
                                maxDate={new Date()}
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="promotion-name-input"
                                name="promotionName"
                                component={CustomSelect}
                                type="text"
                                labelText="Promotion Name"
                                optionsList={promotionNameList}
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="reference-key-input"
                                name="referenceKey"
                                component={CustomInput}
                                type="text"
                                labelText="Reference Key"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="status-input"
                                name="status"
                                component={CustomSelect}
                                type="text"
                                labelText="Status"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    {
                                        label: 'All',
                                        value: 'all'
                                    }, 
                                    {
                                        label: 'Success',
                                        value: 'success'
                                    }, 
                                    {
                                        label: 'Failed',
                                        value: 'failed'
                                    }, 
                                    {
                                        label: 'Void',
                                        value: 'void'
                                    }, 
                                    {
                                        label: 'Reserved',
                                        value: 'reserved'
                                    }
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="reference-code-input"
                                name="referenceCode"
                                component={CustomInput}
                                type="text"
                                labelText="Reference Code"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="Promotion-id-input"
                                name="promotionId"
                                component={CustomInput}
                                type="text"
                                labelText="Promotion id"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Button
                                id="clear-btn"
                                onClick={this.clearFilter}
                                style={{
                                    marginTop: "25px"
                                }}
                                size="sm"
                                color="white">
                                Clear
                            </Button>
                            <Button
                                id="submit-btn"
                                type="submit"
                                style={{
                                    marginRight: "10px", marginTop: "25px"
                                }}
                                size="sm"
                                color="primary">
                                Submit
                            </Button>


                        </GridItem>
                    </GridContainer>
                </form>

                <TablePromotionReport />

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { tableFilter, allPromotionList } = state.promotion
    const { FORM_FILTER_PROMOTION } = state.form;
    const { merchantSetting } = state.authentication;

    return {
        merchantSetting, tableFilter, allPromotionList, FORM_FILTER_PROMOTION
    }
}

const connectedComponent = connect(mapStateToProps)(PromotionReport);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent))
import { earnSpecialsConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";
import lodash from 'lodash';

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableData: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    allProductList: [],
    productList: [],
    selectedProductList: [],
    formProductListLoader: false,
    tableLoader: false,
    formMemberLevellist: [],
    trdPartyCodeSet: [],
    shopListSelected:[],
    rewardImagePreview: defaultImage,
    productListMaster:[]
}

export function earnspecials(state = initialState, action) {
    switch (action.type) {
        case earnSpecialsConstants.CLEAR_PAGE_STATE:
            return initialState
        case earnSpecialsConstants.OPEN_ADD_NEW_SPECIALS:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.data,
                rewardImagePreview: defaultImage
            };
        case earnSpecialsConstants.OPEN_EDIT_EARN_SPECIALS:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                trdPartyCodeSet:(!lodash.isEmpty(action.thirdPartyCode) ? action.thirdPartyCode : {}),
                rewardImagePreview: action.rewardImagePreview
            };
        case earnSpecialsConstants.CLOSE_FORM_EARN_SPECIALS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                singleProductList: [{_id: 0}],
                joinedProductList: [{_id: 0}],
                joinedProductRows : [
                    {
                        productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                        point: 0
                    },
                ]
            }
        case earnSpecialsConstants.GET_EARN_SPECIALS_DATA_SUCCESS: {
            return {
                ...state,
                tableData: action.data,
                tableReload: false,
                tableReset: false
            }
        }
        case earnSpecialsConstants.SHOW_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: true
            }
        }
        case earnSpecialsConstants.HIDE_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: false
            }
        }
        case earnSpecialsConstants.ADD_EARN_SPECIALS_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        }
        case earnSpecialsConstants.EDIT_EARN_SPECIALS_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: true,
                tableReset: false,
            }
        }
        case earnSpecialsConstants.TABLE_RELOAD_SUCCESS: {
            return {
                ...state,
                tableReload: false,
                tableReset: false
            }
        }
        case earnSpecialsConstants.DELETE_EARN_SPECIALS_SUCCESS: {
            return {
                ...state,
                tableReload: true
            }
        }
        case earnSpecialsConstants.GET_MEMBER_LEVEL_LIST_SUCCESS: {
            return {
                ...state,
                formMemberLevellist: action.tmp
            }
        }
        case earnSpecialsConstants.EARN_SPECIAL_ADD_JOINED_PRODUCT_ROW: {
            let stateJoinedProductRow = state.joinedProductRows;
            stateJoinedProductRow.push(
                {
                    productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                    point: 0
                }
            )
            return {
                ...state,
                joinedProductRows: stateJoinedProductRow
            }
        } 
        case earnSpecialsConstants.EARN_SPECIAL_ADD_JOINED_PRODUCT: {
            let stateJoinedProductRow = state.joinedProductRows;
            stateJoinedProductRow[action.index].productWithQty.push(action.productWithQty)
            return {
                ...state,
                joinedProductRows: stateJoinedProductRow
            }
        } 
        case earnSpecialsConstants.EARN_SPECIAL_ADD_SINGLE_PRODUCT: {
            let stateSingleProductRow = state.singleProductList;
            stateSingleProductRow.push(action.objSingle)
            return {
                ...state,
                singleProductList: stateSingleProductRow
            }
        } 
        case earnSpecialsConstants.EARN_SPECIAL_DELETE_JOINED_PRODUCT: {
            let stateJoinedProductRow = state.joinedProductRows;
            stateJoinedProductRow[action.index].productWithQty.pop();
            return {
                ...state,
                joinedProductRows: stateJoinedProductRow
            }
        }
        case earnSpecialsConstants.EARN_SPECIAL_GET_REGION_LIST: {
            return {
                ...state,
                regionList: action.data 
            }
        } 
        case earnSpecialsConstants.EARN_SPECIAL_GET_SHOP_LIST: {
            return {
                ...state,
                allShopList: action.data.shopList ,
                shopList:action.data.shopList,
                allShopListSelected:action.data.shopListSelected,
                shopListSelected:action.data.shopListSelected,
            }
        } 
        case earnSpecialsConstants.EARN_SPECIAL_SELECT_SHOP: {
            return {
                ...state,
                shopList:action.data.shopList,
                allShopList:action.data.allShopList,
                shopListSelected:[...state.shopListSelected,...action.data.shopListSelected],
                allShopListSelected:[...state.allShopListSelected,...action.data.shopListSelected],
            }
        } 
        case earnSpecialsConstants.EARN_SPECIAL_UNSELECT_SHOP: {
            return {
                ...state,
                shopList:[...action.data.shopList,...state.shopList],
                allShopList:[...action.data.shopList,...state.allShopList],
                shopListSelected:action.data.shopListSelected,
                allShopListSelected:action.data.allShopListSelected,
            }
        } 
        case earnSpecialsConstants.EARN_SPECIAL_SELECTED_REGION: {
            return {
                ...state,
                regionSelected:action.data,
            }
        } 
        case earnSpecialsConstants.EARN_SPECIAL_SELECT_ALL_SHOP_LIST: {
            return {
                ...state,
                shopListSelected:[...action.data,...state.shopListSelected],
                shopList:[],
                allShopList:[],
                allShopListSelected:[...action.data,...state.shopListSelected],
            }
        } 
        case earnSpecialsConstants.EARN_SPECIAL_UNSELECT_ALL_SHOP_LIST: {
            return {
                ...state,
                shopList:[...action.data,...state.shopList],
                shopListSelected:[],
                allShopList:[...action.data,...state.shopList],
                allShopListSelected:[],
            }
        }
        case earnSpecialsConstants.EARN_SPECIAL_SEARCH_SHOP_LIST: {
            return {
                ...state,
                shopList:action.data,
            }
        }
        case earnSpecialsConstants.EARN_SPECIAL_SEARCH_SHOP_SELECTED: {
            return {
                ...state,
                shopListSelected:action.data,
            }
        }
        case earnSpecialsConstants.EARN_SPECIAL_CLEAR_SHOP_LIST: {
            return {
                ...state,
                allShopList:[],
                shopList:[],
                allShopListSelected:[],
                shopListSelected:[],
                regionSelected:[],
            }
        }
        case earnSpecialsConstants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS:
            return {
                ...state,
                luckyDrawRewardSet: action.data.rewards
            }
        case earnSpecialsConstants.GET_TRD_PARTY_CODE_SET_SUCCESS:
            return {
                ...state,
                trdPartyCodeSet: action.data
            }
        case earnSpecialsConstants.SET_FORM_PREVIEW_IMAGE_REWARD:
            return {
                ...state,
                rewardImagePreview: action.rewardImagePreview === "default" ? defaultImage : action.rewardImagePreview
            }
        default:
            return state;
    }
}
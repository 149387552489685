import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, arrayPush, change, reset } from 'redux-form';
import lodash from 'lodash';
import moment from 'moment';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import red from '@material-ui/core/colors/red';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import ReactAsyncSelect from 'react-select/async'
import Select from 'react-select';
import { newcondition } from "../../constants"
import Confirm from './Confirm'
import { crmValidator, normalize } from 'helpers';

const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ"
const style = {
    ...modalStyle,
    cardBody: {
        padding: "15px"
    },
    cardHeader: {
        marginLeft: "0px",
        color: "#333"
    },
    table: {
        border: "solid 1px #ccc"
    },
    tdBordered: {
        border: "solid 0px #ccc"
    },
    tableError: {
        border: "solid 2px red"
    },
    optionHover: {
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#eee"
        }
    },
    overrides: {
        MuiDialog: {
            paper: {
                overflowY: "visible"
            }
        }
    }
}

const FORM_NAME = 'datafiltering_new_condition'


const validateRequired = (value, allValues, props, name) => {
    if (value === "" || value === null || value === undefined) {
        if (lodash.includes(name, 'exclude')) {
            return "Field is required"

        } else if (lodash.includes(name, 'table')) {
            return "Table is required"

        } else if (lodash.includes(name, 'field')) {
            return "Field is required"

        } else if (lodash.includes(name, 'compareOperator')) {
            return "Operator is required"

        } else if (lodash.includes(name, 'value')) {
            return "value is required"

        } else {
            return "Required"
        }
    }

    if (parseFloat(value) <= 0) {
        return "Please enter number more than 0"
    }
}

class NewconditionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTableName: {},
            selectFieldName: {},
            selectFieldOptions: {},
            setLastvalues: {},
            conditionResult: ''
        };
    }

    onStartDateFocus = (filtering) => {
        const { FORM_NAME, dispatch } = this.props;
        // if (!lodash.get(FORM_NAME, 'values.startDate')) {
          dispatch(change('FORM_NAME', `${filtering}[value]`, moment({ hour: 0, minute: 0, seconds: 0 })))
        // }
    }

    getTablename = (e, index) => {
        const { selectTableName } = this.state;
        const { filtering } = this.props;
        let result = []
        filtering.map((value) => {
            // if(value.table === e.target.value){
            result.push({ value: value.tableName, label: value.tableName, fields: value.fields })
            // }
        })
        selectTableName[index] = result

        this.setState({
            selectTableName
        });
    }

    getFieldname = (e, index) => {
        const { selectTableName, selectFieldName } = this.state;
        let resultfield = []
        
        selectTableName[index].map((field) => {
            if (field.value === e.target.value) {
                field.fields.map((data) => {     
                    if(data.status === true){
                        resultfield.push({ value: data.fieldName, label: data.displayName, type:data.type, operation: data.operator })
                    }
                })
            }
        })
        selectFieldName[index] = resultfield
        this.setState({
            selectFieldName
        });

    }
    getOperation = (e, index, filtering) => {
        
        const { selectTableName, selectFieldName, selectFieldOptions } = this.state;
        const { dispatch } = this.props
        dispatch(change(FORM_NAME, `${filtering}[compareOperator]`, null ))
        let resultOperation = []
        var type = 'string'
        
        selectFieldName[index].map((operator) => {
            if (operator.value === e.target.value) {
                type = operator.type
                let dataOpe = operator.operation.split("|");
                dataOpe.map((item) => {
                    resultOperation.push({ value: item, label: item, type: type })
                })
            }
        })
        selectFieldOptions[index] = resultOperation
        selectFieldOptions[index]['type'] = type
        
        this.setState({
            selectFieldOptions
        });
        
        dispatch(change(FORM_NAME, `${filtering}[value]`, null ))
        dispatch(change(FORM_NAME, `${filtering}[lastvalue]`, null ))
        if(filtering){
            dispatch(change(FORM_NAME, `${filtering}[fieldtype]`, type ))
        }else{
            dispatch(change(FORM_NAME, `${index}[fieldtype]`, type ))
        }
    }
    
    // setOperation
    setOperation = (e, index, filtering) => {
        const { setLastvalues } = this.state
        const { dispatch } = this.props
        let display = []
        let inputValue = e.target.value
        display.push({'lastvalue' : false}, {'with_in_the_last': false},{ 'fieldselect': inputValue })
        setLastvalues[index] =display
        if(inputValue == '=' || inputValue == '>' || inputValue == '>=' || inputValue == '<' || inputValue == '<=' || inputValue == '<>' || inputValue == 'not_in' ){
            
            setLastvalues[index]['lastvalue'] = true
        }else if(inputValue == 'with_in_the_last' ){
            setLastvalues[index]['with_in_the_last'] = true
            setLastvalues[index]['lastvalue'] = false
        }
        setLastvalues[index]['fieldselect'] = inputValue
        this.setState({
            setLastvalues
        });
        dispatch(change(FORM_NAME, `${filtering}[lastvalue]`, null ))
       
    }

    renderFormInputAdd(filtering,index) {

        if(this.state.selectFieldOptions[index]){
            if(this.state.selectFieldOptions[index]['type'] == 'int'){
                return (
                    <>
                        
                        <TableCell
                        style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                           
                        <Field
                                name={`${filtering}[value]`}
                                component={CustomInput}
                                type="text"
                                // labelText={this.state.selectFieldOptions[index] && this.state.selectFieldOptions[index]['type'] ? this.state.selectFieldOptions[index]['type'] : 'isstring'}
                                labelText={``}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                normalize={(value) => {
                                    if (value) {
                                        if (value === 0 || value === "0") {
                                            return ""
                                        } else {
                                            return normalize.onlyNumber(value)
                                        }
                                    }else {
                                        return ""
                                    }
                                }}
                                validate={[validateRequired]}
                            />
                        </TableCell> 
                        <TableCell style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                            <Field
                                name={`${filtering}[lastvalue]`}
                                component={CustomInput}
                                labelText=""
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                type="text"
                                disabled={this.state.setLastvalues[index] && this.state.setLastvalues[index]['lastvalue']  ? this.state.setLastvalues[index]['lastvalue'] : false}
                            />
                        </TableCell> 
                    </>
                )
            }else if(this.state.selectFieldOptions[index]['type'] == 'date' || this.state.selectFieldOptions[index]['type'] == 'date/time'){
                if(this.state.setLastvalues[index]){
                    if(this.state.setLastvalues[index]['with_in_the_last'] === true){
                        return (
                            <>
                                <TableCell
                                    align="center"
                                    style={{
                                        paddingRight: "1px",
                                        paddingLeft: "10px",
                                        minWidth: "100px",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    <Field
                                        name={`${filtering}[value]`}
                                        component={CustomSelect}
                                        type="text"
                                        labelText={``}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        outlined
                                        optionsList={[
                                            {
                                                value: "day",
                                                label: "Day"
                                            },
                                            {
                                                value: "month",
                                                label: "Month"
                                            },
                                            {
                                                value: "year",
                                                label: "Year"
                                            }
                                        ]}
                                        validate={[validateRequired]}
                                    />

                                </TableCell>
                                <TableCell style={{
                                    paddingRight: "1px",
                                    paddingLeft: "10px",
                                    minWidth: "50px",
                                    whiteSpace: "nowrap"
                                }}>
                                    <Field
                                        name={`${filtering}[lastvalue]`}
                                        component={CustomInput}
                                        labelText=""
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        type="text"
                                        normalize={(value) => {
                                            if (value) {
                                                if (value === 0 || value === "0") {
                                                    return ""
                                                } else {
                                                    return normalize.onlyNumber(value)
                                                }
                                            }else {
                                                return ""
                                            }
                                        }}
                                        validate={[validateRequired]}
                                        disabled={this.state.setLastvalues[index] && this.state.setLastvalues[index]['lastvalue']  ? this.state.setLastvalues[index]['lastvalue'] : false}
                                    />
                                </TableCell>
                            </>
                        )
                        
                    }else if(this.state.selectFieldOptions[index]['type'] == 'date' && this.state.setLastvalues[index]['fieldselect'] == 'between'){
                        return (
                            <>
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                <Field
                                    name={`${filtering}[value]`}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={""}
                                    dateFormat="DD-MMM-YY"
                                    // timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[validateRequired]}
                                    //   maxDate={FORM_BANNER.values.endDate}
                                    // onPickerFocus={this.onStartDateFocus.bind(filtering)}
                                />
                            </TableCell> 
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                
                                <Field
                                    name={`${filtering}[lastvalue]`}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={""}
                                    dateFormat="DD-MMM-YY"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[validateRequired]}
                                    disabled={this.state.setLastvalues[index] && this.state.setLastvalues[index]['lastvalue']  ? this.state.setLastvalues[index]['lastvalue'] : false}
                                />
                            </TableCell>
                        </>
                        )
                    }else if(this.state.selectFieldOptions[index]['type'] == 'date/time' && this.state.setLastvalues[index]['fieldselect'] == 'between'){
                        return (
                            <>
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                <Field
                                    name={`${filtering}[value]`}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={""}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[validateRequired]}
                                    //   maxDate={FORM_BANNER.values.endDate}
                                    // onPickerFocus={this.onStartDateFocus.bind(filtering)}
                                />
                            </TableCell> 
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                
                                <Field
                                    name={`${filtering}[lastvalue]`}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={""}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[validateRequired]}
                                    disabled={this.state.setLastvalues[index] && this.state.setLastvalues[index]['lastvalue']  ? this.state.setLastvalues[index]['lastvalue'] : false}
                                />
                            </TableCell>
                        </>
                        )
                    }
                }
                 if(this.state.selectFieldOptions[index]['type'] == 'date'){
                    //setLastvalues[index]['lastvalue']
                    
                    return (
                        <>
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                <Field
                                    name={`${filtering}[value]`}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={""}
                                    dateFormat="DD-MMM-YY"
                                    // timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[validateRequired]}
                                    //   maxDate={FORM_BANNER.values.endDate}
                                    // onPickerFocus={this.onStartDateFocus.bind(filtering)}
                                />
                            </TableCell> 
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                
                                <Field
                                    name={`${filtering}[lastvalue]`}
                                    component={CustomInput}
                                    labelText=""
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    type="text"
                                    disabled={this.state.setLastvalues[index] && this.state.setLastvalues[index]['lastvalue']  ? this.state.setLastvalues[index]['lastvalue'] : false}
                                />
                            </TableCell>
                        </>
                    )
                }
                    return (
                        <>
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                <Field
                                    name={`${filtering}[value]`}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={""}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[validateRequired]}
                                    //   maxDate={FORM_BANNER.values.endDate}
                                    // onPickerFocus={this.onStartDateFocus.bind(filtering)}
                                />
                            </TableCell> 
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                <Field
                                    name={`${filtering}[lastvalue]`}
                                    component={CustomInput}
                                    labelText=""
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    type="text"
                                    disabled={this.state.setLastvalues[index] && this.state.setLastvalues[index]['lastvalue']  ? this.state.setLastvalues[index]['lastvalue'] : false}
                                />
                            </TableCell>
                        </>
                    )
                
            }else if(this.state.selectFieldOptions[index]['type'] == 'boolean' ){
                return (
                    <>
                        <TableCell
                            align="center"
                            style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "100px",
                                whiteSpace: "nowrap"
                            }}
                        >
                            <Field
                                name={`${filtering}[value]`}
                                component={CustomSelect}
                                type="text"
                                labelText={``}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                outlined
                                optionsList={[
                                    {
                                        value: "false",
                                        label: "false"
                                    },
                                    {
                                        value: "true",
                                        label: "true"
                                    }
                                ]}
                                validate={[validateRequired]}
                            />

                        </TableCell>
                        <TableCell style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                            <Field
                                name={`${filtering}[lastvalue]`}
                                component={CustomInput}
                                labelText=""
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                type="text"
                                disabled={this.state.setLastvalues[index] && this.state.setLastvalues[index]['lastvalue']  ? this.state.setLastvalues[index]['lastvalue'] : false}
                            />
                        </TableCell> 
                    </>
                    
                )
            }else if(this.state.selectFieldOptions[index]['type'] == 'decimal' ){
                return (
                    <>
                        <TableCell
                        style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                        <Field
                                name={`${filtering}[value]`}
                                component={CustomInput}
                                labelText={``}
                                type="text"
                                labelText={this.state.selectFieldOptions[index] && this.state.selectFieldOptions[index]['type'] ? this.state.selectFieldOptions[index]['type'] : 'isstring'}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                normalize={(value) => {
                                    if (value) {
                                        return crmValidator.handleKeyPress(value, { rules: { decimal: true, decimalDigits: 2 } })
                                        
                                    }else {
                                        return ""
                                    }
                                }}
                                validate={[validateRequired]}
                            />
                        </TableCell> 
                        <TableCell style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                            <Field
                                name={`${filtering}[lastvalue]`}
                                component={CustomInput}
                                labelText=""
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                type="text"
                                disabled={this.state.setLastvalues[index] && this.state.setLastvalues[index]['lastvalue']  ? this.state.setLastvalues[index]['lastvalue'] : false}
                            />
                        </TableCell>
                    </> 
                )
            }
        }
        return (
            <>
                <TableCell
                    style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap"
                }}>
                        <Field
                            name={`${filtering}[value]`}
                            component={CustomInput}
                            type="text"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            validate={[validateRequired]}
                        />
                </TableCell>  
                <TableCell style={{
                    paddingRight: "1px",
                    paddingLeft: "10px",
                    minWidth: "50px",
                    whiteSpace: "nowrap"
                }}>
                    <Field
                        name={`${filtering}[lastvalue]`}
                        component={CustomInput}
                        labelText=""
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        disabled={this.state.setLastvalues[index] && this.state.setLastvalues[index]['lastvalue']  ? this.state.setLastvalues[index]['lastvalue'] : false}
                    />
                </TableCell> 
            </>
            )
    }

    renderFormInputOr(row,indexin){
        
        if(this.state.selectFieldOptions[row]){
            if(this.state.selectFieldOptions[row]['type'] == 'int'){
                return (
                    <>
                        
                        <TableCell
                        style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                        <Field
                                name={`${row}[value]`}
                                component={CustomInput}
                                type="text"
                                // labelText={this.state.selectFieldOptions[row] && this.state.selectFieldOptions[row]['type'] ? this.state.selectFieldOptions[row]['type'] : 'isstring'}
                                labelText={``}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                normalize={(value) => {
                                    if (value) {
                                        if (value === 0 || value === "0") {
                                            return ""
                                        } else {
                                            return normalize.onlyNumber(value)
                                        }
                                    }else {
                                        return ""
                                    }
                                }}
                                validate={[validateRequired]}
                            />
                        </TableCell> 
                        <TableCell style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                            <Field
                                name={`${row}[lastvalue]`}
                                component={CustomInput}
                                labelText=""
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                type="text"
                                disabled={this.state.setLastvalues[row] && this.state.setLastvalues[row]['lastvalue']  ? this.state.setLastvalues[row]['lastvalue'] : false}
                            />
                        </TableCell> 
                    </>
                )
            }else if(this.state.selectFieldOptions[row]['type'] == 'date' || this.state.selectFieldOptions[row]['type'] == 'date/time'){
                if(this.state.setLastvalues[row]){
                    if(this.state.setLastvalues[row]['with_in_the_last'] === true){
                        return (
                            <>
                                <TableCell
                                    align="center"
                                    style={{
                                        paddingRight: "1px",
                                        paddingLeft: "10px",
                                        minWidth: "100px",
                                        whiteSpace: "nowrap"
                                    }}
                                >
                                    <Field
                                        name={`${row}[value]`}
                                        component={CustomSelect}
                                        type="text"
                                        labelText={``}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        outlined
                                        optionsList={[
                                            {
                                                value: "day",
                                                label: "Day"
                                            },
                                            {
                                                value: "month",
                                                label: "Month"
                                            },
                                            {
                                                value: "year",
                                                label: "Year"
                                            }
                                        ]}
                                        validate={[validateRequired]}
                                    />

                                </TableCell>
                                <TableCell style={{
                                    paddingRight: "1px",
                                    paddingLeft: "10px",
                                    minWidth: "50px",
                                    whiteSpace: "nowrap"
                                }}>
                                    <Field
                                        name={`${row}[lastvalue]`}
                                        component={CustomInput}
                                        labelText=""
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        type="text"
                                        normalize={(value) => {
                                            if (value) {
                                                if (value === 0 || value === "0") {
                                                    return ""
                                                } else {
                                                    return normalize.onlyNumber(value)
                                                }
                                            }else {
                                                return ""
                                            }
                                        }}
                                        validate={[validateRequired]}
                                        disabled={this.state.setLastvalues[row] && this.state.setLastvalues[row]['lastvalue']  ? this.state.setLastvalues[row]['lastvalue'] : false}
                                    />
                                </TableCell>
                            </>
                        )
                        
                    }else if(this.state.selectFieldOptions[row]['type'] == 'date' && this.state.setLastvalues[row]['fieldselect'] == 'between'){
                        return (
                            <>
                                <TableCell style={{
                                    paddingRight: "1px",
                                    paddingLeft: "10px",
                                    minWidth: "50px",
                                    whiteSpace: "nowrap"
                                }}>
                                    <Field
                                   
                                        name={`${row}[value]`}
                                        component={CustomDatePicker}
                                        type="text"
                                        labelText={""}
                                        dateFormat="DD-MMM-YY"
                                        // timeFormat="HH:mm"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        validate={[validateRequired]}
                                        //   maxDate={FORM_BANNER.values.endDate}
                                        // onPickerFocus={this.onStartDateFocus.bind(filtering)}
                                    />
                                </TableCell> 
                                <TableCell style={{
                                    paddingRight: "1px",
                                    paddingLeft: "10px",
                                    minWidth: "50px",
                                    whiteSpace: "nowrap"
                                }}>
                                    <Field
                                        name={`${row}[lastvalue]`}
                                        component={CustomDatePicker}
                                        type="text"
                                        labelText={""}
                                        dateFormat="DD-MMM-YY"
                                        // timeFormat="HH:mm"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        validate={[validateRequired]}
                                        disabled={this.state.setLastvalues[row] && this.state.setLastvalues[row]['lastvalue']  ? this.state.setLastvalues[row]['lastvalue'] : false}
                                    />
                                </TableCell>
                            </>
                        )
                    }else if(this.state.selectFieldOptions[row]['type'] == 'date/time' && this.state.setLastvalues[row]['fieldselect'] == 'between'){
                        return (
                            <>
                                <TableCell style={{
                                    paddingRight: "1px",
                                    paddingLeft: "10px",
                                    minWidth: "50px",
                                    whiteSpace: "nowrap"
                                }}>
                                    <Field
                                   
                                        name={`${row}[value]`}
                                        component={CustomDatePicker}
                                        type="text"
                                        labelText={""}
                                        dateFormat="DD-MMM-YY"
                                        timeFormat="HH:mm"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        validate={[validateRequired]}
                                        //   maxDate={FORM_BANNER.values.endDate}
                                        // onPickerFocus={this.onStartDateFocus.bind(filtering)}
                                    />
                                </TableCell> 
                                <TableCell style={{
                                    paddingRight: "1px",
                                    paddingLeft: "10px",
                                    minWidth: "50px",
                                    whiteSpace: "nowrap"
                                }}>
                                    <Field
                                        name={`${row}[lastvalue]`}
                                        component={CustomDatePicker}
                                        type="text"
                                        labelText={""}
                                        dateFormat="DD-MMM-YY"
                                        timeFormat="HH:mm"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        validate={[validateRequired]}
                                        disabled={this.state.setLastvalues[row] && this.state.setLastvalues[row]['lastvalue']  ? this.state.setLastvalues[row]['lastvalue'] : false}
                                    />
                                </TableCell>
                            </>
                        )
                    }
                }
                if(this.state.selectFieldOptions[row]['type'] == 'date'){
                    return (
                        <>
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                <Field
                               
                                    name={`${row}[value]`}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={""}
                                    dateFormat="DD-MMM-YY"
                                    // timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[validateRequired]}
                                    //   maxDate={FORM_BANNER.values.endDate}
                                    // onPickerFocus={this.onStartDateFocus.bind(filtering)}
                                />
                            </TableCell> 
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                <Field
                                    name={`${row}[lastvalue]`}
                                    component={CustomInput}
                                    labelText=""
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    type="text"
                                    disabled={this.state.setLastvalues[row] && this.state.setLastvalues[row]['lastvalue']  ? this.state.setLastvalues[row]['lastvalue'] : false}
                                />
                            </TableCell>
                        </>
                    )
                }
                    return (
                        <>
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                <Field
                               
                                    name={`${row}[value]`}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={""}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[validateRequired]}
                                    //   maxDate={FORM_BANNER.values.endDate}
                                    // onPickerFocus={this.onStartDateFocus.bind(filtering)}
                                />
                            </TableCell> 
                            <TableCell style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "50px",
                                whiteSpace: "nowrap"
                            }}>
                                <Field
                                    name={`${row}[lastvalue]`}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={""}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    type="text"
                                    disabled={this.state.setLastvalues[row] && this.state.setLastvalues[row]['lastvalue']  ? this.state.setLastvalues[row]['lastvalue'] : false}
                                />
                            </TableCell>
                        </>
                    )
                
            }else if(this.state.selectFieldOptions[row]['type'] == 'boolean' ){
                return (
                    <>
                        <TableCell
                            align="center"
                            style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "100px",
                                whiteSpace: "nowrap"
                            }}
                        >
                            <Field
                                name={`${row}[value]`}
                                component={CustomSelect}
                                type="text"
                                labelText={``}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                outlined
                                optionsList={[
                                    {
                                        value: "false",
                                        label: "false"
                                    },
                                    {
                                        value: "true",
                                        label: "true"
                                    }
                                ]}
                                validate={[validateRequired]}
                            />

                        </TableCell>
                        <TableCell style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                            <Field
                                name={`${row}[lastvalue]`}
                                component={CustomInput}
                                labelText=""
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                type="text"
                                disabled={this.state.setLastvalues[row] && this.state.setLastvalues[row]['lastvalue']  ? this.state.setLastvalues[row]['lastvalue'] : false}
                            />
                        </TableCell> 
                    </>
                    
                )
            }else if(this.state.selectFieldOptions[row]['type'] == 'decimal' ){
                return (
                    <>
                        <TableCell
                        style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                        <Field
                                name={`${row}[value]`}
                                component={CustomInput}
                                labelText={``}
                                type="text"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                normalize={(value) => {
                                    if (value) {
                                        return crmValidator.handleKeyPress(value, { rules: { decimal: true, decimalDigits: 2 } })
                                        
                                    }else {
                                        return ""
                                    }
                                }}
                                validate={[validateRequired]}
                            />
                        </TableCell> 
                        <TableCell style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap"
                        }}>
                            <Field
                                name={`${row}[lastvalue]`}
                                component={CustomInput}
                                labelText=""
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                type="text"
                                disabled={this.state.setLastvalues[row] && this.state.setLastvalues[row]['lastvalue']  ? this.state.setLastvalues[row]['lastvalue'] : false}
                            />
                        </TableCell>
                    </> 
                )
            }
        }

        return (
            <>
                <TableCell
                    style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap"
                }}>
                        <Field
                            name={`${row}[value]`}
                            component={CustomInput}
                            type="text"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            validate={[validateRequired]}
                        />
                </TableCell>  
                <TableCell style={{
                    paddingRight: "1px",
                    paddingLeft: "10px",
                    minWidth: "50px",
                    whiteSpace: "nowrap"
                }}>
                      {/* <Field
                                name={`${row}[lastvalue]`}
                                component={CustomInput}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                labelText={''}
                                type="text"
                            /> */}
                    <Field
                        name={`${row}[lastvalue]`}
                        component={CustomInput}
                        labelText=""
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        disabled={this.state.setLastvalues[row] && this.state.setLastvalues[row]['lastvalue']  ? this.state.setLastvalues[row]['lastvalue'] : false}
                    />
                </TableCell> 
            </>
            )
    }
    renderRewardCondition = ({ fields, meta: { error, submitFailed } }) => {
        const { classes, formValues, readOnly, filtering } = this.props

        return (
            <React.Fragment>
                {fields.map((filtering, index) =>
                    <React.Fragment key={index}>

                        {(index > 0) &&
                            <div style={{
                                padding: "20px",
                                width: "70px",
                                background: "linear-gradient(#000, #000) no-repeat center/2px 100%",
                                textAlign: "center"
                            }}>
                                <b style={{ backgroundColor: "white" }}>AND</b>
                            </div>
                        }
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tdBordered}>
                                    <TableCell
                                        key="condition_header"
                                        align="center"
                                        style={{
                                            paddingRight: "1px",
                                            paddingLeft: "10px",
                                            minWidth: "90px",
                                            whiteSpace: "nowrap"
                                        }}>
                                        
                                        <Field
                                            name={`${filtering}[exclude]`}
                                            component={CustomSelect}
                                            type="text"
                                            labelText={""}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            outlined
                                            optionsList={[
                                                {
                                                    value: "false",
                                                    label: "include"
                                                },
                                                {
                                                    value: "true",
                                                    label: "exclude"
                                                }
                                            ]}
                                            onChange={(e) => {
                                                this.getTablename(e, index)
                                            }}
                                            validate={[validateRequired]}
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{
                                            paddingRight: "1px",
                                            paddingLeft: "10px",
                                            minWidth: "100px",
                                            whiteSpace: "nowrap"
                                        }}>
                                        <Field
                                            name={`${filtering}[table]`}
                                            component={CustomSelect}
                                            type="text"
                                            labelText={``}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            outlined
                                            optionsList={this.state.selectTableName[index]}
                                            onChange={(e) => {
                                                this.getFieldname(e, index)
                                            }}
                                            validate={[validateRequired]}
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{
                                            paddingRight: "1px",
                                            paddingLeft: "10px",
                                            minWidth: "100px",
                                            whiteSpace: "nowrap"
                                        }}>
                                        <Field
                                            name={`${filtering}[field]`}
                                            component={CustomSelect}
                                            type="text"
                                            labelText={``}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            outlined
                                            optionsList={this.state.selectFieldName[index]}
                                            onChange={(e) => {
                                                this.getOperation(e, index, filtering)
                                            }}
                                            validate={[validateRequired]}
                                        />
                                        <Field 
                                            name={`${filtering}[fieldtype]`}
                                            component="input"
                                            type="hidden"
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{
                                            paddingRight: "1px",
                                            paddingLeft: "10px",
                                            minWidth: "80px",
                                            whiteSpace: "nowrap"
                                        }}>
                                        <Field
                                            name={`${filtering}[compareOperator]`}
                                            component={CustomSelect}
                                            type="text"
                                            labelText={``}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            outlined
                                            optionsList={this.state.selectFieldOptions[index]}
                                            onChange={(e) => {
                                                this.setOperation(e, index, filtering)
                                            }}
                                            validate={[validateRequired]}
                                        />
                                        <Field
                                            name={`${filtering}[fieldtype]`}
                                            component="input"
                                            type="hidden"
                                        />
                                    </TableCell>
                                    {/* //renderRewardCondition */}
                                    {this.renderFormInputAdd(filtering,index)}
                                   
                                    <TableCell style={{ padding: "0px", width: "50px" }}>
                                        <Button
                                            type="button"
                                            color="info"
                                            style={{ width: "50px" }}
                                            // onClick={this.addCondition.bind(this)}
                                            onClick={() => {
                                                this.addOrCondition(index, 'OR')
                                            }}
                                        >OR</Button>

                                    </TableCell>
                                    <TableCell style={{ padding: "0px", width: "50px" }}>
                                        <TableActionButton
                                            actionType="delete"
                                            onClick={() => {
                                                fields.remove(index)
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray
                                    name={`${filtering}.conditions`}
                                    component={this.renderOrCondition}
                                    productConditionIndex={`${index}`}
                                // validate={[validateSizeMorethanOne]}
                                />
                            </TableBody>
                        </Table>
                    </React.Fragment>
                )}

            </React.Fragment>
        )
    }

    renderOrCondition = ({ fields, productConditionIndex, meta: { error, submitFailed } }) => {
        const { classes, readOnly, rewardModalOpen } = this.props
        return (
            <React.Fragment>
                {fields.map((row, indexin) => (
                    <TableRow style={{ borderStyle: "none" }}>
                        <TableCell
                            align="center"
                            style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "90px",
                                whiteSpace: "nowrap"
                            }}>
                            <Field
                                name={`${row}.exclude`}
                                component={CustomSelect}
                                type="text"
                                labelText={""}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                outlined
                                optionsList={[
                                    {
                                        value: "false",
                                        label: "include"
                                    },
                                    {
                                        value: "true",
                                        label: "exclude"
                                    }
                                ]}
                                onChange={(e) => {
                                    this.getTablename(e, row)
                                }}
                                validate={[validateRequired]}
                            />
                        </TableCell>
                        <TableCell
                            align="center"
                            style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "100px",
                                whiteSpace: "nowrap"
                            }}>
                            <Field
                                name={`${row}[table]`}
                                component={CustomSelect}
                                type="text"
                                labelText={``}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                outlined
                                validate={[validateRequired]}
                                // disabled={lodash.size(lodash.get(formValues, `conditions[${index}].in`)) > 0}
                                optionsList={this.state.selectTableName[row]}
                                onChange={(e) => {
                                    this.getFieldname(e, row)
                                }}
                            />
                        </TableCell>
                        <TableCell
                            align="center"
                            style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "100px",
                                whiteSpace: "nowrap"
                            }}>
                            <Field
                                name={`${row}[field]`}
                                component={CustomSelect}
                                type="text"
                                labelText={``}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                outlined
                                optionsList={this.state.selectFieldName[row]}
                                onChange={(e) => {
                                    this.getOperation(e, row)
                                }}
                                validate={[validateRequired]}
                            />
                            <Field 
                                name={`${row}[fieldtype]`}
                                component="input"
                                type="hidden"
                            />
                        </TableCell>
                        <TableCell
                            align="center"
                            style={{
                                paddingRight: "1px",
                                paddingLeft: "10px",
                                minWidth: "80px",
                                whiteSpace: "nowrap"
                            }}>
                            <Field
                                name={`${row}[compareOperator]`}
                                component={CustomSelect}
                                type="text"
                                labelText={""}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                onChange={(e) => {
                                    this.setOperation(e, row)
                                }}
                                validate={[validateRequired]}
                                outlined
                                optionsList={this.state.selectFieldOptions[row]}
                            />
                            <Field
                                name={`${row}[fieldtype]`}
                                component="input"
                                type="hidden"
                            />
                        </TableCell>
                        {this.renderFormInputOr(row,indexin)}
                        
                        <TableCell style={{ padding: "0px", width: "50px" }}>
                            <Button
                                type="button"
                                color="info"
                                style={{ width: "50px" }}
                                onClick={() => {
                                    this.addOrCondition(productConditionIndex, 'OR')
                                }}
                            >OR</Button>

                        </TableCell>
                        <TableCell style={{ padding: "0px", width: "50px" }}>
                            <TableActionButton
                                actionType="delete"
                                onClick={() => {
                                    fields.remove(indexin)
                                }}
                            />
                        </TableCell>
                    </TableRow>
                ))}

            </React.Fragment>

        )
    }

    addCondition() {
        const { dispatch } = this.props
        dispatch(arrayPush(FORM_NAME, 'conditions',
            {
                "type": "main",
                "operator": "AND"
            }
        ))
    }
    addOrCondition(conditionIndex, value) {
        const { dispatch } = this.props
        return new Promise((resolve) => {
            dispatch(arrayPush(FORM_NAME, `conditions[${conditionIndex}].conditions`, {
                "id": value, //lodash.get(value, 'value'),
                "operator": value
            }))
            resolve()
        })
    }
    handleSubmit(value) {
        const { dispatch } = this.props
        const { conditions } = value
        this.setState({ conditionResult: value })

        dispatch({ type: newcondition.OPEN_FORM_TEMPLATE_NAME })

    }
    resetForm() {
        const { dispatch } = this.props
        dispatch(reset(FORM_NAME));
    }

    render() {
        const { classes, filtering, handleSubmit, formTemplateNewOpen, pristine } = this.props
        let { readOnly } = this.props
        return (
            <Card className={classes.cardBody}>
                <CardBody>
                    <Confirm result={this.state.conditionResult} resetform={this.resetForm.bind(this)} />

                    <GridContainer>
                        <GridItem xs={8}>
                            <h4 className={classes.cardHeader}>
                                <b>New Condition</b>
                            </h4>
                        </GridItem>
                        <GridItem xs={4} style={{ textAlign: "right" }}>
                            <Button
                                type="button"
                                color="success"
                                name="btnaddmain"
                                // style={{ marginTop: "0px", width: "80px" }}
                                style={{ width: "80px" }}
                                onClick={this.addCondition.bind(this)}
                            >
                                Add
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <FieldArray
                                    name="conditions"
                                    component={this.renderRewardCondition}
                                // validate={[validateSizeMorethanOne]}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <Button
                                    className={classes.btnSubmit}
                                    disabled={pristine}
                                    color="primary"
                                    type="submit"
                                    name="btn_submit"
                                    id="btn_submit">Submit</Button>
                                <Button
                                    id={`new_condition_btn_cancel`}
                                    type="button"
                                    disabled={pristine}
                                    onClick={() => {
                                        this.resetForm()
                                    }}
                                    color="white">
                                    Cancel
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, filtering, formTemplateNewOpen } = state.df_newcondition;
    return {
        formOpen,

        filtering,
        formTemplateNewOpen
    };
}
const connectedComponent = connect(mapStateToProps)(NewconditionForm);
export default reduxForm({
    form: FORM_NAME,
    // validate:validate,
    // validate: validate,
    enableReinitialize: false
})(withStyles(style)(connectedComponent))
// export default connect(mapStateToProps)(withStyles(style)(NewconditionForm));

import { earnrulesServices } from '../services';
import { earnrulesConstants } from '../constants'
import { loader, alert as customAlert } from 'helpers';
import { change } from 'redux-form';
import i18n from 'i18n';
import lodash from 'lodash';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const earnRulesAction = {
    getListsProduct,
    getEarnRuleLists,
    addNewEarnRules,
    editEarnRules,
    deleteRules,
    getEarnRuleById,
    getRegionList,
    getAllShopList,
    getLuckyDrawRewardSetList,
    importProduct
};

function getListsProduct(params) {
    return dispatch => {
        dispatch({ type: earnrulesConstants.EARNRULES_FORM_PRODUCT_LIST_LOADER, showLoader: true })
        earnrulesServices.getListsProduct(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data.products));
                        } else {
                            dispatch(success([]));
                        }
                    } catch (e) {
                        dispatch(success([]));
                    }
                },
                error => {
                    dispatch(success([]));
                }
            ).then(() => {
                dispatch({ type: earnrulesConstants.EARNRULES_FORM_PRODUCT_LIST_LOADER, showLoader: false })
            })
    };

    function success(tmp) { return { type: earnrulesConstants.GET_PRODUCT_LIST_SUCCESS, productList: tmp } }
}

function getEarnRuleLists(params) {
    return dispatch => {
        // loader(dispatch, true)
        dispatch({ type: earnrulesConstants.SHOW_TABLE_LOADER })
        earnrulesServices.getEarnRuleLists(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                // loader(dispatch, false)
                dispatch({ type: earnrulesConstants.HIDE_TABLE_LOADER })
            })
    }
    function success(tmp) { return { type: earnrulesConstants.GET_EARN_RULES_DATA_SUCCESS, data: tmp } }
}

function addNewEarnRules(params) {
    return dispatch => {
        loader(dispatch, true)
        earnrulesServices.addNewEarnRules(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: earnrulesConstants.ADD_EARN_RULES_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.earn_rule_was_created'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function editEarnRules(params) {
    return dispatch => {
        loader(dispatch, true)
        earnrulesServices.editEarnRules(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: earnrulesConstants.EDIT_EARN_RULES_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.earn_rule_was_updated'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function deleteRules(params) {
    return dispatch => {
        loader(dispatch, true)
        earnrulesServices.deleteRules(params)
            .then(
                tmp => {
                    if (tmp.status) {
                        dispatch({ type: earnrulesConstants.DELETE_EARN_RULES_SUCCESS });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.earn_rule_was_deleted'));
                    } else {
                        if(!tmp.unAlert) customAlert(dispatch, 'error', tmp.message.toString())
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getEarnRuleById(id) {
    return dispatch => {
        loader(dispatch, true)
        earnrulesServices.getEarnRuleById(id)
            .then(
                response => {
                    if (response.status) {
                        let selectedProductList = [];
                        const earnruleData = response.data
                        let formInitialValues = earnruleData
                        let allShopListSelected = []
                        let regionSelected = []

                        if (earnruleData.type === "code") {
                            formInitialValues.earnRuleCode = earnruleData.ruleCode
                            formInitialValues.reward = earnruleData.reward
                            formInitialValues.customRadioEarnFor = earnruleData.earnFor
                            formInitialValues.customRadioEarnBy = earnruleData.earnBy
                            let productPoint = {};
                            
                            lodash.forEach(earnruleData.productIdsWithPoint, function (value) {
                               let productId = value.productId;
                               selectedProductList.push({
                                  _id: value.productId,
                                  name: value.product.name,
                                  prefix: value.product.prefix,
                                  skuId: value.productSkuId
                               });
                   
                               productPoint = {
                                  ...productPoint,
                                  [productId]: value.point
                               }
                            })
                            formInitialValues.productPoint = productPoint;
                         }else if(earnruleData.type === "purchaseAmount"){
                            formInitialValues.customRadioPurshaseAmount = lodash.get(earnruleData.condition,'spendingType') === 'every' ? 'spendingEvery' : 'whenSpend'
                            formInitialValues.spendingEveryInput = lodash.get(earnruleData.condition,'spendingValue') && lodash.get(earnruleData.condition,'spendingType') === 'every' ? earnruleData.condition.spendingValue : ''
                            formInitialValues.willGetInput = lodash.get(earnruleData.condition,'point') && lodash.get(earnruleData.condition,'spendingType') === 'every' ? earnruleData.condition.point : ''
                            
                            formInitialValues.whenSpendInput = lodash.get(earnruleData.condition,'spendingValue') && lodash.get(earnruleData.condition,'spendingType') === 'total' ? earnruleData.condition.spendingValue : ''
                            formInitialValues.willGetInputWhenSpend = lodash.get(earnruleData.condition,'point') && lodash.get(earnruleData.condition,'spendingType') === 'total' ? earnruleData.condition.point : ''

                            formInitialValues.customCheckboxMinimumSpending = lodash.get(earnruleData.condition,'minimum') 
                            formInitialValues.minimumSpending = lodash.get(earnruleData.condition,'minimum') ? lodash.get(earnruleData.condition.minimum,'value') : ''
                            formInitialValues.customRadioMinimumSpending = lodash.get(earnruleData.condition,'minimum') ? lodash.get(earnruleData.condition.minimum,'calculateType') : 'since'
                            
                            formInitialValues.customCheckboxMaximumSpending = lodash.get(earnruleData.condition,'maximum') 
                            formInitialValues.maximumSpending = lodash.get(earnruleData.condition,'maximum') ? lodash.get(earnruleData.condition.maximum,'value') : ''

                            formInitialValues.customRadioParticipatedDay = lodash.get(earnruleData.dateCondition,'type') 
                            formInitialValues.customRadioSelectedDay = lodash.get(earnruleData.dateCondition,'specificDateType') ? lodash.get(earnruleData.dateCondition,'specificDateType'): 'dayOfWeek'

                            if(lodash.get(earnruleData.dateCondition,'specificDateType') === 'dayOfWeek'){
                                formInitialValues = {
                                    ...formInitialValues,
                                    "selectedDate":  {
                                        "monday": lodash.includes(earnruleData.dateCondition.specificDateValue,'monday'),
                                        "tuesday": lodash.includes(earnruleData.dateCondition.specificDateValue,'tuesday'),
                                        "wednesday": lodash.includes(earnruleData.dateCondition.specificDateValue,'wednesday'),
                                        "thursday": lodash.includes(earnruleData.dateCondition.specificDateValue,'thursday'),
                                        "friday": lodash.includes(earnruleData.dateCondition.specificDateValue,'friday'),
                                        "saturday": lodash.includes(earnruleData.dateCondition.specificDateValue,'saturday'),
                                        "sunday": lodash.includes(earnruleData.dateCondition.specificDateValue,'sunday'),
                                     }
                                }
                            }
                            else if(lodash.get(earnruleData.dateCondition,'specificDateType') === 'selectedDate'){
                                formInitialValues.date = lodash.get(earnruleData.dateCondition,'specificDateValue')
                            }

                            allShopListSelected = lodash.get(earnruleData.shopCondition,'shopList') || []
                            regionSelected = lodash.map(allShopListSelected,"shopRegionId") || []
                            regionSelected = lodash.uniq(regionSelected)


                         }else if(earnruleData.type === "product"){ 
                            if(lodash.get(earnruleData,'product')){ 
                                formInitialValues = {
                                    ...formInitialValues,
                                    'selectedRuleProduct':{
                                        'single': lodash.get(earnruleData.product.singleProduct,'checked'),
                                        'joined': lodash.get(earnruleData.product.joinedProduct,'checked')
                                    }
                                }
                            }
                            if(lodash.get(earnruleData,'product') && lodash.get(earnruleData.product.singleProduct,'checked')){
                                let singleNameSkuArr = [];
                                let singleQuantityArr = [];
                                let singlePointArr = [];
                                lodash.each(earnruleData.product.singleProduct.productList, function(productListObject, i){
                                    singleNameSkuArr.push(productListObject.skuId)
                                    singleQuantityArr.push(productListObject.qty)
                                    singlePointArr.push(productListObject.point)
                                    if( parseInt(i) > 0 ){
                                        let objSingle = {_id: parseInt(i)}
                                        dispatch({ type: 'EARN_RULE_ADD_SINGLE_PRODUCT', objSingle })
                                    }
                                })
                                formInitialValues.singleProductNameSkuSelect = singleNameSkuArr;
                                formInitialValues.singleProductQuantityInput = singleQuantityArr;
                                formInitialValues.singleProductPointInput = singlePointArr;
                            }
                            if(lodash.get(earnruleData,'product') && lodash.get(earnruleData.product.joinedProduct,'checked')){
                                let joinedPointArr = [];
                                lodash.each(earnruleData.product.joinedProduct.productList, function(productListObject, i){
                                    joinedPointArr.push(productListObject.point)
                                    if(parseInt(i) !== 0){
                                        dispatch({ type: 'EARN_RULE_ADD_JOINED_PRODUCT_ROW' })
                                    }
                                    lodash.each(productListObject.productWithQty, function(productWithQtyObject, j){
                                        let key = i+'_'+j;
                                        formInitialValues = {
                                            ...formInitialValues,
                                            'joinedProductNameSkuSelect': {
                                                ...formInitialValues.joinedProductNameSkuSelect,
                                                [key]: productWithQtyObject.skuId,
                                            },
                                            'joinedProductQuantityInput': {
                                                ...formInitialValues.joinedProductQuantityInput,
                                                [key]: productWithQtyObject.qty
                                            }
                                        }
                                        if( parseInt(j) > 1 ){
                                            let productWithQty = {_id: parseInt(j), qty: 0}
                                            let index = parseInt(i);
                                            dispatch({ type: 'EARN_RULE_ADD_JOINED_PRODUCT', index, productWithQty })
                                        }
                                    })
                                })
                                formInitialValues.joinedProductPointInput = joinedPointArr;
                            }
                                formInitialValues.customRadioParticipatedDay = lodash.get(earnruleData.dateCondition,'type') 
                                formInitialValues.customRadioSelectedDay = lodash.get(earnruleData.dateCondition,'specificDateType') ? lodash.get(earnruleData.dateCondition,'specificDateType'): 'dayOfWeek'

                                if(lodash.get(earnruleData.dateCondition,'specificDateType') === 'dayOfWeek'){
                                    formInitialValues = {
                                        ...formInitialValues,
                                        "selectedDate":  {
                                            "monday": lodash.includes(earnruleData.dateCondition.specificDateValue,'monday'),
                                            "tuesday": lodash.includes(earnruleData.dateCondition.specificDateValue,'tuesday'),
                                            "wednesday": lodash.includes(earnruleData.dateCondition.specificDateValue,'wednesday'),
                                            "thursday": lodash.includes(earnruleData.dateCondition.specificDateValue,'thursday'),
                                            "friday": lodash.includes(earnruleData.dateCondition.specificDateValue,'friday'),
                                            "saturday": lodash.includes(earnruleData.dateCondition.specificDateValue,'saturday'),
                                            "sunday": lodash.includes(earnruleData.dateCondition.specificDateValue,'sunday'),
                                        }
                                    }
                                }
                                else if(lodash.get(earnruleData.dateCondition,'specificDateType') === 'selectedDate'){
                                    formInitialValues.date = lodash.get(earnruleData.dateCondition,'specificDateValue')
                                }

                                allShopListSelected = lodash.get(earnruleData.shopCondition,'shopList') || []
                                regionSelected = lodash.map(allShopListSelected,"shopRegionId") || []
                                regionSelected = lodash.uniq(regionSelected)
    
                        }
                   
                         dispatch({ type: earnrulesConstants.OPEN_EDIT_EARN_RULES, 
                            data: formInitialValues, 
                            selectedProductList: selectedProductList,
                            regionSelected,
                            allShopListSelected,
                            productListMaster : lodash.get(earnruleData,'product')
                         })
                         dispatch(change('FORM_EARNRULES', 'selectedProductCount', lodash.size(selectedProductList)))
                        
                    } else {
                        customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}


function getRegionList() {
    return (dispatch) => {
        earnrulesServices.getRegionLists().then(
        (response) => {
          if (response.status) {
            dispatch(success(response.data.shopRegions))
          } else {
            if (!response.unAlert)
              customAlert(
                dispatch,
                "error",
                i18n.t(`api_messages.${response.message.toString()}`)
              )
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
    }
  
    function success(data) {
      return { type: earnrulesConstants.EARN_RULE_GET_REGION_LIST, data }
    }
  }


function getAllShopList(params, shopListSelected) {
    return (dispatch) => {
        earnrulesServices.getShopListsByRegion(params).then(
        (response) => {
          if (response.status) {
              const isArrayFormat = Array.isArray(response.data)
              let formatData = []
              if(isArrayFormat){
                 formatData = lodash.map(response.data,((data,index)=>{
                    return data.shopLists
                  }))
                  formatData = lodash.flatten(formatData)
              }else{
                formatData=[...lodash.get(response.data, "shopLists")] 
              }
              //remove shopList Has Selected
              let shopListRemovedSelected = lodash.filter(formatData,(data,index)=>{
                  return !lodash.find(shopListSelected,{_id:data._id})
              })
              shopListRemovedSelected = shopListRemovedSelected.map(data=>{
                  return {
                      ...data,
                      shopRegionId:data.shopRegionId?data.shopRegionId:'not_specified'
                  }
              })
              
            const formData = {
                shopListSelected,
                shopList:shopListRemovedSelected
            }
            dispatch(success(formData))
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
    }
   
  
    function success(data) {
      return { type: earnrulesConstants.EARN_RULE_GET_SHOP_LIST, data }
    }
  }

  function getLuckyDrawRewardSetList(){
    let params = { showOnCampignAndEarnRule: "yes" }
    let strParams = ''
    if (typeof params === 'object') {
        strParams = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    else {
        strParams = params
    }
    return dispatch => {
        loader(dispatch, true)
        earnrulesServices.getRewardList(strParams)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: earnrulesConstants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS, data }}
}

function importProduct(params) {
    return dispatch => {
       loader(dispatch, true)
       earnrulesServices.importProduct(params)
          .then(
             response => {
                if (response.status) {
                   dispatch({
                      type: earnrulesConstants.SHOW_EARN_RULE_IMPORT_PRODUCT_RESULT,
                      data: response.data
                   });
                } else {
                   if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                }
             },
             error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
             }
          ).then(() => {
             loader(dispatch, false)
          })
    }
 }
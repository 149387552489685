import { configConstants } from '../constants';
import axios from 'axios';
import _ from 'lodash';
import { history, setHeaders } from 'helpers';

export const photoGiveawayPhotoServices = {
    getListsPhotoGiveawayPhoto,
    addPhotoGiveawayPhoto,
    updatePhotoGiveawayPhoto,
    deletePhotoGiveawayPhoto,
    getPhotoGiveawayPhotoById
};

function getListsPhotoGiveawayPhoto(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/photo-giveaway/photo?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function addPhotoGiveawayPhoto(values) {
    let formData = new FormData()
    formData.set("name", values.name);
    formData.set("description", values.description ? values.description : "");
    formData.append('file', values.photoGiveawayPhotoImage);
    formData.set("status", values.status);

    var tmp = axios.post(configConstants.API_BACKEND_CRM + '/photo-giveaway/photo', formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });
    return tmp;
}

function updatePhotoGiveawayPhoto(values) {
    var photoGiveawayPhotoId = values._id;
    let formData = new FormData();
    formData.set("name", values.name);
    formData.set("description", values.description ? values.description : "");
    formData.append('file', values.photoGiveawayPhotoImage);
    formData.set("status", values.status);

    var tmp = axios.put(configConstants.API_BACKEND_CRM + `/photo-giveaway/photo/${photoGiveawayPhotoId}`, formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });
    return tmp;
}

function deletePhotoGiveawayPhoto(params) {
    var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/photo-giveaway/photo/${params._id}/force`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function getPhotoGiveawayPhotoById(photoGiveawayPhotoId) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/photo-giveaway/photo/${photoGiveawayPhotoId}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data.photoGiveawayPhoto,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}
import { reset } from 'redux-form'
import lodash from 'lodash';

import { blacklistServices } from '../services';
import { blacklistConstants } from '../constants';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const blacklistAction = {
    getCampaignList,
    getBlacklistList,
    addBlacklist,
    editBlacklist,
    uploadBlacklist,
    saveUploadBlacklist,
    deleteBlacklist,
    // importMember
};

function getCampaignList(params) {
    return dispatch => {
        blacklistServices.getCampaignsList(params)
            .then(
                response => {
                    if (response.status) {
                        if (lodash.has(response, 'data')) {
                            dispatch(success(response.data));
                        }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                // loader(dispatch, false)
            })
    };

    function success(data) {
        return { type: blacklistConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data }
    }
}

function getBlacklistList(params, tableReset = false) {
    return dispatch => {
        dispatch({ type: blacklistConstants.SHOW_LOADER })

        blacklistServices.getBlacklistList(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({ type: blacklistConstants.GET_BLACKLIST_SUCCESS, data: response.data.data, total: response.data.total, tableFilter: params });
                        // if (tableReset) {
                        //    dispatch({ type: whitelistConstants.TABLE_GET_LIST_DATAS_RESET });
                        // }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                dispatch({ type: blacklistConstants.HIDE_LOADER })
            })
    };
}

function addBlacklist(params) {
    // console.log('addNewWhitelist action');
    // console.log('params :', params);
    return dispatch => {
        loader(dispatch, true)

        blacklistServices.addBlacklist(params)
            .then(
                response => {
                    //console.log(response)
                    if (response.status) {
                        dispatch({
                            type: blacklistConstants.ADD_NEW_BLACKLIST_SUCCESS
                        });

                        customAlert(dispatch, 'success', i18n.t('warning_messages.blacklist_was_created'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    //console.log(error)
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    //function success(tmp) { return { type: whitelistConstants.ADD_NEW_WHITELIST_SUCCESS, tmp } }
}

function editBlacklist(params) {
    return dispatch => {
        loader(dispatch, true)

        blacklistServices.editBlacklist(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                        customAlert(dispatch, 'success', i18n.t('warning_messages.blacklist_was_updated'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: blacklistConstants.EDIT_BLACKLIST_SUCCESS, tmp } }
}

function deleteBlacklist(params) {
    //console.log('action',params);
    return dispatch => {
        loader(dispatch, true)
        blacklistServices.deleteBlacklist(params)
            .then(
                (response) => {
                    //console.log(response);
                    if (response.status) {
                        dispatch({ type: blacklistConstants.DELETE_BLACKLIST_SUCCESS })
                        customAlert(
                            dispatch,
                            "success",
                            i18n.t("warning_messages.blacklist_was_deleted")
                        )
                    } else {
                        if (!response.unAlert)
                            customAlert(
                                dispatch,
                                "error",
                                i18n.t(`api_messages.${response.message.toString()}`)
                            )
                    }
                },
                (error) => {
                    customAlert(dispatch, "error", i18n.t(defaultFailedText))
                }
            )
            .then(() => {
                loader(dispatch, false)
            })

    };
}

function uploadBlacklist(params) {
    return dispatch => {
        loader(dispatch, true)
        blacklistServices.uploadBlacklist(params)
            .then(
                response => {
                    if (response.status) {
                        //console.log(response.data)
                        dispatch({
                            type: blacklistConstants.SHOW_UPLOAD_BLACKLIST_RESULT,
                            data: response.data
                        });
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function saveUploadBlacklist(params) {
    return dispatch => {
        loader(dispatch, true)
        blacklistServices.saveUploadBlacklist(params)
            .then(
                response => {
                    if (response.status) {
                        //console.log(response.data)
                        dispatch({
                            type: blacklistConstants.OPEN_SAVE_UPLOAD_BLACKLIST_RESULT,
                            data: response.data
                        });
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import lodash from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

import Badge from "components/Badge/Badge.jsx";
import i18n from 'i18n';

function CampaignSelect({ ...props }) {
    const {
        input,
        classes,
        formControlProps,
        labelText,
        id,
        type,
        disabled,
        labelProps,
        inputProps,
        white,
        optionsList,
        novalidate,
        meta,
    } = props;
    const error = (meta !== undefined && meta.touched && meta.invalid);
    const success = (meta !== undefined && meta.touched && meta.valid);
    const labelClasses = classNames({
        [" " + classes.labelRootError]: error && !novalidate,
        [" " + classes.labelRootSuccess]: success && !error && !novalidate
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error && !novalidate,
        [classes.underlineSuccess]: success && !error && !novalidate,
        [classes.underline]: true,
        [classes.whiteUnderline]: white
    });

    var helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

    const [searchKeyword, setSearchKeyword] = React.useState()

    const selectItemInit = () => {
        if (lodash.size(optionsList) > 0) {
            return optionsList.map((data, i) => {
                const reg = new RegExp(searchKeyword)
                if (reg.test(data.name)) {
                    return (
                        <MenuItem key={i} value={data._id}>
                            {data.name}
                        </MenuItem >
                    )
                } else {
                    return null
                }
            })
        } else {
            return <MenuItem value="" disabled><em>ไม่มีข้อมูล</em></MenuItem>
        }
    }

    let selValue = input.value;
    if (!selValue) {
        selValue = []
    }

    return (
        <FormControl {...formControlProps} className={classes.selectFormControl + " " + underlineClasses}>
            <InputLabel
                className={classes.selectLabel + " " + labelClasses}
                htmlFor={id}
                {...labelProps}
            >
                {labelText}
            </InputLabel>
            <Select
                {...input}
                value={selValue}
                multiple={false}
                classes={{
                    select: classes.select,
                }}
                MenuProps={{
                    className: classes.selectMenu,
                }}
                onClose={() => {
                    setSearchKeyword('')
                }}

                type={type}
                disabled={disabled}
                {...inputProps}
            >
                {selectItemInit()}
            </Select>
            {(meta !== undefined && meta.touched && meta.invalid) === true ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {i18n.t(meta.error)}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
}

CampaignSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helpText: PropTypes.node
};

export default withStyles(styles)(CampaignSelect);

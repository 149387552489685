export const pointByPurchaseConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_PAGE_STATE',
    OPEN_ADD_POINT_BY_PURCHASE: 'OPEN_ADD_POINT_BY_PURCHASE',
    CLOSE_FORM_POINT_BY_PURCHASE: 'CLOSE_FORM_POINT_BY_PURCHASE',
    ADD_POINT_BY_PURCHASE_SUCCESS: 'ADD_POINT_BY_PURCHASE_SUCCESS',

    GET_USER_BY_PHONE_NUMBER: 'GET_USER_BY_PHONE_NUMBER',
    GET_LIST_TABLE_POINT_BY_PURCHASE: 'GET_LIST_TABLE_POINT_BY_PURCHASE',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
    SHOW_LOADER_POINT_BY_PURCHASE: 'SHOW_LOADER_POINT_BY_PURCHASE',
    HIDE_LOADER_POINT_BY_PURCHASE: 'HIDE_LOADER_POINT_BY_PURCHASE',

    POINT_BY_PURCHASE_TABLE_RESET: 'POINT_BY_PURCHASE_REPORT_TABLE_RESET',
    SET_FILTER_SEARCH: 'SET_FILTER_SEARCH',
    CLEAR_FILTER_SEARCH: 'CLEAR_FILTER_SEARCH',

    GET_ADMIN_LIST: 'GET_ADMIN_LIST',
    VOID_POINT_BY_PURCHASE_SUCCESS: 'VOID_POINT_BY_PURCHASE_SUCCESS',
};
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import lodash from "lodash";
import moment from "moment";

// @material-ui/core components
import CardHeader from "components/Card/CardHeader.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import TableMemberList from "views/Member/TableMemberList.jsx";
import { memberActions } from "actions";
import { reportServices } from 'services';
import { crmValidator } from "helpers";

// import Gender from "assets/data/genders.json";

import { plugins, loader, alert as customAlert } from "helpers";

import i18n from 'i18n';

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  reportHeaderContainer: {
    margin: "0px",
    width: "calc(100% - 10px)",
    padding: "0px"
  },
  btnClearFilter: {
    marginTop: "20px",
    width: "100%"
  },
  textCenter: {
    textAlign: "center"
  },
  mt20: {
    marginTop: "20px"
  }
};

const form_validate = {
  phoneNumber: {
      rules: {
          number: true
      },
      messages: {
          required: "กรุณากรอก"
      }
  }
};

const moduleName = "member";
const FORM_NAME = "formFilterMember";

class MemberList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      urlParameters: "page=0&skip=0&limit=10&sortBy=created_at&sortDir=desc&keyword=",
      filter: {
        page: 0,
        rowsPerPage: 10,
        skip: 0,
        limit: 10,
        sortBy: "created_at",
        sortDir: "desc",
        keyword: "",
      }
    }
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(memberActions.getActiveTagList());
    dispatch(memberActions.getActiveMemberLevelList());
  }

  clearFilter = () => {
    const { dispatch } = this.props;
    dispatch(change(FORM_NAME, "gender", ""));
    dispatch(change(FORM_NAME, "filterAge", ""));
    dispatch(change(FORM_NAME, "filterProvince", ""));
    dispatch(change(FORM_NAME, "filterTagList", ""));
    dispatch(change(FORM_NAME, "startDate", ""));
    dispatch(change(FORM_NAME, "endDate", ""));
    dispatch(change(FORM_NAME, "filterMemberLevel", ""));
    dispatch(change(FORM_NAME, "verificationStatus", ""));

    this.setState({
      filter: {
        page: 0,
        rowsPerPage: 10,
        skip: 0,
        limit: 10,
        sortBy: "created_at",
        sortDir: "desc",
        keyword: "",
      }
    })
  };

  // exportCSV() {
  //   const { total, dispatch } = this.props;

  //   console.log('this.state.urlParameters :',this.state.urlParameters);

  //   if (total > 30000) {
  //     customAlert(dispatch, 'error', "Can not export data more than 30,000 rows")
  //   } else {
  //     loader(dispatch, true)
  //     reportServices.downloadMemberReport(this.state.urlParameters)
  //       .then(response => {
  //         loader(dispatch, false)
  //       })
  //       .catch(e => {
  //         customAlert(dispatch, 'error', "api_messages.SOMETHING_WENT_WRONG")
  //         loader(dispatch, false)
  //       });
  //   }
  // }

  setFilter(parameters, filters) {
    this.setState({
      urlParameters: parameters,
      filter: filters
    })
  }

  handleSubmit(values) {
    const { dispatch } = this.props;

    let tableFilter = {}
    let search = { ...values };

    const momentFormat = 'YYYY-MM-DDTHH:mm:ss'
    if (lodash.size(search) > 0) {
      if (lodash.has(values, "startDate") && lodash.has(values, "endDate")) {
        lodash.set(tableFilter, "createDateFrom", moment(values.startDate).second(0).format(momentFormat));
        lodash.set(tableFilter, "createDateTo", moment(values.endDate).second(59).format(momentFormat));
      } else if (lodash.has(values, "startDate")) {
        lodash.set(tableFilter, "createDateFrom", moment(values.startDate).second(0).format(momentFormat));
      } else if (lodash.has(values, "endDate")) {
        lodash.set(tableFilter, "createDateTo", moment(values.endDate).second(59).format(momentFormat));
      }
      if (values.startDate==="") {
        delete tableFilter.createDateFrom
      }
      if (values.endDate==="") {
        delete tableFilter.createDateTo
      }

      if (lodash.has(values, "gender")) {
        lodash.set(tableFilter, "sex", values.gender)
      }
      if (lodash.has(values, "filterAge")) {
        lodash.set(tableFilter, "filterAge", values.filterAge)
      }
      if (lodash.has(values, "memberFirstName")) {
        lodash.set(tableFilter, "memberFirstName", values.memberFirstName)
      }
      if (lodash.has(values, "memberLastName")) {
        lodash.set(tableFilter, "memberLastName", values.memberLastName)
      }
      if (lodash.has(values, "phoneNumber")) {
        lodash.set(tableFilter, "phoneNumber", values.phoneNumber)
      }
      if (lodash.has(values, "memberId")) {
        lodash.set(tableFilter, "memberId", values.memberId)
      }
      if (lodash.has(values, "filterTagList")) {
        lodash.set(tableFilter, "tagList", values.filterTagList)
      }
      if (lodash.has(values, "filterMemberLevel")) {
        lodash.set(tableFilter, "filterMemberLevel", values.filterMemberLevel)
      }
      if (lodash.has(values, "verificationStatus")) {
        lodash.set(tableFilter, "verificationStatus", values.verificationStatus)
      }
      if (lodash.has(values, "filterProvince")) {
        lodash.set(tableFilter, "province", values.filterProvince)
      }

    }

    lodash.set(tableFilter, "page", 0)
    lodash.set(tableFilter, "rowsPerPage", 10)
    lodash.set(tableFilter, "skip", 0)
    lodash.set(tableFilter, "limit", 10)
    lodash.set(tableFilter, "sortBy", "created_at")
    lodash.set(tableFilter, "sortDir", "desc")

    let urlParameters = Object.entries(tableFilter).map(e => e.join('=')).join('&');

    this.setFilter(urlParameters, tableFilter)

    dispatch(memberActions.getTableDatas(urlParameters));
  }

  render() {
    const {
      classes,
      formMemberLevellist,
      formTaglist,
      handleSubmit,
      merchantSetting,
      formFilterMember
    } = this.props;

    let filterAgeOptions = [...merchantSetting.ageRangeList, {
      "label": "Not specified",
      "value": "unknown"
    }];

    let startMaxDate;
    if (lodash.has(formFilterMember, "values.endDate")) {
      startMaxDate = formFilterMember.values.endDate;
    } else {
      startMaxDate = new Date();
    }

    let endMinDate;
    if (lodash.has(formFilterMember, "values.startDate")) {
      endMinDate = formFilterMember.values.startDate;
    }


    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <h3
                className={classes.cardIconTitle}
                style={{ marginLeft: "15px" }}
              >
                <b>{i18n.t("member")}</b>
              </h3>
            </CardHeader>
            <CardBody>
              <form name={FORM_NAME}
                onSubmit={handleSubmit(this.handleSubmit.bind(this))}
              >
                <GridContainer className={classes.reportHeaderContainer}>
                  <GridItem xs={6} sm={3}>
                    <Field
                      name="startDate"
                      component={CustomDatePicker}
                      type="text"
                      maxDate={startMaxDate}
                      labelText={i18n.t("label.start_date")}
                      dateFormat="DD-MMM-YY"
                      timeFormat="HH:mm:ss"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={3}>
                    <Field
                      name="endDate"
                      minDate={endMinDate}
                      maxDate={new Date()}
                      component={CustomDatePicker}
                      type="text"
                      labelText={i18n.t("label.end_date")}
                      dateFormat="DD-MMM-YY"
                      timeFormat="HH:mm:ss"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={3}>
                    <Field
                      name="gender"
                      component={CustomSelect}
                      type="text"
                      labelText={i18n.t("label.gender")}
                      formControlProps={{
                        fullWidth: true
                      }}
                      novalidate
                      // optionsList={Gender}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={3}>
                    <Field
                      name="filterAge"
                      component={CustomSelect}
                      type="text"
                      labelText={i18n.t("label.age")}
                      formControlProps={{
                        fullWidth: true
                      }}
                      novalidate
                      optionsList={filterAgeOptions}
                    />
                  </GridItem>


                  <GridItem xs={6} sm={6} md={3} lg={3}>
                    <Field
                        name="memberFirstName"
                        component={CustomInput}
                        type="text"
                        labelText="Firstname"
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3} lg={3}>
                      <Field
                          name="memberLastName"
                          component={CustomInput}
                          type="text"
                          labelText="Lastname"
                          formControlProps={{
                              fullWidth: true
                          }}
                      />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3} lg={3}>
                    <Field
                        name="phoneNumber"
                        component={CustomInput}
                        type="text"
                        labelText="Mobile No."
                        formControlProps={{
                            fullWidth: true
                        }}
                        normalize={value => {
                            return crmValidator.handleKeyPress(
                                value,
                                form_validate.phoneNumber
                            );
                        }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3} lg={3}>
                      <Field
                          name="memberId"
                          component={CustomInput}
                          type="text"
                          labelText="Member ID"
                          formControlProps={{
                              fullWidth: true
                          }}
                          normalize={value => {
                            return crmValidator.handleKeyPress(
                                value,
                                form_validate.phoneNumber
                            );
                          }}
                      />
                  </GridItem>

                  <GridItem xs={6} sm={3}>
                    <Field
                      name="filterProvince"
                      component={CustomSelect}
                      type="text"
                      labelText={i18n.t("label.province")}
                      novalidate
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={3}>
                    <Field
                      name="filterTagList"
                      component={TagsMultiSelect}
                      labelText={i18n.t("label.tag")}
                      formControlProps={{
                        fullWidth: true
                      }}
                      showtick
                      inputRef={this.inputRef}
                      optionsList={formTaglist}
                    />
                  </GridItem>
                  
                  <GridItem xs={6} sm={3}>
                    <Field
                      name="filterMemberLevel"
                      component={TagsMultiSelect}
                      labelText={i18n.t("label.memberLevel")}
                      formControlProps={{
                        fullWidth: true
                      }}
                      showtick
                      optionsList={formMemberLevellist}
                    />
                  </GridItem>

                  <GridItem xs={6} sm={3}>
                    <Field
                      name="verificationStatus"
                      component={CustomSelect}
                      labelText={i18n.t("label.verification_status")}
                      formControlProps={{
                        fullWidth: true
                      }}
                      optionsList={[{
                        "label": "Pending",
                        "value": "pending"
                      },{
                        "label": "Approve",
                        "value": "approve"
                      },{
                        "label": "Reject",
                        "value": "reject"
                      },
                      {
                        "label": "Not Specified",
                        "value": ""
                      }]}
                    />
                  </GridItem>
                  
                  {/* {plugins.isHasPermission(moduleName, 'export') && plugins.isHasPackageFeatures('export') &&
                    <GridItem xs={12} sm={4} md={4} lg={3} className={classes.textCenter}>
                      <Button
                        style={{
                          marginTop: "25px",
                          backgroundColor: "#84B0F3",
                          float: "left"
                        }}
                        color="primary"
                        onClick={this.exportCSV.bind(this)}
                      >
                        {i18n.t("export_to_csv")}
                    </Button>
                    </GridItem>
                  } */}

                </GridContainer>
                <GridContainer>

                  <GridItem xs={12} sm={4} md={4} lg={3}>
                    <Button
                      onClick={this.clearFilter}
                      style={{
                        marginTop: "25px"
                      }}
                      color="white"
                    >
                      {i18n.t("clear")}
                    </Button>
                    &nbsp;  &nbsp;  &nbsp;
                    <Button
                      type="submit"
                      style={{
                        marginTop: "25px"
                      }}
                      color="primary"
                    >
                      {i18n.t("submit")}
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>

              <GridContainer>
                <GridItem xs={12}>
                  <TableMemberList seturlParameters={(param, filter) => { this.setFilter(param, filter)}} parameters={this.state.filter} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { sweetAlert } = state.sweetAlert;
  const { permission, superAdmin, merchantSetting, visiblePersonalData } = state.authentication;
  const { formFilterMember } = state.form;
  const {
    formTaglist,
    formMemberLevellist,
    tableReset,
    total
  } = state.member;

  return {
    visiblePersonalData,
    tableReset,
    formMemberLevellist,
    sweetAlert,
    permission,
    superAdmin,
    formTaglist,
    formFilterMember,
    merchantSetting,
    total
  };
}
const connectMemberContainer = connect(mapStateToProps)(MemberList);

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(withStyles(style)(connectMemberContainer));

import React from "react";
import { Field, change } from 'redux-form';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";


import { normalize } from 'helpers'

import i18n from 'i18n';
import lodash from 'lodash';

import * as FormValidator from '../validate/FormValidator';


function FormLuckyDraw({ ...props }) {
   const { dispatch, formMode } = props.props
   const  { setLuckyDrawValue } = props
   let { readOnly } = props.props

   if (formMode === `add`) {
      readOnly = false
   } else if (formMode === `edit` && !readOnly) {
      readOnly = false
   } else {
      readOnly = true
   }
 
   return (
      <>
         <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <Field
                        name="luckyDrawRewardId"
                        component={CustomSelect}
                        type="text"
                        labelText={i18n.t("label.true_money_reward")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        optionsList={setLuckyDrawValue}
                        disabled={readOnly || formMode === "edit"}
                     />
                  </GridItem>
               </GridContainer>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <Field
                        name="rewardDetail[luckyChance]"
                        component={CustomInput}
                        type="text"
                        labelText={i18n.t("label.reward_lucky_chance")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        disabled={readOnly}
                        normalize={normalize.onlyNumber}
                     />
                  </GridItem>
               </GridContainer>
            </GridItem>
         </GridContainer>
      </>
   );
}

export default FormLuckyDraw;

import { orderConstants } from '../constants';
import lodash from 'lodash'
import moment from 'moment';

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: Date.now(),
    tableDatas: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false,
    productData: [],
    categoryData: [],
    showLoader: false,
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "created_at",
        sortDir: "desc",
        keyword: "",
        search: {
            startDate: moment().startOf('day'), 
            endDate: moment().set('hour',23).set('minute',59).set('second',59)
        }
    },
    purchaseProductConditionOpen: false,
    purchaseAmountPerbillOpen: false,
    paymentChannelConditionOpen: false,
    promoCodeConditionOpen: false,
    productConditionIndex: null,
    rewardModalOpen: null,
    regionList:[],
    regionSelected:[],
    allShopList:[],
    shopList:[],
    allShopListSelected:[],
    shopListSelected:[],
    additionalConditionShopShow: false,
    additionalConditionTimeShow: false,
    allPromotionList: [],
}

export function order(state = initialState, action) {
    switch (action.type) {
        case orderConstants.CLEAR_PAGE_STATE:
            return initialState;
        case orderConstants.OPEN_EDIT_ORDER:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
            };
        case orderConstants.CLOSE_EDIT_ORDER:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
            }
        case orderConstants.EDIT_ORDER_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case orderConstants.TABLE_GET_LIST_ORDER:
            return {
                ...state,
                tableDatas: action.data,
                total: action.data.total,
                tableReload: false,
                tableReset: false
            }
        case orderConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        default:
            return state;
    }
}
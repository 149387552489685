import React from "react";
import PropTypes from "prop-types";
import defaultImage from "assets/img/image_placeholder.jpg";

class InputUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.refs.fileInput.click();
  }

  componentDidUpdate(){
    if((this.props.imagePreviewUrl === defaultImage) && this.refs.fileInput.value !== null){
      this.refs.fileInput.value = null;
    }
  }

  render() {
    var {
      input,
      accept,
      meta: {touched, error}
    } = this.props;

    return (
      <>
        <input type="file" onChange={input.onChange} name={input.name} accept={accept} ref="fileInput"/>
        {touched && error &&
          <div style={{marginTop: "10px", color: "#f44336"}}>{error}</div>
        }
      </>
    );
  }
}

InputUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object
};

export default InputUpload;

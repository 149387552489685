import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'
import moment from 'moment';

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import ColorSelect from "components/CustomSelect/ColorSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import { blacklistConstants } from '../../constants'
import { blacklistAction } from 'actions';

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";


const FORM_NAME = 'BLACKLIST_FORM';

const form_validate = {
    "campaignId": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "value": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "type": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const normalizeBlacklistMaxLength = (value) => {
    return value.substr(0, 50);
}

class BlacklistForm extends React.Component {
    handleSubmit = (values) => {
        const { dispatch } = this.props
        var reqParams = {
            "value": values.value,
            "type": values.type,
            "campaignId": values.campaignId
        }

        //console.log(this.props.formMode);

        if (this.props.formMode === "add") {
            dispatch(blacklistAction.addBlacklist(reqParams));
        } else {
            reqParams._id = values._id
            dispatch(blacklistAction.editBlacklist(reqParams));
        }
    }

    render() {
        const { classes, formOpen, formMode, handleSubmit, blacklistContainer } = this.props

        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        let campaignAllList = [];

        if (lodash.has(blacklistContainer, 'campaignList')) {
            lodash.forEach(blacklistContainer.campaignList, function (value, key) {

                campaignAllList.push({
                    value: value._id,
                    label: value.name,
                    id: value._id,
                })

            })
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>{formMode === 'add' ? 'Add Blacklist' : (readOnly === true ? 'Blacklist Info' : 'Edit Blacklist')}</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                {formMode === "edit" &&
                                    <Field
                                        name="_id"
                                        component="input"
                                        type="hidden"
                                        disabled={true}
                                    />
                                }
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="campaignId"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Campaign"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={campaignAllList}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="type"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Type"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        { value: "Email", label: "Email" },
                                        { value: "Mobile", label: "Mobile" },
                                        { value: "LineID", label: "Line ID" },
                                        { value: "FacebookID", label: "Facebook ID" }
                                    ]}
                                    disabled={readOnly}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="value"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Blacklist"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={normalizeBlacklistMaxLength}
                                />
                            </GridItem>

                        </GridContainer>

                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>

                        {readOnly === false &&
                            <Button
                                style={{ marginRight: "10px" }}
                                color="primary"
                                type="submit">
                                Save
                            </Button>
                        }

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: blacklistConstants.CLOSE_FORM })
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode, blacklistContainer } = state.blacklist;
    const { BLACKLIST_FORM } = state.form
    return {
        formOpen, formMode, BLACKLIST_FORM, blacklistContainer
    };
}

const connectedComponent = connect(mapStateToProps)(BlacklistForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))

import { loaderServices} from 'services';
import { loader } from '../helpers';
import { loaderConstants } from '../constants';
import lodash from 'lodash';

export const loaderActions = {
    getConfig
}

function getConfig() {
    return dispatch => {
       loader(dispatch, true);
       loaderServices.getEnvConfig()
          .then(
             response => {
                if (response.status) {
                   dispatch({ type: loaderConstants.GET_PUBLIC_CONFIG, envConfig: response.data })
                }
             }
          ).then(() => {
             loader(dispatch, false);
          })
    }
 }
 
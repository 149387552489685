import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, touch } from 'redux-form';
import { crmValidator } from 'helpers';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import { store, normalize, alert as customAlert } from 'helpers';

import { brandConstants } from '../../constants';
import { brandAction } from '../../actions';

const validMimeType = "image/jpeg, image/png";
const validateImageFormat = imageFile => {
    if (imageFile) {
        if (!validMimeType.includes(imageFile.type)) {
            return `รองรับนามสกุลไฟล์ jpeg หรือ png เท่านั้น `;
        }
    }
}
const validateImageSize = imageFile => {
    if (imageFile) {
        if (imageFile.size > (1048576 * 2)) {
            return `Please select file less than 2MB`
        }
    }
}
const FORM_NAME = "FORM_BRAND";
const form_validate = {
    "name": {
        rules: {
            required: true
        },
        messages: {
            required: 'Brand Name is required'
        },
        alert: "Brand Name is required"
    },
    "code": {
        rules: {
            required: true
        },
        messages: {
            required: 'Brand Code is required'
        },
        alert: "Brand Code is required"
    },
    "prefix": {
        rules: {
            required: true
        },
        messages: {
            required: 'Brand Prefix is required'
        },
        alert: "Brand Prefix is required"
    }
};
const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const validateRequiredImage = imageFile => {
    if (imageFile === null || imageFile === undefined || imageFile === "") {
        return "Brand Pic is required"
    }
}

const NotValidateRequiredImage = imageFile => {
    return "";
}


class BrandForm extends React.Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = (values) => {
        const { dispatch, merchantID } = this.props;
        values.merchantId = merchantID

        if (this.props.formMode === "add") {
            dispatch(brandAction.addNewBrands(values))
        }
        else {
            dispatch(brandAction.editBrands(values));
        }
    }


    render() {
        const { formOpen, formMode, classes, dispatch, handleSubmit, brandImagePreview, formTaglist, formMemberLevellist, FORM_BRAND } = this.props;
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Brand' : (readOnly ? 'Brand Info' : 'Edit Brand')}</b></h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="category-form-dialog"
                        className={classes.modalBody}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id={`${FORM_NAME}_name`}
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Brand Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id={`${FORM_NAME}_code`}
                                    name="code"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Brand Code"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id={`${FORM_NAME}_prefix`}
                                    name="prefix"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Brand Prefix"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id={`${FORM_NAME}_bank`}
                                    name="bank"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Brand Bank Account"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id='file'
                                    name="file"
                                    component={ImageUpload}
                                    type="text"
                                    labelText="Brand Picture"
                                    textCenter={true}
                                    width={(1140 / 2.5)}
                                    height={(400 / 2.5)}
                                    showRatioGuide={true}
                                    recommendWidth={1140}
                                    recommendHeight={400}
                                    disabled={false}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let reader = new FileReader();
                                        let file = e.target.files[0];
                                        reader.onloadend = () => {
                                            dispatch({ type: brandConstants.SET_FORM_PREVIEW_IMAGE, brandPreview: reader.result })
                                        };

                                        try {
                                            if (!validMimeType.includes(file.type)) {
                                                dispatch(touch(FORM_NAME, 'file'));
                                            }
                                            reader.readAsDataURL(file);
                                            dispatch(change(FORM_NAME, 'file', file))
                                        } catch (error) {
                                            dispatch({ type: brandConstants.SET_FORM_PREVIEW_IMAGE, brandPreview: "default" })
                                            dispatch(change(FORM_NAME, 'file', ''))
                                        }
                                    }}
                                    imagePreviewUrl={brandImagePreview}
                                    formControlProps={{
                                        fullWidth: true,

                                    }}
                                    validate={[
                                        formMode === 'add' ? validateRequiredImage : NotValidateRequiredImage,
                                        validateImageFormat,
                                        validateImageSize
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>

                    <DialogActions
                        className={classes.modalFooter}>
                        <Button
                            id={`${FORM_NAME}_btn_submit`}
                            type="submit"
                            style={{ marginRight: "10px" }}
                            color="primary">
                            Submit
                            </Button>
                        <Button
                            id={`${FORM_NAME}_btn_cancel`}
                            type="button"
                            onClick={() => {
                                dispatch({ type: brandConstants.CLOSE_FORM_BRAND });
                            }}
                            color="white">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode, brandImagePreview, readOnly } = state.brand
    const { formInitialValue : { merchantID } } = state.merchant
    const { FORM_BRAND } = state.form;
    return {
        formOpen, formMode, brandImagePreview, FORM_BRAND, readOnly, merchantID
    };
}

const connectCategoryForm = connect(mapStateToProps)(BrandForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);

        let globalStore = store.getState();
        let formMeta = globalStore.form[FORM_NAME];
        if (Object.keys(errors).length === 1) {
            if (errors.file && !formMeta.values.file) {
                customAlert(dispatch, 'error', 'Brand Pic is required')
            }
        }

    }
})(withStyles(modalStyle)(connectCategoryForm))
import React from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import { merchantConstants } from '../../constants';
import { merchantActions } from 'actions';
import { plugins, crmValidator } from 'helpers';

const style = {
    actionDiv: {
        displat: "inline-flex"
    },
    actionButton: {
        margin: "0 0 0 5px",
        padding: "5px",
        "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
            marginRight: "0px"
        }
    },
};

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

class TableMerchantList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        };

        this.tableRef = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 500)

    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(merchantActions.getListsMerchant(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.merchants;
        return data[rowIndex];
    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: merchantConstants.CLEAR_PAGE_STATE });
    }

    componentDidMount() {
        let { actions, readOnly } = this.state
        const { permission, superAdmin } = this.props

        if (superAdmin === false) {
            try {
                actions = actions.filter((action) => {
                    if (permission.product.indexOf('update') !== -1) {
                        readOnly = false
                    }
                    return permission.product.indexOf(action.name) !== -1
                })
            } catch (err) {
                actions = []
            }

            this.setState({
                actions: actions,
                readOnly: readOnly
            })
        } else {
            this.setState({
                readOnly: false
            })
        }

        this.getData();
    }

    openAddMerchant = () => {
        const { dispatch } = this.props;
        let initialValues = {
            startDate: "",
            endDate: "",
            productImage: undefined
        }
        dispatch({ type: merchantConstants.OPEN_MANAGEMENT_ADD, data: initialValues });
    }

    openEditMerchant(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.merchants[index]
        dispatch(merchantActions.getMerchantById(rowData._id))
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: merchantConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    render() {
        const { classes, tableDatas, tableKey, tableLoader } = this.props;
        const { rowsPerPage, page } = this.state;

        const columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "contractStartDate", options: { filter: false, display: "excluded" } },
            { name: "contractEndDate", options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            { name: "nameEN", label: "Name" },
            {
                name: "industry", label: "Industry", options: {
                    customBodyRender: (value) => {
                        return (<span style={{ textTransform: "capitalize" }}>{value}</span>)
                    }
                }
            },
            {
                label: "Contract date", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let rowData = this.getRowData(tableMeta.rowIndex)
                        if (!lodash.isEmpty(rowData)) {
                            let startDate = plugins.datetimeFormat(rowData.contractStartDate, "date");
                            let endDate = plugins.datetimeFormat(rowData.contractEndDate, "date");
                            return (<>{startDate}<br />{endDate}</>)
                        } else {
                            return ''
                        }
                    }
                }
            },
            {
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classes.actionDiv}>
                                <TableActionButton
                                    actionType="edit"
                                    onClick={(e) => {
                                        this.openEditMerchant(tableMeta.rowIndex)
                                    }} />
                            </div>
                        )
                    }
                }
            },
        ];

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            customToolbar: () => {
                return (
                    <CustomToolbar handleClick={this.openAddMerchant} title="Add New" />
                );
            },
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        return (
            <div id="TableMerchantList">
                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        title={"Merchant list"}
                        data={tableDatas.merchants}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner/>}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader } = state.merchant;
    const { permission, superAdmin } = state.authentication;
    return {
        formInitialValue, tableReload, tableReset, permission, superAdmin, tableDatas, tableKey, tableLoader
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableMerchantList));
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink, Link } from "react-router-dom";
import cx from "classnames";
import _ from "lodash";
import { connect } from 'react-redux';

import LinkM from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import BallotIcon from '@material-ui/icons/Ballot';

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import defaultAvatar from "assets/img/default-avatar.png";

import { urlPath } from 'helpers';
import { authActions } from 'actions';
import { plugins } from 'helpers';


var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }
    render() {
        const { className, user, headerLinks, links, chatbotLink } = this.props;
        return (
            <div className={className} ref="sidebarWrapper">
                {user}
                {headerLinks}
                {links}
                {chatbotLink}
            </div>
        );
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            openMerchant: false,
            miniActive: true,
            ...this.getCollapseStates(props.routes)
        };
    }

    // getEnvConfig() {
    //     const { dispatch } = this.props;
    //     dispatch(authActions.getEnvConfig());
    // }

    // componentDidMount() {
    //     this.getEnvConfig()
    // }

    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        if (routes !== undefined) {
            routes.map((prop, key) => {
                if (prop.collapse) {
                    initialState = {
                        [prop.state]: this.getCollapseInitialState(prop.views),
                        ...this.getCollapseStates(prop.views),
                        ...initialState
                    };
                }
                return null;
            });
        }
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if ((routes[i].collapse && this.getCollapseInitialState(routes[i].views) || window.location.href.indexOf(routes[i].path) !== -1) && !routes[i].disabled) {
                return true;
            }
        }
        return false;
    }
    // verifies if routeName is the one active (in browser input)
    activeRoute = route => {
        return this.props.location.pathname === route.path && !route.disabled ? "active" : "";
    };
    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }

    handleSwitchMerchant = (merchantId) => {
        const { dispatch } = this.props;
        dispatch(authActions.SwitchMerchant(merchantId));
    }
    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {
        const { classes, color } = this.props;
        if (routes !== undefined) {
            return routes.map((prop, key) => {
                if (prop.redirect || prop.hidden) {
                    return null;
                }
                if (prop.invisible) return null;
                if (prop.collapse) {
                    var st = {};
                    st[prop["state"]] = !this.state[prop.state];
                    const navLinkClasses =
                        classes.itemLink +
                        " " +
                        cx({
                            [" " + classes.collapseActive]: this.getCollapseInitialState(
                                prop.views
                            )
                        });
                    const itemText =
                        classes.itemText +
                        " " +
                        cx({
                            [classes.itemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                        });
                    const collapseItemText =
                        classes.collapseItemText +
                        " " +
                        cx({
                            [classes.collapseItemTextMini]:
                                this.props.miniActive && this.state.miniActive,
                        });
                    const itemIcon = classes.itemIcon;
                    const caret = classes.caret;
                    const collapseItemMini = classes.collapseItemMini;

                    return (
                        <ListItem
                            key={key}
                            id={`menu_${prop.id}`}
                            className={cx(
                                { [classes.item]: prop.icon !== undefined },
                                { [classes.collapseItem]: prop.icon === undefined }
                            )}
                            disabled={prop.disabled}
                        >
                            <NavLink
                                to={"#"}
                                className={navLinkClasses}
                                onClick={e => {
                                    e.preventDefault();
                                    this.setState(st);
                                }}
                            >
                                {prop.icon !== undefined ? (
                                    typeof prop.icon === "string" ? (
                                        <Icon className={itemIcon}>{prop.icon}</Icon>
                                    ) : (
                                        <prop.icon className={itemIcon} />
                                    )
                                ) : (
                                    <span className={collapseItemMini}>
                                        {prop.mini}
                                    </span>
                                )}
                                <ListItemText
                                    primary={prop.displayName}
                                    secondary={
                                        <b
                                            className={
                                                caret +
                                                " " +
                                                (this.state[prop.state] ? classes.caretActive : "")
                                            }
                                        />
                                    }
                                    disableTypography={true}
                                    className={cx(
                                        { [itemText]: prop.icon !== undefined },
                                        { [collapseItemText]: prop.icon === undefined }
                                    )}
                                />
                            </NavLink>
                            <Collapse in={this.state[prop.state]} unmountOnExit>
                                <List className={classes.list + " " + classes.collapseList}>
                                    {this.createLinks(prop.views)}
                                </List>
                            </Collapse>
                        </ListItem>
                    );
                } else {
                    const innerNavLinkClasses =
                        classes.collapseItemLink +
                        " " +
                        cx({
                            [" " + classes[color]]: this.activeRoute(prop)
                        });
                    const collapseItemMini = classes.collapseItemMini;
                    const navLinkClasses =
                        classes.itemLink +
                        " " +
                        cx({
                            [" " + classes[color]]: this.activeRoute(prop)
                        });
                    const itemText =
                        classes.itemText +
                        " " +
                        cx({
                            [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
                        });
                    const collapseItemText =
                        classes.collapseItemText +
                        " " +
                        cx({
                            [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
                        });
                    const itemIcon = classes.itemIcon;
                    return (
                        <ListItem
                            key={key}
                            id={`menu_${prop.id}`}
                            className={cx(
                                { [classes.item]: prop.icon !== undefined },
                                { [classes.collapseItem]: prop.icon === undefined }
                            )}
                            disabled={prop.disabled}
                        >
                            <NavLink
                                to={urlPath + prop.path}
                                className={`${cx(
                                    { [navLinkClasses]: prop.icon !== undefined },
                                    { [innerNavLinkClasses]: prop.icon === undefined }
                                )} menu_${prop.id}`}
                                style={prop.disabled ? { pointerEvents: "none" } : {}}
                            >
                                {prop.icon !== undefined ? (
                                    typeof prop.icon === "string" ? (
                                        <Icon className={itemIcon}>{prop.icon}</Icon>
                                    ) : (
                                        <prop.icon className={itemIcon} />
                                    )
                                ) : (
                                    <span className={collapseItemMini}>
                                        {prop.mini}
                                    </span>
                                )}
                                <ListItemText
                                    primary={prop.displayName}
                                    disableTypography={true}
                                    className={cx(
                                        { [itemText]: prop.icon !== undefined },
                                        { [collapseItemText]: prop.icon === undefined }
                                    )}
                                />
                            </NavLink>
                        </ListItem>
                    );
                }
            });
        }

        return false
    };

    createSwitchMerchant = (merchantLists, merchantId) => {
        const { classes } = this.props;
        const collapseItemMini = classes.collapseItemMini;
        const collapseItemText = classes.collapseItemText + " " + cx({ [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive, });
        let merchants = _.filter(merchantLists, function (tmp) { return tmp._id !== merchantId });
        merchants = _.sortBy(merchants, ['nameEN']);
        if (merchants.length > 0) {
            return merchants.map((prop, key) => {
                return <ListItem className={classes.collapseItem} key={key}>
                    <NavLink
                        // id="btn-signout"
                        to=""
                        onClick={() => this.handleSwitchMerchant(prop._id)}
                        className={
                            classes.itemLink + " " + classes.userCollapseLinks
                        }
                    >
                        <span className={collapseItemMini}>
                            {" - "}
                        </span>
                        <ListItemText
                            primary={prop.nameEN}
                            disableTypography={true}
                            className={collapseItemText}
                        />
                    </NavLink>
                </ListItem>
            })
        }
    }

    render() {
        const {
            classes,
            // logo,
            logoText,
            routes,
            bgColor,
            avatarName,
            defaultMerchantId,
            masterAccount,
            masterLists,
            user,
            merchant,
            envConfig
        } = this.props;

        let merchantName = _.find(masterLists, ['_id', defaultMerchantId]) || {};
        merchantName = merchantName.nameEN || ""
        const itemText =
            classes.itemText +
            " " +
            cx({
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
            });
        const collapseItemText =
            classes.collapseItemText +
            " " +
            cx({
                [classes.collapseItemTextMini]:
                    this.props.miniActive && this.state.miniActive,
            });
        const userWrapperClass =
            classes.user +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });

        const caret = classes.caret;
        const collapseItemMini = classes.collapseItemMini;
        const photo = classes.photo;
        let source = localStorage.getItem('source') || ''
        let accessToken = localStorage.getItem('token') || ''
        let bypassLoginSBO = _.get(envConfig, 'BYPASS_LOGIN_SBO_URL') ? _.get(envConfig, 'BYPASS_LOGIN_SBO_URL') : ""
        let isShopOwner = _.get(user, 'shopOwner', false)

        var sidebarUser = (
            <>
                {masterAccount ?
                    <div className={userWrapperClass}>
                        <div className={photo}>
                            {/* <img src="/assets/img/default-avatar.png" className={classes.avatarImg} onError={(e) => { e.target.onError = null; e.target.src = defaultAvatar; }} alt="..." /> */}
                        </div>
                        <List className={classes.list}>
                            <ListItem className={classes.item + " " + classes.userItem}>
                                <NavLink
                                    to={"#"}
                                    className={classes.itemLink + " " + classes.userCollapseButton}
                                    onClick={() => this.openCollapse("openAvatar")}
                                >
                                    <ListItemText
                                        id="sidebar-user-item-text"
                                        primary={merchantName}
                                        secondary={
                                            <b className={caret + " " + classes.userCaret + " " + (this.state.openAvatar ? classes.caretActive : "")} />
                                        }
                                        disableTypography={true}
                                        className={itemText + " " + classes.userItemText}
                                    />
                                </NavLink>
                                <Collapse in={this.state.openAvatar} unmountOnExit>
                                    <List className={classes.list + " " + classes.collapseList}>
                                        {this.createSwitchMerchant(masterLists, defaultMerchantId)}
                                    </List>
                                </Collapse>
                            </ListItem>
                        </List>
                    </div>
                    : null}
                <div className={userWrapperClass}>
                    <div className={photo}>
                        <img src="/assets/img/default-avatar.png" className={classes.avatarImg} onError={(e) => { e.target.onError = null; e.target.src = defaultAvatar; }} alt="..." />
                    </div>
                    <List className={classes.list}>
                        <ListItem className={classes.item + " " + classes.userItem}>
                            <NavLink
                                to={"#"}
                                className={classes.itemLink + " " + classes.userCollapseButton}
                                onClick={() => this.openCollapse("openMerchant")}
                            >
                                <ListItemText
                                    id="sidebar-user-item-text"
                                    primary={avatarName}
                                    secondary={
                                        <b className={caret + " " + classes.userCaret + " " + (this.state.openMerchant ? classes.caretActive : "")} />
                                    }
                                    disableTypography={true}
                                    className={itemText + " " + classes.userItemText}
                                />
                            </NavLink>
                            <Collapse in={this.state.openMerchant} unmountOnExit>
                                <List className={classes.list + " " + classes.collapseList}>
                                    {/* { plugins.isHasPermission('package', 'read') && !merchant.masterAccount && (source === 'cuaa') && (bypassLoginSBO != "") ?  */}
                                    {plugins.isHasPackageFeatures('package') && !merchant.masterAccount && user.cuaa_id && (bypassLoginSBO != "") ?
                                        <div>
                                            {source === 'cuaa' ? isShopOwner ?
                                                <form
                                                    // className={classes.itemLink} 
                                                    method="POST"
                                                    target="_blank"
                                                    action={bypassLoginSBO}
                                                >
                                                    <input type="hidden" name="access_token" value={accessToken} />
                                                    <Button
                                                        className={classes.itemLink}
                                                        style={{ textDecoration: "none", textTransform: "none", width: "-webkit-fill-available", textAlign: "start" }}
                                                        type="submit"
                                                    >
                                                        <BallotIcon className={classes.customItemIcon} />
                                                        Package
                                                    </Button>
                                                </form> : null
                                                :
                                                <LinkM
                                                    className={classes.itemLink}
                                                    style={{ textDecoration: "none", textTransform: "none", width: "-webkit-fill-available", textAlign: "start" }}
                                                    href={bypassLoginSBO.replace('/api/auth/passlogin', '/home')}
                                                    target="_blank"
                                                >
                                                    <BallotIcon className={classes.customItemIcon} />
                                                    Package
                                                </LinkM>}
                                        </div>
                                        : null}
                                    <ListItem className={classes.collapseItem}>
                                        <NavLink
                                            id="btn-signout"
                                            to="/auth/login"
                                            className={
                                                classes.itemLink + " " + classes.userCollapseLinks
                                            }
                                        >
                                            <span className={collapseItemMini}>
                                                {"SO"}
                                            </span>
                                            <ListItemText
                                                primary={"Sign out"}
                                                disableTypography={true}
                                                className={collapseItemText}
                                            />
                                        </NavLink>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </ListItem>
                    </List>
                </div>
            </>
        );
        var links = (
            <List className={classes.list}>{this.createLinks(routes)}</List>
        );

        // var linkAdmins = (
        //   <List className={classes.list}>{this.createLinks(routeAdminsjs)}</List>
        // );

        const logoNormal =
            classes.logoNormal +
            " " +
            cx({
                [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
            });
        const logoMini = classes.logoMini;
        const logoClasses =
            classes.logo +
            " " +
            cx({
                [classes.whiteAfter]: bgColor === "white"
            });
        var brand = (
            <div className={logoClasses}>
                <Link to="/dashboard" className={logoMini}>
                    <img src="/assets/img/egg-logo.png" alt="logo" className={classes.img} />
                </Link>
                <Link to="/dashboard" className={logoNormal}>
                    {logoText}
                </Link>
            </div>
        );

        var chatbot = plugins.isHasPermission('chatbot', 'read') && !merchant.masterAccount && user.tokenChatbot ? (
            <div >
                <LinkM
                    className={classes.itemLink}
                    style={{ textDecoration: "none" }}
                    href={process.env.REACT_APP_CHATBOT_URL + user.tokenChatbot}
                    target="_blank"
                >
                    <QuestionAnswer className={classes.customItemIcon} />
                    Chatbot & Sale Order
                </LinkM>
            </div>
        ) : undefined

        const drawerPaper =
            classes.drawerPaper +
            " " +
            cx({
                [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
            });
        const sidebarWrapper =
            classes.sidebarWrapper +
            " " +
            cx({
                [classes.drawerPaperMini]:
                    this.props.miniActive && this.state.miniActive,
                [classes.sidebarWrapperWithPerfectScrollbar]:
                    navigator.platform.indexOf("Win") > -1
            });
        return (
            <div ref="mainPanel">
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={"left"}
                        open={this.props.open}
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                    >
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={sidebarUser}
                            links={links}
                            chatbotLink={chatbot}
                        />
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        onMouseOver={() => this.setState({ miniActive: false })}
                        onMouseOut={() => this.setState({ miniActive: true })}
                        anchor={"left"}
                        variant="permanent"
                        open
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                    >
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={sidebarUser}
                            links={links}
                            chatbotLink={chatbot}
                        />
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

Sidebar.defaultProps = {
    bgColor: "blue"
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    color: PropTypes.oneOf([
        "white",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "rose",
        "primary"
    ]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object)
};

function mapStateToProps(state) {
    const { user, merchant, envConfig } = state.authentication;
    return {
        user,
        merchant,
        envConfig
    };
}

const connectApp = connect(mapStateToProps)(withStyles(sidebarStyle)(Sidebar));
// export { connectApp as AdminLayout }

export default connectApp
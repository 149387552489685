import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import lodash from 'lodash'
import { tmnTopupConstants } from '../../constants';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const errMsg = [
    { code: "0", msg: "Success" },
    { code: "190003", msg: "Duplicate Transation ID" },
    { code: "190023", msg: "System Error" },
    { code: "380058", msg: "Invalid Command" },
    { code: "390061", msg: "Invalid Data" },
    { code: "390063", msg: "Invalid Data Exception", sub: "[Not Verify Yet. Error]" },
    { code: "800007", msg: "REQUEST_TIMEOUT" },
    { code: "TWS001", msg: "Could not found E-Wallet" },
    { code: "TWS002", msg: "System Error" },
    { code: "TWS005", msg: "Inactive" },
    { code: "TWS006", msg: "Over Credit Limit" }
]


class ErrorDetail extends React.Component {

    renderErrorCode = () => {
        let render = []
        errMsg.forEach((val, i) => {
            render.push(
                <tr key={i}>
                    <td style={{ border: "1px solid black", borderCollapse: "collapse", textAlign: "center" }}>{val.code}  </td>
                    <td style={{ border: "1px solid black", borderCollapse: "collapse", }}>{val.msg} {lodash.has(val, 'sub') && <p>{val.sub}</p>}</td>
                </tr>
            )
        })
        return render
    }
    render() {
        const { classes,dispatch,errDetailOpen} = this.props
        return (
            <>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={errDetailOpen ? errDetailOpen : false }
                    keepMounted

                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                    maxWidth='xs'
                    scroll="body"
                    disableRestoreFocus={true}
                >
                    <DialogContent>
                        <table style={{
                            width: "100%",
                            fontSize: "12px",
                            border: "1px solid black",
                            borderCollapse: "collapse",
                            marginTop : '35px'
                        }} >
                            <tbody>
                                <tr style={{
                                    border: "1px solid black",
                                    borderCollapse: "collapse"
                                }} >
                                    <th style={{
                                        border: "1px solid black",
                                        borderCollapse: "collapse"
                                    }} >Response Code</th>
                                    <th style={{
                                        border: "1px solid black",
                                        borderCollapse: "collapse"
                                    }} >Response Description</th>
                                </tr>
                                {this.renderErrorCode()}
                            </tbody>
                        </table>
                        <Button
                            id={`btn_cancel`}
                            type="button"
                            style={{ marginTop : '35px'}}
                            onClick={() => {
                                dispatch({ type: tmnTopupConstants.ERR_DETAIL_IS_CLOSE });
                            }}
                            color="white">
                            CLose
                        </Button>
                    </DialogContent>
                </Dialog>

            </>
        )
    }


}


function mapStateToProps(state) {
    const { errDetailOpen } = state.tmnTopup;
    return {
        errDetailOpen
    };
}
const connectedComponent = connect(mapStateToProps)(ErrorDetail);


export default withStyles(sweetAlertStyle)(connectedComponent);
import { configConstants } from '../constants';
import axios from 'axios';
import lodash from 'lodash';

import { history, crmValidator, setHeaders } from 'helpers';

export const uploadWinnerServices = {
    getCampaignsList,
    getUploadWinnerList,
    addUploadWinner,
    editUploadWinner,
    deleteUploadWinner,
    saveUploadUploadWinner,
    uploadUploadWinner,
    // importMember
    getAnnounceDateList,
    getRewardList,
};

function getCampaignsList(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns?rewardType=couponCode`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

function getAnnounceDateList(params) {
    let tmp = axios.get(configConstants.API_LUCKYDRAW + `/list/announce_date`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            // console.log('response announce_date: ', response);
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

// getRewardList
function getRewardList(params) {
    let tmp = axios.get(configConstants.API_LUCKYDRAW + `/list/reward-name?${params}`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            console.log('response reward: ', response);
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

function getUploadWinnerList(params) {
    var tmp = axios.post(configConstants.API_BLACKLIST + '/blacklist/search', params, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function addUploadWinner(params) {
    var tmp = axios.post(configConstants.API_BLACKLIST + '/blacklist', params, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function editUploadWinner(params) {
    var tmp = axios.put(configConstants.API_BLACKLIST + `/blacklist/${params._id}`, params, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function deleteUploadWinner(params) {
    let tmp = axios.delete(configConstants.API_BLACKLIST + `/blacklist/${params._id}`, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function uploadUploadWinner(params) {
    console.log('services params  : ', params);
    try {
        let formData = new FormData();

        if (!crmValidator.isEmptyValue(params)) {
            console.log('1')
            lodash.forEach(params, function (value, key) {
                console.log('value :', value)
                console.log('key :', key)
                if (key === "file") {
                    formData.append('file', value[0]);
                } else {
                    formData.append(key, value)
                }
            })
        }

        console.log('formData : ', formData);

        var tmp = axios.post(configConstants.API_LUCKYDRAW + '/winner/upload', formData, {
            headers: setHeaders({ 'Charset': 'utf-8' })
        })
            .then(response => {
                // console.log('services upload winner response', response);
                if (response.data.statusCode.toString() === "200") {
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: response.data,
                        message: response.data.message
                    };
                }
            })
            .catch(error => {
                return {
                    status: false,
                    data: {
                        statusCode: 500
                    },
                    message: 'File not found, Please select file and try again.'
                };
            })
        return tmp;
    } catch (e) {
        return {
            status: false,
            data: {
                statusCode: 500
            },
            message: 'File not found, Please select file and try again.'
        };
    }
}

function saveUploadUploadWinner(params) {

    console.log("save services params", params)
    try {

        var tmp = axios.post(configConstants.API_LUCKYDRAW + '/winner/savefileupload', params, {
            headers: setHeaders({ 'Charset': 'utf-8' })
        })
            .then(response => {
                //console.log(response)
                if (response.data.statusCode.toString() === "200") {
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: response.data,
                        message: response.data.message
                    };
                }
            })
            .catch(error => {
                return {
                    status: false,
                    data: {
                        statusCode: 500
                    },
                    message: 'File not found, Please select file and try again.'
                };
            })
        return tmp;
    } catch (e) {
        return {
            status: false,
            data: {
                statusCode: 500
            },
            message: 'File not found, Please select file and try again.'
        };
    }
}

export const colorSet = [
    {   "name": "eggYellow",
        "primary" : "#FFC600",
        "fontIconPrimary" : "#292929"
    },
    {   "name" : "turquoiseGreen",
        "primary" : "#46D2B8",
        "fontIconPrimary" : "#292929"
    },
    {   "name": "malibuBlue",
        "primary" : "#73C8FA",
        "fontIconPrimary" : "#292929"
    },
    {   "name": "danubeBlue",
        "primary" : "#6D8FDB",
        "fontIconPrimary" : "#FFFFFF"
    },
    {   "name": "ceruleanBlue",
        "primary" : "#235FAF",
        "fontIconPrimary" : "#FFFFFF"
    },
    {   "name": "pumpkinOrange",
        "primary" : "#FF8039",
        "fontIconPrimary" : "#FFFFFF"
    },
    {   "name": "pigmentGreen",
        "primary" : "#00AD4A",
        "fontIconPrimary" : "#FFFFFF"
    },
    {   "name": "violet",
        "primary" : "#7B61BB",
        "fontIconPrimary" : "#FFFFFF"
    },
    {   "name": "taffyPink",
        "primary" : "#FA9BCB",
        "fontIconPrimary" : "#292929"
    },
    {   "name": "valenciaRed",
        "primary" : "#DE4343",
        "fontIconPrimary" : "#FFFFFF"
    }
]
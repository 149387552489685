import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import MUIDataTable from "mui-datatables";
import { contentActions } from "actions";
import defaultImage from "assets/img/image_placeholder.jpg";
// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

// page components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import { contentManagementConstants, swalConstants } from '../../constants';
import { plugins, crmValidator } from "helpers";
import ContentManagementForm from './ContentManagementForm'
// import ImportResultDetail from './ImportResultDetail'
import i18n from "i18n";

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    actionDiv: {
        display: "inline-flex",
    },
};

const moduleName = "content_management";

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                color: '#666666',
                padding: '4px 24px 4px 15px',
                "&:nth-child(11)": {
                    textAlign: "center"
                }
            },
            body: {
                color: '#666666',
                padding: '4px 15px'
            }
        }
    }
});

class TableContentManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           page: 0,
           rowsPerPage: 10,
           reqParams:""
        }
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: contentManagementConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.contents;
        return data[rowIndex];
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableDatas } = this.props
        let reqParams = "";
    
        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }
    
            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }
    
            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)
            if(tableDatas.total !== 0 && tableDatas.total === skip){
                skip = skip - limit
                this.setState({
                    page: this.state.page - 1,
                })                
            }
            if(skip >= tableDatas.total){
                skip = 0;
                this.setState({
                    page: 0,
                })
            }
            this.setState({
                reqParams: {
                    skip: skip,
                    limit: limit,
                 },
            })
            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit
    
            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }
    
            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }
    
            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + keyword
            }
        } else {
            reqParams = this.state.reqParams
        }
        dispatch(contentActions.getTableContentDatas(reqParams));
        this.setState({
            reqParams: reqParams
        });
    }
       

    openFormAdd = () => {
        const { dispatch } = this.props;
        dispatch({ type: contentManagementConstants.OPEN_FORM_ADD });
    };

    openFormEdit(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.contents[index]
        dispatch({ type: contentManagementConstants.OPEN_FORM_EDIT, data: rowData, 
            mobileImageFilePath: lodash.get(rowData, 'mobileImageFilePath') ?  lodash.get(rowData, 'mobileImageFilePath') : defaultImage, 
            desktopImageFilePath: lodash.get(rowData, 'desktopImageFilePath') ? lodash.get(rowData, 'desktopImageFilePath') : defaultImage,
            mobileImageSelected: lodash.get(rowData, 'mobileImageFilePath') ? true : false,
            desktopImageSelected: lodash.get(rowData, 'desktopImageFilePath') ? true : false,
        })
    }
    render() {
        const { classes, tableKey, tableDatas, formInitialValue, dispatch, visiblePersonalData, showLoader } = this.props
        const { page, rowsPerPage } = this.state
        let columns = [
            { name: "_id", options: { display: "excluded" } },
            {
                label: i18n.t("label.no"),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowSatrt =
                            tableMeta.tableState.page *
                            tableMeta.tableState.rowsPerPage;
                        const rowIndex = rowSatrt + tableMeta.rowIndex;
                        return rowIndex + 1;
                    },
                },
            },
            { name: "displayId", label: "ContentID",},
            { name: "contentName", label: "Content name" },
            { name: "contentType", label: "Type", options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowData = this.getRowData(tableMeta.rowIndex)
                    let typeLabel = 'News'
                    if(rowData.contentType === 'news'){ typeLabel = "News"; }
                    else if(rowData.contentType === 'about_us'){ typeLabel = "About us"; }
                    else if(rowData.contentType === 'contact_us'){ typeLabel = 'Contact us'; }
                    else if(rowData.contentType === 'faq'){ typeLabel = "FAQ"; }
                    else if(rowData.contentType === 'luckydraw'){ typeLabel = "Lucky Draw"; }
                    else if(rowData.contentType === 'other'){ typeLabel = "Other"; }
                    return typeLabel
                } } ,
            },
            { name: "version", label: "Version." },
            { name: "versionDate", label: "Version date", options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowData = this.getRowData(tableMeta.rowIndex)
                    return (rowData.versionDate !== undefined || rowData.versionDate != null) ? (<span className="text-nowrap">{plugins.datetimeFormat(rowData.versionDate, "datetime")}</span>) : ""
                } }
            },
            { name: "visibleDate",label: "Visible date", options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowData = this.getRowData(tableMeta.rowIndex)
                    return (rowData.visibleDate !== undefined || rowData.visibleDate != null) ? (<span className="text-nowrap">{plugins.datetimeFormat(rowData.visibleDate, "datetime")}</span>) : ""
                } }
            },
            { name: "created_at", label: "Create date", options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowData = this.getRowData(tableMeta.rowIndex)
                    return (rowData.created_at !== undefined || rowData.created_at != null) ? (<span className="text-nowrap">{plugins.datetimeFormat(rowData.created_at, "datetime")}</span>) : ""
                } }
            },
            { name: "updated_at", label: "Update date", options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowData = this.getRowData(tableMeta.rowIndex)
                    return (rowData.updated_at !== undefined || rowData.updated_at != null) ? (<span className="text-nowrap">{plugins.datetimeFormat(rowData.updated_at, "datetime")}</span>) : ""
                } }
            },
            { name: "status", label: "Status" },
        ];
        if (plugins.isHasPermission(moduleName, 'read') && visiblePersonalData) {
            columns.push({
               label: "Action",
               options: {
                  filter: false,
                  sort: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                     return (
                        <div className={classes.actionDiv}>
                           {plugins.isHasPermission(moduleName, 'update') &&
                              <TableActionButton
                                 id="btn_edit"
                                 actionType="edit"
                                 onClick={e => {
                                    this.openFormEdit(tableMeta.rowIndex);
                                 }}
                              />
                           }
                           {plugins.isHasPermission(moduleName, 'delete') && (
                              <TableActionButton
                                 id="btn_delete"
                                 actionType="delete"
                                 onClick={e => {
                                    dispatch({
                                       type: swalConstants.SHOW,
                                       data: {
                                          type: "warning",
                                          title: i18n.t("warning_messages.are_you_sure_delete_content"),
                                          message: `${this.getRowData(tableMeta.rowIndex).contentName}`,
                                          onConfirm: function () {
                                             let reqParams = {
                                                _id: tableMeta.rowData[0]
                                             };
                                             dispatch({ type: swalConstants.HIDE });
                                             dispatch(contentActions.deleteContentDatas(reqParams));
                                          },
                                          onCancel: function () {
                                             dispatch({ type: swalConstants.HIDE });
                                          },
                                          showCancel: true,
                                          confirmBtnText: i18n.t("delete"),
                                          cancelBtnText: i18n.t("cancel")
                                       }
                                    });
                                 }} />)}
                        </div>
                     );
                  }
               }
            });
         }

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };
        if (plugins.isHasPermission(moduleName, 'create')) {
            options.customToolbar = () => {
               return (
                  <CustomToolbar handleClick={this.openFormAdd.bind(this)} title="Add New" id='btn_add'/>
               );
            }
         }
        return (
            <div id='tableContentManagementList'>
                <CustomSweetAlert />
                <ContentManagementForm initialValues={formInitialValue}/>
                {/* <ImportResultDetail/> */}
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        title={"CONTENT MANAGEMENT"}
                        data={tableDatas.contents}
                        columns={columns}
                        options={options}
                    />
                    {showLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { tableKey, tableDatas, formInitialValue, tableReset, showLoader, tableFilter, tableReload } = state.content_management;
    const { permission, superAdmin, visiblePersonalData } = state.authentication;
    return {
        permission, superAdmin, tableKey, tableDatas, formInitialValue, tableReset, visiblePersonalData, showLoader, tableFilter, tableReload
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableContentManagement));
import { configConstants } from '../constants';
import axios from 'axios';
import _ from 'lodash';
import { history, crmValidator, setHeaders } from 'helpers';
import objectToFormData from 'object-to-formdata';

export const brandServices = {
  getBrandLists,
  addNewBrands,
  editBrands,
  deleteBrand,
  getListsProduct,
  getEarnRuleById,
  getRegionLists,
  getShopListsByRegion,
  getRewardList,
  downloadEarnRuleReport,
  importProduct
};

function getBrandLists(reqParams = '') {
  let tmp = axios.get(configConstants.API_BACKEND_CRM + `/sales-page/brands?${reqParams}`, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    })

  return tmp;
}

function downloadEarnRuleReport(reqParams) {
  let tmp = axios.get(configConstants.API_BACKEND_CRM + `/earn-rules/export/?${reqParams}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        const urlDownload = _.get(response.data, "data.url")
        if(urlDownload){
           window.location.assign(urlDownload)
        }
        return { status: true, data: {}, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    })

  return tmp;
}

function addNewBrands(params) {
  const formData = objectToFormData(params);
  var tmp = axios.post(configConstants.API_BACKEND_CRM + '/sales-page/brands', formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: e.message };
    })
  return tmp;
}

function editBrands(params) {
  const formData = objectToFormData(
    params
  );

  var tmp = axios.put(configConstants.API_BACKEND_CRM + `/sales-page/brands/${params._id}`, formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: e.message };
    })
  return tmp;
}

function deleteBrand(params) {
  let tmp = axios.delete(configConstants.API_BACKEND_CRM + `/sales-page/brands/${params._id}/soft`, { headers: setHeaders() })
    .then(response => {
      if (response.status.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    });

  return tmp;
}

function getListsProduct(params) {
  var tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/products?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    })
    .catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

  return tmp;
}

function getEarnRuleById(id) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/earn-rules/${id}`, { headers: setHeaders() })
        .then(response => {
            if (response.status.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function getRegionLists(){
  let tmp = axios
    .get(configConstants.API_BACKEND_CRM + `/list/shop-region`, {
      headers: setHeaders({ "Charset": "utf-8" }),
    })
    .then((response) => {
      if (response.data.statusCode.toString() === "200") {
        return {
          status: true,
          data: response.data.data,
          message: response.data.message,
        }
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push("/auth/login")
        return {
          status: false,
          data: {},
          message: response.data.message,
          unAlert: true,
        }
      } else {
        return { status: false, data: {}, message: response.data.message }
      }
    })
    .catch((e) => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" }
    })

  return tmp
}


function getShopListsByRegion(params){
  let tmp = axios
    .get(configConstants.API_BACKEND_CRM + `/list/find-by-region?${params}`, {
      headers: setHeaders({ "Charset": "utf-8" }),
    })
    .then((response) => {
      if (response.data.statusCode.toString() === "200") {
        return {
          status: true,
          data: response.data.data,
          message: response.data.message,
        }
      } else if (response.data.statusCode.toString() === "400") {
        return {
          status: true,
          data: [],
          message: response.data.message,
        }
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push("/auth/login")
        return {
          status: false,
          data: {},
          message: response.data.message,
          unAlert: true,
        }
      } else {
        return { status: false, data: {}, message: response.data.message }
      }
    })
    .catch((e) => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" }
    })

  return tmp
}

function getRewardList(params) {
  let req_headers = {
    'Content-Type': 'application/json',
    'Charset': 'utf-8',
    'Authorization': localStorage.getItem('token')
  }
  let tmp = axios.get(configConstants.API_LUCKYDRAW + `/list/reward-name?${params}`, {
    headers: req_headers
  })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return {
          status: true,
          data: response.data.data,
          message: response.data.message
        };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return {
          status: false,
          data: {},
          message: response.data.message
        };
      }
    })
    .catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

  return tmp;
}

function importProduct(params) {
  try {
      let formData = new FormData();

      if (!crmValidator.isEmptyValue(params)) {
          _.forEach(params, function (value, key) {
              if (key === "fileImport") {
                  formData.append('file', value[0]);
              } else {
                  formData.append(key, value)
              }
          })
      }

      var tmp = axios.post(configConstants.API_BACKEND_CRM + '/earn-rules/import-product', formData, {
          headers: setHeaders({ 'Charset': 'utf-8' })
      })
          .then(response => {
              if (response.data.statusCode.toString() === "200") {
                  return {
                      status: true,
                      data: response.data.data,
                      message: response.data.message
                  };
              } else if (response.data.message === "UNAUTHORIZE") {
                  history.push('/auth/login')
                  return { status: false, data: {}, message: response.data.message, unAlert: true };
              } else {
                  return {
                      status: false,
                      data: response.data,
                      message: response.data.message
                  };
              }
          })
          .catch(error => {
              return {
                  status: false,
                  data: {
                      statusCode: 500
                  },
                  message: 'File not found, Please select file and try again.'
              };
          })
      return tmp;
  } catch (e) {
      return {
          status: false,
          data: {
              statusCode: 500
          },
          message: 'File not found, Please select file and try again.'
      };
  }
}
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import moment from 'moment'
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from 'components/CustomButtons/Button';
import lodash from 'lodash'
import { dashboardActions } from 'actions';

const FORM_NAME = 'formFilterDailyEarnTransaction'
class FormFilterDailyEarnTransaction extends React.Component {

  handleSubmit(values) {
    const { dispatch } = this.props
    let tableDialyEarnTransactionFilter = {
      search: {
        "activeChannel": 'all',
        "startDate": moment(lodash.get(values, 'startDate')).format('YYYY-MM'),
        "endDate": moment(lodash.get(values, 'endDate')).format('YYYY-MM'),
      }
    }

    let activeChannel = lodash.get(values, 'activeChannel')

    tableDialyEarnTransactionFilter.search = {
      "startDate": moment(lodash.get(values, 'startDate')).format('YYYY-MM-DD'),
      "endDate": moment(lodash.get(values, 'endDate')).format('YYYY-MM-DD'),
      "activeChannel": activeChannel
    }

    dispatch(dashboardActions.getTableDailyEarnTransactionDatas(tableDialyEarnTransactionFilter));
    dispatch({type: 'DASHBOARD_RESET_ACTIVE_DAILY_EARN_TRANSACTION', tableDialyEarnTransactionFilter })
  }

  render() {
    const { activeUser, startDate, endDate, handleSubmit } = this.props;
    var startMinDate;
    var startMaxDate;
    if(endDate !== ''){
      startMaxDate = moment(endDate)
      startMinDate = moment(endDate).subtract(30, 'days')
    }else{
      startMaxDate = moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day')
      startMinDate = null;
    }
    var endMinDate;
    var endMaxDate = moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day')
    if(startDate !== ''){ 
      endMinDate = moment(startDate)
      if( moment(startDate).add(30, 'days') <= moment().subtract(1, 'day') ){
          endMaxDate = moment(startDate).add(30, 'days')
      }
    }else{
      endMinDate = null;
    }
    const activeChannel = [{
      label: 'All',
      value: 'all'
    }, {
      label: 'Line',
      value: 'line'
    }, {
      label: 'Web',
      value: 'web'
    }]

    return (
      <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
        <GridContainer>
          <GridItem xs={12} sm={3} md={2} lg={2}>
            <Field
              id="filter_channel_daily_earn_transaction"
              name="activeChannel"
              component={CustomSelect}
              type="text"
              labelText="Active Channel"
              formControlProps={{
                fullWidth: true
              }}
              optionsList={activeChannel}
            />
          </GridItem>

          <div style={{ display: 'contents' }}>
            <GridItem xs={12} sm={6} md={3} lg={3}>
              <Field
                id='filter_start_date_daily_earn_transaction'
                name="startDate"
                component={CustomDatePicker}
                type="text"
                minDate={startMinDate}
                maxDate={startMaxDate}
                labelText="Start Date"
                dateFormat="DD-MMM-YY"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3} lg={3}>
              <Field
                id='filter_end_date_daily_earn_transaction'
                name="endDate"
                component={CustomDatePicker}
                type="text"
                minDate={endMinDate}
                maxDate={endMaxDate}
                labelText="End Date"
                dateFormat="DD-MMM-YY"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
          </div>

          <GridItem xs={12} sm={12} md={2} lg={2}>
            <Button
              type="submit"
              style={{ marginRight: "10px", marginTop: "25px" }}
              size="sm"
              color="primary"
              id='btn_submit'>
              Submit
              </Button>
          </GridItem>

        </GridContainer>

      </form>
    )
  }
}

FormFilterDailyEarnTransaction = reduxForm({
  form: FORM_NAME,
  enableReinitialize: false,
})(FormFilterDailyEarnTransaction)

const selector = formValueSelector(FORM_NAME)

FormFilterDailyEarnTransaction = connect(state => {
  const activeChannel = selector(state, 'activeChannel')
  const startDate = selector(state, 'startDate')
  const endDate = selector(state, 'endDate')
  return {
    activeChannel, startDate, endDate
  }
})(FormFilterDailyEarnTransaction)

export default FormFilterDailyEarnTransaction



import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import TableBannerList from "views/Banner/TableBannerList.jsx";

const style = {
    customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
};

function BannerContainer({ ...props }) {
  return (
    <GridContainer>
        <GridItem xs={12}>
          <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <TableBannerList/>
                  </GridItem>
                </GridContainer>
              </CardBody>
          </Card>
        </GridItem>
    </GridContainer>
  )
}

export default withStyles(style)(BannerContainer);
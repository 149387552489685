import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import _ from 'lodash';
import lodash from 'lodash'

export const orderServices = {
    updateOrder,
    getTableOrderDatas,
    deleteOrder,
    downloadOrderShipment
}

function downloadOrderShipment(params) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + `/sales-page/order-shipment/export`, params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
       .then(response => {
          if (response.data.statusCode.toString() === "200") {
             const urlDownload = _.get(response.data, "data.url")
             if(urlDownload){
                window.location.assign(urlDownload)
             }
             return { status: true, data: {}, message: response.data.message };
          } else if (response.data.message === "UNAUTHORIZE") {
             history.push('/auth/login')
             return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
             return { status: false, data: {}, message: response.data.message };
          }
       })
       .catch(e => {
          return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
       });
 
    return tmp;
 }

function updateOrder(reqBody) {
    let tmp = axios.put(configConstants.API_BACKEND_CRM + `/sales-page/order-shipment/${lodash.get(reqBody, '_id')}`, reqBody, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: lodash.get(response, 'data.data'),
                message: response.data.message
            };
        }
    });
    return tmp;
}

function getTableOrderDatas(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/sales-page/order-shipment?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function deleteOrder(params) {
    var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/sales-page/order-shipment/${params._id}/soft`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}
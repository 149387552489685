import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders, crmValidator } from 'helpers';
import lodash from 'lodash';

export const shipmentServices = {
   editShipment,
   getListsShipment,
   getShippingCompanyList,
   getaddresslabelDetail,
   getCampaignsList,
   exportShipment,
   importFile
};

function importFile(params) {
   try {
       let formData = new FormData();

      //  console.log('params :',params)

       if (!crmValidator.isEmptyValue(params)) {
           lodash.forEach(params, function (value, key) {
               if (key === "file") {
                   formData.append('file', value[0]);
               } else {
                   formData.append(key, value)
               }
           })
       }

      //  console.log('formData :',formData)

       var tmp = axios.post(configConstants.API_SHIPPINGS + '/shippings/import', formData, {
           headers: setHeaders({ 'Charset': 'utf-8' })
       })
           .then(response => {
               if (response.data.statusCode.toString() === "200") {
                   return {
                       status: true,
                       data: response.data.data,
                       message: response.message
                   };
               } else if (response.data.message === "UNAUTHORIZE") {
                   history.push('/auth/login')
                   return { status: false, data: {}, message: response.data.message, unAlert: true };
               } else {
                   return {
                       status: false,
                       data: response.data,
                       message: response.data.message
                   };
               }
           })
           .catch(error => {
               console.log('error :',error)
               return {
                   status: false,
                   data: {
                       statusCode: 500
                   },
                   message: 'File not found, Please select file and try again.'
               };
           })
       return tmp;
   } catch (e) {
       return {
           status: false,
           data: {
               statusCode: 500
           },
           message: 'File not found, Please select file and try again.'
       };
   }
}

function editShipment(params) {
   params.address.address = btoa(encodeURIComponent(lodash.get(params, 'address.address')))
   var tmp = axios.put(configConstants.API_SHIPPINGS + `/shippings/${params.shippingId}/update`, params, { headers: setHeaders() })
      .then(response => {
         if (response.status.toString() === "200") {
            return { status: true, data: response.data, message: "UPDATE SUCCESS!" };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: "UPDATE UNSUCCESS!" };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getListsShipment(params) {
   var tmp = axios.post(configConstants.API_SHIPPINGS + '/shippings/search', params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp;
}

function getShippingCompanyList() {

}

function getaddresslabelDetail(params) {
   var req_headers = {
      // "Authorization": "Basic amFzcGVyYWRtaW46amFzcGVyYWRtaW4="
      Authorization: "Basic bWV0YXNfdW1zOjEyMzRxd2Vy"
   }

   let mock = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&')
   axios({
      url: '/rest_v2/reports/reports/ShippingAddress.pdf?' + mock,
      method: 'GET',
      responseType: 'blob', // important,
      headers: req_headers

   }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'address label.pdf');
      document.body.appendChild(link);
      link.click();
   });
}

function getCampaignsList(params) {
   let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns-shipping`, {
      headers: setHeaders({ 'Charset': 'utf-8' })
   })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return {
               status: true,
               data: response.data.data,
               message: response.data.message
            };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return {
               status: false,
               data: {},
               message: response.data.message
            };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function exportShipment(params) {
   var tmp = axios.post(configConstants.API_SHIPPINGS + '/shippings/search-export', params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            const urlDownload = lodash.get(response.data, "data.url")
            if(urlDownload){
               window.location.assign(urlDownload)
            }
            return { status: true, data: {}, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp;
}
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash';

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import { earnactivitiesConstants } from '../../constants';
import { earnActivitiesAction } from "../../actions/earnactivities.actions";
import * as FormValidator from "../EarnActivities/validate/FormValidator"
import { plugins } from 'helpers';

const FORM_NAME = "FORM_EARNACTIVITIES";
const form_validate_register = {
    "name": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้กรอกชื่อ Activity กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "status": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้เลือกสถานะ กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "startDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้เลือกวันเริ่ม กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "endDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้เลือกวันสิ้นสุด กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "type": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "quotaPerActivity": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "point": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    }
}

const form_validate_update_profile = {
    "name": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้กรอกชื่อ Activity กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "status": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้เลือกสถานะ กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "startDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้เลือกวันเริ่ม กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "endDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้เลือกวันสิ้นสุด กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "type": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "quotaPerActivity": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "conditionEarnPoint": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "point": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    }
}

const form_validate_friend_get_friend = {
    "name": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้กรอกชื่อ Activity กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "status": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้เลือกสถานะ กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "startDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้เลือกวันเริ่ม กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "endDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        },
        alert: "ท่านยังไม่ได้เลือกวันสิ้นสุด กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "type": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "quotaPerActivity": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "conditionEarnPoint": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "pointOfInviter": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "pointOfInvitee": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    }
}

const validate = (values) => {
    let form_validate = form_validate_register
    if (values.type === 'update_profile') {
        form_validate = form_validate_update_profile
    }else if (values.type === 'friend_get_friend') {
        form_validate = form_validate_friend_get_friend
    }
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class EarnActivitiesForm extends React.Component {

    handleSubmit = (values) => {
        const { dispatch, formMode } = this.props;
        let params = values
        if (params) {
            if (formMode === "add") {
                dispatch(earnActivitiesAction.addNewEarnActivity(params))
            } else if (formMode === "edit") {
                dispatch(earnActivitiesAction.editEarnActivity(params))
            }
        }
    }

    renderFormType() {
        const { FORM_EARNACTIVITIES, formMode } = this.props;

        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        const validate_field = {
            numberOnly: {
                rules: {
                    required: false,
                    number: true,
                    noSpecialCharacters: true,
                },
            }
        }

        if (!lodash.has(FORM_EARNACTIVITIES, 'values.type')) {
            return null;
        } else {
            const formtype = FORM_EARNACTIVITIES.values.type;
            switch (formtype) {
                case "update_profile": {
                    return (
                        <>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        id="earn-activity-conditionEarnPoint"
                                        name="conditionEarnPoint"
                                        component={CustomSelect}
                                        type="text"
                                        labelText="Condition Earn Point *"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        optionsList={[
                                            {
                                                id: "daily-selected-value",
                                                value: "daily",
                                                label: "Daily"
                                            }, {
                                                id: "weekly-selected-value",
                                                value: "weekly",
                                                label: "Weekly"
                                            }, {
                                                id: "monthly-selected-value",
                                                value: "monthly",
                                                label: "Monthly"
                                            }
                                        ]}
                                        disabled={readOnly || formMode === "edit"}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={8}>
                                    <Field
                                        id="earn-activity-point"
                                        name="point"
                                        component={CustomInput}
                                        type="number"
                                        labelText="Add point *"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        validate={[FormValidator.validateIsZero]}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(
                                                value,
                                                validate_field.numberOnly
                                            )
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </>
                    );
                }
                case "register": {
                    return (
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={8}>
                                <Field
                                    id="earn-activity-point"
                                    name="point"
                                    component={CustomInput}
                                    type="number"
                                    labelText="Add point *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    validate={[FormValidator.validateIsZero]}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(
                                            value,
                                            validate_field.numberOnly
                                        )
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    );
                }
                default: return null
            }
        }

    }

    renderFormTypeFriendGetFriend() {
        const { FORM_EARNACTIVITIES, formMode } = this.props;
        const validate_field = {
            numberOnly: {
                rules: {
                    required: false,
                    number: true,
                    noSpecialCharacters: true,
                },
            }
        }
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        if (!lodash.has(FORM_EARNACTIVITIES, 'values.type')) {
            return null;
        } else {
            const formtype = FORM_EARNACTIVITIES.values.type;
            switch (formtype) {
                case "friend_get_friend": {
                    return (
                        <>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={8}>
                                    <Field
                                        id="earn-activity-quotaPerInvite"
                                        name="quotaPerInvite"
                                        component={CustomInput}
                                        type="number"
                                        labelText="Quota/Invite"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        validate={[FormValidator.validateIsZero]}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(
                                                value,
                                                validate_field.numberOnly
                                            )
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={8}>
                                    <Field
                                        id="earn-activity-inviter-point"
                                        name="pointOfInviter"
                                        component={CustomInput}
                                        type="number"
                                        labelText="Add point of Inviter"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        // validate={[FormValidator.validateIsZero]}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(
                                                value,
                                                validate_field.numberOnly
                                            )
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={8}>
                                    <Field
                                        id="earn-activity-invitee-point"
                                        name="pointOfInvitee"
                                        component={CustomInput}
                                        type="number"
                                        labelText="Add point of invitee"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        // validate={[FormValidator.validateIsZero]}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(
                                                value,
                                                validate_field.numberOnly
                                            )
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={8}>
                                    <Field
                                        id="earn-activity-threshold-invite"
                                        name="thresholdOfInvite"
                                        component={CustomInput}
                                        type="number"
                                        labelText="Add threshold of bonus"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        validate={[FormValidator.validateIsZero]}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(
                                                value,
                                                validate_field.numberOnly
                                            )
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={8}>
                                    <Field
                                        id="earn-activity-threshold-point"
                                        name="pointOfthreshold"
                                        component={CustomInput}
                                        type="number"
                                        labelText="Add point of bonus"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        // validate={[FormValidator.validateIsZero]}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(
                                                value,
                                                validate_field.numberOnly
                                            )
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={8}>
                                    <Field
                                        id="earn-activity-threshold-maxInvitee"
                                        name="maxInviteeOfthreshold"
                                        component={CustomInput}
                                        type="number"
                                        labelText="Add max invitee of bonus"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        validate={[FormValidator.validateIsZero]}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(
                                                value,
                                                validate_field.numberOnly
                                            )
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            
                        </>
                    );
                }
                default: return null;
            }
        }

    }

    render() {
        const { formOpen, formMode, classes, dispatch, handleSubmit, FORM_EARNACTIVITIES, package_features } = this.props
        let { readOnly } = this.props
        let earnActivityTypeList = plugins.getEarnActListByPacekageFeature(package_features)

        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }
        const validate_field = {
            numberOnly: {
                rules: {
                    required: true,
                    number: true,
                    noSpecialCharacters: true,
                },
            }
        }
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Earn Activity' : (readOnly ? 'Earn Activity Info' : 'Edit Earn Activity')}</b></h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent className={classes.modalBody}>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id="earn-activity-name"
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Earn Activity Name *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                                {formMode === "edit" ?
                                    <Field
                                        name="_id"
                                        component="input"
                                        type="hidden"
                                    />
                                    : null}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id="earn-activity-type"
                                    name="type"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Earn Activity Type *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={earnActivityTypeList}
                                    disabled={readOnly || formMode === "edit"}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id="earn-activity-status"
                                    name="status"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Status *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            id: "active-selected-value",
                                            value: "Active",
                                            label: "Active"
                                        },
                                        {
                                            id: "inactive-selected-value",
                                            value: "Inactive",
                                            label: "Inactive"
                                        }
                                    ]}
                                    disabled={readOnly}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id="earn-activity-startDate"
                                    name="startDate"
                                    component={CustomDatePicker}
                                    type="text"
                                    maxDate={FORM_EARNACTIVITIES.values.endDate}
                                    labelText="Start Date *"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id="earn-activity-endDate"
                                    name="endDate"
                                    minDate={FORM_EARNACTIVITIES.values.startDate}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText="End Date"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={8}>
                                <Field
                                    id="earn-activity-quotaPerActivity"
                                    name="quotaPerActivity"
                                    component={CustomInput}
                                    type="number"
                                    labelText="Quota/rule *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    validate={[FormValidator.validateIsZero]}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(
                                            value,
                                            validate_field.numberOnly
                                        )
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        {this.renderFormType()}
                        {this.renderFormTypeFriendGetFriend()}
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        {!readOnly ?
                            <Button
                                id="earn-activity-submit"
                                type="submit"
                                style={{ marginRight: "10px" }}
                                color="primary">
                                {formMode === "edit" ? "Update" : "Submit"}
                            </Button>
                            : ''
                        }
                        <Button
                            id="earn-activity-cancel"
                            type="button"
                            onClick={() => {
                                dispatch({ type: earnactivitiesConstants.CLOSE_FORM_EARN_ACTIVITIES });
                            }}
                            color="white">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }

}

function mapStateToProps(state) {
    const { formOpen, formMode } = state.earnactivities;
    const { FORM_EARNACTIVITIES } = state.form;
    const { package_features } = state.authentication;

    return {
        formOpen, formMode, FORM_EARNACTIVITIES, package_features
    };
}

const earnActivitiesForm = connect(mapStateToProps)(EarnActivitiesForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
})(withStyles(modalStyle)(earnActivitiesForm))

import lodash from 'lodash'
import moment from 'moment'

var initialState = {
  formOpen: false,
  formInitialValue: {
    // importType: "purchase_transaction"
  },
  tableReload: false,
  tableDatas: {
    data: [],
    total: 0
  },
  tableKey: 0,
  importDetail: {
    type: 'purchase_transaction',
    open: false,
    total: 0,
    success: 0,
    failed: 0,
    duplicated: 0,
    failedList: []
  }
}

export function importReducer(state = initialState, action) {
  switch (action.type) {
    case 'IMPORT_SET_INITIALSTATE':
    {
      return initialState
    }
    case 'GET_IMPORT_HISTORY_SUCCESS': {
      return {
        ...state,
        tableDatas: {
          data: lodash.get(action, 'data.data'),
          total: lodash.get(action, 'data.total')
        }
      }
    }
    case 'GET_IMPORT_DETAIL_SUCCESS': {
      return {
        ...state,
        importDetail: action.importDetail
      }
    }
    case 'CLOSE_IMPORT_DETAIL_DIALOG': {
      return {
        ...state,
        importDetail: initialState.importDetail
      }
    }
    case 'IMPORT_HISTORY_TABLE_RELOAD_SUCCESS': {
      return {
        ...state,
        tableReset: false
      }
    }
    case 'OPEN_FORM_IMPORT_FILE': {
      return {
        ...state,
        formOpen: true,
        formInitialValue: initialState.formInitialValue
      }
    }
    case 'CLOSE_FORM_IMPORT_FILE':{
      return {
        ...state,
        formOpen: false
      }
    }
    case 'ADD_IMPORT_FILE_SUCCESS': {
      return {
        ...state,
        tableReset: true,
        tableKey: moment().format('x')
      }
    }
    default:
      return state;
  }
}
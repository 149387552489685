import { photoGiveawayPhotoConstants } from '../constants';
import { photoGiveawayPhotoServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
// import lodash from 'lodash';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const photoGiveawayPhotoActions = {
   addPhotoGiveawayPhoto,
   updatePhotoGiveawayPhoto,
   deletePhotoGiveawayPhoto,
   getListsPhotoGiveawayPhoto,
   getPhotoGiveawayPhotoById
};

function getListsPhotoGiveawayPhoto(params) {
   return dispatch => {
      dispatch({ type: photoGiveawayPhotoConstants.SHOW_TABLE_LOADER })
      photoGiveawayPhotoServices.getListsPhotoGiveawayPhoto(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: photoGiveawayPhotoConstants.HIDE_TABLE_LOADER })
         })
   };

   function success(tmp) { return { type: photoGiveawayPhotoConstants.TABLE_GET_LIST_PHOTO_GIVEAWAY_PHOTOS, tmp }}
}

function addPhotoGiveawayPhoto(params) {
   return dispatch => {
      loader(dispatch, true)
      photoGiveawayPhotoServices.addPhotoGiveawayPhoto(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: photoGiveawayPhotoConstants.ADD_NEW_PHOTO_GIVEAWAY_PHOTO_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.photogiveaway_photo_was_created'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function updatePhotoGiveawayPhoto(params) {
   return dispatch => {
      loader(dispatch, true)
      photoGiveawayPhotoServices.updatePhotoGiveawayPhoto(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: photoGiveawayPhotoConstants.EDIT_PHOTO_GIVEAWAY_PHOTO_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.photogiveaway_photo_was_updated'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function deletePhotoGiveawayPhoto(params) {
   return dispatch => {
      loader(dispatch, true)
      photoGiveawayPhotoServices.deletePhotoGiveawayPhoto(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch({ type: photoGiveawayPhotoConstants.DELETE_PHOTO_GIVEAWAY_PHOTO_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.photogiveaway_photo_was_deleted'));
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function getPhotoGiveawayPhotoById(params) {
   return dispatch => {
      loader(dispatch, true)
      photoGiveawayPhotoServices.getPhotoGiveawayPhotoById(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch(success(tmp));
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { 
      console.log('tmp :',tmp);
      return { type: photoGiveawayPhotoConstants.OPEN_EDIT_PHOTO_GIVEAWAY_PHOTO, data : tmp.data , photoGiveawayPhotoImagePreview: tmp.data.filePath } 
   }
}


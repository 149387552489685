import { campaign_v2Constants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";
import _ from 'lodash'

const initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {
        startDate: "",
        endDate: "",
        status: "Active",
        redeemFormat: "code",
        type: "point",
        redeemAble: {
            sameAsVisibleDate: true
        },
        campaignImage: undefined,
        rewardPicture: undefined,
        categoryId: [],
        rewardDetail: {
            discountType: 'amount'
        }
    },
    tableReset: false,
    tableReload: false,
    campaignImagePreview: defaultImage,
    rewardImagePreview: defaultImage,
    campaignData: [],
    tableDatas: {
        campaigns: [],
        total: 0
    },
    tableKey: 0,
    trdPartyCodeSet: [],
    trueMoneyRewardSet:[],
    reqParams:"",
    categoryList:[]
};

export function campaign_v2(state = initialState, action) {
    switch (action.type) {
        case campaign_v2Constants.CLEAR_PAGE_STATE:
            return initialState;

        case campaign_v2Constants.OPEN_ADD_CAMPAIGN:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: initialState.formInitialValue,
                campaignImagePreview: defaultImage,
                rewardImagePreview: defaultImage,
            };
        case campaign_v2Constants.OPEN_EDIT_CAMPAIGN:
            const campaignCh = _.get(action, 'data.channel')
            const data = {
                _id: action.data["_id"],
                redeemAble: action.data["redeemAble"],
                endDate: action.data["endDate"],
                normal: (_.indexOf(campaignCh, 'normal') !== -1),
                special: (_.indexOf(campaignCh, 'special') !== -1),
                pos: (_.indexOf(campaignCh, 'pos') !== -1),
                visibility_birthday: action.data["visibility_birthday"],
                afterExpiredDate: action.data["afterExpiredDate"],
                tagList: action.data["tagList"],
                pointBurn: action.data["pointBurn"],
                memberLevel: action.data["memberLevel"],
                customCaption: action.data["customCaption"],
                sendSMSAfterRedeem: action.data["sendSMSAfterRedeem"],
                startDate: action.data["startDate"],
                status: action.data["status"],
                redeemFormat: action.data["redeemFormat"],
                type: action.data["type"],
                name: action.data["name"],
                rewardType: action.data["rewardType"],
                rewardDetail: action.data["rewardDetail"],
                pointCondition : action.data["pointCondition"],
                filePath: action.data["filePath"],
                SMSMessage: action.data["SMSMessage"],
                conditionType: action.data["conditionType"],
                campaignId: action.data["campaignId"],
                displayId: action.data["displayId"],
                giftReceiveCondition: action.data["giftReceiveCondition"],
                trdPartyCodeSetId: action.data["trdPartyCodeSetId"],
                birthdayDisplayType: action.data["birthdayDisplayType"],
                languages: action.data["languages"],
                rewardFilePath: action.data["rewardFilePath"],
                quota: action.data["quota"],
                quotaTypeCampaign: action.data["quotaTypeCampaign"],
                quotaTypeUser: action.data["quotaTypeUser"],
                tmnRewardId: action.data["tmnRewardId"],
                tmnReward: action.data["tmnReward"],
                priority : action.data['priority'],
                categoryId: action.data["categoryId"],
                skuId: action.data["skuId"],
                quantity: action.data["quantity"],
                totalPrice: action.data["totalPrice"],
                luckyDrawRewardId: action.data["luckyDrawRewardId"],
                photoGiveawayRuleId: action.data["photoGiveawayRuleId"],
            }
   
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue:{
                    ...data,
                    rewardDetail:{
                        ...data.rewardDetail,
                        descriptionGift: data.rewardType === 'gift' ? data.rewardDetail.description : '',
                        descriptionDiscount: data.rewardType === 'discount' ? data.rewardDetail.description : '',
                        discountType : data.rewardDetail.privilegeType === 'sku' ? 'amount' : data.rewardDetail.discountType,
                        couponCodeDiscountAmount : data.rewardDetail.privilegeType === 'sku' ? null : data.rewardDetail.couponCodeDiscountAmount,
                        couponCodeDiscountPercentage : data.rewardDetail.privilegeType === 'sku' ? null : data.rewardDetail.couponCodeDiscountPercentage,
                        skuId : data.rewardDetail.privilegeType === 'discount' ? null : data.rewardDetail.skuId,
                        quantity : data.rewardDetail.privilegeType === 'discount' ? null : data.rewardDetail.quantity,
                        totalPrice : data.rewardDetail.privilegeType === 'discount' ? null : data.rewardDetail.totalPrice,
                    }
                },
                trdPartyCodeSet: (!_.isEmpty(action.data["thirdPartyCode"]) ? action.data["thirdPartyCode"] : {}),
                campaignImagePreview: action.campaignImagePreview,
                rewardImagePreview: action.rewardImagePreview,
            };
        case campaign_v2Constants.CLOSE_FORM_CAMPAIGN:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                    campaignImagePreview: "",
                    rewardImagePreview: "",
                    description: ""
                },
                campaignImagePreview: defaultImage,
                rewardImagePreview: defaultImage,
            }
        case campaign_v2Constants.ADD_NEW_CAMPAIGN_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case campaign_v2Constants.EDIT_CAMPAIGN_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case campaign_v2Constants.TABLE_RELOAD_SUCCESS:
        case campaign_v2Constants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false
            }
        case campaign_v2Constants.DELETE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case campaign_v2Constants.SET_FORM_PREVIEW_IMAGE_CAMPAIGN:
            return {
                ...state,
                campaignImagePreview: action.campaignImagePreview === "default" ? defaultImage : action.campaignImagePreview
            }
        case campaign_v2Constants.SET_FORM_PREVIEW_IMAGE_REWARD:
            return {
                ...state,
                rewardImagePreview: action.rewardImagePreview === "default" ? defaultImage : action.rewardImagePreview
            }
        case campaign_v2Constants.GET_CAMPAIGN_DATA_SUCCESS:
            return {
                ...state,
                campaignData: action.data
            }
        case campaign_v2Constants.TABLE_GET_LIST_CAMPAIGNS:
            return {
                ...state,
                tableDatas: action.tmp,
                tableReload: false,
                tableReset: false
            }
        case campaign_v2Constants.GET_TRD_PARTY_CODE_SET_SUCCESS:
            return {
                ...state,
                trdPartyCodeSet: action.data
            }
        case campaign_v2Constants.GET_TRUE_MONEY_REWARD_SET_SUCCESS:
            return {
                ...state,
                trueMoneyRewardSet: action.data
            }
        case campaign_v2Constants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS:
            return {
                ...state,
                luckyDrawRewardSet: action.data.rewards
            }
        case campaign_v2Constants.GET_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categoryList: action.data
            }
        default:
            return state
    }
}
export const earnactivitiesConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_EARN_ACTIVITY_STATE',
    OPEN_ADD_NEW_ACTIVITIES: 'OPEN_ADD_NEW_ACTIVITIES',
    OPEN_EDIT_EARN_ACTIVITIES: 'OPEN_EDIT_EARN_ACTIVITIES',
    CLOSE_FORM_EARN_ACTIVITIES: 'CLOSE_FORM_EARN_ACTIVITIES',
    ADD_EARN_ACTIVITIES_SUCCESS: 'ADD_EARN_ACTIVITIES_SUCCESS',
    EDIT_EARN_ACTIVITIES_SUCCESS: 'EDIT_EARN_ACTIVITIES_SUCCESS',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    GET_EARN_ACTIVITIES_DATA_SUCCESS: 'GET_EARN_ACTIVITIES_DATA_SUCCESS',
    EARNACTIVITIES_FORM_PRODUCT_LIST_LOADER: 'EARNACTIVITIES_FORM_PRODUCT_LIST_LOADER',
    GET_PRODUCT_LIST_SUCCESS: 'GET_PRODUCT_LIST_SUCCESS',
    RULE_TYPECODE_SELECTED_PRODUCT_ITEM: 'RULE_TYPECODE_SELECTED_PRODUCT_ITEM',
    RULE_TYPECODE_SELECTED_MANY_PRODUCT_ITEM: 'RULE_TYPECODE_SELECTED_MANY_PRODUCT_ITEM',
    RULE_TYPECODE_DESELECTED_PRODUCT_ITEM: 'RULE_TYPECODE_DESELECTED_PRODUCT_ITEM',
    ON_EARNACTIVITY_TYPE_CHANGE_TO_CODE: 'ON_EARNACTIVITY_TYPE_CHANGE_TO_CODE',

    DELETE_EARN_ACTIVITIES_SUCCESS: 'DELETE_EARN_ACTIVITIES_SUCCESS',

    SHOW_TABLE_LOADER: 'SHOW_EARNACTIVITY_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_EARNACTIVITY_TABLE_LOADER'
}
import { trueMoneyReportServices } from "../services"
import { loader, alert as customAlert } from "../helpers"
import lodash from "lodash"
import i18n from "i18n"
import qs from "querystring"
import moment from "moment"
import { tmnReportConstants } from "../constants"

const defaultFailedText = "api_messages.SOMETHING_WENT_WRONG"

export const trueMoneyReportActions = {
  getTrueMoneyTransactionReport,
  getTrueMoneyCampaignList,
  getTrueMoneySummaryBudget,
  getTrueMoneySummaryRewardList,
}

function getTrueMoneyTransactionReport(params, tableReset) {
  if (params.startDate) {
    params.startDate = moment(params.startDate).format()
  }

  if (params.endDate) {
    params.endDate = moment(params.endDate).format()
  }

  return (dispatch) => {
    loader(dispatch, true)
    trueMoneyReportServices
      .getTrueMoneyTransactionReport(qs.stringify(params))
      .then(
        (response) => {
          if (response.status) {
            if (lodash.has(response, "data.data")) {
              dispatch({
                type: tmnReportConstants.GET_TRUEMONEY_TRANSACTION_SUCCESS,
                data: response.data.data,
                tableFilter: params,
                total: response.data.total,
              })

              if (tableReset) {
                dispatch({
                  type: tmnReportConstants.TRUEMONEY_TRANSACTION_TABLE_RESET,
                })
              }
            }
          } else {
            if (!response.unAlert)
              customAlert(
                dispatch,
                "error",
                i18n.t(`api_messages.${response.message.toString()}`)
              )
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false)
      })
  }
}

function getTrueMoneyCampaignList(params) {
  return (dispatch) => {
    loader(dispatch, true)
    trueMoneyReportServices
      .getTrueMoneyCampaignList(qs.stringify(params))
      .then(
        (response) => {
          if (response.status) {
            if (lodash.has(response, "data.campaigns")) {
              dispatch({
                type: tmnReportConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS,
                data: response.data.campaigns,
              })
            }
          } else {
            if (!response.unAlert)
              customAlert(
                dispatch,
                "error",
                i18n.t(`api_messages.${response.message.toString()}`)
              )
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false)
      })
  }
}


function getTrueMoneySummaryBudget(params) {
  
  if (params.startDate) {
    params.startDate = moment(params.startDate).format()
  }

  if (params.endDate) {
    params.endDate = moment(params.endDate).format()
  }

  let newParam = {
    startDate:params.startDate,
    endDate:params.endDate,
}

  return (dispatch) => {
    loader(dispatch, true)
    trueMoneyReportServices
      .getTrueMoneySummaryBudget(qs.stringify(newParam))
      .then(
        (response) => {
          if (response.status) {
            if (lodash.has(response, "data.total")) {
              dispatch({
                type: tmnReportConstants.GET_TRUEMONEY_SUMMARY_BUDGET_SUCCESS,
                data: response.data.total,
                tableFilter: params,
              })
            }
          } else {
            if (!response.unAlert)
              customAlert(
                dispatch,
                "error",
                i18n.t(`api_messages.${response.message.toString()}`)
              )
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false)
      })
  }
}

function getTrueMoneySummaryRewardList(params, tableReset) {
  if (params.startDate) {
    params.startDate = moment(params.startDate).format()
  }

  if (params.endDate) {
    params.endDate = moment(params.endDate).format()
  }

  return (dispatch) => {
    loader(dispatch, true)
    trueMoneyReportServices
      .getTrueMoneySummaryRewardList(qs.stringify(params))
      .then(
        (response) => {
          if (response.status) {
            if (lodash.has(response, "data.data")) {
              dispatch({
                type: tmnReportConstants.GET_TRUEMONEY_SUMMARY_REWARD_LIST_SUCCESS,
                data: response.data.data,
                tableFilter: params,
                total: response.data.total,
              })

              if (tableReset) {
                dispatch({
                  type: tmnReportConstants.TRUEMONEY_SUMMARY_REWARD_TABLE_RESET,
                })
              }
            }
          } else {
            if (!response.unAlert)
              customAlert(
                dispatch,
                "error",
                i18n.t(`api_messages.${response.message.toString()}`)
              )
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false)
      })
  }
}
import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableDataFilteringResultList from "views/DataFilteringResult/TableDataFilteringResultList"

import { dataFilteringResultConstants } from '../../constants';

const pageStyle = {}

class DataFilteringResultContainer extends React.Component {

    componentWillMount() {
        const { dispatch } = this.props
        dispatch({ type: dataFilteringResultConstants.CLEAR_PAGE_STATE });
    }

    render() {
        return (
            <div>
                <Card>
                    <CardBody>
                        <CustomSweetAlert />
                        <GridContainer>
                            <GridItem xs={12}>
                                <TableDataFilteringResultList />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

// function mapStateToProps(state) {
//     const { formOpen } = state.dataFilteringResult;
//     return {
//         formOpen
//     };
// }

// export default connect(mapStateToProps)(withStyles(pageStyle)(DataFilteringResultContainer));
export default connect()(withStyles(pageStyle)(DataFilteringResultContainer));
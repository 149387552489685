export const periodConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_PAGE_STATE',

    OPEN_ADD: 'OPEN_ADD_PERIOD',
    OPEN_EDIT: 'OPEN_EDIT_PERIOD',
    CLOSE_FORM: 'CLOSE_FORM_PERIOD',
    GET_PERIOD_SUCCESS: 'GET_PERIOD_SUCCESS',

    ADD_PERIOD_SUCCESS: 'ADD_PERIOD_SUCCESS',
    // ADD_NEW_TAG_FAILURE: 'ADD_NEW_TAG_FAILURE',

    EDIT_PERIOD_SUCCESS: 'EDIT_PERIOD_SUCCESS',
    //EDIT_PERIOD_FAILURE: 'EDIT_PERIOD_FAILURE',

    SHOW_LOADER: 'SHOW_TABLE_TAG_LOADER_SPINNER',
    HIDE_LOADER: 'HIDE_TABLE_TAG_LOADER_SPINNER',

    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    DELETE_PERIOD_SUCCESS: 'DELETE_PERIOD_SUCCESS',
    // DELETE_TAG_FAILURE: 'DELETE_TAG_FAILURE',

    OPEN_FORM_UPLOAD_PERIOD: 'OPEN_FORM_UPLOAD_PERIOD',
    CLOSE_FORM_UPLOAD_PERIOD: 'CLOSE_FORM_UPLOAD_PERIOD',
    SHOW_UPLOAD_PERIOD_RESULT: 'SHOW_UPLOAD_PERIOD_RESULT',
    HIDE_UPLOAD_PERIOD_RESULT: 'HIDE_UPLOAD_PERIOD_RESULT',

    OPEN_SAVE_UPLOAD_PERIOD_RESULT: 'OPEN_SAVE_UPLOAD_PERIOD_RESULT',
    CLOSE_SAVE_UPLOAD_PERIOD_RESULT: 'CLOSE_SAVE_UPLOAD_PERIOD_RESULT',

    GET_DROPDOWN_REWARD_LIST_SUCCESS: 'GET_DROPDOWN_REWARD_LIST_SUCCESS',
    TABLE_RESET: 'TABLE_RESET',
};

//
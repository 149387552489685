import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import lodash from 'lodash'
import { pointAdjustmentActions } from 'actions';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.jsx";

class ApprovalRejectPopup extends React.Component {

    closeDialog() {
        const { dispatch, adjustmentDetail } = this.props;
        if(lodash.get(adjustmentDetail, 'approvalPopupOpen')){
            dispatch({ type: 'CLOSE_APPROVAL_POPUP'});
        }else{
            dispatch({ type: 'CLOSE_REJECTION_POPUP'});
        }
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas;
        if(rowIndex.length === 0 ){
            rowIndex = 0;   
        }
        return data[rowIndex];
    }

    approvalAdjustment(){
        const { dispatch, adjustmentDetail } = this.props;
        dispatch(pointAdjustmentActions.approvePointAdjustment(adjustmentDetail, lodash.get(adjustmentDetail, 'phoneNumber')))
    }

    closeApprovalSuccesPopup(){
        const { dispatch } = this.props;
        dispatch({ type: "CLOSE_APPROVAL_SUCCESS_POPUP"});
    }

    rejectAdjustment(){
        const { dispatch, adjustmentDetail } = this.props;
        dispatch(pointAdjustmentActions.rejectPointAdjustment(adjustmentDetail))
        dispatch({ type: 'CLOSE_REJECTION_POPUP'});
    }

    render() {
        const { classes, adjustmentDetail } = this.props;
        let headerText = lodash.get(adjustmentDetail, 'actionAdjust') === 'add' ? 'Add': 'Deduct'
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open= {lodash.get(adjustmentDetail, 'approvalPopupOpen') || lodash.get(adjustmentDetail, 'rejectionPopupOpen') ? true : false}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                maxWidth='xs'
                scroll="body"
                disableRestoreFocus={true}
            >
            
                <DialogContent
                    id="adjustment-point-dialog"
                    style={{ textAlign: 'center' }}
                >
                { !lodash.get(adjustmentDetail, 'approvalConfirmationPopupOpen') ? <>
                    <h4 className={classes.modalTitle} style={{ marginBottom: '20px'}} >
                        <b>{headerText} Point Comfirmation</b>
                    </h4>
                    Do you want to {lodash.get(adjustmentDetail, 'actionAdjust')} {lodash.get(adjustmentDetail, 'pointAdjust')}{lodash.get(adjustmentDetail, 'pointAdjust') > 1 ? <> points</>: <> point</>} {lodash.get(adjustmentDetail, 'actionAdjust') === 'add' ? <>to</>: <>from</>} 
                    <br/>
                    {lodash.get(adjustmentDetail, 'memberName')} ({lodash.get(adjustmentDetail, 'phoneNumber')})
                    <br/>
                    and the remark is 
                    <br/>
                    "{lodash.get(adjustmentDetail, 'remark')}"
                    <br/>
                    <Button
                        id="cancel-btn"
                        onClick={() => {
                            this.closeDialog()
                        }}
                        size="sm"
                        color="white"
                        style={{ marginTop: '25px', marginRight: '20px'}}>
                        Cancel
                    </Button>
                    <Button
                        id='approve-btn'
                        variant='contained'
                        bcolor='primary'
                        size='sm'
                        onClick={() => {
                            lodash.get(adjustmentDetail, 'approvalPopupOpen') ? this.approvalAdjustment() : this.rejectAdjustment()
                        }}
                        style={{ backgroundColor: lodash.get(adjustmentDetail, 'approvalPopupOpen') ? "#2196F3": "#f44336", marginTop: '25px' }}>
                        {lodash.get(adjustmentDetail, 'btnName')}
                    </Button>
                </>:<>
                    <h4 className={classes.modalTitle} style={{ marginBottom: '20px'}} >
                        <b>Approved Success</b>
                    </h4>
                    Current point of this member
                    <br/>
                    {lodash.get(adjustmentDetail, 'memberName')} ({lodash.get(adjustmentDetail, 'phoneNumber')})
                    <br/>
                    <br/>
                    Current point is {lodash.get(adjustmentDetail, 'currentPoint')} {lodash.get(adjustmentDetail, 'currentPoint') > 1 ? <> points</>: <> point</>}
                    <br/>
                    <Button
                        id="ok-btn"
                        variant='contained'
                        bcolor='primary'
                        size='sm'
                        onClick={() => {
                            this.closeApprovalSuccesPopup()
                        }}
                        style={{ marginTop: '25px', backgroundColor: '#2196F3'}}>
                        OK
                    </Button>
                </>}
                </DialogContent>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { adjustmentDetail } = state.point_adjustment;
    return {
        adjustmentDetail
    };
}
const connectedComponent = connect(mapStateToProps)(ApprovalRejectPopup);

export default withStyles(sweetAlertStyle)(connectedComponent);



import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import moment from 'moment'
import lodash from 'lodash'

export const winnerConfigServices = {
    getWinnerConfigSetting,
    saveWinnerConfigSetting,
    editWinnerConfigSetting
};

function getWinnerConfigSetting() {
    var tmp = axios.get(configConstants.API_LUCKYDRAW + `/winner-config`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            // console.log('getWinnerConfigSetting response', response)
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function saveWinnerConfigSetting(params) {
    let specifyExpirationDate = lodash.get(params, 'specifyExpirationDate')

    let reqParams = new Object({ ...params })
    if (specifyExpirationDate) {
        reqParams.specifyExpirationDate = moment(specifyExpirationDate).format('DD-MM-YYYY')
    }

    var tmp = axios.post(configConstants.API_LUCKYDRAW + `/winner-config`, reqParams, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function editWinnerConfigSetting(params) {
    let specifyExpirationDate = lodash.get(params, 'specifyExpirationDate')

    let reqParams = new Object({ ...params })
    if (specifyExpirationDate) {
        reqParams.specifyExpirationDate = moment(specifyExpirationDate).format('DD-MM-YYYY')
    }
    console.log('reqParams', reqParams);

    var tmp = axios.put(configConstants.API_LUCKYDRAW + `/winner-config/` + reqParams.winnerConfigId, reqParams, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}
import React from 'react'
import { connect } from 'react-redux';
import lodash from 'lodash';
import _ from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { Field, reduxForm, change, touch, reset } from "redux-form"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import ConfigFieldMultiSelect from "components/CustomSelect/ConfigFieldMultiSelect.jsx"
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from 'components/CustomButtons/Button'

import { dfConfigActions } from 'actions';
import i18n from 'i18n';

const FORM_NAME = "datafilteringList"
const style = {
    customCardContentClass: {
       paddingLeft: "0",
       paddingRight: "0"
    },
    cardIconTitle: {
       ...cardTitle,
       marginTop: "15px",
       marginBottom: "0px"
    },
    actionDiv: {
       display: "inline-flex"
    },
    btnClearFilter: {
        marginTop: "20px",
        width: "100%",
    },
};

const moduleName = 'datafilteringList';

const pageStyle = { ...style, ...sweetAlertStyle };

// const THEME = createMuiTheme(MTablestyles);


class DataFilteringList extends React.Component {
    constructor(props) {
        super(props);
  
        this.state = {
           readOnly: false,
           isLoading: false,
           page: 0,
           rowsPerPage: 10,
           count: 100,
           data: [],
           reqParams: "",
           tableTemplateList:[],
           tbNameSelected:[],
           dataloop : [],
           mode: 'edit'
        };
    }

    async componentDidMount(){
        
        await this.callapi();
        // if(formMode != 'edit'){
        
        // }
        
    }
    
    async callapi(){
        const {formMode, dispatch,fieldsList} = this.props;
           
         dispatch( await dfConfigActions.getTableConfig());  // /table-config/list
         if(fieldsList.length === 0 ){
             this.setState({
                mode: 'add'
             })
            dispatch( await dfConfigActions.getTableTemplateList());  // /list/table-template
         }
    }
  
       
    handleTableTemplateSubmit(values) {
        const { reqParams} = this.state
        const { dispatch } = this.props
        const { dbtabletemplate } = values
        let keywords = []
        
        if (this.state.mode === "add") {
            keywords = { "keyword" : dbtabletemplate}
            dispatch(dfConfigActions.searchTableTemplateList(keywords));
        }else{
            if(dbtabletemplate != "All"){
                keywords = { "keyword" : dbtabletemplate}
            }
            dispatch(dfConfigActions.getTableConfig(keywords));
        }
    }

    handleTableTemplateSubmitNew(value){
        const { dispatch } = this.props
        let keywords = []
        if (this.state.mode === "add") {
            keywords = { "keyword" : value}
            dispatch(dfConfigActions.searchTableTemplateList(keywords));
        }else{
            if(value != "All"){
                keywords = { "keyword" : value}
            }
            dispatch(dfConfigActions.getTableConfig(keywords));
        }
    }
   
    handleSubmit = (values) => {
        const { dispatch,tableTemplateList,tableConfigId } = this.props;
        let tables = [];
        let tablesField = [];
    
        lodash.forEach(values.tableName, function (dbvalue, dbkey) {
            lodash.forEach(dbvalue.fields, function (fieldVal, fieldkey) {
                let status = 'hide'
                if(fieldVal.status === true){
                    status = 'show'
                }
                
                tablesField.push({
                    fieldName : fieldkey,
                    displayName : fieldVal.fieldName,
                    status : status,
                    type : fieldVal.type
                })
            })
            
            tables.push({
                tableName: dbkey,
                fields : tablesField
            })    
            tablesField = []   
        })
        alert(this.state.mode)
        if (this.state.mode === "add") {
            dispatch(dfConfigActions.addTableConfig(tables))
        }else{
            if (tableConfigId) {
                dispatch(dfConfigActions.editTableConfig(tables,tableConfigId))
            }
       
        }
    }

    handleToggle = async (newValue) => {
        const { dispatch, fieldsList } = this.props;
        dispatch(change(FORM_NAME, 'selectAll_status',newValue))
        lodash.forEach(fieldsList, function (value, mkey) {
            lodash.forEach(value.value, function(fieldval,fieldkey){
                dispatch(change(FORM_NAME, `tableName[${value.name}]fields[${fieldval.fieldName}][status]`, newValue))
                // dispatch(change(FORM_NAME, `tableName[${value.name}]fields[${fieldval.fieldName}][status]`, false))
            })
        })
    }

    clearFilter = async () => {
        const {formMode, dispatch,fieldsList} = this.props;
        // const { dispatch } = this.props;
        // dispatch(change(FORM_NAME, "gender", ""));
         await this.callapi();
         this.setState({
            readOnly: false,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: "",
            tableTemplateList:[],
            tbNameSelected:[],
            dataloop : [],
            mode: 'edit'
         });
    }


    render() {
        const { classes, dispatch,handleSubmit, tableTemplateList, fieldsList, formMode } = this.props;
        var fieldsArray = [];
        
        lodash.forEach(fieldsList, function (value, mkey) {
            fieldsArray.push(
                <div key={mkey}>
                <h1>{value.name}</h1>
                    <GridContainer>
                        <GridItem xs={6} sm={4} md={4}>
                            <h6><b>{i18n.t("datafiltering.table_template")}</b></h6>
                        </GridItem>
                        <GridItem xs={6} sm={4} md={4}>
                            <h6><b>{i18n.t("datafiltering.display_name")}</b></h6>
                        </GridItem>
                        <GridItem xs={6} sm={4} md={4}>
                            <h6><b>{i18n.t("datafiltering.show")}</b></h6>
                        </GridItem>
                    </GridContainer>
                    {value.value.map((val,key) =>
                        <GridContainer key={`${val.fieldName}${key}`}>
                            <GridItem xs={6} sm={4} md={4}>
                                <div
                                    style={{ paddingTop: "20px"  }}
                                >
                                    <span>{val.fieldName}</span>
                                </div>
                            </GridItem>
                            <GridItem xs={6} sm={4} md={4}>
                                <Field
                                    name={`tableName[${value.name}]fields[${val.fieldName}][type]`}
                                    component="input"
                                    type="hidden"
                                />
                                
                                <Field
                                    name={`tableName[${value.name}]fields[${val.fieldName}][fieldName]`}
                                    component={CustomInput}
                                    type="text"
                                    // labelText={val.fieldName}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    // disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={4} md={4}>
                                <div
                                style={{ paddingTop: "20px"  }}
                                >
                                    <Field
                                        style={{ }}
                                        name={`tableName[${value.name}]fields[${val.fieldName}][status]`}
                                        component={CustomCheckbox}
                                        id={`check_${value.name}_${val.fieldName}`}
                                        // labelText="Normal"
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        
                    )}
                </div>
            )
            lodash.forEach(value.value, function(fieldval,fieldkey){
                // console.log(`tableName[${value.name}]fields[${fieldval.fieldName}][fieldName]`)
                dispatch(change(FORM_NAME, `tableName[${value.name}]fields[${fieldval.fieldName}][type]`, fieldval.type))
                dispatch(change(FORM_NAME, `tableName[${value.name}]fields[${fieldval.fieldName}][fieldName]`, fieldval.displayName))
                if (lodash.has(fieldval, 'status')) {
                    if(fieldval.status === 'show'){
                        dispatch(change(FORM_NAME, `tableName[${value.name}]fields[${fieldval.fieldName}][status]`, true))
                    }
                }
            })
        }
        
        )
    
        return (
            <div id="dataFilteringList">
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <legend><b>{i18n.t("datafiltering.table_template")}</b></legend>
                            <form
                                name={`${FORM_NAME}_config`} 
                                onSubmit={handleSubmit(this.handleTableTemplateSubmit.bind(this))}
                            >
                                <GridContainer>
                                    <GridItem xs={6} sm={4} md={4}>
                                        <Field
                                            id={`${FORM_NAME}_dbTemplate`}
                                            name="dbtabletemplate"
                                            component={ConfigFieldMultiSelect}
                                            labelText={i18n.t("datafiltering.table_template")}
                                            formControlProps={{
                                            fullWidth: true,
                                            }}
                                            onChange={(e)=>{
                                                e.preventDefault();
                                                let selectedValue = e.target.value
                                                
                                                if(lodash.last(selectedValue) === 'All' ){
                                                    dispatch(change(FORM_NAME, 'dbtabletemplate', ['All']))
                                                } else {
                                                    if(lodash.indexOf(selectedValue, 'All') >= 0) {
                                                        selectedValue.shift();
                                                        dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                                    } else {
                                                        dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                                    }
                                                }
                                            }}
                                            showtick
                                            optionsList={tableTemplateList}
                                        />
                                    </GridItem>

                                    <GridItem>
                                        <Button xs={6} sm={4} md={4}
                                            id={`${FORM_NAME}-button-submit`}
                                            type="submit"
                                            style={{ marginRight: "10px", marginTop: "25px" }}
                                            size="sm"
                                            color="primary"
                                        >
                                            Search
                                        </Button>
                                    </GridItem>
                                    
                                </GridContainer>
                            </form>
                            
                            <form name={FORM_NAME}
                                onSubmit={handleSubmit(this.handleSubmit)}
                            >
                             
                                <GridContainer >
                                    <GridItem xs={6} sm={4} md={4}>
                                        
                                    </GridItem>
                                    <GridItem xs={6} sm={4} md={4}>
                                        
                                    </GridItem>
                                    <GridItem xs={6} sm={4} md={4}>
                                        <Field
                                            style={{ }}
                                            name={`selectAll_status`}
                                            component={CustomCheckbox}
                                            id={`selectAll_statuss`}
                                            labelText="Select All"
                                            // checked={isCheckDefault}
                                            onChange={(e,newValue) => {
                                                this.handleToggle(newValue)
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                               {fieldsArray}
                                
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4} lg={3}>
                                        {/* <Button
                                        style={{
                                            marginTop: "25px"
                                        }}
                                        className={classes.btnClearFilter} 
                                        color="white" >
                                            Clear
                                        </Button> */}
                                        <Button
                                            onClick={this.clearFilter}
                                            style={{
                                                marginTop: "25px"
                                            }}
                                            color="white"
                                            >
                                            {i18n.t("clear")}
                                        </Button>
                                        &nbsp;  &nbsp;  &nbsp;
                                        <Button
                                        type="submit"
                                        style={{
                                            marginTop: "25px"
                                        }}
                                        color="primary">
                                            Submit
                                        </Button>
                                    </GridItem>

                                </GridContainer>
                            </form>
                        </GridItem>
                    </GridContainer>
                
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {  tableTemplateList,databaseFieldsList, formMode,tableConfigId } = state.tableTemplateList;
    const { permission, superAdmin } = state.authentication;
    // const { tableTemplateList } = state.list
    return {
        permission, superAdmin, tableTemplateList, tableConfigId, fieldsList :databaseFieldsList, formMode
    };
 }
 
 const connectedComponent =   connect(mapStateToProps)(withStyles(pageStyle)(DataFilteringList));
 
 export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: false
  })(withStyles(style)(connectedComponent))
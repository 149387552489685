import React from "react";
import lodash from "lodash";
import moment from "moment";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, reduxForm, change, getFormValues, destroy, reset } from 'redux-form';
import InputUpload from 'components/CustomUpload/InputUpload.jsx';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { uploadWinnerAction } from 'actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { uploadWinnerConstants } from '../../constants';
import TableUploadWinnerList from "views/UploadWinner/TableUploadWinnerList"
import TableUploadWinnerListUpload from "./TableUploadWinnerListUpload";
import UploadWinnerUploadResultForm from "./UploadWinnerUploadResultForm";
import { plugins, loader, crmValidator, alert as customAlert } from 'helpers';
import { CSVLink } from "react-csv";

const style = {
    reportHeaderContainer: {
        margin: "0px",
        width: "calc(100% - 10px)",
        padding: "0px",
    },
    btnClearFilter: {
        marginRight: "10px",
        marginTop: "25px"
    },
    textCenter: {
        textAlign: "center"
    },
    mt20: {
        marginTop: "20px"
    }
};

const pageStyle = { ...style };
const FORM_NAME = "formFilterUploadWinner";
const form_validate = {
    "announceDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "type": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "rewardName": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "redeemDigit": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอกข้อมูล'
        }
    },   
    "file": {
        rules: {
            required: true
        },
        messages: {
            required: 'Please select file'
        }
    }
}


const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class UploadWinnerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef()
        this.state = {
            campaignList: [],
            campaignAllList: [],
            typeAllList: [],
            announceDateAllList: [],
            rewardAllList: [],
            csvHeaders: [],
            csvData: [],
            csvFileName: ""
        };

        this.csvLink = React.createRef()
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props
        var reqParams = {
            "rewardName": values.rewardName,
            "periodId": values.announceDate,
            "winnerType": values.type,
            "redeemDigit": values.redeemDigit,
            "file": values.file
        }
        dispatch(uploadWinnerAction.uploadUploadWinner(reqParams));
    }

    clearFilter = () => {
        window.location.reload(false);
    }

    saveUpload = (values) => {
        const { dispatch } = this.props
        var reqParams = {
            "periodId": values.announceDate,
            "rewardName": values.rewardName,
            "winnerType": values.type,
            "fileName": "import_winner.csv",
            "redeemDigit": values.redeemDigit
        }
        dispatch(uploadWinnerAction.saveUploadUploadWinner(reqParams));
    }

    clearUpload = () => {
        const { dispatch } = this.props;
        dispatch({ type: uploadWinnerConstants.CLOSE_SAVE_UPLOAD_UPLOAD_WINNER_RESULT });
        window.location.reload(false);
    }

    componentDidMount() {
        this.getAnnounceDateList()
        this.getRewardList()
    }

    getAnnounceDateList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"
        dispatch(uploadWinnerAction.getAnnounceDateList(reqParams))
    }

    getRewardList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"
        dispatch(uploadWinnerAction.getRewardList(reqParams))
    }

    genTemplateUploadWinner(fileName) {
        const headers = [{
            label: 'order',
            key: 'order'
        }, {
            label: 'mobile_no',
            key: 'mobile_no'
        }, {
            label: 'code',
            key: 'code'
        }, {
            label: 'display',
            key: 'display'
        }];

        let data = []

        this.setState({ csvHeaders: headers, csvData: data, csvFileName: fileName }, () => {
            this.csvLink.current.link.click()
        })
    }

    downloadTemplate() {
        let fileName = `Template_Upload_Winner.csv`
        this.genTemplateUploadWinner(fileName)
    }

    render() {
        const { classes, formFilterUploadWinner, handleSubmit, uploadWinnerContainer, formOpen, showImportResult, formInitialValue, importResult } = this.props;

        let typeAllList = [];
        typeAllList.push(
            {
                value: "all",
                label: "All"
            },
            {
                value: "Winner",
                label: "Winner"
            },
            {
                value: "Reserve",
                label: "Reserve"
            },
        )

        let announceDateAllList = [];
        announceDateAllList.push({
            value: "all",
            label: "All"
        })

        if (lodash.has(uploadWinnerContainer, 'announceDateList')) {
            lodash.forEach(uploadWinnerContainer.announceDateList, function (value, key) {
                announceDateAllList.push({
                    value: value.periodId,
                    label: plugins.datetimeFormat(value.announceDate, "fulldatetime") + ' / ' + value.rewardLuckyDraw.name_en,
                    id: value.periodId,
                })
            })
        }

        let rewardAllList = [];
        rewardAllList.push({
            value: "all",
            label: "All"
        })

        if (lodash.has(uploadWinnerContainer, 'rewardList')) {
            lodash.forEach(uploadWinnerContainer.rewardList, function (value, key) {
                rewardAllList.push({
                    value: value.name_en,
                    label: value.name_en,
                    id: value.rewardId,
                })
            })
        }

        const displayCard = (!showImportResult && !formOpen) ? {} : { display: "none" }
        const displayCardUpload = showImportResult ? {} : { display: "none" }
        const disableSaveUpload = importResult.notPass > 0 ?  true  :  false ;
        return (
            <div>
                <UploadWinnerUploadResultForm initialValues={formInitialValue} />
                <Card>
                    <CardBody>
                        <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>UPLOAD WINNER LIST</h4>
                        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                            <GridContainer>
                                <GridItem xs={3} sm={3} md={3}>
                                    <Field
                                        name="announceDate"
                                        component={CustomSelect}
                                        type="text"
                                        labelText="Announce Date"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        optionsList={announceDateAllList}
                                    />
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3}>
                                    <Field
                                        name="rewardName"
                                        component={CustomSelect}
                                        type="text"
                                        labelText="Reward Name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        optionsList={rewardAllList}
                                    />
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3}>
                                    <Field
                                        name="type"
                                        component={CustomSelect}
                                        type="text"
                                        labelText="Type"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        optionsList={typeAllList}
                                    />
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3}>
                                    <Field
                                        name="redeemDigit"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Redeem Digit"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer style={displayCard}>
                                <GridItem xs={12} sm={6} md={6}>
                                    <br></br>
                                    <div style={{ marginTop: '5px' }}>
                                        <Field
                                            name="file"
                                            component={InputUpload}
                                            type="file"
                                            label="File Import"
                                            accept=".csv"
                                            id="btn_select_import_file"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </div>
                                    <div style={{ fontSize: '12px', marginTop: "10px" }}>Maximum 500 rows</div>
                                </GridItem>
                            </GridContainer>
                            <GridContainer style={displayCard}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Button
                                        onClick={this.clearFilter}
                                        style={{
                                            marginTop: "25px"
                                        }}
                                        className={classes.btnClearFilter}
                                        color="white" >
                                        Clear
                                    </Button>

                                    <Button
                                        type="button"
                                        color="success"
                                        style={{
                                            marginTop: "25px"
                                        }}
                                        className={classes.btnClearFilter}
                                        onClick={this.downloadTemplate.bind(this)}>
                                        Download Template
                                    </Button>

                                    <CSVLink
                                        headers={this.state.csvHeaders}
                                        data={this.state.csvData}
                                        filename={this.state.csvFileName}
                                        style={{ display: "none" }}
                                        ref={this.csvLink}
                                        id="btn_download_template"
                                        target="_blank"
                                    />

                                    <Button
                                        type="submit"
                                        style={{
                                            marginTop: "25px"
                                        }}
                                        className={classes.btnClearFilter}
                                        color="primary">
                                        Submit
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </CardBody>
                    
                    <CardBody style={displayCardUpload}>
                        <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}> Total Update: {importResult.total}</h4>
                        <h6 style={{ fontWeight: '500', fontSize: '0.75rem', color: 'black' }}> Pass: {importResult.pass}</h6>
                        <h6 style={{ fontWeight: '500', fontSize: '0.75rem', color: 'red' }}> Not Pass: {importResult.notPass}</h6>
                        <GridContainer>
                            <GridItem xs={12}>
                                <TableUploadWinnerListUpload showAction={false} showSearch={false} />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={3}>
                                <Button
                                    onClick={this.clearUpload}
                                    style={{
                                        marginTop: "25px"
                                    }}
                                    className={classes.btnClearFilter}
                                    color="white" >
                                    Clear
                                </Button>
                                &nbsp;  &nbsp;  &nbsp;
                                <Button
                                    onClick={handleSubmit(this.saveUpload.bind(this))}
                                    disabled={disableSaveUpload}
                                    style={{
                                        marginTop: "25px"
                                    }}
                                    color="primary">
                                    Submit
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { sweetAlert } = state.sweetAlert;
    const { formFilterUploadWinner } = state.form;
    const { formOpen, uploadWinnerContainer, showImportResult, importResult, formInitialValue } = state.uploadWinner;
    return {
        sweetAlert, formFilterUploadWinner, formOpen, uploadWinnerContainer, showImportResult, importResult, formInitialValue
    };
}

//export default connect(mapStateToProps)(withStyles(pageStyle)(WhitelistContainer));

const connectUploadWinnerContainer = connect(mapStateToProps)(UploadWinnerContainer);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onChange: (values, dispatch, props, previousValues) => {
        if (values !== previousValues) {
            dispatch({ type: uploadWinnerConstants.TABLE_RESET });
        }
    }
})(withStyles(pageStyle)(connectUploadWinnerContainer));

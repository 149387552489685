import { reset } from 'redux-form'
import lodash from 'lodash';

import { shipmentConstants, couponUsedReportConstants, winnerConstants } from '../constants';
import { shipmentServices, couponUsedReportServices, winnerServices } from '../services';
import { loader, alert as customAlert } from 'helpers';

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

// const getErrorMessage = (key) => {
//    switch (key) {
//       default: return "กรุณาลองใหม่อีกครั้ง"
//    }
// }

export const winnerActions = {
   // editShipment,
   getTableDatas,
   // getaddresslabelDetail,
   clearWinnerFilter,
   getCampaignList,
   getReferenceList,
   getAnnounceDateList,
   getRewardList
};

function clearWinnerFilter() {
   return dispatch => {
      let tableFilter = {
         page: 0,
         skip: 0,
         limit: 10,
         sortBy: "createdAt",
         sortDir: "desc",
         keyword: "",
         search: {}
      };
      dispatch({ type: winnerConstants.SET_FILTER, tableFilter });
      dispatch(reset('formFilterWinner'))
   }
}

function getTableDatas(params, tableReset = false) {
   return dispatch => {
      dispatch({ type: winnerConstants.SHOW_LOADER_WINNER })
      console.log("action params", params);
      winnerServices.getListsWinner(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: winnerConstants.TABLE_GET_LIST_DATAS, data: response.data.data, total: response.data.total, tableFilter: params });
                  if (tableReset) {
                     dispatch({ type: winnerConstants.TABLE_GET_LIST_DATAS_RESET });
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: winnerConstants.HIDE_LOADER_WINNER })
         })
   };

   // function success(tmp) { return { type: shipmentConstants.TABLE_GET_LIST_DATAS, tmp } }
}

// function getaddresslabelDetail(qryParams) {
//     return dispatch => {
//         couponUsedReportServices.getaddresslabelDetail(qryParams)
//     }
// }

function getCampaignList(params) {
   return dispatch => {
      winnerServices.getCampaignsList(params)
         .then(
            response => {
               // console.log('action response', response);
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     dispatch(success(response.data));
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: winnerConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data }
   }
}

// getReferenceList   REFERENCE
function getReferenceList(params) {
   return dispatch => {
      winnerServices.getReferenceList(params)
         .then(
            response => {
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     dispatch(success(response.data));
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: winnerConstants.GET_DROPDOWN_REFERENCE_LIST_SUCCESS, data }
   }
}

function getAnnounceDateList(params) {
   return dispatch => {
      winnerServices.getAnnounceDateList(params)
         .then(
            response => {
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     dispatch(success(response.data));
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: winnerConstants.GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS, data }
   }
}

function getRewardList(params) {
   return dispatch => {
      winnerServices.getRewardList(params)
         .then(
            response => {
               // console.log('response action winner ', response);
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     dispatch(success(response.data));
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: winnerConstants.GET_DROPDOWN_REWARD_LIST_SUCCESS, data }
   }
}
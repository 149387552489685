import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { crmValidator } from "helpers";
import { change } from "redux-form";

// material-ui components
import ListItemIcon from "@material-ui/core/ListItemIcon";
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import RichEditor from "components/RichEditor/RichEditor.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { merchantConstants } from "../../constants";
import { merchantActions } from "actions";
import { orange } from "@material-ui/core/colors";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import SmsIcon from "@material-ui/icons/Sms";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

const FORM_NAME = "FORM_SETTING";

var form_validate = {
  blockTypeRadio: {
    rules: {
      required: true
    },
    messages: {
      required: "This field is required."
    },
    alert: "Please Select a Input "
  },
  wrongTime: {
    rules: {
      required: true
    },
    messages: {
      required: "This field is required."
    },
    alert: "Please Input a Time ! That user can be wrong."
  },
  wrongTimeType: {
    rules: {
      required: true
    },
    messages: {
      required: "This field is required."
    },
    alert: "Please Select Block Within"
  },
  wrongTimeWithin: {
    rules: {
      required: true
    },
    messages: {
      required: "This field is required."
    },
    alert: "Please Input a Within  "
  },
  blockType: {
    rules: {
      required: true
    },
    messages: {
      required: "This field is required."
    },
    alert: "Please Select Block User Until "
  },
  blockTimeUntil: {
    rules: {
      required: true
    },
    messages: {
      required: "This field is required."
    },
    alert: "Please Input a Block User Until"
  },
  specificTime: {
    rules: {
      required: true
    },
    messages: {
      required: "This field is required."
    },
    alert: "Please Input a specific time"
  },
  lineClientId: {
    rules: {
      requiredRelateTo: "lineSecretKey",
      isEngLetter: "lineClientId"
    },
    messages: {
      requiredRelateTo: "Required",
      isEngLetter: "Only Number 0-9 and [ A-Z , a-z ] are permitted"
    }
  },
  lineSecretKey: {
    rules: {
      requiredRelateTo: "lineClientId",
      isEngLetter: "lineSecretKey"
    },
    messages: {
      requiredRelateTo: "Required",
      isEngLetter: "Only Number 0-9 and [ A-Z , a-z ] are permitted"
    }
  },
  smsClientId: {
    rules: {
      requiredRelateTo: "smsSecretKey"
    },
    messages: {
      requiredRelateTo: "Required",
    }
  },
  smsSecretKey: {
    rules: {
      requiredRelateTo: "smsClientId"
    },
    messages: {
      requiredRelateTo: "Required",
    }
  },
  luckyDrawClientId: {
    rules: {
      isEngLetter: "luckyDrawSecretKey",
      requiredRelateTo: "luckyDrawSecretKey"
    },
    messages: {
      requiredRelateTo: "Required",
      isEngLetter: "Only Number 0-9 and [ A-Z , a-z ] are permitted"
    }
  },
  luckyDrawSecretKey: {
    rules: {
      isEngLetter: "luckyDrawSecretKey",
      requiredRelateTo: "luckyDrawClientId"
    },
    messages: {
      requiredRelateTo: "Required",
      isEngLetter: "Only Number 0-9 and [ A-Z , a-z ] are permitted"
    }
  }
};

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0"
  },
  labelHorizontalCenter: {
    float: "right",
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "5px",
    marginRight: "0"
  },
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400
  },
  head: {
    lineHeight: "24px",
    fontSize: "13px",
    fontWeight: "405",
    position: "relative",
    marginLeft: "39px"
  },
  gridLabel: {
    paddingTop: "10px"
  },
  gridLine: {
    paddingTop: "0px"
  }
};

const validate = values => {
  const errors = crmValidator.validateByRulesList(form_validate, values);
  return errors;
};

const validateNullWithin = value => {
  if (value === "null") {
    return "Please Select Block Within";
  }
};

const validateNullBlockUntil = value => {
  if (value === "null") {
    return "Please Select Block Until";
  }
};

class settingForm extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    let blockMode = props.initialValues.checkBlockUser
      ? props.initialValues.checkBlockUser
      : false;
    let activeAuthorisation = props.initialValues.clientId ? true : false;
    let activeLine = props.initialValues.lineClientId ? true : false;
    let activeSms = props.initialValues.smsSecretKey ? true : false;
    let activeLuckyDraw = props.initialValues.luckyDrawClientId ? true : false;
    let activeWinner = props.initialValues.winnerBucket ? true : false;

    this.state = {
      selectWrongTime: blockMode,
      selectBlockTime: blockMode,
      showBlockMng: blockMode,
      wrongTimeValue: props.initialValues.wrongTimeType,
      blockTimeValue: props.initialValues.blockType,
      showAuthorisation: activeAuthorisation,
      showLine: activeLine,
      showSms: activeSms,
      showLuckyDraw: activeLuckyDraw,
      showEarnRule: props.initialValues.checkBlockUser,
      showShipping: props.initialValues.shippingLabel,
      showWinner: activeWinner,
      expanded: false,
      setExpanded: false
    };
    if (!props.initialValues.announcementTime) {
      dispatch(change("FORM_SETTING", "announcementTime", "00:00"));
    }
  }

  handleSubmit = values => {
    const { dispatch } = this.props;
    if (values.wrongTimeType === "midnight") {
      values.wrongTimeWithin = "00:00";
    }

    values.winnerBucketConfig = btoa(encodeURIComponent(values.winnerBucketConfig))

    dispatch(merchantActions.updateMerchant(values));
  };

  handleClose = values => {
    const { dispatch } = this.props;
    dispatch({ type: merchantConstants.CLOSE_FORM });
  };

  handleWrongTime = e => {
    if (e.target.value !== "null") {
      this.setState({
        wrongTimeValue: e.target.value,
        selectWrongTime: true
      });
    } else {
      this.setState({
        selectWrongTime: false
      });
    }
  };

  handleBlockTime = e => {
    if (e.target.value !== "null") {
      this.setState({
        blockTimeValue: e.target.value,
        selectBlockTime: true
      });
    } else {
      this.setState({
        selectBlockTime: false
      });
    }
  };

  handleBlockMng = e => {
    if (e.target.checked) {
      this.setState({
        showBlockMng: true
      });
    } else {
      this.setState({
        showBlockMng: false
      });
    }
  };

  render() {
    const { formOpen, formMode, handleSubmit, classes } = this.props;
    const {
      selectWrongTime,
      selectBlockTime,
      wrongTimeValue,
      blockTimeValue,
      showBlockMng,
      showAuthorisation,
      showLine,
      showSms,
      showLuckyDraw,
      showEarnRule,
      showShipping,
      showWinner,
    } = this.state;
    const displayCard = formOpen ? { padding: "15px" } : { display: "none" };
    let { readOnly } = this.props;

    if (formMode === `add`) {
      readOnly = false;
    } else if (formMode === `edit` && !readOnly) {
      readOnly = false;
    } else {
      readOnly = true;
    }

    return (
      <div style={displayCard}>
        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
          <CardHeader icon>
            <h4
              className={classes.cardIconTitle}
              style={{ marginLeft: "5px", marginBottom: "15px" }}
            >
              <b>Merchant Setting</b>
            </h4>
          </CardHeader>
          <GridContainer style={{ paddingLeft: "40px" }}>
            <GridItem xs={12} sm={12} md={11}>
              <ExpansionPanel
                expanded={showAuthorisation}
                onChange={() => this.setState({ showAuthorisation: !showAuthorisation })}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <ListItemIcon>
                    <PhoneIphoneIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: "430",
                      position: "relative",
                      fontSize: "13px"
                    }}
                  >
                    Client Authorisation
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}> 
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Client Secret :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="clientSecret"
                          component={CustomInput}
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={true}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Client ID :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="clientId"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={true}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                expanded={showLine}
                onChange={() => this.setState({ showLine: !showLine })}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <ListItemIcon>
                    <PhoneIphoneIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: "430",
                      position: "relative",
                      fontSize: "13px"
                    }}
                  >
                    LINE
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}> 
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Secret Key :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="lineSecretKey"
                          component={CustomInput}
                          onChange={this.handleKeyPress}
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Client ID :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="lineClientId"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Channel access token :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="lineChannelAccessToken"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                expanded={showSms}
                onChange={() => this.setState({ showSms: !showSms })}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <ListItemIcon>
                    <SmsIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: "430",
                      position: "relative",
                      fontSize: "13px"
                    }}
                  >
                    SMS
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}> 
                      <GridItem xs={12} sm={12} md={2} style={{ paddingTop: "15px" }} >
                        <FormLabel className={classes.labelHorizontal}>
                          Secret Key :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Field
                          name="smsSecretKey"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Client ID :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="smsClientId"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          SMS Sender Name :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="smsSenderName"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer style={{ marginLeft: "60px" }}>
                      <GridItem xs={12} sm={9} md={5}>
                        <Field
                          style={{ paddingTop: "40px" }}
                          name={"sendSMSAfterRedeem"}
                          component={CustomCheckbox}
                          disabled={readOnly}
                          labelText="Send SMS after Redemption"
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                expanded={showLuckyDraw}
                onChange={() =>
                  this.setState({ showLuckyDraw: !showLuckyDraw })
                }
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <ListItemIcon>
                    <CardGiftcardIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: "430",
                      position: "relative",
                      fontSize: "13px"
                    }}
                  >
                    LUCKY DRAW
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}> 
                      <GridItem xs={12} sm={12} md={2} style={{ paddingTop: "15px" }} >
                        <FormLabel className={classes.labelHorizontal}>
                          Secret Key :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <Field
                          name="luckyDrawSecretKey"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px"  }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Client ID :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="luckyDrawClientId"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px"  }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Announcement Time :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <Field
                          name={"announcementTime"}
                          component={CustomInput}
                          type="time"
                          value="15:12"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px"  }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                        Luckydraw dashboard URL :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="luckydrawDashboardUrl"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px"  }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                        Height :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="luckydrawDashboardHeight"
                          component={CustomInput}
                          type="text"
                          labelText={`(ex. 1000px)`}
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px"  }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                        Width :
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="luckydrawDashboardWidth"
                          component={CustomInput}
                          type="text"
                          labelText={`(ex. 100%)`}
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                expanded={showEarnRule}
                onChange={() => this.setState({ showEarnRule: !showEarnRule })}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <ListItemIcon>
                    <VpnKeyIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: "430",
                      position: "relative",
                      fontSize: "13px"
                    }}
                  >
                    EARN RULE
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer
                      style={{
                        marginLeft: "60px",
                        marginBottom: "15px",
                      }}
                    >
                      <GridItem xs={12} sm={9} md={5}>
                        <Field
                          style={{ paddingTop: "0px" }}
                          name={"checkBlockUser"}
                          onChange={this.handleBlockMng}
                          component={CustomCheckbox}
                          disabled={readOnly}
                          labelText="Block User"
                        />
                      </GridItem>
                    </GridContainer>
                    {showBlockMng && (
                      <>
                        {/* <div style={{border: "dashed 1px #ccc"}}> */}

                        <GridContainer
                          style={{ marginLeft: "25px", paddingTop: "0px" }}
                        >
                          <GridItem xs={12} sm={12} md={2}>
                            <FormLabel
                              className={classes.labelHorizontal}
                              style={{ paddingTop: "10px" }}
                            >
                              Input :
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={9}>
                            <Field
                              labelText="Input"
                              name="blockTypeRadio"
                              component={CustomRadio}
                              labelPlacement="top"
                              disabled={readOnly}
                              radioLists={[
                                {
                                  value: "incorrect_or_duplicate",
                                  label: "Incorrect or Duplicated Code",
                                  disabled: readOnly
                                },
                                {
                                  value: "incorrect",
                                  label: "Incorrect Code",
                                  disabled: readOnly
                                },
                                {
                                  value: "duplicate",
                                  label: "Duplicated Code",
                                  disabled: readOnly
                                }
                              ]}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}>
                          <GridItem xs={12} sm={12} md={2}>
                            <FormLabel className={classes.labelHorizontal}>
                              For :
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2}>
                            <Field
                              position="end"
                              name="wrongTime"
                              component={CustomInput}
                              endAdornment={
                                <InputAdornment position="end">
                                  Times
                                </InputAdornment>
                              }
                              type="number"
                              min="0"
                              formControlProps={{
                                fullWidth: true
                              }}
                              disabled={readOnly}
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={2} style={{ paddingTop: "15px" }} >
                            <FormLabel className={classes.labelHorizontal}>
                              Within :
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2}>
                            <Field
                              name="wrongTimeType"
                              component={CustomSelect}
                              type="text"
                              validate={validateNullWithin}
                              onChange={this.handleWrongTime}
                              formControlProps={{
                                fullWidth: true
                              }}
                              optionsList={[
                                {
                                  value: "null",
                                  label: "Please Select"
                                },
                                {
                                  value: "minutes",
                                  label: "Minute"
                                },
                                {
                                  value: "hours",
                                  label: "Hour"
                                },
                                {
                                  value: "days",
                                  label: "Day(24 hours)"
                                },
                                {
                                  value: "midnight",
                                  label: "Midnight"
                                }
                              ]}
                              disabled={readOnly}
                            />
                          </GridItem>
                          {selectWrongTime && wrongTimeValue !== "midnight" && (
                            <GridItem xs={12} sm={12} md={3}>
                              <Field
                                position="end"
                                name="wrongTimeWithin"
                                component={CustomInput}
                                min="0"
                                max={
                                  wrongTimeValue === "minutes"
                                    ? "59"
                                    : wrongTimeValue === "hours"
                                    ? "23"
                                    : ""
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    {wrongTimeValue}
                                  </InputAdornment>
                                }
                                type="number"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                disabled={readOnly}
                              />
                            </GridItem>
                          )}
                        </GridContainer>

                        <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}>
                          <GridItem xs={12} sm={12} md={2} >
                            <FormLabel className={classes.labelHorizontal}>
                              Block Time Until :
                            </FormLabel>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={2}>
                            <Field
                              name="blockType"
                              component={CustomSelect}
                              type="text"
                              validate={validateNullBlockUntil}
                              onChange={this.handleBlockTime}
                              formControlProps={{
                                fullWidth: true
                              }}
                              optionsList={[
                                {
                                  value: "null",
                                  label: "Please Select"
                                },
                                {
                                  value: "minutes",
                                  label: "Minute"
                                },
                                {
                                  value: "hours",
                                  label: "Hour"
                                },
                                {
                                  value: "days",
                                  label: "Day(24 hours)"
                                },
                                {
                                  value: "specific",
                                  label: "Specific Time"
                                }
                              ]}
                              disabled={readOnly}
                            />
                          </GridItem>

                          {selectBlockTime && blockTimeValue !== "specific" ? (
                            <GridItem xs={12} sm={12} md={2}>
                              <Field
                                position="end"
                                name="blockTimeUntil"
                                min="0"
                                max={
                                  blockTimeValue === "minutes"
                                    ? "59"
                                    : blockTimeValue === "hours"
                                    ? "23"
                                    : ""
                                }
                                component={CustomInput}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {blockTimeValue}
                                  </InputAdornment>
                                }
                                type="number"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                disabled={readOnly}
                              />
                            </GridItem>
                          ) : (
                            blockTimeValue === "specific" &&
                            selectBlockTime && (
                              <GridItem xs={12} sm={12} md={2}>
                                <Field
                                  name="specificTime"
                                  component={CustomInput}
                                  type="time"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  disabled={readOnly}
                                />
                              </GridItem>
                            )
                          )}
                        </GridContainer>

                        <GridContainer
                          style={{ marginLeft: "25px", paddingTop: "0px" }}
                        >
                          <GridItem xs={12} sm={12} md={2}>
                            <FormLabel className={classes.labelHorizontal}>
                              Error Message :
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={9} md={6}>
                            <Field
                              name="errorMessage"
                              component={CustomInput}
                              type="text"
                              formControlProps={{
                                fullWidth: true
                              }}
                              disabled={readOnly}
                            />
                          </GridItem>
                        </GridContainer>
                      </>
                    )}
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                expanded={showShipping}
                onChange={() => this.setState({ showShipping: !showShipping })}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <ListItemIcon>
                    <LocalShippingIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: "430",
                      position: "relative",
                      fontSize: "13px"
                    }}
                  >
                    SHIPPING MANAGEMENT
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer
                      style={{
                        marginLeft: "60px",
                        marginBottom: "15px",
                      }}
                    >
                      <GridItem xs={12} sm={9} md={5}>
                        <Field
                          style={{ paddingTop: "0px" }}
                          name={"shippingLabel"}
                          component={CustomCheckbox}
                          disabled={readOnly}
                          labelText="Shipping Label"
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                expanded={showWinner}
                onChange={() => this.setState({ showWinner: !showWinner })}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <ListItemIcon>
                    <PhoneIphoneIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500]
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: "430",
                      position: "relative",
                      fontSize: "13px"
                    }}
                  >
                    WINNER
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>

                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}> 
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                         Winner Bucket :
                        </FormLabel>
                      </GridItem>

                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="winnerBucket"
                          component={CustomInput}
                          onChange={this.handleKeyPress}
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                          Winner Bucket Id:
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={9} md={6}>
                        <Field
                          name="winnerBucketId"
                          component={CustomInput}
                          type="text"
                          formControlProps={{
                            fullWidth: true
                          }}
                          disabled={readOnly}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer style={{ marginLeft: "25px", paddingTop: "0px" }}>
                      <GridItem xs={12} sm={12} md={2}>
                        <FormLabel className={classes.labelHorizontal}>
                        Winner Bucket Config :
                        </FormLabel>
                      </GridItem>
                    </GridContainer>

                    <GridContainer style={{ marginLeft: "px", paddingTop: "0px" }}>
                      <GridItem xs={12} sm={12} md={12}>
                        <Field
                           name="winnerBucketConfig"
                           component={RichEditor}
                        />
                      </GridItem>
                    </GridContainer>

                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </GridItem>
          </GridContainer>
          <CardBody>
            <GridContainer style={{ marginTop: "60px" }}>
              <GridItem xs={12} sm={12} md={12}>
                <Button type="submit" color="primary">
                  Submit
                </Button>
                <Button
                  type="button"
                  onClick={this.handleClose}
                  style={{ marginLeft: "10px" }}
                  color="white"
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    formOpen,
    formMode,
    showLine,
    showSms,
    showLuckyDraw,
    showEarnRule,
    showShipping,
    showWinner
  } = state.merchant;
  const { FORM_MERCHANT } = state.form;
  return {
    formOpen,
    formMode,
    FORM_MERCHANT,
    showLine,
    showSms,
    showLuckyDraw,
    showEarnRule,
    showShipping,
    showWinner
  };
}

const connectMerchantForm = connect(mapStateToProps)(settingForm);

export default reduxForm({
  form: FORM_NAME,
  validate: validate,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, props) => {
    crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
  }
})(withStyles({ ...style, ...regularFormsStyle })(connectMerchantForm));

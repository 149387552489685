import { photoGiveawayRulesServices } from '../services';
import { photoGiveawayRuleConstants } from '../constants'
import { loader, alert as customAlert } from 'helpers';
import { change } from 'redux-form';
import i18n from 'i18n';
import lodash from 'lodash';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const photoGiveawayRulesActions = {
    getListsPhotoGiveawayPhoto,
    getPhotoGiveawayRuleLists,
    addPhotoGiveawayRule,
    editPhotoGiveawayRule,
    deletePhotoGiveawayRule,
    getPhotoGiveawayRuleById
};

function getListsPhotoGiveawayPhoto(params) {
    return dispatch => {
        dispatch({ type: photoGiveawayRuleConstants.RULES_FORM_PHOTO_LIST_LOADER, showLoader: true })
        photoGiveawayRulesServices.getListsPhotoGiveawayPhoto(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data.photoGiveawayPhoto));
                        } else {
                            dispatch(success([]));
                        }
                    } catch (e) {
                        dispatch(success([]));
                    }
                },
                error => {
                    dispatch(success([]));
                }
            ).then(() => {
                dispatch({ type: photoGiveawayRuleConstants.RULES_FORM_PHOTO_LIST_LOADER, showLoader: false })
            })
    };

    function success(tmp) { return { type: photoGiveawayRuleConstants.GET_PHOTO_LIST_SUCCESS, photoList: tmp } }
}

function getPhotoGiveawayRuleLists(params) {
    return dispatch => {
        // loader(dispatch, true)
        dispatch({ type: photoGiveawayRuleConstants.SHOW_TABLE_LOADER })
        photoGiveawayRulesServices.getPhotoGiveawayRuleLists(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                // loader(dispatch, false)
                dispatch({ type: photoGiveawayRuleConstants.HIDE_TABLE_LOADER })
            })
    }
    function success(tmp) { return { type: photoGiveawayRuleConstants.GET_RULES_DATA_SUCCESS, data: tmp } }
}

function addPhotoGiveawayRule(params) {
    return dispatch => {
        loader(dispatch, true)
        photoGiveawayRulesServices.addPhotoGiveawayRule(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: photoGiveawayRuleConstants.ADD_RULES_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.earn_rule_was_created'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function editPhotoGiveawayRule(params) {
    return dispatch => {
        loader(dispatch, true)
        photoGiveawayRulesServices.editPhotoGiveawayRule(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: photoGiveawayRuleConstants.EDIT_RULES_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.earn_rule_was_updated'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function deletePhotoGiveawayRule(params) {
    return dispatch => {
        loader(dispatch, true)
        photoGiveawayRulesServices.deletePhotoGiveawayRule(params)
            .then(
                tmp => {
                    if (tmp.status) {
                        dispatch({ type: photoGiveawayRuleConstants.DELETE_RULES_SUCCESS });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.earn_rule_was_deleted'));
                    } else {
                        if(!tmp.unAlert) customAlert(dispatch, 'error', tmp.message.toString())
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getPhotoGiveawayRuleById(id) {
    return dispatch => {
        loader(dispatch, true)
        photoGiveawayRulesServices.getPhotoGiveawayRuleById(id)
            .then(
                response => {
                    if (response.status) {
                        let selectedPhotoList = [];
                        const ruleData = response.data
                        let formInitialValues = ruleData

                        // console.log("formInitialValues :",formInitialValues);

                        // formInitialValues.earnRuleCode = ruleData.ruleCode
                        // formInitialValues.reward = ruleData.reward
                        // formInitialValues.customRadioEarnFor = ruleData.earnFor
                        // formInitialValues.customRadioEarnBy = ruleData.earnBy

                        let photoLimit = {};
                        
                        lodash.forEach(ruleData.photoGiveawayPhotoIdsWithLimit, function (value) {
                            let photoGiveawayPhotoId = value.photoGiveawayPhotoId;
                            selectedPhotoList.push({
                                _id: value.photoGiveawayPhotoId,
                                name: value.photo.name
                            });
                
                            photoLimit = {
                                ...photoLimit,
                                [photoGiveawayPhotoId]: value.limit
                            }
                        })
                        formInitialValues.photoLimit = photoLimit;
                        
                   
                        dispatch({ type: photoGiveawayRuleConstants.OPEN_EDIT_RULES, 
                            data: formInitialValues, 
                            selectedPhotoList: selectedPhotoList,
                            photoListMaster : lodash.get(ruleData,'photoGiveawayPhoto')
                        })
                        dispatch(change('FORM_PHOTOGIVEAWAYRULES', 'selectedPhotoList', lodash.size(selectedPhotoList)))
                        
                    } else {
                        customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}


export const roleConstants = {
   OPEN_FORM_ADD: 'OPEN_ADD_FORM_ROLE',
   OPEN_FORM_EDIT: 'OPEN_EDIT_FORM_ROLE',
   CLOSE_FORM_ROLE: 'CLOSE_FORM_ROLE',

   ADD_NEW_ROLE_SUCCESS: 'ADD_NEW_ROLE_SUCCESS',
   ADD_NEW_ROLE_FAILURE: 'ADD_NEW_ROLE_FAILURE',

   EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
   EDIT_ROLE_FAILURE: 'EDIT_ROLE_FAILURE',

   DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
   DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
   DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE',

   GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
   GET_STATISTICS_FAILURE: 'GET_STATISTICS_FAILURE',

   GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
   GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
   GET_ROLE_FAILURE: 'GET_ROLE_FAILURE',

   GET_FEATURES_REQUEST: 'GET_FEATURES_REQUEST',
   GET_FEATURES_SUCCESS: 'GET_FEATURES_SUCCESS',
   GET_FEATURES_FAILURE: 'GET_FEATURES_FAILURE',

   HIDE_ALERT: 'HIDE_ALERT',
   
   ROLE_LIST_CLEAR_STATE: 'ROLE_LIST_CLEAR_STATE',
   CLEAR_PAGE_STATE: 'ROLE_CLEAR_PAGE_STATE',

   RELOAD_TABLE_ROLELIST: 'RELOAD_TABLE_ROLELIST',
   TABLE_RELOAD_SUCCESS: 'TABLE_ROLE_RELOAD_SUCCESS',
   TABLE_RESET: 'TABLE_RESET',
   TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',
   TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_ROLES',

   SHOW_TABLE_LOADER: 'SHOW_ROLE_TABLE_LOADER',
   HIDE_TABLE_LOADER: 'HIDE_ROLE_TABLE_LOADER',

   BRAND_ADMIN_ROLE_ID: process.env.REACT_APP_BRAND_ADMIN_ROLE_ID || ''
};

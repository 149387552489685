import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import lodash from 'lodash'
import { plugins, crmValidator } from "helpers";
import { reportActions } from 'actions';


import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import RichEditor from "components/RichEditor/RichEditor.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";
import Icon from '@material-ui/core/Icon';
import CheckCircle from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import { Field, reduxForm, change, touch } from 'redux-form';

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";


import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
const moment = require('moment')

const imgStyle = {
    width: "200px",
    height: "200px",
    objectFit: "contain",
    outline: "1px solid #DCDCDC"
}

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {

                textAlign: "center",
                paddingRight: '24px'

            },
            body: {
                textAlign: "center",
                paddingRight: '24px'
            }
        }
    }
});

const FORM_NAME = "FORM_EARN_REPORT";

const form_validate = {
    "remark": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class EarnReportForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            remark: true,
            first: true,
        };
    }

    closeDialog() {
        const { dispatch } = this.props;
        dispatch({ type: 'CLOSE_EARN_REPORT_EDIT' });
    }

    getRowData(rowIndex = 0) {
        const { earnReport } = this.props;
        const { tableData } = earnReport;
        const data = tableData;
        if (rowIndex.length === 0) {
            rowIndex = 0;
        }
        return data[rowIndex];
    }

    handleSubmit(values) {
        this.setState({ remark: false, first: true })
        const { dispatch, user, initialValues } = this.props;
        if (initialValues.remark !== values.remark) {
            const remark = values.remark
            const transactionId = values._id
            const reject_note = values.reject_note
            let date_now_check = new Date();
            
            let year_check = date_now_check.toLocaleString("en-US", {
              year: "numeric",
            });
            let month_check = date_now_check.toLocaleString("th", {
              month: "2-digit",
            });
            let Day_check = date_now_check.toLocaleString("th", {
              day: "2-digit",
            });
            let hour_check = date_now_check.toLocaleString("th", { hour: "2-digit" });
            let minute_check = date_now_check.toLocaleString("th", {
              minute: "2-digit",
            });
            month_check = month_check.padStart(2, "0");
            Day_check = Day_check.padStart(2, "0");
            hour_check = hour_check.padStart(2, "0");
            minute_check = minute_check.padStart(2, "0");
        
            let fulldatecheck =
              year_check + month_check + Day_check ;
            let fulldateNum = Number(fulldatecheck);


            const start = 20230701
            let set_campaign='summer'
            
             if (fulldateNum>=Number(start)) {
                set_campaign="rainy"
            } 

            let params = {
                remark,
                transactionId,
                reject_note,
               
            }
            params.type_campaign=set_campaign
            dispatch(reportActions.updateTransaction(params))
        }
        else if (values.remark == 'REJECT' && initialValues.reject_note !== values.reject_note) {
            const remark = values.remark
            const transactionId = values._id
            const reject_note = values.reject_note
            let params = {
                remark,
                transactionId,
                reject_note,
                
            }
            dispatch(reportActions.updateTransaction(params))
        } else {
            this.closeDialog()
        }
    }

    render() {
        const { handleSubmit, formOpen, fromMode, classes, earnReport, tableKey, initialValues, handleChange } = this.props;

        const user = lodash.get(initialValues, 'user')
        const userFirstName = lodash.get(user, 'firstName')
        const userLastName = lodash.get(user, 'lastName')
        const userPhoneNumber = lodash.get(user, 'phoneNumber')
        const sn_remark = lodash.get(initialValues, 'sn_remark')
        const sn_verified = lodash.get(initialValues, 'sn_verified')
        const date = lodash.get(initialValues, 'date')
        const model = lodash.get(initialValues, 'model')
        const code = lodash.get(initialValues, 'code')
        const check_id = lodash.get(initialValues, 'check_id')
        const store_name = lodash.get(initialValues, 'store_name')
        const machine_number = lodash.get(initialValues, 'machine_number')
        const price = lodash.get(initialValues, 'price')
        const images = lodash.get(initialValues, 'images')
        const images_sn = lodash.get(initialValues, 'images_sn')
        const images1 = lodash.get(initialValues, 'images1')
        const images2 = lodash.get(initialValues, 'images2')
        const remark = lodash.get(initialValues, 'remark')
        let check_reject_note = false
        if (remark === 'REJECT') {
            check_reject_note = true
        }
        let remarkOptions
        if (remark === 'PENDING') {
            remarkOptions = [
                {
                    label: 'Pending',
                    value: 'PENDING'
                },
                {
                    label: 'Approve',
                    value: 'SUCCESS'
                },
                {
                    label: 'Reject',
                    value: 'REJECT'
                }
            ]
        } else {
            remarkOptions = [
                {
                    label: 'Approve',
                    value: 'SUCCESS'
                },
                {
                    label: 'Reject',
                    value: 'REJECT'
                }
            ]
        }

        if (sn_remark === 'CODE_NOT_FOUND' || sn_remark === 'CODE_WAS_USED') {
            remarkOptions = [
                {
                    label: 'Pending',
                    value: 'PENDING'
                },
                {
                    label: 'Reject',
                    value: 'REJECT'
                }
            ]
        }

        let imageList = []
        let imageListSn = []
        let imageList1 = []
        let imageList2 = []


        lodash.forEach(images_sn, function (value, key) {
            imageListSn.push(value.url);
        });

        const showImagesSn = imageListSn.map((item) =>
            <a href={item} target="_blank">
                <div style={{ width: '210px', height: '210px', float: 'left' }}>
                    <img src={item} style={imgStyle} />
                </div>
            </a>
        );

        lodash.forEach(images, function (value, key) {
            imageList.push(value.url);
        });
        const showImages = imageList.map((item) =>
            <a href={item} target="_blank">
                <div style={{ width: '210px', height: '210px', float: 'left' }}>
                    <img src={item} style={imgStyle} />
                </div>
            </a>
        );


        lodash.forEach(images1, function (value, key) {
            imageList1.push(value.url);
        });
        const showImages1 = imageList1.map((item) =>
            <img src={item} width="300" />
        );


        lodash.forEach(images2, function (value, key) {
            imageList2.push(value.url);
        });
        const showImages2 = imageList2.map((item) =>
            <img src={item} width="300" />
        );

        let columns = [
            { name: "skuId", label: "SKU ID" },
            { name: "skuName", label: "SKU NAME" },
            { name: "quantity", label: "Quantity" },
        ]

        let options = {
            serverSide: false,
            count: false,
            page: false,
            rowsPerPage: 100,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: false,
            search: false,
            pagination: false,
            sort: false,
            sortFilterList: false,
            viewColumns: false,
        }
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                maxWidth='lg'
                scroll="body"
                disableRestoreFocus={true}
            >
                {imageList.length != 0 && (<DialogContent
                    id="import-result-detail-dialog"
                    // className={classes.modalBody}
                    style={{ padding: "100", textAlign: 'left' }}
                >
                    <h4 className={classes.modalTitle} style={{ textAlign: 'left' }}>
                        <b>Transaction</b>
                    </h4>

                    <GridContainer spacing={2}>
                            <GridItem xs={2}>
                                <h5><b>ใบกำกับภาษี</b> </h5>
                                <div>{showImages}</div>
                            </GridItem>
                            <GridItem xs={10}>
                                <h5><b>Serial number</b></h5>
                                <div>{showImagesSn}</div>
                            </GridItem>
                    </GridContainer>
                    
                    {model && <div><h5><b>Model</b></h5>
                        <div style={{display: 'inline-flex'}}>
                            <div style={{float: 'left'}}>{model}</div> 
                            <CheckCircle style={{ marginLeft: '5px', color: '#79BF00'}}/>
                        </div>
                    </div>
                    }

                    <h5><b>Serial number</b></h5>
                    {sn_verified !== undefined ?
                    <div style={{display: 'inline-flex'}}>
                        <div style={{float: 'left'}}>{code}</div>
                        {sn_verified ? <CheckCircle style={{ marginLeft: '5px', color: '#79BF00'}}/> : <Cancel style={{ marginLeft: '5px', color: '#dc3545'}}/>}
                        <div style={{ marginLeft: '5px' }}>{sn_remark === "CODE_NOT_FOUND" ? "(รหัสผิด)" 
                        : sn_remark === "CODE_WAS_USED" ? "(รหัสซ้ำ)" 
                        : null}</div>
                    </div>
                    : <div style={{display: 'inline-flex'}}><div style={{float: 'left'}}>{code}</div></div>}

                    <h5><b>ชื่อ</b></h5>
                    <div>{userFirstName}</div>

                    <h5><b>นามสกุล</b></h5>
                    <div>{userLastName}</div>

                    <h5><b>เบอร์โทร</b></h5>
                    <div>{userPhoneNumber}</div>

                    <h5><b>วันที่จากใบเสร็จ</b></h5>
                    <div>{moment(date).format("DD/MM/YYYY")}</div>

                    <h5><b>เลขที่ใบเสร็จ</b></h5>
                    <div>{check_id}</div>

                    <h5><b>ราคาสินค้าในใบเสร็จ ( รวม VAT 7% )</b></h5>
                    <div>{price}</div>

                    <h5><b>ร้านค้าที่ซื้อ</b></h5>
                    <div>{store_name}</div>

                    <h5><b>จำนวนเครื่องที่ซื้อ</b></h5>
                    <div>{machine_number}</div>



                </DialogContent>)}

                {(imageList1.length != 0 && imageList2.length != 0) && (<DialogContent
                    id="import-result-detail-dialog"
                    // className={classes.modalBody}
                    style={{ padding: "100", textAlign: 'left' }}
                >
                    <h4 className={classes.modalTitle} style={{ textAlign: 'left' }}>
                        <b>Transaction</b>
                    </h4>

                    <h5><b>Installation</b></h5>
                    <div>{showImages1}</div>

                    <h5><b>Location</b></h5>
                    <div>{showImages2}</div>


                </DialogContent>)}


                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}
                >
                    <DialogContent
                        id="earn-report-form-dialog"
                        className={classes.modalBody}
                    >
                        <GridContainer>
                            <GridItem xs={6} sm={4} md={4}>
                                <Field
                                    name="remark"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Status"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={(imageList.length != 0 && remark === 'SUCCESS' ) ? true : false}
                                    optionsList={remarkOptions}
                                    onChange={(e) => {
                                        this.setState({
                                            remark: e.target.value === 'REJECT' ? true : false,
                                            first: false
                                        })
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                                    
                        {(imageList.length != 0 && this.state.first && check_reject_note) && (
                            <GridContainer>
                                <GridItem xs={6} sm={5} md={5}>
                                    <Field
                                        name="reject_note"
                                        component={CustomRadio}
                                        radioLists={[
                                            {
                                                value: "ข้อมูลในใบเสร็จไม่ครบถ้วนตามเงื่อนไขโปรโมชั่น",
                                                label: <span style={{ fontWeight: '300' }}>ข้อมูลในใบเสร็จไม่ครบถ้วนตามเงื่อนไขโปรโมชั่น</span>,
                                            },
                                            {
                                                value: "ลงทะเบียนซ้ำ",
                                                label: <span style={{ fontWeight: '300' }}>ลงทะเบียนซ้ำ</span>,
                                            },
                                            {
                                                value: "รูปใบเสร็จไม่ชัดเจน",
                                                label: <span style={{ fontWeight: '300' }}>รูปใบเสร็จไม่ชัดเจน</span>,
                                            },
                                            {
                                                value: "รูป S/N ไม่ชัดเจน",
                                                label: <span style={{ fontWeight: '300' }}>รูป S/N ไม่ชัดเจน</span>,
                                            },
                                            {
                                                value: "S/N ไม่ถูกต้อง",
                                                label: <span style={{ fontWeight: '300' }}>S/N ไม่ถูกต้อง</span>,
                                            },
                                            {
                                                value: "ข้อมูลที่กรอกและข้อมูลรูปถ่ายไม่ตรงกัน",
                                                label: <span style={{ fontWeight: '300' }}>ข้อมูลที่กรอกและข้อมูลรูปถ่ายไม่ตรงกัน</span>,
                                            },
                                            {
                                                value: "ชื่อลูกค้าในใบเสร็จไม่ตรงกับชื่อที่ลงทะเบียนในระบบ",
                                                label: <span style={{ fontWeight: '300' }}>ชื่อลูกค้าในใบเสร็จไม่ตรงกับชื่อที่ลงทะเบียนในระบบ</span>,
                                            },
                                        ]}
                                    />
                                </GridItem>
                            </GridContainer>
                        )}

                        {(imageList.length != 0 && !this.state.first && this.state.remark) && (
                            <GridContainer>
                                <GridItem xs={6} sm={5} md={5}>
                                    <Field
                                        name="reject_note"
                                        component={CustomRadio}
                                        radioLists={[
                                            {
                                                value: "ข้อมูลในใบเสร็จไม่ครบถ้วนตามเงื่อนไขโปรโมชั่น",
                                                label: <span style={{ fontWeight: '300' }}>ข้อมูลในใบเสร็จไม่ครบถ้วนตามเงื่อนไขโปรโมชั่น</span>,
                                            },
                                            {
                                                value: "ลงทะเบียนซ้ำ",
                                                label: <span style={{ fontWeight: '300' }}>ลงทะเบียนซ้ำ</span>,
                                            },
                                            {
                                                value: "รูปใบเสร็จไม่ชัดเจน",
                                                label: <span style={{ fontWeight: '300' }}>รูปใบเสร็จไม่ชัดเจน</span>,
                                            }, 
                                            {
                                                value: "รูป S/N ไม่ชัดเจน",
                                                label: <span style={{ fontWeight: '300' }}>รูป S/N ไม่ชัดเจน</span>,
                                            },
                                            {
                                                value: "S/N ไม่ถูกต้อง",
                                                label: <span style={{ fontWeight: '300' }}>S/N ไม่ถูกต้อง</span>,
                                            },
                                            {
                                                value: "ข้อมูลที่กรอกและข้อมูลรูปถ่ายไม่ตรงกัน",
                                                label: <span style={{ fontWeight: '300' }}>ข้อมูลที่กรอกและข้อมูลรูปถ่ายไม่ตรงกัน</span>,
                                            },
                                            {
                                                value: "ชื่อลูกค้าในใบเสร็จไม่ตรงกับชื่อที่ลงทะเบียนในระบบ",
                                                label: <span style={{ fontWeight: '300' }}>ชื่อลูกค้าในใบเสร็จไม่ตรงกับชื่อที่ลงทะเบียนในระบบ</span>,
                                            },
                                        ]}
                                    />
                                </GridItem>
                            </GridContainer>
                        )}

                        {(imageList1.length != 0 && imageList2.length != 0 && this.state.first && check_reject_note) && (
                            <GridContainer>
                                <GridItem xs={6} sm={5} md={5}>
                                    <Field
                                        name="reject_note"
                                        component={CustomRadio}
                                        radioLists={[
                                            {
                                                value: "รูปภาพการติดตั้งไม่ถูกต้อง",
                                                label: <span style={{ fontWeight: '300' }}>รูปภาพการติดตั้งไม่ถูกต้อง</span>,
                                            },
                                            {
                                                value: "รูปภาพสถานที่ติดตั้งไม่ถูกต้อง",
                                                label: <span style={{ fontWeight: '300' }}>รูปภาพสถานที่ติดตั้งไม่ถูกต้อง</span>,
                                            },
                                            {
                                                value: "รูปภาพไม่ชัดเจน ไม่สามารถตรวจสอบเลข Serial Number ได้",
                                                label: <span style={{ fontWeight: '300' }}>รูปภาพไม่ชัดเจน ไม่สามารถตรวจสอบเลข Serial Number ได้</span>,
                                            },
                                            {
                                                value: "รูป S/N ไม่ชัดเจน",
                                                label: <span style={{ fontWeight: '300' }}>รูป S/N ไม่ชัดเจน</span>,
                                            },
                                             {
                                                value: "S/N ไม่ถูกต้อง",
                                                label: <span style={{ fontWeight: '300' }}>S/N ไม่ถูกต้อง</span>,
                                            },
                                            {
                                                value: "ข้อมูลที่กรอกและข้อมูลรูปถ่ายไม่ตรงกัน",
                                                label: <span style={{ fontWeight: '300' }}>ข้อมูลที่กรอกและข้อมูลรูปถ่ายไม่ตรงกัน</span>,
                                            },
                                        ]}
                                    />
                                </GridItem>
                            </GridContainer>
                        )}

                        {(imageList1.length != 0 && imageList2.length != 0 && !this.state.first && this.state.remark) && (
                            <GridContainer>
                                <GridItem xs={6} sm={5} md={5}>
                                    <Field
                                        name="reject_note"
                                        component={CustomRadio}
                                        radioLists={[
                                            {
                                                value: "รูปภาพการติดตั้งไม่ถูกต้อง",
                                                label: <span style={{ fontWeight: '300' }}>รูปภาพการติดตั้งไม่ถูกต้อง</span>,
                                            },
                                            {
                                                value: "รูปภาพสถานที่ติดตั้งไม่ถูกต้อง",
                                                label: <span style={{ fontWeight: '300' }}>รูปภาพสถานที่ติดตั้งไม่ถูกต้อง</span>,
                                            },
                                            {
                                                value: "รูปภาพไม่ชัดเจน ไม่สามารถตรวจสอบเลข Serial Number ได้",
                                                label: <span style={{ fontWeight: '300' }}>รูปภาพไม่ชัดเจน ไม่สามารถตรวจสอบเลข Serial Number ได้</span>,
                                            },
                                            {
                                                value: "รูปภาพที่แนบมาไม่ถูกต้องตามเงื่อนไขที่กำหนด",
                                                label: <span style={{ fontWeight: '300' }}>รูปภาพที่แนบมาไม่ถูกต้องตามเงื่อนไขที่กำหนด</span>,
                                            },
                                            {
                                                value: "ข้อมูลที่กรอกและข้อมูลรูปถ่ายไม่ตรงกัน",
                                                label: <span style={{ fontWeight: '300' }}>ข้อมูลที่กรอกและข้อมูลรูปถ่ายไม่ตรงกัน</span>,
                                            },
                                        ]}
                                    />
                                </GridItem>
                            </GridContainer>
                        )}



                    </DialogContent>

                    <DialogActions
                        className={classes.modalFooter}>

                        <Button
                            id={`${FORM_NAME}_btn_submit`}
                            type="submit"
                            style={{ marginRight: "10px" }}
                            color="primary">
                            Submit
                        </Button>

                        <Button
                            id={`${FORM_NAME}_btn_cancel`}
                            type="button"
                            onClick={() => {
                                this.setState({ remark: false, first: true })
                                this.closeDialog()
                            }}
                            color="white">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, earnReport, formMode } = state.report;
    const { user } = state.authentication;
    return {
        earnReport,
        formOpen,
        formMode,
        user
    };
}
const connectedComponent = connect(mapStateToProps)(EarnReportForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        //    crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(sweetAlertStyle)(connectedComponent))



export const businessWebsiteConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_PAGE_STATE',
    SET_FORM_PREVIEW_BUSINESS_LOGO: 'SET_FORM_PREVIEW_BUSINESS_LOGO',
    SET_FORM_PREVIEW_BUSINESS_COVER: 'SET_FORM_PREVIEW_BUSINESS_COVER',
    OPEN_CROP_BUSINESS_LOGO: 'OPEN_CROP_BUSINESS_LOGO',
    CLOSE_CROP_BUSINESS_LOGO: 'CLOSE_CROP_BUSINESS_LOGO',
    OPEN_CROP_BUSINESS_COVER: 'OPEN_CROP_BUSINESS_COVER',
    CLOSE_CROP_BUSINESS_COVER: 'CLOSE_CROP_BUSINESS_COVER',
    SET_BUSINESS_LINK: 'SET_BUSINESS_LINK',
    SET_BUSINESS_COLOR_THEME: 'SET_BUSINESS_COLOR_THEME',
    GET_BUSINESS_INFORMATION: 'GET_BUSINESS_INFORMATION',
    UPDATE_BUSINESS_INFORMATION: 'UPDATE_BUSINESS_INFORMATION',
    GET_PRIVACY_NOTICE_AND_TC : 'GET_PRIVACY_NOTICE_AND_TC',
    SET_BUSINESS_LOGO_FILE: 'SET_BUSINESS_LOGO_FILE',
    SET_BUSINESS_COVER_FILE: 'SET_BUSINESS_COVER_FILE',
    CLEAR_CHANGING_BUSINESS_WEBSITE: 'CLEAR_CHANGING_BUSINESS_WEBSITE',
    CLOSE_PRIVACY_NOTICE_AND_TC: 'CLOSE_PRIVACY_NOTICE_AND_TC',
    SET_HINT_TEXT_LINE: 'SET_HINT_TEXT_LINE',
    SET_HINT_TEXT_MESSENGER: 'SET_HINT_TEXT_MESSENGER',
    SET_BUSINESS_INFORMATION_INITIAL_VALUE: 'SET_BUSINESS_INFORMATION_INITIAL_VALUE',
}
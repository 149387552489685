import { reset } from 'redux-form'
import lodash from 'lodash';

import { shipmentConstants } from '../constants';
import { shipmentServices } from '../services';
import { loader, alert as customAlert } from 'helpers';

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

// const getErrorMessage = (key) => {
//    switch (key) {
//       default: return "กรุณาลองใหม่อีกครั้ง"
//    }
// }

export const shipmentActions = {
   editShipment,
   getTableDatas,
   getShippingCompanyList,
   getaddresslabelDetail,
   clearShipmentFilter,
   getCampaignList,
   importFile
};

function importFile(params) {
   return dispatch => {
       loader(dispatch, true)
       shipmentServices.importFile(params)
           .then(
               response => {
                   if (response.status) {
                        dispatch({
                           type: 'SHOW_EARN_RULE_IMPORT_PRODUCT_RESULT',
                           data: response.data
                        });
                   } else {
                       if (!response.unAlert) {
                           customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                       }
                   }
               },
               error => {
                   customAlert(dispatch, 'error', i18n.t(defaultFailedText))
               }
           ).then(() => {
               loader(dispatch, false)
           })
   }
}

function clearShipmentFilter() {
   return dispatch => {
      let tableFilter = {
         page: 0,
         skip: 0,
         limit: 10,
         sortBy: "createdAt",
         sortDir: "desc",
         keyword: "",
         search: {}
      };
      dispatch({ type: shipmentConstants.SET_FILTER, tableFilter });
      dispatch(reset('formFilterShipment'))
   }
}

function editShipment(params) {
   return dispatch => {
      loader(dispatch, true)
      shipmentServices.editShipment(params)
         .then(
            response => {

               if (response.status) {
                  customAlert(dispatch, 'success', i18n.t('warning_messages.shipment_was_updated'));
                  // dispatch(clearShipmentFilter())
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: shipmentConstants.EDIT_SHIPMENT_SUCCESS, tmp } }
}

function getTableDatas(params, tableReset = false) {
   return dispatch => {
      dispatch({ type: shipmentConstants.SHOW_LOADER_SHIPMENT })

      shipmentServices.getListsShipment(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: shipmentConstants.TABLE_GET_LIST_DATAS, data: response.data.data, total: response.data.total, tableFilter: params });
                  if (tableReset) {
                     dispatch({ type: shipmentConstants.TABLE_GET_LIST_DATAS_RESET });
                  }
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: shipmentConstants.HIDE_LOADER_SHIPMENT })
         })
   };

   // function success(tmp) { return { type: shipmentConstants.TABLE_GET_LIST_DATAS, tmp } }
}

function getShippingCompanyList() {
   // return dispatch => {
   //    shipmentServices.getShippingCompanyList()
   //    .then(
   //       response => {  
   //          if (true) {
   //             console.log("response = ", response);
   //             dispatch(success(response.data));
   //          } else {
   //             customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
   //          }
   //       },
   //       error => {
   //          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
   //       }
   //    ).then(() => {
   //    })
   // };

   // function success(tmp){ return { type: shipmentConstants.GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS, tmp } } 
}

function getaddresslabelDetail(qryParams) {
   return dispatch => {
      shipmentServices.getaddresslabelDetail(qryParams)
   }
}

function getCampaignList(params) {
   return dispatch => {
      shipmentServices.getCampaignsList(params)
         .then(
            response => {
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     dispatch(success(response.data));
                  }
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: shipmentConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data }
   }
}
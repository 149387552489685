
import React, { useState, useCallback } from "react";
import { Field, touch, change } from 'redux-form';
import copy from "clipboard-copy"
import { NotificationContainer, NotificationManager } from "react-notifications";

import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import RichEditor from "components/RichEditor/RichEditor.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Icon from '@material-ui/core/Icon';
import QRCode from "qrcode.react";

import { crmValidator, alert as customAlert  } from 'helpers'
import { businessWebsiteConstants } from "../../constants"
import * as FormValidator from './validate/FormValidator';
import phoneIcon from "assets/img/phone.png";
import lineIcon from "assets/img/line.png";
import messengerIcon from "assets/img/messenger.png";
import defaultLogoImage from "assets/img/sample-default-logo-position.png";
import defaultCoverImage from "assets/img/sample-default-bg-cover.jpg";
import lodash from 'lodash'
import i18n from 'i18n';

import Cropper from 'react-easy-crop'
import Slider from '@material-ui/lab/Slider'
import getCroppedImg from './cropImage'
import BusinessColorPalette from "./BusinessColorPalette";
import facebookIcon from "assets/img/facebook.png";
import instagramIcon from "assets/img/instagram.png";
import youtubeIcon from "assets/img/youtube.png";


function BusinessInformation({ ...props }) {
    const { classes, dispatch, formMode, businessLogoPreview, businessCoverPreview, businessLogo, businessCover, BUSINESS_INFORMATION, formOpenCropLogo, formOpenCropCover, businessLink, labelTextMessenger, hintTextMessenger, labelTextLine, hintTextLine } = props.props
    const { FORM_NAME } = props
    let { readOnly } = props.props

    if (formMode === `add`) {
        readOnly = false
    } else if (formMode === `edit` && !readOnly) {
        readOnly = false
    } else {
        readOnly = true
    }
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixelsLogo, setCroppedAreaPixelsLogo] = useState(null)
    const [croppedAreaPixelsCover, setCroppedAreaPixelsCover] = useState(null)
    const [croppedImageLogo, setCroppedImageLogo] = useState(null)
    const [croppedImageCover, setCroppedImageCover] = useState(null)

    const onCropCompleteLogo = useCallback((croppedArea, croppedAreaPixelsLogo) => {
        setCroppedAreaPixelsLogo(croppedAreaPixelsLogo)
    }, [])
    const onCropCompleteCover = useCallback((croppedArea, croppedAreaPixelsCover) => {
        setCroppedAreaPixelsCover(croppedAreaPixelsCover)
    }, [])

    const showCroppedImageLogo = useCallback(async () => {
        try {
            const croppedImageLogo = await getCroppedImg(
                businessLogo,
                croppedAreaPixelsLogo,
                rotation
            )
            setCroppedImageLogo(croppedImageLogo)
            dispatch({ type: businessWebsiteConstants.CLOSE_CROP_BUSINESS_LOGO, businessLogoPreview: croppedImageLogo })
            setDefault()
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixelsLogo, rotation])
    const showCroppedImageCover = useCallback(async () => {
        try {
            const croppedImageCover = await getCroppedImg(
                businessCover,
                croppedAreaPixelsCover,
                rotation
            )
            setCroppedImageCover(croppedImageCover)
            dispatch({ type: businessWebsiteConstants.CLOSE_CROP_BUSINESS_COVER, businessCoverPreview: croppedImageCover })
            setDefault()
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixelsCover, rotation])
    const setDefault = () => {
        setZoom(1)
        setRotation(0)
        setCrop({ x: 0, y: 0 })
    }
    const downloadQRCode = () => {
        // Generate download with use canvas and stream
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qr-code.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const onInsertLineID = (keyword) => {
        dispatch({type: businessWebsiteConstants.SET_HINT_TEXT_LINE, hintText: '', labelText: ''})
        if(keyword.length < 1){
            dispatch({type: businessWebsiteConstants.SET_HINT_TEXT_LINE, hintText: '', labelText: 'Line ID (For example : @eggdigital or ~lineid)'})
        }else{
            if(keyword === '~'){
                keyword = ''
            }else if(keyword === '@' && keyword.length === 1){
                keyword = '@'
            }else if(keyword.length === 1){
                keyword = '~'+keyword
            }
        }
        return keyword;
    }

    const onInsertMessenger = (keyword) => {
        if(keyword.length >= 1){
            dispatch({type: businessWebsiteConstants.SET_HINT_TEXT_MESSENGER, hintText: '', labelText: ''})
        }else{
            dispatch({type: businessWebsiteConstants.SET_HINT_TEXT_MESSENGER, hintText: '', labelText: 'm.me/[FacebookID] (For example : m.me/eggdigital)'})
        }
        return keyword;
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 style={{fontWeight:'500', fontSize:'1.0rem'}}>Business Name</h4>
                    <Field
                        id="business-name-input"
                        name="businessName"
                        component={CustomInput}
                        type="text"
                        labelText="Business Name"
                        formControlProps={{
                            fullWidth: true
                        }}
                        maxLength={50}
                        disabled={readOnly}
                        validate={FormValidator.validateRequired}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                    <h4 style={{fontWeight:'500', fontSize:'1.0rem'}}>Business Logo</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Field
                    name="businessLogo"
                    component={ImageUpload}
                    type="text"
                    textLeft={true}
                    height={120}
                    showRatioGuide={true}
                    alwayClearImage={true}
                    onChange={(e) => { 
                        e.preventDefault();
                        let reader = new FileReader();
                        let file = e.target.files[0];
                        reader.onloadend = () => {
                            if(lodash.get(file, 'type') != undefined){
                                if(!FormValidator.validMimeType.includes(file.type)){
                                    customAlert(dispatch, 'error', i18n.t("warning_messages.only_img_file_type_is_jpg_or_png"))
                                    return false
                                }else if(file.size > (1048576 * 2)){
                                    customAlert(dispatch, 'error', i18n.t("warning_messages.logo_picture_file_size_must_be_less_than_2mb"))
                                    return false
                                }else{
                                    dispatch({ type: businessWebsiteConstants.OPEN_CROP_BUSINESS_LOGO, businessLogoPreview: reader.result })
                                }
                            }
                        };
                        try {
                            // if (file.size > (1048576 * 2)) {
                            //     dispatch(touch(FORM_NAME, 'businessLogo'));
                            // }
                            reader.readAsDataURL(file);
                            dispatch(change(FORM_NAME, 'businessLogo', file))
                        } catch (error) { 
                            if(lodash.get(file, 'type') != undefined){
                                dispatch({ type: businessWebsiteConstants.SET_FORM_PREVIEW_BUSINESS_LOGO, businessLogoPreview: "default" })
                                dispatch(change(FORM_NAME, 'businessLogo', ''))
                            }
                        }
                    }}
                    imagePreviewUrl={businessLogoPreview}
                    formControlProps={{
                        fullWidth: true
                    }}
                    // validate={[FormValidator.validateImageSizeLogo]}
                    disabled={readOnly}
                    />
                </GridItem>
                
                <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpenCropLogo}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth={"sm"}
                disableRestoreFocus={true}
                scroll="body">
                <Icon 
                    id="btn_close_crop_logo"
                    fontSize="small"
                    onClick={() => {
                        dispatch({ type: businessWebsiteConstants.CLOSE_CROP_BUSINESS_LOGO, businessLogoPreview: 'cancel' })
                        setDefault()
                    }}
                    style={{marginTop: '10px', marginLeft: '570px'}}
                >
                    close
                </Icon>
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{paddingTop: '0px'}}>
                    <h4 className={classes.modalTitle} style={{fontWeight:'500', fontSize:'1.0rem', marginTop: '0px', marginBottom: '0px'}}>Crop Business Logo</h4>
                </DialogTitle>
                <DialogContent >
                <Card style={{marginTop: '0px'}}>
                    <CardBody style={{height: '200px'}}>
                    <GridContainer>
                    <div className={classes.cropContainer}>
                        <Cropper
                            image={businessLogo != null ? businessLogo : defaultLogoImage}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropCompleteLogo}
                            onZoomChange={setZoom}
                            restrictPosition={false}
                            minZoom={0.5}
                        />
                    </div>
                    </GridContainer>
                    </CardBody>
                </Card>
                    <div className={classes.controls}>
                        <GridContainer>
                            <GridItem xs={12} sm={2} md={2}>
                                <b>Zoom</b>
                            </GridItem>
                            <GridItem xs={12} sm={10} md={10} style={{marginTop: '10px'}}>
                                <Slider
                                    value={zoom}
                                    min={0.5}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    classes={{ container: classes.slider }}
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={2} md={2}  style={{marginTop: '15px'}} >
                                <b>Rotation</b>
                            </GridItem>
                            <GridItem xs={12} sm={10} md={10} style={{marginTop: '25px'}}>
                                <Slider
                                    value={rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    aria-labelledby="Rotation"
                                    classes={{ container: classes.slider }}
                                    onChange={(e, rotation) => setRotation(rotation)}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }} className={classes.modalFooter}>
                                <Button
                                    id="cancel-btn"
                                    onClick={setDefault}
                                    style={{ marginRight: "10px" }}
                                    size="sm"
                                    color="white">
                                    Default
                                </Button>
                                <Button
                                    id="submit-logo-btn"
                                    onClick={showCroppedImageLogo}
                                    size="sm"
                                    color="primary">
                                    Submit
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </div>
                </DialogContent>
            </Dialog>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                    <h4 style={{fontWeight:'500', fontSize:'1.0rem'}}>Business Cover</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Field
                    name="businessCover"
                    component={ImageUpload}
                    type="text"
                    textLeft={true}
                    height={190}
                    showRatioGuide={true}
                    alwayClearImage={true}
                    onChange={(e) => {
                        e.preventDefault();
                        let reader = new FileReader();
                        let file = e.target.files[0];
                        reader.onloadend = () => {
                            if(!FormValidator.validMimeType.includes(file.type)){
                                customAlert(dispatch, 'error', i18n.t("warning_messages.only_img_file_type_is_jpg_or_png"))
                                return false
                            }else if(file.size > (1048576 * 2)){
                                customAlert(dispatch, 'error', i18n.t("warning_messages.cover_picture_file_size_must_be_less_than_2mb"))
                                return false
                            }else{
                                dispatch({ type: businessWebsiteConstants.OPEN_CROP_BUSINESS_COVER, businessCoverPreview: reader.result })
                            }
                        };
                        try {
                            // if (!FormValidator.validMimeType.includes(file.type)) {
                            //     dispatch(touch(FORM_NAME, 'businessCover'));
                            // }
                            reader.readAsDataURL(file);
                            dispatch(change(FORM_NAME, 'businessCover', file))
                        } catch (error) {
                            if(lodash.get(file, 'type') != undefined){
                                dispatch({ type: businessWebsiteConstants.SET_FORM_PREVIEW_BUSINESS_COVER, businessCoverPreview: "default" })
                                dispatch(change(FORM_NAME, 'businessCover', ''))
                            }
                        }
                    }}
                    imagePreviewUrl={businessCoverPreview}

                    formControlProps={{
                        fullWidth: true
                    }}
                    // validate={[FormValidator.validateImageFormat, FormValidator.validateImageSizeCover]}
                    disabled={readOnly}
                    />
                </GridItem>
                <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpenCropCover}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth={"sm"}
                disableRestoreFocus={true}
                scroll="body">
                <Icon 
                    id="btn_close_crop_cover"
                    fontSize="small"
                    onClick={() => {
                        dispatch({ type: businessWebsiteConstants.CLOSE_CROP_BUSINESS_COVER, businessCoverPreview: 'cancel' })
                        setDefault()
                    }}
                    style={{marginTop: '10px', marginLeft: '570px'}}
                >
                    close
                </Icon>
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{paddingTop: '0px'}}>
                    <h4 className={classes.modalTitle} style={{fontWeight:'500', fontSize:'1.0rem', marginTop: '0px', marginBottom: '0px'}}>Crop Business Cover</h4>
                </DialogTitle>
                <DialogContent >
                <div>
                <Card style={{marginTop: '0px'}}>
                    <CardBody style={{height: '200px'}}>
                    <GridContainer>
                    <div className={classes.cropContainer}>
                        <Cropper
                            image={businessCover != null ? businessCover : defaultCoverImage}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={16 / 9}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropCompleteCover}
                            onZoomChange={setZoom}
                            restrictPosition={false}
                            minZoom={0.5}
                        />
                    </div>
                    </GridContainer>
                    </CardBody>
                </Card>
                    <div className={classes.controls}>
                        <GridContainer>
                            <GridItem xs={12} sm={2} md={2}>
                                <b>Zoom</b>
                            </GridItem>
                            <GridItem xs={12} sm={10} md={10} style={{marginTop: '10px'}}>
                                <Slider
                                    value={zoom}
                                    min={0.5}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    classes={{ container: classes.slider }}
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={2} md={2}  style={{marginTop: '15px'}} >
                                <b>Rotation</b>
                            </GridItem>
                            <GridItem xs={12} sm={10} md={10} style={{marginTop: '25px'}}>
                                <Slider
                                    value={rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    aria-labelledby="Rotation"
                                    classes={{ container: classes.slider }}
                                    onChange={(e, rotation) => setRotation(rotation)}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }} className={classes.modalFooter}>
                                <Button
                                    id="cancel-btn"
                                    onClick={setDefault}
                                    style={{ marginRight: "10px" }}
                                    size="sm"
                                    color="white">
                                    Default
                                </Button>
                                <Button
                                    id="submit-cover-btn"
                                    onClick={showCroppedImageCover}
                                    size="sm"
                                    color="primary">
                                    Submit
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </div>
                    </div>
                </DialogContent>
            </Dialog>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '10px' }}>
                    <h4 style={{fontWeight:'500', fontSize:'1.0rem'}}>Business Cover Theme</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '15px' }}>
                    <BusinessColorPalette props={props.props} />
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
                    <h4 style={{fontWeight:'500', fontSize:'1.0rem'}}>Business Contact</h4>
                </GridItem>
                <GridItem xs={2} sm={1} md={1} style={{ marginTop: '25px' }}>
                    <img style={{ width: '30px' }} src={phoneIcon} className={classes.iconWrapper} onError={(e) => { e.target.onError = null; e.target.src = ""; }} alt="..." />
                </GridItem>
                <GridItem xs={10} sm={3} md={3} style={{ marginTop: '30px' }}>
                    Phone :
                </GridItem>
                <GridItem xs={12} sm={8} md={8}>
                    <Field
                        id="phone-number-input"
                        name="phoneNumber"
                        component={CustomInput}
                        type="text"
                        labelText="Phone Number"
                        formControlProps={{
                            fullWidth: true
                        }}
                        maxLength={10}
                        disabled={readOnly}
                        validate={[FormValidator.validateRequired, FormValidator.validateLengthPhoneNumber]}
                        normalize={(value) => {
                            return crmValidator.handleKeyPress(value, FormValidator.form_validate.phoneNumber)
                        }}
                    />
                </GridItem>
                <GridItem xs={2} sm={1} md={1} style={{ marginTop: '25px' }}>
                    <img style={{ width: '30px' }} src={messengerIcon} className={classes.iconWrapper} onError={(e) => { e.target.onError = null; e.target.src = ""; }} alt="..." />
                </GridItem>
                <GridItem xs={10} sm={3} md={3} style={{ marginTop: '30px' }}>
                Messenger :
                </GridItem>
                <GridItem xs={12} sm={8} md={8}>
                    <Field
                        id="messenger-input"
                        name="messenger"
                        component={CustomInput}
                        type="text"
                        labelText={labelTextMessenger}//"m.me/[FacebookID] (For example : m.me/eggdigital)"
                        hintText={hintTextMessenger}
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                        normalize={(value) => {
                            return crmValidator.handleKeyPress(onInsertMessenger(value));
                        }}
                    />
                </GridItem>
                <GridItem xs={2} sm={1} md={1} style={{ marginTop: '25px' }}>
                    <img style={{ width: '30px' }} src={lineIcon} className={classes.iconWrapper} onError={(e) => { e.target.onError = null; e.target.src = ""; }} alt="..." />
                </GridItem>
                <GridItem xs={10} sm={3} md={3} style={{ marginTop: '30px' }}>
                Line :
                </GridItem>
                <GridItem xs={12} sm={8} md={8}>
                    <Field
                        id="line-input"
                        name="line"
                        component={CustomInput}
                        type="text"
                        labelText={labelTextLine}//"Line ID (For example : @eggdigital or ~lineid)"
                        hintText={hintTextLine}
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                        normalize={(value) => {
                            return crmValidator.handleKeyPress(onInsertLineID(value));
                        }}
                    />
                </GridItem>
            </GridContainer> 

            <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: '15px' }}>
                    <h4 style={{fontWeight:'500', fontSize:'1.0rem'}}>Business Information</h4>
            </GridItem>
            <GridItem xs={2} sm={1} md={1} style={{ marginTop: '25px' }}>
                    <img style={{ width: '30px' }} src={facebookIcon} className={classes.iconWrapper} onError={(e) => { e.target.onError = null; e.target.src = ""; }} alt="..." />
            </GridItem>
            <GridItem xs={10} sm={11} md={3} style={{ marginTop: '30px' }}>
                Facebook :
                </GridItem>
            <GridItem xs={12} sm={12} md={8}>
                    <Field
                        id="facebook-input"
                        name="facebook"
                        component={CustomInput}
                        labelText={"URL : https://www.facebook.com/example"}
                        type="text"
                        validate={[FormValidator.validateURL]}
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                        
                    />
            </GridItem>

            <GridItem xs={2} sm={1} md={1} style={{ marginTop: '25px' }}>
                    <img style={{ width: '30px' }} src={instagramIcon} className={classes.iconWrapper} onError={(e) => { e.target.onError = null; e.target.src = ""; }} alt="..." />
            </GridItem>
            <GridItem xs={10} sm={11} md={3} style={{ marginTop: '30px' }}>
                Instagram :
                </GridItem>
            <GridItem xs={12} sm={12} md={8}>
                    <Field
                        id="instagram-input"
                        name="instagram"
                        component={CustomInput}
                        labelText={"URL : https://www.instagram.com/example"}
                        type="text"
                        validate={[FormValidator.validateURL]}
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                    />
            </GridItem>

            <GridItem xs={2} sm={1} md={1} style={{ marginTop: '25px' }}>
                    <img style={{ width: '30px' }} src={youtubeIcon} className={classes.iconWrapper} onError={(e) => { e.target.onError = null; e.target.src = ""; }} alt="..." />
            </GridItem>
            <GridItem xs={10} sm={11} md={3} style={{ marginTop: '30px' }}>
                Youtube :
                </GridItem>
            <GridItem xs={12} sm={12} md={8}>
                    <Field
                        id="youtube-input"
                        name="youtube"
                        component={CustomInput}
                        labelText={"URL : https://www.youtube.com/channel/{channelId}"}
                        type="text"
                        validate={[FormValidator.validateURL]}
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                    />
            </GridItem>
            <GridItem xs={2} sm={1} md={1} style={{ marginTop: '25px' }}>
            <img style={{ width: '30px' }} src="assets/img/website.png" className={classes.iconWrapper} onError={(e) => { e.target.onError = null; e.target.src = ""; }} alt="..." />
            </GridItem>
            <GridItem xs={10} sm={11} md={3} style={{ marginTop: '30px' }}>
            Website :
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
            <Field
                id="youtube-input"
                name="website"
                component={CustomInput}
                labelText={"URL : https://www.example.com"}
                type="text"
                validate={[FormValidator.validateURL]}
                formControlProps={{
                    fullWidth: true
                }}
                disabled={readOnly}
            />
            </GridItem>
            </GridContainer> 
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '15px' }}>
                        <h4 style={{fontWeight:'500', fontSize:'1.0rem'}}>Business Detail</h4>
                        <Field
                            id="business-detail-input"
                            name="businessDetail"
                            component={RichEditor}
                        />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
                        <h4 style={{fontWeight:'500', fontSize:'1.0rem'}}>Business Link</h4>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: '15px' }}>
                        <span style={{color: '#0000FF'}}>{businessLink}</span> {/* Don't Forget to change param by Pikanate */}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                        <QRCode id="qr-gen-image" value={businessLink} level={"H"} includeMargin={true} size={150}/> {/* Don't Forget to change param by Pikanate */}
                        <QRCode hidden id="qr-gen" value={businessLink} level={"H"} includeMargin={true} size={250}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        id="copy-btn"
                        onClick={() => {
                            copy(businessLink) 
                            NotificationManager.success('Copied', '', 1000);
                         }}
                        style={{ marginRight: "10px" }}
                        size="sm"
                        color="primary">
                        COPY URL {/* Don't Forget to change param by Pikanate */}
                    </Button>
                    <Button
                        id="download-btn"
                        onClick={downloadQRCode}
                        size="sm"
                        color="success">
                        Download QR Code
                    </Button>
                </GridItem>
            </GridContainer>
            <NotificationContainer />
        </>
    )
}
export default BusinessInformation;
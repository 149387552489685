import { configConstants } from "../constants"
import axios from "axios"
import { history, setHeaders } from "helpers"

export const shopServices = {
  getRegionLists,
  getShopLists,
  addShopList,
  updateShopList,
  deleteShopList,
}

function getRegionLists() {
  let tmp = axios
    .get(configConstants.API_BACKEND_CRM + `/shop-region`, {
      headers: setHeaders({ 'Charset': 'utf-8' }),
    })
    .then((response) => {
      if (response.data.statusCode.toString() === "200") {
        return {
          status: true,
          data: response.data.data,
          message: response.data.message,
        }
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push("/auth/login")
        return {
          status: false,
          data: {},
          message: response.data.message,
          unAlert: true,
        }
      } else {
        return { status: false, data: {}, message: response.data.message }
      }
    })
    .catch((e) => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" }
    })

  return tmp
}

function getShopLists(params) {
  let tmp = axios
    .get(configConstants.API_BACKEND_CRM + `/shop?${params}`, {
      headers: setHeaders({ 'Charset': 'utf-8' }),
    })
    .then((response) => {
      if (response.data.statusCode.toString() === "200") {
        return {
          status: true,
          data: response.data.data,
          message: response.data.message,
        }
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push("/auth/login")
        return {
          status: false,
          data: {},
          message: response.data.message,
          unAlert: true,
        }
      } else {
        return { status: false, data: {}, message: response.data.message }
      }
    })
    .catch((e) => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" }
    })

  return tmp
}

function addShopList(params) {
  let tmp = axios
    .post(configConstants.API_BACKEND_CRM + `/shop`, params, {
      headers: setHeaders(),
    })
    .then((response) => {
      if (response.data.statusCode.toString() === "200") {
        return {
          status: true,
          data: response.data.data,
          message: response.data.message,
        }
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push("/auth/login")
        return {
          status: false,
          data: {},
          message: response.data.message,
          unAlert: true,
        }
      } else {
        return { status: false, data: {}, message: response.data.message }
      }
    })
    .catch((e) => {
      return { status: false, data: {}, message: e.message }
    })
  return tmp
}

function updateShopList(params) {
  const { _id } = params
  let tmp = axios
    .put(configConstants.API_BACKEND_CRM + `/shop/${_id}/`, params, {
      headers: setHeaders(),
    })
    .then((response) => {
      if (response.data.statusCode.toString() === "200") {
        return {
          status: true,
          data: response.data.data,
          message: response.data.message,
        }
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push("/auth/login")
        return {
          status: false,
          data: {},
          message: response.data.message,
          unAlert: true,
        }
      } else {
        return { status: false, data: {}, message: response.data.message }
      }
    })
    .catch((e) => {
      return { status: false, data: {}, message: e.message }
    })
  return tmp
}

function deleteShopList(params) {
  const { _id } = params
  let tmp = axios
    .delete(configConstants.API_BACKEND_CRM + `/shop/${_id}/`,{ headers: setHeaders()})
    .then((response) => {
      if (response.data.statusCode.toString() === "200") {
        return {
          status: true,
          data: response.data.data,
          message: response.data.message,
        }
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push("/auth/login")
        return {
          status: false,
          data: {},
          message: response.data.message,
          unAlert: true,
        }
      } else {
        return { status: false, data: {}, message: response.data.message }
      }
    })
    .catch((e) => {
      return { status: false, data: {}, message: e.message }
    })
  return tmp
}

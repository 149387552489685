import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash';

import moment from "moment";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import { alert, store, plugins } from 'helpers';
import { earnSpecialsConstants } from '../../constants';
// import EarnSpecialTypeCode from './EarnSpecialTypeCode.jsx';
// import EarnSpecialTypePurchaseAmount from './EarnSpecialTypePurchaseAmount.jsx';
// import EarnSpecialTypeProduct from './EarnSpecialTypeProduct.jsx';
import { earnSpecialsAction, memberActions } from 'actions';
import * as FormValidator from './validate/FormValidator';

// Condition
import FormPoint from "./FormCondition/FormPoint.jsx";

// Reward
import FormRewardPoint from "./FormReward/FormRewardPoint.jsx";
import Form3rdPartyCode from "./FormReward/Form3rdPartyCode.jsx";
// import FormQRCoupon from "./FormReward/FormQRCoupon";

// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import i18n from "i18n";

const FORM_NAME = "FORM_EARNSPECIALS";
const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ";

const form_validate = {
    "name": {
        specials: {
            required: true
        },
        messages: {
            required: "Please enter Special's name"
        },
        alert: "Please enter Special's name"
    },
    "status": {
        specials: {
            required: true
        },
        messages: {
            required: 'Please enter Status'
        },
        alert: "Please enter Status"
    },
    "startDate": {
        specials: {
            required: true
        },
        messages: {
            required: 'Please enter Start Date'
        },
        alert: "Please enter Start Date"
    },
    "rewardType": {
        specials: {
            required: true
        },
        messages: {
            required: 'Please enter Reward type'
        }
    },
    "pointCondition": {
        "point": {
            rules: {
               required: true,
               number: true,
               noSpecialCharacters: true,
            },
            messages: {
               required: "warning_messages.point_is_required",
               number: "warning_messages.point_equal_or_greater_than_zero",
            },
            alert: "warning_messages.point_is_required"
        },
    },
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(FormValidator.form_validate, values);
    return errors
}

const style = {
	customCardContentClass: {
		paddingLeft: "0",
		paddingRight: "0",
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px",
	},
	cardFooter: {
		padding: "15px",
		textAlign: "right",
		paddingTop: "0",
		margin: "0",
	},
	labelHorizontalCenter: {
		float: "right",
		color: "rgba(0, 0, 0, 0.26)",
		cursor: "pointer",
		display: "inline-flex",
		fontSize: "14px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		paddingTop: "39px",
		marginRight: "0",
	},
	checkboxAndRadio: {
		position: "relative",
		display: "block",
		marginTop: "10px",
		marginBottom: "10px",
	},
	checkboxAndRadioHorizontal: {
		position: "relative",
		display: "block",
		"&:first-child": {
			marginTop: "10px",
		},
		"&:not(:first-child)": {
			marginTop: "-14px",
		},
		marginTop: "0",
		marginBottom: "0",
	},
	paddingLeft32: {
		paddingLeft: "32px",
	},
	borderDashed: {
		border: "dashed 1px rgb(119 116 116)",
		padding: "20px 20px",
		width: "100%",
		margin: "15px 15px",
	},
};

const rewardExpireTypeList = [
	{ value: "day", label: "Day" },
	{ value: "hour", label: "Hour" },
	{ value: "minute", label: "Minute" },
	{ value: "second", label: "Second" },
];

class EarnSpecialsForm extends React.Component {

    handleSubmit = (values) => {
		const { dispatch } = this.props;
		// let channel = [];
		// let data = FormValidator.formatData(JSON.stringify(values));
        // console.log("handleSubmit data: ",data)

        let data = lodash.clone(values) 

		if (data.startDate) {
			data.startDate = moment(data.startDate).format(dbDateFormat);
		}

		if (data.endDate) {
			data.endDate = moment(data.endDate).format(dbDateFormat);
		}

        if(data.rewardPicture){
            data.file = data.rewardPicture
            delete data.rewardPicture
        }

		//quotaTypeCampaign
		if (lodash.get(data, "quotaTypeCampaign") === "day") {
			lodash.unset(data, "quota.campaignPerMonth");
		} else if (lodash.get(data, "quotaTypeCampaign") === "month") {
			lodash.unset(data, "quota.campaignPerDay");
			lodash.unset(data, "quota.campaignPerDayofWeek");
		}

		//quotaTypeUser
		if (lodash.get(data, "quotaTypeUser") === "day") {
			lodash.unset(data, "quota.userPerMonth");
		} else if (lodash.get(data, "quotaTypeUser") === "month") {
			lodash.unset(data, "quota.userPerDay");
			lodash.unset(data, "quota.userPerDayofWeek");
		}


		if (this.props.formMode === "add") {
            // console.log('data:',data);
			dispatch(earnSpecialsAction.addEarnSpecials(data));
		} else {
			dispatch(earnSpecialsAction.updateEarnSpecials(data));
		}
	};

    // renderFormType() {
    //     const { FORM_EARNSPECIALS, formMode } = this.props;

    //     let { readOnly } = this.props
    //     if (formMode === `add`) {
    //         readOnly = false
    //     } else if (formMode === `edit` && !readOnly) {
    //         readOnly = false
    //     } else {
    //         readOnly = true
    //     }

    //     if (!lodash.has(FORM_EARNSPECIALS, 'values.type')) {
    //         return null;
    //     } else {
    //         const formtype = FORM_EARNSPECIALS.values.type;
    //         switch (formtype) {
    //             case "code": {
    //                 return (<EarnSpecialTypeCode readOnly={readOnly} />);
    //             }
    //             case "purchaseAmount": {
    //                 return (<EarnSpecialTypePurchaseAmount readOnly={readOnly} props={this.props} FORM_NAME={FORM_NAME} />);
    //             }
    //             case "product": {
    //                 return (<EarnSpecialTypeProduct readOnly={readOnly} props={this.props} FORM_NAME={FORM_NAME} />);
    //             }
    //             default: return null;
    //         }
    //     }
    // }

    componentDidMount() {
        const { dispatch, FORM_EARNSPECIALS }  = this.props;
        // this.getProductList();
        // dispatch(earnSpecialsAction.getLuckyDrawRewardSetList())
        dispatch(memberActions.getActiveTagList());
		dispatch(memberActions.getActiveMemberLevelList());
        dispatch(
            earnSpecialsAction.getTrdPartyRewardCodeSetList({
                codeSetId: FORM_EARNSPECIALS.values.trdPartyCodeSetId,
            })
        );
    }
    render() {
        const { formOpen, formMode, classes, dispatch, handleSubmit, FORM_EARNSPECIALS, trdPartyCodeSet, formMemberLevellist, formTaglist } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        // let earnSpecialTypeList = plugins.getEarnSpecialListByPacekageFeature(package_features)

        const listRewardType = [
            { value: "3rdPartyCode", label: "3rd party Code" },
            { value: "point", label: "Add Point" }
            // ,{ value: "couponCode", label: "Coupon Code" }
        ]

        let componentReward = null;
        let selTrdPartyValue = [];
        
        // console.log('trdPartyCodeSet :',trdPartyCodeSet);

        if (lodash.has(FORM_EARNSPECIALS, "values.rewardType")) {
			if (FORM_EARNSPECIALS.values.rewardType === "3rdPartyCode") {
				if (formMode === "add") {
					lodash.forEach(trdPartyCodeSet, function (value, key) {
						if (!value.useInCampaignId) {
							let sel_value = value._id;
							let sel_label = `${value.setName} (${value.useable}/${value.total
								})`;
							selTrdPartyValue.push({
								value: sel_value,
								label: sel_label,
							});
						}
					});
				} else if (formMode === "edit" && !lodash.isEmpty(trdPartyCodeSet)) {
					selTrdPartyValue = [
						{
							value: FORM_EARNSPECIALS.values.trdPartyCodeSetId,
							label: trdPartyCodeSet.setName,
						},
					];
				}

                // console.log('selTrdPartyValue :',selTrdPartyValue);

				componentReward = (
					<Form3rdPartyCode
						props={this.props}
						selTrdPartyValue={selTrdPartyValue}
						FORM_NAME={FORM_NAME}
					/>
				);
			} else if (FORM_EARNSPECIALS.values.rewardType === "point") {
				componentReward = (
					<FormRewardPoint props={this.props} FORM_NAME={FORM_NAME} />
				);
			} 
            // else if (FORM_EARNSPECIALS.values.rewardType === "couponCode") {
			// 	componentReward = (
			// 		<FormQRCoupon props={this.props} FORM_NAME={FORM_NAME} />
			// 	);
			// }
		}

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth={"lg"}
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Special' : (readOnly ? 'Special Info' : 'Edit Special')}</b></h4>
                </DialogTitle>

                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent className={classes.modalBody}>

                        <GridContainer>
                            <GridItem xs={12} sm={6} md={8}>
                                <Field
                                    id='special-name-input'
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Name *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                                {formMode === "edit" ?
                                    <Field
                                        id='special-id-input'
                                        name="_id"
                                        component="input"
                                        type="hidden"
                                    />
                                    : null}
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='start-date-select'
                                    name="startDate"
                                    component={CustomDatePicker}
                                    type="text"
                                    maxDate={FORM_EARNSPECIALS.values.endDate}
                                    labelText="Start Date *"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='end-date-select'
                                    name="endDate"
                                    minDate={FORM_EARNSPECIALS.values.startDate}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText="End Date"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4}>
                                <Field
                                    id='special-status-select'
                                    name="status"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Status *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            value: "Active",
                                            label: "Active"
                                        },
                                        {
                                            value: "Inactive",
                                            label: "Inactive"
                                        }
                                    ]}
                                    disabled={readOnly}
                                />
                            </GridItem>

                            {/* <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='reward-type-select'
                                    name="type"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Reward Type *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={listRewardType}
                                    disabled={readOnly || formMode === "edit"} />
                            </GridItem> */}
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    name="memberLevel"
                                    component={TagsMultiSelect}
                                    labelText={i18n.t("label.memberLevel")}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    showtick
                                    optionsList={formMemberLevellist}
                                    disabled={readOnly}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    name="tagList"
                                    component={TagsMultiSelect}
                                    labelText={i18n.t("label.tag")}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    showtick
                                    optionsList={formTaglist}
                                    disabled={readOnly}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <div style={style.borderDashed}>
                                <GridItem xs={12} sm={12} md={12}>
									<legend>
										<b>{i18n.t("campaign.conditions")}</b>
									</legend>

                                    <GridContainer>
										<GridItem xs={12} sm={12} md={6}>
                                            <h6><b>Every X Point</b></h6>
                                            <Field
                                                name="pointCondition[point]"
                                                component={CustomInput}
                                                type="number"
                                                labelText={"Every X Point"}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <FormPoint
                                        props={this.props}
                                        classes={classes}
                                        FORM_EARNSPECIALS={FORM_EARNSPECIALS}
                                        FORM_NAME={FORM_NAME}
                                    />
                                </GridItem>
                            </div>
                        </GridContainer>

                        <GridContainer>
                            <div style={style.borderDashed}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <legend>
                                        <b>{i18n.t("campaign.reward")}</b>
                                    </legend>

                                    <GridContainer>
                                        <GridItem xs={12} sm={6} md={3}>
                                            <Field
                                                name="rewardType"
                                                component={CustomSelect}
                                                type="text"
                                                labelText={i18n.t("label.reward_type")}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                disabled={readOnly || formMode === "edit"}
                                                optionsList={listRewardType}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    
                                    {componentReward}
                                    
                                    {(lodash.get(FORM_EARNSPECIALS, "values.rewardType") === "3rdPartyCode"  && 
                                        <>
                                            <GridContainer>
                                                <GridItem xs={12} sm={6} md={3}>
                                                    <Field
                                                        name="rewardDetail[rewardExpireType]"
                                                        component={CustomSelect}
                                                        type="text"
                                                        labelText={"Reward Expire"}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        // disabled={readOnly || formMode === "edit"}
                                                        optionsList={rewardExpireTypeList}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={6} md={3}>
                                                    <Field
                                                        name="rewardDetail[rewardExpireNumber]"
                                                        component={CustomInput}
                                                        type="number"
                                                        labelText="Reward Expire Number"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        validate={this.rewardNumberValidate}
                                                        normalize={(value) => {
                                                            return crmValidator.handleKeyPress(
                                                                value,
                                                                FormValidator.validate_["point"].pointBurn
                                                            );
                                                        }}
                                                        disabled={readOnly}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </>
									)}
                                </GridItem>
                            </div>
                        </GridContainer>
                    </DialogContent>

                    <DialogActions
                        className={classes.modalFooter}>
                        {!readOnly ?
                            <Button
                                id='submit-btn'
                                type="submit"
                                // disabled={(FORM_EARNSPECIALS.values.type === "code" || FORM_EARNSPECIALS.values.type === "purchaseAmount" || FORM_EARNSPECIALS.values.type === "product") ? false : true}
                                style={{ marginRight: "10px" }}
                                color="primary">
                                {formMode === "edit" ? "Update" : "Submit"}
                            </Button>
                            : ''
                        }
                        <Button
                            id='cancel-btn'
                            type="button"
                            onClick={() => {
                                dispatch({ type: earnSpecialsConstants.CLOSE_FORM_EARN_SPECIALS });
                            }}
                            color="white">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }

}

function mapStateToProps(state) {
    const { formOpen, formMode, productList, allProductList, joinedProductRows, joinedProductList, allShopListSelected, productListMaster,trdPartyCodeSet, rewardImagePreview } = state.earnspecials;
    const { FORM_EARNSPECIALS } = state.form;
    const { formTaglist, formMemberLevellist } = state.member;
    const { package_features } = state.authentication
    return {
        formOpen, formMode, FORM_EARNSPECIALS, allProductList, productList, joinedProductRows, joinedProductList, allShopListSelected, productListMaster, package_features, trdPartyCodeSet, formTaglist, formMemberLevellist, rewardImagePreview
    };
}

const connectCampaignForm = connect(mapStateToProps)(EarnSpecialsForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {

        let globalStore = store.getState();
        let formMeta = globalStore.form[FORM_NAME];

        if (formMeta.values.type === "product") {
            for (let j in errors) {
                if (j == 'singleProductNameSkuSelect' || j == 'singleProductPointInput' || j == 'singleProductQuantityInput') {
                    let temp = lodash.get(errors, j)
                    if (temp.length > 0) {
                        let obj = temp.find(o => lodash.isUndefined(o) === false);
                        alert(dispatch, 'error', obj)
                    }
                }
                else {
                    // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
                }
            }
        } else {
            // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
            if (formMeta.values.type === "code" && !globalStore.alert.isShow) {
                if (lodash.has(errors, 'earnSpecialCode') && !formMeta.values.earnSpecialCode) {
                    alert(dispatch, 'error', 'Please enter Earn Special Code')//ท่านยังไม่ได้กรอก Earn Special Code กรุณากรอกข้อมูลให้ครบถ้วน
                } else if (lodash.has(errors, 'selectedProductCount')) {
                    alert(dispatch, 'error', 'Please select at least a product')
                }
            }
        }
    }
})(withStyles(modalStyle)(connectCampaignForm))
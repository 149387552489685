import { earnSpecialsServices } from '../services';
import { earnSpecialsConstants } from '../constants'
import { loader, alert as customAlert } from 'helpers';
import { change } from 'redux-form';
import i18n from 'i18n';
import lodash from 'lodash';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const earnSpecialsAction = {
    getEarnSpecialLists,
    getTrdPartyRewardCodeSetList,
    addEarnSpecials,
    updateEarnSpecials,
    getEarnSpecialById,
    deleteEarnSpecials,
};

function getEarnSpecialLists(params) {
    return dispatch => {
        // loader(dispatch, true)
        dispatch({ type: earnSpecialsConstants.SHOW_TABLE_LOADER })
        earnSpecialsServices.getEarnSpecialLists(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                // loader(dispatch, false)
                dispatch({ type: earnSpecialsConstants.HIDE_TABLE_LOADER })
            })
    }
    function success(tmp) { return { type: earnSpecialsConstants.GET_EARN_SPECIALS_DATA_SUCCESS, data: tmp } }
}

function getTrdPartyRewardCodeSetList(params) {
    return dispatch => {
        loader(dispatch, true)
        earnSpecialsServices.getTrdPartyRewardCodeSetList(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: earnSpecialsConstants.GET_TRD_PARTY_CODE_SET_SUCCESS, data } }
}

function addEarnSpecials(params) {
    return dispatch => {
        loader(dispatch, true)
        earnSpecialsServices.addEarnSpecials(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: earnSpecialsConstants.ADD_EARN_SPECIALS_SUCCESS
                        });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.campaign_was_created'));
                    } else {
                        // if (response.message === 'CAMPAIGN_ACTIVE_EXCEED_LIMIT') {
                        //     // customAlert(dispatch, 'error', 'Failed Campaign Creation', false, false, campaignActiveLimitExceedMessage)
                        //     campaignLimitationAlert(dispatch)
                        // } else {
                            if (!response.unAlert) {
                                let responseMessage = response.message.toString()
                                if (responseMessage.includes('_')) {
                                    customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                                } else {
                                    customAlert(dispatch, 'error', response.message.toString())
                                }
                            }
                        // }
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function updateEarnSpecials(params) {
    return dispatch => {
        loader(dispatch, true)
        earnSpecialsServices.updateEarnSpecials(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: earnSpecialsConstants.EDIT_EARN_SPECIALS_SUCCESS
                        });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.campaign_was_updated'));
                    } else {
                        // if (response.message === 'CAMPAIGN_ACTIVE_EXCEED_LIMIT') {
                        //     // customAlert(dispatch, 'error', 'Failed Campaign Updates', false, false, campaignActiveLimitExceedMessage)
                        //     campaignLimitationAlert(dispatch)
                        // } else {
                            if (!response.unAlert) {
                                let responseMessage = response.message.toString()
                                if (responseMessage.includes('_')) {
                                    customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                                } else {
                                    customAlert(dispatch, 'error', response.message.toString())
                                }
                            }
                        // }
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getEarnSpecialById(id) {
    return dispatch => {
        loader(dispatch, true)
        earnSpecialsServices.getEarnSpecialById(id)
            .then(
                response => {
                    if (response.status) {
                        // let selectedProductList = [];
                        const earnspecialData = response.data
                        let formInitialValues = earnspecialData

                        let imgReward = formInitialValues.rewardFilePath
                        let thirdPartyCode = formInitialValues.thirdPartyCode
                        
                   
                         dispatch({ type: earnSpecialsConstants.OPEN_EDIT_EARN_SPECIALS, 
                            data: formInitialValues,
                            rewardImagePreview: imgReward,
                            thirdPartyCode:thirdPartyCode
                         })

                        //  dispatch(change('FORM_EARNRULES', 'selectedProductCount', lodash.size(selectedProductList)))
                        
                    } else {
                        customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function deleteEarnSpecials(params) {
    return dispatch => {
        loader(dispatch, true)
        earnSpecialsServices.deleteEarnSpecials(params)
            .then(
                tmp => {
                    if (tmp.status) {
                        dispatch({ type: earnSpecialsConstants.DELETE_EARN_SPECIALS_SUCCESS });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.earn_rule_was_deleted'));
                    } else {
                        if(!tmp.unAlert) customAlert(dispatch, 'error', tmp.message.toString())
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

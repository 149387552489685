import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import lodash from 'lodash';
import moment from 'moment'

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from 'components/CustomButtons/Button'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// import Badge from "components/Badge/Badge.jsx";

import { plugins } from 'helpers';

import { dashboardServices } from 'services';
import { dashboardActions } from "actions/dashboard.actions";


const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                textAlign: "center",
                paddingRight: '24px',
                // "&:nth-child(3)": {
                //     textAlign: "center",
                //     paddingRight: '24px',
                // }
            },
            body: {
                // "&:nth-child(22)": {
                    textAlign: "center",
                    paddingRight: '24px',
                // }
            },
        },
    }
});

class TableDashboardList extends React.Component {
    
    componentDidMount(){
        const { tableFilter, dispatch } = this.props;
        let activeType = lodash.get(tableFilter, 'search.activeType')
        if (activeType === 'burn') {
            dispatch(dashboardActions.getTableBurnDatas(tableFilter));
        } else if (activeType === 'earn') {
            dispatch(dashboardActions.getTableEarnDatas(tableFilter));
        }
        dispatch({type: 'DASHBOARD_RESET_ACTIVE_USER', tableFilter })
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        var data = tableDatas;
        return data[rowIndex];
    }

    getDownloadFile(id) {
        const { formFilterActiveUser } = this.props;
        if(lodash.get(formFilterActiveUser, 'values.activeType') === 'burn'){ 
            dashboardServices.getFileDownloadBurn(id)
            .then(
                tmp => {        
                    if (lodash.get(tmp, 'status') === true) {
                        window.location.assign(tmp.link)
                    }
                }
            )
        }else if(lodash.get(formFilterActiveUser, 'values.activeType') === 'earn'){
            dashboardServices.getFileDownloadEarn(id)
            .then(
                tmp => {
                    if (lodash.get(tmp, 'status') === true) {
                        window.location.assign(tmp.link)
                    }
                }
            )
        }

    }

    render() {
        const { tableDatas, showLoader, tableFilter, visiblePersonalData } = this.props;
        const activeUser = lodash.get(tableFilter, 'search.type')
        var labelReportDate = '';
        if(activeUser === 'monthly'){
            labelReportDate = 'Monthly';
        }else if(activeUser === 'daily'){
            labelReportDate = 'Daily';
        }else if(activeUser === 'period'){
            labelReportDate = 'Period';
        }
       
        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "displayId", options: { filter: false, display: "excluded" } },
            {
                name: "reportDate", label: labelReportDate,
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.reportDate !== undefined && rowData.reportDate != null) { 
                            if ( this.props.tableFilter.search.type === 'monthly' ) {
                                return (<p className="text-nowrap">{moment(rowData.reportDate).format('MMMM YYYY')}</p>)
                            } else if ( this.props.tableFilter.search.type === 'daily' ) { 
                                return (<p className="text-nowrap">{plugins.datetimeFormat(rowData.reportDate, "date")}</p>)
                            } else if ( this.props.tableFilter.search.type === 'period' )  { 
                                if(rowData.type === "last_7_days"){ 
                                    return "7 days ago";
                                }else if(rowData.type === 'last_14_days'){
                                    return "14 days ago";
                                }else if(rowData.type === 'last_30_days'){
                                    return "30 days ago";
                                }
                            }
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "count", label: "Total User"
            },
            {
                name: "detail", label: "Detail",
                options: {
                    display: (plugins.isHasPermission("dashboard", 'export') && plugins.isHasPackageFeatures('export') && visiblePersonalData),
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return (
                            <>
                                <Button
                                    id = "btn_export_csv"
                                    variant="contained"
                                    bcolor="primary"
                                    size="sm"
                                    onClick={(e) => { 
                                        this.getDownloadFile(rowData._id)
                                    }}
                                    style={{ backgroundColor: "#84B0F3" }}
                                    disabled={lodash.get(rowData, '_id') ? false : true}
                                >
                                    Export
                                </Button>

                            </>
                        );

                    }
                }

            },
        ]

        let options = {
            serverSide: false,
            rowsPerPage: 10,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 31],
            search: false,
            viewColumns: false,
            sort: false,
            paging: false
        };

        if(tableFilter.search.type === 'period' || tableFilter.search.type === 'monthly'){
            options.customFooter = () => {
                return (<></>)
            }
            if ( this.props.tableFilter.search.type === 'monthly' ) {
                options = {
                    ...options,
                    rowsPerPage: 12,
                }    
            }
        }

        return (
            <div id="tableDashboardList">
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={showLoader?0:1}
                        data={tableDatas}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { tableDatas, showLoader, tableTotal, tableFilter } = state.dashboard;
    const { formFilterActiveUser } = state.form;
    const { visiblePersonalData } = state.authentication;
    return {
        formFilterActiveUser, tableDatas, showLoader, tableTotal, tableFilter, visiblePersonalData
    };
}
export default connect(mapStateToProps)(withStyles(pageStyle)(TableDashboardList));  
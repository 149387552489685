import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";
import { plugins, crmValidator} from 'helpers';
import { expirationActions } from 'actions';
import { expirationConstants } from '../../constants';
import PointExpirationHistory from "./PointExpirationHistory";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

const style = {

}
const THEME = createMuiTheme(MTablestyles);

class TablePointExpiration extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 0,
         rowsPerPage: 10,
         reqParams: {
            skip: 0,
            limit: 10,
            sortBy: "lastExpirationDate",
            sortDir: "desc",
            keyword: ""
         }
      }
      this.delayedCallback = lodash.debounce(this.getData, 500)
   }

   getData(skip, limit, sortBy, sortDir, keyword) {
      const { dispatch, tableReload, tableDatas, tableFilter } = this.props
      let reqParams = '';
      if (!tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            skip = 0
         }

         if (crmValidator.isEmptyValue(limit)) {
            limit = 10
         }
         this.setState({
            page: skip,
            rowsPerPage: limit
         })
         skip = parseInt(limit) * parseInt(skip)
         if(tableDatas.total !== 0 && tableDatas.total === skip){
            skip = skip - limit
            this.setState({
                page: this.state.page - 1,
            })                
         }
         if(skip >= tableDatas.total){
               skip = 0;
               this.setState({
                  page: 0,
               })
         }
         this.setState({
               reqParams: {
                  skip: skip,
                  limit: limit,
               },
         })
         reqParams += '&skip=' + skip
         reqParams += '&limit=' + limit

         if (!crmValidator.isEmptyValue(sortBy)) {
            reqParams += '&sortBy=' + sortBy
        }

        if (!crmValidator.isEmptyValue(sortDir)) {
            reqParams += '&sortDir=' + sortDir
        }

        if (!crmValidator.isEmptyValue(keyword)) {
            reqParams += '&keyword=' + encodeURIComponent(keyword)
        }
    } else {
        reqParams = this.state.reqParams
    }
      dispatch(expirationActions.getTableExpirationDatas(reqParams+tableFilter.search));
      dispatch({ type: expirationConstants.SET_ROW_PER_PAGE, rowsPerPage: limit })
      this.setState({
         reqParams: reqParams,
         rowsPerPage: tableFilter.limit
      });
   }

   getRowData(rowIndex = 0) {
      const { tableDatas } = this.props;
      const data = tableDatas.data;
      return data[rowIndex];
   }

   openHistoryPointExpiration = (_id) => {
      const { dispatch } = this.props;
      let paramUserId =  'userId='+_id;
      dispatch(expirationActions.getHistoryExpirationById(paramUserId));
      dispatch({ type: expirationConstants.OPEN_HISTORY_POPUP, id: _id })
      

   }

   render() {
      const { classes, tableDatas, tableFilter, tableKey, tableLoader } = this.props;
      const columns = [
         {
            name: "lastExpirationDate",
            label: "Last Point Expire Date",
            options: {
               filter: false,
               customBodyRender: (value, tableMeta) => {
                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  return (rowData.lastExpirationDate ? plugins.datetimeFormat(rowData.lastExpirationDate, "date") : "");
               }
            }
         },
         { name: "lastExpirationPoint", label: "Point Expire Amount" },
         {
            name: "name",
            label: "Name",
            options: {
               filter: false,
               customBodyRender: (value, tableMeta) => {
                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  return rowData.firstName+" "+rowData.lastName
               }
            }
         },
         {
            name: "phoneNumber",
            label: "Mobile Number",
         },
         {
            name: "point",
            label: "Current Point",
         },
         {
            name: "nextExpirationDate",
            label: "Next Point Expire Date",
            options: {
               filter: false,
               customBodyRender: (value, tableMeta) => {
                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  return (rowData.nextExpirationDate ? plugins.datetimeFormat(rowData.nextExpirationDate, "date") : "");
               }
            }
         },
         {
            name: "nextPointExpire",
            label: "Next Point Expire Amount",
         },
         {
            name: "pointUsage", label: "Point Expired History", options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta) => {
                  const rowData = this.getRowData(tableMeta.rowIndex);
                   return (
                       <div className={classes.actionDiv}><center>
                           <TableActionButton
                              id='btn-history-popup'
                              actionType="history"
                              onClick={(e) => {
                                 this.openHistoryPointExpiration(rowData._id)
                              }}
                              disabled={!rowData.hasHistory}
                           /></center>
                       </div>
                   )
               }
            }
         },
      ]
      const options = {
         serverSide: true,
         count: (tableDatas.total ? tableDatas.total : 0),
         page: this.state.page,
         rowsPerPage: tableFilter.limit,
         fixedHeader: true,
         selectableRows: "none",
         print: false,
         download: false,
         filter: false,
         responsive: "scroll",
         rowsPerPageOptions: [5, 10, 20],
         onTableChange: (action, tableState) => {

            let skip, limit, sortBy, sortDir, keyword = ""

            if (tableState.page !== null) {
               skip = tableState.page
            }

            if (tableState.rowsPerPage !== null) {
               limit = tableState.rowsPerPage
            }

            if (tableState.activeColumn !== null) {
               sortBy = tableState.columns[tableState.activeColumn].name
            }
            if (tableState.announceText !== null) {
               sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
            }
            if (tableState.searchText !== null || action === 'search') {
               keyword = tableState.searchText
               this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
            }
            let actionList = ['changePage', 'sort', 'changeRowsPerPage']
            if (actionList.indexOf(action) !== -1) {
               this.getData(skip, limit, sortBy, sortDir, keyword)
            }
         }
      };
      return (
         <div id="tablePointExpirationReport">
            <CustomSweetAlert />
            <PointExpirationHistory />
            <MuiThemeProvider theme={THEME}>
               <MUIDataTable
                  key={tableKey}
                  data={(tableDatas.data ? tableDatas.data : [])}
                  columns={columns}
                  options={options}
               />
               {tableLoader && <TableLoaderSpinner />}
            </MuiThemeProvider>
         </div>
      )
   }
}

function mapStateToProps(state) {
   const { visiblePersonalData, merchantSetting } = state.authentication;
   const { tableDatas, tableTotal, tableFilter, tableKey, tableLoader } = state.expiration
   return {
      visiblePersonalData, merchantSetting, tableDatas, tableTotal, tableFilter, tableKey, tableLoader
   };
}

export default connect(mapStateToProps)(withStyles(style)(TablePointExpiration));
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { crmValidator, plugins, address } from 'helpers';
import lodash from 'lodash';

// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import FormLabel from '@material-ui/core/FormLabel';

import { shipmentActions } from 'actions';
import { shipmentConstants } from '../../constants';
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"

const FORM_NAME = "shipment_form";

var form_validate = {
    "reason": {
        rules: {
            required: true,
        },
        messages: {
            required: 'กรุณากรอกเหตุผลการยกเลิก',
        },
        alert: "ท่านยังไม่ได้กรอกเหตุผล กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "shippedDate": {
        rules: {
            required: true,
        },
        messages: {
            required: 'กรุณากรอกวันที่จัดส่ง',
        },
        alert: "ท่านยังไม่ได้กรอกวันที่จัดส่ง กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "shippingName": {
        rules: {
            required: true,
        },
        messages: {
            required: 'กรุณากรอกบริษัทขนส่ง',
        },
        alert: "ท่านยังไม่ได้กรอกบริษัทขนส่ง กรุณากรอกข้อมูลให้ครบถ้วน"
    },
    "othersShippingCompany": {
        rules: {
            required: true,
        },
        messages: {
            required: 'กรุณากรอกบริษัทขนส่ง',
        },
        alert: "ท่านยังไม่ได้กรอกบริษัทขนส่ง กรุณากรอกข้อมูลให้ครบถ้วน"
    },
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}


class ShipmentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            setValue: "",
            showGridRadioShipped: false,
            showGridRadioCancelled: false,
            fieldDisable: true,
            shippingList: [],
            waitingRadioDisable: false,
            receiverNameForEdit: "",
            othersShipComp: ""
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.formOpen === true && prevProps.formOpen === false) {
            const { dispatch, formInitialValue } = this.props;
            formInitialValue._id = this.props.formInitialValue._id ? this.props.formInitialValue._id : "";
            formInitialValue.shippingId = this.props.formInitialValue.shippingId ? this.props.formInitialValue.shippingId : "";
            formInitialValue.address = {
                firstName: this.props.formInitialValue.address && this.props.formInitialValue.address.firstName ? this.props.formInitialValue.address.firstName : "",
                lastName: this.props.formInitialValue.address && this.props.formInitialValue.address.lastName ? this.props.formInitialValue.address.lastName : "",
                phone: this.props.formInitialValue.address && this.props.formInitialValue.address.phone ? this.props.formInitialValue.address.phone : "",
                address: this.props.formInitialValue.address && this.props.formInitialValue.address.address ? this.props.formInitialValue.address.address : "",
                subDistrict: this.props.formInitialValue.address && this.props.formInitialValue.address.subDistrict ? this.props.formInitialValue.address.subDistrict : "",
                district: this.props.formInitialValue.address && this.props.formInitialValue.address.district ? this.props.formInitialValue.address.district : "",
                province: this.props.formInitialValue.address && this.props.formInitialValue.address.province ? this.props.formInitialValue.address.province : "",
                zipcode: this.props.formInitialValue.address && this.props.formInitialValue.address.zipcode ? this.props.formInitialValue.address.zipcode : ""
            };
            formInitialValue.member = {
                id: this.props.formInitialValue.member && this.props.formInitialValue.member.id ? this.props.formInitialValue.member.id : "",
                firstName: this.props.formInitialValue.member && this.props.formInitialValue.member.firstName ? this.props.formInitialValue.member.firstName : "",
                lastName: this.props.formInitialValue.member && this.props.formInitialValue.member.lastName ? this.props.formInitialValue.member.lastName : "",
                phone: this.props.formInitialValue.member && this.props.formInitialValue.member.phone ? this.props.formInitialValue.member.phone : "",
                email: this.props.formInitialValue.member && this.props.formInitialValue.member.email ? this.props.formInitialValue.member.email : ""
            };
            formInitialValue.trackingNo = this.props.formInitialValue.trackingNo ? this.props.formInitialValue.trackingNo : "";
            formInitialValue.shippingStatus = this.props.formInitialValue.status ? this.props.formInitialValue.status : "";
            formInitialValue.campaign = {
                id: this.props.formInitialValue.campaign && this.props.formInitialValue.campaign.id ? this.props.formInitialValue.campaign.id : "",
                name: this.props.formInitialValue.campaign && this.props.formInitialValue.campaign.name ? this.props.formInitialValue.campaign.name : "",
                title: this.props.formInitialValue.campaign && this.props.formInitialValue.campaign.title ? this.props.formInitialValue.campaign.title : ""
            };
            formInitialValue.reward = {
                name: this.props.formInitialValue.reward && this.props.formInitialValue.reward.name ? this.props.formInitialValue.reward.name : ""
            };
            formInitialValue.shippedDate = this.props.formInitialValue.shippedDate ? plugins.datetimeFormat(this.props.formInitialValue.shippedDate, "datetime") : "";
            formInitialValue.shipping = {
                name: this.props.formInitialValue.shipping && this.props.formInitialValue.shipping.name ? this.props.formInitialValue.shipping.name : "",
                displayName: this.props.formInitialValue.shipping && this.props.formInitialValue.shipping.displayName ? this.props.formInitialValue.shipping.displayName : ""
            };
            formInitialValue.transaction = {
                id: this.props.formInitialValue.transaction && this.props.formInitialValue.transaction.id ? this.props.formInitialValue.transaction.id : "",
                date: this.props.formInitialValue.transaction && this.props.formInitialValue.transaction.date ? plugins.datetimeFormat(this.props.formInitialValue.transaction.date, "datetime") : "",
            };
            formInitialValue.reason = this.props.formInitialValue.reason ? this.props.formInitialValue.reason : "";
            formInitialValue.note = this.props.formInitialValue.note ? this.props.formInitialValue.note : "";
            if (this.props.formInitialValue.shipping && this.props.formInitialValue.shipping.displayName && this.props.formInitialValue.shipping.name !== "Others") {
                formInitialValue.othersShippingCompany = "";
            } else {
                formInitialValue.othersShippingCompany = this.props.formInitialValue.shipping && this.props.formInitialValue.shipping.displayName ? this.props.formInitialValue.shipping.displayName : "";
            }
            formInitialValue.shippingName = this.props.formInitialValue.shipping && this.props.formInitialValue.shipping.name ? this.props.formInitialValue.shipping.name : "";
            const { zipcode, province, district, subDistrict } = formInitialValue.address
            if (province) {
                formInitialValue.province = province;
                this.handleProvinceChange(province, zipcode)
                dispatch(change(FORM_NAME, 'district', district))

            } else {
                this.setState({
                    districtOptionsList: []
                })
            }

            if (district) {
                this.handleDistrictChange(district, zipcode)
                dispatch(change(FORM_NAME, 'subDistrict', subDistrict))
            } else {
                this.setState({
                    subDistrictOptionsList: []
                })
            }
            if (formInitialValue.status !== undefined || formInitialValue.status != null) {
                if (formInitialValue.status === "WAITING") {
                    this.setState({
                        value: 'WAITING',
                        waitingRadioDisable: false
                    });
                } else if (formInitialValue.status === "SHIPPED") {
                    this.setState({
                        value: "SHIPPED",
                        waitingRadioDisable: true
                    });

                } else if (formInitialValue.status === "CANCELLED") {
                    this.setState({
                        value: "CANCELLED",
                        waitingRadioDisable: true
                    });
                }
            }

        }
    }

    handleSubmited = (values) => {
        const { dispatch, formInitialValue } = this.props
        var reqParams = {
            "shippingId": formInitialValue.shippingId,
            "address": {
                "firstName": values.address.firstName,
                "lastName": values.address.lastName,
                "email": "",
                "phone": values.address.phone,
                "address": values.address.address,
                "subDistrict": values.subDistrict,
                "district": values.district,
                "province": values.province,
                "zipcode": values.address.zipcode
            },
            "status": this.state.value,
            "shippedDate": this.state.value !== "SHIPPED" ? null : values.shippedDate,
            "shipping": {
                "name": this.state.value !== "SHIPPED" ? null : values.shippingName,
                "displayName": this.state.value !== "SHIPPED" ? null : values.shippingName
            },
            "trackingNo": this.state.value !== "SHIPPED" ? null : values.trackingNo,
            "reason": this.state.value === "CANCELLED" ? values.reason : null,
            "note": values.note
        }
        if (values.shippingName === "Others" && this.state.value !== "CANCELLED") {
            reqParams.shipping = {
                "name": "Others",
                "displayName": values.othersShippingCompany
            }
        }

        console.log("reqParams = ", reqParams);
        if (this.props.formMode === "edit") {
            dispatch(shipmentActions.editShipment(reqParams));
        }
        this.setState({
            fieldDisable: true,
            waitingRadioDisable: false
        })
    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    }

    formatSelectDropdown = (value) => {
        return value.map((data) => {
            return {
                label: data,
                value: data
            }
        })
    }

    handleProvinceChange = (province, zipcode) => {
        const { dispatch } = this.props
        if (province) {
            let districtList = address.getDistrict(province, zipcode)
            let DistrictInProvince = address.getDistrict(province)
            let optionsList = this.formatSelectDropdown(DistrictInProvince)
            this.setState({
                districtOptionsList: optionsList
            })
            if (lodash.size(districtList) > 0) {
                dispatch(change(FORM_NAME, 'district', districtList[0]))
                this.handleDistrictChange(districtList[0], zipcode)
            }
            if (!zipcode) {
                dispatch(change(FORM_NAME, 'address.zipcode', ''))
                dispatch(change(FORM_NAME, 'district', ''))
                dispatch(change(FORM_NAME, 'subDistrict', ''))
                this.setState({
                    subDistrictOptionsList: []
                })
            }
        } else {
            this.setState({
                districtOptionsList: [],
                subDistrictOptionsList: []
            })
        }
    }

    handleDistrictChange = (district, zipcode) => {
        const { dispatch } = this.props
        if (district) {
            let subDistrictList = address.getSubDistrict(district, zipcode)
            let subDistrictInDistrict = address.getSubDistrict(district)
            let optionsList = this.formatSelectDropdown(subDistrictInDistrict)
            this.setState({
                subDistrictOptionsList: optionsList
            })
            if (lodash.size(subDistrictList) > 0) {
                dispatch(change(FORM_NAME, 'subDistrict', subDistrictList[0]))
            }
            if (!zipcode) {
                dispatch(change(FORM_NAME, 'address.zipcode', ''))
                dispatch(change(FORM_NAME, 'subDistrict', ''))
            }
        } else {
            this.setState({
                subDistrictOptionsList: []
            })
        }
    }

    handleSubDistrictChange = (subDistrict) => {
        const { dispatch, shipment_form } = this.props
        const { values } = shipment_form
        const { province, district } = values
        let zipcode = address.getZipcode(province, district, subDistrict)
        if (zipcode) {
            dispatch(change(FORM_NAME, 'address.zipcode', zipcode))
        }
    }

    openEditShipmentReceiver = () => {
        this.setState({
            fieldDisable: false
        })
    }

    addresslabel = () => {
        const { dispatch, formInitialValue } = this.props
        var qryParams = {
            "id": formInitialValue.shippingId ? formInitialValue.shippingId : ""
        }
        console.log("qryParams = ", qryParams);
        dispatch(shipmentActions.getaddresslabelDetail(qryParams));
    }

    handleshippingCompanyChange = (shippingCompany) => {
        if (shippingCompany === "Others") {
            this.setState({
                showOthersShippingCompanyInput: true,
                othersShipComp: shippingCompany
            })
        } else {
            this.setState({
                showOthersShippingCompanyInput: false,
                othersShipComp: shippingCompany
            })
        }
    }


    render() {
        const { classes, formOpen = false, handleSubmit, shipment_form, dispatch, merchantSetting } = this.props
        let { readOnly } = this.props
        let startMaxDateShipped = new Date();

        let shippingList = [];
        shippingList.push(
            {
                value: "Aden",
                label: "Aden"
            },
            {
                value: "Alpha Fast",
                label: "Alpha Fast"
            },
            {
                value: "CJ Logistics",
                label: "CJ Logistics"
            },
            {
                value: "Deliveree",
                label: "Deliveree"
            },
            {
                value: "DHL",
                label: "DHL"
            },
            {
                value: "FedEX",
                label: "FedEX"
            },
            {
                value: "Flash Express",
                label: "Flash Express"
            },
            {
                value: "IT Transport",
                label: "IT Transport"
            },
            {
                value: "J&T Express",
                label: "J&T Express"
            },
            {
                value: "Kerry Express",
                label: "Kerry Express"
            },
            {
                value: "LALAMOVE",
                label: "LALAMOVE"
            },
            {
                value: "MrSpeedy",
                label: "MrSpeedy"
            },
            {
                value: "NINJA VAN",
                label: "Ninja Van"
            },
            {
                value: "SCG Yamato Express",
                label: "SCG Yamato Express"
            },
            {
                value: "SendIT",
                label: "SendIT"
            },
            {
                value: "Thailand Post",
                label: "Thailand Post"
            },
            {
                value: "TNT",
                label: "TNT"
            },
            {
                value: "361 Express",
                label: "361 Express"
            },
            {
                value: "Others",
                label: "Others"
            },

        )

        return (
            <>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={formOpen}
                    keepMounted
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                    fullWidth={true}
                    maxWidth="md"
                    scroll="body"
                    disableRestoreFocus={true}
                >


                    <DialogTitle
                        id="dialog-form-shipment-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <h4 className={classes.modalTitle}>
                            <b>Shipment Details</b>
                        </h4>
                    </DialogTitle>
                    {/* <br/>
                    <br/> */}
                    <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmited.bind(this))}>
                        <DialogContent
                            id="shipment_form_dialog"
                            className={classes.modalBody}
                            style={{ paddingTop: "0px" }}
                        >
                            {/* {merchantSetting.shippingLabel &&
                                <Button onClick={this.addresslabel}
                                    style={{
                                        backgroundColor: "#0C9AFC",
                                        marginTop: "20px",
                                        width: "160px",
                                        float: "right",
                                        marginRight: "10px"
                                    }}
                                    id='btn_address_label'
                                >
                                    Address Label
                            </Button>} */}
                            <GridContainer>
                                <GridItem xs={12} sm={3} >
                                    <Field
                                        name="member.firstName"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Member first Name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3} >
                                    <Field
                                        name="member.lastName"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Member Last Name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} >
                                    <Field
                                        id='transaction_date'
                                        name="transaction.date"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Redemption Date"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} >
                                    <Field
                                        name="campaign.name"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Campaign"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} >
                                    <Field
                                        name="reward.name"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Reward Name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />
                                </GridItem>
                            </GridContainer>
                            <hr style={{ border: "dashed 1px #ccc" }} />

                            <GridContainer>
                                <GridItem xs={12} sm={3} >
                                    <Field
                                        name="address.firstName"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Receiver First Name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={this.state.fieldDisable}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3} >
                                    <Field
                                        name="address.lastName"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Receiver Last Name"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={this.state.fieldDisable}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={5} >
                                    <Field
                                        name="address.phone"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Phone Number"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={this.state.fieldDisable}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={1} >
                                    <div className={classes.actionDiv} style={{ float: "right" }} >
                                        <TableActionButton
                                            id='btn_edit_form'
                                            actionType="edit"
                                            onClick={(e) => {
                                                this.openEditShipmentReceiver()
                                            }} />
                                    </div>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={6} >
                                    <Field
                                        name="address.address"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Address"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={this.state.fieldDisable}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={3}>
                                    <Field
                                        name="address.zipcode"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Zipcode"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        onChange={(e) => {
                                            let zipcodeValue = e.target.value;
                                            e.target.value = zipcodeValue

                                            if (zipcodeValue.length >= 5) {
                                                let searchAddressResult = address.getAddress(zipcodeValue)
                                                if (lodash.size(searchAddressResult.province) > 0) {
                                                    dispatch(change(FORM_NAME, 'province', searchAddressResult.province[0]))
                                                    this.handleProvinceChange(searchAddressResult.province[0], zipcodeValue)
                                                }
                                                if (lodash.size(searchAddressResult.district) > 0) {
                                                    dispatch(change(FORM_NAME, 'district', searchAddressResult.district[0]))
                                                    this.handleDistrictChange(searchAddressResult.district[0], zipcodeValue)
                                                }
                                            } else {
                                                dispatch(change(FORM_NAME, 'province', ''))
                                                dispatch(change(FORM_NAME, 'district', ''))
                                                dispatch(change(FORM_NAME, 'subDistrict', ''))
                                                this.setState({
                                                    districtOptionsList: [],
                                                    subDistrictOptionsList: []
                                                })
                                            }
                                        }}
                                        disabled={this.state.fieldDisable}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3} >
                                    <Field
                                        name="province"
                                        component={CustomSelect}
                                        type="text"
                                        labelText="Province"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        onChange={(e) => {
                                            this.handleProvinceChange(e.target.value)
                                        }}
                                        disabled={this.state.fieldDisable}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3} >
                                    <Field
                                        name="district"
                                        component={CustomSelect}
                                        type="text"
                                        labelText="District"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        optionsList={this.state.districtOptionsList}
                                        onChange={(e) => {
                                            this.handleDistrictChange(e.target.value)
                                        }}
                                        disabled={this.state.fieldDisable}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={3} >
                                    <Field
                                        name="subDistrict"
                                        component={CustomSelect}
                                        type="text"
                                        labelText="Sub-District"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        onChange={(e) => {
                                            this.handleSubDistrictChange(e.target.value)
                                        }}
                                        optionsList={this.state.subDistrictOptionsList}
                                        disabled={this.state.fieldDisable}
                                    />
                                </GridItem>
                            </GridContainer>

                            <hr style={{ border: "dashed 1px #ccc" }} />
                            <FormLabel component="legend">Shipping Status</FormLabel>
                            <Field
                                name="shippingStatus"
                                component={CustomRadio}
                                disabled={readOnly}
                                radioLists={[
                                    {
                                        value: "WAITING",
                                        label: "Waiting for shipment",
                                        disabled: this.state.waitingRadioDisable
                                    },
                                    {
                                        value: "SHIPPED",
                                        label: "Shipped",
                                    },
                                    {
                                        value: "CANCELLED",
                                        label: "Cancelled",
                                    }
                                ]}
                                onChange={this.handleChange}
                                value={this.state.value}
                            />

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    {lodash.get(shipment_form, 'values[shippingStatus]') === "SHIPPED" ?
                                        <DialogContent >
                                            <GridContainer >
                                                <GridItem xs={6} sm={6}>
                                                    <Field
                                                        name="shippedDate"
                                                        component={CustomDatePicker}
                                                        type="text"
                                                        maxDate={startMaxDateShipped}
                                                        labelText="Shipped Date"
                                                        dateFormat="DD-MMM-YY"
                                                        timeFormat="HH:mm"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer >
                                                <GridItem xs={6} sm={6}>
                                                    <Field
                                                        name="shippingName"
                                                        component={CustomSelect}
                                                        type="text"
                                                        labelText="Shipping Company"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        optionsList={shippingList}
                                                        onChange={(e) => {
                                                            this.handleshippingCompanyChange(e.target.value)
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            {lodash.get(shipment_form, 'values[shippingName]') === "Others" ?
                                                <DialogContent >
                                                    <GridContainer >
                                                        <GridItem xs={6} sm={6}>
                                                            <Field
                                                                name="othersShippingCompany"
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText="Others Shipping Company"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}

                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </DialogContent>
                                                : null}
                                            <GridContainer >
                                                <GridItem xs={6} sm={6}>
                                                    <Field
                                                        name="trackingNo"
                                                        component={CustomInput}
                                                        type="text"
                                                        labelText="Tracking No."
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </DialogContent>
                                        : null}

                                    {/* {this.state.showGridRadioCancelled ? */}
                                    {lodash.get(shipment_form, 'values[shippingStatus]') === "CANCELLED" ?
                                        <DialogContent >
                                            <GridContainer >
                                                <GridItem xs={6} sm={6}>
                                                    <Field
                                                        name="reason"
                                                        component={CustomInput}
                                                        type="text"
                                                        labelText="Cancellation Reason"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        </DialogContent>
                                        : null}
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Field
                                        name="note"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Note"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>

                            </GridContainer>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter}>
                            {!readOnly ?
                                <Button
                                    id='btn_submit_form'
                                    type="submit"
                                    style={{ marginRight: "10px" }}
                                    color="primary">
                                    Submit
                        </Button>
                                : ''
                            }
                            <Button
                                id='btn_cancel_form'
                                type="button"
                                onClick={() => {
                                    dispatch({ type: shipmentConstants.CLOSE_FORM_SHIPMENT });
                                    this.setState({
                                        fieldDisable: true,
                                        waitingRadioDisable: false
                                    })
                                }}
                                color="white">
                                Cancel
                    </Button>
                        </DialogActions>
                    </form>
                </Dialog>

            </>
        )
    }

}

function mapStateToProps(state) {
    const { formOpen, formMode, formTaglist, formInitialValue } = state.shipment;
    const { shipment_form } = state.form;
    const { merchantSetting } = state.authentication;
    const { shippingReport } = state.report;
    return {
        formOpen, formMode, formTaglist, shipment_form, formInitialValue, shippingReport, merchantSetting
    };
}

const connecShipmentForm = connect(mapStateToProps)(ShipmentForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        console.log("error = ", errors);
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connecShipmentForm))
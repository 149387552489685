export const saleProductGroupConstants = {
    OPEN_FORM_ADD: 'OPEN_FORM_ADD_SALEPRODUCTGROUP',
    OPEN_FORM_EDIT_SALEPRODUCTGROUP: 'OPEN_FORM_EDIT_SALEPRODUCTGROUP',
    CLOSE_FORM_SALEPRODUCTGROUP: 'CLOSE_FORM_SALEPRODUCTGROUP',
    ADD_SALEPRODUCTGROUP_SUCCESS: 'ADD_SALEPRODUCTGROUP_SUCCESS',
    GET_SALEPRODUCTGROUP_DATA_SUCCESS: 'GET_SALEPRODUCTGROUP_DATA_SUCCESS',
    TABLE_GET_LIST_SALEPRODUCTGROUP: "TABLE_GET_LIST_SALEPRODUCTGROUP",
    TABLE_RELOAD_SUCCESS: "TABLE_RELOAD_SUCCESS",
    TABLE_RESET_SUCCESS: "TABLE_RESET_SUCCESS",
    DELETE_SALEPRODUCTGROUP_SUCCESS: "DELETE_SALEPRODUCTGROUP_SUCCESS",
    EDIT_SALEPRODUCTGROUP_SUCCESS: "EDIT_SALEPRODUCTGROUP_SUCCESS",
    SET_FORM_PREVIEW_IMAGE : "SET_FORM_PREVIEW_IMAGE"
};
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, touch } from 'redux-form';
import lodash from 'lodash';

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import RichEditor from "components/RichEditor/RichEditor.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { upload_3rd_partyConstants } from '../../constants';
import { uploadActions } from 'actions';
import { crmValidator } from 'helpers';

const validateRequiredImage = imageFile => {
   if (imageFile === null || imageFile === undefined || imageFile === "") {
      return "กรุณาเลือกรูปภาพ"
   }
}
const NotValidateRequiredImage = imageFile => {
   return "";
}
const validMimeType = "image/jpeg, image/png, image/gif, image/svg+xml";
const validateImageFormat = imageFile => {
   if (imageFile) {
      if (!validMimeType.includes(imageFile.type)) {
         return `รองรับนามสกุลไฟล์ jpeg, png, gif หรือ svg เท่านั้น `;
      }
   }
}
const FORM_NAME = "FORM_UPLOAD_3RD_PARTY";
const form_validate = {
   "setName": {
      rules: {
         required: true
      },
      messages: {
         required: 'กรุณากรอก'
      },
      alert: "Reward Code Name is required."
   },
   "setTitle": {
      rules: {
         required: true
      },
      messages: {
         required: 'กรุณากรอก'
      },
      alert: "Reward Code Title is required."
   },
   "status": {
      rules: {
         required: true
      },
      messages: {
         required: 'กรุณาเลือก'
      },
      alert: "Status is required."
   },
   "expired_at": {
      rules: {
         required: true
      },
      messages: {
         required: 'กรุณากรอก'
      },
      alert: "กรุณาใส่วันที่"
   }
};

const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
   },
   cardFooter: {
      padding: "15px",
      textAlign: "right",
      paddingTop: "0",
      margin: "0"
   },
   labelHorizontalCenter: {
      float: "right",
      color: "rgba(0, 0, 0, 0.26)",
      cursor: "pointer",
      display: "inline-flex",
      fontSize: "14px",
      lineHeight: "1.428571429",
      fontWeight: "400",
      paddingTop: "39px",
      marginRight: "0",
   }
};

const validate = (values) => {
   const errors = crmValidator.validateByRulesList(form_validate, values);
   return errors
}

class UploadForm extends React.Component {
   constructor(props) {
      super(props);
      this.state = {

      }
   }

   handleSubmit = (values) => {
      const { dispatch, merchant } = this.props;
      let data = lodash.clone(values)  
      data.merchantId = merchant._id;

      // console.log('handleSubmit data:',data);
      // console.log('merchant._id:',merchant._id);
      if (this.props.formMode === "add") {
         dispatch(uploadActions.addUpload3rdPartyByMerchant(data));
      } else {
         dispatch(uploadActions.updateUpload3rdPartyByMerchant(data));
      }
   }

   handleClose = (values) => {
      const { dispatch } = this.props;
      dispatch({ type: upload_3rd_partyConstants.CLOSE_FORM });
   }

   render() {
      const { formOpen, formMode, classes, ImagePreview, handleSubmit, dispatch } = this.props;
      let { readOnly } = this.props
      if (formMode === `add`) {
         readOnly = false
      } else if (formMode === `edit` && !readOnly) {
         readOnly = false
      } else {
         readOnly = true
      }

      const displayCard = formOpen ? { padding: "15px" } : { display: "none" }

      // console.log('merchant :',merchant);

      // let merchantSelectOption = [];
      // lodash.forEach(merchantLists, function (value) {
      //    merchantSelectOption.push({
      //       "label": value.nameEN,
      //       "value": value._id
      //    })
      // });

      return (
         <GridContainer justify="center">
            <GridItem xs={12} sm={9} md={9}>
               <Card style={displayCard}>
                  <CardHeader icon>
                     <h4 className={classes.cardIconTitle} style={{ marginLeft: "5px" }}>
                        <b>3rd Party Reward Code ({formMode === 'add' ? 'Add Reward Code' : (readOnly ? 'Upload Info' : 'Edit Reward Code')})</b>
                     </h4>
                  </CardHeader>
                  <CardBody>
                     <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                        <Field
                           name="setId"
                           component="input"
                           type="hidden"
                        />

                        <GridContainer>
                           <GridItem xs={6} sm={6} md={6}>
                              <Field
                                 name="setName"
                                 component={CustomInput}
                                 type="text"
                                 labelText="Reward Code Name"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 // normalize={(value)=>{
                                 //    return normalize.productPrefix(value)
                                 // }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={6} sm={6} md={6}>
                              <Field
                                 name="setTitle"
                                 component={CustomInput}
                                 type="text"
                                 labelText="Reward Display Name"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 // normalize={(value)=>{
                                 //    return normalize.productPrefix(value)
                                 // }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                        </GridContainer>

                        <GridContainer>
                           <GridItem xs={12} sm={12} md={12}>
                              <Field
                                 name="description"
                                 component={CustomInput}
                                 type="text"
                                 labelText="Reward Code Description"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 // normalize={(value)=>{
                                 //    return normalize.productPrefix(value)
                                 // }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                        </GridContainer>

                        <GridContainer>
                           <GridItem xs={6} sm={6} md={6}>
                              <Field
                                 name="expired_at"
                                 component={CustomDatePicker}
                                 type="text"
                                 labelText="Last Date Used"
                                 dateFormat="DD-MMM-YY"
                                 timeFormat="HH:mm"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 // disabled={lodash.has(FORM_UPLOAD_3RD_PARTY.values, 'expired_at') && FORM_UPLOAD_3RD_PARTY.values.expired_at !== "" ? false : true}
                              />
                           </GridItem>
                           <GridItem xs={6} sm={6} md={6}>
                              {/* <Field
                                 name="merchantId"
                                 type="text"
                                 labelText="Merchant"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                              /> */}
                              <Field
                                 name="merchantId"
                                 component="input"
                                 type="hidden"
                              />
                           </GridItem>
                        </GridContainer>

                        <GridContainer>
                           <GridItem xs={12} sm={12} md={12}>
                              <Field
                                 name="termAndCondition"
                                 component={RichEditor}
                                 labelText="Term and Condition"
                              />
                           </GridItem>
                        </GridContainer>

                        <GridContainer>
                           <GridItem xs={12} sm={12} md={12}>
                              <Field
                                 name="uploadImage"
                                 component={ImageUpload}
                                 type="text"
                                 labelText="Reward Picture"
                                 textLeft={true}
                                 // imgStype={{ width: "480px", height: "320px" }}
                                 // width={(480 / 2)}
                                 height={190}
                                 showRatioGuide={true}
                                 // recommendWidth={480}
                                 // recommendHeight={320}
                                 onChange={(e) => {
                                    e.preventDefault();
                                    let reader = new FileReader();
                                    let file = e.target.files[0];
                                    reader.onloadend = () => {
                                       dispatch({ type: upload_3rd_partyConstants.SET_FORM_PREVIEW_IMAGE, ImagePreview: reader.result })
                                    };
                                    try {
                                       if (!validMimeType.includes(file.type)) {
                                          dispatch(touch(FORM_NAME, 'uploadImage'));
                                       }
                                       reader.readAsDataURL(file);
                                       dispatch(change(FORM_NAME, 'uploadImage', file))
                                    } catch (error) {
                                       dispatch({ type: upload_3rd_partyConstants.SET_FORM_PREVIEW_IMAGE, ImagePreview: "default" })
                                       dispatch(change(FORM_NAME, 'uploadImage', ''))
                                    }
                                 }}
                                 imagePreviewUrl={ImagePreview}
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={[
                                    validateImageFormat,
                                    (formMode === "add" ? validateRequiredImage : NotValidateRequiredImage),
                                    NotValidateRequiredImage
                                 ]}
                                 disabled={readOnly}
                              />
                           </GridItem>
                        </GridContainer>

                        <GridContainer>
                           <GridItem xs={6} sm={6} md={6}>
                              <Field
                                 name="status"
                                 component={CustomSelect}
                                 type="text"
                                 labelText="Status"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 optionsList={[
                                    {
                                       value: "Active",
                                       label: "Active"
                                    },
                                    {
                                       value: "Inactive",
                                       label: "Inactive"
                                    }
                                 ]}
                                 disabled={readOnly}
                              />
                           </GridItem>
                        </GridContainer>

                        <GridContainer>
                           <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                              <Button
                                 type="submit"
                                 color="primary">
                                 Submit
                           </Button>
                              <Button
                                 type="button"
                                 onClick={this.handleClose}
                                 style={{ marginLeft: "10px" }}
                                 color="white">
                                 Cancel
                           </Button>
                           </GridItem>
                        </GridContainer>
                     </form>
                  </CardBody>
               </Card>
            </GridItem>
         </GridContainer>
      )
   }
}

function mapStateToProps(state) {
   const { ImagePreview, formOpen, formMode, merchantLists } = state.upload_3rd_party;
   const { FORM_UPLOAD_3RD_PARTY } = state.form;
   const { merchant } = state.authentication;
   return {
      formOpen, formMode, FORM_UPLOAD_3RD_PARTY, ImagePreview, merchantLists, merchant
   };
}

const connectUploadForm = connect(mapStateToProps)(UploadForm);

export default reduxForm({
   form: FORM_NAME,
   validate: validate,
   enableReinitialize: true,
   onSubmitFail: (errors, dispatch, props) => {
      crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
   }
})(withStyles({ ...style, ...regularFormsStyle })(connectUploadForm))
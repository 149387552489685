import { configConstants } from '../constants';
import axios from 'axios';
import _ from 'lodash';
import { history, crmValidator, setHeaders } from 'helpers';

export const photoGiveawayRulesServices = {
  getListsPhotoGiveawayPhoto,
  getPhotoGiveawayRuleLists,
  addPhotoGiveawayRule,
  editPhotoGiveawayRule,
  deletePhotoGiveawayRule,
  getPhotoGiveawayRuleById
};

function getPhotoGiveawayRuleLists(reqParams) {
  let tmp = axios.get(configConstants.API_BACKEND_CRM + `/photo-giveaway/rule/?${reqParams}`, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    })

  return tmp;
}

function addPhotoGiveawayRule(params) {
  let tmp = axios.post(configConstants.API_BACKEND_CRM + `/photo-giveaway/rule`, params, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: e.message };
    })
  return tmp;
}

function editPhotoGiveawayRule(params) {
  let tmp = axios.put(configConstants.API_BACKEND_CRM + `/photo-giveaway/rule/${params._id}`, params, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: e.message };
    })
  return tmp;
}

function deletePhotoGiveawayRule(params) {
  let tmp = axios.delete(configConstants.API_BACKEND_CRM + `/photo-giveaway/rule/${params._id}/force`, { headers: setHeaders() })
    .then(response => {
      if (response.status.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    });

  return tmp;
}

function getListsPhotoGiveawayPhoto(params) {
  var tmp = axios.get(configConstants.API_BACKEND_CRM + `/photo-giveaway/photo?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    })
    .catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

  return tmp;
}

function getPhotoGiveawayRuleById(id) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/photo-giveaway/rule/${id}`, { headers: setHeaders() })
        .then(response => {
            if (response.status.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

import { loaderConstants } from '../constants';

var initialState = {
   loader: false
}

export function loader(state = initialState, action) {
   switch (action.type) {
      case loaderConstants.ACTIVE:
         return {
            ...state,
               loader: true
         };
      case loaderConstants.INACTIVE:
         return {
            ...state,
            loader: false
         };
      case loaderConstants.GET_PUBLIC_CONFIG:
         return {
            ...state,
            envConfig: action.envConfig
         }
      default:
         return state;
   }
}
import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";
import { plugins, crmValidator, loader, alert as customAlert } from 'helpers';
import { reportActions } from 'actions';
import { reportServices } from 'services';
import ReportUserAgentDetail from './ReportUserAgentDetail';
import { reportConstants } from "../../constants";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const style = {

}
const THEME = createMuiTheme(MTablestyles);
class TableBurnReport extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 0,
         rowsPerPage: 10,
         filter: {
            skip: 0,
            limit: 10,
            sortBy: "created_at",
            sortDir: "desc",
            keyword: ""
         },
         csvData: []
      }
      this.csvLink = React.createRef()
      this.delayedCallback = lodash.debounce(this.getData, 500)
   }

   exportCSV() {
      const { burnReport, dispatch, merchantSetting } = this.props;

      let { tableFilter, tableTotal } = burnReport;
      tableFilter.skip = 0;
      tableFilter.limit = (tableTotal ? tableTotal : 100000);

      if (tableTotal > 30000) {
         customAlert(dispatch, 'error', "Can not export data more than 30,000 rows")
      } else {
         loader(dispatch, true)
         reportServices.downloadBurnTransaction(tableFilter)
            .then(response => {
               loader(dispatch, false)
            })
            .catch((e) => {
               this.setState({ csvData: [] });
            });
      }
   }

   openUserAgentDetail(rowData) {
      const { dispatch } = this.props;
      const { userAgent, burnChannel }=rowData
      let data = {
          userAgent:userAgent,
          userChannel:burnChannel
      }
      dispatch({type:reportConstants.OPEN_REPORT_USER_AGENT_DETAIL,data:data})
  }

   getData(skip, limit, sortBy, sortDir, keyword) {
      const { dispatch, tableReload, burnReport } = this.props
      let { tableFilter } = burnReport;

      let newFilter = tableFilter;

      if (!tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            newFilter.skip = 0
         }

         if (crmValidator.isEmptyValue(limit)) {
            newFilter.limit = 10
         }
         newFilter.page = skip;
         this.setState({
            rowsPerPage: limit
         })
         skip = parseInt(limit) * parseInt(skip)

         newFilter.skip = skip;
         newFilter.limit = limit;

         if (!crmValidator.isEmptyValue(sortBy)) {
            newFilter.sortBy = sortBy;
         }

         if (!crmValidator.isEmptyValue(sortDir)) {
            newFilter.sortDir = sortDir;
         }

         if (!crmValidator.isEmptyValue(keyword)) {
            newFilter.keyword = keyword;
         } else {
            newFilter.keyword = "";
        }
      } else {
         newFilter = this.state.newFilter
      }

      dispatch(reportActions.getBurnTransactionList(newFilter));

      this.setState({
         isLoading: true,
         filter: newFilter
      });
   }

   getRowData(rowIndex = 0) {
      const { burnReport } = this.props;
      const { tableData } = burnReport;
      const data = tableData;
      return data[rowIndex];
   }

   render() {
      const { rowsPerPage } = this.state;
      const { burnReport } = this.props;
      const { tableData, tableTotal, tableFilter } = burnReport;
      const columns = [
         {
            name: "created_at",
            label: "Redeem Date/Time",
            options: {
               filter: false,
               customBodyRender: (value, tableMeta) => {

                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  return (rowData.created_at ? plugins.datetimeFormat(rowData.created_at, "fulldatetime") : "");
               }
            }
         },
         { name: "transactionId", label: "Transaction ID" },
         // {
         //    name: "memberInfo",
         //    label: "Member Info",
         //    options: {
         //       filter: false,
         //       sort: false,
         //       customBodyRender: (value, tableMeta) => {

         //          const rowIndex = tableMeta.rowIndex;
         //          const rowData = this.getRowData(rowIndex);
         //          return <span key={rowIndex}>
         //             <p style={{whiteSpace: "nowrap"}}>{rowData.memberFullName}</p>
         //             <p>{rowData.phoneNumber}</p>
         //             <p>{rowData.ageDisplay}</p>
         //          </span>
         //       }
         //    }
         // },
         { name: "memberId", label: "Member ID" },
         { name: "memberFullName", label: "Name" ,
               options: {
               customBodyRender: (value) =>
                  lodash.isUndefined(value) ? "" : value === "undefined undefined" ? "" : value
               }
         },
         {
            name: "phoneNumber",
            label: "Mobile No.",
         },
         {
            name: "burnType",
            label: "Campaign Type",
         },
         {
            name: "campaignTypeDisplay",
            label: "Condition/Campaign Name",
            options: {
               customBodyRender: (value,tableMeta) =>{
                 
                   return value === 'undefined / undefined' ? "" : value}
               }
         },
         {
            name: "rewardType",
            label: "Reward Type",
         },
         {
            name: "code",
            label: "Reward Code/Name",
            // options: {
            //    filter: false,
            //    customBodyRender: (value, tableMeta) => {
            //       const rowIndex = tableMeta.rowIndex;
            //       const rowData = this.getRowData(rowIndex);
            //       return lodash.get(rowData, 'rewardDisplay', 'default') !== 'default' && rowData.rewardDisplay !== null && rowData.rewardDetail !== null ? rowData.rewardDisplay : ""
            //    }
            // }
         },
         {
            name: "pointUsage", label: "Point"
         },
         {
            name: "expiration", 
            label: "Expiration", 
            options: {
               filter: false,
               customBodyRender: (value, tableMeta) => {

                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  if(rowData.expire_at){
                     
                     return <p style={{whiteSpace: "nowrap"}}>{(!rowData.expiration ? "Not Expire " : "Expired ") + (plugins.datetimeFormat(rowData.expire_at, "fulldatetime") || "")} </p>
                  }
                  else{
                     return ""
                  }
               }
            }
         },
         { 
            name: "markUsed", 
            label: "Mark used",
            options: {
               filter: false,
               customBodyRender: (value, tableMeta) => {

                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  return <p style={{whiteSpace: "nowrap"}}>{!rowData.markUsed ? "" : "Used " + plugins.datetimeFormat(rowData.markUsedDate, "fulldatetime") || ""}</p>
               }
            } 
         },
         { name: "remark", label: "Status" },
         { name: "burnChannel", label: "Channel" },
         { name: "userAgent", label: "User Agent",
                options: {
                    filter: false, 
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                     const rowIndex = tableMeta.rowIndex;
                     const rowData = this.getRowData(rowIndex);
                     return (
                         <>
                         { lodash.has(value,"source")   &&

                         (<IconButton color="primary" className="" onClick={()=>this.openUserAgentDetail(rowData)}>
                             <InfoOutlinedIcon />
                         </IconButton>)
                         }
                         </>
                         )
                    },
                }, 
            },
      ]
      const options = {
         serverSide: true,
         count: (tableTotal ? tableTotal : 0),
         page: tableFilter.page,
         rowsPerPage: rowsPerPage,
         fixedHeader: true,
         selectableRows: "none",
         print: false,
         customToolbar: () => {
            return (
               <>
                  {plugins.isHasPermission("report[burn]", 'export') && plugins.isHasPackageFeatures('export') &&
                     <Tooltip title="Export CSV">
                        <IconButton className="" onClick={this.exportCSV.bind(this)}>
                           <CloudDownload />
                        </IconButton>
                     </Tooltip>
                  }
               </>
            );
         },
         download: false,
         filter: false,
         responsive: "scroll",
         rowsPerPageOptions: [1, 5, 10, 20],
         onTableChange: (action, tableState) => {

            let skip, limit, sortBy, sortDir, keyword = ""

            if (tableState.page !== null) {
               skip = tableState.page
            }

            if (tableState.rowsPerPage !== null) {
               limit = tableState.rowsPerPage
            }

            if (tableState.activeColumn !== null) {
               sortBy = tableState.columns[tableState.activeColumn].name
            }
            if (tableState.announceText !== null) {
               sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
            }
            if (tableState.searchText !== null || action === 'search') {
               keyword = tableState.searchText
               this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
            }
            let actionList = ['changePage', 'sort', 'changeRowsPerPage']
            if (actionList.indexOf(action) !== -1) {
               this.getData(skip, limit, sortBy, sortDir, keyword)
            }
         }
      };
      return (
         <div id="tableBurnReport">
             <ReportUserAgentDetail />
            <MuiThemeProvider theme={THEME}>
               <MUIDataTable
                  key={burnReport.tableKey}
                  // title={"Campaign list"}
                  data={tableData ? tableData : []}
                  columns={columns}
                  options={options}
               />
            </MuiThemeProvider>
         </div>
      )
   }
}

function mapStateToProps(state) {
   const { visiblePersonalData, merchantSetting } = state.authentication;
   const { burnReport } = state.report
   return {
      visiblePersonalData, merchantSetting, burnReport
   };
}

export default connect(mapStateToProps)(withStyles(style)(TableBurnReport));
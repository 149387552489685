import React from "react";
import i18n from 'i18n';
import lodash from 'lodash';

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { store } from 'helpers';

const swalContainerClass = "swal-container";

class NotifyUpgradePackage extends React.Component {

   componentDidUpdate() {
      let componentDanger = document.getElementsByClassName('swal-btn-danger')[0]
      if (componentDanger) {
         componentDanger.setAttribute('id', 'swal-btn-danger')
      }

      let componentConfirm = document.getElementsByClassName('swal-btn-confirm')[0]
      if (componentConfirm) {
         componentConfirm.setAttribute('id', 'swal-btn-confirm')
      }

      let component = document.getElementsByClassName(swalContainerClass)[0]
      if (component) {
         component.setAttribute('id', swalContainerClass)
      }
   }

   onConfirm() {
      const { closeModal } = this.props;

      let isShowBtnPackage = false

      const storeData = store.getState().authentication

      let source = localStorage.getItem('source') || ''
      let accessToken = localStorage.getItem('token') || ''
      let bypassLoginSBO = lodash.get(storeData, 'envConfig.BYPASS_LOGIN_SBO_URL', '')
      let isCUAA = lodash.get(storeData, 'user.cuaa_id', '')
      let isShopOwner = lodash.get(storeData, 'user.shopOwner', false)

      if (isCUAA && source === 'cuaa' && isShopOwner) {
         var form = document.createElement("form");
         form.setAttribute("method", "post");
         form.setAttribute("action", bypassLoginSBO);
         form.setAttribute("target", '_blank');

         var hiddenField = document.createElement("input");
         hiddenField.setAttribute("type", "hidden");
         hiddenField.setAttribute("name", "access_token");
         hiddenField.setAttribute("value", accessToken);
         form.appendChild(hiddenField);
         document.body.appendChild(form);
         form.submit();

      } else if (isCUAA && source !== 'cuaa' && !isShopOwner) {
         window.open(bypassLoginSBO.replace('/api/auth/passlogin', '/home'), '_blank').focus();
      }
      closeModal()
   }

   render() {
      const { classes, open, closeModal } = this.props;

      let isShowBtnPackage = false

      const storeData = store.getState().authentication

      let source = localStorage.getItem('source') || ''
      let isCUAA = lodash.get(storeData, 'user.cuaa_id', '')
      let isShopOwner = lodash.get(storeData, 'user.shopOwner', false)


      if (isCUAA && source === 'cuaa' && isShopOwner) {
         isShowBtnPackage = true
      } else if (isCUAA && source !== 'cuaa' && !isShopOwner) {
         isShowBtnPackage = true
      }

      return (
         <SweetAlert
            warning
            show={open}
            style={{ display: "block", marginTop: "-200px" }}
            title={(<b id="swal-title">You are restricted from using most features due to the number of member exceed package limitation</b>)}
            onConfirm={this.onConfirm.bind(this)}
            onCancel={closeModal}
            confirmBtnText={isShowBtnPackage ? i18n.t('upgrade_package') : i18n.t('ok')}
            cancelBtnText={i18n.t('closed')}
            showCancel={isShowBtnPackage}
            customClass={
               classes.alertHeaderDanger + " " + swalContainerClass
            }
            confirmBtnCssClass={
               this.props.classes.button + " " + this.props.classes.danger
            }
            cancelBtnCssClass={
               this.props.classes.button + " " + this.props.classes.warning
            }
         >
            <div
               style={{
                  fontSize: '16px',
                  color: '#f44336'
               }}
            >
               <p>Your members will not be able to correct point and redeem reward. And in back office, you can only manage your members and view report data</p>
               <p>For full functionality, please upgrade your package</p>
            </div>
         </SweetAlert>
      );
   }
}

export default withStyles(sweetAlertStyle)(NotifyUpgradePackage);

import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, arrayPush, getFormValues } from 'redux-form';
import lodash from 'lodash'
import qs from 'qs'
import withStyles from "@material-ui/core/styles/withStyles";

// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import { promotionConstants } from "../../../constants"
// import { promotionActions } from "../../../actions"
import { listServices, saleProductServices } from '../../../services'
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import CustomInput from 'components/CustomInput/CustomInput';
import { crmValidator, normalize } from 'helpers';
import { components, createFilter } from 'react-select';
import ReactAsyncSelect from 'react-select/async'
import red from '@material-ui/core/colors/red';

import debounce from "debounce-promise";


import { brandAction } from "actions"

const style = {
    ...modalStyle,
    tdBordered: {
        border: "solid 1px #ccc"
    },
    tableError: {
        border: "solid 2px red"
    },
    optionHover: {
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#eee"
        }
    },
    overrides: {
        MuiDialog: {
            paper: {
                overflowY: "visible"
            }
        }
    }
}

const THEME = createMuiTheme(style)

const FORM_NAME = 'promotion_purchase_product'

const form_validate = {
    collectType: {
        rules: {
            required: true
        },
        messages: {
            required: "Required"
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const defaultCollectType = [
    {
        value: "sku",
        label: "SKU"
    },
    {   
        value: "category",
        label: "Category"
    },
    {   
        value: "saleProduct",
        label: "Sale Product"
    }
]

const MenuList = props => {
    let menuListType = lodash.get(props, 'selectProps.menuListType')
    return (
        <React.Fragment>
            <GridContainer style={{
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
                borderBottom: "1px solid #aaa",
                backgroundColor: "rgb(255, 255, 255)",
                width: "100%",
                margin: "0px",
                padding: "8px 0px"
            }}>
                {menuListType === "sku" &&
                <>
                    <GridItem xs={4}>SKU ID</GridItem>
                    <GridItem xs={8}>Product Name</GridItem>
                </>
                }

                {menuListType === "category" &&
                <>
                    <GridItem xs={4}>Category Code</GridItem>
                    <GridItem xs={8}>Category Name</GridItem>
                </>
                }

                {menuListType === "saleProduct" &&
                <>
                    <GridItem xs={4}>Brand Name</GridItem>
                    <GridItem xs={8}>Product Name</GridItem>
                </>
                }
            </GridContainer>
            <components.MenuList {...props}>
                {props.children}
            </components.MenuList>
        </React.Fragment>
    );
};

const validateRequired = (value, allValues, props, name) => {
    if (value === "" || value === null || value === undefined) {
        if (lodash.includes(name, 'value')) {
            if (lodash.get(allValues, 'collectType') === 'purchase_amount') {
                return "Purchase amount is required"
            } else {
                return "Quantity is required"
            }

        } else if (lodash.includes(name, 'operator')) {
            return "Operator is required"

        } else {
            return "Required"
        }
    }

    if (parseFloat(value) <= 0) {
        return "Please enter number more than 0"
    }
}

const validateSizeMorethanOne = (value) => {
    if (lodash.size(value) === 0) {
        return 'Please add at least 1 condition'
    }
}

class ConditionPurchaseProduct extends React.Component {

    constructor(props) {
        super(props)
        const loadDelays = 800
        const loadProduct = inputValue => this.loadProductOptionsPromise(inputValue)
        this.loadProductOptionsDelay = debounce(loadProduct, loadDelays, {
            leading: true
        })

        const loadCategory = inputValue => this.loadCategoryOptionsPromise(inputValue)
        this.loadCategoryOptionsDelay = debounce(loadCategory, loadDelays, {
            leading: true
        })

        const loadSaleProduct = inputValue => this.loadSaleProductOptionsPromise(inputValue)
        this.loadSaleProductOptionsDelay = debounce(loadSaleProduct, loadDelays, {
            leading: true
        })
    }

    renderCustomOption = (props) => {
        const { classes } = this.props
        delete props.innerProps.onMouseMove;
        delete props.innerProps.onMouseOver;

        return (
            <components.Option {...props} className={classes.optionHover}>
                <GridContainer>
                    <GridItem xs={4}>{lodash.get(props, 'data.value')}</GridItem>
                    <GridItem xs={8}>{lodash.get(props, 'data.label')}</GridItem>
                </GridContainer>
            </components.Option>
        );
    };

    renderCustomSaleProductOption = (props) => {
        const { classes } = this.props
        delete props.innerProps.onMouseMove;
        delete props.innerProps.onMouseOver;

        return (
            <components.Option {...props} className={classes.optionHover}>
                <GridContainer>
                    <GridItem xs={4}>{lodash.get(props, 'data.brandName')}</GridItem>
                    <GridItem xs={8}>{lodash.get(props, 'data.label')}</GridItem>
                </GridContainer>
            </components.Option>
        );
    };

    loadProductOptionsPromise(inputValue) {
        return new Promise(async (resolve, reject) => {
            let { formValues } = this.props
            let formValuesConditions = lodash.get(formValues, 'conditions')
            let selectedSKU = []

            lodash.forEach(formValuesConditions, function (value) {
                let orCondition = lodash.get(value, 'in')
                lodash.forEach(orCondition, function (cond) {
                    let selected = lodash.get(cond, 'id')
                    selectedSKU.push(selected)
                })
            })

            let reqParams = {
                skip: 0,
                limit: 1000,
                sortBy: "name",
                sortDir: "asc",
                keyword: inputValue,
                keywordFilterList: ["name", "skuId"]
            }
            let productList = await listServices.getListsProduct(qs.stringify(reqParams))
            let formattedProductList = []
            if (lodash.has(productList, 'data.products')) {
                productList.data.products.map((value) => {
                    let productsku = lodash.get(value, 'skuId')
                    if ((lodash.indexOf(selectedSKU, productsku) === -1) && productsku) {
                        formattedProductList.push({
                            value: productsku,
                            label: lodash.get(value, 'name')
                        })
                    }
                    return formattedProductList
                })
            }
            resolve(formattedProductList)
        });
    }

    loadCategoryOptionsPromise(inputValue) {
        return new Promise(async (resolve, reject) => {
            let { formValues } = this.props
            let formValuesConditions = lodash.get(formValues, 'conditions')
            let selectedCategory = []

            lodash.forEach(formValuesConditions, function (value) {
                let orCondition = lodash.get(value, 'in')
                lodash.forEach(orCondition, function (cond) {
                    let selected = lodash.get(cond, 'id')
                    selectedCategory.push(selected)
                })
            })

            let reqParams = {
                skip: 0,
                limit: 1000,
                sortBy: "name",
                sortDir: "asc",
                keyword: inputValue,
                keywordFilterList: ["categoryCode", "name"]
            }

            let categoryList = await listServices.getCategoryList('product', qs.stringify(reqParams))
            let formattedCategoryList = []
            if (lodash.has(categoryList, 'data.data') && lodash.isArray(categoryList.data.data)) {
                categoryList.data.data.map((value) => {
                    let catCode = lodash.get(value, 'categoryCode')
                    if ((lodash.indexOf(selectedCategory, catCode) === -1) && catCode) {
                        formattedCategoryList.push({
                            value: catCode,
                            label: lodash.get(value, 'name')
                        })
                    }
                    return formattedCategoryList
                })
            }
            resolve(formattedCategoryList)
        })
    }

    loadSaleProductOptionsPromise(inputValue) {
        const { brands } = this.props

        return new Promise(async (resolve, reject) => {
            let { formValues } = this.props
            let formValuesConditions = lodash.get(formValues, 'conditions')
            let selectedSKU = []

            lodash.forEach(formValuesConditions, function (value) {
                let orCondition = lodash.get(value, 'in')
                lodash.forEach(orCondition, function (cond) {
                    let selected = lodash.get(cond, 'id')
                    selectedSKU.push(selected)
                })
            })

            let reqParams = {
                skip: 0,
                limit: 1000,
                sortBy: "name",
                sortDir: "asc",
                keyword: inputValue,
                keywordFilterList: ["name", "skuId"]
            }
            let saleProductList = await saleProductServices.getListsSaleProduct(qs.stringify(reqParams))

            let formattedSaleProductList = []
            if (lodash.has(saleProductList, 'data.product_sales')) {
                saleProductList.data.product_sales.map((value) => {
                    let productId = lodash.get(value, '_id')

                    const brandId = lodash.get(value, 'brandId')  
                    if ((lodash.indexOf(selectedSKU, productId) === -1) && productId && brandId) {
                        const brand = brands.find(brand => brand._id === value.brandId)
                        formattedSaleProductList.push({
                            value: productId, // brand?.name || '',
                            brandName: brand?.name || '',
                            label: lodash.get(value, 'name')
                        })
                    }
                    return formattedSaleProductList
                })
            }
            resolve(formattedSaleProductList)
        });
    }

    addCondition() {
        const { dispatch } = this.props
        dispatch(arrayPush(FORM_NAME, 'conditions', {
            "type": lodash.get(defaultCollectType, '[0].value'),
            "operator": "OR"
        }))
    }


    addOrCondition(conditionIndex, value) {
        const { dispatch } = this.props
        return new Promise((resolve) => {
            dispatch(arrayPush(FORM_NAME, `conditions[${conditionIndex}].in`, {
                "id": lodash.get(value, 'value'),
                "name": lodash.get(value, 'label'),
                "value": "",
                "brandName": lodash.get(value, 'brandName'),
            }))
            resolve()
        })
    }

    getConditionIndexCollectype(conditionsIndex) {
        let { formValues } = this.props
        let formValuesConditions = lodash.get(formValues, 'conditions')
        let _thisConditionCollectype = lodash.get(formValuesConditions, `[${conditionsIndex}].type`)
        return _thisConditionCollectype
    }

    getConditionCollectTypeOptions(conditionsIndex) {
        let { formValues } = this.props
        let formValuesConditions = lodash.get(formValues, 'conditions')

        let collectTypeOptions = lodash.clone(defaultCollectType)
        let _thisConditionCollectype = this.getConditionIndexCollectype(conditionsIndex)
        let returnOptions = []

        collectTypeOptions.map((collectOption) => {
            let isSelected = lodash.find(formValuesConditions, { type: collectOption.value })
            let disabled = false
            if (isSelected && (isSelected.type !== _thisConditionCollectype)) {
                disabled = true
            }
            return returnOptions.push({ ...collectOption, disabled })
        })

        return returnOptions
    }

    getConditionIndexCollectType(conditionsIndex) {
        let { formValues } = this.props
        let formValuesConditions = lodash.get(formValues, 'conditions')
        let _thisConditionCollectType = lodash.get(formValuesConditions, `[${conditionsIndex}].type`)
        return _thisConditionCollectType
    }

    renderTableHead = (and, conditionsIndex) => {
        let { formValues, classes, readOnly } = this.props
        let collectType = lodash.get(formValues, 'collectType')
        let _thisConditionCollectType = this.getConditionIndexCollectype(conditionsIndex)
        return (
            <TableRow className={classes.tdBordered}>
                {_thisConditionCollectType === "sku" &&
                    <>
                        <TableCell>SKU ID</TableCell>
                        <TableCell>Product Name</TableCell>
                    </>
                }
                {_thisConditionCollectType === "category" &&
                    <>
                        <TableCell>Category Code</TableCell>
                        <TableCell>Category Name</TableCell>
                    </>
                }
                {_thisConditionCollectType === "saleProduct" &&
                    <>
                        <TableCell>Sale Product ID</TableCell>
                        <TableCell>Brand Name</TableCell>
                        <TableCell>Product Name</TableCell>
                    </>
                }
                <TableCell>{(collectType) === "product_qty" ? "Quantity" : "Purchase Amount"}</TableCell>
                <TableCell colSpan={2} align="center" style={{ padding: "10px" }}>
                    <Field
                        name={`${and}.operator`}
                        type="text"
                        component={CustomSelect}
                        formControlProps={{
                            fullWidth: true
                        }}
                        labelText={""}
                        outlined
                        optionsList={[{
                            value: "AND",
                            label: "AND"
                        }, {
                            value: "OR",
                            label: "OR"
                        }]}
                        disabled={readOnly}
                        validate={[validateRequired]}
                    />
                </TableCell>
            </TableRow>
        )
    }

    handleSubmit(submitValues) {
        let { promotionFormValue, dispatch } = this.props
        let conditions = lodash.get(promotionFormValue, 'conditions')
        lodash.remove(conditions, function (n) {
            return n.type === 'purchase_product'
        })

        dispatch(arrayPush('promotion_form', 'conditions', {
            type: "purchase_product",
            collectType: lodash.get(submitValues, 'collectType'),
            conditions: lodash.get(submitValues, 'conditions'),
            operator: lodash.get(submitValues, 'operator')
        }))
        dispatch({ type: promotionConstants.CLOSE_PURCHASE_PRODUCT_CONDITION })

    }

    renderFieldText = ({ ...props }) => lodash.get(props, 'input.value')

    getTableOperatorText = (productConditionIndex) => {
        const { formValues } = this.props
        let operator = lodash.get(formValues, `conditions[${productConditionIndex}].operator`)
        return operator ? lodash.upperCase(operator) : ""
    }

    renderOrCondition = ({ fields, productConditionIndex, meta: { error, submitFailed } }) => {
        const { classes, readOnly, formValues } = this.props

        const _thisConditionCollectType = this.getConditionIndexCollectType(productConditionIndex)

        return (
            <React.Fragment>
                {fields.map((inCondition, index) => (
                    <TableRow key={index} className={classes.tdBordered}>
                        <TableCell
                            style={{
                                paddingRight: "10px"
                            }}>
                            <Field
                                name={`${inCondition}.id`}
                                component={this.renderFieldText}
                                type="text"
                            />
                        </TableCell>
                        {_thisConditionCollectType === "saleProduct" &&
                            <TableCell style={{
                                whiteSpace: "nowrap",
                                maxWidth: "300px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                paddingRight: "10px"
                            }}>
                                <Field
                                    name={`${inCondition}.brandName`}
                                    component={this.renderFieldText}
                                    type="text"
                                />
                            </TableCell>
                        }
                        <TableCell style={{
                            whiteSpace: "nowrap",
                            maxWidth: "300px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            paddingRight: "10px"
                        }}>
                            <Field
                                name={`${inCondition}.name`}
                                component={this.renderFieldText}
                                type="text"
                            />
                        </TableCell>
                        <TableCell>
                            <Field
                                name={`${inCondition}.value`}
                                component={CustomInput}
                                formControlProps={{
                                    style: {
                                        paddingTop: "0px",
                                        margin: "0px"
                                    }
                                }}
                                type="text"
                                normalize={(value) => {
                                    if (lodash.get(formValues, 'collectType') === 'purchase_amount') {
                                        return normalize.onlyFloatingTwo(value)
                                    } else {
                                        return normalize.onlyNumber(value)
                                    }
                                }}
                                validate={[validateRequired]}
                                disabled={readOnly}
                            />
                        </TableCell>
                        <TableCell style={{ padding: "0px", width: "50px" }}>
                            {!readOnly &&
                                <TableActionButton
                                    actionType="delete"
                                    onClick={() => {
                                        fields.remove(index)
                                    }}
                                />
                            }
                        </TableCell>
                        <TableCell style={{ padding: "0px" }}>
                            {(index < (fields.length - 1)) ? <b>{this.getTableOperatorText(productConditionIndex)}</b> : null}
                        </TableCell>
                    </TableRow>
                ))}
                { error && submitFailed &&
                    <TableRow className={classes.tdBordered}>
                        <TableCell colSpan={5} align="center"><span style={{ color: red[500] }}>{error}</span></TableCell>
                    </TableRow>
                }
            </React.Fragment>
        )
    }

    renderPurchaseProductCondition = ({ fields, meta: { error, submitFailed } }) => {
        const { classes, formValues, readOnly } = this.props
        return (
            <React.Fragment>
                {fields.map((conditions, index) => (
                    <React.Fragment key={index}>
                        { (index > 0) &&
                            <div style={{
                                padding: "20px",
                                width: "68px",
                                background: "linear-gradient(#000, #000) no-repeat center/2px 100%",
                                textAlign: "center"
                            }}>
                                <b style={{ backgroundColor: "white" }}>{lodash.upperCase(lodash.get(formValues, 'operator'))}</b>
                            </div>
                        }
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tdBordered}>
                                    <TableCell
                                        key="condition_header"
                                        align="center"
                                        style={{
                                            paddingRight: "10px",
                                            minWidth: "160px",
                                            whiteSpace: "nowrap"
                                        }}>
                                        <Field
                                            name={`${conditions}.type`}
                                            component={CustomSelect}
                                            type="text"
                                            labelText={""}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            outlined
                                            disabled={lodash.size(lodash.get(formValues, `conditions[${index}].in`)) > 0}
                                            optionsList={defaultCollectType}
                                        />
                                    </TableCell>
                                    <TableCell colSpan="3">
                                        {!readOnly ?
                                            <>
                                                {lodash.get(formValues, `conditions[${index}].type`) === "sku" &&
                                                    <ReactAsyncSelect
                                                        placeholder="Search..."
                                                        // defaultOptions
                                                        key={`select_${index}_${lodash.get(formValues, `conditions[${index}].type`)}`}
                                                        loadOptions={inputValue => this.loadProductOptionsDelay(inputValue)}
                                                        value=""
                                                        blurInputOnSelect={true}
                                                        noOptionsMessage={({ inputValue }) => {
                                                            if (inputValue.length === 0) {
                                                                return (<span>Please enter keyword(s) to search product.</span>)
                                                            } else {
                                                                return (<span>Not found</span>)
                                                            }
                                                        }}
                                                        onChange={(value) => {
                                                            this.addOrCondition(index, value)
                                                        }}
                                                        menuListType="sku"
                                                        components={{ MenuList, Option: this.renderCustomOption.bind(this) }}
                                                        filterOption={createFilter({ignoreAccents: false})}
                                                    />
                                                }
                                                {lodash.get(formValues, `conditions[${index}].type`) === "category" &&
                                                    <ReactAsyncSelect
                                                        placeholder="Search..."
                                                        value=""
                                                        // defaultOptions
                                                        key={`select_${index}_${lodash.get(formValues, `conditions[${index}].type`)}`}
                                                        loadOptions={inputValue => this.loadCategoryOptionsDelay(inputValue)}
                                                        blurInputOnSelect={true}
                                                        noOptionsMessage={({ inputValue }) => {
                                                            if (inputValue.length === 0) {
                                                                return (<span>Please enter keyword(s) to search category.</span>)
                                                            } else {
                                                                return (<span>Not found</span>)
                                                            }
                                                        }}
                                                        onChange={(value) => {
                                                            this.addOrCondition(index, value)
                                                        }}
                                                        menuListType="category"
                                                        components={{ MenuList, Option: this.renderCustomOption.bind(this) }}
                                                        filterOption={createFilter({ ignoreAccents: false })}
                                                    />
                                                }
                                                {lodash.get(formValues, `conditions[${index}].type`) === "saleProduct" &&
                                                    <ReactAsyncSelect
                                                        placeholder="Search..."
                                                        value=""
                                                        // defaultOptions
                                                        key={`select_${index}_${lodash.get(formValues, `conditions[${index}].type`)}`}
                                                        loadOptions={inputValue => this.loadSaleProductOptionsDelay(inputValue)}
                                                        blurInputOnSelect={true}
                                                        noOptionsMessage={({ inputValue }) => {
                                                            if (inputValue.length === 0) {
                                                                return (<span>Please enter keyword(s) to search sale product.</span>)
                                                            } else {
                                                                return (<span>Not found</span>)
                                                            }
                                                        }}
                                                        onChange={(value) => {
                                                            this.addOrCondition(index, value)
                                                        }}
                                                        menuListType="saleProduct"
                                                        components={{ MenuList, Option: this.renderCustomSaleProductOption.bind(this) }}
                                                        filterOption={createFilter({ ignoreAccents: false })}
                                                    />
                                                }
                                            </>
                                            : null}
                                    </TableCell>
                                    <TableCell width="50px">
                                        {!readOnly &&
                                            <TableActionButton
                                                actionType="delete"
                                                onClick={() => {
                                                    fields.remove(index)
                                                }}
                                            />
                                        }
                                    </TableCell>
                                </TableRow>
                                {this.renderTableHead(conditions, index)}
                            </TableHead>
                            <TableBody>
                                <FieldArray
                                    name={`${conditions}.in`}
                                    component={this.renderOrCondition}
                                    productConditionIndex={`${index}`}
                                    validate={[validateSizeMorethanOne]}
                                />
                            </TableBody>
                        </Table>
                    </React.Fragment>
                ))}
                {error && submitFailed && <div style={{ color: red[500] }}>{error}</div>}
            </React.Fragment>
        )
    }

    componentDidMount() {
        const {
            dispatch
        } = this.props
        
        dispatch(brandAction.getBrandLists(''))
    }

    render() {
        const { classes, dispatch, purchaseProductConditionOpen, handleSubmit, formValues, readOnly } = this.props
        let collectType = lodash.get(formValues, 'collectType')
        return (
            <MuiThemeProvider theme={THEME}>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={purchaseProductConditionOpen}
                    keepMounted
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                    fullWidth={true}
                    maxWidth="md"
                    disableRestoreFocus={true}
                    scroll="body">
                    <DialogTitle
                        disableTypography
                        className={classes.modalHeader}>
                        <h4 className={classes.modalTitle}><b>Purchase Product Condition</b></h4>
                    </DialogTitle>
                    <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                        <DialogContent
                            id="purchase-product-condition-dialog"
                            className={classes.modalBody}
                            style={{
                                overflow: "visible"
                            }}
                        >
                            <GridContainer>
                                <GridItem xs={2}>Condition with</GridItem>
                                <GridItem xs={4}>
                                    <Field
                                        name="collectType"
                                        component={CustomSelect}
                                        type="text"
                                        labelText={""}
                                        formControlProps={{
                                            fullWidth: true,
                                            style: {
                                                position: "relative",
                                                top: "-18px"
                                            }
                                        }}
                                        optionsList={[
                                            {
                                                value: "product_qty",
                                                label: "Product Quantity"
                                            },
                                            {
                                                value: "purchase_amount",
                                                label: "Purchase Amount"
                                            }
                                        ]}
                                        disabled={readOnly}
                                    />
                                </GridItem>
                                <GridItem xs={2}>

                                </GridItem>
                                <GridItem xs={2}>
                                    <Field
                                        name="operator"
                                        type="text"
                                        component={CustomSelect}
                                        formControlProps={{
                                            fullWidth: true,
                                            style: {
                                                position: "relative",
                                                top: "-18px",
                                                textAlign: "center"
                                            }
                                        }}
                                        labelText={""}
                                        outlined
                                        optionsList={[{
                                            value: "AND",
                                            label: "AND"
                                        }, {
                                            value: "OR",
                                            label: "OR"
                                        }]}
                                        disabled={readOnly}
                                        validate={[validateRequired]}
                                    />

                                </GridItem>
                                <GridItem xs={2} style={{ textAlign: "center" }}>
                                    {!readOnly &&
                                        <Button
                                            // size="sm"
                                            type="button"
                                            color="success"
                                            style={{ marginTop: "-14px", width: "80px" }}
                                            onClick={this.addCondition.bind(this)}
                                        >Add</Button>
                                    }
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12}>
                                    <FieldArray
                                        key={collectType}
                                        name="conditions"
                                        component={this.renderPurchaseProductCondition}
                                        validate={[validateSizeMorethanOne]}
                                    />
                                </GridItem>
                            </GridContainer>

                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter}>
                            {!readOnly &&
                                <Button
                                    id={`promotion_purchase_product_condition_btn_submit`}
                                    type="submit"
                                    style={{ marginRight: "10px" }}
                                    color="primary">
                                    Submit
                                </Button>
                            }
                            <Button
                                id={`promotion_purchase_product_condition_btn_cancel`}
                                type="button"
                                onClick={() => {
                                    dispatch({ type: promotionConstants.CLOSE_PURCHASE_PRODUCT_CONDITION });
                                }}
                                color="white">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </MuiThemeProvider>
        )
    }
}

function mapStateToProps(state) {
    const { purchaseProductConditionOpen, productData, categoryData, formMode } = state.promotion;
    const formValues = getFormValues(FORM_NAME)(state)
    const promotionFormValue = getFormValues('promotion_form')(state)
    const { tableData: { brands } } = state.brand;

    return {
        purchaseProductConditionOpen,
        formValues,
        productData,
        categoryData,
        promotionFormValue,
        formMode,
        brands
    };
}

const connectedComponent = connect(mapStateToProps)(ConditionPurchaseProduct);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: false
})(withStyles(style)(connectedComponent))

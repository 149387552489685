import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, touch  } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash';
import i18n from "i18n";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import RichEditor from "components/RichEditor/RichEditor.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import { plugins } from 'helpers';
import { contentActions } from 'actions';
// import * as FormValidator from './validate/FormValidator';
import { contentManagementConstants, swalConstants } from '../../constants';
import * as FormValidator from '../ContentManagement/validate/FormValidator';
import { campaignActions } from 'actions';
const FORM_NAME = "FORM_CONTENT_MANAGEMENT";

const validMimeType = "image/jpeg";

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(FormValidator.form_validate , values);
    return errors
}       

const validate_field = {
  version: {
    rules: {
      version: true
    },
    messages: {
      required: 'Please insert correct Version.'
    },
    alert: "Please insert correct Version."
  },
}
  
class ContentManagementForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: false,
      display: true
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(campaignActions.getListsCampaignV2());
  }

  getFormSubmitParams = (values, formMode) =>{
    const { FORM_CONTENT_MANAGEMENT, mobileImageUpdated, desktopImageUpdated } = this.props;
   
    let result = {
      contentName: lodash.get(values,'contentName') ? values.contentName : "",
      status: lodash.get(values,'status') ? values.status : "Active",
      contentType: lodash.get(values,'contentType') ? values.contentType : "news",
      version: lodash.get(values,'version') ? values.version : "",
      versionDate: lodash.get(values,'versionDate') ? plugins.dateFormatForDB(values.versionDate) : "",
      visibleDate: lodash.get(values,'visibleDate') ? plugins.dateFormatForDB(values.visibleDate) : "",
      content: lodash.get(values,'content') ? values.content : "",
    }
    if(values.contentType === 'luckydraw'){ 
      result = {
        ...result,
        campaignId: lodash.get(values,'campaignId') ? values.campaignId : "",
        priority: lodash.get(values,'priority') ? values.priority : 1,
      }
    }
    if(lodash.get(FORM_CONTENT_MANAGEMENT.values,'contentImage')){ 
      result = {
        ...result,
        mobileImage: values.contentImage,
      }
    }

    if(lodash.get(FORM_CONTENT_MANAGEMENT.values,'contentImageDesktop')){ 
      result = {
        ...result,
        desktopImage: values.contentImageDesktop,
      }
    }

    if(formMode === "edit"){
      result = {
        ...result,
        _id: FORM_CONTENT_MANAGEMENT.values._id,
        mobileImageUpdated : mobileImageUpdated === true ? 'Y' : 'N',
        desktopImageUpdated : desktopImageUpdated === true ? 'Y' : 'N',
      }
    }
    return result;
  }

  handleSubmit = (values) => {
    const { dispatch, formMode } = this.props;
    let params = this.getFormSubmitParams(values, formMode)

    console.log('params :',params);

    if (this.props.formMode === "add") {
      dispatch(contentActions.addContent(params));
   } else {
      dispatch(contentActions.updateContent(params));
   }
  }

  handleChange = (event) => {
    const { dispatch } = this.props;
    if (event.target.value === 'luckydraw'){
      this.setState({
        campaign: true,
        display: true
      });
      dispatch(campaignActions.getListsCampaignV2());
    } else {
      this.setState({
        campaign: false,
        display: false
      })
    }
  }

  onInsertVersion = (keyword) => {
      // let regex = new RegExp( /[(0-9)]+([.]?)([0-9]+)?([.]?)([0-9]+)?([.]?)([0-9]+)?([.]?)([0-9]+)?([.]?)([0-9]+)?/g);
    let regex = new RegExp(/[a-zA-zก-๎ !"#$%&'()*+,/:;<=>?@[\]\\^_`{|}~-]/g);
    const chars = keyword.split('');
    const char = chars.pop();
      if (regex.test(char)) {
        keyword = chars.join(''); 
      }
      return keyword;
  }
    render (){
        const { formOpen, formMode, classes, dispatch, handleSubmit, contentImagePreview, contentDesktopImagePreview, mobileImageSelected, desktopImageSelected, tableDatas, formInitialValue } = this.props;
        let setCampaignList = [];
        let statusCampaign = this.state.campaign;
        if (formInitialValue.contentType === 'luckydraw' && this.state.display) {
          statusCampaign = true
        }
        lodash.forEach(tableDatas.campaigns, function (value, key) {
          let sel_value = value._id;
          let sel_label = value.name;
          if(value.rewardType==="luckyDraw"){
            setCampaignList.push({
              value: sel_value,
              label: sel_label,
              id: value._id,
            });
          }
        });

        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }
        var date = new Date();
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth={"sm"}
                disableRestoreFocus={true}
                scroll="body"
            >
            <DialogTitle
                disableTypography
                className={classes.modalHeader}><center>
                <h4 className={classes.modalTitle}><b>{'CONTENT DETAIL'}</b></h4></center>
            </DialogTitle>
            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
            <DialogContent className={classes.modalBody}>
            <GridContainer>
                <GridItem xs={4} sm={4} md={4}
                    style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                    Content name :
                </GridItem>
                <GridItem xs={7} sm={7} md={7} >
                    <Field
                        id='content-name-input'
                        name="contentName"
                        component={CustomInput}
                        type="text"
                        labelText="content name"
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                        maxLength={100}
                        // validate={[FormValidator.validateContentName]}
                    />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}></GridItem>
                <GridItem xs={4} sm={4} md={4}
                    style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                    Status :
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                    <Field
                        id='status-select'
                        name="status"
                        component={CustomSelect}
                        type="text"
                        labelText="status"
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                        optionsList={[
                            {
                              value: "Active",
                              label: "Active"
                            },
                            {
                              value: "Inactive",
                              label: "Inactive"
                            }
                        ]}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}></GridItem>
                <GridItem xs={4} sm={4} md={4}
                    style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                    Content type :
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                    <Field
                        id='content-type-select'
                        name="contentType"
                        component={CustomSelect}
                        type="text"
                        labelText="content type"
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                        optionsList={[
                            {
                              value: "news",
                              label: "News"
                            },
                            {
                              value: "about_us",
                              label: "About us"
                            },
                            {
                                value: "contact_us",
                                label: "Contact us"
                            },
                            {
                                value: "faq",
                                label: "FAQ"
                            },
                            {
                              value: "luckydraw",
                              label: "Luckydraw"
                            },
                            {
                              value: "other",
                              label: "Other"
                            },
                        ]}
                        onChange={this.handleChange}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}></GridItem>
                { statusCampaign &&
                  <>
                    <GridItem xs={4} sm={4} md={4}
                      style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                      Campaign : 
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5}>
                        <Field
                            id='content-type-campaignId'
                            name="campaignId"
                            component={CustomSelect}
                            type="text"
                            labelText="campaign"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={readOnly}
                            optionsList={setCampaignList}
                            validate={[FormValidator.validateCampaign]}
                        />
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}></GridItem>

                    
                    <GridItem xs={4} sm={4} md={4}
                      style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                      Priority : 
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5}>
                      <Field
												name="priority"
												component={CustomInput}
												type="text"
												labelText="Priority"
												hintText="1 ~ 9999"
												formControlProps={{
													fullWidth: true,
												}}
												normalize={(value) => {
													return crmValidator.handleKeyPress(
														value,
														FormValidator.form_validate["priority"]
													);
												}}
												maxLength={4}
												disabled={readOnly}
											/>
                    </GridItem>
                    <GridItem xs={3} sm={3} md={3}></GridItem>
                  </>
                }
                
                <GridItem xs={4} sm={4} md={4}
                    style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                    Version :
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                    <Field
                        id='version-input'
                        name="version"
                        component={CustomInput}
                        type="text"
                        labelText="version"
                        formControlProps={{
                            fullWidth: true
                        }}
                        disabled={readOnly}
                        normalize={(value) => {
                          return crmValidator.handleKeyPress(this.onInsertVersion(value), validate_field.version);
                        }}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}></GridItem>
                <GridItem xs={4} sm={4} md={4}
                    style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                    Version date :
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                    <Field
                        id='version-date-select'
                        name="versionDate"
                        component={CustomDatePicker}
                        type="text"
                        // maxDate={FORM_EARNRULES.values.endDate}
                        labelText="version date"
                        dateFormat="DD-MMM-YY"
                        timeFormat="HH:mm"
                        formControlProps={{
                          fullWidth: true
                        }}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}></GridItem>
                <GridItem xs={4} sm={4} md={4}
                    style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                    Visible date :
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                    <Field
                        id='visible-date-select'
                        name="visibleDate"
                        component={CustomDatePicker}
                        type="text"
                        minDate={date.setDate(date.getDate()-1)}
                        labelText="visible date"
                        dateFormat="DD-MMM-YY"
                        timeFormat="HH:mm"
                        formControlProps={{
                          fullWidth: true
                        }}
                    />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}></GridItem>
                <GridItem xs={4} sm={4} md={4}
                    style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                    Image mobile size :
                </GridItem>
                <GridItem xs={8} sm={8} md={8} style={{textAlign: "right"}}>
                  { mobileImageSelected ?
                    <span style={{ color: 'red', textDecoration: 'underline', fontSize: '13px', fontWeight: '400'}} id='btn_delete_image_01'
                    onClick={e => {
                      dispatch({
                         type: swalConstants.SHOW,
                         data: {
                            type: "warning",
                            title: i18n.t("warning_messages.are_you_sure_delete_image_content"),
                            onConfirm: function () { 
                               dispatch({ type: swalConstants.HIDE });
                               dispatch({ type: contentManagementConstants.SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT, contentImagePreview: "default", mobileImageUpdated: true })
                            },
                            onCancel: function () {
                               dispatch({ type: swalConstants.HIDE });
                            },
                            showCancel: true,
                            confirmBtnText: i18n.t("delete"),
                            cancelBtnText: i18n.t("cancel")
                         }
                      });
                  }}
                    >
                      Delete Image
                    </span>
                    : <span style={{ color: '#666666', textDecoration: 'underline', fontSize: '13px', fontWeight: '400'}} id='btn_delete_image_01'>
                        Delete Image
                      </span> 
                  }
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <Field
                  id="imageUploadButton_1"
                  name="contentImage"
                  component={ImageUpload}
                  type="text"
                  textCenter={true}
                  width={(1140 / 2.5)}
                  height={(400 / 2.5)}
                  showRatioGuide={true}
                  recommendWidth={1140}
                  recommendHeight={400}
                  onChange={(e) => {
                    e.preventDefault();
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    reader.onloadend = () => {
                      dispatch({ type: contentManagementConstants.SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT, contentImagePreview: reader.result, mobileImageUpdated: true })
                    };
                    try {
                      if (!validMimeType.includes(file.type)) {
                        dispatch(touch(FORM_NAME, 'contentImage'));
                      }
                      reader.readAsDataURL(file);
                      dispatch(change(FORM_NAME, 'contentImage', file))
                    } catch (error) {
                      dispatch({ type: contentManagementConstants.SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT, contentImagePreview: "default", mobileImageUpdated: false  })
                      dispatch(change(FORM_NAME, 'contentImage', ''))
                    }
                  }}
                  imagePreviewUrl={contentImagePreview}
                  formControlProps={{
                    fullWidth: true
                  }}
                  validate={mobileImageSelected ? [
                    FormValidator.validateImageFormat,
                    FormValidator.validateImageSize
                  ]: [] }
                  disabled={readOnly}
                />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}
                    style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                    Image desktop size :
                </GridItem>
                <GridItem xs={8} sm={8} md={8} style={{textAlign: "right"}}>
                  { desktopImageSelected ?
                    <span style={{ color: 'red', textDecoration: 'underline', fontSize: '13px', fontWeight: '400'}} id='btn_delete_image_02'
                    onClick={e => {
                      dispatch({
                         type: swalConstants.SHOW,
                         data: {
                            type: "warning",
                            title: i18n.t("warning_messages.are_you_sure_delete_image_content"),
                            onConfirm: function () {
                               dispatch({ type: swalConstants.HIDE });
                               dispatch({ type: contentManagementConstants.SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT_DESKTOP, contentDesktopImagePreview: "default", desktopImageUpdated: true });
                            },
                            onCancel: function () {
                               dispatch({ type: swalConstants.HIDE });
                            },
                            showCancel: true,
                            confirmBtnText: i18n.t("delete"),
                            cancelBtnText: i18n.t("cancel")
                         }
                      });
                  }}
                    >
                      Delete Image
                    </span>
                    : <span style={{ color: '#666666', textDecoration: 'underline', fontSize: '13px', fontWeight: '400'}} id='btn_delete_image_02'>
                        Delete Image
                      </span> 
                  }
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <Field
                  id="imageUploadButton_2"
                  name="contentImageDesktop"
                  component={ImageUpload}
                  type="text"
                  textCenter={true}
                  width={(1140 / 2.5)}
                  height={(400 / 2.5)}
                  showRatioGuide={true}
                  recommendWidth={1140}
                  recommendHeight={400}
                  onChange={(e) => {
                    e.preventDefault();
                    let reader = new FileReader();
                    let file = e.target.files[0];
                    reader.onloadend = () => {
                      dispatch({ type: contentManagementConstants.SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT_DESKTOP, contentDesktopImagePreview: reader.result, desktopImageUpdated: true })
                    };
                    try {
                      if (!validMimeType.includes(file.type)) {
                        dispatch(touch(FORM_NAME, 'contentImageDesktop'));
                      }
                      reader.readAsDataURL(file);
                      dispatch(change(FORM_NAME, 'contentImageDesktop', file))
                    } catch (error) {
                      dispatch({ type: contentManagementConstants.SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT_DESKTOP, contentDesktopImagePreview: "default", desktopImageUpdated: false })
                      dispatch(change(FORM_NAME, 'contentImageDesktop', ''))
                    }
                  }}
                  imagePreviewUrl={contentDesktopImagePreview}
                  formControlProps={{
                    fullWidth: true
                  }}
                  validate={desktopImageSelected ? [
                    FormValidator.validateImageFormat,
                    FormValidator.validateImageSize
                  ]: [] }
                  disabled={readOnly}
                />
                
                </GridItem>
                <GridItem xs={3} sm={3} md={3}
                    style={{textAlign: 'right', color: 'black', margin: 'auto'}}>
                    Content :
                </GridItem>
                <GridItem xs={9} sm={9} md={9}></GridItem>
                <GridItem xs={1} sm={1} md={1}></GridItem>
                <GridItem xs={10} sm={10} md={10} style={{margin: 'auto'}}>
                    <Field
                      id="cotent-text-area"
                      name="content"
                      component={RichEditor}
                      validate={[FormValidator.validateContent]}
                    />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}></GridItem>
            </GridContainer>
                
            </DialogContent>
            <center style={{ paddingBottom: "15px" }}>
                <Button
                    id='btn_cancel'
                    type="button"
                    onClick={() => {
                    dispatch({ type: contentManagementConstants.CLOSE_FORM_CONTENT_MANAGEMENT });
                    }}
                    style={{ width: "200px", marginRight: "10px" }}
                    color="white">
                    Cancel
                </Button>
                {!readOnly ?
                <Button
                    id='btn_submit'
                    type="submit"
                    style={{ width: "200px" }}
                    color="primary">
                    {formMode === "edit"?"Update":"Save"}
                </Button>
                    : ''
                }
            </center>
            </form>
            </Dialog>
        )
    }
}
function mapStateToProps(state) {
    const { formOpen, formMode, contentImagePreview, contentDesktopImagePreview, formInitialValue, mobileImageSelected, desktopImageSelected, mobileImageUpdated, desktopImageUpdated } = state.content_management;
    const { tableReload, tableReset, campaignData, tableDatas, tableKey } = state.campaign_v2;
    const { FORM_CONTENT_MANAGEMENT } = state.form;
    return {
       formOpen, formMode, formInitialValue, contentImagePreview, contentDesktopImagePreview, FORM_CONTENT_MANAGEMENT, mobileImageSelected, desktopImageSelected, mobileImageUpdated, desktopImageUpdated, tableDatas
    };
  }
  
  const connectCampaignForm = connect(mapStateToProps)(ContentManagementForm);
  
  export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
      crmValidator.alertFirstInvalid(FORM_NAME, errors, FormValidator.form_validate);
    }
  })(withStyles(modalStyle)(connectCampaignForm))
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputUpload from 'components/CustomUpload/InputUpload.jsx';

import { earnrulesConstants } from '../../constants'
import { earnRulesAction } from "../../actions/earnrules.actions";

import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

const FORM_NAME = 'EARNRULE_FORM_IMPORT'
const form_validate = {
    "fileImport": {
        rules: {
            required: true,
            fileSize: 2097152
        },
        messages: {
            required: 'กรุณาเลือกไฟล์',
            fileSize: 'ไฟล์ที่เลือกต้องมีขนาดน้อยกว่า 2MB'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class EarnRuleFormImport extends React.Component {

    handleSubmit = (values) => {
        
        const { dispatch } = this.props 
        var reqParams = {
            "earnRuleId": values.earnRuleId,
            "fileImport": values.fileImport
        }
        dispatch(earnRulesAction.importProduct(reqParams));
    }


    render() {
        const { classes, formImportOpen, handleSubmit } = this.props
        let { readOnly } = this.props

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formImportOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-earn-rule-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>Add Earn Rule by Uploading Product List</b>
                    </h4>
                    
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="earn-rule-form-import-member-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "30px" }}
                    >
                        <Field
                            name="earnRuleId"
                            component="input"
                            type="hidden"
                        />

                        { formImportOpen &&
                            <Field
                            name="fileImport"
                            component={InputUpload}
                            type="file"
                            label="File Import"
                            accept=".csv"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={readOnly}
                            />
                        }
                        
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        
                        <Button
                            style={{ marginRight: "10px" }}
                            color="primary"
                            type="submit">
                                Submit
                        </Button>

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: earnrulesConstants.CLOSE_EARN_RULE_FORM_IMPORT })
                        }}>
                                Cancel
                        </Button>

                    </DialogActions>

                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formImportOpen } = state.earnrules;
    const { EARNRULE_FORM_IMPORT } = state.form
    return {
        formImportOpen, EARNRULE_FORM_IMPORT
    };
}

const connectedComponent = connect(mapStateToProps)(EarnRuleFormImport);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))
import React from "react"
import { connect } from "react-redux"
import lodash from "lodash"
import MUIDataTable from "mui-datatables"
import i18n from "i18n"

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"

import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx"
import Badge from "components/Badge/Badge.jsx"
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx"
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from "components/Table/TableLoaderSpinner"
import ShopListForm from "./ShopListForm.jsx"

import { shopConstants, swalConstants } from "../../constants"
import { plugins, crmValidator } from "helpers"
import { shopActions } from "actions"

const style = {
  actionDiv: {
    display: "inline-block",
  },
  upperCase: {
    textTransfrom: "uppercase",
  },
}

const moduleName = "shop[list]"

const pageStyle = { ...style, ...sweetAlertStyle }

const THEME = createMuiTheme(MTablestyles)

class TableShopList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      readOnly: true,
      isLoading: false,
      page: 0,
      rowsPerPage: 10,
      count: 100,
      data: [],
      reqParams: "",
    }

    this.delayedCallback = lodash.debounce(this.getData, 500)
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({ type: shopConstants.CLEAR_PAGE_STATE })
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(shopActions.getRegionOptionList())
    this.getData()
  }

  componentDidUpdate() {
    const { tableReload, tableReset, dispatch } = this.props
    if (tableReload || tableReset) {
      this.getData()
      dispatch({ type: shopConstants.TABLE_RELOAD_SUCCESS })
    }
  }

  getData = (skip, limit, sortBy, sortDir, keyword) => {
    const { dispatch, tableReload } = this.props

    let reqParams = ""

    if (!tableReload) {
      if (crmValidator.isEmptyValue(skip)) {
        skip = 0
      }

      if (crmValidator.isEmptyValue(limit)) {
        limit = 10
      }

      this.setState({
        page: skip,
        rowsPerPage: limit,
      })
      skip = parseInt(limit) * parseInt(skip)

      reqParams += "&skip=" + skip
      reqParams += "&limit=" + limit

      if (!crmValidator.isEmptyValue(sortBy)) {
        reqParams += "&sortBy=" + sortBy
      }

      if (!crmValidator.isEmptyValue(sortDir)) {
        reqParams += "&sortDir=" + sortDir
      }

      if (!crmValidator.isEmptyValue(keyword)) {
        reqParams += "&keyword=" + encodeURIComponent(keyword)
      }
    } else {
      reqParams = this.state.reqParams
    }

    dispatch(shopActions.getShopLists(reqParams))

    this.setState({
      isLoading: true,
      reqParams: reqParams,
    })
  }

  openFormAdd = () => {
    const { dispatch } = this.props
    let initialValues = {
      lat: "13.736717",
      lng: "100.523186"
    }
    dispatch({ type: shopConstants.OPEN_ADD_FORM_SHOP, data: initialValues })
  }

  openFormEdit(index) {
    const { dispatch } = this.props
    const rowData = this.getRowData(index)

    console.log('r', rowData)
    dispatch({ type: shopConstants.OPEN_EDIT_FORM_SHOP, data: rowData })
  }

  getRowData(rowIndex = 0) {
    const { tableDatas } = this.props
    const data = tableDatas.shops
    return data[rowIndex]
  }

  renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
      case "Active":
        BadgeColor = "success"
        break
      case "Inactive":
        BadgeColor = "gray"
        break
      default:
        BadgeColor = "gray"
    }
    return <Badge color={BadgeColor}>{status}</Badge>
  }

  handleClickDelete(rowIndex) {
    const { dispatch } = this.props
    let rowData = this.getRowData(rowIndex)
    dispatch({
      type: swalConstants.SHOW,
      data: {
        type: "warning",
        title: i18n.t("warning_messages.are_you_sure_delete_shop"),
        message: `${rowData.shopName}`,
        onConfirm: function() {
          let reqParams = {
            _id: rowData._id,
          }
          dispatch({ type: swalConstants.HIDE })
          dispatch(shopActions.deleteShopList(reqParams))
        },
        onCancel: function() {
          dispatch({ type: swalConstants.HIDE })
        },
        showCancel: true,
        confirmBtnText: i18n.t("delete"),
        cancelBtnText: i18n.t("cancel"),
      },
    })
  }

  render() {
    const {
      classes,
      dispatch,
      formInitialValue,
      tableDatas,
      tableKey,
      tableLoader,
    } = this.props
    const { rowsPerPage, page } = this.state
    let columns = [
      { name: "_id", options: { filter: false, display: "excluded" } },
      {
        label: "No.",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowSatrt =
              tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
            const rowIndex = rowSatrt + tableMeta.rowIndex

            return rowIndex + 1
          },
        },
      },
      { name: "shopId", label: "Shop ID" },
      { name: "shopName", label: "Shop Name" },
      {
        name: "shopRegion",
        label: "Shop Region",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return lodash.get(value, "shopRegionName")||"Not Specified"
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return this.renderBadgeStatus(value)
          },
        },
      },
    ]

    if (plugins.isHasPermission(moduleName, "read")) {
      columns.push({
        label: "Action",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className={classes.actionDiv}>
                {plugins.isHasPermission(moduleName, "update") && (
                  <TableActionButton
                    actionType="edit"
                    onClick={(e) => {
                      this.openFormEdit(tableMeta.rowIndex)
                    }}
                  />
                )}
                {plugins.isHasPermission(moduleName, "delete") && (
                  <TableActionButton
                    actionType="delete"
                    onClick={() => this.handleClickDelete(tableMeta.rowIndex)}
                  />
                )}
              </div>
            )
          },
        },
      })
    }

    let options = {
      serverSide: true,
      count: tableDatas.total,
      page: page,
      rowsPerPage: rowsPerPage,
      selectableRows: "none",
      print: false,
      download: false,
      filter: false,
      responsive: "scroll",
      rowsPerPageOptions: [5, 10, 20],
      onTableChange: (action, tableState) => {
        let skip,
          limit,
          sortBy,
          sortDir,
          keyword = ""
        if (tableState.page !== null) {
          skip = tableState.page
        }

        if (tableState.rowsPerPage !== null) {
          limit = tableState.rowsPerPage
        }

        if (tableState.activeColumn !== null) {
          sortBy = tableState.columns[tableState.activeColumn].name
        }
        if (tableState.announceText !== null) {
          sortDir =
            tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
        }
        if (tableState.searchText !== null || action === "search") {
          keyword = tableState.searchText
          this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
        }
        let actionList = ["changePage", "sort", "changeRowsPerPage"]
        if (actionList.indexOf(action) !== -1) {
          this.getData(skip, limit, sortBy, sortDir, keyword)
        }
      },
    }

    if (plugins.isHasPermission(moduleName, "create")) {
      options.customToolbar = () => {
        return (
          <CustomToolbar
            id="add_new_shop"
            handleClick={this.openFormAdd}
            title="Add New"
          />
        )
      }
    }

    return (
      <div id="TableShopList">
        <CustomSweetAlert />
        <ShopListForm
          initialValues={formInitialValue}
          readOnly={!plugins.isHasPermission(moduleName, "update")}
        />

        <MuiThemeProvider theme={THEME}>
          <MUIDataTable
            key={tableKey}
            title={"Shop lists"}
            data={tableDatas.shops}
            columns={columns}
            options={options}
          />
          {tableLoader && <TableLoaderSpinner />}
        </MuiThemeProvider>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    formInitialValue,
    tableReload,
    tableReset,
    tableDatas,
    tableKey,
    tableLoader,
  } = state.shop
  //   const { permission, superAdmin } = state.authentication
  return {
    formInitialValue,
    tableReload,
    tableReset,
    tableDatas,
    tableKey,
    tableLoader,
  }
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableShopList))

import { promotionConstants } from '../constants';
import lodash from 'lodash'
import moment from 'moment';

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: Date.now(),
    tableDatas: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false,
    productData: [],
    categoryData: [],
    showLoader: false,
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "created_at",
        sortDir: "desc",
        keyword: "",
        search: {
            startDate: moment().startOf('day'), 
            endDate: moment().set('hour',23).set('minute',59).set('second',59)
        }
    },
    purchaseProductConditionOpen: false,
    purchaseAmountPerbillOpen: false,
    paymentChannelConditionOpen: false,
    promoCodeConditionOpen: false,
    productConditionIndex: null,
    rewardModalOpen: null,
    regionList:[],
    regionSelected:[],
    allShopList:[],
    shopList:[],
    allShopListSelected:[],
    shopListSelected:[],
    additionalConditionShopShow: false,
    additionalConditionTimeShow: false,
    allPromotionList: [],
}

export function promotion(state = initialState, action) {
    switch (action.type) {
        case promotionConstants.CLEAR_PAGE_STATE:
            return initialState;
        case promotionConstants.OPEN_ADD_PROMOTION:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: {
                    status: "Active",
                    contentType: 'news',
                    customRadioParticipatedDay: 'everyday',
                    customRadioSelectedDay: 'dayOfWeek',
                    quotaPromotionType: 'pleaseSelect',
                },
                additionalConditionShopShow: false,
                additionalConditionTimeShow: false,
            };
        case promotionConstants.OPEN_EDIT_PROMOTION:
            let hasShopCond = lodash.size(lodash.get(action, 'data.shopCondition.shopList')) > 0

            let hasDateTimeCond = false
            if(lodash.size(lodash.get(action, 'data.specific_date_time.time')) > 0) {
                hasDateTimeCond = true
            } else if (lodash.get(action, 'data.specific_date_time.day.type') === 'everyday') {
                hasDateTimeCond = true
            } else if (lodash.size(lodash.get(action, 'data.specific_date_time.day.date_list')) > 0) {
                hasDateTimeCond = true
            }
            
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: {
                    ...action.data,
                    customRadioParticipatedDay: lodash.get(action.data,'customRadioParticipatedDay') ? lodash.get(action.data,'customRadioParticipatedDay') : 'everyday',
                    customRadioSelectedDay: lodash.get(action.data,'customRadioSelectedDay') ? lodash.get(action.data,'customRadioSelectedDay') : 'dayOfWeek',
                    quotaPromotionType: lodash.get(action.data,'quota.promotionPerMonth')? 'quotaPerMonth' : 
                        (lodash.get(action.data,'quota.promotionPerDayofWeek')? 'quotaPerDay' : 'pleaseSelect')
                },
                additionalConditionShopShow: hasShopCond,
                additionalConditionTimeShow: hasDateTimeCond,
                regionSelected:lodash.get(action.data,'regionSelected'),
                allShopListSelected:lodash.get(action.data,'allShopListSelected'),
                shopListSelected:lodash.get(action.data,'allShopListSelected'),
            };
        case promotionConstants.EDIT_PROMOTION_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case promotionConstants.CLOSE_FORM_PROMOTION:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                    contentImagePreview: "",
                },
            }
        case promotionConstants.ADD_PROMOTION_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case promotionConstants.TABLE_GET_LIST_PROMOTION:
            return {
                ...state,
                tableDatas: action.data,
                total: action.data.total,
                tableReload: false,
                tableReset: false
            }
        case promotionConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case promotionConstants.DELETE_PROMOTION_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case promotionConstants.SHOW_LOADER_PROMOTION:
            return {
                ...state,
                showLoader: true
            }
        case promotionConstants.HIDE_LOADER_PROMOTION:
            return {
                ...state,
                showLoader: false
            }
        case promotionConstants.OPEN_PURCHASE_PRODUCT_CONDITION:
            return {
                ...state,
                purchaseProductConditionOpen: true,
                productConditionIndex: lodash.get(action, 'productConditionIndex')
            }
        case promotionConstants.CLOSE_PURCHASE_PRODUCT_CONDITION:
            return {
                ...state,
                purchaseProductConditionOpen: false
            }
        case promotionConstants.OPEN_PURCHASE_AMOUNT_CONDITION:
            return {
                ...state,
                purchaseAmountPerbillOpen: true
            }
        case promotionConstants.CLOSE_PURCHASE_AMOUNT_CONDITION:
            return {
                ...state,
                purchaseAmountPerbillOpen: false
            }
        case promotionConstants.OPEN_PAYMENT_CHANNEL_CONDITION:
            return {
                ...state,
                paymentChannelConditionOpen: true
            }
        case promotionConstants.CLOSE_PAYMENT_CHANNEL_CONDITION:
            return {
                ...state,
                paymentChannelConditionOpen: false
            }
        case promotionConstants.GET_LIST_PRODUCT:
            return {
                ...state,
                productData: action.tmp,
                total: action.tmp.total,
            }
        case promotionConstants.GET_LIST_CATEGORY:
            return {
                ...state,
                categoryData: action.tmp,
                total: action.tmp.total,
            }
        case promotionConstants.OPEN_PROMOCODE_CONDITION:
            return {
                ...state,
                promoCodeConditionOpen: true
            }
        case promotionConstants.CLOSE_PROMOCODE_CONDITION:
            return {
                ...state,
                promoCodeConditionOpen: false
            }
        case promotionConstants.OPEN_REWARD_MODAL:
            return {
                ...state,
                rewardModalOpen: lodash.get(action, 'rewardType')
            }
        case promotionConstants.CLOSE_REWARD_MODAL:
            return {
                ...state,
                rewardModalOpen: null
            }
    
        case promotionConstants.PROMOTION_GET_REGION_LIST: {
            return {
                ...state,
                regionList: action.data 
            }
        } 
        case promotionConstants.PROMOTION_GET_SHOP_LIST: {
            return {
                ...state,
                allShopList: action.data.shopList ,
                shopList:action.data.shopList,
                allShopListSelected:action.data.shopListSelected,
                shopListSelected:action.data.shopListSelected,
            }
        } 
        case promotionConstants.PROMOTION_SELECT_SHOP: {
            return {
                ...state,
                shopList:action.data.shopList,
                allShopList:action.data.allShopList,
                shopListSelected:[...state.shopListSelected,...action.data.shopListSelected],
                allShopListSelected:[...state.allShopListSelected,...action.data.shopListSelected],
            }
        } 
        case promotionConstants.PROMOTION_UNSELECT_SHOP: {
            return {
                ...state,
                shopList:[...action.data.shopList,...state.shopList],
                allShopList:[...action.data.shopList,...state.allShopList],
                shopListSelected:action.data.shopListSelected,
                allShopListSelected:action.data.allShopListSelected,
            }
        } 
        case promotionConstants.PROMOTION_SELECTED_REGION: {
            return {
                ...state,
                regionSelected:action.data,
            }
        } 
        case promotionConstants.PROMOTION_SELECT_ALL_SHOP_LIST: {
            return {
                ...state,
                shopListSelected:[ ...lodash.get(action.data,'shopList'),...state.shopListSelected],
                shopList:[],
                allShopList:lodash.get(action.data,'differenceShopList'),
                allShopListSelected:[...lodash.get(action.data,'shopList'),...state.allShopListSelected],
            }
        } 
        case promotionConstants.PROMOTION_UNSELECT_ALL_SHOP_LIST: {
            return {
                ...state,
                shopList:[...lodash.get(action.data,'shopListSelected'),...state.shopList],
                shopListSelected:[],
                allShopListSelected:lodash.get(action.data,'differenceShopListSelected'),
                allShopList:[...lodash.get(action.data,'shopListSelected'),...state.allShopList],
            }
        }
        case promotionConstants.PROMOTION_SEARCH_SHOP_LIST: {
            return {
                ...state,
                shopList:action.data,
            }
        }
        case promotionConstants.PROMOTION_SEARCH_SHOP_SELECTED: {
            return {
                ...state,
                shopListSelected:action.data,
            }
        }
        case promotionConstants.PROMOTION_CLEAR_SHOP_LIST: {
            return {
                ...state,
                allShopList:[],
                shopList:[],
                allShopListSelected:[],
                shopListSelected:[],
                regionSelected:[],
            }
        }
        case promotionConstants.OPEN_CONDITION_SHOP: {
            return {
                ...state,
                additionalConditionShopShow: true
            }
        }
        case promotionConstants.CLOSE_CONDITION_SHOP: {
            return {
                ...state,
                additionalConditionShopShow: false
            }
        }
        case promotionConstants.OPEN_CONDITION_TIME: {
            return {
                ...state,
                additionalConditionTimeShow: true
            }
        }
        case promotionConstants.CLOSE_CONDITION_TIME: {
            return {
                ...state,
                additionalConditionTimeShow: false
            }
        }
        case promotionConstants.CLEAR_PROMOTION_FILTER: {
            return {
                ...state,
                tableFilter: initialState.tableFilter
            }
        }
        case promotionConstants.GET_TABLE_LIST_PROMOTION_REPORT: {
            return {
                ...state,
                // tableFilter: {
                //     ...action.tableFilter,
                //     page: (action.tableFilter.skip >= action.total ? (action.tableFilter.page-1) : action.tableFilter.page),
                //     skip: (action.tableFilter.skip >= action.total ? (action.tableFilter.page-1) * action.tableFilter.limit : action.tableFilter.skip),
                // },
                tableFilter: action.tableFilter,
                tableDatas: {
                    data: (action.data ? action.data : []),
                    total: (action.total ? action.total : 0)
                },
            }
        }
        case promotionConstants.PROMOTION_REPORT_TABLE_RESET:{
            return {
                ...state,
                tableKey: Date.now()
            }
        }
        case promotionConstants.GET_LIST_NAME_PROMOTION: {
            return {
                ...state,
                allPromotionList: action.data
            }
        }
        default:
            return state;
    }
}
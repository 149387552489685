import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableTagList from "views/Tag/TableTagList"

const pageStyle = {}

class TagContainer extends React.Component {

    render() {
        return (
            <div>
                <Card>
                    <CardBody>
                        <CustomSweetAlert />
                        <GridContainer>
                            <GridItem xs={12}>
                                <TableTagList />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { formOpen } = state.tag;
    return {
        formOpen
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TagContainer));
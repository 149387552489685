import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import lodash from 'lodash'

export const promotionServices = {
    addPromotion,
    updatePromotion,
    getTablePromotionDatas,
    deletePromotion,
    getPromotionById,
    getRegionLists,
    getShopListsByRegion,
    getTablePromotionReportDatas,
    getPromotionLists,
}

function addPromotion(reqBody) {
    let tmp = axios.post(configConstants.API_BACKEND_CRM + '/promotion', reqBody, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: lodash.get(response, 'data.data'),
                message: response.data.message
            };
        }
    });
    return tmp;
}

function updatePromotion(reqBody) {
    let tmp = axios.put(configConstants.API_BACKEND_CRM + `/promotion/${lodash.get(reqBody, '_id')}`, reqBody, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: lodash.get(response, 'data.data'),
                message: response.data.message
            };
        }
    });
    return tmp;
}

function getPromotionById(promotionId) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/promotion/${promotionId}`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            // return response.data.data
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getTablePromotionDatas(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/promotion?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function deletePromotion(params) {
    var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/promotion/${params._id}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function getRegionLists() {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/shop-region`, {
        headers: setHeaders({ 'Charset': 'utf-8' }),
    }).then((response) => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message,
            }
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push("/auth/login")
            return {
                status: false,
                data: {},
                message: response.data.message,
                unAlert: true,
            }
        } else {
            return { status: false, data: {}, message: response.data.message }
        }
    })
        .catch((e) => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" }
        })

    return tmp
}

function getShopListsByRegion(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/find-by-region?${params}`, {
        headers: setHeaders({ 'Charset': 'utf-8' }),
    }).then((response) => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message,
            }
        } else if (response.data.statusCode.toString() === "400") {
            return {
                status: true,
                data: [],
                message: response.data.message,
            }
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push("/auth/login")
            return {
                status: false,
                data: {},
                message: response.data.message,
                unAlert: true,
            }
        } else {
            return { status: false, data: {}, message: response.data.message }
        }
    }).catch((e) => {
        return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" }
    })

    return tmp
}

function getTablePromotionReportDatas(params) {
    let tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/promotion`, params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        }).catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getPromotionLists() {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/promotion`, {
        headers: setHeaders({ 'Charset': 'utf-8' }),
    }).then((response) => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message,
            }
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push("/auth/login")
            return {
                status: false,
                data: {},
                message: response.data.message,
                unAlert: true,
            }
        } else {
            return { status: false, data: {}, message: response.data.message }
        }
    }).catch((e) => {
        return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" }
    })

    return tmp
}
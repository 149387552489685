import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";
import { plugins, crmValidator } from 'helpers';
import { pointAdjustmentActions } from 'actions';
import { pointAdjustmentConstants } from '../../constants';
// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

import ApprovalRejectPopup from "./ApprovalRejectPopup";
import i18n from 'i18n';

const style = {}
const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(11)": {
                    paddingLeft: "210px",
                },
                "&:nth-child(10)": {
                    paddingRight: "24px",
                },
                "&:nth-child(5)": {
                    paddingRight: "70px",
                }
            },
            body: {
                
            }
        }
    }
});
const moduleName = "point[point_adjustment]";

class TablePointAdjustment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            reqParams: {
                skip: 0,
                limit: 10,
                sortBy: "created_at",
                sortDir: "desc",
                keyword: ""
            }
        }
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableDatas, tableFilter } = this.props
        
        let newFilter = tableFilter;

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                newFilter.skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                newFilter.limit = 10
            }
            newFilter.page = skip;
            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)
            if(tableDatas.total !== 0 && tableDatas.total === skip){
                skip = skip - limit
                this.setState({
                    page: this.state.page - 1,
                })                
            }
            if(skip >= tableDatas.total){
                skip = 0;
                this.setState({
                    page: 0,
                })
            }
            this.setState({
                newFilter: {
                    skip: skip,
                    limit: limit,
                 },
            })
            newFilter.skip = skip;
            newFilter.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                newFilter.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                newFilter.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                newFilter.keyword = keyword;
            }
        } else {
            newFilter = this.state.newFilter;
        }
        dispatch(pointAdjustmentActions.getTablePointAdjustmentDatas(newFilter));

        this.setState({
            reqParams: newFilter,
        });
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.data;
        return data[rowIndex];
    }

    openApprovalPopup(rowData) {
        const { dispatch } = this.props;
        dispatch({ type: "OPEN_APPROVAL_POPUP", rowData: rowData });
    }
    openRejectionPopup(rowData) {
        const { dispatch } = this.props;
        dispatch({ type: "OPEN_REJECTION_POPUP", rowData: rowData });
    }

    openAddPointAdjustment = () => {
        const { dispatch } = this.props;
        let initialValues = {
        }
        dispatch({ type: pointAdjustmentConstants.OPEN_ADD_POINT_ADJUSTMENT, data: initialValues });
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch, tableFilter } = this.props;
        if (tableReload || tableReset) {
            dispatch(pointAdjustmentActions.getTablePointAdjustmentDatas(tableFilter));
            dispatch({ type: pointAdjustmentConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    render() {
        const { classes, tableDatas, tableKey, tableLoader, tableFilter, visiblePersonalData } = this.props;
        const columns = [
            {
                name: "created_at",
                label: "Adjustment Date/Time",
                options: {
                    filter: false, sort: true,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        return (rowData.created_at ? plugins.datetimeFormat(rowData.created_at, "fulldatetime") : "");
                    }
                }
            },
            {
                name: "updated_at",
                label: "Approved/Reject Date/Time",
                options: {
                    filter: false, sort: true,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        return (rowData.updated_at ? plugins.datetimeFormat(rowData.updated_at, "fulldatetime") : "");
                    }
                }
            },
            {
                name: "memberName", label: "Member Name", options: { sort: true }
            },
            {
                name: "phoneNumber", label: "Mobile Number", options: { sort: true }
            },
            {
                name: "type", label: "Action", options: { sort: true,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        let actionText = 'Add Point'
                        if(rowData.type === 'deduct'){ actionText = 'Deduct Point' }
                        return actionText
                    } 
                }
            },
            {
                name: "point", label: "Point", options: { sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        let pointText = rowData.point
                        if(rowData.type === 'deduct'){ pointText = '-'+rowData.point }
                        return pointText
                    } 
                }
            },
            {
                name: "remark", label: "Remark", options: { sort: true }
            },
            {
                name: "status", label: "Approved Status", options: { sort: true ,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        let statusText = ''
                        if(rowData.mustApproved && rowData.status === 'waiting'){ statusText = 'Waiting for approval' }
                        else if(rowData.mustApproved && rowData.status === 'approved'){ statusText = 'Approved' }
                        else if(rowData.mustApproved && rowData.status === 'rejected'){ statusText = 'Rejected' }
                        else if(!rowData.mustApproved && rowData.status === 'no_need_approved'){ statusText = 'No need to approve' }
                        return statusText
                    } 
                }
            },
            {
                name: "adjustBy", label: "Adjusted By", options: { sort: true }
            },
            {
                name: "actionBy", label: "Approved/Rejected By", options: { sort: true }
            }
        ]

        if (plugins.isHasPermission(moduleName, "read")) {
            columns.push({
                name: "btn-approve",
                label: " ",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        let btnColorReject = '#aaaaaa'
                        let btnColorApprove = '#aaaaaa'
                        if(rowData.mustApproved && rowData.status === 'waiting'){
                            btnColorReject = '#f44336'
                            btnColorApprove = '#2196F3'
                        }
                        return (
                            <>
                                {!(!rowData.mustApproved && rowData.status === 'no_need_approved') ? (<>
                                    {plugins.isHasPermission(moduleName, "update") && (
                                        <Button
                                            id='reject-btn'
                                            variant='contained'
                                            bcolor='primary'
                                            size='sm'
                                            onClick={() => { 
                                                this.openRejectionPopup(rowData);
                                            }}
                                            disabled={!(rowData.mustApproved && rowData.status === 'waiting')}
                                            style={{ backgroundColor: btnColorReject, marginRight: '10px' }}>
                                            Reject
                                        </Button>
                                    )}
                                    {plugins.isHasPermission(moduleName, "update") && (
                                        <Button
                                            id='approve-btn'
                                            variant='contained'
                                            bcolor='primary'
                                            size='sm'
                                            onClick={() => {
                                                this.openApprovalPopup(rowData);
                                            }}
                                            disabled={!(rowData.mustApproved && rowData.status === 'waiting')}
                                            style={{ backgroundColor: btnColorApprove }}>
                                            Approve
                                        </Button>
                                    )}
                                </>) : null}
                            </>
                        );
                    },
                },
            })
        }
        const options = {
            serverSide: true,
            count: (tableDatas.total ? tableDatas.total : 0),
            page: this.state.page,
            rowsPerPage: tableFilter.limit,
            fixedHeader: true,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            search: false,
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        if (plugins.isHasPermission(moduleName, 'create')) {
            options.customToolbar = () => {
               return <CustomToolbar handleClick={this.openAddPointAdjustment} title={i18n.t("label.add_new")} />;
            };
        }

        return (
            <div id="tablePointAdjustment">
                <CustomSweetAlert/>
                <ApprovalRejectPopup/>
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        data={(tableDatas.data ? tableDatas.data : [])}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { visiblePersonalData, merchantSetting } = state.authentication;
    const { tableDatas, tableFilter, tableKey, tableLoader, tableReset } = state.point_adjustment;
    return {
        visiblePersonalData, merchantSetting, tableDatas, tableFilter, tableKey, tableLoader, tableReset,
    };
}

export default connect(mapStateToProps)(withStyles(style)(TablePointAdjustment));
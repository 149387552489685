import { importServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const importAction = {
    importFile,
    getImportHistory,
    getDetailById,
    downloadTemplate
};

function importFile(params) {
    return dispatch => {
        loader(dispatch, true)
        importServices.importFile(params)
            .then(
                response => {
                    if (response.status) {
                        customAlert(dispatch, 'success', 'SUCCESS')
                        dispatch({ type: 'ADD_IMPORT_FILE_SUCCESS' })
                    } else {
                        if (!response.unAlert) {
                            customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                        }
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function getImportHistory(params) {
    return dispatch => {
        loader(dispatch, true)
        importServices.getImportHistory(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({ type: "GET_IMPORT_HISTORY_SUCCESS", data: response.data });
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function getDetailById(importId) {
    return dispatch => {
        loader(dispatch, true)
        importServices.getDetailById(importId)
            .then(
                response => {
                    if (response.status) {
                        let detailData = lodash.get(response, 'data.detail')
                        dispatch({
                            type: "GET_IMPORT_DETAIL_SUCCESS", importDetail: {
                                open: true,
                                type: lodash.get(detailData, 'type'),
                                total: lodash.get(detailData, 'total'),
                                success: lodash.get(detailData, 'success'),
                                failed: lodash.get(detailData, 'failed'),
                                duplicated: lodash.get(detailData, 'duplicated'),
                                failedList: lodash.get(response, 'data.failedList'),
                                resultFilePath: lodash.get(response, 'data.resultFilePath')
                            }
                        });
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function downloadTemplate(importType) {
    return dispatch => {
       importServices.downloadTemplate(importType)
    }
}

import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import lodash from 'lodash'

export const authServices = {
    login,
    logout,
    getRoles,
    checkRoles,
    getMerchantSetting,
    switchMerchant,
    loginBypass,
    LoginByCUAA,
    loginBypassFromSBO,
    getEnvConfig
};

const authFailedRedirectPath = "/auth/login";

function login(email, password) {
    const tmp = axios.post(configConstants.API_BACKEND_CRM + '/admins/auth/signin', {
        email: email,
        password: password
    }).then(handleResponseLogin)

    return tmp;
}

function LoginByCUAA(username, password) {
    const tmp = axios.post(configConstants.API_UAA + '/auth/request-token/cuaa', {
        username: username,
        password: password
    }).then(res => handleResponseLogin(res, 'cuaa')
    )

    return tmp;
}

function getEnvConfig() {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + '/admins/auth/config', { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                // history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: 'SOMETHING_WENT_WRONG' };
        });
    return tmp;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('source');
    localStorage.removeItem('bypassLoginSBO');
}

function getRoles() {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + '/admins/auth/role-get', { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } 
            // else if (response.data.message === "UNAUTHORIZE") {
            //     history.push(authFailedRedirectPath)
            //     return { status: false, data: {}, message: response.data.message, unAlert: true };
            // } 
            else {
                history.push(authFailedRedirectPath)
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            }
        })
        .catch(e => {
            history.push(authFailedRedirectPath)
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        })

    return tmp;
}

function checkRoles() {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + '/admins/auth/me', { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } 
            // else if (response.data.message === "UNAUTHORIZE") {
            //     history.push(authFailedRedirectPath)
            //     return { status: false, data: {}, message: response.data.message, unAlert: true };
            // } 
            else {
                history.push(authFailedRedirectPath)
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            }
        })
        .catch(e => {
            history.push(authFailedRedirectPath)
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getMerchantSetting() {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + '/admins/auth/setting', { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: 'SOMETHING_WENT_WRONG' };
        });

    return tmp;
}

function handleResponseLogin(response, source) {
    if (response.data.statusCode.toString() === "200") {
        if (response.data.length === 0) {
            logout();
            return { status: false, data: {}, message: response.data.message };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            localStorage.setItem('token', response.data.data);
            if(source){
                localStorage.setItem('source', 'cuaa');
            }
            return { status: true, data: response.data.data, message: '' };
        }
    } else {
        logout();
        return { status: false, data: {}, message: response.data.message };
    }
}

function switchMerchant(merchantId) {
    const body = {
        merchantId: merchantId
    }
    var tmp = axios.post(configConstants.API_UAA + '/auth/switch-merchant', body, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                localStorage.setItem('token', response.data.data);
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function loginBypass(token, source) {
    let req_headers = {
        "Content-Type": "application/json"
    }
    const tmp = axios.post(configConstants.API_UAA + '/auth/bypass-token', {
        token: token,
        source: source
    }, { headers: req_headers }).then(handleResponseLogin)

    return tmp;
}

function loginBypassFromSBO(token) {
    var tmp = axios.post(configConstants.API_UAA + '/auth/bypass-token/sbo', {
        token: token
    }).then(res => handleResponseLogin(res, 'cuaa'))

    return tmp;
}
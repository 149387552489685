import moment from 'moment';

const initialState = {
  summaryEarn: {
    tableKey: Date.now(),
    tableData: [],
    tableTotal: 0,
    tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "reportDate",
      sortDir: "desc",
      startDate: moment().startOf('month'),
      endDate: moment().set('hour', 23).set('minute', 59).set('second', 59)
    },
    topbarTotal: {
      success: 0,
      unSuccess: 0,
      total: 0
    }
  },
  summaryBurn: {
    tableKey: Date.now(),
    tableData: [],
    tableTotal: 0,
    tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "reportDate",
      sortDir: "desc",
      startDate: moment().startOf('month'),
      endDate: moment().set('hour', 23).set('minute', 59).set('second', 59)
    },
    topbarTotal: {
      success: 0,
      unSuccess: 0,
      total: 0
    }
  }
}

export function summaryReport(state = initialState, action) {
  switch (action.type) {
    case 'SUMMARY_REPORT_REDUCER_INIT': return initialState
    case 'CLEAR_EARN_SUMMARY_REPORT_FILTER':
      return {
        ...state,
        summaryEarn: {
          ...state.summaryEarn,
          tableFilter: initialState.summaryEarn.tableFilter
        }
      }
    case 'GET_EARN_SUMMARY_REPORT_REQUEST':
      return {
        ...state,
        summaryEarn: {
          ...state.summaryEarn,
          topbarTotal: {
            success: 0,
            unSuccess: 0,
            total: 0
          }
        }
      }
    case 'GET_EARN_SUMMARY_REPORT_SUCCESS':
      return {
        ...state,
        summaryEarn: {
          ...state.summaryEarn,
          tableFilter: action.tableFilter,
          tableData: action.data ? action.data : [],
          tableTotal: action.total ? action.total : 0,
          topbarTotal: action.sum ? action.sum : initialState.summaryEarn.topbarTotal
        }
      }
    case 'EARN_SUMMARY_REPORT_TABLE_RESET':
      return {
        ...state,
        summaryEarn: {
          ...state.summaryEarn,
          tableKey: Date.now(),
        }
      }
    case 'CLEAR_BURN_SUMMARY_REPORT_FILTER':
      return {
        ...state,
        summaryBurn: {
          ...state.summaryBurn,
          tableFilter: initialState.summaryBurn.tableFilter
        }
      }
    case 'GET_BURN_SUMMARY_REPORT_REQUEST':
      return {
        ...state,
        summaryBurn: {
          ...state.summaryBurn,
          topbarTotal: {
            success: 0,
            unSuccess: 0,
            total: 0
          }
        }
      }
    case 'GET_BURN_SUMMARY_REPORT_SUCCESS':
      return {
        ...state,
        summaryBurn: {
          ...state.summaryBurn,
          tableFilter: action.tableFilter,
          tableData: action.data ? action.data : [],
          tableTotal: action.total ? action.total : 0,
          topbarTotal: action.sum ? action.sum : initialState.summaryBurn.topbarTotal
        }
      }
    case 'BURN_SUMMARY_REPORT_TABLE_RESET':
      return {
        ...state,
        summaryBurn: {
          ...state.summaryBurn,
          tableKey: Date.now(),
        }
      }
    default: return state
  }
}
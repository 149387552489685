import { orderConstants } from '../constants';
import { orderServices, productServices, categoryServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const orderActions = {
    updateOrder,
    getTableOrderDatas,
    deleteOrder,
};

function updateOrder(params) {
    return dispatch => {
        loader(dispatch, true)
        orderServices.updateOrder(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: orderConstants.EDIT_ORDER_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.order_was_updated'));
                        } else {
                            customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getTableOrderDatas(params) {
    return dispatch => {
        loader(dispatch, true)
        orderServices.getTableOrderDatas(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(data) { return { type: orderConstants.TABLE_GET_LIST_ORDER, data } }
}

function deleteOrder(params) {
    return dispatch => {
        loader(dispatch, true)
        orderServices.deleteOrder(params)
            .then(
                tmp => {
                    if (tmp.status) {
                        dispatch({ type: orderConstants.DELETE_ORDER_SUCCESS });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.order_was_deleted'));
                    } else {
                        if (!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}
import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import MUIDataTable from "mui-datatables";
import { saleProductGroupActions } from "actions";
// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx";
import TableLoaderSpinner from "components/Table/TableLoaderSpinner";

// page components
import ProductGroupForm from "./ProductGroupForm";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import { saleProductGroupConstants, swalConstants } from "../../../constants";
import { crmValidator } from "helpers";
import i18n from "i18n";

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  actionDiv: {
    display: "inline-flex",
  },
};

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme({
  ...MTablestyles,
  overrides: {
    ...MTablestyles.overrides,
    MuiTableCell: {
      head: {
        color: "#666666",
        padding: "4px 24px 4px 15px",
        "&:nth-child(7)": {
          textAlign: "center",
        },
      },
      body: {
        color: "#666666",
        padding: "4px 15px",
        "&:nth-child(14)": {
          textAlign: "center",
          color: "#ffffff",
        },
      },
    },
  },
});

class TableProductGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      reqParams: "",
    };
    this.delayedCallback = lodash.debounce(this.getData, 500);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {
    const { tableReload, tableReset, dispatch } = this.props;
    if (tableReload || tableReset) {
      this.getData();
      dispatch({ type: saleProductGroupConstants.TABLE_RELOAD_SUCCESS });
    }
  }

  getRowData(rowIndex = 0) {
    const { tableData } = this.props;
    const data = tableData.product_group;
    return data[rowIndex];
  }

  getData = (skip, limit, sortBy, sortDir, keyword) => {
    const { dispatch, tableReload, tableData } = this.props;
    let reqParams = "";

    if (!tableReload) {
      if (crmValidator.isEmptyValue(skip)) {
        skip = 0;
      }

      if (crmValidator.isEmptyValue(limit)) {
        limit = 10;
      }

      this.setState({
        page: skip,
        rowsPerPage: limit,
      });
      skip = parseInt(limit) * parseInt(skip);
      if (tableData.total !== 0 && tableData.total === skip) {
        skip = skip - limit;
        this.setState({
          page: this.state.page - 1,
        });
      }
      if (skip >= tableData.total) {
        skip = 0;
        this.setState({
          page: 0,
        });
      }
      this.setState({
        reqParams: {
          skip: skip,
          limit: limit,
        },
      });
      reqParams += "&skip=" + skip;
      reqParams += "&limit=" + limit;

      if (!crmValidator.isEmptyValue(sortBy)) {
        if (sortBy === "startDateEndDate") {
          sortBy = "startDate";
        }
        reqParams += "&sortBy=" + sortBy;
      }

      if (!crmValidator.isEmptyValue(sortDir)) {
        reqParams += "&sortDir=" + sortDir;
      }

      if (!crmValidator.isEmptyValue(keyword)) {
        reqParams += "&keyword=" + keyword;
      }
    } else {
      reqParams = this.state.reqParams;
    }
    dispatch(saleProductGroupActions.getListsSaleProductGroup(reqParams));
    this.setState({
      reqParams: reqParams,
    });
  };

  openFormAdd = () => {
    const { dispatch } = this.props;
    dispatch({
      type: saleProductGroupConstants.OPEN_FORM_ADD,
      formInitialValue: { status: "Active" },
    });
  };

  openFormEdit(index) {
    const { dispatch } = this.props;
    const tmp = this.getRowData(index);
    dispatch(success(tmp));
    function success(tmp) {
      return { type: saleProductGroupConstants.OPEN_FORM_EDIT_SALEPRODUCTGROUP, tmp }
    }
  }
  render() {
    const {
      classes,
      tableKey,
      tableData,
      formInitialValue,
      dispatch,
      visiblePersonalData,
      showLoader,
    } = this.props;
    const { page, rowsPerPage } = this.state;
    let columns = [
      { name: "_id", options: { display: "excluded" } },
      { name: "name", label: "Product Group Name" },
      {
        label: "Action",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className={classes.actionDiv}>
                <TableActionButton
                  id="btn_edit"
                  actionType="edit"
                  onClick={(e) => {
                    this.openFormEdit(tableMeta.rowIndex);
                  }}
                />

                <TableActionButton
                  id="btn_delete"
                  actionType="delete"
                  onClick={(e) => {
                    dispatch({
                      type: swalConstants.SHOW,
                      data: {
                        type: "warning",
                        title: i18n.t(
                          "warning_messages.are_you_sure_delete_sale_product_group"
                        ),
                        message: `${this.getRowData(tableMeta.rowIndex).name}`,
                        onConfirm: function () {
                          let reqParams = {
                            _id: tableMeta.rowData[0],
                          };
                          dispatch({ type: swalConstants.HIDE });
                          dispatch(
                            saleProductGroupActions.deleteSaleProductGroup(
                              reqParams
                            )
                          );
                        },
                        onCancel: function () {
                          dispatch({ type: swalConstants.HIDE });
                        },
                        showCancel: true,
                        confirmBtnText: i18n.t("delete"),
                        cancelBtnText: i18n.t("cancel"),
                      },
                    });
                  }}
                />
              </div>
            );
          },
        },
      },
    ];

    let options = {
      serverSide: true,
      count: tableData.total,
      page: page,
      rowsPerPage: rowsPerPage,
      selectableRows: "none",
      print: false,
      download: false,
      filter: false,
      responsive: "scroll",
      rowsPerPageOptions: [5, 10, 20],
      onTableChange: (action, tableState) => {
        let skip,
          limit,
          sortBy,
          sortDir,
          keyword = "";

        if (tableState.page !== null) {
          skip = tableState.page;
        }

        if (tableState.rowsPerPage !== null) {
          limit = tableState.rowsPerPage;
        }

        if (tableState.activeColumn !== null) {
          sortBy = tableState.columns[tableState.activeColumn].name;
        }
        if (tableState.announceText !== null) {
          sortDir =
            tableState.announceText.search("descending") !== -1
              ? "desc"
              : "asc";
        }
        if (tableState.searchText !== null || action === "search") {
          keyword = tableState.searchText;
          this.delayedCallback(skip, limit, sortBy, sortDir, keyword);
        }
        let actionList = ["changePage", "sort", "changeRowsPerPage"];
        if (actionList.indexOf(action) !== -1) {
          this.getData(skip, limit, sortBy, sortDir, keyword);
        }
      },
      customToolbar: () => {
        return (
          <CustomToolbar
            handleClick={this.openFormAdd.bind(this)}
            title="Add New"
            id="btn_add"
          />
        );
      },
    };
    return (
      <div id="tableContentManagementList">
        <CustomSweetAlert />
        <ProductGroupForm initialValues={formInitialValue} />
        <MuiThemeProvider theme={THEME}>
          <MUIDataTable
            key={tableKey}
            title={"Product Group List"}
            data={tableData.product_group}
            columns={columns}
            options={options}
          />
          {showLoader && <TableLoaderSpinner />}
        </MuiThemeProvider>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    tableKey,
    tableData,
    formInitialValue,
    tableReset,
    showLoader,
    tableFilter,
    tableReload,
  } = state.saleProductGroup;
  const { permission, superAdmin, visiblePersonalData } = state.authentication;
  return {
    permission,
    superAdmin,
    visiblePersonalData,
    tableKey,
    tableData,
    formInitialValue,
    tableReset,
    showLoader,
    tableFilter,
    tableReload,
  };
}

export default connect(mapStateToProps)(
  withStyles(pageStyle)(TableProductGroup)
);

import React from 'react'
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset, change } from 'redux-form';
import { crmValidator, plugins, normalize } from 'helpers';
import lodash from 'lodash';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect";

import Radio from '@material-ui/core/Radio'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from "@material-ui/core/IconButton";
import { rfmConfigActions } from 'actions';

import { rfmConfigConstants } from '../../constants';
import moment from 'moment';
import HelpOutline from '@material-ui/icons/HelpOutline'
// import DialogExampleWinnerConfig from './DialogExampleWinnerConfig'

const FORM_NAME = 'winner_config_setting'
const moduleName = 'datafilteringList'

const pageStyle = {
    ...modalStyle,
    hoverPointer: {
        "&:hover": {
            cursor: "pointer"
        }
    }
};

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    // cardIconTitle: {
    //     ...cardTitle,
    //     marginTop: "15px",
    //     marginBottom: "0px"
    // },
    actionDiv: {
        display: "inline-flex"
    }
};


const muiRadio = ({ ...props }) => {
    const { input, disabled } = props
    // console.log('input', input);
    return (
        <Radio
            onChange={input.onChange}
            name={input.name}
            id={input.name + "_" + input.value}
            disabled={disabled}
            {...input}
        />
    )
}

class RfmConfigContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reqParams: "",
            rfmConfig: [],
            databaseFieldsList: [],
            tbNameSelected: [],
            dataloop: [],
            rfmConfigRecencyColumn: [],
            rfmConfigFrequencyColumn: [],
            rfmConfigMonetaryColumn: [],
            formInitialValue: [],
        };
    }

    handleSubmit = (values) => {
        const { dispatch, rfmConfig, databaseFieldsList } = this.props;
        // console.log("databaseFieldsList : ", databaseFieldsList);
        if (!lodash.isEmpty(databaseFieldsList)) {
            // console.log("values : ", values);
            let data = {};
            data = {
                "period": {
                    "start_date": moment(values.start_date).format("YYYY-MM-DD"),
                    "operator": values.type
                },
                "recency": {
                    "table": values.recencyTable.data,
                    "column": values.recencyColumn
                },
                "frequency": {
                    "table": values.frequencyTable.data,
                    "column": values.frequencyColumn
                },
                "monetary": {
                    "table": values.monetaryTable.data,
                    "column": values.monetaryColumn
                }
            }
            console.log("databaseFieldsList 1")
            dispatch(rfmConfigActions.editRfmConfig(data))
        }
        else {
            let data = {};
            data = {
                "period": {
                    "start_date": moment(values.start_date).format("YYYY-MM-DD"),
                    "operator": values.type
                },
                "recency": {
                    "table": values.recencyTable.data,
                    "column": values.recencyColumn
                },
                "frequency": {
                    "table": values.frequencyTable.data,
                    "column": values.frequencyColumn
                },
                "monetary": {
                    "table": values.monetaryTable.data,
                    "column": values.monetaryColumn
                }
            }
            // console.log("databaseFieldsList 2")
            dispatch(rfmConfigActions.saveRfmConfig(data))
        }
    }

    getRecencyColumn = (e) => {
        const { rfmConfigRecencyColumn } = this.state;
        let resultfield = []
        console.log("log : ", e);
        e.target.value.fields.map((field) => {
            resultfield.push({ value: field.fieldName, label: field.displayName, operation: field.operator })
        })

        this.setState({
            rfmConfigRecencyColumn: resultfield
        });

        console.log("rfmConfigColumn 1: ", rfmConfigRecencyColumn);
    }

    getRecencyColumnEdit = (data) => {
        const { rfmConfigRecencyColumn } = this.state;

        let resultfield = []
        // console.log("getRecencyColumnEdit : ", data);
        data.map((field) => {
            resultfield.push({ value: field.fieldName, label: field.displayName, operation: field.operator })
        })
        // console.log("rfmConfigColumn 2: ", resultfield);
        // rfmConfigRecencyColumn = resultfield
        console.log("Edit rfmConfigRecencyColumn : ", rfmConfigRecencyColumn);
        // this.setState({
        //     rfmConfigRecencyColumn: resultfield
        // });
    }

    getFrequencyColumn = (e) => {
        const { rfmConfigFrequencyColumn } = this.state;
        let resultfield = []

        e.target.value.fields.map((field) => {
            resultfield.push({ value: field.fieldName, label: field.displayName, operation: field.operator })
        })

        this.setState({
            rfmConfigFrequencyColumn: resultfield
        });

        // console.log("rfmConfigColumn : ", rfmConfigRecencyColumn);
    }
    // getMonetaryColumn
    getMonetaryColumn = (e) => {
        const { rfmConfigMonetaryColumn } = this.state;
        let resultfield = []

        e.target.value.fields.map((field) => {
            resultfield.push({ value: field.fieldName, label: field.displayName, operation: field.operator })
        })

        this.setState({
            rfmConfigMonetaryColumn: resultfield
        });

        // console.log("rfmConfigColumn : ", rfmConfigRecencyColumn);
    }

    async componentDidMount() {
        await this.callapi();
        // const { formMode, dispatch, fieldsList, rfmConfig, databaseFieldsList, formInitialValue, initialValues } = this.props;
        // console.log("componentDidMount formInitialValue : ", formInitialValue)
    }

    async componentDidUpdate() {
        // await this.callapi();
        // const { formMode, dispatch, fieldsList, rfmConfig, databaseFieldsList, formInitialValue, initialValues } = this.props;
        // console.log("componentDidUpdate formInitialValue : ", formInitialValue)
        // this.setState({
        //     formInitialValue: formInitialValue,
        // });


    }

    async callapi() {
        const { formMode, dispatch, fieldsList, rfmConfig, databaseFieldsList, formInitialValue, initialValues } = this.props;
        dispatch(await rfmConfigActions.getTableTemplateList());  // /list/table-template
        this.setState({
            rfmConfig: rfmConfig,
            databaseFieldsList: databaseFieldsList
        });

        dispatch(await rfmConfigActions.getTableConfig());  // /table-config/list

        // console.log("callapi formInitialValue : ", formInitialValue)
        // this.setState({
        //     formInitialValue: formInitialValue,
        // });


        // const { formInitialValue } = this.state;
        console.log("callapi formInitialValue : ", formInitialValue)


        // if (lodash.isEmpty(rfmConfig.formInitialValue)) {
        //     console.log("rfmConfig.formInitialValue 1")
        // }
    }

    openRecencyDetail() {
        const { dispatch } = this.props;
        // const { userAgent, earnChannel } = rowData
        // let data = {
        //     userAgent: userAgent,
        //     userChannel: earnChannel
        // }
        dispatch({ type: rfmConfigConstants.OPEN_RECENCY_DETAIL })
    }

    render() {
        const { classes, winner_case, handleSubmit, rfmConfig, dispatch, databaseFieldsList, formInitialValue } = this.props
        const { rfmConfigRecencyColumn, rfmConfigFrequencyColumn, rfmConfigMonetaryColumn } = this.state;

        let recencyColumnList = []
        let frequencyColumnList = []
        let monetaryColumnList = []

        if (!lodash.isEmpty(formInitialValue)) {
            rfmConfig.map((field) => {
                //console.log("render field.label : ", field);
                if (field.label === formInitialValue.recencyTable.data) {

                    console.log("render formInitialValue.recencyTable.data : ", formInitialValue.recencyTable.data);
                    let resultfield = []
                    field.value.fields.map((field) => {
                        resultfield.push({ value: field.fieldName, label: field.displayName, operation: field.operator })
                    })
                    recencyColumnList = resultfield
                }
                if (field.label === formInitialValue.frequencyTable.data) {
                    console.log("render formInitialValue.frequencyTable.data : ", formInitialValue.frequencyTable.data);
                    let resultfield = []
                    // console.log("getRecencyColumnEdit : ", data);
                    field.value.fields.map((field) => {
                        resultfield.push({ value: field.fieldName, label: field.displayName, operation: field.operator })
                    })

                    frequencyColumnList = resultfield
                }
                if (field.label === formInitialValue.monetaryTable.data) {
                    console.log("render formInitialValue.monetaryTable.data : ", formInitialValue.monetaryTable.data);
                    let resultfield = []
                    field.value.fields.map((field) => {
                        resultfield.push({ value: field.fieldName, label: field.displayName, operation: field.operator })
                    })
                    monetaryColumnList = resultfield
                }
            })
        }

        if (!lodash.isEmpty(rfmConfigRecencyColumn)) {
            recencyColumnList = rfmConfigRecencyColumn
        }

        if (!lodash.isEmpty(rfmConfigFrequencyColumn)) {
            frequencyColumnList = rfmConfigFrequencyColumn
        }

        if (!lodash.isEmpty(rfmConfigMonetaryColumn)) {
            monetaryColumnList = rfmConfigMonetaryColumn
        }

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader>
                            <h3
                                className={classes.cardIconTitle}
                                style={{ marginLeft: "15px" }}
                            >
                                <b>RFM Analysis : Define Period and Factors</b>
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)} style={{ paddingLeft: "20px" }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <h5 style={{ paddingTop: "20px" }}><b>Period</b></h5>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <div style={{ paddingTop: "5px" }}>
                                            <Field
                                                name="start_date"
                                                component={CustomDatePicker}
                                                type="text"
                                                // maxDate={startMaxDate}
                                                // labelText="Announce Date/Time"
                                                dateFormat="DD-MMM-YY"
                                                // timeFormat="HH:mm:ss"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                required
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <Field
                                            name="type"
                                            component={CustomSelect}
                                            type="text"
                                            // labelText="Reward Type"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            optionsList={[
                                                {
                                                    value: "Years",
                                                    label: "Years"
                                                },
                                                {
                                                    value: "Months",
                                                    label: "Months"
                                                },
                                                {
                                                    value: "Weeks",
                                                    label: "Weeks"
                                                },
                                                {
                                                    value: "Days",
                                                    label: "Days"
                                                }
                                            ]}
                                            required
                                        // disabled={readOnly}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>

                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <div style={{ paddingTop: "60px" }}>
                                            <h5>
                                                <b>RECENCY</b>
                                                {/* <IconButton color="primary" className="" onClick={this.openRecencyDetail()}>
                                                    <InfoOutlinedIcon />
                                                </IconButton> */}
                                            </h5>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <div style={{ paddingTop: "20px" }}>
                                            <Field
                                                // id={`${FORM_NAME}_dbTemplate`} RECENCY recency

                                                name="recencyTable"
                                                component={CustomSelect}
                                                labelText="Table"
                                                // labelText={i18n.t("datafiltering.table_template")}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                // onChange={(e) => {
                                                //     e.preventDefault();
                                                //     let selectedValue = e.target.value

                                                //     console.log("selectedValue : ", selectedValue);
                                                //     console.log("FORM_NAME : ", FORM_NAME);

                                                //     if (lodash.last(selectedValue) === 'All') {
                                                //         console.log("num 1");
                                                //         dispatch(change(FORM_NAME, 'dbtabletemplate', ['All']))
                                                //     } else {
                                                //         console.log("num 2");
                                                //         if (lodash.indexOf(selectedValue, 'All') >= 0) {
                                                //             console.log("num 3");
                                                //             selectedValue.shift();
                                                //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                                //         } else {
                                                //             console.log("num 4");
                                                //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                                //         }
                                                //     }
                                                // }}
                                                onChange={(e) => {
                                                    // console.log("e : ", e);
                                                    this.getRecencyColumn(e)
                                                }}
                                                required
                                                // showtick
                                                optionsList={rfmConfig}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <div style={{ paddingTop: "20px" }}>
                                            <Field
                                                // id={`${FORM_NAME}_dbTemplate`}
                                                name="recencyColumn"
                                                component={CustomSelect}
                                                labelText="Column"
                                                // labelText={i18n.t("datafiltering.table_template")}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                // onChange={(e) => {
                                                //     e.preventDefault();
                                                //     let selectedValue = e.target.value

                                                //     if (lodash.last(selectedValue) === 'All') {
                                                //         dispatch(change(FORM_NAME, 'dbtabletemplate', ['All']))
                                                //     } else {
                                                //         if (lodash.indexOf(selectedValue, 'All') >= 0) {
                                                //             selectedValue.shift();
                                                //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                                //         } else {
                                                //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                                //         }
                                                //     }
                                                // }}
                                                // showtick
                                                optionsList={recencyColumnList}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>

                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <div style={{ paddingTop: "20px" }}>
                                            <h5 ><b>FREQUENCY</b></h5>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        {/* FREQUENCY frequency */}
                                        <Field
                                            // id={`${FORM_NAME}_dbTemplate`} RECENCY recency

                                            name="frequencyTable"
                                            component={CustomSelect}
                                            labelText="Table"
                                            // labelText={i18n.t("datafiltering.table_template")}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            // onChange={(e) => {
                                            //     e.preventDefault();
                                            //     let selectedValue = e.target.value

                                            //     console.log("selectedValue : ", selectedValue);
                                            //     console.log("FORM_NAME : ", FORM_NAME);

                                            //     if (lodash.last(selectedValue) === 'All') {
                                            //         console.log("num 1");
                                            //         dispatch(change(FORM_NAME, 'dbtabletemplate', ['All']))
                                            //     } else {
                                            //         console.log("num 2");
                                            //         if (lodash.indexOf(selectedValue, 'All') >= 0) {
                                            //             console.log("num 3");
                                            //             selectedValue.shift();
                                            //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                            //         } else {
                                            //             console.log("num 4");
                                            //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                            //         }
                                            //     }
                                            // }}
                                            onChange={(e) => {
                                                this.getFrequencyColumn(e)
                                            }}

                                            // showtick
                                            optionsList={rfmConfig}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <Field
                                            // id={`${FORM_NAME}_dbTemplate`}
                                            name="frequencyColumn"
                                            component={CustomSelect}
                                            labelText="Column"
                                            // labelText={i18n.t("datafiltering.table_template")}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            // onChange={(e) => {
                                            //     e.preventDefault();
                                            //     let selectedValue = e.target.value

                                            //     if (lodash.last(selectedValue) === 'All') {
                                            //         dispatch(change(FORM_NAME, 'dbtabletemplate', ['All']))
                                            //     } else {
                                            //         if (lodash.indexOf(selectedValue, 'All') >= 0) {
                                            //             selectedValue.shift();
                                            //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                            //         } else {
                                            //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                            //         }
                                            //     }
                                            // }}
                                            // showtick
                                            optionsList={frequencyColumnList}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>

                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <div style={{ paddingTop: "20px" }}>
                                            <h5 ><b>MONETARY</b></h5>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        {/* MONETARY  monetaryTable */}
                                        <Field
                                            // id={`${FORM_NAME}_dbTemplate`} RECENCY recency
                                            name="monetaryTable"
                                            component={CustomSelect}
                                            labelText="Table"
                                            // labelText={i18n.t("datafiltering.table_template")}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            // onChange={(e) => {
                                            //     e.preventDefault();
                                            //     let selectedValue = e.target.value

                                            //     console.log("selectedValue : ", selectedValue);
                                            //     console.log("FORM_NAME : ", FORM_NAME);

                                            //     if (lodash.last(selectedValue) === 'All') {
                                            //         console.log("num 1");
                                            //         dispatch(change(FORM_NAME, 'dbtabletemplate', ['All']))
                                            //     } else {
                                            //         console.log("num 2");
                                            //         if (lodash.indexOf(selectedValue, 'All') >= 0) {
                                            //             console.log("num 3");
                                            //             selectedValue.shift();
                                            //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                            //         } else {
                                            //             console.log("num 4");
                                            //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                            //         }
                                            //     }
                                            // }}
                                            onChange={(e) => {
                                                this.getMonetaryColumn(e)
                                            }}

                                            // showtick
                                            optionsList={rfmConfig}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={2}>
                                        <Field
                                            // id={`${FORM_NAME}_dbTemplate`}
                                            name="monetaryColumn"
                                            component={CustomSelect}
                                            labelText="Column"
                                            // labelText={i18n.t("datafiltering.table_template")}
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            // onChange={(e) => {
                                            //     e.preventDefault();
                                            //     let selectedValue = e.target.value

                                            //     if (lodash.last(selectedValue) === 'All') {
                                            //         dispatch(change(FORM_NAME, 'dbtabletemplate', ['All']))
                                            //     } else {
                                            //         if (lodash.indexOf(selectedValue, 'All') >= 0) {
                                            //             selectedValue.shift();
                                            //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                            //         } else {
                                            //             dispatch(change(FORM_NAME, 'dbtabletemplate', selectedValue))
                                            //         }
                                            //     }
                                            // }}
                                            // showtick
                                            optionsList={monetaryColumnList}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>

                                    </GridItem>
                                    {/* ///////////////// */}
                                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: "50px", marginBottom: "50px" }}>
                                        {/* {plugins.isHasPermission(moduleName, 'update') && */}
                                        <Button
                                            id='btn_submit_form'
                                            type="submit"
                                            style={{ marginRight: "10px" }}
                                            color="primary">
                                            Submit
                                            </Button>
                                        {/* } */}
                                        <Button
                                            id='btn_cancel_form'
                                            type="button"
                                            style={{ marginRight: "10px" }}
                                            // onClick={() => {
                                            //     const { dispatch } = this.props
                                            //     dispatch(reset(FORM_NAME));
                                            //     dispatch(rfmConfigActions.getWinnerConfigSetting())
                                            // }}
                                            color="white">
                                            Cancel
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
                {/* <DialogExampleWinnerConfig /> */}
            </GridContainer>
        )
    }
}

function mapStateToProps(state) {
    const { setting, rfmConfig, databaseFieldsList, formInitialValue } = state.rfmConfig
    return { setting, rfmConfig, databaseFieldsList, formInitialValue }
}

const connectedComponent = connect(mapStateToProps)(RfmConfigContainer);

let RfmConfigContainerForm = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors);
    }
})(withStyles(pageStyle)(connectedComponent))

const selector = formValueSelector(FORM_NAME)

RfmConfigContainerForm = connect(state => {
    const rfmConfig = selector(state, 'rfmConfig')
    return {
        initialValues: lodash.get(state, 'rfmConfig.formInitialValue'),
        // rfmConfig
    }
})(RfmConfigContainerForm)

export default RfmConfigContainerForm

// let RfmConfigContainerForm = reduxForm({
//     form: FORM_NAME,
//     enableReinitialize: true,
//     onSubmitFail: (errors, dispatch, props) => {
//         crmValidator.alertFirstInvalid(FORM_NAME, errors);
//     }
// })(withStyles(pageStyle)(connectedComponent))

// const selector = formValueSelector(FORM_NAME)

// RfmConfigContainerForm = connect(state => {
//     const winner_case = selector(state, 'winner_case')
//     return {
//         initialValues: lodash.get(state, 'winner_config.setting'),
//         winner_case
//     }
// })(RfmConfigContainerForm)

// export default RfmConfigContainerForm
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, arrayPush, getFormValues, FieldArray, SubmissionError } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'
import withStyles from "@material-ui/core/styles/withStyles";

// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"

import { promotionConstants } from "../../../constants"
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import red from '@material-ui/core/colors/red';

const FORM_NAME = 'promotion_payment_channel_form'

const style = {
    ...modalStyle
}

const form_validate = {
}

const requiredPaymentChannel = (value) => {
    if (value === undefined || value === null || lodash.size(value) === 0) {
        return "Please add at least 1 channel"
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const validateRequired = (value) => {
    if (lodash.trim(value) === "") {
        return "Payment Channel is required"
    }
}

const maxLength35 = (value) => {
    if(value) {
        if(value.length >= 35) {
            return "Payment Channel must not be more than 35 characters"
        }
    }
}

class ConditionPaymentChannel extends React.Component {

    handleSubmit(values) {
        const { dispatch, promotionFormValues } = this.props
        let conditions = lodash.get(promotionFormValues, 'conditions')

        let paymentChValues = lodash.get(values, 'paymentChannel')
        if (lodash.isArray(paymentChValues) && lodash.size(paymentChValues) > 0) {
            lodash.remove(conditions, function (n) {
                return n.type === 'payment_channel'
            });

            dispatch(arrayPush('promotion_form', 'conditions', {
                type: 'payment_channel',
                paymentChannel: lodash.get(values, 'paymentChannel')
            }))

            dispatch({ type: promotionConstants.CLOSE_PAYMENT_CHANNEL_CONDITION })
        } else {
            throw new SubmissionError({
                "paymentChannel": "Payment Channel is required"
            })
        }
    }

    renderSelectedChannelList = ({ formInvalid, fields, meta: { error } }) => {
        const { formValues, readOnly } = this.props
        let paymentChannelValue = lodash.get(formValues, 'paymentChannel')
        if (lodash.size(paymentChannelValue) >= 1) {
            return (
                <table width="100%" style={{ border: "solid 1px #ccc" }}>
                    <tbody>
                        {fields.map((paymentChannel, index) => (
                            <tr key={index}>
                                <td width="80%" style={{
                                    paddingLeft: "15px",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    color: "#111"
                                }}>
                                    <Field
                                        name={paymentChannel}
                                        component={CustomInput}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        type="text"
                                        disabled={readOnly}
                                        validate={[validateRequired, maxLength35]}
                                    />
                                </td>
                                <td>
                                    {!readOnly &&
                                        <TableActionButton
                                            actionType="delete"
                                            onClick={() => {
                                                fields.remove(index)
                                            }}
                                        />
                                    }
                                    {(index + 1) < lodash.size(paymentChannelValue) && <b>OR</b>}
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            )
        } else {
            return (
                <table width="100%" style={{ border: "solid 1px", borderColor: (formInvalid ? red[500] : "#ccc") }}>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "center", height: "55px" }}>
                                {formInvalid ?
                                    <span style={{ color: red[500] }}>
                                        Please add at least one payment channel.
                                    </span>
                                    :
                                    <span style={{
                                        color: "#aaa",
                                        fontStyle: 'italic'
                                    }}>
                                        No payment channel added.
                                    </span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        }
    }

    render() {
        const { classes, dispatch, paymentChannelConditionOpen, handleSubmit, readOnly } = this.props
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={paymentChannelConditionOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body">
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{ paddingBottom: "0px" }}
                >
                    <h4 className={classes.modalTitle}><b>Payment Channel Condition</b></h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                    <DialogContent
                        id="payment-channel-condition-dialog"
                        className={classes.modalBody}
                    >
                        <GridContainer>
                            <GridItem xs={3}>
                                {!readOnly &&
                                    <Button
                                        type="button"
                                        color="success"
                                        size="sm"
                                        style={{ marginBottom: "15px" }}
                                        onClick={() => {
                                            dispatch(arrayPush(FORM_NAME, 'paymentChannel', ''))
                                        }}>
                                        Add Channel
                                    </Button>
                                }
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12}>
                                <FieldArray
                                    name="paymentChannel"
                                    component={this.renderSelectedChannelList}
                                    validate={[requiredPaymentChannel]}
                                    formInvalid={lodash.get(this.props, 'submitFailed')}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        {!readOnly &&
                            <Button
                                id={`payment_channel_condition_btn_submit`}
                                type="submit"
                                style={{ marginRight: "10px" }}
                                color="primary">
                                Submit
                        </Button>
                        }
                        <Button
                            id={`payment_channel_condition_btn_cancel`}
                            type="button"
                            onClick={() => {
                                dispatch({ type: promotionConstants.CLOSE_PAYMENT_CHANNEL_CONDITION });
                            }}
                            color="white">
                            Cancel
                    </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { paymentChannelConditionOpen } = state.promotion;
    const promotionFormValues = getFormValues('promotion_form')(state)
    const formValues = getFormValues(FORM_NAME)(state)
    return {
        promotionFormValues, paymentChannelConditionOpen, formValues
    };
}

const connectedComponent = connect(mapStateToProps)(ConditionPaymentChannel);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: false,
})(withStyles(style)(connectedComponent))

import { memberLevelConstants } from '../constants';
import lodash from 'lodash'

const initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    formConditionInitialValue: {},
    tableKey: new Date(),
    tableReload: false,
    tableReset: false,
    showLoader: false,
    isClear: false,
    showLoaderRanking: false,
    showPurchase: false,
    isSubmitRank: false,
    newSubmit: false,
    memberLevelCondition: [],
    rankingList: [],
    sortedData: [],
    tableDatas: {
        lists: [],
        total: 0
    },
    isSuccess: false,
    isReset: false,
    memberLevelList: {},
    collectList: []
}

const mockData = (data) => {
    let tmpValue = {}
    if (data) {
        for (let obj of data) {
            tmpValue[obj._id] = obj.amount || 0
        }
    }

    return tmpValue
}

export function memberLevel(state = initialState, action) {
    switch (action.type) {
        case memberLevelConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case memberLevelConstants.OPEN_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue
            }
        case memberLevelConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formMode: 'add',
                formInitialValue: {}
            }
        case memberLevelConstants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case memberLevelConstants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case memberLevelConstants.SHOW_LOADER_RANK:
            return {
                ...state,
                showLoaderRanking: true
            }
        case memberLevelConstants.HIDE_LOADER_RANK:
            return {
                ...state,
                isSuccess: false,
                showLoaderRanking: false
            }
        case memberLevelConstants.GET_DATA_SUCCESS:
            return {
                ...state,
                tableDatas: {
                    lists: lodash.has(action, 'tmp.memberLevels') ? action.tmp.memberLevels : [],
                    total: lodash.has(action, 'tmp.total') ? action.tmp.total : 0
                },
                memberLevelList: lodash.has(action, 'tmp.memberLevels') ? action.tmp.memberLevels : [],
                formInitialValue: {}
            }
        case memberLevelConstants.ADD_NEW_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                formInitialValue: {}
            }
        case memberLevelConstants.EDIT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case memberLevelConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReset: false,
                tableReload: false
            }
        case memberLevelConstants.DELETE_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case memberLevelConstants.SUBMIT_RANKING:
            return {
                ...state,
                isSubmitRank: true,
                newSubmit: true,
                sortedData: JSON.parse(JSON.stringify(action.data)).reverse(),
                formConditionInitialValue: {
                    periodReaching: "NO_LIMIT",
                    resetMemberLevel: "NEVER",
                    downGrade: "true",
                    levelUp: "day",
                    selectedDate: "selectedDate",
                    collectWithin: "day",
                    purchaseAmountChecked: false,
                    numberTimeChecked: false,
                    earnPointChecked: false,
                    numberActiveChecked: false,
                    type_numberTime: "input_code",
                    ruleBase: "OR",
                    activeProductPurchase: mockData(lodash.get(action.tmp, "memberLevelCondition[shifting][activeProductPurchase][list]")),
                    earnPoint: mockData(lodash.get(action.tmp, "memberLevelCondition[shifting][earnPoint][list]")),
                    purchaseAmount: mockData(lodash.get(action.tmp, "memberLevelCondition[shifting][purchaseAmount][list]")),
                    successCodePurchase: mockData(lodash.get(action.tmp, "memberLevelCondition[shifting][successCodePurchase][list]")),
                    _v: new Date()
                },
                collectList: [{
                    value: "day",
                    label: "Day",
                },
                {
                    value: "month",
                    label: "Month",
                },
                {
                    value: "year",
                    label: "Year",
                },
                ],
                rankingList: action.data,
                showPurchase: false,
                isReset: false
            }
        case memberLevelConstants.SET_SUBMIT_RANKING:
            return {
                ...state,
                isSubmitRank: false,
                isReset: false
            }
        case memberLevelConstants.SET_CLEAR_RANKING:
            return {
                ...state,
                isClear: false,
                isReset: false
            }
        case memberLevelConstants.RESET_RANK:
            return {
                ...state,
                isSubmitRank: false,
                isClear: true,
                reloadRank: false,
                isReset: false
                // sortedData: [],
                // formConditionInitialValue: {}
            }
        case memberLevelConstants.GET_RANK_DATA_SUCCESS:
            const data = {
                downGrade: lodash.get(action.tmp, "memberLevelCondition.period.downGrade") === true ? "true" : "false",
                ruleBase: lodash.get(action.tmp, "memberLevelCondition.shifting.condition"),
                purchaseAmountChecked: lodash.get(action.tmp.memberLevelCondition, "shifting.purchaseAmount.checked"),
                numberTimeChecked: lodash.get(action.tmp.memberLevelCondition, "shifting.successCodePurchase.checked"),
                earnPointChecked: lodash.get(action.tmp.memberLevelCondition, "shifting.earnPoint.checked"),
                numberActiveChecked: lodash.get(action.tmp.memberLevelCondition, "shifting.activeProductPurchase.checked"),
                type_numberTime: lodash.get(action.tmp.memberLevelCondition, "shifting.successCodePurchase.type"),
                periodReaching: lodash.get(action.tmp.memberLevelCondition, "period.limit") === true ? "LIMITTED" : "NO_LIMIT",
                collectEvery: lodash.get(action.tmp.memberLevelCondition, "period.limitTime.amount"),
                collectWithin: lodash.get(action.tmp.memberLevelCondition, "period.limitTime.type"),
                selectedDate: lodash.get(action.tmp.memberLevelCondition, "period.limitTime.start.activity"),
                selectedCountDate: lodash.get(action.tmp.memberLevelCondition, "period.limitTime.start.selectedDate"),
                levelUp: lodash.get(action.tmp.memberLevelCondition, "period.rearrangeTime"),
                resetMemberLevel: lodash.get(action.tmp.memberLevelCondition, "reset.checked") === true ? "YES" : "NEVER",
                whenReset: lodash.get(action.tmp.memberLevelCondition, "reset.time") === "selectedDate" ? "reset_selected_date" : "reset_end_of",
                selected_reset_date: lodash.get(action.tmp.memberLevelCondition, "reset.selectedDate"),
                reset_every_end_of: lodash.get(action.tmp.memberLevelCondition, "reset.time") !== "selectedDate" && lodash.get(action.tmp.memberLevelCondition, "reset.time"),
                activeProductPurchase: mockData(lodash.get(action.tmp, "memberLevelCondition[shifting][activeProductPurchase][list]")),
                earnPoint: mockData(lodash.get(action.tmp, "memberLevelCondition[shifting][earnPoint][list]")),
                purchaseAmount: mockData(lodash.get(action.tmp, "memberLevelCondition[shifting][purchaseAmount][list]")),
                successCodePurchase: mockData(lodash.get(action.tmp, "memberLevelCondition[shifting][successCodePurchase][list]")),
                _v: new Date()
            }

            let list = []
            if (lodash.isEqualWith(data.levelUp, 'month')) {
                list = [{
                    value: "month",
                    label: "Month",
                },
                {
                    value: "year",
                    label: "Year",
                },
                ]
            } else {
                list = [{
                    value: "day",
                    label: "Day",
                },
                {
                    value: "month",
                    label: "Month",
                },
                {
                    value: "year",
                    label: "Year",
                },
                ]
            }

            let sortedData = JSON.stringify(lodash.get(action, 'tmp.memberLevelCondition.rankingList') || [])
            return {
                ...state,
                newSubmit: false,
                collectList: list,
                isSuccess: true,
                isReset:  true,
                formConditionInitialValue: data,
                memberLevelCondition: lodash.get(action.tmp, "memberLevelCondition"),
                rankingList: lodash.has(action, 'tmp.memberLevelCondition.rankingList') ? lodash.get(action, 'tmp.memberLevelCondition.rankingList') : [],
                sortedData: JSON.parse(sortedData).reverse(),
                onSubmitRank: lodash.has(action, 'tmp.memberLevelCondition.rankingList') ? true : false,
            }
        default:
            return state
    }
}
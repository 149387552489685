import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'
import moment from 'moment';

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";

import { dataFilteringResultConstants } from '../../../constants';
import { dataFilteringResultAction, memberActions } from '../../../actions';

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import i18n from 'i18n';


const FORM_NAME = 'FORM_DATAFILTERING_TAG';

const form_validate = {
    "tagList": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class FormDataFilteringAssignTag extends React.Component {
    handleSubmit = (values) => {
        const { dispatch } = this.props
        var reqParams = {
            "tagLists": values.tagList,
            "conditionId": values.conditionId,
            "resultId": values.resultId
        }
        dispatch(dataFilteringResultAction.assignTag(reqParams));
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(memberActions.getActiveTagList())
    }

    render() {
        const { classes, formAssignTagOpen, handleSubmit, formTaglist } = this.props

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formAssignTagOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>Assign Campaign</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="resultId"
                                    component="input"
                                    type="hidden"
                                    disabled={true}
                                />

                                <Field
                                    name="conditionId"
                                    component="input"
                                    type="hidden"
                                    disabled={true}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                       name="tagList"
                                       component={TagsMultiSelect}
                                       labelText={i18n.t("label.tag")}
                                       formControlProps={{
                                          fullWidth: true
                                       }}
                                       showtick
                                       optionsList={formTaglist}
                                    />
                            </GridItem>

                        </GridContainer>

                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>

                        <Button
                            style={{ marginRight: "10px" }}
                            color="primary"
                            type="submit">
                            Save
                        </Button>

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: dataFilteringResultConstants.CLOSE_FORM_ASSIGN_TAG })
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formAssignTagOpen } = state.dataFilteringResult;
    const { formTaglist } = state.member;
    const { FORM_DATAFILTERING_TAG } = state.form
    return {
        formAssignTagOpen, FORM_DATAFILTERING_TAG, formTaglist
    };
}

const connectedComponent = connect(mapStateToProps)(FormDataFilteringAssignTag);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))

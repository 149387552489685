import React from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import { masterAdminConstants, swalConstants } from '../../constants';
import { masterAdminActions } from 'actions';
import { crmValidator } from 'helpers';

import AdminForm from "./AdminForm.jsx";
import FormEditPassword from "./FormEditPassword";

import i18n from 'i18n';

const style = {
    actionDiv: {
        displat: "inline-flex"
    },
    actionButton: {
        margin: "0 0 0 5px",
        padding: "5px",
        "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
            marginRight: "0px"
        }
    },
};
const moduleName = 'admin'

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "Active":
            BadgeColor = "success";
            break;
        case "Inactive":
            BadgeColor = "danger";
            break;
        default:
            BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}

class TableAdminList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        };

        this.tableRef = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 500)

    }

    componentWillMount() {
        const { dispatch } = this.props;

        dispatch({ type: masterAdminConstants.CLEAR_PAGE_STATE });
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: masterAdminConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(masterAdminActions.getTableDatas(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.admins;
        return data[rowIndex];
    }

    openFormAdd = () => {
        const { dispatch } = this.props;

        dispatch({ type: masterAdminConstants.OPEN_FORM_ADD, formInitialValue: {} })
    }

    openFormEdit(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.admins[index]

        dispatch(masterAdminActions.getAdminByID(rowData._id));
    }

    openFormEditPassword(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.admins[index]

        dispatch({ type: masterAdminConstants.OPEN_FORM_EDIT_PASSWORD, formInitialValue: { _id: rowData._id } })
    }

    render() {
        const { classes, dispatch, formInitialValue, tableDatas, tableKey, tableLoader, formOpen } = this.props;
        const { rowsPerPage, page } = this.state;

        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            {
                name: 'no.',
                label: i18n.t("label.no"),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            { name: "name", label: i18n.t("label.admin_name") },
            { name: "email", label: i18n.t("Email") },
            { name: "phoneNumber", label: i18n.t("Phone No.") },
            {
                name: "status", label: i18n.t("label.status"), options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return renderBadgeStatus(value)
                    }
                }
            },
            {
                label: i18n.t("label.action"), options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classes.actionDiv}>
                                <TableActionButton
                                    actionType="edit"
                                    onClick={(e) => {
                                        this.openFormEdit(tableMeta.rowIndex)
                                    }}
                                />
                                <TableActionButton
                                    actionType="delete"
                                    onClick={(e) => {
                                        dispatch({
                                            type: swalConstants.SHOW, data: {
                                                type: "warning",
                                                title: i18n.t("warning_messages.are_you_sure_delete_admin"),
                                                message: `${this.getRowData(tableMeta.rowIndex).name}`,
                                                onConfirm: function () {
                                                    let reqParams = {
                                                        _id: tableMeta.rowData[0]
                                                    }
                                                    dispatch({ type: swalConstants.HIDE })
                                                    dispatch(masterAdminActions.deleteAdmin(reqParams));
                                                },
                                                onCancel: function () {
                                                    dispatch({ type: swalConstants.HIDE })
                                                },
                                                showCancel: true,
                                                confirmBtnText: i18n.t("delete"),
                                                cancelBtnText: i18n.t("cancel")
                                            }
                                        })
                                    }}
                                />
                                <TableActionButton
                                    actionType="reset_password"
                                    onClick={(e) => {
                                        this.openFormEditPassword(tableMeta.rowIndex)
                                    }}
                                />
                            </div>
                        )
                    }
                }
            }
        ]

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
            customToolbar: () => {
                return (
                    <CustomToolbar handleClick={this.openFormAdd} title="Add New" />
                );
            }
        };

        return (
            <div id="tableAdminList">
                <CustomSweetAlert />
                {formOpen &&
                    <AdminForm
                        initialValues={formInitialValue}
                    />
                }
                <FormEditPassword initialValues={formInitialValue} />

                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        title={i18n.t("Admin")}
                        data={tableDatas.admins}
                        columns={columns}
                        options={options}
                    />

                    {tableLoader && <TableLoaderSpinner />}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader, formOpen } = state.masterAdmin;
    const { permission, superAdmin } = state.authentication;

    return {
        formInitialValue, tableReload, tableReset, permission, superAdmin, tableDatas, tableKey, tableLoader, formOpen
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableAdminList));
import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { crmValidator } from 'helpers';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
// import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import ImageUpload from './ImageUploader.jsx';

import { listActions, saleProductActions } from 'actions';

import lodash from 'lodash'
import { saleProductConstants } from '../../../constants';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { t } from 'i18next';

import { store } from 'helpers';
import PromotionMultiSelect from "components/CustomSelect/PromotionMultiSelect.jsx";

const FORM_NAME = "FORM_SALEPRODUCT";
const form_validate = {
    "name": {
        rules: {
            required: true
        },
        messages: {
            required: 'Name is required'
        },
        alert: "Name is required"
    },
    "group_title": {
        rules: {
            required: true
        },
        messages: {
            required: 'Group_title is required'
        },
        alert: "Group_title is required"
    },
    "sub_group_title": {
        rules: {
            required: true
        },
        messages: {
            required: 'Sub_group_title is required'
        },
        alert: "sub_group_title is required"
    },
    "description": {
        rules: {
            required: true
        },
        messages: {
            required: 'Description is required'
        },
        alert: "description is required"
    },
    "categoryId": {
        rules: {
            required: true
        },
        messages: {
            required: 'CategoryId is required'
        },
        alert: "categoryId is required"
    },
    "promotionIds": {
        rules: {
            required: true
        },
        messages: {
            required: 'promotionIds is required'
        },
        alert: "promotionIds is required"
    },
    "product": {
        rules: {
            required: true
        },
        messages: {
            required: 'SubGroups is required'
        },
        alert: "SubGroups is required"
    },
};

const form_array_validate = {
    "skuId": {
        rules: {
            required: true
        },
        messages: {
            required: 'SkuId is required'
        },
        alert: "SkuId is required"
    },
    "price": {
        rules: {
            required: true
        },
        messages: {
            required: 'Price is required'
        },
        alert: "Price is required"
    },
    "salesPrice": {
        rules: {
            required: true
        },
        messages: {
            required: 'SalesPrice is required'
        },
        alert: "SalesPrice is required"
    },
    "weightKg": {
        rules: {
            required: true
        },
        messages: {
            required: 'WeightKg is required'
        },
        alert: "WeightKg is required"
    },
    "filePath": {
        rules: {
            required: true
        },
        messages: {
            required: 'FilePath is required'
        },
        alert: "FilePath is required"
    },
};
const validate = (values) => {
    const errors = {
        ...crmValidator.validateByRulesList(form_validate, values),
        product: values.product && values.product.length && values.product.filter((item) => {
                return item.isDefault === true
            }).length != 0 ? values.product.map((item) => {
            return crmValidator.validateByRulesList(form_array_validate, item);
        }) : { _error: 'At least one product must be entered' },
        // TODO : validate checkbox
        // _error: values.product && values.product.length ? values.product.filter((item) => {
        //     return item.isDefault === true
        // }).length != 0 ? undefined : 'At least one product must be entered' : undefined
    };

    return errors
}

const validateBrandId = (value) => {
    if (!value) {
        return "Brand is required"
    }
    return ""
}


class ProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productGroupIndex: null,
        }
    }

    componentDidUpdate(prevProps) {
        const { formOpen, dispatch } = this.props;

        if (formOpen === true && (prevProps.formOpen === false)) {
            this.setState({
                productGroupIndex: null
            })
            dispatch(listActions.getCategoryList('product'));
            dispatch(listActions.getListProductGroup());
            dispatch(listActions.getListBrand());
            dispatch(listActions.getTablePromotionDatas(`skip=0`));
        }
    }


    handleSubmit = (values) => {
        const { dispatch } = this.props;

        const storeData = store.getState().authentication
        const isBrandAdminRole = lodash.get(storeData, 'user.isBrandAdminRole', false)
        const brandId = lodash.get(storeData, 'user.brandId', '')

        if (isBrandAdminRole) {
            values.brandId = brandId
        }

        const value = {
            ...values,
            product: values.product.map((item) => {

                const { filePath } = item
                const isEmp = lodash.isEmpty(filePath)
                if (isEmp) {
                    return {
                        ...item
                    }
                } else {

                    if( typeof filePath === 'object' ){
                        return {
                            ...item,
                            ...filePath
                        }
                    } else{
                        return {
                            ...item,
                            filePath
                        }
                    }
                }
            })
        }

        // Convert json to formdata
        var form_data = new FormData();

        for ( var key in value ) {
            console.log( value[key])
            if(key === "product"){
                let product_encode = Buffer.from( JSON.stringify(value[key]) ).toString('Base64')
                form_data.append(key, product_encode);
            }else if(key === "name" || key === "description"){
                let encode_string = Buffer.from( value[key] ).toString('Base64')
                form_data.append(key, encode_string);
            }else{
                form_data.append(key, value[key]);
            }
        }

        if (this.props.formMode === "add") {
            dispatch(saleProductActions.addSaleProduct(form_data))
        }
        else {
            dispatch(saleProductActions.updateSaleProduct(form_data));
        }
    }

    setStatusDefault = () => {
        const { dispatch } = this.props
        dispatch(change("FORM_SALEPRODUCT", "status", "Active"));
    }

    onCheckBoxChange = (e) => {
        const { dispatch } = this.props
        const { product } = this.props.FORM_SALEPRODUCT.values
        const { checked } = e.target
        if (checked) {
            product.map((item, index) => {
                 dispatch(change("FORM_SALEPRODUCT", `product[${index}].isDefault`, false));
            })
        }

    }

    renderMembers = ({ fields }) => {
        const { classes, readOnly, listProductGroup } = this.props
        const options = listProductGroup ? [
            {
                value: -1,
                label: 'None'
            },
            ...listProductGroup.map((value, index) => {
                return {
                    value: index,
                    label: value.name
                }
            })] : [{
                value: -1,
                label: 'None'
            }]

        return <>
            <GridContainer>
                <GridItem xs={3}>
                </GridItem>
                <GridItem xs>
                    <Field
                        id={`${FORM_NAME}_subGroups`}
                        name="subGroups"
                        component={CustomSelect}
                        labelText={"Product Group"}
                        formControlProps={{
                            fullWidth: true
                        }}
                        optionsList={options}
                        onChange={(e) => {
                            const { value } = e.target
                            this.setState({
                                productGroupIndex: value
                            })
                        }}
                    />
                </GridItem>
                <GridItem xs='auto'>
                    <Button
                        id={`${FORM_NAME}_btn_set`}
                        type="button"
                        style={{ marginRight: "10px" }}
                        color="primary"
                        onClick={() => {
                            const { dispatch } = this.props
                            const { productGroupIndex } = this.state
                            if (productGroupIndex !== null) {
                                if (productGroupIndex === -1) {
                                    fields.removeAll()
                                    fields.push({ groupName: 'None', subGroupName: '',isDefault: true})
                                } else if (productGroupIndex >= 0) {
                                    const noneIndex = fields.getAll()?.findIndex((item) => {
                                        return item.groupName === 'None'
                                    })

                                    if (noneIndex !== -1) {
                                        fields.remove(noneIndex)
                                    }

                                    const { listProductGroup } = this.props
                                    const { name, subgroup } = listProductGroup[productGroupIndex]
                                    subgroup.map((item,index) => {
                                        fields.push({ groupName: name, subGroupName: item.name})
                                    })

                                    fields = fields.getAll()?.map((item,index)=>{
                                        dispatch(change("FORM_SALEPRODUCT", `product[${index}].isDefault`, false));
                                        return item;
                                    })
                                }

                                dispatch(change("FORM_SALEPRODUCT", `product[0].isDefault`, true));
                            }
                        }}>
                        Set
                    </Button>
                </GridItem>
            </GridContainer>

            <GridContainer style={{ overflowY: 'auto' }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>
                                Default Product
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Group
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Subgroup
                            </TableCell>
                            <TableCell className={classes.tableHeadInput}>
                                SKU ID
                            </TableCell>
                            <TableCell className={classes.tableHeadInput}>
                                Price
                            </TableCell>
                            <TableCell className={classes.tableHeadInput}>
                                Sales Price
                            </TableCell>
                            <TableCell className={classes.tableHeadInput}>
                                Weight (kg)
                            </TableCell>
                            <TableCell className={classes.tableHeadInput}>
                                Image
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ border: "solid 1px #eee" }}>
                        {fields.map((member, index) => (
                            <TableRow key={index}>
                                <TableCell className={classes.tableCell}>
                                    <Field
                                        name={`${member}.isDefault`}
                                        component={CustomCheckbox}
                                        type="checkbox"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        onChange={this.onCheckBoxChange}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {fields.get(index).groupName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {fields.get(index).subGroupName}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Field
                                        name={`${member}.skuId`}
                                        type="text"
                                        component={CustomInput}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Field
                                        name={`${member}.price`}
                                        type="number"
                                        component={CustomInput}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Field
                                        name={`${member}.salesPrice`}
                                        type="number"
                                        component={CustomInput}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Field
                                        name={`${member}.weightKg`}
                                        type="number"
                                        component={CustomInput}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Field
                                        name={`${member}.filePath`}
                                        type="text"
                                        component={ImageUpload}
                                        id='name'
                                        disabled={readOnly}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Button
                                        color={"danger"}
                                        simple
                                        className={classes.actionButton}
                                        onClick={() => {
                                            fields.remove(index);
                                        }}>
                                        <Close />
                                    </Button>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </GridContainer>
        </>
    }

    render() {
        const { formOpen, formMode, classes, dispatch, handleSubmit } = this.props;
        const { categoryList, formPromotionlist, listBrand } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        const storeData = store.getState().authentication
        const isBrandAdminRole = lodash.get(storeData, 'user.isBrandAdminRole', false)

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="lg"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Product' : (readOnly ? 'Product Info' : 'Edit Product')}</b></h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="product Group-form-dialog"
                        className={classes.modalBody}
                    >
                        <GridContainer>

                        {!isBrandAdminRole &&
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    name="brandId"
                                    component={CustomSelect}
                                    labelText={"Brand"}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    optionsList={listBrand.map(value => {
                                        return {
                                            value: value._id,
                                            label: value.name
                                        }
                                    })}
                                    disabled={readOnly}
                                    validate={[
                                        validateBrandId
                                    ]}
                                />
                            </GridItem>
                            }
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id='name'
                                    name="name"
                                    component={CustomInput}
                                    onChange={this.setStatusDefault}
                                    type="text"
                                    labelText="Product Name"
                                    disabled={readOnly}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    name="categoryId"
                                    component={CustomSelect}
                                    labelText={"Product Category"}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    optionsList={categoryList.map(value => {
                                        return {
                                            value: value._id,
                                            label: value.name
                                        }
                                    })}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id='group_title'
                                    name="group_title"
                                    component={CustomInput}
                                    onChange={this.setStatusDefault}
                                    type="text"
                                    labelText="Group title"
                                    disabled={readOnly}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id='description'
                                    name="description"
                                    component={CustomInput}
                                    onChange={this.setStatusDefault}
                                    type="text"
                                    labelText="Product Description"
                                    disabled={readOnly}
                                    formControlProps={{
                                        fullWidth: true,
                                        multiline: true,
                                    }}
                                    multiline

                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id='sub_group_title'
                                    name="sub_group_title"
                                    component={CustomInput}
                                    onChange={this.setStatusDefault}
                                    type="text"
                                    labelText="Sub Group title"
                                    disabled={readOnly}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                            <Field
                                name="promotionIds"
                                component={PromotionMultiSelect}
                                labelText="Promotion"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                showtick
                                inputRef={this.inputRef}
                                optionsList={formPromotionlist.map(value => {
                                    return {
                                        value: value._id,
                                        label: value.promotionName
                                    }
                                })}
                            />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id={`${FORM_NAME}_status`}
                                    name="status"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Status"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            id: 'select_box_active',
                                            value: "Active",
                                            label: "Active"
                                        },
                                        {
                                            id: 'select_box_inactive',
                                            value: "Inactive",
                                            label: "Inactive"
                                        }
                                    ]}

                                />
                            </GridItem>
                        </GridContainer>
                        <FieldArray name="product" component={this.renderMembers} />
                    </DialogContent>

                    <DialogActions
                        className={classes.modalFooter}>
                        <Button
                            id={`${FORM_NAME}_btn_submit`}
                            type="submit"
                            style={{ marginRight: "10px" }}
                            color="primary">
                            Submit
                        </Button>
                        <Button
                            id={`${FORM_NAME}_btn_cancel`}
                            type="button"
                            onClick={() => {
                                dispatch({ type: saleProductConstants.CLOSE_FORM_SALEPRODUCT });
                            }}
                            color="white">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode, saleProductImagePreview, readOnly } = state.saleProduct
    const { formTaglist, formMemberLevellist } = state.member;
    const { FORM_SALEPRODUCT } = state.form;
    const { categoryList, listProductGroup, formPromotionlist, listBrand } = state.list

    return {
        formOpen, formMode, saleProductImagePreview, formTaglist, formPromotionlist, formMemberLevellist, FORM_SALEPRODUCT, readOnly, categoryList, listProductGroup, listBrand
    };
}

const connectProductForm = connect(mapStateToProps)(ProductForm);

const pageStyles = {
    ...modalStyle,
    tableHeadInput: {
        minWidth: "150px",
    },
}

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        console.log(errors);
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
        crmValidator.alertFirstInvalidByErrorObject(errors);

    }
})(withStyles(pageStyles)(connectProductForm))
import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";

import MUIDataTable from "mui-datatables";
import { plugins, crmValidator, loader, alert as customAlert } from "helpers";
import  { trueMoneyReportActions} from "actions";
import  { trueMoneyReportServices} from "services";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { TableCell } from '@material-ui/core';

const style = {
    typography: {
        padding: "10px"      },
      tableCode:{
        border: "1px solid black",
        borderCollapse: "collapse",
        textAlign: "center",
      },
      headerCode:{
        backgroundColor: "#d4d4d4",
      },
      bodyCode:{
        border: "1px solid black",
        padding: "0 20px",
      },
      infoStyle:{
        "height":"100%",
        "padding":"12px",
        "fontSize":"1.5rem",
        "transition":"background-color 150ms cubic-bezier(0.4,0,0.2,1) 0ms",
        "outline":"none",
        "verticalAlign":"middle",
        "justifyContent":"center",
        "MozAppearance":"none"
    },
    
  };
  
const THEME = createMuiTheme(MTablestyles);

class TableTrueMoneyTransactionReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "sortBy",
                sortDir: "desc",
                keyword: "",
            },
            csvData: [],
            ancherEl: null, 
            open: false
        };
        this.csvLink = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 800);
    }

    flipOpen = () => this.setState({open: !this.state.open });

    handleInfoOpen = event => {
      const { currentTarget } = event
        this.setState({ anchorEl:currentTarget  });
        this.setState({open: true })
      };
      handleInfoClose = event =>{ 
        this.setState({ anchorEl: null })
        this.setState({open: false })

      }

    exportCSV() {
        const { transactionReport, dispatch } = this.props;
        let { tableFilter, tableTotal } = transactionReport;
        tableFilter.skip = 0;
        tableFilter.limit = tableTotal ? tableTotal : 100000;
        if (tableTotal > 30000) {
            customAlert(
                dispatch,
                "error",
                "Can not export data more than 30,000 rows"
            );
        } else {
            loader(dispatch, true);
            trueMoneyReportServices.downloadTrueMoneyTransaction(tableFilter)
                .then((response) => {
                    loader(dispatch, false);
                })
                .catch((e) => {
                    this.setState({ csvData: [] });
                });
        }
    }

    getData(skip, limit, sortBy, sortDir, keyword) {
        const { dispatch, tableReload, transactionReport } = this.props;
        let { tableFilter } = transactionReport;

        let newFilter = tableFilter;
        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                newFilter.skip = 0;
            }

            if (crmValidator.isEmptyValue(limit)) {
                newFilter.limit = 10;
            }
            newFilter.page = skip;
            this.setState({
                rowsPerPage: limit,
            });
            skip = parseInt(limit) * parseInt(skip);

            newFilter.skip = skip;
            newFilter.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                newFilter.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                newFilter.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                newFilter.keyword = keyword;
            } else {
                newFilter.keyword = "";
            }
        } else {
            newFilter = this.state.newFilter;
        }
        dispatch(trueMoneyReportActions.getTrueMoneyTransactionReport(newFilter));

        this.setState({
            isLoading: true,
            filter: newFilter,
        });
    }

    getRowData(rowIndex = 0) {
        const { transactionReport } = this.props;
        const { tableData } = transactionReport;
        const data = tableData;
        return data[rowIndex];
    }

    render() {
        const { rowsPerPage } = this.state;
        const { transactionReport } = this.props;
        const { tableData, tableTotal, tableFilter } = transactionReport ;

        // const open = this.state.anchorEl === null ? false : true;
        const id = this.state.open ? "simple-popper" : null;
        const { classes } = this.props;
        const columns = [
            {
                name: "created_at",
                label: "Trans Date/Time",
                options: {
                    customBodyRender: (value) =>
                        value
                            ? plugins.datetimeFormat(value, "fulldatetime")
                            : "",
                },
            },
            { name: "TMNTransactionId", label: "TMN Trans ID" },
            { name: "burnTransactionId", label: "Burn Trans ID" },
            {
                name: "campaignName",
                label: "Campaign Name",
            },
            { name: "amount", label: "Amount" },
            { name: "topupNumber",label: "Topup Mobile NO.", },
            { 
                name: "actionType", 
                label: "Type",
                options:{
                    customBodyRender: (value) =>{ 
                        return value==="notify"? "Topup": (value==="verify"?"Verify":value)
                    }
                }
         },
            {
                name: "status",
                label: "Status",
                options: {
                    customBodyRender: (value) =>{
                        return value
                            ? "Success"
                            : "Failed"
                        }
                },
            },
            { name: "responseCode", label: "Error Code",
        options:{
            customHeadRender:(columnMeta, handleToggleColumn, sortOrder)=>{
            return (
            <TableCell style={{backgroundColor:"#fff"}} key={columnMeta.index} variant="head">
                <span  title="responseCode">
                {columnMeta.label}
                </span>
               <span>
               <span style={style.infoStyle}
                        id='button-Info-TmnTransaction'
                        className=''
                        onMouseEnter={event =>this.handleInfoOpen(event)}
                        onMouseLeave={event =>this.handleInfoClose(event)}>
                        <InfoOutlinedIcon />
                    </span>
               </span>
        </TableCell> )
        },
            customBodyRender: (value) =>{ 
                return value==="0"? "": value
            }
        },
       
    },];
        const options = {
            serverSide: true,
            count: tableTotal ? tableTotal : 0,
            page: tableFilter.page,
            rowsPerPage: rowsPerPage,
            fixedHeader: true,
            selectableRows: "none",
            print: false,
            customToolbar: () => {
                return (
                    <>
                        {plugins.isHasPermission("report[tmn_transaction]", "export") && plugins.isHasPackageFeatures('export') && (
                            <Tooltip title='Export CSV'>
                                <IconButton
                                    id='button-earnTable-exportCsv'
                                    className=''
                                    onClick={this.exportCSV.bind(this)}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [1, 5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip = "";
                let limit = "";
                let sortBy = "";
                let sortDir = "";
                let keyword = "";
    
                if (tableState.page !== null) {
                    skip = tableState.page;
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage;
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name;
                }
                if (tableState.announceText !== null) {
                    sortDir =
                        tableState.announceText.search("descending") !== -1
                            ? "desc"
                            : "asc";
                }
                if (tableState.searchText !== null || action === "search") {
                    keyword = tableState.searchText;
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword);
                }
                let actionList = ["changePage", "sort", "changeRowsPerPage"];
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword);
                }
            },
        };
        return (
            <div id='tableTrueMoneyTransactionReport'>
                <CustomSweetAlert />
                {/* <EarnReportDetail /> */}
                <Popper
              id={id}
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              placement="left"
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper >
                    <div className={classes.typography}>
                      <table style={style.tableCode}>
                        <thead style={style.headerCode}>
                            <tr>
                                <th style={style.bodyCode} >Response Code</th>
                                <th style={style.bodyCode}>Response Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td style={style.bodyCode}>0</td>
                            <td style={style.bodyCode}>Success</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>190003</td>
                            <td style={style.bodyCode}>Duplicate Transaction ID</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>190023</td>
                            <td style={style.bodyCode}>System Error</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>390058</td>
                            <td style={style.bodyCode}>Invalid command</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>390061</td>
                            <td style={style.bodyCode}>Invalid data</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>390063</td>
                            <td style={style.bodyCode}>Invalid Data Exception [Not Verify Yet.Error]</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>800007</td>
                            <td style={style.bodyCode}>REQUEST_TIMEOUT</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>TWS001</td>
                            <td style={style.bodyCode}>Could not found E-Wallet</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>TWS002</td>
                            <td style={style.bodyCode}>System Error</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>TWS005</td>
                            <td style={style.bodyCode}>Inactive</td>
                            </tr>
                            <tr>
                            <td style={style.bodyCode}>TWS006</td>
                            <td style={style.bodyCode}>Over credit limit</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        
                        data={tableData ? tableData : []}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { visiblePersonalData, merchantSetting } = state.authentication;
    const { transactionReport } = state.tmnReport;
    return {
        transactionReport,
        visiblePersonalData,
        merchantSetting,
    };
}




export default connect(mapStateToProps)(withStyles(style)(TableTrueMoneyTransactionReport));

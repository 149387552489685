import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';

export const dashboardServices = {
   getListsBurnDashboard,
   getListsEarnDashboard,
   getFileDownloadBurn,
   getFileDownloadEarn,
   getListsDailyRegistrationDashboard,
   getFileDownloadRegister,
   getListsDailyEarnTransactionDashboard,
   getFileDownloadEarnTransaction,
   getKeySummary,
   getRegistration,
   getRegistrationDayofweek,
   getEarnTransactionList,
   getBurnTransactionList,
   getTableEarnBySkuDatas,
   getTableRegistrationByHourDatas,
   getTableRegistrationByProvinceDatas,
   getTableBurnTransactionByCampaignDatas,
   getTableInactiveUser,
   downloadInactiveUser,
   getCampaignsList,
   getListsDailyBurnTransactionDashboard,
   getTableBurnTransactionByDayAndHourDatas,
   getTableEarnTransactionByDayAndHourDatas,
   getFileDownloadBurnTransaction,
};

function getListsBurnDashboard(params) {
   let mock = Object.keys(params.search).map(key => key + '=' + encodeURIComponent(params.search[key])).join('&')
   var tmp = axios.get(configConstants.API_BACKEND_CRM + '/reports/active-burn?' + mock, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getListsEarnDashboard(params) {
   let mock = Object.keys(params.search).map(key => key + '=' + encodeURIComponent(params.search[key])).join('&')
   var tmp = axios.get(configConstants.API_BACKEND_CRM + '/reports/active-earn?' + mock, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getFileDownloadBurn(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/download/active-burn/` + params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, link: response.data.message.url[0], message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp
}

function getFileDownloadEarn(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/download/active-earn/` + params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, link: response.data.message.url[0], message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp
}

function getListsDailyRegistrationDashboard(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   let mock = Object.keys(params.search).map(key => key + '=' + encodeURIComponent(params.search[key])).join('&')
   var tmp = axios.get(configConstants.API_BACKEND_CRM + '/reports/active-register?' + mock, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getFileDownloadRegister(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }

   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/download/active-register/` + params, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, link: response.data.message.url[0], message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp
}

function getListsDailyEarnTransactionDashboard(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   let mock = Object.keys(params.search).map(key => key + '=' + encodeURIComponent(params.search[key])).join('&')
   var tmp = axios.get(configConstants.API_BACKEND_CRM + '/reports/active-earn-transaction-by-channel?' + mock, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getFileDownloadEarnTransaction(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }

   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/download/active-earn-transaction-by-channel/` + params, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, link: response.data.message.url[0], message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp
}

function getKeySummary() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/key-summary`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: 'สัญญาณอินเตอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง' };
      });

   return tmp;
}

function getRegistration() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/registration-ageRange-gender`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: 'สัญญาณอินเตอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง' };
      });

   return tmp;
}

function getRegistrationDayofweek() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/registration-dayofweek`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: 'สัญญาณอินเตอร์เน็ตขัดข้อง กรุณาลองใหม่อีกครั้ง' };
      });

   return tmp;
}

function getEarnTransactionList() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/statistics/earn-transaction-status`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
} 

function getBurnTransactionList() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/statistics/burn-transaction-status`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
} 

function getTableEarnBySkuDatas() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/earn-transaction-by-sku`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getTableRegistrationByHourDatas() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/active-register-by-hour`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function  getTableRegistrationByProvinceDatas() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/registration-province`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function  getTableBurnTransactionByCampaignDatas() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/burn-transaction-by-campaign`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getCampaignsList(params) {
   let req_headers = {
       'Content-Type': 'application/json',
       'Charset': 'utf-8',
       'Authorization': localStorage.getItem('token')
   }
   let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns`, {
       headers: req_headers
   })
       .then(response => {
           if (response.data.statusCode.toString() === "200") {
               return {
                   status: true,
                   data: response.data.data,
                   message: response.data.message
               };
           } else if (response.data.message === "UNAUTHORIZE") {
               history.push('/auth/login')
               return { status: false, data: {}, message: response.data.message, unAlert: true };
           } else {
               return {
                   status: false,
                   data: {},
                   message: response.data.message
               };
           }
       })
       .catch(e => {
           return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
       });

   return tmp;
}

function getListsDailyBurnTransactionDashboard(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   let mock = Object.keys(params.search).map(key => key + '=' + encodeURIComponent(params.search[key])).join('&')
   var tmp = axios.get(configConstants.API_BACKEND_CRM + '/reports/active-burn-transaction-by-campaign?' + mock, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp;
}

function  getTableBurnTransactionByDayAndHourDatas() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/statistics/burn-transaction-success`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp;
}

function  getTableEarnTransactionByDayAndHourDatas() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/statistics/earn-transaction-success`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp;
}


function  getTableInactiveUser() {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/inactive-user`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}


function  downloadInactiveUser(param) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/download/inactive-user/${param}`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, link: response.data.data.url[0], message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getFileDownloadBurnTransaction(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }

   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/download/active-burn-transaction-by-campaign/` + params, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, link: response.data.message.url[0], message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp
}
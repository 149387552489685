import { configConstants } from '../constants';
import axios from 'axios';
import lodash from 'lodash';

import { history, crmValidator, setHeaders } from 'helpers';

export const whitelistServices = {
    getCampaignsList,
    getWhitelistList,
    addWhitelist,
    editWhitelist,
    deleteWhitelist,
    saveUploadWhitelist,
    uploadWhitelist,
    // importMember
};

function getCampaignsList(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns?rewardType=couponCode`, {
       headers: setHeaders({ 'Charset': 'utf-8' })
    })
       .then(response => {
          if (response.data.statusCode.toString() === "200") {
             return {
                status: true,
                data: response.data.data,
                message: response.data.message
             };
          } else if (response.data.message === "UNAUTHORIZE") {
             history.push('/auth/login')
             return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
             return {
                status: false,
                data: {},
                message: response.data.message
             };
          }
       })
       .catch(e => {
          return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
       });
 
    return tmp;
}

function getWhitelistList(params) {
    var tmp = axios.post(configConstants.API_WHITELIST + '/whitelist/search', params, { headers: setHeaders() })
    .then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}

function addWhitelist(params) {
    var tmp = axios.post(configConstants.API_WHITELIST + '/whitelist', params, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function editWhitelist(params) {
    var tmp = axios.put(configConstants.API_WHITELIST + `/whitelist/${params._id}`, params, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function deleteWhitelist(params) {
    let tmp = axios.delete(configConstants.API_WHITELIST + `/whitelist/${params._id}`, {
        headers: setHeaders()
    })
        .then(response => {
            //console.log('response :',response);
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                // history.push('/auth/login')
                // return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function uploadWhitelist(params) {
    try {
        let formData = new FormData();

        if (!crmValidator.isEmptyValue(params)) {
            lodash.forEach(params, function (value, key) {
                if (key === "file") {
                    formData.append('file', value[0]);
                } else {
                    formData.append(key, value)
                }
            })
        }

        var tmp = axios.post(configConstants.API_WHITELIST + '/whitelist/checkfileupload', formData, {
            headers: setHeaders({ 'Charset': 'utf-8' })
        })
            .then(response => {
                if (response.data.statusCode.toString() === "200") {
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: response.data,
                        message: response.data.message
                    };
                }
            })
            .catch(error => {
                return {
                    status: false,
                    data: {
                        statusCode: 500
                    },
                    message: 'File not found, Please select file and try again.'
                };
            })
        return tmp;
    } catch (e) {
        return {
            status: false,
            data: {
                statusCode: 500
            },
            message: 'File not found, Please select file and try again.'
        };
    }
}

function saveUploadWhitelist(params) {
    try {
        const req = {
            data : params
        }

        var tmp = axios.post(configConstants.API_WHITELIST + '/whitelist/savefileupload', req, {
            headers: setHeaders({ 'Charset': 'utf-8' })
        })
            .then(response => {
                //console.log(response)
                if (response.data.statusCode.toString() === "200") {
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: response.data,
                        message: response.data.message
                    };
                }
            })
            .catch(error => {
                return {
                    status: false,
                    data: {
                        statusCode: 500
                    },
                    message: 'File not found, Please select file and try again.'
                };
            })
        return tmp;
    } catch (e) {
        return {
            status: false,
            data: {
                statusCode: 500
            },
            message: 'File not found, Please select file and try again.'
        };
    }
}

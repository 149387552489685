import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";
import { plugins, crmValidator, loader, alert as customAlert } from 'helpers';
import { promotionActions } from 'actions';
import { reportServices } from 'services'
// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";

const style = {

}
const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {

            },
            body: {
                "&:nth-child(2)": {
                    paddingRight: "10px",
                },
                "&:nth-child(4)": {
                    paddingRight: "10px",
                }
            }
        }
    }
});
class TablePromotionReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            reqParams: {
                skip: 0,
                limit: 10,
                sortBy: "created_at",
                sortDir: "desc",
                keyword: ""
            }
        }
        this.csvLink = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableDatas, tableFilter } = this.props
        
        let newFilter = tableFilter;

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                newFilter.skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                newFilter.limit = 10
            }
            newFilter.page = skip;
            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)
            if(tableDatas.total !== 0 && tableDatas.total === skip){
                skip = skip - limit
                this.setState({
                    page: this.state.page - 1,
                })                
            }
            if(skip >= tableDatas.total){
                skip = 0;
                this.setState({
                    page: 0,
                })
            }
            this.setState({
                newFilter: {
                    skip: skip,
                    limit: limit,
                 },
            })
            newFilter.skip = skip;
            newFilter.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                newFilter.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                newFilter.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                newFilter.keyword = keyword;
            }
        } else {
            newFilter = this.state.newFilter;
        }
        dispatch(promotionActions.getTablePromotionReportDatas(newFilter));

        this.setState({
            reqParams: newFilter,
        });
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.data;
        return data[rowIndex];
    }

    exportCSV() {
        const { tableFilter, tableDatas, dispatch } = this.props;
        tableFilter.skip = 0;
        tableFilter.limit = tableDatas.total ? tableDatas.total : 100000;
        if (tableDatas.total > 30000) {
            customAlert(
                dispatch,
                "error",
                "Can not export data more than 30,000 rows"
            );
        } else {
            loader(dispatch, true);
            reportServices
                .downloadPromotionTransaction(tableFilter)
                .then((response) => {
                    loader(dispatch, false);
                })
                .catch((e) => {
                    this.setState({ csvData: [] });
                })
        }
}

    render() {
        const { classes, tableDatas, tableKey, tableLoader, tableFilter } = this.props;
        const columns = [
            {
                name: "reserveDateTime",
                label: "Reserve Date/time",
                options: {
                    filter: false, sort: true,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        return (rowData.reserveDateTime ? plugins.datetimeFormat(rowData.reserveDateTime, "fulldatetime") : "");
                    }
                }
            },
            {
                name: "updatedDateTime",
                label: "Updated Date/time",
                options: {
                    filter: false, sort: true,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        return (rowData.updatedDateTime ? plugins.datetimeFormat(rowData.updatedDateTime, "fulldatetime") : "");
                    }
                }
            },
            {
                name: "referenceKey", label: "Reference key", options: { sort: true }
            },
            {
                name: "promotionId", label: "Promotion id", options: { sort: true }
            },
            {
                name: "promotionName", label: "Promotion Name", options: { sort: true }
            },
            {
                name: "referenceCode", label: "Reference Code", options: { sort: true }
            },
            {
                name: "remark", label: "Remark", options: { sort: true }
            },
        ]
        const options = {
            serverSide: true,
            count: (tableDatas.total ? tableDatas.total : 0),
            page: this.state.page,
            rowsPerPage: tableFilter.limit,
            fixedHeader: true,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            search: false,
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
            customToolbar: () => {
                return (
                    <>
                        {plugins.isHasPermission("report[promotion]", "export") && plugins.isHasPackageFeatures('export') &&(
                            <Tooltip title="Export CSV">
                                <IconButton id="button-promotionTable-exportCSV" className="" onClick={this.exportCSV.bind(this)}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
        };
        return (
            <div id="tablePromotionReport">
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        data={(tableDatas.data ? tableDatas.data : [])}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { visiblePersonalData, merchantSetting } = state.authentication;
    const { tableDatas, tableFilter, tableKey, tableLoader } = state.promotion
    return {
        visiblePersonalData, merchantSetting, tableDatas, tableFilter, tableKey, tableLoader
    };
}

export default connect(mapStateToProps)(withStyles(style)(TablePromotionReport));
import { winnerConfigServices } from '../services'
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'
import moment from 'moment'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const winnerConfigActions = {
    getWinnerConfigSetting,
    saveWinnerConfigSetting,
    editWinnerConfigSetting
};

function getWinnerConfigSetting() {
    return dispatch => {
        loader(dispatch, true)
        winnerConfigServices.getWinnerConfigSetting()
            .then(
                response => {
                    // console.log('getWinnerConfigSetting action response', response);
                    if (response.status) {
                        // let limitTime = lodash.get(response, 'data.pointExpiration.limitTime')
                        let formInitialValues = {
                            ...lodash.get(response, 'data'),
                            // limitTime: limitTime === 0 ? undefined : limitTime
                        }

                        // console.log('formInitialValues', formInitialValues);
                        // let specifyExpirationDate = lodash.get(formInitialValues, 'specifyExpirationDate')
                        // if (specifyExpirationDate) {
                        //     formInitialValues.specifyExpirationDate = moment(specifyExpirationDate)
                        // }

                        dispatch({ type: 'GET_WINNER_CONFIG_SETTING_SUCCESS', data: formInitialValues })
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function saveWinnerConfigSetting(params) {
    return dispatch => {
        loader(dispatch, true)
        // console.log('saveWinnerConfigSetting params', params);
        winnerConfigServices.saveWinnerConfigSetting(params)
            .then(
                response => {
                    // console.log('saveWinnerConfigSetting response', response);
                    if (response.status) {
                        customAlert(dispatch, 'success', "SUCCESS");
                        dispatch(getWinnerConfigSetting())
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}


function editWinnerConfigSetting(params) {
    return dispatch => {
        loader(dispatch, true)
        winnerConfigServices.editWinnerConfigSetting(params)
            .then(
                response => {
                    if (response.status) {
                        customAlert(dispatch, 'success', "SUCCESS");
                        dispatch(getWinnerConfigSetting())
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}
export const bannerConstants = {
   OPEN_FORM_ADD: 'OPEN_ADD_BANNER',
   OPEN_FORM_EDIT: 'OPEN_EDIT_BANNER',
   CLOSE_FORM_BANNER: 'CLOSE_FORM_BANNER',
   ADD_NEW_BANNER_SUCCESS: 'ADD_NEW_BANNER_SUCCESS',
   EDIT_BANNER_SUCCESS: 'EDIT_BANNER_SUCCESS',
   TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
   TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',
   SET_FORM_PREVIEW_IMAGE: 'SET_FORM_PREVIEW_IMAGE',
   DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
   GET_CAMPAIGN_DATA_SUCCESS: 'BANNER_GET_CAMPAIGNS_DATA_SUCCESS',
   CLEAR_PAGE_STATE: 'BANNER_CLEAR_PAGE_STATE',
   TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_BANNERS',
   GET_BANNER_LIST_REQUEST: 'GET_BANNER_LIST_REQUEST',
   GET_BANNER_LIST_SUCCESS: 'GET_BANNER_LIST_SUCCESS'
};

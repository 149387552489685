import _ from 'lodash'
export const form_validate = {
   "name": {
      rules: {
        required: true
      },
      messages: {
        required: "Please enter Rule's name"
      },
      alert: "Please enter Rule's name"
    },
    "status": {
      rules: {
        required: true
      },
      messages: {
        required: 'Please enter Status'
      },
      alert: "Please enter Status"
    },
    "startDate": {
      rules: {
        required: true
      },
      messages: {
        required: 'Please enter Start Date'
      },
      alert: "Please enter Start Date"
    },
    "type": {
      rules: {
        required: true
      },
      messages: {
        required: 'Please enter type'
      }
    },
    "checkBoxRegionSelected": {
      rules: {
        required: false
      },
      messages: { 
        required: 'warning_messages.please_select_region',
      },
      alert: "warning_messages.please_select_region",
      },
  "shopCondition[shopList]": {
      rules: {
      required: false
      },
      messages: {
      required: 'warning_messages.please_select_shop_list'
      },
      alert: "warning_messages.please_select_shop_list",
 },
}

export const validateIsZero = (values, allValues) => {
   if (values === "0") {
      return "Please more than 0"
   }
   if (values === undefined || values === '') {
      return "Please enter point"
   }
}

export const validateIsZeroMinimumSpending = (values, allValues) => {
   if (values === undefined || values === '') {
      return "Please enter minimum spending amount"
   }
}

export const validateIsZeroMount = (values, allValues) => {
   if (values === "0") {
      return "Please more than 0"
   }
   if (values === undefined || values === '') {
      return "Please enter spending amount"
   }
}

export const validateDateSelect = values => {
    if (values === undefined || values.length === 0) {
        return "Please Select date"
     }
}

export const validateSelectedDate = (value, allValues) => {
   if(_.get(allValues,'customRadioSelectedDay') === 'dayOfWeek'){
      let selectedDate = _.get(allValues,'selectedDate')
      if(_.size(selectedDate) === 0 || ( 
        (_.get(selectedDate,'monday') === undefined || !_.get(selectedDate,'monday')) && 
        (_.get(selectedDate,'tuesday') === undefined || !_.get(selectedDate,'tuesday')) && 
        (_.get(selectedDate,'wednesday') === undefined || !_.get(selectedDate,'wednesday')) && 
        (_.get(selectedDate,'thursday') === undefined || !_.get(selectedDate,'thursday')) && 
        (_.get(selectedDate,'friday') === undefined || !_.get(selectedDate,'friday')) && 
        (_.get(selectedDate,'saturday') === undefined || !_.get(selectedDate,'saturday')) && 
        (_.get(selectedDate,'sunday') === undefined || !_.get(selectedDate,'sunday'))
        ) ){
         return "Please Select date"
      }
   }
}

export const validateIsLessThanSpendingEvery = (values, allValues) => {
   if(parseInt(_.get(allValues, 'spendingEveryInput')) > parseInt(values)){
      return "Please enter maximum spending amount at least "+_.get(allValues, 'spendingEveryInput');
   }
   if (values === undefined || values === '') {
      return "Please enter maximum spending amount"
   }
}

export const validateTypeProductNameSkuSelect = (values, allValues) => {
  //console.log(allValues)
   if (values === undefined || values === '') {
       return "Please select at least 1 product"
    }
}

export const validateTypeProductNameSkuSelectMore = (values, allValues) => {
   let lengthProductList = allValues.singleProductList;

   if(!_.get(allValues.selectedRuleProduct, 'single')){
      return null
   }

   for(let i=1; i<lengthProductList; i++){
      if((_.get(allValues,'singleProductPointInput['+i+']') === undefined || _.get(allValues,'singleProductPointInput['+i+']') === '')
      && (_.get(allValues,'singleProductQuantityInput['+i+']') === undefined || _.get(allValues,'singleProductQuantityInput['+i+']') === '')
      && (_.get(allValues,'singleProductNameSkuSelect['+i+']') === undefined || _.get(allValues,'singleProductNameSkuSelect['+i+']') === '')){
         return null;
      }
   }
   if (values === undefined || values === '') {
       return "Please select at least 1 product"
    }
}

export const validateTypeProductNameSkuSelectJoinedProduct_2 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if(!_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === undefined || values === '') {
         return "Please select at least 1 product"
      }
   }
}

export const validateTypeProductNameSkuSelectJoinedProduct_3 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_2]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_2]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === undefined || values === '') {
         return "Please select at least 1 product"
      }
   }
}

export const validateTypeProductNameSkuSelectJoinedProduct_4 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_2]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_2]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_3]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_3]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === undefined || values === '') {
         return "Please select at least 1 product"
      }
   }
}

export const validateTypeProductNameSkuSelectJoinedProduct_5 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_2]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_2]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_3]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_3]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_4]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_4]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_4]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_4]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === undefined || values === '') {
         return "Please select at least 1 product"
      }
   }
}

export const validateIsZeroTypeProductQuantity = (values, allValues) => {
   if (values === "0") {
      return "Please enter the number greater than 0"
   }
   else if (values === undefined || values === '') {
      return "Please enter Quantity" 
   }
}

export const validateIsZeroTypeProductQuantityMore = (values, allValues) => {
   let lengthProductList = allValues.singleProductList;

   if(!_.get(allValues.selectedRuleProduct, 'single')){
      return null
   }

   for(let i=1; i<lengthProductList; i++){
      if((_.get(allValues,'singleProductPointInput['+i+']') === undefined || _.get(allValues,'singleProductPointInput['+i+']') === '')
      && (_.get(allValues,'singleProductQuantityInput['+i+']') === undefined || _.get(allValues,'singleProductQuantityInput['+i+']') === '')
      && (_.get(allValues,'singleProductNameSkuSelect['+i+']') === undefined || _.get(allValues,'singleProductNameSkuSelect['+i+']') === '')){
         return null;
      }
   }
   if (values === "0") {
      return "Please enter the number greater than 0"
   }
   else if (values === undefined || values === '') {
      return "Please enter Quantity" 
   }
}

export const validateIsZeroTypeProductQuantityJoinedProduct_2 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === "0") {
         return "Please enter the number greater than 0"
      }
      else if (values === undefined || values === '') {
         return "Please enter Quantity" 
      }
   }
}

export const validateIsZeroTypeProductQuantityJoinedProduct_3 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_2]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_2]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === "0") {
         return "Please enter the number greater than 0"
      }
      else if (values === undefined || values === '') {
         return "Please enter Quantity" 
      }
   }
}

export const validateIsZeroTypeProductQuantityJoinedProduct_4= (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_2]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_2]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_3]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_3]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === "0") {
         return "Please enter the number greater than 0"
      }
      else if (values === undefined || values === '') {
         return "Please enter Quantity" 
      }
   }
}

export const validateIsZeroTypeProductQuantityJoinedProduct_5 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_2]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_2]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_3]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_3]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_4]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_4]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_4]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_4]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === "0") {
         return "Please enter the number greater than 0"
      }
      else if (values === undefined || values === '') {
         return "Please enter Quantity" 
      }
   }
}

export const validateIsZeroTypeProductPoint = (values, allValues) => {
   if (values === "0") {
      return "Please enter the number greater than 0"
   }
   else if (values === undefined || values === '') {
      return "Please enter Point" 
   }
}

export const validateIsZeroTypeProductPointMore = (values, allValues) => {
   let lengthProductList = allValues.singleProductList;

   if(!_.get(allValues.selectedRuleProduct, 'single')){
      return null
   }

   for(let i=1; i<lengthProductList; i++){
      if((_.get(allValues,'singleProductPointInput['+i+']') === undefined || _.get(allValues,'singleProductPointInput['+i+']') === '')
      && (_.get(allValues,'singleProductQuantityInput['+i+']') === undefined || _.get(allValues,'singleProductQuantityInput['+i+']') === '')
      && (_.get(allValues,'singleProductNameSkuSelect['+i+']') === undefined || _.get(allValues,'singleProductNameSkuSelect['+i+']') === '')){
         return null;
      }
   }
   if (values === "0") {
      return "Please enter the number greater than 0"
   }
   else if (values === undefined || values === '') {
      return "Please enter Point" 
   }
}

export const validateIsZeroTypeProductPointJoinedProduct_2 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === "0") {
         return "Please enter the number greater than 0"
      }
      else if (values === undefined || values === '') {
         return "Please enter Point" 
      }
   }
}

export const validateIsZeroTypeProductPointJoinedProduct_3 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_2]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_2]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === "0") {
         return "Please enter the number greater than 0"
      }
      else if (values === undefined || values === '') {
         return "Please enter Point" 
      }
   }
}

export const validateIsZeroTypeProductPointJoinedProduct_4= (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_2]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_2]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_3]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_3]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === "0") {
         return "Please enter the number greater than 0"
      }
      else if (values === undefined || values === '') {
         return "Please enter Point" 
      }
   }
}

export const validateIsZeroTypeProductPointJoinedProduct_5 = (values, allValues) => {
   let lengthProductList = _.get(allValues,'joinedProductList') ? _.get(allValues,'joinedProductList') : 0 ;
   
   if( !_.get(allValues.selectedRuleProduct, 'joined')){
      return null
   }else{

   for(let index=1; index<lengthProductList; index++){
      if(   (_.get(allValues,'joinedProductQuantityInput['+index+'_0]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_1]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_1]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_2]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_2]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_3]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_3]') === '')
         && (_.get(allValues,'joinedProductQuantityInput['+index+'_4]') === undefined || _.get(allValues,'joinedProductQuantityInput['+index+'_4]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_0]') === '')
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_1]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_2]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_3]') === '') 
         && (_.get(allValues,'joinedProductNameSkuSelect['+index+'_4]') === undefined || _.get(allValues,'joinedProductNameSkuSelect['+index+'_4]') === '') 
         && (_.get(allValues,'joinedProductPointInput['+index+']') === undefined || _.get(allValues,'joinedProductPointInput['+index+']') === '' ) ){
            return null;
         }
   }
      if (values === "0") {
         return "Please enter the number greater than 0"
      }
      else if (values === undefined || values === '') {
         return "Please enter Point" 
      }
   }
}
import React from "react";
import { Field, change } from 'redux-form';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx"
import * as FormValidator from "../../Mission/validate/FormValidator"

import { crmValidator } from 'helpers'
import lodash from 'lodash';

function FormMissionPrivilegeType1({ ...props }) {
    const { dispatch, formMode, FORM_MISSION, campaignList } = props.props
    let { readOnly } = props.props
    if (formMode === `add`) {
        readOnly = false
    } else if (formMode === `edit` && !readOnly) {
        readOnly = false
    } else {
        readOnly = true
    }
    const validate_field = {
        numberOnly: {
            rules: {
                required: false,
                number: true,
                noSpecialCharacters: true,
            },
        }
    }
    let campaignListSelect = [];
    if (lodash.has(props.props, 'campaignList')) {
        lodash.forEach(campaignList, function (value, key) {
            campaignListSelect.push({
                value: value._id,
                label: value.name
            })
        })
    }
    return (
        <>
            <GridContainer style={{ paddingRight: '35px' }}>
                {lodash.includes(FORM_MISSION.values.missionPrivilege, "point") && <>
                    <GridItem xs={12} style={{ marginLeft: "35px", backgroundColor : '#EEE' }}>
                        <h4><b>Point</b></h4>
                        <GridItem xs={12} style={{ marginLeft: "30px", marginTop: "-20px" }}>
                            <span style={{ marginRight: '10px' }}>Collect point one time on last day of achieved mission</span>
                            <Field
                                id="privilege-type-point"
                                name="earnReward[achievePoint]"
                                component={CustomInput}
                                type="text"
                                formControlProps={{
                                    fullWidth: true,
                                    style: { maxWidth: "120px",}
                                }}
                                normalize={(value) => {
                                    return crmValidator.handleKeyPress( value, validate_field.numberOnly )
                                }}
                                validate={FormValidator.validateIsZero}
                            />
                            <span style={{ marginLeft: '10px' }}>Point(s)</span>
                        </GridItem>
                    </GridItem>
                <br/></>}
                {lodash.includes(FORM_MISSION.values.missionPrivilege, "reward") && <>
                    <GridItem xs={12} style={{ marginLeft: "35px", backgroundColor : '#EEE' }}>
                        <h4><b>Reward</b></h4>
                        <GridItem xs={12} style={{ marginLeft: "30px" }}>
                            <b style={{fontSize: "1rem"}}>Reward Redemption</b>
                            <br/>
                            <span style={{ marginLeft: "30px" }}>
                            <Field
                                id="reward-redemption"
                                name="earnReward[achieveReward]"
                                component={CustomRadio}
                                radioLists={[{
                                    value: "Manually",
                                    label: ( <span style={{ fontWeight: "300" }}> Manually </span> ),
                                    id: "Manually",
                                    disabled: readOnly
                                }]}
                            />
                            </span>
                            <span style={{ marginLeft: "30px" }}>
                            <Field
                                id="reward-redemption"
                                name="earnReward[achieveReward]"
                                component={CustomRadio}
                                radioLists={[{
                                    value: "Automatically",
                                    label: ( <span style={{ fontWeight: "300" }}> Automatically </span> ),
                                    id: "Automatically",
                                    disabled: readOnly
                                }]}
                            />
                            </span>
                        </GridItem>
                        <GridItem xs={12} style={{ marginLeft: "30px" }}>
                            <b style={{fontSize: "1rem"}}>Time to get reward</b>
                            <br/><br/>
                            <span style={{ marginLeft: "30px", marginRight: '10px' }}>Get Reward one time on last day of achieved mission</span>
                            <br/><br/>
                            <span style={{ marginLeft: "30px", marginRight: '10px'}}>Reward</span>
                            <Field
                                id="privilege-type-reward"
                                name="privilegeTypeReward"
                                component={CustomSelect}
                                type="text"
                                labelText="Select Campaign"
                                formControlProps={{
                                    fullWidth: true,
                                    style: { maxWidth: "400px", top: "-30px"}
                                }}
                                optionsList={campaignListSelect}
                                validate={FormValidator.validateSelectedCampaign}
                            />
                        </GridItem>
                    </GridItem>
                <br/></>}
                {lodash.includes(FORM_MISSION.values.missionPrivilege, "luckyChance") && <>
                    <GridItem xs={12} style={{ marginLeft: "35px", backgroundColor : '#EEE' }}>
                        <h5><b>Lucky Draw Chance</b></h5>
                        <GridItem xs={12} style={{ marginLeft: "30px", marginTop: "-20px" }}>
                            <span style={{ marginRight: '10px' }}>Collect chance one time on last day of achieved mission</span>
                            <Field
                                id="privilege-type-lucky-draw"
                                name="earnReward[achieveLuckyChance]"
                                component={CustomInput}
                                type="text"
                                formControlProps={{
                                    fullWidth: true,
                                    style: { maxWidth: "120px",}
                                }}
                                normalize={(value) => {
                                    return crmValidator.handleKeyPress( value, validate_field.numberOnly )
                                }}
                                validate={FormValidator.validateIsZero}
                            />
                            <span style={{ marginLeft: '10px' }}>Chance(s)</span>
                        </GridItem>
                    </GridItem>
                <br/></>}
            </GridContainer>
        </>
    );
}

export default FormMissionPrivilegeType1;

import _ from 'lodash';

export const validate_ = {
   "point": {
      "pointBurn": {
         rules: {
            required: true,
            number: true,
            noSpecialCharacters: true,
         },
         messages: {
            required: "warning_messages.point_is_required",
            number: "warning_messages.point_equal_or_greater_than_zero",
         },
         alert: "warning_messages.point_is_required"
      },
      "quotaCampaign": {
         rules: {
            number: true,
         },
         messages: {
            number: "warning_messages.number_greater_than_zero"
         },
      },
      "quota[campaignPerCampaign]": {
         rules: {
            number: true,
         },
         messages: {
            number: "warning_messages.number_greater_than_zero"
         },
      },
      "quota[quotaUser]": {
         rules: {
            number: true,
         },
         messages: {
            number: "warning_messages.number_greater_than_zero"
         },
      },
      "quota[userPerCampaign]": {
         rules: {
            number: true,
         },
         messages: {
            number: "warning_messages.number_greater_than_zero"
         },
      },
   },
   "every_x_point": {
      "pointBurn": {
         rules: {
            required: true,
            number: true,
            noSpecialCharacters: true,
         },
         messages: {
            required: "warning_messages.point_is_required",
            number: "warning_messages.point_equal_or_greater_than_zero",
         },
         alert: "warning_messages.point_is_required"
      },
      "quotaCampaign": {
         rules: {
            number: true,
         },
         messages: {
            number: "warning_messages.number_greater_than_zero"
         },
      },
      "quota[campaignPerCampaign]": {
         rules: {
            number: true,
         },
         messages: {
            number: "warning_messages.number_greater_than_zero"
         },
      },
      "quota[quotaUser]": {
         rules: {
            number: true,
         },
         messages: {
            number: "warning_messages.number_greater_than_zero"
         },
      },
      "quota[userPerCampaign]": {
         rules: {
            number: true,
         },
         messages: {
            number: "warning_messages.number_greater_than_zero"
         },
      },
   },
}

export const form_validate = {
   "name": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.name_is_required"
      },
      alert: "warning_messages.name_is_required"
   },
   "type": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.type_is_required"
      },
      alert: "warning_messages.type_is_required"
   },
   "startDate": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.start_date_is_required"
      },
      alert: "warning_messages.start_date_is_required"
   },
   "endDate": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.end_date_is_required"
      },
      alert: "warning_messages.end_date_is_required"
   },
   "customCaption": {
      rules: {
         maxLength: 30,
         noSpecialCharacters: true
      },
      messages: {
         maxLength: "warning_messages.invalid_data",
         noSpecialCharacters: "warning_messages.invalid_data"
      },
   },
   "status": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.status_is_required"
      },
      alert: "warning_messages.status_is_required"
   },
   "priority": {
      rules: {
        number: true,
        min: 1
      },
      messages: {
        number: 'Priority allows only number',
        min: 'Priority should be greater than 0'
      },
    },
};

export const reward_validate = {
   "luckyDrawRewardId": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.true_money_reward_is_required"
      },
      alert: "warning_messages.true_money_reward_is_required"
   },
   "rewardDetail[discountValue]": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.reward_discount_is_required"
      },
      alert: "warning_messages.reward_discount_is_required"
   },
   "rewardDetail[setName]": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.reward_name_is_required"
      },
      alert: "warning_messages.reward_name_is_required"
   },
   "rewardDetail[description]": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.reward_description_is_required"
      },
      alert: "warning_messages.reward_description_is_required"
   },
   "rewardType": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.reward_type_is_required"
      },
      alert: "warning_messages.reward_type_is_required"
   },
   "giftReceiveCondition": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.receiving_condition_is_required"
      },
      alert: "warning_messages.receiving_condition_is_required"
   },
   "rewardDetail[pointEarn]": {
      rules: {
         required: true,
         min: 0,
         noSpecialCharacters: true,
      },
      messages: {
         required: "warning_messages.reward_point_is_required",
         min: "warning_messages.point_equal_or_greater_than_zero",
         noSpecialCharacters: "please enter number only"
      },
      alert: "warning_messages.reward_point_is_required"
   },
   "rewardDetail[moneyTopUp]": {
      rules: {
         required: true,
         min: 1,
         noSpecialCharacters: true,
      },
      messages: {
         required: "warning_messages.true_money_top_up_request",
         min: "warning_messages.true_money_top_up_greater_than_zero",
      },
      alert: "warning_messages.true_money_top_up_request"
   },
   "tmnRewardId": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.true_money_reward_is_required"
      },
      alert: "warning_messages.true_money_reward_is_required"
   },
   "noSpecialChar": {
      rules: {
        noSpecialCharacters: true,      
      },
   },
   "rewardDetail[luckyChance]": {
      rules: {
         required: true,
         min: 1,
         noSpecialCharacters: true,
      },
      messages: {
         required: "warning_messages.lucky_chance_request",
         min: "warning_messages.number_greater_than_zero",
      },
      alert: "warning_messages.lucky_chance_request"
   },
};

export const detail_validate = {
   "languages[en][title]": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.title_is_required"
      },
      alert: "warning_messages.title_is_required"
   },
   "languages[en][description]": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.description_is_required"
      },
      alert: "warning_messages.description_is_required"
   },
   "languages[en][termAndCondition]": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.term_and_condition_is_required"
      },
      alert: "warning_messages.term_and_condition_is_required"
   },
};

export const validMimeType = "image/jpeg, image/png";

export const required = (value, allValues, props, name) => {
   return value ? undefined : 'warning_messages.required'
}

export const validateImageFormat = imageFile => {
   if (imageFile && !validMimeType.includes(imageFile.type)) {
      return "warning_messages.only_img_file_type_is_jpg_or_png";
   }
}

export const validateImageSize = imageFile => {
   if (imageFile) {
     if (imageFile.size > (1048576 * 2)) {
       return "warning_messages.campaign_picture_file_size_must_be_less_than_2mb";
     }
   }
}

export const validateRequiredImage = (imageFile, allValues, props, name) => {
   let msg = "warning_messages.please_upload_file";
   if (imageFile === null || imageFile === undefined || imageFile === "") {
      if (name === "campaignImage") {
         msg = "warning_messages.campaign_picture_is_required"
      } else if (name === "rewardPicture") {
         msg = "warning_messages.reward_picture_is_required"
      }

      return msg
   }
}

export const validateRequired = (value, allValues, props, name) => {
   let msg = "";
   if (name === "quota[campaignPerMonth]" || name === "quota[userPerMonth]") {
      msg = "warning_messages.quota_is_required"
   } else if (name === "trdPartyCodeSetId") {
      msg = "warning_messages.3rd_code_set_is_required"
   } else if (name === "birthdayDisplayType") {
      msg = "warning_messages.birthday_display_type_is_required"
   } else if (name === "rewardDetail[privilegeType]"){
      msg = "warning_messages.reward_privilege_is_required"
   } else if (name === "rewardDetail[couponCodeDiscountAmount]" || name === "rewardDetail[couponCodeDiscountPercentage]"){
      msg = "warning_messages.reward_discount_amount_is_required"
   } else if (name === "rewardDetail[skuId]"){
      msg = "warning_messages.reward_sku_is_required"
   } else if (name === "rewardDetail[quantity]"){
      msg = "warning_messages.reward_quantity_is_required"
   } else if (name === "rewardDetail[totalPrice]"){
      msg = "warning_messages.reward_total_price_is_required"
   }

   if (value === null || value === undefined || value === "") {
      return msg
   }
}

export const validateIsZero = values => {
   if ((values != null || values != undefined) && _.toInteger(values) === 0) {
      return "warning_messages.number_greater_than_zero"
   }
}

export const validateMax = (value, allValues, props, name) => {
   let max = false
   let msg = "warning_messages.quota_exceeded_per_campaign"

   if (name === "quota[userPerMonth]" || name.includes("quota[userPerDayofWeek]")) {
      const isDay = name.replace("quota[userPerDayofWeek]", "")
      if ( _.has(allValues, "quota[userPerCampaign]") && parseFloat(_.get(allValues, "quota[userPerCampaign]")) < parseInt(value)) { // case 1 > quota person
         max = _.get(allValues, "quota[userPerCampaign]")
         msg = "warning_messages.quota_exceeded_per_person"

      } else if (parseFloat(_.get(allValues, `quota[campaignPerCampaign]`)) < parseInt(value)) { // case 2 > campaignPerCampaign
         max = _.get(allValues, `quota[campaignPerCampaign]`)
         msg = "warning_messages.must_not_be_above_number_of_quota_per_campaign"

      } else if (name.includes("quota[userPerDayofWeek]") && allValues.quotaTypeCampaign === "day" && (parseFloat(_.get(allValues, `quota[campaignPerDayofWeek]${isDay}`)) < parseInt(value))) { // case 3 > campaignPerDay
         max = _.get(allValues, `quota[campaignPerDayofWeek]${isDay}`)
         msg = `Must not be above number of quota on ${isDay.replace(/\[|\]/g, '')} day of campaign`

      } else if (allValues.quotaTypeCampaign === "month" && (parseFloat(_.get(allValues, `quota[campaignPerMonth]`)) < parseInt(value))) { // case 4 > campaignPerMonth
         max = _.get(allValues, `quota[campaignPerMonth]`)
         msg = "warning_messages.must_not_be_above_number_of_quota_per_campaign"

      } else {
         max = _.get(allValues, "quota[userPerCampaign]")
         msg = "warning_messages.quota_exceeded_per_person"

      }

   } else if ((name === "quota[campaignPerMonth]" || name.includes("quota[campaignPerDayofWeek]"))) { 
      max = _.get(allValues, "quota[campaignPerCampaign]")
   }

   if (name === "quota[userPerCampaign]" && _.has(allValues, "quota[campaignPerCampaign]")) {
      max = _.get(allValues, "quota[campaignPerCampaign]")
   }

   return value && max && parseFloat(value) > parseFloat(max) ? msg : undefined

}

export const formatData = (values) => {
   return JSON.parse(values)
}


export const trueMoneyBudgetLimit = (value, allValues) => {
   const trueMoneyBudgetLimit = _.get(allValues, 'trueMoneyBudgetLimit')
   const tmnReward = _.get(allValues, 'tmnReward.available')
   const budgetLimit = trueMoneyBudgetLimit ||  tmnReward
   
   if(budgetLimit){
      if(value>budgetLimit ){
         return  "warning_messages.true_money_top_up_lesser_than_budget_limit"
      }
      if(value<=0){
         return  "warning_messages.number_greater_than_zero"
      }    
   }else if(budgetLimit===0){
         return  "warning_messages.true_money_budget_limit_not_enough"
   }else{
         return  "warning_messages.true_money_reward_was_not_select"
   }

}

export const validateSKU = (value, allValues) => {
   if ( ((_.get(allValues, "rewardDetail[skuId]") != undefined && _.get(allValues, "rewardDetail[skuId]") != null && _.get(allValues, "rewardDetail[skuId]") != "")
      || (_.get(allValues, "rewardDetail[quantity]") != undefined && _.get(allValues, "rewardDetail[quantity]") != null && _.get(allValues, "rewardDetail[quantity]") != "")
      || (_.get(allValues, "rewardDetail[totalPrice]") != undefined && _.get(allValues, "rewardDetail[totalPrice]") != null && _.get(allValues, "rewardDetail[totalPrice]") != ""))
      && (_.get(allValues, "rewardDetail[skuId]") === undefined || _.get(allValues, "rewardDetail[skuId]") === null || _.get(allValues, "rewardDetail[skuId]") === "") )
      {
         return "warning_messages.reward_sku_is_required"
      }
}

export const validateQuantity = (value, allValues) => {
   if ( ((_.get(allValues, "rewardDetail[skuId]") != undefined && _.get(allValues, "rewardDetail[skuId]") != null && _.get(allValues, "rewardDetail[skuId]") != "")
      || (_.get(allValues, "rewardDetail[quantity]") != undefined && _.get(allValues, "rewardDetail[quantity]") != null && _.get(allValues, "rewardDetail[quantity]") != "")
      || (_.get(allValues, "rewardDetail[totalPrice]") != undefined && _.get(allValues, "rewardDetail[totalPrice]") != null && _.get(allValues, "rewardDetail[totalPrice]") != ""))
      && (_.get(allValues, "rewardDetail[quantity]") === undefined || _.get(allValues, "rewardDetail[quantity]") === null || _.get(allValues, "rewardDetail[quantity]") === "") )
      {
         return "warning_messages.reward_quantity_is_required"
      }
}

export const validateTotalPrice = (value, allValues) => {
   if ( ((_.get(allValues, "rewardDetail[skuId]") != undefined && _.get(allValues, "rewardDetail[skuId]") != null && _.get(allValues, "rewardDetail[skuId]") != "")
      || (_.get(allValues, "rewardDetail[quantity]") != undefined && _.get(allValues, "rewardDetail[quantity]") != null && _.get(allValues, "rewardDetail[quantity]") != "")
      || (_.get(allValues, "rewardDetail[totalPrice]") != undefined && _.get(allValues, "rewardDetail[totalPrice]") != null && _.get(allValues, "rewardDetail[totalPrice]") != ""))
      && (_.get(allValues, "rewardDetail[totalPrice]") === undefined || _.get(allValues, "rewardDetail[rewardDetail[totalPrice]]") === null || _.get(allValues, "rewardDetail[totalPrice]") === "") )
      {
         return "warning_messages.reward_total_price_is_required"
      }
}
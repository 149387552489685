export const promotionConstants = {
    CLEAR_PAGE_STATE: 'PROMOTION_CLEAR_PAGE_STATE',
    OPEN_ADD_PROMOTION: 'OPEN_FORM_ADD_PROMOTION',
    OPEN_EDIT_PROMOTION: 'OPEN_EDIT_FORM_PROMOTION',
    CLOSE_FORM_PROMOTION: 'CLOSE_FORM_PROMOTION',
    
    ADD_PROMOTION_SUCCESS: 'ADD_PROMOTION_SUCCESS',

    EDIT_PROMOTION_SUCCESS: 'EDIT_PROMOTION_SUCCESS',
    EDIT_PROMOTION_FAILURE: 'EDIT_PROMOTION_FAILURE',

    DELETE_PROMOTION_SUCCESS: 'DELETE_PROMOTION_SUCCESS',

    GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
    GET_STATISTICS_FAILURE: 'GET_STATISTICS_FAILURE',
    TABLE_GET_LIST_PROMOTION: 'TABLE_GET_LIST_PROMOTION',
    GET_LIST_PRODUCT: 'GET_LIST_PRODUCT',
    GET_LIST_CATEGORY: 'GET_LIST_CATEGORY',

    HIDE_ALERT: 'HIDE_ALERT',
    SHOW_LOADER_PROMOTION: 'SHOW_LOADER_PROMOTION',
    HIDE_LOADER_PROMOTION: 'HIDE_LOADER_PROMOTION',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    OPEN_PURCHASE_PRODUCT_CONDITION: 'OPEN_PURCHASE_PRODUCT_CONDITION',
    CLOSE_PURCHASE_PRODUCT_CONDITION: 'CLOSE_PURCHASE_PRODUCT_CONDITION',

    OPEN_PURCHASE_AMOUNT_CONDITION: 'OPEN_PURCHASE_AMOUNT_CONDITION',
    CLOSE_PURCHASE_AMOUNT_CONDITION: 'CLOSE_PURCHASE_AMOUNT_CONDITION',

    OPEN_PAYMENT_CHANNEL_CONDITION: 'PROMOTION_OPEN_PAYMENT_CHANNEL_CONDITION',
    CLOSE_PAYMENT_CHANNEL_CONDITION: 'PROMOTION_CLOSE_PAYMENT_CHANNEL_CONDITION',

    OPEN_PROMOCODE_CONDITION: 'PROMOTION_OPEN_PROMOCODE_CONDITION',
    CLOSE_PROMOCODE_CONDITION: 'PROMOTION_CLOSE_PROMOCODE_CONDITION',

    OPEN_REWARD_MODAL: "PROMOTION_FORM_OPEN_REWARD_MODAL",
    CLOSE_REWARD_MODAL: "PROMOTION_FORM_CLOSE_REWARD_MODAL",

    OPEN_CONDITION_SHOP: "OPEN_CONDITION_SHOP",
    CLOSE_CONDITION_SHOP: "CLOSE_CONDITION_SHOP",
    OPEN_CONDITION_TIME: "OPEN_CONDITION_TIME",
    CLOSE_CONDITION_TIME: "CLOSE_CONDITION_TIME",

    PROMOTION_GET_REGION_LIST:"PROMOTION_GET_REGION_LIST",
    PROMOTION_GET_SHOP_LIST:"PROMOTION_GET_SHOP_LIST",
    PROMOTION_SELECT_SHOP:"PROMOTION_SELECT_SHOP",
    PROMOTION_UNSELECT_SHOP:"PROMOTION_UNSELECT_SHOP",
    PROMOTION_FILTER_SELECTED_SHOP_BY_REGION:"PROMOTION_FILTER_SELECTED_SHOP_BY_REGION",
    PROMOTION_SELECTED_REGION:"PROMOTION_SELECTED_REGION",
    PROMOTION_SELECT_ALL_SHOP_LIST:"PROMOTION_SELECT_ALL_SHOP_LIST",
    PROMOTION_UNSELECT_ALL_SHOP_LIST:"PROMOTION_UNSELECT_ALL_SHOP_LIST",
    PROMOTION_SEARCH_SHOP_LIST:"PROMOTION_SEARCH_SHOP_LIST",
    PROMOTION_SEARCH_SHOP_SELECTED:"PROMOTION_SEARCH_SHOP_SELECTED",
    PROMOTION_CLEAR_SHOP_LIST:"PROMOTION_CLEAR_SHOP_LIST",

    CLEAR_PROMOTION_FILTER: "CLEAR_PROMOTION_FILTER",
    GET_TABLE_LIST_PROMOTION_REPORT:"GET_TABLE_LIST_PROMOTION_REPORT",
    PROMOTION_REPORT_TABLE_RESET:"PROMOTION_REPORT_TABLE_RESET",
    GET_LIST_NAME_PROMOTION: "GET_LIST_NAME_PROMOTION",
 };
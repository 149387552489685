import React from "react";
import { connect } from 'react-redux';
import { change } from 'redux-form';
import i18n from 'i18n';

import MUIDataTable from "mui-datatables";
import lodash from 'lodash';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import { dataFilteringTemplateConstants, swalConstants } from '../../constants';
import { dataFilteringTemplateAction } from 'actions'
import { plugins, crmValidator } from 'helpers';

import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import FormDataFilteringTemplate from 'views/DataFilteringTemplate/FormDataFilteringTemplate'
import DataFilteringTemplateFormEdit from 'views/DataFilteringTemplate/FormEditDataFilteringTemplate'
// import FormTagImportMember from 'views/DataFilteringTemplate/FormTagImportMember.jsx';
import Badge from "components/Badge/Badge.jsx";

import SWImportResult from './SWImportResult';

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const CustomMTablestyles = {
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MUIDataTableHeadCell: {
            fixedHeader: {
                "&:nth-child(1)": {
                    width: "50%"
                },
            }
        }
    }
}

const moduleName = 'dataFiltering[templates]';

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "Active": BadgeColor = "success"; break;
        case "Expired": BadgeColor = "danger"; break;
        default: BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}

const pageStyle = { ...style, ...sweetAlertStyle };
const THEME = createMuiTheme(CustomMTablestyles);

class TableDataFilteringTemplateList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            readOnly: true,
            loaderSpiner: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: "",
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "created_at",
                sortDir: "desc",
                keyword: ""
            },
            refreshTable: true
        }
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(dataFilteringTemplateAction.getDataFilteringTemplateList())
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload,tableFilter } = this.props

        if (this.state.refreshTable) {
            tableFilter.skip = null
            tableFilter.sortBy = "createdAt"
            tableFilter.sortDir = "desc"
            tableFilter.keyword = ""
            tableFilter.search = {}
            this.setState({
                refreshTable: false
            })
        }

        let reqParams = tableFilter;

       
        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                reqParams.skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                reqParams.limit = 10
            }
            reqParams.page = skip;
            this.setState({
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams.skip = skip;
            reqParams.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams.keyword = keyword;
            } else {
                reqParams.keyword = ""
            }
        } else {
            reqParams = tableFilter;
        }
        
        dispatch(dataFilteringTemplateAction.getDataFilteringTemplateList(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.dataFilteringTemplates;
        if (lodash.size(data) > 0) {
            return data[rowIndex];
        } else {
            return null
        }
    }

    openFromAdd() {
        const { dispatch } = this.props;
        dispatch({
            type: dataFilteringTemplateConstants.OPEN_ADD, formInitialValue: {
                status: 'Active'
            }
        })
    }

    openFormEdit(rowIndex) {
        const { dispatch,formImportInitialValue } = this.props;
        const rowData = this.getRowData(rowIndex)
        // console.log('openFormEdit : ', rowData)
        dispatch({ type: dataFilteringTemplateConstants.OPEN_EDIT, formImportInitialValue: { ...rowData } })
// console.log('open formImportInitialValue : ', formImportInitialValue)
    }

    openFormInfo(rowIndex) {
        const { dispatch } = this.props;
        const rowData = this.getRowData(rowIndex)
        dispatch({ type: dataFilteringTemplateConstants.OPEN_INFO, formInitialValue: { ...rowData } })
    }

    openFormImportMember(rowIndex) {
        const { dispatch } = this.props;
        dispatch(change('DATAFILTERING_TEMPLATE_EDIT_FORM', 'fileImport', undefined))
        const rowData = this.getRowData(rowIndex)
        dispatch({
            type: dataFilteringTemplateConstants.OPEN_DATAFILTERING_TEMPLATE_EDIT_FORM, formImportInitialValue: {
                dataFilteringTemplateId: rowData._id
            }
        })
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: dataFilteringTemplateConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    render() {
       
        const { classes, dispatch, formInitialValue, tableDatas, tableFilter, showLoader, tableKey, formImportInitialValue } = this.props;
        let columns = [
            { name: "_id", label: 'Id', options: { filter: false, display: "excluded" } },
            
            {
                name: "templateName", label: "Template Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return rowData.templateName
                    }
                }
            },
            {
                name: "created_at", label: "Created at", options: {
                    customBodyRender: (value) => {
                        return plugins.datetimeFormat(value, 'date')
                    }
                }
            }
        ]
        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: "Conditions", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return (
                            <div className={classes.actionDiv}>
                                {
                                    plugins.isHasPermission(moduleName, 'update') &&
                                    <TableActionButton
                                        actionType="info"
                                        onClick={(e) => {
                                            this.openFormInfo(tableMeta.rowIndex)
                                        }}
                                    />
                                }
                            </div>
                        )
                    }
                }
            })
        }

        // if (plugins.isHasPermission(moduleName, 'read')) {
        //     columns.push({
        //         label: "Re-run", options: {
        //             filter: false,
        //             sort: false,
        //             customBodyRender: (value, tableMeta) => {
        //                 const rowData = this.getRowData(tableMeta.rowIndex)
                       
        //                 return (
        //                     <div className={classes.actionDiv}>
        //                         {plugins.isHasPermission(moduleName, 'delete') &&
        //                             <TableActionButton
        //                                 actionType="rerun"
        //                                 onClick={(e) => {
        //                                     dispatch({
        //                                         type: swalConstants.SHOW, data: {
        //                                             type: "warning",
        //                                             title: i18n.t("warning_messages.are_you_sure_rerun_dataFilteringTemplate"),
        //                                             message: `${rowData.templateName}`,
        //                                             onConfirm: function () {
        //                                                 let reqParams = {
        //                                                     templateName: rowData.templateName,
        //                                                     conditions: rowData.conditions
        //                                                 }
        //                                                 dispatch({ type: swalConstants.HIDE })
        //                                                 dispatch(dataFilteringTemplateAction.rerunDataFilteringTemplate(reqParams));
        //                                             },
        //                                             onCancel: function () {
        //                                                 dispatch({ type: swalConstants.HIDE })
        //                                             },
        //                                             showCancel: true,
        //                                             confirmBtnText: i18n.t("re-run"),
        //                                             cancelBtnText: i18n.t("cancel")
        //                                         }
        //                                     })
        //                                 }} />
        //                         }
        //                     </div>
        //                 )
        //             }
        //         }
        //     })
        // }

        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: "Edit", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return (
                            <div className={classes.actionDiv}>
                                {
                                    plugins.isHasPermission(moduleName, 'update') &&
                                    <TableActionButton
                                        actionType="edit"
                                        onClick={(e) => {
                                            this.openFormEdit(tableMeta.rowIndex)
                                        }}
                                    />
                                }
                            </div>
                        )
                    }
                }
            })
        }

        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: "Delete", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return (
                            <div className={classes.actionDiv}>
                                {plugins.isHasPermission(moduleName, 'delete') &&
                                    <TableActionButton
                                        actionType="delete"
                                        onClick={(e) => {
                                            dispatch({
                                                type: swalConstants.SHOW, data: {
                                                    type: "warning",
                                                    title: i18n.t("warning_messages.are_you_sure_delete_tag"),
                                                    message: `${rowData.templateName}`,
                                                    onConfirm: function () {
                                                        let reqParams = {
                                                            _id: rowData._id
                                                        }
                                                        dispatch({ type: swalConstants.HIDE })
                                                        dispatch(dataFilteringTemplateAction.deleteDataFilteringTemplate(reqParams));
                                                    },
                                                    onCancel: function () {
                                                        dispatch({ type: swalConstants.HIDE })
                                                    },
                                                    showCancel: true,
                                                    confirmBtnText: i18n.t("delete"),
                                                    cancelBtnText: i18n.t("cancel")
                                                }
                                            })
                                        }} />
                                }
                            </div>
                        )
                    }
                }
            })
        }

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: tableFilter.page ? tableFilter.page : 0,
            rowsPerPage: this.state.rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }

            }
        }
        return (
            <div id="TableDataFilteringTemplateList">
                <CustomSweetAlert />
                <SWImportResult isOpen={true} />
                <FormDataFilteringTemplate
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                    initialValues={formInitialValue} />
                <DataFilteringTemplateFormEdit
                    initialValues={formImportInitialValue}
                />
                {/* <FormTagImportMember
                    initialValues={formImportInitialValue} /> */}
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        title={"DataFiltering Template"}
                        data={tableDatas.dataFilteringTemplates}
                        columns={columns}
                        options={options}
                    />
                    {showLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableDatas,tableFilter, tableReload, showLoader, tableKey, tableReset, formImportInitialValue } = state.dataFilteringTemplate;
    // const { permission, superAdmin } = state.authentication;
    return {
        formInitialValue, tableDatas, tableFilter, tableReload, showLoader, tableKey, tableReset, formImportInitialValue
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableDataFilteringTemplateList));


import { bannerConstants } from '../constants';
import { bannerServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';
export const bannerActions = {
   addBanner,
   updateBanner,
   deleteBanner,
   getTableDatas
};

function addBanner(params){
   return dispatch => {
      loader(dispatch, true)
      bannerServices.addBanner(params)
         .then(
            response => {
               if(response.status){
                  dispatch({ type: bannerConstants.ADD_NEW_BANNER_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.banner_was_created'));
               }else{
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function updateBanner(params){
   return dispatch => {
      loader(dispatch, true)
      bannerServices.updateBanner(params)
         .then(
            response => {
               if(response.status){
                  dispatch({ type: bannerConstants.EDIT_BANNER_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.banner_was_updated'));
               }else{
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function deleteBanner(params){
   return dispatch => {
      loader(dispatch, true)
      bannerServices.deleteBanner(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch({ type: bannerConstants.DELETE_BANNER_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.banner_was_deleted"))
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function getTableDatas(params) {
   return dispatch => {
      dispatch({ type: bannerConstants.GET_BANNER_LIST_REQUEST })
      bannerServices.getListsBanner(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: bannerConstants.GET_BANNER_LIST_SUCCESS })
         })
   };

   function success(tmp) { return { type: bannerConstants.TABLE_GET_LIST_DATAS, tmp } }
}

import { configConstants } from '../constants';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { history, crmValidator, setHeaders } from 'helpers';
import lodash from 'lodash';
import objectToFormData from 'object-to-formdata';

export const campaignServices = {
    getCampaignsList,
    getListsCampaign,
    addCampaign,
    updateCampaign,
    deleteCampaign,
    getTrdPartyRewardCodeSetList,
    getListsCampaign_V2,
    addCampaign_V2,
    updateCampaign_V2,
    getCampaignByID_V2,
    deleteCampaign_v2,
    getTrueMoneyRewardSetList,
    createJsonFile,
    checkLimit,
    downloadCampaignReport
};

const formContentType = "multipart/form-data";
const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ"

function getCampaignsList(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/cms/campaigns`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getListsCampaign(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/cms/campaigns?${params}`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function addCampaign(values) {
    let formData = new FormData();
    formData.set("name", values.name);
    formData.set("title", values.title);
    formData.set("type", values.type);
    if (!crmValidator.isEmptyValue(values.quota)) {
        lodash.forEach(values.quota, function (value, key) {
            formData.set(`quota[${key}]`, parseInt(value))
        })
    }
    if (!crmValidator.isEmptyValue(values.quotaType)) {
        formData.set("quotaType", values.quotaType);
    }
    if (!crmValidator.isEmptyValue(values.useLimit)) {
        formData.set("useLimit", values.useLimit);
    }

    formData.set("pointBurn", values.pointBurn);
    formData.set("startDate", moment(values.startDate).format(dbDateFormat));
    if (!crmValidator.isEmptyValue(values.endDate)) {
        formData.set("endDate", moment(values.endDate).format(dbDateFormat));
    }
    if (!crmValidator.isEmptyValue(values.afterExpiredDate)) {
        formData.set("afterExpiredDate", moment(values.afterExpiredDate).format(dbDateFormat));
    }
    if (!crmValidator.isEmptyValue(values.trdPartyCodeSetId)) {
        formData.set('trdPartyCodeSetId', values.trdPartyCodeSetId)
    }
    formData.append('file', values.campaignImage);
    formData.set("description", values.description);
    formData.set("termAndCondition", btoa(encodeURIComponent(values.termAndCondition)));
    if (!crmValidator.isEmptyValue(values.SMSMessage)) {
        formData.set("SMSMessage", values.SMSMessage);
    }
    formData.set("redeemFormat", values.redeemFormat);
    formData.set("status", values.status);

    if (!crmValidator.isEmptyValue(values.tagList)) {
        formData.set("tagList", values.tagList);
    }
    if (!crmValidator.isEmptyValue(values.memberLevel)) {
        formData.set("memberLevel", values.memberLevel);
    }
    if (!crmValidator.isEmptyValue(values.customCaption)) {
        formData.set("customCaption", values.customCaption);
    }

    let tmp = axios.post(configConstants.API_BACKEND_CRM + '/cms/campaigns', formData, {
        headers: setHeaders({ 'Content-Type': formContentType, 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function updateCampaign(values) {
    let campaignId = values.campaignId;
    let formData = new FormData();
    formData.set("name", values.name);
    formData.set("title", values.title);
    formData.set("type", values.type);
    if (!crmValidator.isEmptyValue(values.quota)) {
        lodash.forEach(values.quota, function (value, key) {
            formData.set(`quota[${key}]`, parseInt(value))
        })
    }
    if (!crmValidator.isEmptyValue(values.quotaType)) {
        formData.set("quotaType", values.quotaType);
    }
    if (!crmValidator.isEmptyValue(values.useLimit)) {
        formData.set("useLimit", values.useLimit);
    }
    formData.set("pointBurn", values.pointBurn);
    formData.set("startDate", moment(values.startDate).format(dbDateFormat));
    if (!crmValidator.isEmptyValue(values.endDate)) {
        formData.set("endDate", moment(values.endDate).format(dbDateFormat));
    }
    if (!crmValidator.isEmptyValue(values.afterExpiredDate)) {
        formData.set("afterExpiredDate", moment(values.afterExpiredDate).format(dbDateFormat));
    }
    if (!crmValidator.isEmptyValue(values.trdPartyCodeSetId)) {
        formData.set('trdPartyCodeSetId', values.trdPartyCodeSetId)
    }
    if (values.campaignImage) {
        formData.append('file', values.campaignImage);
    }
    if (!crmValidator.isEmptyValue(values.description)) {
        formData.set("description", values.description);
    }
    if (!crmValidator.isEmptyValue(values.termAndCondition)) {
        formData.set("termAndCondition", btoa(encodeURIComponent(values.termAndCondition)));
    }
    if (!crmValidator.isEmptyValue(values.SMSMessage)) {
        formData.set("SMSMessage", values.SMSMessage);
    }
    if (!crmValidator.isEmptyValue(values.languages)) {
        formData.set("languages", values.languages);
    }
    if (!crmValidator.isEmptyValue(values.rewardType)) {
        formData.set("rewardType", values.rewardType);
    }

    formData.set("status", values.status);

    if (!crmValidator.isEmptyValue(values.tagList)) {
        formData.set("tagList", values.tagList);
    }
    if (!crmValidator.isEmptyValue(values.memberLevel)) {
        formData.set("memberLevel", values.memberLevel);
    }
    if (!crmValidator.isEmptyValue(values.customCaption)) {
        formData.set("customCaption", values.customCaption);
    }

    let tmp = axios.put(configConstants.API_BACKEND_CRM + `/cms/campaigns/${campaignId}`, formData, {
        headers: setHeaders({ 'Content-Type': formContentType, 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function deleteCampaign(params) {
    let tmp = axios.delete(configConstants.API_BACKEND_CRM + `/cms/campaigns/${params._id}/force`, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function getTrdPartyRewardCodeSetList(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/codeset${params.codeSetId ? '?codeSetId=' + params.codeSetId : ''}`, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.status.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

// V2

function getListsCampaign_V2(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/cms/campaigns/v2?${params}`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function addCampaign_V2(values) {
    const formData = objectToFormData(
        values
    );

    let tmp = axios.post(configConstants.API_BACKEND_CRM + '/cms/campaigns/v2', formData, {
        headers: setHeaders({ 'Content-Type': formContentType })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function updateCampaign_V2(values) {
    let campaignId = values._id;
    values = lodash.omit(values, "rewardDetail.imgPath")
    const formData = objectToFormData(
        values
    );

    let tmp = axios.put(configConstants.API_BACKEND_CRM + `/cms/campaigns/v2/${campaignId}`, formData, {
        headers: setHeaders({ 'Content-Type': formContentType })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function getCampaignByID_V2(campaignId) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/cms/campaigns/v2/find/${campaignId}`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                // return response.data.data
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function deleteCampaign_v2(params) {
    let tmp = axios.delete(configConstants.API_BACKEND_CRM + `/cms/campaigns/v2/${params._id}/force`, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function getTrueMoneyRewardSetList() {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/tmn-rewards`, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.status.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function createJsonFile(campaignId) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/cms/campaigns/v2/create-json/${campaignId}`, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.status.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function checkLimit() {
    let tmp = axios.post(configConstants.API_BACKEND_CRM + `/cms/campaigns/v2/check/campaign-limit`, null, {
        headers: setHeaders()
    })
        .then(response => {
            let statusCode = lodash.get(response, 'data.statusCode')
            if (statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function downloadCampaignReport(reqParams) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/cms/campaigns/v2/export/?${reqParams}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
        if (response.data.statusCode.toString() === "200") {
          const urlDownload = _.get(response.data, "data.url")
          if(urlDownload){
             window.location.assign(urlDownload)
          }
          return { status: true, data: {}, message: response.data.message };
        } else if (response.data.message === "UNAUTHORIZE") {
          history.push('/auth/login')
          return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
          return { status: false, data: {}, message: response.data.message };
        }
      }).catch(e => {
        return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      })
  
    return tmp;
}
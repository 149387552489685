import React from "react";
import lodash from "lodash";
import moment from "moment";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, reduxForm, change, getFormValues } from 'redux-form';

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { blacklistAction } from 'actions';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { blacklistConstants } from '../../constants';

import TableBlacklistList from "views/Blacklist/TableBlacklistList"
import TableBlacklistListUpload from "./TableBlacklistListUpload";
import BlacklistUploadResultForm from "./BlacklistUploadResultForm";

const style = {
    reportHeaderContainer: {
        margin: "0px",
        width: "calc(100% - 10px)",
        padding: "0px",
    },
    btnClearFilter: {
        marginRight: "10px",
        marginTop: "25px"
    },
    textCenter: {
        textAlign: "center"
    },
    mt20: {
        marginTop: "20px"
    }
};

const pageStyle = { ...style };
const FORM_NAME = "formFilterBlacklist";

class BlacklistContainer extends React.Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef()
        this.state = {
            campaignList: [],
            campaignAllList: [],
            typeAllList: []
        };
    }

    handleSubmit(values) {
        const { dispatch, formFilterBlacklist } = this.props;
        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "createdAt",
            sortDir: "desc",
            keyword: "",
            search: {}
        }

        tableFilter.search = {
            "campaign": (formFilterBlacklist.values === undefined || formFilterBlacklist.values.campaign === undefined || formFilterBlacklist.values.campaign === 'all') ? "" : formFilterBlacklist.values.campaign,
            "type": (formFilterBlacklist.values === undefined || formFilterBlacklist.values.type === undefined || formFilterBlacklist.values.type === 'all') ? "" : formFilterBlacklist.values.type,
            "value": (formFilterBlacklist.values === undefined || formFilterBlacklist.values.blacklistvalue === undefined) ? "" : formFilterBlacklist.values.blacklistvalue
        }
        const resetTable = true;

        dispatch(blacklistAction.getBlacklistList(tableFilter, resetTable));
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch(change(FORM_NAME, 'campaign', ''));
        dispatch(change(FORM_NAME, 'type', ''));
        dispatch(change(FORM_NAME, 'blacklistvalue', ''));

        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "createdAt",
            sortDir: "desc",
            keyword: "",
            search: {}
        }
        const resetTable = true
        dispatch(blacklistAction.getBlacklistList(tableFilter, resetTable));
    }

    saveUpload = () => {
        const { dispatch, importResult } = this.props;
        dispatch(blacklistAction.saveUploadBlacklist(importResult));
    }

    clearUpload = () => {
        const { dispatch } = this.props;
        dispatch({ type: blacklistConstants.CLOSE_SAVE_UPLOAD_BLACKLIST_RESULT });
    }

    componentDidMount() {
        this.getCampaignList()
    }

    getCampaignList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"

        dispatch(blacklistAction.getCampaignList(reqParams))
    }

    render() {

        const { classes, formFilterBlacklist, handleSubmit, blacklistContainer, formOpen, showImportResult, formInitialValue } = this.props;

        let campaignAllList = [];
        campaignAllList.push({
            value: "all",
            label: "All"
        })

        if (lodash.has(blacklistContainer, 'campaignList')) {
            lodash.forEach(blacklistContainer.campaignList, function (value, key) {
                //console.log("aaaaaa",value)
                campaignAllList.push({
                    value: value.name,
                    label: value.name,
                    id: value._id,
                })

            })
        }

        let typeAllList = [];
        typeAllList.push(
            {
                value: "all",
                label: "All"
            },
            {
                value: "Mobile",
                label: "Mobile"
            },
            {
                value: "Email",
                label: "Email"
            },
            {
                value: "LineID",
                label: "Line ID"
            },
            {
                value: "FacebookID",
                label: "Facebook ID"
            },
        )

        //console.log(formOpen,showImportResult)
        const displayCard = (!showImportResult && !formOpen) ? {} : { display: "none" }
        const displayCardUpload = showImportResult ? {} : { display: "none" }
        return (
            <div>
                {/* <Card>
                    <CardBody style={displayCard}>

                    </CardBody>
                </Card> */}
                <BlacklistUploadResultForm initialValues={formInitialValue} />

                <Card>
                    <CardBody style={displayCard}>
                        <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>BLACKLIST LIST</h4>
                        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                            <GridContainer className={classes.reportHeaderContainer}>
                                <GridItem xs={6} sm={3}>
                                    <h6><b>Campaign name</b></h6>
                                </GridItem>
                                <GridItem xs={6} sm={3}>
                                    <h6><b>Type</b></h6>
                                </GridItem>
                                <GridItem xs={6} sm={3}>
                                    <h6><b>Blacklists</b></h6>
                                </GridItem>
                            </GridContainer>

                            <GridContainer className={classes.reportHeaderContainer}>
                                <GridItem xs={6} sm={3}>
                                    <Field
                                        name="campaign"
                                        component={CustomSelect}
                                        type="text"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        optionsList={campaignAllList}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={3}>
                                    <Field
                                        name="type"
                                        component={CustomSelect}
                                        type="text"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        optionsList={typeAllList}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={3}>
                                    <Field
                                        name="blacklistvalue"
                                        component={CustomInput}
                                        type="text"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4} lg={3}>
                                    <Button
                                        onClick={this.clearFilter}
                                        style={{
                                            marginTop: "25px"
                                        }}
                                        className={classes.btnClearFilter}
                                        color="white" >
                                        Clear
                                    </Button>
                                    &nbsp;  &nbsp;  &nbsp;
                                    <Button
                                        type="submit"
                                        style={{
                                            marginTop: "25px"
                                        }}
                                        color="primary">
                                        Submit
                                    </Button>
                                </GridItem>

                            </GridContainer>
                        </form>
                        <GridContainer>
                            <GridItem xs={12}>
                                <TableBlacklistList showAction={false} showSearch={false} />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody style={displayCardUpload}>
                        <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>BLACKLIST LIST UPLOAD</h4>
                        <GridContainer>
                            <GridItem xs={12}>
                                <TableBlacklistListUpload showAction={false} showSearch={false} />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={3}>
                                <Button
                                    onClick={this.saveUpload}
                                    style={{
                                        marginTop: "25px"
                                    }}
                                    color="primary">
                                    Submit
                                </Button>
                                &nbsp;  &nbsp;  &nbsp;
                                <Button
                                    onClick={this.clearUpload}
                                    style={{
                                        marginTop: "25px"
                                    }}
                                    className={classes.btnClearFilter}
                                    color="white" >
                                    Clear
                                </Button>
                            </GridItem>

                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { sweetAlert } = state.sweetAlert;
    const { formFilterBlacklist } = state.form;
    const { formOpen, blacklistContainer, showImportResult, importResult, formInitialValue } = state.blacklist;
    return {
        sweetAlert, formFilterBlacklist, formOpen, blacklistContainer, showImportResult, importResult, formInitialValue
    };
}

//export default connect(mapStateToProps)(withStyles(pageStyle)(WhitelistContainer));

const connectBlacklistContainer = connect(mapStateToProps)(BlacklistContainer);

export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    onChange: (values, dispatch, props, previousValues) => {
        if (values !== previousValues) {
            dispatch({ type: blacklistConstants.TABLE_RESET });
        }
    }
})(withStyles(pageStyle)(connectBlacklistContainer));

import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import MUIDataTable from "mui-datatables";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// page components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Button from "components/CustomButtons/Button";

import { plugins, crmValidator } from "helpers";
import FormImportFile from './FormImportFile'
import ImportResultDetail from './ImportResultDetail'

import i18n from "i18n";
import { importAction } from "actions/import.actions";

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    actionDiv: {
        display: "inline-flex",
    },
};

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

const renderStatus = (status) => {
    let color = ""
    let statusText = ""
    switch(status){
       case "success": color = "#4caf50"; statusText = "complete"; break;
       case "waiting": color = "#ff9800"; statusText = "waiting"; break;
       case "inprogress": color = "#2196f3"; statusText = "in progress"; break;
       default: color = ""; statusText = "";
    }
    return (<span style={{
        textTransform: "capitalize",
        color: color,
        fontWeight: 900 
    }}>{statusText}</span>)
}

const renderImportTypeName = (type) => {
    switch(type){
        case "purchase_transaction": return "Purchase Transaction"
        case "create_product": return "Create Product"
        case "member": return "Member"
        default: return ""
    }
}

class TableImportHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            reqParams: ""
        }
        this.delayedCallback = lodash.debounce(this.getData, 500)
        this.openImportForm = this.openImportForm.bind(this)
    }

    openImportForm() {
        const { dispatch } = this.props;
        dispatch({ type: 'OPEN_FORM_IMPORT_FILE' })
    }

    openDetail(rowIndex) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.data[rowIndex]
        dispatch(importAction.getDetailById(rowData._id));
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        const { tableReset, dispatch } = this.props;
        if (tableReset) {
           this.getData();
           dispatch({ type: 'IMPORT_HISTORY_TABLE_RELOAD_SUCCESS' })
        }
     }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props
        let reqParams = ""
        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }
            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }
            this.setState({
                page: skip,
                rowsPerPage: limit
            })

            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + keyword
            }

        } else {
            reqParams = this.state.reqParams
        }

        dispatch(importAction.getImportHistory(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });
    }

    isHasCreatePermission() {
        return plugins.isHasPermission("import[purchase_transaction]", 'create') || plugins.isHasPermission("import[create_product]", 'create') || plugins.isHasPermission("import[member]", 'create')
    }

    render() {
        const { tableKey, tableDatas, formInitialValue } = this.props
        const { page, rowsPerPage } = this.state

        let columns = [
            { name: "_id", options: { display: "excluded" } },
            {
                label: i18n.t("label.no"),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowSatrt =
                            tableMeta.tableState.page *
                            tableMeta.tableState.rowsPerPage;
                        const rowIndex = rowSatrt + tableMeta.rowIndex;
                        return rowIndex + 1;
                    },
                },
            },
            {
                name: "created_at",
                label: "Upload Date/time",
                options:{
                    customBodyRender: (value) => plugins.datetimeFormat(value, 'datetime')
                }
            },
            { name: "fileName", label: "File Name" },
            {
                name: "type",
                label: "Import Type",
                options: {
                    customBodyRender: (value) => renderImportTypeName(value)
                }
            },
            {
                name: "status",
                label: "Import Status",
                options: {
                    customBodyRender: (value) => renderStatus(value)
                }
            },
            { name: "noOfFailed", label: "No. of Unsuccessful Row" },
            {
                name: "status",
                label: "Detail",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        if(value === 'success'){
                            return (
                            <Button
                                size="sm"
                                id={`btn_import_detail_${tableMeta.rowIndex + 1}`}
                                style={{backgroundColor: "#2196f3"}}
                                onClick={()=>{this.openDetail(tableMeta.rowIndex)}}>
                                    Detial
                            </Button>)
                        }
                    }
                }},
        ];

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [1, 5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        if (this.isHasCreatePermission()) {
            options.customToolbar = () => {
                return (
                    <CustomToolbar handleClick={this.openImportForm} id="btn_open_form_import" title="Import File" importIcon />
                );
            }
        }

        return (
            <div id='tableImportList'>
                <CustomSweetAlert />
                <FormImportFile initialValues={formInitialValue}/>
                <ImportResultDetail/>
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        title={"IMPORT FILE LIST"}
                        data={tableDatas.data}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { tableKey, tableDatas, formInitialValue, tableReset } = state.importReducer;
    const { permission, superAdmin } = state.authentication;
    return {
        permission, superAdmin, tableKey, tableDatas, formInitialValue, tableReset
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableImportHistory));

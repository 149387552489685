import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import TableProduct from "./TableProduct"

 
const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};
function Product({ ...props }) {
    return (
        <Card>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12}>
                        <TableProduct/>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card> 
    )
}

export default withStyles(style)(Product);
import { winnerApproveConstants } from '../constants';
const initialState = {
   formOpen: false,
   submitForm: false,
   formInitialValue: {},
   tableReload: false,
   tableReset: false,
   tableDatas: [],
   tableKey: 0,
   tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "createdAt",
      sortDir: "desc",
      keyword: "",
      search: {}
   },
   showLoader: false
};

export function winner_approve(state = initialState, action) {
   switch (action.type) {
      case winnerApproveConstants.CLEAR_PAGE_STATE:
         return initialState;
      case winnerApproveConstants.TABLE_RESET:
         return {
            ...state,
            tableReload: false,
            tableReset: false,
         }
      case winnerApproveConstants.SHOW_LOADER_WINNER_APPROVE:
         return {
            ...state,
            showLoader: true
         }
      case winnerApproveConstants.HIDE_LOADER_WINNER_APPROVE:
         return {
            ...state,
            showLoader: false
         }
      case winnerApproveConstants.TABLE_RELOAD_SUCCESS:
      case winnerApproveConstants.TABLE_RESET:
         return {
            ...state,
            tableReload: false,
            tableReset: false,
         }
      case winnerApproveConstants.TABLE_GET_LIST_DATAS:
         return {
            ...state,
            ...state.winner_approve,
            tableFilter: action.tableFilter,
            tableDatas: (action.data ? action.data : []),
            tableTotal: (action.total ? action.total : 0)
         }
      case winnerApproveConstants.TABLE_GET_LIST_DATAS_RESET:
         return {
            ...state,
            winnerApproveList: {
               ...state.winnerApproveList,
               tableKey: Date.now()
            }
         }
      case winnerApproveConstants.APPROVE_WINNER_SUCCESS:
      case winnerApproveConstants.APPROVE_UPLOAD_WINNER_SUCCESS:
      case winnerApproveConstants.REJECT_WINNER_SUCCESS:
         return {
            ...state,
            formOpen: false,
            tableReload: true,
            tableReset: false,
            tableKey: new Date(),
            formInitialValue: {}
         }
      case winnerApproveConstants.GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS:
         return {
            ...state,
            winnerApproveList: {
               ...state.winnerApproveList,
               announceDateList: action.data.announceDates,
            },
         }
      case winnerApproveConstants.GET_DROPDOWN_REWARD_LIST_SUCCESS:
         return {
            ...state,
            winnerApproveList: {
               ...state.winnerApproveList,
               rewardList: action.data.rewards,
            },
         }
      default:
         return state;
   }
}

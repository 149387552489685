export const campaignConstants = {
   OPEN_ADD_CAMPAIGN: 'OPEN_ADD_CAMPAIGN',
   OPEN_ADD_CAMPAIGN_NEW: 'OPEN_ADD_CAMPAIGN_NEW',
   OPEN_EDIT_CAMPAIGN: 'OPEN_EDIT_CAMPAIGN',
   CLOSE_FORM_CAMPAIGN: 'CLOSE_FORM_CAMPAIGN',
   ADD_NEW_CAMPAIGN_SUCCESS: 'ADD_NEW_CAMPAIGN_SUCCESS',
   EDIT_CAMPAIGN_SUCCESS: 'EDIT_CAMPAIGN_SUCCESS',
   TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
   TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',
   SET_FORM_PREVIEW_IMAGE_CAMPAIGN: 'SET_FORM_PREVIEW_IMAGE_CAMPAIGN',
   DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS',
   GET_CAMPAIGN_DATA_SUCCESS: 'GET_CAMPAIGN_DATA_SUCCESS',
   CLEAR_PAGE_STATE: 'CAMPAIGN_CLEAR_PAGE_STATE',
   TABLE_GET_LIST_CAMPAIGNS: 'TABLE_GET_LIST_CAMPAIGNS',
   GET_TRD_PARTY_CODE_SET_SUCCESS: 'GET_TRD_PARTY_CODE_SET_SUCCESS'
};
import { dashboardConstants } from '../constants';
import { dashboardServices } from '../services';
import lodash from 'lodash';
import moment from 'moment'


import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const dashboardActions = {
   getTableBurnDatas,
   getTableEarnDatas,
   getTableDailyRegistrationDatas,
   getTableDailyEarnTransactionDatas,
   getKeySummary,
   getRegistration,
   getRegistrationDayofweek,
   getEarnTransactionList,
   getBurnTransactionList,
   getTableEarnBySkuDatas,
   getTableRegistrationByHourDatas,
   getTableRegistrationByProvinceDatas,
   getTableBurnTransactionByCampaignDatas,
   getTableInactiveUser,
   getCampaignList,
   getTableDailyBurnTransactionDatas,
   getTableBurnTransactionByDayAndHourDatas,
   getTableEarnTransactionByDayAndHourDatas
};

function getTableBurnDatas(params) {
   return dispatch => {
      dispatch({ type: dashboardConstants.SHOW_LOADER_DASHBOARD })
      dashboardServices.getListsBurnDashboard(params)
         .then(
            response => {
               if (response.status) {
                  const apiData = response.data.data
                  const activeUser = lodash.get(params, 'search.type')
                  const startDate = lodash.get(params, 'search.startDate')
                  const endDate = lodash.get(params, 'search.endDate')
                  const nextLoopCondition = (activeUser === 'monthly') ? 'months' : 'days'
                  const labelsFormat = (activeUser === 'monthly') ? 'YYYY-MM' : 'YYYY-MM-DD'
                  let newTableData = []

                  if (activeUser !== 'period') {
                     for (var m = moment(endDate); m.diff(startDate, 'days') >= 0; m.add(-1, nextLoopCondition)) {
                        let result = lodash.find(apiData, { reportDate: m.format(labelsFormat) })
                        if (result) {
                           newTableData.push(result)
                        } else {
                           newTableData.push({
                              _id: '',
                              reportDate: moment(m).format(labelsFormat),
                              count: 0
                           })
                        }
                     } 
                  } else if (activeUser === 'period') {
                     newTableData = apiData;
                  }
                  newTableData.reverse();
                  dispatch({ type: dashboardConstants.TABLE_GET_LIST_DATAS, data: newTableData, tableFilter: params })

               } else {
                  dispatch({ type: dashboardConstants.TABLE_GET_LIST_DATAS, data: [], tableFilter: params })
               }
            },
            error => {
               dispatch({ type: dashboardConstants.TABLE_GET_LIST_DATAS, data: [], tableFilter: params })
            }
         ).then(() => {
            dispatch({ type: dashboardConstants.HIDE_LOADER_DASHBOARD })
         })
   }
}

function getTableEarnDatas(params) {
   return dispatch => {
      dispatch({ type: dashboardConstants.SHOW_LOADER_DASHBOARD })
      dashboardServices.getListsEarnDashboard(params)
         .then(
            response => {
               if (response.status) {
                  const apiData = response.data.data
                  const activeUser = lodash.get(params, 'search.type')
                  const startDate = lodash.get(params, 'search.startDate')
                  const endDate = lodash.get(params, 'search.endDate')
                  const nextLoopCondition = (activeUser === 'monthly') ? 'months' : 'days'
                  const labelsFormat = (activeUser === 'monthly') ? 'YYYY-MM' : 'YYYY-MM-DD'
                  let newTableData = []

                  if (activeUser !== 'period') {
                     for (var m = moment(endDate); m.diff(startDate, 'days') >= 0; m.add(-1, nextLoopCondition)) {
                        let result = lodash.find(apiData, { reportDate: m.format(labelsFormat) })
                        if (result) {
                           newTableData.push(result)
                        } else {
                           newTableData.push({
                              _id: '',
                              reportDate: moment(m).format(labelsFormat),
                              count: 0
                           })
                        }
                     }
                  } else if (activeUser === 'period') {
                     newTableData = apiData;
                  }
                  newTableData.reverse();
                  dispatch({ type: dashboardConstants.TABLE_GET_LIST_DATAS, data: newTableData, tableFilter: params })

               } else {
                  dispatch({ type: dashboardConstants.TABLE_GET_LIST_DATAS, data: [], tableFilter: params })
               }
            },
            error => {
               dispatch({ type: dashboardConstants.TABLE_GET_LIST_DATAS, data: [], tableFilter: params })
            }
         ).then(() => {
            dispatch({ type: dashboardConstants.HIDE_LOADER_DASHBOARD })
         })
   }
}

function getTableDailyRegistrationDatas(params) {
   return dispatch => {
      dispatch({ type: dashboardConstants.SHOW_LOADER_DAILY_REGISTRATION_DASHBOARD })
      dashboardServices.getListsDailyRegistrationDashboard(params)
         .then(
            response => {
               if (response.status) {
                  const apiData = response.data.data
                  const startDate = lodash.get(params, 'search.startDate')
                  const endDate = lodash.get(params, 'search.endDate')
                  const nextLoopCondition = 'days'
                  const labelsFormat = 'YYYY-MM-DD'
                  let newTableData = []

                  for (var m = moment(endDate); m.diff(startDate, 'days') >= 0; m.add(-1, nextLoopCondition)) {
                     let result = lodash.find(apiData, { reportDate: m.format(labelsFormat) })
                     if (result) {
                        newTableData.push(result)
                     } else {
                        newTableData.push({
                           _id: '',
                           reportDate: moment(m).format(labelsFormat),
                           count: 0
                        })
                     }
                  }

                  newTableData.reverse();
                  dispatch({ type: dashboardConstants.TABLE_GET_DAILY_REGISTRATION_LIST_DATAS, data: newTableData, tableDialyRegistrationFilter: params })

               } else {
                  dispatch({ type: dashboardConstants.TABLE_GET_DAILY_REGISTRATION_LIST_DATAS, data: [], tableDialyRegistrationFilter: params })
               }
            },
            error => {
               dispatch({ type: dashboardConstants.TABLE_GET_DAILY_REGISTRATION_LIST_DATAS, data: [], tableDialyRegistrationFilter: params })
            }
         ).then(() => {
            dispatch({ type: dashboardConstants.HIDE_LOADER_DAILY_REGISTRATION_DASHBOARD })
         })
   }
}

function getTableDailyEarnTransactionDatas(params) {
   return dispatch => {
      dispatch({ type: dashboardConstants.SHOW_LOADER_DAILY_EARN_TRANSACTION_DASHBOARD })
      dashboardServices.getListsDailyEarnTransactionDashboard(params)
         .then(
            response => {
               if (response.status) {
                  const apiData = response.data.data
                  const startDate = lodash.get(params, 'search.startDate')
                  const endDate = lodash.get(params, 'search.endDate')
                  const nextLoopCondition = 'days'
                  const labelsFormat = 'YYYY-MM-DD'
                  let newTableData = []

                  for (var m = moment(endDate); m.diff(startDate, 'days') >= 0; m.add(-1, nextLoopCondition)) {
                     let result = lodash.find(apiData, { reportDate: m.format(labelsFormat) })
                     if (result) {
                        newTableData.push(result)
                     } else {
                        newTableData.push({
                           _id: '',
                           reportDate: moment(m).format(labelsFormat),
                           count: 0
                        })
                     }
                  }

                  newTableData.reverse();
                  dispatch({ type: dashboardConstants.TABLE_GET_DAILY_EARN_TRANSACTION_LIST_DATAS, data: newTableData, tableDialyEarnTransactionFilter: params })

               } else {
                  dispatch({ type: dashboardConstants.TABLE_GET_DAILY_EARN_TRANSACTION_LIST_DATAS, data: [], tableDialyEarnTransactionFilter: params })
               }
            },
            error => {
               dispatch({ type: dashboardConstants.TABLE_GET_DAILY_EARN_TRANSACTION_LIST_DATAS, data: [], tableDialyEarnTransactionFilter: params })
            }
         ).then(() => {
            dispatch({ type: dashboardConstants.HIDE_LOADER_DAILY_EARN_TRANSACTION_DASHBOARD })
         })
   }
}

function getKeySummary() {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getKeySummary()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_KEYSUMMARY_SUCCESS, data } }
}

function getRegistration() {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getRegistration()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_REGISTRATION_SUCCESS, data } }
}

function getRegistrationDayofweek() {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getRegistrationDayofweek()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_REGISTRATION_DAYOFWEEK_SUCCESS, data } }
}

function getEarnTransactionList(param) {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getEarnTransactionList(param)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_EARN_STATUS_SUCCESS, data } }
}

function getBurnTransactionList(param) {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getBurnTransactionList(param)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_BURN_STATUS_SUCCESS, data } }
}

function getTableEarnBySkuDatas() {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getTableEarnBySkuDatas()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_EARN_SKU_SUCCESS, data } }
}

function getTableRegistrationByHourDatas() {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getTableRegistrationByHourDatas()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_REGISTRATION_BY_HOUR_SUCCESS, data } }
}

function getTableRegistrationByProvinceDatas() {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getTableRegistrationByProvinceDatas()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_REGISTRATION_BY_PROVINCE_SUCCESS, data } }
}

function getTableBurnTransactionByCampaignDatas() {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getTableBurnTransactionByCampaignDatas()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_BURN_TRANSACTION_BY_CAMPAIGN_SUCCESS, data } }
}

function getCampaignList(params) {
   return dispatch => {
      dashboardServices.getCampaignsList(params)
           .then(
               response => {
                   if (response.status) {
                       if (lodash.has(response, 'data')) {
                           dispatch(success(response.data));
                       }
                   } else {
                       if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                   }
               },
               error => {
                   customAlert(dispatch, 'error', i18n.t(defaultFailedText))
               }
           ).then(() => {
               // loader(dispatch, false)
           })
   };

   function success(data) {
       return { type: dashboardConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data }
   }
}

function getTableDailyBurnTransactionDatas(params) {
   return dispatch => {
      dispatch({ type: dashboardConstants.SHOW_LOADER_DAILY_BURN_TRANSACTION_DASHBOARD })
      dashboardServices.getListsDailyBurnTransactionDashboard(params)
         .then(
            response => {
               if (response.status) {
                  const apiData = response.data.data
                  const startDate = lodash.get(params, 'search.startDate')
                  const endDate = lodash.get(params, 'search.endDate')
                  const nextLoopCondition = 'days'
                  const labelsFormat = 'YYYY-MM-DD'
                  let newTableData = []

                  for (var m = moment(endDate); m.diff(startDate, 'days') >= 0; m.add(-1, nextLoopCondition)) {
                     let result = lodash.find(apiData, { reportDate: m.format(labelsFormat) })
                     if (result) {
                        newTableData.push(result)
                     } else {
                        newTableData.push({
                           _id: '',
                           reportDate: moment(m).format(labelsFormat),
                           count: 0
                        })
                     }
                  }

                  newTableData.reverse();
                  dispatch({ type: dashboardConstants.TABLE_GET_DAILY_BURN_TRANSACTION_LIST_DATAS, data: newTableData, tableDialyBurnTransactionFilter: params })

               } else {
                  dispatch({ type: dashboardConstants.TABLE_GET_DAILY_BURN_TRANSACTION_LIST_DATAS, data: [], tableDialyBurnTransactionFilter: params })
               }
            },
            error => {
               dispatch({ type: dashboardConstants.TABLE_GET_DAILY_BURN_TRANSACTION_LIST_DATAS, data: [], tableDialyBurnTransactionFilter: params })
            }
         ).then(() => {
            dispatch({ type: dashboardConstants.HIDE_LOADER_DAILY_BURN_TRANSACTION_DASHBOARD })
         })
   }
}

function getTableBurnTransactionByDayAndHourDatas() {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getTableBurnTransactionByDayAndHourDatas()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_BURN_TRANSACTION_BY_DAY_HOUR_SUCCESS, data } }
}

function getTableEarnTransactionByDayAndHourDatas() {
   return dispatch => {
      loader(dispatch, true)
      dashboardServices.getTableEarnTransactionByDayAndHourDatas()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_EARN_TRANSACTION_BY_DAY_HOUR_SUCCESS, data } }
}


function getTableInactiveUser() {
   return dispatch => {
      dispatch({ type: dashboardConstants.SHOW_LOADER_INACTIVE_USER })
      dashboardServices.getTableInactiveUser()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_INACTIVE_USER, data } }
}
import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import { plugins } from 'helpers';
import OcrSummaryReport from './OcrSummaryReport'
const style = {};

class OcrReportContainer extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'OCR_REDUCER_INIT' })
    }

    render() {
        return (
            <Card>
                <CardBody>
                <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>OCR Report</h4>
                <OcrSummaryReport initialValues={{
                        startDate: moment().startOf('month'),
                        endDate: moment()
                    }} />
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { tableReset } = state.member;
    const { user, superAdmin, permission } = state.authentication;
    return {
        tableReset, user, superAdmin, permission
    };
}
const connectOcrContainer = connect(mapStateToProps)(OcrReportContainer);

export default withStyles(style)(connectOcrContainer);


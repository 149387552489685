
import { dataFilteringResultConstants } from '../constants';
import lodash from 'lodash'

const initialState = {
    formOpen: false,
    formConditionOpen: false,
    formAssignCampaignOpen: false,
    formAssignTagOpen: false,
    formUnAssignOpen: false,
    formVisualOpen: false,
    formInitialValue: {},
    tableKey: new Date(),
    tableReload: false,
    tableReset: false,
    showLoader: false,
    tableDatas: {
        dataFilteringResult: [],
        total: 0
    },
    datafiltering: [],
    campaignList: [],
    assignCampaign: [],
    assignTag: [],
    visualization: [],
}

export function dataFilteringResult(state = initialState, action) {
    switch (action.type) {
        case dataFilteringResultConstants.CLEAR_PAGE_STATE:
            return initialState;
        case dataFilteringResultConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                campaignList: action.data.campaigns
            }
        case dataFilteringResultConstants.OPEN_FORM_CONDITION:
            const data = {
                _id: action.data["_id"],
                conditionId: action.data["conditionId"],
                templateName: action.data["templateName"],
                conditions: action.data["conditions"],
            }
   
            return {
                ...state,
                formConditionOpen: true,
                formInitialValue:{
                    ...data
                },
            };
        case dataFilteringResultConstants.CLOSE_FORM_CONDITION:
            return {
                ...state,
                formConditionOpen: false,
                formInitialValue: {}
            }
        case dataFilteringResultConstants.OPEN_FORM_ASSIGN_CAMPAIGN:
            return {
                ...state,
                formAssignCampaignOpen: true,
                formInitialValue: action.formInitialValue,
            };
        case dataFilteringResultConstants.CLOSE_FORM_ASSIGN_CAMPAIGN:
            return {
                ...state,
                formAssignCampaignOpen: false,
                formInitialValue: {}
            }
        case dataFilteringResultConstants.ASSIGN_CAMPAIGN_SUCCESS:
            return {
                ...state,
                formAssignCampaignOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case dataFilteringResultConstants.OPEN_FORM_ASSIGN_TAG:
            return {
                ...state,
                formAssignTagOpen: true,
                formInitialValue: action.formInitialValue,
            };
        case dataFilteringResultConstants.CLOSE_FORM_ASSIGN_TAG:
            return {
                ...state,
                formAssignTagOpen: false,
                formInitialValue: {}
            }
        case dataFilteringResultConstants.ASSIGN_TAG_SUCCESS:
            return {
                ...state,
                formAssignTagOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case dataFilteringResultConstants.OPEN_FORM_UNASSIGN:
            const assignCampaign = action.data["campaigns"];
            const assignTag = action.data["tags"];

            return {
                ...state,
                formUnAssignOpen: true,
                assignCampaign: assignCampaign,
                assignTag: assignTag,
                formInitialValue: {
                    conditionId:action.data["conditionId"],
                    resultId:action.data["resultId"]
                }
            };
        case dataFilteringResultConstants.CLOSE_FORM_UNASSIGN:
            return {
                ...state,
                formUnAssignOpen: false,
                formInitialValue: {},
                assignCampaign:[],
                assignTag:[],
            }
        case dataFilteringResultConstants.UNASSIGN_TAG_SUCCESS:
        case dataFilteringResultConstants.UNASSIGN_CAMPAIGN_SUCCESS:
            return {
                ...state,
                formUnAssignOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case dataFilteringResultConstants.OPEN_FORM_VISUALIZATION:
            return {
                ...state,
                formVisualOpen: true,
                visualization: action.data.visualization,
            };
        case dataFilteringResultConstants.CLOSE_FORM_VISUALIZATION:
            return {
                ...state,
                formVisualOpen: false,
            }
        case dataFilteringResultConstants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case dataFilteringResultConstants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case dataFilteringResultConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReset: false,
                tableReload: false
            }
        case dataFilteringResultConstants.GET_DATAFILTERING_RESULT_SUCCESS:
            return {
                ...state,
                tableDatas: {
                    dataFilteringResult: lodash.has(action, 'tmp') ? action.tmp : [],
                    total: lodash.has(action, 'tmp.length') ? action.tmp.length : 0
                },
                formInitialValue: {}
            }
        case dataFilteringResultConstants.DELETE_DATAFILTERRING_RESULT_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        default:
            return state
    }
}

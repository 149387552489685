import { reset } from 'redux-form'
import lodash from 'lodash';

import { searchCodeServices } from '../services';
import { searchCodeConstants } from '../constants';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const searchCodeAction = {
   searchCode,
};

function searchCode(params) {
   return dispatch => {
        searchCodeServices.searchCode(params)
         .then(
            response => {
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     //console.log(response.data.data);
                     dispatch(success(response.data));
                  }
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: searchCodeConstants.GET_CODE_SUCCESS, data }
   }
}

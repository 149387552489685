import _ from "lodash"

import { shopConstants } from "../constants"

const initialState = {
  isOpen: false,
  formOpen: false,
  formMode: "add",
  formInitialValue: {},
  tableReload: false,
  sweetAlert: null,
  tableReset: false,
  tableDatas: {
    result: [],
    total: 0,
  },
  tableKey: 0,
  tableLoader: false,
  regionOptionList: [],
}

export function shop(state = initialState, action) {
  switch (action.type) {
    case shopConstants.CLEAR_PAGE_STATE:
      return initialState
    case shopConstants.OPEN_ADD_FORM_SHOP:
      return {
        ...state,
        formOpen: true,
        formMode: "add",
        formInitialValue: {
          _id: "",
          name: "",
          lat:_.get(action, "data.lat"),
          lng:_.get(action, "data.lng")
        },
      }
    case shopConstants.OPEN_EDIT_FORM_SHOP:
      return {
        ...state,
        formOpen: true,
        formMode: "edit",
        formInitialValue: {
          _id: _.get(action, "data._id"), 
          isPickUp:_.get(action, "data.isPickUp"),
          shopId:_.get(action, "data.shopId"),
          shopName:_.get(action, "data.shopName"),
          shopRegion:_.get(action, "data.shopRegion"),
          status:_.get(action, "data.status"),
          address:_.get(action, "data.address"),
          subDistrict:_.get(action, "data.subDistrict"),
          district:_.get(action, "data.district"),
          province:_.get(action, "data.province"),
          zipcode:_.get(action, "data.zipcode"),
          shopRegionId:_.get(action, "data.shopRegionId")||"notSpecified",
          brandId:_.get(action, "data.brandId"),
          openTime:_.get(action, "data.openTime"),
          closingTime:_.get(action, "data.closingTime"),
          lat:_.get(action, "data.lat"),
          lng:_.get(action, "data.lng"),
        },
      }
    case shopConstants.CLOSE_FORM_SHOP:
      return {
        ...state,
        formOpen: false,
        formInitialValue: {},
      }

    case shopConstants.ADD_NEW_SHOP_SUCCESS:
      return {
        ...state,
        formOpen: false,
        formInitialValue: {},
        tableReload: false,
        tableReset: true,
        tableKey: new Date(),
      }
    case shopConstants.EDIT_SHOP_SUCCESS:
      return {
        ...state,
        formOpen: false,
        formInitialValue: {},
        tableReset: false,
        tableReload: true,
      }
    case shopConstants.TABLE_RELOAD_SUCCESS:
      return {
        ...state,
        tableReload: false,
        tableReset: false,
      }
    case shopConstants.TABLE_RESET:
      return {
        ...state,
        tableReset: true,
      }
    case shopConstants.TABLE_RESET_SUCCESS:
      return {
        ...state,
        tableReset: false,
      }
    case shopConstants.SHOW_TABLE_LOADER:
      return {
        ...state,
        tableLoader: true,
      }
    case shopConstants.HIDE_TABLE_LOADER:
      return {
        ...state,
        tableLoader: false,
      }
    case shopConstants.TABLE_GET_LIST_DATA:
      return {
        ...state,
        tableDatas: action.data,
        tableReload: false,
        tableReset: false,
      }
    case shopConstants.GET_SHOP_REGION_LIST:
      return {
        ...state,
        regionOptionList:_.get(action, "data.shopRegions"),
      }

      case shopConstants.DELETE_SHOP_LIST_SUCCESS: {
        return {
            ...state,
            tableReload: true
        }
    }
    default:
      return state
  }
}

export const ReferenceCodeConstants = {

    CLEAR_PAGE_STATE: 'REFERENCE_CODE_CLEAR_PAGE_STATE',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
    TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',
    TABLE_GET_LIST_REFERENCE: 'TABLE_GET_LIST_REFERENCE',

    GET_REFERENCE_CAMPAIGNSLIST_REQUEST: 'GET_REFERENCE_CAMPAIGNSLIST_REQUEST',
    GET_REFERENCE_CAMPAIGNSLIST_SUCCESS: 'GET_REFERENCE_CAMPAIGNSLIST_SUCCESS',

    OPEN_ADD_REFERENCE: 'OPEN_ADD_REFERENCE',
    OPEN_EDIT_REFERENCE: 'OPEN_EDIT_REFERENCE',
    CLOSE_FORM_REFERENCE: 'CLOSE_FORM_REFERENCE',

    ADD_NEW_REFERENCE_SUCCESS: 'ADD_NEW_REFERENCE_SUCCESS',
    ADD_NEW_REFERENCE_FAILURE: 'ADD_NEW_REFERENCE_FAILURE',
    
    EDIT_REFERENCE_SUCCESS: 'EDIT_TAG_SUCCESS',
    EDIT_REFERENCE_FAILURE: 'EDIT_TAG_FAILURE',

    DELETE_REFERENCE_SUCCESS: 'DELETE_REFERENCE_SUCCESS',
    DELETE_REFERENCE_FAILURE: 'DELETE_REFERENCE_FAILURE',

    

 };
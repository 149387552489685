import _ from 'lodash'
import moment from "moment";

export const form_validate = {
    "quotaNumber": {
        rules: {
            number: true,
            noSpecialCharacters: true,
        },
        messages: {
            number: "warning_messages.number_greater_than_zero"
        },
        alert: "warning_messages.number_greater_than_zero"
    },
    "referenceCode": {
        rules: {
            referenceCodeCharacters: true,
        },
        messages: {
            referenceCodeCharacters: "warning_messages.invalide_reference_code"
        },
        alert: "warning_messages.invalide_reference_code"
    },
}

export const validateTextInput = values => {
    if (!values || values.length === 0) {
        return "Please Type input."
     }
}

export const validateDateSelect = values => {
    if (values === undefined || values.length === 0) {
        return "Please Select date."
     }
}

export const validateSelectedDate = (value, allValues) => {
   if(_.get(allValues,'customRadioSelectedDay') === 'dayOfWeek'){
      let selectedDate = _.get(allValues,'selectedDate')
      if(_.size(selectedDate) === 0 || ( 
        (_.get(selectedDate,'mon') === undefined || !_.get(selectedDate,'mon')) && 
        (_.get(selectedDate,'tue') === undefined || !_.get(selectedDate,'tue')) && 
        (_.get(selectedDate,'wed') === undefined || !_.get(selectedDate,'wed')) && 
        (_.get(selectedDate,'thu') === undefined || !_.get(selectedDate,'thu')) && 
        (_.get(selectedDate,'fri') === undefined || !_.get(selectedDate,'fri')) && 
        (_.get(selectedDate,'sat') === undefined || !_.get(selectedDate,'sat')) && 
        (_.get(selectedDate,'sun') === undefined || !_.get(selectedDate,'sun'))
        ) ){
         return "Please Select date."
      }
   }
}

export const validateInputStartTime = (value, allValues) => {
    if(value === undefined || value === null || value === ''){
        return "Please enter Start time."
    }
    let parsedStartTime = moment(_.get(allValues,'startTime'), "HH:mm:ss");
    let parsedEndTime = moment(_.get(allValues,'endTime'), "HH:mm:ss");
    if(parsedStartTime >= parsedEndTime){
        return "Start time must be less than end time"
    }
}

export const validateInputEndTime = (value, allValues) => {
    if(value === undefined || value === null || value === ''){
        return "Please enter End time."
    }
    let parsedStartTime = moment(_.get(allValues,'startTime'), "HH:mm:ss");
    let parsedEndTime = moment(_.get(allValues,'endTime'), "HH:mm:ss");
    if(parsedStartTime >= parsedEndTime){
        return "End time must be more than start time"
    }
}

export const validateQuota = (value, allValues) => {
    let quotaPerPromotion = parseInt(_.get(allValues, 'quota[promotionPerPromotion]'));
    let quotaPerPurchase = parseInt(_.get(allValues, 'quota[promotionPerPurchase]'));
    if (!isNaN(parseInt(_.get(allValues, 'quota[promotionPerPromotion]'))) && quotaPerPurchase > quotaPerPromotion) {
        return "warning_messages.must_not_be_above_number_of_quota_per_promotion"
    }
}

export const validateIsZero = values => {
    if (parseInt(values) === 0) {
        return "warning_messages.number_greater_than_zero"
    }
}

export const validateMaxPerMonth = (value, allValues) => {
    if (_.get(allValues, 'quota[promotionPerMonth]')) {
        if (!isNaN(parseInt(_.get(allValues, 'quota[promotionPerPromotion]'))) && parseInt(_.get(allValues, 'quota[promotionPerPromotion]')) < parseInt(_.get(allValues, 'quota[promotionPerMonth]'))) {
            return "warning_messages.must_not_be_above_number_of_quota_per_promotion"
        }
    }
}

export const validateMaxPerDay = (value, allValues, props, name) => {
    let max = false
    let msg = ""
    let quotaPerPromotion = parseInt(_.get(allValues, 'quota[promotionPerPromotion]'))
    const isDay = name.replace("quota[promotionPerDayofWeek]", "")

    if (!isNaN(parseInt(_.get(allValues, 'quota[promotionPerPromotion]'))) && _.get(allValues, 'quotaPromotionType') === "quotaPerDay" && (parseInt(_.get(allValues, `quota[promotionPerDayofWeek]${isDay}`)) > quotaPerPromotion) ) {
        max = _.get(allValues, `quota[promotionPerDayofWeek]${isDay}`)
        msg = `Must not be above number of quota on per promotion on ${isDay.replace(/\[|\]/g, '')}`
    }
    return max && parseFloat(quotaPerPromotion) < parseFloat(max) ? msg : undefined
}

export const validateRequiredMonth = (value, allValues, props, name) => {
    if (value === null || value === undefined || value === "") {
       return "warning_messages.quota_is_required"
    }
 }

 export const validateRequiredDay = (value, allValues, props, name) => {
    if ((_.get(allValues, 'quota[promotionPerDayofWeek][monday]') === null || _.get(allValues, 'quota[promotionPerDayofWeek][monday]') === undefined || _.get(allValues, 'quota[promotionPerDayofWeek][monday]') === "") &&
        (_.get(allValues, 'quota[promotionPerDayofWeek][tuesday]') === null || _.get(allValues, 'quota[promotionPerDayofWeek][tuesday]') === undefined || _.get(allValues, 'quota[promotionPerDayofWeek][tuesday]') === "") &&
        (_.get(allValues, 'quota[promotionPerDayofWeek][wednesday]') === null || _.get(allValues, 'quota[promotionPerDayofWeek][wednesday]') === undefined || _.get(allValues, 'quota[promotionPerDayofWeek][wednesday]') === "") &&
        (_.get(allValues, 'quota[promotionPerDayofWeek][thursday]') === null || _.get(allValues, 'quota[promotionPerDayofWeek][thursday]') === undefined || _.get(allValues, 'quota[promotionPerDayofWeek][thursday]') === "") &&
        (_.get(allValues, 'quota[promotionPerDayofWeek][friday]') === null || _.get(allValues, 'quota[promotionPerDayofWeek][friday]') === undefined || _.get(allValues, 'quota[promotionPerDayofWeek][friday]') === "") &&
        (_.get(allValues, 'quota[promotionPerDayofWeek][saturday]') === null || _.get(allValues, 'quota[promotionPerDayofWeek][saturday]') === undefined || _.get(allValues, 'quota[promotionPerDayofWeek][saturday]') === "") &&
        (_.get(allValues, 'quota[promotionPerDayofWeek][sunday]') === null || _.get(allValues, 'quota[promotionPerDayofWeek][sunday]') === undefined || _.get(allValues, 'quota[promotionPerDayofWeek][sunday]') === ""))  {
       return "warning_messages.quota_is_required"
    }
 }
import React from 'react';
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash'
import moment from 'moment'
import { crmValidator, plugins } from 'helpers';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Badge from "components/Badge/Badge.jsx";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { whitelistConstants } from '../../constants'

const FORM_NAME = 'FORM_UPLOAD_RESULT_WHITELIST'

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "OK": BadgeColor = "success"; break;
        default: BadgeColor = "danger";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(11)": {
                    textAlign: "right"
                }
            },
            body: {
                "&:nth-child(22)": {
                    textAlign: "right"
                }
            }
        }
    }
});

class WhitelistUploadResultForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    closeForm() {
        const { dispatch } = this.props
        dispatch({ type: whitelistConstants.CLOSE_SAVE_UPLOAD_WHITELIST_RESULT })
    }

    render() {
        const { classes, formSaveImportOpen, saveImportResult } = this.props

        const columns = [
            { label: "Line.", name: "line",
                options: {
                    filter: false,
                    sort: false
                }
            },
            //{ label: "campaignId", name: "campaignId" },
            { label: "Type", name: "type" }, 
            { label: "Value", name: "value" }, 
            { label: "Database Status", name: "status",
                options: {
                    customBodyRender: (value) => {
                        return renderBadgeStatus(value)
                    }
                }
            }
        ];        

        const options = {
            filterType: 'dropdown',
            responsive: 'stacked',
            selectableRows: "none",
            print: false,
            download: false,
        };

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formSaveImportOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{ position: "relative" }}
                >
                    <h4 className={classes.modalTitle}><b>Upload Whitelist Result</b></h4>
                    <IconButton aria-label="close" id="btn_close_form_import" className={classes.closeButton} onClick={this.closeForm.bind(this)} style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px"
                    }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <h6><b>Success : {saveImportResult.success}</b></h6>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <h6><b>Fail : {saveImportResult.fail}</b></h6>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <MuiThemeProvider theme={THEME}>
                                <MUIDataTable
                                data={saveImportResult.detail}
                                columns={columns}
                                options={options}
                                />
                            </MuiThemeProvider>
                        </GridItem>
                    </GridContainer>
                </DialogContent >
            </Dialog >
        )
    }
}


function mapStateToProps(state) {
    const { formSaveImportOpen, saveImportResult } = state.whitelist
    //const { FORM_IMPORT_FILE } = state.form
    return {
        formSaveImportOpen, saveImportResult
        //, FORM_IMPORT_FILE
    };
}

//const connectedComponent = connect(mapStateToProps)(WhitelistUploadResultForm);
export default connect(mapStateToProps)(withStyles(pageStyle)(WhitelistUploadResultForm));
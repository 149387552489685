import { configConstants } from '../constants';
import axios from 'axios';
import _ from 'lodash';
import { history } from 'helpers';

export const luckyDrawReportServices = {
   getCollectTransactionList,
   getCampaignsList,
   getListsProduct,
   getLuckyDrawDowloadList,
   getFileDownload,
   downloadCollectTransaction,
   downloadBurnTransaction,
   getCollectTransactionById,
   downloadMemberReport,
   downloadPromotionTransaction
};

function getCollectTransactionList(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/collect-luckydraw`, params, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getCampaignsList(params) {
   let req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns`, {
      headers: req_headers
   })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return {
               status: true,
               data: response.data.data,
               message: response.data.message
            };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return {
               status: false,
               data: {},
               message: response.data.message
            };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getListsProduct(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/products?${params}`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getLuckyDrawDowloadList(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }

   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/transaction-luckydraw?${params}`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: e.message };
      });
   return tmp
}

function getFileDownload(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }

   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/download/luckydraw-transaction/` + params, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, link: response.data.message.url[0], message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp
}

function downloadCollectTransaction(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/collect-luckydraw/export`, params, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            const urlDownload = _.get(response.data, "data.url")
            if(urlDownload){
               window.location.assign(urlDownload)
            }
            return { status: true, data: {}, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function downloadBurnTransaction(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/transaction-burn/export`, params, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            const urlDownload = _.get(response.data, "data.url")
            if(urlDownload){
               window.location.assign(urlDownload)
            }
            return { status: true, data: {}, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getCollectTransactionById(_id){
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/transaction-collect/${_id}`, { headers: req_headers })
   .then(response => {
      if (response.data.statusCode.toString() === "200") {
         return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
         history.push('/auth/login')
         return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
         return { status: false, data: {}, message: response.data.message };
      }
   }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
   });
   return tmp;
}

function downloadMemberReport(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/users/export?${params}`, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            const urlDownload = _.get(response.data, "data.url")
            if(urlDownload){
               window.location.assign(urlDownload)
            }
            return { status: true, data: {}, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}


function downloadPromotionTransaction(params) {
   var req_headers = {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      'Authorization': localStorage.getItem('token')
   }
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/promotion/export`,params, { headers: req_headers })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            const urlDownload = _.get(response.data, "data.url")
            if(urlDownload){
               window.location.assign(urlDownload)
            }
            return { status: true, data: {}, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}



import _ from 'lodash'
export const form_validate = {
   "missionName": {
       rules: {
           required: true,
           maxLength: 50,
       },
       messages: {
           required: 'Mission Name is required',
           maxLength: 'Mission Name is not over 50 charactors',
       },
       alert: "Mission Name is required"
   },
   "status": {
       rules: {
           required: true
       },
       messages: {
           required: 'Status is required'
       },
       alert: "Status is required"
   },
   "startDate" : {
       rules: {
           required: true,
       },
       messages: {
           required: 'Start Date is required',
       },
       alert: 'Start Date is required'
   },
   "endDate" : {
       rules: {
           sameDateTime : true,
           requireStartDate : true,
           required: true,
       },
       messages: {
           requireStartDate : "Please select start date",
           sameDateTime: 'Can not select the same value as Start Date',
           required: 'End Date is required'
       },
       alert: 'End Date is required'
   },
    "missionType": {
        rules: {
            required: true
        },
        messages: {
            required: 'Mission Type is required'
        },
        alert: "Mission Type is required"
    },
    "loginCondition": {
        rules: {
            required: true
        },
        messages: {
            required: 'Login Condition is required'
        },
        alert: "Login Condition is required"
    },
    "achieveList": {
        alert: "Please enter reward"
    }
};

export const validateIsZero = (values, allValues) => {
    if (values === "0") {
        return "Please more than 0"
    }
    if (values === undefined || values === '') {
        return "Please enter number"
    }
}

export const validateDateSelect = values => {
    if (values === undefined) {
        return "Please Select"
    }
}

export const validateSelectedCampaign = values => {
    if (values === undefined) {
        return "Please Select campaign"
    }
}

export const validatePrivilegeTypes = (values) => {
    if (_.size(values) === 0) {
        return "Mission Privilege is required"
    }
}
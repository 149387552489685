import moment from 'moment';
import { luckyDrawReportConstants } from "../constants";

const initialState = {
  collectReport: {
    tableKey: Date.now(),
    tableDatas: {
      data: [],
      total: 0
    },
    tableTotal: 0,
    purchaseDetail:[],
    modalOpen: false,
    tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "created_at",
      sortDir: "desc",
      keyword: "",
      search: {
        startDate: moment().startOf('day'), 
        endDate: moment().set('hour',23).set('minute',59).set('second',59)
      }
    },
    productList: [],
    campaignList: [],
    earnDetail: {
      open: false,
    },
    earnDetailImages: {
      open: false,
    }
  },
  luckyDraw: {
    tableKey: Date.now(),
    tableData: [],
    tableTotal: 0,
    tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "transactionDate",
      sortDir: "desc",
      keyword: "",
    },
    productList: [],
    luckyDrawRewardSet: [],
  }
};

export function luckyDrawReport(state = initialState, action) {
  switch (action.type) {
    case 'REPORT_REDUCER_INIT':
      return initialState
    case 'CLEAR_COLLECT_FILTER':
      return {
        ...state,
        collectReport: {
          ...state.collectReport,
          tableFilter: initialState.collectReport.tableFilter
        }
      }
    case 'CLEAR_LUCKYDRAW_FILTER':
      return {
        ...state,
        luckyDraw: {
          ...state.luckyDraw,
          tableFilter: initialState.luckyDraw.tableFilter
        }
      }
    case luckyDrawReportConstants.GET_MEMBER_REPORT_SUCCESS:
      return {
        ...state,
        insight: {
          ...state.insight,
          ageRangeFilterOption: action.ageFilter,
          ageRangeMode: action.ageRangeMode,
          tableTotal: action.tableTotal,
          setting: action.setting
        }
      };
    case luckyDrawReportConstants.GET_DROPDOWN_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        collectReport: {
          ...state.collectReport,
          productList: action.data
        }
      };
    case luckyDrawReportConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        collectReport: {
          ...state.collectReport,
          campaignList: action.data
        }
      };
    case luckyDrawReportConstants.GET_COLLECT_TRANSACTON_SUCCESS:
      return {
        ...state,
        collectReport: {
          ...state.collectReport,
          modalOpen:false,
          tableFilter: action.tableFilter,
          tableData: action.data ? action.data : [],
          tableTotal: action.total ? action.total : 0
        }
      };
    case luckyDrawReportConstants.COLLECT_TRANSACTON_TABLE_RESET:
      return {
        ...state,
        collectReport: {
          ...state.collectReport,
          tableKey: Date.now()
        }
      };
    case luckyDrawReportConstants.LUCKYDRAW_DOWLOAD_RESET:
      return {
        ...state,
        luckyDraw: {
          ...state.luckyDraw,
          tableKey: Date.now()
        }
      };
    case luckyDrawReportConstants.GET_LUCKYDRAW_DOWLOAD_SUCCESS:
      return {
        ...state,
        luckyDraw: {
          ...state.luckyDraw,
          tableFilter: action.tableFilter,
          tableData: action.data ? action.data : [],
          tableTotal: action.total ? action.total : 0
        }
      };
    case luckyDrawReportConstants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS:
      return {
        ...state,
        luckyDrawRewardSet: action.data.rewards
      }
    default:
      return state;
  }

}

import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import objectToFormData from 'object-to-formdata';

export const missionServices = {
    getListsMission,
    addMission,
    updateMission,
    deleteMission,
    getMissionDetail,
    getCampaignsList,
}
function getListsMission(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/mission?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function addMission(values) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + '/mission', values, { headers: setHeaders({ 'Content-Type': 'application/json' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.data ? response.data.message.data :  response.data.message };
            }
        });
    return tmp;
}

function updateMission(values) {
    var missionId = values._id;
    var tmp = axios.put(configConstants.API_BACKEND_CRM + `/mission/${missionId}`, values, { headers: setHeaders({ 'Content-Type': 'application/json' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.data ? response.data.data :  response.data.message };
            }
        });
    return tmp;
}

function deleteMission(params) {
    var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/mission/${params._id}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function getMissionDetail(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/mission/${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getCampaignsList(params) {
    let req_headers = {
       'Content-Type': 'application/json',
       'Charset': 'utf-8',
       'Authorization': localStorage.getItem('token')
    }
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns`, {
       headers: req_headers
    })
       .then(response => {
          if (response.data.statusCode.toString() === "200") {
             return {
                status: true,
                data: response.data.data,
                message: response.data.message
             };
          } else if (response.data.message === "UNAUTHORIZE") {
             history.push('/auth/login')
             return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
             return {
                status: false,
                data: {},
                message: response.data.message
             };
          }
       })
       .catch(e => {
          return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
       });
 
    return tmp;
 }
import React from "react";
import { Field } from 'redux-form';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import i18n from 'i18n';

import * as FormValidator from '../validate/FormValidator';

function Form3rdPartyCode({ ...props }) {
   const { formMode } = props.props
   const { selTrdPartyValue } = props

   let { readOnly } = props.props

   if (formMode === `add`) {
      readOnly = false
   } else if (formMode === `edit` && !readOnly) {
      readOnly = false
   } else {
      readOnly = true
   }

      return (
         <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
               <Field
                  name="trdPartyCodeSetId"
                  component={CustomSelect}
                  type="text"
                  labelText={i18n.t("label.3rd_party_reward_code")}
                  formControlProps={{
                     fullWidth: true
                  }}
                  optionsList={selTrdPartyValue}
                  validate={[FormValidator.validateRequired]}
                  disabled={readOnly || formMode === "edit"}
               />
            </GridItem>
         </GridContainer>
      );
}

export default Form3rdPartyCode;

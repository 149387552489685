import React from "react";
import { connect } from "react-redux";
import lodash, { result } from "lodash";
import MUIDataTable from "mui-datatables";
import { plugins, crmValidator, loader, alert as customAlert } from "helpers";
import { luckyDrawReportActions } from "actions";
import { luckyDrawReportServices } from "services";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

const style = {};
const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(3)": {
                    paddingRight: "100px",
                },
                "&:nth-child(6)": {
                    paddingRight: "220px",
                }
            },
            body: {
                
            }
        }
    }
});
class TableCollectReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "transactionDate",
                sortDir: "desc",
                keyword: "",
            },
            csvData: [],
        };
        this.csvLink = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 800);
    }

    exportCSV() {
        const { collectReport, dispatch } = this.props;
        let { tableFilter, tableTotal } = collectReport;
        tableFilter.skip = 0;
        tableFilter.limit = tableTotal ? tableTotal : 100000;
        if (tableTotal > 30000) {
            customAlert(
                dispatch,
                "error",
                "Can not export data more than 30,000 rows"
            );
        } else {
            loader(dispatch, true);
            luckyDrawReportServices
                .downloadCollectTransaction(tableFilter)
                .then((response) => {
                    loader(dispatch, false);
                })
                .catch((e) => {
                    this.setState({ csvData: [] });
                });
        }
    }

    getData(skip, limit, sortBy, sortDir, keyword) {
        const { dispatch, tableReload, tableTotal, collectReport } = this.props;
        let { tableFilter } = collectReport;

        let newFilter = tableFilter;

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                newFilter.skip = 0;
            }

            if (crmValidator.isEmptyValue(limit)) {
                newFilter.limit = 10;
            }
            newFilter.page = skip;
            this.setState({
                page: skip,
                rowsPerPage: limit,
            });
            skip = parseInt(limit) * parseInt(skip);
            if(tableTotal !== 0 && tableTotal === skip){
                skip = skip - limit
                this.setState({
                    page: this.state.page - 1,
                })                
            }
            if(skip >= tableTotal){
                skip = 0;
                this.setState({
                    page: 0,
                })
            }
            this.setState({
                newFilter: {
                    skip: skip,
                    limit: limit,
                 },
            })
            newFilter.skip = skip;
            newFilter.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                newFilter.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                newFilter.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                newFilter.keyword = keyword;
            } else {
                newFilter.keyword = "";
            }
        } else {
            newFilter = this.state.newFilter;
        }

        dispatch(luckyDrawReportActions.getCollectTransactionList(newFilter));
        this.setState({
            isLoading: true,
            filter: newFilter,
        });
    }

    getRowData(rowIndex = 0) {
        const { collectReport } = this.props;
        const { tableData } = collectReport;
        const data = tableData;
        return data[rowIndex];
    }

    render() {
        const { rowsPerPage } = this.state;
        const { collectReport } = this.props;
        const { tableData, tableTotal, tableFilter } = collectReport;

        const columns = [
            {
                name: "created_at",
                label: "Create Date/Time",
                options: {
                    customBodyRender: (value) =>
                        value ? plugins.datetimeFormat(value, "fulldatetime") : "",
                },
            },
            { name: "_id", label: "Transaction ID" },
            { name: "user", label: "Name" ,
                options: {
                    customBodyRender: (value) =>
                        lodash.isUndefined(value) ? "" : lodash.get(value, 'firstName')+" "+lodash.get(value, 'lastName')
                    }
            },
            {
                name: "user", label: "Mobile No.",
                options: {
                    customBodyRender: (value) =>
                        lodash.isUndefined(value) ? "" : lodash.get(value, 'phoneNumber')
                    }
            },
            { name: "channel", label: "Collect Type" },
            {
                name: "rewardDetail", label: "Reward",
                options: {
                    customBodyRender: (value) =>
                        lodash.isUndefined(value) ? "" : lodash.get(value, 'setName')
                    }
            },
            {   name: "campaign", label: "Campaign",
                options: {
                    customBodyRender: (value) =>
                        lodash.isUndefined(value) ? "" : lodash.get(value, 'name')
                    } 
            },
            {   name: "product", label: "Product",
                options: {
                    customBodyRender: (value) =>
                        lodash.isUndefined(value) ? "" : lodash.get(value, 'name')
                    }  
            },
            { name: "code", label: "Code" },
            { name: "luckyChance", label: "Chance"},
            { name: "remark", label: "Remark" }
        ];
        const options = {
            serverSide: true,
            count: tableTotal ? tableTotal : 0,
            page: tableFilter.page,
            rowsPerPage: rowsPerPage,
            fixedHeader: true,
            selectableRows: "none",
            print: false,
            customToolbar: () => {
                return (
                    <>
                        {plugins.isHasPermission("report[collect_luckydraw]", "export") && plugins.isHasPackageFeatures('export') &&(
                            <Tooltip title='Export CSV'>
                                <IconButton
                                    id='button-collectTable-exportCsv'
                                    className=''
                                    onClick={this.exportCSV.bind(this)}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [1, 5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip,
                    limit,
                    sortBy,
                    sortDir,
                    keyword = "";

                if (tableState.page !== null) {
                    skip = tableState.page;
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage;
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name;
                }
                if (tableState.announceText !== null) {
                    sortDir =
                        tableState.announceText.search("descending") !== -1
                            ? "desc"
                            : "asc";
                }
                if (tableState.searchText !== null || action === "search") {
                    keyword = tableState.searchText;
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword);
                }
                let actionList = ["changePage", "sort", "changeRowsPerPage"];
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword);
                }
            },
        };
        return (
            <div id='tableCollectReport'>
                <CustomSweetAlert />
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={collectReport.tableKey}
                        data={tableData ? tableData : []}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { visiblePersonalData, merchantSetting } = state.authentication;
    const { collectReport } = state.luckyDrawReport;
    return {
        visiblePersonalData,
        merchantSetting,
        collectReport
    };
}

export default connect(mapStateToProps)(withStyles(style)(TableCollectReport));

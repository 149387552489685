import React from "react";
import PropTypes from "prop-types";

import FormHelperText from "@material-ui/core/FormHelperText";

import defaultImage from "assets/img/image_placeholder.jpg";

import Button from "components/CustomButtons/Button.jsx";
import i18n from 'i18n';

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.refs.fileInput.click();
    }

    componentDidUpdate() {
        const { alwayClearImage = false } = this.props
        if (((this.props.imagePreviewUrl === defaultImage) && this.refs.fileInput.value !== null) || alwayClearImage) {
            this.refs.fileInput.value = null;
        }
    }

    render() {
        let {
            avatar,
            addButtonProps,
            labelText,
            buttonText = "Select image",
            imagePreviewUrl = '',
            input,
            disabled,
            textCenter,
            imgStype,
            meta: { touched, error },
            height = (400 / 2.5),
            id
        } = this.props;

        const divStyle = "fileinput " + (textCenter === true ? "text-center" : "")

        const labelClass = {
            fontSize: "14px",
            marginTop: "10px",
            marginBottom: "10px",
            color: "#AAA",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            opacity: "1",
            lineHeight: "1.42857"
        }
        const labelClassError = {
            fontSize: "14px",
            marginTop: "10px",
            marginBottom: "10px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            opacity: "1",
            lineHeight: "1.42857"
        }

        if(imagePreviewUrl.includes('default')){
            imagePreviewUrl = imagePreviewUrl + "?v=" + Date.now()
        }

        return (
            <>
                {(touched && error) ?
                    <div style={labelClassError} className="labelRootError">{labelText}</div> :
                    <div style={labelClass}>{labelText}</div>
                }

                <div className={divStyle}>
                    <input type="file" onChange={input.onChange} name={input.name} ref="fileInput" />
                    <div className={"thumbnail" + (avatar ? " img-circle" : "")} style={{ width: "100%", height: height + "px" }}>
                        <img
                            style={imgStype ? imgStype : null}
                            src={imagePreviewUrl ? imagePreviewUrl : defaultImage}
                            onError={(e) => { e.target.onError = null; e.target.src = defaultImage }}
                            alt="..." />
                    </div>
                    <div>
                        {disabled === false ?
                            <Button {...addButtonProps} id={id} onClick={() => this.handleClick()} size="sm">
                                {buttonText}
                            </Button> : null}
                        {touched && error ?
                            <FormHelperText id={input.id + "-text"} className="labelRootError">
                                {i18n.t(error)}
                            </FormHelperText>
                            : null}
                    </div>
                </div>
            </>
        );
    }
}

ImageUpload.propTypes = {
    avatar: PropTypes.bool,
    addButtonProps: PropTypes.object
};

export default ImageUpload;

import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import MUIDataTable from "mui-datatables";
import { plugins, crmValidator } from "helpers";
import { reportActions } from "actions";
import { reportServices } from "services";
// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import CloudDownload from "@material-ui/icons/CloudDownload";
import Button from 'components/CustomButtons/Button'

import "react-notifications/lib/notifications.css";
import moment from "moment";

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },

  actionDiv: {
    display: "inline-flex"
  }
};

const THEME = createMuiTheme({
  ...MTablestyles,
  overrides: {
    ...MTablestyles.overrides,
    // MUIDataTable: {
    //   responsiveScroll: {
    //     minHeight: "490px"
    //   }
    // },
    MUIDataTableHeadCell: {
      fixedHeader: {
        "&:nth-child(1)": {
          width: "20%"
        },
        "&:nth-child(2)": {
          width: "40%"
        },
        "&:nth-child(3)": {
          width: "30%"
        },
      }
    },
    // MUIDataTableResponsiveScroll: {
    //   position: { 'relative;min-height': '300px;' }
    // }
  }
});


class TableLuckyDraw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      filter: {
        skip: 0,
        limit: 10,
        sortBy: "created_at",
        sortDir: "desc",
        keyword: ""
      },
      csvData: []
    };
    this.csvLink = React.createRef();
    this.delayedCallback = lodash.debounce(this.getData, 500);
  }


  getData(skip, limit, sortBy, sortDir, keyword) {
    const { dispatch, tableReload, luckyDraw, tableTotal } = this.props;
    let { tableFilter } = luckyDraw;
    let newFilter = tableFilter;

    if (!tableReload) {
      if (crmValidator.isEmptyValue(skip)) {
        newFilter.skip = 0;
      }

      if (crmValidator.isEmptyValue(limit)) {
        newFilter.limit = 10;
      }
      newFilter.page = skip;
      this.setState({
        page: skip,
        rowsPerPage: limit
      });
      skip = parseInt(limit) * parseInt(skip);
      if(tableTotal !== 0 && tableTotal === skip){
        skip = skip - limit
        this.setState({
            page: this.state.page - 1,
        })                
      }
      if(skip >= tableTotal){
          skip = 0;
          this.setState({
              page: 0,
          })
      }
      this.setState({
          newFilter: {
              skip: skip,
              limit: limit,
          },
      })
      newFilter.skip = skip;
      newFilter.limit = limit;

      if (!crmValidator.isEmptyValue(sortBy)) {
        newFilter.sortBy = sortBy;
      }

      if (!crmValidator.isEmptyValue(sortDir)) {
        newFilter.sortDir = sortDir;
      }

      if (!crmValidator.isEmptyValue(keyword)) {
        newFilter.keyword = keyword;
      } else {
        newFilter.keyword = "";
      }
    } else {
      newFilter = this.state.newFilter;
    }

    dispatch(reportActions.getLuckyDrawDowloadList(newFilter));

    this.setState({
      isLoading: true,
      filter: newFilter
    });
  }

  getRowData(rowIndex = 0) {
    const { luckyDraw } = this.props;
    const { tableData } = luckyDraw;
    const data = tableData;
    return data[rowIndex];
  }

  getDownloadFile(id) {
    reportServices.getFileDownload(id)
      .then(
        tmp => {
          if (lodash.get(tmp, 'status') === true) {
            window.location.assign(tmp.link)
          }
        }
      )

  }

  getRewardNameById(id){
    const { luckyDrawRewardSet } = this.props;
    let rewardLuckyObj = lodash.find(luckyDrawRewardSet, {_id: id})
      let rewardName = lodash.get(rewardLuckyObj, 'name_th')? lodash.get(rewardLuckyObj, 'name_th'):lodash.get(rewardLuckyObj, 'name_en');
    return rewardName ? rewardName : ''
  }

  render() {
    const { rowsPerPage } = this.state;
    const { luckyDraw, visiblePersonalData } = this.props;
    const { tableData, tableTotal, tableFilter } = luckyDraw;
    const columns = [
      {
        name: "transactionDate",
        label: "Date/Time",
        options: {
          customBodyRender: value => {
            return moment(value).format('DD-MMM-YYYY')
          }
        }
      },
      { name: "rewardName", label: "Reward",
        // options: {
        //   customBodyRender: value => {
        //     return this.getRewardNameById(value)
        //   }
        // } 
      },
      { name: "transactionCount", label: "Transactions" },
      {
        name: "_id",
        label: "Files",
        options: {
          display: (plugins.isHasPermission("report[lucky_draw]", 'export') && plugins.isHasPackageFeatures('export') && visiblePersonalData),
          filter: false,
          sort: false,
          customBodyRender: value => {
            return (
              <>
              <Button
                variant="contained"
                bcolor="primary"
                size="sm"
                onClick={(e) => {
                  this.getDownloadFile(value)
                }}
                style={{backgroundColor: "#84B0F3" }}
              >
                Download &nbsp;&nbsp;&nbsp;<CloudDownload />
                 
              </Button>
              </>
            );
          }
        }
      }
    ];
    
    const options = {
      serverSide: true,
      count: tableTotal ? tableTotal : 0,
      page: tableFilter.page,
      search: false,
      viewColumns: false,
      rowsPerPage: rowsPerPage,
      fixedHeader: true,
      selectableRows: "none",
      print: false,
      download: false,
      filter: false,
      responsive: "scroll",
      rowsPerPageOptions: [1, 5, 10, 20],
      onTableChange: (action, tableState) => {
        let skip,
          limit,
          sortBy,
          sortDir,
          keyword = "";
        if (tableState.page !== null) {
          skip = tableState.page;
        }

        if (tableState.rowsPerPage !== null) {
          limit = tableState.rowsPerPage;
        }

        if (tableState.activeColumn !== null) {
          sortBy = tableState.columns[tableState.activeColumn].name;
        }
        if (tableState.announceText !== null) {
          sortDir =
            tableState.announceText.search("descending") !== -1
              ? "desc"
              : "asc";
        }
        if (tableState.searchText !== null || action === "search") {
          keyword = tableState.searchText;
          this.delayedCallback(skip, limit, sortBy, sortDir, keyword);
        }
        let actionList = ["changePage", "sort", "changeRowsPerPage"];
        if (actionList.indexOf(action) !== -1) {
          this.getData(skip, limit, sortBy, sortDir, keyword);
        }
      }
    };
    return (
      <>
        <div id="tableLuckyDraw" style={{minHeight: '320px'}}>
          <MuiThemeProvider theme={THEME}>
            <MUIDataTable
              key={luckyDraw.tableKey}
              data={tableData ? tableData : [] }
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>

        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { visiblePersonalData } = state.authentication;
  const { luckyDraw, luckyDrawRewardSet } = state.luckyDrawReport;
  return {
    visiblePersonalData,
    luckyDraw,
    luckyDrawRewardSet,
  };
}

export default connect(mapStateToProps)(withStyles(style)(TableLuckyDraw));

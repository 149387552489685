import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import lodash from 'lodash'
import moment from 'moment'

export const merchantServices = {
    getListsMerchant,
    apiAddMerchant,
    apiUpdateMerchant,
    apiDeleteMerchant,
    getMerchantById,
    addMerchantBudget,
    getMerchantBudget,
    createSubsciption
};

function getListsMerchant(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/merchants?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function apiAddMerchant(values) {
    let formData = {
        nameEN: values.merchantNameEN,
        nameTH: values.merchantNameTH,
        contactName: values.contactPerson,
        contactNumber: values.contactTel,
        contactEmail: values.contactEmail,
        contractStartDate: values.dateFrom,
        contractEndDate: values.dateTo,
        projectCode: values.merchantCode,
        industry: values.industry,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
        frontEndUrl: values.frontEndUrl,
        couponUrl: lodash.get(values, 'couponUrl'),
        campaignUrl: lodash.get(values, 'campaignUrl'),
        winnerUrl: lodash.get(values, 'winnerUrl'),
        SMSSenderName: values.SMSSenderName,
        SMSMessageTemplate: values.SMSMessageTemplate,
        merchantFrontEndUrl:values.merchantFrontEndUrl,
    };

    var tmp = axios.post(configConstants.API_BACKEND_CRM + '/merchants', formData, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });
    return tmp;
}

function apiUpdateMerchant(values) {
    const merchantId = values.merchantID;

    const tmp = axios.put(configConstants.API_BACKEND_CRM + `/merchants/${merchantId}`, values, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });
    return tmp;
}

function apiDeleteMerchant(params) {
    var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/merchants/${params._id}/force`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}


function getMerchantById(merchantId) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/merchants/${merchantId}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function addMerchantBudget(merchantId, values) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + `/merchants/${merchantId}/add-budget`, values, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getMerchantBudget(merchantId, params) {
    params = params ? `?${params}` : ""

    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/merchants/${merchantId}/budget${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function createSubsciption(merchantId, merchantName) {
    let reqBody = {
        "package_code": "EGGCRM_FULL",
        "package_price_code": "EGGCRM_FULL",
        "package_price": 0,
        "billing_cycle_type": "unlimit",
        "reference_code": merchantId,
        "reference_desc": merchantName,
        "subscription_date": moment().format('YYYY-MM-DD HH:mm:ss'),
        "expire_date": moment().add(10, 'years').format('YYYY-MM-DD HH:mm:ss'),
        "status": "active"
    }

    var tmp = axios.post(configConstants.API_BACKEND_CRM + `/package-subscriptions`, reqBody, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}
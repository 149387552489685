import React from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import Badge from "components/Badge/Badge.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import { roleConstants, swalConstants } from '../../constants';
import { roleActions } from 'actions';
import { plugins, crmValidator } from 'helpers';

import RoleForm from "./RoleForm.jsx";

import i18n from 'i18n';

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const moduleName = 'role'

const pageStyle = { ...style, ...sweetAlertStyle };

const CustomMTablestyles = {
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MUIDataTableHeadCell: {
            fixedHeader: {
                "&:nth-child(1)": {
                    width: "30px"
                },
                "&:nth-child(2)": {
                    width: "80%"
                },
            }
        }
    }
}

const THEME = createMuiTheme(CustomMTablestyles);

const renderBadgeStatus = (status) => {
    let BadgeColor
    let message = ""
    switch (status) {
        case true:
            BadgeColor = "success";
            message = "Yes";
            break;
        case false:
            BadgeColor = "danger";
            message = "No";
            break;
        default:
            BadgeColor = "danger";
            message = "No";
    }

    return (<Badge color={BadgeColor}>{message}</Badge>)
}

class TableRoleList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        };

        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(roleActions.getTableDatas(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.roles;
        return data[rowIndex];
    }

    componentWillMount() {
        const { dispatch } = this.props;

        dispatch({ type: roleConstants.CLEAR_PAGE_STATE });
        dispatch(roleActions.getListsFeatures());
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: roleConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    componentDidMount() {
        this.getData();
    }

    confirmDeleteRole(RoleData) {
        const { dispatch } = this.props;
        var reqParams = {
            _id: RoleData._id
        }
        dispatch(roleActions.deleteRole(reqParams));
    }

    openFormAdd = () => {
        const { dispatch } = this.props;
        dispatch({ type: roleConstants.OPEN_FORM_ADD, formInitialValue: {} })
    }

    openFormEdit(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.roles[index]

        dispatch(roleActions.getRoleByID(rowData._id));
        // dispatch({ type: roleConstants.OPEN_FORM_EDIT, formInitialValue: rowData })
    }

    render() {

        const { classes, dispatch, formInitialValue, tableDatas, tableKey, tableLoader } = this.props;
        const { rowsPerPage, page } = this.state;

        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "displayId", options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            { name: "role", label: "Name" },
            {
                name: "visiblePersonalData",
                label: "Display personal data",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex);
                        return renderBadgeStatus(rowData.displayStatus)
                    }
                }
            },
        ];

        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classes.actionDiv}>
                                {plugins.isHasPermission(moduleName, 'update') &&
                                    <TableActionButton
                                        actionType="edit"
                                        onClick={(e) => {
                                            this.openFormEdit(tableMeta.rowIndex)
                                        }}
                                    />
                                }
                                {plugins.isHasPermission(moduleName, 'delete') &&
                                    <TableActionButton
                                        actionType="delete"
                                        onClick={(e) => {
                                            dispatch({
                                                type: swalConstants.SHOW, data: {
                                                    type: "warning",
                                                    title: i18n.t("warning_messages.are_you_sure_delete_role"),
                                                    message: `${this.getRowData(tableMeta.rowIndex).role}`,
                                                    onConfirm: function () {
                                                        let reqParams = {
                                                            _id: tableMeta.rowData[0]
                                                        }
                                                        dispatch({ type: swalConstants.HIDE })
                                                        dispatch(roleActions.deleteRole(reqParams));
                                                    },
                                                    onCancel: function () {
                                                        dispatch({ type: swalConstants.HIDE })
                                                    },
                                                    showCancel: true,
                                                    confirmBtnText: i18n.t("delete"),
                                                    cancelBtnText: i18n.t("cancel")
                                                }
                                            })
                                        }} />
                                }
                            </div>
                        )
                    }
                }
            })
        }

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        if (plugins.isHasPermission(moduleName, 'create')) {
            options.customToolbar = () => {
                return (
                    <CustomToolbar handleClick={this.openFormAdd} title="Add New" id="btn_add_new" />
                );
            }
        }

        return (
            <div id="tableRoleList">
                <CustomSweetAlert />
                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        title={"Role list"}
                        data={tableDatas.roles}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner />}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader } = state.role;
    // const { permission, superAdmin } = state.authentication;
    return {
        formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableRoleList));
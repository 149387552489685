import { tagServices } from '../services';
import { tagConstants } from '../constants';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const tagAction = {
   getTagList,
   addNewTag,
   editTag,
   deleteTag,
   importMember
};

function getTagList(params) {
   return dispatch => {
      dispatch({ type: tagConstants.SHOW_LOADER })
      const defaultData = {
         data: [],
         total: 0
      }
      tagServices.getTagList(params)
         .then(
            response => {
               try {
                  if (response.status) {
                     dispatch(success(response.data));
                  } else {
                     dispatch(success(defaultData));
                  }
               } catch (e) {
                  dispatch(success(defaultData));
               }
            },
            error => {
               dispatch(success(defaultData));
            }
         ).then(() => {
            dispatch({ type: tagConstants.HIDE_LOADER })
         })
   };

   function success(tmp) { return { type: tagConstants.GET_TAG_SUCCESS, tmp: tmp } }
}

function addNewTag(params) {
   return dispatch => {
      loader(dispatch, true)
      tagServices.addNewTag(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({
                     type: tagConstants.ADD_NEW_TAG_SUCCESS
                  });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.tag_was_created'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function editTag(params) {
   return dispatch => {
      loader(dispatch, true)
      tagServices.editTag(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.tag_was_updated'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: tagConstants.EDIT_TAG_SUCCESS, tmp } }
}

function deleteTag(params) {
   return dispatch => {
      loader(dispatch, true)
      tagServices.deleteTag(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({
                     type: tagConstants.DELETE_TAG_SUCCESS
                  });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.tag_was_deleted"))
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function importMember(params) {
   return dispatch => {
      loader(dispatch, true)
      tagServices.importMember(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({
                     type: tagConstants.SHOW_TAG_IMPORT_MEMBER_RESULT,
                     data: response.data
                  });
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   }
}
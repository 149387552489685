import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";
import { Field,reduxForm , reset } from 'redux-form';
import i18n from 'i18n';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";

import ProductForm from "views/Product/ProductForm.jsx";

import { productConstants, swalConstants } from '../../constants';
import { productActions } from 'actions';
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { listActions } from 'actions';

import { productServices } from "services";

import { plugins, crmValidator , loader, alert as customAlert } from "helpers";

const style = {
    actionDiv: {
        display: "inline-block"
    },
    upperCase: {
        textTransfrom: 'uppercase'
    }
};

const FORM_NAME = 'PRODUCT_FILTER_FORM'

const moduleName = 'product'

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "Active": BadgeColor = "success"; break;
        case "Expired": BadgeColor = "danger"; break;
        default: BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}
class TableProductList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        };

        this.delayedCallback = lodash.debounce(this.getData, 500)

    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(productActions.getListsProduct(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: productConstants.CLEAR_PAGE_STATE });
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(listActions.getCategoryList('product'))
        this.getData();
    }

    exportCSV() {
        const { dispatch } = this.props;
        let tableFilter = {
           page:0,
           rowsPerPage:0,
           skip:0,
           limit:10,
           sortBy:"created_at",
           sortDir:"desc",
        }
  
        let reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');
  
        loader(dispatch, true)
        productServices.downloadProductReport(reqParams)
        .then(response => {
           loader(dispatch, false)
        })
        .catch(e => {
           customAlert(dispatch, 'error', "api_messages.SOMETHING_WENT_WRONG")
           loader(dispatch, false)
        });
    }

    openAddProduct = () => {
        const { dispatch } = this.props;
        let initialValues = {
            startDate: "",
            endDate: "",
            productImage: undefined ,
            onEarnrule : false 
        }
        dispatch({ type: productConstants.OPEN_ADD_PRODUCT, data: initialValues });
    }

    openEditProduct(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.products[index]
        dispatch(productActions.getProductById(rowData._id))
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: productConstants.TABLE_RELOAD_SUCCESS })
        }
    }
    categoryIdConvertToName(_id){
        const { categoryList } = this.props
        let categoryObj = lodash.find(categoryList,{"_id":_id})
        let categoryName = lodash.get(categoryObj,'name',_id)
        return categoryObj ? categoryName : ""
   }

     handleCategorySubmit(values) {
        const { reqParams} = this.state
        const { dispatch } = this.props
        const { category } = values
        this.setState({
           categorySelected:category
        })
  
        let _reqParams = reqParams.split('&category[]=')['0']
        if(!crmValidator.isEmptyValue(category)){
           for(let item of category){
              _reqParams = `${_reqParams}&category[]=${item}`
           }
        }
        dispatch(productActions.getListsProduct(_reqParams));
      }
      clearFilter = () =>{
        const { dispatch } = this.props
        dispatch(reset(FORM_NAME))
        this.setState({
           categorySelected:[]
        })
      }
  

    render() {
        const { classes, dispatch, formInitialValue, tableDatas, tableKey, tableLoader,categoryList,handleSubmit } = this.props;
        const { rowsPerPage, page } = this.state;

        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "displayId", options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            {
                name: "productId", label: "Product ID", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return tableMeta.rowData[1]
                    }
                }
            },
            {
                name: "name", label: "Product Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<div style={{ minWidth: "130px" }}>{value}</div>)
                    }
                }
            },
            {
                name: "skuId", label: "SKU ID", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<div>{value}</div>)
                    }
                }
            },
            {
                name: "categoryId", label: "Category", options: {
                   filter: false,
                   customBodyRender: (value, tableMeta, updateValue) => {
                      const renderCategory = (category,index)=>{
                         let categoryName = this.categoryIdConvertToName(category)
                         return ( <div key={index} className="text-nowrap">{categoryName}</div>)
                      }
                      const isArrayValue = lodash.isArray(value)
                      return (
                            <>
                            { isArrayValue && (<div>{value.map(renderCategory)}</div>) }
                            {!isArrayValue && <span>{value}</span>}
                            </>
                      )
                   } 
                }
             },
            {
                name: "prefix", label: "Prefix", options: {
                    customBodyRender: (value) => {
                        return (<span className={classes.upperCase}>{value}</span>)
                    }
                }
            },
            {
                name: "status", label: "Status", options: {
                    // filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return renderBadgeStatus(value)
                    }
                }
            },

        ];

        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classes.actionDiv}>
                                {
                                    plugins.isHasPermission(moduleName, 'update') && 
                                    <TableActionButton
                                        actionType="edit"
                                        onClick={(e) => {
                                            this.openEditProduct(tableMeta.rowIndex)
                                        }} 
                                    />
                                }
                                {plugins.isHasPermission(moduleName, 'delete') &&

                                    <TableActionButton
                                        actionType="delete"
                                        onClick={(e) => {
                                            dispatch({
                                                type: swalConstants.SHOW, data: {
                                                    type: "warning",
                                                    title: i18n.t("warning_messages.are_you_sure_delete_product"),
                                                    message: `${tableMeta.rowData[4]}`,
                                                    onConfirm: function () {
                                                        let reqParams = {
                                                            _id: tableMeta.rowData[0]
                                                        }
                                                        dispatch({ type: swalConstants.HIDE })
                                                        dispatch(productActions.deleteProduct(reqParams));
                                                    },
                                                    onCancel: function () {
                                                        dispatch({ type: swalConstants.HIDE })
                                                    },
                                                    showCancel: true,
                                                    confirmBtnText: i18n.t("delete"),
                                                    cancelBtnText: i18n.t("cancel")
                                                }
                                            })
                                        }} />
                                }
                            </div>
                        )
                    }
                }
            })
        }

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            customToolbar: () => {

                return (
                   <>
                      {plugins.isHasPermission(moduleName, 'read') &&(
                            <Tooltip title='Export CSV'>
                               <IconButton
                                  id='button-productTable-exportCsv'
                                  className=''
                                  onClick={this.exportCSV.bind(this)}>
                                  <CloudDownload />
                               </IconButton>
                            </Tooltip>
                      )}
    
                      {plugins.isHasPermission(moduleName, 'create') &&(
                         <CustomToolbar id="add_new_product" handleClick={this.openAddProduct} title="Add New" />
                      )}
                   </>
                );
             },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        // if (plugins.isHasPermission(moduleName, 'create')) {
        //     options.customToolbar = () => {
        //         return (
        //             <CustomToolbar id="add_new_product" handleClick={this.openAddProduct} title="Add New" />
        //         );
        //     }
        // }

        return (
            <div id="tableProductList">
                <CustomSweetAlert />
                <ProductForm
                    initialValues={formInitialValue}
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                />
                   <div style={{ padding : '30px'}}>
               <form
                  name={FORM_NAME}
                  onSubmit={handleSubmit(this.handleCategorySubmit.bind(this))}
               >
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={6} lg={6}>
                     <Field
                        id={`${FORM_NAME}_category`}
                        name="category"
                        component={TagsMultiSelect}
                        labelText={'Category'}
                        formControlProps={{
                           fullWidth: true,
                        }}
                        showtick
                        optionsList={categoryList}
                     />
                     </GridItem>
                     <GridItem xs={12} sm={6} md={6} lg={6}>
                     <Button
                        id={`${FORM_NAME}-button-clear`}
                        onClick={this.clearFilter}
                        style={{
                           marginTop: "25px",
                           marginRight: "10px",
                        }}
                        size="sm"
                        color="white"
                     >
                        Clear
                     </Button>
                     <Button
                        id={`${FORM_NAME}-button-submit`}
                        type="submit"
                        style={{ marginRight: "10px", marginTop: "25px" }}
                        size="sm"
                        color="primary"
                     >
                        Submit
                     </Button>
                     </GridItem>
                  </GridContainer>
               </form>
            </div>
                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        title={"Product list"}
                        data={tableDatas.products}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner />}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader } = state.product;
    // const { permission, superAdmin } = state.authentication;
    const { categoryList } = state.list
    return {
        formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader,categoryList
    };
}

const connectedComponent =   connect(mapStateToProps)(withStyles(pageStyle)(TableProductList));

export default reduxForm({
    form: FORM_NAME,
  })(withStyles(style)(connectedComponent))
 
// export default connect(mapStateToProps)(withStyles(pageStyle)(TableProductList));
import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import TableRoleList from "./TableRoleList.jsx";
import RoleForm from "./RoleForm.jsx";

import { plugins } from 'helpers';

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class RoleList extends React.Component {

  render() {
    const { formInitialValue, formOpen } = this.props;
    const displayCard = formOpen ? { display: "none" } : {}
    return (
      <>
        {formOpen && <RoleForm initialValues={formInitialValue} readOnly={!plugins.isHasPermission('role', 'update')} />}

        {/* <GridContainer> */}
        {/* <GridItem xs={12}> */}
        <Card style={displayCard}>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>

                <TableRoleList />

              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        {/* </GridItem> */}
        {/* </GridContainer> */}
      </>
    )
  }

}

function mapStateToProps(state) {
  const { formInitialValue, formOpen } = state.role;
  return {
    formInitialValue, formOpen
  };
}

export default connect(mapStateToProps)(withStyles(style)(RoleList));
export const tmnTopupConstants = {
    GET_TRUEMONEY_TRANSACTION_SUCCESS: "GET_TRUEMONEY_TRANSACTION_SUCCESS",
    TRUEMONEY_TRANSACTION_TABLE_RESET: "TRUEMONEY_TRANSACTION_TABLE_RESET",
    CLEAR_TRUEMONEY_TOPUP_FILTER:"CLEAR_TRUEMONEY_TOPUP_FILTER",
    CLEAR_PAGE_STATE: "CLEAR_PAGE_STATE",
    TABLE_GET_LIST_DATA: "TABLE_GET_LIST_DATA",
    TABLE_RELOAD: "TABLE_RELOAD",
    TABLE_RELOAD_SUCCESS: "TABLE_RELOAD_SUCCESS",
    TABLE_RESET: "TABLE_RESET",
    TABLE_RESET_SUCCESS: "TABLE_RESET_SUCCESS",
    HIDE_ALERT: "HIDE_ALERT",
    SHOW_TABLE_LOADER: "SHOW_TABLE_LOADER",
    HIDE_TABLE_LOADER: "HIDE_TABLE_LOADER",
    GET_TRUEMONEY_TOPUP_SUCCESS: "GET_TRUEMONEY_TOPUP_SUCCESS",
    TRUEMONEY_TOPUP_TABLE_RESET: "TRUEMONEY_TOPUP_TABLE_RESET",
    ERR_DETAIL_IS_OPEN : "ERR_DETAIL_IS_OPEN",
    ERR_DETAIL_IS_CLOSE:'ERR_DETAIL_IS_CLOSE'
}


import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, touch } from 'redux-form';
import { crmValidator } from 'helpers';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import { store, normalize, alert as customAlert } from 'helpers';
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";

import { categoryConstants } from '../../constants';
import { memberActions, categoryActions } from '../../actions';

import lodash from 'lodash'
import moment from 'moment';
import i18n from 'i18n';

const validMimeType = "image/jpeg, image/png";
const validateImageFormat = imageFile => {
    if (imageFile) {
        if (!validMimeType.includes(imageFile.type)) {
            return `รองรับนามสกุลไฟล์ jpeg หรือ png เท่านั้น `;
        }
    }
}
const validateImageSize = imageFile => {
    if (imageFile) {
        if (imageFile.size > (1048576 * 2)) {
            return `Please select file less than 2MB`
        }
    }
}
const FORM_NAME = "FORM_CATEGORY";
const form_validate = {
    "type": {
        rules: {
            required: true
        },
        messages: {
            required: 'Category Type is required'
        },
        alert: "Category Type is required"
    },
    "name": {
        rules: {
            required: true,
            maxLength: 50,
        },
        messages: {
            required: 'Category Name is required',
            maxLength: 'Category Name is not over 50 charactors',
        },
        alert: "Category Name is required"
    },
    "categoryCode": {
        rules: {
            required: true,
            noSpecialCharacters: true
        },
        messages: {
            required: 'Category Code is required',
            noSpecialCharacters: 'Category Code is Invalid'
        },
        alert: "Category Code is required"
    },
    "status": {
        rules: {
            required: true
        },
        messages: {
            required: 'Status is required'
        },
        alert: "Status is required"
    },
    "startDate" : {
        rules: {
            sameDateTime : true
        },
        messages: {
            sameDateTime: 'Can not select the same value as EndDate'
        },
        alert: 'Can not select the same value as EndDate'
    },
    "endDate" : {
        rules: {
            sameDateTime : true,
            requireStartDate : true
        },
        messages: {
            requireStartDate : "Please select start date",
            sameDateTime: 'Can not select the same value as StartDate'
        },
        alert: 'Can not select the same value as StartDate'
    }
};
const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const categoryTypeList =
    [
        {
            id: 'campaign',
            value: "campaign",
            label: "Campaign"
        },
        {
            id: 'banner',
            value: "banner",
            label: "Banner"
        },
        {
            id: 'product',
            value: "product",
            label: "Product"
        },
        {
            id: 'promotion',
            value: "promotion",
            label: "Promotion"
        }
    ]

const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ"


class CategoryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(memberActions.getActiveTagList())
        dispatch(memberActions.getActiveMemberLevelList())
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props;
        if(values.startDate){
            values.startDate = moment(values.startDate).format(dbDateFormat)
        }
        if(values.endDate){
            values.endDate = moment(values.endDate).format(dbDateFormat)
        }

        let tagList = lodash.get(values, "tagList", []);
		if (tagList.length === 0) {
			delete values.tagList;
		}

        let memberLevel = lodash.get(values, "memberLevel", []);
		if (memberLevel.length === 0) {
			delete values.memberLevel;
		}

        if (this.props.formMode === "add") {
            dispatch(categoryActions.addCategory(values))
        }
        else {
            dispatch(categoryActions.updateCategory(values));
        }
    }

    setStatusDefault = () => {
        const { dispatch } = this.props
        dispatch(change("FORM_CATEGORY", "status", "Active"));
    }


    render() {
        const { formOpen, formMode, classes, dispatch, handleSubmit, categoryImagePreview, formTaglist, formMemberLevellist, FORM_CATEGORY } = this.props;
        console.log('FORM_CATEGORY', FORM_CATEGORY)
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Category' : (readOnly ? 'Category Info' : 'Edit Category')}</b></h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="category-form-dialog"
                        className={classes.modalBody}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id='type'
                                    name="type"
                                    component={CustomSelect}
                                    onChange={this.setStatusDefault}
                                    type="text"
                                    labelText="Category Type"
                                    disabled={readOnly}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={categoryTypeList}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id={`${FORM_NAME}_name`}
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Category Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={!lodash.get(FORM_CATEGORY, 'values.type')}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id={`${FORM_NAME}_categoryCode`}
                                    name="categoryCode"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Category Code"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={!lodash.get(FORM_CATEGORY, 'values.type')}
                                    normalize={(value) => {
                                        return normalize.upperCasewithNumber(value)
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    name="startDate"
                                    component={CustomDatePicker}
                                    type="text"
                                    maxDate={lodash.get(FORM_CATEGORY, 'values.endDate')}
                                    labelText={i18n.t("label.start_date")}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    disabled={!lodash.get(FORM_CATEGORY, 'values.type')}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    name="endDate"
                                    minDate={lodash.get(FORM_CATEGORY, 'values.startDate')}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={i18n.t("label.end_date")}
                                    disabled={!lodash.get(FORM_CATEGORY, 'values.type')}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    name="tagList"
                                    component={TagsMultiSelect}
                                    labelText={i18n.t("label.tag")}
                                    disabled={!lodash.get(FORM_CATEGORY, 'values.type')}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    showtick
                                    optionsList={formTaglist}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    name="memberLevel"
                                    component={TagsMultiSelect}
                                    disabled={!lodash.get(FORM_CATEGORY, 'values.type')}
                                    labelText={i18n.t("label.memberLevel")}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    showtick
                                    optionsList={formMemberLevellist}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id={`${FORM_NAME}_status`}
                                    name="status"
                                    disabled={!lodash.get(FORM_CATEGORY, 'values.type')}
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Status"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            id: 'select_box_active',
                                            value: "Active",
                                            label: "Active"
                                        },
                                        {
                                            id: 'select_box_inactive',
                                            value: "Inactive",
                                            label: "Inactive"
                                        }
                                    ]}

                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id='file'
                                    name="file"
                                    component={ImageUpload}
                                    type="text"
                                    labelText="Category Picture"
                                    textCenter={true}
                                    width={(1140 / 2.5)}
                                    height={(400 / 2.5)}
                                    showRatioGuide={true}
                                    recommendWidth={1140}
                                    recommendHeight={400}
                                    disabled={false}
                                    addButtonProps={{ disabled: !lodash.get(FORM_CATEGORY, 'values.type') }}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        let reader = new FileReader();
                                        let file = e.target.files[0];
                                        reader.onloadend = () => {
                                            dispatch({ type: categoryConstants.SET_FORM_PREVIEW_IMAGE, categoryImage: reader.result })
                                        };

                                        try {
                                            if (!validMimeType.includes(file.type)) {
                                                dispatch(touch(FORM_NAME, 'file'));
                                            }
                                            reader.readAsDataURL(file);
                                            dispatch(change(FORM_NAME, 'file', file))
                                        } catch (error) {
                                            dispatch({ type: categoryConstants.SET_FORM_PREVIEW_IMAGE, categoryImage: "default" })
                                            dispatch(change(FORM_NAME, 'file', ''))
                                        }
                                    }}
                                    imagePreviewUrl={categoryImagePreview}
                                    formControlProps={{
                                        fullWidth: true,

                                    }}
                                    validate={[
                                        validateImageFormat,
                                        validateImageSize
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>

                    <DialogActions
                        className={classes.modalFooter}>
                        <Button
                            id={`${FORM_NAME}_btn_submit`}
                            type="submit"
                            style={{ marginRight: "10px" }}
                            color="primary">
                            Submit
                            </Button>
                        <Button
                            id={`${FORM_NAME}_btn_cancel`}
                            type="button"
                            onClick={() => {
                                dispatch({ type: categoryConstants.CLOSE_FORM_CATEGORY });
                            }}
                            color="white">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    console.log("state",state)
    const { formOpen, formMode, categoryImagePreview, readOnly } = state.category
    const { formTaglist, formMemberLevellist } = state.member;
    const { FORM_CATEGORY } = state.form;
    return {
        formOpen, formMode, categoryImagePreview, formTaglist, formMemberLevellist, FORM_CATEGORY, readOnly
    };
}

const connectCategoryForm = connect(mapStateToProps)(CategoryForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);

        let globalStore = store.getState();
        let formMeta = globalStore.form[FORM_NAME];
        if (Object.keys(errors).length === 1) {
            if (errors.file && !formMeta.values.file) {
                customAlert(dispatch, 'error', 'Category Pic is required')
            }
        }

    }
})(withStyles(modalStyle)(connectCategoryForm))
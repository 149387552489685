import { listServices} from 'services';
import { promotionServices} from 'services';
import { loader } from '../helpers';
import { listConstants } from '../constants';
import lodash from 'lodash';

export const listActions = {
    getCategoryList,
    getListProductGroup,
    getListBrand,
    getTablePromotionDatas
}

function getCategoryList(type, queryStringParams){
    return dispatch => {
        listServices.getCategoryList(type, queryStringParams)
            .then(
                response => {
                    if (response.status) {
                        // dispatch(success(response.data))
                        const categoryList = lodash.has(response.data,'data') ? lodash.get(response.data,'data') : []
                        dispatch(success(categoryList))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: listConstants.GET_CAMPAIGN_CATEGORY_LIST, data }}
}

function getTablePromotionDatas(params) {
    return dispatch => {
        loader(dispatch, true)
        promotionServices.getTablePromotionDatas(params)
            .then(
                response => {
                    if (response.status) {
                        const formPromotionlist = lodash.has(response.data,'promotions') ? lodash.get(response.data,'promotions') : []
                        //console.log(formPromotionlist);
                        dispatch(success(formPromotionlist));
                    } else {
                        // if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(data) { return { type: listConstants.GET_CAMPAIGN_PROMOTION_LIST, data } }
}

function getListProductGroup(params){
    return dispatch => {
        listServices.getListProductGroup(params)
            .then(
                response => {
                    if (response.status) {
                        const listProductGroup = lodash.has(response.data,'product_group') ? lodash.get(response.data,'product_group') : []
                        dispatch(success(listProductGroup))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: listConstants.GET_CAMPAIGN_PRODUCT_GROUP, data }}
}

function getListBrand(params){
    return dispatch => {
        listServices.getListBrand(params)
            .then(
                response => {
                    if (response.status) {
                        const listBrand = lodash.has(response.data,'brands') ? lodash.get(response.data,'brands') : []
                        dispatch(success(listBrand))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: listConstants.GET_ฺBRAND, data }}
}

import { merchantConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";

const initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableReset: false,
    tableReload: false,
    productImagePreview: defaultImage,
    campaignData: [],
    tableDatas: {
        merchants: [],
        total: 0
    },
    tableBudget: {
        data: [],
        total: 0,
        summary: {
            budget: 0,
            reserved: 0,
            availble: 0,
        }
    },
    tableBudgetReset: false,
    tableKey: 0,
    tableLoader: false,
    showLine: false,
    showSms: false,
    showLuckyDraw: false,
    showEarnRule: false,
    showShipping: false,
    showWinner: false,
};

export function merchant(state = initialState, action) {
    switch (action.type) {
        case merchantConstants.CLEAR_PAGE_STATE:
            return initialState;

        case merchantConstants.OPEN_MANAGEMENT_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.data,
                productImagePreview: defaultImage
            };
        case merchantConstants.OPEN_MANAGEMENT_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                productImagePreview: action.productImagePreview,
                showLine: false,
                showSms: false,
                showLuckyDraw: false,
                showEarnRule: false,
                showShipping: false
            };
        case merchantConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.data,
                productImagePreview: defaultImage
            };
        case merchantConstants.OPEN_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                productImagePreview: action.productImagePreview
            };
        case merchantConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                    productImage: ""
                },
                productImagePreview: defaultImage
            }
        case merchantConstants.ADD_NEW_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case merchantConstants.EDIT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
                showLine: false,
                showSms: false,
                showLuckyDraw: false,
                showEarnRule: false,
                showShipping: false
            }
        case merchantConstants.TABLE_GET_LIST:
            return {
                ...state,
                tableDatas: action.tmp,
                tableReload: false,
                tableReset: false,
            }
        case merchantConstants.TABLE_RELOAD_SUCCESS:
        case merchantConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case merchantConstants.DELETE_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case merchantConstants.SET_FORM_PREVIEW_IMAGE:
            return {
                ...state,
                productImagePreview: action.productImagePreview === "default" ? defaultImage : action.productImagePreview
            }
        case merchantConstants.GET_DATA_SUCCESS:
            return {
                ...state,
                campaignData: action.data
            }
        case merchantConstants.SHOW_TABLE_LOADER:
            return {
                ...state,
                tableLoader: true
            }
        case merchantConstants.HIDE_TABLE_LOADER:
            return {
                ...state,
                tableLoader: false
            }
        case merchantConstants.GET_BUDGET_SUCCESS:
            return {
                ...state,
                tableBudgetReset: false,
                tableBudget: action.tmp,
            }
        case merchantConstants.ADD_BUDGET_SUCCESS:
            return {
                ...state,
                tableBudgetReset: true
            }
        case merchantConstants.CLEAR_BUDGET_LISTS:
            return {
                ...state,
                tableBudgetReset: false,
                tableBudget: initialState.tableBudget
            }
        case merchantConstants.BUDGET_RELOAD_SUCCESS:
            return {
                ...state,
                tableBudgetReset: false,
            }
        default:
            return state
    }
}
import { categoryConstants } from '../constants';
import { categoryServices } from '../services';
import { loader, alert as customAlert } from '../helpers';

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const categoryActions = {
    getListsCategory,
    deleteCategory,
    updateCategory,
    addCategory,
    getCategoryDetail
}

function getListsCategory(params) {
    return dispatch => {
        loader(dispatch, true)
        categoryServices.getListsCategory(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: categoryConstants.TABLE_GET_LIST_CATEGORY, tmp }}
}

function addCategory(params) {
    return dispatch => {
        loader(dispatch, true)
        categoryServices.addCategory(params)
            .then(
                response => { 
                    try {
                        if (response.status) {
                            dispatch({ type: categoryConstants.ADD_CATEGORY_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.category_was_created'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function deleteCategory(params) {
    return dispatch => {
        loader(dispatch, true)
        categoryServices.deleteCategory(params)
           .then(
              tmp => {
                 if (tmp.status) {
                    dispatch({ type: categoryConstants.DELETE_CATEGORY_SUCCESS });
                    customAlert(dispatch, 'success', i18n.t('warning_messages.category_was_deleted'));
                 } else {
                    if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
                 }
              },
              error => {
                 customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
           ).then(() => {
              loader(dispatch, false)
           })
     };
}

function updateCategory(params) {
    return dispatch => {
        loader(dispatch, true)
        categoryServices.updateCategory(params)
           .then(
              response => {
                 if (response.status) {
                    dispatch({ type: categoryConstants.EDIT_CATEGORY_SUCCESS });
                    customAlert(dispatch, 'success', i18n.t('warning_messages.category_was_updated'));
                 } else {
                    if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                 }
              },
              error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
           ).then(() => {
              loader(dispatch, false)
           })
     };
}

function getCategoryDetail(params) {
    return dispatch => {
        loader(dispatch, true)
        categoryServices.getCategoryDetail(params)
            .then(
                response => {
                    //console.log('getCategoryDetail response :',response);
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) {
        console.log('getCategoryDetail tmp :',tmp);
         return { type: categoryConstants.OPEN_FORM_EDIT_CATEGORY , tmp }
    }
}



import React from 'react';
import { connect } from 'react-redux';
import { Field, change, unregisterField } from 'redux-form';
import { normalize, crmValidator } from 'helpers';
import lodash from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import Loader from 'react-loader-spinner';

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx"

import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from '@material-ui/core/Icon';
import Close from "@material-ui/icons/Close";
import Search from '@material-ui/icons/Search';

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { earnrulesConstants } from '../../constants';
import { earnRulesAction } from 'actions';

const validateRequired = value => {
  if (value === null || value === undefined || value === "") {
    return "Please enter"//กรุณากรอก
  }
}

const validateCampaignCode = value => {
  const errorMsg = 'Please enter Campaign Code 3 letters'//กรุณาระบุ Campaign Code ให้ครบ 3 ตัวอักษร
  if (typeof value === "string") {
    if (value.length !== 3) {
      return errorMsg;
    }
  } else {
    return errorMsg;
  }
}

const validateProductCount = value => {
  if (value === 0) {
    return "Please select at least a product"//กรุณาเลือกสินค้าอย่างน้อย 1 รายการ
  }
}
const validateIsZero = (values) => {
  if (parseInt(values) === 0) {
    return "Please more than 0"
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  tableHead: {
    backgroundColor: "#eee",
    position: "sticky",
    top: 0,
    padding: "0px 10px",
    zIndex: 10
  },
  tableCell: {
    padding: "0px 10px"
  },
  tableCellLoader: {
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  tableHover: {
    transitionDuration: "0.3s",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#efefef"
    }
  },
  tableSelectProduct: {
    maxHeight: "300px",
    overflow: "auto",
    marginBottom: "10px"
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  noPaddingTop: {
    "& > div": {
      paddingTop: "0px"
    }
  },
  textUpper: {
    textTransform: 'uppercase'
  },
  haveNoData: {
    color: "#777",
    fontStyle: "italic",
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  tableError: {
    border: "solid 2px red"
  }
});

const validate_field = {
  numberOnly: {
    rules: {
      required: true,
      number: true,
      noSpecialCharacters: true,
    },
  },
}
const FORM_NAME = 'FORM_EARNRULES';

class EarnRuleTypeCode extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      keyword: undefined,
      addingProduct: false
    }
    this.onSearchProductChange = lodash.debounce((keyword) => {
      this.searchProductList(keyword)
    }, 800)
    this.totalDisplayProduct = 0
  }

  componentDidMount() {
    const { dispatch, formMode } = this.props;
    this.totalDisplayProduct = 0
    dispatch(earnRulesAction.getLuckyDrawRewardSetList())
    this.getProductList()
    //clear selected ProductList
    if (formMode === "add") {
      dispatch({ type: earnrulesConstants.ON_EARNRULE_TYPE_CHANGE_TO_CODE })
    }
  }

  getProductList(keyword) {
    const { dispatch } = this.props;
    let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"
    if (keyword) {
      reqParams += `&keyword=${keyword}`
    }
    dispatch(earnRulesAction.getListsProduct(reqParams))
  }

  searchProductList(keyword) {
    if (keyword) {
      this.setState({ keyword: keyword })
    } else {
      this.setState({ keyword: undefined })
    }
  }

  addAllProductItem() {
    const { selectedProductList, dispatch, allProductList } = this.props;
    const _this = this;
    let selected = []
    lodash.each(allProductList, function (currentObject) {
      let find = lodash.find(selectedProductList, ['_id', currentObject._id])
      let isShow = true
      if (_this.state.keyword) {
        let regex = _this.state.keyword.toLowerCase()
        regex = regex.trim()
        isShow = ((currentObject.name.toLowerCase().indexOf(regex) !== -1) || (currentObject.prefix.toLowerCase().indexOf(regex) !== -1))
      }
      if (find === undefined && isShow) {
        selected.push(currentObject)
        // _this.selectProductItem(currentObject);
      }
    })
    dispatch({ type: earnrulesConstants.RULE_TYPECODE_SELECTED_MANY_PRODUCT_ITEM, data: selected })
    dispatch(change(FORM_NAME, 'selectedProductCount', lodash.size(selectedProductList) + lodash.size(selected)))
    this.setState({ addingProduct: false });
  }

  selectProductItem(data) {
    const { dispatch, selectedProductList } = this.props;
    let find = lodash.find(selectedProductList, ['_id', data._id])
    if (find === undefined) {
      dispatch({ type: earnrulesConstants.RULE_TYPECODE_SELECTED_PRODUCT_ITEM, data: data })
      dispatch(change(FORM_NAME, 'selectedProductCount', lodash.size(selectedProductList) + 1))
    }
  }

  removeSelectedItem(data) {
    const { dispatch, selectedProductList } = this.props;
    dispatch({ type: earnrulesConstants.RULE_TYPECODE_DESELECTED_PRODUCT_ITEM, data: data })
    dispatch(unregisterField(FORM_NAME, `productPoint[${data._id}]`))
    dispatch(change(FORM_NAME, 'selectedProductCount', lodash.size(selectedProductList) - 1))
  }

  setAllProductPoint() {
    const { FORM_EARNRULES, selectedProductList, dispatch } = this.props;

    if (lodash.has(FORM_EARNRULES, 'values.setAllProductPoint')) {
      let point = FORM_EARNRULES.values.setAllProductPoint
      lodash.forEach(selectedProductList, function (currentObject) {
        dispatch(change(FORM_NAME, `productPoint[${currentObject._id}]`, point))
      })

      dispatch(change(FORM_NAME, `setAllProductPoint`, ''))
    }
  }

  renderProductListLoader() {
    const { classes } = this.props
    return (
      <TableRow>
        <TableCell
          colSpan={2}
          className={classes.tableCell + " " + classes.tableCellLoader}>
          <Loader
            type="Oval"
            color="#f4b340"
            height="40"
            width="40"
          />
        </TableCell>
      </TableRow>
    )
  }

  renderHaveNodata(cellProps) {
    const { classes } = this.props;
    let colspan = cellProps.colspan;
    if (!colspan) {
      colspan = 2
    }
    return (
      <TableRow>
        <TableCell
          colSpan={colspan}
          className={classes.tableCell + " " + classes.haveNoData}>
          Have no Data
        </TableCell>
      </TableRow>
    )
  }

  renderTableProductList() {
    const { classes, selectedProductList, formProductListLoader, allProductList } = this.props;
    if (formProductListLoader || this.state.addingProduct) {
      this.totalDisplayProduct = 0
      return this.renderProductListLoader()
    } else {
      let total = 0;
      if (lodash.size(allProductList) > 0) {
        let template = allProductList.map(n => {
          let find = lodash.find(selectedProductList, ['_id', n._id])
          let show = true
          if (this.state.keyword) {
            let regex = this.state.keyword.toLowerCase()
            regex = regex.trim()
            show = ((n.name.toLowerCase().indexOf(regex) !== -1) || (n.prefix.toLowerCase().indexOf(regex) !== -1))
          }
          if (show && find === undefined) {
            total += 1;
            return (
              <TableRow key={n._id} className={classes.tableHover} onClick={() => {
                this.selectProductItem(n)
              }}>
                <TableCell className={classes.tableCell}>{n.name}</TableCell>
                <TableCell className={classes.tableCell + " " + classes.textUpper}>{n.prefix}</TableCell>
                <TableCell className={classes.tableCell + " " + classes.textUpper}>{n.skuId}</TableCell>
              </TableRow>
            );
          } else {
            return null
          }
        })
        this.totalDisplayProduct = total
        if (total === 0) {
          return this.renderHaveNodata({ colspan: 2 })
        } else {
          return template
        }
      } else {
        this.totalDisplayProduct = 0
        return this.renderHaveNodata({ colspan: 2 })
      }
    }
  }

  selectedProductCount = ({ ...props }) => {
    const { meta } = props
    const error = (meta !== undefined && meta.touched && meta.invalid);
    if (error) {
      return (<div style={{ color: "red", margin: "0px 15px 10px 15px" }}>{meta.error}</div>)
    } else {
      return null
    }
  }

  renderSelectedProductList() {
    const { selectedProductList, classes, readOnly } = this.props
    const _this = this;
    let template = selectedProductList.map(n => {
      return (
        <TableRow key={n._id}>
          <TableCell className={classes.tableCell}>{n.name}</TableCell>
          <TableCell className={classes.tableCell + " " + classes.textUpper}>{n.prefix}</TableCell>
          <TableCell className={classes.tableCell + " " + classes.textUpper}>{n.skuId}</TableCell>
          <TableCell className={classes.tableCell}>
            <Field
              name={`productPoint[${n._id}]`}
              component={CustomInput}
              type="number"
              formControlProps={{
                fullWidth: true
              }}
              min={1}
              validate={[validateRequired, validateIsZero]}
              normalize={(value) => {
                return crmValidator.handleKeyPress(value, validate_field.numberOnly)
              }}
              disabled={readOnly}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            {readOnly === false &&
              <Button
                color={"danger"}
                simple
                className={classes.actionButton}
                onClick={() => {
                  _this.removeSelectedItem(n)
                }}>
                <Close className={classes.Close} />
              </Button>
            }
          </TableCell>
        </TableRow>
      );
    })
    return template
  }

  render() {
    const { classes, selectedProductList, FORM_EARNRULES, readOnly, formMode, luckyDrawRewardSet } = this.props
    let productTemplate = this.renderTableProductList()

    let setLuckyDrawValue = [];
    lodash.forEach(luckyDrawRewardSet, function (value, key) {
      if (!value.useInCampaignId) {
        let sel_value = value._id;
        let sel_label = value.name_th;
        setLuckyDrawValue.push({
          value: sel_value,
          label: sel_label,
        });
      }
    });
    return (
      <div style={{ border: "dashed 1px #ccc", padding: "0px 20px" }}>
        <GridContainer style={{ marginTop: "15px" }}>
          <GridItem xs={6} sm={6} md={6}>
            <b>Earn For</b>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <b>Earn By</b>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <Field
              id="radio-earn-for"
              name="customRadioEarnFor"
              component={CustomRadio}
              radioLists={[
                {
                  value: "point",
                  label: (
                    <span style={{ fontWeight: "300" }}>Point</span>
                  ),
                  disabled: readOnly || (formMode === "edit"),
                  id: "radio-earn-for-point",
                },
                {
                  value: "luckyChance",
                  label: (
                    <span style={{ fontWeight: "300" }}>
                      Lucky Chance
                    </span>
                  ),
                  disabled: readOnly || (formMode === "edit"),
                  id: "radio-earn-for-luckyChance",
                },
              ]}
              disabled={readOnly || (formMode === "edit")}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Field
              id="radio-earn-by"
              name="customRadioEarnBy"
              component={CustomRadio}
              radioLists={[
                {
                  value: "prefix",
                  label: (
                    <span style={{ fontWeight: "300" }}>Prefix (Default)</span>
                  ),
                  disabled: readOnly || (formMode === "edit"),
                  id: "radio-earn-by-prefix",
                },
                {
                  value: "skuId",
                  label: (
                    <span style={{ fontWeight: "300" }}>
                      SKU ID
                    </span>
                  ),
                  disabled: readOnly || (formMode === "edit"),
                  id: "radio-earn-by-skuId",
                },
              ]}
              disabled={readOnly || (formMode === "edit")}
            />
          </GridItem>
          {lodash.get(FORM_EARNRULES.values, "customRadioEarnFor") ===
            "luckyChance" ? (
            <GridItem xs={12} sm={6} md={6} style={{ marginLeft: "35px" }}>
              <Field
                id='rule-status-select'
                name="luckyDrawRewardId"
                component={CustomSelect}
                type="text"
                labelText="Reward"
                formControlProps={{
                  fullWidth: true
                }}
                optionsList={setLuckyDrawValue}
                disabled={readOnly || (formMode === "edit")}
                validate={[validateRequired]}
              />
            </GridItem>
          ) : null}
        </GridContainer>

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <Field
              name="earnRuleCode"
              component={CustomInput}
              type="text"
              labelText="Earn Rule Code"
              formControlProps={{
                fullWidth: true
              }}
              normalize={(value) => {
                return normalize.campaignCode(value)
              }}
              disabled={readOnly || (formMode === "edit")}
              validate={[validateRequired, validateCampaignCode]}
            />
          </GridItem>
        </GridContainer>
        {readOnly === false &&
          <>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <b>Add Product</b>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={5}>
                <CustomInput
                  placeholder="Search by Product name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  input={{
                    onChange: (e) => {
                      this.onSearchProductChange(e.target.value)
                    }
                  }}
                  startAdornment={(<InputAdornment position="start"><Search style={{ color: "#ccc" }} /></InputAdornment>)}
                  name="searchProduct" />
              </GridItem>
              <GridItem xs={12} sm={4} md={7} style={{ textAlign: "right" }}>
                <span style={{ position: "relative", top: "35px", right: "20px" }}>Result: {this.totalDisplayProduct}</span>
                <Button color="primary" size="sm" onClick={() => {
                  this.setState({
                    addingProduct: true
                  }, () => {
                    this.addAllProductItem()
                  });
                }} style={{ float: "right", marginTop: "30px" }}>Add All</Button>
              </GridItem>

              <GridItem xs={12} sm={12} md={12} className={classes.tableSelectProduct}>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        Name
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        Prefix
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        SKU ID
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ border: "solid 1px #eee" }}>
                    {productTemplate}
                  </TableBody>
                </Table>
              </GridItem>

              <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                <Icon fontSize="large">arrow_downward</Icon>
              </GridItem>
            </GridContainer>
          </>
        }

        <GridContainer>
          <GridItem xs={12} sm={4} md={4} style={{ marginBottom: "20px" }}>
            <div style={{ paddingTop: "8px" }}><b>Product List</b></div>
          </GridItem>

          {readOnly === false &&
            <>
              <GridItem xs={4} sm={3} md={3} style={{ textAlign: "right" }}>
                <p style={{ paddingTop: "8px" }}>Set All Point : </p>
              </GridItem>

              <GridItem xs={5} sm={3} md={3} className={classes.noPaddingTop}>

                <Field
                  name="setAllProductPoint"
                  component={CustomInput}
                  type="number"
                  formControlProps={{
                    fullWidth: true
                  }}
                  min={1}
                  validate={[validateIsZero]}
                  normalize={(value) => {
                    return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                  }}
                />

              </GridItem>

              <GridItem xs={12} sm={2} md={2} style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    this.setAllProductPoint()
                  }}>
                  Set
                </Button>
              </GridItem>
            </>}

        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.tableSelectProduct}>
            <Table className={
              lodash.has(FORM_EARNRULES, 'syncErrors.selectedProductCount') === true
                && lodash.has(FORM_EARNRULES, 'fields.selectedProductCount.touched') === true
                ? classes.tableError : null
            }>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>
                    Name
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Prefix
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    SKU ID
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Point
                  </TableCell>
                  <TableCell className={classes.tableHead}>

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ border: "solid 1px #eee" }}>
                {
                  (lodash.size(selectedProductList) > 0) ?
                    this.renderSelectedProductList()
                    :
                    this.renderHaveNodata({ colspan: 4 })
                }
              </TableBody>
            </Table>
          </GridItem>

          <Field
            component={this.selectedProductCount}
            // validate={[validateProductCount]}
            name="selectedProductCount"
            type="hidden"
          />

        </GridContainer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { formOpen, formMode, allProductList, productList, selectedProductList, formProductListLoader, luckyDrawRewardSet } = state.earnrules;
  const { FORM_EARNRULES } = state.form;
  return {
    formOpen, formMode, allProductList, productList, selectedProductList, formProductListLoader, FORM_EARNRULES, luckyDrawRewardSet
  };
}

const connectEarnRuleTypeCode = connect(mapStateToProps)(EarnRuleTypeCode);

export default withStyles(styles)(connectEarnRuleTypeCode);
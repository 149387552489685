import { missionConstants } from '../constants';
import lodash from 'lodash'
var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {
        loginCondition: 'continuouslyUntil',
        missionType: "Login",
        status: "Active",
        earnReward: {
            achieveReward: 'Manually'
        },
        rewardRedemption: "0"
    },
    tableKey: 0,
    tableData: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false,
    missionData: [],
    readOnly: false,
    startDateOriginal: null,
    rewardList: [],
    campaignList: []

}

export function mission(state = initialState, action) {
    switch (action.type) {
        case missionConstants.CLEAR_PAGE_STATE:
            return initialState;

        case missionConstants.OPEN_FORM_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: initialState.formInitialValue,
                startDateOriginal: null,
            };
        case missionConstants.OPEN_FORM_EDIT:
            let loginCond = 'continuouslyUntil'
            let privilege = []
            switch (action.tmp.loginCondition.conditionType) {
                case 0: {
                    loginCond = 'continuouslyUntil'; break;
                } case 1: {
                    loginCond = 'continuouslyEveryDay'; break;
                } case 2: {
                    loginCond = 'justLoginNoNeedContinue'; break;
                } case 3: {
                    loginCond = 'justLoginGetPrivilegeBySequence'; break;
                } case 4: {
                    loginCond = 'justLoginGetPrivilegeOnTheDay'; break;
                } default: loginCond = 'continuouslyUntil'
            }
            if (lodash.get(action.tmp, 'missionPrivilege.point') === true) {
                privilege.push('point')
            } if (lodash.get(action.tmp, 'missionPrivilege.reward') === true) {
                privilege.push('reward')
            } if (lodash.get(action.tmp, 'missionPrivilege.luckyChance') === true) {
                privilege.push('luckyChance')
            }

            let achieveList = lodash.get(action.tmp, 'earnReward.achieveList', [])
            let tmp_achieveList = {}
            achieveList = achieveList.map((tmp) => {

                if(lodash.get(tmp, 'point')){
                    lodash.set(tmp_achieveList, `[${tmp.no}][point]`, lodash.get(tmp, 'point'))
                }
                if(lodash.get(tmp, 'campaignId')){
                    lodash.set(tmp_achieveList, `[${tmp.no}][campaignId]`, lodash.get(tmp, 'campaignId'))
                }
                if(lodash.get(tmp, 'reward')){
                    lodash.set(tmp_achieveList, `[${tmp.no}][reward]`, lodash.get(tmp, 'reward'))
                }
                if(lodash.get(tmp, 'luckyChance')){
                    lodash.set(tmp_achieveList, `[${tmp.no}][luckyChance]`, lodash.get(tmp, 'luckyChance'))
                }

                return tmp
            })

            state.formInitialValue = action.tmp
            return {
                ...state,
                formOpen: true,
                readOnly: true,
                formMode: 'edit',
                formInitialValue: {
                    ...state.formInitialValue,
                    loginCondition: loginCond,
                    continuouslyLoginUntil: lodash.get(action.tmp, 'loginCondition.contLoginDay') || null,
                    continuouslyLoginEverySelectDays: lodash.get(action.tmp, 'loginCondition.contLoginType') || null,
                    justLoginNoNeedContinue: lodash.get(action.tmp, 'loginCondition.nonContLoginDay') || null,
                    missionPrivilege: privilege,
                    achieveList: tmp_achieveList,
                    rewardRedemption: lodash.get(action.tmp, 'missionPrivilege.redemption', "0").toString(),
                },
                startDateOriginal: action.tmp.startDate,
            };
        case missionConstants.CLOSE_FORM_MISSION:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                startDateOriginal: null,
            }
        case missionConstants.ADD_MISSION_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
                startDateOriginal: null,
            }
        case missionConstants.GET_MISSION_DATA_SUCCESS:
            return {
                ...state,
                missionData: action.data
            }
        case missionConstants.TABLE_GET_LIST_MISSION:
            return {
                ...state,
                tableData: {
                    data: action.tmp.missions,
                    total: action.tmp.total,
                },
                tableReload: false,
                tableReset: false
            }
        case missionConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case missionConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case missionConstants.DELETE_MISSION_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case missionConstants.EDIT_MISSION_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case missionConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                campaignList: action.data
            };
        case missionConstants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS:
            return {
                ...state,
                rewardList: action.data.rewards
            };
        default:
            return state;
    }
}
export const rfmConfigConstants = {
    INIT_LIST_CONSTANT: 'INIT_LIST_CONSTANT',
    GET_TABLE_TEMPLATE_LIST: "GET_TABLE_TEMPLATE",
    GET_RFM_CONFIG_LIST: "GET_RFM_CONFIG_LIST",

    ADD_RFM_CONFIG_SUCCESS: 'ADD_RFM_CONFIG_SUCCESS',
    EDIT_RFM_CONFIG_SUCCESS: 'EDIT_RFM_CONFIG_SUCCESS',

    SET_TABLE_TEMPLATE_LIST: 'SET_TABLE_TEMPLATE_LIST',
    SEARCH_FIELD_TEMPLATE_LIST: 'SEARCH_FIELD_TEMPLATE_LIST',
    CHECK_TABLE_TEMPLATE_CONFIG: 'CHECK_TABLE_TEMPLATE_CONFIG',
    OPEN_RECENCY_DETAIL: 'OPEN_RECENCY_DETAIL',
}

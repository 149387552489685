import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import lodash from 'lodash';
import moment from 'moment'

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from 'components/CustomButtons/Button'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// import Badge from "components/Badge/Badge.jsx";

import { plugins } from 'helpers';

import { dashboardServices } from 'services';
import { dashboardActions } from "actions/dashboard.actions";


const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                textAlign: "center",
                paddingRight: '24px',
            },
            body: {
                textAlign: "center",
                paddingRight: '24px',
            },
        },
    }
});

class TableDailyBurnTransactionDashboardList extends React.Component {
    
    componentDidMount(){
        const { tableDialyBurnTransactionFilter, dispatch } = this.props;
        dispatch(dashboardActions.getTableDailyBurnTransactionDatas(tableDialyBurnTransactionFilter));
        dispatch({type: 'DASHBOARD_RESET_ACTIVE_DAILY_BURN_TRANSACTION', tableDialyBurnTransactionFilter })
    }

    getRowData(rowIndex = 0) {
        const { tableDialyBurnTransactionDatas } = this.props;
        var data = tableDialyBurnTransactionDatas;
        return data[rowIndex];
    }

    getDownloadFile(id) {
        dashboardServices.getFileDownloadBurnTransaction(id)
        .then(
            tmp => {        
                if (lodash.get(tmp, 'status') === true) {
                    window.location.assign(tmp.link)
                }
            }
        )
    }

    render() {
        const { showLoader, tableDialyBurnTransactionDatas,  tableDialyBurnTransactionTotal, tableDialyBurnTransactionFilter, visiblePersonalData } = this.props;
        var labelReportDate = 'Daily';
       
        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "displayId", options: { filter: false, display: "excluded" } },
            {
                name: "reportDate", label: labelReportDate
            },
            {
                name: "count", label: "Total User"
            },
            {
                name: "detail", label: "Detail",
                options: {
                    display: (plugins.isHasPermission("dashboard", 'export') && plugins.isHasPackageFeatures('export') && visiblePersonalData),
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return (
                            <>
                                <Button
                                    id = "btn_export_csv"
                                    variant="contained"
                                    bcolor="primary"
                                    size="sm"
                                    onClick={(e) => { 
                                        this.getDownloadFile(rowData._id)
                                    }}
                                    style={{ backgroundColor: "#84B0F3" }}
                                    disabled={lodash.get(rowData, '_id') ? false : true}
                                >
                                    Export
                                </Button>

                            </>
                        );

                    }
                }

            },
        ]

        let options = {
            serverSide: false,
            rowsPerPage: 10,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 31],
            search: false,
            viewColumns: false,
            sort: false,
            paging: false
        };

        return (
            <div id="tableDailyBurnTransactionDashboardList">
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={showLoader?0:1}
                        data={tableDialyBurnTransactionDatas}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { showLoader, tableDialyBurnTransactionDatas, tableDialyBurnTransactionTotal, tableDialyBurnTransactionFilter } = state.dashboard;
    const { formFilterDialyBurnTransaction } = state.form;
    const { visiblePersonalData } = state.authentication;
    return {
        formFilterDialyBurnTransaction, showLoader, tableDialyBurnTransactionDatas, tableDialyBurnTransactionTotal, tableDialyBurnTransactionFilter, visiblePersonalData
    };
}
export default connect(mapStateToProps)(withStyles(pageStyle)(TableDailyBurnTransactionDashboardList));  
import { pointAdjustmentConstants } from '../constants';
import lodash from 'lodash'
import moment from 'moment';

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableDatas: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false,
    showLoader: false,
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "created_at",
        sortDir: "desc",
        keyword: ""
    },
    adjustmentDetail: {
        approvalPopupOpen: false,
        rejectionPopupOpen: false,
        approvalConfirmationPopupOpen: false,
        createConfirmationPopupOpen: false,
        actionAdjust: "deduct",
        pointAdjust: 0,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        remark: "",
        btnName:"",
        currentPoint:0,
    },
    searchData: {
        data: [],
    }
}

export function point_adjustment(state = initialState, action) {
    switch (action.type) {
        case pointAdjustmentConstants.CLEAR_PAGE_STATE:
            return initialState;
        case pointAdjustmentConstants.OPEN_ADD_POINT_ADJUSTMENT:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: {
                    ...action.data,
                },
                searchData: {
                    data: [],
                }
            };
        case pointAdjustmentConstants.CLOSE_FORM_POINT_ADJUSTMENT:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
            }
        case pointAdjustmentConstants.ADD_POINT_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case pointAdjustmentConstants.GET_USER_BY_PHONE_NUMBER:
            return {
                ...state,
                searchData: {
                    data: lodash.get(action, 'data') ? [lodash.get(action, 'data')]: [],
                },
                tableReload: false,
                tableReset: false,
                adjustmentDetail: {
                    ...state.adjustmentDetail,
                    currentPoint: lodash.get(action,'data.point') ? lodash.get(action,'data.point'): 0,
                },

            }
        case pointAdjustmentConstants.GET_LIST_TABLE_POINT_ADJUSTMENT:
            return {
                ...state,
                tableDatas: {
                    data: action.data,
                    total: action.total,
                },
                tableReload: false,
                tableReset: false
            }
        case pointAdjustmentConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case pointAdjustmentConstants.REJECT_APPROVE_POINT_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case pointAdjustmentConstants.SHOW_LOADER_POINT_ADJUSTMENT:
            return {
                ...state,
                showLoader: true
            }
        case pointAdjustmentConstants.HIDE_LOADER_POINT_ADJUSTMENT:
            return {
                ...state,
                showLoader: false
            }
        case pointAdjustmentConstants.OPEN_APPROVAL_POPUP:
            return {
                ...state,
                adjustmentDetail: {
                    approvalPopupOpen: true,
                    rejectionPopupOpen: false,
                    actionAdjust: action.rowData.type,
                    pointAdjust: action.rowData.point,
                    memberName: action.rowData.memberName,
                    phoneNumber: action.rowData.phoneNumber,
                    remark: action.rowData.remark,
                    btnName: 'Approve',
                    _id: action.rowData._id,
                }

            }
        case pointAdjustmentConstants.CLOSE_APPROVAL_POPUP:
            return {
                ...state,
                adjustmentDetail: {
                    ...state.adjustmentDetail,
                    approvalPopupOpen: false,
                    rejectionPopupOpen: false,
                    btnName: 'Approve',
                }
            }
        case pointAdjustmentConstants.OPEN_REJECTION_POPUP:
            return {
                ...state,
                adjustmentDetail: {
                    rejectionPopupOpen: true,
                    approvalPopupOpen: false,
                    actionAdjust: action.rowData.type,
                    pointAdjust: action.rowData.point,
                    memberName: action.rowData.memberName,
                    phoneNumber: action.rowData.phoneNumber,
                    remark: action.rowData.remark,
                    btnName: 'Reject',
                    _id: action.rowData._id,
                }
            }
        case pointAdjustmentConstants.CLOSE_REJECTION_POPUP:
            return {
                ...state,
                adjustmentDetail: {
                    ...state.adjustmentDetail,
                    approvalPopupOpen: false,
                    rejectionPopupOpen: false,
                    btnName: 'Reject',
                }
            }    
        case pointAdjustmentConstants.OPEN_APPROVAL_SUCCESS_POPUP:
            return {
                ...state,
                adjustmentDetail: {
                    ...state.adjustmentDetail,
                    approvalConfirmationPopupOpen: true,
                },

            }
        case pointAdjustmentConstants.CLOSE_APPROVAL_SUCCESS_POPUP:
            return {
                ...state,
                adjustmentDetail: {
                    ...state.adjustmentDetail,
                    // approvalConfirmationPopupOpen: false,
                    approvalPopupOpen: false,
                    rejectionPopupOpen: false,
                }
            }
        case pointAdjustmentConstants.OPEN_CONFIRMATION_CREATE_ADJUSTMENT_POPUP:
            return {
                ...state,
                adjustmentDetail: {
                    createConfirmationPopupOpen: true,
                    type: lodash.get(action.rowData, 'type') ? lodash.get(action.rowData, 'type') : "",
                    point: lodash.get(action.rowData, 'point') ? lodash.get(action.rowData, 'point') : "",
                    memberName: lodash.get(action.rowData, 'memberName') ? lodash.get(action.rowData, 'memberName') : "",
                    phoneNumber: lodash.get(action.rowData, 'phoneNumber') ? lodash.get(action.rowData, 'phoneNumber') : "",
                    remark: lodash.get(action.rowData, 'remark') ? lodash.get(action.rowData, 'remark') : "",
                    mustApproved: lodash.get(action.rowData, 'mustApproved'),
                }
            }
        case pointAdjustmentConstants.CLOSE_CONFIRMATION_CREATE_ADJUSTMENT_POPUP:
            return {
                ...state,
                adjustmentDetail: {
                    ...state.adjustmentDetail,
                    createConfirmationPopupOpen: false,
                }
            }   
        case pointAdjustmentConstants.CLEAR_POINT_ADJUSTMENT_FILTER: {
            return {
                ...state,
                tableFilter: initialState.tableFilter
            }
        }
        case pointAdjustmentConstants.POINT_ADJUSTMENT_TABLE_RESET:{
            return {
                ...state,
                tableKey: Date.now()
            }
        }
        case pointAdjustmentConstants.SET_FILTER_SEARCH: {
            return {
                ...state,
                // tableFilter: {
                //     ...state.tableFilter,
                //     search: action.search,
                // }
                tableFilter: action.search
            }
        }
        default:
            return state;
    }
}
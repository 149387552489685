import React from "react";
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { plugins, crmValidator, alert as customAlert } from 'helpers';
import lodash from 'lodash'
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSweetAlert from 'components/SweetAlert/CustomSweetAlert'
import BusinessInformation from './BusinessInformation.jsx'
import BusinessThemePreview from './BusinessThemePreview.jsx'
import { businessWebsiteAction } from 'actions';
import { businessWebsiteConstants } from "../../constants"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Resizer from 'react-image-file-resizer';

const FORM_NAME = "BUSINESS_INFORMATION";
const moduleName = 'business_website'

const style = {
    ...modalStyle,
    cardBody: {
        padding: "15px"
    },
    cardHeader: {
        marginLeft: "0px",
        color: "#333"
    },
    formFooter: {
        textAlign: 'right'
    },
    btnSubmit: {
        marginRight: "20px"
    },
    conditionBlock: {
        padding: "10px",
        marginLeft: "0px",
        border: "dashed 1px #ccc"
    },
    tdBordered: {
        border: "solid 1px #ddd"
    },
    subHeader: {
        fontWeight: 900,
        fontSize: "16px",
        marginTop: "20px"
    }
}
const pageStyle = { ...style, ...sweetAlertStyle };
const form_validate = {
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

const resizeFile = (file, type) => 
    new Promise(resolve => {
        var widthImg = type === 'logo' ? 225 : 400;
        Resizer.imageFileResizer(file, widthImg, 225, 'PNG', 100, 0,
        uri => {
            resolve(uri);
        },
        'base64'
        );
    });
    
class BusinessWebsiteForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            imageUrl: '', // url
            imageFile: null, // binary
            imageW: '',
            imageH: '',
        }
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(businessWebsiteAction.getBusinessInfomation())
    }

    async componentDidUpdate(prevProps){
        const { dispatch } = this.props
        if(prevProps.businessLogoPreview != this.props.businessLogoPreview){ // An old url of image have to change to File type.
            if(this.props.changeLogo){
                var fileLogoImage = this.dataURLtoFile(lodash.get(this.props, 'businessLogoPreview'),'logo.png');
                var imageLogo = await resizeFile(fileLogoImage, 'logo'); // resize and return base64
                var fileLogoResized = this.dataURLtoFile(imageLogo, 'logo.png');// change base64 to File
                dispatch({type: businessWebsiteConstants.SET_BUSINESS_LOGO_FILE, businessLogoFile: fileLogoResized, businessLogoPreview: imageLogo})
                // this.reduceSizeImage(fileLogoImage, 'logo')
            }
            // var base64Logo = await this.getBase64Image(this.props.businessLogoPreview, 'logo')
            // var fileLogoImage = this.dataURLtoFile(base64Logo,'logo.png');
            // dispatch({type: businessWebsiteConstants.SET_BUSINESS_LOGO_FILE, businessLogoFile: fileLogoImage})
        }

        if(prevProps.businessCoverPreview != this.props.businessCoverPreview){ // An old url of image have to change to File type.
            if(this.props.changeCover){
                var fileCoverImage = this.dataURLtoFile(lodash.get(this.props, 'businessCoverPreview'),'cover.jpg'); //cropped image change base64 to File(big file,that have to reduce)
                var imageCover = await resizeFile(fileCoverImage, 'cover'); // resize and return base64
                var fileCoverResized = this.dataURLtoFile(imageCover, 'cover.png');// change base64 to File
                dispatch({type: businessWebsiteConstants.SET_BUSINESS_COVER_FILE, businessCoverFile: fileCoverResized, businessCoverPreview: imageCover})
                // this.reduceSizeImage(fileCoverImage, 'cover')
            }
            // var base64Cover = await this.getBase64Image(this.props.businessCoverPreview, 'cover')
            // var fileCoverImage = this.dataURLtoFile(base64Cover, 'cover.png');
            // dispatch({ type: businessWebsiteConstants.SET_BUSINESS_COVER_FILE, businessCoverFile: fileCoverImage})
        }
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: 'image/png' });
    }

    async getBase64Image(imageSrc, type) {
        var canvas = document.createElement("canvas");
        const img = await createImage(imageSrc)

        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL('image/png');
        return dataURL
    }
    

    async handleSubmit(values)  { 
        try {
        const { dispatch, businessThemeColor, formInitialValue } = this.props

        // var fileDefaultLogoImage = this.dataURLtoFile(defaultLogoImage,'defaultlogo.png');
        // dispatch({type: businessWebsiteConstants.SET_BUSINESS_LOGO_FILE, businessLogoFile: fileDefaultLogoImage})
        // var base64CoverImage = this.getBase64Image(defaultCoverImage)
        // .then(function(data) { return data })
        // base64CoverImage.then((a) => {
        //     var fileDefaultCoverImage = this.dataURLtoFile(a,'defaultcover.png');
        //     dispatch({type: businessWebsiteConstants.SET_BUSINESS_COVER_FILE, businessCoverFile: fileDefaultCoverImage})
        // });

        let data = JSON.parse((JSON.stringify(values)));

        let value = {
            businessName: data.businessName ? data.businessName : '',
            businessContact: {
                phoneNumber: data.phoneNumber ? data.phoneNumber : '',
                messenger: data.messenger ? data.messenger : '',
                line: data.line ? data.line : '',
            },
            businessInformation:{
                facebook: data.facebook ? data.facebook : '',
                instagram: data.instagram ? data.instagram : '',
                youtube: data.youtube ? data.youtube : '',
                website: data.website ? data.website : '',
            },
            businessDetail: data.businessDetail ? btoa(encodeURIComponent(data.businessDetail)) : btoa(encodeURIComponent(formInitialValue.businessDetail)),
            businessThemeColor: lodash.get(businessThemeColor, "name") ? businessThemeColor.name : 'eggYellow' ,  
        }
        if(this.props.changeLogo){// if you open a new image, you have to change image type base64 to File.
            value = {
                ...value,
                businessLogo: this.props.businessLogoFile
            }
        }

        if(this.props.changeCover){// if you open a new image, you have to change image type base64 to File.
            value = {
                ...value,
                businessCover: this.props.businessCoverFile
            }
        }
        dispatch(businessWebsiteAction.updateBusinessInfomation(value));
        } catch(e) {
            console.log(e)
        }
    }

    getRowData(rowIndex = 0) {
        const { searchData } = this.props;
        const data = searchData.data;
        return data[rowIndex];
     }

    render() {
        const { classes, formMode, handleSubmit, dispatch, formInitialValue, BUSINESS_INFORMATION } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && plugins.isHasPermission(moduleName, 'update')) {
            readOnly = false
        } else {
            readOnly = true
        }

        return (
            <div>
                <CustomSweetAlert />
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>  
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <BusinessInformation props={this.props} FORM_NAME={FORM_NAME} />
                        </GridItem>  
                        <GridItem xs={12} sm={12} md={6}>
                            <h4 style={{fontWeight:'500', fontSize:'1.0rem'}}>Example Website Screen</h4> 
                            <BusinessThemePreview {...this.props} FORM_NAME={FORM_NAME} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: "right" }} className={classes.modalFooter}>
                            {(plugins.isHasPermission(moduleName, 'update')) &&
                            <Button
                                id="submit-btn"
                                type="submit"
                                style={{ marginRight: "10px" }}
                                size="sm"
                                color="primary">
                                Submit
                            </Button>
                            }
                            <Button
                                id="cancel-btn"
                                onClick={()=>{ 
                                    dispatch({type: businessWebsiteConstants.CLEAR_CHANGING_BUSINESS_WEBSITE }) 
                                    lodash.set(BUSINESS_INFORMATION, 'values.businessName', formInitialValue.businessName)
                                    lodash.set(BUSINESS_INFORMATION, 'values.phoneNumber', formInitialValue.phoneNumber)
                                    lodash.set(BUSINESS_INFORMATION, 'values.messenger', formInitialValue.messenger)
                                    lodash.set(BUSINESS_INFORMATION, 'values.line', formInitialValue.line)
                                    lodash.set(BUSINESS_INFORMATION, 'values.facebook', formInitialValue.facebook)
                                    lodash.set(BUSINESS_INFORMATION, 'values.instagram', formInitialValue.instagram)
                                    lodash.set(BUSINESS_INFORMATION, 'values.youtube', formInitialValue.youtube)
                                    lodash.set(BUSINESS_INFORMATION, 'values.website', formInitialValue.website)
                                    lodash.set(BUSINESS_INFORMATION, 'values.businessDetail', formInitialValue.businessDetail)
                                    lodash.set(BUSINESS_INFORMATION, "syncErrors.businessName",'')
                                    lodash.set(BUSINESS_INFORMATION, "syncErrors.phoneNumber",'')
                                    }}
                                size="sm"
                                color="white">
                                Cancel
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { formOpen, formMode, formInitialValue, businessLogoPreview, businessCoverPreview, formOpenCropLogo, formOpenCropCover, businessLogo, businessCover, businessLink, businessThemeColor, businessDetail, changeLogo, changeCover, businessLogoFile, businessCoverFile, labelTextMessenger, hintTextMessenger, labelTextLine, hintTextLine } = state.business_website;
    const { BUSINESS_INFORMATION } = state.form
    return {
        formOpen, formMode, formInitialValue, businessLogoPreview, businessCoverPreview, formOpenCropLogo, formOpenCropCover, businessLogo, businessCover, businessLink, businessThemeColor, businessDetail, changeLogo, changeCover, businessLogoFile, businessCoverFile, labelTextMessenger, hintTextMessenger, labelTextLine, hintTextLine, BUSINESS_INFORMATION
    };
}

const connectedForm = connect(mapStateToProps)(BusinessWebsiteForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(pageStyle)(connectedForm))

import { dashboardConstants } from '../constants';
import lodash, { includes } from 'lodash';
import moment from 'moment'
import i18n from 'i18n';

const initialState = {
   statistics: {
      gender: {
         labels: [],
         data: []
      },
      age: {
         labels: [],
         data: []
      },
      earnStatus: {
         labels: [],
         data: []
      },
      burnStatus: {
         labels: [],
         data: []
      }
   },
   tableReload: false,
   tableReset: false,
   tableDatas: [],
   tableKey: 0,
   tableFilter: {
      search: {
         startDate: moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, 'day'),
         endDate: moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, 'day'),
         type: "daily",
         activeType: "burn"
      }
   },
   showLoader: false,

   tableDialyRegistrationDatas: [],
   tableDialyRegistrationKey: 0,
   tableDialyRegistrationFilter: {
      search: {
         startDate: moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, 'day'),
         endDate: moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, 'day'),
         activeChannel: "all"
      }
   },
   tableDialyRegistrationTotal: 0,
   showDialyRegistrationLoader: false,

   tableDialyEarnTransactionDatas: [],
   tableDialyEarnTransactionKey: 0,
   tableDialyEarnTransactionFilter: {
      search: {
         startDate: moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, 'day'),
         endDate: moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, 'day'),
         activeChannel: "all"
      }
   },
   tableDialyEarnTransactionTotal: 0,
   showDialyEarnTransactionLoader: false,
   keySummary: {},
   tableInactiveUser:{
      loader:false,
      earn:[],
      burn:[],
      earn_burn:[]
   },

   tableDialyBurnTransactionDatas: [],
   tableDialyBurnTransactionKey: 0,
   tableDialyBurnTransactionFilter: {
      search: {
         startDate: moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, 'day'),
         endDate: moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).subtract(1, 'day'),
         activeCampaign: "all"
      }
   },
   tableDialyBurnTransactionTotal: 0,
   showDialyBurnTransactionLoader: false,

   keySummary: {},
   campaignList: {}
};
export function dashboard(state = initialState, action) {
   switch (action.type) {
      case dashboardConstants.GET_STATISTICS_SUCCESS:

         let genderLabels = []
         let genderData = [0, 0, 0, 0]
         let sortedExpectValue = ['M', 'F', 'O', 'N'];

         lodash.forEach(sortedExpectValue, function (value, index) {
            let genderLabel;
            switch (value) {
               case "M":
                  genderLabel = i18n.t(`gender.M`)
                  break;
               case "F":
                  genderLabel = i18n.t(`gender.F`)
                  break;
               case "O":
                  genderLabel = i18n.t(`gender.O`)
                  break;
               case "N":
                  genderLabel = i18n.t(`gender.N`)
                  break;
               default:
                  genderLabel = i18n.t(`gender.O`)
            }

            let countByGender = lodash.sumBy(action.data.gender, function (o) {
               if (value === "N" && (o._id === "N" || o._id === null)) {
                  return o.count
               } else if (o._id === value) {
                  return o.count
               } else {
                  return 0
               }
            });

            genderLabels.push(genderLabel)
            genderData[index] = countByGender

         });

         return {
            ...state,
            statistics: {
               gender: {
                  labels: genderLabels,
                  data: genderData
               },
               age: action.data.age
            }
         };
      case dashboardConstants.GET_STATISTICS_FAILURE:
         return {
            ...state,
            statistics: {}
         };
      case dashboardConstants.SHOW_LOADER_DASHBOARD:
         return {
            ...state,
            showLoader: true
         };
      case dashboardConstants.HIDE_LOADER_DASHBOARD:
         return {
            ...state,
            showLoader: false
         };
      case dashboardConstants.TABLE_GET_LIST_DATAS:
         return {
            ...state,
            ...state.dashboardReport,
            tableFilter: action.tableFilter,
            tableDatas: (action.data ? action.data : []),
            tableTotal: (action.total ? action.total : 0)
         };
      case 'DASHBOARD_RESET_ACTIVE_USER':
         return {
            ...state,
            tableFilter: action.tableFilter
         };
      case dashboardConstants.DASHBOARD_TABLE_RESET_SUCCESS:
         return {
            ...state,
            dashboardReport: {
               ...state.dashboardReport,
               tableReset: false
            }
         };
      case dashboardConstants.DASHBOARD_CHANGE_TABLEKEY:
         return {
            ...state,
            tableKey: action.tableKey
         };
      case dashboardConstants.SHOW_LOADER_DAILY_REGISTRATION_DASHBOARD:
         return {
            ...state,
            showDialyRegistrationLoader: true
         };
      case dashboardConstants.HIDE_LOADER_DAILY_REGISTRATION_DASHBOARD:
         return {
            ...state,
            showDialyRegistrationLoader: false
         };
      case dashboardConstants.TABLE_GET_DAILY_REGISTRATION_LIST_DATAS:
         return {
            ...state,
            ...state.dashboardReport,
            tableDialyRegistrationFilter: action.tableDialyRegistrationFilter,
            tableDialyRegistrationDatas: (action.data ? action.data : []),
            tableDialyRegistrationTotal: (action.total ? action.total : 0)
         };
      case 'DASHBOARD_RESET_ACTIVE_DAILY_REGISTRATION':
         return {
            ...state,
            tableDialyRegistrationFilter: action.tableDialyRegistrationFilter
         };
      case dashboardConstants.SHOW_LOADER_DAILY_EARN_TRANSACTION_DASHBOARD:
         return {
            ...state,
            showDialyEarnTransactionLoader: true
         };
      case dashboardConstants.HIDE_LOADER_DAILY_EARN_TRANSACTION_DASHBOARD:
         return {
            ...state,
            showDialyEarnTransactionLoader: false
         };
      case dashboardConstants.TABLE_GET_DAILY_EARN_TRANSACTION_LIST_DATAS:
         return {
            ...state,
            ...state.dashboardReport,
            tableDialyEarnTransactionFilter: action.tableDialyEarnTransactionFilter,
            tableDialyEarnTransactionDatas: (action.data ? action.data : []),
            tableDialyEarnTransactionTotal: (action.total ? action.total : 0)
         };
      case 'DASHBOARD_RESET_ACTIVE_DAILY_EARN_TRANSACTION':
         return {
            ...state,
            tableDialyEarnTransactionFilter: action.tableDialyEarnTransactionFilter
         };
      case dashboardConstants.GET_KEYSUMMARY_SUCCESS:
         return {
            ...state,
            keySummary: action.data
         };  
      case dashboardConstants.GET_REGISTRATION_SUCCESS:
         return {
            ...state,
            registration: action.data
         };
      case dashboardConstants.GET_REGISTRATION_DAYOFWEEK_SUCCESS:
         return {
            ...state,
            registrationDayofweek: action.data
         }; 
      case dashboardConstants.GET_EARN_STATUS_SUCCESS:
         return {
            ...state,
            statistics: {
               ...state.statistics,
               earnStatus: action.data,
            }
         }; 
      case dashboardConstants.GET_BURN_STATUS_SUCCESS:
         return {
            ...state,
            statistics: {
               ...state.statistics,
               burnStatus: action.data,
            }
         }; 
      case dashboardConstants.GET_EARN_SKU_SUCCESS:
         let dataList = action.data.data;
         let lists = [];
         let i = true
         dataList.map((x) =>{
            var succ = 0;
            var unsucc = 0;
            lists.map((y, j) =>{
               if(x._id === y._id && x.sku === y.sku){
                  if(y.success === 0){
                     lists[j].success = x.count
                  }else{//y.unsuccess === 0
                     lists[j].unsuccess = x.count
                  }
                  i = false;
               }
            }) 
            if(i === true){ 
               if(x.remark === "SUCCESS"){
                  succ = x.count
               }else{
                  unsucc = x.count
               }
               lists.push({
                  _id: x._id,
                  sku: x.sku,
                  success: succ,
                  unsuccess: unsucc
               })  
            }  
            i = true;   
         })

         return {
            ...state,
            earnSku: {
               data: lists
            }
         }; 
      case dashboardConstants.GET_REGISTRATION_BY_HOUR_SUCCESS:
         return {
            ...state,
            registrationByHour: {
               data: action.data.data,
            }
         }; 
      case dashboardConstants.GET_REGISTRATION_BY_PROVINCE_SUCCESS:
         return {
            ...state,
            registrationByProvince: {
               data: action.data.data
            }
         }; 
      case dashboardConstants.GET_BURN_TRANSACTION_BY_CAMPAIGN_SUCCESS:
         return {
            ...state,
            burnTransactionByCampaign: {
               data: action.data.data
            }
         }; 
      case dashboardConstants.GET_INACTIVE_USER:
         return {
            ...state,
            tableInactiveUser: {
               loader: false,
               earn:lodash.sortBy(action.data.earn,"period"),
               burn:lodash.sortBy(action.data.burn,"period"),
               earn_burn:lodash.sortBy(action.data.earn_burn,"period"),
            }
         }; 
      case dashboardConstants.SHOW_LOADER_INACTIVE_USER:
         return {
            ...state,
            tableInactiveUser: {
               ...state.tableInactiveUser,
               loader: true
            }
         }; 
      case dashboardConstants.HIDE_LOADER_INACTIVE_USER:
         return {
            ...state,
            tableInactiveUser: {
               ...state.tableInactiveUser,
               loader: false
            }
         }; 
      case dashboardConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
         return {
            ...state,
            campaignList: action.data.campaigns
         };
      case dashboardConstants.SHOW_LOADER_DAILY_BURN_TRANSACTION_DASHBOARD:
         return {
            ...state,
            showDialyBurnTransactionLoader: true
         };
      case dashboardConstants.HIDE_LOADER_DAILY_BURN_TRANSACTION_DASHBOARD:
         return {
            ...state,
            showDialyBurnTransactionLoader: false
         };
      case dashboardConstants.TABLE_GET_DAILY_BURN_TRANSACTION_LIST_DATAS:
         return {
            ...state,
            ...state.dashboardReport,
            tableDialyBurnTransactionFilter: action.tableDialyBurnTransactionFilter,
            tableDialyBurnTransactionDatas: (action.data ? action.data : []),
            tableDialyBurnTransactionTotal: (action.total ? action.total : 0)
         };
      case 'DASHBOARD_RESET_ACTIVE_DAILY_BURN_TRANSACTION':
         return {
            ...state,
            tableDialyBurnTransactionFilter: action.tableDialyBurnTransactionFilter
         };
      case dashboardConstants.GET_BURN_TRANSACTION_BY_DAY_HOUR_SUCCESS:
         let datasBurn = action.data;
         datasBurn.sort(function(a, b) {
            var keyA = new Date(a.hour),
              keyB = new Date(b.hour);
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
         let newDatasBurn = [{
            "hour": '12 AM',
            "sunday": "-",
            "monday": "-",
            "tuesday": "-",
            "wednesday": "-",
            "thursday": "-",
            "friday": "-",
            "saturday": "-",
            "grandTotal": "-"
         }]
         let burnRow = {}
         for(let i=1; i<24; i++){
            if(i<12){ //1 - 11
               burnRow = { "hour": i+' AM' }
            }else{
               if(i>12){
                  burnRow = { "hour": (i-12)+' PM' }
               }else{
                  burnRow = { "hour": i+' PM' }
               }
            }
            burnRow = { ...burnRow,
               "sunday": "-",
               "monday": "-",
               "tuesday": "-",
               "wednesday": "-",
               "thursday": "-",
               "friday": "-",
               "saturday": "-",
               "grandTotal": "-"
            }
            newDatasBurn.push(burnRow)
         }

         datasBurn.map((x) => {
            if(x._id != null){
            let i = x.hour
            if(x.point != 0){
               if(x._id === 1){
                  newDatasBurn[i] = { ...newDatasBurn[i],
                     "sunday": x.point
                  }
               }else if(x._id === 2){
                  newDatasBurn[i] = { ...newDatasBurn[i],
                     "monday": x.point 
                  }
               }else if(x._id === 3){
                  newDatasBurn[i] = { ...newDatasBurn[i],
                     "tuesday": x.point 
                  }
               }else if(x._id === 4){
                  newDatasBurn[i] = { ...newDatasBurn[i],
                     "wednesday": x.point 
                  }
               }else if(x._id === 5){
                  newDatasBurn[i] = { ...newDatasBurn[i],
                     "thursday": x.point 
                  }
               }else if(x._id === 6){
                  newDatasBurn[i] = { ...newDatasBurn[i],
                     "friday": x.point 
                  }
               }else if(x._id === 7){
                  newDatasBurn[i] = { ...newDatasBurn[i],
                     "saturday": x.point 
                  }
               }
            }
            }
         })
         let grandTotalSundayBurn = 0
         let grandTotalMondayBurn = 0
         let grandTotalTuesdayBurn = 0
         let grandTotalWednesdayBurn = 0
         let grandTotalThursdayBurn = 0
         let grandTotalFridayBurn = 0
         let grandTotalSaturdayBurn = 0

         newDatasBurn.map((x, index) => {
            let sun = (x.sunday === '-' ? 0 : x.sunday)
            let mon = (x.monday === '-' ? 0 : x.monday)
            let tue = (x.tuesday === '-' ? 0 : x.tuesday)
            let wed = (x.wednesday === '-' ? 0 : x.wednesday)
            let thu = (x.thursday === '-' ? 0 : x.thursday)
            let fri = (x.friday === '-' ? 0 : x.friday)
            let sat = (x.saturday === '-' ? 0 : x.saturday)

            newDatasBurn[index] = { ...x,
               "grandTotal": sun + mon + tue + wed + thu + fri + sat
            }
            grandTotalSundayBurn = grandTotalSundayBurn + sun
            grandTotalMondayBurn = grandTotalMondayBurn + mon
            grandTotalTuesdayBurn = grandTotalTuesdayBurn + tue
            grandTotalWednesdayBurn = grandTotalWednesdayBurn + wed
            grandTotalThursdayBurn = grandTotalThursdayBurn + thu
            grandTotalFridayBurn = grandTotalFridayBurn + fri
            grandTotalSaturdayBurn = grandTotalSaturdayBurn + sat

         })
         let lateBurnRow = {
            "hour": "Grand Total",
            "sunday": grandTotalSundayBurn,
            "monday": grandTotalMondayBurn,
            "tuesday": grandTotalTuesdayBurn,
            "wednesday": grandTotalWednesdayBurn,
            "thursday": grandTotalThursdayBurn,
            "friday": grandTotalFridayBurn,
            "saturday": grandTotalSaturdayBurn,
            "grandTotal": grandTotalSundayBurn + grandTotalMondayBurn + grandTotalTuesdayBurn + grandTotalWednesdayBurn + grandTotalThursdayBurn + grandTotalFridayBurn + grandTotalSaturdayBurn
         }
         newDatasBurn.push(lateBurnRow)
         return {
            ...state,
            burnTransactionByDayHour: {
               data: newDatasBurn
            }
         };
      case dashboardConstants.GET_EARN_TRANSACTION_BY_DAY_HOUR_SUCCESS:
         let datasEarn = action.data;
         datasEarn.sort(function(a, b) {
            var keyA = new Date(a.hour),
              keyB = new Date(b.hour);
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
         let newDatasEarn = [{
            "hour": '12 AM',
            "sunday": "-",
            "monday": "-",
            "tuesday": "-",
            "wednesday": "-",
            "thursday": "-",
            "friday": "-",
            "saturday": "-",
            "grandTotal": "-"
         }]
         let earnRow = {}
         for(let i=1; i<24; i++){
            if(i<12){ //1 - 11
               earnRow = { "hour": i+' AM' }
            }else{
               if(i>12){
                  earnRow = { "hour": (i-12)+' PM' }
               }else{
                  earnRow = { "hour": i+' PM' }
               }
            }
            earnRow = { ...earnRow,
               "sunday": "-",
               "monday": "-",
               "tuesday": "-",
               "wednesday": "-",
               "thursday": "-",
               "friday": "-",
               "saturday": "-",
               "grandTotal": "-"
            }
            newDatasEarn.push(earnRow)
         }
         let k = 0
         datasEarn.map((x) => {
            if(x._id != null){
            let i = x.hour
            if(x.point != 0){
               if(x._id === 1){
                  newDatasEarn[i] = { ...newDatasEarn[i],
                     "sunday": x.point
                  }
               }else if(x._id === 2){
                  newDatasEarn[i] = { ...newDatasEarn[i],
                     "monday": x.point 
                  }
               }else if(x._id === 3){
                  newDatasEarn[i] = { ...newDatasEarn[i],
                     "tuesday": x.point 
                  }
               }else if(x._id === 4){
                  newDatasEarn[i] = { ...newDatasEarn[i],
                     "wednesday": x.point 
                  }
               }else if(x._id === 5){
                  newDatasEarn[i] = { ...newDatasEarn[i],
                     "thursday": x.point 
                  }
               }else if(x._id === 6){
                  newDatasEarn[i] = { ...newDatasEarn[i],
                     "friday": x.point 
                  }
               }else if(x._id === 7){
                  newDatasEarn[i] = { ...newDatasEarn[i],
                     "saturday": x.point 
                  }
               }
            }
            }
         })
         let grandTotalSunday = 0
         let grandTotalMonday = 0
         let grandTotalTuesday = 0
         let grandTotalWednesday = 0
         let grandTotalThursday = 0
         let grandTotalFriday = 0
         let grandTotalSaturday = 0

         newDatasEarn.map((x, index) => {
            let sun = (x.sunday === '-' ? 0 : x.sunday)
            let mon = (x.monday === '-' ? 0 : x.monday)
            let tue = (x.tuesday === '-' ? 0 : x.tuesday)
            let wed = (x.wednesday === '-' ? 0 : x.wednesday)
            let thu = (x.thursday === '-' ? 0 : x.thursday)
            let fri = (x.friday === '-' ? 0 : x.friday)
            let sat = (x.saturday === '-' ? 0 : x.saturday)

            newDatasEarn[index] = { ...x,
               "grandTotal": sun + mon + tue + wed + thu + fri + sat
            }
            grandTotalSunday = grandTotalSunday + sun
            grandTotalMonday = grandTotalMonday + mon
            grandTotalTuesday = grandTotalTuesday + tue
            grandTotalWednesday = grandTotalWednesday + wed
            grandTotalThursday = grandTotalThursday + thu
            grandTotalFriday = grandTotalFriday + fri
            grandTotalSaturday = grandTotalSaturday + sat

         })
         let lateEarnRow = {
            "hour": "Grand Total",
            "sunday": grandTotalSunday,
            "monday": grandTotalMonday,
            "tuesday": grandTotalTuesday,
            "wednesday": grandTotalWednesday,
            "thursday": grandTotalThursday,
            "friday": grandTotalFriday,
            "saturday": grandTotalSaturday,
            "grandTotal": grandTotalSunday + grandTotalMonday + grandTotalTuesday + grandTotalWednesday + grandTotalThursday + grandTotalFriday + grandTotalSaturday
         }
         newDatasEarn.push(lateEarnRow)
         return {
            ...state,
            earnTransactionByDayHour: {
               data: newDatasEarn
            }
         };
      default:
         return state
   }
}
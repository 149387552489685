
import React from "react";
import Button from "components/CustomButtons/Button.jsx";

import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PersonAdd from '@material-ui/icons/PersonAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

import Update from "@material-ui/icons/Update";

const style = {
  actionButton: {
    padding: "10px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    textAlign: "center",
    "&:active": {
      backgroundColor: "#ddd"
    }
  }
};

function TableActionButton({ ...props }) {
  const {
    id,
    classes,
    children,
    disabled,
    size,
    className,
    actionType,
    iconProps,
    tooltip,
    customBtnStyle,
    ...rest
  } = props;

  const getBtnColor = (actionType) => {
    switch (actionType) {
      case "edit": return "info";
      case "delete": return "danger";
      case "info": return "info";
      case "upload": return "warning";
      case "tagImport": return "github";
      case "history": return "info";
      case "rerun": return "info";
      default: return "success"
    }
  }

  const getBtnIcon = (actionType) => {
    switch (actionType) {
      case "edit": {
        return (<Edit {...iconProps} />)
      }
      case "delete": {
        return (<Close {...iconProps} />)
      }
      case "info": {
        return (<Search {...iconProps} />)
      }
      case "upload": {
        return (<AttachFileIcon {...iconProps} />)
      }
      case "tagImport": {
        return (<PersonAdd {...iconProps} />)
      }
      case "history": {
        return (<DescriptionIcon {...iconProps} />)
      }
      case "rerun": {
        return (<Update {...iconProps} />)
      }
      case "key":
      case "reset_password": return (<VpnKeyIcon {...iconProps} />)
      default: return ''
    }
  }

  const getBtnTooltipText = (actionType) => {
    if (!tooltip) {
      switch (actionType) {
        case "edit": {
          return "Edit"
        } case "delete": {
          return "Delete"
        } case "info": {
          return "View"
        } case "upload": {
          return "Upload"
        } case "tagImport": {
          return "Import"
        } case "reset_password": {
          return "Reset password"
        } case "rerun": {
          return "Re-run"
        } default:
          return actionType
      }
    } else {
      return tooltip;
    }
  }

  let btnColor = getBtnColor(actionType);
  let btnIcon = getBtnIcon(actionType);
  let tooltipText = getBtnTooltipText(actionType);

  return (
    <Tooltip title={tooltipText} placement="bottom">
      <Button
        id={id}
        color={btnColor}
        simple
        disabled={disabled}
        className={classes.actionButton}
        style={customBtnStyle}
        {...rest}>
        {btnIcon}
      </Button>
    </Tooltip>
  )
}

TableActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  actionType: PropTypes.oneOf(["info", "edit", "delete", "upload", "tagImport", "reset_password", "rerun"]),
  disabled: PropTypes.bool
}

export default withStyles(style)(TableActionButton);
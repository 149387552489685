
import { rfmConfigServices } from 'services';
import { loader, alert as customAlert } from '../helpers';
import { rfmConfigConstants } from '../constants';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const rfmConfigActions = {
    getTableTemplateList,
    getTableConfig,
    saveRfmConfig,
    editRfmConfig,
    // searchTableTemplateList,

    // checkTableConfig
}


function getTableTemplateList(queryStringParams) {
    return dispatch => {
        rfmConfigServices.getTableTemplateList(queryStringParams)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: rfmConfigConstants.GET_TABLE_TEMPLATE_LIST, data } }
}

function getTableConfig() {
    return dispatch => {
        loader(dispatch, true)
        rfmConfigServices.getTableConfig()
            .then(
                response => {
                    console.log("ac response  : ", response);
                    if (response.status) {
                        if (response.data != "Data Not Found") {
                            dispatch(success(response.data))
                        }
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: rfmConfigConstants.GET_RFM_CONFIG_LIST, data } }
}

// function checkTableConfig(queryStringParams) {
//     return dispatch => {
//         loader(dispatch, true)
//         rfmConfigServices.getTableConfig(queryStringParams)
//             .then(
//                 response => {
//                     if (response.status) {
//                         dispatch(success(response.data))
//                     }
//                 },
//                 error => {
//                     return [];
//                 }
//             ).then(() => {
//                 loader(dispatch, false)
//             })
//     };
//     function success(data) { return { type: rfmConfigConstants.CHECK_TABLE_TEMPLATE_CONFIG, data } }
// }

// function searchTableTemplateList(queryStringParams) {
//     return dispatch => {
//         loader(dispatch, true)
//         rfmConfigServices.getTableTemplateList(queryStringParams)
//             .then(
//                 response => {
//                     if (response.status) {
//                         if (response.data != "Data Not Found") {
//                             dispatch(success(response.data))
//                         }
//                     }
//                 },
//                 error => {
//                     return [];
//                 }
//             ).then(() => {
//                 loader(dispatch, false)
//             })
//     };
//     function success(data) { return { type: rfmConfigConstants.SEARCH_FIELD_TEMPLATE_LIST, data } }
// }

function saveRfmConfig(params) {
    // let data = []
    // data = {
    //     tables: params
    // }
    // console.log("saveRfmConfig params : ", params);
    return dispatch => {
        loader(dispatch, true)
        rfmConfigServices.saveRfmConfig(params)
            .then(
                response => {
                    // console.log("saveRfmConfig response : ", response);
                    if (response.status) {
                        dispatch({
                            type: rfmConfigConstants.ADD_RFM_CONFIG_SUCCESS, data: response.data
                        });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.rfm_config_was_created'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function editRfmConfig(params) {
    return dispatch => {
        loader(dispatch, true)
        rfmConfigServices.editRfmConfig(params)
            .then(
                response => {
                    // console.log("saveRfmConfig response : ", response);
                    if (response.status) {
                        dispatch({
                            type: rfmConfigConstants.EDIT_RFM_CONFIG_SUCCESS, data: response.data
                        });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.rfm_config_was_created'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'
import moment from 'moment';

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormLabel from "@material-ui/core/FormLabel";
import Close from "@material-ui/icons/Close";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { dataFilteringResultConstants } from '../../../constants';
import { dataFilteringResultAction, memberActions } from '../../../actions';

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import i18n from 'i18n';


const FORM_NAME = 'FORM_DATAFILTERING_UNASSIGN';

class FormDataFilteringUnAssign extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            resultId: "",
            conditionId: ""
        }

        this.unAssignCampaign = this.unAssignCampaign.bind(this);
        this.unAssignTag = this.unAssignTag.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props
        this.getCampaignList()
        dispatch(memberActions.getActiveTagList())
    }


    getCampaignList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"

        dispatch(dataFilteringResultAction.getCampaignList(reqParams))
    }

    renderAssignCampaign = () => {
        const { classes, campaignList, assignCampaign } = this.props
        let resultCampaign = [];

        console.log('assignCampaign.length :',assignCampaign.length);

        if(assignCampaign.length > 0){
            assignCampaign.forEach(savecampaign => {
                for (let i = 0; i < campaignList.length; i++) {
                    if(savecampaign.campaignId === campaignList[i]._id){
                        let dataCampaign = {
                            _id:campaignList[i]._id,
                            name:campaignList[i].name,
                            assignType:savecampaign.assignType
                        }
                        resultCampaign.push(dataCampaign)
                    }
                }
            })
        }

        console.log('resultCampaign :',resultCampaign);

        if(resultCampaign.length > 0){
            return (
                <>

                    <GridItem xs={12} sm={12} md={12}></GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h6><b>Campaign</b></h6>
                    </GridItem>

                    <Table key="table_unassign_campaign">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead}>
                                    Campaign Name
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Assign Type
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Unassign
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {resultCampaign.map(campaign => 
                                <TableRow key={campaign._id}>
                                    <TableCell
                                        align="left"
                                        style={{
                                            paddingRight: "1px"
                                        }}
                                        width="50%"
                                    >
                                        {campaign.name}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        style={{
                                            paddingRight: "1px"
                                        }}
                                        width="20%"
                                    >
                                        {campaign.assignType}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                    >
                                        <Button
                                            id={`unassign-campaign-btn-${campaign._id}`}
                                            color={"danger"}
                                            simple
                                            className={classes.actionButton}
                                            onClick={() => {
                                                this.unAssignCampaign(campaign._id,campaign.assignType)
                                            }}
                                            >
                                            <Close className={classes.Close} />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}  
                        </TableBody>
                    </Table>
                </>
            )
        }else{
            return (
                <></>
            )
        }
        
    }

    unAssignCampaign = (campaignId,assignType) => {
        const { dispatch, formInitialValue } = this.props;
        var reqParams = {
            "campaignId": campaignId,
            "conditionId": formInitialValue.conditionId,
            "resultId": formInitialValue.resultId,
            "assignType": assignType
        }
        dispatch(dataFilteringResultAction.unAssignCampaign(reqParams));
    }

    renderAssignTag = () => {
        const { classes, formTaglist, assignTag } = this.props

        let resultTag = [];
        if(assignTag.length > 0){
            assignTag.forEach(savetag => {
                for (let i = 0; i < formTaglist.length; i++) {
                    if(savetag.campaignId === formTaglist[i]._id){
                        let dataTag = {
                            _id:formTaglist[i]._id,
                            name:formTaglist[i].name,
                        }
                        resultTag.push(dataTag)
                    }
                }
            })
        }

        if(resultTag.length > 0){
            return (
                <>

                    <GridItem xs={12} sm={12} md={12}></GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <h6><b>Tag</b></h6>
                    </GridItem>

                    <Table key="table_unassign_tag">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead}>
                                    Tag Name
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                    Unassign
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resultTag.map(tag => 
                                <TableRow key={tag._id}>
                                    <TableCell
                                        align="left"
                                        style={{
                                            paddingRight: "1px"
                                        }}
                                        width="70%"
                                    >
                                        {tag.name}
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                    >
                                        <Button
                                            color={"danger"}
                                            simple
                                            className={classes.actionButton}
                                            onClick={() => {
                                                this.unAssignTag(tag._id)
                                            }}>
                                            <Close className={classes.Close} />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}  
                        </TableBody>
                    </Table>
                </>
            )
        }else{
            return (
                <></>
            )
        }
        
    }

    unAssignTag = (tagId) => {
        const { dispatch,formInitialValue } = this.props
        var reqParams = {
            "tagLists": tagId,
            "conditionId": formInitialValue.conditionId,
            "resultId": formInitialValue.resultId
        }
        dispatch(dataFilteringResultAction.unAssignTag(reqParams));
    }

    render() {
        const { classes, formUnAssignOpen } = this.props

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formUnAssignOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>Assign Campaign</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            {/* <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="resultId"
                                    component="input"
                                    type="text"
                                />

                                <Field
                                    name="conditionId"
                                    component="input"
                                    type="text"
                                />
                            </GridItem> */}

                            {this.renderAssignCampaign()}

                            {this.renderAssignTag()}

                        </GridContainer>

                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: dataFilteringResultConstants.CLOSE_FORM_UNASSIGN })
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formUnAssignOpen, campaignList, assignTag, assignCampaign, formInitialValue } = state.dataFilteringResult;
    const { formTaglist } = state.member;
    const { FORM_DATAFILTERING_TAG } = state.form
    return {
        formUnAssignOpen, FORM_DATAFILTERING_TAG, formTaglist, campaignList, assignTag, assignCampaign, formInitialValue
    };
}

const connectedComponent = connect(mapStateToProps)(FormDataFilteringUnAssign);

export default reduxForm({
    form: FORM_NAME,
    // validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))

import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import lodash from 'lodash';
import moment from 'moment'

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from 'components/CustomButtons/Button'
import { dashboardServices } from "services";


import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// import Badge from "components/Badge/Badge.jsx";

import { plugins } from 'helpers';



const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                textAlign: "center",
                paddingRight: '24px',
                // "&:nth-child(3)": {
                //     textAlign: "center",
                //     paddingRight: '24px',
                // }
            },
            body: {
                // "&:nth-child(22)": {
                    textAlign: "center",
                    paddingRight: '24px',
                // }
            },
        },
    }
});

class TableInactiveUser extends React.Component {
    
    componentDidMount(){
    }



    getDownloadFile(id) {
        dashboardServices.downloadInactiveUser(id)
          .then(
            tmp => {
              if (lodash.get(tmp, 'status') === true) {
                window.location.assign(tmp.link)
              }
            }
          )
    
      }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        var data = tableDatas;
        return data[rowIndex];
    }

    render() {
        const { tableDatas=[], loader=true, tableTitle="", visiblePersonalData } = this.props;
        let columns = [
            { name: "period", label: "Period", options: {
                customBodyRender:(value)=>{return `${value} Days`}
            }
            },
            { name: "count", label: "Users", },
            { name: "fileName", label: "Export", options: {
                display: (plugins.isHasPermission("dashboard", 'export') && plugins.isHasPackageFeatures('export') && visiblePersonalData),
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowData = this.getRowData(tableMeta.rowIndex)
                    return (
                        <>
                            <Button
                                id = "btn_export_csv"
                                variant="contained"
                                bcolor="primary"
                                size="sm"
                                onClick={(e) => { 
                                    this.getDownloadFile(rowData._id)
                                }}
                                style={{ backgroundColor: "#84B0F3" }}
                                disabled={lodash.get(rowData, '_id') ? false : true}
                            >
                                Export
                            </Button>

                        </>
                    );

                }
            } },
    
        ]

        let options = {
            serverSide: false,
            rowsPerPage: 10,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 31],
            search: false,
            viewColumns: false,
            sort: false,
            paging: false,
            pagination:false,
        };

        return (
            <div id="tableInactiveUsers">
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={1}
                        title={tableTitle}
                        data={tableDatas}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
   
    const { visiblePersonalData } = state.authentication;
    return { visiblePersonalData };
}
export default connect(mapStateToProps)(withStyles(pageStyle)(TableInactiveUser));
import React from 'react';
import { connect } from 'react-redux';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { shipmentConstants } from '../../constants'

const FORM_NAME = 'FORM_IMPORT_FILE_RESULT'

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(11)": {
                    textAlign: "right"
                }
            },
            body: {
                "&:nth-child(22)": {
                    textAlign: "right"
                }
            }
        }
    }
});

class FormImportFileResult extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    closeForm() {
        const { dispatch } = this.props;
        dispatch({ type: shipmentConstants.HIDE_EARN_RULE_IMPORT_PRODUCT_RESULT });
    }

    render() {
        const { classes, showImportResult, importResult } = this.props;

        console.log('importResult :',importResult)

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={showImportResult}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="xs"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{ position: "relative" }}
                >
                    <h4 className={classes.modalTitle}><b>Import Shipment Result</b></h4>
                    <IconButton aria-label="close" id="btn_close_form_import" className={classes.closeButton} onClick={this.closeForm.bind(this)} style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px"
                    }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <h6>Total : {importResult.total}</h6>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <h6>Success : {importResult.pass}</h6>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <h6>Fail : <span style={{ textTransform: "capitalize", color: "red", fontWeight: 900 }}>{importResult.notPass}</span></h6>
                        </GridItem>
                    </GridContainer>
                </DialogContent >
            </Dialog >
        )
    }
}

function mapStateToProps(state) {
    const { showImportResult, importResult } = state.shipment
    return {
        showImportResult, importResult
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(FormImportFileResult));

import { reset } from 'redux-form'
import lodash from 'lodash';

import { luckydrawRewardServices } from '../services';
import { luckydrawRewardConstants } from '../constants';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const LuckydrawRewardActions = {
    getListsLuckydrawReward,
    addLuckydrawReward,
    editLuckydrawReward,
    deleteLuckydrawReward
};


function getListsLuckydrawReward(params, tableReset = false) {
   return dispatch => {
      dispatch({ type: luckydrawRewardConstants.SHOW_LOADER })
      luckydrawRewardServices.getListsLuckydrawReward(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ 
                        type: luckydrawRewardConstants.GET_LUCKYDRAW_REWARD_SUCCESS, 
                        data: response.data.rewards, 
                        total: response.data.total, 
                        tableFilter: params 
                    });
                 
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: luckydrawRewardConstants.HIDE_LOADER })
         })
   };
}
function addLuckydrawReward(params) {
    return dispatch => {
       loader(dispatch, true)
       luckydrawRewardServices.addNewLuckydrawReward(params)
          .then(
             response => {
                if (response.status) {
                   dispatch({
                      type: luckydrawRewardConstants.ADD_NEW_LUCKYDRAW_REWARD_SUCCESS
                   });
                   customAlert(dispatch, 'success', i18n.t('warning_messages.luckydraw_reward_was_created'));
                } else {
                   if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                }
             },
             error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
             }
          ).then(() => {
             loader(dispatch, false)
          })
    };
 }

 function editLuckydrawReward(params) {
    return dispatch => {
       loader(dispatch, true)
 
       luckydrawRewardServices.editLuckydrawReward(params)
          .then(
             response => {
                if (response.status) {
                   dispatch(success(response.data));
                   customAlert(dispatch, 'success', i18n.t('warning_messages.luckydraw_reward_was_updated'));
                } else {
                   if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                }
             },
             error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
             }
          ).then(() => {
             loader(dispatch, false)
          })
    };
    function success(tmp) { return { type: luckydrawRewardConstants.EDIT_LUCKYDRAW_REWARD_SUCCESS, tmp } }
 }

 
 function deleteLuckydrawReward(params) {
    return dispatch => {
       loader(dispatch, true)
       luckydrawRewardServices.deleteLuckydrawReward(params)
       .then(
          (response) => {
             if (response.status) {
                dispatch({ type: luckydrawRewardConstants.DELETE_LUCKYDRAW_REWARD_SUCCESS })
                customAlert(
                      dispatch,
                      "success",
                      i18n.t("warning_messages.luckydraw_reward_was_deleted")
                )
             } else {
                if (!response.unAlert)
                      customAlert(
                         dispatch,
                         "error",
                         i18n.t(`api_messages.${response.message.toString()}`)
                      )
             }
          },
          (error) => {
              customAlert(dispatch, "error", i18n.t(defaultFailedText))
          }
       )
       .then(() => {
          loader(dispatch, false)
       })
 
    };
}
 
const customMTableStyle = {
   typography: {
      useNextVariants: true,
   },
   palette: {
      primary: {
         main: '#f4b340',
      },
      secondary: {
         main: '#f4b340',
      },
   },
   actionDiv: {
      display: "inline-flex"
   },
   overrides: {
      MuiInput: {
         underline: {
            "&:after": {
               borderBottom: "2px solid #f4b340"
            }
         }
      },
      MUIDataTableHeadRow: {
         root: {
           whiteSpace: 'nowrap'
         }
      },
      MuiTableCell: {
         body: {
            color: "#666",
         },
         root: {
            padding: "4px 24px 4px 24px"
         }
      },
      MuiToolbar: {
         regular: {
            paddingLeft: "6px"
         }
      },
      MuiTableRow: {
         root: {
            "&:hover": {
               backgroundColor: "#eee"
            }
         },
      },
      MuiDialog: {
         paper: {
            overflowY: "visible"
         }
      },
      MuiPaper: {
         elevation4: {
            boxShadow: "none"
         }
      },
      MUIDataTable: {
         responsiveScroll: {
            maxHeight: "none"
         }
      },
      MUIDataTableBody: {
         emptyTitle: {
            color: "#aaa",
            fontStyle: 'italic'
         }
      }
   }
}

export default customMTableStyle;

import React from "react";
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import lodash from 'lodash';
import moment from 'moment'

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import TablePointExpiration from "./TablePointExpiration.jsx";
import { expirationActions } from 'actions';
import { crmValidator } from 'helpers';
import { expirationConstants } from '../../constants';

const style = {

}

const FORM_NAME = 'FORM_FILTER_POINT_EXPIRATION';

const form_validate = {
    phoneNumber: {
      rules: {
        number: true
      },
      messages: {
        required: "กรุณากรอก"
      }
    }
};

class PointExpirationReport extends React.Component {

    handleSubmit(values) {
        const { dispatch, FORM_FILTER_POINT_EXPIRATION, tableFilter } = this.props;
        let filter = "page=0&skip=0&limit="+tableFilter.limit+"&sortBy=lastExpirationDate&sortDir=desc";
        let urlParams = ''
        if(lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'startDate')){
            urlParams += '&startDate=' + moment(lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'startDate')).format('YYYY-MM-DD');
        }
        if(lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'endDate')){
            urlParams += '&endDate=' + moment(lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'endDate')).format('YYYY-MM-DD');
        }
        if(lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'firstName')){
            urlParams += '&searchFirstName=' + lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'firstName');
        }
        if(lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'lastName')){
            urlParams += '&searchLastName=' + lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'lastName');
        }
        if(lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'phoneNumber')){
            urlParams += '&searchPhoneNumber=' + lodash.get(FORM_FILTER_POINT_EXPIRATION.values,'phoneNumber');
        }
        dispatch({ type: expirationConstants.SET_FILTER_SEARCH, search: urlParams })
        dispatch(expirationActions.getTableExpirationDatas(filter+urlParams));
    }

    clearFilter = () => {
        const { dispatch, tableFilter } = this.props;
        dispatch({ type: "CLEAR_EXPIRATION_FILTER" })
        dispatch(reset(FORM_NAME))
        dispatch(expirationActions.getTableExpirationDatas(tableFilter));
    }

    componentDidMount() {
        const { tableFilter, dispatch } = this.props;
        dispatch(expirationActions.getTableExpirationDatas(tableFilter));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: "CLEAR_EXPIRATION_FILTER" })
        dispatch(reset(FORM_NAME))


    }

    render() {
        const { FORM_FILTER_POINT_EXPIRATION, handleSubmit } = this.props;
        return (
            <div>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="start-date-input"
                                name="startDate"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Start Date"
                                dateFormat="DD-MMM-YY"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="end-date-input"
                                name="endDate"
                                component={CustomDatePicker}
                                type="text"
                                labelText="End Date"
                                dateFormat="DD-MMM-YY"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="first-name-input"
                                name="firstName"
                                component={CustomInput}
                                type="text"
                                labelText="Firstname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="last-name-input"
                                name="lastName"
                                component={CustomInput}
                                type="text"
                                labelText="Lastname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="phone-number-input"
                                name="phoneNumber"
                                component={CustomInput}
                                type="text"
                                labelText="Mobile Number"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, form_validate.phoneNumber)
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>

                            <Button
                                id="clear-btn"
                                onClick={this.clearFilter}
                                style={{
                                    marginTop: "25px"
                                }}
                                size="sm"
                                color="white">
                                Clear
                            </Button>
                            <Button
                                id="submit-btn"
                                type="submit"
                                style={{
                                    marginRight: "10px", marginTop: "25px"
                                }}
                                size="sm"
                                color="primary">
                                Submit
                            </Button>


                        </GridItem>
                    </GridContainer>
                </form>

                <TablePointExpiration />

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { FORM_FILTER_POINT_EXPIRATION } = state.form;
   const { tableFilter } = state.expiration

    const { merchantSetting } = state.authentication;

    return {
        FORM_FILTER_POINT_EXPIRATION, merchantSetting, tableFilter
    }
}

const connectedComponent = connect(mapStateToProps)(PointExpirationReport);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent))
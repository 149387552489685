import { bannerConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";

const initialState = {
   formOpen: false,
   formMode: 'add',
   formInitialValue: {},
   tableReset: false,
   tableReload: false,
   bannerImagePreview: defaultImage,
   tableDatas: {
      roles: [],
      total: 0
   },
   tableKey: 0,
   showLoader: false
};

export function banner(state = initialState, action) {
   switch (action.type) {
      case bannerConstants.CLEAR_PAGE_STATE:
         return initialState;
         
      case bannerConstants.OPEN_FORM_ADD:
         return {
            ...state,
            formOpen: true,
            formMode: 'add',
            formInitialValue: action.formInitialValue,
            bannerImagePreview: defaultImage
         };
      case bannerConstants.OPEN_FORM_EDIT:
         return {
            ...state,
            formOpen: true,
            formMode: 'edit',
            formInitialValue: action.data,
            bannerImagePreview: action.bannerImagePreview
         };
      case bannerConstants.CLOSE_FORM_BANNER:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {
               bannerImage: ""
            },
            bannerImagePreview: defaultImage
         }
      case bannerConstants.ADD_NEW_BANNER_SUCCESS:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            tableReload: false,
            tableReset: true,
            tableKey: new Date(),
         }
      case bannerConstants.EDIT_BANNER_SUCCESS:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            tableReset: false,
            tableReload: true
         }
      case bannerConstants.TABLE_GET_LIST_DATAS:
         return {
            ...state,
            tableDatas: action.tmp,
            tableReload: false,
            tableReset: false,
         }
      case bannerConstants.TABLE_RELOAD_SUCCESS:
      case bannerConstants.TABLE_RESET_SUCCESS:
         return {
            ...state,
            tableReload: false,
            tableReset: false,
         }
      case bannerConstants.DELETE_BANNER_SUCCESS:
         return {
            ...state,
            tableReload: true
         }
      case bannerConstants.SET_FORM_PREVIEW_IMAGE:
         return {
            ...state,
            bannerImagePreview: action.bannerImagePreview === "default"? defaultImage : action.bannerImagePreview
         }
      case bannerConstants.GET_BANNER_LIST_REQUEST:
         return {
            ...state,
            showLoader: true
         }
      case bannerConstants.GET_BANNER_LIST_SUCCESS:
         return {
            ...state,
            showLoader: false
         }
      default:
         return state
   }
}

import { upload_3rd_partyConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";

const initialState = {
    formOpen: false,
    formUpload: false,
    formMode: 'add',
    formInitialValue: {},
    tableReset: false,
    tableReload: false,
    ImagePreview: defaultImage,
    campaignData: [],
    merchantLists: [],
    tableDatas: {
        data: [],
        total: 0
    },
    tableKey: 0,
    tableLoader: false
};

export function upload_3rd_party(state = initialState, action) {
    switch (action.type) {
        case upload_3rd_partyConstants.CLEAR_PAGE_STATE:
            return initialState;

        case upload_3rd_partyConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.data,
                ImagePreview: defaultImage
            };
        case upload_3rd_partyConstants.OPEN_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                ImagePreview: action.ImagePreview
            };
        case upload_3rd_partyConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                    uploadImage: ""
                },
                ImagePreview: defaultImage
            }
        case upload_3rd_partyConstants.OPEN_FORM_UPLOAD:
            return {
                ...state,
                formUpload: true,
                formMode: 'add',
                formInitialValue: action.data,
            };
        case upload_3rd_partyConstants.CLOSE_FORM_UPLOAD:
            return {
                ...state,
                formUpload: false,
                formInitialValue: {},
            }
        case upload_3rd_partyConstants.IMPORT_CODE_SUCCESS:
            return {
                ...state,
                formUpload: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case upload_3rd_partyConstants.ADD_NEW_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case upload_3rd_partyConstants.EDIT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case upload_3rd_partyConstants.TABLE_GET_LIST:
            return {
                ...state,
                tableDatas: action.tmp,
                tableReload: false,
                tableReset: false,
            }
        case upload_3rd_partyConstants.TABLE_RELOAD_SUCCESS:
        case upload_3rd_partyConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case upload_3rd_partyConstants.DELETE_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case upload_3rd_partyConstants.SET_FORM_PREVIEW_IMAGE:
            return {
                ...state,
                ImagePreview: action.ImagePreview === "default" ? defaultImage : action.ImagePreview
            }
        case upload_3rd_partyConstants.GET_DATA_SUCCESS:
            return {
                ...state,
                campaignData: action.data
            }
        case upload_3rd_partyConstants.GET_LIST_MERCHANT:
            return {
                ...state,
                merchantLists: action.tmp.merchants,
            }
        case upload_3rd_partyConstants.SHOW_TABLE_LOADER:
            return {
                ...state,
                tableLoader: true
            }
        case upload_3rd_partyConstants.HIDE_TABLE_LOADER:
            return {
                ...state,
                tableLoader: false
            }
        default:
            return state
    }
}
import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import FormTreeMap from "views/TreeMap/FormTreeMap.jsx";

// import TreeMap from "react-d3-treemap";
// Include its styles in you build process as well
// import "react-d3-treemap/dist/react.d3.treemap.css";

import Chart from "react-apexcharts";


import { treemapActions } from 'actions';

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

class TreeMapList extends React.Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
        this.state = {
            data: {},
        };
        
    }

    getTreemapData = () => {
        const { dispatch } = this.props;
        let reqParams = "page=0&skip=0&limit=0&sortBy=created_at&sortDir=desc&keyword="
        dispatch(treemapActions.getTreeMap(reqParams))
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
           this.getTreemapData();
        }
    }

    componentDidMount() {
        this.getTreemapData();
    }
    
    render(){
        const { treemapList } = this.props;
    

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            
                            <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>TreeMap</h4>
                            <GridContainer>
                                <GridItem xs={12}>
                                    { treemapList ? <Chart
                                        options={treemapList.options}
                                        series={treemapList.series}
                                        type={treemapList.chart.type}
                                        width={treemapList.chart.width}
                                    />
                                    : <></>}
                                    <FormTreeMap />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

function mapStateToProps(state) {
    const { tableReset, treemapList, tableReload } = state.treemap;

    return {
        tableReset, treemapList, tableReload
    };
}
const connectTreeMapContainer = connect(mapStateToProps)(TreeMapList);

export default withStyles(style)(connectTreeMapContainer);
import React from "react"
import { connect } from "react-redux"
import { Field, change, formValueSelector } from "redux-form"
import { crmValidator } from "helpers"
import lodash from "lodash"
import withStyles from "@material-ui/core/styles/withStyles"

import CustomInput from "components/CustomInput/CustomInput.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx"
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx"
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx"
import ShopSelections from './ShopSelections.jsx';

import * as FormValidator from "../EarnRules/validate/FormValidator"
import { memberActions } from 'actions';

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  tableHead: {
    backgroundColor: "#eee",
    position: "sticky",
    top: 0,
    padding: "0px 10px",
    zIndex: 10,
  },
  tableCell: {
    padding: "0px 10px",
  },
  tableCellLoader: {
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  tableHover: {
    transitionDuration: "0.3s",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#efefef",
    },
  },
  tableSelectShops: {
    maxHeight: "300px",
    minHeight: "300px",
    overflow: "auto",
    marginBottom: "10px",
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },
  noPaddingTop: {
    "& > div": {
      paddingTop: "0px",
    },
  },
  textUpper: {
    textTransform: "uppercase",
  },
  haveNoData: {
    color: "#777",
    fontStyle: "italic",
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  tableError: {
    border: "solid 2px red",
  },
})

class EarnRuleTypePurchaseAmount extends React.Component {
  constructor(props){
    super(props);
  }
  componentDidMount() {
    const { dispatch, formMode, regionSelected } = this.props
    dispatch(memberActions.getActiveMemberLevelList())
    if(formMode === "edit"){
    }
  }

  render() {
    const {
      readOnly,
      formMode,
      formMemberLevellist,
      FORM_EARNRULES,
    } = this.props
    const validate_field = {
      numberOnly: {
        rules: {
          required: true,
          number: true,
          noSpecialCharacters: true,
        },
      },
      date: {
        rules: {
          required: true,
        },
      },
    }

    return (
      <div
        style={{
          border: "dashed 1px #ccc",
          padding: "0px 20px",
          color: "black",
        }}
      >
        <br />

        <br />

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ color: "#3c4858" }}>
                <b>Purchase Amount</b>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <Field
                  id="radio-purshase-amount"
                  name="customRadioPurshaseAmount"
                  notLabel={true}
                  component={CustomRadio}
                  disabled={readOnly}
                  radioLists={[
                    {
                      value: "spendingEvery",
                      label: (
                        <span style={{ fontWeight: "300" }}>
                          Spending Every
                        </span>
                      ),
                      id: "radio-purshase-amount-spending-every",
                    },
                  ]}
                />
              </GridItem>
              <GridContainer
                style={{
                  marginTop: "-65px",
                  marginLeft: "165px",
                  marginRight: "-150px",
                  width: "460px",
                }}
              >
                <GridItem xs={4} sm={4} md={4} style={{ marginLeft: "-15px" }}>
                  <Field
                    id="spending-every-input"
                    name="spendingEveryInput"
                    component={CustomInput}
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    disabled={
                      readOnly ||
                      !(
                        lodash.get(
                          FORM_EARNRULES.values,
                          "customRadioPurshaseAmount"
                        ) === "spendingEvery"
                      )
                    }
                    normalize={(value) => {
                      return crmValidator.handleKeyPress(
                        value,
                        validate_field.numberOnly
                      )
                    }}
                    validate={
                      lodash.get(
                        FORM_EARNRULES.values,
                        "customRadioPurshaseAmount"
                      ) === "spendingEvery"
                        ? [FormValidator.validateIsZeroMount]
                        : null
                    }
                  />
                </GridItem>
                <GridItem
                  xs={3}
                  sm={3}
                  md={3}
                  style={{ marginTop: "30px", marginLeft: "-15px" }}
                >
                  will get
                </GridItem>
                <GridItem xs={3} sm={3} md={3} style={{ marginLeft: "-50px" }}>
                  <Field
                    id="spending-every-will-get-input"
                    name="willGetInput"
                    component={CustomInput}
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    disabled={
                      readOnly ||
                      !(
                        lodash.get(
                          FORM_EARNRULES.values,
                          "customRadioPurshaseAmount"
                        ) === "spendingEvery"
                      )
                    }
                    normalize={(value) => {
                      return crmValidator.handleKeyPress(
                        value,
                        validate_field.numberOnly
                      )
                    }}
                    validate={
                      lodash.get(
                        FORM_EARNRULES.values,
                        "customRadioPurshaseAmount"
                      ) === "spendingEvery"
                        ? [FormValidator.validateIsZero]
                        : null
                    }
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} style={{ marginTop: "30px" }}>
                  Point <span style={{ fontSize: "17px" }}>▾</span>
                </GridItem>
              </GridContainer>
            </GridContainer>
            {lodash.get(FORM_EARNRULES.values, "customRadioPurshaseAmount") ===
            "spendingEvery" ? (
              <div style={{ marginLeft: "25px" }}>
                <GridContainer style={{ marginTop: "-15px" }}>
                  <GridItem xs={1} sm={1} md={1}>
                    <Field
                      id="checkbox-minimum-spending"
                      name="customCheckboxMinimumSpending"
                      notLabel={true}
                      component={CustomCheckbox}
                      disabled={readOnly}
                    />
                  </GridItem>
                  <GridItem
                    xs={11}
                    sm={11}
                    md={11}
                    style={{ marginTop: "31px" }}
                  >
                    Minimum Spending Amount for Calculate Point{" "}
                    <span style={{ fontSize: "17px" }}>▾</span>
                  </GridItem>
                </GridContainer>
                {lodash.get(
                  FORM_EARNRULES.values,
                  "customCheckboxMinimumSpending"
                ) ? (
                  <>
                    <div>
                      <GridContainer style={{ marginLeft: "7px" }}>
                        <GridItem
                          xs={4}
                          sm={4}
                          md={4}
                          style={{ marginTop: "4px", textAlign: "right" }}
                        >
                          Minimum Spending
                        </GridItem>
                        <GridItem
                          xs={4}
                          sm={4}
                          md={5}
                          style={{ marginTop: "-30px" }}
                        >
                          <Field
                            id="minimum-spending-input"
                            name="minimumSpending"
                            component={CustomInput}
                            type="number"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabled={readOnly}
                            normalize={(value) => {
                              return crmValidator.handleKeyPress(
                                value,
                                validate_field.numberOnly
                              )
                            }}
                            validate={
                              lodash.get(
                                FORM_EARNRULES.values,
                                "customCheckboxMinimumSpending"
                              )
                                ? [FormValidator.validateIsZeroMinimumSpending]
                                : null
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    </div>
                    <div>
                      <GridContainer style={{ marginLeft: "30px" }}>
                        <GridItem xs={12} sm={12} md={12}>
                          <Field
                            id="radio-minimum-spending"
                            name="customRadioMinimumSpending"
                            component={CustomRadio}
                            disabled={readOnly}
                            radioLists={[
                              {
                                value: "since",
                                label: (
                                  <span style={{ fontWeight: "300" }}>
                                    Calculate since minimum amount
                                  </span>
                                ),
                                id: "radio-minimum-spending-since",
                              },
                              {
                                value: "whole",
                                label: (
                                  <span style={{ fontWeight: "300" }}>
                                    Calculate for whole amount
                                  </span>
                                ),
                                id: "radio-minimum-spending-whole",
                              },
                            ]}
                          />
                        </GridItem>
                      </GridContainer>
                    </div>{" "}
                  </>
                ) : null}

                <GridContainer style={{ marginTop: "-15px" }}>
                  <GridItem xs={1} sm={1} md={1}>
                    <Field
                      id="checkbox-maximum-spending"
                      name="customCheckboxMaximumSpending"
                      notLabel={true}
                      component={CustomCheckbox}
                      disabled={readOnly}
                    />
                  </GridItem>
                  <GridItem
                    xs={11}
                    sm={11}
                    md={11}
                    style={{ marginTop: "31px" }}
                  >
                    Maximum Spending Amount for Calculate Point{" "}
                    <span style={{ fontSize: "17px" }}>▾</span>
                  </GridItem>
                </GridContainer>
                {lodash.get(
                  FORM_EARNRULES.values,
                  "customCheckboxMaximumSpending"
                ) ? (
                  <GridContainer style={{ marginLeft: "10px" }}>
                    <GridItem
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ marginTop: "4px", textAlign: "right" }}
                    >
                      Maximum Spending
                    </GridItem>
                    <GridItem
                      xs={3}
                      sm={4}
                      md={5}
                      style={{ marginTop: "-27px" }}
                    >
                      <Field
                        id="maximum-spending-input"
                        name="maximumSpending"
                        component={CustomInput}
                        type="number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        disabled={readOnly}
                        normalize={(value) => {
                          return crmValidator.handleKeyPress(
                            value,
                            validate_field.numberOnly
                          )
                        }}
                        validate={
                          lodash.get(
                            FORM_EARNRULES.values,
                            "customCheckboxMaximumSpending"
                          )
                            ? [FormValidator.validateIsLessThanSpendingEvery]
                            : null
                        }
                      />
                    </GridItem>
                  </GridContainer>
                ) : null}
              </div>
            ) : null}
            <GridContainer style={{ marginTop: "15px" }}>
              <GridItem xs={4} sm={4} md={4}>
                <Field
                  id="radio-purshase-amount"
                  name="customRadioPurshaseAmount"
                  notLabel={true}
                  component={CustomRadio}
                  disabled={readOnly}
                  radioLists={[
                    {
                      value: "whenSpend",
                      label: (
                        <span style={{ fontWeight: "300" }}>When Spending</span>
                      ),
                      id: "radio-purshase-amount-when-spend",
                    },
                  ]}
                />
              </GridItem>

              <GridContainer
                style={{
                  marginTop: "-65px",
                  marginLeft: "150px",
                  marginRight: "-150px",
                  width: "460px",
                }}
              >
                <GridItem xs={4} sm={4} md={4}>
                  <Field
                    id="when-spend-input"
                    name="whenSpendInput"
                    component={CustomInput}
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    onChange={this.onBasicFieldChange}
                    disabled={
                      readOnly ||
                      !(
                        lodash.get(
                          FORM_EARNRULES.values,
                          "customRadioPurshaseAmount"
                        ) === "whenSpend"
                      )
                    }
                    normalize={(value) => {
                      return crmValidator.handleKeyPress(
                        value,
                        validate_field.numberOnly
                      )
                    }}
                    validate={
                      lodash.get(
                        FORM_EARNRULES.values,
                        "customRadioPurshaseAmount"
                      ) === "whenSpend"
                        ? [FormValidator.validateIsZeroMount]
                        : null
                    }
                  />
                </GridItem>
                <GridItem
                  xs={3}
                  sm={3}
                  md={3}
                  style={{ marginTop: "30px", marginLeft: "-15px" }}
                >
                  will get
                </GridItem>
                <GridItem xs={3} sm={3} md={3} style={{ marginLeft: "-50px" }}>
                  <Field
                    id="when-spend-will-get-input"
                    name="willGetInputWhenSpend"
                    component={CustomInput}
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    disabled={
                      readOnly ||
                      !(
                        lodash.get(
                          FORM_EARNRULES.values,
                          "customRadioPurshaseAmount"
                        ) === "whenSpend"
                      )
                    }
                    normalize={(value) => {
                      return crmValidator.handleKeyPress(
                        value,
                        validate_field.numberOnly
                      )
                    }}
                    validate={
                      lodash.get(
                        FORM_EARNRULES.values,
                        "customRadioPurshaseAmount"
                      ) === "whenSpend"
                        ? [FormValidator.validateIsZero]
                        : null
                    }
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2} style={{ marginTop: "30px" }}>
                  Point
                </GridItem>
              </GridContainer>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ color: "#3c4858" }}>
                <b>Participated Day in Campaign</b>
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Field
                  id="radio-participated-day"
                  name="customRadioParticipatedDay"
                  component={CustomRadio}
                  disabled={readOnly}
                  radioLists={[
                    {
                      value: "everyday",
                      label: (
                        <span style={{ fontWeight: "300" }}>Every Day</span>
                      ),
                      id: "radio-participated-day-everyday",
                    },
                    {
                      value: "specific",
                      label: (
                        <span>
                          <span style={{ fontWeight: "300" }}>
                            Selected Day{" "}
                          </span>
                          <span style={{ fontSize: "17px" }}>▾</span>
                        </span>
                      ),
                      id: "radio-participated-day-specific",
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
            {lodash.get(FORM_EARNRULES.values, "customRadioParticipatedDay") ===
            "specific" ? (
              <>
                <GridContainer style={{ marginLeft: "15px" }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Field
                      id="radio-selected-day"
                      name="customRadioSelectedDay"
                      component={CustomRadio}
                      disabled={readOnly}
                      radioLists={[
                        {
                          value: "dayOfWeek",
                          label: (
                            <span>
                              <span style={{ fontWeight: "300" }}>
                                Day of Week{" "}
                              </span>
                              <span style={{ fontSize: "17px" }}>▾</span>
                            </span>
                          ),
                          id: "radio-selected-day-day-of-week",
                        },
                      ]}
                    />
                  </GridItem>
                </GridContainer>
                {lodash.get(FORM_EARNRULES.values, "customRadioSelectedDay") ===
                "dayOfWeek" ? (
                  <GridContainer style={{ marginLeft: "45px" }}>
                    <Field
                      id="checkbox-monday"
                      name="selectedDate[monday]"
                      component={CustomCheckbox}
                      disabled={readOnly}
                      labelText={
                        <span style={{ fontSize: "11px", fontWeight: "300" }}>
                          Mon
                        </span>
                      }
                      validate={FormValidator.validateSelectedDate}
                    />
                    <Field
                      id="checkbox-tuesday"
                      name="selectedDate[tuesday]"
                      component={CustomCheckbox}
                      disabled={readOnly}
                      labelText={
                        <span style={{ fontSize: "11px", fontWeight: "300" }}>
                          Tue
                        </span>
                      }
                      validate={FormValidator.validateSelectedDate}
                    />
                    <Field
                      id="checkbox-wednesday"
                      name="selectedDate[wednesday]"
                      component={CustomCheckbox}
                      disabled={readOnly}
                      labelText={
                        <span style={{ fontSize: "11px", fontWeight: "300" }}>
                          Wed
                        </span>
                      }
                      validate={FormValidator.validateSelectedDate}
                    />
                    <Field
                      id="checkbox-thursday"
                      name="selectedDate[thursday]"
                      component={CustomCheckbox}
                      disabled={readOnly}
                      labelText={
                        <span style={{ fontSize: "11px", fontWeight: "300" }}>
                          Thu
                        </span>
                      }
                      validate={FormValidator.validateSelectedDate}
                    />
                    <Field
                      id="checkbox-friday"
                      name="selectedDate[friday]"
                      component={CustomCheckbox}
                      disabled={readOnly}
                      labelText={
                        <span style={{ fontSize: "11px", fontWeight: "300" }}>
                          Fri
                        </span>
                      }
                      validate={FormValidator.validateSelectedDate}
                    />
                    <Field
                      id="checkbox-saturday"
                      name="selectedDate[saturday]"
                      component={CustomCheckbox}
                      disabled={readOnly}
                      labelText={
                        <span style={{ fontSize: "11px", fontWeight: "300" }}>
                          Sat
                        </span>
                      }
                      validate={FormValidator.validateSelectedDate}
                    />
                    <Field
                      id="checkbox-sunday"
                      name="selectedDate[sunday]"
                      component={CustomCheckbox}
                      disabled={readOnly}
                      labelText={
                        <span style={{ fontSize: "11px", fontWeight: "300" }}>
                          Sun
                        </span>
                      }
                      validate={FormValidator.validateSelectedDate}
                    />
                  </GridContainer>
                ) : null}
                <GridContainer style={{ marginLeft: "15px" }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Field
                      id="radio-selected-day"
                      name="customRadioSelectedDay"
                      component={CustomRadio}
                      disabled={readOnly}
                      radioLists={[
                        {
                          value: "birthday",
                          label: (
                            <span style={{ fontWeight: "300" }}>Birthday</span>
                          ),
                          id: "radio-selected-day-birthday",
                        },
                        {
                          value: "birthmonth",
                          label: (
                            <span style={{ fontWeight: "300" }}>
                              Any Day in Birth Month
                            </span>
                          ),
                          id: "radio-selected-day-birthmonth",
                        },
                      ]}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginLeft: "15px" }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Field
                      id="radio-selected-day"
                      name="customRadioSelectedDay"
                      component={CustomRadio}
                      disabled={readOnly}
                      radioLists={[
                        {
                          value: "selectedDate",
                          label: (
                            <span style={{ fontWeight: "300" }}>
                              Selected Date
                            </span>
                          ),
                          id: "radio-selected-day-selected-date",
                        },
                      ]}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginLeft: "15px" }}>
                  <GridItem
                    xs={4}
                    sm={4}
                    md={4}
                    style={{
                      marginTop: "-60px",
                      marginLeft: "140px",
                      width: "70px",
                    }}
                  >
                    <Field
                      id="tags-multi-select-date"
                      name="date"
                      component={TagsMultiSelect}
                      labelText="Date"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      showtick
                      optionsList={[
                        { _id: 1, name: 1 },
                        { _id: 2, name: 2 },
                        { _id: 3, name: 3 },
                        { _id: 4, name: 4 },
                        { _id: 5, name: 5 },
                        { _id: 6, name: 6 },
                        { _id: 7, name: 7 },
                        { _id: 8, name: 8 },
                        { _id: 9, name: 9 },
                        { _id: 10, name: 10 },
                        { _id: 11, name: 11 },
                        { _id: 12, name: 12 },
                        { _id: 13, name: 13 },
                        { _id: 14, name: 14 },
                        { _id: 15, name: 15 },
                        { _id: 16, name: 16 },
                        { _id: 17, name: 17 },
                        { _id: 18, name: 18 },
                        { _id: 19, name: 19 },
                        { _id: 20, name: 20 },
                        { _id: 21, name: 21 },
                        { _id: 22, name: 22 },
                        { _id: 23, name: 23 },
                        { _id: 24, name: 24 },
                        { _id: 25, name: 25 },
                        { _id: 26, name: 26 },
                        { _id: 27, name: 27 },
                        { _id: 28, name: 28 },
                        { _id: 29, name: 29 },
                        { _id: 30, name: 30 },
                        { _id: 31, name: 31 },
                      ]}
                      disabled={
                        readOnly ||
                        !(
                          lodash.get(
                            FORM_EARNRULES.values,
                            "customRadioSelectedDay"
                          ) === "selectedDate"
                        )
                      }
                      normalize={(value) => {
                        return crmValidator.handleKeyPress(
                          value,
                          validate_field.date
                        )
                      }}
                      validate={
                        lodash.get(
                          FORM_EARNRULES.values,
                          "customRadioSelectedDay"
                        ) === "selectedDate"
                          ? [FormValidator.validateDateSelect]
                          : null
                      }
                    />
                  </GridItem>
                </GridContainer>
              </>
            ) : null}

            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ color: "#3c4858" }}>
                <b>Participated Member Level in Campaign</b>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Field
                  id="tags-multi-select-member-level"
                  name="memberLevel"
                  component={TagsMultiSelect}
                  labelText="Member Level"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  showtick
                  optionsList={formMemberLevellist}
                  disabled={readOnly}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>

        <div style={{ border: "dashed 1px #ccc", padding: "0px 20px" }} />
        <br />

      <ShopSelections readOnly={readOnly}></ShopSelections>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const {
    formOpen,
    formMode,
    allProductList,
    productList,
    selectedProductList,
    formProductListLoader,
    formInitialValue,
    formMemberLevellist,
  } = state.earnrules
  const { FORM_EARNRULES } = state.form
  return {
    formOpen,
    formMode,
    allProductList,
    productList,
    selectedProductList,
    formProductListLoader,
    FORM_EARNRULES,
    formInitialValue,
    formMemberLevellist,
  }
}

const connectEarnRuleTypePurchaseAmount = connect(mapStateToProps)(
  EarnRuleTypePurchaseAmount
)
export default withStyles(styles)(connectEarnRuleTypePurchaseAmount)

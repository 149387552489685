import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, arrayPush, getFormValues, change, SubmissionError, clearSubmitErrors, getFormSubmitErrors } from 'redux-form';
import lodash from 'lodash'
import qs from 'qs'
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from 'components/CustomInput/CustomInput';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment"
import Search from "@material-ui/icons/Search"

import { listServices, saleProductServices } from '../../../services'
import { promotionConstants } from "../../../constants"
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import { crmValidator, normalize, plugins, loader } from 'helpers';

import red from '@material-ui/core/colors/red';

const style = {
    ...modalStyle,
    leftRightSelectMainContainer: {
        marginBottom: "20px"
    },
    leftRightSelectTableContainer: {
        maxHeight: "300px",
        minHeight: "300px",
        overflow: "auto",
        marginBottom: "10px",
        border: "solid 1px #eee"
    },
    leftRightSelectTableContainerError: {
        maxHeight: "300px",
        minHeight: "300px",
        overflow: "auto",
        marginBottom: "10px",
        border: "solid 1px " + red[500]
    },
    leftRightSelectHead: {
        backgroundColor: "#eee",
        position: "sticky",
        top: -2,
        zIndex: 10,
        paddingLeft: "10px",
        paddingRight: ["10px", "!important"],
        "& button": {
            color: "#111",
            boxShadow: "none",
            backgroundColor: "rgba(0,0,0,0)",
            "&:hover": {
                color: "#111",
                boxShadow: "none",
                backgroundColor: "rgba(1,1,1,0.1)",
            }
        },
    },
    tableHover: {
        transitionDuration: "0.3s",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#efefef",
        },
    },
    tableCell: {
        padding: "0px 10px",
    },
    tdBordered: {
        border: "solid 1px #ccc"
    },
    tableError: {
        border: "solid 2px red"
    },
    overrides: {
        MuiDialog: {
            paper: {
                overflowY: "visible"
            }
        }
    }
}

const THEME = createMuiTheme(style)

const FORM_NAME = 'promotion_reward_discount'

const form_validate = {
    discount_type: {
        rules: {
            required: true
        },
        messages: {
            required: 'Discount Type is required',
        },
        alert: "Discount Type is required"
    },
    discount_value: {
        rules: {
            required: true,
            min: 0.01
        },
        messages: {
            required: 'Discount Value is required',
            min: "Please enter number more than 0"
        },
        alert: "Discount Value is required"
    }
}

const validateRequired = (value, allValues, props, name) => {
    if (value === "" || value === null || value === undefined) {
        if (lodash.includes(name, 'discount_apply_for')) {
            return "Discount apply for is required"

        } else if (lodash.includes(name, 'specificType')) {
            return "Please select SKU or Category"

        } else {
            return "Required"
        }
    }

    if (parseInt(value) <= 0) {
        return "Please enter number more than 0"
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const renderFieldError = ({ meta: { touched, error } }) => (
    <div className="input-row">
        {touched && error &&
            <span className="error" style={{ color: red[500] }}>{error}</span>}
    </div>
)

class PromotionRewardFreeProduct extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            productList: [],
            categoryList: [],
            saleProductList: [],
            stateSelectedOption: []
        }
    }

    handleSubmit(submitValues) {
        let { promotionFormValue, dispatch, rewardModalOpen } = this.props
        let { stateSelectedOption } = this.state
        let rewards = lodash.get(promotionFormValue, 'rewards')
        let discountApplyfor = lodash.get(submitValues, 'discount_apply_for')

        if (discountApplyfor === 'skuOrCategory' && lodash.size(stateSelectedOption) === 0) {
            throw new SubmissionError({
                "skuOrCategory_validator": "SKU or Category is required"
            })
        }

        let rewardType = rewardModalOpen
        lodash.remove(rewards, function (n) {
            return n.type === rewardType
        })

        let conditions = []

        if (discountApplyfor === "whole_bill") {
            conditions.push({
                type: "whole_bill",
                discount_type: lodash.get(submitValues, 'discount_type'),
                discount_value: lodash.get(submitValues, 'discount_value')
            })
        } else if (discountApplyfor === 'skuOrCategory') {
            let type = lodash.get(submitValues, 'specificType')
            conditions.push({
                type: type,
                discount_type: lodash.get(submitValues, 'discount_type'),
                discount_value: lodash.get(submitValues, 'discount_value'),
                in: this.state.stateSelectedOption
            })
        }
        dispatch(arrayPush('promotion_form', 'rewards', {
            type: rewardType,
            conditions: conditions
        }))
        dispatch({ type: promotionConstants.CLOSE_REWARD_MODAL })

    }

    async componentDidMount() {

        const { formValues, dispatch } = this.props
        if (lodash.has(formValues, 'initialSelected')) {
            this.setState({ stateSelectedOption: formValues.initialSelected })
        }
        loader(dispatch, true)
        let reqParamsProduct = {
            skip: 0,
            limit: 1000000,
            sortBy: "name",
            sortDir: "asc",
            keywordFilterList: ["name", "skuId"]
        }
        let productList = await listServices.getListsProduct(qs.stringify(reqParamsProduct))
        if (lodash.has(productList, 'data.products')) {
            this.setState({ productList: lodash.get(productList, 'data.products') })
        }

        let reqParamsCat = {
            skip: 0,
            limit: 1000000,
            sortBy: "name",
            sortDir: "asc",
            keywordFilterList: ["categoryCode", "name"]
        }

        let categoryList = await listServices.getCategoryList('product', qs.stringify(reqParamsCat))
        if (lodash.has(categoryList, 'data')) {
            this.setState({ categoryList: lodash.get(categoryList, 'data') })
        }

        let reqParamsSaleProduct = {
            skip: 0,
            limit: 1000000,
            sortBy: "name",
            sortDir: "asc",
            keywordFilterList: ["name", "skuId"]
        }
        let saleProductList = await saleProductServices.getListsSaleProduct(qs.stringify(reqParamsSaleProduct))
        if (lodash.has(saleProductList, 'data.product_sales')) {
            this.setState({ saleProductList: lodash.get(saleProductList, 'data.product_sales') })
        }

        loader(dispatch, false)

    }

    getRightOptionList() {
        const { formValues } = this.props
        const { stateSelectedOption } = this.state
        let searchRightBox = lodash.toLower(lodash.get(formValues, 'searchRightBox'))
        let newSelected = []
        stateSelectedOption.map((data, index) => {
            let name = lodash.get(data, 'name')
            let id = lodash.get(data, 'id')
            if (
                (!searchRightBox || searchRightBox === "") ||
                (lodash.includes(lodash.toLower(id), searchRightBox)) ||
                (lodash.includes(lodash.toLower(name), searchRightBox))
            ) {
                newSelected.push(data)
            }
            return data
        })
        newSelected = lodash.sortBy(newSelected, ['name'])
        return newSelected
    }

    getLeftOptionList() {
        const { formValues } = this.props
        const { productList, categoryList, saleProductList, stateSelectedOption } = this.state
        let searchLeftBox = lodash.toLower(lodash.get(formValues, 'searchLeftBox'))
        let specificType = lodash.get(formValues, 'specificType')

        if (specificType === 'sku') {
            let newProductList = []
            productList.map((data, index) => {
                let name = lodash.get(data, 'name')
                let skuId = lodash.get(data, 'skuId')
                let foundInSelected = lodash.find(stateSelectedOption, { id: skuId })
                if (lodash.has(data, 'skuId') && !foundInSelected) {
                    if (
                        (!searchLeftBox || searchLeftBox === "") ||
                        (lodash.includes(lodash.toLower(skuId), searchLeftBox)) ||
                        (lodash.includes(lodash.toLower(name), searchLeftBox))
                    ) {
                        newProductList.push(data)
                    }
                }
                return data
            })
            return newProductList

        } else if (specificType === 'category') {
            let newCat = []
            if(lodash.has(categoryList, 'data') && lodash.isArray(categoryList.data)){
                categoryList.data.map((data, index) => {
                    let name = lodash.get(data, 'name')
                    let categoryCode = lodash.get(data, 'categoryCode')
                    let foundInSelected = lodash.find(stateSelectedOption, { id: categoryCode })

                    if (lodash.has(data, 'categoryCode') && !foundInSelected) {
                        if (
                            (!searchLeftBox || searchLeftBox === "") ||
                            (lodash.includes(lodash.toLower(categoryCode), searchLeftBox)) ||
                            (lodash.includes(lodash.toLower(name), searchLeftBox))
                        ) {
                            newCat.push(data)
                        }
                    }
                    return data
                })
            }
            return newCat
        } else if (specificType === 'saleProduct') {
            let newProductList = []

            saleProductList.map((data, index) => {
                let name = lodash.get(data, 'name')
                let productId = lodash.get(data, '_id')
                let foundInSelected = lodash.find(stateSelectedOption, { id: productId })
                if (productId && !foundInSelected) {
                    if (
                        (!searchLeftBox || searchLeftBox === "") ||
                        (lodash.includes(lodash.toLower(productId), searchLeftBox)) ||
                        (lodash.includes(lodash.toLower(name), searchLeftBox))
                    ) {
                        newProductList.push(data)
                    }
                }
                return data
            })
            return newProductList
        }
    }

    renderLeftBox(optionsList = []) {
        const { classes, formValues, dispatch, readOnly } = this.props
        let { stateSelectedOption } = this.state
        let specificType = lodash.get(formValues, 'specificType')
        return (
            <>
                {optionsList.map((data, index) => {
                    return (
                        <TableRow
                            key={index}
                            className={classes.tableHover}
                            id={'discount-option-' + lodash.get(data, '_id')}
                            onClick={() => {
                                if (!readOnly) {
                                    const id = specificType === 'sku' ? lodash.get(data, 'skuId') :
                                    specificType === 'category' ? lodash.get(data, 'categoryCode') :
                                    specificType === 'saleProduct' ? lodash.get(data, '_id') :
                                        null
                                    if (id) {
                                        stateSelectedOption.push({
                                            id,
                                            name: lodash.get(data, 'name')
                                        })
                                    }
                                    this.setState({ stateSelectedOption })
                                    dispatch(clearSubmitErrors(FORM_NAME))
                                }
                            }}
                        >
                            {specificType === 'sku' &&
                                <TableCell className={classes.tableCell}>
                                    {lodash.get(data, 'name')} {`(${data.skuId})`}
                                </TableCell>
                            }
                            {specificType === 'category' &&
                                <TableCell className={classes.tableCell}>
                                    {lodash.get(data, 'name')} {`(${data.categoryCode})`}
                                </TableCell>
                            }
                            {specificType === 'saleProduct' &&
                                <TableCell className={classes.tableCell}>
                                    {lodash.get(data, 'name')} {`(${data._id})`}
                                </TableCell>
                            }
                        </TableRow>
                    )
                })}
            </>
        )
    }

    getTotalLeftDisplay() {
        const { formValues } = this.props
        const { stateSelectedOption, productList, categoryList } = this.state
        let specificType = lodash.get(formValues, 'specificType')

        let selectedSize = lodash.size(stateSelectedOption)
        let productSize = lodash.size(productList)
        let categorySize = lodash.size(categoryList)

        if (specificType === 'sku') {
            return productSize - selectedSize

        } else if (specificType === 'category') {
            return categorySize - selectedSize

        } else {
            return 0
        }
    }

    renderLeftRightBoxContainer() {
        const { classes, formValues, valid, dispatch, readOnly, formSubmitError } = this.props;
        let { stateSelectedOption } = this.state
        let optionList = this.getLeftOptionList()
        let displaySelectedList = this.getRightOptionList()

        return (
            <GridContainer>
                <GridItem xs={2}></GridItem>
                <GridItem xs={10} style={{
                    border: "dashed 1px #ccc",
                }}>
                    <GridContainer style={{ marginTop: "20px", marginBottom: "15px" }}>
                        <GridItem xs={12}>
                            <Typography variant="body1" display="block">
                                Add SKU, Category or Sale Product by selection to the right hand side
                            </Typography>
                        </GridItem>
                    </GridContainer>

                    <GridContainer className={classes.leftRightSelectMainContainer}>
                        <GridItem xs={6}>
                            <Field
                                component={CustomInput}
                                placeholder="Search by name or ID"
                                formControlProps={{
                                    fullWidth: true,
                                    style: {
                                        paddingTop: "0px"
                                    }
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search style={{ color: "#ccc" }} />
                                    </InputAdornment>
                                }
                                name="searchLeftBox"
                            />
                            <div className={classes.leftRightSelectTableContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.leftRightSelectHead}>
                                                <Button
                                                    type="button"
                                                    id="btn-selectAll"
                                                    onClick={() => {
                                                        let selectedOption = lodash.get(this, 'state.stateSelectedOption')
                                                        let specificType = lodash.get(formValues, 'specificType')
                                                        let newSelected = []
                                                        optionList.map((data) => {
                                                            const id = specificType === 'sku' ? lodash.get(data, 'skuId') :
                                                                specificType === 'category' ? lodash.get(data, 'categoryCode') :
                                                                specificType === 'saleProduct' ? lodash.get(data, '_id') :
                                                                    null
                                                            if (id) {
                                                                newSelected.push({
                                                                    id,
                                                                    name: lodash.get(data, 'name'),
                                                                })
                                                            }
                                                            return data
                                                        })
                                                        let newData = lodash.union(selectedOption, newSelected)
                                                        this.setState({ stateSelectedOption: newData })
                                                        dispatch(clearSubmitErrors(FORM_NAME))
                                                    }}
                                                    disabled={readOnly}
                                                    fullWidth={true}
                                                >
                                                    <ArrowForwardIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        key={lodash.get(formValues, 'searchLeftBox')}
                                        style={{ border: "solid 1px #eee" }}>
                                        {this.renderLeftBox(optionList)}
                                    </TableBody>
                                </Table>
                            </div>
                            <Typography variant="caption" display="block">
                                Showing {plugins.numberFormat(lodash.size(optionList))} of {plugins.numberFormat(this.getTotalLeftDisplay())}
                            </Typography>
                        </GridItem>
                        <GridItem xs={6}>
                            <Field
                                component={CustomInput}
                                placeholder="Search by name or ID"
                                formControlProps={{
                                    fullWidth: true,
                                    style: {
                                        paddingTop: "0px"
                                    }
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search style={{ color: "#ccc" }} />
                                    </InputAdornment>
                                }
                                name="searchRightBox"
                            />
                            <div className={(!valid && lodash.has(formSubmitError, 'skuOrCategory_validator'))? classes.leftRightSelectTableContainerError : classes.leftRightSelectTableContainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.leftRightSelectHead}>
                                                <Button
                                                    onClick={() => {
                                                        this.setState({ stateSelectedOption: lodash.difference(stateSelectedOption, displaySelectedList) })
                                                    }}
                                                    fullWidth={true}
                                                    disabled={readOnly}
                                                >
                                                    <ArrowBackIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        key={lodash.get(formValues, 'searchRightBox')}
                                        style={{ border: "solid 1px #eee" }}>
                                        {displaySelectedList.map((data, index) => (
                                            <TableRow key={index}
                                                className={classes.tableHover}
                                                onClick={() => {
                                                    if (!readOnly) {
                                                        let newOption = lodash.clone(stateSelectedOption)
                                                        lodash.pullAt(newOption, [index])
                                                        this.setState({ stateSelectedOption: newOption })
                                                    }
                                                }}>
                                                <TableCell className={classes.tableCell}>
                                                    {lodash.get(data, 'name')} {`(${lodash.get(data, 'id')})`}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                            <Field
                                component={renderFieldError}
                                type="input"
                                name="skuOrCategory_validator"
                            />
                            <Typography variant="caption" display="block">
                                Showing {plugins.numberFormat(lodash.size(displaySelectedList))} of {plugins.numberFormat(lodash.size(lodash.get(this, 'state.stateSelectedOption')))}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        )
    }

    render() {
        const { rewardModalOpen, classes, dispatch, handleSubmit, readOnly, formValues } = this.props
        return (
            <MuiThemeProvider theme={THEME}>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={(rewardModalOpen === 'discount')}
                    keepMounted
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description"
                    fullWidth={true}
                    maxWidth="md"
                    disableRestoreFocus={true}
                    scroll="body">

                    <DialogTitle
                        disableTypography
                        className={classes.modalHeader}>
                        <h4 className={classes.modalTitle}>
                            <b>{"Discount"}</b>
                        </h4>
                    </DialogTitle>

                    <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                        <DialogContent
                            id="promotion-reward-discount-dialog"
                            className={classes.modalBody}
                            style={{
                                overflow: "visible",
                                paddingTop: "0px"
                            }}
                        >
                            <GridContainer>

                                <GridItem xs={4}>
                                    <Field
                                        name="discount_type"
                                        component={CustomSelect}
                                        type="text"
                                        labelText={"Discount Type"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        optionsList={[
                                            {
                                                value: "amount",
                                                label: "Amount"
                                            },
                                            {
                                                value: "percentage",
                                                label: "Percentage"
                                            }
                                        ]}
                                        disabled={readOnly}
                                    />
                                </GridItem>
                                <GridItem xs={4}>
                                    <Field
                                        name="discount_value"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"Discount Value"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        normalize={normalize.onlyFloatingTwo}
                                        disabled={readOnly}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={2} style={{ marginTop: "13px" }}>
                                    <b>Discount apply for</b>
                                </GridItem>
                                <GridItem xs={3}>
                                    <Field
                                        name="discount_apply_for"
                                        component={CustomRadio}
                                        onChange={(e) => {
                                            let changedValue = lodash.get(e, 'target.value')
                                            if (changedValue === "skuOrCategory") {
                                                dispatch(change(FORM_NAME, 'specificType', 'sku'))
                                            } else {
                                                dispatch(change(FORM_NAME, 'specificType', null))
                                            }
                                        }}
                                        radioLists={[
                                            {
                                                value: 'whole_bill',
                                                label: "Whole Bill",
                                                disabled: readOnly
                                            },
                                            {
                                                value: 'skuOrCategory',
                                                label: "Specific SKU or Category",
                                                disabled: readOnly
                                            },
                                        ]}
                                        validate={[validateRequired]}
                                    />
                                </GridItem>
                                <GridItem xs={2}>
                                    <div style={{ marginTop: "48px" }}>
                                        {(lodash.get(formValues, 'discount_apply_for') === "skuOrCategory") &&
                                            <Field
                                                name="specificType"
                                                component={CustomSelect}
                                                type="text"
                                                labelText={""}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={() => {
                                                    this.setState({ stateSelectedOption: [] })
                                                }}
                                                optionsList={[
                                                    {
                                                        value: "sku",
                                                        label: "SKU"
                                                    },
                                                    {
                                                        value: "category",
                                                        label: "Category"
                                                    },
                                                    {
                                                        value: "saleProduct",
                                                        label: "Sale Product"
                                                    }
                                                ]}
                                                disabled={readOnly}
                                            />
                                        }
                                    </div>
                                </GridItem>
                            </GridContainer>
                            {['sku', 'category', 'saleProduct'].includes(lodash.get(formValues, 'specificType')) &&
                                this.renderLeftRightBoxContainer()
                            }

                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter}>
                            {!readOnly &&
                                <Button
                                    id={`promotion_discount_btn_submit`}
                                    type="submit"
                                    style={{ marginRight: "10px" }}
                                    color="primary">
                                    Submit
                                </Button>
                            }
                            <Button
                                id={`promotion_discount_btn_cancel`}
                                type="button"
                                onClick={() => {
                                    dispatch({ type: promotionConstants.CLOSE_REWARD_MODAL });
                                }}
                                color="white">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>

                </Dialog>
            </MuiThemeProvider>
        )
    }
}

function mapStateToProps(state) {
    const { rewardModalOpen, formMode } = state.promotion;
    const formValues = getFormValues(FORM_NAME)(state)
    const formSubmitError = getFormSubmitErrors(FORM_NAME)(state)
    const promotionFormValue = getFormValues('promotion_form')(state)
    return {
        rewardModalOpen, formValues, promotionFormValue, formMode, formSubmitError
    };
}

const connectedComponent = connect(mapStateToProps)(PromotionRewardFreeProduct);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: false,
    onSubmitFail: (errors) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(style)(connectedComponent))

var initialState = {
    openExample: false,
    setting: {
        url: "",
        width: "",
        height: ""
    }
}

export function luckydrawDashboard(state = initialState, action) {
    switch (action.type) {
        case 'GET_LUCKYDRAW_DASHBOARD_SETTING_SUCCESS':
            console.log("action : ",action);
            return {
                ...state,
                setting: {
                    url: action.data.url,
                    width: action.data.width,
                    height: action.data.height
                }
            }
        case 'OPEN_EXAMPLE_WINNER_CONFIG':
            return {
                ...state,
                openExample: true
            }
        case 'CLOSE_EXAMPLE_WINNER_CONFIG':
            return {
                ...state,
                openExample: false
            }
        default:
            return state;
    }
}
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import ColorSelect from "components/CustomSelect/ColorSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import { dataFilteringTemplateConstants } from '../../constants'
import { dataFilteringTemplateAction } from 'actions';

import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"

const FORM_NAME = 'DATAFILTERING_TEMPLATE_FORM'
const form_validate = {
    "name": {
        rules: {
            required: true,
            noSpecialCharacters: true,
            maxLength: 50
        },
        messages: {
            required: 'กรุณากรอก',
            noSpecialCharacters: 'ไม่สามารถใช้อักขระพิเศษในชื่อ Tag ได้',
            maxLength: 'ไม่สามารถใช้ชื่อ Tag ได้มากกว่า 50 ตัวอักษร'
        }
    },
    "color": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const normalizeTagMaxLength = (value) => {
    return value.substr(0, 50);
}

class DATAFILTERING_TEMPLATE_FORM extends React.Component {

    handleSubmit = (values) => {
        const { dispatch } = this.props
        var reqParams = {
            "name": values.name,
            "color": values.color,
            "startDate": values.startDate,
            "endDate": values.endDate,
            "status": values.status
        }

        if (this.props.formMode === "add") {
            dispatch(dataFilteringTemplateAction.addNewDataFilteringTemplate(reqParams));
        } else {
            reqParams._id = values._id
            dispatch(dataFilteringTemplateAction.editDataFilteringTemplate(reqParams));
        }
    }

    render() {
        const { classes, formOpen, formMode, handleSubmit, DATAFILTERING_TEMPLATE_FORM, initialValues } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        let startDateMinDate = new Date().setHours(0, 0, 0, 0)
        if (initialValues.startDate) {
            startDateMinDate = moment(initialValues.startDate)
        }

        let endDateMinDate = startDateMinDate;
        if (lodash.has(DATAFILTERING_TEMPLATE_FORM, 'values.startDate')) {
            if (DATAFILTERING_TEMPLATE_FORM.values.startDate) {
                endDateMinDate = moment(DATAFILTERING_TEMPLATE_FORM.values.startDate)
            }
        }

        const conditions1 = [DATAFILTERING_TEMPLATE_FORM.values.conditions]

        const conditions = DATAFILTERING_TEMPLATE_FORM.values.conditions

        var rows = [];

        for (const property in conditions) {
            rows.push(conditions[property]);
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="lg"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>{formMode === 'add' ? 'Add Tag' : (readOnly === true ? 'Tag Info' : 'Edit Tag')}</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <React.Fragment>
                                {rows.map((filtering, index) =>
                                    <React.Fragment key={index}>
                                        {(index > 0) &&
                                            <div style={{
                                                padding: "20px",
                                                width: "70px",
                                                background: "linear-gradient(#000, #000) no-repeat center/2px 100%",
                                                textAlign: "center"
                                            }}>
                                                <b style={{ backgroundColor: "white" }}>AND</b>
                                            </div>
                                        }
                                        {filtering.conditions.map((condition, conditionindex) =>
                                            <Table className={classes.table} >
                                                <TableHead>
                                                    <TableRow className={classes.tdBordered}>

                                                        <TableCell
                                                            key="condition_header"
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "90px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                // name={condition.exclude}
                                                                // name={`filtering.conditions${conditionindex}.exclude`}
                                                                name={`conditions[${index}].conditions[${conditionindex}].exclude`}
                                                                component={CustomSelect}
                                                                type="text"
                                                                labelText={""}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                outlined
                                                                disabled
                                                                value={condition.exclude}
                                                                // selected={false}
                                                                optionsList={[
                                                                    {
                                                                        value: 'false',
                                                                        label: "include",
                                                                    },
                                                                    {
                                                                        value: 'true',
                                                                        label: "exclude"
                                                                    }
                                                                ]}
                                                            // onChange={(e) => {
                                                            //     this.getTablename(e, index)
                                                            // }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "100px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                // name={`${filtering}[table]`}
                                                                // name={`filtering.conditions${conditionindex}.table`}
                                                                // name={`conditions[${index}].conditions[${conditionindex}].table`}
                                                                // component={CustomSelect}
                                                                // type="text"
                                                                // labelText={``}
                                                                // formControlProps={{
                                                                //     fullWidth: true,
                                                                // }}
                                                                // outlined
                                                                // disabled
                                                                // optionsList={this.state.selectTableName[index]}
                                                                // onChange={(e) => {
                                                                //     this.getFieldname(e, index)
                                                                // }}

                                                                name={`conditions[${index}].conditions[${conditionindex}].table`}
                                                                // name="conditions[0]"
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "100px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                // // name={`${filtering}[field]`}
                                                                // name={`filtering.conditions${conditionindex}.field`}
                                                                // component={CustomSelect}
                                                                // type="text"
                                                                // labelText={``}
                                                                // formControlProps={{
                                                                //     fullWidth: true,
                                                                // }}
                                                                // outlined
                                                                // // optionsList={this.state.selectFieldName[index]}
                                                                // onChange={(e) => {
                                                                //     this.getOperation(e, index)
                                                                // }}
                                                                name={`conditions[${index}].conditions[${conditionindex}].field`}
                                                                // name="conditions[0]"
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "80px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                // name={`${condition}.compareOperator`}
                                                                // name={`filtering.conditions${conditionindex}.compareOperator`}
                                                                // component={CustomSelect}
                                                                // type="text"
                                                                // labelText={``}
                                                                // formControlProps={{
                                                                //     fullWidth: true,
                                                                // }}
                                                                // outlined
                                                                // optionsList={this.state.selectFieldOptions[index]}
                                                                name={`conditions[${index}].conditions[${conditionindex}].compareOperator`}
                                                                // name="conditions[0]"
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "50px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                // name={`${filtering}.value`}
                                                                name={`conditions[${index}].conditions[${conditionindex}].value`}
                                                                // name="conditions[0]"
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                            // value={filtering.conditions[conditionindex].value}

                                                            // name="name"
                                                            // component={CustomInput}
                                                            // type="text"
                                                            // labelText="Tag"
                                                            // formControlProps={{
                                                            //     fullWidth: true
                                                            // }}
                                                            //disabled={readOnly}
                                                            // normalize={normalizeTagMaxLength}

                                                            // disabled
                                                            // value={condition.value}
                                                            />

                                                        </TableCell>
                                                        <TableCell style={{
                                                            paddingRight: "1px",
                                                            paddingLeft: "10px",
                                                            minWidth: "50px",
                                                            whiteSpace: "nowrap"
                                                        }}>
                                                            <Field
                                                                // name={`${filtering}[lastvalue]`}
                                                                name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                                                                component={CustomInput}
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                                type="text"
                                                            />
                                                        </TableCell>
                                                        {/* <TableCell style={{ padding: "0px", width: "50px" }}>
                                                            <Button
                                                                type="button"
                                                                color="info"
                                                                style={{ width: "50px" }}
                                                                // onClick={this.addCondition.bind(this)}
                                                                onClick={() => {
                                                                    this.addOrCondition(index, 'OR')
                                                                }}
                                                            >OR{index}</Button>

                                                        </TableCell> */}
                                                        {/* <TableCell style={{ padding: "0px", width: "50px" }}>
                                                        <TableActionButton
                                                            actionType="delete"
                                                            onClick={() => {
                                                                // fields.remove(index)
                                                            }}
                                                        />
                                                    </TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {/* <FieldArray
                                                    name={`${filtering}.conditions`}
                                                    component={this.renderOrCondition}
                                                    productConditionIndex={`${index}`}
                                                // validate={[validateSizeMorethanOne]}
                                                /> */}
                                                </TableBody>
                                            </Table>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: dataFilteringTemplateConstants.CLOSE_FORM })
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog >
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode } = state.dataFilteringTemplate;
    const { DATAFILTERING_TEMPLATE_FORM } = state.form
    return {
        formOpen, formMode, DATAFILTERING_TEMPLATE_FORM
    };
}

const connectedComponent = connect(mapStateToProps)(DATAFILTERING_TEMPLATE_FORM);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))
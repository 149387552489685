import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { crmValidator, plugins, address } from "helpers";
import moment from "moment";
import lodash from "lodash";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import MemberLevelSelect from "components/CustomSelect/MemberLevelSelect.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import Gender from "assets/data/genders.json";

import { memberActions } from "actions";
import { memberConstants } from "../../constants";
import i18n from "i18n";

const FORM_NAME = "member_form";

const form_validate = {
  firstName: {
    rules: {
      // isAllLetter: true
    },
    messages: {
      // isAllLetter: 'warning_messages.name_must_not_contain_special_characters_or_numeric'
    }
  },
  lastName: {
    rules: {
      // isAllLetter: true
    },
    messages: {
      // isAllLetter: 'warning_messages.last_name_must_not_contain_special_characters_or_numeric'
    }
  },
  email: {
    rules: {
      // required: true,
      isEmail: true
    },
    messages: {
      // required: 'warning_messages.email_is_required',
      isEmail: "warning_messages.email_is_invalid"
    },
    alert: "warning_messages.email_is_required"
  },
  phoneNumber: {
    rules: {
      required: true,
      isMobilePhone: true,
      maxLength: 10
    },
    messages: {
      required: "warning_messages.mobile_number_is_required",
      isMobilePhone: "warning_messages.mobile_number_is_invalid",
      maxLength: "warning_messages.mobile_number_is_invalid"
    },
    alert: "warning_messages.mobile_number_is_required"
  },
  idCardNumber: {
    rules: {
      thaiID: true
    },
    messages: {
      thaiID: "warning_messages.id_card_is_invalid"
    }
    // alert: "ท่านยังไม่ได้กรอกเลขบัตรประชาชน กรุณากรอกข้อมูลให้ครบถ้วน"
  },
  // "province": {
  //     rules: {
  //         required: true
  //     },
  //     messages: {
  //         required: 'กรุณากรอก'
  //     },
  //     alert: "ท่านยังไม่ได้กรอกจังหวัด กรุณากรอกข้อมูลให้ครบถ้วน"
  // },
  zipcode: {
    rules: {
      // required: true,
      number: true,
      minLength: 5,
      maxLength: 5
    },
    messages: {
      // required: 'warning_messages.zipcode_is_required',
      number: "warning_messages.zipcode_is_invalid",
      minLength: "warning_messages.zipcode_is_invalid",
      maxLength: "warning_messages.zipcode_is_invalid"
    }
    // alert: "warning_messages.zipcode_is_required"
  },
  password: {
    rules: {
      minLength: 8,
      passwordStrength: true
    },
    messages: {
      minLength: "warning_messages.password_is_invalid", //รหัสผ่านต้องมีมากกว่า 8 ตัวขึ้นไป
      passwordStrength: "warning_messages.password_is_invalid" //รหัสผ่านต้องประกอบด้วยตัวพิมพ์ใหญ่ (A-Z), ตัวพิมพ์เล็ก (a-z), ตัวเลข (0-9) และตัวอักขระพิเศษ (!,@,#,$,%,^,&,*)
    },
    alert: "warning_messages.password_is_required"
  },
  passwordConfirmation: {
    rules: {
      equalTo: "password"
    },
    messages: {
      required: "warning_messages.password_confirmation_is_required",
      equalTo: "warning_messages.confirm_password_not_match" //กรุณากรอกรหัสผ่านให้ตรงกัน
    },
    alert: "warning_messages.password_confirmation_is_required"
  }
};

const validate = values => {
  const errors = crmValidator.validateByRulesList(form_validate, values);
  return errors;
};
class MemberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceOptionsList: [],
      districtOptionsList: [],
      subDistrictOptionsList: [],
      zipcodeDisabled: true,
      zipcode: "",
      province: "",
      district: "",
      subDistrict: "",
      disabledProvinceSelected: true,
      disabledDistrictSelected: true,
      disabledSubDistrictSelected: true,
      verificationStatus: "",
      idCardNumber: "",
      userType: ""
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.formOpen === true && prevProps.formOpen === false) {
      const { dispatch, formInitialValue } = this.props;
      dispatch(memberActions.getActiveTagList());

      const { province, district, subDistrict, verificationStatus, userType } = formInitialValue;

      if (verificationStatus) {
        this.setState({
          verificationStatus: verificationStatus
        });
      }

      if (userType) {
        this.setState({
          userType: userType
        });
      }

      if (province) {
        this.handleProvinceChange(province, undefined);
        dispatch(change(FORM_NAME, "district", district));
      } else {
        this.setState({
          districtOptionsList: []
        });
      }

      if (district) {
        this.handleDistrictChange(district, undefined);
        dispatch(change(FORM_NAME, "subDistrict", subDistrict));
      } else {
        this.setState({
          subDistrictOptionsList: []
        });
      }

      dispatch(memberActions.getActiveMemberLevelList());
    }
  }

  handleSubmit = values => {
    const { dispatch } = this.props;
    let gender = lodash.find(Gender, function(o) {
      return o.value === values.gender || o.value === "N";
    });
    var reqBody = {
      phoneNumber: values.phoneNumber,
      email: values.email,
      password: values.password,
      passwordConfirmation: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      idCardNumber: values.idCardNumber,
      gender: gender.label,
      sex: values.gender,
      addressLine1: values.addressLine1,
      zipcode: values.zipcode,
      province: values.province,
      district: values.district,
      subDistrict: values.subDistrict,
      externalId: values.externalId,
      _id: values._id,
      verificationStatus: values.verificationStatus,
      userType: values.userType
    };

    if (values.birthday) {
      reqBody.birthday = plugins.datetimeFormat(values.birthday, "mongo");
    } else {
      reqBody.birthday = "";
    }

    if (values.ageRange) {
      reqBody.ageRange = values.ageRange;
    }

    if (values.tagList) {
      reqBody.tagList = values.tagList;
    }

    if (values.type) {
      reqBody.type = values.type;
    }    

    if (values.misce) {
      reqBody.misce = values.misce;
    }  

    if (values.memberLevel && values.memberLevel.length > 0) {
      reqBody.memberLevel = values.memberLevel;
    }

    if (values.verificationStatus) {
      reqBody.verificationStatus = values.verificationStatus;
    } else {
      reqBody.verificationStatus = "pending"
    }

    if (this.props.formMode === "add") {
      dispatch(memberActions.addNewMember(reqBody));
    } else {
      dispatch(memberActions.editMember(reqBody));
    }
  };
  idCardLength(value) {
    if (value) {
      if (value.length > 13) {
        return value.substr(0, 13);
      } else {
        return value;
      }
    }
  }

  formatSelectDropdown(value) {
    return value.map(data => {
      return {
        label: data,
        value: data
      };
    });
  }

  handleProvinceChange = (province, zipcode) => {
    const { dispatch } = this.props;

    // if (province) {
    //     dispatch(change(FORM_NAME, 'province', province))

    //     let districtList = address.getDistrict(province, zipcode)
    //     let DistrictInProvince = address.getDistrict(province)
    //     let optionsList = this.formatSelectDropdown(DistrictInProvince)
    //     this.setState({
    //         districtOptionsList: optionsList
    //     })

    //     if (lodash.size(districtList) > 0) {
    //         dispatch(change(FORM_NAME, 'district', districtList[0]))
    //         this.handleDistrictChange(districtList[0], zipcode)
    //     }
    //     if (!zipcode) {
    //         dispatch(change(FORM_NAME, 'zipcode', ''))
    //         dispatch(change(FORM_NAME, 'district', ''))
    //         dispatch(change(FORM_NAME, 'subDistrict', ''))
    //         this.setState({
    //             subDistrictOptionsList: []
    //         })
    //     }
    // } else {
    //     this.setState({
    //         districtOptionsList: [],
    //         subDistrictOptionsList: []
    //     })
    // }

    if (province) {
      this.setState({
        province: province
      });
      let districtList = [];
      let DistrictInProvince = [];
      if (!zipcode) {
        districtList = address.getDistrictNoZipcode(province);
        DistrictInProvince = address.getDistrictNoZipcode(province);
      } else {
        districtList = address.getDistrict(province, zipcode);
        DistrictInProvince = address.getDistrict(province, zipcode);
      }
      let optionsList = this.formatSelectDropdown(DistrictInProvince);
      this.setState({
        districtOptionsList: optionsList,
        disabledDistrictSelected: false
      });
      if (lodash.size(districtList) > 0) {
        dispatch(change(FORM_NAME, "district", districtList[0]));
        this.handleDistrictChange(districtList[0], zipcode);
      }
      dispatch(change(FORM_NAME, "province", province));
    } else {
      this.setState({
        districtOptionsList: [],
        subDistrictOptionsList: [],
        disabledDistrictSelected: true,
        disabledSubDistrictSelected: true
      });
      dispatch(change(FORM_NAME, "province", ""));
    }
  };

  handleDistrictChange = (district, zipcode) => {
    const { dispatch } = this.props;
    if (district) {
      this.setState({
        district: district
      });
      let subDistrictList = [];
      let subDistrictInDistrict = [];
      if (!zipcode) {
        subDistrictList = address.getSubDistrictNoZipcode(district);
        subDistrictInDistrict = address.getSubDistrictNoZipcode(district);
      } else {
        subDistrictList = address.getSubDistrict(district, zipcode);
        subDistrictInDistrict = address.getSubDistrict(district, zipcode);
      }
      let optionsList = this.formatSelectDropdown(subDistrictInDistrict);
      this.setState({
        subDistrictOptionsList: optionsList,
        disabledSubDistrictSelected: false
      });
      if (lodash.size(subDistrictList) === 1) {
        this.setState({
          subDistrict: subDistrictList[0],
          disabledSubDistrictSelected: true
        });
        // setFieldValue('district', district)
        dispatch(change(FORM_NAME, "district", district));
        dispatch(change(FORM_NAME, "subDistrict", subDistrictList[0]));
      } else if (lodash.size(subDistrictList) > 1) {
        this.setState({
          subDistrict: subDistrictList[0],
          disabledSubDistrictSelected: false
        });
        dispatch(change(FORM_NAME, "district", district));
        dispatch(change(FORM_NAME, "subDistrict", ""));
      }

      // OLD
      // let subDistrictList = address.getSubDistrict(district, zipcode)
      // let subDistrictInDistrict = address.getSubDistrict(district)
      // let optionsList = this.formatSelectDropdown(subDistrictInDistrict)
      // this.setState({
      //     subDistrictOptionsList: optionsList
      // })
      // if (lodash.size(subDistrictList) > 0) {
      //     dispatch(change(FORM_NAME, 'subDistrict', subDistrictList[0]))
      // }
      // if (!zipcode) {
      //     // dispatch(change(FORM_NAME, 'zipcode', ''))
      //     dispatch(change(FORM_NAME, 'subDistrict', ''))
      // }
    } else {
      this.setState({
        subDistrictOptionsList: [],
        disabledSubDistrictSelected: true
      });
      dispatch(change(FORM_NAME, "subDistrict", ""));
      dispatch(change(FORM_NAME, "district", ""));
    }
  };

  handleSubDistrictChange = subDistrict => {
    const { dispatch } = this.props;
    // const { province, district } = values

    // OLD
    // let zipcode = address.getZipcode(province, district, subDistrict)
    // if (zipcode) {
    //     // dispatch(change(FORM_NAME, 'zipcode', zipcode))
    // }

    dispatch(change(FORM_NAME, "subDistrict", subDistrict));
    this.setState({
      subDistrict: subDistrict
    });
  };

  render() {
    const {
      handleSubmit,
      classes,
      formOpen = false,
      formMode,
      dispatch,
      formTaglist,
      formMemberLevellist,
      merchantSetting
    } = this.props;

    let { readOnly } = this.props;
    if (formMode === `add`) {
      readOnly = false;
    } else if (formMode === `edit` && !readOnly) {
      readOnly = false;
    } else {
      readOnly = true;
    }

    let ageRangeMode = false;
    if (lodash.has(merchantSetting, "ageRangeMember")) {
      if (merchantSetting.ageRangeMember === true) {
        ageRangeMode = true;
      }
    }

    return (
      <>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={formOpen}
          keepMounted
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          fullWidth={true}
          maxWidth="md"
          scroll="body"
          disableRestoreFocus={true}
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>
              <b>
                {formMode === "add"
                  ? i18n.t("label.add_member")
                  : readOnly === true
                  ? i18n.t("label.member_info")
                  : i18n.t("label.edit_member")}
              </b>
            </h4>
          </DialogTitle>

          <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
            <DialogContent
              id="member-form-dialog"
              className={classes.modalBody}
              style={{ minHeight: "420px" }}
            >
              <GridContainer>
                <GridItem xs={12} sm={6} md={5}>
                  <Field
                    name="firstName"
                    component={CustomInput}
                    type="text"
                    labelText={i18n.t("label.name")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={5}>
                  <Field
                    name="lastName"
                    component={CustomInput}
                    type="text"
                    labelText={i18n.t("label.last_name")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={5}>
                  <Field
                    name="externalId"
                    component={CustomInput}
                    type="text"
                    labelText={i18n.t("label.external_id")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={5}>
                  <Field
                    name="idCardNumber"
                    component={CustomInput}
                    type="text"
                    labelText={i18n.t("label.id_card")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    normalize={value => {
                      return this.idCardLength(value);
                    }}
                    disabled={formMode === "edit" ? true : readOnly}
                  />
                </GridItem>
                {formMode === "edit" ? (
                  <GridItem xs={12} sm={6} md={4}>
                    <Field
                      name="displayId"
                      component={CustomInput}
                      type="text"
                      labelText={i18n.t("label.user_id")}
                      formControlProps={{
                        fullWidth: true
                      }}
                      disabled
                    />
                  </GridItem>
                ) : null}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <Field
                    name="email"
                    component={CustomInput}
                    type="email"
                    labelText={i18n.t("label.email")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Field
                    name="gender"
                    component={CustomSelect}
                    type="text"
                    labelText={i18n.t("label.gender")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  {ageRangeMode === false ? (
                    <Field
                      name="birthday"
                      component={CustomDatePicker}
                      type="text"
                      timeFormat={false}
                      labelText={i18n.t("label.birthday")}
                      isMaxDate={true}
                      maxDate={moment()}
                      formControlProps={{
                        fullWidth: true
                      }}
                      disabled={readOnly}
                    />
                  ) : (
                    <Field
                      name="ageRange"
                      component={CustomSelect}
                      type="text"
                      labelText={i18n.t("label.age_range")}
                      formControlProps={{
                        fullWidth: true
                      }}
                      optionsList={merchantSetting.ageRangeList}
                      disabled={readOnly}
                    />
                  )}
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Field
                    name="phoneNumber"
                    component={CustomInput}
                    type="tel"
                    labelText={i18n.t("label.mobile_no")}
                    maxLength={10}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                </GridItem>
              </GridContainer>

              <div style={{ marginTop: "10px" }}>
                <b>Address</b>
              </div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Field
                    name="addressLine1"
                    component={CustomInput}
                    type="text"
                    labelText={i18n.t("label.address_1")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Field
                    name="zipcode"
                    component={CustomInput}
                    type="text"
                    labelText={i18n.t("label.zip_code")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    normalize={value => {
                      return crmValidator.handleKeyPress(
                        value,
                        form_validate.zipcode
                      );
                    }}
                    onChange={e => {
                      let zipcodeValue = e.target.value;
                      e.target.value = zipcodeValue;

                      if (zipcodeValue.length >= 5) {
                        let searchAddressResult = address.getAddress(
                          zipcodeValue
                        );
                        let optionsList = this.formatSelectDropdown(
                          searchAddressResult.province
                        );
                        if (lodash.size(searchAddressResult.province) > 0) {
                          // dispatch(change(FORM_NAME, 'province', searchAddressResult.province[0]))
                          // this.handleProvinceChange(searchAddressResult.province[0], zipcodeValue)
                          // this.setState({
                          //     zipcodeDisabled: false
                          // })

                          this.setState({
                            zipcode: zipcodeValue,
                            disabledDistrictSelected: false,
                            disabledSubDistrictSelected: false
                          });

                          if (lodash.size(searchAddressResult.province) > 0) {
                            dispatch(
                              change(
                                FORM_NAME,
                                "province",
                                searchAddressResult.province[0]
                              )
                            );
                            this.setState({
                              province: searchAddressResult.province[0],
                              provinceOptionsList: optionsList
                            });
                            this.handleProvinceChange(
                              searchAddressResult.province[0],
                              zipcodeValue
                            );
                          }
                          if (lodash.size(searchAddressResult.district) === 1) {
                            this.setState({
                              district: searchAddressResult.district[0],
                              disabledDistrictSelected: true
                            });
                            this.handleDistrictChange(
                              searchAddressResult.district[0],
                              zipcodeValue
                            );
                          }
                        }
                        // if (lodash.size(searchAddressResult.district) > 0) {
                        //     dispatch(change(FORM_NAME, 'district', searchAddressResult.district[0]))
                        //     this.handleDistrictChange(searchAddressResult.district[0], zipcodeValue)
                        // }
                        dispatch(change(FORM_NAME, "zipcode", e.target.value));
                      } else {
                        dispatch(change(FORM_NAME, "province", ""));
                        dispatch(change(FORM_NAME, "district", ""));
                        dispatch(change(FORM_NAME, "subDistrict", ""));
                        dispatch(change(FORM_NAME, "zipcode", e.target.value));
                        this.setState({
                          provinceOptionsList: [],
                          districtOptionsList: [],
                          subDistrictOptionsList: [],
                          disabledDistrictSelected: true,
                          disabledSubDistrictSelected: true
                        });
                      }
                    }}
                    disabled={readOnly}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Field
                    name="province"
                    component={CustomSelect}
                    type="text"
                    labelText={i18n.t("label.province")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    optionsList={this.state.provinceOptionsList}
                    onChange={e => {
                      if (this.state.zipcode) {
                        this.handleProvinceChange(
                          e.target.value,
                          this.state.zipcode
                        );
                      } else {
                        this.handleProvinceChange(e.target.value, "");
                      }
                    }}
                    disabled={readOnly}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Field
                    name="district"
                    component={CustomSelect}
                    type="text"
                    labelText={i18n.t("label.district")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    optionsList={this.state.districtOptionsList}
                    onChange={e => {
                      // this.handleDistrictChange(e.target.value)
                      if (this.state.postcode) {
                        this.handleDistrictChange(
                          e.target.value,
                          this.state.postcode
                        );
                      } else {
                        this.handleDistrictChange(e.target.value, "");
                      }
                    }}
                    disabled={readOnly || this.state.disabledDistrictSelected}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Field
                    name="subDistrict"
                    component={CustomSelect}
                    type="text"
                    labelText={i18n.t("label.sub_district")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    onChange={e => {
                      this.handleSubDistrictChange(e.target.value);
                    }}
                    optionsList={this.state.subDistrictOptionsList}
                    disabled={
                      readOnly || this.state.disabledSubDistrictSelected
                    }
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Field
                    name="tagList"
                    component={TagsMultiSelect}
                    labelText={i18n.t("label.tag")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    showtick
                    optionsList={formTaglist}
                    disabled={readOnly}
                  />
                </GridItem>
              
                {/*<GridItem xs={12} sm={6} md={3}>
                  <Field
                    name="type"
                    component={CustomSelect}
                    type="text"
                    labelText={i18n.t("label.type")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                  </GridItem>*/}

                <GridItem xs={12} sm={6} md={5}>
                  <Field
                    name="type"
                    component={CustomInput}
                    type="text"
                    labelText={i18n.t("label.type")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                </GridItem> 

                <GridItem xs={12} sm={6} md={5}>
                  <Field
                    name="misce"
                    component={CustomInput}
                    type="text"
                    labelText={i18n.t("label.misce")}
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled={readOnly}
                  />
                </GridItem> 

                <GridItem xs={12} sm={12} md={12}>
                  <Field
                    name="memberLevel"
                    component={MemberLevelSelect}
                    labelText={i18n.t("label.memberLevel")}
                    //labelText="MMMMMM"
                    formControlProps={{
                      fullWidth: true
                    }}
                    showtick
                    optionsList={formMemberLevellist}
                    disabled={readOnly}
                  />
                </GridItem>

                { this.state.userType ? (       
                  <GridItem xs={12} sm={12} md={12}>
                    <Field
                      name="userType"
                      component={CustomSelect}
                      labelText={i18n.t("label.user_type")}
                      formControlProps={{
                        fullWidth: true
                      }}
                      optionsList={[{
                        "label": "Store",
                        "value": "store"
                      },{
                        "label": "Technician",
                        "value": "technician"
                      }]}
                      disabled={readOnly}
                    />
                  </GridItem>
                 ) : null } 
                
                { this.state.verificationStatus ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <Field
                      name="verificationStatus"
                      component={CustomSelect}
                      labelText={i18n.t("label.verification_status")}
                      formControlProps={{
                        fullWidth: true
                      }}
                      optionsList={[{
                        "label": "Pending",
                        "value": "pending"
                      },{
                        "label": "Approve",
                        "value": "approve"
                      },{
                        "label": "Reject",
                        "value": "reject"
                      }]}
                      disabled={readOnly}
                    />
                  </GridItem>
                ) : null } 

              </GridContainer>
              {formMode === "add" ? (
                <>
                  <div style={{ marginTop: "20px" }}>
                    <b>More information</b>
                  </div>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <Field
                        name="password"
                        component={CustomInput}
                        type="password"
                        labelText={i18n.t("label.password")}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <Field
                        name="passwordConfirmation"
                        component={CustomInput}
                        type="password"
                        labelText={i18n.t("label.confirm_password")}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </>
              ) : null}
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              {!readOnly ? (
                <Button
                  style={{ marginRight: "10px" }}
                  color="primary"
                  type="submit"
                >
                  {i18n.t("save")}
                </Button>
              ) : (
                ""
              )}
              <Button
                onClick={() => {
                  dispatch({ type: memberConstants.CLOSE_FORM_MEMBER });
                }}
                color="white"
              >
                {i18n.t("cancel")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    formOpen,
    formMode,
    formTaglist,
    formMemberLevellist,
    formInitialValue
  } = state.member;

  const { member_form } = state.form;
  return {
    formOpen,
    formMode,
    formTaglist,
    formMemberLevellist,
    member_form,
    formInitialValue
  };
}

const connectMemberForm = connect(mapStateToProps)(MemberForm);

export default reduxForm({
  form: FORM_NAME,
  validate: validate,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, props) => {
    crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
  }
})(withStyles(modalStyle)(connectMemberForm));

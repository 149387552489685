import { saleProductConstants } from '../constants';
var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableData: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false,
    saleProductData: [],
    readOnly : false

}

export function saleProduct(state = initialState, action) {
    switch (action.type) {
        case saleProductConstants.OPEN_FORM_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case saleProductConstants.OPEN_FORM_EDIT_SALEPRODUCT:
            return {
                ...state,
                formOpen: true,
                readOnly : false,
                formMode: 'edit',
                formInitialValue: action.tmp,
            };
        case saleProductConstants.CLOSE_FORM_SALEPRODUCT:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
            }
        case saleProductConstants.ADD_SALEPRODUCT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case saleProductConstants.GET_SALEPRODUCT_DATA_SUCCESS:
            return {
                ...state,
                saleProductData: action.data
            }
        case saleProductConstants.TABLE_GET_LIST_SALEPRODUCT:
            return {
                ...state,
                tableData: action.tmp,
                tableReload: false,
                tableReset: false
            }
        case saleProductConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case saleProductConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case saleProductConstants.DELETE_SALEPRODUCT_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case saleProductConstants.EDIT_SALEPRODUCT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case saleProductConstants.SET_FORM_PREVIEW_IMAGE:
                return {
                    ...state,
                }
        default:
            return state;
    }
}
import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import { Field, reduxForm, change, touch, reset } from "redux-form"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx"
// page components
import CampaignForm from "./CampaignForm.jsx";
import Badge from "components/Badge/Badge.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Button from 'components/CustomButtons/Button'

import { campaign_v2Constants, swalConstants } from '../../constants';
import { campaignActions } from 'actions';
import { listActions } from 'actions';
import { campaignServices } from "services";

import copy from 'clipboard-copy'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";

import { plugins, crmValidator , loader, alert as customAlert } from "helpers";

import i18n from 'i18n';

const FORM_NAME = "CAMPAIGN_FILTER"

const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
   },
   actionDiv: {
      display: "inline-flex"
   }
};

const moduleName = 'campaign_new';

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

const renderBadgeStatus = (status) => {
   let BadgeColor
   switch (status) {
      case "Active": BadgeColor = "success"; break;
      case "Expired": BadgeColor = "danger"; break;
      default: BadgeColor = "gray";
   }
   return (<Badge color={BadgeColor}>{status}</Badge>)
}

class TableCampaignList extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         readOnly: true,
         isLoading: false,
         page: 0,
         rowsPerPage: 10,
         count: 100,
         data: [],
         reqParams: "",
         categorySelected:[]
      };

      this.tableRef = React.createRef();
      this.delayedCallback = lodash.debounce(this.getData, 500)

   }

   componentWillMount() {
      const { dispatch } = this.props;
      dispatch({ type: campaign_v2Constants.CLEAR_PAGE_STATE })
   }

   componentDidMount() {
      const { dispatch } = this.props;
      dispatch(listActions.getCategoryList('campaign'))
      this.getData();
   }

   componentDidUpdate() {
      const { tableReload, tableReset, dispatch } = this.props;
      if (tableReload || tableReset) {
         this.getData();
         dispatch({ type: campaign_v2Constants.TABLE_RELOAD_SUCCESS })
      }
   }

   getData = (skip, limit, sortBy, sortDir, keyword) => {
      const { dispatch, tableReload } = this.props
      const { categorySelected } = this.state
      let reqParams = "";

      if (!tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            skip = 0
         }

         if (crmValidator.isEmptyValue(limit)) {
            limit = 10
         }

         this.setState({
            page: skip,
            rowsPerPage: limit
         })
         skip = parseInt(limit) * parseInt(skip)

         reqParams += '&skip=' + skip
         reqParams += '&limit=' + limit

         if (!crmValidator.isEmptyValue(sortBy)) {
            reqParams += '&sortBy=' + sortBy
         }

         if (!crmValidator.isEmptyValue(sortDir)) {
            reqParams += '&sortDir=' + sortDir
         }

         if (!crmValidator.isEmptyValue(keyword)) {
            reqParams += '&keyword=' + keyword
         }
         if(!crmValidator.isEmptyValue(categorySelected)){
            let _reqParams = ''
            for(let item of categorySelected){
               _reqParams = `${_reqParams}&category[]=${item}`
            }
            reqParams += _reqParams
         }

      } else {
         reqParams = this.state.reqParams
      }

      dispatch(campaignActions.getListsCampaignV2(reqParams));
      this.setState({
         isLoading: true,
         reqParams: reqParams
      });

   }

   handleCategorySubmit(values) {
      const { reqParams} = this.state
      const { dispatch } = this.props
      const { category } = values
      this.setState({
         categorySelected:category
      })

      let _reqParams = reqParams.split('&category[]=')['0']
      if(!crmValidator.isEmptyValue(category)){
         for(let item of category){
            _reqParams = `${_reqParams}&category[]=${item}`
         }
      }
      dispatch(campaignActions.getListsCampaignV2(_reqParams));
    }

    clearFilter = () =>{
      const { dispatch } = this.props
      dispatch(reset(FORM_NAME))
      this.setState({
         categorySelected:[]
      })
    }

   exportCSV() {
      const { dispatch } = this.props;
      let tableFilter = {
         page:0,
         rowsPerPage:0,
         skip:0,
         limit:10,
         sortBy:"created_at",
         sortDir:"desc",
      }

      let reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');

      loader(dispatch, true)
      campaignServices.downloadCampaignReport(reqParams)
      .then(response => {
         loader(dispatch, false)
      })
      .catch(e => {
         customAlert(dispatch, 'error', "api_messages.SOMETHING_WENT_WRONG")
         loader(dispatch, false)
      });
   }

   openAddCampaign = () => {
      const { dispatch } = this.props;
      dispatch(campaignActions.checkLimit())
      // dispatch({ type: campaign_v2Constants.OPEN_ADD_CAMPAIGN });
   }

   openEditCampaign = (index) => {
      const { dispatch, tableDatas } = this.props;
      const rowData = tableDatas.campaigns[index]

      dispatch(campaignActions.getCampaignByID(rowData._id));
   }

   getRowData(rowIndex = 0) {
      const { tableDatas } = this.props;
      const data = tableDatas.campaigns;
      return data[rowIndex];
   }

   displayId(val) {
      if (val) {
         return `CP${val.toString().padStart(5, '0')}`
      }
   }

   categoryIdConvertToName(_id){
      const { categoryList } = this.props
      let categoryObj = lodash.find(categoryList,{"_id":_id})
      let categoryName = lodash.get(categoryObj,'name',_id)
      return categoryObj ? categoryName : ""
   }

   copyStaticCampaignAndBuildJsonFile(campaignId) {
      const { tableDatas, dispatch } = this.props
      const staticCampaignUrl = tableDatas.staticCampaignUrl

      loader(dispatch, true)

      campaignActions.copyStaticCampaignAndBuildJsonFile(campaignId).then(response => {
         if(response.status){
            if(lodash.get(response.data, 'upload')){
               copy(`${staticCampaignUrl}/${campaignId}`)
               NotificationManager.success('Copied', 'Upload Successfully', 1000);
            }else{
               NotificationManager.error('Error', 'Upload Unsuccessfully', 1000);
            }
            loader(dispatch, false)
         }else{
            NotificationManager.error('Error', 'Upload Unsuccessfully', 1000);
            loader(dispatch, false)
         }
      })
   }
   
   render() {
      const { classes, dispatch, formInitialValue, tableDatas, tableKey, categoryList, handleSubmit } = this.props;
      const { rowsPerPage, page } = this.state;
      let columns = [
         { name: "_id", options: { display: "excluded" } },
         { name: "displayId", options: { display: "excluded" } },
         {
            label: i18n.t("label.no"),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta, updateValue) => {

                  const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                  const rowIndex = rowSatrt + tableMeta.rowIndex;

                  return rowIndex + 1;
               }
            }
         },
         {
            name: "campaignId", label: i18n.t("label.campaign_id"), options: {
               customBodyRender: (value) => {
                  return this.displayId(value)
               }
            }
         },
         { name: "priority", label: i18n.t("label.priority") },
         { name: "name", label: i18n.t("label.name") },
         { name: "categoryId", label: i18n.t("label.category"),
            options: {
               filter: false,
               customBodyRender: (value, tableMeta, updateValue) => {
                  const renderCategory = (category,index)=>{
                     let categoryName = this.categoryIdConvertToName(category)
                     return ( <div key={index} className="text-nowrap">{categoryName}</div>)
                  }
                  const isArrayValue = lodash.isArray(value)
                  return (
                        <>
                        { isArrayValue && (<div>{value.map(renderCategory)}</div>) }
                        {!isArrayValue && <span>{value}</span>}
                        </>
                  )
               } 
            }
         },
         { name: "pointBurn", label: i18n.t("label.point") },
         {
            name: "startDate", label: i18n.t("label.start_date"), options: {
               filter: false,
               customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                     <span className="text-nowrap">{plugins.datetimeFormat(value, "datetime")}</span>
                  )
               }
            }
         },
         {
            name: "endDate", label: i18n.t("label.end_date"), options: {
               filter: false,
               customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                     crmValidator.isEmptyValue(value) ? "" : <span className="text-nowrap">{plugins.datetimeFormat(value, "datetime")}</span>
                  )
               }
            }
         },
         {
            name: "status", label: i18n.t("label.status"), options: {
               filter: false,
               customBodyRender: (value, tableMeta) => {
                  const rowData = this.getRowData(tableMeta.rowIndex);
                  return renderBadgeStatus(rowData.displayStatus)
               }
            }
         }
      ];

      if (plugins.isHasPermission(moduleName, 'read')) {
         columns.push({
            label: i18n.t("label.action"), options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  const { campaignUrl, staticCampaignUrl } = this.props.tableDatas
                  return (
                     <div className={classes.actionDiv}>
                        {plugins.isHasPermission(moduleName, 'update') &&
                           <TableActionButton
                              actionType="edit"
                              onClick={(e) => {
                                 this.openEditCampaign(tableMeta.rowIndex)
                              }}
                           />
                        }
                        {plugins.isHasPermission(moduleName, 'delete') &&
                           <TableActionButton
                              actionType="delete"
                              onClick={(e) => {
                                 const rowData = this.getRowData(tableMeta.rowIndex);
                                 dispatch({
                                    type: swalConstants.SHOW, data: {
                                       type: "warning",
                                       title: i18n.t("warning_messages.are_you_sure_delete_campaign"),
                                       message: `"${rowData.name}"`,
                                       onConfirm: function () {
                                          let reqParams = {
                                             _id: rowData._id
                                          }
                                          dispatch({ type: swalConstants.HIDE })
                                          dispatch(campaignActions.deleteCampaign_V2(reqParams));
                                       },
                                       onCancel: function () {
                                          dispatch({ type: swalConstants.HIDE })
                                       },
                                       showCancel: true,
                                       confirmBtnText: i18n.t("delete"),
                                       cancelBtnText: i18n.t("cancel")
                                    }
                                 })
                              }} />
                        }
                        {campaignUrl &&
                           <Button
                              variant="contained"
                              color="primary"
                              size="sm"
                              onClick={() => {
                                 copy(`${campaignUrl}/${rowData._id}`)
                                 NotificationManager.success('Copied', '', 1000);
                              }}
                              style={{ padding: "10px", backgroundColor: "#276be6" }}
                           >
                              Copy URL
                           </Button>
                        }
                        {staticCampaignUrl &&
                           <Button
                              variant="contained"
                              color="primary"
                              size="sm"
                              onClick={() => {
                                 this.copyStaticCampaignAndBuildJsonFile(rowData._id)
                              }}
                              style={{ padding: "10px", backgroundColor: "#ff9900" }}
                           >
                              Copy Static URL
                           </Button>
                        }
                     </div>
                  )
               }
            }
         })
      }

      let options = {
         serverSide: true,
         count: tableDatas.total,
         page: page,
         rowsPerPage: rowsPerPage,
         selectableRows: "none",
         print: false,
         customToolbar: () => {

            return (
               <>
                  {plugins.isHasPermission(moduleName, 'export') &&(
                        <Tooltip title='Export CSV'>
                           <IconButton
                              id='button-campaignTable-exportCsv'
                              className=''
                              onClick={this.exportCSV.bind(this)}>
                              <CloudDownload />
                           </IconButton>
                        </Tooltip>
                  )}

                  {plugins.isHasPermission(moduleName, 'create') &&(
                     <CustomToolbar handleClick={this.openAddCampaign} title={i18n.t("label.add_new")} />
                  )}
               </>
            );
         },
         download: false,
         filter: false,
         responsive: "scroll",
         rowsPerPageOptions: [1, 5, 10, 20],
         onTableChange: (action, tableState) => {

            let skip, limit, sortBy, sortDir, keyword = ""

            if (tableState.page !== null) {
               skip = tableState.page
            }

            if (tableState.rowsPerPage !== null) {
               limit = tableState.rowsPerPage
            }

            if (tableState.activeColumn !== null) {
               sortBy = tableState.columns[tableState.activeColumn].name
            }
            if (tableState.announceText !== null) {
               sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
            }
            if (tableState.searchText !== null || action === 'search') {
               keyword = tableState.searchText
               this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
            }
            let actionList = ['changePage', 'sort', 'changeRowsPerPage']
            if (actionList.indexOf(action) !== -1) {
               this.getData(skip, limit, sortBy, sortDir, keyword)
            }
         },
      };

      // if (plugins.isHasPermission(moduleName, 'create')) {
      //    options.customToolbar = () => {
      //       return (
      //          <CustomToolbar handleClick={this.openAddCampaign} title={i18n.t("label.add_new")} />
      //       );
      //    }
      // }

      return (
         <div id="tableCampaignList">
            <CustomSweetAlert />
            <CampaignForm
               initialValues={formInitialValue}
               readOnly={!plugins.isHasPermission(moduleName, 'read')}
            />
            <div>
               <form
                  name={FORM_NAME}
                  onSubmit={handleSubmit(this.handleCategorySubmit.bind(this))}
               >
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={6} lg={6}>
                     <Field
                        id={`${FORM_NAME}_category`}
                        name="category"
                        component={TagsMultiSelect}
                        labelText={i18n.t("label.category")}
                        formControlProps={{
                           fullWidth: true,
                        }}
                        showtick
                        optionsList={categoryList}
                     />
                     </GridItem>
                     <GridItem xs={12} sm={6} md={6} lg={6}>
                     <Button
                        id={`${FORM_NAME}-button-clear`}
                        onClick={this.clearFilter}
                        style={{
                           marginTop: "25px",
                           marginRight: "10px",
                        }}
                        size="sm"
                        color="white"
                     >
                        Clear
                     </Button>
                     <Button
                        id={`${FORM_NAME}-button-submit`}
                        type="submit"
                        style={{ marginRight: "10px", marginTop: "25px" }}
                        size="sm"
                        color="primary"
                     >
                        Submit
                     </Button>
                     </GridItem>
                  </GridContainer>
               </form>
            </div>
            <MuiThemeProvider theme={THEME}>

               <MUIDataTable
                  key={tableKey}
                  title={i18n.t("campaign.campaign_list")}
                  data={tableDatas.campaigns}
                  columns={columns}
                  options={options}
               />

            </MuiThemeProvider>
            <NotificationContainer />
         </div>
      )
   }
}

function mapStateToProps(state) {
   const { formInitialValue, tableReload, tableReset, campaignData, tableDatas, tableKey } = state.campaign_v2;
   const { permission, superAdmin } = state.authentication;
   const { categoryList } = state.list
   return {
      formInitialValue, tableReload, tableReset, campaignData, tableDatas, tableKey, permission, superAdmin, categoryList
   };
}

const connectedComponent =   connect(mapStateToProps)(withStyles(pageStyle)(TableCampaignList));

export default reduxForm({
   form: FORM_NAME,
 })(withStyles(style)(connectedComponent))
export const configConstants = {
    API_BACKEND_CRM: "/api/v1",
    API_SHIPPINGS: "/api/v1",
    API_UAA: "/api/v1",
    API_WHITELIST: "/api/v1",
    API_REFERENCE_CODE: "/api/v1",
    API_BLACKLIST: "/api/v1",
    API_LUCKYDRAW: "/lucky-draw/api/v1",
    API_DATAFILTERING: "/datafiltering/api/v1",
};

import moment from "moment"
import { tmnReportConstants } from "../constants"

const initialState = {
  transactionReport: {
    tableKey: Date.now(),
    tableData: [],
    tableTotal: 0,
    tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "created_at",
      sortDir: "desc",
      startDate: moment().startOf("month"),
      endDate: moment()
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 59),
      status: "",
      campaignId: "",
      actionType: "",
      keyword: "",
    },
    campaignList: [],
  },
  summaryReward: {
    tableKey: Date.now(),
    tableData: [],
    tableTotal: 0,
    tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "created_at",
      sortDir: "desc",
      startDate: moment().startOf("month"),
      endDate: moment()
        .set("hour", 23)
        .set("minute", 59)
        .set("second", 59),
    },
    topBarTotal: {
      budget: 0,
      verify: 0,
      topup: 0,
    },
  },
}

export function tmnReport(state = initialState, action) {
  switch (action.type) {
    case tmnReportConstants.TRUEMONEY_REPORT_REDUCER_INIT:
      return initialState
    case tmnReportConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        transactionReport: {
          ...state.transactionReport,
          campaignList: action.data,
        },
      }
    case tmnReportConstants.GET_TRUEMONEY_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactionReport: {
          ...state.transactionReport,
          tableFilter: action.tableFilter,
          tableData: action.data ? action.data : [],
          tableTotal: action.total ? action.total : 0,
        },
      }
    case tmnReportConstants.TRUEMONEY_TRANSACTION_TABLE_RESET:
      return {
        ...state,
        transactionReport: {
          ...state.transactionReport,
          tableKey: Date.now(),
        },
      }
    case tmnReportConstants.GET_TRUEMONEY_TRANSACTION_REPORT_REQUEST:
      return {
        ...state,
        transactionReport: {
          ...state.transactionReport,
        },
      }
    case tmnReportConstants.CLEAR_TRUEMONEY_TRANSACTION_FILTER:
      return {
        ...state,
        transactionReport: {
          ...state.transactionReport,
          tableFilter: initialState.transactionReport.tableFilter,
        },
      }
    case tmnReportConstants.GET_TRUEMONEY_SUMMARY_BUDGET_SUCCESS:
      return {
        ...state,
        summaryReward: {
          ...state.summaryReward,
          topBarTotal: {
            budget: action.data.budget || 0,
            verify: action.data.verify || 0,
            topup: action.data.topup || 0,
          },
          tableFilter: action.tableFilter,
        },
      }
      case tmnReportConstants.CLEAR_TRUEMONEY_SUMMARY_REPORT_FILTER:
      return {
        ...state,
        summaryReward: {
          ...state.summaryReward,
          tableFilter: {
            ...initialState.summaryReward.tableFilter
          },
        },
      }

      case tmnReportConstants.GET_TRUEMONEY_SUMMARY_REWARD_LIST_SUCCESS:
      return {
        ...state,
        summaryReward: {
          ...state.summaryReward,
          tableFilter: action.tableFilter,
          tableData: action.data ? action.data : [],
          tableTotal: action.total ? action.total : 0,
        },
      }

      case tmnReportConstants.TRUEMONEY_SUMMARY_REWARD_TABLE_RESET:
        return {
          ...state,
          summaryReward: {
            ...state.summaryReward,
            tableKey: Date.now(),
          },
        }
    default:
      return state
  }
}

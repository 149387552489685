import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

import i18n from 'i18n';

function CustomInput({ ...props }) {
    const {
        input,
        classes,
        formControlProps,
        labelText,
        id,
        type,
        disabled,
        labelProps,
        startAdornment,
        white,
        inputRootCustomClasses,
        maxLength,
        min,
        max,
        hintText = false,
        placeholder,
        meta,
        autoComplete,
        multiline,
        rows
    } = props;
    let { endAdornment } = props;
    const [showPassword, setShowPassword] = React.useState(false);

    const error = (meta !== undefined && meta.touched && meta.invalid);
    const success = (meta !== undefined && meta.touched && meta.valid);
    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
        [classes.whiteUnderline]: white
    });
    const marginTop = classNames({
        [inputRootCustomClasses]: inputRootCustomClasses !== undefined
    });
    const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: white
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
        formControlClasses = classNames(
            formControlProps.className,
            classes.formControl
        );
    } else {
        formControlClasses = classes.formControl;
    }
    var helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const inputPasswordAdornment = () => {
        return (
            <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={handleClickShowPassword.bind(this)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>
        )
    }

    if (type === "password") {
        endAdornment = inputPasswordAdornment()
    }

    return (
        <FormControl {...formControlProps} className={formControlClasses}>
            {labelText !== undefined ? (
                <InputLabel
                    className={classes.labelRoot + " " + labelClasses}
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}{hintText && <small> ({hintText})</small>}
                </InputLabel>
            ) : null}
            <Input
                {...input}
                onKeyDown={(e) => {
                    if (type === "number") {
                        let invalidChars = ['e', '+', '-']
                        if (invalidChars.includes(e.key)) {
                            e.preventDefault();
                        }
                    }
                }}
                onPaste={(e) => {
                    if (type === "number") {
                        let clipboardData = e.clipboardData.getData('text/plain');
                        clipboardData = clipboardData.replace(/[e+-]/g, '');
                        e.preventDefault();
                        input.onChange(clipboardData)
                    }
                }}
                classes={{
                    input: inputClasses,
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses,
                }}
                style={{
                    width: type === "time" ? "200px": "100%"
                }}
                id={id}
                type={showPassword ? "text" : type}
                disabled={disabled}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                inputProps={{
                    maxLength: maxLength,
                    min: min,
                    max: max,
                    autoComplete: autoComplete
                }}
                placeholder={placeholder}
                multiline={multiline}
                rows={rows | 1}
            />
            {(meta !== undefined && meta.touched && meta.invalid) === true ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {i18n.t(meta.error)}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
}

CustomInput.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    white: PropTypes.bool,
    helpText: PropTypes.node,
    meta: PropTypes.object,
    multiline: PropTypes.bool,
    rows: PropTypes.number
};

export default withStyles(customInputStyle)(CustomInput);

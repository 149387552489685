export const adminConstants = {
    OPEN_FORM_ADD: 'OPEN_ADD_FORM_ADMIN',
    OPEN_FORM_EDIT: 'OPEN_EDIT_FORM_ADMIN',
    CLOSE_FORM_ADMIN: 'CLOSE_FORM_ADMIN',

    ADD_NEW_ADMIN_SUCCESS: 'ADD_NEW_ADMIN_SUCCESS',
    ADD_NEW_ADMIN_FAILURE: 'ADD_NEW_ADMIN_FAILURE',

    EDIT_ADMIN_SUCCESS: 'EDIT_ADMIN_SUCCESS',
    EDIT_ADMIN_FAILURE: 'EDIT_ADMIN_FAILURE',

    DELETE_ADMIN_REQUEST: 'DELETE_ADMIN_REQUEST',
    DELETE_ADMIN_SUCCESS: 'DELETE_ADMIN_SUCCESS',
    DELETE_ADMIN_FAILURE: 'DELETE_ADMIN_FAILURE',

    GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
    GET_STATISTICS_FAILURE: 'GET_STATISTICS_FAILURE',

    GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
    GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
    GET_ROLE_FAILURE: 'GET_ROLE_FAILURE',

    HIDE_ALERT: 'HIDE_ALERT',

    ADMIN_LIST_CLEAR_STATE: 'ADMIN_LIST_CLEAR_STATE',

    RELOAD_TABLE_ADMINLIST: 'RELOAD_TABLE_ADMINLIST',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
    TABLE_RESET: 'TABLE_RESET',
    TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',

    CLEAR_PAGE_STATE: 'ADMIN_CLEAR_PAGE_STATE',
    TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_ADMINS',

    SHOW_TABLE_LOADER: 'SHOW_ADMIN_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_ADMIN_TABLE_LOADER'
};

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, touch } from 'redux-form';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';

import { crmValidator, store, alert as customAlert } from 'helpers';

import { tmn_rewardConstants } from '../../constants';
import { tmn_rewardActions } from 'actions';

import i18n from 'i18n';

const validMimeType = "image/jpeg, image/png";
const validateRequiredImage = imageFile => {
   if (imageFile === null || imageFile === undefined || imageFile === "") {
      return "warning_messages.reward_picture_is_required";
   }
}
const NotValidateRequiredImage = imageFile => {
   return "";
}
const validateImageFormat = imageFile => {
   if (imageFile) {
      if (!validMimeType.includes(imageFile.type)) {
         return "warning_messages.only_img_file_type_is_jpg_or_png";
      }
   }
}
const validateImageSize = imageFile => {
   if (imageFile) {
      if (imageFile.size > (1048576 * 2)) {
         return "warning_messages.campaign_picture_file_size_must_be_less_than_2mb";
      }
   }
}

const FORM_NAME = "FORM_TMN_REWARD";
const form_validate = {
   "name": {
      rules: {
         required: true
      },
      messages: {
         required: "warning_messages.reward_name_is_required"
      },
      alert: "warning_messages.reward_name_is_required"
   },
   "budget": {
      rules: {
         required: true,
         number: true,
      },
      messages: {
         required: "warning_messages.reward_budget_is_required",
         number: "warning_messages.number_greater_than_zero"
      },
      alert: "warning_messages.reward_budget_is_required"
   },
};

const validate = (values) => {
   const errors = crmValidator.validateByRulesList(form_validate, values);
   return errors
}

class TopUpForm extends React.Component {
   constructor(props) {
      super(props);
      this.state = {

      }
   }

   handleSubmit = (values) => {
      const { dispatch } = this.props;
      if (this.props.formMode === "add") {
         dispatch(tmn_rewardActions.addReward(values));
      } else {
         dispatch(tmn_rewardActions.updateReward(values));
      }
   }

   render() {
      const { formOpen, formMode, classes, dispatch, handleSubmit, rewardImagePreview } = this.props;

      let { readOnly } = this.props
      if (formMode === `add`) {
         readOnly = false
      } else if (formMode === `edit` && !readOnly) {
         readOnly = false
      } else {
         readOnly = true
      }
      return (
         <Dialog
            classes={{
               root: classes.center,
               paper: classes.modal
            }}
            open={formOpen}
            keepMounted
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            fullWidth={true}
            maxWidth="sm"
            disableRestoreFocus={true}
            scroll="body">

            <DialogTitle
               disableTypography
               className={classes.modalHeader}
            >
               <h4 className={classes.modalTitle}>
                  <b>
                     {formMode === 'add' ? i18n.t("tmn_reward._add") : (readOnly ? i18n.t("tmn_reward._info") : i18n.t("tmn_reward._edit"))}
                  </b>
               </h4>
            </DialogTitle>
            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
               <DialogContent id="reward-form-dialog" className={classes.modalBody} >
                  <GridContainer>
                     <Field
                        name="rewardId"
                        component="input"
                        type="hidden"
                     />
                     <GridItem xs={12} sm={12} md={12}>
                        <Field
                           id={`${FORM_NAME}_name`}
                           name="name"
                           component={CustomInput}
                           type="text"
                           labelText={i18n.t("label.reward_name")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={12} md={12}>
                        <Field
                           id={`${FORM_NAME}_description`}
                           name="description"
                           component={CustomInput}
                           type="text"
                           labelText={i18n.t("label.reward_description")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                     <GridItem xs={12} sm={12} md={12}>
                        <Field
                           id={`${FORM_NAME}_image`}
                           name="rewardImage"
                           component={ImageUpload}
                           type="text"
                           labelText={i18n.t("label.reward_picture")}
                           textCenter={true}
                           width={(1140 / 2.5)}
                           height={(400 / 2.5)}
                           showRatioGuide={true}
                           recommendWidth={1140}
                           recommendHeight={400}
                           onChange={(e) => {
                              e.preventDefault();
                              let reader = new FileReader();
                              let file = e.target.files[0];
                              reader.onloadend = () => {
                                 dispatch({ type: tmn_rewardConstants.SET_FORM_PREVIEW_IMAGE, rewardImagePreview: reader.result })
                              };
                              try {
                                 if (!validMimeType.includes(file.type)) {
                                    dispatch(touch(FORM_NAME, 'rewardImage'));
                                 }
                                 reader.readAsDataURL(file);
                                 dispatch(change(FORM_NAME, 'rewardImage', file))
                              } catch (error) {
                                 dispatch({ type: tmn_rewardConstants.SET_FORM_PREVIEW_IMAGE, rewardImagePreview: "default" })
                                 dispatch(change(FORM_NAME, 'rewardImage', ''))
                              }
                           }}
                           imagePreviewUrl={rewardImagePreview}
                           formControlProps={{
                              fullWidth: true
                           }}
                           validate={[
                              validateImageFormat,
                              (formMode === "add" ? validateRequiredImage : NotValidateRequiredImage),
                              validateImageSize
                           ]}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={6} md={6}>
                        <Field
                           id={`${FORM_NAME}_budget`}
                           name="budget"
                           component={CustomInput}
                           type="text"
                           labelText={i18n.t("label.reward_budget")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           normalize={(value) => {
                              return crmValidator.handleKeyPress(value, form_validate["budget"])
                           }}
                           disabled={readOnly || (formMode === 'edit')}
                        />
                     </GridItem>
                  </GridContainer>
               </DialogContent>

               <DialogActions
                  className={classes.modalFooter}>
                  {!readOnly && <Button
                     id={`${FORM_NAME}_btn_submit`}
                     type="submit"
                     style={{ marginRight: "10px" }}
                     color="primary">
                     {i18n.t('submit')}
                  </Button>}
                  <Button
                     id={`${FORM_NAME}_btn_cancel`}
                     type="button"
                     onClick={() => {
                        dispatch({ type: tmn_rewardConstants.CLOSE_FORM });
                     }}
                     color="white">
                     {i18n.t('cancel')}
                  </Button>
               </DialogActions>
            </form>
         </Dialog>
      )
   }
}

function mapStateToProps(state) {
   const { formOpen, formMode, rewardImagePreview, campaignData, onEarnrule } = state.tmn_reward;
   const { FORM_TMN_REWARD } = state.form;
   return {
      formOpen, formMode, rewardImagePreview, FORM_TMN_REWARD, campaignData, onEarnrule
   };
}

const connectTopUpForm = connect(mapStateToProps)(TopUpForm);

export default reduxForm({
   form: FORM_NAME,
   validate: validate,
   enableReinitialize: true,
   onSubmitFail: (errors, dispatch, props) => {
      crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);

      let globalStore = store.getState();
      let formMeta = globalStore.form[FORM_NAME];
      if (Object.keys(errors).length === 1) {
         if (errors.rewardImage && !formMeta.values.rewardImage) {
            customAlert(dispatch, 'error', 'Reward Picture is required')
         }
      }

   }
})(withStyles(modalStyle)(connectTopUpForm))
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { authentication } from './auth.reducer';
import { alert } from './alert.reducer';
import { loader } from './loader.reducer';
import { member } from './member.reducer';
import { admin } from './admin.reducer';
import { dashboard } from './dashboard.reducer';
import { sweetAlert } from './sweetalert.reducer';
import { banner } from './banner.reducer';
import { role } from './role.reducer';
import { campaign } from './campaign.reducer';
import { category } from './category.reducer';
import { report } from './report.reducer';
import { product } from './product.reducer';
import { earnrules } from './earnrules.reducer';
import { earnactivities } from './earnactivities.reducer';
import { merchant } from './merchant.reducer';
import { upload_3rd_party } from './upload_3rd_party.reducer';
import { tag } from './tag.reducer';
import { memberLevel } from './member_level.reducer';
import { shipment } from './shipment.reducer';
import { campaign_v2 } from './campaign_v2.reducer';
import { importReducer } from './import.reducer';
import { summaryReport } from './summaryReport.reducer'
import { content_management } from './content_management.reducer'
import { tmn_reward } from './tmn_reward.reducer'
import { tmnReport } from './tmnReport.reducer'
import { tmnTopup } from './tmnTopup.reducer'
import { shop } from "./shop.reducer"
import { point } from './point.reducer'
import { masterAdmin } from './master_admin.reducer';
import { expiration } from './expiration.reducer';
import { promotion } from './promotion.reducer'
import { list } from './list.reducer'
import { whitelist } from './whitelist.reducer'
import { reference_code } from './reference_code.reducer';
import { blacklist } from './blacklist.reducer';
import { luckydraw_reward } from './luckydraw_reward.reducer';
import { period } from './period.reducer';
import { winner_approve } from './winner_approve.reducer';
import { search_code } from './search_code.reducer';
import { winner } from './winner.reducer';
import { uploadWinner } from './uploadWinner.reducer';
import { winner_config } from './winner_config.reducer';
import { df_newcondition } from './df_newcondition.reducer';
import { tableTemplateList } from './datafiltering.reducer';
import { dataFilteringTemplate } from './dataFilteringTemplate.reducer';
import { dataFilteringResult } from './datafilteringresult.reducer';
import { point_by_purchase } from './pointByPurchase.reducer';
import { point_adjustment } from './pointAdjustment.reducer';
import { business_website } from './business_website.reducer';
import { luckydrawDashboard } from './luckydrawDashboard.reducer';
import { rfmConfig } from './rfmConfig.reducer';
import { treemap } from './treemap.reducer';

import { luckyDrawReport } from './luckyDrawReport.reducer';
import { mission } from './mission.reducer';
import { ocrReport } from './ocr_report.reducer';
import { earnspecials } from './earnspecials.reducer';
import { photoGiveawayPhoto } from './photoGiveawayPhoto.reducer';
import { photoGiveawayRule } from './photoGiveawayRule.reducer';
import { order } from './order.reducer';
import { saleProduct } from './sale-product.reducer';
import { saleProductGroup } from './sale-product-group.reducer';

import { brand } from './brand.reducer';

const reducer = combineReducers({
  authentication,
  alert,
  loader,
  member,
  admin,
  dashboard,
  banner,
  role,
  campaign,
  category,
  sweetAlert,
  report,
  product,
  promotion,
  earnrules,
  earnactivities,
  expiration,
  merchant,
  upload_3rd_party,
  tag,
  memberLevel,
  shipment,
  campaign_v2,
  importReducer,
  summaryReport,
  content_management,
  tmn_reward,
  tmnReport,
  tmnTopup,
  shop,
  point,
  list,
  whitelist,
  blacklist,
  reference_code,
  luckydraw_reward,
  period,
  winner_approve,
  search_code,
  winner,
  uploadWinner,
  winner_config,
  luckydrawDashboard,
  dataFilteringTemplate,
  rfmConfig,
  treemap,
  masterAdmin,
  point_adjustment,
  df_newcondition,
  tableTemplateList,
  dataFilteringResult,
  business_website,
  luckydrawDashboard,
  luckyDrawReport,
  mission,
  ocrReport,
  point_by_purchase,
  earnspecials,
  photoGiveawayPhoto,
  photoGiveawayRule,
  brand,
  order,
  saleProduct,
  saleProductGroup,
  form: formReducer
})

export default reducer

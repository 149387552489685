import { configConstants } from '../constants';
import axios from 'axios';
import _ from 'lodash';
import { history, setHeaders } from 'helpers';

import objectToFormData from 'object-to-formdata';

export const earnSpecialsServices = {
  getEarnSpecialLists,
  getTrdPartyRewardCodeSetList,
  addEarnSpecials,
  updateEarnSpecials,
  getEarnSpecialById,
  deleteEarnSpecials,
};

const formContentType = "multipart/form-data";
const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ"

function getEarnSpecialLists(reqParams) {
  let tmp = axios.get(configConstants.API_BACKEND_CRM + `/earn-specials?${reqParams}`, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    })

  return tmp;
}

function getTrdPartyRewardCodeSetList(params) {
  let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/codeset${params.codeSetId ? '?codeSetId=' + params.codeSetId : ''}`, {
      headers: setHeaders()
  })
      .then(response => {
          if (response.status.toString() === "200") {
              return {
                  status: true,
                  data: response.data.data,
              };
          } else if (response.data.message === "UNAUTHORIZE") {
              history.push('/auth/login')
              return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
              return {
                  status: false,
                  data: {},
                  message: response.data.message
              };
          }
      });
  return tmp;
}

function addEarnSpecials(values) {
    const formData = objectToFormData(
        values
    );

    // console.log('formData :',formData);

    let tmp = axios.post(configConstants.API_BACKEND_CRM + '/earn-specials', formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' })
    }).then(response => {
          if (response.data.statusCode.toString() === "200") {
              return {
                  status: true,
                  data: response.data.data,
                  message: response.data.message
              };
          } else if (response.data.message === "UNAUTHORIZE") {
              history.push('/auth/login')
              return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
              return {
                  status: false,
                  data: {},
                  message: response.data.message
              };
          }
      });
  return tmp;
}

function updateEarnSpecials(values) {
  let specialEarnId = values._id;
  values = _.omit(values, "rewardDetail.imgPath")
  const formData = objectToFormData(
      values
  );

  let tmp = axios.put(configConstants.API_BACKEND_CRM + `/earn-specials/${specialEarnId}`, formData, {
      headers: setHeaders({ 'Content-Type': formContentType })
  })
      .then(response => {
          if (response.data.statusCode.toString() === "200") {
              return {
                  status: true,
                  data: response.data.data,
                  message: response.data.message
              };
          } else if (response.data.message === "UNAUTHORIZE") {
              history.push('/auth/login')
              return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
              return {
                  status: false,
                  data: {},
                  message: response.data.message
              };
          }
      });
  return tmp;
}

function getEarnSpecialById(id) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/earn-specials/${id}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.status.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function deleteEarnSpecials(params) {
    let tmp = axios.delete(configConstants.API_BACKEND_CRM + `/earn-specials/${params._id}/force`, { headers: setHeaders() })
        .then(response => {
        if (response.status.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return { status: false, data: {}, message: response.data.message };
        }
        });
    
    return tmp;
}
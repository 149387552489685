import { categoryConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";
var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    categoryImagePreview: defaultImage,
    tableKey: 0,
    tableData: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false,
    categoryData: [],
    readOnly : false

}

export function category(state = initialState, action) {
    switch (action.type) {
        case categoryConstants.OPEN_FORM_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
                categoryImagePreview : defaultImage,
            };
        case categoryConstants.OPEN_FORM_EDIT_CATEGORY:
            return {
                ...state,
                formOpen: true,
                readOnly : true,
                formMode: 'edit',
                formInitialValue: action.tmp,
                categoryImagePreview : action.tmp.imgPath ? action.tmp.imgPath : defaultImage
            };
        case categoryConstants.CLOSE_FORM_CATEGORY:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                categoryImagePreview: defaultImage
            }
        case categoryConstants.ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case categoryConstants.GET_CATEGORY_DATA_SUCCESS:
            return {
                ...state,
                categoryData: action.data
            }
        case categoryConstants.TABLE_GET_LIST_CATEGORY:
            return {
                ...state,
                tableData: action.tmp,
                tableReload: false,
                tableReset: false
            }
        case categoryConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case categoryConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case categoryConstants.DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case categoryConstants.EDIT_CATEGORY_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case categoryConstants.SET_FORM_PREVIEW_IMAGE:
                return {
                    ...state,
                    categoryImagePreview: action.categoryImage === "default" ? defaultImage : action.categoryImage
                }
        default:
            return state;
    }
}
import React, { useEffect, useRef } from "react";
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import TablePromotionList from "views/Promotion/TablePromotionList.jsx";
import PromotionForm from 'views/Promotion/PromotionForm.jsx';

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

function PromotionContainer({ ...props }) {
  const { formOpen, formInitialValue } = props
  const promotionContainerRef = useRef(null)
  useEffect(() => promotionContainerRef.current.scrollIntoView(), [props.formOpen]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        {formOpen &&
          <PromotionForm initialValues={formInitialValue} />
        }
        <Card style={{ display: (formOpen ? "none" : "block") }}>
          <div ref={promotionContainerRef}></div>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <TablePromotionList />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

function mapStateToProps(state) {
  const { formInitialValue, formOpen } = state.promotion;
  return {
    formInitialValue, formOpen
  };
}

export default connect(mapStateToProps)(withStyles(style)(PromotionContainer));
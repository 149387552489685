import React from 'react'
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { crmValidator, plugins, normalize } from 'helpers';
import lodash from 'lodash';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import Radio from '@material-ui/core/Radio'

import { winnerConfigActions } from 'actions';
import HelpOutline from '@material-ui/icons/HelpOutline'
// import DialogExampleWinnerConfig from './DialogExampleWinnerConfig'

const FORM_NAME = 'winner_config_setting'
const moduleName = 'luckyDraw[luckydrawWinnerConfig]'

const pageStyle = {
    ...modalStyle,
    hoverPointer: {
        "&:hover": {
            cursor: "pointer"
        }
    }
};

const muiRadio = ({ ...props }) => {
    const { input, disabled } = props
    console.log('input', input);
    return (
        <Radio
            onChange={input.onChange}
            name={input.name}
            id={input.name + "_" + input.value}
            disabled={disabled}
            {...input}
        />
    )
}

class WinnerConfigSetting extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(winnerConfigActions.getWinnerConfigSetting())
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props;
        if (values.winnerConfigId) {
            dispatch(winnerConfigActions.editWinnerConfigSetting(values))
        } else {
            dispatch(winnerConfigActions.saveWinnerConfigSetting(values))
        }
    }

    render() {
        const { classes, winner_case, handleSubmit } = this.props
        let disableUnderLimit = (winner_case !== "LIMIT")

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader>
                            <h3
                                className={classes.cardIconTitle}
                                style={{ marginLeft: "15px" }}
                            >
                                <b>Winner Config</b>
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)} style={{ paddingLeft: "50px" }}>
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="winner_case" component={muiRadio} type="radio" value="1" />
                                                <span style={{ color: "#111" }}>Case 1 : Winner ครั้งนี้ สามารถเป็น Winner ครั้งถัดไปได้</span>
                                            </label>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="winner_case" component={muiRadio} type="radio" value="2" />
                                                <span style={{ color: "#111" }}>Case 2 : Winner ครั้งนี้ สามารถเป็น Reserve ครั้งถัดไปได้</span>
                                            </label>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="winner_case" component={muiRadio} type="radio" value="3" />
                                                <span style={{ color: "#111" }}>Case 3 : Winner หรือ Reserve ครั้งนี้ สามารถเป็น Winner หรือ Reserve ครั้งถัดไปได้</span>
                                            </label>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="winner_case" component={muiRadio} type="radio" value="4" />
                                                <span style={{ color: "#111" }}>Case 4 : Winner หรือ Reserve ครั้งนี้ ไม่สามารถเป็น Winner หรือ Reserve ครั้งถัดไปได้</span>
                                            </label>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="winner_case" component={muiRadio} type="radio" value="5" />
                                                <span style={{ color: "#111" }}>Case 5 : Reserve ครั้งนี้ สามารถเป็น Reserve ครั้งถัดไปได้</span>
                                            </label>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="winner_case" component={muiRadio} type="radio" value="6" />
                                                <span style={{ color: "#111" }}>Case 6 : Reserve ครั้งนี้ สามารถเป็น Winner ครั้งถัดไปได้</span>
                                            </label>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="winner_case" component={muiRadio} type="radio" value="7" />
                                                <span style={{ color: "#111" }}>Case 7 : Reserve ครั้งนี้ สามารถเป็น Winner หรือ Reserve ครั้งถัดไปได้</span>
                                                <div style={{ paddingLeft: "50px", color: (disableUnderLimit ? "#ff3333" : "#111"), fontSize: "13px" }}>
                                                    * ถ้าได้เป็น Winner แล้ว ไม่สามารถ Upload ได้อีก
                                                </div>
                                            </label>
                                        </div>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: "50px", marginBottom: "50px" }}>

                                        {plugins.isHasPermission(moduleName, 'update') &&
                                            <Button
                                                id='btn_submit_form'
                                                type="submit"
                                                style={{ marginRight: "10px" }}
                                                color="primary">
                                                Submit
                                        </Button>
                                        }

                                        <Button
                                            id='btn_cancel_form'
                                            type="button"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => {
                                                const { dispatch } = this.props
                                                dispatch(reset(FORM_NAME));
                                                dispatch(winnerConfigActions.getWinnerConfigSetting())
                                            }}
                                            color="white">
                                            Cancel
                                        </Button>

                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
                {/* <DialogExampleWinnerConfig /> */}
            </GridContainer>
        )
    }
}

function mapStateToProps(state) {
    const { setting } = state.winner_config
    return { setting }
}

const connectedComponent = connect(mapStateToProps)(WinnerConfigSetting);

let WinnerConfigSettingForm = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors);
    }
})(withStyles(pageStyle)(connectedComponent))

const selector = formValueSelector(FORM_NAME)

WinnerConfigSettingForm = connect(state => {
    const winner_case = selector(state, 'winner_case')
    return {
        initialValues: lodash.get(state, 'winner_config.setting'),
        winner_case
    }
})(WinnerConfigSettingForm)

export default WinnerConfigSettingForm
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, arrayPush, arrayRemove } from "redux-form";
import { crmValidator } from "helpers";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import { store, alert as customAlert } from "helpers";

import { saleProductGroupActions } from "actions";

import lodash from "lodash";
import { saleProductGroupConstants } from "../../../constants";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

const FORM_NAME = "FORM_PRODUCT_GROUP";
const form_validate = {
    name: {
        rules: {
            required: true,
        },
        messages: {
            required: "Name is required",
        },
        alert: "Name is required",
    },
    subgroup: {
        rules: {
            required: true,
        },
        messages: {
            required: "Sub Groups is required",
        },
        alert: "Sub Groups is required",
    },
};
const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors;
};

class ProductGroupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productGroupName: null,
        };
    }

    handleSubmit = (values) => {
        const { dispatch , FORM_SALEPRODUCTGROUP , initialValues } = this.props;
        const subgroup = lodash.get(FORM_SALEPRODUCTGROUP, "values.subgroup", []);
        const value = {
            _id: initialValues._id,
            name: values.name,
            subgroup: subgroup,
        }

        if (this.props.formMode === "add") {
            dispatch(saleProductGroupActions.addSaleProductGroup(value))
        }
        else {
            dispatch(saleProductGroupActions.updateSaleProductGroup(value));
        }
    };

    onSetSubGroup = () => {
        const groupName = this.state.productGroupName;
        const { dispatch } = this.props;
        dispatch(arrayPush("FORM_SALEPRODUCTGROUP", "subgroup", {
            name: groupName,
        }));
    };

    onProductGroupChange = (event) => {
        this.setState({ productGroupName: event.target.value });
    };

    componentDidUpdate(prevProps) {
        const {
            formOpen,
            formMode,
            dispatch,
            initialValues
        } = this.props;
        if(formOpen === true && (prevProps.formOpen === false) && formMode === 'add'){
            this.setState({ productGroupName: null });
            dispatch(change("FORM_SALEPRODUCTGROUP", "subgroup", []));
        }
        if(formOpen === true && (prevProps.formOpen === false) && formMode === 'edit' && initialValues){
            this.setState({ productGroupName: null });
            const subGroups = lodash.get(initialValues, "subgroup", []);
            dispatch(change("FORM_SALEPRODUCTGROUP", "subgroup", subGroups));
        }
    }


    renderSubGroups() {
        const { FORM_SALEPRODUCTGROUP, classes } = this.props
        const subGroups = lodash.get(FORM_SALEPRODUCTGROUP, "values.subgroup", []);
        const _this = this;
        let template = subGroups.map((n, i) => {
            return (
                <TableRow key={n._id}>
                    <TableCell className={classes.tableCell}>{n.name}</TableCell>
                    <TableCell className={classes.tableCell}>
                        <Button
                            color={"danger"}
                            simple
                            className={classes.actionButton}
                            onClick={() => {
                                const { dispatch } = _this.props;
                                dispatch(arrayRemove("FORM_SALEPRODUCTGROUP", "subgroup", i));
                            }}>
                            <Close className={classes.Close} />
                        </Button>
                    </TableCell>
                </TableRow>
            );
        })
        return template
    }

    renderHaveNodata(cellProps) {
        const { classes } = this.props;
        let colspan = cellProps.colspan;
        if (!colspan) {
            colspan = 2
        }
        return (
            <TableRow>
                <TableCell
                    colSpan={colspan}
                    className={classes.tableCell + " " + classes.haveNoData}>
                    Have no Data
                </TableCell>
            </TableRow>
        )
    }

    render() {
        const {
            formOpen,
            formMode,
            classes,
            dispatch,
            handleSubmit,
            FORM_SALEPRODUCTGROUP
        } = this.props;
        const subGroups = lodash.get(FORM_SALEPRODUCTGROUP, "values.subgroup", []);
        let { readOnly } = this.props;
        if (formMode === `add`) {
            readOnly = false;
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false;
        } else {
            readOnly = true;
        }
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal,
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body"
            >
                <DialogTitle disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>
                            {formMode === "add"
                                ? "Add Product Group"
                                : readOnly
                                    ? "Product Group Info"
                                    : "Edit Product Group"}
                        </b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="product Group-form-dialog"
                        className={classes.modalBody}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    id="name"
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Product Group Name"
                                    disabled={readOnly}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs>
                                <Field
                                    id={`${FORM_NAME}_subGroups`}
                                    name="subGroups"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Sub Product Group Name"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    onChange={this.onProductGroupChange}
                                />
                            </GridItem>
                            <GridItem xs="auto">
                                <Button
                                    id={`${FORM_NAME}_btn_set`}
                                    type="button"
                                    style={{ marginRight: "10px" }}
                                    color="primary"
                                    onClick={this.onSetSubGroup}
                                >
                                    Set
                                </Button>
                            </GridItem>
                        </GridContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>
                                        Name
                                    </TableCell>
                                    <TableCell className={classes.tableHead}>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ border: "solid 1px #eee" }}>
                                {(lodash.size(subGroups) > 0) ?
                                    this.renderSubGroups() :
                                    this.renderHaveNodata({ colspan: 2 })
                                }
                            </TableBody>
                        </Table>
                    </DialogContent>

                    <DialogActions className={classes.modalFooter}>
                        <Button
                            id={`${FORM_NAME}_btn_submit`}
                            type="submit"
                            style={{ marginRight: "10px" }}
                            color="primary"
                        >
                            Submit
                        </Button>
                        <Button
                            id={`${FORM_NAME}_btn_cancel`}
                            type="button"
                            onClick={() => {
                                dispatch({
                                    type: saleProductGroupConstants.CLOSE_FORM_SALEPRODUCTGROUP,
                                });
                            }}
                            color="white"
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const {
        formOpen,
        formMode,
        saleProductGroupImagePreview,
        readOnly,
    } = state.saleProductGroup;
    const { formTaglist, formMemberLevellist } = state.member;
    const { FORM_SALEPRODUCTGROUP } = state.form;
    return {
        formOpen,
        formMode,
        saleProductGroupImagePreview,
        formTaglist,
        formMemberLevellist,
        FORM_SALEPRODUCTGROUP,
        readOnly,
    };
}

const connectProductGroupForm = connect(mapStateToProps)(ProductGroupForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);

        let globalStore = store.getState();
        let formMeta = globalStore.form[FORM_NAME];
        if (Object.keys(errors).length === 1) {
            if (errors.file && !formMeta.values.file) {
                customAlert(dispatch, "error", "Product Group Pic is required");
            }
        }
    },
})(withStyles(modalStyle)(connectProductGroupForm));

import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';

export const contentServices = {
    getTableContentDatas,
    deleteContentDatas,
    updateContent,
    addContent,
}
function addContent(values) {
    let formData = new FormData();
    formData.set("contentName", values.contentName);
    formData.set("status", values.status);
    formData.set("contentType", values.contentType);
    formData.set("version", values.version);
    if (values.campaignId) {
      formData.set("campaignId", values.campaignId);
    }
    if (values.priority) {
      formData.set("priority", values.priority);
    }
    if (values.versionDate) {
        formData.set("versionDate", values.versionDate);
    }   
    if (values.visibleDate) {
        formData.set("visibleDate", values.visibleDate);
    }
    formData.set("content", btoa(encodeURIComponent(values.content)));
    if(values.mobileImage){
        formData.set("mobileImage", values.mobileImage);
    }
    if(values.desktopImage){
        formData.set("desktopImage", values.desktopImage);
    } 
    let tmp = axios.post(configConstants.API_BACKEND_CRM + `/content`, formData, { headers: setHeaders({ "Content-Type": "application/x-www-form-urlencoded" }) })
      .then(response => { 
        if (response.data.statusCode.toString() === "200") {
          return { status: true, data: response.data.data, message: response.data.message };
        } else if (response.data.message === "UNAUTHORIZE") {
          history.push('/auth/login')
          return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
          return { status: false, data: {}, message: response.data.message };
        }
      }).catch(e => {
        return { status: false, data: {}, message: e.message };
      })
    return tmp;

}

function updateContent(values) { 
  let formData = new FormData();
    formData.set("contentName", values.contentName);
    formData.set("status", values.status);
    formData.set("contentType", values.contentType);
    formData.set("version", values.version);
    formData.set("mobileImageUpdated", values.mobileImageUpdated);
    formData.set("desktopImageUpdated", values.desktopImageUpdated);
    if (values.campaignId) {
      formData.set("campaignId", values.campaignId);
    }
    if (values.priority) {
      formData.set("priority", values.priority);
    }
    if (values.versionDate) {
        formData.set("versionDate", values.versionDate);
    }   
    if (values.visibleDate) {
        formData.set("visibleDate", values.visibleDate);
    }
    formData.set("content", btoa(encodeURIComponent(values.content)));
    if(values.mobileImage){
        formData.set("mobileImage", values.mobileImage);
    }
    if(values.desktopImage){
        formData.set("desktopImage", values.desktopImage);
    } 
  var tmp = axios.put(configConstants.API_SHIPPINGS + `/content/${values._id}`, formData, { headers: setHeaders() })
    .then(response => { 
        if (response.status.toString() === "200") {
          return { status: true, data: response.data, message: "UPDATE SUCCESS!" };
        } else if (response.data.message === "UNAUTHORIZE") {
          history.push('/auth/login')
          return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
          return { status: false, data: {}, message: "UPDATE UNSUCCESS!" };
        }
    })
    .catch(e => {
        return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

  return tmp;
}

function getTableContentDatas(params) {
 let tmp = axios.get(configConstants.API_BACKEND_CRM + `/content?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
       if (response.data.statusCode.toString() === "200") {
          return { status: true, data: response.data.data, message: response.data.message };
       } else if (response.data.message === "UNAUTHORIZE") {
          history.push('/auth/login')
          return { status: false, data: {}, message: response.data.message, unAlert: true };
       } else {
          return { status: false, data: {}, message: response.data.message };
       }
    })
    .catch(e => {
       return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

 return tmp;
}

function deleteContentDatas(params) {
 var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/content/${params._id}`, { headers: setHeaders({ "Content-Type": "application/x-www-form-urlencoded" }) })
    .then(response => {
       if (response.data.statusCode.toString() === "200") {
          return { status: true, data: response.data.data, message: response.data.message };
       } else if (response.data.message === "UNAUTHORIZE") {
          history.push('/auth/login')
          return { status: false, data: {}, message: response.data.message, unAlert: true };
       } else {
          return { status: false, data: {}, message: response.data.message };
       }
    });

 return tmp;
}
export const newcondition = {

    GET_TEMPLATE_CONFIG_LIST_SUCCESS: 'GET_TEMPLATE_CONFIG_LIST_SUCCESS',

    ADD_NEW_CONDITION_SUCCESS: 'ADD_NEW_CONDITION_SUCCESS',
    ADD_NEW_CONDITION_FAILURE: 'ADD_NEW_CONDITION_FAILURE',

    OPEN_FORM_TEMPLATE_NAME: 'OPEN_FORM_TEMPLATE_NAME',
    CLOSE_FORM_TEMPLATE_NAME: 'CLOSE_FORM_TEMPLATE_NAME',

}
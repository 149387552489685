import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';

export const referenceCodeServices = {
    getListsReferenceCode,
    addNewReferenceCode,
    editReferenceCode,
    deleteReferenceCode,
    getActiveCampaignsList
};


function getListsReferenceCode(params) {
    let tmp = axios.get(configConstants.API_REFERENCE_CODE + `/reference-code?${params}`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}


function addNewReferenceCode(params) {
    var tmp = axios.post(configConstants.API_REFERENCE_CODE + '/reference-code', params, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}

function editReferenceCode(params) {
    var tmp = axios.put(configConstants.API_REFERENCE_CODE + `/reference-code/${params._id}`, params, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}

function deleteReferenceCode(params) {
    let tmp = axios.delete(configConstants.API_REFERENCE_CODE + `/reference-code?refId=${params._id}`, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}

function getActiveCampaignsList(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns?rewardType=couponCode`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data.campaigns,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    }).catch(e => {
        return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

    return tmp;
}




import MerchantContainer from "views/Merchant/MerchantContainer.jsx";
import Upload3rdPartyContainer from "views/Upload3rdParty/UploadContainer.jsx";
import MasterAdmin from "views/MasterAdmin/AdminContainer.jsx";

import HowToReg from "@material-ui/icons/HowToReg";
import CloudUpload from "@material-ui/icons/CloudUpload";
import AccountBox from '@material-ui/icons/AccountBox';

const cmsRouteAdmin = [
   {
      path: "/merchant",
      name: "Merchant",
      id: "merchant",
      displayName: "Merchant",
      icon: HowToReg,
      component: MerchantContainer,
      layout: "/"
   },
   {
      path: "/uploads-reward-code",
      name: "Uploads3rdPartyRewardCode",
      id: "3rd_party_reward",
      displayName: "3rd Party Reward Code",
      icon: CloudUpload,
      component: Upload3rdPartyContainer,
      layout: "/"
   },
   {
      path: "/master-admin",
      name: "masterAdmin",
      displayName: "Admin",
      icon: AccountBox,
      component: MasterAdmin,
      layout: "/"
   },
]

export default cmsRouteAdmin;


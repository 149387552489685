import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import lodash from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import Province from "assets/data/addresses.json";
import Gender from "assets/data/genders.json";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

import i18n from "i18n";

function CustomSelect({ ...props }) {
  const {
    input,
    classes,
    formControlProps,
    labelText,
    id,
    type,
    disabled,
    labelProps,
    inputProps,
    white,
    multiple = false,
    showtick,
    novalidate,
    meta,
    outlined = false
  } = props;
  let { optionsList } = props;
  const error = meta !== undefined && meta.touched && meta.invalid;
  const success = meta !== undefined && meta.touched && meta.valid;
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error && !novalidate,
    [" " + classes.labelRootSuccess]:
      success && !error && !novalidate && !outlined
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error && !novalidate,
    [classes.underlineSuccess]: success && !error && !novalidate && !outlined,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });

  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });
  const selectItemInit = () => {
    switch (input.name) {
      case "gender":
        optionsList = [];
        return Gender.map((data, i) => {
          optionsList.push({
            label: i18n.t(`gender.${data.value}`),
            value: data.value
          });
          return (
            <MenuItem key={i} value={data.value}>
              {i18n.t(`gender.${data.value}`)}
            </MenuItem>
          );
        });
      case "province":
      case "filterProvince":
        return Province.map((dt, i) => (
          <MenuItem key={i} value={dt[0]}>
            {dt[0]}
          </MenuItem>
        ));
      default:
        if (lodash.size(optionsList) > 0) {
          return optionsList.map((data, i) => {
            if (lodash.get(data, "disabled")) {
              return (
                <MenuItem key={i} disabled value="" id={data.id}>
                  {showtick && (
                    <Checkbox
                      style={{ paddingLeft: "5px" }}
                      checked={input.value.indexOf(data.value) > -1}
                      disabled
                    />
                  )}
                  {data.label}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem key={i} value={data.value} id={data.id}>
                  {showtick && (
                    <Checkbox
                      style={{ paddingLeft: "5px" }}
                      checked={input.value.indexOf(data.value) > -1}
                    />
                  )}
                  {data.label}
                </MenuItem>
              );
            }
          });
        } else {
          return (
            <MenuItem value="" disabled>
              <em>ไม่มีข้อมูล</em>
            </MenuItem>
          );
        }
    }
  };
  return (
    <>
      <FormControl
        {...formControlProps}
        className={
          (outlined
            ? classes.selectFormControlNoUnderLine
            : classes.selectFormControl) +
          " " +
          underlineClasses
        }
      >
        {labelText !== "" ? (
          <InputLabel
            className={classes.selectLabel + " " + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Select
          id={id}
          {...input}
          value={multiple ? (input.value ? input.value : []) : input.value}
          renderValue={selected => {
            if (multiple) {
              let displayValue = [];
              selected.map(selectedValue => {
                let foundData = lodash.find(optionsList, {
                  value: selectedValue
                });
                return displayValue.push(lodash.get(foundData, "label"));
              });
              return displayValue.join(", ");
            } else if (
              input.name === "filterProvince" ||
              input.name === "province"
            ) {
              return selected;
            } else {
              let foundData = lodash.find(optionsList, { value: selected });
              return lodash.get(foundData, "label");
            }
          }}
          multiple={multiple}
          classes={{
            select: disabled ? classes.disable : classes.select
          }}
          MenuProps={{
            className: classes.selectMenu
          }}
          type={type}
          disabled={disabled}
          style={
            outlined && disabled
              ? {
                  backgroundColor: "#ddd"
                }
              : {}
          }
          {...inputProps}
        >
          {selectItemInit()}
        </Select>
        {outlined === false &&
        (meta !== undefined && meta.touched && meta.invalid) === true ? (
          <FormHelperText id={id + "-text"} className={helpTextClasses}>
            {i18n.t(meta.error)}
          </FormHelperText>
        ) : null}
      </FormControl>
      {outlined &&
      (meta !== undefined && meta.touched && meta.invalid) === true ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {i18n.t(meta.error)}
        </FormHelperText>
      ) : null}
    </>
  );
}

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node
};

export default withStyles(styles)(CustomSelect);

export const photoGiveawayRuleConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_PAGE_STATE',
    OPEN_ADD_NEW_RULES: 'OPEN_ADD_NEW_RULES',
    OPEN_EDIT_RULES: 'OPEN_EDIT_RULES',
    CLOSE_FORM_RULES: 'CLOSE_FORM_RULES',
    ADD_RULES_SUCCESS: 'ADD_RULES_SUCCESS',
    EDIT_RULES_SUCCESS: 'EDIT_RULES_SUCCESS',
    DELETE_RULES_SUCCESS: 'DELETE_RULES_SUCCESS',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    GET_RULES_DATA_SUCCESS: 'GET_RULES_DATA_SUCCESS',
    RULES_FORM_PHOTO_LIST_LOADER: 'RULES_FORM_PHOTO_LIST_LOADER',
    GET_PHOTO_LIST_SUCCESS: 'GET_PHOTO_LIST_SUCCESS',
    
    RULE_TYPECODE_DESELECTED_PHOTO_ITEM: 'RULE_TYPECODE_DESELECTED_PHOTO_ITEM',
    ON_RULE_TYPE_CHANGE_TO_PHOTO: 'ON_RULE_TYPE_CHANGE_TO_PHOTO',
    RULE_TYPECODE_SELECTED_MANY_PHOTO_ITEM: 'RULE_TYPECODE_SELECTED_MANY_PHOTO_ITEM',
    RULE_TYPECODE_SELECTED_PHOTO_ITEM: 'RULE_TYPECODE_SELECTED_PHOTO_ITEM',

    SHOW_TABLE_LOADER: 'SHOW_RULE_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_RULE_TABLE_LOADER',

    RULE_ADD_JOINED_PHOTO: 'EARN_RULE_ADD_JOINED_PHOTO',
    RULE_ADD_JOINED_PHOTO_ROW: 'EARN_RULE_ADD_JOINED_PHOTO_ROW',
    RULE_DELETE_JOINED_PHOTO: 'EARN_RULE_DELETE_JOINED_PHOTO',
    RULE_ADD_SINGLE_PHOTO: 'EARN_RULE_ADD_SINGLE_PHOTO',
};
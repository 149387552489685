import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import lodash from 'lodash'

import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";

import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from '@material-ui/icons/GetApp';

import FileSaver from 'file-saver';

const CustomMTablestyles = {
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MUIDataTableHeadCell: {
            fixedHeader: {
                "&:nth-child(1)": {
                    width: "30%",
                },
            }
        },
        MUIDataTableToolbar: {
            titleText: {
                textAlign: "left"
            }
        }
    }
}
const THEME = createMuiTheme(CustomMTablestyles);

class ImportResultDetail extends React.Component {

    closeDialog() {
        const { dispatch } = this.props;
        dispatch({ type: 'CLOSE_IMPORT_DETAIL_DIALOG' });
    }

    getRowData(rowIndex = 0){
        const { importDetail: { failedList } } = this.props;
        const data = failedList;
        return data[rowIndex];
    }

    renderRemark (remark, rowIndex, importType) {
        let rowData = this.getRowData(rowIndex)
        let failedCase = lodash.get(rowData, 'case')

        switch(remark){
            case 'DUPLICATED': {
                if(importType === 'create_product'){
                    return failedCase
                } else if(importType === 'member'){
                    return failedCase
                }else{
                    return "Duplicated Transaction"
                }
            }
            case 'TAG_NOT_MATCH': {
                return <p><b>Failed : </b> <span>{failedCase}</span></p>
            }
            case 'MEMBER_LEVEL_NOT_MATCH': {
                return <p><b>Failed : </b> <span>{failedCase}</span></p>
            }
            case 'USER_NOT_FOUND': return <p><b>Failed : </b> <span>Member is not found</span></p>
            case 'REQUIRED_FIELD': {
                if(failedCase){
                    return <p><b>Failed :</b> <span>{failedCase}</span></p>
                }else{
                    return <p><b>Failed : </b> <span>Required Field</span></p>
                }
            } 
            case 'INCORRECT_FORMAT':{
                if(failedCase){
                    return <p><b>Failed :</b> <span>{failedCase}</span></p>
                }else{
                    return <p><b>Failed : </b> <span>Incorrect Format</span></p>
                }
            }
            case 'CANNOT_VOID_POINT_EXPIRED': {
                return <p><b>Failed :</b> <span>CANNOT_VOID_POINT_EXPIRED</span></p>
            }
            case 'TRANSACTION_NOTFOUND': return <p><b>Failed : </b> <span>Transaction ID is not found</span></p>
            case 'CATEGORY_IS_NOT_MATCH': return <p><b>Failed : </b> <span>{failedCase}</span></p>
            default: return <p><b>Failed : </b> <span>Incorrect Format</span></p>
        }
    }

    renderRemarkExport (remark, failedCase, importType) {
        switch(remark){
            case 'DUPLICATED': {
                if(importType === 'create_product'){
                    return failedCase
                } else if(importType === 'member'){
                    return failedCase
                }else{
                    return "Duplicated Transaction"
                }
            }
            case 'TAG_NOT_MATCH': {
                return `Failed : ${failedCase}`
            }
            case 'MEMBER_LEVEL_NOT_MATCH': {
                return `Failed : ${failedCase}`
            }
            case 'USER_NOT_FOUND': return `Failed : Member is not found`
            case 'REQUIRED_FIELD': {
                if(failedCase){
                    return `Failed : ${failedCase}`
                }else{
                    return `Failed : Required Field`
                }
            } 
            case 'INCORRECT_FORMAT':{
                if(failedCase){
                    return `Failed : ${failedCase}`
                }else{
                    return `Failed : Incorrect Format`
                }
            }
            case 'CANNOT_VOID_POINT_EXPIRED': {
                return `Failed : CANNOT_VOID_POINT_EXPIRED`
            }
            case 'TRANSACTION_NOTFOUND': return `Failed : Transaction ID is not found`
            case 'CATEGORY_IS_NOT_MATCH': return `Failed : ${failedCase}`
            default: return `Failed : Incorrect Format`
        }
    }

    getResultDetailHeader = (importType) =>{
        if(importType === 'create_product'){
            return 'Result Import File to Add Product'
        } else if(importType === 'member'){
            return 'Result Import File Create/Update Member'
        }else{
            return 'Result Import File Purchase Transaction'
        }
    }

    genExportImportResultFile = () =>{
        const { importDetail } = this.props;
        const resultFilePath = lodash.get(importDetail, 'resultFilePath') ? lodash.get(importDetail, 'resultFilePath') : ""
        FileSaver.saveAs(resultFilePath, 'Result Import File Create/Update Member.xlsx');
    }

    render() {
        const { importDetail, classes } = this.props;
        const importType = lodash.get(importDetail, 'type')
        let showTableFailed = false
        let options = {
            selectableRows: "none",
            viewColumns: false,
            print: false,
            filter: false,
            download: false,
            search: false,
            pagination: true,
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 10, 20, 100]
        };

        if(lodash.size(importDetail.failedList) > 0 ){
            showTableFailed = true
            const resultFilePath = lodash.get(importDetail, 'resultFilePath') ? lodash.get(importDetail, 'resultFilePath') : ""
            // Export Failed List
            if(importType === 'member' && resultFilePath){
                options.customToolbar = () => {
                    return (
                        <>
                            <IconButton
                                id='button-import-result-detail-export-excel'
                                className=''
                                onClick={this.genExportImportResultFile.bind(this)}
                            >
                                <GetAppIcon />
                            </IconButton>
                        </>
                    );
                }
            }
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={importDetail.open}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-result-import-member-tag"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b id="txt_import_detail_header">{this.getResultDetailHeader(importType)}</b>
                    </h4>

                </DialogTitle>
                <DialogContent
                    id="import-result-detail-dialog"
                    className={classes.modalBody}
                    style={{ paddingTop: "0", paddingBottom: "10px" }}
                >
                    <table style={{ width: "100%", fontSize: "14.5px", marginBottom: "20px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}><span>Total Import</span></th>
                                <th style={{ width: "25%" }}><span style={{ color: "#4caf50" }}>Success</span></th>
                                <th style={{ width: "25%" }}><span style={{ color: "#f44336" }}>Failed</span></th>
                                <th style={{ width: "25%" }}><span style={{ color: "#ff9800" }}>Duplicated</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td id="number_import_total">{importDetail.total}</td>
                                <td id="number_import_success">{importDetail.success}</td>
                                <td id="number_import_failed">{importDetail.failed}</td>
                                <td id="number_import_duplicated">{importDetail.duplicated}</td>
                            </tr>
                        </tbody>
                    </table>
                    {showTableFailed &&
                        <>
                            <hr />
                            <MuiThemeProvider theme={THEME}>
                                <div id="table_import_detail_result">
                                <MUIDataTable
                                    // key={tableKey}
                                    title={"Failed List"}
                                    data={lodash.get(importDetail, 'failedList')}
                                    columns={[
                                        {
                                            name: "rowNo",
                                            label: "Row",
                                            options: {
                                                filter: true,
                                                sort: false,
                                            },
                                        },
                                        {
                                            name: "remark",
                                            label: "Status",
                                            options: {
                                                filter: true,
                                                sort: false,
                                                customBodyRender: (value, tableMeta) => {
                                                    let rowIndex = tableMeta.rowIndex
                                                    return this.renderRemark(value, rowIndex, lodash.get(importDetail, 'type'))
                                                }
                                            }
                                        }
                                    ]}
                                    options={options}
                                />
                                </div>
                            </MuiThemeProvider>
                        </>
                    }
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter}>
                    <Button
                        style={{ marginRight: "10px", minWidth: "100px" }}
                        color="primary"
                        id="btn_close_import_detail"
                        onClick={() => {
                            this.closeDialog()
                        }}
                        type="submit">
                        OK
                            </Button>
                </DialogActions>

            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { importDetail } = state.importReducer;
    return {
        importDetail
    };
}
const connectedComponent = connect(mapStateToProps)(ImportResultDetail);

export default withStyles(sweetAlertStyle)(connectedComponent);

// import { configConstants } from '../constants';
// import axios from 'axios';
// import lodash from 'lodash';

// import {
//     history,
//     crmValidator
// } from 'helpers';

// export const winnerServices = {
//     getCampaignsList,
//     getWinnerList,
//     addWinner,
//     editWinner,
//     deleteWinner,
//     saveUploadWinner,
//     uploadWinner,
//     getAnnounceDateList,
//     // importMember
// };

// function getCampaignsList(params) {
//     let req_headers = {
//         'Content-Type': 'application/json',
//         'Charset': 'utf-8',
//         'Authorization': localStorage.getItem('token')
//     }
//     let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns?rewardType=QRCoupon`, {
//         headers: req_headers
//     })
//         .then(response => {
//             if (response.data.statusCode.toString() === "200") {
//                 return {
//                     status: true,
//                     data: response.data.data,
//                     message: response.data.message
//                 };
//             } else if (response.data.message === "UNAUTHORIZE") {
//                 history.push('/auth/login')
//                 return { status: false, data: {}, message: response.data.message, unAlert: true };
//             } else {
//                 return {
//                     status: false,
//                     data: {},
//                     message: response.data.message
//                 };
//             }
//         })
//         .catch(e => {
//             return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
//         });

//     return tmp;
// }

// function getAnnounceDateList(params) {
//     let req_headers = {
//         'Content-Type': 'application/json',
//         'Charset': 'utf-8',
//         'Authorization': localStorage.getItem('token')
//     }

//     var tmp = axios.post(configConstants.API_LUCKYDRAW + '/period/search', params, { headers: req_headers })
//         .then(response => {
//             if (response.data.statusCode.toString() === "200") {
//                 return {
//                     status: true,
//                     data: response.data.data,
//                     message: response.data.message
//                 };
//             } else if (response.data.message === "UNAUTHORIZE") {
//                 history.push('/auth/login')
//                 return { status: false, data: {}, message: response.data.message, unAlert: true };
//             } else {
//                 return {
//                     status: false,
//                     data: {},
//                     message: response.data.message
//                 };
//             }
//         })
//         .catch(e => {
//             return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
//         });

//     return tmp;
// }

// function getWinnerList(params) {
//     var req_headers = {
//         "Content-Type": "application/json",
//         "Authorization": localStorage.getItem('token')
//     }
//     var tmp = axios.post(configConstants.API_BLACKLIST + '/blacklist/search', params, { headers: req_headers })
//         .then(response => {
//             if (response.data.statusCode.toString() === "200") {
//                 return {
//                     status: true,
//                     data: response.data.data,
//                     message: response.data.message
//                 };
//             } else if (response.data.message === "UNAUTHORIZE") {
//                 history.push('/auth/login')
//                 return { status: false, data: {}, message: response.data.message, unAlert: true };
//             } else {
//                 return {
//                     status: false,
//                     data: {},
//                     message: response.data.message
//                 };
//             }
//         });

//     return tmp;
// }

// function addWinner(params) {
//     //console.log('add whitelist aaaaaaa',configConstants.API_WHITELIST);
//     var req_headers = {
//         "Content-Type": "application/json",
//         "Authorization": localStorage.getItem('token')
//     }
//     var tmp = axios.post(configConstants.API_BLACKLIST + '/blacklist', params, {
//         headers: req_headers
//     })
//         .then(response => {
//             if (response.data.statusCode.toString() === "200") {
//                 return {
//                     status: true,
//                     data: response.data.data,
//                     message: response.data.message
//                 };
//             } else if (response.data.message === "UNAUTHORIZE") {
//                 history.push('/auth/login')
//                 return { status: false, data: {}, message: response.data.message, unAlert: true };
//             } else {
//                 return {
//                     status: false,
//                     data: {},
//                     message: response.data.message
//                 };
//             }
//         });

//     return tmp;
// }

// function editWinner(params) {
//     var req_headers = {
//         "Content-Type": "application/json",
//         "Authorization": localStorage.getItem('token')
//     }
//     var tmp = axios.put(configConstants.API_BLACKLIST + `/blacklist/${params._id}`, params, {
//         headers: req_headers
//     })
//         .then(response => {
//             if (response.data.statusCode.toString() === "200") {
//                 return {
//                     status: true,
//                     data: response.data.data,
//                     message: response.data.message
//                 };
//             } else if (response.data.message === "UNAUTHORIZE") {
//                 history.push('/auth/login')
//                 return { status: false, data: {}, message: response.data.message, unAlert: true };
//             } else {
//                 return {
//                     status: false,
//                     data: {},
//                     message: response.data.message
//                 };
//             }
//         });

//     return tmp;
// }

// function deleteWinner(params) {
//     let req_headers = {
//         "Content-Type": "application/json",
//         "Authorization": localStorage.getItem('token')
//     }
//     let tmp = axios.delete(configConstants.API_BLACKLIST + `/blacklist/${params._id}`, {
//         headers: req_headers
//     })
//         .then(response => {
//             if (response.data.statusCode.toString() === "200") {
//                 return {
//                     status: true,
//                     data: response.data.data,
//                     message: response.data.message
//                 };
//             } else if (response.data.message === "UNAUTHORIZE") {
//                 history.push('/auth/login')
//                 return { status: false, data: {}, message: response.data.message, unAlert: true };
//             } else {
//                 return {
//                     status: false,
//                     data: {},
//                     message: response.data.message
//                 };
//             }
//         });

//     return tmp;
// }

// function uploadWinner(params) {
//     try {
//         var req_headers = {
//             'Content-Type': 'application/json',
//             'Charset': 'utf-8',
//             'Authorization': localStorage.getItem('token')
//         }

//         let formData = new FormData();

//         if (!crmValidator.isEmptyValue(params)) {
//             lodash.forEach(params, function (value, key) {
//                 if (key === "file") {
//                     formData.append('file', value[0]);
//                 } else {
//                     formData.append(key, value)
//                 }
//             })
//         }

//         var tmp = axios.post(configConstants.API_BLACKLIST + '/blacklist/checkfileupload', formData, {
//             headers: req_headers
//         })
//             .then(response => {
//                 if (response.data.statusCode.toString() === "200") {
//                     return {
//                         status: true,
//                         data: response.data.data,
//                         message: response.data.message
//                     };
//                 } else if (response.data.message === "UNAUTHORIZE") {
//                     history.push('/auth/login')
//                     return { status: false, data: {}, message: response.data.message, unAlert: true };
//                 } else {
//                     return {
//                         status: false,
//                         data: response.data,
//                         message: response.data.message
//                     };
//                 }
//             })
//             .catch(error => {
//                 return {
//                     status: false,
//                     data: {
//                         statusCode: 500
//                     },
//                     message: 'File not found, Please select file and try again.'
//                 };
//             })
//         return tmp;
//     } catch (e) {
//         return {
//             status: false,
//             data: {
//                 statusCode: 500
//             },
//             message: 'File not found, Please select file and try again.'
//         };
//     }
// }

// function saveUploadWinner(params) {
//     try {
//         var req_headers = {
//             'Content-Type': 'application/json',
//             'Charset': 'utf-8',
//             'Authorization': localStorage.getItem('token')
//         }

//         const req = {
//             data: params
//         }

//         var tmp = axios.post(configConstants.API_BLACKLIST + '/blacklist/savefileupload', req, {
//             headers: req_headers
//         })
//             .then(response => {
//                 //console.log(response)
//                 if (response.data.statusCode.toString() === "200") {
//                     return {
//                         status: true,
//                         data: response.data.data,
//                         message: response.data.message
//                     };
//                 } else if (response.data.message === "UNAUTHORIZE") {
//                     history.push('/auth/login')
//                     return { status: false, data: {}, message: response.data.message, unAlert: true };
//                 } else {
//                     return {
//                         status: false,
//                         data: response.data,
//                         message: response.data.message
//                     };
//                 }
//             })
//             .catch(error => {
//                 return {
//                     status: false,
//                     data: {
//                         statusCode: 500
//                     },
//                     message: 'File not found, Please select file and try again.'
//                 };
//             })
//         return tmp;
//     } catch (e) {
//         return {
//             status: false,
//             data: {
//                 statusCode: 500
//             },
//             message: 'File not found, Please select file and try again.'
//         };
//     }
// }

/////////

import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import lodash from 'lodash';

export const winnerServices = {
    // editShipment,
    getListsWinner,
    // getaddresslabelDetail,
    getCampaignsList,
    getReferenceList,
    getAnnounceDateList,
    getRewardList,
    exportWinner
};

// function editShipment(params) {
//     var req_headers = {
//         "Content-Type": "application/json",
//         "Authorization": localStorage.getItem('token'),
//     }
//     params.address.address = btoa(encodeURIComponent(lodash.get(params, 'address.address')))
//     var tmp = axios.put(configConstants.API_SHIPPINGS + `/shippings/${params.shippingId}/update`, params, { headers: req_headers })
//         .then(response => {
//             if (response.status.toString() === "200") {
//                 return { status: true, data: response.data, message: "UPDATE SUCCESS!" };
//             } else if (response.data.message === "UNAUTHORIZE") {
//                 history.push('/auth/login')
//                 return { status: false, data: {}, message: response.data.message, unAlert: true };
//             } else {
//                 return { status: false, data: {}, message: "UPDATE UNSUCCESS!" };
//             }
//         })
//         .catch(e => {
//             return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
//         });

//     return tmp;
// }

function getListsWinner(params) {
    var tmp = axios.post(configConstants.API_LUCKYDRAW + '/winner/search', params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        // var tmp = axios.get(configConstants.API_QRCOUPON + `/report-coupon-used/search?${params}`, { headers: req_headers })
        .then(response => {
            console.log('getLists response', response);
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

// function getaddresslabelDetail(params) {
//     var req_headers = {
//         // "Authorization": "Basic amFzcGVyYWRtaW46amFzcGVyYWRtaW4="
//         Authorization: "Basic bWV0YXNfdW1zOjEyMzRxd2Vy"
//     }

//     let mock = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&')
//     axios({
//         url: '/rest_v2/reports/reports/ShippingAddress.pdf?' + mock,
//         method: 'GET',
//         responseType: 'blob', // important,
//         headers: req_headers

//     }).then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', 'address label.pdf');
//         document.body.appendChild(link);
//         link.click();
//     });
// }

function getCampaignsList(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns?rewardType=couponCode`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getReferenceList(params) {
    let tmp = axios.get(configConstants.API_REFERENEC_CODE + `/reference-code?${params}`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            // console.log('response service getReferenceList', response);
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getAnnounceDateList() {
    let tmp = axios.get(configConstants.API_LUCKYDRAW + `/list/announce_date`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                //console.log(response.data.data)
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getRewardList(params) {
    let tmp = axios.get(configConstants.API_LUCKYDRAW + `/list/reward-name`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        // let tmp = axios.get(configConstants.API_REFERENEC_CODE + `/reference-code?${params}`, {
        //     headers: req_headers
        // })
        .then(response => {
            // .log('response service getAnnounceDateList', response);
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function exportWinner(params) {
    var tmp = axios.post(configConstants.API_LUCKYDRAW + '/winner/search-export', params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                const urlDownload = lodash.get(response.data, "data.url")
                if (urlDownload) {
                    window.location.assign(urlDownload)
                }
                return { status: true, data: {}, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}
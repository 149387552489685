import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import ColorSelect from "components/CustomSelect/ColorSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

// import { tagConstants } from '../../constants'
import { periodConstants } from '../../constants'
// import { tagAction } from 'actions';
import { periodAction } from 'actions';

import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import moment from 'moment';


const FORM_NAME = 'PERIOD_FORM'
const form_validate = {
    // "name": {
    //     rules: {
    //         required: true,
    //         noSpecialCharacters: true,
    //         maxLength: 50
    //     },
    //     messages: {
    //         required: 'กรุณากรอก',
    //         noSpecialCharacters: 'ไม่สามารถใช้อักขระพิเศษในชื่อ Period ได้',
    //         maxLength: 'ไม่สามารถใช้ชื่อ Period ได้มากกว่า 50 ตัวอักษร'
    //     }
    // },
    "announceDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "round": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "rewardId": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "winners": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "reserves": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },

}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const normalizePeriodMaxLength = (value) => {
    return value.substr(0, 50);
}

class PeriodForm extends React.Component {
    handleSubmit = (values) => {
        const { dispatch } = this.props
        var reqParams = {
            "announceDate": values.announceDate,
            "round": values.round,
            "rewardId": values.rewardId,
            "winners": values.winners,
            "reserves": values.reserves
        }
        // winners , reserves

        console.log('reqParams form add', reqParams);

        if (this.props.formMode === "add") {
            dispatch(periodAction.addPeriod(reqParams));
        } else {
            reqParams.periodId = values.periodId
            dispatch(periodAction.editPeriod(reqParams));
        }

    }
    render() {
        const { classes, formOpen, formMode, handleSubmit, PERIOD_FORM, initialValues, periodContainer } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        const validate_field = {
            "numberOnly": {
                rules: {
                    number: true,
                    noSpecialCharacters: true,
                }
            }
        }


        let startDateMinDate = new Date().setHours(0, 0, 0, 0)
        if (initialValues.startDate) {
            startDateMinDate = moment(initialValues.startDate)
        }

        let endDateMinDate = startDateMinDate;
        if (lodash.has(PERIOD_FORM, 'values.startDate')) {
            if (PERIOD_FORM.values.startDate) {
                endDateMinDate = moment(PERIOD_FORM.values.startDate)
            }
        }

        let rewardAllList = [];

        if (lodash.has(periodContainer, 'rewardsList')) {
            lodash.forEach(periodContainer.rewardsList, function (value, key) {
                rewardAllList.push({
                    value: value.rewardId,
                    label: value.name_en + ' / ' + value.name_th,
                    id: value.rewardId,
                })
            })
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-period-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>{formMode === 'add' ? 'Add Period' : (readOnly === true ? 'Period Info' : 'Edit Period')}</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="period-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            
                            <GridItem xs={8} sm={8} md={8}>
                                {formMode === "edit" &&
                                    <Field
                                        name="periodId"
                                        component="input"
                                        type="hidden"
                                        disabled={true}
                                    />
                                }
                                <Field
                                    name="announceDate"
                                    component={CustomDatePicker}
                                    type="text"
                                    //maxDate={startMaxDate}
                                    labelText="Announce Date/Time"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm:ss"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4}>
                                <Field
                                    name="round"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Round"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                                    }}
                                // normalize={normalizePeriodMaxLength}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="rewardId"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Reward Name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={rewardAllList}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <Field
                                    name="winners"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Winners"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                                    }}
                                // normalize={normalizePeriodMaxLength}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <Field
                                    name="reserves"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Reserves"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={(value) => {
                                        return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                                    }}
                                // normalize={normalizePeriodMaxLength}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>

                        {readOnly === false &&
                            <Button
                                style={{ marginRight: "10px" }}
                                color="primary"
                                type="submit">
                                Save
                            </Button>
                        }

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: periodConstants.CLOSE_FORM })
                        }}>
                            Cancel
                        </Button>

                    </DialogActions>

                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode, periodContainer } = state.period;
    const { PERIOD_FORM } = state.form
    return {
        formOpen, formMode, periodContainer, PERIOD_FORM
    };
}

const connectedComponent = connect(mapStateToProps)(PeriodForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))
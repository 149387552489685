import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import { Field, change, reduxForm } from "redux-form";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import CardHeader from "components/Card/CardHeader.jsx";
import { memberLevelConstants } from "../../constants";
import { memberLevelAction } from "actions";

import i18n from "i18n";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Paper from "@material-ui/core/Paper";
import FastForwardIcon from "@material-ui/icons/FastForward";
import List from "@material-ui/core/List";
import InputAdornment from "@material-ui/core/InputAdornment";
import { alert as customAlert } from "helpers";
import Search from "@material-ui/icons/Search";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import ConditionForm from "./ConditionForm";
import { plugins } from 'helpers';

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  actionDiv: {
    display: "inline-flex",
  },
  containerDiv: {
    padding: "15px",
  },
  cardTitleDiv: {
    marginLeft: "0px",
    marginBottom: "0px",
    marginTop: "0px",
    fontSize: "17px",
  },
  formRankingGrid: {
    paddingLeft: "40px",
    paddingTop: "40px",
    paddingRight: "40px",
    justifyContent: "center",
  },
  paperConstainer: {
    height: "420px",
  },
  leftPaper: {
    paddingLeft: "40px",
    paddingRight: "30px",
    paddingTop: "20px",
    height: "60px",
  },
  boxDiv: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  selectDiv: {
    paddingLeft: "28px",
    paddingRight: "28px",
  },
  listDiv: {
    border: "solid 1px #ccc",
    padding: "0px 0px",
    height: "230px",
  },
  list: {
    maxHeight: "100%",
    overflow: "auto",
  },
  btnLevel: {
    width: "100%",
    backgroundColor: "rgba(177,177,177)",
    marginTop: "-2px",
    marginLeft: "0px",
    borderRadius: "10",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  rightContainer: {
    height: "420px",
    paddingLeft: "5px",
  },
  droppable: {
    paddingLeft: "28px",
    paddingRight: "28px",
    fontSize: "large",
    borderRadius: "20px",
  },
  arrowDiv: {
    marginLeft: "40px",
    marginTop: "150px",
  },
  topArrow: {
    width: "0px",
    height: "0px",
    borderBottom: "10px solid black",
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderTop: "none",
  },
  lineArrow: {
    marginLeft: "7px",
    width: "2px",
    height: "150px",
    backgroundColor: "black",
  },
  bottomArrow: {
    width: "0px",
    height: "0px",
    borderTop: "10px solid black",
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderBottom: "none",
  },
};

const FORM_NAME = "FORM_RANKING";
const moduleName = 'member_level[automatic_shifting_level]';

class AutoLevel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDrag: "",
      dropped: ""
    };
    this.onDragStart = this.onDragStart.bind(this);
    this.over = this.over.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(memberLevelAction.getApiListMemberlevel());
    dispatch(memberLevelAction.getRankingData());
  }

  // componentDidMount() {
  //   this.setRankingLevel()
  // }

  componentDidUpdate(prevProps) {
    if (this.props.isReset && !prevProps.isReset) {
      this.setRankingLevel()
      this.handleReset();
    }
  }

  setRankingLevel = () => {
    const { dispatch, rankingList } = this.props;
    dispatch(change(FORM_NAME, "accessLevelLists", lodash.isEmpty(rankingList) ? [] : JSON.parse(JSON.stringify(rankingList))))
  }

  handleReset = () => {
    const { dispatch } = this.props;
    dispatch({ type: memberLevelConstants.RESET_RANK });
  }

  onDragStart = (event, level) => {
    if (level._id) {
      const { FORM_RANKING } = this.props;

      let accessLevelLists = lodash.get(FORM_RANKING, 'values.accessLevelLists') || []
      let location = lodash.findIndex(accessLevelLists, ["_id", level._id]);

      this.setState({
        startDrag: location,
      });
    }
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDrop = (event, cat) => {
    const { FORM_RANKING, dispatch } = this.props;
    let started = this.state.startDrag;
    let dropped = this.state.dropped;
    let tempRank = lodash.get(FORM_RANKING, 'values.accessLevelLists')

    if (started !== dropped) {
      let tempTarget = tempRank[started];
      lodash.remove(tempRank, { _id: tempTarget._id });
      tempRank.splice(dropped, 0, tempTarget);
      dispatch(change(FORM_NAME, "accessLevelLists", tempRank))
    }
  };

  over(ev) {
    if (!lodash.isUndefined(ev.target.value)) {
      const { FORM_RANKING } = this.props;

      let accessLevelLists = lodash.get(FORM_RANKING, 'values.accessLevelLists') || []
      let location = lodash.findIndex(accessLevelLists, ["_id", ev.target.value]);
      this.setState({
        dropped: location,
      });
    }
  }

  selectLevel = (id, name, color) => {
    const { FORM_RANKING, dispatch } = this.props;

    let accessLevelLists = lodash.get(FORM_RANKING, 'values.accessLevelLists') || []
    accessLevelLists.push({ _id: id, name: name, color: color })

    dispatch(change(FORM_NAME, "accessLevelLists", accessLevelLists))
  };

  removeLevel = (id) => {
    const { FORM_RANKING, dispatch } = this.props;

    let accessLevelLists = lodash.get(FORM_RANKING, 'values.accessLevelLists')
    accessLevelLists = lodash.filter(accessLevelLists, function (obj) {
      return obj._id !== id
    })

    dispatch(change(FORM_NAME, "accessLevelLists", accessLevelLists))
  };

  selectAll = (e) => {
    const { memberLevelList, FORM_RANKING, dispatch } = this.props;

    let keyword = lodash.get(FORM_RANKING, 'values.search_left_box')

    let accessLevelLists = lodash.get(FORM_RANKING, 'values.accessLevelLists') || []

    let renderMemberLevelLists = lodash.filter(memberLevelList, function (obj) {
      return lodash.findIndex(accessLevelLists, function (o) { return o._id === obj._id; }) === -1;
    });

    if (keyword) {
      renderMemberLevelLists = lodash.filter(renderMemberLevelLists, function (obj) {
        keyword = keyword.toLowerCase();
        let levelName = obj.name.toLowerCase();
        return RegExp(keyword).test(levelName)
      });
    }

    accessLevelLists = accessLevelLists.concat(renderMemberLevelLists);

    dispatch(change(FORM_NAME, "accessLevelLists", accessLevelLists))
  };

  removeAll = (e) => {
    const { dispatch, FORM_RANKING } = this.props;
    let keyword = lodash.get(FORM_RANKING, 'values.search_right_box')

    if (keyword) {
      let accessLevelLists = lodash.get(FORM_RANKING, 'values.accessLevelLists') || []
      accessLevelLists = lodash.filter(accessLevelLists, function (obj) {
        keyword = keyword.toLowerCase();
        let levelName = obj.name.toLowerCase();
        return !RegExp(keyword).test(levelName)
      });

      dispatch(change(FORM_NAME, "accessLevelLists", accessLevelLists))
    } else {
      dispatch(change(FORM_NAME, "accessLevelLists", []))
    }
  };

  setName(name) {
    let set = name;
    if (name.length >= 35) {
      let res = set.substring(1, 16);
      res += res + "...";
      set = res;
    }
    return set;
  }

  renderLevelList = () => {
    const { memberLevelList, FORM_RANKING } = this.props;
    const displayRankingList = lodash.get(FORM_RANKING, 'values.accessLevelLists') || []

    let keyword = lodash.get(FORM_RANKING, 'values.search_left_box')
    let renderMemberLevelLists = lodash.filter(memberLevelList, function (obj) {
      return lodash.findIndex(displayRankingList, function (o) { return o._id === obj._id; }) === -1;
    });

    if (keyword) {
      renderMemberLevelLists = lodash.filter(renderMemberLevelLists, function (obj) {
        keyword = keyword.toLowerCase();
        let levelName = obj.name.toLowerCase();
        return RegExp(keyword).test(levelName)
      });
    }

    let levelListComponent = [];
    renderMemberLevelLists.forEach((level, i) => {
      levelListComponent.push(
        <div key={i} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            bcolor="primary"
            size="lg"
            onClick={() => {
              plugins.isHasPermission(moduleName, 'update') === true && this.selectLevel(level._id, level.name, level.color);
            }}
            style={{
              backgroundColor: level.color,
              marginBottom: "5px",
              paddingTop: "8px",
              paddingBottom: "8px",
              borderRadius: "20px",
              height: "28px",
              width: "200px",
            }}
          >
            {this.setName(level.name)}
          </Button>
        </div>
      );
    });
    return levelListComponent;
  }

  renderRankingList = () => {
    const { FORM_RANKING } = this.props;

    let keyword = lodash.get(FORM_RANKING, 'values.search_right_box')
    let displayRankingList = lodash.get(FORM_RANKING, 'values.accessLevelLists') || []

    if (keyword) {
      displayRankingList = lodash.filter(displayRankingList, function (obj) {
        keyword = keyword.toLowerCase();
        let levelName = obj.name.toLowerCase();
        return RegExp(keyword).test(levelName)
      });
    }

    let rankingListComponent = [];
    displayRankingList.forEach((level, i) => {
      rankingListComponent.push(
        <div key={i} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            bcolor="primary"
            size="lg"
            value={level._id}
            draggable={ plugins.isHasPermission(moduleName, 'update')}
            onDragStart={
              (event) => this.onDragStart(event, level)}
            onClick={() => {
              plugins.isHasPermission(moduleName, 'update') === true && this.removeLevel(level._id, level.name, level.color);
            }}
            style={{
              backgroundColor: level.color,
              marginBottom: "5px",
              paddingTop: "8px",
              paddingBottom: "8px",
              borderRadius: "20px",
              height: "28px",
              width: "200px",
            }}
          >
            {this.setName(level.name)}
          </Button>
        </div>
      );
    });
    return rankingListComponent;
  };

  handleRankingSubmit = () => {
    const { dispatch, FORM_RANKING } = this.props;
    let rankingList = lodash.get(FORM_RANKING, 'values.accessLevelLists')

    dispatch({ type: memberLevelConstants.SUBMIT_RANKING, data: JSON.parse(JSON.stringify(rankingList)) });
    customAlert(dispatch, "success", i18n.t("member_level.ranking_submit"));
  };

  handleClearRank = () => {
    const { dispatch, rankingList } = this.props;
    dispatch(change(FORM_NAME, "accessLevelLists", lodash.isEmpty(rankingList) ? [] : JSON.parse(JSON.stringify(rankingList)) ))
  };

  render() {
    const { formConditionInitialValue, FORM_RANKING, classes } = this.props;
    const accessLevelLists = lodash.get(FORM_RANKING, 'values.accessLevelLists') || []

    return (
      <div style={style.containerDiv}>
        <CardHeader icon>
          <div className={classes.cardIconTitle} style={style.cardTitleDiv}>
            <b>Member Level Ranking</b>
          </div>
        </CardHeader>
        <form name={FORM_NAME}>
          <GridContainer style={style.formRankingGrid}>
            <GridItem xs={12} sm={4} md={5} lg={5}>
              <GridContainer style={style.paperConstainer} justify="flex-end">
                <Paper>
                  <div style={style.leftPaper}>
                    <center>
                      <font size="3">
                        <b>
                          Select Member Level to the right box to manage
                          &nbsp;&nbsp; <br />
                          level ranking
                        </b>
                      </font>
                    </center>
                  </div>
                  <div style={style.boxDiv}>
                    <Field
                      id="search_left_box"
                      name="search_left_box"
                      component={CustomInput}
                      placeholder="Search"
                      type="text"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      autoComplete="off"
                      startAdornment={
                        <InputAdornment position="start">
                          <Search style={{ color: "#ccc" }} />
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div className="select" style={style.selectDiv}>
                    <div style={style.listDiv}>
                      <List style={style.list}>{this.renderLevelList()}</List>
                    </div>

                    <Button
                      id="select_all_button"
                      onClick={(value) => {
                        plugins.isHasPermission(moduleName, 'update') === true && this.selectAll(value);
                      }}
                      style={style.btnLevel}
                    >
                      <FastForwardIcon />
                    </Button>
                  </div>
                </Paper>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={5} md={5} lg={5}>
              <GridContainer style={style.rightContainer}>
                <Paper>
                  <div style={style.leftPaper}>
                    <center>
                      <font size="3">
                        <b>Prioritise level ranking by drag and drop </b>
                        <font size="2.8">
                          <br />
                          (Highest level is on the top and Lowest level is on
                          the bottom)
                        </font>
                      </font>
                    </center>
                  </div>
                  <div style={style.boxDiv}>
                    <Field
                      id="search_right_box"
                      name="search_right_box"
                      component={CustomInput}
                      placeholder="Search"
                      type="text"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      autoComplete="off"
                      startAdornment={
                        <InputAdornment position="start">
                          <Search style={{ color: "#ccc" }} />
                        </InputAdornment>
                      }
                    />
                  </div>
                  <div>
                    <div
                      className="droppable"
                      style={style.droppable}
                      onDragOver={(event) => this.onDragOver(event)}
                      onDragEnter={(event) => this.over(event)}
                      onDrop={(event) => this.onDrop(event, "Done")}
                    >
                      <div style={style.listDiv}>
                        <List style={style.list}>
                          {this.renderRankingList()}
                        </List>
                      </div>
                      <Button
                        id="button-earnreport-clear"
                        onClick={(value) => {
                          plugins.isHasPermission(moduleName, 'update') === true && this.removeAll(value);
                        }}
                        style={style.btnLevel}
                      >
                        <FastRewindIcon />
                      </Button>
                    </div>
                  </div>
                </Paper>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={2} md={2} lg={2}>
              <GridContainer style={style.rightContainer}>
                <div style={style.leftPaper}>
                  <div justify="center" style={style.arrowDiv}>
                    <font size="3">Highest</font>
                    <div style={{ marginLeft: "10px" }}>
                      <div style={style.topArrow} />
                      <div style={style.lineArrow} />
                      <div style={style.bottomArrow} />
                    </div>
                    <font size="3">Lowest</font>
                  </div>
                </div>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              {
                plugins.isHasPermission(moduleName, 'update') === true &&
              
              <GridContainer justify="center">
                <Button
                  id="clear"
                  onClick={this.handleClearRank}
                  style={{
                    marginTop: "25px",
                  }}
                  color="white"
                >
                  Cancel
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button
                  onClick={this.handleRankingSubmit}
                  style={{
                    marginTop: "25px",
                  }}
                  id="memberLevel-ranking-submit"
                  color="primary"
                  disabled={accessLevelLists.length < 2}
                >
                  Submit
                </Button>
              </GridContainer>
  }
            </GridItem>
          </GridContainer>
        </form>
        <div>
          <div
            style={{
              border: "0.7px dashed rgb(208, 200, 187)",
              padding: "0px 20px",
              color: "black",
              marginTop: "65px",
            }}
          />
        </div>

        <div style={{ paddingTop: "35px" }}>
          <CardHeader icon>
            <div className={classes.cardIconTitle} style={style.cardTitleDiv}>
              <b>Shifting Member Level</b>
            </div>
          </CardHeader>
          <ConditionForm initialValues={formConditionInitialValue} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    memberLevelList,
    showLoaderRanking,
    onSubmitRank,
    showLoader,
    formConditionInitialValue,
    rankingList,
    isReset
  } = state.memberLevel;

  const { FORM_RANKING } = state.form;
  return {
    memberLevelList,
    onSubmitRank,
    rankingList,
    showLoaderRanking,
    showLoader,
    formConditionInitialValue,
    isReset,
    FORM_RANKING
  };
}

const connectLevelForm = connect(mapStateToProps)(AutoLevel);

export default reduxForm({
  form: FORM_NAME,
})(withStyles(style)(connectLevelForm));

import { configConstants } from '../constants';
import axios from 'axios';
import _ from 'lodash';
import { history, setHeaders } from 'helpers';

export const earnactivitiesServices = {
  getEarnActivityLists,
  addNewEarnActivity,
  editEarnActivity,
  deleteEarnActivity,
  downloadEarnActivitiesReport
};

function getEarnActivityLists(reqParams) {
  let tmp = axios.get(configConstants.API_BACKEND_CRM + `/earn-activity?${reqParams}`, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      //  history.push('/auth/login')
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    })

  return tmp;
}

function addNewEarnActivity(params) {
  let tmp = axios.post(configConstants.API_BACKEND_CRM + `/earn-activity`, params, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: e.message };
    })
  return tmp;
}

function editEarnActivity(params) {
  let tmp = axios.put(configConstants.API_BACKEND_CRM + `/earn-activity/${params._id}`, params, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: e.message };
    })
  return tmp;
}

function deleteEarnActivity(params) {
  let tmp = axios.delete(configConstants.API_BACKEND_CRM + `/earn-activity/${params._id}`, { headers: setHeaders() })
    .then(response => {
      if (response.status.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    });

  return tmp;
}

function downloadEarnActivitiesReport(reqParams) {
  let tmp = axios.get(configConstants.API_BACKEND_CRM + `/earn-activity/export/?${reqParams}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        const urlDownload = _.get(response.data, "data.url")
        if(urlDownload){
           window.location.assign(urlDownload)
        }
        return { status: true, data: {}, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    })

  return tmp;
}
import React from "react";
import { connect } from 'react-redux';
import { Field, getFormValues, change, untouch } from 'redux-form';
import lodash from 'lodash';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import { plugins } from 'helpers';

import { createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import red from '@material-ui/core/colors/red';
import * as FormValidator from '../../Promotion/validate/FormValidator';

const FORM_NAME = "promotion_form";
const moduleName = 'promotion'

const style = {
    overrides: {
        MuiTableRow: {
            root: {
                height: "30px"
            }
        }
    },
    tdBordered: {
        border: "solid 1px #ddd",
        height: "30px"
    }
}

const THEME = createMuiTheme(style);

const ConditionDateTime = ({ ...props }) => {
    const { formValues, dispatch } = props
    let readOnly = plugins.isHasPermission(moduleName, 'update') ? false : true
    return (
        <div style={{ padding: "0px 20px" }}>
            <GridContainer style={{ marginTop: '-15px' }}>
                <GridItem xs={1} sm={1} md={1}>
                    <Field
                        id='checkbox-date-time'
                        name="customCheckboxDate"
                        notLabel={true}
                        component={CustomCheckbox}
                        disabled={readOnly}
                        labelText={<span style={{ fontWeight: '300' }}>Day</span>}
                        onChange={(e) => {
                            if (lodash.get(formValues, 'customCheckboxDate') === true) {
                                dispatch(change(FORM_NAME, 'customRadioParticipatedDay', 'everyday'))
                            } else {
                                dispatch(change(FORM_NAME, 'date', []))
                                dispatch(untouch(FORM_NAME, 'date'))
                                dispatch(change(FORM_NAME, 'selectedDate', {}))
                                dispatch(untouch(FORM_NAME, 'selectedDate'))
                                dispatch(change(FORM_NAME, 'customRadioSelectedDay', 'dayOfWeek'))
                            }
                        }}
                    />
                </GridItem>
            </GridContainer>
            {lodash.get(formValues, 'customCheckboxDate') === true ?
                <div style={{ marginLeft: '35px' }}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Field
                                id='radio-participated-day'
                                name="customRadioParticipatedDay"
                                component={CustomRadio}
                                radioLists={[
                                    {
                                        value: "everyday",
                                        label: <span style={{ fontWeight: '300' }}>Every Day</span>,
                                        id: 'radio-participated-day-everyday',
                                        disabled: readOnly
                                    },
                                    {
                                        value: "specific",
                                        label: <span><span style={{ fontWeight: '300' }}>Selected Day </span><span style={{ fontSize: '17px' }}>▾</span></span>,
                                        id: 'radio-participated-day-specific',
                                        disabled: readOnly
                                    }
                                ]}
                                onChange={(e) => {
                                    if (lodash.get(formValues, 'customRadioParticipatedDay') === 'specific') {
                                        dispatch(change(FORM_NAME, 'customRadioSelectedDay', 'dayOfWeek'))
                                    }
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    {lodash.get(formValues, 'customRadioParticipatedDay') === 'specific' ? <>
                        <GridContainer style={{ marginLeft: '15px' }}>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id='radio-selected-day'
                                    name="customRadioSelectedDay"
                                    component={CustomRadio}
                                    radioLists={[
                                        {
                                            value: "dayOfWeek",
                                            label: <span><span style={{ fontWeight: '300' }}>Day of Week </span><span style={{ fontSize: '17px' }}>▾</span></span>,
                                            id: 'radio-selected-day-day-of-week',
                                            disabled: readOnly
                                        }
                                    ]}
                                    onChange={(e) => {
                                        if (lodash.get(e, 'target.value') === 'dayOfWeek') {
                                            dispatch(change(FORM_NAME, 'date', []))
                                            dispatch(untouch(FORM_NAME, 'date'))
                                        } else {
                                            dispatch(change(FORM_NAME, 'selectedDate', {}))
                                            dispatch(untouch(FORM_NAME, 'selectedDate'))
                                        }
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        {lodash.get(formValues, 'customRadioSelectedDay') === 'dayOfWeek' ?
                            <GridContainer style={{ marginLeft: '65px' }}>
                                <Field
                                    id='checkbox-monday'
                                    name="selectedDate[mon]"
                                    component={CustomCheckbox}
                                    disabled={readOnly}
                                    labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Mon</span>}
                                    validate={FormValidator.validateSelectedDate}
                                />
                                <Field
                                    id='checkbox-tuesday'
                                    name="selectedDate[tue]"
                                    component={CustomCheckbox}
                                    disabled={readOnly}
                                    labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Tue</span>}
                                    validate={FormValidator.validateSelectedDate}
                                />
                                <Field
                                    id='checkbox-wednesday'
                                    name="selectedDate[wed]"
                                    component={CustomCheckbox}
                                    disabled={readOnly}
                                    labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Wed</span>}
                                    validate={FormValidator.validateSelectedDate}
                                />
                                <Field
                                    id='checkbox-thursday'
                                    name="selectedDate[thu]"
                                    component={CustomCheckbox}
                                    disabled={readOnly}
                                    labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Thu</span>}
                                    validate={FormValidator.validateSelectedDate}
                                />
                                <Field
                                    id='checkbox-friday'
                                    name="selectedDate[fri]"
                                    component={CustomCheckbox}
                                    disabled={readOnly}
                                    labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Fri</span>}
                                    validate={FormValidator.validateSelectedDate}
                                />
                                <Field
                                    id='checkbox-saturday'
                                    name="selectedDate[sat]"
                                    component={CustomCheckbox}
                                    disabled={readOnly}
                                    labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Sat</span>}
                                    validate={FormValidator.validateSelectedDate}
                                />
                                <Field
                                    id='checkbox-sunday'
                                    name="selectedDate[sun]"
                                    component={CustomCheckbox}
                                    disabled={readOnly}
                                    labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Sun</span>}
                                    validate={FormValidator.validateSelectedDate}
                                />
                            </GridContainer>
                            : null}
                        <GridContainer style={{ marginLeft: '15px' }}>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    id='radio-selected-day'
                                    name="customRadioSelectedDay"
                                    component={CustomRadio}
                                    radioLists={[
                                        {
                                            value: "selectedDate",
                                            label: <span style={{ fontWeight: '300' }}>Selected Date</span>,
                                            id: 'radio-selected-day-selected-date',
                                            disabled: readOnly
                                        },
                                    ]}
                                    onChange={(e) => {
                                        if (lodash.get(e, 'target.value') === 'dayOfWeek') {
                                            dispatch(change(FORM_NAME, 'date', []))
                                            dispatch(untouch(FORM_NAME, 'date'))
                                        } else {
                                            dispatch(change(FORM_NAME, 'selectedDate', {}))
                                            dispatch(untouch(FORM_NAME, 'selectedDate'))
                                        }
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer style={{ marginLeft: '15px' }}>
                            <GridItem xs={4} sm={4} md={4} style={{ marginTop: '-60px', marginLeft: '140px', width: '70px' }}>
                                <Field
                                    id='tags-multi-select-date'
                                    name="date"
                                    component={TagsMultiSelect}
                                    labelText="Date"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    showtick
                                    optionsList={[
                                        { _id: 1, name: 1 }, { _id: 2, name: 2 }, { _id: 3, name: 3 }, { _id: 4, name: 4 }, { _id: 5, name: 5 }, { _id: 6, name: 6 }, { _id: 7, name: 7 }, { _id: 8, name: 8 }, { _id: 9, name: 9 }, { _id: 10, name: 10 },
                                        { _id: 11, name: 11 }, { _id: 12, name: 12 }, { _id: 13, name: 13 }, { _id: 14, name: 14 }, { _id: 15, name: 15 }, { _id: 16, name: 16 }, { _id: 17, name: 17 }, { _id: 18, name: 18 }, { _id: 19, name: 19 }, { _id: 20, name: 20 },
                                        { _id: 21, name: 21 }, { _id: 22, name: 22 }, { _id: 23, name: 23 }, { _id: 24, name: 24 }, { _id: 25, name: 25 }, { _id: 26, name: 26 }, { _id: 27, name: 27 }, { _id: 28, name: 28 }, { _id: 29, name: 29 }, { _id: 30, name: 30 }, { _id: 31, name: 31 }
                                    ]}
                                    disabled={readOnly || !(lodash.get(formValues, 'customRadioSelectedDay') === 'selectedDate')}
                                    validate={lodash.get(formValues, 'customRadioSelectedDay') === 'selectedDate' ? [FormValidator.validateDateSelect] : null}
                                />
                            </GridItem>
                        </GridContainer>

                    </>
                        : null}
                </div>
                : null}
            <GridContainer style={{ marginTop: '-15px' }} >
                <GridItem xs={12} sm={12} md={12}>
                    <Field
                        id='checkbox-specific-time'
                        name="customCheckboxSpecificTime"
                        notLabel={true}
                        component={CustomCheckbox}
                        disabled={readOnly}
                        labelText={<span style={{ fontWeight: '300' }}>Specific Time Period Promotion</span>}
                        onChange={(e) => {
                            if (lodash.get(formValues, 'customCheckboxSpecificTime') === true) {
                                dispatch(change(FORM_NAME, 'startTime', null))
                                dispatch(untouch(FORM_NAME, 'startTime'))

                                dispatch(change(FORM_NAME, 'endTime', null))
                                dispatch(untouch(FORM_NAME, 'endTime'))
                            }
                        }}
                    />
                </GridItem>
                {lodash.get(formValues, 'customCheckboxSpecificTime') === true ? <>
                    <div style={{ marginTop: '10px', marginRight: '30px', marginLeft: '50px' }}>
                        Start Time
                </div>
                    <div style={{ marginTop: '-20px' }}>
                        <Field
                            id='start-time'
                            name="startTime"
                            component={CustomDatePicker}
                            labelText="Start Time"
                            type="text"
                            dateFormat={false}
                            timeFormat="HH:mm:ss"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={readOnly}
                            validate={lodash.get(formValues, 'customCheckboxSpecificTime') === true ? [FormValidator.validateInputStartTime] : null}
                        />
                    </div>
                    <div style={{ marginTop: '10px', marginRight: '30px', marginLeft: '30px' }}>
                        End Time
                </div>
                    <div style={{ marginTop: '-20px' }}>
                        <Field
                            id='end-time'
                            name="endTime"
                            component={CustomDatePicker}
                            labelText="End Time"
                            type="text"
                            dateFormat={false}
                            timeFormat="HH:mm:ss"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={readOnly}
                            validate={lodash.get(formValues, 'customCheckboxSpecificTime') === true ? [FormValidator.validateInputEndTime] : null}
                        />
                    </div></>
                    : null}
            </GridContainer>
        </div>
    )
}

function mapStateToProps(state) {
    const { formOpen, formMode } = state.promotion;
    const formValues = getFormValues("promotion_form")(state)
    return {
        formOpen, formMode, formValues
    };
}
const connectedForm = connect(mapStateToProps)(ConditionDateTime);
export default withStyles(style)(connectedForm)

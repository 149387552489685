import React from "react";
import { connect } from "react-redux";
import lodash, { result } from "lodash";
import MUIDataTable from "mui-datatables";
import { plugins, crmValidator, loader, alert as customAlert } from "helpers";
import { reportActions } from "actions";
import { reportServices } from "services";
import { reportConstants } from "../../constants";
import EarnReportDetail from "./EarnReportDetail";
import EarnReportForm from "./EarnReportForm";
import EarnReportFormVitamilk from "./EarnReportFormVitamilk";
import ReportUserAgentDetail from './ReportUserAgentDetail';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"


const style = {};
const THEME = createMuiTheme(MTablestyles);

class TableEarnReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "transactionDate",
                sortDir: "desc",
                keyword: "",
            },
            csvData: [],
        };
        this.csvLink = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 800);
    }

    componentDidMount() {     
        this.getProductList();
    }
  
    getProductList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active";
        dispatch(reportActions.getProductList(reqParams));
  
    }

    handleShowDetail(rowData) {
        const { dispatch } = this.props;
        dispatch({
            type: reportConstants.OPEN_PURCHASE_DETAIL,
            purchaseDetail: {
                ...rowData.purchaseDetail,
                transactionDate: rowData.transactionDate,
            },
        });
    }

    openDetail(_id, rowIndex = 0) {
        const { dispatch } = this.props;
        dispatch(reportActions.getEarnTransactionById(_id))
        // dispatch({ type: "OPEN_EARN_REPORT_DETAIL", rowIndex: rowIndex });
    }

    openEditEarn(_id, rowIndex = 0, projectCode) {
        const { dispatch } = this.props;
        if ( projectCode == "vitamilk" ) {
            dispatch(reportActions.getTransactionByIdVitamilk(_id))
        }else{
            dispatch(reportActions.getTransactionById(_id))
        }
       
        // const rowData = tableDatas.products[index]
        
    }

    // openEditProduct(index) {
    //     const { dispatch, tableDatas } = this.props;
    //     const rowData = tableDatas.products[index]
    //     dispatch(productActions.getProductById(rowData._id))
    // }

    openViewImages(_id, rowIndex = 0) {
        const { dispatch } = this.props;
        dispatch(reportActions.getEarnTransactionByIdImages(_id))
        // dispatch({ type: "OPEN_EARN_REPORT_IMAGES", rowIndex: rowIndex });
        // dispatch({ type: "OPEN_EARN_REPORT_DETAIL", rowIndex: rowIndex });
    }

    openUserAgentDetail(rowData) {
        const { dispatch } = this.props;
        const { userAgent, earnChannel }=rowData
        let data = {
            userAgent:userAgent,
            userChannel:earnChannel
        }
        dispatch({type:reportConstants.OPEN_REPORT_USER_AGENT_DETAIL,data:data})
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getProductList(); 
            this.getData();
            dispatch({ type: reportConstants.TABLE_EARN_REPORT_RELOAD_SUCCESS })
        }
    }

    exportCSV() {
        const { earnReport, dispatch } = this.props;
        let { tableFilter, tableTotal } = earnReport;
        tableFilter.skip = 0;
        tableFilter.limit = tableTotal ? tableTotal : 100000;
        if (tableTotal > 30000) {
            customAlert(
                dispatch,
                "error",
                "Can not export data more than 30,000 rows"
            );
        } else {
            loader(dispatch, true);
            reportServices
                .downloadEarnTransaction(tableFilter)
                .then((response) => {
                    loader(dispatch, false);
                })
                .catch((e) => {
                    this.setState({ csvData: [] });
                });
        }
    }

    getData(skip, limit, sortBy, sortDir, keyword) {
        const { dispatch, tableReload, earnReport } = this.props;
        let { tableFilter } = earnReport;

        let newFilter = tableFilter;

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                newFilter.skip = 0;
            }

            if (crmValidator.isEmptyValue(limit)) {
                newFilter.limit = 10;
            }
            newFilter.page = skip;
            this.setState({
                rowsPerPage: limit,
            });
            skip = parseInt(limit) * parseInt(skip);

            newFilter.skip = skip;
            newFilter.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                newFilter.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                newFilter.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                newFilter.keyword = keyword;
            } else {
                newFilter.keyword = "";
            }
        }

        dispatch(reportActions.getEarnTransactionList(newFilter));

        this.setState({
            isLoading: true,
            filter: newFilter,
        });
    }

    getRowData(rowIndex = 0) {
        const { earnReport } = this.props;
        const { tableData } = earnReport;
        const data = tableData;
        return data[rowIndex];
    }

    render() {
        const { rowsPerPage } = this.state;
        const { classes, earnReport, formInitialValue } = this.props;
        let data_open = false
        if ( formInitialValue.remark != undefined) {
            data_open = true
        }
        const { tableData, tableTotal, tableFilter } = earnReport;
        const columns = [
            {
                name: "created_at",
                label: "Transaction Date/Time",
                options: {
                    customBodyRender: (value) =>
                        value
                            ? plugins.datetimeFormat(value, "fulldatetime")
                            : "",
                },
            },
            { name: "transactionId", label: "Transaction ID" },
            { name: "memberId", label: "Member ID" },
            { name: "memberFullName", label: "Name" ,
                options: {
                customBodyRender: (value) =>
                    lodash.isUndefined(value) ? "" : value === "undefined undefined" ? "" : value
                }
            },
            {
                name: "phoneNumber",
                label: "Mobile No.",
            },
            { name: "earnRuleType",
              label: "Rule Type" , 
              options: {
                customBodyRender: (value) =>
                value === "adjustment"? "Point Adjustment" : value
                }
            },
            {
                name: "earnRuleName",
                label: "Rule Name",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        return (
                            rowData.earnRuleName !== null &&
                            rowData.earnRuleName
                        );
                    },
                },
            },
            {
                name: "productName",
                label: "Product",
            },
            { name: "code", label: "Code" },
            { name: "reward", label: "Reward Name" },
            { name: "point", label: "Point",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        // return (
                            if(rowData.remark !== 'VOID'){
                                return rowData.point;
                            }else{
                                return "-"+rowData.point;
                            }
                        // );
                    },
                },
            },
            { name: "remark", label: "Status" },
            { name: "source", label: "Source" },
            { name: "earnChannel", label: "Channel" },
            { name: "userAgent", label: "User Agent",
                options: {
                    filter: false, 
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        return (
                            <>
                            { lodash.has(value,"source")   &&

                            (<IconButton color="primary" className="" onClick={()=>this.openUserAgentDetail(rowData)}>
                                <InfoOutlinedIcon />
                            </IconButton>)
                            }
                            </>
                            )
                    },
                }, 
            },
            {
                name: "_id",
                label: " ",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        return (
                            <>
                                {rowData.source === "import_file" ||
                                rowData.source === "POS" ? (
                                    <Button
                                        id='detail-btn'
                                        variant='contained'
                                        bcolor='primary'
                                        size='sm'
                                        onClick={() => {
                                            this.openDetail(value, rowIndex);
                                        }}
                                        style={{ backgroundColor: "#02A536" }}>
                                        Detail
                                    </Button>
                                ) : null}
                            </>
                        );
                    },
                },
            },
            {
                name: "_id",
                label: "Action", options: {
                   filter: false,
                   sort: false,
                   customBodyRender: (value, tableMeta, updateValue) => {
                    const rowIndex = tableMeta.rowIndex;
                    const rowData = this.getRowData(rowIndex);
                      return (
                        <div className={classes.actionDiv}>
                      
                             { rowData.hasImages ? (
                               <TableActionButton
                                  actionType="edit"
                                  onClick={(e) => {
                                    this.openEditEarn(value, rowIndex, rowData.projectCode);
                                  }}
                               />
                             ) : null}  
                         </div>
                      )
                   }
                }
             }
            
        ];
        const options = {
            serverSide: true,
            count: tableTotal ? tableTotal : 0,
            page: tableFilter ? tableFilter.page : 0,
            rowsPerPage: rowsPerPage,
            fixedHeader: true,
            selectableRows: "none",
            print: false,
            customToolbar: () => {
                return (
                    <>
                        {plugins.isHasPermission("report[earn]", "export") && plugins.isHasPackageFeatures('export') &&(
                            <Tooltip title='Export CSV'>
                                <IconButton
                                    id='button-earnTable-exportCsv'
                                    className=''
                                    onClick={this.exportCSV.bind(this)}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [1, 5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip,
                    limit,
                    sortBy,
                    sortDir,
                    keyword = "";

                if (tableState.page !== null) {
                    skip = tableState.page;
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage;
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name;
                }
                if (tableState.announceText !== null) {
                    sortDir =
                        tableState.announceText.search("descending") !== -1
                            ? "desc"
                            : "asc";
                }
                if (tableState.searchText !== null || action === "search") {
                    keyword = tableState.searchText;
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword);
                }
                let actionList = ["changePage", "sort", "changeRowsPerPage"];
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword);
                }
            },
        };
        return (
            <div id='tableEarnReport'>
                <CustomSweetAlert />
                <EarnReportDetail />
                <EarnReportForm 
                    initialValues={formInitialValue}
                />
                 {data_open && 
                    <EarnReportFormVitamilk
                        initialValues={formInitialValue}
                    />
                }  
                <ReportUserAgentDetail />
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={earnReport.tableKey}
                        data={tableData ? tableData : []}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { visiblePersonalData, merchantSetting } = state.authentication;
    const { formInitialValue, earnReport, tableReload } = state.report;
    return {
        formInitialValue,
        visiblePersonalData,
        merchantSetting,
        earnReport,
        tableReload
    };
}

export default connect(mapStateToProps)(withStyles(style)(TableEarnReport));

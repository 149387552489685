import { pointByPurchaseConstants } from '../constants';
import { pointByPurchaseServices } from '../services'
import { loader, alert as customAlert } from 'helpers';
import lodash from 'lodash'
import i18n from 'i18n';
import qs from "querystring"
import moment from 'moment'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const pointByPurchaseActions = {
    getTablePointByPurchaseDatas,
    getUserByPhoneNumber,
    createPointByPurchase,
    voidPointByPurchase,
    getListsAdmin,
};


function getTablePointByPurchaseDatas(params, tableReset = false) {
    return dispatch => {
        loader(dispatch, true);
        pointByPurchaseServices.getTablePointByPurchaseDatas(params)
            .then(
                response => {
                    if (response.status) { 
                        dispatch({
                            type: pointByPurchaseConstants.GET_LIST_TABLE_POINT_BY_PURCHASE,
                            data: response.data.data,
                            total: response.data.total,
                            tableFilter: params
                        });
                        if (tableReset) {
                            dispatch({ type: pointByPurchaseConstants.POINT_BY_PURCHASE_TABLE_RESET });
                        }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false);
            })
    };
}

function getUserByPhoneNumber(phoneNumber) {
    return dispatch => {
        loader(dispatch, true)
        pointByPurchaseServices.getUserByPhoneNumber(phoneNumber)
            .then(
                response => {
                    if (response.status) {
                        dispatch({ type: "GET_USER_BY_PHONE_NUMBER", data: lodash.get(response, 'data') });
                    } else {
                        dispatch({ type: "GET_USER_BY_PHONE_NUMBER", data: false });
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            )
            .then(() => {
                loader(dispatch, false);
            });
    }
}

function createPointByPurchase(reqBody) {
    return dispatch => {
        loader(dispatch, true)
        pointByPurchaseServices.createPointByPurchase(reqBody)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: pointByPurchaseConstants.ADD_POINT_BY_PURCHASE_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.point_by_purchase_was_created'));
                        } else {
                            let resmessage = `${lodash.get(response, 'message')}`
                            if (!response.unAlert) {
                                if (resmessage === "BAD_REQUEST") {
                                    let resData = lodash.join(lodash.get(response, 'data'), ',')
                                    if (resData === 'PHONE_NUMBER_IS_REQUIRED') {
                                        customAlert(dispatch, 'error', 'Mobile Number is required')
                                    }
                                }
                            } else {
                                customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                            }
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function voidPointByPurchase(reqBody) {
    return dispatch => {
        loader(dispatch, true)
        pointByPurchaseServices.voidPointByPurchase(reqBody)
            .then(
                tmp => {
                    if (tmp.status) {
                        dispatch({ type: pointByPurchaseConstants.VOID_POINT_BY_PURCHASE_SUCCESS });
                    } else {
                        if (!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getListsAdmin(params) {
    return dispatch => {
        pointByPurchaseServices.getListsAdmin(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({ type: "GET_ADMIN_LIST", data: lodash.get(response, 'data') });
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false);
            })
    };
}
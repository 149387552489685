import { earnactivitiesConstants } from '../constants';

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableData: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false
}

export function earnactivities(state = initialState, action) {
    switch (action.type) {
        case earnactivitiesConstants.CLEAR_PAGE_STATE:
            return initialState
        case earnactivitiesConstants.OPEN_ADD_NEW_ACTIVITIES:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.data,
            };
        case earnactivitiesConstants.OPEN_EDIT_EARN_ACTIVITIES:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                selectedProductList: action.selectedProductList
            };
        case earnactivitiesConstants.CLOSE_FORM_EARN_ACTIVITIES:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {}
            }
        case earnactivitiesConstants.GET_EARN_ACTIVITIES_DATA_SUCCESS: {
            return {
                ...state,
                tableData: action.data,
                tableReload: false,
                tableReset: false
            }
        }
        case earnactivitiesConstants.SHOW_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: true
            }
        }
        case earnactivitiesConstants.HIDE_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: false
            }
        }
        case earnactivitiesConstants.ADD_EARN_ACTIVITIES_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date()
            }
        }
        case earnactivitiesConstants.EDIT_EARN_ACTIVITIES_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: true,
                tableReset: false
            }
        }
        case earnactivitiesConstants.TABLE_RELOAD_SUCCESS: {
            return {
                ...state,
                tableReload: false,
                tableReset: false
            }
        }
        case earnactivitiesConstants.DELETE_EARN_ACTIVITIES_SUCCESS: {
            return {
                ...state,
                tableReload: true
            }
        }
        default:
            return state;
    }
}
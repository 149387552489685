import { contentManagementConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableDatas: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false,
    contentImagePreview: defaultImage,
    contentDesktopImagePreview: defaultImage,
    mobileImageSelected: false,
    desktopImageSelected: false,
    mobileImageUpdated: false, 
    desktopImageUpdated: false,
    contentManagementData: [],
    showLoader: false,
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "updated_at",
        sortDir: "desc",
        keyword: "",
        search: {}
    },
}

export function content_management(state = initialState, action) {
    switch (action.type) {
        case contentManagementConstants.OPEN_FORM_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: {
                    status: "Active",
                    contentType: 'news'
                },
                contentImagePreview: defaultImage,
                contentDesktopImagePreview: defaultImage,
                mobileImageSelected: false,
                desktopImageSelected: false,
            };
        case contentManagementConstants.OPEN_FORM_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                contentImagePreview: action.mobileImageFilePath,
                contentDesktopImagePreview: action.desktopImageFilePath,
                mobileImageSelected: action.mobileImageSelected,
                desktopImageSelected: action.desktopImageSelected,
                mobileImageUpdated: false, 
                desktopImageUpdated: false,
            };
        case contentManagementConstants.EDIT_CONTENT_MANAGEMENT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case contentManagementConstants.CLOSE_FORM_CONTENT_MANAGEMENT:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                    contentImagePreview: "",
                 },
                 contentImagePreview: defaultImage,
                 contentDesktopImagePreview: defaultImage,
            }
        case contentManagementConstants.ADD_CONTENT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                contentImagePreview: defaultImage,
                contentDesktopImagePreview: defaultImage,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case contentManagementConstants.SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT:
            return {
                ...state,
                contentImagePreview: action.contentImagePreview === "default"? defaultImage : action.contentImagePreview,
                mobileImageSelected: action.contentImagePreview === "default"? false : true,
                mobileImageUpdated: action.mobileImageUpdated,
            }
        case contentManagementConstants.SET_FORM_PREVIEW_IMAGE_CONTENT_MANAGEMENT_DESKTOP:
            return {
                ...state,
                contentDesktopImagePreview: action.contentDesktopImagePreview === "default"? defaultImage : action.contentDesktopImagePreview,
                desktopImageSelected: action.contentDesktopImagePreview === "default"? false : true,
                desktopImageUpdated: action.desktopImageUpdated,
            }
        case contentManagementConstants.GET_CONTENT_MANAGEMENT_DATA_SUCCESS:
            return {
                ...state,
                contentManagementData: action.data
            }
        case contentManagementConstants.TABLE_GET_LIST_CONTENT_MANAGEMENT:
            return {
                ...state,
                tableDatas: action.data,
                total: action.data.total,
                tableReload: false,
                tableReset: false
            }
        case contentManagementConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case contentManagementConstants.DELETE_CONTENT_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        default:
            return state;
    }
}
export const tmnReportConstants = {
    TRUEMONEY_REPORT_REDUCER_INIT:"TRUEMONEY_REPORT_REDUCER_INIT",
    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:"GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS",
    GET_TRUEMONEY_TRANSACTION_SUCCESS:"GET_TRUEMONEY_TRANSACTION_SUCCESS",
    TRUEMONEY_TRANSACTION_TABLE_RESET:"TRUEMONEY_TRANSACTION_TABLE_RESET",
    GET_TRUEMONEY_TRANSACTION_REPORT_REQUEST:"GET_TRUEMONEY_TRANSACTION_REPORT_REQUEST",
    CLEAR_TRUEMONEY_TRANSACTION_FILTER:"CLEAR_TRUEMONEY_TRANSACTION_FILTER",
    
    GET_TRUEMONEY_SUMMARY_BUDGET_SUCCESS:"GET_TRUEMONEY_SUMMARY_BUDGET_SUCCESS",
    CLEAR_TRUEMONEY_SUMMARY_REPORT_FILTER:"CLEAR_TRUEMONEY_SUMMARY_REPORT_FILTER",
    GET_TRUEMONEY_SUMMARY_REWARD_LIST_SUCCESS:"GET_TRUEMONEY_SUMMARY_REWARD_LIST_SUCCESS",
    TRUEMONEY_SUMMARY_REWARD_TABLE_RESET:"TRUEMONEY_SUMMARY_REWARD_TABLE_RESET"
}


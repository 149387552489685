import _ from 'lodash'

export function setHeaders(headers = {}) {
   try {
      let token = localStorage.getItem('token');
      let defaultHeadres = {
         'Content-Type': 'application/json'
      };

      if (token) {
         defaultHeadres['Authorization'] = token
      }

      if (localStorage.getItem('source')) {
         defaultHeadres['source'] = localStorage.getItem('source')
      }

      if (typeof headers === "object") {
         defaultHeadres = _.extend(defaultHeadres, headers)
      }

      return defaultHeadres
   } catch (error) {
      console.log(error)
      return {}
   }
}
import React from "react";
import { Field, change } from 'redux-form';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";


import { normalize } from 'helpers'

import i18n from 'i18n';
import lodash from 'lodash';

import * as FormValidator from '../validate/FormValidator';


function FormTrueMoney({ ...props }) {
   const { dispatch, formMode } = props.props
   const  { setTruemoneyRewardValue } = props
   let { readOnly } = props.props

   if (formMode === `add`) {
      readOnly = false
   } else if (formMode === `edit` && !readOnly) {
      readOnly = false
   } else {
      readOnly = true
   }
 
   return (
      <>
         <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <div style={{display: 'none'}}>
                     <Field
                        name="trueMoneyBudgetLimit"
                        component="input"
                        type="number"
                        />
                        </div>
                     <Field
                        name="tmnRewardId"
                        component={CustomSelect}
                        type="text"
                        labelText={i18n.t("label.true_money_reward")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        optionsList={setTruemoneyRewardValue}
                        validate={[FormValidator.validateRequired]}
                        disabled={readOnly || formMode === "edit"}
                        onChange={(value)=>{
                              let trueMoneyBudgetLimit = lodash.find(setTruemoneyRewardValue,{ 'value': value.target.value})
                              trueMoneyBudgetLimit = lodash.get(trueMoneyBudgetLimit,"budget")
                              dispatch(change('FORM_CAMPAIGN_V2', 'trueMoneyBudgetLimit', trueMoneyBudgetLimit))
                           }
                        }
                     />
                  </GridItem>
               </GridContainer>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <Field
                        name="rewardDetail[moneyTopUp]"
                        component={CustomInput}
                        type="text"
                        labelText={i18n.t("label.true_money_top_up")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        disabled={readOnly}
                        normalize={normalize.onlyNumber}
                        validate={FormValidator.trueMoneyBudgetLimit}
                     />
                  </GridItem>
               </GridContainer>
            </GridItem>
         </GridContainer>
      </>
   );
}

export default FormTrueMoney;

import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
//page components
import TableCampaignList from "./TableCampaignList.jsx";
import CampaignForm from "./CampaignForm";
import { plugins } from 'helpers';

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const pageStyle = { ...style, ...sweetAlertStyle };
const moduleName = 'campaign_new';

class CampaignContainer extends React.Component {

  render() {
    const { formInitialValue, formOpen } = this.props;
    const displayCard = formOpen ? {display: "none" } : {}
    return (
      <div>
          <CampaignForm
            initialValues={formInitialValue}
            readOnly={!plugins.isHasPermission(moduleName, 'update')}
          />
          <Card style={displayCard}>
            <CardBody>
              <CustomSweetAlert />
              <GridContainer>
                <GridItem xs={12}>
                 
                </GridItem>
                <GridItem xs={12}>
                  <TableCampaignList />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
      </div>
    )
  }

}

function mapStateToProps(state) {
  const { formInitialValue, formOpen } = state.campaign_v2;
  return {
    formInitialValue, formOpen
  };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(CampaignContainer));

import { expirationConstants } from '../constants';
import { expirationServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const expirationActions = {
    getTableExpirationDatas,
    getHistoryExpirationById,
};
function getTableExpirationDatas(params) {
    return dispatch => {
        dispatch({ type: expirationConstants.SHOW_TABLE_LOADER })
        expirationServices.getTableExpirationDatas(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                dispatch({ type: expirationConstants.HIDE_TABLE_LOADER })
            })
    }
    function success(tmp) { return { type: expirationConstants.GET_EXPIRATION_DATA_SUCCESS, data: tmp } }
}

function getHistoryExpirationById(params) {
    return dispatch => {
        dispatch({ type: expirationConstants.SHOW_TABLE_HISTORY_LOADER })
        expirationServices.getHistoryExpirationById(params)
            .then(
                response => {
                    try {
                        if (response.status) { 
                            dispatch(success(response.data));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                dispatch({ type: expirationConstants.HIDE_TABLE_HISTORY_LOADER })
            })
    }
    function success(tmp) { return { type: expirationConstants.GET_HISTORY_EXPIRATION_DATA_SUCCESS, data: tmp } }
}
import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import moment from 'moment';
import lodash from "lodash";

// material-ui components
import Icon from "@material-ui/core/Icon";
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { orange } from "@material-ui/core/colors";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import * as FormValidator from "./validate/FormValidator";

import { memberLevelAction , memberActions} from "actions";
import { crmValidator } from "helpers";
import { plugins } from 'helpers';
const moduleName = 'member_level[automatic_shifting_level]';


const FORM_NAME = "form_condition";
const form_validate = {
  ruleBase: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select Rule Base Condition",
    },
    alert: "Please Select Rule Base Condition",
  },
  periodReaching: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select Period for reaching each member level",
    },
    alert: "Please Select Period for reaching each member level",
  },
  collectEvery: {
    rules: {
      required: true,
      min: 1
    },
    messages: {
      required: "Please input collected time every ",
      min: "Please enter the number greater than 0",
    },
    alert: "Please input collected time every ",
  },
  collectWithin: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select collected within ",
    },
    alert: "Please Select collected time within ",
  },
  selectedDate: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select date for start counting",
    },
    alert: "Please Select date for start counting",
  },
  selectedCountDate: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select date for start counting",
    },
    alert: "Please Select date for start counting",
  },
  downGrade: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select downgrade option for Member Level",
    },
    alert: "Please Select downgrade option for Member Level",
  },
  levelUp: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select Time for Member Level rearrangement",
    },
    alert: "Please Select Time for Member Level rearrangement",
  },
  resetMemberLevel: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select Reset All Member Level",
    },
    alert: "Please Select Reset All Member Level",
  },
  reset_every_end_of: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select time to reset",
    },
    alert: "Please Select time to reset",
  },
  selected_reset_date: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select time to reset",
    },
    alert: "Please Select time to reset",
  },
  whenReset: {
    rules: {
      required: true,
    },
    messages: {
      required: "Please Select time to reset",
    },
    alert: "Please Select time to reset",
  },
};
const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0",
  },
  labelHorizontalCenter: {
    float: "right",
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingTop: "5px",
    marginRight: "0",
  },
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
  head: {
    lineHeight: "24px",
    fontSize: "13px",
    fontWeight: "405",
    position: "relative",
    marginLeft: "39px",
  },
  gridLabel: {
    paddingTop: "10px",
  },
  gridLine: {
    paddingTop: "0px",
  },
  text: {
    paddingTop: "500px",
    color: "red",
  },
};

const collectWithinDay = [
  {
    value: "day",
    label: "Day",
  },
  {
    value: "month",
    label: "Month",
  },
  {
    value: "year",
    label: "Year",
  },
]

const collectWithinMonth = [
  {
    value: "month",
    label: "Month",
  },
  {
    value: "year",
    label: "Year",
  },
]

const validate = (values) => {
  const errors = crmValidator.validateByRulesList(form_validate, values);
  return errors;
};

class ConditionForm extends React.Component {

  setName(name) {
    let set = name;
    if (name.length >= 35) {
      let res = set.substring(1, 16);
      res += res + "...";
      set = res;
    }
    return set;
  }

  displayAmount = (value, from, title) => {
    let temp = value;
    let show = "";

    if (title === "purchaseAmount" || title === "earnPoint") {
      temp = Number.parseFloat(temp).toFixed(2);
    }

    show = isNaN(lodash.parseInt(value)) ? 0 : temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return show;
  };

  renderRankingList = (title) => {
    const { form_condition, sortedData } = this.props;
    let readOnly = !plugins.isHasPermission(moduleName, 'update')
    let rankingListComponent = [];

    sortedData.forEach((level, i) => {
      let name = i + "_" + title;
      let inputName = `${title}[${level._id}]`
      let currentData = lodash.get(form_condition, `values[${title}][${level._id}]`)

      rankingListComponent.push(
        <div key={name}>
          <GridContainer style={{ paddingTop: "0px" }}>
            <GridItem xs={12} sm={7} md={3}>
              <div style={{ paddingTop: "23px" }}>
                <Button
                  variant="contained"
                  bcolor="primary"
                  size="lg"
                  value={level._id}
                  style={{
                    backgroundColor: level.color,
                    marginBottom: "44px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    borderRadius: "20px",
                    height: "28px",
                    width: "160px",
                  }}
                >
                  {this.setName(level.name)}
                </Button>
              </div>
            </GridItem>
            <GridItem xs={12} sm={1} md={1}>
              <div key={name} style={{ paddingTop: "27px", textAlign: "center" }}>
                <Icon
                  style={{
                    fontSize: "30px",
                    color: "#319635",
                    marginBottom: "40px",
                  }}
                >
                  arrow_forward
                </Icon>
              </div>
            </GridItem>
            {i !== 0 ? (
              <GridItem xs={12} sm={7} md={2}>
                <Field
                  label={title}
                  name={inputName}
                  component={CustomInput}
                  type="text"
                  validate={[
                    FormValidator.validateRequired,
                  ]}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  normalize={(value) => {
                    if (title === "successCodePurchase" || title === "activeProductPurchase") {
                      return crmValidator.handleKeyPress(value, { rules: { number: true, isInteger: true } })
                    } else {
                      return crmValidator.handleKeyPress(value, { rules: { decimal: true, decimalDigits: 2 } }, currentData)
                    }
                  }}
                  disabled={readOnly}
                />
              </GridItem>
            ) : (
                <GridItem xs={12} sm={7} md={2}>
                  <div
                    key={name}
                    style={{
                      textAlign: "center",
                      paddingTop: "33px",
                    }}
                  >
                    <font size="4">0</font>
                  </div>
                </GridItem>
              )}

            <GridItem xs={12} sm={7} md={3}>
              <div
                key={name}
                className={i}
                style={{
                  textAlign: "left",
                  paddingTop: "33px",
                }}
              >
                <font size="1">( {this.displayAmount(currentData, "CURRENT", title)} or above)</font>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      );
    });
    return rankingListComponent;
  };

  handleLimitedTime = (e) => {
    const { dispatch } = this.props;
    if (e.target.value !== "LIMITTED") {
      dispatch(change(FORM_NAME, "collectEvery", ""));
      dispatch(change(FORM_NAME, "selectedCountDate", ""));
    }
  };

  handleResetAllMember = (e) => {
    const { dispatch } = this.props;
    if (e.target.value !== "YES") {
      dispatch(change(FORM_NAME, "reset_every_end_of", ""));
      dispatch(change(FORM_NAME, "selected_reset_date", ""));
    }
  };

  mockDataLevelLists = (lists, ranking, checked) => {
    let tmpData = []
    if (lists) {
      for (let obj of ranking) {
        let amount = lodash.get(lists, `${obj._id}`) || 0
        tmpData.push({ _id: obj._id, rank: obj.name, amount: checked ? amount.toString() : "0" })
      }
    }

    return tmpData
  }

  handleSubmit = (values) => {
    const { dispatch, rankingList } = this.props;
    const sortRanking = JSON.parse(JSON.stringify(rankingList)).reverse()
    let formSubmit = {
      memberLevelCondition: {
        rankingList: rankingList,
        shifting: {
          condition: values.ruleBase,
          purchaseAmount: {
            checked: values.purchaseAmountChecked,
            list: this.mockDataLevelLists(values.purchaseAmount, sortRanking, values.purchaseAmountChecked)
          },
          earnPoint: {
            checked: values.earnPointChecked,
            list: this.mockDataLevelLists(values.earnPoint, sortRanking, values.earnPointChecked)
          },
          successCodePurchase: {
            checked: values.numberTimeChecked,
            type: values.type_numberTime,
            list: this.mockDataLevelLists(values.successCodePurchase, sortRanking, values.numberTimeChecked)
          },
          activeProductPurchase: {
            checked: values.numberActiveChecked,
            list: this.mockDataLevelLists(values.activeProductPurchase, sortRanking, values.numberActiveChecked)
          },
        },
        period: {
          limit: values.periodReaching === "LIMITTED" ? true : false,
          limitTime: {
            amount: values.collectEvery,
            type: values.collectWithin,
            start: {
              activity: values.selectedDate,
              selectedDate: values.selectedCountDate,
            },
          },
          downGrade: values.downGrade,
          rearrangeTime: values.levelUp,
        },
        reset: {
          checked: values.resetMemberLevel === "YES" ? true : false,
          time: values.whenReset === "reset_selected_date" ? "selectedDate" : values.reset_every_end_of,
          selectedDate: values.selected_reset_date,
        },
      },
    };

    dispatch(memberLevelAction.setCondition(formSubmit));
  };

  handleClear = () => {
    const { dispatch } = this.props;

    dispatch(memberLevelAction.getRankingData('RESET'));
  };

  validateCountingMinDate = (value, allValues, props, name) => {
    const { form_condition } = this.props;

    let selectedCountDate = lodash.get(form_condition, "values.selectedCountDate")
    let errMgs = "Please input minimum value as current date "
    let oldDate = moment(selectedCountDate).valueOf()
    let newDate = moment(value).valueOf()
    let currentDate = moment(new Date().setHours(0, 0, 0, 0)).valueOf()
    if (newDate >= currentDate || lodash.isEqualWith(oldDate, newDate)) {
      errMgs = ""
    }

    return errMgs
  }

  validateResetMinDate = (value, allValues, props, name) => {
    const { form_condition } = this.props;

    let selected_reset_date = lodash.get(form_condition, "values.selected_reset_date")
    let errMgs = "Please input minimum value as current date "
    let oldDate = moment(selected_reset_date).valueOf()
    let newDate = moment(value).valueOf()
    let currentDate = new Date()
    if (newDate >= currentDate || lodash.isEqualWith(oldDate, newDate)) {
      errMgs = ""
    }

    return errMgs
  }

  render() {
    const { handleSubmit, classes, form_condition } = this.props;

    let selectedCountDate = lodash.get(form_condition, "values.selectedCountDate")
    let startDateMinDate = new Date().setHours(0, 0, 0, 0)
    let minDateCount = !lodash.isUndefined(selectedCountDate) ? selectedCountDate : moment(startDateMinDate)
    let mindateReset = new Date()
    let readOnly = !plugins.isHasPermission(moduleName, 'update')


    return (
      <div>
        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
          <GridContainer style={{ paddingLeft: "20px", paddingTop: "20px" }}>
            <GridItem xs={12} sm={12} md={2}>
              <div style={{ padding: "13px 4px 10px", fontSize: "13px" }}>
                <b>Rule Base Condition</b>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={10}>
              <Field
                id="ruleBase"
                labelText="Input"
                name="ruleBase"
                component={CustomRadio}
                labelPlacement="top"
                style={{ paddingTop: "10px" }}
                disabled={readOnly}
                radioLists={[
                  {
                    value: "OR",
                    label: "Achieve at least one of the selected rule base below for Level Adjustment",
                    disabled: readOnly,
                  },
                  {
                    value: "AND",
                    label: "Achieve all selected rule base below for Level Adjustment",
                    disabled: readOnly,
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{ paddingLeft: "20px", paddingTop: "20px" }}
            justify="center"
          >
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ padding: "13px 4px 25px" }}>
                <font size="4">
                  <b>
                    <u> Please Select Rule Base Condition </u>
                  </b>
                  <font size="3">
                    &nbsp; &nbsp; ( Able to select more than one rule )
                  </font>
                </font>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ExpansionPanel
                expanded={lodash.get(form_condition, "values.purchaseAmountChecked")}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500],
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Field
                    style={{ paddingTop: "40px" }}
                    name={"purchaseAmountChecked"}
                    validate={FormValidator.ruleBaseValidate}
                    component={CustomCheckbox}
                    disabled={readOnly}
                    id="chkbox-ruleBase-purchase"
                    labelText="Based on Total Purchase amount"
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer
                      style={{ marginLeft: "0", paddingTop: "0px" }}
                    >
                      <GridItem xs={12} sm={3} md={3}>
                        <div style={{ textAlign: "center" }}>
                          <FormLabel>
                            <u>Member Level</u>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={4}>
                        <div style={{ textAlign: "center" }}>
                          <FormLabel>
                            <u>Amount to reach each level</u>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ paddingTop: "2px" }}>
                          {this.renderRankingList("purchaseAmount")}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <ExpansionPanel
                expanded={lodash.get(form_condition, "values.earnPointChecked")}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500],
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Field
                    style={{ paddingTop: "40px" }}
                    validate={FormValidator.ruleBaseValidate}
                    name={"earnPointChecked"}
                    component={CustomCheckbox}
                    disabled={readOnly}
                    id="chkbox-ruleBase-totalEarn"
                    labelText="Based on Total Earn Point"
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer
                      style={{ marginLeft: "0", paddingTop: "0px" }}
                    >
                      <GridItem xs={12} sm={3} md={3}>
                        <div style={{ textAlign: "center" }}>
                          <FormLabel>
                            <u>Member Level</u>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={4}>
                        <div style={{ textAlign: "center" }}>
                          <FormLabel>
                            <u>Amount to reach each level</u>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ paddingTop: "2px" }}>
                          {this.renderRankingList("earnPoint")}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <ExpansionPanel
                expanded={lodash.get(form_condition, "values.numberTimeChecked")}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500],
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Field
                    style={{ paddingTop: "40px" }}
                    validate={FormValidator.ruleBaseValidate}
                    name={"numberTimeChecked"}
                    component={CustomCheckbox}
                    disabled={readOnly}
                    id="chkbox-ruleBase-successCode"
                    labelText="Based on Number of times to input success code/Purchase"
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer
                      style={{ marginLeft: "0", paddingTop: "0px" }}
                    >
                      <GridItem xs={12} sm={12} md={12}>
                        <div
                          style={{ paddingBottom: "22px", paddingLeft: "10px" }}
                        >
                          <Field
                            labelText="Input"
                            name="type_numberTime"
                            validate={FormValidator.validateRequired}
                            component={CustomRadio}
                            labelPlacement="start"
                            disabled={readOnly}
                            radioLists={[
                              {
                                value: "input_code",
                                label: "Input Success Code",
                                disabled: readOnly,
                              },
                              {
                                value: "purchase",
                                style: {
                                  marginTop: "-48px",
                                  marginLeft: "195px",
                                },
                                label: "Purchase",
                                disabled: readOnly,
                              },
                            ]}
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <div style={{ textAlign: "center" }}>
                          <FormLabel>
                            <u>Member Level</u>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={4}>
                        <div style={{ textAlign: "center" }}>
                          <FormLabel>
                            <u>Amount to reach each level</u>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ paddingTop: "2px" }}>
                          {this.renderRankingList("successCodePurchase")}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <ExpansionPanel
                expanded={lodash.get(form_condition, "values.numberActiveChecked")}
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        fontSize: "17px",
                        position: "relative",
                        color: orange[500],
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Field
                    style={{ paddingTop: "40px" }}
                    name={"numberActiveChecked"}
                    component={CustomCheckbox}
                    disabled={readOnly}
                    validate={FormValidator.ruleBaseValidate}
                    id="chkbox-ruleBase-activeProduct"
                    labelText="Based on Number of active products purchase"
                  />
                </ExpansionPanelSummary>

                <ExpansionPanelDetails style={{ padding: "0px 24px" }}>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <GridContainer
                      style={{ marginLeft: "0", paddingTop: "0px" }}
                    >
                      <GridItem xs={12} sm={3} md={3}>
                        <div style={{ textAlign: "center" }}>
                          <FormLabel>
                            <u>Member Level</u>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={4}>
                        <div style={{ textAlign: "center" }}>
                          <FormLabel>
                            <u>Amount to reach each level</u>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div style={{ paddingTop: "2px" }}>
                          {this.renderRankingList("activeProductPurchase")}
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </GridItem>
          </GridContainer>

          <div>
            <div
              style={{
                border: "0.5px dashed rgb(208, 200, 187)",
                padding: "0px 20px",
                color: "black",
                marginTop: "65px",
              }}
            />
          </div>

          <div style={{ paddingTop: "35px" }}>
            <CardHeader icon>
              <div
                className={classes.cardIconTitle}
                style={{
                  marginLeft: "0px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  fontSize: "17px",
                }}
              >
                <b>Time for Member Level rearrangement</b>
              </div>
            </CardHeader>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={7}>
              <div style={{ paddingTop: "25px", paddingLeft: "30px" }}>
                <Field
                  labelText="Input"
                  name="levelUp"
                  component={CustomRadio}
                  labelPlacement="top"
                  disabled={readOnly}
                  radioLists={[
                    {
                      value: "day",
                      label: "Every end of the day",
                      disabled: readOnly,
                    },
                    {
                      value: "month",
                      label: "Every end of the month",
                      disabled: readOnly,
                    },
                  ]}
                />
              </div>
            </GridItem>
          </GridContainer>
          <div>
            <div
              style={{
                border: "0.5px dashed rgb(208, 200, 187)",
                padding: "0px 20px",
                color: "black",
                marginTop: "65px",
              }}
            />
          </div>

          <div style={{ paddingTop: "35px" }}>
            <CardHeader icon>
              <div
                className={classes.cardIconTitle}
                style={{
                  marginLeft: "0px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  fontSize: "17px",
                }}
              >
                <b>Period for reaching each Member level</b>
              </div>
            </CardHeader>
          </div>

          <GridContainer
            style={{
              paddingLeft: "30px",
              paddingTop: "20px",
              paddingButtom: "20px",
            }}
          >
            <GridItem xs={12} sm={12} md={10}>
              <Field
                labelText="Input"
                name="periodReaching"
                component={CustomRadio}
                labelPlacement="top"
                onChange={this.handleLimitedTime}
                style={{ paddingTop: "10px" }}
                disabled={readOnly}
                radioLists={[
                  {
                    value: "NO_LIMIT",
                    label: "No time limit",
                    disabled: readOnly,
                  },
                  {
                    value: "LIMITTED",
                    label: "Limited time",
                    disabled: readOnly,
                  },
                ]}
              />
            </GridItem>
            {lodash.get(form_condition, 'values.periodReaching') === 'LIMITTED' && (
              <>
                <GridContainer style={{ paddingTop: "0px" }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={3}>
                        <div
                          style={{ padding: "13px 4px 10px", fontSize: "13px" }}
                        >
                          <FormLabel className={classes.labelHorizontal}>
                            <font color="black"> Collect within every :</font>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={2} md={2}>
                        <div style={{ paddingTop: "13px" }}>
                          <Field
                            position="end"
                            name="collectEvery"
                            component={CustomInput}
                            type="text"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            validate={[FormValidator.collectEveryValidate]}
                            normalize={(value) => {
                              return crmValidator.handleKeyPress(value, { rules: { number: true, isInteger: true } })
                            }}
                            disabled={readOnly}
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <div style={{ paddingTop: "16px" }}>
                          <Field
                            name="collectWithin"
                            component={CustomSelect}
                            type="text"
                            labelText="Please Select *"
                            onChange={this.handleWrongTime}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            optionsList={lodash.get(form_condition, "values.levelUp") === 'month' ? collectWithinMonth : collectWithinDay}
                            disabled={readOnly}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div style={{}}>
                      <FormLabel className={classes.labelHorizontal}>
                        <font color="black">
                          {" "}
                          Start counting for the first time since
                        </font>
                      </FormLabel>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <div style={{ paddingTop: "25px" }}>
                      <Field
                        labelText="Input"
                        name="selectedDate"
                        component={CustomRadio}
                        labelPlacement="top"
                        disabled={readOnly}
                        radioLists={[
                          {
                            value: "selectedDate",
                            label: "Selected Date",
                            disabled: readOnly,
                          },
                        ]}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={2} lg={2}>
                    <Field
                      name="selectedCountDate"
                      validate={this.validateCountingMinDate}
                      minDate={minDateCount}
                      disabled={readOnly}
                      component={CustomDatePicker}
                      type="text"
                      labelText="Selected Date"
                      dateFormat="DD-MMM-YY"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={12} lg={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <div style={{ textAlign: "left" }}>
                          <FormLabel className={classes.labelHorizontal}>
                            <font color="black"> Member Level Downgrade</font>
                          </FormLabel>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={7}>
                        <div style={{ paddingTop: "25px" }}>
                          <Field
                            labelText="Input"
                            name="downGrade"
                            component={CustomRadio}
                            labelPlacement="top"
                            disabled={readOnly}
                            radioLists={[
                              {
                                value: "true",
                                label: "Yes, if cannot at least maintain current level and reach higher level within limited time",
                                disabled: readOnly,
                              },
                              {
                                value: "false",
                                label: "Never",
                                disabled: readOnly,
                              },
                            ]}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </>
            )}
          </GridContainer>

          <div>
            <div
              style={{
                border: "0.5px dashed rgb(208, 200, 187)",
                padding: "0px 20px",
                color: "black",
                marginTop: "55px",
              }}
            />
          </div>
          <div style={{ paddingTop: "35px" }}>
            <CardHeader icon>
              <div
                className={classes.cardIconTitle}
                style={{
                  marginLeft: "0px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  fontSize: "17px",
                }}
              >
                <b>Reset All Member Level</b>
              </div>
            </CardHeader>
          </div>
          <GridContainer
            style={{
              paddingLeft: "30px",
              paddingTop: "20px",
              paddingButtom: "20px",
            }}
          >
            <GridItem xs={12} sm={12} md={10}>
              <Field
                labelText="Input"
                name="resetMemberLevel"
                component={CustomRadio}
                labelPlacement="top"
                style={{ paddingTop: "10px" }}
                onChange={this.handleResetAllMember}
                disabled={readOnly}
                radioLists={[
                  {
                    value: "NEVER",
                    label: "Never",
                    disabled: readOnly,
                  },
                  {
                    value: "YES",
                    label: "Yes",
                    disabled: readOnly,
                  },
                ]}
              />
            </GridItem>

            {lodash.get(form_condition, 'values.resetMemberLevel') === 'YES' && (
              <>
                <GridItem xs={12} sm={12} md={4}>
                  <div style={{ textAlign: "left" }}>
                    <FormLabel className={classes.labelHorizontal}>
                      <font color="black"> When to Reset All Member Level</font>
                    </FormLabel>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <div style={{ paddingTop: "25px" }}>
                    <Field
                      labelText="Input"
                      name="whenReset"
                      component={CustomRadio}
                      labelPlacement="top"
                      disabled={readOnly}
                      radioLists={[
                        {
                          value: "reset_end_of",
                          label: "Every end of",
                          disabled: readOnly,
                        },
                        {
                          value: "reset_selected_date",
                          label: "Selected Date",
                          disabled: readOnly,
                        },
                      ]}
                    />
                  </div>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    {lodash.get(form_condition, 'values.whenReset') === 'reset_end_of' && (
                      <GridItem xs={12} sm={12} md={12}>
                        <div
                          style={{ paddingTop: "3px", paddingRight: "260px" }}
                        >
                          <Field
                            name="reset_every_end_of"
                            component={CustomSelect}
                            type="text"
                            labelText="Please Select *"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            optionsList={[
                              {
                                value: "month",
                                label: "Month",
                              },
                              {
                                value: "year",
                                label: "Year",
                              },
                            ]}
                            disabled={readOnly}
                          />
                        </div>
                      </GridItem>
                    )}
                    {lodash.get(form_condition, 'values.whenReset') === 'reset_selected_date' && (
                      <GridItem xs={6} sm={6} md={6} lg={6}>
                        <div style={{ marginTop: "50px" }}>
                          <Field
                            name="selected_reset_date"
                            minDate={mindateReset}
                            // maxDate={new Date()
                            validate={this.validateResetMinDate}
                            component={CustomDatePicker}
                            type="text"
                            disabled={readOnly}
                            labelText="Selected Date"
                            dateFormat="DD-MMM-YY"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>
                      </GridItem>
                    )}{" "}
                  </GridContainer>
                </GridItem>
              </>
            )}
          </GridContainer>
          <div>
            <div
              style={{
                border: "0.5px dashed rgb(208, 200, 187)",
                padding: "0px 20px",
                color: "black",
                marginTop: "103px",
              }}
            />
          </div>
          <CardBody>
            <GridItem xs={12} sm={12} md={12}>
              {
                plugins.isHasPermission(moduleName, 'update') === true &&

                <GridContainer
                  style={{ paddingBottom: "49px", paddingTop: "49px" }}
                  justify="center"
                >
                  <Button
                    type="button"
                    onClick={this.handleClear}
                    style={{ marginLeft: "10px" }}
                    color="white"
                  >
                    Cancel
                </Button>
                  <Button
                    type="submit"
                    color="primary"
                    id="memberLevel-condition-submit"
                  >
                    Submit
                </Button>
                </GridContainer>
              }
            </GridItem>
          </CardBody>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    showLoader,
    rankingList,
    sortedData,
  } = state.memberLevel;

  const { form_condition } = state.form;

  return {
    showLoader,
    rankingList,
    sortedData,
    form_condition,
  };
}

const connectConditionForm = connect(mapStateToProps)(ConditionForm);

export default reduxForm({
  form: FORM_NAME,
  validate: validate,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, props) => {
    crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
  },
})(withStyles({ ...style, ...regularFormsStyle })(connectConditionForm));

import React from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import { memberLevelConstants } from '../../constants';
import { memberLevelAction } from 'actions'
import { plugins, crmValidator } from 'helpers';
import i18n from 'i18n';

import FormLevel from 'views/MemberLevel/FormLevel.jsx'

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const CustomMTablestyles = {
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MUIDataTableHeadCell: {
            fixedHeader: {
                "&:nth-child(1)": {
                    width: "90%"
                },
            }
        }
    }
}

const moduleName = 'member_level[member_level_list]';

const pageStyle = { ...style, ...sweetAlertStyle };
const THEME = createMuiTheme(CustomMTablestyles);

class TableLevelList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loaderSpiner: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        }
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    componentDidMount() {
        const { dispatch } = this.props;
        let reqParams = "";
        let limit = 10;
        reqParams += '&limit=' + limit
        this.state.reqParams = reqParams
        dispatch(memberLevelAction.getListData(reqParams))
    }
   
    tableColumn() {
        const _this = this;
        const { classes } = this.props;
        let columns = [
            { name: "_id", label: 'Id', options: { filter: false, display: "excluded" } },
            {
                name: "name", label: i18n.t("label.member_level_name"), options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = _this.getRowData(tableMeta.rowIndex)
                        return (
                            <Badge
                                color="primary"
                                title={rowData.name}
                                customStyle={{ backgroundColor: rowData.color, maxWidth: "100%" }}>
                                {rowData.name}
                            </Badge>
                        )
                    }
                }
            }
        ]

        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: i18n.t("label.action"), options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        // const rowData = _this.getRowData(tableMeta.rowIndex)
                        return (
                            <div className={classes.actionDiv}>
                                {plugins.isHasPermission(moduleName, 'update') &&
                                    <TableActionButton
                                        actionType="edit"
                                        onClick={(e) => {
                                            this.openFormEdit(tableMeta.rowIndex)
                                        }}
                                    />
                                }
                            </div>
                        )
                    }
                }
            })
        }
        return columns
    }

    tableOptions() {
        const { tableDatas } = this.props;
        const _this = this
        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: _this.state.page,
            rowsPerPage: _this.state.rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    _this.getData(skip, limit, sortBy, sortDir, keyword)
                }

            }
        }

        if (plugins.isHasPermission(moduleName, 'create')) {
            options.customToolbar = () => {
                return (
                    <CustomToolbar handleClick={this.openFromAdd.bind(this)} title={i18n.t("label.add_new")} />
                );
            }
        }
        
        return options
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(memberLevelAction.getListData(reqParams));

        this.setState({
            reqParams: reqParams
        });

    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.lists;
        if (lodash.size(data) > 0) {
            return data[rowIndex];
        } else {
            return null
        }
    }

    openFromAdd() {
        const { dispatch } = this.props;
        dispatch({
            type: memberLevelConstants.OPEN_ADD, formInitialValue: {
                status: 'Active'
            }
        })
    }

    openFormEdit(rowIndex) {
        const { dispatch } = this.props;
        const rowData = this.getRowData(rowIndex)
        dispatch({ type: memberLevelConstants.OPEN_EDIT, formInitialValue: { ...rowData } })
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: memberLevelConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    render() {
        const { formInitialValue, tableDatas, tableKey } = this.props;
        return (
            <div id="TableLevelList">
               <CustomSweetAlert />
                <FormLevel
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                    initialValues={formInitialValue} />
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        title={i18n.t("member_level.member_level_list")}
                        data={tableDatas.lists}
                        columns={this.tableColumn()}
                        options={this.tableOptions()}
                    />
                    {/* {showLoader && onSubmitRank && <TableLoaderSpinner />} */}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, onSubmitRank, tableDatas, tableReload, showLoader, tableKey, tableReset } = state.memberLevel;
    // const { permission, superAdmin } = state.authentication;
    return {
        formInitialValue,  onSubmitRank, tableDatas, tableReload, showLoader, tableKey, tableReset
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableLevelList));


import React from "react";
import { plugins } from 'helpers';
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MissionForm from './MissionForm'
import TableMission from "./TableMission"

 
const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const moduleName = 'category'//'mission';
class MissionContainer extends React.Component {
    render() {
    const { formInitialValue, formOpen } = this.props;
    const displayCard = formOpen ? {display: "none" } : {}
        return (
            <div>
                <MissionForm
                initialValues={formInitialValue}
                readOnly={!plugins.isHasPermission(moduleName, 'update')}
                />
                <Card  style={displayCard}>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12}>
                                <TableMission/>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card> 
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, formOpen } = state.mission;
    return {
      formInitialValue, formOpen
    };
}  

export default connect(mapStateToProps)(withStyles(style)(MissionContainer));
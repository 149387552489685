import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash';

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import { alert, store, plugins } from 'helpers';
import { photoGiveawayRuleConstants } from '../../constants';
import { photoGiveawayRulesActions } from 'actions';
import * as FormValidator from './validate/FormValidator';

import RulePhoto from './RulePhoto.jsx';

const FORM_NAME = "FORM_PHOTOGIVEAWAYRULES";
const form_validate = {
    "name": {
        rules: {
            required: true
        },
        messages: {
            required: "Please enter Rule's name"
        },
        alert: "Please enter Rule's name"
    },
    "status": {
        rules: {
            required: true
        },
        messages: {
            required: 'Please enter Status'
        },
        alert: "Please enter Status"
    },
    "startDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'Please enter Start Date'
        },
        alert: "Please enter Start Date"
    },
    "endDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'Please enter End Date'
        },
        alert: "Please enter End Date"
    },
    "expireDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'Please enter Expire Date'
        },
        alert: "Please enter Expire Date"
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(FormValidator.form_validate, values);
    return errors
}

class PhotoGiveawayRuleForm extends React.Component {

    getPhotoList(keyword) {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"
        if (keyword) {
            reqParams += `&keyword=${keyword}`
        }
        dispatch(photoGiveawayRulesActions.getListsPhotoGiveawayPhoto(reqParams))
    }

    getFormSubmitParams = (values, formMode) => {
        const { allPhotoList, FORM_PHOTOGIVEAWAYRULES } = this.props;
        let photoGiveawayPhotoIdsWithLimit = [];
        let registeredFields = FORM_PHOTOGIVEAWAYRULES.registeredFields

        lodash.forEach(values.photoLimit, function (value, key) {
            if (lodash.has(registeredFields, `photoLimit[${key}]`)) {
                photoGiveawayPhotoIdsWithLimit.push({
                    photoGiveawayPhotoId: key,
                    limit: value
                })
            }
        })

        const endDate = () => {
            if (lodash.has(values, 'endDate')) {
                return plugins.dateFormatForDB(values.endDate)
            } else {
                return undefined
            }
        }

        const expireDate = () => {
            if (lodash.has(values, 'expireDate')) {
                return plugins.dateFormatForDB(values.expireDate)
            } else {
                return undefined
            }
        }



        let result = {
            name: values.name,
            photoGiveawayPhotoIdsWithLimit: photoGiveawayPhotoIdsWithLimit,
            startDate: plugins.dateFormatForDB(values.startDate),
            endDate: endDate(),
            expireDate: expireDate(),
            status: values.status,
            earnCodeRecivePhoto: values.earnCodeRecivePhoto
        }

        if (formMode === "edit") {
            result._id = values._id
        }

        return result;
    }

    handleSubmit = (values) => {

        // console.log('handleSubmit values:',values);

        const { dispatch, formMode } = this.props;
        let params = this.getFormSubmitParams(values, formMode)

        if (formMode === "add") {
            dispatch(photoGiveawayRulesActions.addPhotoGiveawayRule(params))
        } else if (formMode === "edit") {
            dispatch(photoGiveawayRulesActions.editPhotoGiveawayRule(params))
        }
    }

    componentDidMount() {
        this.getPhotoList();
    }

    renderForm() {
        const { formMode } = this.props;

        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        // const formtype = FORM_PHOTOGIVEAWAYRULES.values.type;
        switch (formMode) {
            case "add": {
                return (<RulePhoto readOnly={readOnly} props={this.props} FORM_NAME={FORM_NAME} />);
            }
            case "edit": {
                return (<RulePhoto readOnly={readOnly} props={this.props} FORM_NAME={FORM_NAME} />);
            }
            default: return null;
        }
    }

    render() {
        const { formOpen, formMode, classes, dispatch, handleSubmit, FORM_PHOTOGIVEAWAYRULES } = this.props

        // console.log('FORM_PHOTOGIVEAWAYRULES :',FORM_PHOTOGIVEAWAYRULES);
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        // let earnRuleTypeList = plugins.getEarnRuleListByPacekageFeature(package_features)

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth={"sm"}
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Rule' : (readOnly ? 'Rule Info' : 'Edit Rule')}</b></h4>
                </DialogTitle>

                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent className={classes.modalBody}>

                        <GridContainer>
                            <GridItem xs={12} sm={6} md={8}>
                                <Field
                                    id='rule-name-input'
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Name *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                                {formMode === "edit" ?
                                    <Field
                                        id='rule-id-input'
                                        name="_id"
                                        component="input"
                                        type="hidden"
                                    />
                                    : null}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <Field
                                    id='rule-status-select'
                                    name="status"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Status *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            value: "Active",
                                            label: "Active"
                                        },
                                        {
                                            value: "Inactive",
                                            label: "Inactive"
                                        }
                                    ]}
                                    disabled={readOnly}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='start-date-select'
                                    name="startDate"
                                    component={CustomDatePicker}
                                    type="text"
                                    maxDate={FORM_PHOTOGIVEAWAYRULES.values.endDate}
                                    labelText="Start Date *"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='end-date-select'
                                    name="endDate"
                                    minDate={FORM_PHOTOGIVEAWAYRULES.values.startDate}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText="End Date"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='end-date-select'
                                    name="expireDate"
                                    minDate={FORM_PHOTOGIVEAWAYRULES.values.endDate}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText="Expire Date"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            {/* <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='rule-type-select'
                                    name="type"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Type *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={earnRuleTypeList}
                                    disabled={readOnly || formMode === "edit"} />
                            </GridItem> */}
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={8}>
                                    <Field
                                        style={{ paddingBottom: "40px" }}
                                        name="earnCodeRecivePhoto"
                                        component={CustomCheckbox}
                                        disabled={readOnly}
                                        id="chkbox-earn-code-receive-photo"
                                        labelText="Enter code will receive a photo"
                                    />
                            </GridItem>
                        </GridContainer>
                        {this.renderForm()}

                        {/* <RulePhoto readOnly={readOnly} props={this.props} FORM_NAME={FORM_NAME} /> */}
                    </DialogContent>

                    <DialogActions
                        className={classes.modalFooter}>
                        {!readOnly ?
                            <Button
                                id='submit-btn'
                                type="submit"
                                style={{ marginRight: "10px" }}
                                color="primary">
                                {formMode === "edit" ? "Update" : "Submit"}
                            </Button>
                            : ''
                        }
                        <Button
                            id='cancel-btn'
                            type="button"
                            onClick={() => {
                                dispatch({ type: photoGiveawayRuleConstants.CLOSE_FORM_RULES });
                            }}
                            color="white">
                            Cancel
        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }

}

function mapStateToProps(state) {
    const { formOpen, formMode, photoList, allPhotoList, joinedPhotoRows, joinedPhotoList, PhotoListMaster } = state.photoGiveawayRule;
    const { FORM_PHOTOGIVEAWAYRULES } = state.form;
    const { package_features } = state.authentication
    return {
        formOpen, formMode, FORM_PHOTOGIVEAWAYRULES, allPhotoList, photoList, joinedPhotoRows, joinedPhotoList, PhotoListMaster, package_features
    };
}

const connectCampaignForm = connect(mapStateToProps)(PhotoGiveawayRuleForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {

        let globalStore = store.getState();
        let formMeta = globalStore.form[FORM_NAME];

        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
        if (lodash.has(errors, 'selectedPhotoCount')) {
            alert(dispatch, 'error', 'Please select at least a photo')
        }
    }
})(withStyles(modalStyle)(connectCampaignForm))
import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import { plugins } from 'helpers';
import EarnSummaryReport from './EarnSummaryReport'
import BurnSummaryReport from './BurnSummaryReport'
const style = {};

class SummaryReportContainer extends React.Component {

    setTab = () => {
        let set = []
        if (plugins.isHasPermission("report[summary_earn]", 'read')) {
            set.push({
                tabButton: "Earn",
                tabContent: (
                    <EarnSummaryReport initialValues={{
                        startDate: moment().startOf('month'),
                        endDate: moment().add(-1, 'days')
                    }} />
                )
            })
        }

        if (plugins.isHasPermission("report[summary_burn]", 'read')) {
            set.push({
                tabButton: "Burn",
                tabContent: (
                    <BurnSummaryReport initialValues={{
                        startDate: moment().startOf('month'),
                        endDate: moment().add(-1, 'days')
                    }} />
                )
            })
        }

        return set
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({ type: 'SUMMARY_REPORT_REDUCER_INIT' })
    }

    render() {
        return (
            <Card>
                <CardBody>
                    <NavPills
                        color="warning"
                        tabBtnType="square"
                        tabs={this.setTab()}
                    />
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { tableReset } = state.member;
    const { user, superAdmin, permission } = state.authentication;
    return {
        tableReset, user, superAdmin, permission
    };
}
const connectReportContainer = connect(mapStateToProps)(SummaryReportContainer);

export default withStyles(style)(connectReportContainer);
import _ from 'lodash';
import { roleConstants } from '../constants';

const initialState = {
    isOpen: false,
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableReload: false,
    sweetAlert: null,
    tableReset: false,
    listFeatures: [],
    permissionList: {},
    tableDatas: {
        roles: [],
        total: 0
    },
    tableKey: 0,
    tableLoader: false
};

const setAllow = (data) => {
    const allow = {}
    for (const tmp of data) {
        allow[tmp] = true
    }
    return allow
}

const setPermission = (permissions) => {
    let mock = {}
    for (const tmp of permissions) {
        if (_.has(tmp, "subRoles")) {
            mock[tmp.name] = setPermission(tmp.subRoles)
        } else {
            let allow = _.get(tmp, 'allow', [])
            if (allow.length > 0) {
                mock[tmp.name] = { allow: setAllow(allow) }
            }
        }
    }

    return mock;
}

export function role(state = initialState, action) {
    switch (action.type) {
        case roleConstants.CLEAR_PAGE_STATE:
            return initialState;
        case roleConstants.OPEN_FORM_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: {
                    _id: "",
                    ...action.formInitialValue
                }
            };
        case roleConstants.OPEN_FORM_EDIT:
            let permissionList = state.permissionList
            let listPermission = setPermission(action.formInitialValue.permission)
            let listSelectAll = {}

            for (const [key, value] of Object.entries(permissionList)) {
                let checkFeatures = Object.keys(value).filter(tmp => value[tmp] === 1)
                let checkAccessFeatures = Object.keys(_.get(listPermission, `${key}[allow]`, {})).filter(tmp => value[tmp] === 1)
                if (checkFeatures.length == checkAccessFeatures.length && _.difference(checkFeatures, checkAccessFeatures).length === 0) {
                    _.set(listSelectAll, key, true);
                }
            }

            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: {
                    _id: action.formInitialValue._id,
                    role: action.formInitialValue.role,
                    superAdmin: action.formInitialValue.superAdmin,
                    visiblePersonalData: action.formInitialValue.visiblePersonalData,
                    permission: listPermission,
                    selectAll: listSelectAll
                }
            };
        case roleConstants.CLOSE_FORM_ROLE:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                }
            };
        case roleConstants.TABLE_GET_LIST_DATAS:
            return {
                ...state,
                tableDatas: action.tmp,
                tableReload: false,
                tableReset: false,
            }
        case roleConstants.RELOAD_TABLE_ROLELIST:
        case roleConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case roleConstants.TABLE_RESET:
            return {
                ...state,
                tableReset: true
            }
        case roleConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReset: false
            }
        case roleConstants.ADD_NEW_ROLE_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            };
        case roleConstants.EDIT_ROLE_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            };
        case roleConstants.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: true,
            };
        case roleConstants.DELETE_ROLE_FAILURE:
            return {
                ...state,
                formOpen: false,
            };

        case roleConstants.GET_ROLE_REQUEST:
            return state
        case roleConstants.GET_ROLE_SUCCESS:
            return {
                ...state,
                listRoles: action.data
            };
        case roleConstants.GET_ROLE_FAILURE:
            return state;

        case roleConstants.GET_FEATURES_REQUEST:
            return state
        case roleConstants.GET_FEATURES_SUCCESS:
            return {
                ...state,
                permissionList: action.permission,
                listFeatures: action.data
            };
        case roleConstants.GET_FEATURES_FAILURE:
            return state;

        case roleConstants.HIDE_ALERT:
            return {
                ...state,
                showAlertDeleteSuccess: false,
                showAlertDeleteFailed: false
            };
        case roleConstants.SHOW_TABLE_LOADER:
            return {
                ...state,
                tableLoader: true
            }
        case roleConstants.HIDE_TABLE_LOADER:
            return {
                ...state,
                tableLoader: false
            }
        default:
            return state
    }
}
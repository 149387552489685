import { ocrReportServices } from "../services";
import { loader, alert as customAlert } from "../helpers";
import lodash from "lodash";
import i18n from 'i18n';
import qs from 'querystring'
import moment from 'moment'
import { ocrReportConstants } from "../constants";
const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';


export const ocrReportActions = {
    getOcrReport,
    getOcrReportCSV,
    getOcrReportDailyCSV
};

function getOcrReport(params) {

    if (params.startDate) {
        params.startDate = moment(params.startDate).format('YYYY-MM-DD')
    }

    if (params.endDate) {
        params.endDate = moment(params.endDate).format('YYYY-MM-DD')
    }

    return dispatch => {
        loader(dispatch, true)
        ocrReportServices
            .getOcrReport(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: ocrReportConstants.GET_OCR_REPORT,
                            data: response.data.data,
                            total: response.data.total,
                            totalRequest: response.data.totalRequest,
                            tableFilter: params
                          });
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            )
            .then(() => {
                loader(dispatch, false)
            });
    };
}

function getOcrReportCSV(params) {

    if (params.startDate) {
        params.startDate = moment(params.startDate).format('YYYY-MM-DD')
    }

    if (params.endDate) {
        params.endDate = moment(params.endDate).format('YYYY-MM-DD')
    }

    return dispatch => {
        loader(dispatch, true)
        ocrReportServices
            .getOcrReportCSV(params)
            .then(
                response => {
                    if (response.status) {
                        loader(dispatch, false)
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            )
            .then(() => {
                loader(dispatch, false)
            });
    };
}

function getOcrReportDailyCSV(params) {
    return dispatch => {
        loader(dispatch, true)
        ocrReportServices
            .getOcrReportDailyCSV(params)
            .then(
                response => {
                    if (response.status) {
                        loader(dispatch, false)
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            )
            .then(() => {
                loader(dispatch, false)
            });
    };
}
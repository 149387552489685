import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import moment from 'moment'
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from 'components/CustomButtons/Button';
import lodash from 'lodash'
import { dashboardActions } from 'actions';

const FORM_NAME = 'formFilterActiveUser'
class FormFilterActiveUser extends React.Component {

  handleSubmit(values) {
    const { dispatch } = this.props
    let tableFilter = {
      search: {
        "type": 'daily',
        "activeType": 'burn',
        "startDate": moment(lodash.get(values, 'startDate')).format('YYYY-MM'),
        "endDate": moment(lodash.get(values, 'endDate')).format('YYYY-MM'),
      }
    }

    let activeUser = lodash.get(values, 'activeUser')
    let activeType = lodash.get(values, 'activeType')

    if (activeUser === 'monthly') {
      tableFilter.search = {
        "startDate": moment(lodash.get(values, 'startDate')).format('YYYY-MM'),
        "endDate": moment(lodash.get(values, 'endDate')).format('YYYY-MM'),
        "activeType": activeType,
        "type": activeUser
      }
      tableFilter.limit = 12;
    }else if (activeUser === 'daily'){
      tableFilter.search = {
        "startDate": moment(lodash.get(values, 'startDate')).format('YYYY-MM-DD'),
        "endDate": moment(lodash.get(values, 'endDate')).format('YYYY-MM-DD'),
        "activeType": activeType,
        "type": activeUser
      }
    }else if (activeUser === 'period'){
      tableFilter.search = {
        "activeType": activeType,
        "type": activeUser
      }
    }

    if (activeType === 'burn') {
      dispatch(dashboardActions.getTableBurnDatas(tableFilter));
    } else if (activeType === 'earn') {
      dispatch(dashboardActions.getTableEarnDatas(tableFilter));
    }
    dispatch({type: 'DASHBOARD_RESET_ACTIVE_USER', tableFilter })
  }

  render() {
    const { activeUser, startDate, endDate, handleSubmit } = this.props;
    var startMinDate;
    var startMaxDate;
    if(endDate !== ''){
      startMaxDate = moment(endDate)
      startMinDate = moment(endDate).subtract(30, 'days')
    }else{
      startMaxDate = moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day')
      startMinDate = null;
    }
    var endMinDate;
    var endMaxDate = moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day')
    if(startDate !== ''){ 
      endMinDate = moment(startDate)
      if( moment(startDate).add(30, 'days') <= moment().subtract(1, 'day') ){
          endMaxDate = moment(startDate).add(30, 'days')
      }
    }else{
      endMinDate = null;
    }
    const activeTypeList = [{
      label: 'Burn',
      value: 'burn'
    }, {
      label: 'Earn',
      value: 'earn'
    }]

    const activeUserList = [{
      value: "daily",
      label: "Daily"
    }, {
      value: "monthly",
      label: "Monthly (12 months ago)"
    }, {
      value: "period",
      label: "Period"
    }]

    return (
      <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
        <GridContainer>
          <GridItem xs={12} sm={3} md={2} lg={2}>
            <Field
              id="filter_active_type"
              name="activeType"
              component={CustomSelect}
              type="text"
              labelText="Active Type"
              formControlProps={{
                fullWidth: true
              }}
              optionsList={activeTypeList}
            />
          </GridItem>

          <GridItem xs={12} sm={3} md={2} lg={2}>
            <Field
              id="filter_active_user"
              name="activeUser"
              component={CustomSelect}
              type="text"
              labelText="Active User"
              formControlProps={{
                fullWidth: true
              }}
              optionsList={activeUserList}
              onChange={(e) => {
                const value = e.target.value
                const { dispatch } = this.props;
                var update_startDate = ''
                let update_endDate = moment().subtract(1, 'day')
                update_endDate = update_endDate.set('hour',0).set('minute',0).set('second',0).set('millisecond',0)
                switch (value) {
                  case 'daily':
                    update_startDate = moment().subtract(1, 'day')
                    update_startDate = update_startDate.set('hour',0).set('minute',0).set('second',0).set('millisecond',0)
                    break;
                  case 'monthly':
                    update_startDate = moment().subtract(12, 'month')
                    update_endDate = moment().subtract(1, 'month')
                    break;
                  default: // period
                    update_startDate = moment().subtract(30, 'days')
                }

                dispatch(change(FORM_NAME, 'startDate', update_startDate))
                dispatch(change(FORM_NAME, 'endDate', update_endDate))

                // this.updateChart()
              }}
            />
          </GridItem>

          <div style={{ display: (activeUser === 'daily' ? 'contents' : 'none') }}>
            <GridItem xs={12} sm={6} md={3} lg={3}>
              <Field
                id='filter_start_date'
                name="startDate"
                component={CustomDatePicker}
                type="text"
                minDate={startMinDate}
                maxDate={startMaxDate}
                labelText="Start Date"
                dateFormat="DD-MMM-YY"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3} lg={3}>
              <Field
                id='filter_end_date'
                name="endDate"
                component={CustomDatePicker}
                type="text"
                minDate={endMinDate}
                maxDate={endMaxDate}
                labelText="End Date"
                dateFormat="DD-MMM-YY"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
          </div>

          <GridItem xs={12} sm={12} md={2} lg={2}>
            <Button
              type="submit"
              style={{ marginRight: "10px", marginTop: "25px" }}
              size="sm"
              color="primary"
              id='btn_submit'>
              Submit
              </Button>
          </GridItem>

        </GridContainer>

      </form>
    )
  }
}

FormFilterActiveUser = reduxForm({
  form: FORM_NAME,
  enableReinitialize: false,
})(FormFilterActiveUser)

const selector = formValueSelector(FORM_NAME)

FormFilterActiveUser = connect(state => {
  const activeType = selector(state, 'activeType')
  const activeUser = selector(state, 'activeUser')
  const startDate = selector(state, 'startDate')
  const endDate = selector(state, 'endDate')
  return {
    activeType, activeUser, startDate, endDate
  }
})(FormFilterActiveUser)

export default FormFilterActiveUser



import { memberLevelServices , memberServices} from '../services';
import { memberLevelConstants } from '../constants';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const memberLevelAction = {
    getListData,
    addNewData,
    editData,
    getRankingData,
    setCondition,
    getApiListMemberlevel
};

function getListData(params) {
    return dispatch => {
        dispatch({ type: memberLevelConstants.SHOW_LOADER })
        const defaultData = {
            data: [],
            total: 0
        }
        memberLevelServices.getAllList(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data));
                        } else {
                            dispatch(success(defaultData));
                        }
                    } catch (err) {
                        dispatch(success(defaultData));
                    }
                },
                error => {
                    dispatch(success(defaultData));
                }
            ).then(() => {
                dispatch({ type: memberLevelConstants.HIDE_LOADER })
            })
    };

    function success(tmp) { return { type: memberLevelConstants.GET_DATA_SUCCESS, tmp: tmp } }
}

function getRankingData(action) {
    return dispatch => {
        loader(dispatch, true)
        dispatch({ type: memberLevelConstants.SHOW_LOADER_RANK })

        const defaultData = {
            data: [],
            total: 0
        }
        memberLevelServices.getRankingData()
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data));
                        } else {
                            dispatch(success(defaultData));
                        }
                    } catch (err) {
                        dispatch(success(defaultData));
                    }
                },
                error => {
                    dispatch(success(defaultData));
                }
            ).then(() => {
                loader(dispatch, false)
                dispatch({ type: memberLevelConstants.HIDE_LOADER_RANK })
            })
    };

    function success(tmp) { return { type: memberLevelConstants.GET_RANK_DATA_SUCCESS, tmp: tmp, slug: action } }
}

function addNewData(params) {
    return dispatch => {
        loader(dispatch, true)
        memberLevelServices.addData(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: memberLevelConstants.ADD_NEW_SUCCESS
                        });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.member_level_was_created'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function editData(params) {
    return dispatch => {
        loader(dispatch, true)
        memberLevelServices.editData(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                        customAlert(dispatch, 'success', i18n.t('warning_messages.member_level_was_updated'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) { return { type: memberLevelConstants.EDIT_SUCCESS, tmp } }
}

function setCondition(params) {
    return dispatch => {
        loader(dispatch, true)
        dispatch({ type: memberLevelConstants.SHOW_LOADER_RANK })
        memberLevelServices.setCondition(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({ type: memberLevelConstants.GET_RANK_DATA_SUCCESS, tmp: response.data });
                        customAlert(dispatch, 'success', "Member Level has been set up successfully");
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                dispatch({ type: memberLevelConstants.HIDE_LOADER_RANK })
                loader(dispatch, false)
            })
    };
}

function getApiListMemberlevel(params) {
    return dispatch => {
        dispatch({ type: memberLevelConstants.SHOW_LOADER })
        const defaultData = {
            data: [],
            total: 0
        }
        memberServices.getActiveMemberLevelList(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data));
                        } else {
                            dispatch(success(defaultData));
                        }
                    } catch (err) {
                        dispatch(success(defaultData));
                    }
                },
                error => {
                    dispatch(success(defaultData));
                }
            ).then(() => {
                dispatch({ type: memberLevelConstants.HIDE_LOADER })
            })
    };

    function success(tmp) { return { type: memberLevelConstants.GET_DATA_SUCCESS, tmp: {memberLevels : tmp} } }
}
export const productConstants = {
   OPEN_ADD_PRODUCT: 'OPEN_ADD_PRODUCT',
   OPEN_EDIT_PRODUCT: 'OPEN_EDIT_PRODUCT',
   CLOSE_FORM_PRODUCT: 'CLOSE_FORM_PRODUCT',
   ADD_NEW_PRODUCT_SUCCESS: 'ADD_NEW_PRODUCT_SUCCESS',
   EDIT_PRODUCT_SUCCESS: 'EDIT_PRODUCT_SUCCESS',
   DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
   GET_PRODUCT_DATA_SUCCESS: 'GET_PRODUCT_DATA_SUCCESS',
   TABLE_GET_LIST_PRODUCTS: 'TABLE_GET_LIST_PRODUCTS',
   TABLE_RELOAD_SUCCESS: 'TABLE_PRODUCT_RELOAD_SUCCESS',
   TABLE_RESET_SUCCESS: 'TABLE_PRODUCT_RESET_SUCCESS',
   SET_FORM_PREVIEW_IMAGE: 'PRODUCT_SET_FORM_PREVIEW_IMAGE',
   CLEAR_PAGE_STATE: 'PRODUCT_CLEAR_PAGE_STATE',
   GET_CAMPAIGN_DATA_SUCCESS: 'GET_CAMPAIGN_DATA_SUCCESS',
   SHOW_TABLE_LOADER: 'SHOW_PRODUCT_TABLE_LOADER',
   HIDE_TABLE_LOADER: 'HIDE_PRODUCT_TABLE_LOADER',
   GET_CATEGORY_LIST:"GET_CAMPAIGN_CATEGORY_LIST",
   GET_CATEGORY_LIST_SUCCESS:"GET_CAMPAIGN_CATEGORY_LIST_SUCCESS"
};

import { photoGiveawayPhotoConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";

const initialState = {
    formOpen: false,
    formMode: 'add',
    // onEarnrule: false ,
    formInitialValue: {},
    tableReset: false,
    tableReload: false,
    photoGiveawayPhotoImagePreview: defaultImage,
    campaignData: [],
    tableDatas: {
        photoGiveawayPhotos: [],
        total: 0
    },
    tableKey: 0,
    tableLoader: false,
    categoryList:[]
};

export function photoGiveawayPhoto(state = initialState, action) {
    switch (action.type) {
        case photoGiveawayPhotoConstants.CLEAR_PAGE_STATE:
            return initialState;

        case photoGiveawayPhotoConstants.OPEN_ADD_PHOTO_GIVEAWAY_PHOTO:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.data,
                photoGiveawayPhotoImagePreview: defaultImage,
                // onEarnrule : false 
            };
        case photoGiveawayPhotoConstants.OPEN_EDIT_PHOTO_GIVEAWAY_PHOTO:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                photoGiveawayPhotoImagePreview: action.photoGiveawayPhotoImagePreview,
                // onEarnrule: action.onEarnrule
            };
        case photoGiveawayPhotoConstants.CLOSE_FORM_PHOTO_GIVEAWAY_PHOTO:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                    photoGiveawayPhotoImage: ""
                },
                photoGiveawayPhotoImagePreview: defaultImage
            }
        case photoGiveawayPhotoConstants.ADD_NEW_PHOTO_GIVEAWAY_PHOTO_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case photoGiveawayPhotoConstants.EDIT_PHOTO_GIVEAWAY_PHOTO_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case photoGiveawayPhotoConstants.TABLE_GET_LIST_PHOTO_GIVEAWAY_PHOTOS:
            return {
                ...state,
                tableDatas: action.tmp,
                tableReload: false,
                tableReset: false,
            }
        case photoGiveawayPhotoConstants.TABLE_RELOAD_SUCCESS:
        case photoGiveawayPhotoConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case photoGiveawayPhotoConstants.DELETE_PHOTO_GIVEAWAY_PHOTO_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case photoGiveawayPhotoConstants.SET_FORM_PREVIEW_IMAGE:
            console.log("action :",action);
            return {
                ...state,
                photoGiveawayPhotoImagePreview: action.photoGiveawayPhotoImagePreview === "default" ? defaultImage : action.photoGiveawayPhotoImagePreview
            }
        case photoGiveawayPhotoConstants.GET_CAMPAIGN_DATA_SUCCESS:
            return {
                ...state,
                campaignData: action.data
            }
        case photoGiveawayPhotoConstants.SHOW_TABLE_LOADER:
            return {
                ...state,
                tableLoader: true
            }
        case photoGiveawayPhotoConstants.HIDE_TABLE_LOADER:
            return {
                ...state,
                tableLoader: false
            }
        default:
            return state
    }
}
import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import lodash from 'lodash';
import moment from 'moment'

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from 'components/CustomButtons/Button'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// import Badge from "components/Badge/Badge.jsx";

import { plugins } from 'helpers';

import { dashboardServices } from 'services';
import { dashboardActions } from "actions/dashboard.actions";


const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                textAlign: "center",
                paddingRight: '24px',
                // "&:nth-child(3)": {
                //     textAlign: "center",
                //     paddingRight: '24px',
                // }
            },
            body: {
                // "&:nth-child(22)": {
                    textAlign: "center",
                    paddingRight: '24px',
                // }
            },
        },
    }
});

class TableDashboardEarnBySku extends React.Component {
    
    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(dashboardActions.getTableEarnBySkuDatas());
    }

    getRowData(rowIndex = 0) {
        const { earnSku } = this.props;
        var data = earnSku.data;
        return data[rowIndex];
    }

    render() {
        const { earnSku, showLoader, visiblePersonalData } = this.props;
       
        let columns = [
            { name: "_id", label: "Prefix"},
            { name: "sku", label: "SKU" },
            { name: "success", label: "Success" },
            { name: "unsuccess", label: "Unsuccess" },
        ]

        let options = {
            serverSide: false,
            rowsPerPage: 5,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            search: false,
            viewColumns: false,
            sort: false,
            paging: false
        };

        return (
            <div id="tableDashboardList">
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={showLoader?0:1}
                        data={lodash.get(earnSku,'data') ? lodash.get(earnSku,'data'):[]}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { earnSku, showLoader, tableTotal, tableFilter } = state.dashboard;
    const { formFilterActiveUser } = state.form;
    const { visiblePersonalData } = state.authentication;
    return {
        formFilterActiveUser, earnSku, showLoader, tableTotal, tableFilter, visiblePersonalData
    };
}
export default connect(mapStateToProps)(withStyles(pageStyle)(TableDashboardEarnBySku));  
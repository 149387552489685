
import { dfconfigServices } from 'services';
import { loader, alert as customAlert } from '../helpers';
import { dfconfigConstants } from '../constants';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const dfConfigActions = {
    getTableTemplateList,
    getTableConfig,
    searchTableTemplateList,
    addTableConfig,
    editTableConfig,
    checkTableConfig
}


function getTableTemplateList(queryStringParams){
    return dispatch => {
        dfconfigServices.getTableTemplateList(queryStringParams)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: dfconfigConstants.GET_TABLE_TEMPLATE_LIST, data }}
}

function getTableConfig(queryStringParams){
    return dispatch => {
        loader(dispatch, true)
        dfconfigServices.getTableConfig(queryStringParams)
            .then(
                response => {
                    if (response.status) {
                        if(response.data != "Data Not Found"){
                            dispatch(success(response.data))
                        }
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: dfconfigConstants.GET_FIELD_TEMPLATE_LIST, data }}
}
function checkTableConfig(queryStringParams){
    return dispatch => {
        loader(dispatch, true)
        dfconfigServices.getTableConfig(queryStringParams)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: dfconfigConstants.CHECK_TABLE_TEMPLATE_CONFIG, data }}
}

function searchTableTemplateList(queryStringParams){
    return dispatch => {
        loader(dispatch, true)
        dfconfigServices.getTableConfig(queryStringParams)
            .then(
                response => {
                    if (response.status) {
                        if(response.data != "Data Not Found"){
                            dispatch(success(response.data))
                        }
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: dfconfigConstants.SEARCH_FIELD_TEMPLATE_LIST, data }}
}

function addTableConfig(params) {
    let data = []
     data ={
        tables : params
     }
    return dispatch => {
       loader(dispatch, true)
       dfconfigServices.addTableConfig(data)
          .then(
             response => {
                if (response.status) {
                   dispatch({
                      type: dfconfigConstants.ADD_NEW_DF_CONFIG_SUCCESS
                   });
                   customAlert(dispatch, 'success', i18n.t('warning_messages.configdatafiltering_was_created'));
                } else {
                   if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                }
             },
             error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
             }
          ).then(() => {
             loader(dispatch, false)
          })
    };
 }
 
 function editTableConfig(params,key){
    let data = []
    data ={
       tables : params
    }
   return dispatch => {
      loader(dispatch, true)
      dfconfigServices.editTableConfig(data, key)
         .then(
            response => {
               if (response.status) {
                  dispatch({
                     type: dfconfigConstants.EDIT_NEW_DF_CONFIG_SUCCESS, data: response.data
                  });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.configdatafiltering_was_created'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
 }
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";


import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import InputUpload from 'components/CustomUpload/InputUpload.jsx';

import { upload_3rd_partyConstants } from '../../constants';
import { uploadActions } from 'actions';

const FORM_NAME = "FORM_IMPORT_CODE";

class UploadFormImport extends React.Component {

   handleSubmit = (values) => {
      const { dispatch } = this.props;
      dispatch(uploadActions.apiImport3rdPartyCode(values));
   }

   render() {
      const { formUpload, formMode, classes, dispatch, handleSubmit } = this.props;
      let { readOnly } = this.props
      if (formMode === `add`) {
         readOnly = false
      } else if (formMode === `edit` && !readOnly) {
         readOnly = false
      } else {
         readOnly = true
      }
      return (
         <Dialog
            classes={{
               root: classes.center,
               paper: classes.modal
            }}
            open={formUpload}
            keepMounted
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            fullWidth={true}
            maxWidth="sm"
            disableRestoreFocus={true}
            scroll="body">

            <DialogTitle
               disableTypography
               className={classes.modalHeader}>
               <h4 className={classes.modalTitle}><b>Upload 3rd Party Reward Code</b></h4>
            </DialogTitle>
            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
               <DialogContent
                  id="banner-form-dialog"
                  className={classes.modalBody}
               >
               {formMode === 'edit' ?
                  <GridContainer>
                     <GridItem xs={12} sm={6} md={6}>
                        <Field
                           name="displayId"
                           component={CustomInput}
                           type="text"
                           labelText="Banner ID"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled
                        />
                     </GridItem>
                  </GridContainer>
               : null}
                  <GridContainer>
                     <Field
                        name="codeSetId"
                        component="input"
                        type="hidden"
                     />
                     <Field
                        name="merchantId"
                        component="input"
                        type="hidden"
                     />
                     <GridItem xs={12} sm={6} md={6}>
                        { formUpload &&
                        <Field
                           name="fileImport"
                           component={InputUpload}
                           type="file"
                           label="File Import"
                           accept=".csv"
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                        }
                     </GridItem>
                  </GridContainer>
               </DialogContent>

               <DialogActions
                  className={classes.modalFooter}>
                  {!readOnly ?
                     <Button
                        type="submit"
                        style={{ marginRight: "10px" }}
                        color="primary">
                        Submit
               </Button>
                     : ''
                  }
                  <Button
                     type="button"
                     onClick={() => {
                        dispatch({ type: upload_3rd_partyConstants.CLOSE_FORM_UPLOAD });
                     }}
                     color="white">
                     Cancel
               </Button>
               </DialogActions>
            </form>
         </Dialog>
      )
   }
}

function mapStateToProps(state) {
   const { formUpload, formMode, campaignData } = state.upload_3rd_party;
   const { FORM_IMPORT_CODE } = state.form;
   return {
      formUpload, formMode, FORM_IMPORT_CODE, campaignData
   };
}

const connectUploadFormImport = connect(mapStateToProps)(UploadFormImport);

export default reduxForm({
   form: FORM_NAME,
   enableReinitialize: true,
})(withStyles(modalStyle)(connectUploadFormImport))
import { trueMoneyTopupServices } from "../services"
import { loader, alert as customAlert } from "../helpers"
import lodash from "lodash"
import i18n from "i18n"
import qs from "querystring"
import moment from "moment"
import { tmnTopupConstants } from "../constants"

const defaultFailedText = "api_messages.SOMETHING_WENT_WRONG"

export const trueMoneyTopupActions = {
  getTrueMoneyTransactionTopup,
  RetryTrueMoney
}



function RetryTrueMoney(params) {
  return dispatch => {
      loader(dispatch, true)
      trueMoneyTopupServices.RetryTrueMoney(params)
          .then(
              response => {
                  if (response.status) {
                        customAlert(dispatch, 'success', 'SUCCESS')
                        dispatch({ type: tmnTopupConstants.TABLE_RESET })
                  } else {
                      if (!response.unAlert) {
                          customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                      }
                  }
              },
              error => {
                  customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
          ).then(() => {
              loader(dispatch, false)
          })
  }
}



function getTrueMoneyTransactionTopup(params, tableReset) {
  if (params.createDateFrom) {
    params.createDateFrom = moment(params.createDateFrom).format()
  }

  if (params.createDateTo) {
    params.createDateTo = moment(params.createDateTo).milliseconds(999).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
  }
  return (dispatch) => {
    loader(dispatch, true)
    trueMoneyTopupServices
      .getTopupLists(qs.stringify(params))
      .then(
        (response) => { 
          if (response.status) {
            if (lodash.has(response, "data.data")) {
              dispatch({
                type: tmnTopupConstants.GET_TRUEMONEY_TOPUP_SUCCESS,
                data: response.data.data,
                tableFilter: params,
                total: response.data.total,
              })

              if (tableReset) {
                dispatch({
                  type: tmnTopupConstants.TRUEMONEY_TOPUP_TABLE_RESET,
                })
              }
            }
          } else {
            if (!response.unAlert)
          {
              customAlert(
                dispatch,
                "error",
                i18n.t(`api_messages.${response.message.toString()}`)
              )
          }
          }
        },
        (error) => {
          customAlert(dispatch, "error", i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false)
      })
  }
}
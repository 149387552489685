import { configConstants } from '../constants';
import axios from 'axios';
import lodash from 'lodash';

import {
    history,
    crmValidator
} from 'helpers';

export const dataFilteringResultServices = {
    getCampaignsList,
    getDataFilteringResultList,
    getDataFilteringConditionById,
    getDataFilteringFromDataPlatformByResultId,
    assignCampaign,
    unAssignCampaign,
    assignTag,
    deleteDataFilteringResult,
    getDataFilteringFromDataPlatformByFilename,
};

function getCampaignsList(params) {
    let req_headers = {
        'Content-Type': 'application/json',
        'Charset': 'utf-8',
        'Authorization': localStorage.getItem('token')
    }
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getDataFilteringResultList(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_DATAFILTERING + '/data-filtering/result-all', params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function getDataFilteringConditionById(conditionId) {
    let req_headers = {
        "Content-Type": "application/json",
        "Charset": "utf-8",
        "Authorization": localStorage.getItem('token')
    }
    
    let tmp = axios.get(configConstants.API_DATAFILTERING + `/data-filtering/condition/${conditionId}`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                // return response.data.data
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
 
    return tmp;
 }

 function getDataFilteringFromDataPlatformByResultId(resultId) {
    let req_headers = {
        "Content-Type": "application/json",
        "Charset": "utf-8",
        "Authorization": localStorage.getItem('token')
    }
    
    let tmp = axios.get(configConstants.API_DATAFILTERING + `/data-filtering/result/${resultId}`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                // return response.data.data
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
 
    return tmp;
 }

 function assignCampaign(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_DATAFILTERING + `/data-filtering/assign/campaign`, params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
 }

 function unAssignCampaign(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_DATAFILTERING + `/data-filtering/unassign/campaign`, params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
 }

 function assignTag(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_DATAFILTERING + `/data-filtering/assign/tag`, params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
 }

 function unAssignTag(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_DATAFILTERING + `/data-filtering/unassign/tag`, params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
 }

 function deleteDataFilteringResult(params) {
    let req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    let tmp = axios.delete(configConstants.API_DATAFILTERING + `/data-filtering/result?conditionId=${params.conditionId}&resultId=${params.resultId}&report=${params.reportName}`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
 }

 function getDataFilteringFromDataPlatformByFilename(params) {
    let req_headers = {
        "Content-Type": "application/json",
        "Charset": "utf-8",
        "Authorization": localStorage.getItem('token')
    }
    
    let tmp = axios.post(configConstants.API_DATAFILTERING + `/data-filtering/result/visualization`, params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                // return response.data.data
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
 
    return tmp;
 }
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, arrayPush, getFormValues } from 'redux-form';
import { crmValidator, normalize } from 'helpers';
import lodash from 'lodash'
import withStyles from "@material-ui/core/styles/withStyles";

// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { promotionConstants } from "../../../constants"
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

const FORM_NAME = 'purchase_amount_per_bill_form'

const style = {
    ...modalStyle
}

const form_validate = {
    per_bill: {
        rules: {
            required: true,
            min: 0.01
        },
        messages: {
            required: 'Purchase Amount per Bill is required',
            min: "Please enter number more than 0"
        },
        alert: "Purchase Amount per Bill is required"
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class ConditionPurchaseAmountPerBill extends React.Component {

    handleSubmit(values) {
        const { dispatch, promotionFormValues } = this.props
        let conditions = lodash.get(promotionFormValues, 'conditions')

        lodash.remove(conditions, function (n) {
            return n.type === 'purchase_amount_per_bill'
        });

        dispatch(arrayPush('promotion_form', 'conditions', {
            type: 'purchase_amount_per_bill',
            ...values
        }))

        dispatch({ type: promotionConstants.CLOSE_PURCHASE_AMOUNT_CONDITION })
    }

    render() {
        const { classes, dispatch, purchaseAmountPerbillOpen, handleSubmit, readOnly } = this.props
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={purchaseAmountPerbillOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="xs"
                disableRestoreFocus={true}
                scroll="body">
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{ paddingBottom: "0px" }}
                >
                    <h4 className={classes.modalTitle}><b>Purchase Amount per Bill Condition</b></h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                    <DialogContent
                        id="purchase-amount-per-bill-condition-dialog"
                        className={classes.modalBody}
                    >
                        <GridContainer>
                            <GridItem xs={12}>
                                <Field
                                    name="per_bill"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Purchase Amount per Bill"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={normalize.onlyFloatingTwo}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        {!readOnly &&
                            <Button
                                id={`promotion_purchase_amount_condition_btn_submit`}
                                type="submit"
                                style={{ marginRight: "10px" }}
                                color="primary">
                                Submit
                        </Button>
                        }
                        <Button
                            id={`promotion_purchase_amount_condition_btn_cancel`}
                            type="button"
                            onClick={() => {
                                dispatch({ type: promotionConstants.CLOSE_PURCHASE_AMOUNT_CONDITION });
                            }}
                            color="white">
                            Cancel
                    </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { purchaseAmountPerbillOpen } = state.promotion;
    const promotionFormValues = getFormValues('promotion_form')(state)
    return {
        purchaseAmountPerbillOpen, promotionFormValues
    };
}

const connectedComponent = connect(mapStateToProps)(ConditionPurchaseAmountPerBill);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: false,
})(withStyles(style)(connectedComponent))

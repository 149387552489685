import { tmnTopupConstants } from "../constants"
const initialState = {
   isOpen: false,
   formOpen: false,
   formMode: 'add',
   formInitialValue: {},
   tableReload: false,
   sweetAlert: null,
   tableReset: false,
   errDetailOpen: false,
   tableDatas: [],
   tableTotal: 0,
   tableKey: Date.now(),
   tableLoader: false,
   tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "created_at",
      sortDir: "desc",
      createDateFrom: '',
      createDateTo: '',
      status: '',
   },
};

export function tmnTopup(state = initialState, action) {
   switch (action.type) {
      case tmnTopupConstants.CLEAR_PAGE_STATE:
         return initialState;
      case tmnTopupConstants.TABLE_GET_LIST_DATA:
         return {
            ...state,
            tableDatas: action.tmp,
            tableReload: false,
            tableReset: false,
         }
      case tmnTopupConstants.TABLE_RELOAD:
      case tmnTopupConstants.TABLE_RELOAD_SUCCESS:
         return {
            ...state,
            tableReload: false,
            tableReset: false,
         }
      case tmnTopupConstants.TABLE_RESET:
         return {
            ...state,
            tableReset: true
         }
      case tmnTopupConstants.TABLE_RESET_SUCCESS:
         return {
            ...state,
            tableReset: false
         }
      case tmnTopupConstants.HIDE_ALERT:
         return {
            ...state,
            showAlertDeleteSuccess: false,
            showAlertDeleteFailed: false
         };
      case tmnTopupConstants.SHOW_TABLE_LOADER:
         return {
            ...state,
            tableLoader: true
         }
      case tmnTopupConstants.HIDE_TABLE_LOADER:
         return {
            ...state,
            tableLoader: false
         }
      case tmnTopupConstants.CLEAR_TRUEMONEY_TOPUP_FILTER:
         return {
            ...state,
            tableFilter: initialState.tableFilter,
         }
      case tmnTopupConstants.ERR_DETAIL_IS_OPEN:
         return {
            ...state,
            errDetailOpen: true
         }
      case tmnTopupConstants.ERR_DETAIL_IS_CLOSE:
         return {
            ...state,
            errDetailOpen: false,
         }
      case tmnTopupConstants.GET_TRUEMONEY_TOPUP_SUCCESS:
         return {
            ...state,
            tableFilter: action.tableFilter,
            tableDatas: action.data ? action.data : [],
            tableTotal: action.total ? action.total : 0,
         }
      case tmnTopupConstants.TRUEMONEY_TOPUP_TABLE_RESET:
         return {
            ...state,
            tableKey: Date.now(),
         }
      default:
         return state
   }
}
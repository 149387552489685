import { pointAdjustmentConstants } from '../constants';
import { pointAdjustmentServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import lodash from 'lodash';
import i18n from 'i18n';
import qs from "querystring"
import moment from 'moment'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const pointAdjustmentActions = {
  getTablePointAdjustmentDatas,
  getUserByPhoneNumber,
  createPointAdjustment,
  rejectPointAdjustment,
  approvePointAdjustment,
  getUserByPhoneNumberTableApprove
};

function getTablePointAdjustmentDatas(params, tableReset = false) {
  if (params.createDateFrom) {
    params.createDateFrom = moment(params.createDateFrom).format()
  }
  if (params.createDateTo) {
    params.createDateTo = moment(params.createDateTo).format()
  }
  if (params.updateDateFrom) {
    params.updateDateFrom = moment(params.updateDateFrom).format()
  }
  if (params.updateDateTo) {
    params.updateDateTo = moment(params.updateDateTo).format()
  }
  return dispatch => {
    loader(dispatch, true);
    pointAdjustmentServices.getTablePointAdjustmentDatas(qs.stringify(params))
      .then(
        response => {
          if (response.status) {
            dispatch({
              type: pointAdjustmentConstants.GET_LIST_TABLE_POINT_ADJUSTMENT,
              data: response.data.pointAdjustments,
              total: response.data.total,
              tableFilter: params
            });
            if (tableReset) {
              dispatch({ type: pointAdjustmentConstants.POINT_ADJUSTMENT_TABLE_RESET });
            }
          } else {
            if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      ).then(() => {
        loader(dispatch, false);
      })
  };
}

function getUserByPhoneNumber(phoneNumber) {
  return dispatch => {
    loader(dispatch, true)
    pointAdjustmentServices.getUserByPhoneNumber(phoneNumber)
      .then(
        response => {
          if (response.status) {
            dispatch({ type: "GET_USER_BY_PHONE_NUMBER", data: lodash.get(response, 'data') });
          }else{
            dispatch({ type: "GET_USER_BY_PHONE_NUMBER", data: false });
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false);
      });
  }
}

function getUserByPhoneNumberTableApprove(phoneNumber) {
  return dispatch => {
    loader(dispatch, true)
    pointAdjustmentServices.getUserByPhoneNumber(phoneNumber)
      .then(
        response => {
          if (response.status) {
            dispatch({ type: "GET_USER_BY_PHONE_NUMBER", data: lodash.get(response, 'data') });
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then((response) => {
        dispatch({ type: "OPEN_APPROVAL_SUCCESS_POPUP", data: lodash.get(response, 'data') })
        loader(dispatch, false);
      });
  }
}

function createPointAdjustment(reqBody) {
  return dispatch => {
    loader(dispatch, true)
    pointAdjustmentServices.createPointAdjustment(reqBody)
      .then(
        response => {
          try {
            if (response.status) {
              dispatch({ type: pointAdjustmentConstants.ADD_POINT_ADJUSTMENT_SUCCESS });
              customAlert(dispatch, 'success', i18n.t('warning_messages.point_adjustment_was_created'));
            } else {
              let resmessage = `${lodash.get(response, 'message')}`
              if (!response.unAlert) {
                if (resmessage === "BAD_REQUEST") {
                  let resData = lodash.join(lodash.get(response, 'data'), ',')
                  if(resData === 'PHONE_NUMBER_IS_REQUIRED'){
                    customAlert(dispatch, 'error', 'Mobile Number is required')
                  }
                }
              } else {
                customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
              }
            }
          } catch (e) {
            customAlert(dispatch, 'error', i18n.t(defaultFailedText))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      ).then(() => {
        loader(dispatch, false)
      })
  }
}

function rejectPointAdjustment(params) {
  return dispatch => {
    loader(dispatch, true)
    pointAdjustmentServices.rejectPointAdjustment(params)
      .then(
        tmp => {
          if (tmp.status) {
            dispatch({ type: pointAdjustmentConstants.REJECT_APPROVE_POINT_ADJUSTMENT_SUCCESS });
          } else {
            if (!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      ).then(() => {
        loader(dispatch, false)
      })
  };
}

function approvePointAdjustment(params, phoneNumber) {
  return dispatch => {
    loader(dispatch, true)
    pointAdjustmentServices.approvePointAdjustment(params)
      .then(
        tmp => {
          if (tmp.status) {
            dispatch({ type: pointAdjustmentConstants.REJECT_APPROVE_POINT_ADJUSTMENT_SUCCESS });
            dispatch(pointAdjustmentActions.getUserByPhoneNumberTableApprove(phoneNumber));
          } else {
            if (!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      ).then(() => {
        loader(dispatch, false)
      })
  };
}
export const dfconfigConstants = {
    INIT_LIST_CONSTANT: 'INIT_LIST_CONSTANT',
    GET_TABLE_TEMPLATE_LIST:"GET_TABLE_TEMPLATE",
    GET_FIELD_TEMPLATE_LIST:"GET_FIELD_TEMPLATE_LIST",

    ADD_NEW_DF_CONFIG_SUCCESS: 'ADD_NEW_DF_CONFIG_SUCCESS',
    EDIT_NEW_DF_CONFIG_SUCCESS: 'EDIT_NEW_DF_CONFIG_SUCCESS',
    ADD_NEW_DF_CONFIG_FAILURE: 'ADD_NEW_DF_CONFIG_FAILURE',

    SET_TABLE_TEMPLATE_LIST: 'SET_TABLE_TEMPLATE_LIST',
    SEARCH_FIELD_TEMPLATE_LIST: 'SEARCH_FIELD_TEMPLATE_LIST',
    CHECK_TABLE_TEMPLATE_CONFIG: 'CHECK_TABLE_TEMPLATE_CONFIG',
}

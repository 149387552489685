import React from "react";
import { connect } from 'react-redux';
import i18n from 'i18n';

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import classNames from "classnames";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { alertActions } from 'actions';

const swalContainerClass = "swal-container";

class SweetAlertPage extends React.Component {
    componentDidUpdate() {
        let componentDanger = document.getElementsByClassName('swal-btn-danger')[0]
        if (componentDanger) {
            componentDanger.setAttribute('id', 'swal-btn-danger')
        }

        let componentConfirm = document.getElementsByClassName('swal-btn-confirm')[0]
        if (componentConfirm) {
            componentConfirm.setAttribute('id', 'swal-btn-confirm')
        }

        let component = document.getElementsByClassName(swalContainerClass)[0]
        if (component) {
            component.setAttribute('id', swalContainerClass)
        }
    }

    onConfirm() {
        const { dispatch, alert } = this.props;
        if (typeof alert.onConfirm === 'function') {
            alert.onConfirm()
        }
        dispatch(alertActions.modal_clear());
    }

    onClosed() {
        const { dispatch } = this.props;
        dispatch(alertActions.modal_clear());
    }

    render() {
        const { classes, alert } = this.props;

        const btnClasses = classNames({
            [" " + classes.danger]: alert.isError,
            [" " + classes.success]: !alert.isError
        });

        const btnClassesCancel = classNames({
            [" " + classes.danger]: false,
            [" " + classes.warning]: true
        });

        return (
            <SweetAlert
                success={alert.isError ? false : true}
                danger={alert.isError}
                show={alert.isShow}
                showCancel={(typeof alert.onConfirm === 'function')}
                style={{ display: "block", marginTop: "-100px" }}
                title={(<span id="swal-title">{alert.message}</span>)}
                onConfirm={this.onConfirm.bind(this)}
                onCancel={this.onClosed.bind(this)}
                confirmBtnText={(typeof alert.onConfirm === 'function') ? i18n.t("upgrade_package") : i18n.t("ok")}
                cancelBtnText={i18n.t("closed")}
                customClass={
                    alert.isError ? classes.alertHeaderDanger + " " + swalContainerClass : classes.alertHeaderSuccess + " " + swalContainerClass
                }
                confirmBtnCssClass={
                    classes.button + " " + btnClasses + " swal-btn-" + (alert.isError ? "danger" : "confirm")
                }
                cancelBtnCssClass={
                    classes.button + " " + btnClassesCancel + " swal-btn-success"
                }
            >
                {/* <span style={{
                    color: alert.isError? 'red': null
                }}>{alert.body ? alert.body : ''}</span> */}
                {alert.body ? <div style={{
                    fontSize: '16px',
                    color: alert.isError ? '#f44336' : null
                }} dangerouslySetInnerHTML={{ __html: alert.body }}></div> : ''}
            </SweetAlert>
        );
    }
}

function mapStateToProps(state) {
    const alert = state.alert;
    return {
        alert
    };
}

const connectApp = connect(mapStateToProps)(withStyles(sweetAlertStyle)(SweetAlertPage));

export { connectApp as SweetAlertPage }

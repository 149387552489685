import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";
import { Field,reduxForm , reset } from 'redux-form';
import i18n from 'i18n';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import PhotoGiveawayRuleForm from "views/PhotoGiveaway/PhotoGiveawayRuleForm.jsx";

import { photoGiveawayRuleConstants, swalConstants } from '../../constants';
import { photoGiveawayRulesActions } from 'actions';

import { plugins, crmValidator , loader, alert as customAlert } from "helpers";

const style = {
    actionDiv: {
        display: "inline-block"
    },
    upperCase: {
        textTransfrom: 'uppercase'
    }
};

const FORM_NAME = 'PRODUCT_FILTER_FORM'

const moduleName = 'photoGiveaway[photoGiveawayRule]'

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "Active": BadgeColor = "success"; break;
        case "Expired": BadgeColor = "danger"; break;
        default: BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}
class TablePhotoGiveawayRuleList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        };

        this.delayedCallback = lodash.debounce(this.getData, 500)

    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(photoGiveawayRulesActions.getPhotoGiveawayRuleLists(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: photoGiveawayRuleConstants.CLEAR_PAGE_STATE });
    }

    componentDidMount() {
        const { dispatch } = this.props;
        this.getData();

        // console.log('get data');
    }

    exportCSV() {
        const { dispatch } = this.props;
        let tableFilter = {
           page:0,
           rowsPerPage:0,
           skip:0,
           limit:10,
           sortBy:"created_at",
           sortDir:"desc",
        }
  
        let reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');
  
        loader(dispatch, true)
        // productServices.downloadProductReport(reqParams)
        .then(response => {
           loader(dispatch, false)
        })
        .catch(e => {
           customAlert(dispatch, 'error', "api_messages.SOMETHING_WENT_WRONG")
           loader(dispatch, false)
        });
    }

    displayId(val) {
        if (val) {
           return `PG${val.toString().padStart(5, '0')}`
        }
     }

    openAddPhotoGiveaway = () => {
        const { dispatch } = this.props;
        let initialValues = {
            startDate: "",
            endDate: "",
            expireDate: ""
        }
        dispatch({ type: photoGiveawayRuleConstants.OPEN_ADD_NEW_RULES, data: initialValues });
    }

    openEditPhotoGiveawayRule(index) {
        const { dispatch, tableData } = this.props;
        const rowData = tableData.photoGiveawayRule[index]
        dispatch(photoGiveawayRulesActions.getPhotoGiveawayRuleById(rowData._id))
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: photoGiveawayRuleConstants.TABLE_RELOAD_SUCCESS })
        }
    }
  
    render() {
        const { classes, dispatch, formInitialValue, tableData, tableKey, tableLoader } = this.props;
        const { rowsPerPage, page } = this.state;

        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            {
                name: "photoGiveawayRuleId", label: "Photo ID", options: {
                   customBodyRender: (value) => {
                      return this.displayId(value)
                   }
                }
            },
            {
                name: "name", label: "Photo Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<div style={{ minWidth: "130px" }}>{value}</div>)
                    }
                }
            },
            {
                name: "status", label: "Status", options: {
                    // filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return renderBadgeStatus(value)
                    }
                }
            },

        ];

        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classes.actionDiv}>
                                {
                                    plugins.isHasPermission(moduleName, 'update') && 
                                    <TableActionButton
                                        actionType="edit"
                                        onClick={(e) => {
                                            this.openEditPhotoGiveawayRule(tableMeta.rowIndex)
                                        }} 
                                    />
                                }
                                {plugins.isHasPermission(moduleName, 'delete') &&

                                    <TableActionButton
                                        actionType="delete"
                                        onClick={(e) => {
                                            dispatch({
                                                type: swalConstants.SHOW, data: {
                                                    type: "warning",
                                                    title: i18n.t("warning_messages.are_you_sure_delete_product"),
                                                    message: `${tableMeta.rowData[4]}`,
                                                    onConfirm: function () {
                                                        let reqParams = {
                                                            _id: tableMeta.rowData[0]
                                                        }
                                                        dispatch({ type: swalConstants.HIDE })
                                                        dispatch(photoGiveawayRulesActions.deletePhotoGiveawayRule(reqParams));
                                                    },
                                                    onCancel: function () {
                                                        dispatch({ type: swalConstants.HIDE })
                                                    },
                                                    showCancel: true,
                                                    confirmBtnText: i18n.t("delete"),
                                                    cancelBtnText: i18n.t("cancel")
                                                }
                                            })
                                        }} />
                                }
                            </div>
                        )
                    }
                }
            })
        }

        let options = {
            serverSide: true,
            count: tableData.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            customToolbar: () => {

                return (
                   <>
                      {plugins.isHasPermission(moduleName, 'create') &&(
                         <CustomToolbar id="add_new_photo_giveaway" handleClick={this.openAddPhotoGiveaway} title="Add New" />
                      )}
                   </>
                );
             },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };



        return (
            <div id="TablePhotoGiveawayRuleList">
                <CustomSweetAlert />
                <PhotoGiveawayRuleForm
                    initialValues={formInitialValue}
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                />
                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        title="Rule List"
                        data={tableData.photoGiveawayRule}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner />}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableData, tableKey, tableLoader } = state.photoGiveawayRule;
    // console.log("state.photoGiveawayRule : ",state.photoGiveawayRule)
    // const { permission, superAdmin } = state.authentication;
    return {
        formInitialValue, tableReload, tableReset, tableData, tableKey, tableLoader
    };
}

const connectedComponent =   connect(mapStateToProps)(withStyles(pageStyle)(TablePhotoGiveawayRuleList));

export default reduxForm({
    form: FORM_NAME,
  })(withStyles(style)(connectedComponent))
 
export const dashboardConstants = {
   GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
   GET_STATISTICS_FAILURE: 'GET_STATISTICS_FAILURE',
   SHOW_LOADER_DASHBOARD: 'SHOW_LOADER_DASHBOARD',
   HIDE_LOADER_DASHBOARD: 'HIDE_LOADER_DASHBOARD',
   
   DASHBOARD_CLEAR_STATE: 'DASHBOARD_CLEAR_STATE',

   SET_FILTER: 'TABLE_DASHBOARD_SETFILTER',

   TABLE_GET_LIST_DATAS: 'DASHBOARD_ACTIVE_USER_TABLE_GET_LIST_DATAS',
   DASHBOARD_CHANGE_TABLEKEY:'DASHBOARD_CHANGE_TABLEKEY',

   SHOW_LOADER_DAILY_REGISTRATION_DASHBOARD: 'SHOW_LOADER_DAILY_REGISTRATION_DASHBOARD',
   HIDE_LOADER_DAILY_REGISTRATION_DASHBOARD: 'HIDE_LOADER_DAILY_REGISTRATION_DASHBOARD',
   TABLE_GET_DAILY_REGISTRATION_LIST_DATAS: 'DASHBOARD_ACTIVE_DAILY_REGISTRATION_TABLE_GET_LIST_DATAS',

   SHOW_LOADER_DAILY_EARN_TRANSACTION_DASHBOARD: 'SHOW_LOADER_DAILY_EARN_TRANSACTION_DASHBOARD',
   HIDE_LOADER_DAILY_EARN_TRANSACTION_DASHBOARD: 'HIDE_LOADER_DAILY_EARN_TRANSACTION_DASHBOARD',
   TABLE_GET_DAILY_EARN_TRANSACTION_LIST_DATAS: 'DASHBOARD_ACTIVE_DAILY_EARN_TRANSACTION_TABLE_GET_LIST_DATAS',

   SHOW_LOADER_DAILY_BURN_TRANSACTION_DASHBOARD: 'SHOW_LOADER_DAILY_BURN_TRANSACTION_DASHBOARD',
   HIDE_LOADER_DAILY_BURN_TRANSACTION_DASHBOARD: 'HIDE_LOADER_DAILY_BURN_TRANSACTION_DASHBOARD',
   TABLE_GET_DAILY_BURN_TRANSACTION_LIST_DATAS: 'DASHBOARD_ACTIVE_DAILY_BURN_TRANSACTION_TABLE_GET_LIST_DATAS',

   GET_KEYSUMMARY_SUCCESS: 'GET_KEYSUMMARY_SUCCESS',
   GET_KEYSUMMARY_FAILURE: 'GET_KEYSUMMARY_FAILURE',

   GET_REGISTRATION_SUCCESS: 'GET_REGISTRATION_SUCCESS',
   GET_REGISTRATION_FAILURE: 'GET_REGISTRATION_FAILURE',

   GET_REGISTRATION_DAYOFWEEK_SUCCESS: 'GET_REGISTRATION_DAYOFWEEK_SUCCESS',
   GET_REGISTRATION_DAYOFWEEK_FAILURE: 'GET_REGISTRATION_DAYOFWEEK_FAILURE',

   GET_EARN_STATUS_SUCCESS: 'GET_EARN_STATUS_SUCCESS',
   GET_EARN_STATUS_FAILURE: 'GET_EARN_STATUS_FAILURE',

   GET_BURN_STATUS_SUCCESS: 'GET_BURN_STATUS_SUCCESS',
   GET_BURN_STATUS_FAILURE: 'GET_BURN_STATUS_FAILURE',

   GET_EARN_SKU_SUCCESS: 'GET_EARN_SKU_SUCCESS',
   GET_EARN_SKU_FAILURE: 'GET_EARN_SKU_FAILURE',

   GET_REGISTRATION_BY_HOUR_SUCCESS: 'GET_REGISTRATION_BY_HOUR_SUCCESS',
   GET_REGISTRATION_BY_HOUR_FAILURE: 'GET_REGISTRATION_BY_HOUR_FAILURE',

   GET_REGISTRATION_BY_PROVINCE_SUCCESS: 'GET_REGISTRATION_BY_PROVINCE_SUCCESS',
   GET_REGISTRATION_BY_PROVINCE_FAILURE: 'GET_REGISTRATION_BY_PROVINCE_FAILURE',

   GET_BURN_TRANSACTION_BY_CAMPAIGN_SUCCESS: 'GET_BURN_TRANSACTION_BY_CAMPAIGN_SUCCESS',
   GET_BURN_TRANSACTION_BY_CAMPAIGN_FAILURE: 'GET_BURN_TRANSACTION_BY_CAMPAIGN_FAILURE',

   GET_INACTIVE_USER:"GET_DASHBOARD_INACTIVE_USER",
   SHOW_LOADER_INACTIVE_USER:"SHOW_LOADER_INACTIVE_USER",
   HIDE_LOADER_INACTIVE_USER:"HIDE_LOADER_INACTIVE_USER",
   
   GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
   GET_BURN_TRANSACTION_BY_DAY_HOUR_SUCCESS: 'GET_BURN_TRANSACTION_BY_DAY_HOUR_SUCCESS',
   GET_BURN_TRANSACTION_BY_DAY_HOUR_FAILURE: 'GET_BURN_TRANSACTION_BY_DAY_HOUR_FAILURE',

   GET_EARN_TRANSACTION_BY_DAY_HOUR_SUCCESS: 'GET_EARN_TRANSACTION_BY_DAY_HOUR_SUCCESS',
   GET_EARN_TRANSACTION_BY_DAY_HOUR_FAILURE: 'GET_EARN_TRANSACTION_BY_DAY_HOUR_FAILURE',
};

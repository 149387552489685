import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import lodash from 'lodash'
import { CSVLink } from "react-csv";
import moment from 'moment'

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputUpload from 'components/CustomUpload/InputUpload.jsx';
import CustomSelect from "components/CustomSelect/CustomSelect";
import Button from "components/CustomButtons/Button.jsx";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { shipmentActions } from 'actions';
import { crmValidator, plugins } from 'helpers';
// import FileSaver from 'file-saver';

const FORM_NAME = 'FORM_IMPORT_FILE'
const form_validate = {
    "importType": {
        rules: {
            required: true,
        },
        messages: {
            required: 'Required',
        }
    },
    "file": {
        rules: {
            required: true,
            fileSize: 2097152
        },
        messages: {
            required: 'Please select file',
            fileSize: 'Selected File size must be less than 2MB'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const validateImportType = (value) => {
    let allowType = ['purchase_transaction','create_product', 'member']
    if(allowType.indexOf(value) === -1){
        return "Not allowed Import type"
    }
}

const acceptFileType = (importType) => {
    if((importType === 'purchase_transaction') || (importType === 'create_product')){
        return ".csv"
    }else if(importType === 'member'){
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    }else{
        return ".csv"
    }
}

class FormImportFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            csvHeaders: [],
            csvData: [],
            csvFileName: ""
        }
        this.csvLink = React.createRef()
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props
        var reqParams = {
            "file": values.file
        }

        console.log('values :',values);
        dispatch({ type: "CLOSE_SHIPMENT_FORM_IMPORT" })
        dispatch(shipmentActions.importFile(reqParams));
    }


    genTemplateShipment(fileName){

        const headers = [{
            label: 'shippingId',
            key: 'shippingId'
        },{
            label: 'First name',
            key: 'firstName'
        },{
            label: 'Last name',
            key: 'lastName'
        },{
            label: 'Redemption Date',
            key: 'redemptionDate'
        },{
            label: 'Campaign',
            key: 'campaign'
        },{
            label: 'Reward Name',
            key: 'rewardName'
        },{
            label: 'Receiver First name',
            key: 'receiverFirstName'
        },{
            label: 'Receiver Last name',
            key: 'receiverLastName'
        },{
            label: 'Phone Number',
            key: 'phoneNumber'
        },{
            label: 'Address',
            key: 'address'
        },{
            label: 'SubDistrict',
            key: 'subDistrict'
        },{
            label: 'District',
            key: 'district'
        },{
            label: 'Province',
            key: 'province'
        },{
            label: 'Zipcode',
            key: 'zipcode'
        },{
            label: 'Status',
            key: 'status'
        },{
            label: 'Shipped Date',
            key: 'shippedDate'
        },{
            label: 'Shipping Company',
            key: 'shippingCompany'
        },{
            label: 'Tracking No.',
            key: 'trackingNo'
        }]

        const data = [{
            shippingId: "3c1ffb86-0e57-4857-8dcf-bd7808106241",
            firstName: "สมชาย",
            lastName: "เข็มกลัด",
            redemptionDate: "11-Apr-22 11:15",
            campaign: "test gift",
            rewardName: "test reward name",
            receiverFirstName: "สมชาย",
            receiverLastName: "เข็มกลัด",
            phoneNumber: " 0933333333",
            address: "99/99",
            subDistrict: "ศาลากลาง",
            district: "บางกรวย",
            province: "นนทบุรี",
            zipcode: "11130",
            status: "WAITING",
            shippedDate: "01-Apr-22 00:00",
            shippingCompany: "DHL",
            trackingNo: "619169179274"
        }]

        this.setState({ csvHeaders: headers, csvData: data, csvFileName: fileName }, () => {
            this.csvLink.current.link.click()
        })
    }


    downloadTemplate() {
        const { FORM_IMPORT_FILE } = this.props
        // let templateType = lodash.get(FORM_IMPORT_FILE, 'values.importType')

        let fileName = `Template_Shipment.csv`
        this.genTemplateShipment(fileName)
    }

    closeForm() {
        const { dispatch } = this.props
        dispatch({ type: "CLOSE_SHIPMENT_FORM_IMPORT" })
    }

    render() {
        const { handleSubmit, formImportOpen, classes, FORM_IMPORT_FILE } = this.props
        let templateType = lodash.get(FORM_IMPORT_FILE, 'values.importType')

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formImportOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{ position: "relative" }}
                >
                    <h4 className={classes.modalTitle}><b>Import File</b></h4>
                    <IconButton aria-label="close" id="btn_close_form_import" className={classes.closeButton} onClick={this.closeForm.bind(this)} style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px"
                    }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                        <GridContainer>
                            {formImportOpen &&
                            <GridItem xs={12} sm={6} md={6}>
                                <div style={{ marginTop: '5px' }}>
                                    <Field
                                        name="file"
                                        component={InputUpload}
                                        type="file"
                                        label="File Import"
                                        accept={acceptFileType(templateType)}
                                        // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        id="btn_select_import_file"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </div>
                                <div style={{ fontSize: '12px', marginTop: "10px" }}>Maximum 5,000 rows</div>
                            </GridItem>
                            }
                            <GridItem xs={3} sm={3} md={3}>
                                <Button
                                    type="button"
                                    color="success"
                                    style={{ display: "block", padding: "4px 30px" }}
                                    onClick={this.downloadTemplate.bind(this)}>
                                    <div>Download</div>
                                    <div>Template</div>
                                </Button>
                                <CSVLink
                                    headers={this.state.csvHeaders}
                                    data={this.state.csvData}
                                    filename={this.state.csvFileName}
                                    style={{ display: "none" }}
                                    ref={this.csvLink}
                                    id="btn_download_template"
                                    target="_blank"
                                />
                            </GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                                <Button type="submit" color="primary" id="btn_submit_import">
                                    Submit
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </DialogContent >
            </Dialog >
        )
    }
}


function mapStateToProps(state) {
    const { formImportOpen } = state.shipment
    const { FORM_IMPORT_FILE } = state.form
    return {
        formImportOpen, FORM_IMPORT_FILE
    };
}

const connectedComponent = connect(mapStateToProps)(FormImportFile);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {

    }
})(withStyles({})(connectedComponent))
import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';

export const roleServices = {
   addNewRole,
   editRole,
   deleteRole,
   getFeatures,
   getRoleByID,
   getListsRole
};

function addNewRole(params) {
   var tmp = axios.post(configConstants.API_BACKEND_CRM + '/roles', params, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

function editRole(params) {
   var tmp = axios.put(configConstants.API_BACKEND_CRM + `/roles/${params._id}`, params, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

function deleteRole(params) {
   var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/roles/${params._id}`, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

function getFeatures(urlParams) {
   if (urlParams !== undefined) {
      urlParams = "?" + urlParams
   } else {
      urlParams = ""
   }

   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/features${urlParams}`, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

function getRoleByID(id) {
   let tmp = axios.get(configConstants.API_BACKEND_CRM + `/roles/find/${id}`, {
      headers: setHeaders({ 'Charset': 'utf-8' })
   })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return {
               status: true,
               data: response.data.data,
               message: response.data.message
            };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return {
               status: false,
               data: {},
               message: response.data.message
            };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getListsRole(urlParams) {
   if (urlParams !== undefined) {
      urlParams = "?" + urlParams
   } else {
      urlParams = ""
   }

   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/roles${urlParams}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      }).catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}
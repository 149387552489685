import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash';

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import FastForwardIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import { masterAdminActions } from 'actions';
import { masterAdminConstants } from '../../constants';
import i18n from 'i18n';

const FORM_NAME = "FORM_ADMIN_RESET_PASSWORD";

const form_validate = {
   "password": {
      rules: {
         required: true,
         minLength: 8,
         passwordStrength: true
      },
      messages: {
         required: "warning_messages.password_is_required",
         minLength: 'รหัสผ่านต้องมีมากกว่า 8 ตัวขึ้นไป',
         passwordStrength: 'รหัสผ่านต้องประกอบด้วยตัวพิมพ์ใหญ่ (A-Z), ตัวพิมพ์เล็ก (a-z), ตัวเลข (0-9) และตัวอักขระพิเศษ (!,@,#,$,%,^,&,*)'
      },
      alert: "warning_messages.password_is_required",
   },
   "passwordConfirmation": {
      rules: {
         required: true,
         equalTo: 'password'
      },
      messages: {
         required: "warning_messages.password_confirmation_is_required",
         equalTo: 'กรุณากรอกรหัสผ่านให้ตรงกัน'
      },
      alert: "warning_messages.password_confirmation_is_required",
   }
}

const validate = (values) => {
   const errors = crmValidator.validateByRulesList(form_validate, values);
   return errors
}

class FormEditPassword extends React.Component {
   constructor(props) {
      super(props);

   }

   handleSubmit = (values) => {
      const { dispatch } = this.props;

      let reqParamsEdit = {
         "_id": values._id,
         "password": values.password,
         "passwordConfirmation": values.passwordConfirmation,
      }
      dispatch(masterAdminActions.editPassword(reqParamsEdit));
   }

   render() {
      const { handleSubmit, classes, formEditPasswordOpen, formMode, dispatch, FORM_ADMIN } = this.props;
      let { readOnly } = this.props

      return (
         <Dialog
            classes={{
               root: classes.center,
               paper: classes.modal
            }}
            open={formEditPasswordOpen}
            keepMounted
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            fullWidth={true}
            maxWidth="sm"
            scroll="body"
         >
            <DialogTitle
               id="edit-password-title"
               disableTypography
               className={classes.modalHeader}>
               <h4 className={classes.modalTitle}><b>Reset password</b></h4>
            </DialogTitle>

            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
               <DialogContent
                  id="edit-password-form-dialog"
                  className={classes.modalBody}
               >
                  <GridContainer>
                     <GridItem xs={12} sm={6} md={6}>
                        <Field
                           id="password-edit-input"
                           name="password"
                           component={CustomInput}
                           type="password"
                           labelText={i18n.t("label.password")}
                           formControlProps={{
                              fullWidth: true
                           }}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={6} md={6}>
                        <Field
                           id="password-edit-confirmation-input"
                           name="passwordConfirmation"
                           component={CustomInput}
                           type="password"
                           labelText={i18n.t("label.confirm_password")}
                           formControlProps={{
                              fullWidth: true
                           }}
                        />
                     </GridItem>
                  </GridContainer>
                  <DialogActions className={classes.modalFooter}>
                     <Button
                        id="submit-edit-btn"
                        style={{ marginRight: "10px" }}
                        color="primary"
                        type="submit">
                        {i18n.t('submit')}
                     </Button>
                     <Button
                        id="cancel-edit-btn"
                        name="closeForm"
                        onClick={() => {
                           dispatch({ type: masterAdminConstants.CLOSE_FORM_ADMIN })
                        }}
                        color="white">
                        {i18n.t('cancel')}
                     </Button>
                  </DialogActions>
               </DialogContent>
            </form>
         </Dialog>
      )
   }
}

function mapStateToProps(state) {
   const { formEditPasswordOpen, formMode, reload, merchantAllList, merchantAccessList } = state.masterAdmin;
   const { FORM_ADMIN } = state.form;
   return {
      formEditPasswordOpen, formMode, reload, FORM_ADMIN, merchantAllList, merchantAccessList
   };
}

const connectFormEditPassword = connect(mapStateToProps)(FormEditPassword);

export default reduxForm({
   form: FORM_NAME,
   validate: validate,
   enableReinitialize: true,
   onSubmitFail: (errors) => {
      crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
   }
})(withStyles(modalStyle)(connectFormEditPassword))
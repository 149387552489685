import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import objectToFormData from 'object-to-formdata';

export const businessWebsiteServices = {
    getBusinessInfomation,
    updateBusinessInfomation,
    getPrivacyAndTc
};

function getBusinessInfomation() {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/business-website`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function updateBusinessInfomation(values) {
    const formData = objectToFormData(values);
    var tmp = axios.post(configConstants.API_BACKEND_CRM + '/business-website/', formData, { headers: setHeaders({'Content-Type': 'application/x-www-form-urlencoded'}) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.data ? response.data.data :  response.data.message };
            }
        });
    return tmp;
}



function getPrivacyAndTc(values) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/weomni/cms/${values}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.data ? response.data.data :  response.data.message };
            }
        });
    return tmp;
}
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, touch } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash';
import moment from 'moment';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import RichEditor from "components/RichEditor/RichEditor.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import { store, alert as customAlert } from 'helpers';

import { bannerConstants } from '../../constants';
import { bannerActions, memberActions } from '../../actions';

const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ"
const validateRequiredImage = imageFile => {
  if (imageFile === null || imageFile === undefined || imageFile === "") {
    return "Banner Picture is required"
  }
}
const NotValidateRequiredImage = imageFile => {
  return "";
}
const validMimeType = "image/jpeg, image/png";
const validateImageFormat = imageFile => {
  if (imageFile) {
    if (!validMimeType.includes(imageFile.type)) {
      return `Allow file type .jpeg or .png only `;
    }
  }
}
const validateImageSize = imageFile => {
  if (imageFile) {
    if (imageFile.size > (1048576 * 2)) {
      return `Banner picture file size must be less than 2MB`
    }
  }
}

const FORM_NAME = "FORM_BANNER";
const form_validate = {
  "name": {
    rules: {
      required: true
    },
    messages: {
      required: 'required'
    },
    alert: "Banner name is required"
  },
  "title": {
    rules: {
      required: true
    },
    messages: {
      required: 'required'
    },
    alert: "Banner title is required"
  },
  "priority": {
    rules: {
      number: true,
      min: 1
    },
    messages: {
      number: 'Press Enter Number only',
      min: 'Priority should be greater than 0'
    },
  },
  "status": {
    rules: {
      required: true
    },
    messages: {
      required: 'required'
    },
    alert: "Banner status is required"
  },
  "type": {
    rules: {
      required: true
    },
    messages: {
      required: 'required'
    },
    alert: "Banner type is required"
  },
  "url": {
    rules: {
      isURL: true
    },
    messages: {
      isURL: 'Invalid URL'
    },
    alert: "Invalid URL"
  },
  "detail": {
    rules: {
      required: true
    },
    messages: {
      required: 'required'
    },
    alert: "Banner Description is required"
  },
  "startDate": {
    rules: {
      required: true
    },
    messages: {
      required: 'required'
    },
    alert: "Start Date is required"
  },
  "linkOutURL": {
    rules: {
      requiredLinkout: true,
      isURL: true
    },
    messages: {
      requiredLinkout: 'required',
      isURL: 'Invalid URL'
    },
    alert: "URL link is required"
  },
  "linkOutBtnName": {
    rules: {
      requiredLinkout: true,
      noSpecialCharacters: true
    },
    messages: {
      requiredLinkout: 'required',
      noSpecialCharacters: "Special character is not allowed"
    },
    alert: "Custom Caption is required"
  }
};

const validate = (values) => {
  const errors = crmValidator.validateByRulesList(form_validate, values);
  return errors
}

class BannerForm extends React.Component {

  validateRequiredLinkout = (value, allValues) => {
    if (allValues.showLinkOut === true) {
      if (value === "" || value === undefined || value === null) {
        return "required"
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { formOpen, dispatch } = this.props;
    if (formOpen === true && (prevProps.formOpen === false)) {
      dispatch(memberActions.getActiveTagList())
      dispatch(memberActions.getActiveMemberLevelList())
    }
  }

 
  handleSubmit = (values) => {
    const { dispatch } = this.props;
    let data = lodash.clone(values)  
    if (data.startDate) {
      data.startDate = moment(data.startDate).format(dbDateFormat)
   }

   if (data.endDate) {
      data.endDate = moment(data.endDate).format(dbDateFormat)
   }
    if(data.bannerImage){
      data.file = data.bannerImage
      delete data.bannerImage
    }

    if (this.props.formMode === "add") {
      dispatch(bannerActions.addBanner(data));
    } else {
      dispatch(bannerActions.updateBanner(data));
    }
  }


  onStartDateFocus = () => {
    const { FORM_BANNER, dispatch } = this.props;
    if (!lodash.get(FORM_BANNER, 'values.startDate')) {
      dispatch(change('FORM_BANNER', 'startDate', moment({ hour: 0, minute: 0, seconds: 0 })))
    }
  }

  onEndDateFocus = () => {
    const { FORM_BANNER, dispatch } = this.props;
    if (!lodash.get(FORM_BANNER, 'values.endDate')) {
      dispatch(change('FORM_BANNER', 'endDate', moment({ hour: 23, minute: 59, seconds: 59 })));
    }
  }

  renderFormTypeCond(readOnly) {
    const { FORM_BANNER } = this.props;
    if (lodash.has(FORM_BANNER, 'values.type')) {
      const bannerType = FORM_BANNER.values.type;
      if (bannerType === "image") {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <Field
              name="url"
              component={CustomInput}
              type="text"
              labelText="URL Link"
              formControlProps={{
                fullWidth: true
              }}
              disabled={readOnly}
            />
          </GridItem>
        )
      } else if (bannerType === "info") {
        const showLinkOut = lodash.get(FORM_BANNER, 'values.showLinkOut')
        return (
          <>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="detail"
                component={RichEditor}
                type="text"
                labelText="Description"
                formControlProps={{
                  fullWidth: true
                }}
                disabled={readOnly}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
              <Field
                name="showLinkOut"
                labelText="Show / Hide URL Link Button"
                component={CustomCheckbox}
                type="checkbox"
                formControlProps={{
                  fullWidth: true
                }}
                disabled={readOnly}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6} style={{ marginTop: "-15px" }}>
              <Field
                name="linkOutBtnName"
                component={CustomInput}
                type="text"
                labelText="Custom Caption"
                formControlProps={{
                  fullWidth: true
                }}
                disabled={!showLinkOut}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6} style={{ marginTop: "-15px" }}>
              <Field
                name="linkOutURL"
                component={CustomInput}
                type="text"
                labelText="URL Link"
                formControlProps={{
                  fullWidth: true
                }}
                disabled={!showLinkOut}
              />
            </GridItem>
          </>
        )
      }
    }
    return (<></>);
  }

  render() {
    const { formOpen, formMode, classes, dispatch, handleSubmit, bannerImagePreview, formTaglist, formMemberLevellist, FORM_BANNER, categoryList } = this.props;
    let { readOnly } = this.props
    if (formMode === `add`) {
      readOnly = false
    } else if (formMode === `edit` && !readOnly) {
      readOnly = false
    } else {
      readOnly = true
    }

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={formOpen}
        keepMounted
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        fullWidth={true}
        maxWidth="md"
        disableRestoreFocus={true}
        scroll="body">

        <DialogTitle
          disableTypography
          className={classes.modalHeader}>
          <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Banner' : (readOnly ? 'Banner Info' : 'Edit Banner')}</b></h4>
        </DialogTitle>
        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
          <DialogContent
            id="banner-form-dialog"
            className={classes.modalBody}
          >
            {formMode === 'edit' ?
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <Field
                    name="displayId"
                    component={CustomInput}
                    type="text"
                    labelText="Banner ID"
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled
                  />
                </GridItem>
              </GridContainer>
              : null}
            <GridContainer>
              <Field
                name="_id"
                component="input"
                type="hidden"
              />
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  name="name"
                  component={CustomInput}
                  type="text"
                  labelText="Banner Name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  name="title"
                  component={CustomInput}
                  type="text"
                  labelText="Banner Title"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled={readOnly}
                />

              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  name="startDate"
                  component={CustomDatePicker}
                  type="text"
                  labelText="Start Date"
                  dateFormat="DD-MMM-YY"
                  timeFormat="HH:mm"
                  formControlProps={{
                    fullWidth: true
                  }}
                  maxDate={FORM_BANNER.values.endDate}
                  onPickerFocus={this.onStartDateFocus.bind(this)}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  name="endDate"
                  component={CustomDatePicker}
                  type="text"
                  dateFormat="DD-MMM-YY"
                  timeFormat="HH:mm"
                  labelText="End Date"
                  formControlProps={{
                    fullWidth: true
                  }}
                  minDate={FORM_BANNER.values.startDate}
                  onPickerFocus={this.onEndDateFocus.bind(this)}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  name="status"
                  component={CustomSelect}
                  type="text"
                  labelText="Status"
                  formControlProps={{
                    fullWidth: true
                  }}
                  optionsList={[
                    {
                      value: "Active",
                      label: "Active"
                    },
                    {
                      value: "Inactive",
                      label: "Inactive"
                    }
                  ]}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  name="priority"
                  component={CustomInput}
                  type="text"
                  labelText="Priority"
                  hintText="1 ~ 9999"
                  formControlProps={{
                    fullWidth: true
                  }}
                  normalize={(value) => {
                    return crmValidator.handleKeyPress(value, form_validate.priority)
                  }}
                  maxLength={4}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  name="tagList"
                  component={TagsMultiSelect}
                  labelText="Tag"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  showtick
                  optionsList={formTaglist}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Field
                  name="memberLevel"
                  component={TagsMultiSelect}
                  labelText="Member Level"
                  formControlProps={{
                    fullWidth: true
                  }}
                  showtick
                  optionsList={formMemberLevellist}
                  disabled={readOnly}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
              <Field
                id={`categoryId`}
                name='categoryId'
                component={TagsMultiSelect}
                labelText="Category"
                formControlProps={{
                  fullWidth: true
                }}
                showtick
                optionsList={categoryList}
                disabled={readOnly}
              />
            </GridItem>
            </GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Field
                name="bannerImage"
                component={ImageUpload}
                type="text"
                labelText="Banner Pic"
                textCenter={true}
                width={(1140 / 2.5)}
                height={(400 / 2.5)}
                showRatioGuide={true}
                recommendWidth={1140}
                recommendHeight={400}
                onChange={(e) => {
                  e.preventDefault();
                  let reader = new FileReader();
                  let file = e.target.files[0];
                  reader.onloadend = () => {
                    dispatch({ type: bannerConstants.SET_FORM_PREVIEW_IMAGE, bannerImagePreview: reader.result })
                  };
                  try {
                    if (!validMimeType.includes(file.type)) {
                      dispatch(touch(FORM_NAME, 'bannerImage'));
                    }
                    reader.readAsDataURL(file);
                    dispatch(change(FORM_NAME, 'bannerImage', file))
                  } catch (error) {
                    dispatch({ type: bannerConstants.SET_FORM_PREVIEW_IMAGE, bannerImagePreview: "default" })
                    dispatch(change(FORM_NAME, 'bannerImage', ''))
                  }
                }}
                imagePreviewUrl={bannerImagePreview}
                formControlProps={{
                  fullWidth: true
                }}
                validate={[
                  validateImageFormat,
                  (formMode === "add" ? validateRequiredImage : NotValidateRequiredImage),
                  validateImageSize
                ]}
                disabled={readOnly}
              />
            </GridItem>
            <GridContainer>
              <GridItem xs={6} sm={4} md={4}>
                <Field
                  name="type"
                  component={CustomSelect}
                  type="text"
                  labelText="Type"
                  formControlProps={{
                    fullWidth: true
                  }}
                  onChange={(e) => {

                  }}
                  disabled={readOnly}
                  optionsList={[
                    {
                      label: 'Image',
                      value: 'image'
                    },
                    {
                      label: 'Info',
                      value: 'info'
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              {this.renderFormTypeCond(readOnly)}
            </GridContainer>
          </DialogContent>

          <DialogActions
            className={classes.modalFooter}>
            {!readOnly ?
              <Button
                type="submit"
                style={{ marginRight: "10px" }}
                color="primary">
                Submit
               </Button>
              : ''
            }
            <Button
              type="button"
              onClick={() => {
                dispatch({ type: bannerConstants.CLOSE_FORM_BANNER });
              }}
              color="white">
              Cancel
               </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

function mapStateToProps(state) {
  const { formOpen, formMode, bannerImagePreview } = state.banner;
  const { FORM_BANNER } = state.form;
  const { categoryList } = state.list
  const { formTaglist, formMemberLevellist } = state.member;
  return {
    formOpen, formMode, bannerImagePreview, FORM_BANNER, formTaglist, formMemberLevellist,categoryList
  };
}

const connectBannerForm = connect(mapStateToProps)(BannerForm);

export default reduxForm({
  form: FORM_NAME,
  validate: validate,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, props) => {
    crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);

    let globalStore = store.getState();
    let formMeta = globalStore.form[FORM_NAME];
    if (Object.keys(errors).length === 1) {
      if (errors.bannerImage && !formMeta.values.bannerImage) {
        customAlert(dispatch, 'error', 'กรุณาใส่ภาพแบนด์เนอร์')
      }
    }

  }
})(withStyles(modalStyle)(connectBannerForm))

export const validMimeType = "image/jpeg, image/png";
export const form_validate = {
    phoneNumber: {
      rules: {
        number: true
      }
    }
};
export const validateImageFormat = imageFile => {
    if (imageFile && !validMimeType.includes(imageFile.type)) {
        return "warning_messages.only_img_file_type_is_jpg_or_png";
    }
}
export const validateImageSizeLogo = imageFile => {
    if (imageFile) {
        if (imageFile.size > (1048576 * 2)) {
            return "warning_messages.logo_picture_file_size_must_be_less_than_2mb";
        }
    }
}

export const validateImageSizeCover = imageFile => {
    if (imageFile) {
        if (imageFile.size > (1048576 * 2)) {
            return "warning_messages.cover_picture_file_size_must_be_less_than_2mb";
        }
    }
}

export const validateRequired = (value, allValues, props, name) => {
    let msg = "";
    if (name === "businessName") {
       msg = "warning_messages.business_name_is_required"
    } else if (name === "phoneNumber") {
       msg = "warning_messages.phone_number_is_required"
    }
 
    if (value === null || value === undefined || value === "") {
       return msg
    }
 }

 export const validateLengthPhoneNumber = value => {
    if (value.length > 10) {
        return "warning_messages.phone_number_is_invalid";
    }
}

export const validateURL = value =>{
    const regulars = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    console.log(' regulars.test(value)',   regulars.test(value))
    if(!regulars.test(value) && value){
        return "warning_messages.url_is_wrong_format";
    }
}
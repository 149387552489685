import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, touch, getFormValues } from "redux-form";
import lodash from "lodash";
import qs from "qs";
import moment from "moment";
import ReactAsyncSelect from "react-select/async";
import { components, createFilter } from "react-select";
import debounce from "debounce-promise";
import red from "@material-ui/core/colors/red";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import RichEditor from "components/RichEditor/RichEditor.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FormLabel from "@material-ui/core/FormLabel";
// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

// Condition
import FormPoint from "./FormCondition/FormPoint.jsx";

// Reward
import FormGift from "./FormReward/FormGift.jsx";
import FormRewardPoint from "./FormReward/FormPoint.jsx";
import Form3rdPartyCode from "./FormReward/Form3rdPartyCode.jsx";
import FormTrueMoney from "./FormReward/FormTrueMoney.jsx";
import FormDiscount from "./FormReward/FormDiscount.jsx";
import FormQRCoupon from "./FormReward/FormQRCoupon";
import FormLuckyDraw from "./FormReward/FormLuckyDraw";
import FormPhotoGiveaway from "./FormReward/FormPhotoGiveaway";

import { crmValidator, plugins, normalize } from "helpers";
import { campaign_v2Constants } from "../../constants";
import { listServices } from "../../services";
import { campaignActions, memberActions } from "actions";
import { photoGiveawayRulesActions } from 'actions';

import i18n from "i18n";

import * as FormValidator from "./validate/FormValidator";

const FORM_NAME = "FORM_CAMPAIGN_V2";
const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ";

const style = {
	customCardContentClass: {
		paddingLeft: "0",
		paddingRight: "0",
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px",
	},
	cardFooter: {
		padding: "15px",
		textAlign: "right",
		paddingTop: "0",
		margin: "0",
	},
	labelHorizontalCenter: {
		float: "right",
		color: "rgba(0, 0, 0, 0.26)",
		cursor: "pointer",
		display: "inline-flex",
		fontSize: "14px",
		lineHeight: "1.428571429",
		fontWeight: "400",
		paddingTop: "39px",
		marginRight: "0",
	},
	checkboxAndRadio: {
		position: "relative",
		display: "block",
		marginTop: "10px",
		marginBottom: "10px",
	},
	checkboxAndRadioHorizontal: {
		position: "relative",
		display: "block",
		"&:first-child": {
			marginTop: "10px",
		},
		"&:not(:first-child)": {
			marginTop: "-14px",
		},
		marginTop: "0",
		marginBottom: "0",
	},
	paddingLeft32: {
		paddingLeft: "32px",
	},
	borderDashed: {
		border: "dashed 1px rgb(119 116 116)",
		padding: "20px 20px",
		width: "100%",
		margin: "15px 15px",
	},
};

const customReactSelectStyles = {
	control: (provided, state) => ({
		...provided,
		border: "none",
		borderRadius: "none",
		borderBottom: "solid 1px #D2D2D2",
		boxShadow: "none",
		"&:hover": {
			borderColor: "#f4b340",
		},
	}),
	container: (provided, state) => ({
		...provided,
		paddingTop: "22px",
	}),
};

const listCampaignType = [
	{ value: "point", label: "Point" },
	{ value: "every_x_point", label: "Every X Point" },
];

const listRewardType = [
   { value: "3rdPartyCode", label: "3rd party Code" },
   { value: "gift", label: "Gift" },
   { value: "point", label: "Add Point" },
   { value: "trueMoney", label: "True Money" },
   { value: "discount", label: "Discount" },
   { value: "couponCode", label: "Coupon Code" },
   { value: "luckyDraw", label: "Lucky Draw" },
   { value: "photoGiveaway", label: "Photo Giveaway" }
]

const rewardExpireTypeList = [
	{ value: "day", label: "Day" },
	{ value: "hour", label: "Hour" },
	{ value: "minute", label: "Minute" },
	{ value: "second", label: "Second" },
];

const validate = (values) => {
	const errors = crmValidator.validateByRulesList(
		{
			...FormValidator.form_validate,
			...FormValidator.validate_[values.type],
			...FormValidator.reward_validate,
			...FormValidator.detail_validate,
		},
		values
	);
	return errors;
};

const onInsertSku = (keyword) => {
   let regex = new RegExp(/[ !"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]/g);
   const chars = keyword.split('');
   const char = chars.pop();
      if (regex.test(char)) {
        keyword = chars.join(''); 
      }
   return keyword;
}

const MenuList = props => {
   return (
       <React.Fragment>
           <GridContainer style={{
               borderTopLeftRadius: "6px",
               borderTopRightRadius: "6px",
               borderBottom: "1px solid #aaa",
               backgroundColor: "rgb(255, 255, 255)",
               width: "100%",
               margin: "0px",
               padding: "8px 0px"
           }}>
               <GridItem xs={4}>SKU ID</GridItem>
               <GridItem xs={8}>Product Name</GridItem>
           </GridContainer>
           <components.MenuList {...props}>
               {props.children}
           </components.MenuList>
       </React.Fragment>
   );
};

const renderFieldError = (field) => (
	<div className="input-row">
		{field.meta.touched && field.meta.error && (
			<span className="error" style={{ color: red[500], fontSize: "0.75rem" }}>
				{i18n.t(field.meta.error)}
			</span>
		)}
	</div>
);
class CampaignForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			conditionType: "point",
			point: 0,
		};
		const loadDelays = 800;
		const loadProduct = (inputValue) =>
			this.loadProductOptionsPromise(inputValue);
		this.loadProductOptionsDelay = debounce(loadProduct, loadDelays, {
			leading: true,
		});
	}

	componentDidUpdate(prevProps) {
		const { formOpen, formMode, dispatch, FORM_CAMPAIGN_V2 } = this.props;

		if (formOpen === true && prevProps.formOpen === false) {
			dispatch(memberActions.getActiveTagList());
			dispatch(memberActions.getActiveMemberLevelList());
			dispatch(campaignActions.getCategoryList());
			if (formMode === "add") {
				dispatch(
					campaignActions.getTrdPartyRewardCodeSetList({
						codeSetId: FORM_CAMPAIGN_V2.values.trdPartyCodeSetId,
					})
				);
				dispatch(
					campaignActions.getTrueMoneyRewardSetList({
						codeSetId: FORM_CAMPAIGN_V2.values.trueMoneyRewardSet,
					})
				);
				dispatch(
					campaignActions.getLuckyDrawRewardSetList()
				);
			}if (formMode === "edit") {
				dispatch(campaignActions.getLuckyDrawRewardSetList());
			}
		}
	}

	handleSubmit = (values) => {
		const { dispatch } = this.props;
		let channel = [];
		let data = FormValidator.formatData(JSON.stringify(values));
		data.rewardDetail.totalPrice = parseFloat(
			lodash.get(data, "rewardDetail.totalPrice")
		).toFixed(2);
		data.rewardDetail.totalPrice =
			lodash.get(data, "rewardDetail.totalPrice") === "NaN" ||
				lodash.get(data, "rewardDetail.totalPrice") === null ||
				lodash.get(data, "rewardDetail.totalPrice") === undefined
				? ""
				: lodash.get(data, "rewardDetail.totalPrice");
		if (lodash.get(data, "rewardDetail.descriptionDiscount")) {
			data.rewardDetail.description = lodash.get(
				data,
				"rewardDetail.descriptionDiscount"
			);
			data.rewardDetail.descriptionDiscount = "";
		} else if (lodash.get(data, "rewardDetail.descriptionGift")) {
			data.rewardDetail.description = lodash.get(
				data,
				"rewardDetail.descriptionGift"
			);
			data.rewardDetail.descriptionGift = "";
		}
		if (
			lodash.isEqualWith(data.special, true) ||
			lodash.isEqualWith(data.special, "special")
		) {
			channel.push("special");
		}
		if (lodash.isEqualWith(data.normal, true)) {
			channel.push("normal");
		}
		if (lodash.isEqualWith(data.pos, true)) {
			channel.push("pos");
		}
		if (data.startDate) {
			data.startDate = moment(data.startDate).format(dbDateFormat);
		}

		if (data.endDate) {
			data.endDate = moment(data.endDate).format(dbDateFormat);
		}

		let redeemAbleStartDate = lodash.get(data, "redeemAble.startDate");
		let redeemAbleEndDate = lodash.get(data, "redeemAble.endDate");

		if (!lodash.isEmpty(redeemAbleStartDate)) {
			data.redeemAble.startDate = moment(redeemAbleStartDate).format(
				dbDateFormat
			);
		}

		if (!lodash.isEmpty(redeemAbleEndDate)) {
			data.redeemAble.endDate = moment(redeemAbleEndDate).format(dbDateFormat);
		}
		if (!lodash.isEmpty(channel)) {
			data.channel = channel;
		}
		for (const lang in data.languages) {
			if (!lodash.isEmpty(data.languages[lang].termAndCondition)) {
				data.languages[lang].termAndCondition = btoa(
					encodeURIComponent(data.languages[lang].termAndCondition)
				);
			}
		}
		if (lodash.get(data, "rewardDetail.termAndCondition")) {
			data.rewardDetail.termAndCondition = btoa(encodeURIComponent(data.rewardDetail.termAndCondition));
		}
		// if(!lodash.isEmpty(values.maximumPointPerTime)){

		// }
		// data.languages.en.termAndCondition = btoa(encodeURIComponent(data.languages.en.termAndCondition))
		// data.pointCondition.maximumPointPerTime = values.maximumPointPerTime

		data.campaignImage = values.campaignImage;
		data.rewardPicture = values.rewardPicture;

		// delete data.campaignImage;
		//quotaTypeCampaign

		if (lodash.get(data, "quotaTypeCampaign") === "day") {
			lodash.unset(data, "quota.campaignPerMonth");
		} else if (lodash.get(data, "quotaTypeCampaign") === "month") {
			lodash.unset(data, "quota.campaignPerDay");
			lodash.unset(data, "quota.campaignPerDayofWeek");
		}

		//quotaTypeUser
		if (lodash.get(data, "quotaTypeUser") === "day") {
			lodash.unset(data, "quota.userPerMonth");
		} else if (lodash.get(data, "quotaTypeUser") === "month") {
			lodash.unset(data, "quota.userPerDay");
			lodash.unset(data, "quota.userPerDayofWeek");
		}

		delete data.rewardFilePath;
		delete data.displayId;
		delete data.campaignId;
		delete data.filePath;

		let categoryId = lodash.get(data, "categoryId", []);
		if (categoryId.length === 0) {
			delete data.categoryId;
		}

		if (this.props.formMode === "add") {
			dispatch(campaignActions.addCampaignNew(data));
		} else {
			dispatch(campaignActions.updateCampaignNew(data));
		}
	};

	handleClose = () => {
		const { dispatch } = this.props;
		dispatch({ type: campaign_v2Constants.CLOSE_FORM_CAMPAIGN });
	};
	setType = (val) => {
		this.setState({
			conditionType: val.target.value,
			point: 0,
		});
	};
	pointXEveryValidate = (value) => {
		const { type } = this.props.FORM_CAMPAIGN_V2.values;
		let errMess = "";
		if (type === "every_x_point") {
			if (value === "0") {
				errMess = "Please input point at least 1";
			}
		}
		return errMess;
	};

	maximumPointPerTimeValidate = (value) => {
		const { pointBurn } = this.props.FORM_CAMPAIGN_V2.values;
		let errMess = "";
		if (parseInt(value) < parseInt(pointBurn)) {
			errMess = "Please input value more than or equal with Point";
		}
		return errMess;
	};
	rewardNumberValidate = (value) => {
		let errMess = "";
		if (value === "0") {
			errMess = "Please input number at least 1";
		}

		return errMess;
	};

	renderCustomOption = (props) => {
		const { classes } = this.props;
		delete props.innerProps.onMouseMove;
		delete props.innerProps.onMouseOver;

		return (
			<components.Option {...props} className={classes.optionHover}>
				<GridContainer>
					<GridItem xs={4}>{lodash.get(props, "data.value")}</GridItem>
					<GridItem xs={8}>{lodash.get(props, "data.label")}</GridItem>
				</GridContainer>
			</components.Option>
		);
	};

	loadProductOptionsPromise(inputValue) {
		return new Promise(async (resolve, reject) => {
			let { formValues } = this.props;
			let formValuesConditions = lodash.get(formValues, "conditions");
			let selectedSKU = [];

			lodash.forEach(formValuesConditions, function (value) {
				let orCondition = lodash.get(value, "in");
				lodash.forEach(orCondition, function (cond) {
					let selected = lodash.get(cond, "id");
					selectedSKU.push(selected);
				});
			});

			let reqParams = {
				skip: 0,
				limit: 1000,
				sortBy: "name",
				sortDir: "asc",
				keyword: inputValue,
				keywordFilterList: ["name", "skuId"],
			};
			let productList = await listServices.getListsProduct(
				qs.stringify(reqParams)
			);
			let formattedProductList = [];
			if (lodash.has(productList, "data.products")) {
				productList.data.products.map((value) => {
					let productsku = lodash.get(value, "skuId");
					if (lodash.indexOf(selectedSKU, productsku) === -1 && productsku) {
						formattedProductList.push({
							value: productsku,
							label: lodash.get(value, "name"),
						});
					}
					return formattedProductList;
				});
			}
			resolve(formattedProductList);
		});
	}
	
	getRewardList = () => {
		let { package_features, formValues } = this.props
		let campaignType = lodash.get(formValues, 'type')
		if(campaignType === 'every_x_point') {
			return plugins.getRewardListByPackageFeature(package_features, [{ value: "discount", label: "Discount" },{ value: "luckyDraw", label: "Lucky Draw" },{ value: "photoGiveaway", label: "Photo Giveaway" }])
		} else {
			return plugins.getRewardListByPackageFeature(package_features)
		}
	}


    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props
        let reqParams = "";
        dispatch(photoGiveawayRulesActions.getPhotoGiveawayRuleLists(reqParams));
    }

    componentDidMount() {
        const { dispatch } = this.props;
        this.getData();
    }

	render() {
		const {
			formOpen,
			formMode,
			classes,
			dispatch,
			handleSubmit,
			campaignImagePreview,
			formTaglist,
			categoryList,
			formMemberLevellist,
			trdPartyCodeSet,
			FORM_CAMPAIGN_V2,
			merchantSetting,
			trueMoneyRewardSet,
			luckyDrawRewardSet,
			tableData
		} = this.props;

		let { readOnly } = this.props;
		const { type } = FORM_CAMPAIGN_V2.values;
		let listRewardType = this.getRewardList()

		if (formMode === `add`) {
			readOnly = false;
		} else if (formMode === `edit` && !readOnly) {
			readOnly = false;
		} else {
			readOnly = true;
		}

		let componentType = null;
		if (lodash.has(FORM_CAMPAIGN_V2, "values.type")) {
			if (type === "point" || type === "every_x_point") {
				componentType = (
					<FormPoint
						props={this.props}
						classes={classes}
						FORM_CAMPAIGN_V2={FORM_CAMPAIGN_V2}
						FORM_NAME={FORM_NAME}
					/>
				);
			}
		}

		let componentReward = null;
		let selTrdPartyValue = [];
		let setTruemoneyRewardValue = [];
		let setLuckyDrawValue = [];
		let setPhotoGiveawayValue = [];
		
		if (lodash.has(FORM_CAMPAIGN_V2, "values.rewardType")) {
			if (FORM_CAMPAIGN_V2.values.rewardType === "3rdPartyCode") {
				if (formMode === "add") {
					lodash.forEach(trdPartyCodeSet, function (value, key) {
						if (!value.useInCampaignId) {
							let sel_value = value._id;
							let sel_label = `${value.setName} (${value.useable}/${value.total
								})`;
							selTrdPartyValue.push({
								value: sel_value,
								label: sel_label,
							});
						}
					});
				} else if (formMode === "edit" && !lodash.isEmpty(trdPartyCodeSet)) {
					selTrdPartyValue = [
						{
							value: FORM_CAMPAIGN_V2.values.trdPartyCodeSetId,
							label: trdPartyCodeSet.setName,
						},
					];
				}

				componentReward = (
					<Form3rdPartyCode
						props={this.props}
						selTrdPartyValue={selTrdPartyValue}
						FORM_NAME={FORM_NAME}
					/>
				);
			} else if (FORM_CAMPAIGN_V2.values.rewardType === "gift") {
				componentReward = <FormGift props={this.props} FORM_NAME={FORM_NAME} />;
			} else if (FORM_CAMPAIGN_V2.values.rewardType === "point") {
				componentReward = (
					<FormRewardPoint props={this.props} FORM_NAME={FORM_NAME} />
				);
			} else if (FORM_CAMPAIGN_V2.values.rewardType === "trueMoney") {
				if (formMode === "add") {
					lodash.forEach(trueMoneyRewardSet, function (value, key) {
						if (!value.useInCampaignId) {
							let sel_value = value._id;
							let sel_label = `${value.name} (${value.available}/${value.budget
								})`;
							setTruemoneyRewardValue.push({
								value: sel_value,
								label: sel_label,
								budget: value.budget,
							});
						}
					});
				} else if (formMode === "edit") {
					const { tmnReward } = FORM_CAMPAIGN_V2.values;
					let sel_value = tmnReward._id;
					let sel_label = `${tmnReward.name} (${tmnReward.available}/${tmnReward.budget
						})`;
					setTruemoneyRewardValue = [
						{ value: sel_value, label: sel_label, budget: tmnReward.budget },
					];
				}
				componentReward = (
					<FormTrueMoney
						props={this.props}
						setTruemoneyRewardValue={setTruemoneyRewardValue}
						FORM_NAME={FORM_NAME}
					/>
				);
			} else if (FORM_CAMPAIGN_V2.values.rewardType === "discount") {
				componentReward = (
					<FormDiscount props={this.props} FORM_NAME={FORM_NAME} />
				);
			} else if (FORM_CAMPAIGN_V2.values.rewardType === "couponCode") {
				componentReward = (
					<FormQRCoupon props={this.props} FORM_NAME={FORM_NAME} />
				);
			} else if (FORM_CAMPAIGN_V2.values.rewardType === "luckyDraw") {
				if (formMode === "add") {
					lodash.forEach(luckyDrawRewardSet, function (value, key) {
						if (!value.useInCampaignId) {
							let sel_value = value._id;
							let sel_label = value.name_th;
								setLuckyDrawValue.push({
									value: sel_value,
									label: sel_label,
								});
						}
					});
				} else if (formMode === "edit") {
					const { luckyDrawRewardId, luckyDrawReward } = FORM_CAMPAIGN_V2.values;
					let rewardLuckyObj = lodash.find(luckyDrawRewardSet, {_id: luckyDrawRewardId})
					let sel_value = luckyDrawRewardId;
					let sel_label = lodash.get(rewardLuckyObj, 'name_th')? lodash.get(rewardLuckyObj, 'name_th'):lodash.get(rewardLuckyObj, 'name_en');
					setLuckyDrawValue = [
						{ value: sel_value, label: sel_label },
					];
				}
				componentReward = (
					<FormLuckyDraw
						props={this.props}
						setLuckyDrawValue={setLuckyDrawValue}
						FORM_NAME={FORM_NAME}
					/>
				);
			} else if (FORM_CAMPAIGN_V2.values.rewardType === "photoGiveaway") {
				if (formMode === "add") {
					lodash.forEach(tableData.photoGiveawayRule, function (value, key) {
						let sel_value = value._id;
						let sel_label = value.name;
						setPhotoGiveawayValue.push({
							value: sel_value,
							label: sel_label,
						});
					});
				} else if (formMode === "edit") {
					const photoGiveawayRuleId = FORM_CAMPAIGN_V2.values.photoGiveawayRuleId;

					let ruleObj = lodash.find(tableData.photoGiveawayRule, {_id: photoGiveawayRuleId})

					let sel_value = photoGiveawayRuleId;
					let sel_label = lodash.get(ruleObj, 'name');
					setPhotoGiveawayValue = [
						{ value: sel_value, label: sel_label },
					];
				}
				componentReward = (					<FormPhotoGiveaway
						props={this.props}
						setPhotoGiveawayValue={setPhotoGiveawayValue}
						FORM_NAME={FORM_NAME}
					/>
				);
			}
		}
		const displayCard = formOpen ? { padding: "15px" } : { display: "none" };

		return (
			<Card style={displayCard}>
				<CardHeader icon>
					<h4 className={classes.modalTitle}>
						<b>
							{formMode === "add"
								? i18n.t("campaign.add_campaign")
								: readOnly
									? i18n.t("campaign.campaign_info")
									: i18n.t("campaign.edit_campaign")}
						</b>
					</h4>
				</CardHeader>
				<CardBody>
					<form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
						{formMode === "edit" ? (
							<GridContainer>
								<GridItem xs={12} sm={6} md={4}>
									<Field
										name="displayId"
										component={CustomInput}
										type="text"
										labelText={i18n.t("label.campaign_id")}
										formControlProps={{
											fullWidth: true,
										}}
										disabled
									/>
								</GridItem>
							</GridContainer>
						) : null}
						<GridContainer>
							<GridItem xs={6} sm={6} md={6}>
								<Field
									name="name"
									component={CustomInput}
									type="text"
									labelText={i18n.t("label.campaign_name")}
									formControlProps={{
										fullWidth: true,
									}}
									disabled={readOnly}
								/>
							</GridItem>
							<GridItem xs={6} sm={6} md={6}>
								<Field
									name="categoryId"
									component={TagsMultiSelect}
									labelText={i18n.t("label.category")}
									formControlProps={{
										fullWidth: true,
									}}
									showtick
									optionsList={categoryList}
									disabled={readOnly}
								/>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<Field name="campaignId" component="input" type="hidden" />
							<div style={style.borderDashed}>
								<GridItem xs={12} sm={12} md={12}>
									<legend>
										<b>{i18n.t("campaign.visibility")}</b>
									</legend>
									<GridContainer>
										<GridItem xs={12} sm={12} md={6}>
											<GridContainer>
												<GridItem xs={12} sm={6} md={6}>
													<Field
														name="startDate"
														component={CustomDatePicker}
														type="text"
														maxDate={FORM_CAMPAIGN_V2.values.endDate}
														labelText={i18n.t("label.start_date")}
														dateFormat="DD-MMM-YY"
														timeFormat="HH:mm"
														formControlProps={{
															fullWidth: true,
														}}
														onChange={(val) => {
															if (
																lodash.get(
																	FORM_CAMPAIGN_V2.values,
																	"redeemAble[sameAsVisibleDate]"
																)
															) {
																dispatch(
																	change(
																		FORM_NAME,
																		"redeemAble[startDate]",
																		val
																	)
																);
															}
														}}
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={6}>
													<Field
														name="endDate"
														minDate={FORM_CAMPAIGN_V2.values.startDate}
														component={CustomDatePicker}
														type="text"
														labelText={i18n.t("label.end_date")}
														dateFormat="DD-MMM-YY"
														timeFormat="HH:mm"
														formControlProps={{
															fullWidth: true,
														}}
														onChange={(val) => {
															if (val === "") {
																dispatch(
																	change(FORM_NAME, "afterExpiredDate", "")
																);
															}
															if (
																lodash.get(
																	FORM_CAMPAIGN_V2.values,
																	"redeemAble[sameAsVisibleDate]"
																)
															) {
																dispatch(
																	change(FORM_NAME, "redeemAble[endDate]", val)
																);
															}
														}}
													/>
												</GridItem>
											</GridContainer>
											<GridContainer>
												<GridItem xs={12} sm={12} md={12}>
													<Field
														name="memberLevel"
														component={TagsMultiSelect}
														labelText={i18n.t("label.memberLevel")}
														formControlProps={{
															fullWidth: true,
														}}
														showtick
														optionsList={formMemberLevellist}
														disabled={readOnly}
													/>
												</GridItem>
												<GridItem xs={12} sm={12} md={12}>
													<div
														style={{
															padding: "14px 24px 10px",
															marginLeft: "30px",
														}}
													>
														<FormLabel className={classes.labelHorizontal}>
															<font color="#999" size="4">
																{" "}
															Channel :
														</font>
														</FormLabel>
													</div>
												</GridItem>

												<div
													style={{
														padding: "14px 24px 10px",
														marginLeft: "30px",
													}}
												>
													<Field
														style={{ paddingTop: "40px" }}
														name={"normal"}
														component={CustomCheckbox}
														disabled={readOnly}
														id="chkbox-ruleBase-totalEarn"
														labelText="Normal"
													/>
												</div>

												<div
													style={{
														padding: "13px 4px 10px",
														marginLeft: "30px",
													}}
												>
													<Field
														style={{ paddingTop: "40px" }}
														name={"special"}
														component={CustomCheckbox}
														disabled={readOnly}
														id="chkbox-ruleBase-totalEarn"
														labelText="Special"
													/>
												</div>

												<div
													style={{
														padding: "13px 4px 10px",
														marginLeft: "30px",
													}}
												>
													<Field
														style={{ paddingTop: "40px" }}
														name={"pos"}
														component={CustomCheckbox}
														disabled={readOnly}
														id="chkbox-ruleBase-totalEarn"
														labelText="Pos"
													/>
												</div>
											</GridContainer>
										</GridItem>

										<GridItem xs={12} sm={12} md={6}>
											<GridContainer>
												<GridItem xs={12} sm={12} md={6}>
													<Field
														name="status"
														component={CustomSelect}
														type="text"
														labelText={i18n.t("label.status")}
														formControlProps={{
															fullWidth: true,
														}}
														optionsList={[
															{
																value: "Active",
																label: "Active",
															},
															{
																value: "Inactive",
																label: "Inactive",
															},
														]}
														disabled={readOnly}
													/>
												</GridItem>
												<GridItem xs={12} sm={12} md={12}>
													<Field
														name="tagList"
														component={TagsMultiSelect}
														labelText={i18n.t("label.tag")}
														formControlProps={{
															fullWidth: true,
														}}
														showtick
														optionsList={formTaglist}
														disabled={readOnly}
													/>
												</GridItem>
												<GridItem xs={12} sm={12} md={4}>
													<div style={{ padding: "37px 0px 10px 0px" }}>
														<FormLabel className={classes.labelHorizontal}>
															<font color="#999" size="4">
																{" "}
																Visibility Birthday :
															</font>
														</FormLabel>
													</div>
												</GridItem>
												<GridItem xs={12} sm={12} md={8}>
													<div
														style={{ marginLeft: "-98px", marginTop: "65px" }}
														className={
															classes.checkboxAndRadio +
															" " +
															classes.checkboxAndRadioHorizontal
														}
													>
														<Field
															name={"visibility_birthday"}
															component={CustomCheckbox}
															disabled={readOnly}
															labelText={i18n.t("label.birthday")}
														/>
													</div>
													{FORM_CAMPAIGN_V2.values.visibility_birthday ? (
														<div
															className={
																classes.checkboxAndRadio +
																" " +
																classes.checkboxAndRadioHorizontal +
																" " +
																classes.paddingLeft32
															}
															style={{ marginTop: "-48px" }}
														>
															<Field
																name="birthdayDisplayType"
																component={CustomRadio}
																disabled={readOnly}
																radioLists={[
																	{
																		value: "all_month",
																		label: "Visible for whole campaign type",
																		disabled:
																			readOnly ||
																			!FORM_CAMPAIGN_V2.values
																				.visibility_birthday,
																	},
																	{
																		value: "only_birthmonth",
																		label: "Visible only Birth Month type",
																		disabled:
																			readOnly ||
																			!FORM_CAMPAIGN_V2.values
																				.visibility_birthday,
																	},
																	{
																		value: "only_birthday",
																		label: "Visible only Birthday type",
																		disabled:
																			readOnly ||
																			!FORM_CAMPAIGN_V2.values
																				.visibility_birthday,
																	},
																]}
																validate={FormValidator.validateRequired}
															/>
														</div>
													) : null}
												</GridItem>
											</GridContainer>
										</GridItem>
										<GridItem xs={12} sm={6} md={4}>
											<Field
												name="priority"
												component={CustomInput}
												type="text"
												labelText="Priority"
												hintText="1 ~ 9999"
												formControlProps={{
													fullWidth: true,
												}}
												normalize={(value) => {
													return crmValidator.handleKeyPress(
														value,
														FormValidator.form_validate["priority"]
													);
												}}
												maxLength={4}
												disabled={readOnly}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</div>
							<div style={style.borderDashed}>
								<GridItem xs={12} sm={12} md={12}>
									<legend>
										<b>{i18n.t("campaign.conditions")}</b>
									</legend>
									<GridContainer>
										<GridItem xs={12} sm={12} md={6}>
											<GridContainer>
												<GridItem xs={12} sm={6} md={6}>
													<Field
														name="type"
														component={CustomSelect}
														onChange={this.setType}
														type="text"
														labelText={i18n.t("label.condition_type")}
														formControlProps={{
															fullWidth: true,
														}}
														disabled={
															lodash.isEqualWith(formMode, "edit")
																? true
																: false
														}
														optionsList={listCampaignType}
													/>
												</GridItem>
												<GridItem xs={12} sm={6} md={3}>
													<Field
														name="pointBurn"
														component={CustomInput}
														type="number"
														labelText={i18n.t("label.point")}
														formControlProps={{
															fullWidth: true,
														}}
														validate={this.pointXEveryValidate}
														normalize={(value) => {
															return crmValidator.handleKeyPress(
																value,
																FormValidator.validate_[
																	lodash.get(FORM_CAMPAIGN_V2.values, "type")
																].pointBurn
															);
														}}
														disabled={readOnly}
													/>
												</GridItem>

												{lodash.get(FORM_CAMPAIGN_V2.values, "type") ===
													"every_x_point" && (
														<GridItem xs={12} sm={8} md={8}>
															<Field
																name="pointCondition[maximumPointPerTime]"
																component={CustomInput}
																type="number"
																validate={this.maximumPointPerTimeValidate}
																labelText={"Maximum point can be used per time"}
																formControlProps={{
																	fullWidth: true,
																}}
																normalize={(value) => {
																	return crmValidator.handleKeyPress(
																		value,
																		FormValidator.validate_["every_x_point"]
																			.pointBurn
																	);
																}}
																disabled={readOnly}
															/>
														</GridItem>
													)}
											</GridContainer>
										</GridItem>
									</GridContainer>
									{componentType}
								</GridItem>
								<GridContainer style={{ width: "100%", marginLeft: "1px" }}>
									<GridItem xs={12} sm={12} md={6}>
										<Field
											name="redeemAble[memberLevel]"
											component={TagsMultiSelect}
											labelText={i18n.t("label.memberLevel")}
											formControlProps={{
												fullWidth: true,
											}}
											showtick
											optionsList={formMemberLevellist}
											disabled={readOnly}
										/>
									</GridItem>

									<GridItem xs={12} sm={12} md={6}>
										<Field
											name="redeemAble[tagList]"
											component={TagsMultiSelect}
											labelText={i18n.t("label.tag")}
											formControlProps={{
												fullWidth: true,
											}}
											showtick
											optionsList={formTaglist}
											disabled={readOnly}
										/>
									</GridItem>
								</GridContainer>
							</div>
						</GridContainer>

						<GridContainer>
							<div style={style.borderDashed}>
								<GridItem xs={12} sm={12} md={12}>
									<legend>
										<b>{i18n.t("campaign.reward")}</b>
									</legend>
									<GridContainer>
										<GridItem xs={12} sm={6} md={3}>
											<Field
												name="rewardType"
												component={CustomSelect}
												type="text"
												labelText={i18n.t("label.reward_type")}
												formControlProps={{
													fullWidth: true,
												}}
												disabled={readOnly || formMode === "edit"}
												optionsList={listRewardType}
											/>
										</GridItem>
									</GridContainer>
									{componentReward}
									{(FORM_CAMPAIGN_V2.values.rewardType === "discount" ||
										lodash.get(FORM_CAMPAIGN_V2, "values.rewardType") ===
										"3rdPartyCode" ||
										(lodash.get(FORM_CAMPAIGN_V2, "values.rewardType") ===
											"gift" &&
											lodash.get(
												FORM_CAMPAIGN_V2,
												"values.giftReceiveCondition"
											) === "pickup")) && (
											<>
												<GridContainer>
													<GridItem xs={12} sm={6} md={3}>
														<Field
															name="rewardDetail[rewardExpireType]"
															component={CustomSelect}
															type="text"
															labelText={"Reward Expire"}
															formControlProps={{
																fullWidth: true,
															}}
															// disabled={readOnly || formMode === "edit"}
															optionsList={rewardExpireTypeList}
														/>
													</GridItem>
													<GridItem xs={12} sm={6} md={3}>
														<Field
															name="rewardDetail[rewardExpireNumber]"
															component={CustomInput}
															type="number"
															labelText="Reward Expire Number"
															formControlProps={{
																fullWidth: true,
															}}
															validate={this.rewardNumberValidate}
															normalize={(value) => {
																return crmValidator.handleKeyPress(
																	value,
																	FormValidator.validate_["point"].pointBurn
																);
															}}
															disabled={readOnly}
														/>
													</GridItem>
												</GridContainer>
											</>
										)}
									{ (FORM_CAMPAIGN_V2.values.giftReceiveCondition === 'shipping' || FORM_CAMPAIGN_V2.values.giftReceiveCondition === 'pickup') && FORM_CAMPAIGN_V2.values.rewardType === 'gift' ?
                           <>
                           <GridContainer>
                           <GridItem xs={12} sm={6} md={4}>
                              <ReactAsyncSelect
                                 styles={customReactSelectStyles}
                                 value={ lodash.get(FORM_CAMPAIGN_V2.values, 'rewardDetail[skuId]') ? { label: lodash.get(FORM_CAMPAIGN_V2.values, 'rewardDetail[skuId]'), value: lodash.get(FORM_CAMPAIGN_V2.values, 'rewardDetail[skuId]')} : ""}
                                 name="rewardDetail[skuId]"
                                 placeholder="SKU"
                                 loadOptions={inputValue => this.loadProductOptionsDelay(inputValue)}
                                 onChange={(value) => {
                                    if(value != null){
                                       const skuId = value.value ? value.value : ""
                                       dispatch(change(FORM_NAME, 'rewardDetail[skuId]', skuId))
                                    }else{
                                       dispatch(change(FORM_NAME, 'rewardDetail[skuId]', ""))
                                    }
                                 }}
                                 isDisabled={readOnly}
                                 isClearable={true}
                                 menuListType="sku"
                                 components={{ MenuList, Option: this.renderCustomOption.bind(this) }}
                                 noOptionsMessage={({ inputValue }) => {
                                    if (inputValue.length === 0) {
                                       return (<span>Please enter keyword(s) to search product.</span>)
                                    } else {
                                       return (<span>Not found</span>)
                                    }
                                 }}
                                 filterOption={createFilter({ignoreAccents: false})}
                              />
                              <Field
                                component={renderFieldError}
                                type="input"
                                name="rewardDetail[skuId]"
                                validate={FormValidator.validateSKU}
                              />
                           </GridItem>
                           <GridItem xs={12} sm={6} md={3}>
                              <Field
                                 name="rewardDetail[quantity]"
                                 component={CustomInput}
                                 type="text"
                                 labelText={i18n.t("label.quantity")}
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 disabled={readOnly}
                                 normalize={normalize.numberMoreThanZero}
                                 validate={FormValidator.validateQuantity}
                                 />
                           </GridItem>
                           <GridItem xs={12} sm={6} md={3}>
                              <Field
                                 name="rewardDetail[totalPrice]"
                                 component={CustomInput}
                                 type="text"
                                 labelText={i18n.t("label.totalPrice")}
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 disabled={readOnly}
                                 normalize={normalize.onlyFloatingTwo}
                                 validate={FormValidator.validateTotalPrice}
                              />
                           </GridItem> 
                           </GridContainer>
                           </>
                  : <></> }
								</GridItem>
							</div>
						</GridContainer>

						<GridContainer>
							<div style={style.borderDashed}>
								<GridItem xs={12} sm={12} md={12}>
									<legend>
										<b>{i18n.t("campaign.detail")}</b>
									</legend>
								</GridItem>

								<GridItem xs={12} sm={12} md={12}>
									<Field
										name="campaignImage"
										component={ImageUpload}
										type="text"
										labelText={i18n.t("label.campaign_picture")}
										textLeft={true}
										height={190}
										showRatioGuide={true}
										onChange={(e) => {
											e.preventDefault();
											let reader = new FileReader();
											let file = e.target.files[0];
											reader.onloadend = () => {
												dispatch({
													type:
														campaign_v2Constants.SET_FORM_PREVIEW_IMAGE_CAMPAIGN,
													campaignImagePreview: reader.result,
												});
											};
											try {
												if (!FormValidator.validMimeType.includes(file.type)) {
													dispatch(touch(FORM_NAME, "campaignImage"));
												}
												reader.readAsDataURL(file);
												dispatch(change(FORM_NAME, "campaignImage", file));
											} catch (error) {
												dispatch({
													type:
														campaign_v2Constants.SET_FORM_PREVIEW_IMAGE_CAMPAIGN,
													campaignImagePreview: "default",
												});
												dispatch(change(FORM_NAME, "campaignImage", ""));
											}
										}}
										imagePreviewUrl={campaignImagePreview}
										formControlProps={{
											fullWidth: true,
										}}
										validate={
											formMode === "add"
												? [
													FormValidator.validateImageFormat,
													FormValidator.validateRequiredImage,
													FormValidator.validateImageSize,
												]
												: [
													FormValidator.validateImageFormat,
													FormValidator.validateImageSize,
												]
										}
										disabled={readOnly}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<Field
										name="languages[en][title]"
										component={CustomInput}
										type="text"
										labelText={i18n.t("label.title")}
										formControlProps={{
											fullWidth: true,
										}}
										disabled={readOnly}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									<Field
										name="languages[en][description]"
										component={CustomInput}
										type="text"
										labelText={i18n.t("label.description")}
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={12}>
									<Field
										name="languages[en][termAndCondition]"
										component={RichEditor}
										labelText={i18n.t("label.term_and_condition")}
									/>
								</GridItem>
								{merchantSetting.sendSMSAfterRedeem && (
									<>
										<GridItem xs={12} sm={12} md={12}>
											<div
												className={
													classes.checkboxAndRadio +
													" " +
													classes.checkboxAndRadioHorizontal
												}
											>
												<Field
													name={"sendSMSAfterRedeem"}
													component={CustomCheckbox}
													disabled={readOnly}
													labelText={i18n.t("label.send_sms_after_redeem")}
												/>
											</div>
										</GridItem>
										<GridItem xs={12} sm={12} md={12}>
											<Field
												name="SMSMessage"
												component={CustomInput}
												labelText={i18n.t("label.sms_message")}
												type="text"
												formControlProps={{
													fullWidth: true,
												}}
											/>
											<div style={{ color: "#555", fontSize: "13px" }}>
												Supported Key: [reward_name], [reward_expire_at],
												[coupon_url], [reward_code]
                      						</div>
										</GridItem>
									</>
								)}
								<GridItem xs={12} sm={6} md={6}>
									<Field
										name="customCaption"
										component={CustomInput}
										type="text"
										labelText={i18n.t("label.customCaption")}
										formControlProps={{
											fullWidth: true,
										}}
										normalize={(value) => {
											return crmValidator.handleKeyPress(
												value,
												FormValidator.form_validate.customCaption
											);
										}}
										disabled={readOnly}
									/>
								</GridItem>
							</div>
						</GridContainer>

						<GridContainer>
							<GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
								<Button type="submit" color="primary">
									{i18n.t("submit")}
								</Button>
								<Button
									type="button"
									onClick={this.handleClose}
									style={{ marginLeft: "10px" }}
									color="white"
								>
									{i18n.t("cancel")}
								</Button>
							</GridItem>
						</GridContainer>
					</form>
				</CardBody>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	const {
		formOpen,
		formMode,
		campaignImagePreview,
		rewardImagePreview,
		campaignData,
		trdPartyCodeSet,
		trueMoneyRewardSet,
		luckyDrawRewardSet,
		categoryList,
	} = state.campaign_v2;
	const { formTaglist, formMemberLevellist } = state.member;
	const { merchantSetting, package_features } = state.authentication;
	const { FORM_CAMPAIGN_V2 } = state.form;
	const formValues = getFormValues(FORM_NAME)(state)
    const { tableData } = state.photoGiveawayRule;

	return {
		formOpen,
		formMode,
		campaignImagePreview,
		rewardImagePreview,
		campaignData,
		FORM_CAMPAIGN_V2,
		trdPartyCodeSet,
		formTaglist,
		formMemberLevellist,
		categoryList,
		merchantSetting,
		trueMoneyRewardSet,
		luckyDrawRewardSet,
		package_features,
		formValues,
		tableData
	};
}

const connectCampaignForm = connect(mapStateToProps)(CampaignForm);

export default reduxForm({
	form: FORM_NAME,
	validate: validate,
	enableReinitialize: true,
	onSubmitFail: (errors, dispatch, props) => {
		let validate = FormValidator.form_validate;
		crmValidator.alertFirstInvalid(FORM_NAME, errors, validate);
	},
})(withStyles(style)(connectCampaignForm));

import React from "react";
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

class CustomSweetAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sweetAlert: {
                isShow: false
            }
        };
    }

    render() {
        const { sweetAlert, isShow } = this.props;

        return (
            <SweetAlert
                success={(sweetAlert.success) ? true : false}
                danger={(sweetAlert.danger) ? true : false}
                warning={(sweetAlert.type === "warning") ? true : false}
                show={isShow}
                style={{ display: "block" }}
                title={""}
                onConfirm={sweetAlert.onConfirm}
                onCancel={sweetAlert.onCancel}
                confirmBtnText={sweetAlert.confirmBtnText}
                cancelBtnText={sweetAlert.cancelBtnText}
                customClass={sweetAlert.customClass}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
                showCancel={false}
                showConfirm={false}
            >
                <h4>{sweetAlert.title}</h4>
                <h4>{sweetAlert.message}</h4>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        id="swal-btn-confirm"
                        className={
                            this.props.classes.button + " " + this.props.classes.success}
                        onClick={sweetAlert.onConfirm}>{sweetAlert.confirmBtnText}
                    </Button>
                    {sweetAlert.showCancel && <Button
                        id="swal-btn-cancel"
                        className={this.props.classes.button + " " + this.props.classes.danger}
                        style={{ marginLeft: "10px" }}
                        onClick={sweetAlert.onCancel}>{sweetAlert.cancelBtnText}
                    </Button>}
                </div>
            </SweetAlert>
        );
    }
}

function mapStateToProps(state) {
    const { sweetAlert, isShow } = state.sweetAlert;
    return {
        sweetAlert, isShow
    };
}

export default connect(mapStateToProps)(withStyles(sweetAlertStyle)(CustomSweetAlert));

import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";

import MUIDataTable from "mui-datatables";
import { plugins, crmValidator, loader, alert as customAlert } from "helpers";
import  { trueMoneyReportActions} from "actions";
import  { trueMoneyReportServices} from "services";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

const style = {};
  
const THEME = createMuiTheme(MTablestyles);

class TableTrueMoneySummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "sortBy",
                sortDir: "desc",
                keyword: "",
            },
            csvData: []
        };
        this.csvLink = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 800);
    }

    exportCSV() {
        const { summaryReward, dispatch } = this.props;
        let { tableFilter, tableTotal } = summaryReward;
        tableFilter.skip = 0;
        tableFilter.limit = tableTotal ? tableTotal : 100000;
        if (tableTotal > 30000) {
            customAlert(
                dispatch,
                "error",
                "Can not export data more than 30,000 rows"
            );
        } else {
            loader(dispatch, true);
            trueMoneyReportServices.downloadTrueMoneySummaryReward(tableFilter)
                .then((response) => {
                    loader(dispatch, false);
                })
                .catch((e) => {
                    this.setState({ csvData: [] });
                });
        }
    }

    getData(skip, limit, sortBy, sortDir, keyword) {
        const { dispatch, tableReload, summaryReward } = this.props;
        let { tableFilter } = summaryReward;

        let newFilter = tableFilter;
        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                newFilter.skip = 0;
            }

            if (crmValidator.isEmptyValue(limit)) {
                newFilter.limit = 10;
            }
            newFilter.page = skip;
            this.setState({
                rowsPerPage: limit,
            });
            skip = parseInt(limit) * parseInt(skip);

            newFilter.skip = skip;
            newFilter.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                newFilter.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                newFilter.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                newFilter.keyword = keyword;
            } else {
                newFilter.keyword = "";
            }
        } else {
            newFilter = this.state.newFilter;
        }
        dispatch(trueMoneyReportActions.getTrueMoneySummaryRewardList(newFilter));

        this.setState({
            isLoading: true,
            filter: newFilter,
        });
    }

    getRowData(rowIndex = 0) {
        const { summaryReward } = this.props;
        const { tableData } = summaryReward;
        const data = tableData;
        return data[rowIndex];
    }

    render() {
        const { rowsPerPage } = this.state;
        const { summaryReward } = this.props;
        const { tableData, tableTotal, tableFilter } = summaryReward ;

        const columns = [
            { name: "No", label: "No.",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
 
                   const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                   const rowIndex = rowSatrt + tableMeta.rowIndex;
 
                   return rowIndex + 1;
                }
             } 
            },
            { name: "rewardName", label: "Reward Name" },
            { name: "rewardStatus", label: "Status" },
            { name: "rewardBudget", label: "Reward Budget",},
            { name: "redeemSuccess", label: "Redeem Success" },
            { name: "verifyAmount", label: "Total Money Verify", },
            { name: "notifyAmount", label: "Total Money Topup" },
        ];
        const options = {
            serverSide: true,
            count: tableTotal ? tableTotal : 0,
            page: tableFilter.page,
            rowsPerPage: rowsPerPage,
            fixedHeader: true,
            selectableRows: "none",
            print: false,
            // search:false,
            customToolbar: () => {
                return (
                    <>
                        {plugins.isHasPermission("report[tmn_summary]", "export") &&plugins.isHasPackageFeatures('export') && (
                            <Tooltip title='Export CSV'>
                                <IconButton
                                    id='button-earnTable-exportCsv'
                                    className=''
                                    onClick={this.exportCSV.bind(this)}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [1, 5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip = "";
                let limit = "";
                let sortBy = "";
                let sortDir = "";
                let keyword = "";
    
                if (tableState.page !== null) {
                    skip = tableState.page;
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage;
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name;
                }
                if (tableState.announceText !== null) {
                    sortDir =
                        tableState.announceText.search("descending") !== -1
                            ? "desc"
                            : "asc";
                }
                if (tableState.searchText !== null || action === "search") {
                    keyword = tableState.searchText;
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword);
                }
                let actionList = ["changePage", "sort", "changeRowsPerPage"];
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword);
                }
            },
        };
        return (
            <div id='tableTrueMoneySummary'>
                <CustomSweetAlert />
                {/* <EarnReportDetail /> */}
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        
                        data={tableData ? tableData : []}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { visiblePersonalData, merchantSetting } = state.authentication;
    const { summaryReward } = state.tmnReport;
    return {
        summaryReward,
        visiblePersonalData,
        merchantSetting,
    };
}




export default connect(mapStateToProps)(withStyles(style)(TableTrueMoneySummary));

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import { withStyles } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";

const defaultToolbarStyles = {
  iconButton: {
    "&:hover":{
      color: primaryColor[0]
    }
  }
};

class CustomToolbar extends React.Component {
  render() {
    const { classes, handleClick, title, id, importIcon } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={title}>
          <IconButton className={classes.iconButton} onClick={handleClick} id={id}>
            {importIcon?
              <PublishIcon className={classes.deleteIcon}/>
              :<AddIcon className={classes.deleteIcon} />}
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles)(
  CustomToolbar
);

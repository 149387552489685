import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";
import i18n from 'i18n';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import { promotionConstants, swalConstants } from '../../constants';
import { promotionActions, brandAction } from 'actions';
import { plugins, crmValidator } from 'helpers';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { Field, reduxForm, change } from "redux-form";

import { store } from 'helpers';

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import Button from "components/CustomButtons/Button.jsx";

const style = {
    actionDiv: {
        display: "inline-block"
    },
    upperCase: {
        textTransfrom: 'uppercase'
    }
};

const FORM_NAME = "formFilterPromotion"

const moduleName = 'promotion'

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                color: '#666666',
                padding: '4px 24px 4px 15px',
                "&:nth-child(7)": {
                    textAlign: "center"
                }
            },
            body: {
                color: '#666666',
                padding: '4px 15px',
                "&:nth-child(14)": {
                    textAlign: "center"
                }
            }
        }
    }
});

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "Active": BadgeColor = "success"; break;
        case "Expired": BadgeColor = "danger"; break;
        default: BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}
class TablePromotionList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            searchFilter: {},
        };

        this.delayedCallback = lodash.debounce(this.getData, 500)

    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableDatas } = this.props

        let tableFilter = this.state.searchFilter

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)
            if(tableDatas.total !== 0 && tableDatas.total === skip){
                skip = skip - limit
                this.setState({
                    page: this.state.page - 1,
                })
            }
            if(skip >= tableDatas.total){
                skip = 0;
                this.setState({
                    page: 0,
                })
            }

            lodash.set(tableFilter, "skip", skip)
            lodash.set(tableFilter, "limit", limit)

            if (!crmValidator.isEmptyValue(sortBy)) {
                lodash.set(tableFilter, "sortBy", limit)
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                lodash.set(tableFilter, "sortDir", limit)
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                lodash.set(tableFilter, "keyword", encodeURIComponent(keyword))
            }
        }

        const reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');
        dispatch(promotionActions.getTablePromotionDatas(reqParams));

        this.setState({
            isLoading: true
        });

    }

    handleSubmit(values) {
        let searchFilter = {}

        if (lodash.size(values) > 0) {
          if (lodash.has(values, "brandId")) {
            lodash.set(searchFilter, "brandId", values.brandId)
          }
        }

        this.setState({
            searchFilter
        }, function () {
            this.getData(0,
                this.state.limit,
                this.state.sortBy,
                this.state.sortDir,
                this.state.keyword
            )
        })
    }

    componentDidMount() {
        const {
            dispatch
        } = this.props

        dispatch(brandAction.getBrandLists(''))
        this.getData();
    }

    openAddPromotion = () => {
        const { dispatch } = this.props;
        let initialValues = {
            startDate: "",
            endDate: "",
        }
        dispatch({ type: promotionConstants.OPEN_ADD_PROMOTION, data: initialValues });
    }

    openEditPromotion(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.promotions[index]
        dispatch(promotionActions.getPromotionById(rowData._id))
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: promotionConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.promotions;
        return data[rowIndex];
    }

    getListBrands = (brands) => {
        if (brands !== undefined) {
           return brands.map((brand) => {
              return {
                 value: brand._id,
                 label: brand.name
              }
           })
        }
        return []
    }

    render() {
        const {
            classes,
            dispatch,
            tableDatas,
            tableKey,
            tableLoader,
            handleSubmit
         } = this.props;
        const { rowsPerPage, page } = this.state;

        const storeData = store.getState().authentication
        const isBrandAdminRole = lodash.get(storeData, 'user.isBrandAdminRole', false)
        const superAdmin = !isBrandAdminRole || lodash.get(storeData, 'superAdmin', false)

        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "displayId", options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            superAdmin ? {
                name: "brandName", label: "brand Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        const brandId = rowData.brandId || ''
                        if (!brandId) {
                            return ''
                        }

                        const findBrand = (this.props.brands || []).find(brand => brand._id === brandId)
                        if (findBrand) {
                            return findBrand.name
                        }

                        return ''
                    }
                }
            } : null,
            {   name: "promotionId", label: "Promotion ID" },
            {
                name: "promotionName", label: "Promotion Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<div style={{ minWidth: "130px" }}>{value}</div>)
                    }
                }
            },
            {
                name: "startDate", label: "Start Date - End Date", options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        const startDateStr = (rowData.startDate !== undefined || rowData.startDate != null) ? (
                            <span className="text-nowrap">{plugins.datetimeFormat(rowData.startDate, "fulldatetime")}</span>
                        ) : ""

                        const endDateStr = (rowData.endDate !== undefined || rowData.endDate != null) ? (<span className="text-nowrap"> - {plugins.datetimeFormat(rowData.endDate, "fulldatetime")}</span>) : ""

                        return <>{startDateStr}{endDateStr}</>
                    }
                }
            },
            {
                name: "updated_at", label: "Update Date/Time", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.updated_at !== undefined || rowData.updated_at != null) {
                            return (<span className="text-nowrap">{plugins.datetimeFormat(rowData.updated_at, "fulldatetime")}</span>)
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "status", label: "Status", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return renderBadgeStatus(value)
                    }
                }
            },

        ];
        if (plugins.isHasPermission(moduleName, 'view')) {
            columns.push({
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classes.actionDiv}>
                                {
                                    <TableActionButton
                                        actionType={plugins.isHasPermission(moduleName, 'update')?"edit":"info"}
                                        onClick={(e) => {
                                            this.openEditPromotion(tableMeta.rowIndex)
                                        }}
                                    />
                                }
                                {plugins.isHasPermission(moduleName, 'delete') &&

                                    <TableActionButton
                                        actionType="delete"
                                        onClick={(e) => {
                                            dispatch({
                                                type: swalConstants.SHOW, data: {
                                                    type: "warning",
                                                    title: i18n.t("warning_messages.are_you_sure_delete_promotion"),
                                                    message: `${tableMeta.rowData[4]}`,
                                                    onConfirm: function () {
                                                        let reqParams = {
                                                            _id: tableMeta.rowData[0]
                                                        }
                                                        dispatch({ type: swalConstants.HIDE })
                                                        dispatch(promotionActions.deletePromotion(reqParams));
                                                    },
                                                    onCancel: function () {
                                                        dispatch({ type: swalConstants.HIDE })
                                                    },
                                                    showCancel: true,
                                                    confirmBtnText: i18n.t("delete"),
                                                    cancelBtnText: i18n.t("cancel")
                                                }
                                            })
                                        }} />
                                }
                            </div>
                        )
                    }
                }
            })
        }
        columns = columns.filter(c => c)

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };
        if (plugins.isHasPermission(moduleName, 'create')) {
            options.customToolbar = () => {
                return (
                    <CustomToolbar id="add_new_promotion" handleClick={this.openAddPromotion} title="Add New" />
                );
            }
        }

        return (
            <div id="tablePromotionList">
                <CustomSweetAlert />
                <MuiThemeProvider theme={THEME}>
                {!isBrandAdminRole &&
                    <>
                    <h4>
                        <b>PROMOTION LIST</b>
                    </h4>

                    <form
                    name={FORM_NAME}
                    onSubmit={handleSubmit(this.handleSubmit.bind(this))}
                    >
                        <GridContainer>
                            { !isBrandAdminRole && <GridItem xs={6} md={3} lg={3}>
                                <Field
                                    name="brandId"
                                    component={CustomSelect}
                                    type="text"
                                    labelText={"Brand"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            value: "",
                                            label: "All"
                                        },
                                        ...this.getListBrands(this.props.brands)
                                    ]}
                                />
                            </GridItem>
                            }

                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={3}>
                                <Button
                                onClick={this.clearFilter}
                                style={{
                                    marginTop: "25px"
                                }}
                                color="white"
                                >
                                {i18n.t("clear")}
                                </Button>
                                &nbsp;  &nbsp;  &nbsp;
                                <Button
                                    type="submit"
                                    style={{
                                        marginTop: "25px"
                                    }}
                                    color="primary"
                                    >
                                    {i18n.t("submit")}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                    </>
                    }
                    <MUIDataTable
                        key={tableKey}
                        title={`${isBrandAdminRole ? 'Promotion List' : ''}`}
                        data={tableDatas.promotions}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner />}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader } = state.promotion;
    const { permission, superAdmin, visiblePersonalData } = state.authentication;
    const { tableData: { brands } } = state.brand;
    return {
        permission,
        superAdmin,
        visiblePersonalData,
        formInitialValue,
        tableReload,
        tableReset,
        tableDatas,
        tableKey,
        tableLoader,
        brands
    };
}

const connectContainer = connect(mapStateToProps)(withStyles(pageStyle)(TablePromotionList));

export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
  })(withStyles(style)(connectContainer));
import React from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import lodash from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import TableWinnerList from "views/Winner/TableWinnerList.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { winnerConstants } from '../../constants';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { winnerActions } from 'actions';
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { plugins, loader, alert as customAlert } from 'helpers';
import { winnerServices } from 'services';

const style = {
    reportHeaderContainer: {
        margin: "0px",
        width: "calc(100% - 10px)",
        padding: "0px",
    },
    btnClearFilter: {
        marginRight: "10px",
        marginTop: "25px"
    },
    textCenter: {
        textAlign: "center"
    },
    mt20: {
        marginTop: "20px"
    }
};

const pageStyle = { ...style };
const FORM_NAME = "formFilterWinner";

class Winner extends React.Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
        this.state = {
            csvData: [],
            campaignList: [],
            rewardList: [],
            campaignAllList: [],
            announceDateAllList: [],
            rewardAllList: [],
            cnt: 0
        };
    }

    exportCSV() {
        const { dispatch, tableTotal, formFilterWinner } = this.props;
        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "created_at",
            sortDir: "desc",
            keyword: "",
            search: {}
        }
        tableFilter.search = {
            "mobileNo": (formFilterWinner.values === undefined || formFilterWinner.values.mobileNo === undefined) ? "" : formFilterWinner.values.mobileNo,
            "code": (formFilterWinner.values === undefined || formFilterWinner.values.code === undefined) ? "" : formFilterWinner.values.code,
            "display": (formFilterWinner.values === undefined || formFilterWinner.values.display === undefined) ? "" : formFilterWinner.values.display,
            "periodId": (formFilterWinner.values === undefined || formFilterWinner.values.periodId === undefined) ? "" : formFilterWinner.values.periodId,
            "rewardType": (formFilterWinner.values === undefined || formFilterWinner.values.rewardType === undefined) ? "" : formFilterWinner.values.rewardType,
            "rewardName": (formFilterWinner.values === undefined || formFilterWinner.values.rewardName === undefined) ? "" : formFilterWinner.values.rewardName,
            "winnerType": (formFilterWinner.values === undefined || formFilterWinner.values.winnerType === undefined) ? "" : formFilterWinner.values.winnerType,
        }
        tableFilter.skip = 0;
        tableFilter.limit = (tableTotal ? tableTotal : 100000);
        if (tableTotal > 30000) {
            customAlert(dispatch, 'error', "Can not export data more than 30,000 rows")
        } else {
            loader(dispatch, true)
            winnerServices.exportWinner(tableFilter)
                .then(response => {
                    loader(dispatch, false)
                })
                .catch(e => {
                    customAlert(dispatch, 'error', "SOMETHING_WENT_WRONG")
                    loader(dispatch, false)
                });
        }
    }

    handleSubmit(values) {
        const { dispatch, formFilterWinner } = this.props;
        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "createdAt",
            sortDir: "desc",
            keyword: "",
            search: {}
        }

        tableFilter.search = {
            "mobileNo": (formFilterWinner.values === undefined || formFilterWinner.values.mobileNo === undefined) ? "" : formFilterWinner.values.mobileNo,
            "code": (formFilterWinner.values === undefined || formFilterWinner.values.code === undefined) ? "" : formFilterWinner.values.code,
            "display": (formFilterWinner.values === undefined || formFilterWinner.values.display === undefined) ? "" : formFilterWinner.values.display,
            "periodId": (formFilterWinner.values === undefined || formFilterWinner.values.periodId === undefined) ? "" : formFilterWinner.values.periodId,
            "rewardType": (formFilterWinner.values === undefined || formFilterWinner.values.rewardType === undefined) ? "" : formFilterWinner.values.rewardType,
            "rewardName": (formFilterWinner.values === undefined || formFilterWinner.values.rewardName === undefined) ? "" : formFilterWinner.values.rewardName,
            "winnerType": (formFilterWinner.values === undefined || formFilterWinner.values.winnerType === undefined) ? "" : formFilterWinner.values.winnerType,
        }
        const resetTable = true
        dispatch(winnerActions.getTableDatas(tableFilter, resetTable));
    }

    componentDidMount() {
        this.getAnnounceDateList()
        this.getRewardList()
    }

    getAnnounceDateList() {
        const { dispatch } = this.props;
        let reqParams = "page=0&skip=0&limit=0&sortBy=created_at&sortDir=desc&keyword="
        dispatch(winnerActions.getAnnounceDateList(reqParams))
    }

    getRewardList() {
        const { dispatch } = this.props;
        let reqParams = "page=0&skip=0&limit=0&sortBy=created_at&sortDir=desc&keyword="
        dispatch(winnerActions.getRewardList(reqParams))
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch(change(FORM_NAME, 'mobileNo', ''));
        dispatch(change(FORM_NAME, 'code', ''));
        dispatch(change(FORM_NAME, 'display', ''));
        dispatch(change(FORM_NAME, 'periodId', ''));
        dispatch(change(FORM_NAME, 'rewardType', ''));
        dispatch(change(FORM_NAME, 'rewardName', ''));
        dispatch(change(FORM_NAME, 'winnerType', ''));

        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "createdAt",
            sortDir: "desc",
            keyword: "",
            search: {}
        }
        const resetTable = true
        dispatch(winnerActions.getTableDatas(tableFilter, resetTable));
    }

    render() {
        const { classes, formFilterWinner, handleSubmit, winnerList, merchantSetting, visiblePersonalData } = this.props;

        let campaignAllList = [];
        campaignAllList.push({
            value: "",
            label: "All"
        })

        if (lodash.has(winnerList, 'campaignList')) {
            lodash.forEach(winnerList.campaignList, function (value, key) {
                campaignAllList.push({
                    value: value._id,
                    label: value.name,
                    id: value._id,
                })
            })
        }

        let announceDateAllList = [];
        announceDateAllList.push({
            value: "",
            label: "All"
        })

        if (lodash.has(winnerList, 'announceDateList')) {
            lodash.forEach(winnerList.announceDateList, function (value, key) {
                announceDateAllList.push({
                    value: value.periodId,
                    label: plugins.datetimeFormat(value.announceDate, "fulldatetime") + ' / ' + value.rewardLuckyDraw.name_en,
                    id: value.periodId,
                })
            })
        }

        let rewardAllList = [];
        rewardAllList.push({
            value: "",
            label: "All"
        })

        if (lodash.has(winnerList, 'rewardList')) {
            lodash.forEach(winnerList.rewardList, function (value, key) {
                rewardAllList.push({
                    value: value.name_en,
                    label: value.name_en,
                    id: value.name_en,
                })
            })
        }

        return (
            <div>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                    {plugins.isHasPermission("luckyDraw[luckydrawWinnerManagement]", 'export') &&
                        <Button onClick={this.exportCSV.bind(this)}
                            style={{
                                backgroundColor: "#02A536",
                                marginTop: "20px",
                                width: "160px",
                                float: "right",
                                marginRight: "10px"
                            }}
                            id='btn_export'
                        >
                            Export File .csv
                        </Button>
                    }

                    <GridContainer className={classes.reportHeaderContainer}>
                        <GridItem xs={6} sm={2}>
                            {/* <h6><b>Redemption Date</b></h6> */}
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.reportHeaderContainer}>
                        <GridItem xs={6} sm={6} md={4} lg={4}>
                            <Field
                                name="mobileNo"
                                component={CustomInput}
                                type="text"
                                labelText="Mobile No."
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} lg={4}>
                            <Field
                                name="code"
                                component={CustomInput}
                                type="text"
                                labelText="Code"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} lg={4}>
                            <Field
                                name="display"
                                component={CustomInput}
                                type="text"
                                labelText="Display"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>

                        <GridItem xs={6} sm={6} md={4} lg={4}>
                            <Field
                                name="periodId"
                                component={CustomSelect}
                                type="text"
                                labelText="Announce Date"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                optionsList={announceDateAllList}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="rewardType"
                                component={CustomSelect}
                                type="text"
                                labelText="Reward Type"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                novalidate
                                optionsList={[
                                    {
                                        label: "All",
                                        value: "",
                                    },
                                    {
                                        label: "Jackpot",
                                        value: "Jackpot",
                                    },
                                    {
                                        label: "Regular",
                                        value: "Regular",
                                    },
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="rewardName"
                                component={CustomSelect}
                                type="text"
                                labelText="Reward Name"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                optionsList={rewardAllList}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={2} lg={2}>
                            <Field
                                name="winnerType"
                                component={CustomSelect}
                                type="text"
                                labelText="Winner Type"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                novalidate
                                optionsList={[
                                    {
                                        label: "All",
                                        value: "",
                                    },
                                    {
                                        label: "Winner",
                                        value: "Winner",
                                    },
                                    {
                                        label: "Reserve",
                                        value: "Reserve",
                                    },
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.reportHeaderContainer}>
                        <GridItem xs={12} sm={3}>
                            <Button
                                type="submit"
                                style={{ marginRight: "10px", marginTop: "25px" }}
                                size="sm"
                                color="primary"
                                id='btn_submit'>
                                Submit
                            </Button>
                            <Button color="white" className={classes.btnClearFilter} onClick={this.clearFilter} size="sm" id='btn_cancel'>
                                Clear
            </Button>
                        </GridItem>

                    </GridContainer>
                </form>
                <GridContainer className={classes.mt20}>
                    <GridItem xs={12}>
                        <TableWinnerList showAction={false} showSearch={false} />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { sweetAlert } = state.sweetAlert;
    const { permission, superAdmin, merchantSetting, visiblePersonalData } = state.authentication;
    const filterValues = getFormValues(FORM_NAME)(state)
    const { formFilterWinner } = state.form;
    const { tableDatas, tableTotal, submitForm, winnerList } = state.winner;
    return {
        sweetAlert, permission, superAdmin, filterValues, tableTotal, tableDatas, formFilterWinner, submitForm, winnerList, merchantSetting, visiblePersonalData
    };
}
const connectFilterWinnerForm = connect(mapStateToProps)(Winner);

export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    onChange: (values, dispatch, props, previousValues) => {
        if (values !== previousValues) {
            dispatch({ type: winnerConstants.TABLE_RESET });
        }
    }
})(withStyles(pageStyle)(connectFilterWinnerForm))
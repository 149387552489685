import React from "react";
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import lodash from 'lodash'

class barchartActiveUser extends React.Component {

    render() {
        let { tableDatas } = this.props
        let chartdata = []
        let labels = ["7 Days ago", "14 Days ago", "30 Days ago"]
        const apiData = tableDatas
        if (lodash.size(apiData) > 0) {
            lodash.mapValues(apiData, (val) => {
                chartdata.push(parseInt(val.count))
            });
        }
        lodash.reverse(labels)
        const data = {
            labels: labels ,
            datasets: [{
                label: "Active User ",
                data: chartdata,
                borderCapStyle: 'butt',
                barThickness: 70,
                backgroundColor: [
                    'rgba(255, 99, 132)',
                    'rgba(54, 162, 235)',
                    'rgba(255, 206, 86)',
                ],
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1
            }]
        };

        return (
            <>
                <Bar data={data} type="bar" options={{
                    maintainAspectRatio: true,
                    legend: false,
                    responsive: true,
                    scales: {
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Total User'
                            },
                            ticks: {
                                maxTicksLimit: 10,
                                beginAtZero: true,
                                stepSize: 1,
                                callback: function (value) {
                                    return parseInt(value)
                                }
                            }
                        }]
                    },
                }} ></Bar>
            </>
        )
    }
}

barchartActiveUser = connect(state => {
    const dashboard = state.dashboard
    const startDate = lodash.get(dashboard, 'tableFilter.search.startDate')
    const endDate = lodash.get(dashboard, 'tableFilter.search.endDate')
    const activeUser = lodash.get(dashboard, 'tableFilter.search.type')

    return {
        activeUser, startDate, endDate
    }
})(barchartActiveUser)

export default barchartActiveUser
import React from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { plugins, crmValidator, alert as customAlert } from 'helpers';
import lodash from 'lodash'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import MUIDataTable from "mui-datatables";
import i18n from 'i18n';
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { pointByPurchaseActions } from "actions/pointByPurchase.actions";
const FORM_NAME = "FORM_FILTER_POINT_BY_PURCHASE";
const moduleName = 'point[point_by_purchase]'

const style = {
    ...modalStyle,
    cardBody: {
        padding: "15px"
    },
    cardHeader: {
        marginLeft: "0px",
        color: "#333"
    },
    infoStyle: {
        "height": "100%",
        "padding": "12px",
        "fontSize": "1.5rem",
        "transition": "background-color 150ms cubic-bezier(0.4,0,0.2,1) 0ms",
        "outline": "none",
        "verticalAlign": "middle",
        "justifyContent": "center",
        "MozAppearance": "none"
    },
    typography: {
        padding: "10px"
    },
}
const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                textAlign: "center",
                "&:nth-child(1)": {
                    padding: '2px 24px'
                },
                "&:nth-child(2)": {
                    padding: '2px 60px'
                }
            },
            body: {
                textAlign: "center"
            }
        }
    }
});

const form_validate = {
    phoneNumber: {
        rules: {
            required: true,
            number: true,
            isMobilePhone: true,
        },
        messages: {
            required: 'warning_messages.mobile_number_is_required',
            isMobilePhone: 'warning_messages.member_mobile_number_is_invalid',
        },
        alert: 'warning_messages.mobile_number_is_required'
    },
    totalAmount: {
        rules: {
            required: true,
            number: true,
            noSpecialCharacters: true,
        },
        messages: {
            required: 'warning_messages.purchase_amount_is_required',
        },
        alert: 'warning_messages.purchase_amount_is_required'
    },
    purchaseDate: {
        rules: {
            required: true,
        },
        messages: {
            required: 'warning_messages.purchase_date_is_required',
        },
        alert: 'warning_messages.purchase_date_is_required'
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}
const onInsertPoint = (keyword) => {
    let regex
    if (parseInt(keyword) < 10) {
        regex = new RegExp(/[-.0]/g);
    } else {
        regex = new RegExp(/[-.]/g);
    }

    const chars = keyword.split('');
    const char = chars.pop();
    if (regex.test(char)) {
        keyword = chars.join('');
    }
    let keyInt = parseInt(keyword).toString()
    return keyInt;
}

class PointByPurchaseForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ancherEl: null,
            open: false
        };
    };

    handleSubmit(values) {
        const { dispatch, searchData } = this.props
        let reqBody = {
            "member.phoneNumber": lodash.get(searchData.data[0], 'phoneNumber'),
            "totalAmount": lodash.get(values, 'totalAmount'),
            "calculatePrice": lodash.get(values, 'totalAmount'),
        }
        if(lodash.get(values, 'purchaseDate')){
            reqBody = { ...reqBody,
                "purchaseDate": lodash.get(values, 'purchaseDate'),
            }
        }
        if(lodash.get(values, 'referenceCode')){
            reqBody = { ...reqBody,
                "refCode": lodash.get(values, 'referenceCode'),
            }
        }
        if(lodash.get(values, 'remark')){
            reqBody = { ...reqBody,
                "remark": lodash.get(values, 'remark'),
            }
        }
        dispatch(pointByPurchaseActions.createPointByPurchase(reqBody))
    };

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: "CLEAR_PROMOTION_FILTER" })
        dispatch(reset(FORM_NAME))
    }

    cancelCreate = () => {
        const { dispatch, tableFilter } = this.props;
        let _tableFilter = {
            page: 0,
            skip: 0,
            limit: tableFilter.limit,
            sortBy: "created_at",
            sortDir: "desc",
        }
        dispatch({ type: "CLOSE_FORM_POINT_BY_PURCHASE" })
        dispatch(reset(FORM_NAME))
        dispatch(pointByPurchaseActions.getTablePointByPurchaseDatas(_tableFilter));
    };

    searchUserByNumber = () => {
        const { dispatch, FORM_FILTER_POINT_BY_PURCHASE } = this.props
        dispatch(pointByPurchaseActions.getUserByPhoneNumber(lodash.get(FORM_FILTER_POINT_BY_PURCHASE, 'values.phoneNumber')))
    };

    getRowData(rowIndex = 0) {
        const { searchData } = this.props;
        const data = searchData.data;
        return data[rowIndex];
    };

    handleInfoOpen = event => {
        const { currentTarget } = event
        this.setState({ anchorEl: currentTarget });
        this.setState({ open: true })
    };

    handleInfoClose = event => {
        this.setState({ anchorEl: null })
        this.setState({ open: false })

    };

    render() {
        const { classes, formMode, handleSubmit, searchData, tableLoader, tableKey, FORM_FILTER_POINT_BY_PURCHASE, formOpen } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && plugins.isHasPermission(moduleName, 'update')) {
            readOnly = false
        } else {
            readOnly = true
        }

        const columns = [
            {
                name: "phoneNumber", label: "Mobile Number", options: { sort: false }
            },
            {
                name: "name", label: "Name", options: {
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex);
                        return rowData.lastName === undefined || rowData.lastName === null
                            ? rowData.firstName
                            : rowData.firstName + " " + rowData.lastName;
                    }
                }
            },
            {
                name: "point", label: "Current Point", options: { sort: false }
            },
        ]
        const options = {
            serverSide: true,
            count: false,
            page: false,
            rowsPerPage: 10,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: false,
            search: false,
            pagination: false,
            sort: false,
            sortFilterList: false,
            viewColumns: false
        };
        const displayCard = formOpen ? { padding: "15px" } : { display: "none" };

        return (
            <Card style={displayCard} className={classes.cardBody}>
                <CardBody>
                    <h4 className={classes.cardHeader}>
                        <b>Add Point by Purchase</b>
                    </h4>
                    <div style={{ marginLeft: '20px' }}>
                        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                            <GridContainer>
                                <span style={{ marginTop: '30px' }}>Member's Mobile Number *</span>
                                <GridItem xs={6} sm={3} md={3} lg={3}>
                                    <Field
                                        id="phone-number-input"
                                        name="phoneNumber"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Mobile Number *"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(value, form_validate.phoneNumber)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3} lg={3}>
                                    <Button
                                        id="search-btn"
                                        onClick={this.searchUserByNumber}
                                        style={{
                                            marginTop: "25px",
                                            backgroundColor: "#4caf50"
                                        }}
                                        size="sm">
                                        Search
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            <GridItem xs={12} sm={12} md={10} lg={10} style={{ marginLeft: '-30px' }}>
                                <div id="tableSearchPhoneNumber" style={{ borderTopStyle: 'solid', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderWidth: '1px', borderColor: 'rgb(224, 224, 224)' }}>
                                    <MuiThemeProvider theme={THEME}>
                                        <MUIDataTable
                                            key={tableKey}
                                            data={(searchData.data ? searchData.data : [])}
                                            columns={columns}
                                            options={options}
                                        />
                                        {tableLoader && <TableLoaderSpinner />}
                                    </MuiThemeProvider>
                                </div>
                            </GridItem>
                            <br />
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <span>Purchase Amount *</span>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <span>Purchase Date *</span>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <Field
                                        id="purchase-amount-input"
                                        name="totalAmount"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Purchase Amount *"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        min={1}
                                        disabled={readOnly}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(onInsertPoint(value), form_validate.totalAmount)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <Field
                                        id="purchase-date-input"
                                        name="purchaseDate"
                                        component={CustomDatePicker}
                                        type="text"
                                        labelText="Purchase Date *"
                                        maxDate={new Date()}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                    />
                                </GridItem>
                            </GridContainer>
                            <span>Reference Code</span>
                            <span style={style.infoStyle}
                                id='button-Info-TmnTransaction'
                                onMouseEnter={event => this.handleInfoOpen(event)}
                                onMouseLeave={event => this.handleInfoClose(event)}
                            >
                                <InfoOutlinedIcon />
                            </span>
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <Field
                                        id="reference-input"
                                        name="referenceCode"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Reference Code"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                    />
                                </GridItem>
                            </GridContainer>
                            <span style={{ marginTop: '30px' }}>Remark</span>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <Field
                                        id="remark-input"
                                        name="remark"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Remark"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        maxLength={250}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6} style={{ marginLeft: '-15px', marginTop: "25px" }}>
                                    <Button
                                        id="submit-btn"
                                        type="submit"
                                        style={{
                                            marginRight: "10px"
                                        }}
                                        size="sm"
                                        color="primary">
                                        Submit
                                    </Button>
                                    <Button
                                        id="cancel-btn"
                                        onClick={this.cancelCreate}
                                        size="sm"
                                        color="white">
                                        Cancel
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        
                        <Popper
                            open={this.state.open}
                            anchorEl={this.state.anchorEl}
                            placement="right"
                            transition
                        >
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper >
                                        <div className={classes.typography}>
                                            <span>ระบุหมายเลขอ้างอิง เช่น หมายเลขคำสั่งซื้อ เป็นต้น</span>
                                        </div>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                        </form>
                    </div>
                </CardBody>
            </Card>
        )
    }
}


function mapStateToProps(state) {
    const { formOpen, formMode, formInitialValue, searchData, tableLoader, tableKey, tableFilter } = state.point_by_purchase;
    const { FORM_FILTER_POINT_BY_PURCHASE } = state.form
    return {
        formOpen, formMode, formInitialValue, searchData, tableLoader, tableKey, tableFilter, FORM_FILTER_POINT_BY_PURCHASE
    };
}

const connectedForm = connect(mapStateToProps)(PointByPurchaseForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(style)(connectedForm))

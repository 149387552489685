import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

//page components
import TableAdminList from "./TableAdminList.jsx"

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const pageStyle = { ...style, ...sweetAlertStyle };
class AdminContainer extends React.Component {
    render() {
        // const { formInitialValue, formOpen } = this.props;
        // const displayCard = formOpen ? { display: "none" } : {}
        return (
            <div>
                <Card /*style={displayCard}*/>
                    <CardBody>
                        <CustomSweetAlert />
                        <GridContainer>
                            <GridItem xs={12}>
                                <TableAdminList />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }
}
// function mapStateToProps(state) {
//     const { formInitialValue, formOpen } = state.masterAdmin;
//     return {
//         formInitialValue, formOpen
//     };
// }

export default withStyles(pageStyle)(AdminContainer)
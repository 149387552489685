export const saleProductConstants = {
    OPEN_FORM_ADD: 'OPEN_FORM_ADD_SALEPRODUCT',
    OPEN_FORM_EDIT_SALEPRODUCT: 'OPEN_FORM_EDIT_SALEPRODUCT',
    CLOSE_FORM_SALEPRODUCT: 'CLOSE_FORM_SALEPRODUCT',
    ADD_SALEPRODUCT_SUCCESS: 'ADD_SALEPRODUCT_SUCCESS',
    GET_SALEPRODUCT_DATA_SUCCESS: 'GET_SALEPRODUCT_DATA_SUCCESS',
    TABLE_GET_LIST_SALEPRODUCT: "TABLE_GET_LIST_SALEPRODUCT",
    TABLE_RELOAD_SUCCESS: "TABLE_RELOAD_SUCCESS",
    TABLE_RESET_SUCCESS: "TABLE_RESET_SUCCESS",
    DELETE_SALEPRODUCT_SUCCESS: "DELETE_SALEPRODUCT_SUCCESS",
    EDIT_SALEPRODUCT_SUCCESS: "EDIT_SALEPRODUCT_SUCCESS",
    SET_FORM_PREVIEW_IMAGE : "SET_FORM_PREVIEW_IMAGE"
};
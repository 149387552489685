export const winnerApproveConstants = {

    GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS: 'GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS',
    GET_DROPDOWN_REWARD_LIST_SUCCESS: 'GET_DROPDOWN_REWARD_LIST_SUCCESS',

    CLEAR_PAGE_STATE: 'WINNER_APPROVE_CLEAR_PAGE_STATE',
    TABLE_RESET: 'TABLE_RESET',

    SHOW_LOADER_WINNER_APPROVE: 'SHOW_LOADER_WINNER_APPROVE',
    HIDE_LOADER_WINNER_APPROVE: 'HIDE_LOADER_WINNER_APPROVE',
    TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_DATAS',
    TABLE_GET_LIST_DATAS_RESET: 'TABLE_GET_LIST_DATAS_RESET',

    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
    APPROVE_WINNER_SUCCESS: 'APPROVE_WINNER_SUCCESS',
    REJECT_WINNER_SUCCESS: 'REJECT_WINNER_SUCCESS',
    APPROVE_UPLOAD_WINNER_SUCCESS: 'APPROVE_UPLOAD_WINNER_SUCCESS',
};

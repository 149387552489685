import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from 'react-redux'

import { AuthLayout } from "layouts/Auth.jsx";
import { AdminLayout } from "layouts/Admin.jsx";

import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";

import { SweetAlertPage } from "components/SweetAlert/SweetAlert.jsx";

import { store, history } from 'helpers';

import './i18n';

const PrivateRoute = ({ component: Component, ...rest }) => {
    return <Route
      {...rest}
      render={props => (
        localStorage.getItem('token') ? <Component {...props} /> : <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
      )}
    />
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <PrivateRoute path="/" component={AdminLayout} />
      </Switch>
    </Router>
    <SweetAlertPage />
  </Provider>,
  document.getElementById("root")
);

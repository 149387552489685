import { earnactivitiesServices } from '../services';
import { earnactivitiesConstants } from '../constants'
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const earnActivitiesAction = {
    getEarnActivityLists,
    addNewEarnActivity,
    editEarnActivity,
    deleteEarnActivity
};

function getEarnActivityLists(params) {
    return dispatch => {
        // loader(dispatch, true)
        dispatch({ type: earnactivitiesConstants.SHOW_TABLE_LOADER })
        earnactivitiesServices.getEarnActivityLists(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                // loader(dispatch, false)
                dispatch({ type: earnactivitiesConstants.HIDE_TABLE_LOADER })
            })
    }
    function success(tmp) { return { type: earnactivitiesConstants.GET_EARN_ACTIVITIES_DATA_SUCCESS, data: tmp } }
}

function addNewEarnActivity(params) {
    return dispatch => {
        loader(dispatch, true)
        earnactivitiesServices.addNewEarnActivity(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: earnactivitiesConstants.ADD_EARN_ACTIVITIES_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.earn_activity_was_created'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function editEarnActivity(params) {
    return dispatch => {
        loader(dispatch, true)
        earnactivitiesServices.editEarnActivity(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: earnactivitiesConstants.EDIT_EARN_ACTIVITIES_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.earn_activity_was_updated'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function deleteEarnActivity(params) {
    return dispatch => {
        loader(dispatch, true)
        earnactivitiesServices.deleteEarnActivity(params)
            .then(
                tmp => {
                    if (tmp.status) {
                        dispatch({ type: earnactivitiesConstants.DELETE_EARN_ACTIVITIES_SUCCESS });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.earn_activity_was_deleted'));
                    } else {
                        if(!tmp.unAlert) customAlert(dispatch, 'error', tmp.message.toString())
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

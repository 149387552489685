import React from "react";
import { connect } from 'react-redux';
import { change } from 'redux-form';

import MUIDataTable from "mui-datatables";
import lodash from 'lodash';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import UploadFormImport from "./UploadFormImport.jsx";

import { upload_3rd_partyConstants, swalConstants } from '../../constants';
import { uploadActions } from 'actions';
import { plugins, crmValidator } from 'helpers';

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

class TableUpload3rdPartyList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        };

        this.tableRef = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 500)

    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(uploadActions.getListsUpload3rdParty(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.data;
        return data[rowIndex];
    }

    componentWillMount() {
        const { dispatch } = this.props;
        let reqParams = ""
        dispatch({ type: upload_3rd_partyConstants.CLEAR_PAGE_STATE });
        dispatch(uploadActions.getListsMerchant(reqParams));
    }

    componentDidMount() {
        let { actions, readOnly } = this.state
        const { permission, superAdmin } = this.props

        if (superAdmin === false) {
            try {
                actions = actions.filter((action) => {
                    if (permission.product.indexOf('update') !== -1) {
                        readOnly = false
                    }
                    return permission.product.indexOf(action.name) !== -1
                })
            } catch (err) {
                actions = []
            }

            this.setState({
                actions: actions,
                readOnly: readOnly
            })
        } else {
            this.setState({
                readOnly: false
            })
        }

        this.getData();
    }

    openAddUpload3rdParty = () => {
        const { dispatch } = this.props;
        let initialValues = {
            expired_at: "",
            uploadImage: undefined,
            status: "Active"
        }
        dispatch({ type: upload_3rd_partyConstants.OPEN_ADD, data: initialValues });
    }

    openEditUpload3rdParty(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.data[index]

        let formInitData = {
            setId: rowData._id,
            setName: rowData.setName,
            setTitle: rowData.setTitle,
            description: rowData.description,
            expired_at: rowData.expired_at,
            merchantId: rowData.merchantId,
            termAndCondition: rowData.termAndCondition,
            status: rowData.status,
        }
        dispatch({ type: upload_3rd_partyConstants.OPEN_EDIT, data: formInitData, ImagePreview: rowData.imgPath });
    }

    openFormUpload3rdPartyFile(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.data[index]

        let formInitData = {
            codeSetId: rowData._id,
            merchantId: rowData.merchantId,
            fileImport: "",
        }
        dispatch(change('FORM_IMPORT_CODE', 'fileImport', undefined))
        dispatch({ type: upload_3rd_partyConstants.OPEN_FORM_UPLOAD, data: formInitData });
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: upload_3rd_partyConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    render() {
        const { classes, tableDatas, tableKey, formInitialValue, dispatch, tableLoader } = this.props;
        const { rowsPerPage, page } = this.state;

        const columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            {
                name: "merchant.nameEN", label: "Merchant Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let rowData = this.getRowData(tableMeta.rowIndex)
                        return (rowData.merchant.nameEN)
                    }
                }
            },
            { name: "setName", label: "Reward Name" },
            {
                name: "imgPath", label: "Reward Picture", options: {
                    sort: false,
                    customBodyRender: (value) => {
                        return (
                            <div className="text-center"><img src={value} style={{ maxWidth: "150px", maxHeight: "50px" }} alt="Reward Pic"></img></div>
                        )
                    }
                }
            },
            {
                name: "updated_at", label: "Update date/Time", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let rowData = this.getRowData(tableMeta.rowIndex)
                        if (!lodash.isEmpty(rowData)) {
                            let updateDate = plugins.datetimeFormat(rowData.updated_at, "datetime");
                            return (updateDate)
                        } else {
                            return ''
                        }
                    }
                }
            },
            {
                name: "unusedCode", label: "Available/All", options: {
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let rowData = this.getRowData(tableMeta.rowIndex)
                        return (rowData.unusedCode + "/" + rowData.totalCode)
                    }
                }
            },
            {
                name: "expired_at", label: "Last Date Used", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let rowData = this.getRowData(tableMeta.rowIndex)
                        if (!lodash.isEmpty(rowData)) {
                            let updateDate = plugins.datetimeFormat(rowData.expired_at, "date");
                            return (updateDate)
                        } else {
                            return ''
                        }
                    }
                }
            },
            {
                name: "status", label: "Status", options: {
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let rowData = this.getRowData(tableMeta.rowIndex)
                        return rowData.displayStatus
                    }
                }
            },
            {
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let rowData = this.getRowData(tableMeta.rowIndex)
                        return (
                            <div className={classes.actionDiv}>
                                <TableActionButton
                                    actionType="upload"
                                    onClick={(e) => {
                                        this.openFormUpload3rdPartyFile(tableMeta.rowIndex)
                                    }}
                                />
                                <TableActionButton
                                    actionType="edit"
                                    onClick={(e) => {
                                        this.openEditUpload3rdParty(tableMeta.rowIndex)
                                    }}
                                />
                                <TableActionButton
                                    actionType="delete"
                                    onClick={(e) => {
                                        dispatch({
                                            type: swalConstants.SHOW, data: {
                                                type: "warning",
                                                title: "ต้องการลบ?",
                                                message: `ชื่อ ${rowData.setName}`,
                                                onConfirm: function () {
                                                    let reqParams = {
                                                        _id: rowData._id
                                                    }
                                                    dispatch({ type: swalConstants.HIDE })
                                                    dispatch(uploadActions.deleteUpload3rdParty(reqParams));
                                                },
                                                onCancel: function () {
                                                    dispatch({ type: swalConstants.HIDE })
                                                },
                                                showCancel: true,
                                                confirmBtnText: 'ตกลง',
                                                cancelBtnText: 'ยกเลิก'
                                            }
                                        })
                                    }}
                                />
                            </div>
                        )
                    }
                }
            },
        ];

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            customToolbar: () => {
                return (
                    <CustomToolbar handleClick={this.openAddUpload3rdParty} title="Add New" />
                );
            },
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        return (
            <div id="TableUpload3rdPartyList">
                <CustomSweetAlert />
                <UploadFormImport
                    initialValues={formInitialValue}
                // readOnly={lodash.indexOf(permission[moduleName], 'update') === -1 && !superAdmin}
                />
                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        title={"Upload 3rd Party list"}
                        data={tableDatas.data}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner/>}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader } = state.upload_3rd_party;
    const { permission, superAdmin } = state.authentication;
    return {
        formInitialValue, tableReload, tableReset, permission, superAdmin, tableDatas, tableKey, tableLoader
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableUpload3rdPartyList));
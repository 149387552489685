import _ from 'lodash';
import { authConstants, roleConstants } from '../constants';
import { authServices } from '../services';
import { brandServices } from '../services';
import { alert, history, loader, urlPath, plugins } from 'helpers';

import routesjs from "routes.js";
import routeAdminsjs from "routeAdmins.js";

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const authActions = {
   Login,
   logout,
   getRole,
   getMerchantSetting,
   SwitchMerchant,
   LoginBypass,
   LoginByCUAA,
   LoginBypassFromSBO,
   getEnvConfig
};

function Login(email, password) {
   return dispatch => {
      loader(dispatch, true)

      authServices.login(email, password)
         .then(
            tmp => {
               if (!tmp.status) {
                  dispatch(failure(tmp.message.toString()));
                  if (!tmp.unAlert) alert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               } else {
                  dispatch(success(tmp.data));
                  history.push(urlPath + '/');
               }
            },
            error => {
               dispatch(failure(error.toString()));
               alert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: authConstants.LOGIN_SUCCESS, tmp } }
   function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function logout() {
   authServices.logout();
   return { type: authConstants.LOGOUT };
}

async function checkRoute(data, permission, isSuperAdmin, package_features) {
   let checkPermission = false
   for (const name of data) {
      const allowed = await plugins.isHasPermission(name.trim(), 'read', permission, isSuperAdmin, package_features);
      if (allowed) {
         checkPermission = true
      }
   }

   return checkPermission
}

async function assignRoute(data, permission, isSuperAdmin, package_features, limitation) {
   let ca = []
   let overLimit = _.find(limitation, { feature: "EGGCRM_member" })
   overLimit = _.get(overLimit, "over_limitation", false)
   data = _.create(data)
   const notDisabled = ["dashboard", "member", "report_transaction", "report", "report_summary", "report_truemoney", "report_point_expiration", "report_promotion"]

   for (const key in data) {
      let cl = []
      if (data[key].collapse) {
         cl = await assignRoute(data[key].views, permission, isSuperAdmin, package_features, limitation);
      }

      const checkPermission = await checkRoute(data[key].name.split('||'), permission, isSuperAdmin, package_features)
      if (data[key].bypass || (checkPermission && !data[key].collapse) || cl.length > 0) {
         if (cl.length > 0) {
            data[key].views = cl
         }
         data[key].disabled = (overLimit && !notDisabled.includes(data[key].id))
         ca.push(data[key])
      }
   }

   return ca
}

function getRole(pathName) {
   let isPermission = {}
   let isSuperAdmin = false
   let setting = {}
   let merchant = {}
   let user = {}
   let routes = _.cloneDeep(routesjs)

   return dispatch => {
      authServices.checkRoles()
         .then(
            async response => {
               try {
                  const data = response.data
                  let visiblePersonalData = data.roles.visiblePersonalData === true || false
                  isSuperAdmin = data.roles.superAdmin

                  const roleIds = _.get(data, 'roleIds') || []
                  const isBrandAdminRole = roleIds.length > 0 && roleIds[0] === roleConstants.BRAND_ADMIN_ROLE_ID

                  const brandId = _.get(data, 'brandId', null)
                  let brand = null
                  if (isBrandAdminRole) {
                     const brands = await brandServices.getBrandLists().then((res) => res.data.brands)
                     brand = brands.find(b => b._id === brandId) || null
                  }

                  user = {
                     email: response.data.email,
                     name: response.data.name,
                     defaultMerchantId: _.get(data, 'defaultMerchantId') || null,
                     masterAccount: _.get(data, 'masterAccount') || false,
                     masterLists: _.get(data, 'merchantLists') || [],
                     roleIds,
                     isBrandAdminRole,
                     tokenChatbot: _.get(data, 'chatbot') || undefined,
                     cuaa_id: _.get(data, 'cuaa_id') || null,
                     shopOwner: _.get(data, 'shopOwner', false),
                     brandId,
                     brand
                  }
                  let package_features = _.get(data, 'package_features', [])
                  let limitation = _.get(data, 'limitation', [])

                  let overLimitMember = _.find(limitation, { feature: "EGGCRM_member" })
                  overLimitMember = _.get(overLimitMember, "over_limitation", false)

                  if (!_.isEmpty(data.merchant) && data.merchant.masterAccount === true) {
                     routes = _.cloneDeep(routeAdminsjs)
                     isSuperAdmin = true
                  } else {
                     if (!_.isEmpty(data.roles) && !isSuperAdmin) {
                        let permission = _.get(data, 'roles.permission', [])
                        isPermission = plugins.setPermission(permission, 'allow')
                     }

                     routes = await assignRoute(routes, isPermission, isSuperAdmin, package_features, limitation)

                     routes.sort(function (a, b) {
                        return a.sortBy - b.sortBy;
                     });
                  }


                  if (!_.isEmpty(data.merchant)) {
                     setting = data.merchant.setting
                     merchant = data.merchant
                  }

                  dispatch(success(
                     {
                        routes: routes,
                        superAdmin: isSuperAdmin,
                        permission: isPermission,
                        pathName: pathName,
                        merchantSetting: setting,
                        user: user,
                        merchant: merchant,
                        visiblePersonalData: visiblePersonalData,
                        package_features: package_features,
                        overLimitMember: overLimitMember
                     }
                  ));
                  return { ...response, isPermission }
               } catch (error) {
                  return false;
               }
            }
         );
      return Promise.resolve()
   };

   function success(data) { return { type: authConstants.AUTH_ROLE_PERMISSION_SUCCESS, data } }
}

function getMerchantSetting() {
   return dispatch => {
      loader(dispatch, true);
      authServices.getMerchantSetting()
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: authConstants.GET_MERCHANT_SETTING_SUCCESS, merchantSetting: response.data })
               }
            }
         ).then(() => {
            loader(dispatch, false);
         })
   }
}

function SwitchMerchant(merchantId) {
   return dispatch => {
      loader(dispatch, true)

      authServices.switchMerchant(merchantId)
         .then(
            tmp => {
               if (!tmp.status) {
                  dispatch(failure(tmp.message.toString()));
                  if (!tmp.unAlert) alert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               } else {
                  dispatch(success(tmp.data));
                  // history.replace('/');
                  window.location.reload('/')
               }
            },
            error => {
               dispatch(failure(error.toString()));
               alert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: authConstants.SWITCH_MERCHANT_SUCCESS, tmp } }
   function failure(error) { return { type: authConstants.SWITCH_MERCHANT_FAILURE, error } }
}

const splitRoutes = (array, routes) => {
   for (const data of routes) {
      if (data.views) {
         splitRoutes(array, data.views)
      } else {
         array.push({ path: data.path, name: _.map(data.name.split('||'), _.trim) })
      }
   }

   return array

}

const checkFeature = async (pathName) => {
   return await authServices.checkRoles()
      .then(response => {
         let role = _.get(response, 'data.roles', {})
         let tmpRoute = splitRoutes([], routesjs)
         let package_features = _.get(response, 'data.package_features', [])

         let matchRoute = _.find(tmpRoute, (tmp) => {
            return tmp.path === pathName && _.intersection(tmp.name, package_features).length != 0
         })

         if(!matchRoute){
            return false
         }

         if (role.superAdmin) {
            return true
         } else {
            let permission = _.get(role, 'permission', [])
            permission = _.findIndex(permission, (tmp) => {
               return _.indexOf(matchRoute.name, tmp.name) != -1
            })

            return permission.length !== 0
         }

      }).catch(() => {
         return false
      })
}

function LoginBypass(token, redirect, source) {
   return dispatch => {
      loader(dispatch, true)

      authServices.loginBypass(token, source)
         .then(
            async tmp => {
               if (!tmp.status) {
                  dispatch(failure(tmp.message.toString()));
                  // if (!tmp.unAlert) alert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               } else {
                  dispatch(success(tmp.data));
                  let pathRedirect = await checkFeature(`/${redirect}`) ? `/${redirect}` : ''
                  history.push(urlPath + pathRedirect);
               }
            },
            error => {
               dispatch(failure(error.toString()));
               // alert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: authConstants.LOGIN_SUCCESS, tmp } }
   function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function LoginByCUAA(email, password) {
   return dispatch => {
      loader(dispatch, true)

      authServices.LoginByCUAA(email, password)
         .then(
            tmp => {
               if (!tmp.status) {
                  dispatch(failure(tmp.message.toString()));
                  if (!tmp.unAlert) alert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               } else {
                  dispatch(success(tmp.data));
                  history.push(urlPath + '/');
               }
            },
            error => {
               dispatch(failure(error.toString()));
               alert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: authConstants.LOGIN_SUCCESS, tmp } }
   function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}


function LoginBypassFromSBO(token, redirect) {
   return dispatch => {
      loader(dispatch, true)

      authServices.loginBypassFromSBO(token)
         .then(
            async tmp => {
               if (!tmp.status) {
                  dispatch(failure(tmp.message.toString()));
                  // if (!tmp.unAlert) alert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               } else {
                  dispatch(success(tmp.data));
                  // history.push(urlPath + '/' + redirect);
                  let pathRedirect = await checkFeature(`/${redirect}`) ? `/${redirect}` : ''
                  history.push(urlPath + pathRedirect);
               }
            },
            error => {
               dispatch(failure(error.toString()));
               // alert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: authConstants.LOGIN_SUCCESS, tmp } }
   function failure(error) { return { type: authConstants.LOGIN_FAILURE, error } }
}

function getEnvConfig() {
   return dispatch => {
      loader(dispatch, true);
      authServices.getEnvConfig()
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: authConstants.GET_ENV_CONFIG_SUCCESS, envConfig: response.data })
               }
            }
         ).then(() => {
            loader(dispatch, false);
         })
   }
}

import { uploadWinnerConstants } from '../constants';
import lodash from 'lodash'

const initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: new Date(),
    tableReload: false,
    tableReset: false,
    showLoader: false,
    tableDatas: [],
    formImportOpen: false,
    formImportInitialValue: {},
    showImportResult: false,
    importResult: [],
    formSaveImportOpen: false,
    saveImportResult: [],
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "createdAt",
        sortDir: "desc",
        keyword: "",
        search: {}
    },
};

export function uploadWinner(state = initialState, action) {
    switch (action.type) {
        case uploadWinnerConstants.CLEAR_PAGE_STATE:
            return initialState;
        case uploadWinnerConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case uploadWinnerConstants.OPEN_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue
            }
        case uploadWinnerConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formMode: 'add',
                formInitialValue: {}
            }
        case uploadWinnerConstants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case uploadWinnerConstants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case uploadWinnerConstants.GET_UPLOAD_WINNER_SUCCESS:
            return {
                ...state,
                tableFilter: action.tableFilter,
                tableDatas: (action.data ? action.data : []),
                tableTotal: (action.total ? action.total : 0)
            }
        case uploadWinnerConstants.ADD_NEW_UPLOAD_WINNER_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                formInitialValue: {}
            }
        case uploadWinnerConstants.EDIT_UPLOAD_WINNER_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case uploadWinnerConstants.TABLE_RELOAD_SUCCESS:
        case uploadWinnerConstants.TABLE_RESET:
            return {
                ...state,
                tableReset: false,
                tableReload: false,
            }
        case uploadWinnerConstants.DELETE_UPLOAD_WINNER_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case uploadWinnerConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                uploadWinnerContainer: {
                    ...state.uploadWinnerContainer,
                    campaignList: action.data.campaigns
                }
            }
        // announceDates
        case uploadWinnerConstants.GET_DROPDOWN_ANNOUCE_DATE_LIST_SUCCESS:
            return {
                ...state,
                uploadWinnerContainer: {
                    ...state.uploadWinnerContainer,
                    announceDateList: action.data.announceDates
                }
            }
        // reward
        case uploadWinnerConstants.GET_DROPDOWN_REWARD_LIST_SUCCESS:
            return {
                ...state,
                uploadWinnerContainer: {
                    ...state.uploadWinnerContainer,
                    rewardList: action.data.rewards
                }
            }
        case uploadWinnerConstants.OPEN_FORM_UPLOAD_UPLOAD_WINNER:
            return {
                ...state,
                formImportOpen: true,
                formImportInitialValue: (action.formImportInitialValue ? action.formImportInitialValue : {})
            }
        case uploadWinnerConstants.CLOSE_FORM_UPLOAD_UPLOAD_WINNER:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {}
            }
        case uploadWinnerConstants.SHOW_UPLOAD_UPLOAD_WINNER_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: true,
                importResult: action.data
            }
        case uploadWinnerConstants.HIDE_UPLOAD_UPLOAD_WINNER_RESULT:
            return {
                ...state,
                showImportResult: false,
                importResult: {}
            }
        case uploadWinnerConstants.OPEN_SAVE_UPLOAD_UPLOAD_WINNER_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: false,
                importResult: [],
                formSaveImportOpen: true,
                saveImportResult: action.data
            }
        case uploadWinnerConstants.CLOSE_SAVE_UPLOAD_UPLOAD_WINNER_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: false,
                importResult: [],
                formSaveImportOpen: false,
                saveImportResult: []
            }
        default:
            return state
    }
}

import { configConstants } from '../constants';
import axios from 'axios';
import _ from 'lodash';
import { history, setHeaders } from 'helpers';

export const reportServices = {
   getMemberReport,
   getEarnTransactionList,
   getBurnTransactionList,
   getCampaignsList,
   getListsProduct,
   getActiveTagList,
   getLuckyDrawDowloadList,
   getFileDownload,
   downloadEarnTransaction,
   downloadBurnTransaction,
   getEarnTransactionById,
   getEarnTransactionByIdImages,
   editTransaction,
   downloadMemberReport,
   downloadPromotionTransaction,
   getEarnRuleLists
};

function getMemberReport(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/members?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getEarnTransactionList(params) {
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/transaction-earn`, params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getBurnTransactionList(params) {
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/transaction-burn`, params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getCampaignsList(params) {
   let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns`, {
      headers: setHeaders({ 'Charset': 'utf-8' })
   })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return {
               status: true,
               data: response.data.data,
               message: response.data.message
            };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return {
               status: false,
               data: {},
               message: response.data.message
            };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getListsProduct(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/products?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getEarnRuleLists(params) {
   let tmp = axios.get(configConstants.API_BACKEND_CRM + `/earn-rules?${params}`, { headers: setHeaders() })
     .then(response => {
       if (response.data.statusCode.toString() === "200") {
         return { status: true, data: response.data.data, message: response.data.message };
       } else if (response.data.message === "UNAUTHORIZE") {
         history.push('/auth/login')
         return { status: false, data: {}, message: response.data.message, unAlert: true };
       } else {
         return { status: false, data: {}, message: response.data.message };
       }
     }).catch(e => {
       return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
     })
 
   return tmp;
 }

function getActiveTagList() {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/tags`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: e.message };
      });

   return tmp;
}

function getLuckyDrawDowloadList(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/transaction-luckydraw?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: e.message };
      });
   return tmp
}

function getFileDownload(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/download/luckydraw-transaction/` + params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, link: response.data.message.url[0], message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });
   return tmp
}

function downloadEarnTransaction(params) {
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/transaction-earn/export`, params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            const urlDownload = _.get(response.data, "data.url")
            if(urlDownload){
               window.location.assign(urlDownload)
            }
            return { status: true, data: {}, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function downloadBurnTransaction(params) {
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/transaction-burn/export`, params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            const urlDownload = _.get(response.data, "data.url")
            if(urlDownload){
               window.location.assign(urlDownload)
            }
            return { status: true, data: {}, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getEarnTransactionById(_id){
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/transaction-earn/${_id}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
   .then(response => {
      if (response.data.statusCode.toString() === "200") {
         return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
         history.push('/auth/login')
         return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
         return { status: false, data: {}, message: response.data.message };
      }
   }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
   });
   return tmp;
}

function getEarnTransactionByIdImages(_id){
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/transaction-earn/${_id}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
   .then(response => {
      if (response.data.statusCode.toString() === "200") {
         return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
         history.push('/auth/login')
         return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
         return { status: false, data: {}, message: response.data.message };
      }
   }).catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
   });
   return tmp;
}

function editTransaction(params) {
   var tmp = axios.put(configConstants.API_BACKEND_CRM + `/reports/transaction-earn`, params, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

function downloadMemberReport(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/users/export?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            const urlDownload = _.get(response.data, "data.url")
            if(urlDownload){
               window.location.assign(urlDownload)
            }
            return { status: true, data: {}, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}


function downloadPromotionTransaction(params) {
   var tmp = axios.post(configConstants.API_BACKEND_CRM + `/reports/promotion/export`,params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            const urlDownload = _.get(response.data, "data.url")
            if(urlDownload){
               window.location.assign(urlDownload)
            }
            return { status: true, data: {}, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}



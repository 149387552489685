import { Dashboard } from "views/Dashboard/Dashboard.jsx";
import MemberListPage from "views/Member/MemberContainer.jsx"
import AdminListPage from "views/Admin/AdminList.jsx"
import RoleListPage from "views/Role/RoleList.jsx"
import ShipmentListPage from "views/Shipment/ShipmentList.jsx";
import BannerContainer from "views/Banner/BannerContainer.jsx";
import BusinessWebsite from "views/BusinessWebsite/BusinessWebsiteContainer.jsx"
import ReportContainer from "views/Report/ReportContainer.jsx";
import SummaryReportContainer from "views/SummaryReport/SummaryReportContainer.jsx";
import PointExpirationContainer from "views/PointExpirationReport/PointExpirationContainer.jsx";
import CampaignNewContainer from "views/CampaignNew/CampaignContainer.jsx";
import EarnRulesContainer from "views/EarnRules/EarnRulesContainer.jsx";
import EarnActivitiesContainer from "views/EarnActivities/EarnActivitiesContainer.jsx";
import EarnSpecialContainer from "views/EarnSpecials/EarnSpecialContainer.jsx";
import ProductContainer from "views/Product/ProductContainer.jsx";
import PromotionContainer from "views/Promotion/PromotionContainer.jsx";
import PromotionForm from 'views/Promotion/PromotionForm.jsx';
import TagContainer from "views/Tag/TagContainer.jsx";
import MemberLevelContainer from "views/MemberLevel/LevelContainer.jsx";
import ImportContainer from "views/Import/ImportContainer.jsx";
import ContentManagement from "views/ContentManagement/ContentManagementList.jsx";
import RewardContainer from "views/TrueMoneyReward/RewardContainer.jsx";
import TopUpContainer from "views/TrueMoneyTopUp/TopUpContainer.jsx";
import TrueMoneyReportContainer from "views/TrueMoneyReport/TrueMoneyReportContainer.jsx";
import PointExpirationSetting from 'views/Point/PointExpirationSetting.jsx'
import PointAdjustmentContainer from 'views/PointAdjustment/PointAdjustmentContainer.jsx';
import PointAdjustmentForm from 'views/PointAdjustment/PointAdjustmentForm.jsx';
import PointByPurchaseContainer from 'views/PointByPurchase/PointByPurchaseContainer.jsx';
import WhitelistContainer from 'views/Whitelist/WhitelistContainer.jsx'
import ReferenceCode from "views/ReferenceCode/ReferenceCodeContainer.jsx";
import ShopContainer from 'views/ShopManagement/ShopContainer.jsx'
import CategoryContainer from "views/CategoryReport/CategoryContainer.jsx";
import LuckydrawRewaredList from "views/LuckydrawReward/LuckydrawRewardList";
import BlacklistListPage from "views/Blacklist/BlacklistContainer.jsx";
import PeriodContainer from "views/Period/PeriodContainer.jsx";
import WinnerApproveList from "views/WinnerApprove/WinnerApproveList";
import SearchCodeContainer from "views/SearchCode/SearchCodeContainer.jsx";
import WinnerConfigSetting from 'views/WinnerConfig/WinnerConfigSetting.jsx';
import UploadWinnerContainer from "views/UploadWinner/UploadWinnerContainer.jsx";
import WinnerContainer from "views/Winner/WinnerContainer.jsx";
import NewconditionContainer from "views/NewCondition/NewconditionContainer.jsx";
import DataFilteringConfigContainer from "views/DataFiltering/DataFilteringConfigContainer";
import DataFilteringTemplateContainer from "views/DataFilteringTemplate/DataFilteringTemplateContainer.jsx";
import DataFilteringResultContainer from "views/DataFilteringResult/DataFilteringResultContainer";
import LuckydrawDashboard from "views/LuckydrawDashboard/LuckydrawDashboard.jsx"

import PromotionReportContainer from "views/PromotionReport/PromotionReportContainer.jsx";
import RfmConfigContainer from "views/RfmConfig/RfmConfigContainer.jsx";
import TreeMapContainer from "views/TreeMap/TreeMapContainer.jsx";
import LuckyDrawContainer from "views/LuckyDrawReport/ReportContainer.jsx";
import MissionContainer from "views/Mission/MissionContainer.jsx";
import OcrReportContainer from "views/OcrReport/OcrReportContainer.jsx";
import Upload3rdPartyByMerchantContainer from "views/Upload3rdPartyByMerchant/UploadContainer.jsx";
import PhotoGiveawayRuleContainer from "views/PhotoGiveaway/PhotoGiveawayRuleContainer.jsx";
import PhotoGiveawayPhotoContainer from "views/PhotoGiveaway/PhotoGiveawayPhotoContainer.jsx";
import OrderContainer from "views/Sale/Order/OrderContainer.jsx";
import OrderForm from 'views/Sale/Order/OrderForm.jsx';

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import ViewArrayIcon from "@material-ui/icons/ViewArray";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import People from "@material-ui/icons/People";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Poll from "@material-ui/icons/Poll";
import ViewCompact from "@material-ui/icons/ViewCompact";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import HowToReg from "@material-ui/icons/HowToReg";
import Redeem from "@material-ui/icons/Redeem";
import LocalOffer from "@material-ui/icons/LocalOffer";
import PublishIcon from "@material-ui/icons/Publish";
import SettingsIcon from '@material-ui/icons/Settings';
import StoreIcon from '@material-ui/icons/Store';
import Money from '@material-ui/icons/Money'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AppsIcon from '@material-ui/icons/Apps';
import Block from '@material-ui/icons/Block';
import AssessmentIcon from '@material-ui/icons/Assessment';
import WebIcon from '@material-ui/icons/Web';
import EventAvailable from '@material-ui/icons/EventAvailable'
import CloudUpload from "@material-ui/icons/CloudUpload";
import CollectionsIcon from '@material-ui/icons/Collections';
import ProductGroup from "views/Sale/ProductGroup/ProductGroup";
import Product from "views/Sale/Product/Product";


const cmsRoute = [
    {
        path: "/dashboard",
        name: "dashboard",
        id: "dashboard",
        displayName: "Dashboard",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/",
        sortBy: 1
    },
    {
        path: "/sale",
        name: "",
        bypass:true,
        id: "sale",
        displayName: "Sale",
        icon: AttachMoneyIcon,
        component: ProductGroup,
        layout: "/",
        sortBy: 1,
        collapse: true,
        state: "saleCollapse",
        views: [
            {
                path: "/sale/product_group",
                name: "product_group",
                bypass:true,
                id: "product_group",
                displayName: "Product Group",
                component: ProductGroup,
                layout: "/"
            },
            {
                path: "/sale/product_sale",
                name: "product_sale",
                bypass:true,
                id: "product_sale",
                displayName: "Product",
                component: Product,
                layout: "/"
            },
            {
                path: "/sale/order",
                name: "dashboard",
                id: "report_transaction",
                displayName: "Order",
                component: OrderContainer,
                layout: "/"
            },
        ]
    },
    {
        path: "/business_website",
        name: "business_website",
        id: "business_website",
        displayName: "Business Website",
        icon: WebIcon,
        component: BusinessWebsite,
        layout: "/",
        sortBy: 2
    },
    {
        path: "/report",
        name: "report",
        id: "report",
        displayName: "Report",
        icon: ViewArrayIcon,
        component: ReportContainer,
        layout: "/",
        sortBy: 2,
        collapse: true,
        state: "reportCollapse",
        views: [
            {
                path: "/report/transaction",
                name: "report[earn] || report[burn]",
                id: "report_transaction",
                displayName: "Transaction",
                component: ReportContainer,
                layout: "/"
            },
            {
                path: "/report/summary",
                name: "report[summary_earn] || report[summary_burn]",
                id: "report_summary",
                displayName: "Summary Report",
                component: SummaryReportContainer,
                layout: "/"
            },
            {
                path: "/report/luckyDraw",
                name: "report[collect_luckydraw] || report[lucky_draw]",
                id: "report_transaction",
                displayName: "Lucky Draw",
                component: LuckyDrawContainer,
                layout: "/"
            },
            {
                path: "/report/truemoney",
                name: "report[tmn_transaction] || report[tmn_summary]",
                id: "report_truemoney",
                displayName: "True Money",
                component: TrueMoneyReportContainer,
                layout: "/"
            },
            {
                path: "/report/expiration",
                name: "report[point_expiration]",
                id: "report_point_expiration",
                displayName: "Point Expiration Report",
                component: PointExpirationContainer,
                layout: "/"
            },
            {
                path: "/report/promotion",
                name: "report[promotion]",
                id: "report_promotion",
                displayName: "Promotion",
                component: PromotionReportContainer,
                layout: "/"
            },
            {
                path: "/report/ocr",
                name: "report[ocr]",
                id: "report_ocr",
                displayName: "OCR",
                component: OcrReportContainer,
                layout: "/"
            },
        ]
    },
    {
        path: "/member",
        name: "member",
        id: "member",
        displayName: "Member",
        icon: People,
        component: MemberListPage,
        layout: "/",
        sortBy: 3
    },
    {
        path: "/category",
        name: "category",
        id: "category",
        displayName: "Category",
        icon: AppsIcon,
        component: CategoryContainer,
        layout: "/",
        sortBy: 3
    }, 
    {
        path: "/tag",
        name: "tag",
        id: "tag",
        displayName: "Tag",
        icon: LocalOffer,
        component: TagContainer,
        layout: "/",
        sortBy: 4
    }, {
        path: "/new-campaign",
        name: "campaign_new",
        id: "campaign",
        displayName: "Campaign",
        icon: Poll,
        component: CampaignNewContainer,
        layout: "/",
        sortBy: 6
    }, {
        path: "/uploads-reward-code",
        name: "uploads_reward_code",
        id: "3rd_party_reward",
        displayName: "3rd Party Reward Code",
        icon: CloudUpload,
        component: Upload3rdPartyByMerchantContainer,
        layout: "/",
        sortBy: 6
    }, 
    {
        path: "/earnrules",
        name: "earn_rule",
        id: "earn_rule",
        displayName: "Earn Rule",
        icon: Redeem,
        component: EarnRulesContainer,
        layout: "/",
        sortBy: 9
    }, {
        path: "/earnactivities",
        name: "earn_activity",
        id: "earn_activity",
        displayName: "Earn Activity",
        icon: Redeem,
        component: EarnActivitiesContainer,
        layout: "/",
        sortBy: 8
    }, 
    {
        path: "/earnspecials",
        name: "earn_special",
        id: "earn_special",
        displayName: "Earn Special",
        icon: Redeem,
        component: EarnSpecialContainer,
        layout: "/",
        sortBy: 9
    },    
    {
        path: "/promotion",
        name: "promotion",
        id: "promotion",
        displayName: "Promotion",
        icon: MonetizationOnIcon,
        component: PromotionContainer,
        layout: "/",
        sortBy: 9.0
    },
    {
        path: "/promotion/create",
        name: "promotion",
        id: "promotion",
        displayName: "Promotion",
        icon: MonetizationOnIcon,
        component: PromotionForm,
        layout: "/",
        hidden: true,
        sortBy: 0
    }, {
        path: "/product",
        name: "product",
        id: "product",
        displayName: "Product",
        icon: ShoppingCart,
        component: ProductContainer,
        layout: "/",
        sortBy: 9.1
    },
    {
        path: "/banner",
        name: "banner",
        id: "banner",
        displayName: "Banner",
        icon: ViewCompact,
        component: BannerContainer,
        layout: "/",
        sortBy: 7
    },
    {
        path: "/admin",
        name: "admin",
        id: "admin",
        displayName: "Admin",
        icon: SupervisorAccount,
        component: AdminListPage,
        layout: "/",
        sortBy: 11
    }, {
        path: "/roles",
        name: "role",
        id: "role",
        displayName: "Role",
        icon: HowToReg,
        component: RoleListPage,
        layout: "/",
        sortBy: 12
    },
    {
        path: "/member-level",
        name: "member_level[member_level_list] || member_level[automatic_shifting_level]",
        id: "member_level",
        displayName: "Member Level",
        icon: LocalOffer,
        component: MemberLevelContainer,
        layout: "/",
        sortBy: 13
    },
    {
        path: "/shipment",
        name: "shipment",
        id: "shipment",
        displayName: "Shipment",
        icon: LocalShippingIcon,
        component: ShipmentListPage,
        layout: "/",
        sortBy: 14
    },
    {
        path: "/import",
        name: "import[purchase_transaction] || import[create_product] || import[member]",
        id: "import",
        displayName: "Import File",
        icon: PublishIcon,
        component: ImportContainer,
        layout: "/",
        sortBy: 15
    },
    {
        path: "/true_money",
        name: "true_money",
        id: "true_money",
        displayName: "True money",
        icon: Redeem,
        component: RewardContainer,
        layout: "/",
        sortBy: 16,
        collapse: true,
        state: "tmnCollapse",
        views: [
            {
                path: "/true_money/reward",
                name: "true_money[reward]",
                id: "true_money_reward",
                displayName: "Reward",
                component: RewardContainer,
                layout: "/"
            },
            {
                path: "/true_money/topup",
                name: "true_money[top_up]",
                id: "true_money_top_up",
                displayName: "Top up",
                component: TopUpContainer,
                layout: "/"
            }
        ]
    },
    {
        path: "/contentmanagement",
        name: "content_management",
        id: "content_management",
        displayName: "Content Management",
        icon: SettingsIcon,
        component: ContentManagement,
        layout: "/",
        sortBy: 17
    },
    {
        path: "/shop",
        name: "shop",
        displayName: "Shop Management",
        id: 'shop_management',
        icon: StoreIcon,
        component: ShopContainer,
        layout: "/",
        sortBy: 18,
        collapse: true,
        state: "shopCollapse",
        views: [
            {
                path: "/shop/list",
                id: 'shop',
                name: "shop[list]",
                displayName: "Shop",
                component: ShopContainer,
                layout: "/"
            },
        ]
    },
    {
        path: "/point",
        name: "point",
        id: "point",
        displayName: "Point",
        icon: Money,
        component: "",
        layout: "/",
        sortBy: 19,
        collapse: true,
        state: "pointCollapse",
        views: [
            {
                path: "/point/point_adjustment",
                name: "point[point_adjustment]",
                id: "point_adjustment",
                displayName: "Point Adjustment",
                component: PointAdjustmentContainer,
                layout: "/"
            },
            {
                path: "/point/point_adjustment/create",
                name: "point_adjustment",
                id: "point_adjustment_form",
                displayName: "Point Adjustment",
                component: PointAdjustmentForm,
                layout: "/",
            },
            {
                path: "/point/point_expiration",
                name: "point[point_expiration]",
                id: "point_expiration",
                displayName: "Point Expiration",
                component: PointExpirationSetting,
                layout: "/"
            },
            {
                path: "/point/point_by_purchase",
                name: "point[point_by_purchase]",
                id: "point_by_purchase",
                displayName: "Point By Purchase",
                component: PointByPurchaseContainer,
                layout: "/"
            },
        ]
    },
    {
        path: "/whitelist",
        name: "whiteList",
        id: "whitelist",
        displayName: "Whitelist",
        icon: SettingsIcon,
        component: WhitelistContainer,
        layout: "/",
        sortBy: 20
    },
    {
        path: "/blacklist",
        name: "blackList",
        id: "blackList",
        displayName: "Blacklist",
        icon: Block,
        component: BlacklistListPage,
        layout: "/",
        sortBy: 21
    },
    {
        path: "/referencecode",
        name: "reference_code",
        id: "reference_code",
        displayName: "Reference Code",
        icon: SettingsIcon,
        component: ReferenceCode,
        layout: "/",
        sortBy: 22
    },
    {
        path: "/luckydraw",
        name: "luckyDraw",
        id: "luckydraw",
        displayName: "LuckyDraw",
        icon: ViewArrayIcon,
        component: LuckydrawRewaredList,
        layout: "/",
        sortBy: 23,
        collapse: true,
        state: "luckydrawCollapse",
        views: [
            {
                path: "/luckydraw/dashboard",
                name: "luckyDraw[luckydrawDashboard]",
                id: "luckydraw_dashboard",
                displayName: "Dashboard",
                component: LuckydrawDashboard,
                layout: "/"
            },
            {
                path: "/luckydraw/reward",
                name: "luckyDraw[luckydrawReward]",
                id: "luckydraw_reward",
                displayName: "Reward",
                component: LuckydrawRewaredList,
                layout: "/"
            },
            {
                path: "/luckydraw/period",
                name: "luckyDraw[luckydrawPeriod]",
                id: "luckydraw_period",
                displayName: "Period",
                component: PeriodContainer,
                layout: "/"
            },
            {
                path: "/luckydraw/uploadWinner",
                name: "luckyDraw[luckydrawWinnerUpload]",
                id: "luckydraw_uploadWinner",
                displayName: "Winner Upload",
                component: UploadWinnerContainer,
                layout: "/"
            },
            {
                path: "/luckydraw/winnerApprove",
                name: "luckyDraw[luckydrawWinnerApprove]",
                id: "luckydraw_winnerApprove",
                displayName: "Winner Approve",
                component: WinnerApproveList,
                layout: "/"
            },
            {
                path: "/luckydraw/winner",
                name: "luckyDraw[luckydrawWinnerManagement]",
                id: "luckydraw_winnerManagement",
                displayName: "Winner Management",
                component: WinnerContainer,
                layout: "/"
            },
            {
                path: "/luckydraw/winnerConfig",
                name: "luckyDraw[luckydrawWinnerConfig]",
                id: "luckydraw_winnerConfig",
                displayName: "Winner Config",
                component: WinnerConfigSetting,
                layout: "/"
            },
        ]
    },
    {
        path: "/search_code",
        name: "search_code",
        id: "search_code",
        displayName: "Search Code",
        icon: SettingsIcon,
        component: SearchCodeContainer,
        layout: "/",
        sortBy: 24
    },
    {
        path: "/df",
        name: "dataFiltering",
        id: "datafiltering",
        displayName: "DataFiltering",
        icon: ViewArrayIcon,
        component: DataFilteringConfigContainer,
        layout: "/",
        sortBy: 25,
        collapse: true,
        state: "datafilteringCollapse",
        views: [
            {
                path: "/df/tableconfig",
                name: "dataFiltering[tableConfig]",
                id: "datafiltering_tableconfig",
                displayName: "Table Config",
                component: DataFilteringConfigContainer,
                layout: "/"
            },
            {
                path: "/df/newcondition",
                name: "dataFiltering[newCondition]",
                id: "datafiltering_newcondition",
                displayName: "New condition",
                component: NewconditionContainer,
                layout: "/"
            },
            {
                path: "/df/conditionmanagement",
                name: "dataFiltering[conditionManagement]",
                id: "datafiltering_conditionmanagement",
                displayName: "Condition Management",
                component: DataFilteringTemplateContainer,
                layout: "/"
            },
            // {
            //     path: "/df/result",
            //     name: "dataFiltering[result]",
            //     id: "datafiltering_result",
            //     displayName: "Result Page",
            //     component: DataFilteringResultContainer,
            //     layout: "/"
            // },
        ]
    },
    {
        path: "/rfm",
        name: "rfm",
        id: "rfm",
        displayName: "RFM",
        icon: ViewArrayIcon,
        component: RfmConfigContainer,
        layout: "/",
        sortBy: 26,
        collapse: true,
        state: "rfmCollapse",
        views: [
            {
                path: "/rfm/rfmconfig",
                name: "rfm[configList]",
                id: "rfm_configList",
                displayName: "RFM Config",
                component: RfmConfigContainer,
                layout: "/"
            },
            {
                path: "/rfm/treemap",
                name: "rfm[treemap]",
                id: "rfm_treemap",
                displayName: "TreeMap",
                component: TreeMapContainer,
                layout: "/"
            },
        ]
    },
    {
        path: "/rfmconfig",
        name: "rfmConfigList",
        id: "rfmConfigList",
        displayName: "RFM Config",
        icon: SettingsIcon,
        component: RfmConfigContainer,
        layout: "/",
        sortBy: 26
    },
    {
        path: "/treemap",
        name: "treemap",
        id: "treemap",
        displayName: "TreeMap",
        icon: SettingsIcon,
        component: TreeMapContainer,
        layout: "/",
        sortBy: 27
    },
    {
        path: "/photogiveaway",
        name: "photogiveaway",
        id: "photogiveaway",
        displayName: "Photo Giveaway",
        icon: CollectionsIcon,
        component: "",
        layout: "/",
        sortBy: 28,
        collapse: true,
        state: "photogiveawayCollapse",
        views: [
            {
                path: "/photogiveaway/photo_giveaway_photo",
                name: "photoGiveaway[photoGiveawayPhoto]",
                id: "photo_giveaway_photo",
                displayName: "Photo",
                component: PhotoGiveawayPhotoContainer,
                layout: "/"
            },
            {
                path: "/photogiveaway/photo_giveaway_rule",
                name: "photoGiveaway[photoGiveawayRule]",
                id: "photo_giveaway_rule",
                displayName: "Rule",
                component: PhotoGiveawayRuleContainer,
                layout: "/",
            }
        ]
    }
]

export default cmsRoute;


import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import lodash from 'lodash';

export const pointByPurchaseServices = {
    getTablePointByPurchaseDatas,
    getUserByPhoneNumber,
    createPointByPurchase,
    voidPointByPurchase,
    getListsAdmin,
    downloadPointByPurchaseTransaction,
};

function getTablePointByPurchaseDatas(params) { 
    let tmp = axios.post(configConstants.API_BACKEND_CRM + `/point-by-purchase/purchase-transaction`, params, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getUserByPhoneNumber(phoneNumber) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/user/phoneNumber/${phoneNumber}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function createPointByPurchase(reqBody) {
    let tmp = axios.post(configConstants.API_BACKEND_CRM + '/point-by-purchase', reqBody, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: lodash.get(response, 'data.data'),
                message: response.data.message
            };
        }
    });
    return tmp;
}

function voidPointByPurchase(reqBody) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + `/point-by-purchase/void-transaction-earn`, reqBody, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function getListsAdmin(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/admins?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        }).catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function downloadPointByPurchaseTransaction(params) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + `/point-by-purchase/purchase-transaction/export`,params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
       .then(response => {
          if (response.data.statusCode.toString() === "200") {
             const urlDownload = lodash.get(response.data, "data.url")
             if(urlDownload){
                window.location.assign(urlDownload)
             }
             return { status: true, data: {}, message: response.data.message };
          } else if (response.data.message === "UNAUTHORIZE") {
             history.push('/auth/login')
             return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
             return { status: false, data: {}, message: response.data.message };
          }
       })
       .catch(e => {
          return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
       });
 
    return tmp;
 }
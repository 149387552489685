import { expirationConstants } from '../constants';
const initialState = {
    formOpen: false,
    formInitialValue: {},
    tableReload: false,
    sweetAlert: null,
    tableReset: false,
    tableDatas: {
        data: [],
        total: 0
    },
    tableKey: 0,
    tableLoader: false,
    tableLoaderHistory: false,
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "lastExpirationDate",
        sortDir: "desc",
        keyword: "",
        search: ""
      },
    tableDatasHistory: {
        data: [],
        total: 0
    }
};

export function expiration(state = initialState, action) {
    switch (action.type) {
        case expirationConstants.CLEAR_EXPIRATION_FILTER:
            return initialState;
        case expirationConstants.OPEN_HISTORY_POPUP:
            return {
                ...state,
                formOpen: true,
                id: action.id
            }
            case expirationConstants.CLOSE_HISTORY_POPUP:
            return {
                ...state,
                formOpen: false,
            }
        case expirationConstants.SHOW_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: true
            }
        }
        case expirationConstants.HIDE_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: false
            }
        }
        case expirationConstants.SHOW_TABLE_HISTORY_LOADER: {
            return {
                ...state,
                tableLoaderHistory: true
            }
        }
        case expirationConstants.HIDE_TABLE_HISTORY_LOADER: {
            return {
                ...state,
                tableLoaderHistory: false
            }
        }
        case expirationConstants.GET_EXPIRATION_DATA_SUCCESS: {
            return {
                ...state,
                tableDatas: action.data,
                tableReload: false,
                tableReset: false
            }
        }
        case expirationConstants.GET_HISTORY_EXPIRATION_DATA_SUCCESS: {
            return {
                ...state,
                tableDatasHistory: {
                    data: action.data.data,
                    total: action.data.total,
                }
            }
        }
        case expirationConstants.SET_FILTER_SEARCH: {
            return {
                ...state,
                tableFilter: {
                    ...state.tableFilter,
                    search: action.search,
                }
            }
        }
        case expirationConstants.SET_ROW_PER_PAGE: {
            return {
                ...state,
                tableFilter: {
                    ...state.tableFilter,
                    limit: action.rowsPerPage,
                }
            }
        }
        default:
            return state
    }
}
import { configConstants } from '../constants';
import axios from 'axios';
import lodash from 'lodash';

import { history, crmValidator, setHeaders } from 'helpers';

export const tagServices = {
    getTagList,
    addNewTag,
    editTag,
    deleteTag,
    importMember
};

function getTagList(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/tag?${(params) ? params : ""}`, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function addNewTag(params) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + '/tag', params, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function editTag(params) {
    var tmp = axios.put(configConstants.API_BACKEND_CRM + `/tag/${params._id}`, params, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function deleteTag(params) {
    let tmp = axios.delete(configConstants.API_BACKEND_CRM + `/tag/${params._id}`, {
        headers: setHeaders()
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function importMember(params) {
    try {
        let formData = new FormData();

        if (!crmValidator.isEmptyValue(params)) {
            lodash.forEach(params, function (value, key) {
                if (key === "fileImport") {
                    formData.append('file', value[0]);
                } else {
                    formData.append(key, value)
                }
            })
        }

        var tmp = axios.post(configConstants.API_BACKEND_CRM + '/tag/import-member', formData, {
            headers: setHeaders({ 'Charset': 'utf-8' })
        })
            .then(response => {
                if (response.data.statusCode.toString() === "200") {
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: response.data,
                        message: response.data.message
                    };
                }
            })
            .catch(error => {
                return {
                    status: false,
                    data: {
                        statusCode: 500
                    },
                    message: 'File not found, Please select file and try again.'
                };
            })
        return tmp;
    } catch (e) {
        return {
            status: false,
            data: {
                statusCode: 500
            },
            message: 'File not found, Please select file and try again.'
        };
    }
}
import React from "react";
import Loader from 'react-loader-spinner'

class TableLoaderSpinner extends React.Component {
    render() {
        return (
            <div 
                className="loader-spinner"
                style={{ 
                textAlign: "center", 
                position: "absolute",
                top: "0px",
                height: "100%",
                width: "calc(100% - 20px)",
                backgroundColor: "rgba(255,255,255,0.7)",
                zIndex: "800",
                display: "table"
                }}>
                <Loader
                    type="TailSpin"
                    color="orange"
                    height={50}
                    width={50}
                />
            </div>
        )
    }
}

export default TableLoaderSpinner;
import { campaignConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";

const initialState = {
   formOpen: false,
   formMode: 'add',
   formInitialValue: {
      startDate: "",
      endDate: "",
      status: "Active",
      redeemFormat: "Barcode",
      type: "point",
      redeemAble: {
         sameAsVisibleDate: true
      },
      campaignImage: undefined,
   },
   tableReset: false,
   tableReload: false,
   campaignImagePreview: defaultImage,
   campaignData: [],
   tableDatas: {
      campaigns: [],
      total: 0
   },
   tableKey: 0,
   trdPartyCodeSet: []
};

export function campaign(state = initialState, action) {
   switch (action.type) {
      case campaignConstants.CLEAR_PAGE_STATE:
         return initialState;
         
      case campaignConstants.OPEN_ADD_CAMPAIGN:
         return {
            ...state,
            formOpen: true,
            formMode: 'add',
            formInitialValue: action.data,
            campaignImagePreview: defaultImage
         };
      case campaignConstants.OPEN_ADD_CAMPAIGN_NEW:
         return {
            ...state,
            formOpen: true,
            formMode: 'add',
            formInitialValue: initialState.formInitialValue,
            campaignImagePreview: defaultImage
         };
      case campaignConstants.OPEN_EDIT_CAMPAIGN:
         return {
            ...state,
            formOpen: true,
            formMode: 'edit',
            formInitialValue: action.data,
            campaignImagePreview: action.campaignImagePreview
         };
      case campaignConstants.CLOSE_FORM_CAMPAIGN:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {
               campaignImagePreview: "",
               description: ""
            },
            campaignImagePreview: defaultImage
         }
      case campaignConstants.ADD_NEW_CAMPAIGN_SUCCESS:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            tableReload: false,
            tableReset: true,
            tableKey: new Date(),
         }
      case campaignConstants.EDIT_CAMPAIGN_SUCCESS:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            tableReset: false,
            tableReload: true
         }
      case campaignConstants.TABLE_RELOAD_SUCCESS:
      case campaignConstants.TABLE_RESET_SUCCESS:
         return {
            ...state,
            tableReload: false,
            tableReset: false
         }
      case campaignConstants.DELETE_CAMPAIGN_SUCCESS:
         return {
            ...state,
            tableReload: true
         }
      case campaignConstants.SET_FORM_PREVIEW_IMAGE_CAMPAIGN:
         return {
            ...state,
            campaignImagePreview: action.campaignImagePreview === "default"? defaultImage : action.campaignImagePreview
         }
      case campaignConstants.GET_CAMPAIGN_DATA_SUCCESS:
         return {
            ...state,
            campaignData: action.data
         }
      case campaignConstants.TABLE_GET_LIST_CAMPAIGNS:
         return {
            ...state,
            tableDatas: action.tmp,
            tableReload: false,
            tableReset: false
         }
      case campaignConstants.GET_TRD_PARTY_CODE_SET_SUCCESS:
         return {
            ...state,
            trdPartyCodeSet: action.data
         }
      default:
         return state
   }
}
export const whitelistConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_PAGE_STATE',

    OPEN_ADD: 'OPEN_ADD_WHITELIST',
    OPEN_EDIT: 'OPEN_EDIT_WHITELIST',
    CLOSE_FORM: 'CLOSE_FORM_WHITELIST',
    GET_WHITELIST_SUCCESS: 'GET_WHITELIST_SUCCESS' ,

    ADD_NEW_WHITELIST_SUCCESS: 'ADD_NEW_WHITELIST_SUCCESS',
    // ADD_NEW_TAG_FAILURE: 'ADD_NEW_TAG_FAILURE',

    EDIT_WHITELIST_SUCCESS: 'EDIT_WHITELIST_SUCCESS',
    //EDIT_WHITELIST_FAILURE: 'EDIT_WHITELIST_FAILURE',

    SHOW_LOADER: 'SHOW_TABLE_TAG_LOADER_SPINNER',
    HIDE_LOADER: 'HIDE_TABLE_TAG_LOADER_SPINNER',

    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    DELETE_WHITELIST_SUCCESS: 'DELETE_WHITELIST_SUCCESS',
    // DELETE_TAG_FAILURE: 'DELETE_TAG_FAILURE',
    
    OPEN_FORM_UPLOAD_WHITELIST: 'OPEN_FORM_UPLOAD_WHITELIST',
    CLOSE_FORM_UPLOAD_WHITELIST: 'CLOSE_FORM_UPLOAD_WHITELIST',
    SHOW_UPLOAD_WHITELIST_RESULT: 'SHOW_UPLOAD_WHITELIST_RESULT',
    HIDE_UPLOAD_WHITELIST_RESULT: 'HIDE_UPLOAD_WHITELIST_RESULT',

    OPEN_SAVE_UPLOAD_WHITELIST_RESULT: 'OPEN_SAVE_UPLOAD_WHITELIST_RESULT',
    CLOSE_SAVE_UPLOAD_WHITELIST_RESULT: 'CLOSE_SAVE_UPLOAD_WHITELIST_RESULT',

    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
    TABLE_RESET: 'TABLE_RESET',
 };
 
import { grayColor, dangerColor, successColor } from "assets/jss/material-dashboard-pro-react.jsx";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const sweetAlertStyle = {
    cardTitle: {
        marginTop: "0",
        marginBottom: "3px",
        color: grayColor[2],
        fontSize: "16px"
    },
    center: {
        textAlign: "center"
    },
    right: {
        textAlign: "right"
    },
    left: {
        textAlign: "left"
    },
    alertHeader: {
        "& > h2": {
            fontSize: "18px"
        }
    },
    alertHeaderDanger: {
        "& > h2": {
            fontSize: "18px",
            color: dangerColor[0]
        }
    },
    alertHeaderSuccess: {
        "& > h2": {
            fontSize: "18px",
            color: successColor[0]
        }
    },
    ...buttonStyle
};

export default sweetAlertStyle;

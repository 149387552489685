export const uploadWinnerConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_PAGE_STATE',
    // UPLOAD_WINNER
    OPEN_ADD: 'OPEN_ADD_UPLOAD_WINNER',
    OPEN_EDIT: 'OPEN_EDIT_UPLOAD_WINNER',
    CLOSE_FORM: 'CLOSE_FORM_UPLOAD_WINNER',
    GET_UPLOAD_WINNER_SUCCESS: 'GET_UPLOAD_WINNER_SUCCESS',

    ADD_NEW_UPLOAD_WINNER_SUCCESS: 'ADD_NEW_UPLOAD_WINNER_SUCCESS',
    // ADD_NEW_TAG_FAILURE: 'ADD_NEW_TAG_FAILURE',

    EDIT_UPLOAD_WINNER_SUCCESS: 'EDIT_UPLOAD_WINNER_SUCCESS',
    //EDIT_BLACKLIST_FAILURE: 'EDIT_BLACKLIST_FAILURE',

    SHOW_LOADER: 'SHOW_TABLE_TAG_LOADER_SPINNER',
    HIDE_LOADER: 'HIDE_TABLE_TAG_LOADER_SPINNER',

    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    DELETE_UPLOAD_WINNER_SUCCESS: 'DELETE_UPLOAD_WINNER_SUCCESS',
    // DELETE_TAG_FAILURE: 'DELETE_TAG_FAILURE',

    OPEN_FORM_UPLOAD_UPLOAD_WINNER: 'OPEN_FORM_UPLOAD_UPLOAD_WINNER',
    CLOSE_FORM_UPLOAD_UPLOAD_WINNER: 'CLOSE_FORM_UPLOAD_UPLOAD_WINNER',
    SHOW_UPLOAD_UPLOAD_WINNER_RESULT: 'SHOW_UPLOAD_UPLOAD_WINNER_RESULT',
    HIDE_UPLOAD_UPLOAD_WINNER_RESULT: 'HIDE_UPLOAD_UPLOAD_WINNER_RESULT',

    OPEN_SAVE_UPLOAD_UPLOAD_WINNER_RESULT: 'OPEN_SAVE_UPLOAD_UPLOAD_WINNER_RESULT',
    CLOSE_SAVE_UPLOAD_UPLOAD_WINNER_RESULT: 'CLOSE_SAVE_UPLOAD_UPLOAD_WINNER_RESULT',

    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
    GET_DROPDOWN_ANNOUCE_DATE_LIST_SUCCESS: 'GET_DROPDOWN_ANNOUCE_DATE_LIST_SUCCESS',
    GET_DROPDOWN_REWARD_LIST_SUCCESS: 'GET_DROPDOWN_REWARD_LIST_SUCCESS',
    TABLE_RESET: 'TABLE_RESET',
};


import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import lodash from 'lodash'
import { crmValidator,plugins } from "helpers";

import { reportActions } from 'actions';

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import moment from "moment";

import { Field, reduxForm } from 'redux-form';

import ProductMultiSelect from "components/CustomSelect/ProductMultiSelect.jsx";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

const FORM_NAME = "FORM_EARN_REPORT_VITAMILK";   
const form_validate = {
    "remark": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "code": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอก'
        }
    },
    "point": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณากรอกจำนวนเงิน'
        }
    },
    "productId": {
      rules: {
          required: true
      },
      messages: {
          required: 'กรุณาเลือกสินค้า'
      }
  },"receiptDate": {
    rules: {
        required: true
    },
    messages: {
        required: 'กรุณากรอกวันที่ในใบเสร็จ'
    }
}
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class EarnReportFormVitamilk extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            remark: true,
            first: true,
            approved_vitamilk: false
        };
    }

    closeDialog() {
        const { dispatch } = this.props;
        dispatch({ type: 'CLOSE_EARN_REPORT_EDIT' });
    }

    getRowData(rowIndex = 0) {
        const { earnReport } = this.props;
        const { tableData } = earnReport;
        const data = tableData;
        if (rowIndex.length === 0) {
            rowIndex = 0;
        }
        return data[rowIndex];
    }

    handleSubmit(values) {
      
        const { dispatch, user, initialValues,earnReport} = this.props;

        if (initialValues.remark !== values.remark||initialValues.code!==values.code) {
         if (values.remark=="PENDING") {
          const remark = values.remark;
          const transactionId = values._id;
          const code = values.code
          let params = {
            remark,
            transactionId,
            code
          };
          dispatch(reportActions.updateTransaction(params));
          
         }else{
          const remark = values.remark;
          const transactionId = values._id;
          const reject_note = values.reject_note;
          const productId = values.productId;
          const code = values.code
          let receiptDate = "";
          if (values.receiptDate) {
            receiptDate = plugins.datetimeFormat(
              values.receiptDate,
              "mongo"
            );
          } else {
            receiptDate = "";
          }
          let params = {};
          if (values.point) {
            const point = Number(values.point);
            let productNameList = [];
            if (lodash.has(earnReport, "productList")) {
              let data_product = lodash.get(earnReport, "productList");
              for (let index = 0; index < data_product.length; index++) {
                for (let info = 0;info < values.productId.length;info++ ) {
                  if (values.productId[info] == data_product[index]._id) {
                    productNameList.push(data_product[index].name);
                  }
                }
              }
            }
            params = {
              remark,
              transactionId,
              reject_note,
              point,
              productId,
              receiptDate,
              productNameList,
              code       
            };
          } else {
            params = {
              remark,
              transactionId,
              reject_note,
              code
            };
          }
             dispatch(reportActions.updateTransaction(params))  
         }
         
          /*  this.setState({ remark: false, first: true, approved_vitamilk: false }) */
        } else if (
          values.remark == "REJECT" &&
          initialValues.reject_note !== values.reject_note
        ) {
          const remark = values.remark;
          const transactionId = values._id;
          const reject_note = values.reject_note;
          const code = values.code;
          let params = {
            remark,
            transactionId,
            reject_note,
            code
          };
          dispatch(reportActions.updateTransaction(params));
        } else {
          this.closeDialog();
        }
    }

    render() {
        const { handleSubmit, formOpenVitamilk, fromMode, classes, earnReport, tableKey, initialValues, handleChange } = this.props;

        const images_rc = lodash.get(initialValues, 'images_rc')
        const remark = lodash.get(initialValues, 'remark')
        const code = lodash.get(initialValues, 'code')

        let productList = [];

        if (lodash.has(earnReport, "productList")) {
            lodash.forEach(earnReport.productList, function (value, key) {
                productList.push({
                    value: value._id,
                    label: value.name
                });
            });
        }

        let check_success_vi = false
        let check_reject_note = false
        if (remark === 'SUCCESS'&& images_rc) {
          check_success_vi = true
        }
        if (remark === 'REJECT') {
            check_reject_note = true
        }
        let remarkOptions
        if (remark === 'PENDING') {
            remarkOptions = [
                {
                    label: 'Pending',
                    value: 'PENDING'
                },
                {
                    label: 'Approve',
                    value: 'SUCCESS'
                },
                {
                    label: 'Reject',
                    value: 'REJECT'
                }
            ]
        } 
        else {
            remarkOptions = [
                {
                    label: 'Approve',
                    value: 'SUCCESS'
                },
                {
                    label: 'Reject',
                    value: 'REJECT'
                }
            ]
        }

        let imageListRc = []
    
        lodash.forEach(images_rc, function (value, key) {
            imageListRc.push(value.url);
        });

        const showImagesRc = imageListRc.map((item) => (
          <a href={item} key={item} target="_blank">
            <img src={item} width="300" />
          </a>
        ));

        return (
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={formOpenVitamilk}
            keepMounted
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            maxWidth="lg"
            scroll="body"
            disableRestoreFocus={true}
          >
            <form
              name={FORM_NAME}
              onSubmit={handleSubmit(this.handleSubmit.bind(this))}
            >
              <DialogContent
                id="earn-report-form-dialog"
                className={classes.modalBody}
                style={{ padding: "100", textAlign: "left" }}
          
              >
                 <h4
                  className={classes.modalTitle}
                  style={{ textAlign: "left" }}
                >
                  <b>Transaction</b>
                </h4>

                <h5>
                  <b>ใบเสร็จ</b>
                </h5>
                <div>{showImagesRc}</div>
                <GridContainer>
                  <GridItem xs={3} sm={4} md={4}>
                        <Field
                          name="code"
                          component={CustomInput}
                          type="text"
                          disabled={
                            imageListRc.length != 0 &&
                            remark === "SUCCESS"
                              ? true
                              : false
                          }
                          labelText="หมายเลขใบเสร็จ"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  <GridContainer>
                  {check_success_vi == true ||
                  this.state.approved_vitamilk == true ? (
                    <GridItem xs={3} sm={4} md={4}>
                      <Field
                        name="productId" 
                        component={ProductMultiSelect}
                        labelText="เลือกสินค้า"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        showtick
                        optionsList={productList}
                        disabled={
                          imageListRc.length != 0 &&
                          remark === "SUCCESS"
                            ? true
                            : false
                        }
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}
                </GridContainer>

                {imageListRc.length != 0 && (
                  <GridContainer>
                    <GridItem xs={3} sm={4} md={4}>
                      <Field
                        name="remark"
                        component={CustomSelect}
                        type="text"
                        labelText="Status"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        disabled={
                          imageListRc.length != 0 &&
                          remark === "SUCCESS"
                            ? true
                            : false
                        }
                        optionsList={remarkOptions}
                        onChange={(e) => {
                          this.setState({
                            approved_vitamilk:
                              e.target.value === "SUCCESS"
                                ? true
                                : false,
                          });
                          if (e.target.value === "SUCCESS") {
                            check_success_vi = true;
                          }
                        }}
                      />
                    </GridItem>
                    {check_success_vi == true ||
                    this.state.approved_vitamilk == true ? (
                      <>
                      <GridItem xs={3} sm={4} md={4}> 
                        <Field
                          name="point"
                          component={CustomInput}
                          type="number"
                          disabled={
                            imageListRc.length != 0 &&
                            remark === "SUCCESS"
                              ? true
                              : false
                          }
                          labelText="จำนวนเงิน"
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3} >
                           <Field
                             name="receiptDate"
                             component={CustomDatePicker}
                             type="text"
                             timeFormat={false}
                             labelText="ระบุวันที่ในใบเสร็จ"
                             isMaxDate={true}
                             maxDate={moment()}
                             formControlProps={{
                               fullWidth: true 
                             }}
                             style={{position:"relative",zIndex:""}}
                             disabled={
                              imageListRc.length != 0 &&
                              remark === "SUCCESS"
                                ? true
                                : false
                            }
                           />
                       </GridItem>
                       </>
                    ) : (
                      ""
                    )}
                    
                  </GridContainer>
                )}
              </DialogContent>

              <DialogActions className={classes.modalFooter}>
                <Button
                  id={`${FORM_NAME}_btn_submit`}
                  type="submit"
                  style={{ marginRight: "10px" }}
                  color="primary"
                >
                  Submit
                </Button>

                <Button
                  id={`${FORM_NAME}_btn_cancel`}
                  type="button"
                  onClick={() => {
                    this.setState({ remark: false, first: true });
                    this.closeDialog();
                  }}
                  color="white"
                >
                  Cancel
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { formOpenVitamilk, earnReport, formMode } = state.report;
    const { user } = state.authentication;
    return {
        earnReport,
        formOpenVitamilk,
        formMode,
        user
    };
}
const connectedComponent = connect(mapStateToProps)(EarnReportFormVitamilk);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        //    crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(sweetAlertStyle)(connectedComponent))



import moment from 'moment';
import { ocrReportConstants } from "../constants";

const initialState = {
        tableKey: Date.now(),
        tableData: [],
        tableTotal: 0,
        totalRequest: 0,
        tableFilter: {
          page: 0,
          skip: 0,
          limit: 10,
          sortBy: "date",
          sortDir: "desc",
          keyword: "",
          search: {
              startDate: moment().startOf('month'), 
              endDate: moment()
          }
        },
};

export function ocrReport(state = initialState, action) {
  switch (action.type) {
      case 'OCR_REDUCER_INIT': return initialState
      case ocrReportConstants.GET_OCR_REPORT:
        return {
            ...state,
             tableFilter: action.tableFilter,
             tableData: action.data ? action.data : [],
             tableTotal: action.total ? action.total : 0,
             totalRequest: action.totalRequest ? action.totalRequest : 0

        };
    default:
      return state;
  }

}

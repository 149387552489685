import { campaignConstants, campaign_v2Constants } from '../constants';
import { campaignServices, listServices, uploadWinnerServices } from '../services';
import { store, loader, alert as customAlert } from '../helpers';
import lodash from 'lodash';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';
const campaignActiveLimitExceedMessage = "Active Campaign exceed package limitation<br/>If you need a new active campaign, <br/>please upgrade your package<br/>or inactive previous ones"

export const campaignActions = {
    addCampaign,
    updateCampaign,
    deleteCampaign,
    getListsCampaign,
    getTrdPartyRewardCodeSetList,
    getCampaignDetail,
    getListsCampaignV2,
    addCampaignNew,
    updateCampaignNew,
    getCampaignByID,
    deleteCampaign_V2,
    getTrueMoneyRewardSetList,
    getLuckyDrawRewardSetList,
    copyStaticCampaignAndBuildJsonFile,
    getCategoryList,
    checkLimit
};

const campaignLimitationAlert = (dispatch) => {
    const storeData = store.getState().authentication

    let source = localStorage.getItem('source') || ''
    let accessToken = localStorage.getItem('token') || ''
    let bypassLoginSBO = lodash.get(storeData, 'envConfig.BYPASS_LOGIN_SBO_URL', '')
    let isCUAA = lodash.get(storeData, 'user.cuaa_id', '')
    let isShopOwner = lodash.get(storeData, 'user.shopOwner', false)

    const headerPopup = 'Failed Campaign Creation'

    if (isCUAA && source === 'cuaa' && isShopOwner) {
        customAlert(dispatch, 'error', headerPopup, false, false, campaignActiveLimitExceedMessage, () => {

            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", bypassLoginSBO);
            form.setAttribute("target", '_blank');

            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", "access_token");
            hiddenField.setAttribute("value", accessToken);
            form.appendChild(hiddenField);

            document.body.appendChild(form);

            form.submit();

        })
    } else if (isCUAA && source !== 'cuaa' && !isShopOwner) {
        customAlert(dispatch, 'error', headerPopup, false, false, campaignActiveLimitExceedMessage, () => {

            window.open(bypassLoginSBO.replace('/api/auth/passlogin', '/home'), '_blank').focus();

        })
    } else {
        customAlert(dispatch, 'error', headerPopup, false, false, campaignActiveLimitExceedMessage)
    }
}

function getListsCampaign(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.getListsCampaign(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) { return { type: campaignConstants.TABLE_GET_LIST_CAMPAIGNS, tmp } }
}

function addCampaign(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.addCampaign(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: campaignConstants.ADD_NEW_CAMPAIGN_SUCCESS
                        });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.campaign_was_created'));
                    } else {
                        if (!response.unAlert) {
                            let responseMessage = response.message.toString()
                            if (responseMessage.includes('_')) {
                                customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                            } else {
                                customAlert(dispatch, 'error', response.message.toString())
                            }
                        }
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function updateCampaign(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.updateCampaign(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: campaignConstants.EDIT_CAMPAIGN_SUCCESS
                        });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.campaign_was_updated'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function deleteCampaign(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.deleteCampaign(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: campaignConstants.DELETE_CAMPAIGN_SUCCESS
                        });
                        customAlert(dispatch, 'success', i18n.t("warning_messages.campaign_was_deleted"))
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getTrdPartyRewardCodeSetList(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.getTrdPartyRewardCodeSetList(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: campaignConstants.GET_TRD_PARTY_CODE_SET_SUCCESS, data } }
}

function getCampaignDetail(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.getListsCampaign(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) { return { type: campaignConstants.TABLE_GET_LIST_CAMPAIGNS, tmp } }
}

function getListsCampaignV2(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.getListsCampaign_V2(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) { return { type: campaign_v2Constants.TABLE_GET_LIST_CAMPAIGNS, tmp } }
}

function addCampaignNew(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.addCampaign_V2(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: campaign_v2Constants.ADD_NEW_CAMPAIGN_SUCCESS
                        });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.campaign_was_created'));
                    } else {
                        if (response.message === 'CAMPAIGN_ACTIVE_EXCEED_LIMIT') {
                            // customAlert(dispatch, 'error', 'Failed Campaign Creation', false, false, campaignActiveLimitExceedMessage)
                            campaignLimitationAlert(dispatch)
                        } else {
                            if (!response.unAlert) {
                                let responseMessage = response.message.toString()
                                if (responseMessage.includes('_')) {
                                    customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                                } else {
                                    customAlert(dispatch, 'error', response.message.toString())
                                }
                            }
                        }
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function updateCampaignNew(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.updateCampaign_V2(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: campaign_v2Constants.EDIT_CAMPAIGN_SUCCESS
                        });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.campaign_was_updated'));
                    } else {
                        if (response.message === 'CAMPAIGN_ACTIVE_EXCEED_LIMIT') {
                            // customAlert(dispatch, 'error', 'Failed Campaign Updates', false, false, campaignActiveLimitExceedMessage)
                            campaignLimitationAlert(dispatch)
                        } else {
                            if (!response.unAlert) {
                                let responseMessage = response.message.toString()
                                if (responseMessage.includes('_')) {
                                    customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                                } else {
                                    customAlert(dispatch, 'error', response.message.toString())
                                }
                            }
                        }
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getCampaignByID(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.getCampaignByID_V2(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data, response.data.filePath, response.data.rewardFilePath));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(data, img, imgReward) { return { type: campaign_v2Constants.OPEN_EDIT_CAMPAIGN, data: data, campaignImagePreview: img, rewardImagePreview: imgReward } }
}

function deleteCampaign_V2(params) {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.deleteCampaign_v2(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({
                            type: campaign_v2Constants.DELETE_CAMPAIGN_SUCCESS
                        });
                        customAlert(dispatch, 'success', i18n.t("warning_messages.campaign_was_deleted"))
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getTrueMoneyRewardSetList() {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.getTrueMoneyRewardSetList()
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: campaign_v2Constants.GET_TRUE_MONEY_REWARD_SET_SUCCESS, data } }
}

function getLuckyDrawRewardSetList() {
    let params = { showOnCampignAndEarnRule: "yes" }
    let strParams = ''
    if (typeof params === 'object') {
        strParams = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    }
    else {
        strParams = params
    }
    return dispatch => {
        loader(dispatch, true)
        uploadWinnerServices.getRewardList(strParams)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: campaign_v2Constants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS, data } }
}

function copyStaticCampaignAndBuildJsonFile(campaignId) {
    let tmp = campaignServices.createJsonFile(campaignId).then(
        response => {
            return response
        });
    return tmp
}

function getCategoryList() {
    return dispatch => {
        loader(dispatch, true)
        listServices.getCategoryList('campaign')
            .then(
                response => {
                    if (response.status) {
                        const categoryList = lodash.has(response.data, 'data') ? lodash.get(response.data, 'data') : []
                        dispatch(success(categoryList))
                    }
                },
                error => {
                    return [];
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(data) { return { type: campaign_v2Constants.GET_CATEGORY_LIST_SUCCESS, data } }
}

function checkLimit() {
    return dispatch => {
        loader(dispatch, true)
        campaignServices.checkLimit()
            .then(
                response => {
                    if (response.status) {
                        dispatch({ type: campaign_v2Constants.OPEN_ADD_CAMPAIGN });
                    } else {
                        if (response.message === 'CAMPAIGN_ACTIVE_EXCEED_LIMIT') {
                            // customAlert(dispatch, 'error', 'Failed Campaign Creation', false, false, campaignActiveLimitExceedMessage)
                            campaignLimitationAlert(dispatch)
                        } else {
                            customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                        }
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

import { brandServices } from '../services';
import { brandConstants } from '../constants'
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const brandAction = {
    getBrandLists,
    addNewBrands,
    editBrands,
    deleteBrand,
};

function getBrandLists(params) {
    return dispatch => {
        dispatch({ type: brandConstants.SHOW_TABLE_LOADER })

        loader(dispatch, true)
        dispatch({ type: brandConstants.SHOW_TABLE_LOADER })
        brandServices.getBrandLists(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch(success(response.data));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
                dispatch({ type: brandConstants.HIDE_TABLE_LOADER })
            })
    }
    function success(data) { return { type: brandConstants.GET_BRANDS_DATA_SUCCESS, data } }
}

function addNewBrands(params) {
    return dispatch => {
        loader(dispatch, true)
        brandServices.addNewBrands(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: brandConstants.ADD_BRAND_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.brand_was_created'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function editBrands(params) {
    return dispatch => {
        loader(dispatch, true)
        brandServices.editBrands(params)
            .then(
                response => {
                    try {
                        if (response.status) {
                            dispatch({ type: brandConstants.EDIT_BRAND_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('warning_messages.brand_was_updated'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function deleteBrand(params) {
    return dispatch => {
        loader(dispatch, true)
        brandServices.deleteBrand(params)
            .then(
                tmp => {
                    if (tmp.status) {
                        dispatch({ type: brandConstants.DELETE_BRAND_SUCCESS });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.brand_was_deleted'));
                    } else {
                        if(!tmp.unAlert) customAlert(dispatch, 'error', tmp.message.toString())
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

export const authConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    REQUEST_OTP_REQUEST: 'USERS_REQUEST_OTP_REQUEST',
    REQUEST_OTP_SUCCESS: 'USERS_REQUEST_OTP_SUCCESS',
    REQUEST_OTP_FAILURE: 'USERS_REQUEST_OTP_FAILURE',

    SEND_OTP_REQUEST: 'USERS_SEND_OTP_REQUEST',
    SEND_OTP_SUCCESS: 'USERS_SEND_OTP_SUCCESS',
    SEND_OTP_FAILURE: 'USERS_SEND_OTP_FAILURE',

    REQUEST_RESEND_OTP: 'REQUEST_RESEND_OTP',
    REQUEST_RESEND_SUCCESS: 'REQUEST_RESEND_SUCCESS',
    REQUEST_RESEND_FAILED: 'REQUEST_RESEND_FAILED',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    SWITCH_MERCHANT_SUCCESS: 'SWITCH_MERCHANT_SUCCESS',
    SWITCH_MERCHANT_FAILURE: 'SWITCH_MERCHANT_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    FORGOT_REQUEST_OTP_REQUEST: 'FORGOT_REQUEST_OTP_REQUEST',
    FORGOT_REQUEST_OTP_SUCCESS: 'FORGOT_REQUEST_OTP_SUCCESS',
    FORGOT_REQUEST_OTP_FAILURE: 'FORGOT_REQUEST_OTP_FAILURE',

    FORGOT_VERIFY_OTP_REQUEST: 'FORGOT_VERIFY_OTP_REQUEST',
    FORGOT_VERIFY_OTP_SUCCESS: 'FORGOT_VERIFY_OTP_SUCCESS',
    FORGOT_VERIFY_OTP_FAILURE: 'FORGOT_VERIFY_OTP_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    FORGOT_REQUEST_RESEND_OTP: 'FORGOT_REQUEST_RESEND_OTP',
    FORGOT_REQUEST_RESEND_SUCCESS: 'FORGOT_REQUEST_RESEND_SUCCESS',
    FORGOT_REQUEST_RESEND_FAILED: 'FORGOT_REQUEST_RESEND_FAILED',

    AUTH_ROLE_PERMISSION_REQUEST: 'AUTH_ROLE_PERMISSION_REQUEST',
    AUTH_ROLE_PERMISSION_SUCCESS: 'AUTH_ROLE_PERMISSION_SUCCESS',
    AUTH_ROLE_PERMISSION_FAILURE: 'AUTH_ROLE_PERMISSION_FAILURE',

    GET_MERCHANT_SETTING_SUCCESS: 'GET_MERCHANT_SETTING_SUCCESS',
    GET_ENV_CONFIG_SUCCESS: 'GET_ENV_CONFIG_SUCCESS',

    CREATE_ROUTES: 'AUTH_CREATE_ROUTES',
    
    CLEAR_PAGE_STATE: 'AUTH_CLEAR_PAGE_STATE'
};

export const tmn_rewardConstants = {
    OPEN_FORM_ADD: 'OPEN_ADD_FORM_TMN_REWARD',
    OPEN_FORM_EDIT: 'OPEN_EDIT_FORM_TMN_REWARD',
    CLOSE_FORM: 'CLOSE_FORM_TMN_REWARD',

    ADD_NEW_SUCCESS: 'ADD_NEW_TMN_REWARD_SUCCESS',
    ADD_NEW_FAILURE: 'ADD_NEW_TMN_REWARD_FAILURE',

    EDIT_SUCCESS: 'EDIT_TMN_REWARD_SUCCESS',
    EDIT_FAILURE: 'EDIT_TMN_REWARD_FAILURE',

    ADD_BUDGET_SUCCESS: 'ADD_BUDGET_TMN_REWARD_SUCCESS',
    ADD_BUDGET_FAILURE: 'ADD_BUDGET_TMN_REWARD_FAILURE',

    DELETE_REQUEST: 'DELETE_TMN_REWARD_REQUEST',
    DELETE_SUCCESS: 'DELETE_TMN_REWARD_SUCCESS',
    DELETE_FAILURE: 'DELETE_TMN_REWARD_FAILURE',

    GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
    GET_STATISTICS_FAILURE: 'GET_STATISTICS_FAILURE',

    GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
    GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
    GET_ROLE_FAILURE: 'GET_ROLE_FAILURE',

    HIDE_ALERT: 'HIDE_ALERT',

    ADMIN_LIST_CLEAR_STATE: 'ADMIN_LIST_CLEAR_STATE',

    TABLE_RELOAD: 'RELOAD_TABLE_TMN_REWARD',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
    TABLE_RESET: 'TABLE_RESET',
    TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',
    FORM_RESET_SUCCESS: 'FORM_RESET_SUCCESS',

    CLEAR_PAGE_STATE: 'ADMIN_CLEAR_PAGE_STATE',
    TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_TMN_REWARDS',

    SHOW_TABLE_LOADER: 'SHOW_TMN_REWARD_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_TMN_REWARD_TABLE_LOADER',

    SET_FORM_PREVIEW_IMAGE: 'TMN_REWARD_SET_FORM_PREVIEW_IMAGE',
};


import { configConstants } from "../constants";
import axios from "axios";
import { history, setHeaders } from "helpers";

const API_PATH = "sales-page/product-sales";
export const saleProductServices = {
  getListsSaleProduct,
  addSaleProduct,
  updateSaleProduct,
  deleteSaleProduct,
  getSaleProductDetail,
  uploadFile
};
function getListsSaleProduct(params) {
  var tmp = axios.get(configConstants.API_BACKEND_CRM + `/${API_PATH}?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    })
    .catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

  return tmp;
}

function addSaleProduct(values) {
  var tmp = axios.post(configConstants.API_BACKEND_CRM + `/${API_PATH}`, values, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.data ? response.data.message.data : response.data.message };
      }
    });
  return tmp;
}

function updateSaleProduct(values) {

  var object = {};
  values.forEach((value, key) => object[key] = value);
  var productId = object._id;

  var tmp = axios.put(configConstants.API_BACKEND_CRM + `/${API_PATH}/${productId}`, values, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.data ? response.data.data : response.data.message };
      }
    });
  return tmp;
}

function deleteSaleProduct(params) {
  var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/${API_PATH}/${params._id}/soft`, { headers: setHeaders() })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    });

  return tmp;
}

function getSaleProductDetail(params) {
  var tmp = axios.get(configConstants.API_BACKEND_CRM + `/${API_PATH}/${params._id}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    })
    .catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

  return tmp;
}

function uploadFile(formData) {
  var tmp = axios
    .post(configConstants.API_BACKEND_CRM + `/${API_PATH}/image`, formData, {
      headers: setHeaders({ "Content-Type": "multipart/form-data" }),
    })
  return tmp;
}

// import { campaignConstants, campaign_v2Constants } from '../constants';
import { ReferenceCodeConstants } from '../constants';
import { referenceCodeServices } from '../services';
import { loader, alert as customAlert } from '../helpers';

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const ReferenceCodeActions = {
    getListsReferenceCode,
    addNewReferenceCode,
    editReferenceCode,
    deleteReferenceCode,
    getCampaignByID,
    getActiveCampaignsList
};

function getListsReferenceCode(params) {
    return dispatch => {
        loader(dispatch, true)
        referenceCodeServices.getListsReferenceCode(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) { return { type: ReferenceCodeConstants.TABLE_GET_LIST_REFERENCE, tmp }}
}

function addNewReferenceCode(params) {
    return dispatch => {
       loader(dispatch, true)
       referenceCodeServices.addNewReferenceCode(params)
          .then(
             response => {
                if (response.status) {
                   dispatch({
                      type: ReferenceCodeConstants.ADD_NEW_REFERENCE_SUCCESS
                   });
                   customAlert(dispatch, 'success', i18n.t('warning_messages.reference_code_was_created'));
                } else {
                   if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                }
             },
             error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
             }
          ).then(() => {
             loader(dispatch, false)
          })
    };
 }

 function editReferenceCode(params) {
    return dispatch => {
       loader(dispatch, true)
       referenceCodeServices.editReferenceCode(params)
          .then(
             response => {
                if (response.status) {
                   dispatch(success(response.data));
                   customAlert(dispatch, 'success', i18n.t('warning_messages.reference_code_was_updated'));
                } else {
                   if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                }
             },
             error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
             }
          ).then(() => {
             loader(dispatch, false)
          })
    };
    function success(tmp) { return { type: ReferenceCodeConstants.EDIT_REFERENCE_SUCCESS, tmp } }
 }

function deleteReferenceCode(params) {
return dispatch => {
    loader(dispatch, true)
    referenceCodeServices.deleteReferenceCode(params)
        .then(
            response => {
            if (response.status) {
                dispatch({
                    type: ReferenceCodeConstants.DELETE_REFERENCE_SUCCESS
                });
                customAlert(dispatch, 'success', i18n.t("warning_messages.reference_code_was_deleted"))
            } else {
                if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
            }
            },
            error => {
            customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
        ).then(() => {
            loader(dispatch, false)
        })
};
}
 function getActiveCampaignsList() {
    return dispatch => {

        dispatch({ type: ReferenceCodeConstants.GET_REFERENCE_CAMPAIGNSLIST_REQUEST })
        loader(dispatch, true)

        referenceCodeServices.getActiveCampaignsList()
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(tmp) { return { type: ReferenceCodeConstants.GET_REFERENCE_CAMPAIGNSLIST_SUCCESS, tmp } }
}


function getCampaignByID(params) {
    return dispatch => {
        loader(dispatch, true)
        referenceCodeServices.getCampaignByID_V2(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data, response.data.filePath, response.data.rewardFilePath));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };

    function success(data, img, imgReward) { return { type: ReferenceCodeConstants.OPEN_EDIT_CAMPAIGN, data: data, campaignImagePreview: img, rewardImagePreview: imgReward }}
}


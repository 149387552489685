import React from "react";
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//page components
import MemberList from "views/Member/MemberList.jsx";

const style = {};

class MemberContainer extends React.Component {
    render() {
        const { formInitialValue } = this.props
        return (
            <MemberList initialValues={formInitialValue}/>
        )    
    }
}

function mapStateToProps(state) {
  const { formInitialValue } = state.member;
  return {
    formInitialValue
  };
}
const connectMemberContainer = connect(mapStateToProps)(MemberContainer);

export default withStyles(style)(connectMemberContainer);
import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import MUIDataTable from "mui-datatables";
import { plugins, crmValidator, loader , alert as customAlert } from "helpers";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import moment from 'moment'
import { summaryReportActions } from 'actions'
import { summaryReportServices } from 'services'
import { CSVLink } from "react-csv";
import qs from 'querystring'
import Button from "components/CustomButtons/Button.jsx";
import { ocrReportActions } from "actions";

const THEME = createMuiTheme(MTablestyles);
const style = {
    actionDiv: {
        display: "inline-flex"
    }
};
class TableOcrSummaryReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "reportDate",
                sortDir: "desc",
            },
            csvData: [],
            csvFileName: ""
        };
        this.csvLink = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 800);
    }

    getData(skip, limit, sortBy, sortDir, keyword) {
        const { dispatch, tableReload, tableFilter } = this.props;
        let newFilter = tableFilter;
        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                newFilter.skip = 0;
            }

            if (crmValidator.isEmptyValue(limit)) {
                newFilter.limit = 10;
            }
            newFilter.page = skip;
            this.setState({
                rowsPerPage: limit,
            });
            skip = parseInt(limit) * parseInt(skip);

            newFilter.skip = skip;
            newFilter.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                newFilter.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                newFilter.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                newFilter.keyword = keyword;
            }

            if (crmValidator.isEmptyValue(keyword)) {
                newFilter.keyword = "";
            }
        } else {
            newFilter = this.state.newFilter;
        }

        dispatch(ocrReportActions.getOcrReport(newFilter));

        this.setState({
            isLoading: true,
            filter: newFilter,
        });
    }

    exportCSV() {
        const { tableFilter, dispatch } = this.props;
        dispatch(ocrReportActions.getOcrReportCSV(tableFilter));
    }

    exportDailyCSV(index) {
        const { tableData, dispatch } = this.props;
        let date =  moment(tableData[index]._id).format("YYYYMMDD")
        dispatch(ocrReportActions.getOcrReportDailyCSV(date));
    }

    render() {
        const { tableData, tableTotal, tableFilter, tableKey  } = this.props;
        const { rowsPerPage } = this.state
        const columns = [
            {
                name: "_id",
                label: "Date",
                options: {
                    filter: false,
                    customBodyRender: (value) => moment(value).format("DD-MMM-YY")
                },
            },
            {
                name: "totalRequest",
                label: "Total Request",
                options: {
                    filter: false,
                    customBodyRender: (value) => plugins.numberFormat(value)
                }
            },
            {
                name: "files", label: "Files", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        //   const rowData = this.getRowData(tableMeta.rowIndex);
                        return (
                            <div className={style.actionDiv}><center>
                                <Button
                                     onClick={() => this.exportDailyCSV(tableMeta.rowIndex)}
                                    style={{
                                        marginTop: "25px",
                                        backgroundColor: "#84B0F3",
                                        float: "left"
                                    }}
                                    color="primary"
                                >
                                    DOWNLOAD <CloudDownload style={{
                                        marginLeft: "5px"
                                    }}/>
                                </Button>
                            </center>
                            </div>
                        )
                    }
                }
            },
        ];

        const options = {
            serverSide: true,
            count: tableTotal ? tableTotal : 0,
            page: tableFilter.page,
            rowsPerPage: rowsPerPage,
            fixedHeader: true,
            selectableRows: "none",
            print: false,
            customToolbar: () => {
                return (
                    <>
                        {plugins.isHasPermission("report[ocr]", "export") && plugins.isHasPackageFeatures('export') && (
                            <Tooltip title="Export CSV">
                                <IconButton id="button-earnSummaryTable-exportCSV" className="" onClick={this.exportCSV.bind(this)}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            search: false,
            onTableChange: (action, tableState) => {
                let skip,
                    limit,
                    sortBy,
                    sortDir,
                    keyword

                if (tableState.page !== null) {
                    skip = tableState.page;
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage;
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name;
                }
                if (tableState.announceText !== null) {
                    sortDir =
                        tableState.announceText.search("descending") !== -1
                            ? "desc"
                            : "asc";
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }

                let actionList = ["changePage", "sort", "changeRowsPerPage"];
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir,keyword);
                }
            },
        };

        return (
            <div id='tableEarnSummaryReport'>
                <CSVLink
                    headers={[
                        { label: "Date", key: "date" },
                        { label: "Total Request", key: "totalRequest" },
                        { label: "files", key: "files" },
                    ]}
                    data={this.state.csvData}
                    filename={this.state.csvFileName}
                    className="btn btn-primary"
                    target="_blank"
                    ref={this.csvLink}
                    style={{ display: "none" }}
                />

                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        data={tableData ? tableData : []}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { tableData, tableFilter, tableKey, tableTotal } = state.ocrReport;

    return {
        tableData, tableFilter, tableKey, tableTotal
    };
}

export default connect(mapStateToProps)(
    withStyles(style)(TableOcrSummaryReport)
);

import { reset } from 'redux-form'
import lodash from 'lodash';

import { treemapConstants } from '../constants';
import { treemapServices } from '../services';
import { loader, alert as customAlert } from 'helpers';

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

// const getErrorMessage = (key) => {
//    switch (key) {
//       default: return "กรุณาลองใหม่อีกครั้ง"
//    }
// }

export const treemapActions = {
   // editShipment,
   
   // getaddresslabelDetail,
   clearWinnerFilter,

   getTreeMap,
   getTableDatas,
   visualizationData,
   getListCampaigns,
   rfmAddedCampaign,
   getListRFMCampaigns
};

function getTreeMap(params) {
    return dispatch => {
        treemapServices.getTreeMap(params)
          .then(
             response => {
                
                if (response.status) {
                   if (lodash.has(response, 'data')) {
                      dispatch(success(response.data));
                   }
                } else {
                   if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                }
             },
             error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
             }
          ).then(() => {
             // loader(dispatch, false)
          })
    };
 
    function success(data) {
       return { type: treemapConstants.GET_TREEMAP_LIST_SUCCESS, data }
    }
 }

function clearWinnerFilter() {
   return dispatch => {
      let tableFilter = {
         page: 0,
         skip: 0,
         limit: 10,
         sortBy: "createdAt",
         sortDir: "desc",
         keyword: "",
         search: {}
      };
      dispatch({ type: treemapConstants.SET_FILTER, tableFilter });
      dispatch(reset('formFilterWinner'))
   }
}

function getTableDatas(params, tableReset = false) {
   return dispatch => {
      dispatch({ type: treemapConstants.SHOW_LOADER_TREEMAP })
      
      // winnerServices.getListsWinner(params) 
      treemapServices.getTableDatas(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: treemapConstants.TABLE_GET_LIST_DATAS, data: response.data.data, total: response.data.total, tableFilter: params });
                  if (tableReset) {
                     dispatch({ type: treemapConstants.TABLE_GET_LIST_DATAS_RESET });
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: treemapConstants.HIDE_LOADER_TREEMAP })
         })
   };

   // function success(tmp) { return { type: shipmentConstants.TABLE_GET_LIST_DATAS, tmp } }
}

//visualizationData
function visualizationData(params) {
   return dispatch => {
       loader(dispatch, true)
       treemapServices.visualizationData(params)
           .then(
               response => {
                   if (response.status) {
                       dispatch(success(response.data[0]));
                   } else {
                       if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                   }
               },
               error => {
                   customAlert(dispatch, 'error', i18n.t(defaultFailedText))
               }
           ).then(() => {
               loader(dispatch, false)
           })
   };

   function success(data) { return { type: treemapConstants.OPEN_FORM_VISUALIZATION, data: data }}
}
function getListCampaigns(params) {
   return dispatch => {
      treemapServices.getListCampaigns(params)
           .then(
               response => {
                   if (response.status) {
                       if (lodash.has(response, 'data')) {
                           dispatch(success(response.data));
                       }
                   } else {
                       if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                   }
               },
               error => {
                   customAlert(dispatch, 'error', i18n.t(defaultFailedText))
               }
           ).then(() => {
               // loader(dispatch, false)
           })
   };
   function success(data) {
      return { type: treemapConstants.GET_CAMPAIGN_LIST_SUCCESS, data }
  }
}

//getListRFMCampaigns
function getListRFMCampaigns(params) {
   return dispatch => {
      treemapServices.getListRFMCampaigns(params)
           .then(
               response => {
                   if (response.status) {
                       if (lodash.has(response, 'data')) {
                        dispatch({ type: treemapConstants.SET_SEGMENT_IN_OPEN_FORM_CAMPAIGNS, data:params});
                           dispatch(success(response.data));
                       }
                   } else {
                       if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                   }
               },
               error => {
                   customAlert(dispatch, 'error', i18n.t(defaultFailedText))
               }
           ).then(() => {
               // loader(dispatch, false)
           })
   };
   function success(data) {
      return { type: treemapConstants.OPEN_FORM_CAMPAIGNS, data }
  }
}

//rfmAddedCampaign
function rfmAddedCampaign(params) {
   // let data = []
   //  data ={
   //     tables : params
   //  }
   return dispatch => {
      loader(dispatch, true)
      treemapServices.rfmAddedCampaign(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({
                     type: treemapConstants.ADDED_CAMPAIGN_SUCCESS
                  });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.configdatafiltering_was_created'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}



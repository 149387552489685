import React, { useEffect, useRef } from "react";
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import PointAdjustmentReport from "./PointAdjustmentReport.jsx";
import PointAdjustmentForm from "views/PointAdjustment/PointAdjustmentForm.jsx";
const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

function PointAdjustmentContainer({ ...props }) {
    const { formOpen, formInitialValue } = props
    const pointAdjustmentContainerRef = useRef(null)
    useEffect(() => pointAdjustmentContainerRef.current.scrollIntoView(), [props.formOpen]);
    return (
        <GridContainer> 
            <GridItem xs={12}>
                {formOpen &&
                    <PointAdjustmentForm initialValues={formInitialValue} />
                }
                <Card style={{ display: (formOpen ? "none" : "block") }}>
                    <div ref={pointAdjustmentContainerRef}></div>
                    <CardBody>
                        <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>POINT ADJUSTMENT</h4>
                        <GridContainer>
                            <GridItem xs={12}>
                                <PointAdjustmentReport /*initialValues={{ startDate: moment().startOf('day'), endDate: moment().set('hour',23).set('minute',59).set('second',59) }}*/ />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}
function mapStateToProps(state) {
    const { formInitialValue, formOpen } = state.point_adjustment;
    return {
      formInitialValue, formOpen
    };
  }  
export default connect(mapStateToProps)(withStyles(style)(PointAdjustmentContainer));
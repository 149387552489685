export const upload_3rd_partyConstants = {
   OPEN_ADD: 'OPEN_ADD_UPLOAD_3RD_PARTY_REWARD_CODE',
   OPEN_EDIT: 'OPEN_EDIT_3RD_PARTY_REWARD_CODE',
   OPEN_FORM_UPLOAD: 'OPEN_FORM_UPLOAD_3RD_PARTY_REWARD_CODE',
   CLOSE_FORM: 'CLOSE_FORM_3RD_PARTY_REWARD_CODE',
   CLOSE_FORM_UPLOAD: 'CLOSE_FORM_UPLOAD_3RD_PARTY_REWARD_CODE',
   ADD_NEW_SUCCESS: 'ADD_NEW_3RD_PARTY_REWARD_CODE_SUCCESS',
   EDIT_SUCCESS: 'EDIT_3RD_PARTY_REWARD_CODE_SUCCESS',
   DELETE_SUCCESS: 'DELETE_3RD_PARTY_REWARD_CODE_SUCCESS',
   GET_DATA_SUCCESS: 'GET_3RD_PARTY_REWARD_CODE_DATA_SUCCESS',
   TABLE_GET_LIST: 'TABLE_GET_LIST_3RD_PARTY_REWARD_CODES',
   TABLE_RELOAD_SUCCESS: 'TABLE_3RD_PARTY_REWARD_CODE_RELOAD_SUCCESS',
   TABLE_RESET_SUCCESS: 'TABLE_3RD_PARTY_REWARD_CODE_RESET_SUCCESS',
   SET_FORM_PREVIEW_IMAGE: '3RD_PARTY_REWARD_CODE_SET_FORM_PREVIEW_IMAGE',
   CLEAR_PAGE_STATE: '3RD_PARTY_REWARD_CODE_CLEAR_PAGE_STATE',

   IMPORT_CODE_SUCCESS: 'IMPORT_CODE_SUCCESS',
   IMPORT_CODE_FAIL: 'IMPORT_CODE_FAIL',
   GET_LIST_MERCHANT: 'GET_LIST_MERCHANT_FOR_UPLOADS',

   SHOW_TABLE_LOADER: 'SHOW_TRDPARTY_TABLE_LOADER',
   HIDE_TABLE_LOADER: 'HIDE_TRDPARTY_TABLE_LOADER'
};

import React from 'react'
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { crmValidator, plugins, normalize } from 'helpers';
import lodash from 'lodash';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import Radio from '@material-ui/core/Radio'

import { pointActions } from 'actions';
import HelpOutline from '@material-ui/icons/HelpOutline'
import DialogExampleCalcPoint from './DialogExampleCalcPoint'

const FORM_NAME = 'point_expiration_setting'
const moduleName = 'point[point_expiration]'

var form_validate = {
    condition: {
        rules: {
            required: true,
        },
        messages: {
            required: 'Condition is required',
        },
        alert: "กรุณาเลือกเงื่อนไขวันหมดอายุ"
    }
}

const pageStyle = {
    ...modalStyle,
    hoverPointer: {
        "&:hover": {
            cursor: "pointer"
        }
    }
};

const muiRadio = ({ ...props }) => {
    const { input, disabled } = props
    return (
        <Radio
            onChange={input.onChange}
            name={input.name}
            id={input.name + "_" + input.value}
            disabled={disabled}
            {...input}
        />
    )
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class PointExpirationSetting extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(pointActions.getPointExpirationSetting())
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props;
        dispatch(pointActions.savePointExpirationSetting(values))
    }

    validateLimitTime = (value, allValues) => {
        const formCondition = lodash.get(allValues, 'condition')
        if (formCondition === "LIMIT") {
            if (value === "" || value === null || value === undefined) {
                return "Each point is valid for _ month(s) is required"
            } else if (value === 0 || value === "0") {
                return "Point valid month must be greater than 0"
            }
        }
    }

    validateReceivedPointFrom = (value, allValues) => {
        const formCondition = lodash.get(allValues, 'condition')
        if (formCondition === "LIMIT") {
            if (value === "" || value === null || value === undefined) {
                return "From Month/Year that point was received is required"
            }
        }
    }

    validateExpirationDate = (value, allValues) => {
        const formCondition = lodash.get(allValues, 'condition')
        if (formCondition === "SPECIFY_DATE") {
            if (value === "" || value === null || value === undefined) {
                return "Expiration Date is required"
            }
        }
    }

    render() {
        const { classes, condition, handleSubmit, formError } = this.props
        let disableUnderLimit = (condition !== "LIMIT")
        let disableUnderSpicify = (condition !== "SPECIFY_DATE")

        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader>
                            <h3
                                className={classes.cardIconTitle}
                                style={{ marginLeft: "15px" }}
                            >
                                <b>Point Expiration</b>
                            </h3>
                        </CardHeader>
                        <CardBody>
                            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)} style={{ paddingLeft: "50px" }}>
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="condition" component={muiRadio} type="radio" value="NO_LIMIT" />
                                                <span style={{ color: "#111" }}>No Expiration</span>
                                            </label>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="condition" component={muiRadio} type="radio" value="LIMIT" />
                                                <span style={{ color: "#111" }}>Gradually Expires (depend on month/year received points)</span>
                                            </label>
                                            <div style={{ position: "relative", width: "20px", height: "20px", display: "inline-block" }}>
                                                <HelpOutline
                                                    onClick={() => {
                                                        const { dispatch } = this.props
                                                        dispatch({ type: "OPEN_EXAMPLE_POINT_EXPIRATION" })
                                                    }}
                                                    className={classes.hoverPointer}
                                                    style={{
                                                        position: "absolute",
                                                        top: "3px",
                                                        left: "10px",
                                                        color: "#777"
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ paddingLeft: "80px", color: (disableUnderLimit ? "#aaa" : "#111"), fontWeight: "400" }}>
                                            <div>
                                                <span>Each point is valid for
                                                    <Field
                                                        name="limitTime"
                                                        component={CustomInput}
                                                        type="text"
                                                        disabled={disableUnderLimit}
                                                        id="input_limitTime"
                                                        validate={[this.validateLimitTime]}
                                                        normalize={(value) => {
                                                            if (value) {
                                                                if (value === 0 || value === "0") {
                                                                    return ""
                                                                } else {
                                                                    return normalize.onlyNumber(value)
                                                                }
                                                            }else {
                                                                return ""
                                                            }
                                                        }}
                                                        formControlProps={{ style: { paddingTop: "0px", marginLeft: "20px", marginRight: "20px", width: "200px" } }}
                                                    />
                                                months</span>
                                            </div>
                                            <div>
                                                <div>
                                                    <span>From</span>
                                                    <label>
                                                        <Field name="receivedPointFrom" component={muiRadio} type="radio" value="MONTH" disabled={disableUnderLimit} validate={[this.validateReceivedPointFrom]} />
                                                        <span style={{ color: (disableUnderLimit ? "#aaa" : "#111") }}>Month that point was received</span>
                                                    </label>
                                                </div>
                                                <div style={{ paddingLeft: "32px" }}>
                                                    <label>
                                                        <Field name="receivedPointFrom" component={muiRadio} type="radio" value="YEAR" disabled={disableUnderLimit} validate={[this.validateReceivedPointFrom]} />
                                                        <span style={{ color: (disableUnderLimit ? "#aaa" : "#111") }}>Year that point was received</span>
                                                    </label>
                                                </div>
                                                {lodash.get(formError, 'receivedPointFrom') &&
                                                    <p id="receivedPointFrom_error" style={{
                                                        color: "#f44336",
                                                        fontSize: "0.75rem"
                                                    }}>{lodash.get(formError, 'receivedPointFrom')}</p>
                                                }
                                            </div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div>
                                            <label>
                                                <Field name="condition" component={muiRadio} type="radio" value="SPECIFY_DATE" />
                                                <span style={{ color: "#111" }}>Set an expiration date (All point will expire on the specified date)</span>
                                            </label>
                                        </div>
                                        <div style={{ paddingLeft: "80px", color: (disableUnderSpicify ? "#aaa" : "#111"), fontWeight: "400" }}>
                                            <span>
                                                Expiration Date
                                                <Field
                                                    name="specifyExpirationDate"
                                                    component={CustomDatePicker}
                                                    type="text"
                                                    id="input_specifyExpirationDate"
                                                    disabled={disableUnderSpicify}
                                                    validate={[this.validateExpirationDate]}
                                                    formControlProps={{ style: { paddingTop: "0px", marginLeft: "20px", marginRight: "20px" } }}
                                                />
                                            </span>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: "50px", marginBottom: "50px" }}>
                                        {plugins.isHasPermission(moduleName, 'update') &&
                                            <Button
                                                id='btn_submit_form'
                                                type="submit"
                                                style={{ marginRight: "10px" }}
                                                color="primary">
                                                Submit
                                        </Button>
                                        }

                                        <Button
                                            id='btn_cancel_form'
                                            type="button"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => {
                                                const { dispatch } = this.props
                                                dispatch(reset(FORM_NAME));
                                                dispatch(pointActions.getPointExpirationSetting())
                                            }}
                                            color="white">
                                            Cancel
                                        </Button>

                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
                <DialogExampleCalcPoint />
            </GridContainer>

        )
    }
}

function mapStateToProps(state) {
    const { setting } = state.point
    return { setting }
}

const connectedComponent = connect(mapStateToProps)(PointExpirationSetting);

let PointExpirationSettingForm = reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(pageStyle)(connectedComponent))

const selector = formValueSelector(FORM_NAME)

PointExpirationSettingForm = connect(state => {
    const condition = selector(state, 'condition')
    const formError = lodash.get(state, 'form.point_expiration_setting.syncErrors')
    return {
        initialValues: lodash.get(state, 'point.setting'),
        formError,
        condition
    }
})(PointExpirationSettingForm)

export default PointExpirationSettingForm
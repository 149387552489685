import { configConstants } from '../constants';
import axios from 'axios';
import moment from 'moment';
import { history, crmValidator, setHeaders } from 'helpers';
import objectToFormData from 'object-to-formdata';

export const bannerServices = {
  getListsBanner,
  addBanner,
  updateBanner,
  deleteBanner
};

const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ"

function getListsBanner(params) {
  var tmp = axios.get(configConstants.API_BACKEND_CRM + `/banners?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    })
    .catch(e => {
      return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
    });

  return tmp;
}

function addBanner(values) {

  let formData = objectToFormData(values);
  // formData.append('file', values.bannerImage);

  var tmp = axios.post(configConstants.API_BACKEND_CRM + '/banners', formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    });
  return tmp;
}

function updateBanner(values) {
  var bannerId = values._id;
  let formData = new FormData();
  formData.set("name", values.name);
  formData.set("title", values.title);
  formData.set("type", values.type);
  formData.set("startDate", moment(values.startDate).format(dbDateFormat));
  if (values.endDate) {
    formData.set("endDate", moment(values.endDate).format(dbDateFormat));
  }
  if (values.file) {
    formData.append('file', values.file);
  }
  formData.set("status", values.status);
  if (values.priority !== undefined && values.priority !== null && values.priority !== "") {
    formData.set("priority", values.priority);
  }
  if (!crmValidator.isEmptyValue(values.tagList)) {
    formData.set("tagList", values.tagList);
  }

  if (!crmValidator.isEmptyValue(values.memberLevel)) {
    formData.set("memberLevel", values.memberLevel);
  }

  if (!crmValidator.isEmptyValue(values.url)) {
    formData.set("url", values.url);
  }

  if (!crmValidator.isEmptyValue(values.detail)) {
    formData.set("detail", btoa(encodeURIComponent(values.detail)));
  }

  formData.set("showLinkOut", values.showLinkOut)
  if (!crmValidator.isEmptyValue(values.linkOutBtnName)) {
    formData.set("linkOutBtnName", values.linkOutBtnName)
  }
  if (!crmValidator.isEmptyValue(values.linkOutURL)) {
    formData.set("linkOutURL", values.linkOutURL)
  }
  // if (!crmValidator.isEmptyValue(values.categoryId)) {
  //   formData.set("categoryId", values.categoryId);
  // }
  //categoryId
  if(values.categoryId){
    var categoryIds = values.categoryId;
    if(Array.isArray(categoryIds)){
        for(let i=0 ; i < categoryIds.length ; i++){
            formData.append('categoryId[]', categoryIds[i]);
        }
    }else{
        formData.append('categoryId[]', "");
    }
  }

  var tmp = axios.put(configConstants.API_BACKEND_CRM + `/banners/${bannerId}`, formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
    .then(response => {
      if (response.data.statusCode.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    });
  return tmp;
}

function deleteBanner(params) {
  var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/banners/${params._id}/force`, { headers: setHeaders() })
    .then(response => {
      if (response.status.toString() === "200") {
        return { status: true, data: response.data.data, message: response.data.message };
      } else if (response.data.message === "UNAUTHORIZE") {
        history.push('/auth/login')
        return { status: false, data: {}, message: response.data.message, unAlert: true };
      } else {
        return { status: false, data: {}, message: response.data.message };
      }
    });

  return tmp;
}

export const dataFilteringResultConstants = {
    CLEAR_PAGE_STATE: 'DATAFILTERING_CLEAR_PAGE_STATE',

    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
    GET_DATAFILTERING_RESULT_SUCCESS: 'GET_DATAFILTERING_RESULT_SUCCESS',

    SHOW_LOADER: 'SHOW_TABLE_DATAFILTERING_RESULT_LOADER_SPINNER',
    HIDE_LOADER: 'HIDE_TABLE_DATAFILTERING_RESULT_LOADER_SPINNER',

    OPEN_FORM_CONDITION: 'OPEN_FORM_CONDITION',
    CLOSE_FORM_CONDITION: 'CLOSE_FORM_CONDITION',

    OPEN_FORM_ASSIGN_CAMPAIGN: 'OPEN_FORM_ASSIGN_CAMPAIGN',
    CLOSE_FORM_ASSIGN_CAMPAIGN: 'CLOSE_FORM_ASSIGN_CAMPAIGN',
    ASSIGN_CAMPAIGN_SUCCESS: 'ASSIGN_CAMPAIGN_SUCCESS',

    OPEN_FORM_ASSIGN_TAG: 'OPEN_FORM_ASSIGN_TAG',
    CLOSE_FORM_ASSIGN_TAG: 'CLOSE_FORM_ASSIGN_TAG',
    ASSIGN_TAG_SUCCESS: 'ASSIGN_TAG_SUCCESS',

    OPEN_FORM_UNASSIGN: 'OPEN_FORM_UNASSIGN',
    CLOSE_FORM_UNASSIGN: 'CLOSE_FORM_UNASSIGN',
    UNASSIGN_CAMPAIGN_SUCCESS: 'UNASSIGN_CAMPAIGN_SUCCESS',
    UNASSIGN_TAG_SUCCESS: 'UNASSIGN_TAG_SUCCESS',

    DELETE_DATAFILTERRING_RESULT_SUCCESS: 'DELETE_DATAFILTERRING_RESULT_SUCCESS',

    OPEN_FORM_VISUALIZATION: 'OPEN_FORM_VISUALIZATION',
    CLOSE_FORM_VISUALIZATION: 'CLOSE_FORM_VISUALIZATION',

    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

}

import { tagConstants } from '../constants';
import lodash from 'lodash'

const initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: new Date(),
    tableReload: false,
    tableReset: false,
    showLoader: false,
    tableDatas: {
        tags: [],
        total: 0
    },
    formImportOpen: false,
    formImportInitialValue: {},
    showImportResult: false,
    importResult: {}
}

export function tag(state = initialState, action) {
    switch (action.type) {
        case tagConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case tagConstants.OPEN_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue
            }
        case tagConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formMode: 'add',
                formInitialValue: {}
            }
        case tagConstants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case tagConstants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case tagConstants.GET_TAG_SUCCESS:
            return {
                ...state,
                tableDatas: {
                    tags: lodash.has(action, 'tmp.tags')?action.tmp.tags:[],
                    total: lodash.has(action, 'tmp.total')?action.tmp.total:0
                },
                formInitialValue: {}
            }
        case tagConstants.ADD_NEW_TAG_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                formInitialValue: {}
            }
        case tagConstants.EDIT_TAG_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case tagConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReset: false,
                tableReload: false
            }
        case tagConstants.DELETE_TAG_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case tagConstants.OPEN_TAG_FORM_IMPORT:
            return {
                ...state,
                formImportOpen: true,
                formImportInitialValue: (action.formImportInitialValue? action.formImportInitialValue:{})
            }
        case tagConstants.CLOSE_TAG_FORM_IMPORT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {}
            }
        case tagConstants.SHOW_TAG_IMPORT_MEMBER_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: true,
                importResult: action.data
            }
        case tagConstants.HIDE_TAG_IMPORT_MEMBER_RESULT:
            return {
                ...state,
                showImportResult: false,
                importResult: {}
            }
        default:
            return state
    }
}
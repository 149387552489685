import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import lodash from 'lodash'

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import ColorSelect from "components/CustomSelect/ColorSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import { dataFilteringResultConstants } from '../../../constants';
// import { dataFilteringTemplateAction } from 'actions';

import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const FORM_NAME = 'FORM_DATAFILTERING_CONDITION'


class FormDataFilteringCondition extends React.Component {

    render() {
        const { classes, formConditionOpen, FORM_DATAFILTERING_CONDITION , initialValues } = this.props
        let readOnly = true;

        const conditions = FORM_DATAFILTERING_CONDITION.values.conditions

        var rows = [];

        for (const property in conditions) {
            rows.push(conditions[property]);
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formConditionOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="lg"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>Report Condition</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <React.Fragment>
                                {rows.map((filtering, index) =>
                                    <React.Fragment key={index}>
                                        {(index > 0) &&
                                            <div style={{
                                                padding: "20px",
                                                width: "70px",
                                                background: "linear-gradient(#000, #000) no-repeat center/2px 100%",
                                                textAlign: "center"
                                            }}>
                                                <b style={{ backgroundColor: "white" }}>AND</b>
                                            </div>
                                        }
                                        {filtering.conditions.map((condition, conditionindex) =>
                                            <Table className={classes.table} >
                                                <TableHead>
                                                    <TableRow className={classes.tdBordered}>

                                                        <TableCell
                                                            key="condition_header"
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "90px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                name={`conditions[${index}].conditions[${conditionindex}].exclude`}
                                                                component={CustomSelect}
                                                                type="text"
                                                                labelText={""}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                outlined
                                                                disabled
                                                                value={condition.exclude}
                                                                optionsList={[
                                                                    {
                                                                        value: false,
                                                                        label: "include",
                                                                    },
                                                                    {
                                                                        value: true,
                                                                        label: "exclude"
                                                                    }
                                                                ]}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "100px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                name={`conditions[${index}].conditions[${conditionindex}].table`}
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "100px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                name={`conditions[${index}].conditions[${conditionindex}].field`}
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "80px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                name={`conditions[${index}].conditions[${conditionindex}].compareOperator`}
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "50px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                name={`conditions[${index}].conditions[${conditionindex}].value`}
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                            />

                                                        </TableCell>
                                                        <TableCell style={{
                                                            paddingRight: "1px",
                                                            paddingLeft: "10px",
                                                            minWidth: "50px",
                                                            whiteSpace: "nowrap"
                                                        }}>
                                                            <Field
                                                                name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                                                                component={CustomInput}
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                disabled
                                                                type="text"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                </TableBody>
                                            </Table>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: dataFilteringResultConstants.CLOSE_FORM_CONDITION })
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog >
        )
    }
}

function mapStateToProps(state) {
    const { formConditionOpen } = state.dataFilteringResult;
    const { FORM_DATAFILTERING_CONDITION } = state.form
    return {
        formConditionOpen, FORM_DATAFILTERING_CONDITION
    };
}

const connectedComponent = connect(mapStateToProps)(FormDataFilteringCondition);

export default reduxForm({
    form: FORM_NAME,
    // validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))
import React from "react";
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import moment from 'moment';
// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import ShipmentReport from "views/Shipment/ShipmentReport.jsx";

const style = {
    customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
};

function ShipmentList({ ...props }) {
  return (
    <GridContainer>
        <GridItem xs={12}>
          <Card>
              <CardBody>
              <h4 style={{fontWeight:'500', fontSize:'1.25rem'}}>SHIPMENT LIST</h4> 
                <GridContainer>
                  <GridItem xs={12}>
                  <ShipmentReport initialValues={{
                        startRedemptionDate: moment().startOf('day'),
                        endRedemptionDate: moment().set('hour',23).set('minute',59).set('second',59),
                        startShippingDate: moment().startOf('day'),
                        endShippingDate: moment().set('hour',23).set('minute',59).set('second',59)
                  }}/>
                  </GridItem>
                </GridContainer>
              </CardBody>
          </Card>
        </GridItem>
    </GridContainer>
  )
}

function mapStateToProps(state) {
  const { tableReset } = state.shipment;
  return {
    tableReset
  };
}
const connectShipmentContainer = connect(mapStateToProps)(ShipmentList);

export default withStyles(style)(connectShipmentContainer);
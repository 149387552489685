import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import lodash from 'lodash'
import { CSVLink } from "react-csv";
import moment from 'moment'

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputUpload from 'components/CustomUpload/InputUpload.jsx';
import CustomSelect from "components/CustomSelect/CustomSelect";
import Button from "components/CustomButtons/Button.jsx";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { importAction } from 'actions';
import { crmValidator, plugins } from 'helpers';
import FileSaver from 'file-saver';

const FORM_NAME = 'FORM_IMPORT_FILE'
const form_validate = {
    "importType": {
        rules: {
            required: true,
        },
        messages: {
            required: 'Required',
        }
    },
    "file": {
        rules: {
            required: true,
            fileSize: 2097152
        },
        messages: {
            required: 'Please select file',
            fileSize: 'Selected File size must be less than 2MB'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const validateImportType = (value) => {
    let allowType = ['purchase_transaction','create_product', 'member']
    if(allowType.indexOf(value) === -1){
        return "Not allowed Import type"
    }
}

const acceptFileType = (importType) => {
    if((importType === 'purchase_transaction') || (importType === 'create_product')){
        return ".csv"
    }else if(importType === 'member'){
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    }else{
        return ".csv"
    }
}

class FormImportFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            csvHeaders: [],
            csvData: [],
            csvFileName: ""
        }
        this.csvLink = React.createRef()
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props
        var reqParams = {
            "importType": values.importType,
            "file": values.file,
            "promotionId" : values.promotionId
        }
        dispatch({ type: "CLOSE_FORM_IMPORT_FILE" })
        dispatch(importAction.importFile(reqParams));
    }

    genTemplatePurchaseTransaction(fileName) {
        const headers = [{
            label: 'Purchase date and time',
            key: 'purchaseDatetime'
        }, {
            label: 'Shop ID',
            key: 'shopId'
        }, {
            label: 'Transaction ID',
            key: 'transactionId'
        }, {
            label: 'External Member ID',
            key: 'externalMemberId'
        }, {
            label: 'Firstname',
            key: 'firstName'
        }, {
            label: 'Lastname',
            key: 'lastName'
        }, {
            label: 'Mobile number',
            key: 'phoneNumber'
        }, {
            label: 'SKU ID',
            key: 'skuId'
        }, {
            label: 'SKU Name',
            key: 'skuName'
        }, {
            label: 'Quantity',
            key: 'qty'
        }, {
            label: 'Total Spending',
            key: 'totalSpending'
        }, {
            label: 'Payment Channel',
            key: 'paymentChannel'
        }, {
            label: 'Void date and time',
            key: 'voidDateTime'
        }, {
            label: 'Remark',
            key: 'remark'
        }];

        let data = [{
            purchaseDatetime: moment().add(-1, 'days').format('DD/MM/YYYY HH:mm:ss'),
            shopId: "Branch 123",
            transactionId: '123456789',
            externalMemberId: 'AA123456',
            firstName: "Peter",
            lastName: "Parker",
            phoneNumber: "0812345678",
            skuId: "NP262|SD368|FD556",
            skuName: "Espresso|Chocolate|Thai Tea",
            qty: "3|2|1",
            totalSpending: "550.25",
            paymentChannel: "Cash",
            voidDateTime: "",
            remark: ""
        }, {
            purchaseDatetime: moment().add(-1, 'days').format('DD/MM/YYYY HH:mm:ss'),
            shopId: "Branch 123",
            transactionId: '123456789',
            externalMemberId: 'AA123456',
            firstName: "Peter",
            lastName: "Parker",
            phoneNumber: "0812345678",
            skuId: "NP262|SD368|FD556",
            skuName: "Espresso|Chocolate|Thai Tea",
            qty: "3|2|1",
            totalSpending: "550.25",
            paymentChannel: "Cash",
            voidDateTime: moment().format('DD/MM/YYYY HH:mm:ss'),
            remark: ""
        }]

        this.setState({ csvHeaders: headers, csvData: data, csvFileName: fileName }, () => {
            this.csvLink.current.link.click()
        })
    }

    genTemplateProduct(fileName){

        const headers = [{
            label: 'SKU ID',
            key: 'skuId'
        },{
            label: 'Prefix',
            key: 'prefix'
        },{
            label: 'Product Name',
            key: 'name'
        },{
            label: 'Category',
            key: 'category'
        }]

        const data = [{
            skuId: "SKU123456",
            prefix: "A1",
            name: "Espresso Small Cap",
            category: "category1 | category2 | category3"
        }]

        this.setState({ csvHeaders: headers, csvData: data, csvFileName: fileName }, () => {
            this.csvLink.current.link.click()
        })
    }

    genTemplateMember(){
        FileSaver.saveAs(
              "/assets/file/Template Import Member.xlsx",
              "Template Import Member.xlsx"
        );
    }

    downloadTemplate() {
        const { FORM_IMPORT_FILE } = this.props
        let templateType = lodash.get(FORM_IMPORT_FILE, 'values.importType')

        let fileName = `Template_${templateType}.csv`
        if (templateType === 'purchase_transaction') {
            this.genTemplatePurchaseTransaction(fileName)
        }else if(templateType === 'create_product'){
            this.genTemplateProduct(fileName)
        }else if(templateType === 'member'){
            this.genTemplateMember()
        }
    }

    closeForm() {
        const { dispatch } = this.props
        dispatch({ type: "CLOSE_FORM_IMPORT_FILE" })
    }

    render() {
        const { handleSubmit, formOpen, classes, FORM_IMPORT_FILE } = this.props
        let templateType = lodash.get(FORM_IMPORT_FILE, 'values.importType')
        let optionsLists = []
        if(plugins.isHasPermission("import[purchase_transaction]", 'create')){
            optionsLists.push({
                value: "purchase_transaction",
                label: "Purchase Transaction",
                id: "select_value_purchase_transaction"
            })
        }
        if(plugins.isHasPermission("import[create_product]", 'create')){
            optionsLists.push({
                value: "create_product",
                label: "Create Product",
                id: "select_value_create_product"
            })
        }
        if(plugins.isHasPermission("import[member]", 'create')){
            optionsLists.push({
                value: "member",
                label: "Create/Update Member",
                id: "select_value_member"
            })
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{ position: "relative" }}
                >
                    <h4 className={classes.modalTitle}><b>Import File</b></h4>
                    <IconButton aria-label="close" id="btn_close_form_import" className={classes.closeButton} onClick={this.closeForm.bind(this)} style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px"
                    }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="importType"
                                    component={CustomSelect}
                                    type="text"
                                    id="select_importtype"
                                    labelText="Import Type"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={optionsLists}
                                    validate={[validateImportType]}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>

                                <Field
                                    name="promotionId"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"promotionId"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />

                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            {formOpen &&
                            <GridItem xs={12} sm={6} md={6}>
                                <div style={{ marginTop: '5px' }}>
                                    <Field
                                        name="file"
                                        component={InputUpload}
                                        type="file"
                                        label="File Import"
                                        accept={acceptFileType(templateType)}
                                        // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        id="btn_select_import_file"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </div>
                                <div style={{ fontSize: '12px', marginTop: "10px" }}>Maximum 5,000 rows</div>
                            </GridItem>
                            }
                            <GridItem xs={3} sm={3} md={3}>
                            {
                                (templateType === 'member') ?
                                        <Button
                                            type="button"
                                            color="success"
                                            style={{ display: "block", padding: "4px 30px" }}
                                            onClick={this.downloadTemplate.bind(this)}>
                                            <div>Download</div>
                                            <div>Template</div>
                                        </Button>
                                    :
                                    <>
                                        <Button
                                            type="button"
                                            color="success"
                                            style={{ display: "block", padding: "4px 30px" }}
                                            onClick={this.downloadTemplate.bind(this)}>
                                            <div>Download</div>
                                            <div>Template</div>
                                        </Button>
                                        <CSVLink
                                            headers={this.state.csvHeaders}
                                            data={this.state.csvData}
                                            filename={this.state.csvFileName}
                                            style={{ display: "none" }}
                                            ref={this.csvLink}
                                            id="btn_download_template"
                                            target="_blank"
                                        />
                                    </>
                            }
                            </GridItem>
                            <GridItem xs={3} sm={3} md={3}>
                                <Button type="submit" color="primary" id="btn_submit_import">
                                    Submit
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </DialogContent >
            </Dialog >
        )
    }
}


function mapStateToProps(state) {
    const { formOpen } = state.importReducer
    const { FORM_IMPORT_FILE } = state.form
    return {
        formOpen, FORM_IMPORT_FILE
    };
}

const connectedComponent = connect(mapStateToProps)(FormImportFile);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {

    }
})(withStyles({})(connectedComponent))
export const earnrulesConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_EARN_RULE_STATE',
    OPEN_ADD_NEW_RULES: 'OPEN_ADD_NEW_RULES',
    OPEN_EDIT_EARN_RULES: 'OPEN_EDIT_EARN_RULES',
    CLOSE_FORM_EARN_RULES: 'CLOSE_FORM_EARN_RULES',
    ADD_EARN_RULES_SUCCESS: 'ADD_EARN_RULES_SUCCESS',
    EDIT_EARN_RULES_SUCCESS: 'EDIT_EARN_RULES_SUCCESS',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    GET_EARN_RULES_DATA_SUCCESS: 'GET_EARN_RULES_DATA_SUCCESS',
    EARNRULES_FORM_PRODUCT_LIST_LOADER: 'EARNRULES_FORM_PRODUCT_LIST_LOADER',
    GET_PRODUCT_LIST_SUCCESS: 'GET_PRODUCT_LIST_SUCCESS',
    RULE_TYPECODE_SELECTED_PRODUCT_ITEM: 'RULE_TYPECODE_SELECTED_PRODUCT_ITEM',
    RULE_TYPECODE_SELECTED_MANY_PRODUCT_ITEM: 'RULE_TYPECODE_SELECTED_MANY_PRODUCT_ITEM',
    RULE_TYPECODE_DESELECTED_PRODUCT_ITEM: 'RULE_TYPECODE_DESELECTED_PRODUCT_ITEM',
    ON_EARNRULE_TYPE_CHANGE_TO_CODE: 'ON_EARNRULE_TYPE_CHANGE_TO_CODE',
    ON_EARNRULE_TYPE_CHANGE_TO_PRODUCT: 'ON_EARNRULE_TYPE_CHANGE_TO_PRODUCT',

    DELETE_EARN_RULES_SUCCESS: 'DELETE_EARN_RULES_SUCCESS',

    SHOW_TABLE_LOADER: 'SHOW_EARNRULE_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_EARNRULE_TABLE_LOADER',

    GET_MEMBER_LEVEL_LIST_SUCCESS: 'GET_MEMBER_LEVEL_LIST_SUCCESS',

    EARN_RULE_ADD_JOINED_PRODUCT: 'EARN_RULE_ADD_JOINED_PRODUCT',
    EARN_RULE_ADD_JOINED_PRODUCT_ROW: 'EARN_RULE_ADD_JOINED_PRODUCT_ROW',
    EARN_RULE_DELETE_JOINED_PRODUCT: 'EARN_RULE_DELETE_JOINED_PRODUCT',
    EARN_RULE_ADD_SINGLE_PRODUCT: 'EARN_RULE_ADD_SINGLE_PRODUCT',

    EARN_RULE_GET_REGION_LIST:"EARN_RULE_GET_REGION_LIST",
    EARN_RULE_GET_SHOP_LIST:"EARN_RULE_GET_SHOP_LIST",
    EARN_RULE_SELECT_SHOP:"EARN_RULE_SELECT_SHOP",
    EARN_RULE_UNSELECT_SHOP:"EARN_RULE_UNSELECT_SHOP",
    EARN_RULE_FILTER_SELECTED_SHOP_BY_REGION:"EARN_RULE_FILTER_SELECTED_SHOP_BY_REGION",
    EARN_RULE_SELECTED_REGION:"EARN_RULE_SELECTED_REGION",
    EARN_RULE_SELECT_ALL_SHOP_LIST:"EARN_RULE_SELECT_ALL_SHOP_LIST",
    EARN_RULE_UNSELECT_ALL_SHOP_LIST:"EARN_RULE_UNSELECT_ALL_SHOP_LIST",
    EARN_RULE_SEARCH_SHOP_LIST:"EARN_RULE_SEARCH_SHOP_LIST",
    EARN_RULE_SEARCH_SHOP_SELECTED:"EARN_RULE_SEARCH_SHOP_SELECTED",
    EARN_RULE_CLEAR_SHOP_LIST:"EARN_RULE_CLEAR_SHOP_LIST",
    GET_LUCKY_DRAW_REWARD_SET_SUCCESS:"GET_LUCKY_DRAW_REWARD_SET_SUCCESS",

    OPEN_EARN_RULE_FORM_IMPORT: 'OPEN_EARN_RULE_FORM_IMPORT',
    CLOSE_EARN_RULE_FORM_IMPORT: 'CLOSE_EARN_RULE_FORM_IMPORT',
    SHOW_EARN_RULE_IMPORT_PRODUCT_RESULT: 'SHOW_EARN_RULE_IMPORT_PRODUCT_RESULT',
    HIDE_EARN_RULE_IMPORT_PRODUCT_RESULT: 'HIDE_EARN_RULE_IMPORT_PRODUCT_RESULT'
}
import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableDataFilteringTemplateList from "views/DataFilteringTemplate/TableDataFilteringTemplateList"

import { dataFilteringTemplateAction } from "actions"

const pageStyle = {}

const datafiltering = [
    {
        "tableName": "USER",
        "fields": [
            {
                "fieldName": "oid",
                "displayName": "oidUser",
                "status": "show",
                "type": "string",
                "name": "oid",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "point",
                "displayName": "point",
                "status": "show",
                "type": "int",
                "name": "point",
                "operator": "=|>|>=|<|<=|<>|between"
            },
            {
                "fieldName": "taglist",
                "displayName": "taglist",
                "status": "hide",
                "type": "string",
                "name": "taglist",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "firstname",
                "displayName": "firstname",
                "status": "hide",
                "type": "string",
                "name": "firstname",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "lastname",
                "displayName": "lastname",
                "status": "hide",
                "type": "string",
                "name": "lastname",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "email",
                "displayName": "email",
                "status": "hide",
                "type": "string",
                "name": "email",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "phonenumber",
                "displayName": "phonenumber",
                "status": "hide",
                "type": "string",
                "name": "phonenumber",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            }
        ]
    },
    {
        "tableName": "EARNS",
        "fields": [
            {
                "fieldName": "oid",
                "displayName": "oidEarns",
                "status": "show",
                "type": "string",
                "name": "oid",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "productcode",
                "displayName": "productcode",
                "status": "hide",
                "type": "string",
                "name": "productcode",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "product_name",
                "displayName": "product_name",
                "status": "hide",
                "type": "string",
                "name": "product_name",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            },
            {
                "fieldName": "rulecode",
                "displayName": "rulecode",
                "status": "hide",
                "type": "string",
                "name": "rulecode",
                "operator": "=|contain|in|no_tin|with_in_the_last"
            }
        ]
    }
]

class DataFilteringTemplateContainer extends React.Component {

    componentWillMount() {
        const { dispatch } = this.props
        dispatch(dataFilteringTemplateAction.getTableConfig())
    }

    render() {
        return (
            <div>
                <Card>
                    <CardBody>
                        <CustomSweetAlert />
                        <GridContainer>
                            <GridItem xs={12}>
                                <TableDataFilteringTemplateList />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen } = state.dataFilteringTemplate;
    return {
        formOpen
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(DataFilteringTemplateContainer));
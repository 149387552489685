import { configConstants } from '../constants';
import axios from 'axios';
import lodash from 'lodash';

import {
    history,
    crmValidator
} from 'helpers';

export const dataFilteringTemplateServices = {
    getDataFilteringTemplateList,
    addNewDataFilteringTemplate,
    editDataFilteringTemplate,
    deleteDataFilteringTemplate,
    rerunDataFilteringTemplate,
    importMember,
    getTableConfig
};

function getDataFilteringTemplateList(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_DATAFILTERING + '/data-template/search', params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function addNewDataFilteringTemplate(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_BACKEND_CRM + '/tag', params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function editDataFilteringTemplate(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }

    

    var tmp = axios.put(configConstants.API_DATAFILTERING + `/data-filtering/condition/${params.conditionId}`, params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function deleteDataFilteringTemplate(params) {
    let req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    let tmp = axios.delete(configConstants.API_DATAFILTERING + `/data-filtering/condition/${params._id}`, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function rerunDataFilteringTemplate(params) {
    let req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    let tmp = axios.post(configConstants.API_DATAFILTERING + `/data-filtering/condition`, params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function importMember(params) {
    try {
        var req_headers = {
            'Content-Type': 'application/json',
            'Charset': 'utf-8',
            'Authorization': localStorage.getItem('token')
        }

        let formData = new FormData();

        if (!crmValidator.isEmptyValue(params)) {
            lodash.forEach(params, function (value, key) {
                if (key === "fileImport") {
                    formData.append('file', value[0]);
                } else {
                    formData.append(key, value)
                }
            })
        }

        var tmp = axios.post(configConstants.API_BACKEND_CRM + '/tag/import-member', formData, {
            headers: req_headers
        })
            .then(response => {
                if (response.data.statusCode.toString() === "200") {
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: response.data,
                        message: response.data.message
                    };
                }
            })
            .catch(error => {
                return {
                    status: false,
                    data: {
                        statusCode: 500
                    },
                    message: 'File not found, Please select file and try again.'
                };
            })
        return tmp;
    } catch (e) {
        return {
            status: false,
            data: {
                statusCode: 500
            },
            message: 'File not found, Please select file and try again.'
        };
    }
}


function getTableConfig(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    let tmp = axios.post(configConstants.API_DATAFILTERING + `/table-config/list`, params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

import { whitelistConstants } from '../constants';
import lodash from 'lodash'

const initialState = {
   formOpen: false,
   formMode: 'add',
   formInitialValue: {},
   tableKey: new Date(),
   tableReload: false,
   tableReset: false,
   showLoader: false,
   tableDatas: [],
   formImportOpen: false,
   formImportInitialValue: {},
   showImportResult: false,
   importResult: [],
   formSaveImportOpen: false,
   saveImportResult: [],
   tableFilter: {
    page: 0,
    skip: 0,
    limit: 10,
    sortBy: "createdAt",
    sortDir: "desc",
    keyword: "",
    search: {}
   },
};

export function whitelist(state = initialState, action) { 
    switch (action.type) {
        case whitelistConstants.CLEAR_PAGE_STATE:
            return initialState;
        case whitelistConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case whitelistConstants.OPEN_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue
            }
        case whitelistConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formMode: 'add',
                formInitialValue: {}
            }
        case whitelistConstants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case whitelistConstants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case whitelistConstants.GET_WHITELIST_SUCCESS:
            return {
                ...state,
                tableFilter: action.tableFilter,
                tableDatas: (action.data?action.data:[]),
                tableTotal: (action.total?action.total:0)
            }
        case whitelistConstants.ADD_NEW_WHITELIST_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                formInitialValue: {}
            }
        case whitelistConstants.EDIT_WHITELIST_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case whitelistConstants.TABLE_RELOAD_SUCCESS:
        case whitelistConstants.TABLE_RESET:
            return {
                ...state,
                tableReset: false,
                tableReload: false
            }
        case whitelistConstants.DELETE_WHITELIST_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case whitelistConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                whitelistContainer: {
                    ...state.whitelistContainer,
                    campaignList: action.data.campaigns
                }
            }
        case whitelistConstants.OPEN_FORM_UPLOAD_WHITELIST:
            return {
                ...state,
                formImportOpen: true,
                formImportInitialValue: (action.formImportInitialValue? action.formImportInitialValue:{})
            }
        case whitelistConstants.CLOSE_FORM_UPLOAD_WHITELIST:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {}
            }
        case whitelistConstants.SHOW_UPLOAD_WHITELIST_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: true,
                importResult: action.data
            }
        case whitelistConstants.HIDE_UPLOAD_WHITELIST_RESULT:
            return {
                ...state,
                showImportResult: false,
                importResult: {}
            }
        case whitelistConstants.OPEN_SAVE_UPLOAD_WHITELIST_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: false,
                importResult: [],
                formSaveImportOpen: true,
                saveImportResult: action.data
            }
        case whitelistConstants.CLOSE_SAVE_UPLOAD_WHITELIST_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: false,
                importResult: [],
                formSaveImportOpen: false,
                saveImportResult: [],
                tableReset: false,
                tableReload: true
            }
        default:
            return state
    }
 }
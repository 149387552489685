export const winnerConstants = {
    OPEN_FORM_EDIT: 'OPEN_EDIT_FORM_WINNER',
    CLOSE_FORM_WINNER: 'CLOSE_FORM_SHIPMENT',
    RELOAD_TABLE_WINNER_LIST: 'RELOAD_TABLE_WINNER_LIST',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
    TABLE_RESET: 'TABLE_RESET',
    TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',
    EDIT_WINNER_SUCCESS: 'EDIT_WINNER_SUCCESS',
    EDIT_WINNER_FAILURE: 'EDIT_WINNER_FAILURE',
    HIDE_ALERT: 'HIDE_ALERT',
    CLEAR_PAGE_STATE: 'WINNER_CLEAR_PAGE_STATE',
    TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_WINNERS',
    SHOW_LOADER_WINNER: 'SHOW_LOADER_WINNER',
    HIDE_LOADER_WINNER: 'HIDE_LOADER_WINNER',
    GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS: 'GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS',
    GET_SHIPPING_DETAIL: 'GET_SHIPPING_DETAIL',
    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
    TABLE_GET_LIST_DATAS_RESET: 'TABLE_GET_LIST_DATAS_RESET',
    SET_FILTER: 'TABLE_WINNER_SETFILTER',
    GET_DROPDOWN_REFERENCE_LIST_SUCCESS: "GET_DROPDOWN_REFERENCE_LIST_SUCCESS",
    GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS: "GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS",
    GET_DROPDOWN_REWARD_LIST_SUCCESS: "GET_DROPDOWN_REWARD_LIST_SUCCESS"
};

export const earnSpecialsConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_EARN_SPECIAL_STATE',
    OPEN_ADD_NEW_SPECIALS: 'OPEN_ADD_NEW_SPECIALS',
    OPEN_EDIT_EARN_SPECIALS: 'OPEN_EDIT_EARN_SPECIALS',
    CLOSE_FORM_EARN_SPECIALS: 'CLOSE_FORM_EARN_SPECIALS',
    ADD_EARN_SPECIALS_SUCCESS: 'ADD_EARN_SPECIALS_SUCCESS',
    EDIT_EARN_SPECIALS_SUCCESS: 'EDIT_EARN_SPECIALS_SUCCESS',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    GET_EARN_SPECIALS_DATA_SUCCESS: 'GET_EARN_SPECIALS_DATA_SUCCESS',
    EARNSPECIALS_FORM_PRODUCT_LIST_LOADER: 'EARNSPECIALS_FORM_PRODUCT_LIST_LOADER',
    GET_PRODUCT_LIST_SUCCESS: 'GET_PRODUCT_LIST_SUCCESS',
    SPECIAL_TYPECODE_SELECTED_PRODUCT_ITEM: 'SPECIAL_TYPECODE_SELECTED_PRODUCT_ITEM',
    SPECIAL_TYPECODE_SELECTED_MANY_PRODUCT_ITEM: 'SPECIAL_TYPECODE_SELECTED_MANY_PRODUCT_ITEM',
    SPECIAL_TYPECODE_DESELECTED_PRODUCT_ITEM: 'SPECIAL_TYPECODE_DESELECTED_PRODUCT_ITEM',
    ON_EARNSPECIAL_TYPE_CHANGE_TO_CODE: 'ON_EARNSPECIAL_TYPE_CHANGE_TO_CODE',
    ON_EARNSPECIAL_TYPE_CHANGE_TO_PRODUCT: 'ON_EARNSPECIAL_TYPE_CHANGE_TO_PRODUCT',

    DELETE_EARN_SPECIALS_SUCCESS: 'DELETE_EARN_SPECIALS_SUCCESS',

    SHOW_TABLE_LOADER: 'SHOW_EARNSPECIAL_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_EARNSPECIAL_TABLE_LOADER',

    GET_MEMBER_LEVEL_LIST_SUCCESS: 'GET_MEMBER_LEVEL_LIST_SUCCESS',

    EARN_SPECIAL_ADD_JOINED_PRODUCT: 'EARN_SPECIAL_ADD_JOINED_PRODUCT',
    EARN_SPECIAL_ADD_JOINED_PRODUCT_ROW: 'EARN_SPECIAL_ADD_JOINED_PRODUCT_ROW',
    EARN_SPECIAL_DELETE_JOINED_PRODUCT: 'EARN_SPECIAL_DELETE_JOINED_PRODUCT',
    EARN_SPECIAL_ADD_SINGLE_PRODUCT: 'EARN_SPECIAL_ADD_SINGLE_PRODUCT',

    EARN_SPECIAL_GET_REGION_LIST:"EARN_SPECIAL_GET_REGION_LIST",
    EARN_SPECIAL_GET_SHOP_LIST:"EARN_SPECIAL_GET_SHOP_LIST",
    EARN_SPECIAL_SELECT_SHOP:"EARN_SPECIAL_SELECT_SHOP",
    EARN_SPECIAL_UNSELECT_SHOP:"EARN_SPECIAL_UNSELECT_SHOP",
    EARN_SPECIAL_FILTER_SELECTED_SHOP_BY_REGION:"EARN_SPECIAL_FILTER_SELECTED_SHOP_BY_REGION",
    EARN_SPECIAL_SELECTED_REGION:"EARN_SPECIAL_SELECTED_REGION",
    EARN_SPECIAL_SELECT_ALL_SHOP_LIST:"EARN_SPECIAL_SELECT_ALL_SHOP_LIST",
    EARN_SPECIAL_UNSELECT_ALL_SHOP_LIST:"EARN_SPECIAL_UNSELECT_ALL_SHOP_LIST",
    EARN_SPECIAL_SEARCH_SHOP_LIST:"EARN_SPECIAL_SEARCH_SHOP_LIST",
    EARN_SPECIAL_SEARCH_SHOP_SELECTED:"EARN_SPECIAL_SEARCH_SHOP_SELECTED",
    EARN_SPECIAL_CLEAR_SHOP_LIST:"EARN_SPECIAL_CLEAR_SHOP_LIST",
    GET_LUCKY_DRAW_REWARD_SET_SUCCESS:"GET_LUCKY_DRAW_REWARD_SET_SUCCESS",

    GET_TRD_PARTY_CODE_SET_SUCCESS:"GET_TRD_PARTY_CODE_SET_SUCCESS",
    SET_FORM_PREVIEW_IMAGE_REWARD: 'SET_FORM_PREVIEW_IMAGE_REWARD',
    ADD_NEW_EARN_SPECIAL_SUCCESS: 'ADD_NEW_EARN_SPECIAL_SUCCESS',
    EDIT_NEW_EARN_SPECIAL_SUCCESS: 'EDIT_NEW_EARN_SPECIAL_SUCCESS',
}
import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import lodash from "lodash"
import qs from "querystring"


export const trueMoneyReportServices = {
    getTrueMoneyTransactionReport,
    getTrueMoneyCampaignList,
    downloadTrueMoneyTransaction,
    getTrueMoneySummaryBudget,
    getTrueMoneySummaryRewardList,
    downloadTrueMoneySummaryReward
}

function getTrueMoneyTransactionReport(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/tmn-transaction?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getTrueMoneyCampaignList(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/campaigns?rewardType=trueMoney`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function downloadTrueMoneyTransaction(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/tmn-transaction/export?${qs.stringify(params)}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
       .then(response => {
          if (response.data.statusCode.toString() === "200") {
             const urlDownload = lodash.get(response.data, "data.url")
             if(urlDownload){
                window.location.assign(urlDownload)
             }
             return { status: true, data: {}, message: response.data.message };
          } else if (response.data.message === "UNAUTHORIZE") {
             history.push('/auth/login')
             return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
             return { status: false, data: {}, message: response.data.message };
          }
       })
       .catch(e => {
          return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
       });
 
    return tmp;
 }

 function getTrueMoneySummaryBudget(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/tmn-summary/budget?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getTrueMoneySummaryRewardList(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/tmn-summary-by-reward?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function downloadTrueMoneySummaryReward(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/reports/tmn-summary-by-reward/export?${qs.stringify(params)}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
       .then(response => {
          if (response.data.statusCode.toString() === "200") {
             const urlDownload = lodash.get(response.data, "data.url")
             if(urlDownload){
                window.location.assign(urlDownload)
             }
             return { status: true, data: {}, message: response.data.message };
          } else if (response.data.message === "UNAUTHORIZE") {
             history.push('/auth/login')
             return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
             return { status: false, data: {}, message: response.data.message };
          }
       })
       .catch(e => {
          return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
       });
 
    return tmp;
 }
import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import lodash from "lodash"
import qs from "querystring"

export const trueMoneyTopupServices = {
    getTopupLists,
    RetryTrueMoney,
    downloadTrueMoneyTopup
}

function getTopupLists(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/tmn-notify-transaction?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function RetryTrueMoney(params) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + `/tmn/notify`,params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function downloadTrueMoneyTopup(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/tmn-notify-transaction/export?${qs.stringify(params)}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
       .then(response => {
          if (response.data.statusCode.toString() === "200") {
             const urlDownload = lodash.get(response.data, "data.url")
             if(urlDownload){
                window.location.assign(urlDownload)
             }
             return { status: true, data: {}, message: response.data.message };
          } else if (response.data.message === "UNAUTHORIZE") {
             history.push('/auth/login')
             return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
             return { status: false, data: {}, message: response.data.message };
          }
       })
       .catch(e => {
          return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
       });
 
    return tmp;
 }
import { configConstants } from '../constants';
import axios from 'axios';

import { history, setHeaders } from 'helpers';

export const periodServices = {
    getRewardList,
    getPeriodList,
    addPeriod,
    editPeriod,
    deletePeriod,
};

function getRewardList(params) {
    let tmp = axios.get(configConstants.API_LUCKYDRAW + `/reward`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            // console.log('response getRewardList service', response);
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}
// configConstants.API_LUCKYDRAW + `/period/search`
function getPeriodList(params) {
    var tmp = axios.post(configConstants.API_LUCKYDRAW + '/period/search', params, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function addPeriod(params) {
    var tmp = axios.post(configConstants.API_LUCKYDRAW + '/period', params, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}

function editPeriod(params) {
    var tmp = axios.put(configConstants.API_LUCKYDRAW + `/period/${params.periodId}`, params, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}

function deletePeriod(params) {
    let tmp = axios.delete(configConstants.API_LUCKYDRAW + `/period/${params._id}`, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}





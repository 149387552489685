export const tagConstants = {
    OPEN_ADD: 'OPEN_ADD_TAG',
    OPEN_EDIT: 'OPEN_EDIT_TAG',
    CLOSE_FORM: 'CLOSE_FORM_TAG',
    GET_TAG_SUCCESS: 'GET_TAG_SUCCESS' ,

    ADD_NEW_TAG_SUCCESS: 'ADD_NEW_TAG_SUCCESS',
    ADD_NEW_TAG_FAILURE: 'ADD_NEW_TAG_FAILURE',

    EDIT_TAG_SUCCESS: 'EDIT_TAG_SUCCESS',
    EDIT_TAG_FAILURE: 'EDIT_TAG_FAILURE',

    SHOW_LOADER: 'SHOW_TABLE_TAG_LOADER_SPINNER',
    HIDE_LOADER: 'HIDE_TABLE_TAG_LOADER_SPINNER',

    TABLE_RELOAD_SUCCESS: 'TAG_TABLE_RELOAD_SUCCESS',

    DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
    DELETE_TAG_FAILURE: 'DELETE_TAG_FAILURE',
    
    OPEN_TAG_FORM_IMPORT: 'OPEN_TAG_FORM_IMPORT',
    CLOSE_TAG_FORM_IMPORT: 'CLOSE_TAG_FORM_IMPORT',
    SHOW_TAG_IMPORT_MEMBER_RESULT: 'SHOW_TAG_IMPORT_MEMBER_RESULT',
    HIDE_TAG_IMPORT_MEMBER_RESULT: 'HIDE_TAG_IMPORT_MEMBER_RESULT'
}
import { photoGiveawayRuleConstants } from '../constants';
import lodash from 'lodash';

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableData: {
        photoGiveawayRule: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    allPhotoList: [],
    photoList:[],
    selectedPhotoList: [],
    formPhotoListLoader: false,
    tableLoader: false,
    formMemberLevellist: [],
    singlePhotoList: [{_id: 0}],
    joinedPhotoList: [{_id: 0}],
    joinedPhotoRows : [
        {
            productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
            point: 0
        },
    ],
    photoListMaster:[],
    formImportOpen: false,
    formImportInitialValue: {},
    showImportResult: false,
    importResult: {}
}

export function photoGiveawayRule(state = initialState, action) {
    switch (action.type) {
        case photoGiveawayRuleConstants.CLEAR_PAGE_STATE:
            return initialState
        case photoGiveawayRuleConstants.OPEN_ADD_NEW_RULES:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.data,
                selectedPhotoList:[]
            };
        case photoGiveawayRuleConstants.OPEN_EDIT_RULES:

            let selectedPhotoList = action.selectedPhotoList;
            let photoList = state.photoList;

            lodash.each(selectedPhotoList, function (currentObject) {
                photoList = photoList.filter(function (obj) {
                    return obj._id !== currentObject._id;
                });
            })


            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                selectedPhotoList: action.selectedPhotoList,
                photoList: photoList,
                photoListMaster:action.photoListMaster
            };
        case photoGiveawayRuleConstants.CLOSE_FORM_RULES:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                singlePhotoList: [{_id: 0}],
                joinedPhotoList: [{_id: 0}],
                joinedPhotoRows : [
                    {
                        productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                        point: 0
                    },
    ]

            }
        case photoGiveawayRuleConstants.GET_RULES_DATA_SUCCESS: {
            return {
                ...state,
                tableData: action.data,
                tableReload: false,
                tableReset: false
            }
        }
        case photoGiveawayRuleConstants.GET_PHOTO_LIST_SUCCESS: {
            let selectedPhotoList = state.selectedPhotoList;
            let photoList = action.photoList;

            lodash.each(selectedPhotoList, function (currentObject) {
                photoList = photoList.filter(function (obj) {
                    return obj._id !== currentObject._id;
                });
            })

            return {
                ...state,
                allPhotoList: action.photoList,
                photoList: photoList
            }
        }
        case photoGiveawayRuleConstants.RULE_TYPECODE_SELECTED_PHOTO_ITEM: {
            
            let photoData = action.data;
            let newPhotoList = state.photoList;
            let selectedPhotoList = state.selectedPhotoList;

            // console.log('state :',state);
            // console.log('photoData :',photoData);
            // console.log('newPhotoList :',newPhotoList);
            // console.log('selectedPhotoList :',selectedPhotoList);

            newPhotoList = newPhotoList.filter(function (obj) {
                return obj._id !== photoData._id;
            });

            selectedPhotoList.push(photoData)

            return {
                ...state,
                photoList: newPhotoList,
                selectedPhotoList: selectedPhotoList
            }
        }
        case photoGiveawayRuleConstants.RULE_TYPECODE_SELECTED_MANY_PHOTO_ITEM: {
            let photoDataList = action.data;
            let selectedPhotoList = state.selectedPhotoList;

            return {
                ...state,
                photoList: [],
                selectedPhotoList: lodash.unionBy(photoDataList, selectedPhotoList, '_id')
            }
        }
        case photoGiveawayRuleConstants.RULE_TYPECODE_DESELECTED_PHOTO_ITEM: {
            // console.log('action.data :',action.data)
            // console.log('state.photoList :',state.photoList)
            // console.log('state.selectedPhotoList :',state.selectedPhotoList)

            let photoData = action.data;
            let photoList = state.photoList;
            let selectedPhotoList = state.selectedPhotoList;

            selectedPhotoList = selectedPhotoList.filter(function (obj) {
                return obj._id !== photoData._id;
            });

            photoList.push(photoData)
            photoList = lodash.orderBy(photoList, ['updated_at'], ['desc']);

            return {
                ...state,
                photoList: photoList,
                selectedPhotoList: selectedPhotoList
            }
        }
        case photoGiveawayRuleConstants.ON_RULE_TYPE_CHANGE_TO_PHOTO: {
            // console.log('123456')
            return {
                ...state,
                selectedPhotoList: []
            }
        }
        case photoGiveawayRuleConstants.EARNRULES_FORM_PRODUCT_LIST_LOADER: {
            let showLoader = action.showLoader
            return {
                ...state,
                formPhotoListLoader: showLoader
            }
        }
        case photoGiveawayRuleConstants.SHOW_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: true
            }
        }
        case photoGiveawayRuleConstants.HIDE_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: false
            }
        }
        case photoGiveawayRuleConstants.ADD_RULES_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                singlePhotoList: [{_id: 0}],
                joinedPhotoList: [{_id: 0}],
                joinedPhotoRows : [
                    {
                        productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                        point: 0
                    },
                ],
            }
        }
        case photoGiveawayRuleConstants.EDIT_RULES_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: true,
                tableReset: false,
                singlePhotoList: [{_id: 0}],
                joinedPhotoList: [{_id: 0}],
                joinedPhotoRows : [
                    {
                        productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                        point: 0
                    },
                ],
            }
        }
        case photoGiveawayRuleConstants.TABLE_RELOAD_SUCCESS: {
            return {
                ...state,
                tableReload: false,
                tableReset: false
            }
        }
        case photoGiveawayRuleConstants.DELETE_RULES_SUCCESS: {
            return {
                ...state,
                tableReload: true
            }
        }
        case photoGiveawayRuleConstants.GET_MEMBER_LEVEL_LIST_SUCCESS: {
            return {
                ...state,
                formMemberLevellist: action.tmp
            }
        }
        case photoGiveawayRuleConstants.EARN_RULE_ADD_JOINED_PRODUCT_ROW: {
            let stateJoinedPhotoRow = state.joinedPhotoRows;
            stateJoinedPhotoRow.push(
                {
                    productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                    point: 0
                }
            )
            return {
                ...state,
                joinedPhotoRows: stateJoinedPhotoRow
            }
        } 
        case photoGiveawayRuleConstants.EARN_RULE_ADD_JOINED_PRODUCT: {
            let stateJoinedPhotoRow = state.joinedPhotoRows;
            stateJoinedPhotoRow[action.index].productWithQty.push(action.productWithQty)
            return {
                ...state,
                joinedPhotoRows: stateJoinedPhotoRow
            }
        } 
        case photoGiveawayRuleConstants.EARN_RULE_ADD_SINGLE_PRODUCT: {
            let stateSinglePhotoRow = state.singlePhotoList;
            stateSinglePhotoRow.push(action.objSingle)
            return {
                ...state,
                singlePhotoList: stateSinglePhotoRow
            }
        } 
        case photoGiveawayRuleConstants.EARN_RULE_DELETE_JOINED_PRODUCT: {
            let stateJoinedPhotoRow = state.joinedPhotoRows;
            stateJoinedPhotoRow[action.index].productWithQty.pop();
            return {
                ...state,
                joinedPhotoRows: stateJoinedPhotoRow
            }
        }
        case photoGiveawayRuleConstants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS: {
            return {
                ...state,
                luckyDrawRewardSet: action.data.rewards
            }
        }
        case photoGiveawayRuleConstants.OPEN_RULE_FORM_IMPORT: {
            return {
                ...state,
                formImportOpen: true,
                formImportInitialValue: (action.formImportInitialValue? action.formImportInitialValue:{})
            }
        }     
        case photoGiveawayRuleConstants.CLOSE_RULE_FORM_IMPORT: {
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {}
            }
        }   
        case photoGiveawayRuleConstants.SHOW_RULE_IMPORT_PRODUCT_RESULT: {
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: true,
                importResult: action.data
            }
        }
        case photoGiveawayRuleConstants.HIDE_RULE_IMPORT_PRODUCT_RESULT:
            return {
                ...state,
                showImportResult: false,
                importResult: {}
            }
        default:
            return state;
    }
}
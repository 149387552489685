import axios from 'axios';
import moment from 'moment';
import lodash from 'lodash';

import { history, crmValidator, setHeaders } from 'helpers';
import { configConstants } from '../constants';

export const uploadServices = {
   getListsUpload3rdParty,
   getListsUpload3rdPartyByMerchant,
   apiAddUpload3rdParty,
   apiAddUpload3rdPartyByMerchant,
   apiUpdateUpload3rdParty,
   apiUpdateUpload3rdPartyByMerchant,
   apiDeleteUpload3rdParty,
   apiDeleteUpload3rdPartyByMerchant,
   apiImport3rdPartyCode,
   apiImport3rdPartyCodeByMerchant
};

function getListsUpload3rdParty(params) {
   // console.log('setHeaders :',setHeaders({ 'Charset': 'utf-8' }))
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/trd-party-codes/list?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         console.log('response :',response)
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function getListsUpload3rdPartyByMerchant(params) {
   var tmp = axios.get(configConstants.API_BACKEND_CRM + `/trd-party-code-by-merchant/list?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function apiAddUpload3rdParty(values) {
   values.expired_at = moment(values.expired_at).format('YYYY-MM-DDTHH:mm:ss.sssZ')

   let formData = new FormData();

   if (!crmValidator.isEmptyValue(values)) {
      lodash.forEach(values, function (value, key) {
         if (key === "uploadImage") {
            formData.append('file', value);
         } else if (key === "termAndCondition") {
            formData.append('termAndCondition', btoa(encodeURIComponent(value)))
         } else {
            formData.append(key, value)
         }
      })
   }

   var tmp = axios.post(configConstants.API_BACKEND_CRM + '/trd-party-codes/codeset/create', formData, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });
   return tmp;
}

function apiAddUpload3rdPartyByMerchant(values) {
   values.expired_at = moment(values.expired_at).format('YYYY-MM-DDTHH:mm:ss.sssZ')

   let formData = new FormData();

   if (!crmValidator.isEmptyValue(values)) {
      lodash.forEach(values, function (value, key) {
         if (key === "uploadImage") {
            formData.append('file', value);
         } else if (key === "termAndCondition") {
            formData.append('termAndCondition', btoa(encodeURIComponent(value)))
         } else {
            formData.append(key, value)
         }
      })
   }

   var tmp = axios.post(configConstants.API_BACKEND_CRM + '/trd-party-code-by-merchant/codeset/create', formData, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });
   return tmp;
}

function apiUpdateUpload3rdParty(values) {
   values.expired_at = moment(values.expired_at).format('YYYY-MM-DDTHH:mm:ss.sssZ')

   let formData = new FormData();

   if (!crmValidator.isEmptyValue(values)) {
      lodash.forEach(values, function (value, key) {
         if (key === "uploadImage") {
            formData.append('file', value);
         } else if (key === "termAndCondition") {
            formData.append('termAndCondition', btoa(encodeURIComponent(value)))
         } else {
            formData.append(key, value)
         }
      })
   }

   const tmp = axios.post(configConstants.API_BACKEND_CRM + '/trd-party-codes/codeset/update', formData, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });
   return tmp;
}

function apiUpdateUpload3rdPartyByMerchant(values) {
   values.expired_at = moment(values.expired_at).format('YYYY-MM-DDTHH:mm:ss.sssZ')

   let formData = new FormData();

   if (!crmValidator.isEmptyValue(values)) {
      lodash.forEach(values, function (value, key) {
         if (key === "uploadImage") {
            formData.append('file', value);
         } else if (key === "termAndCondition") {
            formData.append('termAndCondition', btoa(encodeURIComponent(value)))
         } else {
            formData.append(key, value)
         }
      })
   }

   const tmp = axios.post(configConstants.API_BACKEND_CRM + '/trd-party-code-by-merchant/codeset/update', formData, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });
   return tmp;
}

function apiDeleteUpload3rdParty(params) {
   var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/trd-party-codes/${params._id}/force`, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

function apiDeleteUpload3rdPartyByMerchant(params) {
   var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/trd-party-code-by-merchant/${params._id}/force`, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });

   return tmp;
}

function apiImport3rdPartyCode(values) {
   let formData = new FormData();

   if (!crmValidator.isEmptyValue(values)) {
      lodash.forEach(values, function (value, key) {
         if (key === "fileImport") {
            formData.append('file', value[0]);
         } else {
            formData.append(key, value)
         }
      })
   }

   var tmp = axios.post(configConstants.API_BACKEND_CRM + '/trd-party-codes/import', formData, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });
   return tmp;
}

function apiImport3rdPartyCodeByMerchant(values) {
   let formData = new FormData();

   if (!crmValidator.isEmptyValue(values)) {
      lodash.forEach(values, function (value, key) {
         if (key === "fileImport") {
            formData.append('file', value[0]);
         } else {
            formData.append(key, value)
         }
      })
   }

   var tmp = axios.post(configConstants.API_BACKEND_CRM + '/trd-party-code-by-merchant/import', formData, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });
   return tmp;
}

import { summaryReportServices } from "../services";
import { loader, alert as customAlert } from "../helpers";
import lodash from "lodash";
import i18n from 'i18n';
import qs from 'querystring'
import moment from 'moment'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const summaryReportActions = {
    getEarnSummaryReport,
    getBurnSummaryReport
};

function getEarnSummaryReport(params, tableReset) {

    if (params.startDate) {
        params.startDate = moment(params.startDate).format('YYYY-MM-DD')
    }

    if (params.endDate) {
        params.endDate = moment(params.endDate).format('YYYY-MM-DD')
    }

    return dispatch => {
        loader(dispatch, true)
        summaryReportServices
            .getEarnSummaryReport(qs.stringify(params))
            .then(
                response => {
                    if (response.status) {
                        if (lodash.has(response, "data.data")) {
                            dispatch({ type: 'GET_EARN_SUMMARY_REPORT_REQUEST' })
                            dispatch({
                                type: 'GET_EARN_SUMMARY_REPORT_SUCCESS',
                                data: response.data.data,
                                total: response.data.totalDate,
                                sum: lodash.get(response, 'data.sum'),
                                tableFilter: params
                            });

                            if (tableReset) {
                                dispatch({ type: 'EARN_SUMMARY_REPORT_TABLE_RESET' });
                            }

                        }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            )
            .then(() => {
                loader(dispatch, false)
            });
    };
}

function getBurnSummaryReport(params, tableReset) {

    if (params.startDate) {
        params.startDate = moment(params.startDate).format('YYYY-MM-DD')
    }

    if (params.endDate) {
        params.endDate = moment(params.endDate).format('YYYY-MM-DD')
    }

    return dispatch => {
        loader(dispatch, true)
        summaryReportServices
            .getBurnSummaryReport(qs.stringify(params))
            .then(
                response => {
                    if (response.status) {
                        if (lodash.has(response, "data.data")) {
                            dispatch({ type: 'GET_BURN_SUMMARY_REPORT_REQUEST' })
                            dispatch({
                                type: 'GET_BURN_SUMMARY_REPORT_SUCCESS',
                                data: response.data.data,
                                total: response.data.totalDate,
                                sum: lodash.get(response, 'data.sum'),
                                tableFilter: params
                            });

                            if (tableReset) {
                                dispatch({ type: 'BURN_SUMMARY_REPORT_TABLE_RESET' });
                            }

                        }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            )
            .then(() => {
                loader(dispatch, false)
            });
    };
}
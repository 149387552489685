import React, { Component } from "react"
import { connect } from "react-redux"
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import { reportConstants } from "../../constants"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import lodash from "lodash";

const style = {
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}

class ReportUserAgentDetail extends Component {
  closeDialog() {
    const { dispatch } = this.props
    dispatch({ type: reportConstants.CLOSE_REPORT_USER_AGENT_DETAIL })
  }

  render() {
    const { userAgentDetail } = this.props
    const { isOpen, userAgent, userChannel } = userAgentDetail

    return (
      <Dialog
        onClose={() => {
          this.closeDialog()
        }}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            style={style.closeButton}
            onClick={() => {
              this.closeDialog()
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
            <Grid style={{minWidth: 512}} container direction="row" spacing={0}>
              <Grid item xs={12} md={2} >
                <Typography fontWeight={500} variant="body1" gutterBottom>Device :</Typography>
              </Grid>
              <Grid item xs={12} md={10} >
                <Typography fontWeight={0} variant="body1" gutterBottom>
                 {
                lodash.get(userAgent,"source","-")
                 }
                </Typography>
              </Grid>
            </Grid>
            
            <Grid style={{minWidth: 512}} container spacing={0}>
              <Grid item xs={2} md={2} >
                <Typography fontWeight={500} variant="body1" gutterBottom>Channel :</Typography>
              </Grid>
              <Grid item xs={10} md={10} >
                <Typography fontWeight={0} variant="body1" gutterBottom>
                  {userChannel}
                </Typography>
              </Grid>
            </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

function mapStateToProps(state) {
  const { userAgentDetail } = state.report
  return {
    userAgentDetail,
  }
}
const connectedComponent = connect(mapStateToProps)(ReportUserAgentDetail)

export default connectedComponent

import React from "react";
import { connect } from 'react-redux';
import { crmValidator } from 'helpers';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, reduxForm, change } from 'redux-form';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

import { searchCodeConstants } from '../../constants';
//page components
import SearchCodeResult from "views/SearchCode/SearchCodeResult.jsx";

// material-ui icons
// import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
// import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { searchCodeAction } from 'actions';

const style = {
  reportHeaderContainer: {
    margin: "0px",
    width: "calc(100% - 10px)",
    padding: "0px",
  },
  btnClearFilter: {
    marginRight: "10px",
    marginTop: "25px"
  },
  textCenter: {
    textAlign: "center"
  },
  mt20: {
    marginTop: "20px"
  }
};

const pageStyle = { ...style };
const FORM_NAME = "formSearchCode";

const form_validate = {
  "code": {
      rules: {
          required: true
      },
      messages: {
          required: 'กรุณากรอกข้อมูล'
      }
  },
  "type": {
    rules: {
        required: true
    },
    messages: {
        required: 'กรุณาเลือกข้อมูล'
    }
  } 
}

const validate = (values) => {
  const errors = crmValidator.validateByRulesList(form_validate, values);
  return errors
}

class SearchCodeContainer extends React.Component {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef()
    this.state = {
      isLoading: false
    };
  }

  clearFilter = () => {
    const { dispatch } = this.props;
    dispatch(change(FORM_NAME, 'code', ''));
    dispatch(change(FORM_NAME, 'type', ''));
    dispatch({ type: searchCodeConstants.CLEAR_PAGE_STATE });
  }

  handleSubmit(values) {
    const { dispatch, formSearchCode } = this.props;
    let params = {
      "code" : (formSearchCode.values === undefined || formSearchCode.values.code === undefined ) ? "" : formSearchCode.values.code,
      "type" : (formSearchCode.values === undefined || formSearchCode.values.type === undefined ) ? "" : formSearchCode.values.type,
    }
    dispatch(searchCodeAction.searchCode(params));
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch({ type: searchCodeConstants.CLEAR_PAGE_STATE });
    dispatch(change(FORM_NAME, 'type', 'earnCode'));
  } 

    render() {
        const { classes, searchDatas, handleSubmit } = this.props;

        let codeType = [];
        codeType.push(
            {
                value: "earnCode",
                label: "Earn rule"
            },
            {
                value: "3rdPartyCode",
                label: "3rd Party Code"
            },
        )

        return (
        <div>
            <Card >
                <CardBody>
                <CustomSweetAlert />
                <h4 style={{fontWeight:'500', fontSize:'1.25rem'}}>SEARCH CODE</h4> 
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>

                  <GridContainer className={classes.reportHeaderContainer}>
                      <GridItem xs={6} sm={3}>
                          <h6><b>Code type</b></h6>
                      </GridItem>
                  </GridContainer>

                  <GridContainer className={classes.reportHeaderContainer}>
                    <GridItem xs={6} sm={3}>
                      <Field
                          name="type"
                          component={CustomSelect}
                          type="text"
                          formControlProps={{
                              fullWidth: true
                          }}
                          optionsList={codeType}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer className={classes.reportHeaderContainer}>
                      <GridItem xs={6} sm={3}>
                          <h6><b>Code</b></h6>
                      </GridItem>
                  </GridContainer>

                  <GridContainer className={classes.reportHeaderContainer}>
                    <GridItem xs={6} sm={3}>
                        <Field
                            name="code"
                            component={CustomInput}
                            type="text"
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                      <GridItem xs={12} sm={4} md={4} lg={3}>
                          <Button
                          onClick={this.clearFilter}
                          style={{
                              marginTop: "25px"
                          }}
                          className={classes.btnClearFilter} 
                          color="white" >
                              Clear
                          </Button>
                          &nbsp;  &nbsp;  &nbsp;
                          <Button
                          type="submit"
                          style={{
                              marginTop: "25px"
                          }}
                          color="primary">
                              Submit
                          </Button>
                      </GridItem>

                  </GridContainer>  
                </form>
                </CardBody>
            </Card>
            <SearchCodeResult
              initialValues={{
                result: searchDatas.data.status
              }}
            />
        </div>
        )
    }

}

const connectSearchCodeContainer = connect(mapStateToProps)(SearchCodeContainer);

function mapStateToProps(state) {
  const { sweetAlert } = state.sweetAlert;
  const { formSearchCode } = state.form;
  const { formOpen, searchDatas, formInitialValue, isLoading } = state.search_code;
  return {
      sweetAlert, formSearchCode, formOpen, searchDatas, formInitialValue, isLoading
  };
}

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  validate: validate,
  onChange: (values, dispatch, props, previousValues) => {
    // if (values !== previousValues) {
    //   dispatch({ type: SearchCodeContainer.TABLE_RESET });
    // }
  }
})(withStyles(pageStyle)(connectSearchCodeContainer));

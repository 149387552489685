import React from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// core components
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import ShipmentForm from "views/Shipment/ShipmentForm.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

// import Badge from "components/Badge/Badge.jsx";

import { shipmentActions } from 'actions';
import { shipmentServices } from 'services';
import { plugins, crmValidator, loader, alert as customAlert} from 'helpers';
import { shipmentConstants } from '../../constants';

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";


import FormImportFile from './FormImportFile'
import FormImportFileResult from './FormImportFileResult'

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const moduleName = 'shipment'

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(11)": {
                    textAlign: "right"
                }
            },
            body: {
                "&:nth-child(22)": {
                    textAlign: "right"
                }
            }
        }
    }
});

class TableShipmentList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: "",
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "created_at",
                sortDir: "desc",
                keyword: ""
            },
            csvData: [],
            refreshTable: true
        };
        this.csvLink = React.createRef()
        this.openImportForm = this.openImportForm.bind(this)
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }


    openImportForm() {
        const { dispatch } = this.props;
        dispatch({ type: 'OPEN_SHIPMENT_FORM_IMPORT' })
    }

    exportCSV() {
        const { tableFilter, tableTotal, dispatch } = this.props;
        tableFilter.skip = 0;
        tableFilter.limit = tableTotal ? tableTotal : 100000;
        if (tableTotal > 30000) {
            customAlert(
                dispatch,
                "error",
                "Can not export data more than 30,000 rows"
            );
        } else {
            loader(dispatch, true);
            shipmentServices.exportShipment(tableFilter)
            .then(response => {
              loader(dispatch, false)
            })
            .catch(e => {
              customAlert(dispatch, 'error', "SOMETHING_WENT_WRONG")
              loader(dispatch, false)
            });
        }
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableFilter } = this.props
        if (this.state.refreshTable) {
            tableFilter.skip = null
            tableFilter.sortBy = "createdAt"
            tableFilter.sortDir = "desc"
            tableFilter.keyword = ""
            tableFilter.search = {}
            this.setState({
                refreshTable: false
            })
        }

        let reqParams = tableFilter;

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                reqParams.skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                reqParams.limit = 10
            }
            reqParams.page = skip;
            this.setState({
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams.skip = skip;
            reqParams.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams.keyword = keyword;
            } else {
                reqParams.keyword = ""
            }
        } else {
            reqParams = tableFilter

        }
        dispatch(shipmentActions.getTableDatas(reqParams));
        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas;
        return data[rowIndex];
    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: shipmentConstants.CLEAR_PAGE_STATE });
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: shipmentConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    componentDidMount() {
        this.setState({
            refreshTable: true
        })

        this.getData();
    }

    openEditShipmentDetail = (rowIndex) => {
        const { dispatch, tableDatas } = this.props;
        let rowData = tableDatas[rowIndex]
        dispatch({ type: shipmentConstants.OPEN_FORM_EDIT, formInitialValue: { ...rowData } })
    }

    render() {
        const { classes, formInitialValue, tableDatas, tableKey, visiblePersonalData, showLoader, tableTotal, tableFilter, formImportInitialValue } = this.props;
        const { rowsPerPage } = this.state;
        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "displayId", options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            {
                name: "member.firstName", label: "Member Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.member !== undefined || rowData.member != null) {
                            return (rowData.member.lastName === undefined || rowData.member.lastName === null ? rowData.member.firstName : rowData.member.firstName + " " + rowData.member.lastName)
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "transaction.date", label: "Redemption Date", options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.transaction !== undefined || rowData.transaction != null) {
                            return (<span className="text-nowrap">{plugins.datetimeFormat(rowData.transaction.date, "datetime")}</span>)
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "campaign.name", label: "Campaign", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return (rowData.campaign === undefined || rowData.campaign === null ? "" : rowData.campaign.name)
                    }
                }
            },
            {
                name: "reward.name", label: "Reward Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return (rowData.reward === undefined || rowData.reward === null ? "" : rowData.reward.name)
                    }
                }
            },
            {
                name: "address.firstName", label: "Receiver Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.address !== undefined || rowData.address != null) {
                            return (rowData.address.lastName === undefined || rowData.address.lastName === null ? rowData.address.firstName : rowData.address.firstName + " " + rowData.address.lastName)
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "status", label: "Status", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.status !== undefined || rowData.status != null) {
                            if (rowData.status === "WAITING") {
                                return "Waiting for shipment"
                            } else if (rowData.status === "SHIPPED") {
                                return "Shipped"
                            } else if (rowData.status === "CANCELLED") {
                                return "Cancelled"
                            }
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "shippedDate", label: "Shipped Date", options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <span className="text-nowrap">{plugins.datetimeFormat(value, "datetime")}</span>
                        )
                    }
                }
            },
            {
                name: "shipping.displayName", label: "Shipping Company", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return (rowData.shipping === undefined || rowData.shipping === null ? "" : rowData.shipping.displayName)
                    }
                }
            },
            { name: "trackingNo", label: "Tracking No" },
        ];
        if (plugins.isHasPermission(moduleName, 'read')) {
            columns.push({
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classes.actionDiv}>
                                {(plugins.isHasPermission(moduleName, 'update') && visiblePersonalData) &&
                                    <TableActionButton
                                        id='btn_edit_popup'
                                        actionType="edit"
                                        onClick={(e) => {
                                            this.openEditShipmentDetail(tableMeta.rowIndex)
                                        }}
                                    />
                                }
                            </div>
                        )
                    }
                }
            })
        }

        let options = {
            serverSide: true,
            count: (tableTotal ? tableTotal : 0),
            page: tableFilter.page ? tableFilter.page : 0,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            customToolbar: () => {

                return (
                    <>
                        {plugins.isHasPermission("shipment", 'export') && plugins.isHasPackageFeatures('export') &&(
                                <Tooltip title='Export CSV'>
                                <IconButton
                                    id='button-shippingTable-exportCsv'
                                    className=''
                                    onClick={this.exportCSV.bind(this)}
                                    >
                                    <CloudDownload />
                                </IconButton>
                                </Tooltip>
                        )}
                        
                        {plugins.isHasPermission("shipment", 'update') &&(
                            <CustomToolbar handleClick={this.openImportForm} id="btn_open_form_import" title="Import File" importIcon />
                        )}
                    </>
                );
            },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        return (
            <div id="tableShipmentList">
                <CustomSweetAlert />
                <FormImportFile initialValues={formImportInitialValue}/>
                <FormImportFileResult/>
                <ShipmentForm
                    initialValues={formInitialValue}
                    readOnly={!plugins.isHasPermission(moduleName, 'read')}
                />
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        data={tableDatas}
                        columns={columns}
                        options={options}
                    />
                    {showLoader && <TableLoaderSpinner />}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, showLoader, tableTotal, tableFilter, formImportInitialValue } = state.shipment;
    const { visiblePersonalData, merchantSetting } = state.authentication;

    return {
        formInitialValue, tableReload, tableReset, visiblePersonalData, tableDatas, tableKey, showLoader, merchantSetting, tableTotal, tableFilter, formImportInitialValue
    };
}
export default connect(mapStateToProps)(withStyles(pageStyle)(TableShipmentList));  

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash';

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { roleActions } from 'actions';
import { roleConstants } from '../../constants';

import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import i18n from 'i18n';

const style = {};

const CustomMTablestyles = {
   ...MTablestyles,
   overrides: {
      ...MTablestyles.overrides,
      MuiTableCell: {
         root: {
            padding: "4px 16px 4px 6px;"
         }
      },
   }
}

const THEME = createMuiTheme(CustomMTablestyles);

const FORM_NAME = "role_form";

const form_validate = {
   "role": {
      rules: {
         required: true,
      },
      messages: {
         required: "warning_messages.role_name_is_required",
      },
      alert: "warning_messages.role_name_is_required"
   },
}

const validate = (values) => {
   const errors = crmValidator.validateByRulesList(form_validate, values);
   return errors
}

const setAllow = (data) => {
   let tmp = []
   for (const [key, value] of Object.entries(data)) {
      if (value) {
         tmp.push(key)
      }
   }
   return tmp
}

const assignFeatrure = async (features) => {
   let ca = []
   let subRoles = []
   for (const [key, value] of Object.entries(features)) {
      if (!lodash.has(value, 'allow')) {
         subRoles = await assignFeatrure(value)
         ca.push({
            "name": key,
            "subRoles": subRoles,
         })
      } else {
         ca.push({
            "name": key,
            "allow": setAllow(value.allow),
         })
      }
   }

   return ca

}

class RoleForm extends React.Component {
   componentWillReceiveProps(nextProps) {
      if (nextProps.formOpen === false && nextProps.reload === true) {
         this.props.handleReload();
      }
   }

   handleSubmit = async (values) => {
      const { dispatch } = this.props;
      var reqParams = {
         "role": values.role,
         "superAdmin": values.superAdmin,
         "visiblePersonalData": values.visiblePersonalData,
         "permission": []
      }

      if (lodash.has(values, 'permission') && !values.superAdmin) { 
         reqParams.permission = await assignFeatrure(values.permission)
      }

      if (this.props.formMode === "add") {
         dispatch(roleActions.addNewRole(reqParams));
      } else {
         reqParams._id = values._id;
         dispatch(roleActions.editRole(reqParams));
      }
   }

   handleToggle = (value) => {
      const { checked } = this.state;
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
         newChecked.push(value);
      } else {
         newChecked.splice(currentIndex, 1);
      }

      this.setState({
         checked: newChecked
      });
   }

   handleClose = () => {
      const { dispatch } = this.props;
      dispatch({ type: roleConstants.CLOSE_FORM_ROLE });
   }

   createFeatures = (features, ck, padding, ci = "0") => {
      const { role_form, classes, readOnly, dispatch } = this.props;
      let isSuperAdmin = lodash.get(role_form, 'values[superAdmin]', false)
      let selectedChatbot = lodash.get(role_form, 'values[selectAll][chatbot]', false);
      let selectedPackage = lodash.get(role_form, 'values[selectAll][package]', false);
      ck = ck || "";
      padding = ck === "" ? 0 : padding + 30
      ci = ci.toString()
      return features.map((row, index) => {
         let keyIndex = ci + index.toString()
         let keyName = (ck === "") ? `[${row.name}]` : ck + `[${row.name}]`
         if (lodash.has(row, 'subRoles')) {
            return (
               <>
                  <TableRow key={keyIndex}>
                     <TableCell component="th" scope="row">
                        <b className={isSuperAdmin ? classes.labelDisabled : ""} style={{ "paddingLeft": padding + "px" }}>{row.label}</b>
                     </TableCell>
                     <TableCell align="right" colSpan="6"></TableCell>
                  </TableRow>
                  {this.createFeatures(row.subRoles, keyName, padding, keyIndex)}
               </>
            )
         } else {
            let isRead = (lodash.get(row, "permission.read") !== 1);
            let isCreate = (lodash.get(row, "permission.create") !== 1);
            let isUpdate = (lodash.get(row, "permission.update") !== 1);
            let isDelete = (lodash.get(row, "permission.delete") !== 1);
            let isExport = (lodash.get(row, "permission.export") !== 1);
            return (
               <>
                  <TableRow key={keyIndex}>
                     <TableCell component="th" scope="row">
                        <p className={isSuperAdmin ? classes.labelDisabled : ""} style={{ "paddingLeft": padding + "px" }}>{row.label}</p>
                     </TableCell>
                     <TableCell align="right">
                        <Field
                           id={"selectAll_" + keyName}
                           name={"selectAll" + keyName}
                           component={CustomCheckbox}
                           labelText="Select all"
                           checked={isSuperAdmin}
                           onChange={(event, newValue, previousValue, name) => {
                              if (keyName === '[chatbot]') {
                                    dispatch(change(FORM_NAME, "permission" + keyName + "[allow][read]", newValue))
                              } else if(keyName === '[package]'){
                                    dispatch(change(FORM_NAME, "permission" + keyName + "[allow][read]", newValue))
                              } else {
                                 if (!isRead)
                                    dispatch(change(FORM_NAME, "permission" + keyName + "[allow][read]", newValue))
                                 if (!isCreate)
                                    dispatch(change(FORM_NAME, "permission" + keyName + "[allow][create]", newValue))
                                 if (!isUpdate)
                                    dispatch(change(FORM_NAME, "permission" + keyName + "[allow][update]", newValue))
                                 if (!isDelete)
                                    dispatch(change(FORM_NAME, "permission" + keyName + "[allow][delete]", newValue))
                                 if (!isExport)
                                    dispatch(change(FORM_NAME, "permission" + keyName + "[allow][export]", newValue))
                              }
                           }}
                           disabled={isSuperAdmin || readOnly}
                        />
                     </TableCell>
                     <TableCell align="right">
                        <Field
                           onChange={(event, newValue, previousValue, name) => {
                              if (!newValue) {
                                 dispatch(change(FORM_NAME, "selectAll" + keyName, newValue))
                                 dispatch(change(FORM_NAME, "permission" + keyName + "[allow][create]", newValue))
                                 dispatch(change(FORM_NAME, "permission" + keyName + "[allow][update]", newValue))
                                 dispatch(change(FORM_NAME, "permission" + keyName + "[allow][delete]", newValue))
                                 dispatch(change(FORM_NAME, "permission" + keyName + "[allow][export]", newValue))
                              }
                           }}
                           id={"permission_" + keyName + "_allow_read"}
                           name={"permission" + keyName + "[allow][read]"}
                           component={CustomCheckbox}
                           labelText="View"
                           checked={(isSuperAdmin && !isRead) || (selectedChatbot && keyName === '[chatbot]') || (selectedPackage && keyName === '[package]')}
                           disabled={isSuperAdmin || isRead || readOnly || keyName === '[chatbot]' || keyName === '[package]'}
                        />
                     </TableCell>
                     <TableCell align="right">
                        <Field
                           onChange={(event, newValue, previousValue, name) => {
                              if (!newValue) {
                                 dispatch(change(FORM_NAME, "selectAll" + keyName, newValue))
                              } else {
                                 dispatch(change(FORM_NAME, "permission" + keyName + "[allow][read]", newValue))
                              }
                           }}
                           id={"permission_" + keyName + "_allow_create"}
                           name={"permission" + keyName + "[allow][create]"}
                           component={CustomCheckbox}
                           labelText="Create"
                           checked={(isSuperAdmin && !isCreate) || (selectedChatbot && keyName === '[chatbot]')}
                           disabled={isSuperAdmin || isCreate || readOnly}
                        />
                     </TableCell>
                     <TableCell align="right">
                        <Field
                           onChange={(event, newValue, previousValue, name) => {
                              if (!newValue) {
                                 dispatch(change(FORM_NAME, "selectAll" + keyName, newValue))
                              } else {
                                 dispatch(change(FORM_NAME, "permission" + keyName + "[allow][read]", newValue))
                              }
                           }}
                           id={"permission_" + keyName + "_allow_update"}
                           name={"permission" + keyName + "[allow][update]"}
                           component={CustomCheckbox}
                           labelText="Update"
                           checked={(isSuperAdmin && !isUpdate) || (selectedChatbot && keyName === '[chatbot]') || (selectedPackage && keyName === '[package]')}
                           disabled={isSuperAdmin || isUpdate || readOnly}
                        />
                     </TableCell>
                     <TableCell align="right">
                        <Field
                           onChange={(event, newValue, previousValue, name) => {
                              if (!newValue) {
                                 dispatch(change(FORM_NAME, "selectAll" + keyName, newValue))
                              } else {
                                 dispatch(change(FORM_NAME, "permission" + keyName + "[allow][read]", newValue))
                              }
                           }}
                           id={"permission_" + keyName + "_allow_delete"}
                           name={"permission" + keyName + "[allow][delete]"}
                           component={CustomCheckbox}
                           labelText="Delete"
                           checked={(isSuperAdmin && !isDelete) || (selectedChatbot && keyName === '[chatbot]')}
                           disabled={isSuperAdmin || isDelete || readOnly}
                        />
                     </TableCell>
                     <TableCell align="right">
                        <Field
                           onChange={(event, newValue, previousValue, name) => {
                              if (!newValue) {
                                 dispatch(change(FORM_NAME, "selectAll" + keyName, newValue))
                              } else {
                                 dispatch(change(FORM_NAME, "permission" + keyName + "[allow][read]", newValue))
                              }
                           }}
                           id={"permission_" + keyName + "_allow_export"}
                           name={"permission" + keyName + "[allow][export]"}
                           component={CustomCheckbox}
                           labelText="Export"
                           checked={(isSuperAdmin && !isExport) || (selectedChatbot && keyName === '[chatbot]')}
                           disabled={isSuperAdmin || isExport || readOnly}
                        />
                     </TableCell>
                  </TableRow>
               </>
            )
         }
      });
   }

   render() {
      const {
         handleSubmit,
         classes,
         formMode,
         formOpen,
         listFeatures,
      } = this.props;

      let { readOnly } = this.props

      if (formMode === `add`) {
         readOnly = false
      } else if (formMode === `edit` && !readOnly) {
         readOnly = false
      } else {
         readOnly = true
      }

      const displayCard = formOpen ? { padding: "15px" } : { display: "none" }

      return (
         <Card style={displayCard}>
            <CardHeader icon>
               <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Role' : (readOnly === true ? 'Role Info' : 'Edit Role')}</b></h4>
            </CardHeader>
            <CardBody>
               <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={3}>
                        <Field
                           id="roleName"
                           name="role"
                           component={CustomInput}
                           type="text"
                           labelText="Role name"
                           formControlProps={{
                              fullWidth: true
                           }}
                           onChange={this.onBasicFieldChange}
                           disabled={readOnly}
                        />
                     </GridItem>
                     <Field
                        id="_id"
                        name="_id"
                        component="input"
                        type="hidden"
                     />
                     <GridItem xs={12} sm={6} md={3}>
                        <Field
                           id="superAdmin"
                           name={"superAdmin"}
                           notLabel={true}
                           component={CustomCheckbox}
                           disabled={readOnly}
                           labelText="Super admin"
                        />
                     </GridItem>
                     <GridItem xs={12} sm={6} md={3}>
                        <Field
                           id="visiblePersonalData"
                           name={"visiblePersonalData"}
                           notLabel={true}
                           component={CustomCheckbox}
                           disabled={readOnly}
                           labelText="Display personal data"
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12}>
                        {/* <div className={classes.tableResponsive}> */}
                        <MuiThemeProvider theme={THEME}>
                           <Table className={classes.table}>
                              <TableHead>
                                 <TableRow>
                                    <TableCell>Menu</TableCell>
                                    <TableCell colSpan="6" align="center">Permission</TableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {this.createFeatures(listFeatures)}
                              </TableBody>
                           </Table>
                        </MuiThemeProvider>
                        {/* </div> */}
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                        <Button
                           id="btn_submit"
                           type="submit"
                           color="primary">
                           {i18n.t('submit')}
                        </Button>
                        <Button
                           id="btn_cancel"
                           type="button"
                           onClick={this.handleClose}
                           style={{ marginLeft: "10px" }}
                           color="white">
                           {i18n.t('cancel')}
                        </Button>
                     </GridItem>
                  </GridContainer>
               </form>
            </CardBody>
         </Card>
      )
   }
}

function mapStateToProps(state) {
   const { formOpen, formMode, reload, listFeatures } = state.role;
   const { role_form } = state.form;
   return {
      formOpen, formMode, reload, role_form, listFeatures
   };
}

const connectRoleForm = connect(mapStateToProps)(RoleForm);

export default reduxForm({
   form: FORM_NAME,
   validate: validate,
   enableReinitialize: true,
   onSubmitFail: (errors, dispatch, props) => {
      crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
   }
})(withStyles(style)(connectRoleForm))


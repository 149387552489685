import React from "react";
import { Field, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import lodash from "lodash";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import { ocrReportActions } from "actions";
import TableOcrSummaryReport from "./TableOcrSummaryReport";
import CloudDownload from "@material-ui/icons/CloudDownload";

import { orange, grey, lightGreen } from "@material-ui/core/colors";
import { plugins } from "helpers";
import CountTo from 'react-count-to';

const FORM_NAME = "FORM_FILTER_OCR_REPORT";

const style = {};

class OcrSummaryReport extends React.Component {
    componentDidMount() {
        const { dispatch, initialValues } = this.props;
        let { startDate, endDate } = initialValues;
        let queryParams = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "date",
            sortDir: "desc",
            search :{
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
            }
        };

        dispatch(ocrReportActions.getOcrReport(queryParams));
    }

    componentWillUnmount() {
        this.clearFilter();
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch(reset(FORM_NAME));
        dispatch({ type: "CLEAR_EARN_SUMMARY_REPORT_FILTER" });
    };

    handleSubmit(values) {
        
        const { dispatch,tableFilter } = this.props;
        let tableFilterParam = {
            ...tableFilter,
            search :{
                startDate: moment(values.startDate).format("YYYY-MM-DD"),
                endDate: moment(values.endDate).format("YYYY-MM-DD"),
            }
        };
        dispatch(ocrReportActions.getOcrReport(tableFilterParam));
    }

    render() {
        const {
            FORM_FILTER_OCR_REPORT,
            handleSubmit,
            totalRequest
        } = this.props;
        
        let startMaxDate = new Date();
        if (lodash.has(FORM_FILTER_OCR_REPORT, "values.endDate")) {
            startMaxDate = FORM_FILTER_OCR_REPORT.values.endDate;
        }

        let endMinDate;
        if (lodash.has(FORM_FILTER_OCR_REPORT, "values.startDate")) {
            endMinDate = FORM_FILTER_OCR_REPORT.values.startDate;
        }

        return (
            <>
                <div>
                    <form
                        name={FORM_NAME}
                        onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={4} lg={4}>
                                <Field
                                    name='startDate'
                                    component={CustomDatePicker}
                                    type='text'
                                    maxDate={startMaxDate}
                                    labelText='Start Date'
                                    dateFormat='DD-MMM-YY'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4} lg={4}>
                                <Field
                                    name='endDate'
                                    minDate={endMinDate}
                                    maxDate={new Date(moment())}
                                    component={CustomDatePicker}
                                    type='text'
                                    labelText='End Date'
                                    dateFormat='DD-MMM-YY'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3} lg={3}>
                                <Button
                                    id='button-earn-summary-report-clear'
                                    onClick={this.clearFilter}
                                    style={{
                                        marginTop: "25px",
                                    }}
                                    size='sm'
                                    color='white'>
                                    Clear
                                </Button>
                                <Button
                                    type='submit'
                                    id='button-earn-summary-report-submit'
                                    style={{
                                        marginRight: "10px",
                                        marginTop: "25px",
                                        marginLeft: "15px",
                                    }}
                                    size='sm'
                                    color='primary'>
                                    Submit
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </div>
                <GridContainer style={{ marginTop: "30px" }}>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                        <div style={{ padding: "0px 25px" }}>
                            <TotalCard color="total" label="Total Request" total={totalRequest}/>
                        </div>
                    </GridItem>
                </GridContainer>
                <div>
                    <TableOcrSummaryReport />
                </div>
            </>
        );
    }
}

const TotalCard = (props) => {
    const { color, label, total } = props
    let bgColor, borderColor = ""
    switch (color) {
        case "success": {
            bgColor = orange[200]
            borderColor = orange[500]
            break;
        }
        case "unsuccess": {
            bgColor = grey[200]
            borderColor = grey[500]
            break;
        }
        default: {
            bgColor = lightGreen[200]
            borderColor = lightGreen[500]
            break;
        }
    }
    return (
        <div className="text-center" style={{
            backgroundColor: bgColor,
            border: "solid 2px" + borderColor,
            borderRadius: "5px",
            }}>
            <h4 style={{ margin: "10px", fontWeight: 500 }}>{label}</h4>
            <h3 style={{ fontWeight: 900 }}>
                <CountTo to={total?total:0} speed={1000}>{fnAfterCountto}</CountTo>
            </h3>
        </div>
    )
}

const fnAfterCountto = value => <span>{plugins.numberFormat(value)}</span>;

function mapStateToProps(state) {
    const { FORM_FILTER_OCR_REPORT } = state.form;
    const { totalRequest, tableFilter} = state.ocrReport;
    return {
        FORM_FILTER_OCR_REPORT,
        totalRequest,
        tableFilter
    };
}

const connectedComponent = connect(mapStateToProps)(OcrSummaryReport);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent));

import { reportConstants, memberConstants } from "../constants";
import { reportServices } from "../services";
import { loader, alert as customAlert } from "../helpers";
import lodash from "lodash";
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const reportActions = {
  getProductList,
  getBurnCampaignList,
  getEarnTransactionList,
  getBurnTransactionList,
  getActiveTagList,
  getLuckyDrawDowloadList,
  getMemberReport,
  getEarnTransactionById,
  getEarnTransactionByIdImages,
  getTransactionById,
  getTransactionByIdVitamilk,
  updateTransaction,
  getEarnRuleLists
};

function getProductList(params) {
  return dispatch => {
    // loader(dispatch, true)
    reportServices
      .getListsProduct(params)
      .then(
        response => {
          if (response.status) {
            if (lodash.has(response, "data.products")) {
              dispatch(success(response.data.products));
            }
          } else {
            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        // loader(dispatch, false)
      });
  };

  function success(data) {
    return { type: reportConstants.GET_DROPDOWN_PRODUCT_LIST_SUCCESS, data };
  }
}

function getBurnCampaignList(params) {
  return dispatch => {
    // loader(dispatch, true)
    reportServices
      .getCampaignsList(params)
      .then(
        response => {
          if (response.status) {
            if (lodash.has(response, "data.campaigns")) {
              dispatch(success(response.data.campaigns));
            }
          } else {
            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
  };

  function success(data) {
    return { type: reportConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data };
  }
}

function getEarnRuleLists(params) {
  return dispatch => {
      // loader(dispatch, true)
      reportServices
        .getEarnRuleLists(params)
        .then(
            response => {
                try {
                    if (response.status) {
                        dispatch(success(response.data.earnRules));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                } catch (e) {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            },
            error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
        )
  }

  function success(data) { 
    return { type: reportConstants.GET_EARN_RULES_DATA_SUCCESS, data: data } 
  }
}

function getEarnTransactionList(params, tableReset = false) {
  return dispatch => {
    loader(dispatch, true);
    reportServices
      .getEarnTransactionList(params)
      .then(
        response => {
          if (response.status) {
            dispatch({
              type: reportConstants.GET_EARN_TRANSACTON_SUCCESS,
              data: response.data.data,
              total: response.data.total,
              tableFilter: params
            });
            if (tableReset) {
              dispatch({ type: reportConstants.EARN_TRANSACTON_TABLE_RESET });
            }
          } else {
            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false);
      });
  };
}

function getBurnTransactionList(params, tableReset = false) {
  return dispatch => {
    loader(dispatch, true);
    reportServices
      .getBurnTransactionList(params)
      .then(

        response => {
          if (response.status) {
            dispatch({
              type: reportConstants.GET_BURN_TRANSACTON_SUCCESS,
              data: response.data.data,
              total: response.data.total,
              tableFilter: params
            });
            if (tableReset) {
              dispatch({ type: reportConstants.BURN_TRANSACTON_TABLE_RESET });
            }
          } else {
            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false);
      });
  };
}

function getActiveTagList() {
  return dispatch => {
    dispatch({ type: memberConstants.GET_MEMBER_TAGLIST_REQUEST });
    loader(dispatch, true);
    reportServices
      .getActiveTagList()
      .then(
        response => {
          if (response.status) {
            dispatch(success(response.data));
          } else {
            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false);
      });
  };

  function success(tmp) {
    return { type: memberConstants.GET_MEMBER_TAGLIST_SUCCESS, tmp };
  }
}

function getLuckyDrawDowloadList(params, tableReset = false) {
  let strParams = ''

  if (typeof params === 'object') {
    strParams = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  }
  else {
    strParams = params
  }

  return dispatch => {
    loader(dispatch, true);
    reportServices
      .getLuckyDrawDowloadList(strParams)
      .then(
        response => {
          if (response.status === true) {
            if (response.data) {
              dispatch({
                type: reportConstants.GET_LUCKYDRAW_DOWLOAD_SUCCESS,
                data: response.data.data,
                total: response.data.total,
                tableFilter: params
              });
              if (tableReset) {
                dispatch({ type: reportConstants.LUCKYDRAW_DOWLOAD_RESET });
              }
            } else {
              if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
            }
          } else {
            dispatch({
              type: reportConstants.GET_LUCKYDRAW_DOWLOAD_SUCCESS,
              data: [],
              total: 0,
              tableFilter: params
            });
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false);
      });
  };
}

function getMemberReport(params, tableReset = false) {
  return dispatch => {
    loader(dispatch, true);
    reportServices
      .getMemberReport(params)
      .then(
        response => {
          if (response.status) {
            dispatch({
              type: reportConstants.GET_MEMBER_REPORT_SUCCESS,
              ageFilter: response.data.ageFilter,
              ageRangeMode: response.data.ageRangeMode,
              tableTotal: response.data.total,
              setting: response.data.setting
            });
            if (tableReset) {
              dispatch({ type: reportConstants.INSIGHT_TABLE_RESET_SUCCESS });
            }
          } else {
            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
          }
        },
        error => {
          customAlert(dispatch, 'error', i18n.t(defaultFailedText))
        }
      )
      .then(() => {
        loader(dispatch, false);
      });
  };
}

function getTransactionById(_id) {
  return dispatch => {
    loader(dispatch, true)
    reportServices.getEarnTransactionById(_id)
    .then(
      response => {
        if (response.status) {
          dispatch({ type: reportConstants.OPEN_EARN_REPORT_EDIT, data : lodash.get(response, 'data') })
        }
      },
      error => {
        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
      }
    )
    .then(() => {
      loader(dispatch, false);
    });
  }
}
function getTransactionByIdVitamilk(_id) {
  return dispatch => {
    loader(dispatch, true)
    reportServices.getEarnTransactionById(_id)
    .then(
      response => {
        if (response.status) {
          dispatch({ type: reportConstants.OPEN_EARN_REPORT_EDIT_VITAMILK, data : lodash.get(response, 'data') })
        }
      },
      error => {
        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
      }
    )
    .then(() => {
      loader(dispatch, false);
    });
  }
}

function updateTransaction(params){
  console.log('aaa:', params)
  return dispatch => {
     loader(dispatch, true)
     reportServices.editTransaction(params)
        .then(
           response => {
              if(response.status){
                 dispatch({ type: reportConstants.EDIT_EARN_REPORT_SUCCESS });
                 customAlert(dispatch, 'success', i18n.t('warning_messages.transaction_was_updated'));
              }else{
                 if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
              }
           },
           error => {
              customAlert(dispatch, 'error', i18n.t(defaultFailedText))
           }
        ).then(() => {
           loader(dispatch, false)
        })
  };
}

// function getTransactionById(_id) {
//   return dispatch => {
//      loader(dispatch, true)
//      reportServices.getEarnTransactionById(_id)
//         .then(
//            tmp => {
//               if (tmp.status) {
//                  dispatch(success(tmp));
//               } else {
//                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
//               }
//            },
//            error => {
//               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
//            }
//         ).then(() => {
//            loader(dispatch, false)
//         })
//   };
//   function success(tmp) { return { type: reportConstants.OPEN_EARN_REPORT_EDIT, formInitialValue : tmp.data } }
// }

function getEarnTransactionById(_id) {
  return dispatch => {
    loader(dispatch, true)
    reportServices.getEarnTransactionById(_id)
    .then(
      response => {
        if (response.status) {
          dispatch({ type: "OPEN_EARN_REPORT_DETAIL", data: lodash.get(response, 'data') });
        }
      },
      error => {
        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
      }
    )
    .then(() => {
      loader(dispatch, false);
    });
  }
}

function getEarnTransactionByIdImages(_id) {
  return dispatch => {
    loader(dispatch, true)
    reportServices.getEarnTransactionByIdImages(_id)
    .then(
      response => {
        if (response.status) {
          dispatch({ type: "OPEN_EARN_REPORT_IMAGES", data: lodash.get(response, 'data') });
        }
      },
      error => {
        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
      }
    )
    .then(() => {
      loader(dispatch, false);
    });
  }
}


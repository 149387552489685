import { configConstants } from '../constants';
import axios from 'axios';
import lodash from 'lodash';
import { history, crmValidator, setHeaders } from 'helpers';

export const importServices = {
    importFile,
    getImportHistory,
    getDetailById,
    downloadTemplate
};

function importFile(params) {
    try {
        let formData = new FormData();

        if (!crmValidator.isEmptyValue(params)) {
            lodash.forEach(params, function (value, key) {
                if (key === "file") {
                    formData.append('file', value[0]);
                } else {
                    formData.append(key, value)
                }
            })
        }

        var tmp = axios.post(configConstants.API_BACKEND_CRM + '/sales-page/product-sales/import', formData, {
            headers: setHeaders({ 'Charset': 'utf-8' })
        })
            .then(response => {
                if (response.data.statusCode.toString() === "200") {
                    return {
                        status: true,
                        data: response.data.data,
                        message: response.data.message
                    };
                } else if (response.data.message === "UNAUTHORIZE") {
                    history.push('/auth/login')
                    return { status: false, data: {}, message: response.data.message, unAlert: true };
                } else {
                    return {
                        status: false,
                        data: response.data,
                        message: response.data.message
                    };
                }
            })
            .catch(error => {
                return {
                    status: false,
                    data: {
                        statusCode: 500
                    },
                    message: 'File not found, Please select file and try again.'
                };
            })
        return tmp;
    } catch (e) {
        return {
            status: false,
            data: {
                statusCode: 500
            },
            message: 'File not found, Please select file and try again.'
        };
    }
}

function getImportHistory(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/import?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getDetailById(importId) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/import/${importId}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function downloadTemplate(type) {
    axios({
        url: configConstants.API_BACKEND_CRM + `/download/import-template?type=${type}`,
        method: 'GET',
        responseType: 'blob', // important,
        headers: setHeaders({ 'Charset': 'utf-8' })

    }).then((response) => {
        // let resContentType = lodash.get(response, 'headers.content-type')
        // if(resContentType === "text/csv; charset=utf-8"){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Template_${type}.csv`);
            document.body.appendChild(link);
            link.click();
        // }
    });
}
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, touch, untouch, stopSubmit, SubmissionError } from 'redux-form';
import { crmValidator } from 'helpers';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx"
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import { store, normalize, alert as customAlert } from 'helpers';
import { missionConstants } from '../../constants';
import { missionActions } from '../../actions';
import * as FormValidator from "../Mission/validate/FormValidator"
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import FormMissionPrivilegeType1 from "./components/FormMissionPrivilegeType1"
import FormMissionPrivilegeType2 from "./components/FormMissionPrivilegeType2"

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import lodash from 'lodash'
import moment from 'moment';
import i18n from 'i18n';

const FORM_NAME = "FORM_MISSION";
const style = {
    ...modalStyle,
    cardBody: {
        padding: "15px"
    },
    cardHeader: {
        marginLeft: "0px",
        color: "#333"
    },
    formFooter: {
        textAlign: 'right'
    },
    btnSubmit: {
        marginRight: "20px"
    },
    conditionBlock: {
        padding: "10px",
        marginLeft: "0px",
        border: "dashed 1px #ccc"
    },
    tdBordered: {
        border: "solid 1px #ddd"
    },
    subHeader: {
        fontWeight: 900,
        fontSize: "16px",
        marginTop: "20px"
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(FormValidator.form_validate, values);
    return errors
}
const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ"
class MissionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        this.getCampaignList()
        dispatch(missionActions.getLuckyDrawRewardSetList());
    }

    getCampaignList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"
        dispatch(missionActions.getCampaignList(reqParams))
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props;
        let data = {}
        if (values.startDate) {
            data = {
                ...data,
                "startDate": moment(values.startDate).format(dbDateFormat)
            }
        }
        if (values.endDate) {
            data = {
                ...data,
                "endDate": moment(values.endDate).format(dbDateFormat)
            }
        }
        let condType = 0
        switch (values.loginCondition) {
            case 'continuouslyUntil': {
                condType = 0; break;
            }
            case 'continuouslyEveryDay': {
                condType = 1; break;
            }
            case 'justLoginNoNeedContinue': {
                condType = 2; break;
            }
            case 'justLoginGetPrivilegeBySequence': {
                condType = 3; break;
            }
            case 'justLoginGetPrivilegeOnTheDay': {
                condType = 4; break;
            }
            default: condType = 0
        }
        data = {
            ...data,
            "_id": values._id,
            "missionId": values._id,
            "missionName": values.missionName,
            "status": values.status,
            "missionType": values.missionType,
            "loginCondition": {
                conditionType: condType,
                contLoginDay: lodash.get(values, 'continuouslyLoginUntil') || "",
                contLoginType: lodash.get(values, 'continuouslyLoginEverySelectDays') || "",
                nonContLoginDay: lodash.get(values, 'justLoginNoNeedContinue') || "",
            },
            "missionPrivilege": {
                redemption: 0,
                point: lodash.includes(values.missionPrivilege, "point"),
                reward: lodash.includes(values.missionPrivilege, "reward"),
                luckyChance: lodash.includes(values.missionPrivilege, "luckyChance")
            },
            "earnReward": {
                achievePoint: lodash.get(values, 'earnReward.achievePoint')
            }
        }

        if ([3, 4].includes(condType)) {
            let achieveList = lodash.get(values, 'achieveList')

            lodash.set(data, 'missionPrivilege.redemption', lodash.get(values, 'rewardRedemption', "0"))

            if (Array.isArray(achieveList)) {
                achieveList = Object.assign({}, achieveList);
            }

            if (achieveList) {

                for (const [key, value] of Object.entries(achieveList)) {
                    achieveList[key] = { ...value, no: key }
                }

                achieveList = lodash.filter(achieveList, (o) => {
                    return lodash.get(o, 'point', false) || lodash.get(o, 'campaignId', false) || lodash.get(o, 'reward', false) || lodash.get(o, 'luckyChance', false)
                })

                data = {
                    ...data,
                    earnReward: {
                        achieveList: achieveList
                    }
                }
            } else {
                // dispatch(stopSubmit(FORM_NAME, {
                //     achieveList: 'Please enter reward',
                // }))
                // throw new SubmissionError({
                //     achieveList: 'Please enter reward'
                // })
                customAlert(dispatch, 'error', 'Please enter reward')
                return false
            }
        }

        if (this.props.formMode === "add") {
            dispatch(missionActions.addMission(data))
        }
        else {
            dispatch(missionActions.updateMission(data));
        }
    }

    setStatusDefault = () => {
        const { dispatch } = this.props
        dispatch(change("FORM_MISSION", "status", "Active"));
    }


    render() {
        const { formOpen, formMode, classes, dispatch, handleSubmit, FORM_MISSION, startDateOriginal } = this.props;
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }
        const displayCard = formOpen ? { padding: "15px" } : { display: "none" };
        const missionTypeList = [
            {
                id: 'select_box_active',
                value: "Login",
                label: "Login"
            },
        ]
        const everyOptionList = [
            {
                id: 'day',
                value: "day",
                label: "Day"
            },
            {
                id: 'week',
                value: "week",
                label: "Week"
            },
            {
                id: 'month',
                value: "month",
                label: "Month"
            },
        ]
        const validate_field = {
            numberOnly: {
                rules: {
                    required: false,
                    number: true,
                    noSpecialCharacters: true,
                },
            }
        }
        let today = new Date();
        const disableDate = today > new Date(startDateOriginal) && startDateOriginal != null ? true : false
        let minDateCalendar = today.setDate(today.getDate() - 1)
        if (formMode === `edit` && disableDate) {
            minDateCalendar = startDateOriginal
        }
        return (
            <Card style={displayCard}>
                <CardBody>
                    <CustomSweetAlert />
                    <h4 className={classes.modalTitle}>
                        <b>
                            {formMode === "add"
                                ? i18n.t("mission.add_mission")
                                : readOnly
                                    ? i18n.t("mission.mission_info")
                                    : i18n.t("mission.edit_mission")}
                        </b>
                    </h4>
                    <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                        <GridContainer>
                            <GridItem xs={12} sm={8} md={8}>
                                <Field
                                    id="missionName"
                                    name="missionName"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Mission Name*"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id="status"
                                    name="status"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Status*"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            id: 'select_box_active',
                                            value: "Active",
                                            label: "Active"
                                        },
                                        {
                                            id: 'select_box_inactive',
                                            value: "Inactive",
                                            label: "Inactive"
                                        }
                                    ]}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='start-date'
                                    name="startDate"
                                    component={CustomDatePicker}
                                    type="text"
                                    minDate={minDateCalendar}
                                    maxDate={lodash.get(FORM_MISSION, 'values.endDate')}
                                    labelText="Start Date*"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={disableDate}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='end-date'
                                    name="endDate"
                                    minDate={lodash.get(FORM_MISSION, 'values.startDate') ? lodash.get(FORM_MISSION, 'values.startDate') : minDateCalendar}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText="End Date*"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={disableDate}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='mission-type'
                                    name="missionType"
                                    component={CustomSelect}
                                    onChange={this.setStatusDefault}
                                    type="text"
                                    labelText="Mission Type*"
                                    disabled={readOnly}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={missionTypeList}
                                />
                            </GridItem>
                        </GridContainer>

                        <GridContainer className={classes.conditionBlock} style={{ width: "100%" }}>
                            <GridItem xs={12}>
                                <h4><b>Login Condition</b></h4>
                                <GridContainer style={{ marginBottom: '-10px' }}> {/* Continuously log in until ____ day(s) */}
                                    <GridItem xs={3} sm={3} md={3}>
                                        <Field
                                            id="login-condition-radio"
                                            name="loginCondition"
                                            component={CustomRadio}
                                            onChange={(e) => {
                                                if (lodash.get(e, 'target.value') === 'continuouslyUntil') {
                                                    dispatch(change(FORM_NAME, 'continuouslyLoginEverySelectDays', null))
                                                    dispatch(untouch(FORM_NAME, 'continuouslyLoginEverySelectDays'))

                                                    dispatch(change(FORM_NAME, 'justLoginNoNeedContinue', null))
                                                    dispatch(untouch(FORM_NAME, 'justLoginNoNeedContinue'))

                                                    dispatch(untouch(FORM_NAME, 'continuouslyLoginUntil', null))
                                                }
                                            }}
                                            radioLists={[
                                                {
                                                    value: "continuouslyUntil",
                                                    label: (
                                                        <span style={{ fontWeight: "300" }}>
                                                            Continuously log in until
                                                        </span>
                                                    ),
                                                    id: "continuouslyUntil",
                                                    disabled: readOnly
                                                },
                                            ]}
                                        />
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} style={{ marginLeft: "-50px", marginTop: "-15px" }}>
                                        <Field
                                            id="continuously-login-until"
                                            name="continuouslyLoginUntil"
                                            component={CustomInput}
                                            type="text"
                                            formControlProps={{ fullWidth: true }}
                                            disabled={
                                                readOnly ||
                                                !(
                                                    lodash.get(
                                                        FORM_MISSION.values,
                                                        "loginCondition"
                                                    ) === "continuouslyUntil"
                                                )
                                            }
                                            normalize={(value) => {
                                                return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                                            }}
                                            validate={lodash.get(FORM_MISSION.values, "loginCondition") === 'continuouslyUntil' ? FormValidator.validateIsZero : null}
                                        />
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} style={{ marginTop: "15px", marginLeft: "-15px" }} >
                                        day(s)
                                </GridItem>
                                </GridContainer>
                                <GridContainer style={{ marginBottom: '-10px' }}> {/* Continuously log in every ____ until campaign end to get privilege */}
                                    <GridItem xs={3} sm={3} md={3}>
                                        <Field
                                            id="login-condition-radio"
                                            name="loginCondition"
                                            component={CustomRadio}
                                            onChange={(e) => {
                                                if (lodash.get(e, 'target.value') === 'continuouslyEveryDay') {
                                                    dispatch(change(FORM_NAME, 'continuouslyLoginUntil', null))
                                                    dispatch(untouch(FORM_NAME, 'continuouslyLoginUntil'))

                                                    dispatch(change(FORM_NAME, 'justLoginNoNeedContinue', null))
                                                    dispatch(untouch(FORM_NAME, 'justLoginNoNeedContinue'))

                                                    dispatch(untouch(FORM_NAME, 'continuouslyLoginEverySelectDays', null))
                                                }
                                            }}
                                            radioLists={[
                                                {
                                                    value: "continuouslyEveryDay",
                                                    label: (
                                                        <span style={{ fontWeight: "300" }}>
                                                            Continuously log in every
                                                        </span>
                                                    ),
                                                    id: "continuouslyEveryDay",
                                                    disabled: readOnly
                                                },
                                            ]}
                                        />
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} style={{ marginLeft: "-50px", marginTop: "-15px" }}>
                                        <Field
                                            id="continuously-login-every-select-days"
                                            name="continuouslyLoginEverySelectDays"
                                            component={CustomSelect}
                                            type="number"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            optionsList={everyOptionList}
                                            disabled={
                                                readOnly ||
                                                !(
                                                    lodash.get(
                                                        FORM_MISSION.values,
                                                        "loginCondition"
                                                    ) === "continuouslyEveryDay"
                                                )
                                            }
                                            validate={lodash.get(FORM_MISSION.values, "loginCondition") === 'continuouslyEveryDay' ? FormValidator.validateDateSelect : null}
                                        />
                                    </GridItem>
                                    <GridItem xs={5} sm={5} md={5} style={{ marginTop: "15px", marginLeft: "-15px" }} >
                                        until campaign end to get privilege
                                </GridItem>
                                </GridContainer>
                                <GridContainer style={{ marginBottom: '-10px' }}> {/* Just log in (No need to continue) until ____ day(s) */}
                                    <GridItem xs={4} sm={4} md={4}>
                                        <Field
                                            id="login-condition-radio"
                                            name="loginCondition"
                                            component={CustomRadio}
                                            onChange={(e) => {
                                                if (lodash.get(e, 'target.value') === 'justLoginNoNeedContinue') {
                                                    dispatch(change(FORM_NAME, 'continuouslyLoginUntil', null))
                                                    dispatch(untouch(FORM_NAME, 'continuouslyLoginUntil'))

                                                    dispatch(change(FORM_NAME, 'continuouslyLoginEverySelectDays', null))
                                                    dispatch(untouch(FORM_NAME, 'continuouslyLoginEverySelectDays'))

                                                    dispatch(untouch(FORM_NAME, 'justLoginNoNeedContinue', null))
                                                }
                                            }}
                                            radioLists={[
                                                {
                                                    value: "justLoginNoNeedContinue",
                                                    label: (
                                                        <span style={{ fontWeight: "300" }}>
                                                            Just log in (No need to continue) until
                                                        </span>
                                                    ),
                                                    id: "justLoginNoNeedContinue",
                                                    disabled: readOnly
                                                },
                                            ]}
                                        />
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} style={{ marginLeft: "-60px", marginTop: "-15px" }}>
                                        <Field
                                            id="just-login-no-need-continue"
                                            name="justLoginNoNeedContinue"
                                            component={CustomInput}
                                            type="text"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            disabled={
                                                readOnly ||
                                                !(
                                                    lodash.get(
                                                        FORM_MISSION.values,
                                                        "loginCondition"
                                                    ) === "justLoginNoNeedContinue"
                                                )
                                            }
                                            normalize={(value) => {
                                                return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                                            }}
                                            validate={lodash.get(FORM_MISSION.values, "loginCondition") === 'justLoginNoNeedContinue' ? FormValidator.validateIsZero : null}
                                        />
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} style={{ marginTop: "15px", marginLeft: "-15px" }} >
                                        day(s)
                                </GridItem>
                                </GridContainer>
                                <GridContainer> {/* Just log in to get privilege by sequence & on the day */}
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Field
                                            id="login-condition-radio"
                                            name="loginCondition"
                                            component={CustomRadio}
                                            onChange={(e) => {
                                                if (lodash.get(e, 'target.value') === 'justLoginGetPrivilegeBySequence' || lodash.get(e, 'target.value') === 'justLoginGetPrivilegeOnTheDay') {
                                                    dispatch(change(FORM_NAME, 'continuouslyLoginUntil', null))
                                                    dispatch(untouch(FORM_NAME, 'continuouslyLoginUntil'))

                                                    dispatch(change(FORM_NAME, 'continuouslyLoginEverySelectDays', null))
                                                    dispatch(untouch(FORM_NAME, 'continuouslyLoginEverySelectDays'))

                                                    dispatch(change(FORM_NAME, 'justLoginNoNeedContinue', null))
                                                    dispatch(untouch(FORM_NAME, 'justLoginNoNeedContinue'))
                                                }
                                            }}
                                            radioLists={[
                                                {
                                                    value: "justLoginGetPrivilegeBySequence",
                                                    label: (
                                                        <span style={{ fontWeight: "300" }}>
                                                            Just log in to get privilege by sequence
                                                        </span>
                                                    ),
                                                    id: "justLoginGetPrivilegeBySequence",
                                                    disabled: readOnly
                                                }, {
                                                    value: "justLoginGetPrivilegeOnTheDay",
                                                    label: (
                                                        <span style={{ fontWeight: "300" }}>
                                                            Just log in to get privilege on the day
                                                        </span>
                                                    ),
                                                    id: "justLoginGetPrivilegeOnTheDay",
                                                    disabled: readOnly
                                                },
                                            ]}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>

                        <GridContainer style={{ paddingRight: '35px' }}>
                            <GridItem xs={12} style={{ margin: "10px" }}>
                                <h4><b>Mission Privilege</b></h4>
                            </GridItem>
                            <GridItem xs={12} style={{ margin: "10px", marginBottom: '-30px' }}>
                                <span style={{ marginRight: "20px" }}>Privilege Type</span>
                                <Field
                                    name="missionPrivilege"
                                    component={CustomSelect}
                                    multiple={true}
                                    type="text"
                                    labelText="Select Privilege"
                                    formControlProps={{
                                        fullWidth: false,
                                        style: { minWidth: "362px", top: "-30px" }
                                    }}
                                    showtick={true}
                                    disabled={readOnly}
                                    optionsList={[
                                        {
                                            value: "point",
                                            label: "Point",
                                            disabled: false
                                        },
                                        // {
                                        //     value: "reward",
                                        //     label: "Reward",
                                        //     disabled: false
                                        // },
                                        // {
                                        //     value: "luckyChance",
                                        //     label: "Lucky Draw Chance",
                                        //     disabled: false
                                        // }
                                    ]}
                                    validate={[FormValidator.validatePrivilegeTypes]}
                                />
                            </GridItem>
                        </GridContainer>

                        {['continuouslyUntil', 'continuouslyEveryDay', 'justLoginNoNeedContinue'].indexOf(lodash.get(FORM_MISSION.values, "loginCondition", '')) != -1 ? <FormMissionPrivilegeType1 props={this.props} /> : null}
                        {['justLoginGetPrivilegeBySequence', 'justLoginGetPrivilegeOnTheDay'].indexOf(lodash.get(FORM_MISSION.values, "loginCondition", '')) != -1 ? <FormMissionPrivilegeType2 props={this.props} /> : null}

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
                                <Button type="submit" color="primary">
                                    {i18n.t("submit")}
                                </Button>
                                <Button
                                    type="button"
                                    onClick={() => { dispatch({ type: 'CLOSE_FORM_MISSION' }) }}
                                    style={{ marginLeft: "10px" }}
                                    color="white"
                                >
                                    {i18n.t("cancel")}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode, readOnly, campaignList, rewardList, startDateOriginal } = state.mission
    const { FORM_MISSION } = state.form;
    return {
        formOpen, formMode, FORM_MISSION, readOnly, campaignList, rewardList, startDateOriginal
    };
}

const connectMissionForm = connect(mapStateToProps)(MissionForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    // onSubmitFail: (errors, dispatch, props, values) => {
    //     if (lodash.get(values, 'values.continuouslyLoginUntil') === null) dispatch(change(FORM_NAME, 'continuouslyLoginUntil', ''))
    //     if (lodash.get(values, 'values.continuouslyLoginEverySelectDays') === null) dispatch(change(FORM_NAME, 'continuouslyLoginEverySelectDays', ''))
    //     if (lodash.get(values, 'values.justLoginNoNeedContinue') === null) dispatch(change(FORM_NAME, 'justLoginNoNeedContinue', ''))
    //     crmValidator.alertFirstInvalid(FORM_NAME, errors, FormValidator.form_validate);
    // }
})(withStyles({ ...style, ...regularFormsStyle })(connectMissionForm))
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import grey from '@material-ui/core/colors/grey';
import blueGrey from '@material-ui/core/colors/blueGrey';

import Badge from "components/Badge/Badge.jsx";

import i18n from 'i18n';

function ColorSelect({ ...props }) {
    const {
        input,
        classes,
        formControlProps,
        labelText,
        id,
        type,
        disabled,
        labelProps,
        inputProps,
        white,
        multiple = false,
        novalidate,
        meta
    } = props;
    
    const error = (meta !== undefined && meta.touched && meta.invalid);
    const success = (meta !== undefined && meta.touched && meta.valid);
    const labelClasses = classNames({
        [" " + classes.labelRootError]: error && !novalidate,
        [" " + classes.labelRootSuccess]: success && !error && !novalidate
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error && !novalidate,
        [classes.underlineSuccess]: success && !error && !novalidate,
        [classes.underline]: true,
        [classes.whiteUnderline]: white
    });

    const helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

    const ColorList = [{
        name: 'Red',
        value: red[500]
    },{
        name: 'Pink',
        value: pink[500]
    },{
        name: 'Purple',
        value: purple[500]
    },{
        name: 'Deep Purple',
        value: deepPurple[500]
    },{
        name: 'Indigo',
        value: indigo[500]
    },{
        name: 'Blue',
        value: blue[500]
    },{
        name: 'Light Blue',
        value: lightBlue[500]
    },{
        name: 'Cyan',
        value: cyan[500]
    },{
        name: 'Teal',
        value: teal[500]
    },{
        name: 'Green',
        value: green[500]
    },{
        name: 'Light Green',
        value: lightGreen[500]
    },{
        name: 'Lime',
        value: lime[500]
    },{
        name: 'Yellow',
        value: yellow[500]
    },{
        name: 'Amber',
        value: amber[500]
    },{
        name: 'Orange',
        value: orange[500]
    },{
        name: 'Deep Orange',
        value: deepOrange[500]
    },{
        name: 'Brown',
        value: brown[500]
    },{
        name: 'Grey',
        value: grey[500]
    },{
        name: 'Blue Grey',
        value: blueGrey[500]
    }]

    const selectItemInit = () => {
        return ColorList.map((data, i) => {
            return(
                <MenuItem key={i} value={data.value}>
                    <Badge key={i} color="primary" title={data.name} customStyle={{ backgroundColor: data.value, maxWidth: "100%" }}>{data.name}</Badge>
                </MenuItem>
            )
        })
    }

    return (
        <FormControl {...formControlProps} className={classes.selectFormControl + " " + underlineClasses}>
            <InputLabel
                className={classes.selectLabel + " " + labelClasses}
                htmlFor={id}
                {...labelProps}
            >
                {labelText}
            </InputLabel>
            <Select
                {...input}
                value={input.value}
                multiple={multiple}
                classes={{
                    select: classes.select,
                }}
                MenuProps={{
                    className: classes.selectMenu,
                }}
                type={type}
                disabled={disabled}
                {...inputProps}
            >
                {selectItemInit()}
            </Select>
            {(meta !== undefined && meta.touched && meta.invalid) === true ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {i18n.t(meta.error)}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
}

ColorSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helpText: PropTypes.node
};

export default withStyles(styles)(ColorSelect);

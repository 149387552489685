import { contentManagementConstants } from '../constants';
import { contentServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const contentActions = {
    getTableContentDatas,
    deleteContentDatas,
    updateContent,
    addContent,
};
function addContent(params) {
    return dispatch => {
        loader(dispatch, true)
        contentServices.addContent(params)
            .then(
                response => {  
                    try {
                        if (response.status) {
                            dispatch({ type: contentManagementConstants.ADD_CONTENT_SUCCESS });
                            customAlert(dispatch, 'success', i18n.t('Content was created successfully'));
                        } else {
                            if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                        }
                    } catch (e) {
                        customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }

}

function updateContent(params) {
    return dispatch => {
        loader(dispatch, true)
        contentServices.updateContent(params)
           .then(
              response => { 
                 if (response.status) {
                    dispatch({ type: contentManagementConstants.EDIT_CONTENT_MANAGEMENT_SUCCESS });
                    customAlert(dispatch, 'success', i18n.t('warning_messages.content_was_updated'));
                 } else {
                    if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                 }
              },
              error => {
                 customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
           ).then(() => {
              loader(dispatch, false)
           })
     };
}

function getTableContentDatas(params) {
    return dispatch => {
        loader(dispatch, true)
        contentServices.getTableContentDatas(params)
           .then(
              response => {
                 if (response.status) {
                    dispatch(success(response.data));
                 } else {
                    if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                 }
              },
              error => {
                 customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
           ).then(() => {
              loader(dispatch, false)
           })
     };
  
     function success(data) { return { type: contentManagementConstants.TABLE_GET_LIST_CONTENT_MANAGEMENT, data } }
}

function deleteContentDatas(params) {
    return dispatch => {
        loader(dispatch, true)
        contentServices.deleteContentDatas(params)
           .then(
              tmp => {
                 if (tmp.status) {
                    dispatch({ type: contentManagementConstants.DELETE_CONTENT_SUCCESS });
                    customAlert(dispatch, 'success', i18n.t('warning_messages.content_was_deleted'));
                 } else {
                    if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
                 }
              },
              error => {
                 customAlert(dispatch, 'error', i18n.t(defaultFailedText))
              }
           ).then(() => {
              loader(dispatch, false)
           })
     };
}
import moment from 'moment';
import lodash from 'lodash';
import { store } from 'helpers'

export const plugins = {
    datetimeFormat,
    dateFormatForDB,
    getFormatFilterAge,
    numberFormat,
    ageRangeFormat,
    datetimeFormatBE,
    isHasPermission,
    getAgeRange,
    setPermission,
    getRewardListByPackageFeature,
    getEarnRuleListByPacekageFeature,
    getEarnActListByPacekageFeature,
    isHasPackageFeatures
};

function isEmpty(value) {
    return (value === undefined || value === null || value === "")
}

function getDateFormatStr(type) {
    switch (type) {
        case "date": return "DD-MMM-YY";
        case "datetime": return "DD-MMM-YY HH:mm";
        case "fulldatetime": return "DD-MMM-YY HH:mm:ss";
        default: return "YYYY-MM-DDTHH:mm:ss.sssZ";
    }
}
function getAgeRange(data) {
    if (!isEmpty(data)) {
        //return moment(data.now()).format('DD-MM-YYYY');
        let newDate = new Date()
        let age = moment(newDate).diff(moment(data), 'years')
        return age
    }
    else {
        return ""
    }
}

function datetimeFormat(date, type) {
    if (!isEmpty(date)) {
        let formatStr = getDateFormatStr(type);
        return moment(date).format(formatStr);
    } else {
        return "";
    }
}

function datetimeFormatBE(date) {
    if (!isEmpty(date)) {
        let monthInt = moment(date).format("M") - 1;
        let monthNames = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        let monthStr = monthNames[monthInt];
        return moment(date).format(`DD ${monthStr} YYYY`);

    } else {
        return "";
    }
}

function dateFormatForDB(date) {
    if (!isEmpty(date)) {
        return moment(date).format('YYYY-MM-DDTHH:mm:ss.sssZ');
    } else {
        return "";
    }
}

function getFormatFilterAge(values, type) {
    const dateFormat = "YYYY-MM-DD";
    let today = moment().format(dateFormat);
    if (values === "null" || values === null || values === "") {
        return "unknown";
    } else if (values === "unlimit") {
        return false;
    } else {
        if (type === 'startDate') {
            return moment(today + ' 23:59:59').add((parseInt(values) + 1) * -1, 'years').add(1, 'days').format(dateFormat)
        } else {
            return moment(today + ' 00:00:00').add(values * -1, 'years').format(dateFormat)
        }
    }
}

function numberFormat(values, fp = 0) {
    if (typeof values == "number") {
        values = (values).toFixed(fp)
        values = values.toString();
    }
    return (values).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function ageRangeFormat(memberData) {
    if (lodash.has(memberData, 'ageRange')) {
        if (lodash.has(memberData, 'ageRange.min') && lodash.has(memberData, 'ageRange.max')) {
            if (memberData.ageRange.min === "0") {
                return `Under ${parseInt(memberData.ageRange.max) + 1} years old`;
            } else if (memberData.ageRange.max === "unlimit") {
                return `Since ${memberData.ageRange.min} years old`;
            } else {
                return `${memberData.ageRange.min} - ${memberData.ageRange.max} years old`;
            }

        } else if (lodash.has(memberData, 'ageRange.min') && !lodash.has(memberData, 'ageRange.max')) {
            return `Under ${parseInt(memberData.ageRange.min) + 1} years old`;

        } else if (!lodash.has(memberData, 'ageRange.min') && lodash.has(memberData, 'ageRange.max')) {
            return `Since ${memberData.ageRange.max} years old`;

        } else {
            return ""
        }
    }
    return "";
}

function isHasPermission(moduleName, permissionType, permission, superAdmin, package_features) {
    try {

        if (permissionType === "view") permissionType = "read"
        const storeData = store.getState().authentication
        // console.log('storeData', storeData)
        // const { superAdmin, permission, package_features } = storeData
        superAdmin = superAdmin || storeData.superAdmin
        permission = permission || storeData.permission
        package_features = package_features || storeData.package_features

        if (package_features.indexOf(moduleName) !== -1) {
            if (superAdmin) {
                return true;
            } else {

                let hasPermission = lodash.get(permission, moduleName) || []
                return hasPermission.indexOf(permissionType) !== -1
            }
        } else {
            return false;
        }
    } catch (e) {
        return false
    }
}

function isHasPackageFeatures(moduleName, superAdmin, package_features) {
    try {

        const storeData = store.getState().authentication
        // superAdmin = superAdmin || storeData.superAdmin
        package_features = package_features || storeData.package_features

        if (package_features.indexOf(moduleName) !== -1) {
            return true;
        }

        return false;
    } catch (e) {
        return false
    }
}

function setPermission(permissions, fieldName = 'permission', mock, ck) {
    ck = ck || "";
    mock = mock || {}

    for (const tmp of permissions) {
        let keyName = (ck === "") ? tmp.name : ck + `[${tmp.name}]`
        if (lodash.has(tmp, "subRoles")) {
            setPermission(tmp.subRoles, fieldName, mock, keyName)
        } else {
            Object.assign(mock, {
                [keyName]: tmp[fieldName]
            });
        }
    }

    return mock;
}

function getRewardListByPackageFeature (package_features, defaultListRewardType = [
    { value: "3rdPartyCode", label: "3rd party Code" },
    { value: "gift", label: "Gift" },
    { value: "point", label: "Add Point" },
    { value: "trueMoney", label: "True Money" },
    { value: "discount", label: "Discount" },
    { value: "couponCode", label: "Coupon Code" },
    { value: "luckyDraw", label: "Lucky Draw" },
    { value: "photoGiveaway", label: "Photo Giveaway" }
]) {
    let returnRewardLists = []
    let mapRewardName = [
        { value: "3rdPartyCode", feature: "campaign_reward[3rd_party_code]" },
        // { value: "gift", feature: "campaign_reward[gift_pickup]" },
        { value: "point", feature: "campaign_reward[addpoint]" },
        { value: "trueMoney", feature: "campaign_reward[truemoney]" },
        { value: "discount", feature: "campaign_reward[discount]" },
        { value: "couponCode", feature: "campaign_reward[coupon_code]" },
        { value: "luckyDraw", feature: "campaign_reward[lucky_draw]" },
        { value: "photoGiveaway", feature: "campaign_reward[photo_giveaway]" }
    ]

    for (let rewardDetail of defaultListRewardType) {
        let rewardName = lodash.get(rewardDetail, 'value')
        if (rewardName === 'gift') {
            if (lodash.indexOf(package_features, 'campaign_reward[gift_pickup]') >= 0 || lodash.indexOf(package_features, 'campaign_reward[gift_delivery]') >= 0) {
                returnRewardLists.push({ value: "gift", label: "Gift" },)
            }
        } else {
            let featureName = lodash.get(lodash.find(mapRewardName, { value: rewardName }), 'feature')
            if (lodash.indexOf(package_features, featureName) >= 0) {
                returnRewardLists.push(rewardDetail)
            }
        }
    }
    return returnRewardLists
}

function getEarnRuleListByPacekageFeature (package_features, defaulistTypeList = [
    {
        value: "purchaseAmount",
        label: "Purchase Amount",
    }, {
        value: "code",
        label: "Code"
    }, {
        value: "product",
        label: "Product"
    }
]) {
    let returnRewardLists = []
    let mapRuleType = [
        { value: "code", feature: "earn_rule[code]" },
        { value: "purchaseAmount", feature: "earn_rule[purchase_amount]" },
        { value: "product", feature: "earn_rule[product]" },
    ]

    for (let ruleTypes of defaulistTypeList) {
        let typeName = lodash.get(ruleTypes, 'value')
        let featureName = lodash.get(lodash.find(mapRuleType, { value: typeName }), 'feature')
        if (lodash.indexOf(package_features, featureName) >= 0) {
            returnRewardLists.push(ruleTypes)
        }
    }
    return returnRewardLists
}

function getEarnActListByPacekageFeature (package_features, defaulistTypeList = [
    {
      id: "update-profile-selected-value",
      value: "update_profile",
      label: "Update Profile"
    },
    {
      id: "register-selected-value",
      value: "register",
      label: "Register"
    },
    {
      id: "friend-get-friend-selected-value",
      value: "friend_get_friend",
      label: "Friend get Friend"
    }
  ]) {
    let returnRewardLists = []
    let mapRuleType = [
        { value: "update_profile", feature: "earn_activity[update_profile]" },
        { value: "register", feature: "earn_activity[register]" },
        { value: "friend_get_friend", feature: "earn_activity[friend_get_friend]" },
    ]

    for (let ruleTypes of defaulistTypeList) {
        let typeName = lodash.get(ruleTypes, 'value')
        let featureName = lodash.get(lodash.find(mapRuleType, { value: typeName }), 'feature')
        if (lodash.indexOf(package_features, featureName) >= 0) {
            returnRewardLists.push(ruleTypes)
        }
    }
    return returnRewardLists
}
import React from "react";
import { connect } from 'react-redux';
import { change, Field, getFormValues } from 'redux-form';
import lodash from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import Button from '@material-ui/core/Button';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { promotionConstants, swalConstants } from "../../../constants"
import red from '@material-ui/core/colors/red';
import { plugins } from 'helpers';
const moduleName = 'promotion'

const style = {
    overrides: {
        MuiTableRow: {
            root: {
                height: "30px"
            }
        }
    },
    tdBordered: {
        border: "solid 1px #ddd",
        height: "30px"
    }
}

const THEME = createMuiTheme(style);

const getTypeName = (typeName) => {
    let displayName
    if (typeName === "free_product") {
        displayName = "Free Product"

    } else if (typeName === "discount") {
        displayName = "Discount"

    } else if (typeName === "special_price") {
        displayName = "Buy product with special price"

    }
    return displayName
}

const renderFieldText = ({ ...props }) => {
    const { meta: { error, touched } } = props
    return (
        <div style={{ color: red[500] }}>{(touched && error) ? error : ""}</div>
    )
}

const validateRewardType = (value, allValues, props, name) => {
    let conditionsValue = lodash.get(allValues, 'rewards')
    let conditionTypes = lodash.get(allValues, 'rewardTypes')
    //* name = is FieldName must be `${xxx}_validator` *
    //${xxx}_validator is used for (redux-from fieldlevel-validation), it's a hidden field input for check values of promotion-conditions

    if ((lodash.indexOf(conditionTypes, 'free_product') > -1) && name === 'free_product_validator'){
        let findPurchaseProductCond = lodash.find(conditionsValue, function (o) {
            return (lodash.size(lodash.get(o, 'conditions')) > 0) && o.type === 'free_product'
        })
        if (!findPurchaseProductCond) {
            return "Free Product is required"
        }
    } else if ((lodash.indexOf(conditionTypes, 'special_price') > -1) && name === 'special_price_validator') {
        let findPurchaseProductCond = lodash.find(conditionsValue, function (o) {
            return (lodash.size(lodash.get(o, 'conditions')) > 0) && o.type === 'special_price'
        })
        if (!findPurchaseProductCond) {
            return "Buy product with special price is required"
        }
    } else if ((lodash.indexOf(conditionTypes, 'discount') > -1) && name === 'discount_validator') {
        let findPurchaseProductCond = lodash.find(conditionsValue, function (o) {
            return (lodash.size(lodash.get(o, 'conditions')) > 0) && o.type === 'discount'
        })
        if (!findPurchaseProductCond) {
            return "Discount is required"
        }
    }
}

const RewardBox = ({ ...props }) => {
    const { classes, formValues, dispatch, readOnly } = props
    let { rewardTypes = [] } = formValues

    const openRewardModal = (rewardType) => {
        dispatch({ type: promotionConstants.OPEN_REWARD_MODAL, rewardType: rewardType })
    }

    const deleteCondition = (typeName) => {
        let rewards = lodash.get(formValues, 'rewards')
        lodash.remove(rewards, function (n) {
            return n.type === typeName
        });

        lodash.remove(rewardTypes, function (n) {
            return n === typeName
        });

        dispatch(change('promotion_form', 'rewardTypes', rewardTypes))
        dispatch(change('promotion_form', 'rewardTypesKey', lodash.size(rewardTypes)))
        dispatch(change('promotion_form', 'rewards', rewards))
    }

    const getButtonText = (typeName) => {
        let returnText = "ADD"
        let rewards = lodash.get(formValues, 'rewards')
        let rewardTypesValue = lodash.find(rewards, { type: typeName })
        if (rewardTypesValue) {
            if (plugins.isHasPermission(moduleName, 'update')) {
                returnText = "EDIT"
            } else {
                returnText = "VIEW"
            }
        }
        return returnText
    }

    if (lodash.size(rewardTypes) > 0) {
        return (
            <MuiThemeProvider theme={THEME}>
                <Table className={classes.table} aria-label="spanning table" style={{marginBottom: "20px"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell key="condition_header" className={classes.tdBordered} align="center"><b>Reward Type</b></TableCell>
                            <TableCell key="condition_header_action" className={classes.tdBordered}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rewardTypes.map((typeName, index) => {
                            return (
                                <React.Fragment key={`PromotionRewardTableCell_${index}`}>
                                    { (index > 0) && (
                                        <TableRow key={`tablecell_and_${index}`}>
                                            <TableCell colSpan="2" className={classes.tdBordered}><b>AND</b></TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow key={`tablecell_${typeName}_${index}`}>
                                        <TableCell className={classes.tdBordered} key={`tablecell_${typeName}_${index}_0`}>
                                            {getTypeName(typeName)}
                                            <Field
                                                name={`${typeName}_validator`}
                                                validate={[validateRewardType]}
                                                component={renderFieldText}
                                            />
                                        </TableCell>
                                        <TableCell
                                            className={classes.tdBordered}
                                            size="sm"
                                            key={`tablecell_${typeName}_${index}_1`}
                                            align="center">
                                            <Button
                                                style={{ backgroundColor: "#eee" }}
                                                onClick={() => {
                                                openRewardModal(typeName)
                                            }}>{getButtonText(typeName)}</Button>

                                            {!readOnly &&
                                                <TableActionButton
                                                    actionType="delete"
                                                    onClick={(e) => {
                                                        dispatch({
                                                            type: swalConstants.SHOW, data: {
                                                                type: "warning",
                                                                title: "Are you sure you want to delete this reward?",
                                                                message: `"${getTypeName(typeName)}"`,
                                                                onConfirm: function () {
                                                                    deleteCondition(typeName)
                                                                    dispatch({ type: swalConstants.HIDE })
                                                                },
                                                                onCancel: function () {
                                                                    dispatch({ type: swalConstants.HIDE })
                                                                },
                                                                showCancel: true,
                                                                confirmBtnText: "Delete",
                                                                cancelBtnText: "Cancel"
                                                            }
                                                        })
                                                    }}
                                                />
                                            }
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </MuiThemeProvider>
        )
    } else {
        return null
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode } = state.promotion;
    const formValues = getFormValues("promotion_form")(state)
    return {
        formOpen, formMode, formValues
    };
}
const connectedForm = connect(mapStateToProps)(RewardBox);
export default withStyles(style)(connectedForm)

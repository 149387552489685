import React from "react";
import { Field, reduxForm, change, reset } from "redux-form";
import { connect } from "react-redux";
import lodash from "lodash";
import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { luckyDrawReportActions } from "actions";
import TableLuckyDraw from "./TableLuckyDraw.jsx";

const style = {};

const FORM_NAME = "FORM_FILTER_LUCKYDRAW";

class LuckyDraw extends React.Component {
  componentDidMount() {
    const { dispatch, luckyDraw } = this.props;
    const { tableFilter } = luckyDraw;
    dispatch(luckyDrawReportActions.getLuckyDrawDowloadList(tableFilter));
    dispatch(luckyDrawReportActions.getLuckyDrawRewardSetList());
  }

  handleSubmit(values) { 
    const { dispatch, luckyDraw } = this.props;
    const { tableFilter } = luckyDraw
        let _tableFilter = {
            page: tableFilter.page,
            skip: tableFilter.skip,
            limit: tableFilter.limit,
            keyword: "",
        }
        if(lodash.get(values, 'rewardName')){
          _tableFilter = {
            ..._tableFilter,
            id: lodash.get(values, 'rewardName')
          }
        }

    let search = { ...values };

    if (lodash.size(search) > 0) {
      if (
        lodash.has(values, "startDate._i") &&
        lodash.has(values, "endDate._i")
      ) {
        lodash.set(
          _tableFilter,
          "startDate",
          moment(values.startDate._i).format("YYYY-MM-DD")
        );
        lodash.set(
          _tableFilter,
          "endDate",
          moment(values.endDate._i).format("YYYY-MM-DD")
        );
      } else if (lodash.has(values, "startDate._i")) {
        lodash.set(
          _tableFilter,
          "startDate",
          moment(values.startDate._i).format("YYYY-MM-DD")
        );
      } else if (lodash.has(values, "endDate._i")) {
        lodash.set(
          _tableFilter,
          "endDate",
          moment(values.endDate._i).format("YYYY-MM-DD")
        );
      }
    }
    
    dispatch(luckyDrawReportActions.getLuckyDrawDowloadList(_tableFilter));
  }

  clearFilter = () => {
    const { dispatch } = this.props;
    dispatch(change(FORM_NAME, "startDate", ""));
    dispatch(change(FORM_NAME, "endDate", ""));
    dispatch(change(FORM_NAME, "rewardName", ""));
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({ type: "CLEAR_LUCKYDRAW_FILTER" });
    dispatch(reset(FORM_NAME));
  }

  render() {
    const { FORM_FILTER_LUCKYDRAW, handleSubmit, luckyDrawRewardSet } = this.props;
    let startMaxDate = new Date();

    if (lodash.has(FORM_FILTER_LUCKYDRAW, "values.endDate")) {
      startMaxDate = FORM_FILTER_LUCKYDRAW.values.endDate;
    }

    let endMinDate;
    if (lodash.has(FORM_FILTER_LUCKYDRAW, "values.startDate")) {
      endMinDate = FORM_FILTER_LUCKYDRAW.values.startDate;
    }

    let setLuckyDrawValue = [];
    lodash.forEach(luckyDrawRewardSet, function (value, key) {
      if (!value.useInCampaignId) {
        let sel_value = value._id;
        let sel_label = value.name_th;
          setLuckyDrawValue.push({
            value: sel_value,
            label: sel_label,
          });
      }
    });

    return (
      <div>
        <form
          name={FORM_NAME}
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <GridContainer >
            <GridItem xs={6} sm={6} md={3} lg={3}>
              <Field
                name="startDate"
                component={CustomDatePicker}
                type="text"
                maxDate={startMaxDate}
                labelText="Start Date"
                dateFormat="DD-MMM-YY"
                timeFormat="HH:mm"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={3} lg={3}>
              <Field
                name="endDate"
                minDate={endMinDate}
                maxDate={new Date()}
                component={CustomDatePicker}
                type="text"
                labelText="End Date"
                dateFormat="DD-MMM-YY"
                timeFormat="HH:mm"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={3} lg={3}>
              <Field
                  name="rewardName"
                  component={CustomSelect}
                  type="text"
                  labelText="Reward"
                  formControlProps={{
                      fullWidth: true
                  }}
                  optionsList={setLuckyDrawValue}
              />
            </GridItem>

            <GridItem xs={6} sm={6} md={3} lg={3}>
              <Button
                onClick={this.clearFilter}
                style={{ marginRight: "10px", marginTop: "25px" }}
                size="sm"
                color="white"
              >
                Clear
              </Button>
              <Button
                type="submit"
                style={{ marginTop: "25px" }}
                size="sm"
                color="primary"
              >
                Submit
              </Button>
            </GridItem>
          </GridContainer>
        </form>
        <TableLuckyDraw />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { FORM_FILTER_LUCKYDRAW } = state.form;
  const { luckyDrawRewardSet, luckyDraw } = state.luckyDrawReport;

  return {
    FORM_FILTER_LUCKYDRAW,
    luckyDraw,
    luckyDrawRewardSet
  };
}

const connectedComponent = connect(mapStateToProps)(LuckyDraw);

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true
})(withStyles(style)(connectedComponent));

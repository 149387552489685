import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'
import moment from 'moment';

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import ColorSelect from "components/CustomSelect/ColorSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import { dataFilteringResultConstants } from '../../../constants';
import { dataFilteringResultAction } from '../../../actions';

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";


const FORM_NAME = 'FORM_DATAFILTERING_CAMPAIGN';

const form_validate = {
    "assignType": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    },
    "campaignId": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class FormDataFilteringAssignCampaign extends React.Component {
    handleSubmit = (values) => {
        const { dispatch } = this.props
        var reqParams = {
            "assignType": values.assignType,
            "campaignId": values.campaignId,
            "conditionId": values.conditionId,
            "resultId": values.resultId
        }

        //console.log('handleSubmit values : ',values);

        dispatch(dataFilteringResultAction.assignCampaign(reqParams));
    }

    componentDidMount() {
        this.getCampaignList()
    }

    getCampaignList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"

        dispatch(dataFilteringResultAction.getCampaignList(reqParams))
    }

    render() {
        const { classes, formAssignCampaignOpen, campaignList, handleSubmit, formInitialValue } = this.props

        let campaignAllList = [];

        lodash.forEach(campaignList, function (value, key) {

            campaignAllList.push({
                value: value._id,
                label: value.name,
                id: value._id,
            })

        })

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formAssignCampaignOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>Assign Campaign</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="resultId"
                                    component="input"
                                    type="hidden"
                                />

                                <Field
                                    name="conditionId"
                                    component="input"
                                    type="hidden"
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="assignType"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Type"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            value: 'whitelist',
                                            label: 'Whitelist',
                                        },
                                        {
                                            value: 'blacklist',
                                            label: 'Blacklist',
                                        }
                                    ]}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="campaignId"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Campaign"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={campaignAllList}
                                />
                            </GridItem>

                        </GridContainer>

                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>

                        <Button
                            style={{ marginRight: "10px" }}
                            color="primary"
                            type="submit">
                            Save
                        </Button>

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: dataFilteringResultConstants.CLOSE_FORM_ASSIGN_CAMPAIGN })
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formAssignCampaignOpen, campaignList, formInitialValue } = state.dataFilteringResult;
    const { FORM_DATAFILTERING_CAMPAIGN } = state.form
    return {
        formAssignCampaignOpen, campaignList, FORM_DATAFILTERING_CAMPAIGN, formInitialValue
    };
}

const connectedComponent = connect(mapStateToProps)(FormDataFilteringAssignCampaign);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))

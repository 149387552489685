import { pointService } from '../services'
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'
import moment from 'moment'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const pointActions = {
    getPointExpirationSetting,
    savePointExpirationSetting
};

function getPointExpirationSetting() {
    return dispatch => {
        loader(dispatch, true)
        pointService.getPointExpirationSetting()
            .then(
                response => {
                    if (response.status) {
                        let limitTime = lodash.get(response, 'data.pointExpiration.limitTime')
                        let formInitialValues = {
                            ...lodash.get(response, 'data.pointExpiration'),
                            limitTime: limitTime === 0?undefined: limitTime
                        }
                        let specifyExpirationDate = lodash.get(formInitialValues, 'specifyExpirationDate')
                        if(specifyExpirationDate) {
                            formInitialValues.specifyExpirationDate = moment(specifyExpirationDate)
                        }

                        dispatch({ type: 'GET_POINT_EXPIRATION_SETTING_SUCCESS', data: formInitialValues })
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function savePointExpirationSetting(params) {
    return dispatch => {
        loader(dispatch, true)
        pointService.savePointExpirationSetting(params)
            .then(
                response => {
                    if (response.status) {
                        customAlert(dispatch, 'success', "SUCCESS");
                        dispatch(getPointExpirationSetting())
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}
import { alertConstants } from '../constants'
import { history } from './';

export const alert = async (dispatch, type, message=``, timeOut=false, redirect=false, body=false, onConfirm=undefined) => {
    let payload
    switch (type) {
        case `success`:
            payload = { type: alertConstants.SUCCESS, message, body, onConfirm }
            break;
    
        case `error`:
            payload = { type: alertConstants.ERROR, message, body, onConfirm }
            break;

        case `clear`:
            payload = { type: alertConstants.CLEAR }
            break;
        case `empty_field`:
            payload = { type: alertConstants.ERROR, message:`ท่านยังไม่ได้กรอก${message} กรุณากรอกข้อมูลให้ครบถ้วน` }
            break;
        default: 
            payload = { type: alertConstants.SUCCESS, message }
            break;
    }

    await dispatch(payload)

    if(timeOut) {
        setTimeout(() => {
            dispatch({ type: alertConstants.CLEAR })
            if(redirect) {
                history.push(redirect)
            }
        }, 2000)
    }

}
import { saleProductGroupConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";
var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableData: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    tableLoader: false,
    saleProductGroupData: [],
    readOnly : false

}

export function saleProductGroup(state = initialState, action) {
    switch (action.type) {
        case saleProductGroupConstants.OPEN_FORM_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case saleProductGroupConstants.OPEN_FORM_EDIT_SALEPRODUCTGROUP:
            return {
                ...state,
                formOpen: true,
                readOnly : false,
                formMode: 'edit',
                formInitialValue: action.tmp,
            };
        case saleProductGroupConstants.CLOSE_FORM_SALEPRODUCTGROUP:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
            }
        case saleProductGroupConstants.ADD_SALEPRODUCTGROUP_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case saleProductGroupConstants.GET_SALEPRODUCTGROUP_DATA_SUCCESS:
            return {
                ...state,
                saleProductGroupData: action.data
            }
        case saleProductGroupConstants.TABLE_GET_LIST_SALEPRODUCTGROUP:
            return {
                ...state,
                tableData: action.tmp,
                tableReload: false,
                tableReset: false
            }
        case saleProductGroupConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case saleProductGroupConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case saleProductGroupConstants.DELETE_SALEPRODUCTGROUP_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case saleProductGroupConstants.EDIT_SALEPRODUCTGROUP_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case saleProductGroupConstants.SET_FORM_PREVIEW_IMAGE:
                return {
                    ...state,
                }
        default:
            return state;
    }
}
import React from "react";
import { Field, change } from 'redux-form';
import _ from 'lodash';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import { crmValidator } from 'helpers';

import i18n from 'i18n';

import * as FormValidator from '../validate/FormValidator';

function FromPoint({ ...props }) {

   const { classes, formMode, FORM_CAMPAIGN_V2, dispatch } = props.props
   const { FORM_NAME } = props

   let { readOnly } = props.props

   if (formMode === `add`) {
      readOnly = false
   } else if (formMode === `edit` && !readOnly) {
      readOnly = false
   } else {
      readOnly = true
   }

   const listQuota = [
      { label: i18n.t("label.per_day"), value: "day" },
      { label: i18n.t("label.per_month"), value: "month" }
   ]

   return (
      <>
         <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <h6><b>{i18n.t("campaign.quota_per_campaign")}</b></h6>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                     <Field
                        name="quota[campaignPerCampaign]"
                        component={CustomInput}
                        type="number"
                        labelText={i18n.t("label.quota_per_campaign")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        normalize={(value) => {
                           return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].campaignPerCampaign)
                        }}
                        validate={[FormValidator.validateIsZero]}
                        disabled={readOnly}
                     />
                  </GridItem>
               </GridContainer>
               <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                     <Field
                        name="quotaTypeCampaign"
                        component={CustomSelect}
                        type="text"
                        labelText={i18n.t("label.day_or_month")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        optionsList={listQuota}
                        disabled={readOnly}
                     />
                  </GridItem>
                  {(FORM_CAMPAIGN_V2.values.quotaTypeCampaign === "month") ?
                     <GridItem xs={12} sm={6} md={3}>
                        <Field
                           name="quota[campaignPerMonth]"
                           component={CustomInput}
                           type="number"
                           labelText={i18n.t("label.quota")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeCampaign) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                           normalize={(value) => {
                              return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                           }}
                           disabled={readOnly || _.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeCampaign) ? true : false}
                        />
                     </GridItem>
                     : null}
               </GridContainer>
               {(FORM_CAMPAIGN_V2.values.quotaTypeCampaign === "day") ?
                  <GridContainer>
                     <GridItem xs={12} sm={6} md={6}>
                        <GridContainer>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[campaignPerDayofWeek][monday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.monday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeCampaign) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[campaignPerDayofWeek][tuesday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.tuesday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeCampaign) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[campaignPerDayofWeek][wednesday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.wednesday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeCampaign) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[campaignPerDayofWeek][thursday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.thursday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeCampaign) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[campaignPerDayofWeek][friday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.friday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeCampaign) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[campaignPerDayofWeek][saturday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.saturday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeCampaign) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[campaignPerDayofWeek][sunday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.sunday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeCampaign) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>

                        </GridContainer>
                     </GridItem>
                  </GridContainer>
                  : null}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <h6><b>{i18n.t("campaign.quota_per_person")}</b></h6>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                     <Field
                        name="quota[userPerCampaign]"
                        component={CustomInput}
                        type="number"
                        labelText={i18n.t("label.quota_per_person_throughout_campaign")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        normalize={(value) => {
                           return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].userPerCampaign)
                        }}
                        validate={[FormValidator.validateIsZero, FormValidator.validateMax]}
                        disabled={readOnly}
                     />
                  </GridItem>
               </GridContainer>
               <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                     <Field
                        name="quotaTypeUser"
                        component={CustomSelect}
                        type="text"
                        labelText={i18n.t("label.day_or_month")}
                        formControlProps={{
                           fullWidth: true
                        }}
                        optionsList={listQuota}
                        disabled={readOnly}
                     />
                  </GridItem>
                  {(FORM_CAMPAIGN_V2.values.quotaTypeUser === "month") ?
                     <GridItem xs={12} sm={6} md={3}>
                        <Field
                           name="quota[userPerMonth]"
                           component={CustomInput}
                           type="number"
                           labelText={i18n.t("label.quota")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeUser) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                           normalize={(value) => {
                              return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaUser)
                           }}
                           disabled={readOnly || _.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeUser) ? true : false}
                        />
                     </GridItem>
                     : null}
               </GridContainer>
               {(FORM_CAMPAIGN_V2.values.quotaTypeUser === "day") ?
                  <GridContainer>
                     <GridItem xs={12} sm={6} md={6}>
                        <GridContainer>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[userPerDayofWeek][monday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.monday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeUser) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[userPerDayofWeek][tuesday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.tuesday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeUser) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[userPerDayofWeek][wednesday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.wednesday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeUser) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[userPerDayofWeek][thursday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.thursday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeUser) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[userPerDayofWeek][friday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.friday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeUser) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[userPerDayofWeek][saturday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.saturday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeUser) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <Field
                                 name="quota[userPerDayofWeek][sunday]"
                                 component={CustomInput}
                                 labelText={i18n.t("label.sunday")}
                                 type="number"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 validate={_.isEmpty(FORM_CAMPAIGN_V2.values.quotaTypeUser) ? [] : [FormValidator.validateRequired, FormValidator.validateIsZero, FormValidator.validateMax]}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, FormValidator.validate_["point"].quotaCampaign)
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                        </GridContainer>
                     </GridItem>
                  </GridContainer>
                  : null}
            </GridItem>

         </GridContainer>

         <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                     <h6><b>{i18n.t("campaign.redeemable")}</b></h6>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                     <div
                        className={
                           classes.checkboxAndRadio +
                           " " +
                           classes.checkboxAndRadioHorizontal
                        }
                     >
                        <Field
                           name={"redeemAble[sameAsVisibleDate]"}
                           component={CustomCheckbox}
                           disabled={readOnly}
                           labelText={i18n.t("label.same_as_start_date_and_end_date")}
                           onChange={(val) => {
                              if(val){
                                 const startDate = _.get(FORM_CAMPAIGN_V2.values, 'startDate')
                                 const endDate = _.get(FORM_CAMPAIGN_V2.values, 'endDate')
   
                                 if(!_.isEmpty(startDate)){
                                    dispatch(change(FORM_NAME, 'redeemAble[startDate]', startDate))
                                 }
                                 if(!_.isEmpty(endDate)){
                                    dispatch(change(FORM_NAME, 'redeemAble[endDate]', endDate))
                                 }
                              }
                           }}
                        />
                     </div>
                  </GridItem>
               </GridContainer>
            </GridItem>
         </GridContainer>
         <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
               <Field
                  name="redeemAble[startDate]"
                  component={CustomDatePicker}
                  type="text"
                  maxDate={_.get(FORM_CAMPAIGN_V2.values, 'redeemAble[endDate]')}
                  labelText={i18n.t("label.start_date")}
                  dateFormat="DD-MMM-YY"
                  timeFormat="HH:mm"
                  formControlProps={{
                     fullWidth: true
                  }}
                  disabled={(readOnly || _.get(FORM_CAMPAIGN_V2.values, 'redeemAble[sameAsVisibleDate]'))}
               />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
               <Field
                  name="redeemAble[endDate]"
                  minDate={_.get(FORM_CAMPAIGN_V2.values, 'redeemAble[startDate]')}
                  component={CustomDatePicker}
                  type="text"
                  labelText={i18n.t("label.end_date")}
                  dateFormat="DD-MMM-YY"
                  timeFormat="HH:mm"
                  formControlProps={{
                     fullWidth: true
                  }}
                  disabled={(readOnly || _.get(FORM_CAMPAIGN_V2.values, 'redeemAble[sameAsVisibleDate]'))}
               />
            </GridItem>
         </GridContainer>
         <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
               <Field
                  name="redeemAble[birthday]"
                  component={CustomSelect}
                  type="text"
                  labelText={i18n.t("label.birthday_redeemable")}
                  formControlProps={{
                     fullWidth: true
                  }}
                  optionsList={[
                     {
                        value: "day",
                        label: i18n.t("label.day")
                     },
                     {
                        value: "month",
                        label: i18n.t("label.month")
                     }
                  ]}
                  disabled={(readOnly || !FORM_CAMPAIGN_V2.values.visibility_birthday)}
               />
            </GridItem>
         </GridContainer>
      </>
   );
}

export default FromPoint;

import PropTypes from "prop-types";
import classNames from "classnames";
import React from "react";
import lodash from "lodash";
import AsyncSelect from 'react-select/async'
import qs from 'qs'
import { listServices } from '../../../services'
import { createFilter } from 'react-select';
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import i18n from 'i18n';


const ProductAsyncSelection = ({ input: { onChange, value }, options, name, ...props }) => {

    const {
        input,
        classes,
        formControlProps,
        labelText,
        id,
        type,
        disabled,
        labelProps,
        inputProps,
        white,
        multiple = false,
        showtick,
        optionsList,
        novalidate,
        meta,
        index,
        outlined = false
    } = props;

    let error = (meta !== undefined && meta.touched && meta.invalid);
    let success = (meta !== undefined && meta.touched && meta.valid);
    const [initialValue, setinitialValue] = React.useState('')


    const labelClasses = classNames({
        [" " + classes.labelRootError]: error && !novalidate,
        [" " + classes.labelRootSuccess]: success && !error && !novalidate && !outlined
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error && !novalidate,
        [classes.underlineSuccess]: success && !error && !novalidate && !outlined,
        [classes.underline]: true,
        [classes.whiteUnderline]: white
    });

    var helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });



    const loadProductOptionsPromise = (inputValue) => {
        return new Promise(async (resolve, reject) => {
            let selectedSKU = []
            let reqParams = {
                skip: 0,
                limit: 1000,
                sortBy: "name",
                sortDir: "asc",
                keyword: inputValue,
                keywordFilterList: ["name", "skuId"]
            }
            let productList = await listServices.getListsProduct(qs.stringify(reqParams))
            let formattedProductList = []
            if (lodash.has(productList, 'data.products')) {
                productList.data.products.map((value) => {
                    let productsku = lodash.get(value, 'skuId')
                    if ((lodash.indexOf(selectedSKU, productsku) === -1) && productsku) {
                        formattedProductList.push({
                            value: productsku,
                            label: lodash.get(value, 'name')
                        })
                    }
                    return formattedProductList
                })
            }
            resolve(formattedProductList)
        });
    }
    const patchedOnChange = (value) => {
        let skuId = lodash.get(value, 'value')

        if (lodash.isUndefined(skuId)) {
            error = true
            success = false
        } else {
            error = false
            success = true
        }
        onChange(skuId);
    }
        let zIndex = lodash.isUndefined(index) ? 0 : index
    return (
        <>
            <FormControl {...formControlProps} style={{position: 'relative' , padding: "19px 0px",  zIndex : `${90 - zIndex}` }}>
                <AsyncSelect
                    noOptionsMessage={({ inputValue }) => {
                        if (inputValue.length === 0) {
                            return (<span>Please enter keyword(s) to search product.</span>)
                        } else {
                            return (<span>Not found</span>)
                        }
                    }}
                    placeholder="Search..."
                    isClearable
                    defaultValue={(meta.initial === value) ? props.customInitial : ""}
                    value={value.value}
                    isDisabled={props.disabled}
                    blurInputOnSelect={true}
                    loadOptions={loadProductOptionsPromise}
                    onBlurResetsInput={false}
                    onChange={patchedOnChange}
                    filterOption={createFilter({ ignoreAccents: false })}
                    getOptionLabel={option => `${option.label}  (${option.value})`}
                />
                {(outlined === false) && (meta !== undefined && meta.touched && meta.invalid) === true ? (
                    <FormHelperText id={id + "-text"} className={helpTextClasses}>
                        {i18n.t(meta.error)}
                    </FormHelperText>
                ) : null}
            </FormControl>
            {outlined && (meta !== undefined && meta.touched && meta.invalid) === true ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {i18n.t(meta.error)}
                </FormHelperText>
            ) : null}
        </>
    );
}

ProductAsyncSelection.propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    inputRootCustomClasses: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    white: PropTypes.bool,
    helpText: PropTypes.node
};

export default withStyles(styles)(ProductAsyncSelection)
import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { loaderConstants } from '../../constants';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { Pie, Bar } from 'react-chartjs-2';

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import { memberActions, dashboardActions } from 'actions';
import lodash from 'lodash';
import ChartActiveUser from './component/chartActiveUser'
import BarchartActiveUser from './component/barchartActiveUser'
import TableDashboardReport from 'views/Dashboard/TableDashboardList'
import FormFilterActiveUser from 'views/Dashboard/component/formFilterActiveUser'
import TableDashboardEarnBySku from 'views/Dashboard/TableDashboardEarnBySku'
import TableDashboardRegistrationByHour from 'views/Dashboard/TableDashboardRegistrationByHour'
import TableDashboardRegistrationByProvince from 'views/Dashboard/TableDashboardRegistrationByProvince'
import TableDashboardBurnTransactionByCampaign from 'views/Dashboard/TableDashboardBurnTransactionByCampaign'
import FormFilterDailyRegistration from 'views/Dashboard/component/formFilterDailyRegistration'
import TableDailyRegistrationDashboardList from 'views/Dashboard/TableDailyRegistrationDashboardList'
import ChartActiveDailyRegistration from './component/chartActiveDailyRegistration'
import TableDashboardBurnByDayAndHour from 'views/Dashboard/TableDashboardBurnByDayAndHour'
import TableDashboardEarnByDayAndHour from 'views/Dashboard/TableDashboardEarnByDayAndHour'
import FormFilterEarnTransaction from 'views/Dashboard/component/formFilterDailyEarnTransaction'
import TableDailyEarnTransactionDashboardList from 'views/Dashboard/TableDailyEarnTransactionDashboardList'
import ChartActiveDailyEarnTransaction from './component/chartActiveDailyEarnTransaction'
import TableInactive from './component/TableInactiveUser';


import FormFilterBurnTransaction from 'views/Dashboard/component/formFilterDailyBurnTransaction'
import TableDailyBurnTransactionDashboardList from 'views/Dashboard/TableDailyBurnTransactionDashboardList'
import ChartActiveDailyBurnTransaction from './component/chartActiveDailyBurnTransaction'

import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import moment from "moment";

const HaveNodata = () => (
  <div style={{ minHeight: "170px", textAlign: "center", color: "#777", paddingTop: "80px", fontStyle: "italic" }}>Have no Data</div>
)
class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.props.dispatch({ type: loaderConstants.ACTIVE });
  }

  state = {
    value: 0
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  getCampaignList() {
      const { dispatch } = this.props;
      let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"

      dispatch(dashboardActions.getCampaignList(reqParams))
  }

  componentDidMount() {
    const { dispatch } = this.props
    setTimeout(function () { dispatch({ type: loaderConstants.INACTIVE }); }, 1000);
    this.getCampaignList();
    this.props.dispatch(memberActions.getStatisticsMember());

    this.props.dispatch(dashboardActions.getKeySummary());
    this.props.dispatch(dashboardActions.getRegistration());
    this.props.dispatch(dashboardActions.getRegistrationDayofweek());
    this.props.dispatch(dashboardActions.getEarnTransactionList());
    this.props.dispatch(dashboardActions.getBurnTransactionList());
    this.props.dispatch(dashboardActions.getTableInactiveUser());

  }

  render() {
    const { classes, statistics, tableDatas, showLoader, tableFilter, tableDialyRegistrationDatas, showDialyRegistrationLoader, tableDialyEarnTransactionDatas, showDialyEarnTransactionLoader, keySummary, registration, registrationDayofweek, earnStatus, campaignList , tableDialyBurnTransactionDatas, showDialyBurnTransactionLoader, tableInactiveUser} = this.props;
    let ageStatData = {
      data: [],
      label: [],
      color: []
    }
    let earnStatData = {
      data: [],
      label: [],
      color: []
    }

    let burnStatData = {
      data: [],
      label: [],
      color: []
    }

    const ageColor = [
      '#FF6384',
      '#36A2EB',
      '#ff9800',
      '#FFCE56',
      "#90C978",
      "#83C6DD",
      "#B29DD9",
      "#FDFD98",
      "#FE6B64",
      "#efefef"
    ]

    lodash.forEach(statistics.age, function (value, key) {
      ageStatData.data.push(value.count);
      ageStatData.label.push(value.label);
      ageStatData.color.push(ageColor[key]);
    })
    lodash.forEach(statistics.earnStatus, function (value, key) {
      earnStatData.data.push(value.count);
      earnStatData.label.push(value._id);
      earnStatData.color.push(ageColor[key]);
    })
    lodash.forEach(statistics.burnStatus, function (value, key) {
      burnStatData.data.push(value.count);
      burnStatData.label.push(value._id);
      burnStatData.color.push(ageColor[key]);
    })
    const dataRegistration = registration

    const dataRegistrationDayofweek = registrationDayofweek
    
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }

    // console.log('tableDialyEarnTransactionDatas :',tableDialyEarnTransactionDatas);
    // console.log('tableDialyBurnTransactionDatas :',tableDialyBurnTransactionDatas);

    return (
      <div>
        <h4>Member Stats</h4>
        <GridContainer>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="danger" style={{ height: '63px' }}>
                  <Icon>pie_chart</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                Total Member by Gender
                </h4>
              </CardHeader>
              <CardBody>
                {lodash.max(lodash.get(statistics,'gender.data')) > 0 ?
                  <Pie data={() => {
                    return {
                      labels: statistics.gender.labels,
                      datasets: [{
                        data: statistics.gender.data,
                        backgroundColor: [
                          '#36A2EB',
                          '#FF6384',
                          '#FFCE56'
                        ],
                        hoverBackgroundColor: [
                          '#36A2EB',
                          '#FF6384',
                          '#FFCE56'
                        ]
                      }]
                    }
                  }} />
                  :
                  <HaveNodata />
                }
              </CardBody>

            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="danger" style={{ height: '63px' }}>
                  <Icon>dashboard</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                Total Member by Age Range
                </h4>
              </CardHeader>
              <CardBody>
                {lodash.max(lodash.get(ageStatData,'data')) > 0 ?
                  <Pie data={() => {
                    return {
                      labels: ageStatData.label,
                      datasets: [{
                        data: ageStatData.data,
                        backgroundColor: ageStatData.color
                      }]
                    }
                  }} />
                  : <HaveNodata />}
              </CardBody>

            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning" style={{ height: '63px' }}>
                  <Icon>add_reaction</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                Earns by status
                </h4>
              </CardHeader>
              <CardBody>
                {lodash.max(lodash.get(earnStatData,'data')) > 0 ?
                  <Pie data={() => {
                    return {
                      labels: earnStatData.label,
                      datasets: [{
                        data: earnStatData.data,
                        backgroundColor: earnStatData.color
                      }]
                    }
                  }} />
                  : <HaveNodata />}
              </CardBody>

            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <Icon>category</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Earns By SKU
                </h4>
              </CardHeader>
              <CardBody>
          
                <GridContainer>
                  {showLoader && <TableLoaderSpinner/>}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableDashboardEarnBySku />
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <Icon>groups</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Total Registration by Hour
                </h4>
              </CardHeader>
              <CardBody>
          
                <GridContainer>
                  {showLoader && <TableLoaderSpinner/>}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableDashboardRegistrationByHour />
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <Icon>groups</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Total Member by Province
                </h4>
              </CardHeader>
              <CardBody>
          
                <GridContainer>
                  {showLoader && <TableLoaderSpinner/>}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableDashboardRegistrationByProvince />
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning" style={{ height: '63px' }}>
                  <Icon>groups</Icon>
                </CardIcon>
                <h6 className={classes.cardIconTitle}>
                  Total users
                </h6>
              </CardHeader>
              <CardBody>
                {keySummary.totalUsers}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning" style={{ height: '63px' }}>
                  <Icon>sports_handball</Icon>
                </CardIcon>
                <h6 className={classes.cardIconTitle}>
                  Daily active users
                </h6>
              </CardHeader>
              <CardBody>
                Yesterday : {keySummary.dailyActiveUsers_2} / 
                Today : {keySummary.dailyActiveUsers_1}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning" style={{ height: '63px' }}>
                  <Icon>add_reaction</Icon>
                </CardIcon>
                <h6 className={classes.cardIconTitle}>
                  Total success earns
                </h6>
              </CardHeader>
              <CardBody>
              {keySummary.totalSuccessEarns}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning" style={{ height: '63px' }}>
                  <Icon>card_giftcard</Icon>
                </CardIcon>
                <h6 className={classes.cardIconTitle}>
                  Total success burns
                </h6>
              </CardHeader>
              <CardBody>
              {keySummary.totalBurns}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="warning">
                  <Icon>groups</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Daily Registration(Data as of yesterday date) 
                </h4>
              </CardHeader>
              <CardBody>
                
                <GridContainer style={{padding: '0px 13px'}}>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <FormFilterDailyRegistration initialValues={{
                      activeChannel: 'all',
                      startDate: moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day'),
                      endDate: moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day'),
                    }}/>
                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                  {showDialyRegistrationLoader && <TableLoaderSpinner/>}

                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <ChartActiveDailyRegistration tableDialyRegistrationDatas={tableDialyRegistrationDatas}/>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <TableDailyRegistrationDashboardList showAction={false} showSearch={false}/>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <Icon>groups</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Total Member by Age Range and Gender
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Bar data={dataRegistration} options={options} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={6} sm={6} md={6}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <Icon>groups</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Total Registration by Day of Week
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Bar data={dataRegistrationDayofweek} options={options} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="warning">
                  <Icon>groups</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Daily Earns(Data as of yesterday date) 
                </h4>
              </CardHeader>
              <CardBody>
                
                <GridContainer style={{padding: '0px 13px'}}>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <FormFilterEarnTransaction initialValues={{
                      activeChannel: 'all',
                      startDate: moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day'),
                      endDate: moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day'),
                    }}/>
                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                  {showDialyEarnTransactionLoader && <TableLoaderSpinner/>}

                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <ChartActiveDailyEarnTransaction tableDialyEarnTransactionDatas={tableDialyEarnTransactionDatas}/>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <TableDailyEarnTransactionDashboardList showAction={false} showSearch={false}/>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning" style={{ height: '63px' }}>
                  <Icon>add_reaction</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                Burn by status
                </h4>
              </CardHeader>
              <CardBody>
                {lodash.max(lodash.get(burnStatData,'data')) > 0 ?
                  <Pie data={() => {
                    return {
                      labels: burnStatData.label,
                      datasets: [{
                        data: burnStatData.data,
                        backgroundColor: burnStatData.color
                      }]
                    }
                  }} />
                  : <HaveNodata />}
              </CardBody>

            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <Icon>redeem</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Success Burns by Campaign
                </h4>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  {showLoader && <TableLoaderSpinner/>}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableDashboardBurnTransactionByCampaign />
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <Icon>redeem</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Success Burns by Day of Week and Hour
                </h4>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  {showLoader && <TableLoaderSpinner/>}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableDashboardBurnByDayAndHour />
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <Icon>add_reaction</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Success Earns by Day of Week and Hour
                </h4>
              </CardHeader>
              <CardBody>

                <GridContainer>
                  {showLoader && <TableLoaderSpinner/>}
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TableDashboardEarnByDayAndHour />
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="warning">
                  <Icon>groups</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Daily Burns(Data as of yesterday date) 
                </h4>
              </CardHeader>
              <CardBody>
                
                <GridContainer style={{padding: '0px 13px'}}>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    { campaignList.length > 0 ?
                        <FormFilterBurnTransaction initialValues={{
                          campaignList: campaignList,
                          campaignId: 'all',
                          startDate: moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day'),
                          endDate: moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day'),
                        }}/>
                      :
                     <></>
                    }

                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                  { showDialyBurnTransactionLoader && <TableLoaderSpinner/>}

                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <ChartActiveDailyBurnTransaction tableDialyBurnTransactionDatas={tableDialyBurnTransactionDatas}/>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <TableDailyBurnTransactionDashboardList showAction={false} showSearch={false}/>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="danger">
                  <Icon>dashboard</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Active User (Data as of yesterday date) 
                </h4>
              </CardHeader>
              <CardBody>
                
                <GridContainer style={{padding: '0px 13px'}}>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <FormFilterActiveUser initialValues={{
                      activeType: 'burn',
                      activeUser: 'daily',
                      startDate: moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day'),
                      endDate: moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day'),
                    }}/>
                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                  {showLoader && <TableLoaderSpinner/>}
                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    { 
                    lodash.get(tableFilter, 'search.type') === "period"?
                     <BarchartActiveUser tableDatas={tableDatas} /> :
                      <ChartActiveUser tableDatas={tableDatas}/>  
                    }
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} lg={6}>
                    <TableDashboardReport showAction={false} showSearch={false}/>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger" icon>
                <CardIcon color="danger">
                  <Icon>face</Icon>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Inactive User
                </h4>
              </CardHeader>
              <CardBody>                
                <GridContainer>
                  {tableInactiveUser.loader && <TableLoaderSpinner/>}

                  <GridItem xs={12} sm={12} md={4} lg={4}>
                  <h4 className={classes.cardIconTitle}>
                  Earn
                </h4>
                    <TableInactive tableDatas={tableInactiveUser.earn}/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                  <h4 className={classes.cardIconTitle}>
                  Burn
                </h4>
                    <TableInactive tableDatas={tableInactiveUser.burn}/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                  <h4 className={classes.cardIconTitle}>
                  Earn And Burn 
                </h4>
                    <TableInactive tableDatas={tableInactiveUser.earn_burn}/>
                  </GridItem>
                </GridContainer>

              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { loader } = state.loader;
  const { statistics, tableDatas, showLoader, tableFilter, tableDialyRegistrationDatas, showDialyRegistrationLoader, tableDialyEarnTransactionDatas, showDialyEarnTransactionLoader, keySummary, registration, registrationDayofweek, earnStatus, campaignList, tableDialyBurnTransactionDatas, showDialyBurnTransactionLoader, tableInactiveUser } = state.dashboard;
  const { permission, superAdmin } = state.authentication;
  return {
    loader, statistics, permission, superAdmin, tableDatas, showLoader, tableFilter, tableDialyRegistrationDatas, showDialyRegistrationLoader, tableDialyEarnTransactionDatas, showDialyEarnTransactionLoader, keySummary, registration, registrationDayofweek, earnStatus, campaignList, tableDialyBurnTransactionDatas, showDialyBurnTransactionLoader,tableInactiveUser
  };
}

const connectApp = connect(mapStateToProps)(withStyles(dashboardStyle)(Dashboard));
export { connectApp as Dashboard }
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash';

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import { alert, store, plugins } from 'helpers';
import { earnrulesConstants } from '../../constants';
import EarnRuleTypeCode from './EarnRuleTypeCode.jsx';
import EarnRuleTypePurchaseAmount from './EarnRuleTypePurchaseAmount.jsx';
import EarnRuleTypeProduct from './EarnRuleTypeProduct.jsx';
import { earnRulesAction } from 'actions';
import * as FormValidator from './validate/FormValidator';

const FORM_NAME = "FORM_EARNRULES";
const form_validate = {
    "name": {
        rules: {
            required: true
        },
        messages: {
            required: "Please enter Rule's name"
        },
        alert: "Please enter Rule's name"
    },
    "status": {
        rules: {
            required: true
        },
        messages: {
            required: 'Please enter Status'
        },
        alert: "Please enter Status"
    },
    "startDate": {
        rules: {
            required: true
        },
        messages: {
            required: 'Please enter Start Date'
        },
        alert: "Please enter Start Date"
    },
    "type": {
        rules: {
            required: true
        },
        messages: {
            required: 'Please enter type'
        }
    },
    "checkBoxRegionSelected": {
        rules: {
            required: false
        },
        messages: {
            required: 'warning_messages.please_select_region'
        },
        alert: "warning_messages.please_select_region",
    },
    "shopCondition[shopList]": {
        rules: {
            required: false
        },
        messages: {
            required: 'warning_messages.please_select_shop_list'
        },
        alert: "warning_messages.please_select_shop_list",
    },
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(FormValidator.form_validate, values);
    return errors
}

class EarnRulesForm extends React.Component {
    getProductList(keyword) {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"
        if (keyword) {
            reqParams += `&keyword=${keyword}`
        }
        dispatch(earnRulesAction.getListsProduct(reqParams))
    }

    getFormSubmitParams = (values, formMode) => {
        const { allProductList, FORM_EARNRULES, joinedProductRows, allShopListSelected, luckyDrawRewardSet } = this.props;
        if (values.type === "code") {
            let productIdsWithPoint = [];
            let registeredFields = FORM_EARNRULES.registeredFields

            lodash.forEach(values.productPoint, function (value, key) {
                if (lodash.has(registeredFields, `productPoint[${key}]`)) {
                    productIdsWithPoint.push({
                        earnBy: values.customRadioEarnBy,
                        productId: key,
                        productPrefix: lodash.find(allProductList, { '_id': key }).prefix,
                        productSkuId: lodash.find(allProductList, { '_id': key }).skuId,
                        point: value
                    })
                }
            })

            const endDate = () => {
                if (lodash.has(values, 'endDate')) {
                    return plugins.dateFormatForDB(values.endDate)
                } else {
                    return undefined
                }
            }

            let selectRewardId = null;
            let selectRewardNameTH = "";
            let selectRewardNameEN = "";
            if(values.customRadioEarnFor === 'luckyChance'){
                lodash.forEach(luckyDrawRewardSet, function (value, key) {
                    if (value._id=== values.luckyDrawRewardId) {
                        selectRewardId= value._id;
                        selectRewardNameTH= value.name_th;
                        selectRewardNameEN= value.name_en;
                    }
                });
            }
            
            let result = {
                name: values.name,
                type: values.type,
                ruleCode: values.earnRuleCode,
                productIdsWithPoint: productIdsWithPoint,
                description: "",
                startDate: plugins.dateFormatForDB(values.startDate),
                endDate: endDate(),
                status: values.status,
                earnFor: values.customRadioEarnFor,
                earnBy: values.customRadioEarnBy,
                luckyDrawRewardId: selectRewardId,
                rewardDetail: {
                    nameTH: selectRewardNameTH,
                    nameEN: selectRewardNameEN
                }
            }

            if (formMode === "edit") {
                result._id = values._id
            }

            return result;
        } else if (values.type === "purchaseAmount") {
            const endDate = () => {
                if (lodash.has(values, 'endDate')) {
                    return plugins.dateFormatForDB(values.endDate)
                } else {
                    return undefined
                }
            }
            let result = {};
            if (FORM_EARNRULES.values.customRadioPurshaseAmount === 'spendingEvery') {
                result = {
                    name: values.name,
                    type: values.type,
                    startDate: plugins.dateFormatForDB(values.startDate),
                    endDate: endDate(),
                    condition: {
                        spendingType: "every",
                        spendingValue: values.spendingEveryInput,
                        point: values.willGetInput,
                    },
                    dateCondition: {
                        type: lodash.get(values, 'customRadioParticipatedDay')
                    },
                    memberLevel: (lodash.get(values, 'memberLevel') ? values.memberLevel : []),
                    status: values.status
                }
                if (lodash.get(values, 'customCheckboxMinimumSpending')) {
                    result.condition = {
                        ...result.condition,
                        minimum: {
                            calculateType: (lodash.get(values, 'customRadioMinimumSpending') ? values.customRadioMinimumSpending : ''),
                            value: parseInt(values.minimumSpending)
                        }
                    }
                }
                if (lodash.get(values, 'customCheckboxMaximumSpending')) {
                    result.condition = {
                        ...result.condition,
                        maximum: {
                            value: parseInt(values.maximumSpending)
                        }
                    }
                }
                if (lodash.get(values, 'customRadioParticipatedDay') === 'specific') {
                    result.dateCondition = {
                        ...result.dateCondition,
                        specificDateType: lodash.get(values, 'customRadioSelectedDay') ? lodash.get(values, 'customRadioSelectedDay') : "",

                    }

                    if (lodash.get(values, 'customRadioSelectedDay') === 'dayOfWeek') {
                        const arr = [],
                            obj = Object.keys(values.selectedDate);
                        for (var x in obj) {
                            if (values.selectedDate[obj[x]]) {
                                arr.push(obj[x]);
                            }
                        }
                        result.dateCondition = {
                            ...result.dateCondition,
                            specificDateValue: arr
                        }
                    }
                    if (lodash.get(values, 'customRadioSelectedDay') === 'selectedDate') {
                        result.dateCondition = {
                            ...result.dateCondition,
                            specificDateValue: values.date
                        }
                    }
                }
                if (formMode === "edit") {
                    result._id = values._id
                }
            }
            else if (FORM_EARNRULES.values.customRadioPurshaseAmount === 'whenSpend') {
                result = {
                    name: values.name,
                    type: values.type,
                    startDate: plugins.dateFormatForDB(values.startDate),
                    endDate: endDate(),
                    condition: {
                        spendingType: "total",
                        spendingValue: values.whenSpendInput,
                        point: values.willGetInputWhenSpend,
                    },
                    dateCondition: {
                        type: values.customRadioParticipatedDay,
                    },
                    memberLevel: (lodash.get(values, 'memberLevel') ? values.memberLevel : []),
                    status: values.status
                }
                if (lodash.get(values, 'customRadioParticipatedDay') === 'specific') {
                    result.dateCondition = {
                        ...result.dateCondition,
                        specificDateType: lodash.get(values, 'customRadioSelectedDay') ? lodash.get(values, 'customRadioSelectedDay') : "",

                    }

                    if (lodash.get(values, 'customRadioSelectedDay') === 'dayOfWeek') {
                        const arr = [],
                            obj = Object.keys(values.selectedDate);
                        for (var x in obj) {
                            if (values.selectedDate[obj[x]]) {
                                arr.push(obj[x]);
                            }
                        }
                        result.dateCondition = {
                            ...result.dateCondition,
                            specificDateValue: arr
                        }
                    }
                    if (lodash.get(values, 'customRadioSelectedDay') === 'selectedDate') {
                        result.dateCondition = {
                            ...result.dateCondition,
                            specificDateValue: values.date
                        }
                    }
                }
                if (formMode === "edit") {
                    result._id = values._id
                }
            }

            const shopParams = allShopListSelected.map(shopData => {
                return {
                    shopId: shopData.shopId,
                    _id: shopData._id,
                    shopName: shopData.shopName,
                    shopRegionId: shopData.shopRegionId
                }
            })
            result.shopCondition = {
                ...result.shopCondition,
                shopList: shopParams
            }

            if (formMode === "edit") {
                result._id = values._id
            }
            return result;
        } else if (values.type === "product") {
            const endDate = () => {
                if (lodash.has(values, 'endDate')) {
                    return plugins.dateFormatForDB(values.endDate)
                } else {
                    return undefined
                }
            }
            let result = {};
            result = {
                name: values.name,
                type: values.type,
                startDate: plugins.dateFormatForDB(values.startDate),
                endDate: endDate(),
                status: values.status,
                memberLevel: (lodash.get(values, 'memberLevel') ? values.memberLevel : []),
                dateCondition: {
                    type: lodash.get(values, 'customRadioParticipatedDay')
                },
                product: {
                    singleProduct: {
                        "checked": lodash.get(values.selectedRuleProduct, 'single') === true ? true : false,
                    },
                    joinedProduct: {
                        "checked": lodash.get(values.selectedRuleProduct, 'joined') === true ? true : false,
                    }
                }
            }
            if (lodash.get(values, 'customRadioParticipatedDay') === 'specific') {
                result.dateCondition = {
                    ...result.dateCondition,
                    specificDateType: lodash.get(values, 'customRadioSelectedDay') ? lodash.get(values, 'customRadioSelectedDay') : "",
                }

                if (lodash.get(values, 'customRadioSelectedDay') === 'dayOfWeek') {
                    const arr = [],
                        obj = Object.keys(values.selectedDate);
                    for (var x in obj) {
                        if (values.selectedDate[obj[x]]) {
                            arr.push(obj[x]);
                        }
                    }
                    result.dateCondition = {
                        ...result.dateCondition,
                        specificDateValue: arr
                    }
                }
                if (lodash.get(values, 'customRadioSelectedDay') === 'selectedDate') {
                    result.dateCondition = {
                        ...result.dateCondition,
                        specificDateValue: values.date
                    }
                }
            }


            //single product  //map skuid and name

            const setInitialNameValue = (value) => {
                if (value) {
                    let productName = this.props.productList.find(val => val.skuId === value)
                    return lodash.isUndefined(productName, 'name') ? 'No Product Name' : productName.name
                } else {
                    return 'No Product Name'
                }
            }

            if (lodash.get(values.selectedRuleProduct, 'single') === true) {

                let productSingleList = [];
                let listMaster = []

                if (formMode === "edit") {
                    listMaster = lodash.get(this.props, "productListMaster.singleProduct.productList")
                }

                lodash.forEach(values.singleProductNameSkuSelect, function (value, key) {
                    if (value !== undefined) {
                        productSingleList.push({
                            skuId: value,
                            qty: lodash.get(values, 'singleProductQuantityInput[' + key + ']'),
                            point: lodash.get(values, 'singleProductPointInput[' + key + ']'),
                            skuName: formMode === "edit" && lodash.get(listMaster[key], 'skuId') == value ? listMaster[key].skuName : setInitialNameValue(value)
                        })
                    }
                })

                result.product.singleProduct = {
                    ...result.product.singleProduct,
                    productList: productSingleList,
                }
            }




            //multi product 

            if (lodash.get(values.selectedRuleProduct, 'joined') === true) {
                let productJoinedList = [];
                let productWithQty = [];

                let listMaster = []



                for (let i = 0; i < joinedProductRows.length; i++) {
                    for (let j = 0; j < joinedProductRows[i].productWithQty.length; j++) {
                        if (lodash.get(values, 'joinedProductNameSkuSelect[' + i + '_' + j + ']') !== undefined) {
                            if (formMode === "edit") {
                                listMaster = lodash.get(this.props, `productListMaster.joinedProduct.productList[${i}].productWithQty[${j}]`)

                            }
                            let skuId = lodash.get(values, 'joinedProductNameSkuSelect[' + i + '_' + j + ']')
                            productWithQty.push({
                                skuId: lodash.get(values, 'joinedProductNameSkuSelect[' + i + '_' + j + ']'),
                                qty: lodash.get(values, 'joinedProductQuantityInput[' + i + '_' + j + ']'),
                                skuName: formMode === "edit" && skuId == lodash.get(listMaster, 'skuId') ? listMaster.skuName : setInitialNameValue(lodash.get(values, 'joinedProductNameSkuSelect[' + i + '_' + j + ']'))
                            })
                        }
                    }
                    if (productWithQty.length !== 0) {
                        productJoinedList.push({
                            productWithQty: productWithQty,
                            point: lodash.get(values, 'joinedProductPointInput[' + i + ']')
                        })
                    }
                    productWithQty = [];
                }
                result.product.joinedProduct = {
                    ...result.product.joinedProduct,
                    productList: productJoinedList,
                }
            }

            const shopParams = allShopListSelected.map(shopData => {
                return {
                    shopId: shopData.shopId,
                    _id: shopData._id,
                    shopName: shopData.shopName,
                    shopRegionId: shopData.shopRegionId
                }
            })
            result.shopCondition = {
                ...result.shopCondition,
                shopList: shopParams
            }

            if (formMode === "edit") {
                result._id = values._id
            }
            return result;
        }
        else {
            return false
        }
    }

    handleSubmit = (values) => {
        const { dispatch, formMode } = this.props;
        let params = this.getFormSubmitParams(values, formMode)
        if (values.type === "code" || values.type === "purchaseAmount") {
            if (params) {
                if (formMode === "add") {
                    dispatch(earnRulesAction.addNewEarnRules(params))
                } else if (formMode === "edit") {
                    dispatch(earnRulesAction.editEarnRules(params))
                }
            }
        } else if (values.type === "product") {
            if (params) {
                if (formMode === "add") {
                    dispatch(earnRulesAction.addNewEarnRules(params))
                } else if (formMode === "edit") {
                    dispatch(earnRulesAction.editEarnRules(params))
                }
            }
        }
    }

    renderFormType() {
        const { FORM_EARNRULES, formMode } = this.props;

        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        if (!lodash.has(FORM_EARNRULES, 'values.type')) {
            return null;
        } else {
            const formtype = FORM_EARNRULES.values.type;
            switch (formtype) {
                case "code": {
                    return (<EarnRuleTypeCode readOnly={readOnly} />);
                }
                case "purchaseAmount": {
                    return (<EarnRuleTypePurchaseAmount readOnly={readOnly} props={this.props} FORM_NAME={FORM_NAME} />);
                }
                case "product": {
                    return (<EarnRuleTypeProduct readOnly={readOnly} props={this.props} FORM_NAME={FORM_NAME} />);
                }
                default: return null;
            }
        }
    }

    componentDidMount() {
        const { dispatch }  = this.props
        this.getProductList();
        dispatch(earnRulesAction.getLuckyDrawRewardSetList())
    }
    render() {
        const { formOpen, formMode, classes, dispatch, handleSubmit, FORM_EARNRULES, package_features } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        let earnRuleTypeList = plugins.getEarnRuleListByPacekageFeature(package_features)

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth={['product', 'purchaseAmount'].indexOf(FORM_EARNRULES.values.type) >= 0 ? "lg" : "sm"}
                disableRestoreFocus={true}
                scroll="body">

                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}><b>{formMode === 'add' ? 'Add Rule' : (readOnly ? 'Rule Info' : 'Edit Rule')}</b></h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent className={classes.modalBody}>

                        <GridContainer>
                            <GridItem xs={12} sm={6} md={8}>
                                <Field
                                    id='rule-name-input'
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Name *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                                {formMode === "edit" ?
                                    <Field
                                        id='rule-id-input'
                                        name="_id"
                                        component="input"
                                        type="hidden"
                                    />
                                    : null}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>
                                <Field
                                    id='rule-status-select'
                                    name="status"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Status *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={[
                                        {
                                            value: "Active",
                                            label: "Active"
                                        },
                                        {
                                            value: "Inactive",
                                            label: "Inactive"
                                        }
                                    ]}
                                    disabled={readOnly}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='start-date-select'
                                    name="startDate"
                                    component={CustomDatePicker}
                                    type="text"
                                    maxDate={FORM_EARNRULES.values.endDate}
                                    labelText="Start Date *"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='end-date-select'
                                    name="endDate"
                                    minDate={FORM_EARNRULES.values.startDate}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText="End Date"
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={4} md={4}>
                                <Field
                                    id='rule-type-select'
                                    name="type"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Type *"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    optionsList={earnRuleTypeList}
                                    disabled={readOnly || formMode === "edit"} />
                            </GridItem>
                        </GridContainer>
                        {this.renderFormType()}
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        {!readOnly ?
                            <Button
                                id='submit-btn'
                                type="submit"
                                disabled={(FORM_EARNRULES.values.type === "code" || FORM_EARNRULES.values.type === "purchaseAmount" || FORM_EARNRULES.values.type === "product") ? false : true}
                                style={{ marginRight: "10px" }}
                                color="primary">
                                {formMode === "edit" ? "Update" : "Submit"}
                            </Button>
                            : ''
                        }
                        <Button
                            id='cancel-btn'
                            type="button"
                            onClick={() => {
                                dispatch({ type: earnrulesConstants.CLOSE_FORM_EARN_RULES });
                            }}
                            color="white">
                            Cancel
        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }

}

function mapStateToProps(state) {
    const { formOpen, formMode, productList, allProductList, joinedProductRows, joinedProductList, allShopListSelected, productListMaster, luckyDrawRewardSet } = state.earnrules;
    const { FORM_EARNRULES } = state.form;
    const { package_features } = state.authentication
    return {
        formOpen, formMode, FORM_EARNRULES, allProductList, productList, joinedProductRows, joinedProductList, allShopListSelected, productListMaster, package_features, luckyDrawRewardSet
    };
}

const connectCampaignForm = connect(mapStateToProps)(EarnRulesForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {

        let globalStore = store.getState();
        let formMeta = globalStore.form[FORM_NAME];

        if (formMeta.values.type === "product") {
            for (let j in errors) {
                if (j == 'singleProductNameSkuSelect' || j == 'singleProductPointInput' || j == 'singleProductQuantityInput') {
                    let temp = lodash.get(errors, j)
                    if (temp.length > 0) {
                        let obj = temp.find(o => lodash.isUndefined(o) === false);
                        alert(dispatch, 'error', obj)
                    }
                }
                else {
                    crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
                }
            }
        } else {
            crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
            if (formMeta.values.type === "code" && !globalStore.alert.isShow) {
                if (lodash.has(errors, 'earnRuleCode') && !formMeta.values.earnRuleCode) {
                    alert(dispatch, 'error', 'Please enter Earn Rule Code')//ท่านยังไม่ได้กรอก Earn Rule Code กรุณากรอกข้อมูลให้ครบถ้วน
                } else if (lodash.has(errors, 'selectedProductCount')) {
                    alert(dispatch, 'error', 'Please select at least a product')
                }
            }
        }
    }
})(withStyles(modalStyle)(connectCampaignForm))
export const treemapConstants = {
    OPEN_FORM_EDIT: 'OPEN_EDIT_FORM_TREEMAP',
    CLOSE_FORM_TREEMAP: 'CLOSE_FORM_SHIPMENT',
    RELOAD_TABLE_TREEMAP_LIST: 'RELOAD_TABLE_TREEMAP_LIST',
    
    TABLE_RESET: 'TABLE_RESET',
    TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',
    EDIT_TREEMAP_SUCCESS: 'EDIT_TREEMAP_SUCCESS',
    EDIT_TREEMAP_FAILURE: 'EDIT_TREEMAP_FAILURE',
    HIDE_ALERT: 'HIDE_ALERT',
    CLEAR_PAGE_STATE: 'TREEMAP_CLEAR_PAGE_STATE',
    
    SHOW_LOADER_TREEMAP: 'SHOW_LOADER_TREEMAP',
    
    GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS: 'GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS',
    GET_SHIPPING_DETAIL: 'GET_SHIPPING_DETAIL',
    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
    TABLE_GET_LIST_DATAS_RESET: 'TABLE_GET_LIST_DATAS_RESET',
    SET_FILTER: 'TABLE_TREEMAP_SETFILTER',
    GET_DROPDOWN_REFERENCE_LIST_SUCCESS: "GET_DROPDOWN_REFERENCE_LIST_SUCCESS",
    GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS: "GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS",
    GET_DROPDOWN_REWARD_LIST_SUCCESS: "GET_DROPDOWN_REWARD_LIST_SUCCESS",

    HIDE_LOADER_TREEMAP: 'HIDE_LOADER_TREEMAP',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
    TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_DATAS',
    GET_TREEMAP_LIST_SUCCESS : "GET_TREEMAP_LIST_SUCCESS",

    OPEN_FORM_VISUALIZATION: 'OPEN_FORM_VISUALIZATION',
    CLOSE_FORM_VISUALIZATION: 'CLOSE_FORM_VISUALIZATION',

    SET_SEGMENT_IN_OPEN_FORM_CAMPAIGNS: 'SET_SEGMENT_IN_OPEN_FORM_CAMPAIGNS',
    OPEN_FORM_CAMPAIGNS: 'OPEN_FORM_CAMPAIGNS',
    CLOSE_FORM_CAMPAIGNS: 'CLOSE_FORM_CAMPAIGNS',

    GET_CAMPAIGN_LIST_SUCCESS: 'GET_CAMPAIGN_LIST_SUCCESS',
    
    ADD_NEW_CAMPAIGN_USE: 'ADD_NEW_CAMPAIGN_USE',

    ADDED_CAMPAIGN_SUCCESS: 'ADDED_CAMPAIGN_SUCCESS'
};

export const shipmentConstants = {
    OPEN_FORM_EDIT: 'OPEN_EDIT_FORM_SHIPMENT',
    CLOSE_FORM_SHIPMENT: 'CLOSE_FORM_SHIPMENT',
 
    RELOAD_TABLE_SHIPMENTLIST: 'RELOAD_TABLE_SHIPMENTLIST',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
    TABLE_RESET: 'TABLE_RESET',
    TABLE_RESET_SUCCESS: 'TABLE_RESET_SUCCESS',
 
    EDIT_SHIPMENT_SUCCESS: 'EDIT_SHIPMENT_SUCCESS',
    EDIT_SHIPMENT_FAILURE: 'EDIT_SHIPMENT_FAILURE',

    HIDE_ALERT: 'HIDE_ALERT',
 
    CLEAR_PAGE_STATE: 'SHIPMENT_CLEAR_PAGE_STATE',
    TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_SHIPMENTS',
 
    SHOW_LOADER_SHIPMENT: 'SHOW_LOADER_SHIPMENT',
    HIDE_LOADER_SHIPMENT: 'HIDE_LOADER_SHIPMENT',

    GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS: 'GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS',
    GET_SHIPPING_DETAIL: 'GET_SHIPPING_DETAIL',
    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',

    TABLE_GET_LIST_DATAS_RESET: 'TABLE_GET_LIST_DATAS_RESET',
    SET_FILTER: 'TABLE_SHIPMENT_SETFILTER',

    OPEN_SHIPMENT_FORM_IMPORT: 'OPEN_SHIPMENT_FORM_IMPORT',
    CLOSE_SHIPMENT_FORM_IMPORT: 'CLOSE_SHIPMENT_FORM_IMPORT',

    SHOW_EARN_RULE_IMPORT_PRODUCT_RESULT: 'SHOW_EARN_RULE_IMPORT_PRODUCT_RESULT',
    HIDE_EARN_RULE_IMPORT_PRODUCT_RESULT: 'HIDE_EARN_RULE_IMPORT_PRODUCT_RESULT'
 };
 
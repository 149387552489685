export const reportConstants = {
  REPORT_REDUCER_INIT: 'REPORT_REDUCER_INIT',
  INSIGHT_TABLE_RELOAD: 'INSIGHT_TABLE_RELOAD',
  INSIGHT_TABLE_RELOAD_SUCCESS: 'INSIGHT_TABLE_RELOAD_SUCCESS',

  INSIGHT_TABLE_RESET: 'INSIGHT_TABLE_RESET',
  INSIGHT_TABLE_RESET_SUCCESS: 'INSIGHT_TABLE_RESET_SUCCESS',
  GET_MEMBER_REPORT_SUCCESS: 'GET_MEMBER_REPORT_SUCCESS',

  GET_DROPDOWN_PRODUCT_LIST_SUCCESS: 'GET_DROPDOWN_PRODUCT_LIST_SUCCESS',
  GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
  GET_EARN_RULES_DATA_SUCCESS: 'GET_EARN_RULES_DATA_SUCCESS',
  
  GET_EARN_TRANSACTON_SUCCESS: 'GET_EARN_TRANSACTON_SUCCESS',
  EARN_TRANSACTON_TABLE_RESET: 'EARN_TRANSACTON_TABLE_RESET',

  GET_BURN_TRANSACTON_SUCCESS: 'GET_BURN_TRANSACTON_SUCCESS',
  BURN_TRANSACTON_TABLE_RESET: 'BURN_TRANSACTON_TABLE_RESET',

  GET_LUCKYDRAW_DOWLOAD_SUCCESS: 'GET_LUCKYDRAW_DOWLOAD_SUCCESS',
  LUCKYDRAW_DOWLOAD_RESET: 'LUCKYDRAW_DOWLOAD_RESET',

  OPEN_EARN_REPORT_DETAIL: 'OPEN_EARN_REPORT_DETAIL',
  CLOSE_EARN_REPORT_DETAIL: 'CLOSE_EARN_REPORT_DETAIL',

  OPEN_EARN_REPORT_IMAGES: 'OPEN_EARN_REPORT_IMAGES',
  CLOSE_EARN_REPORT_IMAGES: 'CLOSE_EARN_REPORT_IMAGES',

  OPEN_EARN_REPORT_EDIT: 'OPEN_EARN_REPORT_EDIT',
  CLOSE_EARN_REPORT_EDIT: 'CLOSE_EARN_REPORT_EDIT',

  OPEN_EARN_REPORT_EDIT_VITAMILK: 'OPEN_EARN_REPORT_EDIT_VITAMILK',
  CLOSE_EARN_REPORT_EDIT_VITAMILK: 'CLOSE_EARN_REPORT_EDIT_VITAMILK',

  EDIT_EARN_REPORT_SUCCESS: 'EDIT_EARN_REPORT_SUCCESS',
  TABLE_EARN_REPORT_RELOAD_SUCCESS: 'TABLE_EARN_REPORT_RELOAD_SUCCESS',

  OPEN_REPORT_USER_AGENT_DETAIL:"OPEN_USER_AGENT_DETAIL",
  CLOSE_REPORT_USER_AGENT_DETAIL:"CLOSE_USER_AGENT_DETAIL",
};
import React from "react";
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import lodash from 'lodash';
import moment from 'moment'
import { plugins } from "helpers";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import { crmValidator } from 'helpers';
import TablePointByPurchaseList from "./TablePointByPurchaseList.jsx";
import { pointByPurchaseActions } from 'actions';
import { pointByPurchaseConstants } from '../../constants';

const style = {}
const FORM_NAME = 'FORM_FILTER_POINT_POINT_BY_PURCHASE';
const form_validate = {
    phoneNumber: {
      rules: {
        number: true
      }
    }
};

class PointByPurchaseReport extends React.Component {

    handleSubmit(values) { 
        const { dispatch, tableFilter } = this.props;
        let _tableFilter = {
            page: 0,
            skip: 0,
            limit: tableFilter.limit,
            keyword: "",
            sortBy: "created_at",
            sortDir: "desc",
            search: {}
        }
        // _tableFilter = {
        //   ..._tableFilter,
        //   ...values
        // }
        let search = { ...values };
        if (lodash.size(search) > 0) {
          _tableFilter.search = search;
        }
        const resetTable = true;
        dispatch({ type: pointByPurchaseConstants.SET_FILTER_SEARCH, search: _tableFilter })
        dispatch(pointByPurchaseActions.getTablePointByPurchaseDatas(_tableFilter, resetTable));
    }

    clearFilter = () => {
        const { dispatch, tableFilter } = this.props;
        let _tableFilter = {
            page: 0,
            skip: 0,
            limit: tableFilter.limit,
            sortBy: "created_at",
            sortDir: "desc",
        }
        dispatch({ type: "CLEAR_FILTER_SEARCH" })
        dispatch(reset(FORM_NAME))
        dispatch(pointByPurchaseActions.getTablePointByPurchaseDatas(_tableFilter));
    }

    componentDidMount() {
        const { tableFilter, dispatch } = this.props;
        let params = {
            page: 0,
            skip: 0,
            sortBy: "created_at",
            sortDir: "desc",
        }
        dispatch(pointByPurchaseActions.getTablePointByPurchaseDatas(tableFilter));
        dispatch(pointByPurchaseActions.getListsAdmin(params));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: "CLEAR_FILTER_SEARCH" })
        dispatch(reset(FORM_NAME))
    }

    render() {
        const { handleSubmit, FORM_FILTER_POINT_POINT_BY_PURCHASE, updateByList } = this.props;

        let startMaxDatePointByPurchase = new Date();
        if (lodash.get(FORM_FILTER_POINT_POINT_BY_PURCHASE, 'values.endDate')) {
            startMaxDatePointByPurchase = FORM_FILTER_POINT_POINT_BY_PURCHASE.values.endDate;
        }
        let endMinDatePointByPurchase;
        if (lodash.get(FORM_FILTER_POINT_POINT_BY_PURCHASE, 'values.startDate')) {
            endMinDatePointByPurchase = FORM_FILTER_POINT_POINT_BY_PURCHASE.values.startDate;
        }
        let startMaxDatePurchase = new Date();
        if (lodash.get(FORM_FILTER_POINT_POINT_BY_PURCHASE, 'values.purchaseEndDate')) {
            startMaxDatePurchase = FORM_FILTER_POINT_POINT_BY_PURCHASE.values.purchaseEndDate;
        }
        let endMinDatePurchase;
        if (lodash.get(FORM_FILTER_POINT_POINT_BY_PURCHASE, 'values.purchaseStartDate')) {
            endMinDatePurchase = FORM_FILTER_POINT_POINT_BY_PURCHASE.values.purchaseStartDate;
        }
        return (
            <div>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* start date */}
                            <Field
                                id="start-date-input"
                                name="startDate"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Start Date"
                                maxDate={startMaxDatePointByPurchase}
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* end date */}
                            <Field
                                id="end-date-input"
                                name="endDate"
                                component={CustomDatePicker}
                                type="text"
                                labelText="End Date"
                                minDate={endMinDatePointByPurchase}
                                maxDate={new Date()}
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* first name */}
                            <Field
                                id="first-name-input"
                                name="memberFirstName"
                                component={CustomInput}
                                type="text"
                                labelText="Firstname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* last name */}
                            <Field
                                id="last-name-input"
                                name="memberLastName"
                                component={CustomInput}
                                type="text"
                                labelText="Lastname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* phone number */}
                            <Field
                                id="phone-input"
                                name="phoneNumber"
                                component={CustomInput}
                                type="text"
                                labelText="Mobile No."
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, form_validate.phoneNumber)
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* sex */}
                            <Field
                                id="sex-inpot"
                                name="filterSex"
                                component={CustomSelect}
                                type="text"
                                labelText="Gender"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                novalidate
                                optionsList={[
                                    {
                                        label: "Male",
                                        value: "M"
                                    },
                                    {
                                        label: "Female",
                                        value: "F"
                                    },
                                    {
                                        label: "Other",
                                        value: "O"
                                    },
                                    {
                                        label: "Not specified",
                                        value: "N"
                                    }
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* province */}
                            <Field
                                id="province-input"
                                name="filterProvince"
                                component={CustomSelect}
                                type="text"
                                labelText="Province"
                                novalidate
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* reference code */}
                            <Field
                                id="reference-code-input"
                                name="refCode"
                                component={CustomInput}
                                type="text"
                                labelText="Reference Code"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* start date purchase */}
                            <Field
                                id="purchase-start-date-input"
                                name="purchaseStartDate"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Purchase Start Date"
                                maxDate={startMaxDatePurchase}
                                dateFormat="DD-MMM-YY"
                                // timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* end date purchase */}
                            <Field
                                id="purchase-end-date-input"
                                name="purchaseEndDate"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Purchase End Date"
                                minDate={endMinDatePurchase}
                                maxDate={new Date()}
                                dateFormat="DD-MMM-YY"
                                // timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* update by */}
                            <Field
                                id="update-by-input"
                                name="created_by"
                                component={CustomSelect}
                                type="text"
                                labelText="Updated By"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={updateByList}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* status */}
                            <Field
                                id="status-input"
                                name="status"
                                component={CustomSelect}
                                type="text"
                                labelText="Status"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    {
                                        label: 'All',
                                        value: 'all'
                                    }, 
                                    {
                                        label: 'Success',
                                        value: 'success'
                                    }, 
                                    {
                                        label: 'Voided',
                                        value: 'voided'
                                    },
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* source */}
                            <Field
                                id="source-input"
                                name="source"
                                component={CustomSelect}
                                type="text"
                                labelText="Source"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    {
                                        label: 'All',
                                        value: 'All'
                                    }, 
                                    {
                                        label: 'Import',
                                        value: 'Import'
                                    }, 
                                    {
                                        label: 'Manual',
                                        value: 'Manual'
                                    },
                                    {
                                        label: 'External API',
                                        value: 'POS'
                                    },
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}> {/* botton clear | submit  */}
                            <Button
                                id="clear-btn"
                                onClick={this.clearFilter}
                                style={{
                                    marginRight: "10px", marginTop: "25px"
                                }}
                                size="sm"
                                color="white">
                                Clear
                            </Button>
                            <Button
                                id="submit-btn"
                                type="submit"
                                style={{
                                    marginTop: "25px"
                                }}
                                size="sm"
                                color="primary">
                                Submit
                            </Button>


                        </GridItem>
                    </GridContainer>
                </form>

                <TablePointByPurchaseList />

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { tableFilter, updateByList } = state.point_by_purchase;
    const { FORM_FILTER_POINT_POINT_BY_PURCHASE } = state.form;
    const { merchantSetting } = state.authentication;

    return {
        merchantSetting, tableFilter, FORM_FILTER_POINT_POINT_BY_PURCHASE, updateByList
    }
}

const connectedComponent = connect(mapStateToProps)(PointByPurchaseReport);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent))
import React from "react";
import PropTypes from "prop-types";

import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

import withStyles from "@material-ui/core/styles/withStyles";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

function CustomCheckbox({ ...props }) {
   const {
      input,
      classes,
      labelText,
      meta,
      notLabel,
      disabled,
      checked,
      id,
   } = props;

   const error = (meta !== undefined && meta.touched && meta.invalid);
   const errorLabel = error ? " " + classes.labelRootError : ""

   return (
      <>
         {notLabel ? <FormLabel component="legend"></FormLabel> : ""}
         <FormControlLabel
            classes={{
               label: classes.label + errorLabel
            }}
            disabled={disabled}
            label={labelText !== undefined ? labelText : false}
            control={
               <Checkbox
                  tabIndex={-1}
                  onChange={input.onChange}
                  checked={(input.value || checked) ? true : false}
                  disabled={disabled}
                  checkedIcon={
                     <Check className={classes.checkedIcon} />
                  }
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                     checked: classes.checked,
                     disabled: classes.disabledCheckboxAndRadio,
                     root: classes.checkRoot
                  }}
                  id={id}
               />
            }
         />
      </>
   );
}

CustomCheckbox.propTypes = {
   classes: PropTypes.object.isRequired,
   labelText: PropTypes.node,
};

export default withStyles(customCheckboxRadioSwitch)(CustomCheckbox);

import lodash from "lodash";


export const validateRequired = (value, allValues, props, name) => {
    let errMgs = undefined
    let checkedPurchase = lodash.get(allValues, 'purchaseAmount', null)
    let checkedEarn = lodash.get(allValues, 'earnPoint', null)
    let checkedTime = lodash.get(allValues, 'numberTime', null)
    let checkedActive = lodash.get(allValues, 'numberActive', null)
    let checkName = lodash.split(name, '_', 2)

    if (checkedPurchase && lodash.isEqualWith(checkName[1], 'purchaseAmount')) {
        if (lodash.isUndefined(value)) {
            errMgs = "Please enter amount"
        } 
        // else if (lodash.parseInt(value) === 0) {
        //     errMgs = "Input value must be greater than 0"
        // }
    } else if (checkedEarn && lodash.isEqualWith(checkName[1], 'earnPoint')) {
        if (lodash.isUndefined(value)) {
            errMgs = "Please enter amount"
        } 
        // else if (lodash.parseInt(value) === 0) {
        //     errMgs = "Input value must be greater than 0"
        // }
    } else if (checkedTime && lodash.isEqualWith(checkName[1], 'numberTime')) {
        if (lodash.isUndefined(value)) {
            errMgs = checkName[0] === "type" ? "Input value can not be invalid" : "Please enter amount"
        } 
        // else if (lodash.parseInt(value) === 0) {
        //     errMgs = "Input value must be greater than 0"
        // }
    } else if (checkedActive && lodash.isEqualWith(checkName[1], 'numberActive')) {
        if (lodash.isUndefined(value)) {
            errMgs = "Please enter amount"
        } 
        // else if (lodash.parseInt(value) === 0) {
        //     errMgs = "Input value must be greater than 0"
        // }
    }
    return errMgs
}


export const ruleBaseValidate = (value, allValues, props, name) => {
    let errMgs = "Please select the condition at least one "
    let checkedPurchase = lodash.get(allValues, 'purchaseAmount', null)
    let checkedEarn = lodash.get(allValues, 'earnPoint', null)
    let checkedTime = lodash.get(allValues, 'numberTime', null)
    let checkedActive = lodash.get(allValues, 'numberActive', null)

    if (checkedPurchase || checkedEarn || checkedTime || checkedActive) {
        errMgs = undefined
    }
    return errMgs
}

export const collectEveryValidate = (value, allValues, props, name) => {
    let errMgs = ""
    const { collectWithin } = allValues
    if (lodash.isEqualWith(collectWithin, 'day') && Number(value) > 365) {
        errMgs = "Please input value not over 365 days !"
    }
    return errMgs
}
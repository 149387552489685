import { configConstants } from '../constants';
import axios from 'axios';
import { history } from 'helpers';

export const dfnewConditionService = {
    getTableConfig,
    addNewCondition
}

const apiContentType = "application/json";

function getTableConfig(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    let tmp = axios.post(configConstants.API_DATAFILTERING + `/table-config/list`,params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

async function addNewCondition(params) {
    let param = await setDatay(params)
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    let tmp = axios.post(configConstants.API_DATAFILTERING + `/data-filtering/condition`,param, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function setDatay(params){
    const { result, value } = params
    const {conditions } = result
    let arrayData = [];
    let arrayMain = [];
     conditions.map((data,index) => {
       arrayMain = []
       if(data.type === 'main'){
          if(data.conditions && data.conditions.length > 0){
             arrayMain = data.conditions
          }
          arrayMain.push({
             "compareOperator": data.compareOperator,
             "exclude": data.exclude,
             "field": data.field,
             "fieldtype": data.fieldtype,
             "operator": "OR",
             "table": data.table,
             "fieldtype": data.fieldtype,
             "value": data.value,
             "lastvalue": data.lastvalue
          })
       }
       arrayData[index] = {
          "operator": "AND",
          "conditions": arrayMain
       }
     })
 
    const paramData = {
       "templateName": value.templateName,
       "conditions": arrayData
    }
    return paramData
 }
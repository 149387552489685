import { reset } from 'redux-form'
import lodash from 'lodash';

import { uploadWinnerServices } from '../services';
import { uploadWinnerConstants } from '../constants';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const uploadWinnerAction = {
    getCampaignList,
    getUploadWinnerList,
    addUploadWinner,
    editUploadWinner,
    uploadUploadWinner,
    saveUploadUploadWinner,
    deleteUploadWinner,
    // importMember
    getAnnounceDateList,
    getRewardList,
};

function getCampaignList(params) {
    return dispatch => {
        uploadWinnerServices.getCampaignsList(params)
            .then(
                response => {
                    if (response.status) {
                        if (lodash.has(response, 'data')) {
                            dispatch(success(response.data));
                        }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                // loader(dispatch, false)
            })
    };

    function success(data) {
        return { type: uploadWinnerConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data }
    }
}

function getAnnounceDateList(params) {
    return dispatch => {
        uploadWinnerServices.getAnnounceDateList(params)
            .then(
                response => {
                    if (response.status) {
                        if (lodash.has(response, 'data')) {
                            dispatch(success(response.data));
                        }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                // loader(dispatch, false)
            })
    };

    function success(data) {
        return { type: uploadWinnerConstants.GET_DROPDOWN_ANNOUCE_DATE_LIST_SUCCESS, data }
    }
}

function getRewardList(params) {
    return dispatch => {
        uploadWinnerServices.getRewardList(params)
            .then(
                response => {
                    if (response.status) {
                        if (lodash.has(response, 'data')) {
                            dispatch(success(response.data));
                        }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                // loader(dispatch, false)
            })
    };

    function success(data) {
        return { type: uploadWinnerConstants.GET_DROPDOWN_REWARD_LIST_SUCCESS, data }
    }
}

function getUploadWinnerList(params, tableReset = false) {
    return dispatch => {
        dispatch({ type: uploadWinnerConstants.SHOW_LOADER })

        uploadWinnerServices.getUploadWinnerList(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({ type: uploadWinnerConstants.GET_UPLOAD_WINNER_SUCCESS, data: response.data.data, total: response.data.total, tableFilter: params });
                        // if (tableReset) {
                        //    dispatch({ type: whitelistConstants.TABLE_GET_LIST_DATAS_RESET });
                        // }
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                dispatch({ type: uploadWinnerConstants.HIDE_LOADER })
            })
    };
}

function addUploadWinner(params) {
    // console.log('addNewWhitelist action');
    // console.log('params :', params);
    return dispatch => {
        loader(dispatch, true)

        uploadWinnerServices.addUploadWinner(params)
            .then(
                response => {
                    //console.log(response)
                    if (response.status) {
                        dispatch({
                            type: uploadWinnerConstants.ADD_NEW_UPLOAD_WINNER_SUCCESS
                        });

                        customAlert(dispatch, 'success', i18n.t('warning_messages.uploadWinner_was_created'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    //console.log(error)
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    //function success(tmp) { return { type: whitelistConstants.ADD_NEW_WHITELIST_SUCCESS, tmp } }
}

function editUploadWinner(params) {
    return dispatch => {
        loader(dispatch, true)

        uploadWinnerServices.editUploadWinner(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                        customAlert(dispatch, 'success', i18n.t('warning_messages.uploadWinner_was_updated'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: uploadWinnerConstants.EDIT_UPLOAD_WINNER_SUCCESS, tmp } }
}

function deleteUploadWinner(params) {
    //console.log('action',params);
    return dispatch => {
        loader(dispatch, true)
        uploadWinnerServices.deleteUploadWinner(params)
            .then(
                (response) => {
                    //console.log(response);
                    if (response.status) {
                        dispatch({ type: uploadWinnerConstants.DELETE_UPLOAD_WINNER_SUCCESS })
                        customAlert(
                            dispatch,
                            "success",
                            i18n.t("warning_messages.uploadWinner_was_deleted")
                        )
                    } else {
                        if (!response.unAlert)
                            customAlert(
                                dispatch,
                                "error",
                                i18n.t(`api_messages.${response.message.toString()}`)
                            )
                    }
                },
                (error) => {
                    customAlert(dispatch, "error", i18n.t(defaultFailedText))
                }
            )
            .then(() => {
                loader(dispatch, false)
            })
    };
}

function uploadUploadWinner(params) {
    console.log('active params: ', params);
    return dispatch => {
        loader(dispatch, true)
        uploadWinnerServices.uploadUploadWinner(params)
            .then(
                response => {
                    if (response.status) {
                        //console.log('actions upload winner response', response);
                        dispatch({
                            type: uploadWinnerConstants.SHOW_UPLOAD_UPLOAD_WINNER_RESULT,
                            data: response.data.winner_upload
                        });
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

function saveUploadUploadWinner(params) {
    return dispatch => {
        loader(dispatch, true)
        //console.log('save action params', params);
        uploadWinnerServices.saveUploadUploadWinner(params)
            .then(
                response => {
                    if (response.status) {
                        //console.log(response.data)
                        dispatch({
                            type: uploadWinnerConstants.OPEN_SAVE_UPLOAD_UPLOAD_WINNER_RESULT,
                            data: response.data
                        });
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    }
}

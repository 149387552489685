export const orderConstants = {
    CLEAR_PAGE_STATE: 'ORDER_CLEAR_PAGE_STATE',
    OPEN_EDIT_ORDER: 'OPEN_EDIT_ORDER',
    CLOSE_EDIT_ORDER: 'CLOSE_EDIT_ORDER',

    EDIT_ORDER_SUCCESS: 'EDIT_ORDER_SUCCESS',
    EDIT_ORDER_FAILURE: 'EDIT_ORDER_FAILURE',

    DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',

    TABLE_GET_LIST_ORDER: 'TABLE_GET_LIST_ORDER',

    HIDE_ALERT: 'HIDE_ALERT',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',
 };
import React from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, getFormSyncErrors } from 'redux-form';
import moment from "moment";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";

import { brandAction } from "actions"

import * as FormValidator from "./validate/FormValidator"

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { orderConstants } from "../../../constants";
import { orderActions } from "actions";

const FORM_NAME = "order_form";

let shippingList = [];
shippingList.push(
  {
    value: "Aden",
    label: "Aden"
  },
  {
    value: "Alpha Fast",
    label: "Alpha Fast"
  },
  {
    value: "CJ Logistics",
    label: "CJ Logistics"
  },
  {
    value: "Deliveree",
    label: "Deliveree"
  },
  {
    value: "DHL",
    label: "DHL"
  },
  {
    value: "FedEX",
    label: "FedEX"
  },
  {
    value: "Flash Express",
    label: "Flash Express"
  },
  {
    value: "IT Transport",
    label: "IT Transport"
  },
  {
    value: "J&T Express",
    label: "J&T Express"
  },
  {
    value: "BEST Express",
    label: "BEST Express"
  },
  {
    value: "Kerry Express",
    label: "Kerry Express"
  },
  {
    value: "LALAMOVE",
    label: "LALAMOVE"
  },
  {
    value: "MrSpeedy",
    label: "MrSpeedy"
  },
  {
    value: "NINJA VAN",
    label: "Ninja Van"
  },
  {
    value: "SCG Yamato Express",
    label: "SCG Yamato Express"
  },
  {
    value: "SendIT",
    label: "SendIT"
  },
  {
    value: "Thailand Post",
    label: "Thailand Post"
  },
  {
    value: "TNT",
    label: "TNT"
  },
  {
    value: "361 Express",
    label: "361 Express"
  },
  {
    value: "Others",
    label: "Others"
  },
)

const styleTextRight = { textAlign: 'right' }
const style = {
    ...modalStyle,
    cardBody: {
        padding: "15px"
    },
    cardHeader: {
        marginLeft: "0px",
        color: "#333"
    },
    formFooter: {
        textAlign: 'right'
    },
    btnSubmit: {
        marginRight: "20px"
    },
    conditionBlock: {
        padding: "10px",
        marginLeft: "0px",
        border: "dashed 1px #ccc"
    },
    tdBordered: {
        border: "solid 1px #ddd"
    },
    subHeader: {
        fontWeight: 900,
        fontSize: "16px",
        marginTop: "20px"
    }
}

class OrderForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    }

    sumTotal = (value) => {
        let total = 0
        total = value.items.reduce((sum, item) => sum + item.amount, 0)
        if (value.shipment.shippingCost) {
            total = total+value.shipment.shippingCost
        }
        const couponValue = value.promotion ? value.promotion.find(item => "coupon" in item) : null
        if (couponValue) {
            total = total+couponValue.coupon
        }
        return Number(total.toFixed(2))
    }

    handleSubmit(values) {
        const { dispatch } = this.props

        const dbDateFormat = "YYYY-MM-DDTHH:mm:ss.sssZ"

        const reqBody = {
            _id: values._id,
            payment: {
                status: values.payment.status,
                remark: values.payment.remark,
            },
            shipment: {
                status: {
                    waiting: values.shipment.status.waiting ? moment(values.shipment.status.waiting).format(dbDateFormat) : '',
                    inProgress: values.shipment.status.inProgress ? moment(values.shipment.status.inProgress).format(dbDateFormat) : '',
                    done: values.shipment.status.done ? moment(values.shipment.status.done).format(dbDateFormat) : '',
                },
                trackingNumber: values.shipment.trackingNumber,
                trackingProvider: values.shipment.trackingProvider,
            }
        }

        dispatch(orderActions.updateOrder(reqBody))
    }

    getListBrands = (brands,formInitialValue) => {
        if (brands !== undefined) {
           return brands.filter(item=>item._id == formInitialValue.brandId)[0]
        }
        return []
      }

    componentDidMount () {
        const {
          dispatch
        } = this.props

        dispatch(brandAction.getBrandLists(''))
      }

    render() {
        const {
            classes,
            formMode,
            formValues,
            handleSubmit,
            dispatch,
            formInitialValue,
        } = this.props
        let { readOnly } = this.props

        const handlePaymentStatusChange = (status) => {
            if (status === 'paid' && !formValues.shipment.delivery) {
                formValues.waitingStatus = true
                formValues.shipment.status.waiting = moment().format('YYYY-MM-DD HH:mm:ss').toString()
            } else if (status === 'paid' && formValues.shipment.delivery) {
                    formValues.inprogressStatus = true
                    formValues.shipment.status.inProgress = moment().format('YYYY-MM-DD HH:mm:ss').toString()
            } else {
                formValues.waitingStatus = false
                formValues.shipment.status.waiting = ""
                formValues.inprogressStatus = false
                formValues.shipment.status.inProgress = ""
            }
        }

        return (
            <>
            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                <Card>
                    <CardBody>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4>Order</h4>
                        {this.props.brands != null &&
                       this.props.brands.map(data=>(
                        data._id == formInitialValue.brandId &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={data.filePath} alt={data.name} style={{ width: '50px', height: '50px',marginRight:"10px" }} />
                                    <span key={data._id} style={{ marginRight: '10px' }}>{data.name}</span>
                                </div>
                       ))

                        }
                    </div>

                        <Field
                            name="orderId"
                            component={CustomInput}
                            type="text"
                            labelText={"Order ID"}
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={true}
                        />

                        <Table aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Product name</TableCell>
                                    <TableCell>SKU ID</TableCell>
                                    <TableCell>Group</TableCell>
                                    <TableCell>Sub Group</TableCell>
                                    <TableCell>Weight (kg)</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Total Amount</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {formInitialValue.items.map((item) => (
                                    <TableRow key={item.orderId}>
                                        <TableCell>{item.productName || ''}</TableCell>
                                        <TableCell>{item.skuId}</TableCell>
                                        <TableCell>{item.group}</TableCell>
                                        <TableCell>{item.subGroup}</TableCell>
                                        <TableCell>{Number(item.wieghtKg.toFixed(2))}</TableCell>
                                        <TableCell>{Number(item.qty.toFixed(2))}</TableCell>
                                        <TableCell>{Number(item.price.toFixed(2))}</TableCell>
                                        <TableCell style={styleTextRight}>{Number(item.amount.toFixed(2))}</TableCell>
                                    </TableRow>
                                ))}
                                 {formInitialValue.promotion.map((item,index) => (
                                    <TableRow key={index}>
                                        <TableCell>{Object.keys(item)[0] === "coupon" ? "coupon" : item[Object.keys(item)[0]]}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell style={styleTextRight}>{item.coupon?Number(item.coupon.toFixed(2)):0.00}</TableCell>
                                    </TableRow>
                                ))}
                                  {formInitialValue.shipment.shippingCost &&
                                     <TableRow key={0}>
                                        <TableCell>ค่าจัดส่ง</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell>{}</TableCell>
                                        <TableCell style={styleTextRight}>{Number(formInitialValue.shipment.shippingCost.toFixed(2))}</TableCell>
                                    </TableRow>
                                }
                                    <TableRow key={0}>
                                        <TableCell>total</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{Number(formInitialValue.items.reduce((sum, item) => sum + item.wieghtKg, 0).toFixed(2))}</TableCell>
                                        <TableCell>{Number(formInitialValue.items.reduce((sum, item) => sum + item.qty, 0).toFixed(2))}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell style={styleTextRight}>{this.sumTotal(formInitialValue)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs="12" lg="6">
                                <h4>Payment</h4>
                            </GridItem>
                            <GridItem xs="12" lg="6" className="text-right">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position">
                                        <FormControlLabel control={<Radio color="primary" checked={formInitialValue.payment.taxRequired} />} label="ออกใบกำกับ" disabled />
                                        <FormControlLabel control={<Radio color="primary" checked={!formInitialValue.payment.taxRequired} />} label="ไม่ออกใบกำกับ" disabled />
                                    </RadioGroup>
                                </FormControl>
                            </GridItem>
                        </GridContainer>


                        <GridContainer>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="payment.status"
                                    component={CustomSelect}
                                    type="text"
                                    labelText={"Payment Status"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    //disabled={['paid', 'cancel'].includes(formValues.payment.status)}
                                    onChange={(e) => handlePaymentStatusChange(e.target.value)}
                                    optionsList={[
                                        {
                                            value: "wait",
                                            label: "รออัพโหลดสลิป"
                                        },
                                        {
                                            value: "checking",
                                            label: "รอยืนยันออเดอร์"
                                        },
                                        {
                                            value: "paid",
                                            label: "ชำระเงินแล้ว"
                                        },
                                        {
                                            value: "cancel",
                                            label: "ยกเลิกรายการสั่งซื้อ"
                                        }
                                    ]}
                                />


                                {formValues.payment.status === 'cancel' &&
                                    <Field
                                        name="payment.remark"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"Remark"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        multiline
                                        rows={3}
                                        validate={[
                                            FormValidator.validateTextInput
                                        ]}
                                    />
                                }

                                {formInitialValue.payment.filePath &&
                                <div className="text-center">
                                    <img src={formInitialValue.payment.filePath} height={500} width={'100%'} />
                                    <a href={formInitialValue.payment.filePath} target="_blank"><u>กดเพื่อดูรูปใหญ่</u></a>
                                </div>
                                }

                            </GridItem>

                            <GridItem xs={12} lg={6}>
                                {//formInitialValue.payment.taxRequired &&
                                <div>
                                { !formInitialValue.payment.taxRequired || formInitialValue.payment.header == "บุคลธรรมดา"?
                                    <>
                                        <Field
                                            name="payment.customerName"
                                            component={CustomInput}
                                            type="text"
                                            labelText={"Customer name"}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            disabled={true}
                                        />
                                        <Field
                                            name="payment.customerPhoneNumber"
                                            component={CustomInput}
                                            type="text"
                                            labelText={"Customer phone number"}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            disabled={true}
                                        />
                                    </>
                                    :
                                    <>
                                        <Field
                                        name="payment.company"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"Company name"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                        />

                                        <Field
                                        name="payment.branch"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"Branch name"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                        />
                                    </>}
                                    <Field
                                        name="payment.taxID"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"Tax ID"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />

                                    <Field
                                        name="payment.subDistrict"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"Sub-District"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />

                                    <Field
                                        name="payment.district"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"District"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />

                                    <Field
                                        name="payment.province"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"Province"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />

                                    <Field
                                        name="payment.zipCode"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"Zipcode"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={true}
                                    />

                                    <Field
                                        name="payment.address"
                                        component={CustomInput}
                                        type="text"
                                        labelText={"Address"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        multiline
                                        rows={3}
                                        disabled={true}
                                    />
                                </div>
                                }
                            </GridItem>
                        </GridContainer>

                    </CardBody>
                </Card>

                <Card>
                    <CardBody>

                        <GridContainer>
                            <GridItem xs="12" lg="6">
                                <h4>{formInitialValue.shipment.delivery ? 'Customer' : 'Shop'} Address</h4>
                            </GridItem>
                            <GridItem xs="12" lg="6" className="text-right">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position">
                                        <FormControlLabel control={<Radio color="primary" checked={formInitialValue.shipment.delivery} />} label="Delivery" disabled />
                                        <FormControlLabel control={<Radio color="primary" checked={!formInitialValue.shipment.delivery} />} label="Pick Up" disabled />
                                    </RadioGroup>
                                </FormControl>
                            </GridItem>
                        </GridContainer>

                        {!formInitialValue.shipment.delivery &&
                        <GridContainer>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.shopName"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Shop name"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.subDistrict"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Sub-District"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.district"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"District"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.province"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Province"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.zipCode"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"ZipCode"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.address"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Address"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    multiline
                                    rows={3}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.dropOffTime"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Pick Up Time"}
                                    formControlProps={{
                                    fullWidth: true
                                 }}
                                 disabled={true}
                                />
                            </GridItem>
                        </GridContainer>
                        }

                        {formInitialValue.shipment.delivery &&
                        <GridContainer>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.contactName"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Customer name"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.contactPhoneNumber"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Customer phone number"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.subDistrict"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Sub-District"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.district"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"District"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.province"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Province"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.zipCode"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"ZipCode"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={true}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={6}>
                                <Field
                                    name="shipment.address"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Address"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    multiline
                                    rows={3}
                                    disabled={true}
                                />
                            </GridItem>
                        </GridContainer>
                        }

                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        <h4>Shipment</h4>

                        {!formValues.shipment.delivery &&
                        <GridContainer>
                            <GridItem xs="12" lg="3">
                                <Field
                                    id='waitingStatus'
                                    name="waitingStatus"
                                    component={CustomCheckbox}
                                    disabled={true}
                                    labelText={'กำลังเตรียมสินค้า'}
                                />
                            </GridItem>
                            <GridItem xs="12" lg="3">
                            <Field
                                    name="shipment.status.waiting"
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={"Date time"}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={!formValues.waitingStatus}
                                    validate={[
                                        formValues.waitingStatus ? FormValidator.validateDateSelect : () => ''
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>
                        }

                        {formValues.shipment.delivery &&
                        <GridContainer>
                            <GridItem xs="12" lg="3">
                                <Field
                                    id='inprogressStatus'
                                    name="inprogressStatus"
                                    component={CustomCheckbox}
                                    disabled={true}
                                    labelText={'กำลังเตรียมสินค้าและจัดส่ง'}
                                />
                            </GridItem>
                            <GridItem xs="12" lg="3">
                            <Field
                                    name="shipment.status.inProgress"
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={"Date time"}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={!formValues.inprogressStatus}
                                    validate={[
                                        formValues.inprogressStatus ? FormValidator.validateDateSelect : () => ''
                                    ]}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={3}>
                                <Field
                                    name="shipment.trackingProvider"
                                    component={CustomSelect}
                                    type="text"
                                    labelText="Tracking provider"
                                    formControlProps={{
                                    fullWidth: true
                                    }}
                                    disabled={!formValues.inprogressStatus}
                                    optionsList={shippingList}
                                />
                            </GridItem>
                            <GridItem xs={12} lg={3}>
                                <Field
                                    name="shipment.trackingNumber"
                                    component={CustomInput}
                                    type="text"
                                    labelText={"Tracking number"}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[
                                        formValues.shipment.trackingProvider ? FormValidator.validateTextInput : () => ''
                                    ]}
                                    disabled={!formValues.inprogressStatus}
                                />
                            </GridItem>
                        </GridContainer>
                        }

                        <GridContainer>
                            <GridItem xs="12" lg="3">
                                <Field
                                    id='doneStatus'
                                    name="doneStatus"
                                    component={CustomCheckbox}
                                    disabled={(!formValues.shipment.delivery && !formValues.waitingStatus && !formValues.shipment.status.waiting) || (formValues.shipment.delivery && !formValues.inprogressStatus && !formValues.shipment.status.inProgress && !formValues.shipment.trackingProvider && !formValues.shipment.trackingNumber)}
                                    labelText={'ยืนยันการรับสินค้า'}
                                />
                            </GridItem>
                            <GridItem xs="12" lg="3">
                            <Field
                                    name="shipment.status.done"
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText={"Date time"}
                                    dateFormat="DD-MMM-YY"
                                    timeFormat="HH:mm"
                                    disabled={!formValues.doneStatus}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    validate={[
                                        formValues.doneStatus ? FormValidator.validateDateSelect : () => ''
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>


                    </CardBody>
                </Card>

                <GridContainer className={classes.formFooter}>
                    <GridItem xs={12}>
                        <Button className={classes.btnSubmit} color="primary" type="submit">Submit</Button>
                        <Button color="white" type="button" onClick={() => {
                            // history.push('/sale/order')
                            dispatch({ type: orderConstants.CLOSE_EDIT_ORDER })
                        }}>Cancel</Button>
                    </GridItem>
                </GridContainer>
            </form>
            </>
        )
    }
}


function mapStateToProps(state) {
    const {
        formOpen,
        formMode,
        purchaseAmountPerbillOpen,
        purchaseProductConditionOpen,
        paymentChannelConditionOpen,
        productConditionIndex,
        promoCodeConditionOpen,
        rewardModalOpen,
        allShopListSelected,
        formInitialValue,
        additionalConditionShopShow,
        additionalConditionTimeShow,
    } = state.order;

    const { tableData: { brands } } = state.brand;
    const formValues = getFormValues(FORM_NAME)(state)
    const formSyncErrors = getFormSyncErrors(FORM_NAME)(state)

    return {
        formOpen,
        formMode,
        formValues,
        purchaseAmountPerbillOpen,
        purchaseProductConditionOpen,
        paymentChannelConditionOpen,
        productConditionIndex,
        promoCodeConditionOpen,
        rewardModalOpen,
        allShopListSelected,
        formInitialValue,
        formSyncErrors,
        brands,
        additionalConditionShopShow,
        additionalConditionTimeShow,
    };
}

const connectedForm = connect(mapStateToProps)(OrderForm);

export default reduxForm({
    form: FORM_NAME,
    // validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(style)(connectedForm))

import React from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import lodash from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { plugins, loader, alert as customAlert } from 'helpers';

import { treemapActions } from 'actions';
import { treemapConstants } from '../../constants';
import TableTreeMapList from "views/TreeMap/TableTreeMapList.jsx";
import { treemapServices } from 'services';

const style = {
    reportHeaderContainer: {
        margin: "0px",
        width: "calc(100% - 10px)",
        padding: "0px",
    },
    btnClearFilter: {
        marginRight: "10px",
        marginTop: "25px"
    },
    textCenter: {
        textAlign: "center"
    },
    mt20: {
        marginTop: "20px"
    },
    borderDashed: {
		border: "dashed 1px rgb(119 116 116)",
		padding: "20px 20px",
		width: "100%",
		margin: "15px 0px 10px 0px",
	},
    borderDescription: {
		border: "dashed 1px rgb(119 116 116)",
		padding: "20px 0px 20px 20px",
		width: "100%",
		// margin: "10px 0px 10px 0px",
	},
    
};

const pageStyle = { ...style };
const FORM_NAME = "formFilterTreemap";

class TreeMap extends React.Component {
    constructor(props) {
        super(props)
        this.inputRef = React.createRef()
        this.state = {
            csvData: [],
            campaignList: [],
            rewardList: [],
            treemapAllList: [],
            disableAtRisk: false,
            disableAsUsual: false,
            disableAboutTo: false,
            disableHighValue: false,
            disableTrueLove: false,
            cnt: 0
        };
    }

    handleSubmit(values) {
        const { dispatch, formFilterTreemap } = this.props;
        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "id",
            sortDir: "asc",
            keyword: "",
            search: {}
        }

        const {disableHighValue } = this.state

        this.setState({
            disableAtRisk: false,
            disableAsUsual: false,
            disableAboutTo: false,
            disableHighValue: false,
            disableTrueLove: false,
        })

        let segment = (formFilterTreemap.values === undefined || formFilterTreemap.values.segment === undefined) ? "" : formFilterTreemap.values.segment
        
        if(!segment){
            customAlert(dispatch, 'error', "Please select segment")
        }

        if(segment == "At Risk"){
            this.setState({
                disableAtRisk : true
            })
        }
        else if(segment == "As Usual") {
            this.setState({
                disableAsUsual : true
            })
        }
        else if(segment == "About to") {
            this.setState({
                disableAboutTo : true
            })
        }
        else if(segment == "High Value") {
            this.setState({
                disableHighValue : true
            })
        }
        else if(segment == "True Love") {
            this.setState({
                disableTrueLove : true
            })
        }

        tableFilter.search = {
            "segment": (formFilterTreemap.values === undefined || formFilterTreemap.values.segment === undefined) ? "" : formFilterTreemap.values.segment,
        }
        const resetTable = true
        dispatch(treemapActions.getTableDatas(tableFilter, resetTable));
    }

    exportCSV() {
        const { tableTotal, dispatch, formFilterTreemap } = this.props;
    
        let segment = (formFilterTreemap.values === undefined || formFilterTreemap.values.segment === undefined) ? "" : formFilterTreemap.values.segment
        // console.log("segment : " ,segment);
        if (!segment){
            customAlert(dispatch, 'error', "Please select segment")
        } else if (tableTotal > 10000) {
            customAlert(dispatch, 'error', "Can not export data more than 10,000 rows")
        } else {
            loader(dispatch, true)
            treemapServices.exportRFM(segment)
            .then(response => {
              loader(dispatch, false)
            })
            .catch(e => {
              customAlert(dispatch, 'error', "api_messages.SOMETHING_WENT_WRONG")
              loader(dispatch, false)
            });
        }
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch(change(FORM_NAME, 'mobileNo', ''));
        dispatch(change(FORM_NAME, 'code', ''));
        dispatch(change(FORM_NAME, 'display', ''));
        dispatch(change(FORM_NAME, 'periodId', ''));
        dispatch(change(FORM_NAME, 'rewardType', ''));
        dispatch(change(FORM_NAME, 'rewardName', ''));
        dispatch(change(FORM_NAME, 'winnerType', ''));

        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "id",
            sortDir: "asc",
            keyword: "",
            search: {}
        }
        const resetTable = true
        dispatch(treemapActions.getTableDatas(tableFilter, resetTable));
    }

    render() {
        const { classes, formFilterTreemap, handleSubmit, winnerList, merchantSetting, visiblePersonalData, treemapList, tableFilter } = this.props;

        const {disableAtRisk, disableAsUsual, disableAboutTo, disableHighValue, disableTrueLove } = this.state

        let treemapAllList = [];
        treemapAllList.push(
            { value: "At Risk", label: "At Risk", id: "At Risk" },
            { value: "As Usual", label: "As Usual", id: "As Usual" },
            { value: "About to", label: "About to", id: "About to" },
            { value: "High Value", label: "High Value", id: "High Value" },
            { value: "True Love", label: "True Love", id: "True Love" },
        )

        // console.log("treemapAllList : ",treemapAllList);
// <div style={style.borderDashed}>
        return (
            
            <div style={style.borderDashed}>
                
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>

                    <GridContainer className={classes.reportHeaderContainer}>
                        <GridItem xs={6} sm={2}>
                            {/* <h6><b>Redemption Date</b></h6> */}
                        </GridItem>
                    </GridContainer>
                    {/* <br/> */}

                    <GridContainer className={classes.reportHeaderContainer}>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                            <Field
                                name="segment"
                                component={CustomSelect}
                                type="text"
                                labelText="TreeMap"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                optionsList={treemapAllList}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={9} md={9} lg={9}>
                            { disableAtRisk === true &&
                                <div style={style.borderDescription}>
                                    <h6>Clients who are rarely using compare to before.</h6>
                                </div>
                            }
                            { disableAsUsual === true &&
                                <div style={style.borderDescription}>
                                    <h6>Clients who behave as usual.</h6>
                                </div>
                            }
                            { disableAboutTo === true &&
                                <div style={style.borderDescription}>
                                    <h6>Clients who are interested but not yet spent.</h6>
                                </div>
                            }
                            { disableHighValue === true &&
                                <div style={style.borderDescription}>
                                    <h6>Top spender.</h6>
                                </div>   
                            }
                            { disableTrueLove === true &&
                                <div style={style.borderDescription}>
                                    <h6>Top spender who is also follow up on our activities.</h6>
                                </div>
                            }
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.reportHeaderContainer}>
                        <GridItem xs={12} sm={3}>
                            <Button
                                type="submit"
                                style={{ marginRight: "10px", marginTop: "25px" }}
                                size="sm"
                                color="primary"
                                id='btn_submit'>
                                Submit
                            </Button>
                            <Button color="white" className={classes.btnClearFilter} onClick={this.clearFilter} size="sm" id='btn_cancel'>
                                Clear
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.reportHeaderContainer}>
                        <GridItem xs={12} sm={3}>
                            <Button
                                style={{
                                marginTop: "25px",
                                backgroundColor: "#84B0F3",
                                float: "left"
                                }}
                                size="sm"
                                id='btn_export_rfm'
                                color="primary"
                                 onClick={this.exportCSV.bind(this)}
                                >
                                export
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>

                <GridContainer className={classes.mt20}>
                    <GridItem xs={12}>
                        <TableTreeMapList showAction={false} showSearch={false} />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { sweetAlert } = state.sweetAlert;
    const { permission, superAdmin, merchantSetting, visiblePersonalData } = state.authentication;
    const filterValues = getFormValues(FORM_NAME)(state)
    const { formFilterTreemap } = state.form;
    const { tableDatas, tableTotal, submitForm, winnerList, treemapList} = state.treemap;
    return {
        sweetAlert, permission, superAdmin, filterValues, tableTotal, tableDatas, formFilterTreemap, submitForm, winnerList, merchantSetting, visiblePersonalData, treemapList
    };
}
const connectFilterWinnerForm = connect(mapStateToProps)(TreeMap);

export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    onChange: (values, dispatch, props, previousValues) => {
        if (values !== previousValues) {
            dispatch({ type: treemapConstants.TABLE_RESET });
        }
    }
})(withStyles(pageStyle)(connectFilterWinnerForm))
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { crmValidator, alert as customAlert } from 'helpers';
import lodash from 'lodash';

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import FastForwardIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import { masterAdminActions } from 'actions';
import { masterAdminConstants } from '../../constants';
import i18n from 'i18n';

const FORM_NAME = "FORM_MASTER_ADMIN";
const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0",
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px",
   },
   actionDiv: {
      display: "inline-flex",
   },
   containerDiv: {
      padding: "15px",
   },
   cardTitleDiv: {
      marginLeft: "0px",
      marginBottom: "0px",
      marginTop: "0px",
      fontSize: "17px",
   },
   formRankingGrid: {
      paddingLeft: "40px",
      paddingTop: "40px",
      paddingRight: "40px",
      justifyContent: "center",
   },
   paperConstainer: {
      height: "350px",
   },
   leftPaper: {
      paddingLeft: "40px",
      paddingRight: "30px",
      paddingTop: "20px",
      height: "60px",
   },
   boxDiv: {
      paddingLeft: "30px",
      paddingRight: "30px",
      width: "400px"
   },
   selectDiv: {
      paddingLeft: "28px",
      paddingRight: "28px",
   },
   listDiv: {
      border: "solid 1px #ccc",
      padding: "0px 0px",
      height: "230px",
   },
   list: {
      maxHeight: "100%",
      overflow: "auto",
   },
   btnLevel: {
      width: "100%",
      backgroundColor: "rgba(177,177,177)",
      marginTop: "-2px",
      marginLeft: "0px",
      borderRadius: "10",
      paddingTop: "2px",
      paddingBottom: "2px",
   },
   rightContainer: {
      height: "350px",
      paddingLeft: "5px",
   },
   droppable: {
      paddingLeft: "28px",
      paddingRight: "28px",
      fontSize: "large",
      borderRadius: "20px",
   },
   arrowDiv: {
      marginLeft: "40px",
      marginTop: "150px",
   },
   topArrow: {
      width: "0px",
      height: "0px",
      borderBottom: "10px solid black",
      borderLeft: "8px solid transparent",
      borderRight: "8px solid transparent",
      borderTop: "none",
   },
   lineArrow: {
      marginLeft: "7px",
      width: "2px",
      height: "150px",
      backgroundColor: "black",
   },
   bottomArrow: {
      width: "0px",
      height: "0px",
      borderTop: "10px solid black",
      borderLeft: "8px solid transparent",
      borderRight: "8px solid transparent",
      borderBottom: "none",
   },
};
const form_validate = {
   "name": {
      rules: {
         required: true,
         isAllLetter: true
      },
      messages: {
         required: "warning_messages.name_is_required",
         isAllLetter: 'ชื่อไม่สามารถมีตัวเลขหรือสัญลักษณ์พิเศษได้ กรุณาตรวจสอบอีกครั้ง'
      },
      alert: "warning_messages.name_is_required"
   },
   "email": {
      rules: {
         required: true,
         isEmail: true
      },
      messages: {
         required: "warning_messages.email_is_required",
         isEmail: "warning_messages.email_is_invalid",
      },
      alert: "warning_messages.email_is_required",
   },
   "phoneNumber": {
      rules: {
         // required: true,
         isMobilePhone: true,
         number: true,
         minLength: 10,
         maxLength: 10,
      },
      messages: {
         // required: 'กรุณากรอก',
         isMobilePhone: "warning_messages.mobile_number_is_invalid",
         number: "warning_messages.mobile_number_is_invalid",
         minLength: "warning_messages.mobile_number_is_invalid",
         maxLength: "warning_messages.mobile_number_is_invalid",
      },
      alert: "warning_messages.mobile_number_is_invalid",
   },
   "password": {
      rules: {
         required: true,
         minLength: 8,
         passwordStrength: true
      },
      messages: {
         required: "warning_messages.password_is_required",
         minLength: 'รหัสผ่านต้องมีมากกว่า 8 ตัวขึ้นไป',
         passwordStrength: 'รหัสผ่านต้องประกอบด้วยตัวพิมพ์ใหญ่ (A-Z), ตัวพิมพ์เล็ก (a-z), ตัวเลข (0-9) และตัวอักขระพิเศษ (!,@,#,$,%,^,&,*)'
      },
      alert: "warning_messages.password_is_required",
   },
   "passwordConfirmation": {
      rules: {
         required: true,
         equalTo: 'password'
      },
      messages: {
         required: "warning_messages.password_confirmation_is_required",
         equalTo: 'กรุณากรอกรหัสผ่านให้ตรงกัน'
      },
      alert: "warning_messages.password_confirmation_is_required",
   },
   "status": {
      rules: {
         required: true,
      },
      messages: {
         required: "Please select Status"
      }
   },
   "defaultMerchantId": {
      rules: {
         required: true,
      },
      messages: {
         required: "Please select Merchant"
      },
      alert: "Please select Merchant",
   }
}

const validate = (values) => {
   const errors = crmValidator.validateByRulesList(form_validate, values);
   return errors
}

class AdminForm extends React.Component {
   constructor(props) {
      super(props);

   }

   componentWillMount() {
      const { dispatch } = this.props;
      dispatch(masterAdminActions.getListMerchants());
   }

   getMerchantLists = () => {
      const { merchantLists, FORM_MASTER_ADMIN } = this.props;

      let keyword = lodash.get(FORM_MASTER_ADMIN, 'values.searchLeftBox')
      let accessMerchantLists = lodash.get(FORM_MASTER_ADMIN, 'values.accessMerchantLists')
      let renderMerchantLists = lodash.filter(merchantLists, function (obj) {
         return lodash.indexOf(accessMerchantLists, obj._id) === -1;
      });

      if (keyword) {
         renderMerchantLists = lodash.filter(renderMerchantLists, function (obj) {
            keyword = keyword.toLowerCase();
            let levelName = obj.nameEN.toLowerCase();
            return RegExp(keyword).test(levelName)
         });
      }

      return renderMerchantLists
   }

   getAccessMerchantLists = () => {
      const { merchantLists, FORM_MASTER_ADMIN } = this.props;

      let keyword = lodash.get(FORM_MASTER_ADMIN, 'values.searchRightBox')
      let accessMerchantLists = lodash.get(FORM_MASTER_ADMIN, 'values.accessMerchantLists')
      let renderMerchantLists = lodash.filter(merchantLists, function (obj) {
         return lodash.indexOf(accessMerchantLists, obj._id) !== -1;
      });

      if (keyword) {
         renderMerchantLists = lodash.filter(renderMerchantLists, function (obj) {
            keyword = keyword.toLowerCase();
            let levelName = obj.nameEN.toLowerCase();
            return RegExp(keyword).test(levelName)
         });
      }

      return renderMerchantLists
   }

   getSelectMerchantLists = () => {
      const { merchantLists, merchant, FORM_MASTER_ADMIN } = this.props;

      const accessMerchantLists = lodash.get(FORM_MASTER_ADMIN, 'values.accessMerchantLists')
      const accessMerchantAdmin = lodash.get(FORM_MASTER_ADMIN, 'values.accessMerchantAdmin')

      let selectMerchantLists = lodash.filter(merchantLists, function (obj) {
         return lodash.indexOf(accessMerchantLists, obj._id) !== -1;
      });
      if(accessMerchantAdmin){
         selectMerchantLists.push({
            _id: merchant._id,
            nameEN: merchant.nameEN,
            nameTH: merchant.nameTH
         })
      }

      selectMerchantLists = selectMerchantLists.map( tmp => {
         return { value: tmp._id, label: tmp.nameEN }
      })

      return selectMerchantLists
   }

   onSearchLeftList = (keyword) => {
      this.getMerchantLists()
      return keyword;
   };

   onSearchRightList = (keyword) => {
      this.getAccessMerchantLists()
      return keyword;
   };

   renderMerchantLeftList = () => {
      const mockData = this.getMerchantLists()
      let leftListComponent = mockData.map((tmp, index) => {
         return <div key={index} style={{ textAlign: "center" }}>
            <Button
               variant="contained"
               bcolor="primary"
               size="lg"
               onClick={() => {
                  this.selectLevel(tmp._id);
               }}
               style={{
                  marginBottom: "5px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRadius: "20px",
                  height: "28px",
                  width: "300px",
               }}
            >
               {this.setName(tmp.nameEN)}
            </Button>
         </div>
      })

      return leftListComponent;
   };

   renderMerchantRightList = () => {
      const result = this.getAccessMerchantLists()

      let rightListComponent = result.map((tmp, index) => {
         return <div key={index} style={{ textAlign: "center" }}>
            <Button
               variant="contained"
               bcolor="primary"
               size="lg"
               value={tmp._id}
               draggable
               onClick={() => {
                  this.removeLevel(tmp._id);
               }}
               style={{
                  marginBottom: "5px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  borderRadius: "20px",
                  height: "28px",
                  width: "300px",
               }}
            >
               {this.setName(tmp.nameEN)}
            </Button>
         </div>
      })

      return rightListComponent;
   };

   selectLevel = (id) => {
      const { FORM_MASTER_ADMIN, dispatch } = this.props;

      let accessMerchantLists = lodash.get(FORM_MASTER_ADMIN, 'values.accessMerchantLists') || []
      accessMerchantLists.push(id)

      dispatch(change(FORM_NAME, "accessMerchantLists", accessMerchantLists))
   };

   removeLevel = (id) => {
      const { FORM_MASTER_ADMIN, dispatch } = this.props;

      let accessMerchantLists = lodash.get(FORM_MASTER_ADMIN, 'values.accessMerchantLists')
      accessMerchantLists = lodash.filter(accessMerchantLists, function (val) {
         return val !== id
      })

      const defaultMerchantId = lodash.get(FORM_MASTER_ADMIN, 'values.defaultMerchantId')

      if(defaultMerchantId === id){
         dispatch(change(FORM_NAME, "defaultMerchantId", ""))
      }

      dispatch(change(FORM_NAME, "accessMerchantLists", accessMerchantLists))
   };

   selectAll = (e) => {
      const { merchantLists, dispatch } = this.props;

      let accessMerchantLists = merchantLists.map( tmp => tmp._id ) || []

      dispatch(change(FORM_NAME, "accessMerchantLists", accessMerchantLists))
   };

   removeAll = (e) => {
      const { FORM_MASTER_ADMIN, merchant, dispatch } = this.props;

      const merchantId = lodash.get(merchant, '_id')
      const defaultMerchantId = lodash.get(FORM_MASTER_ADMIN, 'values.defaultMerchantId')
      if(defaultMerchantId !== merchantId){
         dispatch(change(FORM_NAME, "defaultMerchantId", ""))
      }
      dispatch(change(FORM_NAME, "accessMerchantLists", []))
   };

   handleSubmit = (values) => {
      const { dispatch, user, formMode } = this.props;
      const accessMerchantAdmin = values.accessMerchantAdmin
      const accessMerchantBackOffice = values.accessMerchantBackOffice

      if(!accessMerchantAdmin && !accessMerchantBackOffice){
         customAlert(dispatch, 'error', 'Please select Access')
         return false
      }
      if (formMode === "add") {
         let reqParamsAdd = {
            "name": values.name,
            "phoneNumber": values.phoneNumber,
            "email": values.email,
            "status": values.status,
            "accessMerchantAdmin": values.accessMerchantAdmin,
            "accessMerchantBackOffice": values.accessMerchantBackOffice,
            "accessMerchantLists": values.accessMerchantLists,
            "defaultMerchantId": values.defaultMerchantId,
            "roleIds": lodash.get(user, 'roleIds'),
            "password": values.password,
            "passwordConfirmation": values.passwordConfirmation
         }

         dispatch(masterAdminActions.addNewAdmin(reqParamsAdd));
      } else if (formMode === 'edit') {
         let reqParamsEdit = {
            "_id": values._id,
            "name": values.name,
            "phoneNumber": values.phoneNumber,
            "email": values.email,
            "status": values.status,
            "accessMerchantAdmin": values.accessMerchantAdmin,
            "accessMerchantBackOffice": values.accessMerchantBackOffice,
            "accessMerchantLists": values.accessMerchantLists,
            "defaultMerchantId": values.defaultMerchantId
         }

         dispatch(masterAdminActions.editAdmin(reqParamsEdit, formMode));
      }
   }

   setName(name) {
      let set = name;
      if (name.length >= 35) {
         let res = set.substring(1, 16);
         res += res + "...";
         set = res;
      }
      return set;
   }

   render() {
      const { handleSubmit, classes, formOpen, formMode, dispatch, FORM_MASTER_ADMIN, merchant } = this.props;
      let { readOnly } = this.props
      if (formMode === `add`) {
         readOnly = false
      } else if (formMode === `edit` && !readOnly) {
         readOnly = false
      } else {
         readOnly = true
      }

      return (
         <Dialog
            classes={{
               root: classes.center,
               paper: classes.modal
            }}
            open={formOpen}
            keepMounted
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            fullWidth={true}
            maxWidth="md"
            scroll="body"
         >
            <DialogTitle
               id="add-admin-title"
               disableTypography
               className={classes.modalHeader}>
               <h4 className={classes.modalTitle}><b>{formMode === 'add' ? i18n.t("admin.add_") : (readOnly === true ? i18n.t("admin.info_") : i18n.t("admin.edit_"))}</b></h4>
            </DialogTitle>

            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
               <DialogContent
                  id="admin-form-dialog"
                  className={classes.modalBody}
                  style={{ minHeight: "264px" }}
               >
                  <GridContainer>
                     <GridItem xs={6} sm={6} md={4}>
                        <Field
                           id="name-input"
                           name="name"
                           component={CustomInput}
                           type="text"
                           labelText={i18n.t("label.name")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                     <GridItem xs={6} sm={6} md={4}>
                        <Field
                           id="phone-number-input"
                           name="phoneNumber"
                           component={CustomInput}
                           type="phoneNumber"
                           labelText={i18n.t("label.mobile_no")}
                           maxLength={10}
                           formControlProps={{
                              fullWidth: true
                           }}
                           normalize={(value) => {
                              return crmValidator.handleKeyPress(value, form_validate.phoneNumber)
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={12} md={4}>
                        <Field
                           id="email-input"
                           name="email"
                           component={CustomInput}
                           type="email"
                           labelText={i18n.t("label.email")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>
                  {formMode === 'add' &&
                     <GridContainer>
                        <GridItem xs={12} sm={6} md={4}>
                           <Field
                              id="password-input"
                              name="password"
                              component={CustomInput}
                              type="password"
                              labelText={i18n.t("label.password")}
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                           <Field
                              id="password-confirmation-input"
                              name="passwordConfirmation"
                              component={CustomInput}
                              type="password"
                              labelText={i18n.t("label.confirm_password")}
                              formControlProps={{
                                 fullWidth: true
                              }}
                           />
                        </GridItem>
                     </GridContainer>
                  }
                  <GridContainer>
                     <GridItem xs={6} sm={6} md={4}>
                        <Field
                           id="status-input"
                           name="status"
                           component={CustomSelect}
                           type="text"
                           labelText={i18n.t("label.status")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           optionsList={[
                              {
                                 value: "Active",
                                 label: "Active"
                              },
                              {
                                 value: "Inactive",
                                 label: "Inactive"
                              }
                           ]}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={12} style={{ color: '#3c4858' }}>
                        <b>Merchant Management</b>
                     </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={12}>
                        <Field
                           id='access-egg-checkbox'
                           name="accessMerchantAdmin"
                           labelText="Access Egg Admin Back office"
                           component={CustomCheckbox}
                           onChange={(raw, val) => { 
                              if(!val){
                                 if (lodash.get(FORM_MASTER_ADMIN, 'values.defaultMerchantId') === lodash.get(merchant, '_id')) {
                                    dispatch(change(FORM_NAME, "defaultMerchantId", ""))
                                 }
                              } else {
                                 if (!lodash.get(FORM_MASTER_ADMIN, 'values.defaultMerchantId')) {
                                    dispatch(change(FORM_NAME, "defaultMerchantId", lodash.get(merchant, '_id')))
                                 }
                              }
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={12} md={12}>
                        <Field
                           id='access-merchant-checkbox'
                           name="accessMerchantBackOffice"
                           labelText="Access Merchant Admin Back office"
                           component={CustomCheckbox}
                           onChange={(val) => {
                              if (lodash.get(FORM_MASTER_ADMIN, 'values.defaultMerchantId')) {
                                 dispatch(change(FORM_NAME, "accessMerchantLists", []))
                                 dispatch(change(FORM_NAME, "defaultMerchantId", lodash.get(merchant, '_id')))
                              }
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>
                  {lodash.get(FORM_MASTER_ADMIN.values, 'accessMerchantBackOffice') && <>
                     <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                           <p>Choose merchant to the right box for having access to selected merchant's back office</p>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                           <GridContainer style={style.paperConstainer} justify="flex-end">
                              <Paper>
                                 <div style={style.boxDiv}>
                                    <Field
                                       id="search-left-box"
                                       name="searchLeftBox"
                                       component={CustomInput}
                                       placeholder="Search"
                                       type="text"
                                       formControlProps={{
                                          fullWidth: true,
                                       }}
                                       autoComplete="off"
                                       startAdornment={
                                          <InputAdornment position="start">
                                             <Search style={{ color: "#ccc" }} />
                                          </InputAdornment>
                                       }
                                    />
                                 </div>
                                 <div className="select" style={style.selectDiv}>
                                    <div style={style.listDiv}>
                                       <List style={style.list}>
                                          {this.renderMerchantLeftList()}
                                       </List>
                                    </div>

                                    <Button
                                       id="select-all-btn"
                                       onClick={(value) => {
                                          this.selectAll(value);
                                       }}
                                       style={style.btnLevel}
                                    >
                                       <FastForwardIcon />
                                    </Button>
                                 </div>
                              </Paper>
                           </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={6} md={6}>
                           <GridContainer style={style.rightContainer}>
                              <Paper>
                                 <div style={style.boxDiv}>
                                    <Field
                                       id="search-right-box"
                                       name="searchRightBox"
                                       component={CustomInput}
                                       placeholder="Search"
                                       type="text"
                                       formControlProps={{
                                          fullWidth: true,
                                       }}
                                       autoComplete="off"
                                       startAdornment={
                                          <InputAdornment position="start">
                                             <Search style={{ color: "#ccc" }} />
                                          </InputAdornment>
                                       }
                                    />
                                 </div>
                                 <div>
                                    <div
                                       className="droppable"
                                       style={style.droppable}
                                    >
                                       <div style={style.listDiv}>
                                          <List style={style.list}>
                                             {this.renderMerchantRightList()}
                                          </List>
                                       </div>
                                       <Button
                                          id="remove-all-btn"
                                          onClick={(value) => {
                                             this.removeAll(value);
                                          }}
                                          style={style.btnLevel}
                                       >
                                          <FastRewindIcon />
                                       </Button>
                                    </div>
                                 </div>
                              </Paper>
                           </GridContainer>
                        </GridItem>
                     </GridContainer>

                     <GridContainer style={{ paddingRight: '60px' }}>
                        <GridItem xs={6} sm={6} md={6}></GridItem>
                        <GridItem xs={3} sm={3} md={3} style={{ textAlign: "right", margin: "auto" }}>
                           Default Merchant
                           </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                           <Field
                              id="default-merchant-select"
                              name="defaultMerchantId"
                              component={CustomSelect}
                              type="text"
                              labelText={i18n.t("Select Merchant")}
                              formControlProps={{
                                 fullWidth: true
                              }}
                              optionsList={this.getSelectMerchantLists()}
                              disabled={readOnly}
                           />
                        </GridItem>
                     </GridContainer>
                  </>
                  }
               </DialogContent>
               <DialogActions
                  className={classes.modalFooter}>
                  {!readOnly &&
                     <Button
                        id="submit-btn"
                        style={{ marginRight: "10px" }}
                        color="primary"
                        type="submit">
                        {i18n.t('submit')}
                     </Button>
                  }
                  <Button
                     id="cancel-btn"
                     name="closeForm"
                     onClick={() => {
                        // this.handleClearRank()
                        dispatch({ type: masterAdminConstants.CLOSE_FORM_ADMIN })
                     }}
                     color="white">
                     {i18n.t('cancel')}
                  </Button>
               </DialogActions>
            </form>
         </Dialog>
      )
   }
}

function mapStateToProps(state) {
   const { formOpen, formMode, reload, merchantLists, merchantAccessLists, formInitialValue } = state.masterAdmin;
   const { merchant, user } = state.authentication;
   const { FORM_MASTER_ADMIN } = state.form;
   return {
      formOpen, formMode, reload, FORM_MASTER_ADMIN, merchantLists, merchantAccessLists, merchant, user, formInitialValue
   };
}

const connectAdminForm = connect(mapStateToProps)(AdminForm);

export default reduxForm({
   form: FORM_NAME,
   validate: validate,
   enableReinitialize: true,
   onSubmitFail: (errors) => { 
      crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate, errors.defaultMerchantId);
   }
})(withStyles(modalStyle)(connectAdminForm))
import React from "react";
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { crmValidator, plugins, normalize } from 'helpers';
// import lodash from 'lodash';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import lodash from 'lodash'
import IframeResizer from 'iframe-resizer-react'

import { luckydrawDashboardActions } from 'actions';

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const FORM_NAME = 'luckydraw_dashboard'
const moduleName = 'luckyDraw[luckydrawDashboard]'

const pageStyle = { ...style, ...sweetAlertStyle };
class LuckydrawDashboard extends React.Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(luckydrawDashboardActions.getluckydrawDashboardSetting())
    }

    render() {

        const { classes, setting, handleSubmit } = this.props

        console.log("setting : ",setting);

        let url = ""
        let width = "100%"
        let height = "1000px"
        
        url = setting.url;
        

        if(setting.width){
            width = setting.width;
        }

        if(setting.height){
            height = setting.height;
        }
       

        return (
            <div>
                <Card>
                    <CardBody>
                        <GridContainer >
                            <GridItem xs={12}>
                                <div>
                                    {/* <IframeResizer
                                        heightCalculationMethod="bodyScroll"
                                        resizeFrom="child"
                                        autoResize={true}
                                        scrolling={true}
                                        src="https://eggdigital.zwiz.ai"
                                        style={{ width: '100%', border: 'none', minHeight: 'calc(100vh - 100px)' }}
                                    /> */}

                                    <IframeResizer
                                        heightCalculationMethod="bodyScroll"
                                        resizeFrom="child"
                                        autoResize={true}
                                        scrolling={true}
                                        src={url}
                                        style={{ width: width, border: 0, height: height }}
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }
}


// export default withStyles(pageStyle)(LuckydrawDashboard)
function mapStateToProps(state) {
    // console.log("luckydrawDashboard state : ",state);
    const { setting } = state.luckydrawDashboard
    return { setting }
}

const connectedComponent = connect(mapStateToProps)(LuckydrawDashboard);

let LuckydrawDashboardForm = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors);
    }
})(withStyles(pageStyle)(connectedComponent))

const selector = formValueSelector(FORM_NAME)

LuckydrawDashboardForm = connect(state => {
    const dashboard = selector(state, 'dashboard')
    console.log("luckydrawDashboard 1 : ",dashboard);
    return {
        initialValues: lodash.get(state, 'luckydrawDashboard.setting'),
        dashboard
    }
})(LuckydrawDashboardForm)

export default LuckydrawDashboardForm
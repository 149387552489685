var initialState = {
    openExample: false,
    setting: {
        condition: null
    }
}

export function point(state = initialState, action) {
    switch (action.type) {
        case 'GET_POINT_EXPIRATION_SETTING_SUCCESS':
            return {
                ...state,
                setting: action.data
            }
        case 'OPEN_EXAMPLE_POINT_EXPIRATION':
            return {
                ...state,
                openExample: true
            }
        case 'CLOSE_EXAMPLE_POINT_EXPIRATION':
            return {
                ...state,
                openExample: false
            }
        default:
            return state;
    }
}
import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import lodash from 'lodash';

export const pointAdjustmentServices = {
    getTablePointAdjustmentDatas,
    getUserByPhoneNumber,
    createPointAdjustment,
    rejectPointAdjustment,
    approvePointAdjustment
};

function getTablePointAdjustmentDatas(params) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/point-adjustment?${params}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getUserByPhoneNumber(phoneNumber) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/list/user/phoneNumber/${phoneNumber}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function createPointAdjustment(reqBody) {
    let tmp = axios.post(configConstants.API_BACKEND_CRM + '/point-adjustment', reqBody, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: lodash.get(response, 'data.data'),
                message: response.data.message
            };
        }
    });
    return tmp;
}

function rejectPointAdjustment(params) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + `/point-adjustment/${params._id}/reject`, null, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function approvePointAdjustment(params) {
    var tmp = axios.post(configConstants.API_BACKEND_CRM + `/point-adjustment/${params._id}/approve`, null, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

import React from 'react';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";
import TagsMultiSelect from "components/CustomSelect/TagsMultiSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';


import { earnrulesConstants } from '../../constants';
import { earnRulesAction, memberActions } from 'actions';
import ShopSelections from './ShopSelections.jsx';
import ReactAsyncSelect from 'react-select/async'
import * as FormValidator from '../EarnRules/validate/FormValidator';
import ProductAsyncSelection from '../EarnRules/component/ProductAsyncSelection'

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
    },
    tableHead: {
        backgroundColor: "#eee",
        position: "sticky",
        top: 0,
        padding: "0px 10px",
        zIndex: 96,
        textAlign: "center",
    },
    tableCell: {
        padding: "0px 10px"
    },
    tableCellLoader: {
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    tableHover: {
        transitionDuration: "0.3s",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#efefef"
        }
    },
    tableSelectProduct: {
        //   maxHeight:"300px",
        //   overflow: "auto",
        marginBottom: "10px"
    },
    actionButton: {
        margin: "0 0 0 5px",
        padding: "5px",
        "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
            marginRight: "0px"
        }
    },
    noPaddingTop: {
        "& > div": {
            paddingTop: "0px"
        }
    },
    textUpper: {
        textTransform: 'uppercase'
    },
    haveNoData: {
        color: "#777",
        fontStyle: "italic",
        textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    tableError: {
        border: "solid 2px red"
    }
});

const FORM_NAME = 'FORM_EARNRULES';

class EarnRuleTypeProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countJoinedProduct: 2,
            cellKey: '',
            validateSingleProduct: false,
            Z_Index: []

        };
        this.addTableHeadJoinedProduct = this.addTableHeadJoinedProduct.bind(this);
        this.deleteTableHeadJoinedProduct = this.deleteTableHeadJoinedProduct.bind(this);


    }

    componentDidMount() {
        const { dispatch, formMode, joinedProductRows, singleProductList } = this.props;
        this.getProductList();
        dispatch(memberActions.getActiveMemberLevelList())
        if (formMode === "add") {
            dispatch({ type: earnrulesConstants.ON_EARNRULE_TYPE_CHANGE_TO_PRODUCT })
        }
        if (formMode === "edit") {
            let longestRow = 0;
            for (let i = 0; i < joinedProductRows.length; i++) {
                if (joinedProductRows[i].productWithQty.length > longestRow) {
                    longestRow = joinedProductRows[i].productWithQty.length;
                }
            }
            this.setState({
                countJoinedProduct: longestRow
            });
            dispatch(change(FORM_NAME, 'joinedProductList', lodash.size(joinedProductRows)))
            dispatch(change(FORM_NAME, 'singleProductList', lodash.size(singleProductList)))
        }
    }

    getProductList(keyword) {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"
        if (keyword) {
            reqParams += `&keyword=${keyword}`
        }
        dispatch(earnRulesAction.getListsProduct(reqParams))
    }

    setInitialNameValue = (value) => {
        if (value) {
            return { label: lodash.get(value, 'skuName'), value: lodash.get(value, 'skuId') }
        } else {
            return { label: "", value: "" }
        }
    }

    renderAddSingleProduct() {
        const { singleProductList, classes, readOnly, allProductList, FORM_EARNRULES } = this.props
        let productList = [
            { value: undefined, label: '' }
        ]
        lodash.each(allProductList, function (currentObject) {
            productList.push({
                value: currentObject._id,
                label: currentObject.name + " (" + currentObject.skuId + ")",
            })
        })



        let template = singleProductList.map((n, index) => {
            const validate_field = {
                "productPoint": {
                    rules: {
                        number: true,
                    }
                },
                "numberOnly": {
                    rules: {
                        required: true,
                        number: true,
                        noSpecialCharacters: true,
                    }
                },
            }

            const singleProductListWithName = lodash.isUndefined(FORM_EARNRULES, "values.product.singleProduct.productList") ? "" : lodash.get(FORM_EARNRULES, "values.product.singleProduct.productList")
            return (
                <TableRow key={n._id}>
                    <TableCell className={classes.tableCell} style={{ width: '180px' }}>
                        <Field
                            id={`single-product-name-sku-select-${n._id}`}
                            name={`singleProductNameSkuSelect[${n._id}]`}
                            component={ProductAsyncSelection}
                            customInitial={this.setInitialNameValue(lodash.get(singleProductListWithName, `[${index}]`))}
                            formControlProps={{
                                fullWidth: true
                            }}
                            index={index}
                            validate={(lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'single') && index === 0) ? [FormValidator.validateTypeProductNameSkuSelect] : [FormValidator.validateTypeProductNameSkuSelectMore]}
                            disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'single')}
                        />
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ width: '90px' }}>
                        <Field
                            id={`single-product-quantity-input-${n._id}`}
                            name={`singleProductQuantityInput[${n._id}]`}
                            component={CustomInput}
                            type="number"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'single')}
                            validate={(lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'single') && index === 0) ? [FormValidator.validateIsZeroTypeProductQuantity] : [FormValidator.validateIsZeroTypeProductQuantityMore]}
                            normalize={(value) => {
                                return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                            }}
                        />
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ width: '40px', color: '#3c4858' }}>
                        <center><Icon fontSize="large" >arrow_forward</Icon></center>
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ paddingRight: '10px', width: '90px' }}>
                        <Field
                            id={`single-product-point-input-${n._id}`}
                            name={`singleProductPointInput[${n._id}]`}
                            component={CustomInput}
                            type="number"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'single')}
                            validate={(lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'single') && index === 0) ? [FormValidator.validateIsZeroTypeProductPoint] : [FormValidator.validateIsZeroTypeProductPointMore]}
                            normalize={(value) => {
                                return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                            }}
                        />
                    </TableCell>
                </TableRow>
            );
        })
        return template
    }

    addSingleProductItem() {
        const { dispatch, singleProductList, FORM_EARNRULES } = this.props;
        let index = singleProductList.length - 1;

        if (lodash.get(FORM_EARNRULES.values, 'singleProductQuantityInput') && FORM_EARNRULES.values.singleProductQuantityInput[index] !== undefined && FORM_EARNRULES.values.singleProductQuantityInput[index] !== '0'
            && lodash.get(FORM_EARNRULES.values, 'singleProductNameSkuSelect') && FORM_EARNRULES.values.singleProductNameSkuSelect[index] !== undefined && FORM_EARNRULES.values.singleProductNameSkuSelect[index] !== ''
            && lodash.get(FORM_EARNRULES.values, 'singleProductPointInput') && FORM_EARNRULES.values.singleProductPointInput[index] !== undefined && FORM_EARNRULES.values.singleProductPointInput[index] !== '0') {
            dispatch(change(FORM_NAME, 'singleProductList', lodash.size(singleProductList) + 1))
            singleProductList.push({
                _id: lodash.size(singleProductList)
            })
        }
    }

    addJoinedProductItem() {
        const { dispatch, FORM_EARNRULES, joinedProductRows } = this.props;
        let index = joinedProductRows.length - 1;

        if (joinedProductRows[index].productWithQty.length === 2
            && lodash.get(FORM_EARNRULES.values, 'joinedProductQuantityInput') && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_0'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_0'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_1'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_1'] !== '0'
            && lodash.get(FORM_EARNRULES.values, 'joinedProductNameSkuSelect') && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_0'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_0'] !== ''
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_1'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_1'] !== ''
            && lodash.get(FORM_EARNRULES.values, 'joinedProductPointInput') && FORM_EARNRULES.values.joinedProductPointInput[index] !== undefined && FORM_EARNRULES.values.joinedProductPointInput[index] !== '0') {
            dispatch(change(FORM_NAME, 'joinedProductList', lodash.size(joinedProductRows) + 1))
            dispatch({ type: 'EARN_RULE_ADD_JOINED_PRODUCT_ROW' })
            this.setState({
                countJoinedProduct: 0
            })
            // joinedProductList.push({
            //     _id: lodash.size(joinedProductList)
            // })
        }
        else if (joinedProductRows[index].productWithQty.length === 3
            && lodash.get(FORM_EARNRULES.values, 'joinedProductQuantityInput') && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_0'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_0'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_1'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_1'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_2'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_2'] !== '0'
            && lodash.get(FORM_EARNRULES.values, 'joinedProductNameSkuSelect') && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_0'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_0'] !== ''
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_1'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_1'] !== ''
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_2'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_2'] !== ''
            && lodash.get(FORM_EARNRULES.values, 'joinedProductPointInput') && FORM_EARNRULES.values.joinedProductPointInput[index] !== undefined && FORM_EARNRULES.values.joinedProductPointInput[index] !== '0') {
            dispatch(change(FORM_NAME, 'joinedProductList', lodash.size(joinedProductRows) + 1))
            dispatch({ type: 'EARN_RULE_ADD_JOINED_PRODUCT_ROW' })
            this.setState({
                countJoinedProduct: 0
            })
            // joinedProductList.push({
            //     _id: lodash.size(joinedProductList)
            // })
        }
        else if (joinedProductRows[index].productWithQty.length === 4
            && lodash.get(FORM_EARNRULES.values, 'joinedProductQuantityInput') && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_0'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_0'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_1'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_1'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_2'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_2'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_3'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_3'] !== '0'
            && lodash.get(FORM_EARNRULES.values, 'joinedProductNameSkuSelect') && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_0'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_0'] !== ''
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_1'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_1'] !== ''
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_2'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_2'] !== ''
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_3'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_3'] !== ''
            && lodash.get(FORM_EARNRULES.values, 'joinedProductPointInput') && FORM_EARNRULES.values.joinedProductPointInput[index] !== undefined && FORM_EARNRULES.values.joinedProductPointInput[index] !== '0') {
            dispatch(change(FORM_NAME, 'joinedProductList', lodash.size(joinedProductRows) + 1))
            dispatch({ type: 'EARN_RULE_ADD_JOINED_PRODUCT_ROW' })
            this.setState({
                countJoinedProduct: 0
            })
            // joinedProductList.push({
            //     _id: lodash.size(joinedProductList)
            // })
        }
        else if (joinedProductRows[index].productWithQty.length === 5
            && lodash.get(FORM_EARNRULES.values, 'joinedProductQuantityInput') && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_0'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_0'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_1'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_1'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_2'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_2'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_3'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_3'] !== '0'
            && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_4'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_4'] !== '0'
            && lodash.get(FORM_EARNRULES.values, 'joinedProductNameSkuSelect') && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_0'] !== undefined && FORM_EARNRULES.values.joinedProductQuantityInput[index + '_0'] !== '0'
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_1'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_1'] !== ''
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_2'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_2'] !== ''
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_3'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_3'] !== ''
            && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_4'] !== undefined && FORM_EARNRULES.values.joinedProductNameSkuSelect[index + '_4'] !== ''
            && lodash.get(FORM_EARNRULES.values, 'joinedProductPointInput') && FORM_EARNRULES.values.joinedProductPointInput[index] !== undefined && FORM_EARNRULES.values.joinedProductPointInput[index] !== '0') {
            dispatch(change(FORM_NAME, 'joinedProductList', lodash.size(joinedProductRows) + 1))
            dispatch({ type: 'EARN_RULE_ADD_JOINED_PRODUCT_ROW' })
            this.setState({
                countJoinedProduct: 0
            })
            // joinedProductList.push({
            //     _id: lodash.size(joinedProductList)
            // })
        }
    }


    renderjoinedProductField(index, product, joinedProductRows, productList, zIndex) {
        const { FORM_EARNRULES, readOnly } = this.props
        const jointProductListWithName = lodash.get(FORM_EARNRULES, `values.product.joinedProduct.productList[${index}].productWithQty`)
        return (
            <Field
                id={`joined-product-name-sku-select-${index}_${product._id}`}
                name={`joinedProductNameSkuSelect[${index}_${product._id}]`}
                component={ProductAsyncSelection}
                onFocus={(e) => this.test(e, true, index)}
                customInitial={this.setInitialNameValue(lodash.get(jointProductListWithName, product._id))}
                formControlProps={{
                    fullWidth: true
                }}
                optionsList={productList}
                disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'joined')}
                validate={lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'joined') && index === 0 ? [FormValidator.validateTypeProductNameSkuSelect]
                    : lodash.get(joinedProductRows[index].productWithQty, 'length') === 2 ? [FormValidator.validateTypeProductNameSkuSelectJoinedProduct_2]
                        : lodash.get(joinedProductRows[index].productWithQty, 'length') === 3 ? [FormValidator.validateTypeProductNameSkuSelectJoinedProduct_3]
                            : lodash.get(joinedProductRows[index].productWithQty, 'length') === 4 ? [FormValidator.validateTypeProductNameSkuSelectJoinedProduct_4]
                                : [FormValidator.validateTypeProductNameSkuSelectJoinedProduct_5]}
            />
        )
    }

    renderjoinedProductFieldQTY(index, product, joinedProductRows, validate_field) {
        const { FORM_EARNRULES, readOnly } = this.props
        return (
            <Field
                id={`joined-product-quantity-input-${index}_${product._id}`}
                name={`joinedProductQuantityInput[${index}_${product._id}]`}
                component={CustomInput}
                type="number"
                formControlProps={{
                    fullWidth: true
                }}
                disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'joined')}
                validate={lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'joined') && index === 0 ? [FormValidator.validateIsZeroTypeProductQuantity]
                    : lodash.get(joinedProductRows[index].productWithQty, 'length') === 2 ? [FormValidator.validateIsZeroTypeProductQuantityJoinedProduct_2]
                        : lodash.get(joinedProductRows[index].productWithQty, 'length') === 3 ? [FormValidator.validateIsZeroTypeProductQuantityJoinedProduct_3]
                            : lodash.get(joinedProductRows[index].productWithQty, 'length') === 4 ? [FormValidator.validateIsZeroTypeProductQuantityJoinedProduct_4]
                                : [FormValidator.validateIsZeroTypeProductQuantityJoinedProduct_5]}
                normalize={(value) => {
                    return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                }}
            />
        )
    }

    renderAddJoinedProduct() {
        const { FORM_EARNRULES, classes, readOnly, allProductList, joinedProductRows } = this.props
        let productList = [
            { value: undefined, label: '' }
        ]
        let longestRow = 0;

        for (let i = 0; i < joinedProductRows.length; i++) {
            if (joinedProductRows[i].productWithQty.length > longestRow) {
                longestRow = joinedProductRows[i].productWithQty.length;
            }
        }
        lodash.each(allProductList, function (currentObject) {
            productList.push({
                value: currentObject._id,
                label: currentObject.name + " (" + currentObject.skuId + ")",
            })
        })

        let template = joinedProductRows.map((n, index) => {
            const validate_field = {
                "productPoint": {
                    rules: {
                        number: true,
                    }
                },
                "numberOnly": {
                    rules: {
                        required: true,
                        number: true,
                        noSpecialCharacters: true,
                    }
                },
            }
            let productWithQty = lodash.get(n, 'productWithQty')
            if (!productWithQty) {
                productWithQty = []
            }
            //   let currentLength = productWithQty.length;
            const cellWidth = { width: '70px', maxWidth: '150px' }
            let defaultZindex = 95 - (index*5)

            return (
                <TableRow key={index} style={{
                    height: "auto",
                    borderBottom: "solid 5px #efefef"
                }}>
                    <TableCell key={`row_${index}_cell_0`} className={classes.tableCell} style={{ width: '160px', maxWidth: '160px' }}>
                        {(<div id={`row_${index}_cell_0`} style={{ position: "relative", zIndex: `${defaultZindex}` }}> {this.renderjoinedProductField(index, productWithQty[0], joinedProductRows, productList)}</div>)}

                        {lodash.size(productWithQty) >= 3 &&
                            (<div style={{ position: "relative", zIndex: `${defaultZindex-2}` }}>{this.renderjoinedProductField(index, productWithQty[2], joinedProductRows, productList)}</div>)}

                        {lodash.size(productWithQty) >= 5 &&
                            (<div style={{ position: "relative", zIndex: `${defaultZindex-4}` }}>{this.renderjoinedProductField(index, productWithQty[4], joinedProductRows, productList)}</div>)}

                    </TableCell>
                    <TableCell key={`row_${index}_cell_0_1`} className={classes.tableCell} style={cellWidth}>
                        {this.renderjoinedProductFieldQTY(index, productWithQty[0], joinedProductRows, validate_field)}

                        {lodash.size(productWithQty) >= 3 &&
                            (<div>{this.renderjoinedProductFieldQTY(index, productWithQty[2], joinedProductRows, validate_field)}</div>)}

                        {lodash.size(productWithQty) >= 5 &&
                            (<div>{this.renderjoinedProductFieldQTY(index, productWithQty[4], joinedProductRows, validate_field)}</div>)}


                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ width: '20px', color: '#3c4858' }}>
                        <center><Icon fontSize="large" >add</Icon></center>
                    </TableCell>
                    <TableCell key={`row_${index}_cell_1`} className={classes.tableCell} style={{ width: '160px', maxWidth: '160px' }}>
                        {(<div style={{ position: "relative", zIndex: `${defaultZindex}` }}> {this.renderjoinedProductField(index, productWithQty[1], joinedProductRows, productList)} </div>)}

                        {lodash.size(productWithQty) >= 4 &&
                            (<div style={{ position: "relative", zIndex: `${defaultZindex-2}` }} >{this.renderjoinedProductField(index, productWithQty[3], joinedProductRows, productList)}</div>)}
                    </TableCell>
                    <TableCell key={`row_${index}_cell_1_0`} className={classes.tableCell} style={cellWidth}>
                        {this.renderjoinedProductFieldQTY(index, productWithQty[1], joinedProductRows, validate_field)}

                        {lodash.size(productWithQty) >= 4 &&
                            (<div>{this.renderjoinedProductFieldQTY(index, productWithQty[3], joinedProductRows, validate_field)}</div>)}

                    </TableCell>

                    {/* {productWithQty.map((product,productIndex)=>{
                    return(<>
                        <TableCell key={`row_${index}cell_${product._id}`} className={classes.tableCell} style={{width: '125px'}}>
                            <Field
                                id={`joined-product-name-sku-select-${index}_${product._id}`}
                                name={`joinedProductNameSkuSelect[${index}_${product._id}]`}
                                component={CustomSelect}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={productList}
                                disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct,'joined')}
                                validate={lodash.get(FORM_EARNRULES.values.selectedRuleProduct,'joined') && index === 0 ? [FormValidator.validateTypeProductNameSkuSelect] 
                                : lodash.get(joinedProductRows[index].productWithQty,'length') === 2 ? [FormValidator.validateTypeProductNameSkuSelectJoinedProduct_2] 
                                    : lodash.get(joinedProductRows[index].productWithQty,'length') === 3 ? [FormValidator.validateTypeProductNameSkuSelectJoinedProduct_3] 
                                        : lodash.get(joinedProductRows[index].productWithQty,'length') === 4 ? [FormValidator.validateTypeProductNameSkuSelectJoinedProduct_4] 
                                            : [FormValidator.validateTypeProductNameSkuSelectJoinedProduct_5] }
                                />
                        </TableCell>
                        <TableCell className={classes.tableCell} style={{ width: '85px' }}>
                            <Field
                                id={`joined-product-quantity-input-${index}_${product._id}`}
                                name={`joinedProductQuantityInput[${index}_${product._id}]`}
                                component={CustomInput}
                                type="number"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct,'joined')}
                                validate={lodash.get(FORM_EARNRULES.values.selectedRuleProduct,'joined') && index === 0 ? [FormValidator.validateIsZeroTypeProductQuantity] 
                                : lodash.get(joinedProductRows[index].productWithQty,'length') === 2 ? [FormValidator.validateIsZeroTypeProductQuantityJoinedProduct_2] 
                                    : lodash.get(joinedProductRows[index].productWithQty,'length') === 3 ? [FormValidator.validateIsZeroTypeProductQuantityJoinedProduct_3] 
                                        : lodash.get(joinedProductRows[index].productWithQty,'length') === 4 ? [FormValidator.validateIsZeroTypeProductQuantityJoinedProduct_4] 
                                            : [FormValidator.validateIsZeroTypeProductQuantityJoinedProduct_5] }
                                normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                                }}
                            />
                        </TableCell>
                        {(productIndex  < (productWithQty.length - 1)) && 
                        <TableCell className={classes.tableCell} style={{ width: '20px', color:'#3c4858'}}> 
                            <center><Icon fontSize="small" >add</Icon></center>
                        </TableCell>
                        }
                        
                    </>)
                })} */}
                    {/* 

                { longestRow > currentLength ? <>
                    <TableCell className={classes.tableCell} style={{width: '125px'}}></TableCell>
                    <TableCell className={classes.tableCell} style={{width: '85px'}}></TableCell>
                    <TableCell className={classes.tableCell} style={{width: '20px'}}></TableCell> 
                    </>
                : null}
                { longestRow > currentLength+1 ? <>
                    <TableCell className={classes.tableCell} style={{width: '125px'}}></TableCell>
                    <TableCell className={classes.tableCell} style={{width: '85px'}}></TableCell>
                    <TableCell className={classes.tableCell} style={{width: '20px'}}></TableCell> 
                    </>
                : null}
                { longestRow > currentLength+2 ? <>
                    <TableCell className={classes.tableCell} style={{width: '125px'}}></TableCell>
                    <TableCell className={classes.tableCell} style={{width: '85px'}}></TableCell>
                    <TableCell className={classes.tableCell} style={{width: '20px'}}></TableCell> 
                    </>
                : null} */}

                    <TableCell className={classes.tableCell} style={{ width: '20px', color: '#3c4858' }}>
                        <center><Icon fontSize="large" >arrow_forward</Icon></center>
                    </TableCell>
                    <TableCell className={classes.tableCell} style={{ width: '60px' }}>
                        <Field
                            id={`joined-product-point-input-${index}`}
                            name={`joinedProductPointInput[${index}]`}
                            component={CustomInput}
                            type="number"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'joined')}
                            validate={lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'joined') && index === 0 ? [FormValidator.validateIsZeroTypeProductPoint]
                                : lodash.get(joinedProductRows[index].productWithQty, 'length') === 2 ? [FormValidator.validateIsZeroTypeProductPointJoinedProduct_2]
                                    : lodash.get(joinedProductRows[index].productWithQty, 'length') === 3 ? [FormValidator.validateIsZeroTypeProductPointJoinedProduct_3]
                                        : lodash.get(joinedProductRows[index].productWithQty, 'length') === 4 ? [FormValidator.validateIsZeroTypeProductPointJoinedProduct_4]
                                            : [FormValidator.validateIsZeroTypeProductPointJoinedProduct_5]}
                            normalize={(value) => {
                                return crmValidator.handleKeyPress(value, validate_field.numberOnly)
                            }}
                        />
                    </TableCell>

                    <TableCell className={classes.tableCell} style={{ width: '140px', paddingRight: '10px' }}>
                        <center>
                            <Button
                                id={`joined-product-add-product-btn-${index}`}
                                variant="contained"
                                bcolor="primary"
                                size="sm"
                                onClick={() => {
                                    this.addTableHeadJoinedProduct(index)
                                }}
                                style={{ backgroundColor: "#84B0F3", width: '100px', fontSize: '10px' }}
                                disabled={lodash.get(joinedProductRows[index].productWithQty, 'length') >= 5 || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'joined') ? true : false}
                            >
                                Add Product
                    </Button>
                            <Button
                                id={`joined-product-delete-product-btn-${index}`}
                                variant="contained"
                                bcolor="primary"
                                size="sm"
                                onClick={() => {
                                    this.deleteTableHeadJoinedProduct(index)
                                }}
                                style={{ backgroundColor: "#FD7272", width: '100px', fontSize: '10px' }}
                                disabled={lodash.get(joinedProductRows[index].productWithQty, 'length') <= 2 || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'joined') ? true : false}
                            >
                                Delete Product
                    </Button>
                        </center>
                    </TableCell>
                </TableRow>
            );
        })
        return template
    }

    addTableHeadJoinedProduct(index) {
        const { dispatch, joinedProductRows } = this.props;
        let longestRow = 0;

        if (joinedProductRows[index].productWithQty.length + 1 <= 5) {
            let productWithQty = { _id: joinedProductRows[index].productWithQty.length, qty: 0 }
            dispatch({ type: 'EARN_RULE_ADD_JOINED_PRODUCT', index, productWithQty })
            for (let i = 0; i < joinedProductRows.length; i++) {
                if (joinedProductRows[i].productWithQty.length > longestRow) {
                    longestRow = joinedProductRows[i].productWithQty.length;
                }
            }
        }
        if (longestRow <= 5) {
            this.setState({
                countJoinedProduct: longestRow - 1
            });
        }

    }

    deleteTableHeadJoinedProduct(index) {
        const { dispatch, joinedProductRows } = this.props;
        let longestRow = 0;
        if (joinedProductRows[index].productWithQty.length - 1 >= 2) {
            dispatch({ type: 'EARN_RULE_DELETE_JOINED_PRODUCT', index })
            for (let i = 0; i < joinedProductRows.length; i++) {
                if (joinedProductRows[i].productWithQty.length > longestRow) {
                    longestRow = joinedProductRows[i].productWithQty.length;
                }
            }
        }

        if (longestRow >= 2) {
            this.setState({
                countJoinedProduct: this.state.countJoinedProduct - 1
            });
        }
    }

    render() {
        const { classes, readOnly, formMemberLevellist, FORM_EARNRULES } = this.props;
        const validate_field = {
            "numberOnly": {
                rules: {
                    required: true,
                    number: true,
                    noSpecialCharacters: true,
                }
            },
            "date": {
                rules: {
                    required: true
                },
            },
        }

        return (
            <div style={{ border: 'dashed 1px #ccc', padding: '0px 20px', color: 'black' }}>
                <br />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ color: '#3c4858' }}>
                        <b>Product</b>
                    </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                    <GridItem xs={10} sm={10} md={10} style={{ marginLeft: '0px' }}>
                        <Field
                            id='checkbox-single'
                            name="selectedRuleProduct[single]"
                            component={CustomCheckbox}
                            disabled={readOnly}
                            labelText={<span style={{ fontWeight: '300' }}>Single Product</span>}
                        />
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2} style={{ marginLeft: '0px' }}>
                        <Button
                            id='btn-add-single-product'
                            name='addSingleProduct'
                            color="primary"
                            size="sm"
                            onClick={() => {
                                this.addSingleProductItem()
                            }}
                            style={{ float: "right", padding: '2px 5px 0px' }}
                            disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'single')}>
                            Add <br /> Single Product
                        </Button>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} className={classes.tableSelectProduct}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>
                                        Name (SKU ID)
                                    </TableCell>
                                    <TableCell className={classes.tableHead}>
                                        Quantity
                                    </TableCell>
                                    <TableCell className={classes.tableHead}>

                                    </TableCell>
                                    <TableCell className={classes.tableHead} style={{ paddingRight: '10px' }}>
                                        Point
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ border: "solid 1px #eee" }}>
                                {this.renderAddSingleProduct()}
                            </TableBody>
                        </Table>
                    </GridItem>
                </GridContainer>

                <GridContainer style={{ zIndex: "30", position: "relative" }}>
                    <GridItem xs={10} sm={10} md={10} style={{ marginLeft: '0px' }}>
                        <Field
                            id='checkbox-joined'
                            name="selectedRuleProduct[joined]"
                            component={CustomCheckbox}
                            disabled={readOnly}
                            labelText={<span style={{ fontWeight: '300' }}>Joined Product</span>}
                        />
                    </GridItem>
                    <GridItem xs={2} sm={2} md={2} style={{ marginLeft: '0px', marginTop: '20px', marginBottom: '-20px' }}>
                        <Button
                            id='btn-add-joined-product'
                            name='addJoinedProduct'
                            color="primary"
                            size="sm"
                            onClick={() => {
                                this.addJoinedProductItem()
                            }}
                            style={{ float: "right", padding: '2px 5px 0px' }}
                            disabled={readOnly || !lodash.get(FORM_EARNRULES.values.selectedRuleProduct, 'joined')}>
                            Add <br /> Joined Product
                        </Button>
                    </GridItem>

                    <div style={{ marginLeft: '50px', marginTop: '-10px', marginBottom: '10px' }}>
                        (Must buy selected product together as specified quantity to get point)
                </div>

                    <GridItem xs={12} sm={12} md={12} className={classes.tableSelectProduct} >
                        <Table style={{ width: '1190px'  , zIndex: "96"}} >
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>
                                        Name (SKU ID)
                                    </TableCell>
                                    <TableCell className={classes.tableHead}>
                                        Quantity
                                    </TableCell>
                                    <TableCell className={classes.tableHead} style={{ color: '#3c4858' }}>
                                        <Icon fontSize="small" >add</Icon>
                                    </TableCell>
                                    <TableCell className={classes.tableHead}>
                                        Name (SKU ID)
                                    </TableCell>
                                    <TableCell className={classes.tableHead}>
                                        Quantity
                                    </TableCell>

                                    {/* {lodash.get(this.state, 'countJoinedProduct') >= 3 ? 
                                <>
                                    <TableCell className={classes.tableHead} style={{ color:'#3c4858', width: '20px'}}>
                                        <Icon fontSize="small" >add</Icon>
                                    </TableCell> 
                                    <TableCell className={classes.tableHead} style={{width: '125px'}}>
                                        Name (SKU ID)
                                    </TableCell>
                                    <TableCell className={classes.tableHead} style={{width: '125px'}}>
                                        Quantity
                                    </TableCell>
                                </>: null } 

                                {lodash.get(this.state, 'countJoinedProduct') >= 4 ? 
                                <>
                                    <TableCell className={classes.tableHead} style={{ color:'#3c4858', width: '20px'}}>
                                        <Icon fontSize="small" >add</Icon>
                                    </TableCell> 
                                    <TableCell className={classes.tableHead} style={{width: '125px'}}>
                                        Name (SKU ID)
                                    </TableCell>
                                    <TableCell className={classes.tableHead} style={{width: '125px'}}>
                                        Quantity
                                    </TableCell>
                                </>: null } 

                                {lodash.get(this.state, 'countJoinedProduct') >= 5 ? 
                                <>
                                    <TableCell className={classes.tableHead} style={{ color:'#3c4858', width: '20px'}}>
                                        <Icon fontSize="small" >add</Icon>
                                    </TableCell> 
                                    <TableCell className={classes.tableHead} style={{width: '125px'}}>
                                        Name (SKU ID)
                                    </TableCell>
                                    <TableCell className={classes.tableHead} style={{width: '125px'}}>
                                        Quantity
                                    </TableCell>
                                </>: null }  */}

                                    <TableCell className={classes.tableHead} style={{ width: '20px' }}>

                                    </TableCell>
                                    <TableCell className={classes.tableHead} style={{ width: '125px' }}>
                                        Point
                                    </TableCell>
                                    <TableCell className={classes.tableHead} style={{ paddingRight: '10px'/*, width: '60px'*/ }}>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ border: "solid 1px #eee" }}>
                                {this.renderAddJoinedProduct()}
                            </TableBody>
                        </Table>
                    </GridItem>
                </GridContainer>
                <br />
                <div style={{ border: 'dashed 1px #ccc', padding: '0px 20px' }}></div>
                <br />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ color: '#3c4858' }}>
                        <b>Participated Day in Campaign</b>
                    </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Field
                            id='radio-participated-day'
                            name="customRadioParticipatedDay"
                            component={CustomRadio}
                            disabled={readOnly}
                            radioLists={[
                                {
                                    value: "everyday",
                                    label: <span style={{ fontWeight: '300' }}>Every Day</span>,
                                    id: 'radio-participated-day-everyday'
                                },
                                {
                                    value: "specific",
                                    label: <span><span style={{ fontWeight: '300' }}>Selected Day </span><span style={{ fontSize: '17px' }}>▾</span></span>,
                                    id: 'radio-participated-day-specific'
                                }
                            ]}
                        />
                    </GridItem>
                </GridContainer>
                {lodash.get(FORM_EARNRULES.values, 'customRadioParticipatedDay') === 'specific' ? <>
                    <GridContainer style={{ marginLeft: '15px' }}>
                        <GridItem xs={12} sm={12} md={12}>
                            <Field
                                id='radio-selected-day'
                                name="customRadioSelectedDay"
                                component={CustomRadio}
                                disabled={readOnly}
                                radioLists={[
                                    {
                                        value: "dayOfWeek",
                                        label: <span><span style={{ fontWeight: '300' }}>Day of Week </span><span style={{ fontSize: '17px' }}>▾</span></span>,
                                        id: 'radio-selected-day-day-of-week'
                                    }
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                    {lodash.get(FORM_EARNRULES.values, 'customRadioSelectedDay') === 'dayOfWeek' ?
                        <GridContainer style={{ marginLeft: '45px' }}>
                            <Field
                                id='checkbox-monday'
                                name="selectedDate[monday]"
                                component={CustomCheckbox}
                                disabled={readOnly}
                                labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Mon</span>}
                                validate={FormValidator.validateSelectedDate}
                            />
                            <Field
                                id='checkbox-tuesday'
                                name="selectedDate[tuesday]"
                                component={CustomCheckbox}
                                disabled={readOnly}
                                labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Tue</span>}
                                validate={FormValidator.validateSelectedDate}
                            />
                            <Field
                                id='checkbox-wednesday'
                                name="selectedDate[wednesday]"
                                component={CustomCheckbox}
                                disabled={readOnly}
                                labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Wed</span>}
                                validate={FormValidator.validateSelectedDate}
                            />
                            <Field
                                id='checkbox-thursday'
                                name="selectedDate[thursday]"
                                component={CustomCheckbox}
                                disabled={readOnly}
                                labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Thu</span>}
                                validate={FormValidator.validateSelectedDate}
                            />
                            <Field
                                id='checkbox-friday'
                                name="selectedDate[friday]"
                                component={CustomCheckbox}
                                disabled={readOnly}
                                labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Fri</span>}
                                validate={FormValidator.validateSelectedDate}
                            />
                            <Field
                                id='checkbox-saturday'
                                name="selectedDate[saturday]"
                                component={CustomCheckbox}
                                disabled={readOnly}
                                labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Sat</span>}
                                validate={FormValidator.validateSelectedDate}
                            />
                            <Field
                                id='checkbox-sunday'
                                name="selectedDate[sunday]"
                                component={CustomCheckbox}
                                disabled={readOnly}
                                labelText={<span style={{ fontSize: '11px', fontWeight: '300' }}>Sun</span>}
                                validate={FormValidator.validateSelectedDate}
                            />
                        </GridContainer>
                        : null}
                    <GridContainer style={{ marginLeft: '15px' }}>
                        <GridItem xs={12} sm={12} md={12}>
                            <Field
                                id='radio-selected-day'
                                name="customRadioSelectedDay"
                                component={CustomRadio}
                                disabled={readOnly}
                                radioLists={[
                                    {
                                        value: "selectedDate",
                                        label: <span style={{ fontWeight: '300' }}>Selected Date</span>,
                                        id: 'radio-selected-day-selected-date'
                                    },
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{ marginLeft: '15px' }}>
                        <GridItem xs={4} sm={4} md={4} style={{ marginTop: '-60px', marginLeft: '140px', width: '70px' }}>
                            <Field
                                id='tags-multi-select-date'
                                name="date"
                                component={TagsMultiSelect}
                                labelText="Date"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                showtick
                                optionsList={[
                                    { _id: 1, name: 1 }, { _id: 2, name: 2 }, { _id: 3, name: 3 }, { _id: 4, name: 4 }, { _id: 5, name: 5 }, { _id: 6, name: 6 }, { _id: 7, name: 7 }, { _id: 8, name: 8 }, { _id: 9, name: 9 }, { _id: 10, name: 10 },
                                    { _id: 11, name: 11 }, { _id: 12, name: 12 }, { _id: 13, name: 13 }, { _id: 14, name: 14 }, { _id: 15, name: 15 }, { _id: 16, name: 16 }, { _id: 17, name: 17 }, { _id: 18, name: 18 }, { _id: 19, name: 19 }, { _id: 20, name: 20 },
                                    { _id: 21, name: 21 }, { _id: 22, name: 22 }, { _id: 23, name: 23 }, { _id: 24, name: 24 }, { _id: 25, name: 25 }, { _id: 26, name: 26 }, { _id: 27, name: 27 }, { _id: 28, name: 28 }, { _id: 29, name: 29 }, { _id: 30, name: 30 }, { _id: 31, name: 31 }
                                ]}
                                disabled={readOnly || !(lodash.get(FORM_EARNRULES.values, 'customRadioSelectedDay') === 'selectedDate')}
                                normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, validate_field.date)
                                }}
                                validate={lodash.get(FORM_EARNRULES.values, 'customRadioSelectedDay') === 'selectedDate' ? [FormValidator.validateDateSelect] : null}
                            />
                        </GridItem>
                    </GridContainer>

                </>
                    : null}

                <br />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{ color: '#3c4858' }}>
                        <b>Participated Member Level in Campaign</b>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Field
                            id='tags-multi-select-member-level'
                            name="memberLevel"
                            component={TagsMultiSelect}
                            labelText="Member Level"
                            formControlProps={{
                                fullWidth: true
                            }}
                            showtick
                            optionsList={formMemberLevellist}
                            disabled={readOnly}
                        />
                    </GridItem>
                </GridContainer>
                <div style={{ border: "dashed 1px #ccc", padding: "0px 20px" }} />
                <br />

                <ShopSelections readOnly={readOnly} ></ShopSelections>
            </div>

        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode, allProductList, productList, singleProductList, joinedProductList, joinedProductRows, formProductListLoader, formInitialValue, formMemberLevellist } = state.earnrules;
    const { FORM_EARNRULES } = state.form;
    return {
        formOpen, formMode, allProductList, productList, singleProductList, joinedProductList, joinedProductRows, formProductListLoader, FORM_EARNRULES, formInitialValue, formMemberLevellist
    };
}

const connectEarnRuleTypeProduct = connect(mapStateToProps)(EarnRuleTypeProduct);
export default withStyles(styles)(connectEarnRuleTypeProduct)
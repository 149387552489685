import lodash from 'lodash'
export const normalize = {
    campaignCode,
    productPrefix,
    productSku,
    numberMoreThanZero,
    onlyNumber,
    onlyUpperCaseAndNumber,
    onlyNumberTypeString,
    upperCasewithNumber,
    onlyFloatingTwo,
    trimValue
};

function numberMoreThanZero(value) {
    if(lodash.toInteger(value)<1){
        var newValue = value.replace(/[^1-9]+/g, "");
    }else{
        if (value) {
            newValue = value.replace(/[^0-9]+/g, "");
            // if (newValue) {
            //     newValue = lodash.toInteger(newValue)
            // }
            return newValue
        }
    }
    return ""
}

function onlyEngChar(value) {
    return value.replace(/[^a-zA-Z0-9]+/g, "");
}

function onlyNumber(value) {
    if (value) {
        let newValue = value.replace(/[^0-9]+/g, "");
        // if (newValue) {
        //     newValue = lodash.toInteger(newValue)
        // }
        return newValue
    }
    return ""
}

function campaignCode(value) {
    if (value) {
        if (value.length > 3) {
            value = value.substr(0, 3);
        }
        value = onlyEngChar(value)
        value = value.toUpperCase()
        return value;
    } else {
        return "";
    }
}

function productPrefix(value) {
    if (value) {
        if (value.length > 2) {
            value = value.substr(0, 2);
        }
        value = onlyEngChar(value)
        value = value.toUpperCase()
        return value;
    } else {
        return "";
    }
}

function productSku(value) {
    if (value) {
        value = onlyEngChar(value)
        value = value.toUpperCase()
        return value;
    } else {
        return "";
    }
}

function onlyNumberTypeString(value) {
    if (value) {
        let newValue = value.replace(/[^0-9]+/g, "");
        return newValue
    }
    return ""
}

function onlyUpperCaseAndNumber(value){
  const newValue = value.replace(/[^a-zA-Z0-9]+/g, "");
  return lodash.toUpper(newValue);
}

function upperCasewithNumber(value) {
    if (value) {
        value = onlyEngChar(value)
        value = value.toUpperCase()
        return value;
    } else {
        return "";
    }
}

function onlyFloatingTwo(value) {
    if (value) {
        let newValue = value.replace(/[^0-9.]+/g, "");
        if (lodash.includes(newValue, '.') && !lodash.endsWith(newValue, '.')) {
            let valueSplited = newValue.split('.')
            newValue = valueSplited[0] + "." + valueSplited[1].substr(0, 2)
        }
        return newValue
    }
    return ""
}

function trimValue(value) {
    if (value) {
        return lodash.trim(value)
    }
}

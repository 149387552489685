import { upload_3rd_partyConstants } from '../constants';
import { uploadServices, merchantServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const uploadActions = {
   getListsMerchant,
   addUpload3rdParty,
   addUpload3rdPartyByMerchant,
   updateUpload3rdParty,
   updateUpload3rdPartyByMerchant,
   deleteUpload3rdParty,
   deleteUpload3rdPartyByMerchant,
   getListsUpload3rdParty,
   getListsUpload3rdPartyByMerchant,
   apiImport3rdPartyCode,
   apiImport3rdPartyCodeByMerchant,
};

function getListsMerchant(params) {
   return dispatch => {
      // loader(dispatch, true)
      merchantServices.getListsMerchant(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         )
   };

   function success(tmp) { return { type: upload_3rd_partyConstants.GET_LIST_MERCHANT, tmp } }
}

function getListsUpload3rdParty(params) {
   return dispatch => {
      dispatch({ type: upload_3rd_partyConstants.SHOW_TABLE_LOADER })
      uploadServices.getListsUpload3rdParty(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: upload_3rd_partyConstants.HIDE_TABLE_LOADER })
         })
   };

   function success(tmp) { return { type: upload_3rd_partyConstants.TABLE_GET_LIST, tmp } }
}

function getListsUpload3rdPartyByMerchant(params) {
   return dispatch => {
      dispatch({ type: upload_3rd_partyConstants.SHOW_TABLE_LOADER })
      uploadServices.getListsUpload3rdPartyByMerchant(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: upload_3rd_partyConstants.HIDE_TABLE_LOADER })
         })
   };

   function success(tmp) { return { type: upload_3rd_partyConstants.TABLE_GET_LIST, tmp } }
}

function addUpload3rdParty(params) {
   return dispatch => {
      loader(dispatch, true)
      uploadServices.apiAddUpload3rdParty(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: upload_3rd_partyConstants.ADD_NEW_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.3rd_party_was_created"))
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}


function addUpload3rdPartyByMerchant(params) {
   return dispatch => {
      loader(dispatch, true)
      uploadServices.apiAddUpload3rdPartyByMerchant(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: upload_3rd_partyConstants.ADD_NEW_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.3rd_party_was_created"))
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function updateUpload3rdParty(params) {
   return dispatch => {
      loader(dispatch, true)
      uploadServices.apiUpdateUpload3rdParty(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: upload_3rd_partyConstants.EDIT_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.3rd_party_was_updated"))
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function updateUpload3rdPartyByMerchant(params) {
   return dispatch => {
      loader(dispatch, true)
      uploadServices.apiUpdateUpload3rdPartyByMerchant(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: upload_3rd_partyConstants.EDIT_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.3rd_party_was_updated"))
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function deleteUpload3rdParty(params) {
   return dispatch => {
      loader(dispatch, true)
      uploadServices.apiDeleteUpload3rdParty(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch({ type: upload_3rd_partyConstants.DELETE_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.tag_was_deleted"))
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function deleteUpload3rdPartyByMerchant(params) {
   return dispatch => {
      loader(dispatch, true)
      uploadServices.apiDeleteUpload3rdPartyByMerchant(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch({ type: upload_3rd_partyConstants.DELETE_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t("warning_messages.tag_was_deleted"))
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function apiImport3rdPartyCode(params) {
   return dispatch => {
      loader(dispatch, true)
      uploadServices.apiImport3rdPartyCode(params)
         .then(
            response => {
               if (response.status) {
                  const bodyMessage = (
                     `<div>
                           <p>Uploaded ${(parseInt(response.data.nDuplicated) + parseInt(response.data.nSuccess))} codes</p>
                           <p style="color:green">Success ${response.data.nSuccess} codes</p>
                           <p style="color:red">Duplicated ${response.data.nDuplicated} codes</p>
                        </div>`
                  )
                  dispatch({ type: upload_3rd_partyConstants.IMPORT_CODE_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.uploaded_successfully'), false, false, bodyMessage);
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function apiImport3rdPartyCodeByMerchant(params) {
   return dispatch => {
      loader(dispatch, true)
      uploadServices.apiImport3rdPartyCodeByMerchant(params)
         .then(
            response => {
               if (response.status) {
                  const bodyMessage = (
                     `<div>
                           <p>Uploaded ${(parseInt(response.data.nDuplicated) + parseInt(response.data.nSuccess))} codes</p>
                           <p style="color:green">Success ${response.data.nSuccess} codes</p>
                           <p style="color:red">Duplicated ${response.data.nDuplicated} codes</p>
                        </div>`
                  )
                  dispatch({ type: upload_3rd_partyConstants.IMPORT_CODE_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.uploaded_successfully'), false, false, bodyMessage);
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}
import React from "react";
import { Field, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import lodash from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import TableEarnReport from "./TableEarnReport.jsx";

import { reportActions } from "actions";
import { crmValidator } from "helpers";
import PurchaseDetail from './PurchaseDetailDialog'
import { plugins } from 'helpers';
import i18n from 'i18n';

const style = {};
const form_validate = {
    phoneNumber: {
        rules: {
            number: true
        },
        messages: {
            required: "กรุณากรอก"
        }
    }
};

const FORM_NAME = "FORM_FILTER_EARN_TRANSACTION";

class EarnTransactionReport extends React.Component {
    handleSubmit(values) {
        const { dispatch, earnReport } = this.props;
        const { tableFilter } = earnReport
        let _tableFilter = {
            page: tableFilter.page ? tableFilter.page: 0,
            skip: tableFilter.skip,
            limit: tableFilter.limit,
            sortBy: tableFilter.sortBy,
            sortDir: tableFilter.sortDir,
            keyword: "",
            search: {}
        }
        let search = { ...values };
        if (lodash.size(search) > 0) {
            _tableFilter.search = search;
        }
        const resetTable = true
        dispatch(reportActions.getEarnTransactionList(_tableFilter, resetTable));
    }

    componentDidMount() {
        const { dispatch, earnReport } = this.props;
        const { tableFilter } = earnReport;
        this.getProductList();
        this.getEarnRuleLists();
        dispatch(reportActions.getEarnTransactionList(tableFilter));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(reset(FORM_NAME))
        dispatch({ type: "CLEAR_EARNREPORT_FILTER" })
    }

    getProductList(keyword) {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active";
        if (keyword) {
            reqParams += `&keyword=${keyword}`;
        }
        dispatch(reportActions.getProductList(reqParams));
    }

    getEarnRuleLists(keyword) {
        const { dispatch } = this.props;
        let reqParams = "";
        // if (keyword) {
        //     reqParams += `&keyword=${keyword}`;
        // }
        dispatch(reportActions.getEarnRuleLists(reqParams));
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch(reset(FORM_NAME))
        dispatch({ type: "CLEAR_EARNREPORT_FILTER" })
    };

    render() {
        const {
            FORM_FILTER_EARN_TRANSACTION,
            // merchantSetting,
            handleSubmit,
            earnReport,
            package_features
        } = this.props;
        let startMaxDate = new Date();
        // let filterAgeOptions = merchantSetting.ageRangeList;

        if (lodash.has(FORM_FILTER_EARN_TRANSACTION, "values.endDate")) {
            startMaxDate = FORM_FILTER_EARN_TRANSACTION.values.endDate;
        }

        let endMinDate;
        if (lodash.has(FORM_FILTER_EARN_TRANSACTION, "values.startDate")) {
            endMinDate = FORM_FILTER_EARN_TRANSACTION.values.startDate;
        }

        let productList = [];

        productList.push({
            value: "all",
            label: "All"
        });

        if (lodash.has(earnReport, "productList")) {
            lodash.forEach(earnReport.productList, function (value, key) {
                productList.push({
                    value: value._id,
                    label: value.name
                });
            });
        }

        let earnRuleTypeList = plugins.getEarnRuleListByPacekageFeature(package_features)
        let earnActivityTypeList = plugins.getEarnActListByPacekageFeature(package_features)
        let earnTypesList = lodash.union(earnRuleTypeList, earnActivityTypeList)
        earnTypesList = lodash.orderBy(earnTypesList, 'label', 'asc')

        let ruleNameList = [];

        ruleNameList.push({
            value: "all",
            label: "All"
        });

        if (lodash.has(earnReport, "earnRuleList")) {
            lodash.forEach(earnReport.earnRuleList, function (value, key) {
                ruleNameList.push({
                    value: value._id,
                    label: value.name
                });
            });
        }
        return (
            <div>

                <form
                    name={FORM_NAME}
                    onSubmit={handleSubmit(this.handleSubmit.bind(this))}
                >
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="startDate"
                                component={CustomDatePicker}
                                type="text"
                                maxDate={startMaxDate}
                                labelText="Start Date"
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="endDate"
                                minDate={endMinDate}
                                maxDate={new Date()}
                                component={CustomDatePicker}
                                type="text"
                                labelText="End Date"
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="productID"
                                component={CustomSelect}
                                type="text"
                                labelText="Product"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={productList}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={3}>
                            <Field
                                name="gender"
                                component={CustomSelect}
                                type="text"
                                labelText={i18n.t("label.gender")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                novalidate
                                // optionsList={Gender}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="memberFirstName"
                                component={CustomInput}
                                type="text"
                                labelText="Firstname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="memberLastName"
                                component={CustomInput}
                                type="text"
                                labelText="Lastname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="phoneNumber"
                                component={CustomInput}
                                type="text"
                                labelText="Mobile No."
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={value => {
                                    return crmValidator.handleKeyPress(
                                        value,
                                        form_validate.phoneNumber
                                    );
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="memberId"
                                component={CustomInput}
                                type="text"
                                labelText="Member ID"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={value => {
                                    return crmValidator.handleKeyPress(
                                        value,
                                        form_validate.phoneNumber
                                    );
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="ruleId"
                                component={CustomSelect}
                                type="text"
                                labelText="Rule Name"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                novalidate
                                optionsList={ruleNameList}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="earnRuleType"
                                component={CustomSelect}
                                type="text"
                                labelText="Earn Rule Type"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={earnTypesList}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="code"
                                component={CustomInput}
                                type="text"
                                labelText="Code"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>  
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="status"
                                component={CustomSelect}
                                type="text"
                                labelText="Status"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    { value: "all", label: "All" },
                                    { value: "1", label: "Success" },
                                    { value: "0", label: "Failed" },
                                    { value: "void", label: "Void" },
                                    { value: "pending", label: "Pending" },
                                    { value: "reject", label: "Reject" }
                                ]}
                            />
                        </GridItem>                      
                        {/* <GridItem xs={6} sm={3}>
                        <Field
                            name="filterAge"
                            component={CustomSelect}
                            type="text"
                            labelText="Age"
                            formControlProps={{
                            fullWidth: true
                            }}
                            novalidate
                            optionsList={filterAgeOptions}
                        />
                        </GridItem> */}
                        {/* <GridItem xs={6} sm={3}>
                            <Field
                                name="filterProvince"
                                component={CustomSelect}
                                type="text"
                                labelText="Province"
                                novalidate
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem> */}
                        <GridItem xs={12} sm={6} md={3} lg={3}>
                            <Button
                                id="button-earnreport-clear"
                                onClick={this.clearFilter}
                                style={{ marginRight: "10px", marginTop: "25px" }}
                                size="sm"
                                color="white"
                            >
                                Clear
                            </Button>
                            <Button
                                type="submit"
                                id="button-earnreport-submit"
                                style={{ marginTop: "25px" }}
                                size="sm"
                                color="primary"
                            >
                                Submit
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
                <TableEarnReport />
                <PurchaseDetail />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { FORM_FILTER_EARN_TRANSACTION } = state.form;
    const { earnReport } = state.report;
    const { merchantSetting, package_features } = state.authentication;

    return {
        FORM_FILTER_EARN_TRANSACTION,
        earnReport,
        merchantSetting,
        package_features
    };
}

const connectedComponent = connect(mapStateToProps)(EarnTransactionReport);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent));

import React from "react";
import { connect } from 'react-redux';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import BusinessWebsiteForm from "./BusinessWebsiteForm.jsx";

const style = {
    customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
};
const pageStyle = { ...style, ...sweetAlertStyle };

class BusinessWebsiteContainer extends React.Component {
  render() {
    const { formInitialValue } = this.props
    return (
      <GridContainer>
          <GridItem xs={12}>
            <Card>
                <CardBody>
                {/* <h4 style={{fontWeight:'500', fontSize:'1.25rem'}}>BUSINESS WEBSITE</h4>  */}
                  <GridContainer>
                    <GridItem xs={12}>
                      <BusinessWebsiteForm initialValues={formInitialValue}/>
                    </GridItem>
                  </GridContainer>
                </CardBody>
            </Card>
          </GridItem>
      </GridContainer>
    )
  }
}
function mapStateToProps(state) {
  const { formInitialValue, formOpen } = state.business_website;
  return {
    formInitialValue, formOpen
  };
}
export default connect(mapStateToProps)(withStyles(pageStyle)(BusinessWebsiteContainer));
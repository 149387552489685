export const missionConstants = {
    OPEN_FORM_ADD: 'OPEN_FORM_ADD',
    OPEN_FORM_EDIT: 'OPEN_FORM_EDIT',
    CLOSE_FORM_MISSION: 'CLOSE_FORM_MISSION',
    ADD_MISSION_SUCCESS: 'ADD_MISSION_SUCCESS',
    GET_MISSION_DATA_SUCCESS: 'GET_MISSION_DATA_SUCCESS',
    TABLE_GET_LIST_MISSION: "TABLE_GET_LIST_MISSION",
    TABLE_RELOAD_SUCCESS: "TABLE_RELOAD_SUCCESS",
    TABLE_RESET_SUCCESS: "TABLE_RESET_SUCCESS",
    DELETE_MISSION_SUCCESS: "DELETE_MISSION_SUCCESS",
    EDIT_MISSION_SUCCESS: "EDIT_MISSION_SUCCESS",
    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: "GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS",
    CLEAR_PAGE_STATE: 'MISSION_CLEAR_PAGE_STATE',
    GET_LUCKY_DRAW_REWARD_SET_SUCCESS: "MISSION_GET_LUCKY_DRAW_REWARD_SET_SUCCESS",
};
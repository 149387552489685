export const blacklistConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_PAGE_STATE',

    OPEN_ADD: 'OPEN_ADD_BLACKLIST',
    OPEN_EDIT: 'OPEN_EDIT_BLACKLIST',
    CLOSE_FORM: 'CLOSE_FORM_BLACKLIST',
    GET_BLACKLIST_SUCCESS: 'GET_BLACKLIST_SUCCESS',

    ADD_NEW_BLACKLIST_SUCCESS: 'ADD_NEW_BLACKLIST_SUCCESS',
    // ADD_NEW_TAG_FAILURE: 'ADD_NEW_TAG_FAILURE',

    EDIT_BLACKLIST_SUCCESS: 'EDIT_BLACKLIST_SUCCESS',
    //EDIT_BLACKLIST_FAILURE: 'EDIT_BLACKLIST_FAILURE',

    SHOW_LOADER: 'SHOW_TABLE_TAG_LOADER_SPINNER',
    HIDE_LOADER: 'HIDE_TABLE_TAG_LOADER_SPINNER',

    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    DELETE_BLACKLIST_SUCCESS: 'DELETE_BLACKLIST_SUCCESS',
    // DELETE_TAG_FAILURE: 'DELETE_TAG_FAILURE',

    OPEN_FORM_UPLOAD_BLACKLIST: 'OPEN_FORM_UPLOAD_BLACKLIST',
    CLOSE_FORM_UPLOAD_BLACKLIST: 'CLOSE_FORM_UPLOAD_BLACKLIST',
    SHOW_UPLOAD_BLACKLIST_RESULT: 'SHOW_UPLOAD_BLACKLIST_RESULT',
    HIDE_UPLOAD_BLACKLIST_RESULT: 'HIDE_UPLOAD_BLACKLIST_RESULT',

    OPEN_SAVE_UPLOAD_BLACKLIST_RESULT: 'OPEN_SAVE_UPLOAD_BLACKLIST_RESULT',
    CLOSE_SAVE_UPLOAD_BLACKLIST_RESULT: 'CLOSE_SAVE_UPLOAD_BLACKLIST_RESULT',

    GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
    TABLE_RESET: 'TABLE_RESET',
};


import { winnerConstants } from '../constants';
const initialState = {
    formOpen: false,
    formMode: 'add',
    submitForm: false,
    formInitialValue: {},
    tableReload: false,
    tableReset: false,
    tableDatas: [],
    tableKey: 0,
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "createdAt",
        sortDir: "desc",
        keyword: "",
        search: {}
    },
    showLoader: false
};
// winner
export function winner(state = initialState, action) {
    switch (action.type) {
        case winnerConstants.CLEAR_PAGE_STATE:
            return initialState;
        case winnerConstants.OPEN_FORM_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue
            }
        case winnerConstants.CLOSE_FORM_WINNER:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                }
            }
        case winnerConstants.SET_FILTER:
            return {
                ...state,
                tableFilter: action.tableFilter
            }
        case winnerConstants.TABLE_GET_LIST_DATAS:
            return {
                ...state,
                ...state.winner,
                tableFilter: action.tableFilter,
                tableDatas: (action.data ? action.data : []),
                tableTotal: (action.total ? action.total : 0)
            }
        case winnerConstants.RELOAD_TABLE_MEMBERLIST:
        case winnerConstants.TABLE_RELOAD_SUCCESS:
        case winnerConstants.TABLE_RESET:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case winnerConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReset: false
            }
        case winnerConstants.EDIT_WINNER_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
                submitForm: true
            }
        case winnerConstants.SHOW_LOADER_WINNER:
            return {
                ...state,
                showLoader: true
            }
        case winnerConstants.HIDE_LOADER_WINNER:
            return {
                ...state,
                showLoader: false
            }
        case winnerConstants.GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                winnerList: {
                    ...state.winnerList,
                    // shippingCompanyList: action.data
                }
            }
        case winnerConstants.TABLE_GET_LIST_DATAS_RESET:
            return {
                ...state,
                winnerList: {
                    ...state.winnerList,
                    tableKey: Date.now()
                }
            }
        case winnerConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                winnerList: {
                    ...state.winnerList,
                    campaignList: action.data.campaigns
                }
            }
        case winnerConstants.GET_DROPDOWN_REFERENCE_LIST_SUCCESS:
            return {
                ...state,
                winnerList: {
                    ...state.winnerList,
                    referenceList: action.data.refs,
                },
            }
        case winnerConstants.GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS:
            return {
                ...state,
                winnerList: {
                    ...state.winnerList,
                    announceDateList: action.data.announceDates,
                },
            }
        case winnerConstants.GET_DROPDOWN_REWARD_LIST_SUCCESS:
            return {
                ...state,
                winnerList: {
                    ...state.winnerList,
                    rewardList: action.data.rewards,
                },
            }
        default:
            return state
    }
}
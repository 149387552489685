import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import lodash from 'lodash'
import { plugins } from "helpers";
import { pointAdjustmentActions } from 'actions';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.jsx";

class PointAdjustmentCreateConfirmation extends React.Component {

    closeDialog() {
        const { dispatch } = this.props;
        dispatch({ type: 'CLOSE_CONFIRMATION_CREATE_ADJUSTMENT_POPUP'});
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas;
        if(rowIndex.length === 0 ){
            rowIndex = 0;   
        }
        return data[rowIndex];
    }

    comfirmCreateAdjustment(){
        const { dispatch, formMode, adjustmentDetail } = this.props;
        dispatch(pointAdjustmentActions.createPointAdjustment(adjustmentDetail))
        dispatch({ type: 'CLOSE_CONFIRMATION_CREATE_ADJUSTMENT_POPUP'});
    }

    render() {
        const { classes, adjustmentDetail, searchData } = this.props;
        let headerText = lodash.get(adjustmentDetail, 'type') === 'add' ? 'Add': 'Deduct'
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open = {lodash.get(adjustmentDetail, 'createConfirmationPopupOpen')}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                maxWidth='xs'
                scroll="body"
                disableRestoreFocus={true}
            >
            
                <DialogContent
                    id="adjustment-point-dialog"
                    style={{ textAlign: 'center' }}
                >
                    <h4 className={classes.modalTitle} style={{ marginBottom: '20px'}} >
                        <b>{headerText} Point Comfirmation</b>
                    </h4>
                    Do you want to {lodash.get(adjustmentDetail, 'type')} {lodash.get(adjustmentDetail, 'point')}{lodash.get(adjustmentDetail, 'point') > 1 ? <> points</>: <> point</>} {lodash.get(adjustmentDetail, 'type') === 'add' ? <>to</>: <>from</>} 
                    <br/>
                    {lodash.get(adjustmentDetail, 'memberName')} ({lodash.get(searchData.data[0], 'phoneNumber')})
                    <br/>
                    and the remark is 
                    <br/>
                    "{lodash.get(adjustmentDetail, 'remark')}"
                    <br/>
                    <Button
                        id="cancel-btn"
                        onClick={() => {
                            this.closeDialog()
                        }}
                        size="sm"
                        color="white"
                        style={{ marginTop: '25px', marginRight: '20px'}}>
                        Cancel
                    </Button>
                    <Button
                        id='approve-btn'
                        color="primary"
                        size='sm'
                        onClick={() => {
                            this.comfirmCreateAdjustment()
                        }}
                        style={{ marginTop: '25px' }}>
                        Confirm
                    </Button>
                </DialogContent>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { adjustmentDetail, searchData } = state.point_adjustment;
    return {
        adjustmentDetail, searchData
    };
}
const connectedComponent = connect(mapStateToProps)(PointAdjustmentCreateConfirmation);

export default withStyles(sweetAlertStyle)(connectedComponent);



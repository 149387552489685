import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';

import withStyles from "@material-ui/core/styles/withStyles";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import NavPills from "components/NavPills/NavPills.jsx";

import EarnTransactionReport from './EarnTransactionReport.jsx';
import BurnTransactionReport from './BurnTransactionReport.jsx';
import LuckyDrawReport from "./LuckyDrawReport.jsx";
import { plugins } from 'helpers';
const style = {};

class ReportContainer extends React.Component {
 
  setTab = (setting) => {
    let set = []
      if(plugins.isHasPermission("report[earn]", 'read')){
        set.push({
          tabButton: "Earn",
          tabContent: (<EarnTransactionReport initialValues={{ productId: "all", startDate: moment().startOf('day'), endDate: moment().set('hour',23).set('minute',59).set('second',59) }} />)
        });
      }
      if(plugins.isHasPermission("report[burn]", 'read')){
        set.push({
          tabButton: "Burn",
          tabContent: (<BurnTransactionReport initialValues={{ startDate: moment().startOf('day'), endDate: moment().set('hour',23).set('minute',59).set('second',59) }} />)
        });
      }
      // if(plugins.isHasPermission("report[lucky_draw]", 'read')){
      //   set.push({
      //     tabButton: "Lucky Draw",
      //     tabContent: (<LuckyDrawReport />)
      //   });
      // }

    return set
  }

  

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: 'REPORT_REDUCER_INIT' })
  }


  render() {
    const { merchantSetting } = this.props;
    return (
      <Card>
        <CardBody>
          <NavPills
            color="warning"
            tabBtnType="square"
            tabs={this.setTab(merchantSetting.luckyDraw)}
          />
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  const { tableReset } = state.member;
  const { merchantSetting, user, superAdmin, permission } = state.authentication;
  return {
    tableReset, merchantSetting, user, superAdmin, permission
  };
}
const connectReportContainer = connect(mapStateToProps)(ReportContainer);

export default withStyles(style)(connectReportContainer);
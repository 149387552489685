import React from "react";
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import TableTopUpList from "./TableTopUpList.jsx";

import { trueMoneyTopupActions } from 'actions';
import { crmValidator } from 'helpers';

const style = {

}

const FORM_NAME = 'FORM_FILTER_TRUE_MONEY_TOP_UP_LIST';

const form_validate = {

}

class TopUpReport extends React.Component {

    handleSubmit(values) {
        const { dispatch } = this.props;
        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "created_at",
            sortDir: "desc",
            keyword: "",
            search: {},
            createDateFrom: values.createDateFrom,
            createDateTo: values.createDateTo,
            status: values.status,
            topupNumber: values.topupNumber,
        }
        const resetTable = true
        dispatch(trueMoneyTopupActions.getTrueMoneyTransactionTopup(tableFilter, resetTable));
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch({ type: "CLEAR_TRUEMONEY_TOPUP_FILTER" })
        dispatch(reset(FORM_NAME))
    }

    componentDidMount() {
        const { dispatch, tableFilter, resetTable } = this.props;
        // const { tableFilter } = tmnTopup;
        dispatch(trueMoneyTopupActions.getTrueMoneyTransactionTopup(tableFilter, resetTable));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: "CLEAR_TRUEMONEY_TOPUP_FILTER" })
        dispatch(reset(FORM_NAME))


    }

    render() {
        const { handleSubmit } = this.props;
        // let startMaxDate = new Date();

        // let filterAgeOptions = [...merchantSetting.ageRangeList, {
        //     "label": "Not specified",
        //     "value": "unknown"
        // }];
        // let ageRangeMode = merchantSetting.ageRangeMember;

        // if (lodash.has(FORM_FILTER_BURN_TRANSACTION, 'values.endDate')) {
        //     startMaxDate = FORM_FILTER_BURN_TRANSACTION.values.endDate;
        // }

        // let endMinDate;
        // if (lodash.has(FORM_FILTER_BURN_TRANSACTION, 'values.startDate')) {
        //     endMinDate = FORM_FILTER_BURN_TRANSACTION.values.startDate;
        // }

        return (
            <div>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={4} lg={4}>
                            <Field
                                id="trans-start-date"
                                name="createDateFrom"
                                component={CustomDatePicker}
                                type="text"
                                // maxDate={startMaxDate}
                                labelText="Transaction Start Date"
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} lg={4}>
                            <Field
                                id="trans-end-date"
                                name="createDateTo"
                                // minDate={endMinDate}
                                // maxDate={new Date()}
                                component={CustomDatePicker}
                                type="text"
                                labelText="Transaction End Date"
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} lg={4}>
                            <Field
                                id="topup-mobile-number-input"
                                name="topupNumber"
                                component={CustomInput}
                                type="text"
                                labelText="Top Up Mobile No."
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, form_validate.phoneNumber)
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} lg={4}>
                            <Field
                                id="status-select"
                                name="status"
                                component={CustomSelect}
                                type="text"
                                labelText="Status"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    { value: "true", label: "Success" },
                                    { value: "false", label: "Failed" }
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={4} lg={4}>

                            <Button
                                id="clear-btn"
                                onClick={this.clearFilter}
                                style={{
                                    marginTop: "25px"
                                }}
                                size="sm"
                                color="white">
                                Clear
                            </Button>
                            <Button
                                id="submit-btn"
                                type="submit"
                                style={{
                                    marginRight: "10px", marginTop: "25px"
                                }}
                                size="sm"
                                color="primary">
                                Submit
                            </Button>


                        </GridItem>
                    </GridContainer>
                </form>

                <TableTopUpList />

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { FORM_FILTER_TRUE_MONEY_TOP_UP_LIST } = state.form;
    const { tableFilter, resetTable } = state.tmnTopup;
    const { merchantSetting } = state.authentication;

    return {
        FORM_FILTER_TRUE_MONEY_TOP_UP_LIST, merchantSetting, tableFilter, resetTable
    }
}

const connectedComponent = connect(mapStateToProps)(TopUpReport);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent))
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, arrayPush, change } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import ColorSelect from "components/CustomSelect/ColorSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import { dataFilteringTemplateConstants } from '../../constants'
import { dataFilteringTemplateAction } from 'actions';

import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import InputValue from './components/InputValue'

const style = {
    ...modalStyle,
    cardBody: {
        padding: "15px"
    },
    cardHeader: {
        marginLeft: "0px",
        color: "#333"
    },
    table: {
        border: "solid 1px #ccc"
    },
    tdBordered: {
        border: "solid 0px #ccc"
    },
    tableError: {
        border: "solid 2px red"
    },
    optionHover: {
        backgroundColor: "#fff",
        "&:hover": {
            backgroundColor: "#eee"
        }
    },
    overrides: {
        MuiDialog: {
            paper: {
                overflowY: "visible"
            }
        }
    }
}


const FORM_NAME = 'DATAFILTERING_TEMPLATE_FORM_EDIT'

const form_validate = {
    "name": {
        rules: {
            required: true,
            noSpecialCharacters: true,
            maxLength: 50
        },
        messages: {
            required: 'กรุณากรอก',
            noSpecialCharacters: 'ไม่สามารถใช้อักขระพิเศษในชื่อ Tag ได้',
            maxLength: 'ไม่สามารถใช้ชื่อ Tag ได้มากกว่า 50 ตัวอักษร'
        }
    },
    "color": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const validateRequired = (value, allValues, props, name) => {

    console.log('validateRequired name :',name)
    console.log('validateRequired value :',value)
    console.log('validateRequired typeof value :',typeof value)

    if (value === "" || value === null || value === undefined) {

        if (lodash.includes(name, 'exclude')) {
            return "Field is required"

        } else if (lodash.includes(name, 'table')) {
            return "Table is required"

        } else if (lodash.includes(name, 'field')) {
            return "Field is required"

        } else if (lodash.includes(name, 'compareOperator')) {
            return "Operator is required"

        } else if (lodash.includes(name, 'value')) {
            return "value is required"

        } else {
            return "Required"
        }
    }

    if (parseFloat(value) <= 0) {
        return "Please enter number more than 0"
    }
}

class DATAFILTERING_TEMPLATE_FORM_EDIT extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectTableName: {},
            selectFieldName: {},
            selectFieldOptions: {},
            conditionResult: '',
            disableLastvalue:[]
            // disabled: []
        };

    }

    //DATAFILTERING_TEMPLATE_FORM_EDIT
    componentDidMount() {
        const { formImportInitialValue, dataInit } = this.props
        // if (lodash.has(DATAFILTERING_TEMPLATE_FORM_EDIT, 'values.startDate')) {
        //     if (DATAFILTERING_TEMPLATE_FORM_EDIT.values.startDate) {
        //         endDateMinDate = moment(DATAFILTERING_TEMPLATE_FORM_EDIT.values.startDate)
        //     }
        // }

        // const conditions = DATAFILTERING_TEMPLATE_FORM_EDIT.values.conditions
        
        // let index =0;
        // let conditionindex=1;
        // this.setState({
        //     disabled : `conditions[${index}].conditions[${conditionindex}].lastvalue`
        // })
    }
    getTablename = (e, index) => {
        const { selectTableName } = this.state;
        const { datafiltering } = this.props;

        let result = []
        datafiltering.map((value) => {
            if (value.table === e.target.value) {
                result.push({ value: value.tableName, label: value.tableName, fields: value.fields })
            }
        })

        selectTableName[index] = result

        this.setState({
            selectTableName
        });
    }

    getFieldname = (e, index) => {
        const { selectTableName, selectFieldName } = this.state;
        let resultfield = []

        selectTableName[index].map((field) => {
            if (field.value === e.target.value) {
                field.fields.map((data) => {
                    resultfield.push({ value: data.fieldName, label: data.displayName, operation: data.operator, fieldtype: data.fieldtype })
                })
            }
        })
        selectFieldName[index] = resultfield
    }

    getOperation = (e, index, conditionindex) => {
        const { dispatch } = this.props
        const { selectFieldName, selectFieldOptions } = this.state;
        let resultOperation = []
        let type = 'string'
        selectFieldName[index][conditionindex].map((operator) => {
            if (operator.value === e.target.value) {
                type = operator.fieldtype
                let dataOpe = operator.operation.split("|");
                dataOpe.map((item) => {
                    resultOperation.push({ value: item, label: item, fieldtype: operator.type  })
                })
            }
        })

        selectFieldOptions[index] = resultOperation
        selectFieldOptions[index]['fieldtype'] = type

        this.setState({
            selectFieldOptions
        });
        dispatch(change(FORM_NAME, `conditions[${index}].conditions[${conditionindex}].value`, null ))
        dispatch(change(FORM_NAME, `conditions[${index}].conditions[${conditionindex}].lastvalue`, null ))
        dispatch(change(FORM_NAME, `conditions[${index}].conditions[${conditionindex}].fieldtype`, type ))
        // if(filtering){
        //     // name={`conditions[${index}].conditions[${conditionindex}].fieldtype`}
        // }else{
        //     dispatch(change(FORM_NAME, `${index}[fieldtype]`, type ))
        // }
    }
     reassign=(array, index, newValue) => {
        array[index] = newValue;
        return array;
    }
    getShowlastvalue =(e, index, conditionindex, disabled) => {
        const { dispatch } = this.props
        let inputValue = e.target.value
        let result = '';
        let indexofDisabled = disabled.indexOf(`conditions[${index}].conditions[${conditionindex}].lastvalue`)
        
        if(indexofDisabled >= 0){
            if(inputValue == 'in' || inputValue == 'between' || inputValue == 'not_in' ){
                // result = disabled.filter((disable) => disable !== "conditions[0].conditions[0].lastvalue") 
                result = this.reassign(disabled, indexofDisabled, '')  
                // setLastvalues[index]['lastvalue'] = true
                
            }else{
                result = this.reassign(disabled, indexofDisabled, `conditions[${index}].conditions[${conditionindex}].lastvalue`)  
            }
        }
        if(inputValue == 'with_in_the_last' || inputValue !== 'in' || inputValue !== 'between' || inputValue !== 'not_in'){
            dispatch(change(FORM_NAME, `conditions[${index}].conditions[${conditionindex}].lastvalue`, null ))
        }

        dispatch(change(FORM_NAME, `conditions[${index}].conditions[${conditionindex}].value`, null ))
        
        // disabled = result
        this.setState({
            disableLastvalue: result
        })
       
    }
    handleSubmit = (values) => {
        const { dispatch } = this.props

        var reqParams = {
            "conditionId": values.conditionId,
            "templateName": values.templateName,
            "conditions": values.conditions,
        }
        

        dispatch(dataFilteringTemplateAction.editDataFilteringTemplate(reqParams));

        // if (this.props.formMode === "add") {
        //     dispatch(dataFilteringTemplateAction.addNewDataFilteringTemplate(reqParams));
        // } else {
        //     reqParams._id = values._id
        //     dispatch(dataFilteringTemplateAction.editDataFilteringTemplate(reqParams));
        // }
    }

    // handleSubmit(value) {
    //     const { dispatch } = this.props
    //     const { conditions } = value
    //     this.setState({ conditionResult: value })

    //     dispatch({ type: newcondition.OPEN_FORM_TEMPLATE_NAME })

    // }


    addCondition(index) {
        const { dispatch } = this.props
        dispatch(arrayPush(FORM_NAME, `conditions[${index}].conditions`,
            {
                "type": "main",
                "operator": "AND"
            }
        ))
    }

    addOrCondition(conditionIndex, value) {
        const { dispatch } = this.props
        return new Promise((resolve) => {
            dispatch(arrayPush(FORM_NAME, `conditions[${conditionIndex}].conditions`, {
                "id": value, //lodash.get(value, 'value'),
                "operator": value
            }))
            resolve()
        })
        console.log("FORM_NAME  : ", FORM_NAME);

        // dispatch(FORM_NAME, `conditions[${index}].conditions`)
    }

    delOrCondition(index, conditionindex, value) {
        const { dispatch, DATAFILTERING_TEMPLATE_FORM_EDIT } = this.props
        // return new Promise((resolve) => {
        //     dispatch(arrayPush(FORM_NAME, `conditions[${conditionIndex}].conditions`, {
        //         "id": value, //lodash.get(value, 'value'),
        //         "operator": value
        //     }))

        //     // dispatch(change(FORM_NAME, `conditions[${conditionIndex}].conditions`, fieldval.type))

        //     resolve()
        // })
        const tagelist = DATAFILTERING_TEMPLATE_FORM_EDIT.values.conditions

        let tagn = [];
        let vowelArray = lodash.remove(tagelist, function (n, nkey) {
            // let vowels = tagRemove;
            tagn = n.conditions;

            let vArray = lodash.remove(tagn, function (m, mkey) {
                // let vowels = tagRemove;

                if (mkey === conditionindex && nkey === index) {
                    return true;
                }
            });

            

            if (tagn.length === 0) {
                if (n.conditions.length === 0) {
                    if (nkey === index) {
                        return true;
                    }
                }
            }



            // if (nkey === conditionindex) {
            //     return true;
            // }




            // for (let i = 0; i < tagRemove.length; i++) {
            //     if (n === vowels) {
            //         return true;
            //     }
            // }
        });

        dispatch(change(FORM_NAME, `conditions`, tagelist))

        // const { dispatch, DATAFILTERING_TEMPLATE_FORM_EDIT } = this.props
        // dispatch(FORM_NAME, `conditions[${conditionIndex}].conditions`)

        // return new Promise((resolve) => {
        //     dispatch(remove(FORM_NAME, `conditions[${conditionIndex}].conditions`, {
        //         "id": value, //lodash.get(value, 'value'),
        //         "operator": value
        //     }))
        //     resolve()
        // })
        // console.log("dispatch(FORM_NAME, `conditions[${index}].conditions`) : ", dispatch(FORM_NAME, `conditions[${index}].conditions`))
    }
   
    // let vowelArray = _.remove(tagelist, function (n) {
    //     let vowels = tagRemove;
    //     for (let i = 0; i < tagRemove.length; i++) {
    //         if (n === vowels) {
    //             return true;
    //         }
    //     }
    // });

    render() {
        const { classes, formImportOpen, formMode, handleSubmit, DATAFILTERING_TEMPLATE_FORM_EDIT, initialValues, datafiltering,dataInit } = this.props
        
        let { readOnly } = this.props
        let disabled=[]
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }

        let startDateMinDate = new Date().setHours(0, 0, 0, 0)
        if (initialValues.startDate) {
            startDateMinDate = moment(initialValues.startDate)
        }

        let endDateMinDate = startDateMinDate;
        if (lodash.has(DATAFILTERING_TEMPLATE_FORM_EDIT, 'values.startDate')) {
            if (DATAFILTERING_TEMPLATE_FORM_EDIT.values.startDate) {
                endDateMinDate = moment(DATAFILTERING_TEMPLATE_FORM_EDIT.values.startDate)
            }
        }

        const conditions = DATAFILTERING_TEMPLATE_FORM_EDIT.values.conditions

        var rows = [];
        let num = 0;
        let num_value = 0;

        const { selectTableName, selectFieldName, selectFieldOptions } = this.state;

        let result = []
        let resultfield = []
        let resultfield_value = []
        let resultOperation = []
        let resultOperation_value = []

        datafiltering.map((value) => {
            result.push({ value: value.tableName, label: value.tableName, fields: value.fields })
        })
        let disableButton = [];
        for (const property in conditions) {
            rows.push(conditions[property]);

            selectTableName[num] = result

            const test = conditions[property].conditions

            resultfield_value = []
            resultOperation_value = []

            for (const index in test) {
                selectTableName[num].map((field) => {
                    if (field.value === test[index].table) {
                        resultfield = []

                        field.fields.map((data) => {
                            resultfield.push({ value: data.fieldName, label: data.displayName, operation: data.operator, fieldtype: data.fieldtype })
                        })
                        // disableButton.push(`conditions[${num}].conditions[${index}].lastvalue`)
                    }
                })

                resultfield_value[index] = resultfield

                num_value++;

                resultfield.map((operator) => {
                    if (operator.value === test[index].field) {
                        resultOperation = []
                        let dataOpe = operator.operation.split("|");
                        dataOpe.map((item) => {
                            resultOperation.push({ value: item, label: item, fieldtype: operator.fieldtype })
                        })
                    }
                })

                resultOperation_value[index] = resultOperation
            }

            selectFieldName[num] = resultfield_value
            selectFieldOptions[num] = resultOperation_value

            num++;
        }
        rows.map((filtering, index) =>
            filtering.conditions.map((condition, conditionindex) =>{
                let condi = ''
                condi =  condition.lastvalue ? '' : `conditions[${index}].conditions[${conditionindex}].lastvalue`
                disabled.push(
                    condi
                )

                    // disabled :[...this.state.disabled, condi]
                // })
            }
        ))
        
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formImportOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="lg"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>{formMode === 'add' ? 'Add Tag' : (readOnly === true ? 'Tag Info' : 'Edit Tag')}</b>
                    </h4>

                    <Button
                        type="button"
                        color="success"
                        name="btnaddmain"
                        // style={{ marginTop: "0px", width: "80px" }}
                        style={{ width: "80px" }}
                        // onClick={this.addCondition.bind(this)}
                        onClick={() => {
                            this.addCondition(num)
                        }}
                    >
                        Add
                            </Button>
                </DialogTitle>

                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>

                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <React.Fragment>
                                {rows.map((filtering, index) =>
                                    <React.Fragment key={index}>
                                        {/* {this.getdatatest(index)} */}
                                        {(index > 0) &&
                                            <div style={{
                                                padding: "20px",
                                                width: "70px",
                                                background: "linear-gradient(#000, #000) no-repeat center/2px 100%",
                                                textAlign: "center"
                                            }}>
                                                <b style={{ backgroundColor: "white" }}>AND</b>
                                            </div>
                                        }
                                        {filtering.conditions.map((condition, conditionindex) =>{
                                            let hidden = true
                                            let hiddenlog = 'true';
                                            let showValueandLastvalue = '';
                                            if(this.state.disableLastvalue.length > 0 ){
                                                if(this.state.disableLastvalue.indexOf(`conditions[${index}].conditions[${conditionindex}].lastvalue`) == -1){
                                                    hidden = false
                                                    hiddenlog = 'false state'
                                                }else if(disabled.indexOf(`conditions[${index}].conditions[${conditionindex}].lastvalue`)!==-1){
                                                    hiddenlog = 'true dis'
                                                }    
                                            }else{
                                                if(disabled.indexOf(`conditions[${index}].conditions[${conditionindex}].lastvalue`)!==-1){
                                                    hiddenlog = 'true dis'
                                                    hidden = true
                                                }else if(this.state.disableLastvalue.indexOf(`conditions[${index}].conditions[${conditionindex}].lastvalue`) == -1){
                                                    hidden = false
                                                    hiddenlog = 'false state'
                                                }
                                            }
                                            
                                            return (
                                                <>
                                                    <Table className={classes.table} >
                                                <TableHead>
                                                    <TableRow className={classes.tdBordered}>
                                                        <TableCell
                                                            key="condition_header"
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "90px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                // name={`${filtering}[exclude]`}
                                                                name={`conditions[${index}].conditions[${conditionindex}].exclude`}
                                                                component={CustomSelect}
                                                                type="text"
                                                                labelText={""}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                outlined
                                                                optionsList={[
                                                                    {
                                                                        value: 'false',
                                                                        label: "include"
                                                                    },
                                                                    {
                                                                        value: 'true',
                                                                        label: "exclude"
                                                                    }
                                                                ]}
                                                                onChange={(e) => {
                                                                    this.getTablename(e, index)
                                                                }}
                                                                validate={[validateRequired]}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "100px",
                                                                whiteSpace: "nowrap"
                                                            }}>

                                                            <Field
                                                                // name={`${filtering}[table]`}
                                                                name={`conditions[${index}].conditions[${conditionindex}].table`}
                                                                component={CustomSelect}
                                                                type="text"
                                                                labelText={``}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                outlined
                                                                optionsList={this.state.selectTableName[index]}
                                                                onChange={(e) => {
                                                                    this.getFieldname(e, index)
                                                                }}
                                                                validate={[validateRequired]}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "100px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            {/* <Field
                                                                name={`conditions[${index}].conditions[${conditionindex}].field`}
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                            /> */}
                                                            <Field
                                                                name={`conditions[${index}].conditions[${conditionindex}].field`}
                                                                component={CustomSelect}
                                                                type="text"
                                                                labelText={``}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                outlined
                                                                optionsList={selectFieldName[index][conditionindex]}
                                                                onChange={(e) => {
                                                                    this.getOperation(e, index, conditionindex)
                                                                }}
                                                                validate={[validateRequired]}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "80px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            {/* <Field
                                                                name={`conditions[${index}].conditions[${conditionindex}].compareOperator`}
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                            /> */}
                                                            <Field
                                                                // name={`${filtering}[compareOperator]`}
                                                                name={`conditions[${index}].conditions[${conditionindex}].compareOperator`}
                                                                component={CustomSelect}
                                                                type="text"
                                                                labelText={``}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                outlined
                                                                onChange={(e) => {
                                                                    this.getShowlastvalue(e, index, conditionindex, disabled)
                                                                    // this.getShowlastvalue(e, index)
                                                                }}
                                                                optionsList={selectFieldOptions[index][conditionindex]}
                                                                validate={[validateRequired]}
                                                            />
                                                            <Field 
                                                                name={`conditions[${index}].conditions[${conditionindex}].fieldtype`}
                                                                component="input"
                                                                type="hidden"
                                                            />
                                                        </TableCell>
                                                        <InputValue 
                                                            index={index} 
                                                            conditionindex={conditionindex} 
                                                            hidden={hidden} 
                                                            fieldtype={condition.fieldtype}
                                                            validateRequired={validateRequired}
                                                            compareOperator={condition.compareOperator}
                                                            dataValue={selectFieldOptions[index]}
                                                        />
                                        {/* {showValueandLastvalue} 20-04-2021 */}
                                                        {/* <TableCell
                                                            style={{
                                                                paddingRight: "1px",
                                                                paddingLeft: "10px",
                                                                minWidth: "50px",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            <Field
                                                                // name={`${filtering}.value`}
                                                                name={`conditions[${index}].conditions[${conditionindex}].value`}
                                                                // name="conditions[0]"
                                                                component={CustomInput}
                                                                type="text"
                                                                labelText={''}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{
                                                            paddingRight: "1px",
                                                            paddingLeft: "10px",
                                                            minWidth: "50px",
                                                            whiteSpace: "nowrap"
                                                        }}>
                                                            <Field
                                                                // name={`${filtering}[lastvalue]`}
                                                                name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                                                                component={CustomInput}
                                                                // labelText={`${condition}`}
                                                                formControlProps={{
                                                                    fullWidth: true,
                                                                }}
                                                                // disabled={(disabled.indexOf(`conditions[${index}].conditions[${conditionindex}].lastvalue`)!==-1 || this.state.disableLastvalue.indexOf(`conditions[${index}].conditions[${conditionindex}].lastvalue`) !== -1)}
                                                                disabled={hidden}
                                                                type="text"
                                                            />
                                                        </TableCell> */}
                                                        <TableCell style={{ padding: "0px", width: "50px" }}>
                                                            <Button
                                                                type="button"
                                                                color="info"
                                                                style={{ width: "50px" }}
                                                                // onClick={this.addCondition.bind(this)}
                                                                onClick={() => {
                                                                    this.addOrCondition(index, 'OR')
                                                                }}
                                                            >OR</Button>

                                                        </TableCell>
                                                        <TableCell style={{ padding: "0px", width: "50px" }}>
                                                            <TableActionButton
                                                                actionType="delete"
                                                                onClick={() => {
                                                                    this.delOrCondition(index, conditionindex)
                                                                    // conditions.remove(index)
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {/* <FieldArray
                                                    name={`${filtering}.conditions`}
                                                    component={this.renderOrCondition}
                                                    productConditionIndex={`${index}`}
                                                // validate={[validateSizeMorethanOne]}
                                                /> */}
                                                </TableBody>
                                            </Table>
                                                </>
                                            )
                                        }
                                        
                                            
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </GridContainer>
                    </DialogContent>
                    {/* <DialogActions
                        className={classes.modalFooter}>

                        {readOnly === false &&
                            <Button
                                style={{ marginRight: "10px" }}
                                color="primary"
                                type="submit">
                                Save
                            </Button>
                        }

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: tagConstants.CLOSE_FORM })
                        }}>
                            Cancel
                        </Button>

                    </DialogActions> */}
                    <DialogActions
                        className={classes.modalFooter}>

                        <Button
                            style={{ marginRight: "10px" }}
                            color="primary"
                            type="submit">
                            Save
                            </Button>

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: dataFilteringTemplateConstants.CLOSE_DATAFILTERING_TEMPLATE_EDIT_FORM })
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog >
        )
    }
}

function mapStateToProps(state) {
    const { formImportOpen, formMode, datafiltering, formImportInitialValue, dataInit } = state.dataFilteringTemplate;
    const { DATAFILTERING_TEMPLATE_FORM_EDIT } = state.form
    return {
        formImportOpen, formMode, DATAFILTERING_TEMPLATE_FORM_EDIT, datafiltering, formImportInitialValue, dataInit
    };
}

const connectedComponent = connect(mapStateToProps)(DATAFILTERING_TEMPLATE_FORM_EDIT);

export default reduxForm({
    form: FORM_NAME,
    // validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))

import React from "react";
import PropTypes from "prop-types";

import FormHelperText from "@material-ui/core/FormHelperText";

import defaultImage from "assets/img/image_placeholder.jpg";
import ImageIcon from '@material-ui/icons/Image';
import i18n from 'i18n';
import UploadFileIcon from '@material-ui/icons/Publish';
import { saleProductServices } from "services";

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadedFile: null,
            uploadError: false,
            loading: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.onImageChange = this.onImageChange.bind(this);

    }
    handleClick() {
        this.refs.fileInput.click();
    }

    handleOpenImage(url) {
        window.open(url, "_blank")
    }

    onImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.uploadFile(file)
        }

        reader.readAsDataURL(file)

    }

    async uploadFile(file) {
        const { input: { onChange } } = this.props
        let formData = new FormData();
        formData.append('file', file);
        try {
            this.setState({
                loading: true
            })
            const response = await saleProductServices.uploadFile(formData)
            //console.log(response.data.data)
            onChange(response.data.data)
            this.setState({
                uploadError: false,
                uploadedFile: response.data.data.filePath,
                loading: false
            })

        } catch (error) {
            console.error(error)
            this.setState({
                loading: false,
                uploadError: true
            })
        }
    }

    componentDidUpdate() {
        const { alwayClearImage = false } = this.props
        if (((this.props.imagePreviewUrl === defaultImage) && this.refs.fileInput.value !== null) || alwayClearImage) {
            this.refs.fileInput.value = null;
        }
    }

    render() {
        let {
            labelText,
            input,
            disabled,
            textCenter,
            meta: { touched, error } } = this.props;

        const divStyle = "fileinput " + (textCenter === true ? "text-center" : "")

        const labelClass = {
            fontSize: "14px",
            marginTop: "10px",
            marginBottom: "10px",
            color: "#AAA",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            opacity: "1",
            lineHeight: "1.42857"
        }
        const labelClassError = {
            fontSize: "14px",
            marginTop: "10px",
            marginBottom: "10px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            opacity: "1",
            lineHeight: "1.42857"
        }

        const _imagePreviewUrl = this.state.uploadedFile ? this.state.uploadedFile : input.value?.filePath || input.value || defaultImage;
        const uploadError = this.state.uploadError
        if (_imagePreviewUrl.includes('default')) {
            _imagePreviewUrl = _imagePreviewUrl + "?v=" + Date.now()
        }
        const fileInputKey = input.value ? input.value.name : +new Date();

        return (
            <>
                {(touched && error) ?
                    <div style={labelClassError} className="labelRootError">{labelText}</div> :
                    <div style={labelClass}>{labelText}</div>
                }

                <div className={divStyle} style={{ display: 'inline-flex', 'alignItems': 'center' }}>
                    <input key={fileInputKey} type="file" onChange={this.onImageChange} name={input.name} ref="fileInput" />
                    {_imagePreviewUrl && (
                        <>
                            <div className="banner-img-thumbnail-container">
                                <img src={_imagePreviewUrl ? _imagePreviewUrl : defaultImage} alt="banner"
                                    onClick={() => this.handleOpenImage(_imagePreviewUrl)}
                                    onError={(e) => { e.target.onError = null; e.target.src = defaultImage }}
                                    style={{
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {disabled === false && <UploadFileIcon style={{
                        fontSize: 32, color: uploadError ? 'red' : '#AAA', cursor: 'pointer'
                    }} onClick={() => this.handleClick()}
                        disabled={this.state.loading}
                    />}
                    {this.state.loading && <p>
                        Loading...
                    </p>}
                    <div>

                        {touched && error ?
                            <FormHelperText id={input.id + "-text"} className="labelRootError">
                                {i18n.t(error)}
                            </FormHelperText>
                            : null}
                    </div>
                </div >
            </>
        );
    }
}

ImageUpload.propTypes = {
    avatar: PropTypes.bool,
    addButtonProps: PropTypes.object
};

export default ImageUpload;

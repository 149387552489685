export const expirationConstants = {
    OPEN_HISTORY_POPUP: 'OPEN_HISTORY_POPUP',
    CLOSE_HISTORY_POPUP: 'CLOSE_HISTORY_POPUP',
    SHOW_TABLE_LOADER: 'SHOW_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_TABLE_LOADER',
    GET_EXPIRATION_DATA_SUCCESS: 'GET_EXPIRATION_DATA_SUCCESS',
    CLEAR_EXPIRATION_FILTER: 'CLEAR_EXPIRATION_FILTER',
    SET_FILTER_SEARCH: 'SET_FILTER_SEARCH',
    SET_ROW_PER_PAGE: 'SET_ROW_PER_PAGE',
    GET_HISTORY_EXPIRATION_DATA_SUCCESS: 'GET_HISTORY_EXPIRATION_DATA_SUCCESS',
    SHOW_TABLE_HISTORY_LOADER: 'SHOW_TABLE_HISTORY_LOADER',
    HIDE_TABLE_HISTORY_LOADER: 'HIDE_TABLE_HISTORY_LOADER',
};
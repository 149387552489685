import React from "react";
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import lodash from 'lodash';

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import TableBurnReport from "./TableBurnReport.jsx";

import { reportActions } from 'actions';
import { crmValidator, plugins } from "helpers";

import i18n from 'i18n';

const style = {

}

const FORM_NAME = 'FORM_FILTER_BURN_TRANSACTION';

const form_validate = {

}

class BurnTransactionReport extends React.Component {

    handleSubmit(values) {
        const { dispatch, burnReport } = this.props;
        const { tableFilter } = burnReport
        let _tableFilter = {
            page: tableFilter.page,
            skip: tableFilter.skip,
            limit: tableFilter.limit,
            sortBy: tableFilter.sortBy,
            sortDir: tableFilter.sortDir,
            keyword: "",
            search: {}
        }
        let search = { ...values };
        if (lodash.size(search) > 0) {
            _tableFilter.search = search;
        }
        const resetTable = true
        dispatch(reportActions.getBurnTransactionList(_tableFilter, resetTable));
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch({ type: "CLEAR_BURNREPORT_FILTER" })
        dispatch(reset(FORM_NAME))
    }

    componentDidMount() {
        const { dispatch, burnReport } = this.props;
        const { tableFilter } = burnReport;
        this.getCampaignList()
        dispatch(reportActions.getBurnTransactionList(tableFilter));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: "CLEAR_BURNREPORT_FILTER" })
        dispatch(reset(FORM_NAME))


    }

    getCampaignList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"

        dispatch(reportActions.getBurnCampaignList(reqParams))
    }

    render() {
        const { FORM_FILTER_BURN_TRANSACTION, merchantSetting, handleSubmit, burnReport, package_features } = this.props;
        let startMaxDate = new Date();

        let filterAgeOptions = [...merchantSetting.ageRangeList, {
            "label": "Not specified",
            "value": "unknown"
        }];
        // let ageRangeMode = merchantSetting.ageRangeMember;

        if (lodash.has(FORM_FILTER_BURN_TRANSACTION, 'values.endDate')) {
            startMaxDate = FORM_FILTER_BURN_TRANSACTION.values.endDate;
        }

        let endMinDate;
        if (lodash.has(FORM_FILTER_BURN_TRANSACTION, 'values.startDate')) {
            endMinDate = FORM_FILTER_BURN_TRANSACTION.values.startDate;
        }

        let campaignList = [];
        campaignList.push({
            value: "all",
            label: "All"
        })

        if (lodash.has(burnReport, 'campaignList')) {
            lodash.forEach(burnReport.campaignList, function (value, key) {
                campaignList.push({
                    value: value._id,
                    label: value.name
                })
            })
        }

        return (
            <div>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="startDate"
                                component={CustomDatePicker}
                                type="text"
                                maxDate={startMaxDate}
                                labelText="Start Date"
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="endDate"
                                minDate={endMinDate}
                                maxDate={new Date()}
                                component={CustomDatePicker}
                                type="text"
                                labelText="End Date"
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="transactionId"
                                component={CustomInput}
                                type="text"
                                labelText="Transaction ID"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={3}>
                            <Field
                                name="gender"
                                component={CustomSelect}
                                type="text"
                                labelText={i18n.t("label.gender")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                novalidate
                                // optionsList={Gender}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="memberFirstName"
                                component={CustomInput}
                                type="text"
                                labelText="Firstname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="memberLastName"
                                component={CustomInput}
                                type="text"
                                labelText="Lastname"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="phoneNumber"
                                component={CustomInput}
                                type="text"
                                labelText="Mobile."
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, form_validate.phoneNumber)
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="memberId"
                                component={CustomInput}
                                type="text"
                                labelText="Member ID"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={value => {
                                    return crmValidator.handleKeyPress(
                                        value,
                                        form_validate.phoneNumber
                                    );
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="displayId"
                                component={CustomInput}
                                type="text"
                                labelText="Campaign ID"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="campaignId"
                                component={CustomSelect}
                                type="text"
                                labelText="Campaign"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={campaignList}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="campaignType"
                                component={CustomSelect}
                                type="text"
                                labelText="Condition Type"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    { value: "all", label: "All" },
                                    // { value: "3rdPartyReward", label: "3rd party Reward" }, // [ECRM-1707] :Remove 3  3rd party
                                    { value: "point", label: "Point" },
                                    { value: "every_x_point", label: "Every x point(s)" }
                                    // { value: "PromoCode", label: "Promo Code" },
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="burnType"
                                component={CustomSelect}
                                type="text"
                                labelText="Campaign Type"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    { value: "campaign", label: "Campaign" },
                                    { value: "point_adjustment", label: "Point Adjustment" }
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="code"
                                component={CustomInput}
                                type="text"
                                labelText="Reward Name/Code "
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="rewardType"
                                component={CustomSelect}
                                type="text"
                                labelText="Reward Type"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    { value: "all", label: "All" },
                                    ...plugins.getRewardListByPackageFeature(package_features)
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="status"
                                component={CustomSelect}
                                type="text"
                                labelText="Status"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    { value: "all", label: "All" },
                                    { value: "1", label: "Success" },
                                    { value: "0", label: "Failed" }
                                ]}
                            />
                        </GridItem>
                        {/* <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="memberFullName"
                                component={CustomInput}
                                type="text"
                                labelText="Name"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem> */}
                        {/* <GridItem xs={6} sm={3}>
                            <Field
                                name="filterAge"
                                component={CustomSelect}
                                type="text"
                                labelText="Age"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                novalidate
                                optionsList={filterAgeOptions}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="filterProvince"
                                component={CustomSelect}
                                type="text"
                                labelText="Province"
                                novalidate
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem> */}
                        <GridItem xs={12} sm={12} lg={12}>

                            <Button

                                onClick={this.clearFilter}
                                style={{ marginRight: "10px", marginTop: "25px" }}
                                size="sm"
                                color="white">
                                Clear
                            </Button>
                            <Button
                                type="submit"
                                style={{ marginTop: "25px" }}
                                size="sm"
                                color="primary">
                                Submit
                            </Button>


                        </GridItem>
                    </GridContainer>
                </form>

                <TableBurnReport />

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { FORM_FILTER_BURN_TRANSACTION } = state.form;
    const { burnReport } = state.report;
    const { merchantSetting, package_features } = state.authentication;

    return {
        FORM_FILTER_BURN_TRANSACTION, burnReport, merchantSetting, package_features
    }
}

const connectedComponent = connect(mapStateToProps)(BurnTransactionReport);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent))
import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
//page components
import TableReferenceCodeList from "./TableReferenceCodeList.jsx";
import { ReferenceCodeActions } from 'actions';

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const pageStyle = { ...style, ...sweetAlertStyle };

class ReferenceContainer extends React.Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(ReferenceCodeActions.getActiveCampaignsList());
}

  render() {
    const { formInitialValue, formOpen } = this.props;
    return (
      <div>
          <Card >
            <CardBody>
              <CustomSweetAlert />
              <GridContainer>
                <GridItem xs={12}>
                  <TableReferenceCodeList />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
      </div>
    )
  }

}

function mapStateToProps(state) {
  const { formInitialValue, formOpen } = state.reference_code;
  return {
    formInitialValue, formOpen
  };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(ReferenceContainer));

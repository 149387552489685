import React, { Component } from "react"
import { connect } from "react-redux"
import lodash from "lodash"
import withStyles from "@material-ui/core/styles/withStyles"
import { Field, change } from "redux-form"

import CustomInput from "components/CustomInput/CustomInput.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx"
import InputAdornment from "@material-ui/core/InputAdornment"
import Search from "@material-ui/icons/Search"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { earnrulesConstants } from "../../constants"
import { earnRulesAction, memberActions } from "actions"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  tableHead: {
    backgroundColor: "#eee",
    position: "sticky",
    top: 0,
    padding: "0px 10px",
    zIndex: 10,
  },
  tableCell: {
    padding: "0px 10px",
  },
  tableCellLoader: {
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  tableHover: {
    transitionDuration: "0.3s",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#efefef",
    },
  },
  tableSelectShops: {
    maxHeight: "300px",
    minHeight: "300px",
    overflow: "auto",
    marginBottom: "10px",
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },
  noPaddingTop: {
    "& > div": {
      paddingTop: "0px",
    },
  },
  textUpper: {
    textTransform: "uppercase",
  },
  haveNoData: {
    color: "#777",
    fontStyle: "italic",
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  tableError: {
    border: "solid 2px red",
  },
})

export class ShopSelections extends Component {
  constructor(props) {
    super(props)
    this.onSearchShop = lodash.debounce((searchBox, keyword) => {
      this.searchShopList(searchBox, keyword)
    }, 800)
    this.onGetAllShop = lodash.debounce((shopSelected) => {
      this.getAllShopList(shopSelected)
    }, 800)
  }

  componentDidMount() {
    const { dispatch, formMode, regionSelected } = this.props
    this.getRegionList()
    if (formMode === "edit") {
      for (let regionId of regionSelected) {
        dispatch(change("FORM_EARNRULES", `region-${regionId}`, true))
      }
      this.onGetAllShop(regionSelected)
      dispatch(
        change(
          "FORM_EARNRULES",
          "checkBoxRegionSelected",
          regionSelected
        )
      )
    }
  }

  componentWillUnmount() {
    const { dispatch, regionSelected } = this.props
    dispatch({ type: earnrulesConstants.EARN_RULE_CLEAR_SHOP_LIST })
    for (let regionId of regionSelected) {
      dispatch(change("FORM_EARNRULES", `region-${regionId}`, false))
    }
  }

  componentDidUpdate() {}

  getRegionList() {
    const { dispatch } = this.props
    dispatch(earnRulesAction.getRegionList())
  }

  handleRegionSelect(event) {
    const key = event.target.id
    const IsChecked = event.target.checked
    let data = {
      shopRegionId: lodash.replace(key, "checkbox-region-", ""),
      isSelected: IsChecked,
    }
    this.filterShopListByRegion(data)
  }

  filterShopListByRegion(data) {
    const { regionSelected, dispatch } = this.props
    const { shopRegionId, isSelected } = data
    let _regionSelected = [...regionSelected]
    if (isSelected) {
      _regionSelected = [..._regionSelected, shopRegionId]
    } else {
      _regionSelected = lodash.filter(_regionSelected, (data) => {
        return data !== shopRegionId
      })
    }
    dispatch({
      type: earnrulesConstants.EARN_RULE_SELECTED_REGION,
      data: _regionSelected,
    })
    dispatch(
      change(
        "FORM_EARNRULES",
        "checkBoxRegionSelected",
        _regionSelected
      )
    )
    this.onGetAllShop(_regionSelected)
  }

  getAllShopList(regionSelected) {
    const { dispatch, allShopListSelected } = this.props
    const selectedFilterData = lodash.filter(
      allShopListSelected,
      (shopSelectedData) => {
        return regionSelected.includes(shopSelectedData.shopRegionId)
      }
    )

    const reverseRegion = [...regionSelected]
    lodash.reverse(reverseRegion)
    let params = ""
    for (let regionIndex in reverseRegion) {
      if (+regionIndex + 1 === reverseRegion.length) {
        params = `${params}region=${reverseRegion[regionIndex]}`
      } else {
        params = `${params}region=${reverseRegion[regionIndex]}&`
      }
    }
    dispatch(earnRulesAction.getAllShopList(params, selectedFilterData))
  }

  renderShopList() {
    const { classes, shopList, readOnly } = this.props
    const html = (
      <>
        {shopList &&
          shopList.map((data, index) => {
            const regionName = lodash.get(
              data,
              "shopRegion.shopRegionName",
              "Not Specified"
            )
            return (
              <TableRow
                key={index}
                className={classes.tableHover}
                onClick={() => {
                  if (!readOnly) {
                    this.selectShopItem(data)
                  }
                }}
                id={'shop-'+data._id}
              >
                <TableCell className={classes.tableCell}>
                  {`${data.shopName} (${data.shopId}) `}
                </TableCell>
              </TableRow>
            )
          })}
      </>
    )
    return html
  }

  renderSelectedShopList() {
    const { classes, shopListSelected, readOnly } = this.props
    const html = (
      <>
        {shopListSelected &&
          shopListSelected.map((data, index) => {
            const regionName = lodash.get(
              data,
              "shopRegion.shopRegionName",
              "Not Specified"
            )
            return (
              <TableRow
                key={index}
                className={classes.tableHover}
                onClick={() => {
                  if (!readOnly) {
                    this.unSelectShopItem(data)
                  }
                }}
                id={'shopSelected-'+data._id}
              >
                <TableCell className={classes.tableCell}>
                  {`${data.shopName} (${data.shopId})`}
                </TableCell>
              </TableRow>
            )
          })}
      </>
    )
    return html
  }

  selectShopItem(data) {
    const { dispatch, shopList, allShopList } = this.props
    const { _id } = data
    const filterShop = (shopData, shopId) => {
      return lodash.filter(shopData, (shopListData) => {
        return shopListData._id !== shopId
      })
    }

    const shopListFilter = filterShop(shopList, _id)
    const allShopListFilter = filterShop(allShopList, _id)

    const shopListSelected = lodash.filter(shopList, (data) => {
      return data._id === _id
    })

    const selectData = {
      shopList: shopListFilter,
      shopListSelected: shopListSelected,
      allShopList: allShopListFilter,
    }
    dispatch({
      type: earnrulesConstants.EARN_RULE_SELECT_SHOP,
      data: selectData,
    })
    dispatch(
      change("FORM_EARNRULES", "shopCondition[shopList]", shopListSelected)
    )
  }

  unSelectShopItem(data) {
    const {
      dispatch,
      regionSelected,
      allShopListSelected,
      shopListSelected,
    } = this.props
    const { _id } = data
    const filterShop = (shopData, shopId) => {
      return lodash.filter(shopData, (shopListData) => {
        return shopListData._id !== shopId
      })
    }
    const shopListSelectFilter = filterShop(shopListSelected, _id)
    const allShopListSelectFilter = filterShop(allShopListSelected, _id)

    const shopRegionId = lodash.get(data, "shopRegionId") || "not_specified"
    const isRegionSelected =
      lodash.indexOf(regionSelected, shopRegionId) >= 0 ? true : false
    let shopListUnselected = []
    if (isRegionSelected) {
      shopListUnselected = lodash.filter(shopListSelected, (shopListData) => {
        return shopListData._id === _id
      })
    }

    const selectData = {
      shopList: shopListUnselected,
      shopListSelected: shopListSelectFilter,
      allShopListSelected: allShopListSelectFilter,
    }
    dispatch({
      type: earnrulesConstants.EARN_RULE_UNSELECT_SHOP,
      data: selectData,
    })
    dispatch(
      change(
        "FORM_EARNRULES",
        "shopCondition[shopList]",
        allShopListSelectFilter
      )
    )
  }

  searchShopList(searchBox, keyword) {
    const { dispatch, allShopList, allShopListSelected } = this.props
    keyword = keyword.toLowerCase()
    if (searchBox === "shopList") {
      const shopListResult = allShopList.filter((data) => {
        return data.shopName.toLowerCase().includes(keyword) || data.shopId.toLowerCase().includes(keyword)
      })
      dispatch({
        type: earnrulesConstants.EARN_RULE_SEARCH_SHOP_LIST,
        data: shopListResult,
      })
    }

    if (searchBox === "shopListSelected") {
      const shopListSelectedResult = allShopListSelected.filter((data) => {
        return data.shopName.toLowerCase().includes(keyword) || data.shopId.toLowerCase().includes(keyword)
      })
      dispatch({
        type: earnrulesConstants.EARN_RULE_SEARCH_SHOP_SELECTED,
        data: shopListSelectedResult,
      })
    }
  }

  selectAllShopList(selectMode) {
    const { dispatch, allShopListSelected, allShopList } = this.props
    if (selectMode === "selectAll") {
      dispatch({
        type: earnrulesConstants.EARN_RULE_SELECT_ALL_SHOP_LIST,
        data: allShopList,
      })
      dispatch(change("FORM_EARNRULES", "shopCondition[shopList]", allShopList))
    }

    if (selectMode === "unSelectAll") {
      //  const _shopListSelected =  lodash.filter(shopListSelected, (selectShopData)=>{
      //     return regionSelected.includes(selectShopData.shopRegionId)
      //   })
      dispatch({
        type: earnrulesConstants.EARN_RULE_UNSELECT_ALL_SHOP_LIST,
        data: allShopListSelected,
      })
      dispatch(change("FORM_EARNRULES", "shopCondition[shopList]", []))
    }
  }
  render() {
    const {
      readOnly,
      formMode,
      classes,
      FORM_EARNRULES,
      regionList,
      shopList,
      shopListSelected,
    } = this.props

    const _regionList = [
      ...regionList,
      { _id: "not_specified", shopRegionName: "Not Specified" },
    ]
    return (
      <div style={{ zIndex : "0" , position : "relative"}}>
        <Field
          id="purchase-amount-check-box-regionSelected"
          name="checkBoxRegionSelected"
          component="input"
          type="hidden"
        />
        <Field
          id="purchase-amount-shop-list"
          name="shopCondition[shopList]"
          component="input"
          type="hidden"
        />
        <GridContainer style={{ zIndex : "0" , position : "relative"}}>
          <GridItem xs={12} sm={12} md={12} style={{ color: "#3c4858" }}>
            <b>Participated Shop in Campaign</b>
          </GridItem>
        </GridContainer>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            Please Select Regions
          </GridItem>
        </GridContainer>

        <GridContainer style={{ zIndex : "0" , position : "relative"}}> 
          {_regionList &&
            _regionList.map((data, index) => {
              return (
                <GridItem xs={3} sm={3} md={3} key={index}>
                  <Field
                    id={"checkbox-region-" + data._id}
                    name={"region-" + data._id}
                    notLabel={true}
                    component={CustomCheckbox}
                    disabled={readOnly}
                    labelText={data.shopRegionName}
                    onChange={(event) => {
                      this.handleRegionSelect(event)
                    }}
                  />
                </GridItem>
              )
            })}
        </GridContainer>
        <GridContainer style={{ marginTop: 20 }}>
          <GridItem xs={12} sm={12} md={12}>
            Add Shop for this Earn Rule By Selection to the right hand side
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              placeholder="Search by shop name"
              formControlProps={{
                fullWidth: true,
              }}
              input={{
                onChange: (e) => {
                  this.onSearchShop("shopList", e.currentTarget.value)
                },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Search style={{ color: "#ccc" }} />
                </InputAdornment>
              }
              name="searchProduct"
            />
            <div className={classes.tableSelectShops}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      <Button
                        onClick={() => {
                          this.selectAllShopList("selectAll")
                        }}
                        fullWidth={true}
                      >
                        <ArrowForwardIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ border: "solid 1px #eee" }}>
                  {this.renderShopList()}
                </TableBody>
              </Table>
            </div>
            <Typography variant="body1" display="block">
              Showing All {shopList.length}
            </Typography>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              placeholder="Search by shop name"
              formControlProps={{
                fullWidth: true,
              }}
              input={{
                onChange: (e) => {
                  this.onSearchShop("shopListSelected", e.currentTarget.value)
                },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Search style={{ color: "#ccc" }} />
                </InputAdornment>
              }
              name="searchProduct"
            />
            <div className={classes.tableSelectShops}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      <Button
                        onClick={() => {
                          this.selectAllShopList("unSelectAll")
                        }}
                        fullWidth={true}
                      >
                        <ArrowBackIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ border: "solid 1px #eee" }}>
                  {this.renderSelectedShopList()}
                </TableBody>
              </Table>
            </div>
            <Typography variant="body1" display="block">
              Showing All {shopListSelected.length}
            </Typography>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    formOpen,
    formMode,
    regionList,
    shopList,
    allShopList,
    shopListSelected,
    allShopListSelected,
    regionSelected,
  } = state.earnrules
  const { FORM_EARNRULES } = state.form
  return {
    formOpen,
    formMode,
    regionList,
    shopList,
    allShopList,
    shopListSelected,
    regionSelected,
    allShopListSelected,
    FORM_EARNRULES,
  }
}
const connectShopSelections = connect(mapStateToProps)(ShopSelections)
export default withStyles(styles)(connectShopSelections)

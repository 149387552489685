import { productConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";

const initialState = {
    formOpen: false,
    formMode: 'add',
    onEarnrule: false ,
    formInitialValue: {},
    tableReset: false,
    tableReload: false,
    productImagePreview: defaultImage,
    campaignData: [],
    tableDatas: {
        products: [],
        total: 0
    },
    tableKey: 0,
    tableLoader: false,
    categoryList:[]
};

export function product(state = initialState, action) {
    switch (action.type) {
        case productConstants.CLEAR_PAGE_STATE:
            return initialState;

        case productConstants.OPEN_ADD_PRODUCT:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.data,
                productImagePreview: defaultImage,
                onEarnrule : false 
            };
        case productConstants.OPEN_EDIT_PRODUCT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                productImagePreview: action.productImagePreview,
                onEarnrule: action.onEarnrule
            };
        case productConstants.CLOSE_FORM_PRODUCT:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                    productImage: ""
                },
                productImagePreview: defaultImage
            }
        case productConstants.ADD_NEW_PRODUCT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            }
        case productConstants.EDIT_PRODUCT_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case productConstants.TABLE_GET_LIST_PRODUCTS:
            return {
                ...state,
                tableDatas: action.tmp,
                tableReload: false,
                tableReset: false,
            }
        case productConstants.TABLE_RELOAD_SUCCESS:
        case productConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case productConstants.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case productConstants.SET_FORM_PREVIEW_IMAGE:
            console.log("action :",action);
            return {
                ...state,
                productImagePreview: action.productImagePreview === "default" ? defaultImage : action.productImagePreview
            }
        case productConstants.GET_CAMPAIGN_DATA_SUCCESS:
            return {
                ...state,
                campaignData: action.data
            }
        case productConstants.SHOW_TABLE_LOADER:
            return {
                ...state,
                tableLoader: true
            }
        case productConstants.HIDE_TABLE_LOADER:
            return {
                ...state,
                tableLoader: false
            }
        case productConstants.GET_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categoryList: action.data
            }
        default:
            return state
    }
}
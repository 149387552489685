import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";

import MUIDataTable from "mui-datatables";
import lodash from 'lodash';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";

import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import { tagConstants } from '../../constants';

const CustomMTablestyles = {
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MUIDataTableHeadCell: {
            fixedHeader: {
                "&:nth-child(1)": {
                    width: "50%"
                },
            }
        },
        MUIDataTableToolbar: {
            titleText: {
                textAlign: "left"
            }
        }
    }
}
const THEME = createMuiTheme(CustomMTablestyles);

class SWImportResult extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showTableFailed: false,
            tableData: []
        }
    }

    closeDialog() {
        const { dispatch } = this.props;
        dispatch({ type: tagConstants.HIDE_TAG_IMPORT_MEMBER_RESULT });
    }

    initTableData() {
        const { importResult } = this.props;
        let duplicate = 0;
        let notfound = 0;
        if(importResult.duplicate){
            duplicate = parseInt(importResult.duplicate)
        }
        if(importResult.notFound){
            notfound = parseInt(importResult.notFound)
        }

        if((duplicate + notfound) > 0){
            let tableData = []
            lodash.forEach(importResult.notFoundList, (value) => {
                tableData.push({
                    "Phone": value,
                    "Status": "Not Found"
                })
            })

            lodash.forEach(importResult.duplicateList, (value) => {
                tableData.push({
                    "Phone": value,
                    "Status": "Duplicated"
                })
            })

            this.setState({
                showTableFailed: true,
                tableData: tableData
            })

        }else{
            this.setState({
                showTableFailed: false,
                tableData: []
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.showImportResult === false && this.props.showImportResult === true){
            this.initTableData()
        }
    }

    render() {
        const { showImportResult, classes, importResult } = this.props;
        const { showTableFailed, tableData } = this.state;
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={showImportResult}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-result-import-member-tag"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>Result Add Tag by Uploading Member List</b>
                    </h4>
                    
                </DialogTitle>
                    <DialogContent
                        id="tag-result-import-member-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0", paddingBottom: "10px" }}
                    >
                        <table style={{width: "100%", fontSize: "14.5px", marginBottom: "20px" }}>
                            <thead>
                                <tr>
                                    <th style={{width: "25%"}}><span>Total Import</span></th>
                                    <th style={{width: "25%"}}><span style={{color: "#4caf50"}}>Success</span></th>
                                    <th style={{width: "25%"}}><span style={{color: "#f44336"}}>Not Found</span></th>
                                    <th style={{width: "25%"}}><span style={{color: "#ff9800"}}>Duplicated</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{importResult.total}</td>
                                    <td>{importResult.success}</td>
                                    <td>{importResult.notFound}</td>
                                    <td>{importResult.duplicate}</td>
                                </tr>
                            </tbody>
                        </table>
                        { showTableFailed &&
                        <>
                        <hr/>
                        <MuiThemeProvider theme={THEME}>
                        <MUIDataTable
                            // key={tableKey}
                            title={"Failed List"}
                            data={tableData}
                            columns={[
                                {
                                    name: "Phone",
                                    label: "Phone",
                                    options: {
                                        filter: true,
                                        sort: false,
                                    }
                                },
                                {
                                    name: "Status",
                                    label: "Status",
                                    options: {
                                        filter: true,
                                        sort: false,
                                    }
                                }
                            ]}
                            options={{
                                selectableRows: "none",
                                viewColumns: false,
                                print: false,
                                filter: false,
                                pagination: true,
                                rowsPerPage: 5,
                                rowsPerPageOptions: [5,10,20,100]
                            }}
                        />
                        </MuiThemeProvider>
                        </>
                        }
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                            <Button
                                style={{ marginRight: "10px", minWidth: "100px" }}
                                color="primary"
                                onClick={()=>{
                                    this.closeDialog()
                                }}
                                type="submit">
                                    OK
                            </Button>
                    </DialogActions>

            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { showImportResult, importResult } = state.tag;
    return {
        showImportResult, importResult
    };
}
const connectedComponent = connect(mapStateToProps)(SWImportResult);

export default withStyles(sweetAlertStyle)(connectedComponent);
import React from "react"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx"

//page components
import TableShopList from "./TableShopList"

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
}

function ShopContainer({ ...props }) {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <TableShopList />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withStyles(style)(ShopContainer)

import { dataFilteringResultServices } from '../services';
import { dataFilteringResultConstants } from '../constants';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const dataFilteringResultAction = {
   getCampaignList,
   getDataFilteringResultList,
   getDataFilteringConditionById,
   getDataFilteringFromDataPlatformByResultId,
   assignCampaign,
   unAssignCampaign,
   assignTag,
   unAssignTag,
   deleteDataFilteringResult,
   getDataFilteringFromDataPlatformByFilename,
};

function getCampaignList(params) {
   return dispatch => {
      dataFilteringResultServices.getCampaignsList(params)
           .then(
               response => {
                   if (response.status) {
                       if (lodash.has(response, 'data')) {
                           dispatch(success(response.data));
                       }
                   } else {
                       if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                   }
               },
               error => {
                   customAlert(dispatch, 'error', i18n.t(defaultFailedText))
               }
           ).then(() => {
               // loader(dispatch, false)
           })
   };

   function success(data) {
       return { type: dataFilteringResultConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS, data }
   }
}

function getDataFilteringResultList(params) {
   return dispatch => {
      dispatch({ type: dataFilteringResultConstants.SHOW_LOADER })
      const defaultData = {
         data: [],
         total: 0
      }
      dataFilteringResultServices.getDataFilteringResultList(params)
         .then(
            response => {
               console.log("response.data : ", response.data);
               try {
                  if (response.status) {
                     dispatch(success(response.data));
                  } else {
                     dispatch(success(defaultData));
                  }
               } catch (e) {
                  dispatch(success(defaultData));
               }
            },
            error => {
               dispatch(success(defaultData));
            }
         ).then(() => {
            dispatch({ type: dataFilteringResultConstants.HIDE_LOADER })
         })
   };

   function success(tmp) { return { type: dataFilteringResultConstants.GET_DATAFILTERING_RESULT_SUCCESS, tmp: tmp } }
}

function getDataFilteringConditionById(params) {
   return dispatch => {
       loader(dispatch, true)
       console.log('getDataFilteringConditionById params',params)
       dataFilteringResultServices.getDataFilteringConditionById(params)
           .then(
               response => {
                   if (response.status) {
                       dispatch(success(response.data));
                   } else {
                       if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                   }
               },
               error => {
                   customAlert(dispatch, 'error', i18n.t(defaultFailedText))
               }
           ).then(() => {
               loader(dispatch, false)
           })
   };

   function success(data) { return { type: dataFilteringResultConstants.OPEN_FORM_CONDITION, data: data }}
}

function getDataFilteringFromDataPlatformByResultId(params) {
    return dispatch => {
        loader(dispatch, true)
        dataFilteringResultServices.getDataFilteringFromDataPlatformByResultId(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
 
    function success(data) { return { type: dataFilteringResultConstants.OPEN_FORM_UNASSIGN, data: data }}
 }

 function assignCampaign(params) {
    return dispatch => {
        loader(dispatch, true)

        dataFilteringResultServices.assignCampaign(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                        customAlert(dispatch, 'success', i18n.t('warning_messages.datafiltering_campaign_assigned'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: dataFilteringResultConstants.ASSIGN_CAMPAIGN_SUCCESS, tmp } }
 }

 function unAssignCampaign(params) {
    return dispatch => {
        loader(dispatch, true)

        dataFilteringResultServices.unAssignCampaign(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                        customAlert(dispatch, 'success', i18n.t('warning_messages.datafiltering_campaign_unassigned'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: dataFilteringResultConstants.UNASSIGN_CAMPAIGN_SUCCESS, tmp } }
 }

 function assignTag(params) {
    return dispatch => {
        loader(dispatch, true)

        dataFilteringResultServices.assignTag(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                        customAlert(dispatch, 'success', i18n.t('warning_messages.datafiltering_tag_assigned'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: dataFilteringResultConstants.ASSIGN_TAG_SUCCESS, tmp } }
 }

 function unAssignTag(params) {
    return dispatch => {
        loader(dispatch, true)

        dataFilteringResultServices.unAssignTag(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                        customAlert(dispatch, 'success', i18n.t('warning_messages.datafiltering_tag_unassigned'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: dataFilteringResultConstants.UNASSIGN_CAMPAIGN_SUCCESS, tmp } }
 }

 function deleteDataFilteringResult(params) {
    return dispatch => {
       loader(dispatch, true)
       dataFilteringResultServices.deleteDataFilteringResult(params)
          .then(
             response => {
                if (response.status) {
                   dispatch({
                      type: dataFilteringResultConstants.DELETE_DATAFILTERRING_RESULT_SUCCESS
                   });
                   customAlert(dispatch, 'success', i18n.t("warning_messages.dataFilteringResult_was_deleted"))
                } else {
                   if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                }
             },
             error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
             }
          ).then(() => {
             loader(dispatch, false)
          })
    };
 }

 function getDataFilteringFromDataPlatformByFilename(params) {
    return dispatch => {
        loader(dispatch, true)
        dataFilteringResultServices.getDataFilteringFromDataPlatformByFilename(params)
            .then(
                response => {
                    if (response.status) {
                        // console.log('response.data :',response.data)
                        dispatch(success(response.data[0]));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
 
    function success(data) { return { type: dataFilteringResultConstants.OPEN_FORM_VISUALIZATION, data: data }}
 }

import React from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import lodash from 'lodash';
import i18n from 'i18n';
import { crmValidator } from 'helpers';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import TableWinnerApproveList from "views/WinnerApprove/TableWinnerApproveList.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { winnerApproveConstants, swalConstants } from '../../constants';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { winnerApproveActions } from 'actions';

import { plugins } from 'helpers';

import moment from 'moment'

const style = {
  reportHeaderContainer: {
    margin: "0px",
    width: "calc(100% - 10px)",
    padding: "0px",
  },
  btnClearFilter: {
    marginRight: "10px",
    marginTop: "25px"
  },
  textCenter: {
    textAlign: "center"
  },
  mt20: {
    marginTop: "20px"
  }
};

const pageStyle = { ...style };
const FORM_NAME = "formFilterWinnerApprove";
const form_validate = {
  "periodId": {
    rules: {
      required: true
    },
    messages: {
      required: 'กรุณาเลือก'
    }
  },
  "rewardName": {
    rules: {
      required: true
    },
    messages: {
      required: 'กรุณาเลือก'
    }
  },
  "winnerType": {
    rules: {
      required: true
    },
    messages: {
      required: 'กรุณากรอก'
    }
  },
}

const validate = (values) => {
  const errors = crmValidator.validateByRulesList(form_validate, values);
  return errors
}

const moduleName = 'luckyDraw[luckydrawWinnerApprove]'


class WinnerApproveReport extends React.Component {

  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      periodList: [],
      winnerTypeList: [],
      rewardNameList: [],
      cnt: 0,
      checkAnnounceDate: false,
      checkReward: false,
      checkType: false,
    };
  }

  getAnnounceDateList() {
    const { dispatch } = this.props;
    dispatch(winnerApproveActions.getAnnounceDateList())
  }

  getRewardList() {
    const { dispatch } = this.props;
    dispatch(winnerApproveActions.getRewardList())
  }

  componentDidMount() {
    this.getAnnounceDateList()
    this.getRewardList()
  }

  handleApproveWinner() {
    const { dispatch, formFilterWinnerApprove } = this.props;
    let reqParams = {
      "periodId": formFilterWinnerApprove.values.periodId,
      // "rewardName": formFilterWinnerApprove.values.rewardName,
      "winnerType": formFilterWinnerApprove.values.winnerType
    }

    // let reqParamsUpload = {
    //   "periodId": formFilterWinnerApprove.values.periodId,
    // }

    dispatch({
      type: swalConstants.SHOW,
      data: {
        type: "warning",
        title: i18n.t("warning_messages.are_you_sure_approve_winner"),
        message: "",
        onConfirm: function () {
          dispatch({ type: swalConstants.HIDE })
          dispatch(winnerApproveActions.approveWinner(reqParams))
          // dispatch(winnerApproveActions.approveWinnerUpload(reqParamsUpload))
        },
        onCancel: function () {
          dispatch({ type: swalConstants.HIDE })
        },
        showCancel: true,
        confirmBtnText: i18n.t("approve"),
        cancelBtnText: i18n.t("cancel"),
      },
    })
  }

  toggleChangeAnnounceDate = () => {
    this.setState({
      checkAnnounceDate: true,
    });
  }

  toggleChangeReward = () => {
    this.setState({
      checkReward: true,
    });
  }

  toggleChangeType = () => {
    this.setState({
      checkType: true,
    });
  }

  handleRejectWinner() {
    const { dispatch, formFilterWinnerApprove } = this.props;
    let reqParams = {
      "periodId": formFilterWinnerApprove.values.periodId,
      "rewardName": formFilterWinnerApprove.values.rewardName,
      "winnerType": formFilterWinnerApprove.values.winnerType
    }

    dispatch({
      type: swalConstants.SHOW,
      data: {
        type: "warning",
        title: i18n.t("warning_messages.are_you_sure_reject_winner"),
        message: "",
        onConfirm: function () {
          dispatch({ type: swalConstants.HIDE })
          dispatch(winnerApproveActions.rejectWinner(reqParams))
        },
        onCancel: function () {
          dispatch({ type: swalConstants.HIDE })
        },
        showCancel: true,
        confirmBtnText: i18n.t("reject"),
        cancelBtnText: i18n.t("cancel"),
      },
    })
  }

  handleSubmit(values) {
    const { dispatch, formFilterWinnerApprove } = this.props;
    let tableFilter = {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "createdAt",
      sortDir: "desc",
      keyword: "",
      search: {}
    }

    tableFilter.search = {
      "status": "waiting for approval",
      "periodId": (formFilterWinnerApprove.values === undefined || formFilterWinnerApprove.values.periodId === undefined) ? "" : formFilterWinnerApprove.values.periodId,
      // "rewardName": (formFilterWinnerApprove.values === undefined || formFilterWinnerApprove.values.rewardName === undefined) ? "" : formFilterWinnerApprove.values.rewardName,
      "winnerType": (formFilterWinnerApprove.values === undefined || formFilterWinnerApprove.values.winnerType === undefined) ? "" : formFilterWinnerApprove.values.winnerType,
    }
    const resetTable = true
    dispatch(winnerApproveActions.getTableDatas(tableFilter, resetTable));
  }

  render() {
    const { classes, winnerApproveList, handleSubmit, tableDatas } = this.props;

    let checkButtonApprove = true;
    let checkButtonReject = true;
    // if (this.state.checkAnnounceDate === true && this.state.checkReward === true && this.state.checkType === true) {
    //   checkButtonApprove = false
    //   checkButtonReject = false
    // }

    if (tableDatas != '') {
      checkButtonApprove = false
      checkButtonReject = false
    }

    let today = new Date()

    

    let announceDateAllList = [];
    if (lodash.has(winnerApproveList, 'announceDateList')) {
      lodash.forEach(winnerApproveList.announceDateList, function (value, key) {

        if(moment(value.announceDate) > moment(today).subtract(14, 'days')){
          announceDateAllList.push({
            value: value.periodId,
            label: plugins.datetimeFormat(value.announceDate, "fulldatetime") + ' / ' + value.rewardLuckyDraw.name_en,
            id: value.periodId,
          })
        }

      })
    }

    // if (lodash.has(winnerApproveList, 'announceDateList')) {
    //   lodash.forEach(winnerApproveList.announceDateList, function (value, key) {
    //     announceDateAllList.push({
    //       value: value.periodId,
    //       label: plugins.datetimeFormat(value.announceDate, "fulldatetime") + ' / ' + value.rewardLuckyDraw.name_en,
    //       id: value.periodId,
    //     })
    //   })
    // }

    let rewardAllList = [];
    if (lodash.has(winnerApproveList, 'rewardList')) {
      lodash.forEach(winnerApproveList.rewardList, function (value, key) {
        rewardAllList.push({
          value: value.name_en,
          label: value.name_en,
          id: value.name_en,
        })
      })
    }

    return (
      <>
        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>

          <GridContainer className={classes.reportHeaderContainer}>
            <GridItem xs={4} sm={4}>
              <h6><b>Announce Date*</b></h6>
            </GridItem>
            <GridItem xs={4} sm={4}>
              <h6><b>Winner Type*</b></h6>
            </GridItem>
            {/* <GridItem xs={4} sm={4}>
              <h6><b>Reward Name (EN)*</b></h6>
            </GridItem> */}
          </GridContainer>

          <GridContainer className={classes.reportHeaderContainer}>
            <GridItem xs={4} sm={4}>
              <Field
                name="periodId"
                component={CustomSelect}
                type="text"
                formControlProps={{
                  fullWidth: true,
                }}
                optionsList={announceDateAllList}
                onChange={this.toggleChangeAnnounceDate}
              />
            </GridItem>

            <GridItem xs={4} sm={4}>
              <Field
                name="winnerType"
                component={CustomSelect}
                type="text"
                formControlProps={{
                  fullWidth: true
                }}
                optionsList={[
                  { value: "Winner", label: "Winner" },
                  { value: "Reserve", label: "Reserve" }
                ]}

                onChange={this.toggleChangeType}
              />
            </GridItem>

            {/* <GridItem xs={4} sm={4}>
              <Field
                name="rewardName"
                component={CustomSelect}
                type="text"
                formControlProps={{
                  fullWidth: true,
                }}
                optionsList={rewardAllList}
                onChange={this.toggleChangeReward}
              />
            </GridItem> */}
          </GridContainer>

          <GridContainer className={classes.reportHeaderContainer}>
            <GridItem xs={12} sm={4}>
              <Button
                type="submit"
                style={{ marginRight: "10px" }}
                size="sm"
                color="primary"
                id='btn_submit'>
                Search
                </Button>
            </GridItem>
          </GridContainer>

          <GridContainer className={classes.reportHeaderContainer}>
            <GridItem xs={12} sm={4}>
              {plugins.isHasPermission(moduleName, 'update') &&
                <Button
                  type="button"
                  style={{ marginRight: "10px" }}
                  size="sm"
                  color="success"
                  id='btn_winner_approve'
                  onClick={this.handleApproveWinner.bind(this)}
                  disabled={checkButtonApprove}
                >
                  Approve
                  </Button>
              }

              {plugins.isHasPermission(moduleName, 'delete') &&
                <Button
                  type="button"
                  style={{ marginRight: "10px" }}
                  size="sm"
                  color="danger"
                  id='btn_winner_reject'
                  onClick={this.handleRejectWinner.bind(this)}
                  disabled={checkButtonReject}
                >
                  Reject
                  </Button>
              }
            </GridItem>
          </GridContainer>
        </form>

        <GridContainer className={classes.mt20}>
          <GridItem xs={12}>
            <TableWinnerApproveList showAction={false} showSearch={false} />
          </GridItem>
        </GridContainer>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { sweetAlert } = state.sweetAlert;
  const { permission, superAdmin, merchantSetting, visiblePersonalData } = state.authentication;
  const filterValues = getFormValues(FORM_NAME)(state)
  const { formFilterWinnerApprove } = state.form;
  const { tableDatas, tableTotal, winnerApproveList, submitForm, winnerApproveReport } = state.winner_approve;
  return {
    sweetAlert, permission, superAdmin, filterValues, tableTotal, winnerApproveList, tableDatas, formFilterWinnerApprove, submitForm, winnerApproveReport, merchantSetting, visiblePersonalData
  };
}
const connectFilterWinnerApproveForm = connect(mapStateToProps)(WinnerApproveReport);

export default reduxForm({
  form: FORM_NAME,
  validate: validate,
  enableReinitialize: true,
  onChange: (values, dispatch, props, previousValues) => {
    if (values !== previousValues) {
      dispatch({ type: winnerApproveConstants.TABLE_RESET });
    }
  },
  onSubmitFail: (errors) => {
    // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
  }
})(withStyles(pageStyle)(connectFilterWinnerApproveForm))

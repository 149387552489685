import { brandConstants } from '../constants';
import lodash from 'lodash';
import defaultImage from "assets/img/image_placeholder.jpg";

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableData: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    brandImagePreview: defaultImage,
    readOnly: false,
    tableLoader: false,
}

export function brand(state = initialState, action) {
    switch (action.type) {
        case brandConstants.CLEAR_PAGE_STATE:
            return initialState
        case brandConstants.OPEN_FORM_ADD_BRAND:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
                brandImagePreview : defaultImage,
            };
        case brandConstants.OPEN_FORM_EDIT_BRAND:
            return {
                ...state,
                formOpen: true,
                readOnly : false,
                formMode: 'edit',
                formInitialValue: action.tmp,
                brandImagePreview : action.tmp.filePath ? action.tmp.filePath : defaultImage
            };
        case brandConstants.CLOSE_FORM_BRAND:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                brandImagePreview: defaultImage
            }
        case brandConstants.SET_FORM_PREVIEW_IMAGE:
            return {
                ...state,
                brandImagePreview: action.brandPreview === "default" ? defaultImage : action.brandPreview
            }
        case brandConstants.GET_BRANDS_DATA_SUCCESS: {
            return {
                ...state,
                tableData: action.data,
                tableReload: false,
                tableReset: false,
            }
        }
        case brandConstants.SHOW_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: true
            }
        }
        case brandConstants.HIDE_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: false
            }
        }
        case brandConstants.ADD_BRAND_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            }
        case brandConstants.EDIT_BRAND_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: true,
                tableReset: false,
            }
        }
        case brandConstants.DELETE_BRAND_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case brandConstants.TABLE_RELOAD_SUCCESS: {
            return {
                ...state,
                tableReload: false,
                tableReset: false
            }
        }  
        default:
            return state;
    }
}
export const form_validate = {
    "contentName": {
      rules: {
        required: true
      },
      messages: {
        required: "Please insert Content name"
      },
      alert: "Please insert Content name"
    },
    "status": {
      rules: {
        required: true
      },
      messages: {
        required: 'Please enter Status'
      },
      alert: "Please enter Status"
    },
    "contentType": {
      rules: {
        required: true
      },
      messages: {
        required: 'Please enter Content type'
      },
      alert: "Please enter Content type"
    },
    "campaignId": {
      rules: {
        required: true
      },
      messages: {
        required: 'Please select Campaign'
      },
      alert: "Please select Campaign"
    },
    "priority": {
      rules: {
        number: true,
        min: 1
      },
      messages: {
        number: 'Priority allows only number',
        min: 'Priority should be greater than 0'
      },
    },
    
    // "versionDate": {
    //   rules: {
    //     required: true
    //   },
    //   messages: {
    //     required: 'Please enter Version Date'
    //   },
    //   alert: "Please enter Version Date"
    // },
    // "visibleDate": {
    //   rules: {
    //     required: true
    //   },
    //   messages: {
    //     required: 'Please enter Visible Date'
    //   },
    //   alert: "Please enter Visible Date"
    // },
    // "content": {
    //   rules: {
    //     required: true
    //   },
    //   messages: {
    //     required: 'Please enter Content'
    //   },
    //   alert: "Please enter Content"
    // },
  }

export const validateContentName = (values, allValues) => {
  if (values === undefined || values === '') {
     return "Please enter Content name"
  }
}

export const validateContent = (values, allValues) => { 
  if (values === undefined || values === '' || values === '&nbsp;') {
     return "Please insert Content"
  }
}
export const validateCampaign = (values, allValues) => { 
  if (values === undefined || values === '') {
     return "Please select Campaign"
  }
}
const validMimeType = "image/jpeg";
export const validateImageFormat = imageFile => {
  if (imageFile && !validMimeType.includes(imageFile.type)) {
     return 'Only image file type is (jpeg file types) are accepted';
  }
}

export const validateImageSize = imageFile => {
  if (imageFile) {
    if (imageFile.size > (1048576 * 2)) {
      return "Please select file less than 2MB"
    }
  }
}

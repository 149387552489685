import React from 'react';
import { Field, reduxForm, arrayPush, change } from 'redux-form';
import TableCell from '@material-ui/core/TableCell';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import { crmValidator, normalize } from 'helpers';

function InputValue({index, conditionindex, hidden, fieldtype, validateRequired, compareOperator, dataValue  }) {
    
    if(fieldtype == 'int'){
        return (
            <>
                <TableCell
                    style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].value`}
                        component={CustomInput}
                        type="text"
                        labelText={''}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        normalize={(value) => {
                            if (value) {
                                if (value === 0 || value === "0") {
                                    return ""
                                } else {
                                    return normalize.onlyNumber(value)
                                }
                            }else {
                                return ""
                            }
                        }}
                        validate={[validateRequired]}
                    />
                </TableCell>
                <TableCell style={{
                    paddingRight: "1px",
                    paddingLeft: "10px",
                    minWidth: "50px",
                    whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                        component={CustomInput}
                        type="text"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        disabled={hidden}
                        normalize={(value) => {
                            if (value) {
                                if (value === 0 || value === "0") {
                                    return ""
                                } else {
                                    return normalize.onlyNumber(value)
                                }
                            }else {
                                return ""
                            }
                        }}
                    />
                </TableCell>
            </>
        )
    }else if(fieldtype == 'date' || fieldtype == 'date/time' ){

        //check in statue if(date && between)
        //check date
        //return default datetime

        if(fieldtype == 'date' && compareOperator == 'between'){
            return (
                <>
                    <TableCell
                        style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap" }}>
                        <Field
                            name={`conditions[${index}].conditions[${conditionindex}].value`}
                            component={CustomDatePicker}
                            type="text"
                            labelText={""}
                            dateFormat="DD-MMM-YY"
                            // timeFormat="HH:mm"
                            formControlProps={{
                                fullWidth: true
                            }}
                            validate={[validateRequired]}
                        />
                    </TableCell>
                    <TableCell style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap" }}>
                        <Field
                            name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                            component={CustomDatePicker}
                            type="text"
                            labelText={""}
                            dateFormat="DD-MMM-YY"
                            // timeFormat="HH:mm"
                            formControlProps={{
                                fullWidth: true
                            }}
                            validate={[validateRequired]}
                        />
                    </TableCell>
                </>
            )
        }

        if(fieldtype == 'date/time' && compareOperator == 'between'){
            return (
                <>
                    <TableCell
                        style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap" }}>
                        <Field
                            name={`conditions[${index}].conditions[${conditionindex}].value`}
                            component={CustomDatePicker}
                            type="text"
                            labelText={""}
                            dateFormat="DD-MMM-YY"
                            timeFormat="HH:mm"
                            formControlProps={{
                                fullWidth: true
                            }}
                            validate={[validateRequired]}
                        />
                    </TableCell>
                    <TableCell style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap" }}>
                        <Field
                            name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                            component={CustomDatePicker}
                            type="text"
                            labelText={""}
                            dateFormat="DD-MMM-YY"
                            timeFormat="HH:mm"
                            formControlProps={{
                                fullWidth: true
                            }}
                            validate={[validateRequired]}
                        />
                    </TableCell>
                </>
            )
        }

        if(compareOperator == 'with_in_the_last' ){
            return (
                <>
                    <TableCell
                        align="center"
                        style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "100px",
                            whiteSpace: "nowrap" }}>
                                
                        <Field
                            name={`conditions[${index}].conditions[${conditionindex}].value`}
                            component={CustomSelect}
                            type="text"
                            labelText={``}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            outlined
                            optionsList={[
                                {
                                    value: "day",
                                    label: "Day"
                                },
                                {
                                    value: "month",
                                    label: "Month"
                                },
                                {
                                    value: "year",
                                    label: "Year"
                                }
                            ]}
                            validate={[validateRequired]}
                        />
                    </TableCell>
                    <TableCell style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap" }}>
                        <Field
                            name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                            component={CustomInput}
                            labelText=""
                            formControlProps={{
                                fullWidth: true,
                            }}
                            type="text"
                            normalize={(value) => {
                                if (value) {
                                    if (value === 0 || value === "0") {
                                        return ""
                                    } else {
                                        return normalize.onlyNumber(value)
                                    }
                                }else {
                                    return ""
                                }
                            }}
                            validate={[validateRequired]}
                            // disabled={hidden}
                        />
                    </TableCell>
                </>
            )
        }

        if(fieldtype == 'date'){
            return (
                <>
                    <TableCell
                        style={{
                            paddingRight: "1px",
                            paddingLeft: "10px",
                            minWidth: "50px",
                            whiteSpace: "nowrap" }}>
                        <Field
                            name={`conditions[${index}].conditions[${conditionindex}].value`}
                            component={CustomDatePicker}
                            type="text"
                            labelText={""}
                            dateFormat="DD-MMM-YY"
                            // timeFormat="HH:mm"
                            formControlProps={{
                                fullWidth: true
                            }}
                            validate={[validateRequired]}
                        />
                    </TableCell>
                    <TableCell style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap" }}>
                        <Field
                            name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                            component={CustomInput}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            disabled={hidden}
                            type="text"
                        />
                    </TableCell>
                </>
            )
        }
        return (
            <>
                <TableCell
                    style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].value`}
                        component={CustomDatePicker}
                        type="text"
                        labelText={""}
                        dateFormat="DD-MMM-YY"
                        timeFormat="HH:mm"
                        formControlProps={{
                            fullWidth: true
                        }}
                        validate={[validateRequired]}
                    />
                </TableCell>
                <TableCell style={{
                    paddingRight: "1px",
                    paddingLeft: "10px",
                    minWidth: "50px",
                    whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                        component={CustomDatePicker}
                        type="text"
                        labelText={""}
                        dateFormat="DD-MMM-YY"
                        timeFormat="HH:mm"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        disabled={hidden}
                        type="text"
                    />
                </TableCell>
            </>
        )
    }else if(fieldtype == 'boolean'){
        return (
            <>
                <TableCell
                    align="center"
                    style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "160px",
                        whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].value`}
                        component={CustomSelect}
                        type="text"
                        labelText={''}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        outlined
                        optionsList={[
                            {
                                value: "false",
                                label: "false"
                            },
                            {
                                value: "true",
                                label: "true"
                            }
                        ]}
                        validate={[validateRequired]}
                    />
                </TableCell>
                <TableCell style={{
                    paddingRight: "1px",
                    paddingLeft: "10px",
                    minWidth: "50px",
                    whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                        component={CustomInput}
                        type="text"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        disabled={hidden}
                        
                    />
                </TableCell>
            </>
        )
    }else if(fieldtype == 'decimal'){
        return (
            <>
                <TableCell
                    style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].value`}
                        component={CustomInput}
                        type="text"
                        labelText={''}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        normalize={(value) => {
                            if (value) {
                                return crmValidator.handleKeyPress(value, { rules: { decimal: true, decimalDigits: 2 } })
                                
                            }else {
                                return ""
                            }
                        }}
                        validate={[validateRequired]}
                    />
                </TableCell>
                <TableCell style={{
                    paddingRight: "1px",
                    paddingLeft: "10px",
                    minWidth: "50px",
                    whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                        component={CustomInput}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        disabled={hidden}
                        type="text"
                    />
                </TableCell>
            </>
        )
    }

    if(compareOperator == 'contain' || compareOperator == 'in'){
        return (
            <>
                <TableCell
                    style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].value`}
                        component={CustomInput}
                        type="text"
                        labelText={''}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        validate={[validateRequired]}
                    />
                </TableCell>
                
                <TableCell style={{
                    paddingRight: "1px",
                    paddingLeft: "10px",
                    minWidth: "50px",
                    whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                        component={CustomInput}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        // disabled={hidden}
                        type="text"
                    />
                </TableCell>
            </>
        )
    }else{
        return (
            <>
                <TableCell
                    style={{
                        paddingRight: "1px",
                        paddingLeft: "10px",
                        minWidth: "50px",
                        whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].value`}
                        component={CustomInput}
                        type="text"
                        labelText={''}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        validate={[validateRequired]}
                    />
                </TableCell>
                
                <TableCell style={{
                    paddingRight: "1px",
                    paddingLeft: "10px",
                    minWidth: "50px",
                    whiteSpace: "nowrap" }}>
                    <Field
                        name={`conditions[${index}].conditions[${conditionindex}].lastvalue`}
                        component={CustomInput}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        disabled={hidden}
                        type="text"
                    />
                </TableCell>
            </>
        )
    }
    
}

export default InputValue

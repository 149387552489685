import { configConstants } from '../constants';
import axios from 'axios';
import _ from 'lodash';
import { history, setHeaders } from 'helpers';

export const productServices = {
    getListsProduct,
    apiAddProduct,
    apiUpdateProduct,
    apiDeleteProduct,
    getProductById,
    downloadProductReport
};

function getListsProduct(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/products?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function apiAddProduct(values) {
    let formData = new FormData()
    formData.set("prefix", values.prefix ? values.prefix : "");
    formData.set("name", values.name);
    formData.set("skuId", values.skuId)
    formData.set("description", values.description ? values.description : "");
    formData.append('file', values.productImage);
    formData.set("status", values.status);

    //categoryId
    if(values.categoryId){
        var categoryIds = values.categoryId;
        if(Array.isArray(categoryIds)){
            for(let i=0 ; i < categoryIds.length ; i++){
                formData.append('categoryId[]', categoryIds[i]);
            }
        }else{
            formData.append('categoryId[]', "");
        }
    }

    var tmp = axios.post(configConstants.API_BACKEND_CRM + '/products', formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });
    return tmp;
}

function apiUpdateProduct(values) {
    var productId = values._id;
    let formData = new FormData();
    formData.set("prefix", "");
    formData.set("name", values.name);
    formData.set("skuId", "")
    formData.set("description", values.description ? values.description : "");
    formData.append('file', values.productImage);
    formData.set("status", values.status);

    //categoryId
    if(values.categoryId){
        var categoryIds = values.categoryId;
        if(Array.isArray(categoryIds)){
            for(let i=0 ; i < categoryIds.length ; i++){
                formData.append('categoryId[]', categoryIds[i]);
            }
        }else{
            formData.append('categoryId[]', "");
        }
    }

    var tmp = axios.put(configConstants.API_BACKEND_CRM + `/products/${productId}`, formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });
    return tmp;
}

function apiDeleteProduct(params) {
    var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/products/${params._id}/force`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function getProductById(productId) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/products/${productId}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data.product,
                    onEarnrule: response.data.data.onEarnRule,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });
    return tmp;
}

function downloadProductReport(reqParams) {
    let tmp = axios.get(configConstants.API_BACKEND_CRM + `/products/export`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
        if (response.data.statusCode.toString() === "200") {
          const urlDownload = _.get(response.data, "data.url")
          if(urlDownload){
             window.location.assign(urlDownload)
          }
          return { status: true, data: {}, message: response.data.message };
        } else if (response.data.message === "UNAUTHORIZE") {
          history.push('/auth/login')
          return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
          return { status: false, data: {}, message: response.data.message };
        }
      }).catch(e => {
        return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      })
  
    return tmp;
}
import _ from "lodash";

import { memberConstants, dashboardConstants } from '../constants';
import { memberServices } from '../services';
import { store, loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

// const getErrorMessage = (key) => {
//    switch (key) {
//       case "PHONE_IS_DUPLICATE": return "หมายเลขโทรศัพท์นี้ได้ถูกลงทะเบียนไว้แล้ว กรุณาตรวจสอบเบอร์โทรศัพท์ของท่านอีกครั้ง"
//       case "EMAIL_IS_DUPLICATE": return "อีเมลนี้ได้ถูกลงทะเบียนไว้แล้ว กรุณาตรวจสอบอีเมลของท่านอีกครั้ง"
//       case "THIS_ID_CARD_NUMBER_IS_ALREADY_USED": return "เลขบัตรประชาชนนี้ถูกใช้ไปแล้ว"
//       case "ID_CARD_NUMBER_IS_INVALID": return "ไม่มีเลขบัตรประชาชนนี้"
//       case "ID_CARD_NUMBER_IS_WRONG_FORMAT": return "คุณกรอกเลขประชาชนบัตรไม่ถูกต้อง"
//       default: return "กรุณาลองใหม่อีกครั้ง"
//    }
// }

export const memberActions = {
   addNewMember,
   editMember,
   getMemberReport,
   deleteMember,
   getStatisticsMember,
   getTableDatas,
   getActiveTagList,
   getActiveMemberLevelList,
   checkMemberLimit
};

const memberLimitationAlert = (dispatch) => {
   const storeData = store.getState().authentication

   let source = localStorage.getItem('source') || ''
   let accessToken = localStorage.getItem('token') || ''
   let bypassLoginSBO = _.get(storeData, 'envConfig.BYPASS_LOGIN_SBO_URL', '')
   let isCUAA = _.get(storeData, 'user.cuaa_id', '')
   let isShopOwner = _.get(storeData, 'user.shopOwner', false)

   if(isCUAA && source === 'cuaa' && isShopOwner){
      return customAlert(dispatch, 'error', i18n.t(`api_messages.MEMBER_EXCEED_PACKAGE_LIMIT`), false, false, i18n.t(`memberPage.error_member_limitation`), () => {

         var form = document.createElement("form");
         form.setAttribute("method", "post");
         form.setAttribute("action", bypassLoginSBO);
         form.setAttribute("target", '_blank');
   
         var hiddenField = document.createElement("input");
         hiddenField.setAttribute("type", "hidden");
         hiddenField.setAttribute("name", "access_token");
         hiddenField.setAttribute("value", accessToken);
         form.appendChild(hiddenField);
   
         document.body.appendChild(form);
   
         form.submit();
   
      })
   } else if (isCUAA && source !== 'cuaa' && !isShopOwner) {
      return customAlert(dispatch, 'error', i18n.t(`api_messages.MEMBER_EXCEED_PACKAGE_LIMIT`), false, false, i18n.t(`memberPage.error_member_limitation`), () => {

         window.open(bypassLoginSBO.replace('/api/auth/passlogin', '/home'), '_blank').focus();
   
      })
   }else{
      return customAlert(dispatch, 'error', i18n.t(`api_messages.MEMBER_EXCEED_PACKAGE_LIMIT`), false, false, i18n.t(`memberPage.error_member_limitation`))
   }
}

function addNewMember(params) {
   return dispatch => {
      loader(dispatch, true)
      memberServices.addNewMember(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.member_was_created'));
               } else {
                  if (!response.unAlert) {
                     if (response.message === 'MEMBER_EXCEED_PACKAGE_LIMIT') {
                        memberLimitationAlert(dispatch)
                     } else {
                        customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                     }
                  }
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: memberConstants.ADD_NEW_MEMBER_SUCCESS, tmp } }
}

function editMember(params) {
   return dispatch => {
      loader(dispatch, true)
      memberServices.editMember(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.member_was_updated'));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: memberConstants.EDIT_MEMBER_SUCCESS, tmp } }
}

function deleteMember(params) {
   return dispatch => {
      loader(dispatch, true)
      memberServices.deleteMember(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch(success(tmp.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.member_was_deleted'));
               } else {
                  if (!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: memberConstants.DELETE_MEMBER_SUCCESS, tmp } }
}

function getStatisticsMember() {
   return dispatch => {
      loader(dispatch, true)
      memberServices.getStatisticsMember()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_STATISTICS_SUCCESS, data } }
}

function getTableDatas(params) {

   return dispatch => {
      loader(dispatch, true)
      dispatch({ type: memberConstants.SHOW_LOADER_MEMBER })
      memberServices.getListsMember(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: memberConstants.HIDE_LOADER_MEMBER })
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: memberConstants.TABLE_GET_LIST_DATAS, tmp } }
}

function getActiveTagList() {
   return dispatch => {

      dispatch({ type: memberConstants.GET_MEMBER_TAGLIST_REQUEST })
      loader(dispatch, true)
      memberServices.getActiveTagList()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: memberConstants.GET_MEMBER_TAGLIST_SUCCESS, tmp } }
}

function getActiveMemberLevelList() {
   return dispatch => {
      dispatch({ type: memberConstants.GET_MEMBER_LEVEL_LIST_REQUEST })
      loader(dispatch, true)
      memberServices.getActiveMemberLevelList()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: memberConstants.GET_MEMBER_LEVEL_LIST_SUCCESS, tmp } }
}


function getMemberReport(params, tableReset = false) {

   return dispatch => {

      loader(dispatch, true);
      memberServices
         .getMemberReport(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         )
         .then(() => {
            loader(dispatch, false);
         });
   }
   function success(tmp) { return { type: memberConstants.TABLE_GET_LIST_DATAS, tmp } }
}


function checkMemberLimit(formInitialValue,) {
   return dispatch => {
      loader(dispatch, true)
      memberServices.checkMemberLimit()
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: memberConstants.OPEN_FORM_ADD, formInitialValue });
               } else {
                  if (response.message === 'MEMBER_EXCEED_PACKAGE_LIMIT') {
                     memberLimitationAlert(dispatch)
                  } else {
                     customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                  }
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}
import React from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { plugins, crmValidator, alert as customAlert } from 'helpers';
import lodash from 'lodash'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import MUIDataTable from "mui-datatables";
import i18n from 'i18n';
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSweetAlert from 'components/SweetAlert/CustomSweetAlert'
import { pointAdjustmentActions } from "actions/pointAdjustment.actions";
import PointAdjustmentCreateConfirmation from "./PointAdjustmentCreateConfirmation"
const FORM_NAME = "FORM_FILTER_POINT_ADJUSTMENT";
const moduleName = 'point_adjustment'

const style = {
    ...modalStyle,
    cardBody: {
        padding: "15px"
    },
    cardHeader: {
        marginLeft: "0px",
        color: "#333"
    },
    formFooter: {
        textAlign: 'right'
    },
    btnSubmit: {
        marginRight: "20px"
    },
    conditionBlock: {
        padding: "10px",
        marginLeft: "0px",
        border: "dashed 1px #ccc"
    },
    tdBordered: {
        border: "solid 1px #ddd"
    },
    subHeader: {
        fontWeight: 900,
        fontSize: "16px",
        marginTop: "20px"
    }
}
const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                textAlign: "center",
                "&:nth-child(1)": {
                    padding: '2px 24px'
                },
                "&:nth-child(2)": {
                    padding: '2px 60px'
                }
            },
            body: {
                textAlign: "center"
            }
        }
    }
});

const form_validate = {
    type: {
        rules: {
            required: true,
        },
        messages: {
            required: 'warning_messages.action_is_required',
        },
        alert: 'warning_messages.action_is_required'
    },
    phoneNumber: {
        rules: {
            required: true,
            number: true,
            isMobilePhone: true,
        },
        messages: {
            required: 'warning_messages.mobile_number_is_required',
            isMobilePhone: 'warning_messages.member_mobile_number_is_invalid',
        },
        alert: 'warning_messages.mobile_number_is_required'
    },
    point: {
        rules: {
            required: true,
            number: true,
            noSpecialCharacters: true,
        },
        messages: {
            required: 'warning_messages.point_adjust_is_required',
        },
        alert: 'warning_messages.point_adjust_is_required'
    },
    remark: {
        rules: {
            required: true,
        },
        messages: {
            required: 'warning_messages.remark_or_reason_is_required',
        },
        alert: 'warning_messages.remark_or_reason_is_required'
    },
    needToBeApproved: {
        rules: {
            required: true,
        },
        messages: {
            required: '',
        },
        alert: i18n.t('warning_messages.need_to_be_approved_is_required')
    },
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}
// const validatePoint = (value) => { 
//     if(value === "0"){
//         return "warning_messages.point_equal_or_greater_than_zero"
//     }
// }
var warningMessagesRadioNeedToBeApproved = ''
const validateRadioNeedToBeApproved = (value) => {
    if(value === undefined){
        warningMessagesRadioNeedToBeApproved = i18n.t('warning_messages.need_to_be_approved_is_required')
    }else{
        warningMessagesRadioNeedToBeApproved = ''
    }
}
const onInsertPoint = (keyword) => {
    let regex
    if(parseInt(keyword) < 10){
        regex = new RegExp(/[-.0]/g);
    }else{
        regex = new RegExp(/[-.]/g);
    }

    const chars = keyword.split('');
    const char = chars.pop();
    if (regex.test(char)) {
        keyword = chars.join(''); 
    }
    let keyInt = parseInt(keyword).toString()
    return keyInt;
}

class PointAdjustmentForm extends React.Component {

    constructor(props) {
        super(props)
        this.pointAdjustmentFormRef = React.createRef()
    }

    componentDidMount() {
        this.pointAdjustmentFormRef.scrollIntoView()
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: 'CLOSE_FORM_POINT_ADJUSTMENT' })
    }

    handleSubmit(values)  { 
        const { dispatch, searchData } = this.props
        let reqBody = {
            "phoneNumber": lodash.get(searchData.data[0], 'phoneNumber'),
            "type": lodash.get(values, 'type'),
            "point": lodash.get(values, 'point'),
            "remark": lodash.get(values, 'remark'),
            "mustApproved": lodash.get(values, 'needToBeApproved') === 'yes' ? true : false,
        }
        if(lodash.get(values, 'needToBeApproved') === undefined){
            customAlert(dispatch, 'error', i18n.t('warning_messages.need_to_be_approved_is_required'))
        }else{
            dispatch({ type: 'OPEN_CONFIRMATION_CREATE_ADJUSTMENT_POPUP', rowData: reqBody});
        }
    }

    cancelCreate = () => {
        const { dispatch, tableFilter } = this.props;
        let _tableFilter = {
            page: 0,
            skip: 0,
            limit: tableFilter.limit,
            sortBy: "created_at",
            sortDir: "desc",
        }
        dispatch({ type: "CLOSE_FORM_POINT_ADJUSTMENT" })
        dispatch(reset(FORM_NAME))
        dispatch(pointAdjustmentActions.getTablePointAdjustmentDatas(_tableFilter));
    }

    searchUserByNumber = () => {
        const { dispatch, FORM_FILTER_POINT_ADJUSTMENT } = this.props
        dispatch(pointAdjustmentActions.getUserByPhoneNumber(lodash.get(FORM_FILTER_POINT_ADJUSTMENT, 'values.phoneNumber')))
    }

    getRowData(rowIndex = 0) {
        const { searchData } = this.props;
        const data = searchData.data;
        return data[rowIndex];
     }

    render() {
        const { classes, formMode, handleSubmit, searchData, tableLoader, tableKey, FORM_FILTER_POINT_ADJUSTMENT } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && plugins.isHasPermission(moduleName, 'update')) {
            readOnly = false
        } else {
            readOnly = true
        }

        const columns = [
            {
                name: "phoneNumber", label: "Mobile Number", options: { sort: false }
            },
            {
                name: "name", label: "Name", options: { sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex);
                        return rowData.lastName === undefined || rowData.lastName === null
                        ? rowData.firstName
                        : rowData.firstName + " " + rowData.lastName;
                    } 
                }
            },
            {
                name: "point", label: "Current Point", options: { sort: false }
            },
        ]
        const options = {
            serverSide: true,
            count: false,
            page: false,
            rowsPerPage: 10,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: false,
            search: false,
            pagination: false,
            sort: false,
            sortFilterList: false,
            viewColumns: false
        };

        return (
            <Card className={classes.cardBody}>
                <div ref={(ref) => this.pointAdjustmentFormRef = ref}></div>
                <CardBody>
                    <h4 className={classes.cardHeader}>
                        <b>Point Adjustment</b>
                        {/* <b>{formMode === "add" ? "Add Point Adjustment" : (readOnly) ? "Point Adjustment Detail" : "Edit Point Adjustment"}</b> */}
                    </h4>
                    <CustomSweetAlert />
                    <PointAdjustmentCreateConfirmation />
                    <div style={{ marginLeft: '20px'}}>
                        <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>  
                            <GridContainer>
                                <span style={{ marginTop: '30px'}}>Action *</span>
                                <GridItem xs={6} sm={3} md={3} lg={3}>
                                    <Field
                                        id="action-input"
                                        name="type"
                                        component={CustomSelect}
                                        type="text"
                                        labelText="Action *"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        optionsList={[
                                            {
                                                label: 'Add Point',
                                                value: 'add'
                                            }, 
                                            {
                                                label: 'Deduct Point',
                                                value: 'deduct'
                                            },
                                        ]}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <span style={{ marginTop: '30px'}}>Member's Mobile Number *</span>
                                <GridItem xs={6} sm={3} md={3} lg={3}>
                                    <Field
                                        id="phone-number-input"
                                        name="phoneNumber"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Mobile Number *"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(value, form_validate.phoneNumber)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={3} sm={3} md={3} lg={3}>
                                    <Button
                                        id="search-btn"
                                        onClick={this.searchUserByNumber}
                                        style={{
                                            marginTop: "25px",
                                            backgroundColor: "#4caf50"
                                        }}
                                        size="sm">
                                        Search
                                    </Button>
                                </GridItem>
                            </GridContainer>
                                <GridItem xs={12} sm={12} md={10} lg={10} style={{ marginLeft: '-30px'}}>
                                    <div id="tableSearchPhoneNumber" style={{ borderTopStyle: 'solid', borderRightStyle: 'solid', borderLeftStyle: 'solid', borderWidth: '1px', borderColor: 'rgb(224, 224, 224)'}}>
                                        <MuiThemeProvider theme={THEME}>
                                            <MUIDataTable
                                                key={tableKey}
                                                data={(searchData.data ? searchData.data : [])}
                                                columns={columns}
                                                options={options}
                                            />
                                            {tableLoader && <TableLoaderSpinner />}
                                        </MuiThemeProvider>
                                    </div>
                                </GridItem>
                            <GridContainer>
                                <span style={{ marginTop: '30px'}}>Point Adjust *</span>
                                <GridItem xs={6} sm={3} md={3} lg={3}>
                                    <Field
                                        id="point-adjust-input"
                                        name="point"
                                        component={CustomInput}
                                        type="number"
                                        labelText="Point Adjust *"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        min={1}
                                        disabled={readOnly}
                                        // validate={[validatePoint]}
                                        normalize={(value) => {
                                            return crmValidator.handleKeyPress(onInsertPoint(value), form_validate.point)
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <span style={{ marginTop: '30px'}}>Remark / Reason *</span>
                                <GridItem xs={12} sm={12} md={8} lg={8}>
                                    <Field
                                        id="remark-input"
                                        name="remark"
                                        component={CustomInput}
                                        type="text"
                                        labelText="Remark / Reason *"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        disabled={readOnly}
                                        maxLength={250}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <span style={{ marginTop: '30px'}}>Need to be approved *</span>
                            </GridContainer>
                            <GridContainer>
                            <GridItem xs={12} sm={8} md={8} lg={8} style={{ marginLeft: '-15px', marginTop: '10px'}}>
                                <Field
                                    id="need-to-approved-radio"
                                    name="needToBeApproved"
                                    component={CustomRadio}
                                    disabled={readOnly}
                                    radioLists={[
                                        {
                                            value: "yes",
                                            label: <span style={{ fontWeight: '300'}}>Yes</span>,
                                        }
                                    ]}
                                    onChange={this.handleChange}
                                    validate={[validateRadioNeedToBeApproved]}
                                />  
                                <Field
                                    id="need-to-approved-radio"
                                    name="needToBeApproved"
                                    component={CustomRadio}
                                    disabled={readOnly}
                                    radioLists={[
                                        {
                                            value: "no",
                                            label: <span style={{ fontWeight: '300'}}>No</span>,
                                        }
                                    ]}
                                    onChange={this.handleChange}
                                    style={{fontWeight: '300'}}
                                    validate={[validateRadioNeedToBeApproved]}
                                />        
                            </GridItem>
                            </GridContainer>
                            { lodash.get(FORM_FILTER_POINT_ADJUSTMENT,'submitFailed') === true || (lodash.get(FORM_FILTER_POINT_ADJUSTMENT,'submitSucceeded') === true && (lodash.get(FORM_FILTER_POINT_ADJUSTMENT,'syncErrors.needToBeApproved') === "")) ? 
                                <span style={{ marginLeft: '-15px', color: '#f44336', fontSize: '12px'}}>{warningMessagesRadioNeedToBeApproved}</span>
                            : null }
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6} style={{ marginLeft: '-15px', marginTop: "25px"}}>
                                    <Button
                                        id="submit-btn"
                                        type="submit"
                                        style={{
                                            marginRight: "10px"
                                        }}
                                        size="sm"
                                        color="primary">
                                        Submit
                                    </Button>
                                    <Button
                                        id="cancel-btn"
                                        onClick={this.cancelCreate}
                                        size="sm"
                                        color="white">
                                        Cancel
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </form>
                    </div>
                </CardBody>
            </Card>
        )
    }
}


function mapStateToProps(state) {
    const { formOpen, formMode, formInitialValue, searchData, tableLoader, tableKey, tableFilter } = state.point_adjustment;
    const { FORM_FILTER_POINT_ADJUSTMENT } = state.form
    return {
        formOpen, formMode, formInitialValue, searchData, tableLoader, tableKey, tableFilter, FORM_FILTER_POINT_ADJUSTMENT
    };
}

const connectedForm = connect(mapStateToProps)(PointAdjustmentForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch, props) => {
        crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(style)(connectedForm))

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, arrayPush, getFormValues } from 'redux-form';
import { crmValidator, normalize } from 'helpers';
import lodash from 'lodash'
import withStyles from "@material-ui/core/styles/withStyles";

// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { promotionConstants } from "../../../constants"
import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

const FORM_NAME = 'purchase_amount_per_bill_form'

const style = {
    ...modalStyle
}

const form_validate = {
    promotionCode: {
        rules: {
            required: true,
            maxLength: 20
        },
        messages: {
            required: 'Promotion Code is required',
            maxLength: 'Promotion Code must not be more than 20 characters'
        },
        alert: "Promotion Code is required"
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class ConditionPromotionCode extends React.Component {

    handleSubmit(values) {
        const { dispatch, promotionFormValues } = this.props
        let conditions = lodash.get(promotionFormValues, 'conditions')

        lodash.remove(conditions, function(n) {
            return n.type === 'promotion_code'
        });

        dispatch(arrayPush('promotion_form', 'conditions', {
            type: 'promotion_code',
            ...values
        }))

        dispatch({ type: promotionConstants.CLOSE_PROMOCODE_CONDITION })
    }

    render() {
        const { classes, dispatch, promoCodeConditionOpen, handleSubmit } = this.props
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={promoCodeConditionOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="xs"
                disableRestoreFocus={true}
                scroll="body">
                <DialogTitle
                    disableTypography
                    className={classes.modalHeader}
                    style={{paddingBottom: "0px"}}
                    >
                    <h4 className={classes.modalTitle}><b>Promotion Code Condition</b></h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                <DialogContent
                    id="promotion-code-condition-dialog"
                    className={classes.modalBody}
                >
                    <GridContainer>
                        <GridItem xs={12}>
                            <Field
                                name="promotionCode"
                                component={CustomInput}
                                type="text"
                                labelText={"Promotion Code"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={normalize.upperCasewithNumber}
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={classes.modalFooter}>
                    <Button
                        id={`promotioncode_condition_btn_submit`}
                        type="submit"
                        style={{ marginRight: "10px" }}
                        color="primary">
                        Submit
                        </Button>
                    <Button
                        id={`promotioncode_condition_btn_cancel`}
                        type="button"
                        onClick={() => {
                            dispatch({ type: promotionConstants.CLOSE_PROMOCODE_CONDITION });
                        }}
                        color="white">
                        Cancel
                    </Button>
                </DialogActions>
                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { promoCodeConditionOpen } = state.promotion;
    const promotionFormValues = getFormValues('promotion_form')(state)
    return {
        promoCodeConditionOpen, promotionFormValues
    };
}

const connectedComponent = connect(mapStateToProps)(ConditionPromotionCode);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: false,
})(withStyles(style)(connectedComponent))

import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import objectToFormData from 'object-to-formdata';

export const categoryServices = {
    getListsCategory,
    addCategory,
    updateCategory,
    deleteCategory,
    getCategoryDetail
}
function getListsCategory(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/category?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function addCategory(values) {
    const formData = objectToFormData(values);
    var tmp = axios.post(configConstants.API_BACKEND_CRM + '/category', formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.data ? response.data.message.data :  response.data.message };
            }
        });
    return tmp;
}

function updateCategory(values) {
    var categoryId = values._id;
    //values = lodash.omit(values, "rewardDetail.imgPath")
    const formData = objectToFormData(
        values
    );
    
    var tmp = axios.put(configConstants.API_BACKEND_CRM + `/category/${categoryId}`, formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.data ? response.data.data :  response.data.message };
            }
        });
    return tmp;
}

function deleteCategory(params) {
    var tmp = axios.delete(configConstants.API_BACKEND_CRM + `/category/${params._id}`, { headers: setHeaders() })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        });

    return tmp;
}

function getCategoryDetail(params) {
    var tmp = axios.get(configConstants.API_BACKEND_CRM + `/category/${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}
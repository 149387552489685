export const dataFilteringTemplateConstants = {
    OPEN_ADD: 'OPEN_ADD_DATAFILTERRING_TEMPLATE',
    OPEN_EDIT: 'OPEN_EDIT_DATAFILTERRING_TEMPLATE',
    OPEN_INFO: 'OPEN_INFO_DATAFILTERRING_TEMPLATE',
    CLOSE_FORM: 'CLOSE_FORM_DATAFILTERRING_TEMPLATE',
    GET_DATAFILTERRING_TEMPLATE_SUCCESS: 'GET_DATAFILTERRING_TEMPLATE_SUCCESS',

    ADD_NEW_DATAFILTERRING_TEMPLATE_SUCCESS: 'ADD_NEW_DATAFILTERRING_TEMPLATE_SUCCESS',
    ADD_NEW_DATAFILTERRING_TEMPLATE_FAILURE: 'ADD_NEW_DATAFILTERRING_TEMPLATE_FAILURE',

    EDIT_DATAFILTERRING_TEMPLATE_SUCCESS: 'EDIT_DATAFILTERRING_TEMPLATE_SUCCESS',
    EDIT_DATAFILTERRING_TEMPLATE_FAILURE: 'EDIT_DATAFILTERRING_TEMPLATE_FAILURE',

    SHOW_LOADER: 'SHOW_TABLE_DATAFILTERRING_TEMPLATE_LOADER_SPINNER',
    HIDE_LOADER: 'HIDE_TABLE_DATAFILTERRING_TEMPLATE_LOADER_SPINNER',

    TABLE_RELOAD_SUCCESS: 'DATAFILTERRING_TEMPLATE_TABLE_RELOAD_SUCCESS',

    DELETE_DATAFILTERRING_TEMPLATE_SUCCESS: 'DELETE_DATAFILTERRING_TEMPLATE_SUCCESS',
    DELETE_DATAFILTERRING_TEMPLATE_FAILURE: 'DELETE_DATAFILTERRING_TEMPLATE_FAILURE',

    OPEN_DATAFILTERING_TEMPLATE_EDIT_FORM: 'OPEN_DATAFILTERING_TEMPLATE_EDIT_FORM',
    CLOSE_DATAFILTERING_TEMPLATE_EDIT_FORM: 'CLOSE_DATAFILTERING_TEMPLATE_EDIT_FORM',
    SHOW_DATAFILTERRING_TEMPLATE_IMPORT_MEMBER_RESULT: 'SHOW_DATAFILTERRING_TEMPLATE_IMPORT_MEMBER_RESULT',
    HIDE_DATAFILTERRING_TEMPLATE_IMPORT_MEMBER_RESULT: 'HIDE_DATAFILTERRING_TEMPLATE_IMPORT_MEMBER_RESULT',

    GET_TEMPLATE_CONFIG_LIST_SUCCESS: 'GET_TEMPLATE_CONFIG_LIST_SUCCESS',

    RERUN_DATAFILTERRING_TEMPLATE_SUCCESS: 'RERUN_DATAFILTERRING_TEMPLATE_SUCCESS',
    RERUN_DATAFILTERRING_TEMPLATE_FAILURE: 'RERUN_DATAFILTERRING_TEMPLATE_FAILURE',
}
import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import { plugins } from "helpers";

import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Icon from '@material-ui/core/Icon';
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {

                textAlign: "left",
                padding: '4px 20px'
    
            },
            body: {
                textAlign: "left",
                padding: '4px 20px'
            }
        }
    }
});

class PointExpirationHistory extends React.Component {

    handleCloseHistory() {
        const { dispatch } = this.props;
        dispatch({ type: 'CLOSE_HISTORY_POPUP' });
    }

    getRowData(rowIndex = 0) {
        const { tableDatasHistory } = this.props;
        const data = tableDatasHistory.data;
        if(rowIndex.length === 0 ){
            rowIndex = 0;   
        }
        return data[rowIndex];
    }

    render() {
        const { classes, tableKey, formOpen, tableDatasHistory, tableLoaderHistory } = this.props;
        let columns = [
            { name: "date", label: "Date" ,
                options: {
                filter: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        return (rowData.expireDate ? plugins.datetimeFormat(rowData.expireDate, "date") : "");
                    }
                }
            },
            { name: "point", label: "Point Amount" },
        ]

        let options = {
            serverSide: false,
            count: (tableDatasHistory.total ? tableDatasHistory.total : 0),
            rowsPerPage: 10,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            search: false,
            sort: false,
            sortFilterList: false,
            viewColumns: false,
            rowsPerPageOptions: false,
        }
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                maxWidth='xs'
                scroll="body"
                disableRestoreFocus={true}
            >
                <Icon 
                    id="btn-close-point-expiration-report"
                    fontSize="small"
                    onClick={() => {
                        this.handleCloseHistory()
                    }}
                    style={{marginTop: '10px', marginLeft: '330px'}}
                >
                    close
                </Icon>
                <DialogContent
                    id="import-result-detail-dialog"
                    style={{ paddingTop: "0", textAlign: 'left' }}
                >
                    <h4 className={classes.modalTitle} style={{textAlign: 'left', marginTop: '0px'}}>
                        <b>Point Expiration History</b>
                    </h4>
                    <MuiThemeProvider theme={THEME}>
                        <MUIDataTable
                            key={tableKey}
                            data={tableDatasHistory.data}
                            columns={columns}
                            options={options}
                            style={{width: '330px'}}
                        />
                        {tableLoaderHistory && <TableLoaderSpinner />}
                    </MuiThemeProvider>
                </DialogContent>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, tableFilter, tableKey, tableDatas, tableDatasHistory, tableLoaderHistory } = state.expiration;
    return {
        formOpen, tableFilter, tableKey, tableDatas, tableDatasHistory, tableLoaderHistory
    };
}
const connectedComponent = connect(mapStateToProps)(PointExpirationHistory);

export default withStyles(sweetAlertStyle)(connectedComponent);



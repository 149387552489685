import { adminConstants, dashboardConstants } from '../constants';
import { adminServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';
export const adminActions = {
   addNewAdmin,
   editAdmin,
   deleteAdmin,
   getStatisticsAdmin,
   getListsRole,
   getTableDatas,
};

function addNewAdmin(params) {
   return dispatch => {
      loader(dispatch, true)
      adminServices.addNewAdmin(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.admin_was_created'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: adminConstants.ADD_NEW_ADMIN_SUCCESS, tmp } }
}

function editAdmin(params) {
   return dispatch => {
      loader(dispatch, true)
      adminServices.editAdmin(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.admin_was_updated'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: adminConstants.EDIT_ADMIN_SUCCESS, tmp } }
}

function deleteAdmin(params) {
   return dispatch => {
      loader(dispatch, true)
      dispatch({ type: adminConstants.DELETE_ADMIN_REQUEST })
      adminServices.deleteAdmin(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch(success(tmp.data));
                  customAlert(dispatch, 'success', i18n.t("warning_messages.admin_was_deleted"))
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: adminConstants.DELETE_ADMIN_SUCCESS, tmp } }
}

function getStatisticsAdmin() {
   return dispatch => {
      loader(dispatch, true)
      adminServices.getStatisticsAdmin()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(data) { return { type: dashboardConstants.GET_STATISTICS_SUCCESS, data } }
}

function getListsRole() {
   return dispatch => {
      adminServices.getListsRole()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         )
   };
   function success(data) { return { type: adminConstants.GET_ROLE_SUCCESS, data } }
}

function getTableDatas(params) {
   return dispatch => {
      dispatch({ type: adminConstants.SHOW_TABLE_LOADER })
      adminServices.getListsAdmin(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: adminConstants.HIDE_TABLE_LOADER })
         })
   };

   function success(tmp) { return { type: adminConstants.TABLE_GET_LIST_DATAS, tmp } }
}

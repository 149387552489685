import React from "react";
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import moment from 'moment';
// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import FormWinner from "views/Winner/FormWinner.jsx";

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

function WinnerList({ ...props }) {
    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>Winner</h4>
                        <GridContainer>
                            <GridItem xs={12}>
                                <FormWinner />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

function mapStateToProps(state) {
    const { tableReset } = state.winner;
    return {
        tableReset
    };
}
const connectWinnerContainer = connect(mapStateToProps)(WinnerList);

export default withStyles(style)(connectWinnerContainer);
import { adminConstants } from '../constants';
const initialState = {
    isOpen: false,
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableReload: false,
    sweetAlert: null,
    tableReset: false,
    tableDatas: {
        roles: [],
        total: 0
    },
    tableKey: 0,
    tableLoader: false
};

export function admin(state = initialState, action) {
    switch (action.type) {
        case adminConstants.CLEAR_PAGE_STATE:
            return initialState;
        case adminConstants.OPEN_FORM_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: { password: 'P@ssw0rd', passwordConfirmation: 'P@ssw0rd' }
            };
        case adminConstants.OPEN_FORM_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: {
                    _id: action.formInitialValue._id,
                    email: action.formInitialValue.email,
                    name: action.formInitialValue.name,
                    phoneNumber: action.formInitialValue.phoneNumber === null ? undefined : action.formInitialValue.phoneNumber,
                    roleIds: action.formInitialValue.roleIds[0],
                    brandId: action.formInitialValue.brandId
                }
            };
        case adminConstants.CLOSE_FORM_ADMIN:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {
                }
            };
        case adminConstants.TABLE_GET_LIST_DATAS:
            return {
                ...state,
                tableDatas: action.tmp,
                tableReload: false,
                tableReset: false,
            }
        case adminConstants.RELOAD_TABLE_ADMINLIST:
        case adminConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case adminConstants.TABLE_RESET:
            return {
                ...state,
                tableReset: true
            }
        case adminConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReset: false
            }
        case adminConstants.ADD_NEW_ADMIN_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            };
        case adminConstants.EDIT_ADMIN_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            };
        case adminConstants.EDIT_ADMIN_FAILURE:
            return state;
        case adminConstants.DELETE_ADMIN_REQUEST:
            return state
        case adminConstants.DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: true,
            };
        case adminConstants.DELETE_ADMIN_FAILURE:
            return {
                ...state,
                formOpen: false,
            };

        case adminConstants.GET_ROLE_REQUEST:
            return state
        case adminConstants.GET_ROLE_SUCCESS:
            return {
                ...state,
                listRoles: action.data
            };
        case adminConstants.GET_ROLE_FAILURE:
            return state;
        case adminConstants.HIDE_ALERT:
            return {
                ...state,
                showAlertDeleteSuccess: false,
                showAlertDeleteFailed: false
            };
        case adminConstants.SHOW_TABLE_LOADER:
            return {
                ...state,
                tableLoader: true
            }
        case adminConstants.HIDE_TABLE_LOADER:
            return {
                ...state,
                tableLoader: false
            } 
        default:
            return state
    }
}
export const memberLevelConstants = {
    OPEN_ADD: 'OPEN_ADD_MEMBER_LEVEL',
    OPEN_EDIT: 'OPEN_EDIT_MEMBER_LEVEL',
    CLOSE_FORM: 'CLOSE_FORM_MEMBER_LEVEL',
    GET_DATA_SUCCESS: 'GET_MEMBER_LEVEL_SUCCESS',

    ADD_NEW_SUCCESS: 'ADD_NEW_MEMBER_LEVEL_SUCCESS',
    ADD_NEW_FAILURE: 'ADD_NEW_MEMBER_LEVEL_FAILURE',

    EDIT_SUCCESS: 'EDIT_MEMBER_LEVEL_SUCCESS',
    EDIT_FAILURE: 'EDIT_MEMBER_LEVEL_FAILURE',

    SHOW_LOADER: 'SHOW_TABLE_MEMBER_LEVEL_LOADER_SPINNER',
    HIDE_LOADER: 'HIDE_TABLE_MEMBER_LEVEL_LOADER_SPINNER',

    TABLE_RELOAD_SUCCESS: 'MEMBER_LEVEL_TABLE_RELOAD_SUCCESS',

    DELETE_SUCCESS: 'DELETE_MEMBER_LEVEL_SUCCESS',
    DELETE_FAILURE: 'DELETE_MEMBER_LEVEL_FAILURE',

    SUBMIT_RANKING: 'SUBMIT_RANKING',
    RESET_RANK: 'RESET_RANK',

    ADD_NEW_CONDITION: 'ADD_NEW_CONDITION',
    GET_RANK_DATA_SUCCESS: 'GET_RANK_DATA_SUCCESS',

    SHOW_LOADER_RANK: "SHOW_LOADER_RANK",
    HIDE_LOADER_RANK: "HIDE_LOADER_RANK",

    SET_SUBMIT_RANKING: "SET_SUBMIT_RANKING",
    SET_CLEAR_RANKING: "SET_CLEAR_RANKING"

}
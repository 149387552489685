import React from "react";
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import WinnerApproveReport from "views/WinnerApprove/WinnerApproveReport.jsx";

const style = {
    customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
};

function WinnerApproveList({ ...props }) {
  return (
    <GridContainer>
        <GridItem xs={12}>
          <Card>
              <CardBody>
              <h4 style={{fontWeight:'500', fontSize:'1.25rem'}}>Approve</h4> 
                <GridContainer>
                  <GridItem xs={12}>
                    <WinnerApproveReport/>
                  </GridItem>
                </GridContainer>
              </CardBody>
          </Card>
        </GridItem>
    </GridContainer>
  )
}

function mapStateToProps(state) {
  const { tableReset } = state.winner_approve;
  return {
    tableReset
  };
}
const connectWinnerApproveContainer = connect(mapStateToProps)(WinnerApproveList);

export default withStyles(style)(connectWinnerApproveContainer);
import { swalConstants } from '../constants';

var initialState = {
  isShow: false,
  sweetAlert: {
    type: "success",
    title: "",
    message: "",
    onConfirm: function(){},
    onCancel: function(){}
  }
}

export function sweetAlert(state = initialState, action) {
  if(action.data === undefined){
    action.data = initialState.sweetAlert
  }
  switch (action.type) {
    case swalConstants.SHOW:
      return {
        ...state,
        isShow: true,
        sweetAlert: action.data
      };
    case swalConstants.HIDE:
      return {
        ...state,
        isShow: false,
        sweetAlert: initialState.sweetAlert
      };
    default : 
      return state
  }
}
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Icon from "@material-ui/core/Icon";
import Language from "@material-ui/icons/Language";
import Warning from "@material-ui/icons/Warning";


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Danger from "components/Typography/Danger.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";

// import { dataFilteringResultConstants } from '../../../constants';
import { treemapConstants } from '../../../constants';
import { Bar } from 'react-chartjs-2';

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.jsx";

const FORM_NAME = 'FORM_DATA_VISUALIZATION';

class FormDataVisualization extends React.Component {

    render() {
        const {classes, formVisualOpen, visualization } = this.props
        // const classes = useStyles();
        const data = {
            labels: ['Below 15', '15-19', '20-24', '25-29', '30-34', '35-39','40-44','45-49','Over 50'],
            datasets: [
              {
                label: '# Male',
                data: visualization.age_range_female,
                backgroundColor: 'rgb(255, 99, 132)',
              },
              {
                label: '# of Female',
                data: visualization.age_range_male,
                backgroundColor: 'rgb(54, 162, 235)',
              },
            ],
          }
          
          const options = {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }

          let province = [];
          if(visualization.province){
            province = visualization.province;
          }

        return (
              <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formVisualOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="md"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-visualization-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>Visualization</b>
                    </h4>
                </DialogTitle>

                <form name={FORM_NAME}>
                    <DialogContent
                            id="visualization-form-dialog"
                            className={classes.modalBody}
                            style={{ paddingTop: "0px" }}
                        >

                        <GridContainer>
                            <GridItem xs={12} sm={6} md={4} lg={4}>
                                <Card>
                                    <CardHeader color="warning" stats icon>
                                        <CardIcon color="warning">
                                            <Icon>person</Icon>
                                        </CardIcon>
                                        <p className={classes.cardCategory}>Total Users</p>
                                        <h3>
                                            <small>{visualization.total_user} คน</small>
                                        </h3>
                                    </CardHeader>
                                    <CardFooter stats>
                                        <div className={classes.stats}>
                                            {/* <Danger>
                                            <Warning />
                                            </Danger>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            Get more space
                                            </a> */}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4} lg={4}>
                                <Card>
                                    <CardHeader color="warning" stats icon>
                                    <CardIcon color="warning">
                                        <Icon>female</Icon>
                                    </CardIcon>
                                    <p className={classes.cardCategory}>Total Users - Female</p>
                                    <h3>
                                      <small>{visualization.total_user_sex_female} /{visualization.total_user} คน</small>
                                    </h3>
                                    </CardHeader>
                                    <CardFooter stats>
                                        <div className={classes.stats}>
                                            {/* <Danger>
                                            <Warning />
                                            </Danger>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            Get more space
                                            </a> */}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={4} lg={4}>
                                <Card>
                                    <CardHeader color="warning" stats icon>
                                        <CardIcon color="warning">
                                            <Icon>male</Icon>
                                        </CardIcon>
                                        <p className={classes.cardCategory}>Total Users - Male</p>
                                        <h3>
                                            <small>{visualization.total_user_sex_male} /{visualization.total_user}  คน</small>
                                        </h3>
                                    </CardHeader>
                                    <CardFooter stats>
                                        <div className={classes.stats}>
                                            {/* <Danger>
                                            <Warning />
                                            </Danger>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            Get more space
                                            </a> */}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>

                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                <Card>
                                    <CardHeader color="success" icon>
                                        <CardIcon color="success">
                                            <Icon>wc</Icon>
                                        </CardIcon>
                                        <p className={classes.cardCategory}>Users by age range and gender</p>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer justify="space-between">
                                            <GridItem>
                                                <Bar data={data} options={options} width={400} height={510}/>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6}>
                            <Card>
                                <CardHeader color="success" icon>
                                    <CardIcon color="success">
                                        <Language />
                                    </CardIcon>
                                    <p className={classes.cardCategory}>Users by age range and gender</p>
                                </CardHeader>
                                <CardBody>
                                <GridContainer justify="space-between">
                                    <GridItem xs={12} sm={12} md={12}>
                                    <Table
                                        tableData={province}
                                    />
                                    </GridItem>
                                </GridContainer>
                                </CardBody>
                            </Card>
                            </GridItem>
                        </GridContainer>

                    </DialogContent>
                </form>

                <DialogActions
                    className={classes.modalFooter}>

                    <Button color="white" onClick={() => {
                        const { dispatch } = this.props
                        dispatch({ type: treemapConstants.CLOSE_FORM_VISUALIZATION })
                    }}>
                        Cancel
                    </Button>
                </DialogActions>

            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formVisualOpen, visualization } = state.treemap;
    return {
        formVisualOpen, visualization
    };
}


const connectedComponent = connect(mapStateToProps)(FormDataVisualization);

export default reduxForm({
    form: FORM_NAME,
    // validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(styles)(connectedComponent))


import React from "react";
import lodash from "lodash";
import moment from "moment";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, reduxForm, change, getFormValues } from 'redux-form';

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
// period
import { periodAction } from 'actions';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { periodConstants } from '../../constants';

import TablePeriodList from "views/Period/TablePeriodList"

const style = {
    reportHeaderContainer: {
        margin: "0px",
        width: "calc(100% - 10px)",
        padding: "0px",
    },
    btnClearFilter: {
        marginRight: "10px",
        marginTop: "25px"
    },
    textCenter: {
        textAlign: "center"
    },
    mt20: {
        marginTop: "20px"
    }
};

const pageStyle = { ...style };
const FORM_NAME = "formFilterPeriod";

class PeriodContainer extends React.Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef()
        this.state = {
            campaignList: [],
            campaignAllList: [],
            typeAllList: []
        };
    }

    handleSubmit(values) {
        const { dispatch, formFilterPeriod } = this.props;
        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "createdAt",
            sortDir: "desc",
            keyword: "",
            search: {}
        }

        tableFilter.search = {
            "campaign": (formFilterPeriod.values === undefined || formFilterPeriod.values.campaign === undefined || formFilterPeriod.values.campaign === 'all') ? "" : formFilterPeriod.values.campaign,
            "type": (formFilterPeriod.values === undefined || formFilterPeriod.values.type === undefined || formFilterPeriod.values.type === 'all') ? "" : formFilterPeriod.values.type,
            "value": (formFilterPeriod.values === undefined || formFilterPeriod.values.periodvalue === undefined) ? "" : formFilterPeriod.values.periodvalue
        }
        const resetTable = true;

        dispatch(periodAction.getPeriodList(tableFilter, resetTable));
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch(change(FORM_NAME, 'campaign', ''));
        dispatch(change(FORM_NAME, 'type', ''));
        dispatch(change(FORM_NAME, 'periodvalue', ''));

        let tableFilter = {
            page: 0,
            skip: 0,
            limit: 10,
            sortBy: "createdAt",
            sortDir: "desc",
            keyword: "",
            search: {}
        }
        const resetTable = true
        dispatch(periodAction.getPeriodList(tableFilter, resetTable));
    }

    saveUpload = () => {
        const { dispatch, importResult } = this.props;
        dispatch(periodAction.saveUploadPeriod(importResult));
    }

    clearUpload = () => {
        const { dispatch } = this.props;
        dispatch({ type: periodConstants.CLOSE_SAVE_UPLOAD_PERIOD_RESULT });
    }

    componentDidMount() {
        this.getRewardList()
    }

    getRewardList() {
        const { dispatch } = this.props;
        let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"

        dispatch(periodAction.getRewardList(reqParams))
    }

    render() {

        const { classes, formFilterPeriod, handleSubmit, periodContainer, formOpen, showImportResult, formInitialValue } = this.props;

        let campaignAllList = [];
        campaignAllList.push({
            value: "all",
            label: "All"
        })

        if (lodash.has(periodContainer, 'campaignList')) {
            lodash.forEach(periodContainer.campaignList, function (value, key) {
                //console.log("aaaaaa",value)
                campaignAllList.push({
                    value: value.name,
                    label: value.name,
                    id: value._id,
                })

            })
        }

        let typeAllList = [];
        typeAllList.push(
            {
                value: "all",
                label: "All"
            },
            {
                value: "Mobile",
                label: "Mobile"
            },
            {
                value: "Email",
                label: "Email"
            },
            {
                value: "LineID",
                label: "Line ID"
            },
            {
                value: "FacebookID",
                label: "Facebook ID"
            },
        )

        //console.log(formOpen,showImportResult)
        const displayCard = (!showImportResult && !formOpen) ? {} : { display: "none" }
        const displayCardUpload = showImportResult ? {} : { display: "none" }
        return (
            <div>
                <Card>
                    <CardBody style={displayCard}>
                        <GridContainer>
                            <GridItem xs={12}>
                                {/* <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>PERIOD LIST</h4> */}
                                <TablePeriodList showAction={false} showSearch={false} />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </div>
        )
    }

}

function mapStateToProps(state) {
    const { sweetAlert } = state.sweetAlert;
    const { formFilterPeriod } = state.form;
    const { formOpen, periodContainer, showImportResult, importResult, formInitialValue } = state.period;
    return {
        sweetAlert, formFilterPeriod, formOpen, periodContainer, showImportResult, importResult, formInitialValue
    };
}

//export default connect(mapStateToProps)(withStyles(pageStyle)(WhitelistContainer));

const connectPeriodContainer = connect(mapStateToProps)(PeriodContainer);

export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    onChange: (values, dispatch, props, previousValues) => {
        if (values !== previousValues) {
            dispatch({ type: periodConstants.TABLE_RESET });
        }
    }
})(withStyles(pageStyle)(connectPeriodContainer));

import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import { adminActions, brandAction } from 'actions';
import { adminConstants } from '../../constants';

import i18n from 'i18n';

const FORM_NAME = "admin_form";

const form_validate = {
   "name": {
      rules: {
         required: true,
         isAllLetter: true
      },
      messages: {
         required: "warning_messages.name_is_required",
         isAllLetter: 'ชื่อไม่สามารถมีตัวเลขหรือสัญลักษณ์พิเศษได้ กรุณาตรวจสอบอีกครั้ง'
      },
      alert: "warning_messages.name_is_required"
   },
   "roleIds": {
      rules: {
         required: true,
      },
      messages: {
         required: "warning_messages.role_is_required",
      },
      alert: "warning_messages.role_is_required",
   },
   "phoneNumber": {
      rules: {
         // required: true,
         isMobilePhone: true,
         number: true,
         minLength: 10,
         maxLength: 10,
      },
      messages: {
         // required: 'กรุณากรอก',
         isMobilePhone: "warning_messages.mobile_number_is_invalid",
         number: "warning_messages.mobile_number_is_invalid",
         minLength: "warning_messages.mobile_number_is_invalid",
         maxLength: "warning_messages.mobile_number_is_invalid",
      },
      alert: "warning_messages.mobile_number_is_invalid",
   },
   "email": {
      rules: {
         required: true,
         isEmail: true
      },
      messages: {
         required: "warning_messages.email_is_required",
         isEmail: "warning_messages.email_is_invalid",
      },
      alert: "warning_messages.email_is_required",
   },
   "password": {
      rules: {
         required: true,
         minLength: 8,
         passwordStrength: true
      },
      messages: {
         required: "warning_messages.password_is_required",
         minLength: 'รหัสผ่านต้องมีมากกว่า 8 ตัวขึ้นไป',
         passwordStrength: 'รหัสผ่านต้องประกอบด้วยตัวพิมพ์ใหญ่ (A-Z), ตัวพิมพ์เล็ก (a-z), ตัวเลข (0-9) และตัวอักขระพิเศษ (!,@,#,$,%,^,&,*)'
      },
      alert: "warning_messages.password_is_required",
   },
   "passwordConfirmation": {
      rules: {
         required: true,
         equalTo: 'password'
      },
      messages: {
         required: "warning_messages.password_confirmation_is_required",
         equalTo: 'กรุณากรอกรหัสผ่านให้ตรงกัน'
      },
      alert: "warning_messages.password_confirmation_is_required",
   },
}

const validate = (values) => {
   const errors = crmValidator.validateByRulesList(form_validate, values);
   return errors
}

class AdminForm extends React.Component {
   componentWillMount() {
      const { dispatch } = this.props;

      dispatch(adminActions.getListsRole());
   }

   handleSubmit = (values) => {
      const { dispatch } = this.props;
      let reqParams = {
         "name": values.name,
         "phoneNumber": values.phoneNumber,
         "email": values.email,
         "password": values.password,
         "roleIds": [values.roleIds],
         "passwordConfirmation": values.passwordConfirmation,
         "brandId": values.brandId,
         "_id": values._id
      }

      if (this.props.formMode === "add") {
         dispatch(adminActions.addNewAdmin(reqParams));
      } else {
         dispatch(adminActions.editAdmin(reqParams));
      }
   }

   getListRoles = (list) => {
      if (list.roles !== undefined) {
         return list.roles.map((role) => {
            return {
               value: role._id,
               label: role.role
            }
         })
      }
   }

   getListBrands = (brands) => {
      if (brands !== undefined) {
         return brands.map((brand) => {
            return {
               value: brand._id,
               label: brand.name
            }
         })
      }
   }

   componentDidMount() {
      const { dispatch } = this.props;

      dispatch(brandAction.getBrandLists(''))
   }

   render() {
      const {
         handleSubmit,
         classes,
         formOpen,
         formMode,
         dispatch,
         listRoles,
         handleCloseForm
      } = this.props;

      let { readOnly } = this.props

      if (formMode === `add`) {
         readOnly = false
      } else if (formMode === `edit` && !readOnly) {
         readOnly = false
      } else {
         readOnly = true
      }

      return (
         <Dialog
            classes={{
               root: classes.center,
               paper: classes.modal
            }}
            open={formOpen}
            keepMounted
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            fullWidth={true}
            maxWidth="sm"
            scroll="body"
         >
            <DialogTitle
               id="classic-modal-slide-title"
               disableTypography
               className={classes.modalHeader}>
               <h4 className={classes.modalTitle}><b>{formMode === 'add' ? i18n.t("admin.add_") : (readOnly === true ? i18n.t("admin.info_") : i18n.t("admin.edit_"))}</b></h4>
            </DialogTitle>

            <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
               <DialogContent
                  id="admin-form-dialog"
                  className={classes.modalBody}
                  style={{ minHeight: "264px" }}
               >
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={6}>
                        <Field
                           name="name"
                           component={CustomInput}
                           type="text"
                           labelText={i18n.t("label.name")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                     <Field
                        name="_id"
                        component="input"
                        type="hidden"
                     />
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={12} md={6}>
                        <Field
                           name="roleIds"
                           component={CustomSelect}
                           type="text"
                           labelText={i18n.t("label.role")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           optionsList={listRoles !== undefined ? this.getListRoles(listRoles) : []}
                           disabled={readOnly}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={6} md={6}>
                        <Field
                           name="phoneNumber"
                           component={CustomInput}
                           type="phoneNumber"
                           labelText={i18n.t("label.mobile_no")}
                           maxLength={10}
                           formControlProps={{
                              fullWidth: true
                           }}
                           normalize={(value) => {
                              return crmValidator.handleKeyPress(value, form_validate.phoneNumber)
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>
                  <GridContainer>
                     <GridItem xs={12} sm={6} md={6}>
                        <Field
                           name="email"
                           component={CustomInput}
                           type="email"
                           labelText={i18n.t("label.email")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           disabled={readOnly}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={12} md={6}>
                        <Field
                           name="brandId"
                           component={CustomSelect}
                           type="text"
                           labelText={i18n.t("label.brand")}
                           formControlProps={{
                              fullWidth: true
                           }}
                           optionsList={this.props.brands !== undefined ? this.getListBrands(this.props.brands) : []}
                           disabled={readOnly}
                        />
                     </GridItem>
                  </GridContainer>

                  {formMode === 'add' ?
                     <>
                        <GridContainer>
                           <GridItem xs={12} sm={6} md={6}>
                              <Field
                                 name="password"
                                 component={CustomInput}
                                 type="password"
                                 labelText={i18n.t("label.password")}
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                              />
                           </GridItem>
                           <GridItem xs={12} sm={6} md={6}>
                              <Field
                                 name="passwordConfirmation"
                                 component={CustomInput}
                                 type="password"
                                 labelText={i18n.t("label.confirm_password")}
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                              />
                           </GridItem>
                        </GridContainer>
                     </>
                     : ""}

               </DialogContent>
               <DialogActions
                  className={classes.modalFooter}>
                  {/* {!readOnly ? */}
                  <Button
                     style={{ marginRight: "10px" }}
                     color="primary"
                     type="submit">
                     {i18n.t('submit')}
                  </Button>
                  {/* : ''
                 } */}
                  <Button
                     name="closeForm"
                     onClick={() => {
                        if (typeof handleCloseForm === 'function') {
                           handleCloseForm()
                        }
                        dispatch({ type: adminConstants.CLOSE_FORM_ADMIN })
                     }}
                     color="white">
                     {i18n.t('cancel')}
                  </Button>
               </DialogActions>
            </form>
         </Dialog>
      )
   }
}

function mapStateToProps(state) {
   const { formOpen, formMode, reload, listRoles } = state.admin;
   const { tableData: { brands } } = state.brand;
   return {
      formOpen, formMode, reload, listRoles, brands
   };
}

const connectAdminForm = connect(mapStateToProps)(AdminForm);

export default reduxForm({
   form: FORM_NAME,
   validate: validate,
   enableReinitialize: true,
   onSubmitFail: (errors) => {
      crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
   }
})(withStyles(modalStyle)(connectAdminForm))


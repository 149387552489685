import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import lodash from 'lodash';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import MUIDataTable from "mui-datatables";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';


// import { dataFilteringResultConstants } from '../../../constants';
import { treemapConstants } from '../../../constants';
import { treemapActions } from 'actions';


import { plugins, crmValidator } from 'helpers';
import Badge from "components/Badge/Badge.jsx";


const FORM_NAME = 'formRFMAddCampaigns';
const moduleName = 'rfm[treemap]';
const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        // display: "inline-flex"
        textAlign: "center"
    }
};


const pageStyle = { ...style };
const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(11)": {
                    textAlign: "right"
                }
            },
            body: {
                "&:nth-child(22)": {
                    textAlign: "right"
                }
            }
        }
    }
});

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
       case "Active": BadgeColor = "success"; break;
       case "Expired": BadgeColor = "danger"; break;
       default: BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
 }

class FormCampaigns extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: "",
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "id",
                sortDir: "asc",
                keyword: ""
            },
            selectCampaigns: [],
            csvData: [],
            reqParams: "",
            fieldsArray:[],
            addingProduct: false,
            disabled: []
            // refreshTable: true
        };
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }
    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableFilter } = this.props
        // if (this.state.refreshTable) {
        //     tableFilter.skip = null
        //     tableFilter.sortBy = "id"
        //     tableFilter.sortDir = "asc"
        //     tableFilter.keyword = ""
        //     tableFilter.search = {}
        //     this.setState({
        //         refreshTable: false
        //     })
        // }

        // let reqParams = tableFilter;

        let reqParams = "";

      if (!tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            skip = 0
         }

         if (crmValidator.isEmptyValue(limit)) {
            limit = 10
         }

         this.setState({
            page: skip,
            rowsPerPage: limit
         })
         skip = parseInt(limit) * parseInt(skip)

         reqParams += '&skip=' + skip
         reqParams += '&limit=' + limit

         if (!crmValidator.isEmptyValue(sortBy)) {
            reqParams += '&sortBy=' + sortBy
         }

         if (!crmValidator.isEmptyValue(sortDir)) {
            reqParams += '&sortDir=' + sortDir
         }

         if (!crmValidator.isEmptyValue(keyword)) {
            reqParams += '&keyword=' + keyword
         }
        }else {
            reqParams = this.state.reqParams
         }
        // dispatch(treemapActions.getTableDatas(reqParams));

        // const { dispatch } = this.props;
        // let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"

        dispatch(treemapActions.getListCampaigns(reqParams))
        this.setState({
            isLoading: true,
            reqParams: reqParams
        });
    }
    handleSubmit = (values) => {
        const { dispatch, campaignListUse, segment, resetForm } = this.props
        let saveData = {};
        saveData['segment'] = segment.segment;
        saveData['campaign'] = campaignListUse;
        dispatch(treemapActions.rfmAddedCampaign(saveData))
        resetForm();
    }
    getRowData(rowIndex = 0) {
        const { campaignList } = this.props;

        // console.log('campaignList',campaignList);
        const data = campaignList;
        return data[rowIndex];
    }

    getRowDataCampaign() {
        const { campaignListUse } = this.props;
        let arr = [];
        campaignListUse.map((val) => {
            arr.push(val.campaignId)
        })
        // const data = campaignListUse;
        return arr;
    }

    setNewCampaigns(rowIndex = 0){
        const { selectCampaigns } = this.state
        const { campaignList, campaignListUse, dispatch } = this.props;
        const data = campaignList;
        
        const check = campaignListUse && campaignListUse.find((clu) => clu.campaignId === data[rowIndex].campaignId)
        
        if(check === undefined){
            
            campaignListUse.push(data[rowIndex])
            
            dispatch({
                type: treemapConstants.ADD_NEW_CAMPAIGN_USE,data:campaignListUse
            });
            this.setState({addingProduct: true});
        }

    }

    setToggleAllAddedCampaign(newValue){
        const { dispatch,campaignListUse } = this.props;

        campaignListUse.map((obj, index) => {
            dispatch(change(FORM_NAME, `select[${obj.campaignId}]`, newValue))
        })
    }


    removeSelectedItem(obj){
        
        const { campaignList, campaignListUse, dispatch } = this.props;
        const { disabled } = this.state;
        
        const check = lodash.filter(campaignListUse, function (val) {
            return val.campaignId !== obj.campaignId
         })
        dispatch({
            type: treemapConstants.ADD_NEW_CAMPAIGN_USE,data:check
        });
        const checkDisabled = lodash.filter(disabled, function (val) {
            return val !== obj.campaignId
         })
         this.setState({
            disabled: checkDisabled
        })
         
        this.setState({addingProduct: true});
    }
    componentDidMount() {
        const { campaignListUse,dispatch,segment } = this.props
        // if(segment.length > 0){
        //     dispatch(treemapActions.getListRFMCampaigns(segment))
        // }
        // let disableButton = [];
        // campaignListUse.map((obj, index) => {
        //     disableButton.push(obj.campaignId)
        // })
        
        // this.setState({
        //     disabled: disableButton
        // })
    }
    handleToggle = (newValue, campaignId)=> {
        const { dispatch, campaignListUse } = this.props
        let status = newValue ? 'Active' : 'Inactive'
        
        let objIndex = campaignListUse.findIndex((obj => obj.campaignId == campaignId));
        campaignListUse[objIndex].status = status
        dispatch(change(FORM_NAME, `select[${campaignId}]`, newValue))
    }
    renderTableProductList(){
        const { campaignListUse,dispatch } = this.props
        let fieldsArray = [];
        
        { campaignListUse && campaignListUse.map((obj, index) => {
            
        fieldsArray.push (
            <>
                <List >
                    <ListItem>
                        <ListItemText
                            primary={obj.campaignName}
                            // secondary={obj.name ? obj.name : null}
                        />
                        <ListItemSecondaryAction>
                            <Field
                                style={{ }}
                                name={`select[${obj.campaignId}]`}
                                component={CustomCheckbox}
                                id={`select[${obj.campaignId}]`}
                                // labelText="Select All"
                                // checked={obj.status === 'Active' ? true : false}
                                onChange={(e,newValue) => {
                                    this.handleToggle(newValue, obj.campaignId)
                                }}
                            />
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon 
                                    onClick={() => {
                                    this.removeSelectedItem(obj)
                                    }}
                                />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </>
            )
            
            let statusNew = obj.status === 'Active' ? true : false
            dispatch(change(FORM_NAME, `select[${obj.campaignId}]`, statusNew))
            // dispatch(change(FORM_NAME, `btn_campaignList[${obj.campaignId}]`, true))
            
            
        })

       

        return fieldsArray
    }
}

    render() {
        const {classes, formCampaignOpen, handleSubmit, showLoader, campaignList, tableKey,campaignListTotal, campaignListUse } = this.props
        // const classes = useStyles();
        const { rowsPerPage, page, fieldsArray } = this.state;
        let disablebutton = [];
        campaignListUse && campaignListUse.map((obj, index) => {
        disablebutton.push(obj.campaignId)
        })
        
        let columns = [
            {
                name: "name", label: "name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.campaignName !== undefined || rowData.campaignName != null) {
                            return (rowData.campaignName);
                        } else {
                            return "";
                        }
                    }
                }
            },
        ];
        
        if (plugins.isHasPermission(moduleName, 'read')) {

            columns.push({
                name: "status", label: "status", options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    const rowData = this.getRowData(tableMeta.rowIndex);
                    return renderBadgeStatus(rowData.status)
                }
                }
            })

            columns.push({
               label: 'action', options: {
                  filter: false,
                  sort: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                     const rowIndex = tableMeta.rowIndex;
                     const rowData = this.getRowData(rowIndex);
                     const campaign = this.getRowDataCampaign()
                    
                    // {console.log('disablebutton.indexOf :', disablebutton.indexOf(rowData.campaignId)!==-1)}
                     return (
                         
                        <div>
                           {plugins.isHasPermission(moduleName, 'update') &&
                        
                            
                        
                            <AddIcon
                                name={`btn_campaignList[${rowData.campaignId}]`}
                                type="button"
                                // style={{ marginRight: "10px", marginTop: "25px" }}
                                size="sm"
                                color="primary"
                                key={rowData.campaignId}
                                disabled={(this.state.disabled.indexOf(rowData.campaignId)!==-1) || (campaign.indexOf(rowData.campaignId)!==-1)}
                                onClick={(e) => {
                                    this.setNewCampaigns(tableMeta.rowIndex)
                                    this.setState({
                                        disabled: [...this.state.disabled, rowData.campaignId]
                                    })
                                    
                                }}
                                id={`btn_campaignList[${rowData.campaignId}]`}

                            >
                                Active
                            </AddIcon>
                           }
                           
                        </div>
                     )
                  }
               }
            })
         }

        let options = {
            serverSide: true,
            count: (campaignListTotal ? campaignListTotal : 0),
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }
                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }
                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

    let productTemplate = this.renderTableProductList()
    
        return (
              <Dialog
                 classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formCampaignOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}

            >
                <DialogTitle
                    id="dialog-form-visualization-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>Campaigns</b>
                    </h4>
                </DialogTitle>

                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="resultId"
                                    component="input"
                                    type="hidden"
                                />

                                <Field
                                    name="conditionId"
                                    component="input"
                                    type="hidden"
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    style={{ }}
                                    name={`selectAll_campaigns`}
                                    component={CustomCheckbox}
                                    id={`selectAll_campaigns`}
                                    labelText="All Added Campaigns"
                                    // checked={isCheckDefault}
                                    onChange={(e,newValue) => {
                                        // console.log('e :',e);
                                        // console.log('newValue :',newValue);
                                        this.setToggleAllAddedCampaign(newValue);
                                    }}
                                />

                            </GridItem>
                            
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h6><b>Added</b></h6>
                            </GridItem>
                        
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.demo}>
                                {/* {this.showCampaignUser()} */}
                                {/* {fieldsArray} */}
                                {/* {campaignListUse.map((obj, index) => 
                                    this.showCampaignUser(obj)
                                )} */}
                                {productTemplate}
                                </div>
                                
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={THEME}>
                                    <MUIDataTable
                                        key={tableKey}
                                        data={campaignList}
                                        columns={columns}
                                        options={options}
                                    />
                                    {showLoader && <TableLoaderSpinner />}
                                </MuiThemeProvider>
                            </GridItem>
                        </GridContainer>

                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}
                    >
                        <Button
                            style={{ marginRight: "10px" }}
                            color="primary"
                            type="submit">
                            Save
                        </Button>
                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: treemapConstants.CLOSE_FORM_CAMPAIGNS })
                            // dispatch({type: treemapConstants.OPEN_FORM_CAMPAIGNS
                        }}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>

            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formCampaignOpen, visualization, campaignList, tableKey, campaignListTotal, tableFilter, campaignListUse, segment } = state.treemap;
    // const { formRFMAddCampaigns } = state.form;
    return {
        formCampaignOpen, visualization, campaignList, tableKey, campaignListTotal, tableFilter, campaignListUse, segment
    };
}


const connectedComponent = connect(mapStateToProps)(FormCampaigns);

export default reduxForm({
    form: FORM_NAME,
    // validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(pageStyle)(connectedComponent))

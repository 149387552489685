import lodash from 'lodash'
import Address from 'assets/data/addressFormatted.json'

export const address = {
    getAddress,
    getDistrict,
    getDistrictNoZipcode,
    getSubDistrict,
    getSubDistrictNoZipcode,
    getZipcode,
    getProvince
}

function getAddress(zipcode) {
    let province = []
    let district = []
    let subDistrict = []

    let searchResult = Address;
    if(zipcode){
        searchResult = lodash.filter( Address, { "zipcode": zipcode })
    }
    lodash.forEach(searchResult, function(data){

        if(lodash.indexOf(province, data.province) === -1){
            province.push(data.province)
        }

        if(lodash.indexOf(district, data.district) === -1){
            district.push(data.district)
        }

        if(lodash.indexOf(subDistrict, data.subDistrict) === -1){
            subDistrict.push(data.subDistrict)
        }
    })
    return {
        province: province,
        district: lodash.reverse(district),
        subDistrict: lodash.reverse(subDistrict)
    }
}

function getDistrict(province, zipcode){
    let district = []
    let filter = {}
    if(province){
        filter.province = province
    }
    if(zipcode){
        filter.zipcode = zipcode
    }
    let searchResult = lodash.filter(Address, filter)
    lodash.forEach(searchResult, function(data){
        if(lodash.indexOf(district, data.district) === -1){
            district.push(data.district)
        }
    })
    lodash.reverse(district)
    return district
}

function getDistrictNoZipcode(province){
    let district = []
    let filter = {
        province:''
    }
    if(province){
        filter.province = province
    }
    let searchResult = lodash.filter(Address, filter)
    lodash.forEach(searchResult, function(data){
        if(lodash.indexOf(district, data.district) === -1){
            district.push(data.district)
        }
    })
    lodash.reverse(district)
    return district
}

function getSubDistrict(district, zipcode) {
    let subDistrict = []
    let filter = {}
    if(district){
        filter.district = district
    }
    if(zipcode){
        filter.zipcode = zipcode
    }
    let searchResult = lodash.filter(Address, filter)
    lodash.forEach(searchResult, function(data){
        if(lodash.indexOf(subDistrict, data.subDistrict) === -1){
            subDistrict.push(data.subDistrict)
        }
    })
    lodash.reverse(subDistrict)
    return subDistrict
}

function getSubDistrictNoZipcode(district) {
    let subDistrict = []
    let filter = {
        district: '',
    }
    if(district){
        filter.district = district
    }
    let searchResult = lodash.filter(Address, filter)
    lodash.forEach(searchResult, function(data){
        if(lodash.indexOf(subDistrict, data.subDistrict) === -1){
            subDistrict.push(data.subDistrict)
        }
    })
    lodash.reverse(subDistrict)
    return subDistrict
}

function getZipcode(province, district, subDistrict) {
    if(!province || !district || !subDistrict){
        return ""
    }else{
        let searchResult = lodash.filter(Address, {"province": province, "district": district, "subDistrict": subDistrict })
        if(searchResult){
            return searchResult[0].zipcode
        }else{
            return ""
        }
    }
}

function getProvince(){
    let province = []
    lodash.forEach(Address, function(data){
        if(lodash.indexOf(province, data.province) === -1){
            province.push(data.province)
        }
    })
    lodash.reverse(province)
    return province.sort()
}
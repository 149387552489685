import { alertConstants } from '../constants';

var initialState = {
   isShow: false,
   isError: false,
   onConfirm: null,
   message: 'ยังไม่ได้ระบุค่า ​Default'
}

export function alert(state = initialState, action) {
   let tmp = state
   switch (action.type) {
      case alertConstants.SUCCESS:
         state = {
            ...state,
               isShow: true,
               isError: false,
               message: action.message,
               body: action.body
         };

         if(action.onConfirm){
            state.onConfirm = action.onConfirm
         }

         return state
      case alertConstants.ERROR:
         state = {
            ...state,
               isShow: true,
               isError: true,
               message: action.message,
               body: action.body
         };

         if(action.onConfirm){
            state.onConfirm = action.onConfirm
         }
         return state;
      case alertConstants.CLEAR:
         return initialState;
      default:
         return state;
   }
}
export const masterAdminConstants = {
    OPEN_FORM_ADD: 'OPEN_ADD_FORM_MASTER_ADMIN',
    OPEN_FORM_EDIT: 'OPEN_EDIT_FORM_MASTER_ADMIN',
    OPEN_FORM_EDIT_PASSWORD: 'OPEN_EDIT_PASSWORD_FORM_MASTER_ADMIN',
    CLOSE_FORM_ADMIN: 'CLOSE_FORM_MASTER_ADMIN',

    ADD_NEW_ADMIN_SUCCESS: 'ADD_NEW_MASTER_ADMIN_SUCCESS',
    ADD_NEW_ADMIN_FAILURE: 'ADD_NEW_MASTER_ADMIN_FAILURE',

    EDIT_ADMIN_SUCCESS: 'EDIT_MASTER_ADMIN_SUCCESS',
    EDIT_ADMIN_FAILURE: 'EDIT_MASTER_ADMIN_FAILURE',

    DELETE_ADMIN_REQUEST: 'DELETE_MASTER_ADMIN_REQUEST',
    DELETE_ADMIN_SUCCESS: 'DELETE_MASTER_ADMIN_SUCCESS',
    DELETE_ADMIN_FAILURE: 'DELETE_MASTER_ADMIN_FAILURE',

    HIDE_ALERT: 'HIDE_ALERT',

    ADMIN_LIST_CLEAR_STATE: 'MASTER_ADMIN_LIST_CLEAR_STATE',

    RELOAD_TABLE_ADMINLIST: 'RELOAD_TABLE_MASTER_ADMIN_LIST',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_MASTER_ADMIN_SUCCESS',
    TABLE_RESET: 'TABLE_MASTER_ADMIN_RESET',
    TABLE_RESET_SUCCESS: 'TABLE_MASTER_ADMIN_RESET_SUCCESS',

    CLEAR_PAGE_STATE: 'MASTER_ADMIN_CLEAR_PAGE_STATE',
    TABLE_GET_LIST_DATAS: 'TABLE_GET_LIST_MASTER_ADMINS',

    SHOW_TABLE_LOADER: 'SHOW_MASTER_ADMIN_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_MASTER_ADMIN_TABLE_LOADER',
    TABLE_GET_LIST_MERCHANTS: 'TABLE_GET_LIST_MERCHANTS',

    EDIT_PASSWORD_ADMIN_SUCCESS: 'EDIT_PASSWORD_MASTER_ADMIN_SUCCESS',
    EDIT_PASSWORD_ADMIN_FAILURE: 'EDIT_PASSWORD_MASTER_ADMIN_FAILURE',
    
}
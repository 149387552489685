import React from "react";
import { connect } from "react-redux";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import MUIDataTable from "mui-datatables";
// import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.jsx";
import moment from "moment";
import lodash from "lodash";
import { reportConstants } from "../../constants"
// core components


import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";


const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    reportHeaderContainer: {
        margin: "0px",
        width: "calc(100% - 10px)",
        padding: "0px",
    },
    btnClearFilter: {
        marginTop: "20px",
        width: "100%",
    },
    textCenter: {
        textAlign: "center",
    },
    mt01: {
        paddingLeft: "315px",
    },
};

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
       ...MTablestyles.overrides,
       MuiTableCell: {
          head: {
            textAlign: "center"
             
          },
          body: {
            textAlign: "center"
          }
       }
    }
 });
 
class PerchaseDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleCloseDetail(){
        const { dispatch } = this.props;
        dispatch({
          type: reportConstants.CLOSE_PURCHASE_DETAIL,
       });
    }
      

    render() {
        const { classes, earnReport } = this.props;
        const {  purchaseDetail , modalOpen } = earnReport
        let displayHeader = purchaseDetail.void === true ?  "Void" : "Purchase" ;
        let tableData 
        let amount = parseFloat(purchaseDetail.purchaseAmount).toFixed(2)
       
        if(!lodash.isEmpty(purchaseDetail.purchaseSkuAndQuantity) && purchaseDetail.purchaseSkuAndQuantity!==undefined){
            tableData = (purchaseDetail.purchaseSkuAndQuantity)
            
        }
        const columns = [
            { name: "skuId", label: "SKU ID" },
            { name: "skuName", label: "SKU Name" },
            { name: "quantity", label: "Quantity" },
        ];
        const options = {
            serverSide: false,
            fixedHeader: true,
            search: false,
            viewColumns: false,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            sort: false,
            responsive: "scroll",
            rowsPerPageOptions: false,
            paging: false,
        };
        options.customFooter = () => {
            return <></>;
        };
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal,
                }}
                open={modalOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                disableRestoreFocus={true}
                scroll="body"
            >
                <DialogTitle disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>{displayHeader} Detail </b>
                    </h4>
                </DialogTitle>
                <DialogContent id="product-form-dialog" className={classes.modalBody}>
                    <table style={{ fontSize: "12px", marginBottom: "20px", margin:"auto" }}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "end" }}><b>{displayHeader} Date/Time : </b></td>
                                <td style={{ paddingLeft:"20px"}}>{moment(purchaseDetail.transactionDate).format("DD-MMM-YYYY HH:mm:ss")} </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "end" }}><b>{displayHeader} Transaction ID : </b></td>
                                <td style={{ paddingLeft:"20px"}}>{purchaseDetail.purchaseTransactionId}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "end" }}> <b>{displayHeader} Amount : </b></td>
                                <td style={{ paddingLeft:"20px"}}> { isNaN(amount)? "": amount} </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "end" }}> <b>{displayHeader === 'Void' ? 'Refund' : 'Payment'} Chanel : </b></td>
                                <td style={{ paddingLeft:"20px"}}>{purchaseDetail.paymentChannel}</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "end" }}><b>shopId ID : </b></td>
                                <td style={{ paddingLeft:"20px"}}>{purchaseDetail.shopId}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div id="tablePurchaseDetail">
                        <MuiThemeProvider theme={THEME}>
                            <MUIDataTable
                                title={`${displayHeader} SKU`}
                                key={earnReport.tableKey}
                                data={tableData?tableData:[]}
                                columns={columns}
                                options={options}
                            />
                        </MuiThemeProvider>
                    </div>
                    <Button
                     id="button-purchaseDetail-close"
                        style={{
                            marginTop: "25px",
                            float: "right",
                            color:"red"
                        }}
                        size="sm"
                        color="white"
                        onClick={()=>{
                            this.handleCloseDetail()
                        }}
                    >
                        CLOSE
                    </Button>
                </DialogContent>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { earnReport } = state.report
    return {
        earnReport
    };
}

export default connect(mapStateToProps)(withStyles(style)(PerchaseDetail));

import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";
import i18n from 'i18n';
import { change } from 'redux-form';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";

import { swalConstants, earnrulesConstants, brandConstants, merchantConstants } from '../../constants';
import { brandAction } from "../../actions/brand.actions";
import { earnrulesServices } from "services";
import { plugins, crmValidator , loader, alert as customAlert } from "helpers";

import BrandForm from "./BrandForm";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Button from "components/CustomButtons/Button.jsx";

const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
   },
   actionDiv: {
      display: "inline-flex"
   },
   textCapital: {
      textTransform: "capitalize"
   }
};

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);
const moduleName = 'earn_rules' // @TODO : change to brand "brand";

class Brand extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         readOnly: true,
         page: 0,
         rowsPerPage: 10,
         count: 1,
         data: [],
         reqParams: ""
      };

      this.onSearchChange = lodash.debounce(this.getData, 500)
   }

   handleClose = values => {
      const { dispatch } = this.props;
      dispatch({ type: merchantConstants.CLOSE_FORM });
   };   

   openAddBrand = () => {
      const { dispatch } = this.props;

      dispatch({ type: brandConstants.OPEN_FORM_ADD_BRAND });
   }

   exportCSV() {
      const { dispatch } = this.props;
      let tableFilter = {
         page:0,
         rowsPerPage:0,
         skip:0,
         limit:10,
         sortBy:"created_at",
         sortDir:"desc",
      }

      let reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');

      loader(dispatch, true)
      earnrulesServices.downloadEarnRuleReport(reqParams)
      .then(response => {
         loader(dispatch, false)
      })
      .catch(e => {
         customAlert(dispatch, 'error', "api_messages.SOMETHING_WENT_WRONG")
         loader(dispatch, false)
      });
   }

   openEditBrand = (rowIndex) => {
      const { dispatch } = this.props;
      const rowData = this.getRowData(rowIndex)

      dispatch({ type: brandConstants.OPEN_FORM_EDIT_BRAND , tmp: rowData });
   }

   getData = (skip, limit, sortBy, sortDir, keyword) => {
      const { dispatch, tableReload } = this.props;
      let reqParams = "";
      if (!tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            skip = 0
         }
         if (crmValidator.isEmptyValue(limit)) {
            limit = 10
         }
         this.setState({
            page: skip,
            rowsPerPage: limit
         })
         skip = parseInt(limit) * parseInt(skip)

         reqParams += 'skip=' + skip
         reqParams += '&limit=' + limit

         if (!crmValidator.isEmptyValue(sortBy)) {
            reqParams += '&sortBy=' + sortBy
         }

         if (!crmValidator.isEmptyValue(sortDir)) {
            reqParams += '&sortDir=' + sortDir
         }

         if (!crmValidator.isEmptyValue(keyword)) {
            reqParams += '&keyword=' + keyword
         }

      } else {
         reqParams = this.state.reqParams
      }

      dispatch(brandAction.getBrandLists(reqParams))

      this.setState({
         reqParams: reqParams
      });

   }

   componentDidUpdate() {
      const { tableReload, tableReset, dispatch } = this.props;
      if (tableReload || tableReset) {
         dispatch({ type: earnrulesConstants.TABLE_RELOAD_SUCCESS })
         this.getData();
      }
   }

   componentWillMount() {
      const { dispatch } = this.props;
      dispatch({ type: earnrulesConstants.CLEAR_PAGE_STATE, data: {} });
      this.getData()
   }

   getRowData(rowIndex = 0) {
      const { tableData } = this.props;
      const data = tableData.brands;
      return data[rowIndex];
   }

   checkEarnRuleProductPackage(exist, earnRuleType){
      if(earnRuleType === 'product'){
         if(exist){
            return true
         }else{
            return false
         }
      }else{
         return true
      }
   }
   
   render() {
      const { classes, dispatch, tableData, formInitialValue, tableKey, tableLoader, package_features, formImportInitialValue } = this.props;
      const { rowsPerPage, page } = this.state;

      console.log('tableData', tableData)
      const existEarnRuleProductPackage = lodash.find(package_features, "earn_rule[product]")
      let columns = [
         {
            label: "No.",
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta) => {
                  const rowStart = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                  const index = rowStart + tableMeta.rowIndex
                  return index + 1;
               }
            }
         },
         {
            name: "name",
            label: "Brand Name"
         },
      ]

      columns.push(
         {
            label: "Action",
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta) => {
                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);

                  return (
                     <div className={classes.actionDiv}>
                        <TableActionButton
                           actionType="edit"
                           onClick={(e) => {
                              this.openEditBrand(tableMeta.rowIndex)
                           }}
                        />

                        <TableActionButton
                           actionType="delete"
                           onClick={(e) => {
                              console.log('e', e)
                              console.log('tableMeta', tableMeta)
                              let rowData = this.getRowData(tableMeta.rowIndex)

                                 dispatch({
                                    type: swalConstants.SHOW, data: {
                                       type: "warning",
                                       title: i18n.t("warning_messages.are_you_sure_delete_brand"),
                                       message: `${rowData.name}`,
                                       onConfirm: function () {
                                          let reqParams = {
                                             _id: rowData._id
                                          }
                                          dispatch({ type: swalConstants.HIDE })
                                          dispatch(brandAction.deleteBrand(reqParams));
                                       },
                                       onCancel: function () {
                                          dispatch({ type: swalConstants.HIDE })
                                       },
                                       showCancel: true,
                                       confirmBtnText: i18n.t("delete"),
                                       cancelBtnText: i18n.t("cancel")
                                    }
                                 })
                           }} />

                     </div>
                  )
               }
            }
         }
      )

      let options = {
         serverSide: true,
         count: tableData.total,
         page: page,
         rowsPerPage: rowsPerPage,
         print: false,
         customToolbar: () => {

            return (
               <>
                  {true &&(
                     <CustomToolbar handleClick={this.openAddBrand.bind(this)} title="Add New" />
                  )}
               </>
            );
         },

         download: false,
         filter: false,
         selectableRows: "none",
         responsive: "scroll",
         rowsPerPageOptions: [5, 10, 20],
         onTableChange: (action, tableState) => {
            let skip, limit, sortBy, sortDir, keyword = ""

            if (tableState.page !== null) {
               skip = tableState.page
            }

            if (tableState.rowsPerPage !== null) {
               limit = tableState.rowsPerPage
            }

            if (tableState.activeColumn !== null) {
               sortBy = tableState.columns[tableState.activeColumn].name
            }
            if (tableState.announceText !== null) {
               sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
            }
            if (tableState.searchText !== null) {
               keyword = tableState.searchText
            }
            let actionList = ['changePage', 'sort', 'search', 'changeRowsPerPage']

            if (actionList.indexOf(action) !== -1) {
               if (action === "search") {
                  this.onSearchChange(skip, limit, sortBy, sortDir, keyword)
               } else {
                  this.getData(skip, limit, sortBy, sortDir, keyword)
               }
            }
         },
      }

      return (
         <div id="tableBrandList">
            <BrandForm
               initialValues={formInitialValue}
            />
            <MuiThemeProvider theme={THEME}>
               <MUIDataTable
                  key={tableKey}
                  title={"Brand"}
                  data={tableData.brands}
                  columns={columns}
                  options={options}
               />
               {tableLoader && <TableLoaderSpinner />}
            </MuiThemeProvider>
            <GridContainer className="mt-4">
                  <GridItem xs={12} sm={12} md={12}>
                     <Button
                        type="button"
                        onClick={this.handleClose}
                        style={{ marginLeft: "10px" }}
                        color="white"
                     >
                        Cancel
                     </Button>
                  </GridItem>
               </GridContainer>
         </div>
      )
   }
}

function mapStateToProps(state) {
   const { tableReload, tableReset, tableData, formInitialValue, tableKey, tableLoader, formImportInitialValue } = state.brand;
   const { package_features } = state.authentication;

   return {
      tableReload, tableReset, tableData, formInitialValue, tableKey, tableLoader, package_features, formImportInitialValue
   };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(Brand));
import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";
import i18n from 'i18n';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";

import { swalConstants, earnSpecialsConstants } from '../../constants';
import { earnSpecialsAction } from "../../actions/earnspecials.actions";
// import { earnrulesServices } from "services";

import { plugins, crmValidator , loader, alert as customAlert } from "helpers";

import EarnSpecialsForm from "./EarnSpecialsForm.jsx";

const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
   },
   actionDiv: {
      display: "inline-flex"
   },
   textCapital: {
      textTransform: "capitalize"
   }
};

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);
const moduleName = "earn_rule";

const renderBadgeStatus = (status) => {
   let BadgeColor
   switch (status) {
      case "Active": BadgeColor = "success"; break;
      case "Expired": BadgeColor = "danger"; break;
      default: BadgeColor = "gray";
   }
   return (<Badge color={BadgeColor}>{status}</Badge>)
}

class TableEarnSpecialsList extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         readOnly: true,
         page: 0,
         rowsPerPage: 10,
         count: 1,
         data: [],
         reqParams: ""
      };

      this.onSearchChange = lodash.debounce(this.getEarnSpecialsData, 500)
   }

   openAddEarnSpecials = () => {
      const { dispatch, package_features } = this.props;
      // let earnSpecialTypeList = plugins.getEarnSpecialListByPacekageFeature(package_features)

      let initialValues = {
         status: "Active",
         // type: lodash.get(earnSpecialTypeList, '[0].value'),
         // selectedProductCount: 0,
         // customRadioParticipatedDay: 'everyday',
         // customRadioMinimumSpending: 'since',
         // customRadioPurshaseAmount: 'spendingEvery',
         // customRadioSelectedDay: 'dayOfWeek',
         // customRadioEarnFor: 'point',
      }

      dispatch({ type: earnSpecialsConstants.OPEN_ADD_NEW_SPECIALS, data: initialValues })
   }

   // exportCSV() {
   //    const { dispatch } = this.props;
   //    let tableFilter = {
   //       page:0,
   //       rowsPerPage:0,
   //       skip:0,
   //       limit:10,
   //       sortBy:"created_at",
   //       sortDir:"desc",
   //    }

   //    let reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');

   //    loader(dispatch, true)
   //    earnrulesServices.downloadEarnSpecialReport(reqParams)
   //    .then(response => {
   //       loader(dispatch, false)
   //    })
   //    .catch(e => {
   //       customAlert(dispatch, 'error', "api_messages.SOMETHING_WENT_WRONG")
   //       loader(dispatch, false)
   //    });
   // }

   openEditEarnSpecials = (rowIndex) => {
      const { dispatch } = this.props;
      const rowData = this.getRowData(rowIndex)
      dispatch(earnSpecialsAction.getEarnSpecialById(rowData._id))
   }

   getEarnSpecialsData = (skip, limit, sortBy, sortDir, keyword) => {
      const { dispatch, tableReload } = this.props;
      let reqParams = "";
      if (!tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            skip = 0
         }
         if (crmValidator.isEmptyValue(limit)) {
            limit = 10
         }
         this.setState({
            page: skip,
            rowsPerPage: limit
         })
         skip = parseInt(limit) * parseInt(skip)

         reqParams += 'skip=' + skip
         reqParams += '&limit=' + limit

         if (!crmValidator.isEmptyValue(sortBy)) {
            reqParams += '&sortBy=' + sortBy
         }

         if (!crmValidator.isEmptyValue(sortDir)) {
            reqParams += '&sortDir=' + sortDir
         }

         if (!crmValidator.isEmptyValue(keyword)) {
            reqParams += '&keyword=' + keyword
         }

      } else {
         reqParams = this.state.reqParams
      }

      dispatch(earnSpecialsAction.getEarnSpecialLists(reqParams))

      this.setState({
         reqParams: reqParams
      });

   }

   componentDidUpdate() {
      const { tableReload, tableReset, dispatch } = this.props;
      if (tableReload || tableReset) {
         dispatch({ type: earnSpecialsConstants.TABLE_RELOAD_SUCCESS })
         this.getEarnSpecialsData();
      }
   }

   componentWillMount() {
      const { dispatch } = this.props;
      dispatch({ type: earnSpecialsConstants.CLEAR_PAGE_STATE, data: {} });
      this.getEarnSpecialsData()
   }

   getRowData(rowIndex = 0) {
      const { tableData } = this.props;
      const data = tableData.earnSpecials;
      return data[rowIndex];
   }

   render() {
      const { classes, dispatch, tableData, formInitialValue, tableKey, tableLoader } = this.props;
      const { rowsPerPage, page } = this.state;
      let columns = [
         {
            label: "No.",
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta) => {
                  const rowStart = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                  const index = rowStart + tableMeta.rowIndex
                  return index + 1;
               }
            }
         },
         {
            name: "earnSpecialId",
            label: "Special ID",
            options: {
               customBodyRender: (value, tableMeta) => {
                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  if (lodash.has(rowData, 'displayId')) {
                     return rowData.displayId;
                  } else {
                     return ""
                  }
               }
            }
         },
         {
            name: "name",
            label: "Special Name"
         },
         {
            label: "Start Date - End Date",
            name: "startDate",
            options: {
               customBodyRender: (value, tableMeta) => {
                  try {
                     const rowIndex = tableMeta.rowIndex;
                     const rowData = this.getRowData(rowIndex);
                     let startDate = plugins.datetimeFormat(rowData.startDate, "datetime");
                     let endDate = plugins.datetimeFormat(rowData.endDate, "datetime");
                     return (<>{startDate} <br /> {endDate}</>)
                  } catch (e) {
                     return ""
                  }
               }
            }
         },
         {
            name: "rewardType",
            label: "Type",
            options: {
               customBodyRender: (value) => {
                  return (<span className={classes.textCapital}>{value}</span>)
               }
            }
         },
         {
            name: "updated_at",
            label: "Updated Date/Time",
            options: {
               customBodyRender: (value, tableMeta) => {
                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  let updated_datetime = plugins.datetimeFormat(rowData.updated_at, "fulldatetime")
                  return updated_datetime;
               }
            }
         },
         {
            name: "displayStatus",
            label: "Status",
            options: {
               filter: false,
               customBodyRender: (value, tableMeta) => {
                  const rowIndex = tableMeta.rowIndex;
                  const rowData = this.getRowData(rowIndex);
                  return renderBadgeStatus(rowData.displayStatus)
               }
            }
         }
      ]

      if (plugins.isHasPermission(moduleName, 'read')) {
         columns.push(
            {
               label: "Action",
               options: {
                  filter: false,
                  sort: false,
                  customBodyRender: (value, tableMeta) => {
                     return (
                        <div className={classes.actionDiv}>
                           {plugins.isHasPermission(moduleName, 'update') &&
                              <TableActionButton
                                 actionType="edit"
                                 onClick={(e) => {
                                    this.openEditEarnSpecials(tableMeta.rowIndex)
                                 }}
                              />
                           }
                           {plugins.isHasPermission(moduleName, 'delete') &&
                              <TableActionButton
                                 actionType="delete"
                                 onClick={(e) => {
                                    let rowData = this.getRowData(tableMeta.rowIndex)
                                    dispatch({
                                       type: swalConstants.SHOW, data: {
                                          type: "warning",
                                          title: i18n.t("warning_messages.are_you_sure_delete_earn_rule"),
                                          message: `${rowData.name}`,
                                          onConfirm: function () {
                                             let reqParams = {
                                                _id: rowData._id
                                             }
                                             dispatch({ type: swalConstants.HIDE })
                                             dispatch(earnSpecialsAction.deleteEarnSpecials(reqParams));
                                          },
                                          onCancel: function () {
                                             dispatch({ type: swalConstants.HIDE })
                                          },
                                          showCancel: true,
                                          confirmBtnText: i18n.t("delete"),
                                          cancelBtnText: i18n.t("cancel")
                                       }
                                    })
                                 }} />
                           }
                        </div>
                     )
                  }
               }
            }
         )
      }

      let options = {
         serverSide: true,
         count: tableData.total,
         page: page,
         rowsPerPage: rowsPerPage,
         print: false,
         customToolbar: () => {

            return (
               <>
                  {/* {plugins.isHasPermission(moduleName, 'export') &&(
                        <Tooltip title='Export CSV'>
                           <IconButton
                              id='button-earnSpecialTable-exportCsv'
                              className=''
                              onClick={this.exportCSV.bind(this)}>
                              <CloudDownload />
                           </IconButton>
                        </Tooltip>
                  )} */}

                  {plugins.isHasPermission(moduleName, 'create') &&(
                     <CustomToolbar handleClick={this.openAddEarnSpecials.bind(this)} title="Add New" />
                  )}
               </>
            );
         },

         download: false,
         filter: false,
         selectableRows: "none",
         responsive: "scroll",
         rowsPerPageOptions: [5, 10, 20],
         onTableChange: (action, tableState) => {
            let skip, limit, sortBy, sortDir, keyword = ""

            if (tableState.page !== null) {
               skip = tableState.page
            }

            if (tableState.rowsPerPage !== null) {
               limit = tableState.rowsPerPage
            }

            if (tableState.activeColumn !== null) {
               sortBy = tableState.columns[tableState.activeColumn].name
            }
            if (tableState.announceText !== null) {
               sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
            }
            if (tableState.searchText !== null) {
               keyword = tableState.searchText
            }
            let actionList = ['changePage', 'sort', 'search', 'changeRowsPerPage']

            if (actionList.indexOf(action) !== -1) {
               if (action === "search") {
                  this.onSearchChange(skip, limit, sortBy, sortDir, keyword)
               } else {
                  this.getEarnSpecialsData(skip, limit, sortBy, sortDir, keyword)
               }
            }
         },
      }

      if (plugins.isHasPermission(moduleName, 'create')) {
         options.customToolbar = () => {
            return (
               <CustomToolbar handleClick={this.openAddEarnSpecials.bind(this)} title="Add New" />
            );
         }
      }

      return (
         <div id="tableEarnSpecialsList">
            <CustomSweetAlert />
            <EarnSpecialsForm
               initialValues={formInitialValue}
               readOnly={!plugins.isHasPermission(moduleName, 'update')}
            />
            <MuiThemeProvider theme={THEME}>
               <MUIDataTable
                  key={tableKey}
                  title={"Earn Special"}
                  data={tableData.earnSpecials}
                  columns={columns}
                  options={options}
               />
               {tableLoader && <TableLoaderSpinner />}
            </MuiThemeProvider>
         </div>
      )
   }
}

function mapStateToProps(state) {
   const { tableReload, tableReset, tableData, formInitialValue, tableKey, tableLoader } = state.earnspecials;
   const { package_features } = state.authentication;
   return {
      tableReload, tableReset, tableData, formInitialValue, tableKey, tableLoader, package_features
   };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableEarnSpecialsList));
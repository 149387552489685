import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';

export const winnerApproveServices = {
    getAnnounceDateList,
    getRewardList,
    getListsWinner,
    approveWinner,
    approveWinnerUpload,
    rejectWinner
};

function getAnnounceDateList() {
    let tmp = axios.get(configConstants.API_LUCKYDRAW + `/list/announce_date`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                //console.log(response.data.data)
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getRewardList() {
    let tmp = axios.get(configConstants.API_LUCKYDRAW + `/list/reward-name`, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function getListsWinner(params) {
    var tmp = axios.post(configConstants.API_LUCKYDRAW + '/winner/search', params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

function approveWinner(params) {
    var tmp = axios.put(configConstants.API_LUCKYDRAW + `/approve/${params.periodId}`, params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            //console.log('service approveWinner',response);
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

function approveWinnerUpload(params) {
    var tmp = axios.post(configConstants.API_LUCKYDRAW + '/approve/winner/upload', params, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

function rejectWinner(params) {
    var tmp = axios.delete(configConstants.API_LUCKYDRAW + `/approve/${params.periodId}?winnerType=${params.winnerType}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
        .then(response => {

            if (response.data.statusCode.toString() === "200") {
                return { status: true, data: response.data.data, message: response.data.message };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return { status: false, data: {}, message: response.data.message };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });
    return tmp;
}

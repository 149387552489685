import React from "react";
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import moment from 'moment'
import lodash from 'lodash'

class ChartActiveDailyBurnTransaction extends React.Component {

  render() {
    let { startDate, endDate, activeCampaign, tableDialyBurnTransactionDatas } = this.props
    const nextLoopCondition = 'days'
    const labelsFormat = 'DD-MMM-YY'
    const findDataFormat = 'YYYY-MM-DD'
    let labels = []
    let chartdata = []

  
    const apiData = tableDialyBurnTransactionDatas
    for (var m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, nextLoopCondition)) {
      console.log('m.format(findDataFormat) :',m.format(findDataFormat));
      console.log('apiData :',apiData);
      let result = lodash.find(apiData, { reportDate: m.format(findDataFormat) })
    
      if(result){
        chartdata.push(parseInt(result.count))
      }else{
        chartdata.push(0)
      }

      labels.push(m.format(labelsFormat))
    }

    // console.log('chartdata :',chartdata);

    
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Active Daily Regestration',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 0,
          pointHoverRadius: 0,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 5,
          data: chartdata
        }
      ]
    }

    return (
      <>
        <Line data={data} options={{
          maintainAspectRatio: true,
          legend: false,
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Total User'
              },
              ticks: {
                maxTicksLimit: 10,
                beginAtZero: true,
                stepSize: 1,
                callback: function(value) {
                  return parseInt(value)
                }
              }
            }]
          },
        }}/>
      </>
    )
  }
}

ChartActiveDailyBurnTransaction = connect(state => {
  const dashboard = state.dashboard
  const startDate = lodash.get(dashboard, 'tableDialyBurnTransactionFilter.search.startDate')
  const endDate = lodash.get(dashboard, 'tableDialyBurnTransactionFilter.search.endDate')
  const activeCampaign = lodash.get(dashboard, 'tableDialyBurnTransactionFilter.search.activeCampaign')

  return {
    activeCampaign, startDate, endDate
  }
})(ChartActiveDailyBurnTransaction)

export default ChartActiveDailyBurnTransaction
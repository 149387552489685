import { configConstants } from '../constants';
import axios from 'axios';
import { history } from 'helpers';
export const dfconfigServices = {
    getTableTemplateList,
    getTableConfig,
    addTableConfig,
    editTableConfig
}

const apiContentType = "application/json";

function getTableTemplateList(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_DATAFILTERING + '/list/table-template', params, { headers: req_headers })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function getTableConfig(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    let tmp = axios.post(configConstants.API_DATAFILTERING + `/table-config/list`,params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function addTableConfig(params) {
    var req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    var tmp = axios.post(configConstants.API_DATAFILTERING + '/table-config', params, {
        headers: req_headers
    })
        .then(response => {
            if (response.data.statusCode.toString() === "200") {
                return {
                    status: true,
                    data: response.data.data,
                    message: response.data.message
                };
            } else if (response.data.message === "UNAUTHORIZE") {
                history.push('/auth/login')
                return { status: false, data: {}, message: response.data.message, unAlert: true };
            } else {
                return {
                    status: false,
                    data: {},
                    message: response.data.message
                };
            }
        });

    return tmp;
}

function editTableConfig(params,key){
    let req_headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      }
      let tmp = axios.put(configConstants.API_DATAFILTERING + `/table-config/${key}`, params, { headers: req_headers })
        .then(response => {
          if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        }else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
          } else {
            return { status: false, data: {}, message: response.data.message };
          }
        }).catch(e => {
          return { status: false, data: {}, message: e.message };
        })
      return tmp;
}

export const luckyDrawReportConstants = {
  REPORT_REDUCER_INIT: 'REPORT_REDUCER_INIT',
  GET_MEMBER_REPORT_SUCCESS: 'GET_MEMBER_REPORT_SUCCESS',

  GET_DROPDOWN_PRODUCT_LIST_SUCCESS: 'GET_DROPDOWN_PRODUCT_LIST_SUCCESS',
  GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS: 'GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS',
  
  GET_COLLECT_TRANSACTON_SUCCESS: 'GET_COLLECT_TRANSACTON_SUCCESS',
  COLLECT_TRANSACTON_TABLE_RESET: 'COLLECT_TRANSACTON_TABLE_RESET',

  GET_LUCKYDRAW_DOWLOAD_SUCCESS: 'GET_LUCKYDRAW_DOWLOAD_SUCCESS',
  LUCKYDRAW_DOWLOAD_RESET: 'LUCKYDRAW_DOWLOAD_RESET',

  GET_LUCKY_DRAW_REWARD_SET_SUCCESS: 'GET_LUCKY_DRAW_REWARD_SET_SUCCESS',
};
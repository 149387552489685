import { tmn_rewardConstants } from '../constants';
import { tmnServices } from '../services';
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const tmn_rewardActions = {
   getRewardLists,
   getRewardById,
   addReward,
   updateReward,
   addBudgetToReward

};

function getRewardLists(params) {
   return dispatch => {
      dispatch({ type: tmn_rewardConstants.SHOW_TABLE_LOADER })
      tmnServices.getRewardLists(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: tmn_rewardConstants.HIDE_TABLE_LOADER })
         })
   };

   function success(tmp) { return { type: tmn_rewardConstants.TABLE_GET_LIST_DATAS, tmp } }
}

function getRewardById(params) {
   return dispatch => {
      loader(dispatch, true)
      tmnServices.getRewardById(params)
         .then(
            tmp => {
               if (tmp.status) {
                  dispatch(success(tmp));
               } else {
                  if(!tmp.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${tmp.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
   function success(tmp) { return { type: tmn_rewardConstants.OPEN_FORM_EDIT, data : tmp.data , rewardImagePreview: tmp.data.imgPath } }
}

function addReward(params) {
   return dispatch => {
      loader(dispatch, true)
      tmnServices.apiAddReward(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: tmn_rewardConstants.ADD_NEW_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.tmn_reward_was_created'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function updateReward(params) {
   return dispatch => {
      loader(dispatch, true)
      tmnServices.apiUpdateReward(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: tmn_rewardConstants.EDIT_SUCCESS });
                  customAlert(dispatch, 'success', i18n.t('warning_messages.tmn_reward_was_updated'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}


function addBudgetToReward(params) {
   return dispatch => {
      loader(dispatch, true)
      tmnServices.apiAddRewardBudget(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.tmn_reward_was_updated'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: tmn_rewardConstants.ADD_BUDGET_SUCCESS, tmp } }
}

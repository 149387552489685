import { masterAdminConstants } from '../constants';

const initialState = {
    isOpen: false,
    formOpen: false,
    formEditPasswordOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableReload: false,
    sweetAlert: null,
    tableReset: false,
    tableDatas: {
        roles: [],
        total: 0
    },
    tableKey: 0,
    tableLoader: false,
    merchantLists: []
};

export function masterAdmin(state = initialState, action){
    switch (action.type) {
        case masterAdminConstants.CLEAR_PAGE_STATE:
            return initialState;
        case masterAdminConstants.OPEN_FORM_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: { password: 'P@ssw0rd', passwordConfirmation: 'P@ssw0rd' }
            };
        case masterAdminConstants.OPEN_FORM_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: {
                    _id: action.formInitialValue._id,
                    email: action.formInitialValue.email,
                    name: action.formInitialValue.name,
                    phoneNumber: !action.formInitialValue.phoneNumber ? undefined : action.formInitialValue.phoneNumber,
                    accessMerchantAdmin: action.formInitialValue.accessMerchantAdmin,
                    accessMerchantBackOffice: action.formInitialValue.accessMerchantBackOffice,
                    accessMerchantLists: action.formInitialValue.accessMerchantLists,
                    defaultMerchantId: action.formInitialValue.defaultMerchantId,
                    status: action.formInitialValue.status
                }
            };
        case masterAdminConstants.OPEN_FORM_EDIT_PASSWORD:
            return {
                ...state,
                formEditPasswordOpen: true,
                formMode: 'editPassword',
                formInitialValue: {
                    _id: action.formInitialValue._id,
                }
            };
        case masterAdminConstants.CLOSE_FORM_ADMIN:
            return {
                ...state,
                formOpen: false,
                formEditPasswordOpen: false,
                formInitialValue: {
                }
            };
        case masterAdminConstants.TABLE_GET_LIST_DATAS:
            return {
                ...state,
                tableDatas: action.tmp,
                tableReload: false,
                tableReset: false,
            }
        case masterAdminConstants.TABLE_GET_LIST_MERCHANTS:
            return {
                ...state,
                merchantLists: action.tmp,
            }
        case masterAdminConstants.RELOAD_TABLE_ADMINLIST:
        case masterAdminConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false,
            }
        case masterAdminConstants.TABLE_RESET:
            return {
                ...state,
                tableReset: true
            }
        case masterAdminConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReset: false
            }
        case masterAdminConstants.ADD_NEW_ADMIN_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
            };
        case masterAdminConstants.EDIT_ADMIN_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            };
        case masterAdminConstants.EDIT_ADMIN_FAILURE:
            return state;
        case masterAdminConstants.EDIT_PASSWORD_ADMIN_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formEditPasswordOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true
            };
        case masterAdminConstants.EDIT_PASSWORD_ADMIN_FAILURE:
            return state;
        case masterAdminConstants.DELETE_ADMIN_REQUEST:
            return state
        case masterAdminConstants.DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: true,
            };
        case masterAdminConstants.DELETE_ADMIN_FAILURE:
            return {
                ...state,
                formOpen: false,
            };

        case masterAdminConstants.GET_ROLE_REQUEST:
            return state
        case masterAdminConstants.GET_ROLE_SUCCESS:
            return {
                ...state,
                listRoles: action.data
            };
        case masterAdminConstants.GET_ROLE_FAILURE:
            return state;
        case masterAdminConstants.HIDE_ALERT:
            return {
                ...state,
                showAlertDeleteSuccess: false,
                showAlertDeleteFailed: false
            };
        case masterAdminConstants.SHOW_TABLE_LOADER:
            return {
                ...state,
                tableLoader: true
            }
        case masterAdminConstants.HIDE_TABLE_LOADER:
            return {
                ...state,
                tableLoader: false
            } 
        default:
            return state
    }
}

import { dataFilteringTemplateConstants } from '../constants';
import lodash from 'lodash'

const initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: new Date(),
    tableReload: false,
    tableReset: false,
    showLoader: false,
    tableDatas: {
        dataFilteringTemplates: [],
        total: 0
    },
    formImportOpen: false,
    formImportInitialValue: {},
    dataInit:{},
    showImportResult: false,
    importResult: {},
    datafiltering: [],
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "createdAt",
        sortDir: "desc",
        keyword: "",
        search: {}
       },
}

export function dataFilteringTemplate(state = initialState, action) {
    switch (action.type) {
        case dataFilteringTemplateConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case dataFilteringTemplateConstants.OPEN_EDIT:
            return {
                ...state,
                formImportOpen: true,
                formMode: 'edit',
                formImportInitialValue: action.formImportInitialValue,
                dataInit: action.formImportInitialValue,
            }
        case dataFilteringTemplateConstants.OPEN_INFO:
            return {
                ...state,
                formOpen: true,
                formMode: 'info',
                formInitialValue: action.formInitialValue
            }
        case dataFilteringTemplateConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formMode: 'add',
                formInitialValue: {}
            }
        case dataFilteringTemplateConstants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case dataFilteringTemplateConstants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case dataFilteringTemplateConstants.GET_DATAFILTERRING_TEMPLATE_SUCCESS:
            return {
                ...state,
                tableDatas: {
                    dataFilteringTemplates: lodash.has(action, 'tmp.template') ? action.tmp.template : [],
                    total: lodash.has(action, 'tmp.total') ? action.tmp.total : 0
                },
                formInitialValue: {}
            }
        case dataFilteringTemplateConstants.ADD_NEW_DATAFILTERRING_TEMPLATE_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                formInitialValue: {}
            }
        case dataFilteringTemplateConstants.EDIT_DATAFILTERRING_TEMPLATE_SUCCESS:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case dataFilteringTemplateConstants.TABLE_RELOAD_SUCCESS:
            return {
                ...state,
                tableReset: false,
                tableReload: false
            }
        case dataFilteringTemplateConstants.DELETE_DATAFILTERRING_TEMPLATE_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case dataFilteringTemplateConstants.RERUN_DATAFILTERRING_TEMPLATE_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case dataFilteringTemplateConstants.OPEN_DATAFILTERING_TEMPLATE_EDIT_FORM:
            return {
                ...state,
                formImportOpen: true,
                formImportInitialValue: (action.formImportInitialValue ? action.formImportInitialValue : {})
            }
        case dataFilteringTemplateConstants.CLOSE_DATAFILTERING_TEMPLATE_EDIT_FORM:
            return {
                ...state,
                formImportOpen: false,
                formMode: 'add',
                formImportInitialValue: {}
            }
        case dataFilteringTemplateConstants.SHOW_DATAFILTERRING_TEMPLATE_IMPORT_MEMBER_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: true,
                importResult: action.data
            }
        case dataFilteringTemplateConstants.HIDE_DATAFILTERRING_TEMPLATE_IMPORT_MEMBER_RESULT:
            return {
                ...state,
                showImportResult: false,
                importResult: {}
            }
        case dataFilteringTemplateConstants.GET_TEMPLATE_CONFIG_LIST_SUCCESS:
            return {
                ...state,
                datafiltering: action.data.filtering
            }

        default:
            return state
    }
}
import { shipmentConstants } from '../constants';
const initialState = {
   formOpen: false,
   formMode: 'add',
   submitForm: false,
   formInitialValue: {},
   tableReload: false,
   tableReset: false,
   tableDatas: [],
   tableKey: 0,
   tableFilter: {
      page: 0,
      skip: 0,
      limit: 10,
      sortBy: "createdAt",
      sortDir: "desc",
      keyword: "",
      search: {}
    },
   showLoader: false,
   formImportOpen: false,
   formImportInitialValue: {},
   showImportResult: false,
   importResult: {}
};

export function shipment(state = initialState, action) { 
   switch (action.type) {
      case shipmentConstants.CLEAR_PAGE_STATE:
         return initialState;
      case shipmentConstants.OPEN_FORM_EDIT:
         return {
            ...state,
            formOpen: true,
            formMode: 'edit',
            formInitialValue: action.formInitialValue
         }
      case shipmentConstants.CLOSE_FORM_SHIPMENT:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {
            }
         }
      case shipmentConstants.SET_FILTER:
         return {
            ...state,
            tableFilter: action.tableFilter
         }
      case shipmentConstants.TABLE_GET_LIST_DATAS:
         return {
            ...state,
            ...state.shipmentReport,
            tableFilter: action.tableFilter,
            tableDatas: (action.data?action.data:[]),
            tableTotal: (action.total?action.total:0)
         }
      case shipmentConstants.RELOAD_TABLE_MEMBERLIST:
      case shipmentConstants.TABLE_RELOAD_SUCCESS:
      case shipmentConstants.TABLE_RESET:
         return {
            ...state,
            tableReload: false,
            tableReset: false,
         }
      case shipmentConstants.TABLE_RESET_SUCCESS:
         return {
            ...state,
            tableReset: false
         }
      case shipmentConstants.EDIT_SHIPMENT_SUCCESS:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            tableReset: false,
            tableReload: true,
            submitForm: true
         }
      case shipmentConstants.SHOW_LOADER_SHIPMENT:
         return {
            ...state,
            showLoader: true
         }
      case shipmentConstants.HIDE_LOADER_SHIPMENT:
         return {
            ...state,
            showLoader: false
         }
      case shipmentConstants.GET_DROPDOWN_SHIPPING_COMPANY_LIST_SUCCESS:
        return {
          ...state,
          shippingReport: {
            ...state.shippingReport,
            // shippingCompanyList: action.data
          }
        }
      case shipmentConstants.TABLE_GET_LIST_DATAS_RESET:
        return {
          ...state,
          shippingReport: {
            ...state.shippingReport,
            tableKey: Date.now()
          }
        }
      case shipmentConstants.GET_DROPDOWN_CAMPAIGN_LIST_SUCCESS:
           return {
              ...state,
              shippingReport: {
               ...state.shippingReport,
               campaignList: action.data
             }
           }
      case shipmentConstants.OPEN_SHIPMENT_FORM_IMPORT: {
            return {
                ...state,
                formImportOpen: true,
                formImportInitialValue: {}
            }
        }     
      case shipmentConstants.CLOSE_SHIPMENT_FORM_IMPORT: {
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {}
            }
        } 
      case shipmentConstants.SHOW_EARN_RULE_IMPORT_PRODUCT_RESULT: {
         return {
             ...state,
             formImportOpen: false,
             formImportInitialValue: {},
             showImportResult: true,
             importResult: action.data
         }
      }
      case shipmentConstants.HIDE_EARN_RULE_IMPORT_PRODUCT_RESULT:
         return {
             ...state,
             showImportResult: false,
             importResult: {},
             tableReset: false,
             tableReload: true,
             submitForm: true
         }
      default:
         return state
   }
}
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import moment from 'moment'
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from 'components/CustomButtons/Button';
import lodash from 'lodash'

import { dashboardActions } from 'actions';

const FORM_NAME = 'formFilterDailyBurnTransaction'
class FormFilterDailyBurnTransaction extends React.Component {

  handleSubmit(values) {
    const { dispatch } = this.props
    let tableDialyBurnTransactionFilter = {}
    let today = new Date()
    let activeCampaign = lodash.get(values, 'activeCampaign') ? lodash.get(values, 'activeCampaign') : 'all'
    let startDate = null;
    let endDate = null;
    if(lodash.get(values, 'startDate')){
      startDate = moment(lodash.get(values, 'startDate')).format('YYYY-MM-DD')
    }else if(!lodash.get(values, 'startDate') && lodash.get(values, 'endDate')){
      startDate = moment(lodash.get(values, 'endDate')).subtract(30,'days').format('YYYY-MM-DD')
    }
    if(lodash.get(values, 'endDate')){
      endDate = moment(lodash.get(values, 'endDate')).format('YYYY-MM-DD')
    }else if(!lodash.get(values, 'endDate') && lodash.get(values, 'startDate')){
      endDate = moment(lodash.get(values, 'startDate')).add(30,'days') > moment(today) ? moment(today).format('YYYY-MM-DD') : moment(lodash.get(values, 'startDate')).add(30,'days').format('YYYY-MM-DD') 
    }

    if(!lodash.get(values, 'startDate') && !lodash.get(values, 'endDate')){
      startDate = null;
      endDate = null;
    }
    tableDialyBurnTransactionFilter.search = {
      "startDate": startDate,
      "endDate": endDate,
      "activeCampaign": activeCampaign
    }

    dispatch(dashboardActions.getTableDailyBurnTransactionDatas(tableDialyBurnTransactionFilter));
    dispatch({type: 'DASHBOARD_RESET_ACTIVE_DAILY_BURN_TRANSACTION', tableDialyBurnTransactionFilter })
  }




  render() {
    const { startDate, endDate, handleSubmit, campaignList } = this.props;

    // console.log('campaignList :',campaignList);
    var startMinDate;
    var startMaxDate;
    if(endDate !== ''){
      startMaxDate = moment(endDate)
      startMinDate = moment(endDate).subtract(30, 'days')
    }else{
      startMaxDate = moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day')
      startMinDate = null;
    }
    var endMinDate;
    var endMaxDate = moment().set('hour',0).set('minute',0).set('second',0).set('millisecond',0).subtract(1, 'day')
    if(startDate !== ''){ 
      endMinDate = moment(startDate)
      if( moment(startDate).add(30, 'days') <= moment().subtract(1, 'day') ){
          endMaxDate = moment(startDate).add(30, 'days')
      }
    }else{
      endMinDate = null;
    }

    let campaignAllList = [];
    campaignAllList.push({
        value: "all",
        label: "All"
    })

    if (campaignList) {
        lodash.forEach(campaignList, function (value, key) {
            campaignAllList.push({
                value: value.name,
                label: value.name,
                id: value._id,
            })

        })
    }

    return (
      <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
        <GridContainer>
          <GridItem xs={12} sm={3} md={2} lg={2}>
            <Field
              id="filter_campaign_daily_burn_transaction"
              name="activeCampaign"
              component={CustomSelect}
              type="text"
              labelText="Active Campaign"
              formControlProps={{
                fullWidth: true
              }}
              optionsList={campaignAllList}
            />
          </GridItem>

          <div style={{ display: 'contents' }}>
            <GridItem xs={12} sm={6} md={3} lg={3}>
              <Field
                id='filter_start_date_daily_burn_transaction'
                name="startDate"
                component={CustomDatePicker}
                type="text"
                minDate={startMinDate}
                maxDate={startMaxDate}
                labelText="Start Date"
                dateFormat="DD-MMM-YY"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3} lg={3}>
              <Field
                id='filter_end_date_daily_burn_transaction'
                name="endDate"
                component={CustomDatePicker}
                type="text"
                minDate={endMinDate}
                maxDate={endMaxDate}
                labelText="End Date"
                dateFormat="DD-MMM-YY"
                formControlProps={{
                  fullWidth: true
                }}
              />
            </GridItem>
          </div>

          <GridItem xs={12} sm={12} md={2} lg={2}>
            <Button
              type="submit"
              style={{ marginRight: "10px", marginTop: "25px" }}
              size="sm"
              color="primary"
              id='btn_submit'>
              Submit
              </Button>
          </GridItem>

        </GridContainer>

      </form>
    )
  }
}

FormFilterDailyBurnTransaction = reduxForm({
  form: FORM_NAME,
  enableReinitialize: false,
})(FormFilterDailyBurnTransaction)

const selector = formValueSelector(FORM_NAME)

FormFilterDailyBurnTransaction = connect(state => {
//   const activeChannel = selector(state, 'activeChannel')
  const campaignList = selector(state, 'campaignList')
  const startDate = selector(state, 'startDate')
  const endDate = selector(state, 'endDate')
  return {
    startDate, endDate, campaignList
  }
})(FormFilterDailyBurnTransaction)

export default FormFilterDailyBurnTransaction



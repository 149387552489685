import { ReferenceCodeConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";
import _ from 'lodash'

const initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    formCampaignlist: [],
    tableReset: false,
    tableReload: false,
    campaignData: [],
    tableDatas: {
        refs: [],
        total: 0
    },
    tableKey: 0,
    trdPartyCodeSet: [],
    trueMoneyRewardSet:[]
};

export function reference_code(state = initialState, action) {
    switch (action.type) {
        case ReferenceCodeConstants.CLEAR_PAGE_STATE:
            return initialState;
        case ReferenceCodeConstants.TABLE_GET_LIST_REFERENCE:
            return {
                ...state,
                tableDatas: action.tmp,
                tableReload: false,
                tableReset: false
            }
        case ReferenceCodeConstants.TABLE_RELOAD_SUCCESS:
        case ReferenceCodeConstants.TABLE_RESET_SUCCESS:
            return {
                ...state,
                tableReload: false,
                tableReset: false
            }
        case ReferenceCodeConstants.OPEN_ADD_REFERENCE:
            return {
                ...state,
                tableReload: false,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case ReferenceCodeConstants.OPEN_EDIT_REFERENCE:
            return {
                ...state,
                tableReload: false,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue
            }
        case ReferenceCodeConstants.CLOSE_FORM_REFERENCE:
            return {
                ...state,
                formOpen: false,
                formMode: 'add',
                formInitialValue: {}
            }
        case ReferenceCodeConstants.ADD_NEW_REFERENCE_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                formInitialValue: {}
            }
        case ReferenceCodeConstants.EDIT_REFERENCE_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case ReferenceCodeConstants.DELETE_REFERENCE_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case ReferenceCodeConstants.GET_REFERENCE_CAMPAIGNSLIST_REQUEST:
            return {
                ...state,
                formCampaignlist: []
            }

        case ReferenceCodeConstants.GET_REFERENCE_CAMPAIGNSLIST_SUCCESS:
            return {
                ...state,
                formCampaignlist: action.tmp
            }
    
        default:
            return state
    }
}
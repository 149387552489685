
import { businessWebsiteConstants } from '../constants';
import defaultLogoImage from "assets/img/sample-default-logo-position.png";
import defaultCoverImage from "assets/img/sample-default-bg-cover.jpg";
import lodash from 'lodash'
import * as ColorSet from "../views/BusinessWebsite/ColorSet";

const initialState = {
    formOpen: false,
    formMode: 'add',
    formOpenCropLogo: false,
    formOpenCropCover: false,
    formInitialValue: {},
    showLoader: false,
    businessLogoPreview: defaultLogoImage,
    businessCoverPreview: defaultCoverImage,
    businessLogo: defaultLogoImage,
    businessCover: defaultCoverImage,
    businessLink: 'www.WhatTheLuck!!.com',
    businessThemeColor: {   
        name: "eggYellow",
        primary : "#FFC600",
        fontIconPrimary : "#292929"
    },
    previewType : "",
    businessPrivacyAndTc : "",
    isOpen : false,
    changeLogo: false,   
    changeCover: false,
    businessLogoFile: '',
    businessCoverFile: '',
    labelTextMessenger: 'm.me/[FacebookID] (For example : m.me/eggdigital)',
    hintTextMessenger: '',
    labelTextLine: 'Line ID (For example : @eggdigital or ~lineid)',
    hintTextLine: '',
};

export function business_website(state = initialState, action) {
    switch (action.type) {
        case businessWebsiteConstants.CLEAR_PAGE_STATE:
            return initialState;
        case businessWebsiteConstants.SET_FORM_PREVIEW_BUSINESS_LOGO:
            return {
                ...state,
                businessLogoPreview: action.businessLogoPreview === "default" ? defaultLogoImage : action.businessLogoPreview
            }
        case businessWebsiteConstants.SET_FORM_PREVIEW_BUSINESS_COVER:
            return {
                ...state,
                businessCoverPreview: action.businessCoverPreview === "default" ? defaultCoverImage : action.businessCoverPreview
            }
        case businessWebsiteConstants.OPEN_CROP_BUSINESS_LOGO:
            return {
                ...state,
                formOpenCropLogo: true,
                businessLogo: action.businessLogoPreview === "default" ? defaultLogoImage : action.businessLogoPreview,
                changeLogo: true,
            }
        case businessWebsiteConstants.CLOSE_CROP_BUSINESS_LOGO:
            return {
                ...state,
                formOpenCropLogo: false,
                businessLogoPreview: action.businessLogoPreview === "cancel" ? state.businessLogoPreview : action.businessLogoPreview
            }
        case businessWebsiteConstants.OPEN_CROP_BUSINESS_COVER:
            return {
                ...state,
                formOpenCropCover: true,
                businessCover: action.businessCoverPreview === "default" ? defaultCoverImage : action.businessCoverPreview,
                changeCover: true,
            }
        case businessWebsiteConstants.CLOSE_CROP_BUSINESS_COVER:
            return {
                ...state,
                formOpenCropCover: false,
                businessCoverPreview: action.businessCoverPreview === "cancel" ? state.businessCoverPreview : action.businessCoverPreview
            }
        case businessWebsiteConstants.SET_BUSINESS_LINK:
            return {
                ...state,
                businessLink: lodash.get(action, 'businessLink') ? action.businessLink : ''
            }
        case businessWebsiteConstants.SET_BUSINESS_COLOR_THEME:
            return {
                ...state,
                businessThemeColor: lodash.get(action, 'businessThemeColor') ? action.businessThemeColor : ''
            }
        case businessWebsiteConstants.GET_BUSINESS_INFORMATION:
            let indextColor = lodash.findIndex(ColorSet.colorSet, {"name" : lodash.get(action.tmp, 'businessThemeColor') ? action.tmp.businessThemeColor : 'eggYellow'})
            return {
                ...state,
                formInitialValue: {
                    businessName: lodash.get(action.tmp, 'businessName') ? action.tmp.businessName : '',
                    phoneNumber: lodash.get(action.tmp, 'businessContact.phoneNumber') ? action.tmp.businessContact.phoneNumber : '',
                    messenger: lodash.get(action.tmp, 'businessContact.messenger') ? action.tmp.businessContact.messenger : '',
                    line: lodash.get(action.tmp, 'businessContact.line') ? action.tmp.businessContact.line : '',
                    businessDetail: lodash.get(action.tmp, 'businessDetail') ? action.tmp.businessDetail : '',
                    
                    businessLink: lodash.get(action.tmp, 'businessLink') ? action.tmp.businessLink : '',
                    businessLogoPreview: lodash.get(action.tmp, 'businessLogo.fileName') ? action.tmp.businessLogo.fileName : defaultLogoImage,
                    businessCoverPreview: lodash.get(action.tmp, 'businessCover.fileName') ? action.tmp.businessCover.fileName : defaultCoverImage,
                    businessThemeColor: lodash.get(ColorSet, 'colorSet['+indextColor+']') ? lodash.get(ColorSet, 'colorSet['+indextColor+']') : '#FFC600',

                    facebook: lodash.get(action.tmp, 'businessInformation.facebook') ? action.tmp.businessInformation.facebook : '',
                    instagram: lodash.get(action.tmp, 'businessInformation.instagram') ? action.tmp.businessInformation.instagram : '',
                    youtube: lodash.get(action.tmp, 'businessInformation.youtube') ? action.tmp.businessInformation.youtube : '',
                    website: lodash.get(action.tmp, 'businessInformation.website') ? action.tmp.businessInformation.website : '',
                },
                businessLink: lodash.get(action.tmp, 'businessLink') ? action.tmp.businessLink : '',
                businessLogoPreview: lodash.get(action.tmp, 'businessLogo.fileName') ? action.tmp.businessLogo.fileName : defaultLogoImage,
                businessCoverPreview: lodash.get(action.tmp, 'businessCover.fileName') ? action.tmp.businessCover.fileName : defaultCoverImage,
                businessThemeColor: lodash.get(ColorSet, 'colorSet['+indextColor+']') ? lodash.get(ColorSet, 'colorSet['+indextColor+']') : '#FFC600',
                labelTextMessenger: lodash.get(action.tmp, 'businessContact.messenger') ? '' : 'm.me/[FacebookID] (For example : m.me/eggdigital)',
                hintTextMessenger: lodash.get(action.tmp, 'businessContact.messenger') ? '' : '',
                labelTextLine: lodash.get(action.tmp, 'businessContact.line') ? '' : 'Line ID (For example : @eggdigital or ~lineid)',
                hintTextLine: lodash.get(action.tmp, 'businessContact.line') ? '' : '',
            }
        case businessWebsiteConstants.CLEAR_CHANGING_BUSINESS_WEBSITE:
            return {
                ...state,
                formInitialValue: {
                    businessName: lodash.get(state.formInitialValue, 'businessName') ? state.formInitialValue.businessName : '',
                    phoneNumber: lodash.get(state.formInitialValue, 'phoneNumber') ? state.formInitialValue.phoneNumber : '',
                    messenger: lodash.get(state.formInitialValue, 'messenger') ? state.formInitialValue.messenger : '',
                    line: lodash.get(state.formInitialValue, 'line') ? state.formInitialValue.line : '',
                    businessDetail: lodash.get(state.formInitialValue, 'businessDetail') ? state.formInitialValue.businessDetail : '',
                    
                    businessLink: lodash.get(state.formInitialValue, 'businessLink') ? state.formInitialValue.businessLink : '',
                    businessLogoPreview: lodash.get(state.formInitialValue, 'businessLogoPreview') ? state.formInitialValue.businessLogoPreview : defaultLogoImage,
                    businessCoverPreview: lodash.get(state.formInitialValue, 'businessCoverPreview') ? state.formInitialValue.businessCoverPreview : defaultCoverImage,
                    businessThemeColor: lodash.get(state.formInitialValue, 'businessThemeColor') ? state.formInitialValue.businessThemeColor : '#FFC600',

                    facebook: lodash.get(state.formInitialValue, 'facebook') ? state.formInitialValue.facebook : '',
                    instagram: lodash.get(state.formInitialValue, 'instagram') ? state.formInitialValue.instagram : '',
                    youtube: lodash.get(state.formInitialValue, 'youtube') ? state.formInitialValue.youtube : '',
                    website: lodash.get(state.formInitialValue, 'website') ? state.formInitialValue.website : '',
                },
                businessLink: lodash.get(state.formInitialValue, 'businessLink') ? state.formInitialValue.businessLink : '',
                businessLogoPreview: lodash.get(state.formInitialValue, 'businessLogoPreview') ? state.formInitialValue.businessLogoPreview : defaultLogoImage,
                businessCoverPreview: lodash.get(state.formInitialValue, 'businessCoverPreview') ? state.formInitialValue.businessCoverPreview : defaultCoverImage,
                businessThemeColor: lodash.get(state.formInitialValue, 'businessThemeColor') ? state.formInitialValue.businessThemeColor : '#FFC600',
                changeLogo: false,   
                changeCover: false,
                labelTextMessenger: lodash.get(state.formInitialValue, 'messenger') ? '' : 'm.me/[FacebookID] (For example : m.me/eggdigital)',
                hintTextMessenger: lodash.get(state.formInitialValue, 'messenger') ? '' : '',
                labelTextLine: lodash.get(state.formInitialValue, 'line') ? '' : 'Line ID (For example : @eggdigital or ~lineid)',
                hintTextLine: lodash.get(state.formInitialValue, 'line') ? '' : '',
            }
        case businessWebsiteConstants.UPDATE_BUSINESS_INFORMATION:
            return {
                ...state,
                changeLogo: false,   
                changeCover: false,
            }
        case businessWebsiteConstants.SET_BUSINESS_LOGO_FILE:
            return {
                ...state,
                businessLogoFile: action.businessLogoFile,
                businessLogoPreview: action.businessLogoPreview
            }
        case businessWebsiteConstants.SET_BUSINESS_COVER_FILE:
            return {
                ...state,
                businessCoverFile: action.businessCoverFile,
                businessCoverPreview: action.businessCoverPreview
            }
        case businessWebsiteConstants.GET_PRIVACY_NOTICE_AND_TC:
                return {
                    ...state,
                    businessPrivacyAndTc : lodash.get(action.tmp.data, 'descriptionTh') ? action.tmp.data.descriptionTh : '',
                    isOpen : true

                }
        case businessWebsiteConstants.CLOSE_PRIVACY_NOTICE_AND_TC:
                    return {
                        ...state,
                        businessPrivacyAndTc : '',
                        isOpen : false
    
                    }
        case businessWebsiteConstants.SET_HINT_TEXT_MESSENGER:
            return {
                ...state,
                labelTextMessenger: action.labelText,
                hintTextMessenger: action.hintText
            }
        case businessWebsiteConstants.SET_HINT_TEXT_LINE:
            return {
                ...state,
                labelTextLine: action.labelText,
                hintTextLine: action.hintText
            }
        case businessWebsiteConstants.SET_BUSINESS_INFORMATION_INITIAL_VALUE:
            indextColor = lodash.findIndex(ColorSet.colorSet, {"name" : lodash.get(action.tmp, 'businessThemeColor') ? action.tmp.businessThemeColor : 'eggYellow'})
            return {
                ...state,
                formInitialValue: {
                    ...state.formInitialValue,
                    businessName: lodash.get(action.tmp, 'businessName') ? action.tmp.businessName : '',
                    phoneNumber: lodash.get(action.tmp, 'businessContact.phoneNumber') ? action.tmp.businessContact.phoneNumber : '',
                    messenger: lodash.get(action.tmp, 'businessContact.messenger') ? action.tmp.businessContact.messenger : '',
                    line: lodash.get(action.tmp, 'businessContact.line') ? action.tmp.businessContact.line : '',
                    businessDetail: lodash.get(action.tmp, 'businessDetail') ? decodeURIComponent(atob(action.tmp.businessDetail))  : '',
                    
                    businessLogoPreview: lodash.get(state, 'businessLogoPreview') ? state.businessLogoPreview : defaultLogoImage,
                    businessCoverPreview: lodash.get(state, 'businessCoverPreview') ? state.businessCoverPreview : defaultCoverImage,
                    businessThemeColor: lodash.get(ColorSet, 'colorSet['+indextColor+']') ? lodash.get(ColorSet, 'colorSet['+indextColor+']') : '#FFC600',

                    facebook: lodash.get(action.tmp, 'businessInformation.facebook') ? action.tmp.businessInformation.facebook : '',
                    instagram: lodash.get(action.tmp, 'businessInformation.instagram') ? action.tmp.businessInformation.instagram : '',
                    youtube: lodash.get(action.tmp, 'businessInformation.youtube') ? action.tmp.businessInformation.youtube : '',
                    website: lodash.get(action.tmp, 'businessInformation.website') ? action.tmp.businessInformation.website : '',
                },
                businessLogoPreview: lodash.get(state, 'businessLogoPreview') ? state.businessLogoPreview : defaultLogoImage,
                businessCoverPreview: lodash.get(state, 'businessCoverPreview') ? state.businessCoverPreview : defaultCoverImage,
                businessThemeColor: lodash.get(ColorSet, 'colorSet['+indextColor+']') ? lodash.get(ColorSet, 'colorSet['+indextColor+']') : '#FFC600',
                labelTextMessenger: lodash.get(action.tmp, 'businessContact.messenger') ? '' : 'm.me/[FacebookID] (For example : m.me/eggdigital)',
                hintTextMessenger: lodash.get(action.tmp, 'businessContact.messenger') ? '' : '',
                labelTextLine: lodash.get(action.tmp, 'businessContact.line') ? '' : 'Line ID (For example : @eggdigital or ~lineid)',
                hintTextLine: lodash.get(action.tmp, 'businessContact.line') ? '' : '',
            }
        default:
            return state
    }
}

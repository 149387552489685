import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';
import objectToFormData from 'object-to-formdata';

export const luckydrawRewardServices = {
    getListsLuckydrawReward,
    addNewLuckydrawReward,
    editLuckydrawReward,
    deleteLuckydrawReward
};

function getListsLuckydrawReward(params) {
    let tmp = axios.post(configConstants.API_LUCKYDRAW + '/reward/search', params, {
        headers: setHeaders({ 'Charset': 'utf-8' })
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    })
        .catch(e => {
            return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
        });

    return tmp;
}

function addNewLuckydrawReward(params) {
    const formData = objectToFormData(
        params
    );    
    var tmp = axios.post(configConstants.API_LUCKYDRAW + '/reward', formData, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}

function editLuckydrawReward(params) {
    const formData = objectToFormData(
        params
    );
    var tmp = axios.put(configConstants.API_LUCKYDRAW + `/reward/${params._id}`, formData, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}


function deleteLuckydrawReward(params) {

    let tmp = axios.delete(configConstants.API_LUCKYDRAW + `/reward/${params._id}`, {
        headers: setHeaders()
    }).then(response => {
        if (response.data.statusCode.toString() === "200") {
            return {
                status: true,
                data: response.data.data,
                message: response.data.message
            };
        } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
        } else {
            return {
                status: false,
                data: {},
                message: response.data.message
            };
        }
    });

    return tmp;
}

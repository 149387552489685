export const photoGiveawayPhotoConstants = {
    OPEN_ADD_PHOTO_GIVEAWAY_PHOTO: 'OPEN_ADD_PHOTO_GIVEAWAY_PHOTO',
    OPEN_EDIT_PHOTO_GIVEAWAY_PHOTO: 'OPEN_EDIT_PHOTO_GIVEAWAY_PHOTO',
    CLOSE_FORM_PHOTO_GIVEAWAY_PHOTO: 'CLOSE_FORM_PHOTO_GIVEAWAY_PHOTO',
    ADD_NEW_PHOTO_GIVEAWAY_PHOTO_SUCCESS: 'ADD_NEW_PHOTO_GIVEAWAY_PHOTO_SUCCESS',
    EDIT_PHOTO_GIVEAWAY_PHOTO_SUCCESS: 'EDIT_PHOTO_GIVEAWAY_PHOTO_SUCCESS',
    DELETE_PHOTO_GIVEAWAY_PHOTO_SUCCESS: 'DELETE_PHOTO_GIVEAWAY_PHOTO_SUCCESS',
    GET_PHOTO_GIVEAWAY_PHOTO_DATA_SUCCESS: 'GET_PHOTO_GIVEAWAY_PHOTO_DATA_SUCCESS',
    TABLE_GET_LIST_PHOTO_GIVEAWAY_PHOTOS: 'TABLE_GET_LIST_PHOTO_GIVEAWAY_PHOTOS',
    TABLE_RELOAD_SUCCESS: 'TABLE_PHOTO_GIVEAWAY_PHOTO_RELOAD_SUCCESS',
    TABLE_RESET_SUCCESS: 'TABLE_PHOTO_GIVEAWAY_PHOTO_RESET_SUCCESS',
    SET_FORM_PREVIEW_IMAGE: 'PHOTO_GIVEAWAY_PHOTO_SET_FORM_PREVIEW_IMAGE',
    CLEAR_PAGE_STATE: 'PHOTO_GIVEAWAY_PHOTO_CLEAR_PAGE_STATE',
    GET_CAMPAIGN_DATA_SUCCESS: 'GET_CAMPAIGN_DATA_SUCCESS',
    SHOW_TABLE_LOADER: 'SHOW_PHOTO_GIVEAWAY_PHOTO_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_PHOTO_GIVEAWAY_PHOTO_TABLE_LOADER'
 };
 
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay'
import { connect } from 'react-redux';

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

// import routesjs from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/egg-logo.png";

import ErrorPage from "views/Pages/ErrorPage.jsx";

// import Snackbar from "components/Snackbar/Snackbar.jsx";
// import AddAlert from "@material-ui/icons/AddAlert";
// import Close from "@material-ui/icons/Close";

// import Button from "components/CustomButtons/Button";

import Slide from "@material-ui/core/Slide";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";

import { authActions } from 'actions';
import { urlPath, alert as customAlert } from 'helpers';

import NotifyUpgradePackage from "components/SweetAlert/NotifyUpgradePackage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

var ps;
// var routes = routesjs
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    localStorage.getItem('token')
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
  )}
  />
)

const defaultPage = () => {
  return (
    <>
    </>
  )
}

const check_menu = ["dashboard", "member", "report"]

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: image,
      color: "primary",
      bgColor: "black",
      hasImage: false,
      fixedClasses: "dropdown",
      routes: [],
      showWrapper: false,
      avatarName: '',
      permission: [],
      showNotifyOverLimit: false
    };

    this.resizeFunction = this.resizeFunction.bind(this);
  }

  onRouteChanged() {
    const { dispatch } = this.props;
    dispatch(authActions.getRole(this.props.location.pathname.split("/")[1]));
    if (check_menu.includes(this.props.location.pathname.split("/")[1])) {
      this.setState({ showNotifyOverLimit: true })
    }
    dispatch(authActions.getEnvConfig());
  }

  componentWillMount() {
    this.setState({ showWrapper: true })
    this.onRouteChanged()
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    try {
      if (navigator.platform.indexOf("Win") > -1 && this.refs.mainPanel) {
        ps = new PerfectScrollbar(this.refs.mainPanel, {
          suppressScrollX: true,
          suppressScrollY: false
        });
        document.body.style.overflow = "hidden";
      }
      if (e.history.location.pathname !== e.location.pathname) {
        this.refs.mainPanel.scrollTop = 0;
        if (this.state.mobileOpen) {
          this.setState({ mobileOpen: false });
        }

        if (check_menu.includes(this.props.location.pathname.split("/")[1])) {
          this.setState({ showNotifyOverLimit: true })
        }
      }
    } catch (error) {

    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getActiveRoute = routes => {
    let activeRoute = "EGG-CRM";
    if (routes !== undefined) {
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveRoute = this.getActiveRoute(routes[i].views);
          if (collapseActiveRoute !== activeRoute) {
            return collapseActiveRoute;
          }
        } else {
          if (
            window.location.href.indexOf(routes[i].path) !== -1
          ) {
            return routes[i].displayName;
          }
        }
      }
      return activeRoute;
    }
    return false
  };

  getRoutes = routes => {
    if (routes !== undefined) {
      return routes.map((prop, key) => {
        if (prop.collapse) {
          return this.getRoutes(prop.views);
        }
        return (
          !prop.disabled ? <Route
            path={urlPath + prop.path}
            component={prop.component}
            key={key}
          /> : null
        );
      });
    }

    return false
  };

  getRouteAdmmins = routes => {
    if (routes !== undefined) {
      return routes.map((prop, key) => {
        if (prop.collapse) {
          return this.getRouteAdmmins(prop.views);
        }
        return (
          <Route
            path={urlPath + prop.path}
            component={prop.component}
            key={key}
          />
        );
      });
    }

    return false
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, loader, routes, user, showError, overLimitMember, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });

    let avatarName = user.email

    return (
      <>
        {routes === undefined ?
          <LoadingOverlay
            active={loader}
            spinner
            text='LOADING...'
            styles={{
              overlay: (base) => ({
                ...base,
                'z-index': '9999',
                'height': "100vh"
              })
            }}
          />
          :
          <LoadingOverlay
            active={loader}
            spinner
            text='LOADING...'
            styles={{
              overlay: (base) => ({
                ...base,
                'z-index': '9999'
              })
            }}
          >
            <div className={classes.wrapper}>
              {this.state.showWrapper === true ?
                <Sidebar
                  routes={routes}
                  logoText={"EGG-CRM"}
                  logo={logo}
                  avatarName={avatarName}
                  //image={this.state.image}
                  handleDrawerToggle={this.handleDrawerToggle}
                  open={this.state.mobileOpen}
                  color={this.state.color}
                  bgColor={this.state.bgColor}
                  miniActive={this.state.miniActive}
                  defaultMerchantId={user.defaultMerchantId}
                  masterAccount={user.masterAccount}
                  masterLists={user.masterLists}
                  {...rest}
                />
                : null}
              <div className={mainPanel} id="main-panel" ref="mainPanel" style={{ display: (this.state.showWrapper ? "block" : "none") }}>
                <AdminNavbar
                  sidebarMinimize={this.sidebarMinimize.bind(this)}
                  miniActive={this.state.miniActive}
                  sectionName={this.getActiveRoute(routes)}
                  handleDrawerToggle={this.handleDrawerToggle}
                  {...rest}
                />
                {/* <Dialog
                  classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                  }}
                  open={this.state.showNotifyOverLimit && overLimitMember}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => { this.setState({ showNotifyOverLimit: false }) }}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                  color="danger"
                  style={{ color: "red" }}
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  // style={{color: "red"}}
                  >
                    <h4 className={classes.modalTitle}>
                      <b> You are restricted from using most features due to the number of member exceed package limitation </b>
                    </h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                      Your members will not be able to correct point and redeem reward. And in back office, you can only manage your members and view report data
                    </p>
                    <p>
                      For full functionality, please upgrade your package
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter} style={{ justifyContent: "center" }}>
                    <Button
                      onClick={() => { this.setState({ showNotifyOverLimit: false }) }}
                      // color="danger"
                      // simple
                      variant="outlined" color="danger"
                    >
                      UPGRADE PACKAGE
                    </Button>
                  </DialogActions>
                </Dialog> */}

                {/* <Snackbar
                  place="tc"
                  color="danger"
                  icon={AddAlert}
                  message="You are restricted from using most features due to the number of member exceed package limitation."
                  open={true}
                  closeNotification={true}
                  close
                /> */}
                <NotifyUpgradePackage open={this.state.showNotifyOverLimit && overLimitMember} closeModal={() => { this.setState({ showNotifyOverLimit: false }) }} />
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      <PrivateRoute path="/" exact component={defaultPage} />
                      {this.getRoutes(routes)}
                      {/* {this.getRouteAdmmins(routeAdminsjs)} */}
                      {showError && <Route path="*" component={ErrorPage} />}
                    </Switch>
                  </div>
                </div>

                <Footer fluid={true} />
              </div>
            </div>
          </LoadingOverlay>}
      </>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { loader } = state.loader;
  const { routes, user, showError, overLimitMember } = state.authentication;
  return {
    loader, routes, user, showError, overLimitMember
  };
}

const connectApp = connect(mapStateToProps)(withStyles(appStyle)(Dashboard));
export { connectApp as AdminLayout }

// export default withStyles(appStyle)(Dashboard);

export const merchantConstants = {
    OPEN_MANAGEMENT_ADD: 'OPEN_MANAGEMENT_PAGE_ADD',
    OPEN_MANAGEMENT_EDIT: 'OPEN_MANAGEMENT_PAGE_EDIT',
    OPEN_ADD: 'OPEN_ADD_MERCHANTT',
    OPEN_EDIT: 'OPEN_EDIT_MERCHANTT',
    CLOSE_FORM: 'CLOSE_FORM_MERCHANTT',
    ADD_NEW_SUCCESS: 'ADD_NEW_MERCHANTT_SUCCESS',
    EDIT_SUCCESS: 'EDIT_MERCHANTT_SUCCESS',
    DELETE_SUCCESS: 'DELETE_MERCHANTT_SUCCESS',
    GET_DATA_SUCCESS: 'GET_MERCHANTT_DATA_SUCCESS',
    TABLE_GET_LIST: 'TABLE_GET_LIST_MERCHANTTS',
    TABLE_RELOAD_SUCCESS: 'TABLE_MERCHANTT_RELOAD_SUCCESS',
    TABLE_RESET_SUCCESS: 'TABLE_MERCHANTT_RESET_SUCCESS',
    SET_FORM_PREVIEW_IMAGE: 'MERCHANTT_SET_FORM_PREVIEW_IMAGE',
    CLEAR_PAGE_STATE: 'MERCHANTT_CLEAR_PAGE_STATE',

    SHOW_TABLE_LOADER: 'SHOW_MERCHANT_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_MERCHANT_TABLE_LOADER',

    ADD_BUDGET_SUCCESS: 'ADD_BUDGET_SUCCESS',
    GET_BUDGET_SUCCESS: 'GET_BUDGET_SUCCESS',
    CLEAR_BUDGET_LISTS: 'CLEAR_BUDGET_LISTS',
    RELOAD_BUDGET_LISTS: 'RELOAD_BUDGET_LISTS',
    BUDGET_RELOAD_SUCCESS: 'BUDGET_RELOAD_SUCCESS',
};

import { alertConstants } from '../constants';

export const alertActions = {
   modal_success,
   modal_error,
   modal_clear
};

function modal_success(message) {
   return { type: alertConstants.SUCCESS, message };
}

function modal_error(message) {
   return { type: alertConstants.ERROR, message };
}

function modal_clear() {
   return { type: alertConstants.CLEAR };
}

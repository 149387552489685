import _ from 'lodash';

import { tmn_rewardConstants } from '../constants';
import defaultImage from "assets/img/image_placeholder.jpg";

const initialState = {
   isOpen: false,
   formOpen: false,
   formMode: 'add',
   rewardImagePreview: defaultImage,
   formInitialValue: {},
   tableReload: false,
   sweetAlert: null,
   tableReset: false,
   resetBudget: false,
   tableDatas: {
      result: [],
      total: 0
   },
   tableKey: 0,
   tableLoader: false
};

export function tmn_reward(state = initialState, action) {
   switch (action.type) {
      case tmn_rewardConstants.CLEAR_PAGE_STATE:
         return initialState;
      case tmn_rewardConstants.OPEN_FORM_ADD:
         return {
            ...state,
            formOpen: true,
            formMode: 'add',
            formInitialValue: {
               _id: "",
               name: "",
               description: "",
               rewardImage: "",
               budget: ""
            },
            rewardImagePreview: defaultImage,
         };
      case tmn_rewardConstants.OPEN_FORM_EDIT:
         return {
            ...state,
            formOpen: true,
            formMode: 'edit',
            formInitialValue: {
               _id: _.get(action, 'data._id'),
               name: _.get(action, 'data.name'),
               description: _.get(action, 'data.description'),
               budget: _.get(action, 'data.budget'),
               status: _.get(action, 'data.status'),
            },
            rewardImagePreview: action.rewardImagePreview,
         };
      case tmn_rewardConstants.CLOSE_FORM:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            rewardImagePreview: defaultImage,
         };
      case tmn_rewardConstants.TABLE_GET_LIST_DATAS:
         return {
            ...state,
            tableDatas: action.tmp,
            tableReload: false,
            tableReset: false,
         }
      case tmn_rewardConstants.TABLE_RELOAD:
      case tmn_rewardConstants.TABLE_RELOAD_SUCCESS:
         return {
            ...state,
            tableReload: false,
            tableReset: false,
         }
      case tmn_rewardConstants.TABLE_RESET:
         return {
            ...state,
            tableReset: true
         }
      case tmn_rewardConstants.FORM_RESET_SUCCESS:
         return {
            ...state,
            resetBudget: false
         }
      case tmn_rewardConstants.TABLE_RESET_SUCCESS:
         return {
            ...state,
            tableReset: false
         }
      case tmn_rewardConstants.ADD_NEW_SUCCESS:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            tableReload: false,
            tableReset: true,
            tableKey: new Date(),
         };
      case tmn_rewardConstants.EDIT_SUCCESS:
         return {
            ...state,
            formOpen: false,
            formInitialValue: {},
            tableReset: false,
            tableReload: true
         };
      case tmn_rewardConstants.ADD_BUDGET_SUCCESS:
         return {
            ...state,
            resetBudget: true,
            tableReload: true,
            formInitialValue: {
               ...state.formInitialValue,
               budget: _.get(action, 'tmp.netTotal')
            },
         };
      case tmn_rewardConstants.EDIT_FAILURE:
         return state;
      case tmn_rewardConstants.DELETE_REQUEST:
         return state
      case tmn_rewardConstants.DELETE_SUCCESS:
         return {
            ...state,
            formOpen: false,
            tableReload: true,
         };
      case tmn_rewardConstants.DELETE_FAILURE:
         return {
            ...state,
            formOpen: false,
         };

      case tmn_rewardConstants.HIDE_ALERT:
         return {
            ...state,
            showAlertDeleteSuccess: false,
            showAlertDeleteFailed: false
         };
      case tmn_rewardConstants.SHOW_TABLE_LOADER:
         return {
            ...state,
            tableLoader: true
         }
      case tmn_rewardConstants.HIDE_TABLE_LOADER:
         return {
            ...state,
            tableLoader: false
         }
      case tmn_rewardConstants.SET_FORM_PREVIEW_IMAGE:
         return {
             ...state,
             rewardImagePreview: action.rewardImagePreview === "default" ? defaultImage : action.rewardImagePreview
         }
      default:
         return state
   }
}
import { configConstants } from '../constants';
import axios from 'axios';
import { history, setHeaders } from 'helpers';

export const tmnServices = {
   getRewardLists,
   apiAddReward,
   apiUpdateReward,
   getRewardById,
   apiAddRewardBudget
};

function getRewardLists(params) {
   let tmp = axios.get(configConstants.API_BACKEND_CRM + `/reward/tmn?${params}`, { headers: setHeaders({ 'Charset': 'utf-8' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      })
      .catch(e => {
         return { status: false, data: {}, message: "SOMETHING_WENT_WRONG" };
      });

   return tmp;
}

function apiAddReward(values) {
   let formData = new FormData()
   formData.set("name", values.name);
   formData.set("description", values.description);
   formData.append('file', values.rewardImage);
   formData.set("budget", values.budget);

   let tmp = axios.post(configConstants.API_BACKEND_CRM + '/reward/tmn', formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });
   return tmp;
}

function apiUpdateReward(values) {
   let rewardId = values._id;

   let formData = new FormData();
   formData.set("name", values.name);
   formData.set("description", values.description);
   formData.set("status", values.status);
   formData.append('file', values.rewardImage);

   let tmp = axios.put(configConstants.API_BACKEND_CRM + `/reward/tmn/${rewardId}`, formData, { headers: setHeaders({ 'Content-Type': 'multipart/form-data' }) })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });
   return tmp;
}

function getRewardById(rewardId) {
   let tmp = axios.get(configConstants.API_BACKEND_CRM + `/reward/tmn/${rewardId}`, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return {
               status: true,
               data: response.data.data,
               message: response.data.message
            };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return {
               status: false,
               data: {},
               message: response.data.message
            };
         }
      });
   return tmp;
}

function apiAddRewardBudget(values) {
   let rewardId = values._id;

   let bodyData = {
      amount: values.amount
   }

   let tmp = axios.post(configConstants.API_BACKEND_CRM + `/reward/tmn/${rewardId}/add-budget`, bodyData, { headers: setHeaders() })
      .then(response => {
         if (response.data.statusCode.toString() === "200") {
            return { status: true, data: response.data.data, message: response.data.message };
         } else if (response.data.message === "UNAUTHORIZE") {
            history.push('/auth/login')
            return { status: false, data: {}, message: response.data.message, unAlert: true };
         } else {
            return { status: false, data: {}, message: response.data.message };
         }
      });
   return tmp;
}

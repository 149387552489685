import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import MUIDataTable from "mui-datatables";
import _ from "lodash";

// material-ui components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { merchantConstants } from "../../constants";
import { merchantActions } from "actions";

import { plugins, crmValidator } from "helpers";

import i18n from 'i18n';

const FORM_NAME = "TRUE_MONEY_BUDGET";

var form_validate = {
   "budget": {
      rules: {
         required: true,
         number: true,
         min: 1,
      },
      messages: {
         required: "warning_messages.budget_is_required",
         number: "warning_messages.cannot_enter_negative_value",
         min: "warning_messages.number_greater_than_zero"
      },
      alert: "warning_messages.budget_is_required"
   }
};

const style = {
   customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
   },
   cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
   },
   cardFooter: {
      padding: "15px",
      textAlign: "right",
      paddingTop: "0",
      margin: "0"
   },
   labelHorizontalCenter: {
      float: "right",
      color: "rgba(0, 0, 0, 0.26)",
      cursor: "pointer",
      display: "inline-flex",
      fontSize: "14px",
      lineHeight: "1.428571429",
      fontWeight: "400",
      paddingTop: "5px",
      marginRight: "0"
   },
   root: {
      height: 110,
      flexGrow: 1,
      maxWidth: 400
   },
   head: {
      lineHeight: "24px",
      fontSize: "13px",
      fontWeight: "405",
      position: "relative",
      marginLeft: "39px"
   },
   gridLabel: {
      paddingTop: "10px"
   },
   gridLine: {
      paddingTop: "0px"
   }
};

const THEME = createMuiTheme({
   ...MTablestyles,
   overrides: {
      ...MTablestyles.overrides,
      MUIDataTable: {
         responsiveScroll: {
            minHeight: "490px"
         }
      },
      MUIDataTableHeadCell: {
         fixedHeader: {
            "&:nth-child(1)": {
               width: "40%"
            },
            "&:nth-child(2)": {
               width: "40%"
            }
         }
      },
      MUIDataTableResponsiveScroll: {
         position: { 'relative;min-height': '300px;' }
      }
   }
});

const validate = values => {
   const errors = crmValidator.validateByRulesList(form_validate, values);
   return errors;
};


class TrueMoneyBudget extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 0,
         rowsPerPage: 10,
         filter: {
            skip: 0,
            limit: 10,
            sortBy: "created_at",
            sortDir: "desc",
            keyword: ""
         },
         reqParams: ""
      };

   }

   componentDidMount() {
      this.getData();
   }

   componentDidUpdate() {
      const { tableBudgetReset, dispatch } = this.props;
      if (tableBudgetReset) {
         this.getData();
         dispatch({ type: merchantConstants.BUDGET_RELOAD_SUCCESS })
      }
   }

   getData(skip, limit, sortBy, sortDir, keyword) {
      const { dispatch, formInitialValue, tableBudget } = this.props;
      const merchantId = _.get(formInitialValue, 'merchantID')
      let { filter } = this.state;
      let newFilter = filter;

      let reqParams = "";

      if (!tableBudget.tableReload) {
         if (crmValidator.isEmptyValue(skip)) {
            skip = 0
         }

         if (crmValidator.isEmptyValue(limit)) {
            limit = 10
         }

         this.setState({
            page: skip,
            rowsPerPage: limit
         })
         skip = parseInt(limit) * parseInt(skip)

         reqParams += '&skip=' + skip
         reqParams += '&limit=' + limit

         if (!crmValidator.isEmptyValue(sortBy)) {
            reqParams += '&sortBy=' + sortBy
         }

         if (!crmValidator.isEmptyValue(sortDir)) {
            reqParams += '&sortDir=' + sortDir
         }

         if (!crmValidator.isEmptyValue(keyword)) {
            reqParams += '&keyword=' + encodeURIComponent(keyword)
         }
      } else {
         reqParams = this.state.reqParams
      }

      dispatch(merchantActions.getMerchantBudgetLists(merchantId, reqParams));

      this.setState({
         isLoading: true,
         filter: newFilter,
         reqParams: reqParams
      });
   }

   handleSubmit = data => {
      const { dispatch, formInitialValue } = this.props;

      const merchantId = _.get(formInitialValue, 'merchantID')
      const values = {
         amount: data.budget
      }
      dispatch(merchantActions.addBudgetToMerchant(merchantId, values));
      dispatch(reset(FORM_NAME))
   };

   handleClose = values => {
      const { dispatch } = this.props;
      dispatch({ type: merchantConstants.CLOSE_FORM });
   };

   render() {
      const { formMode, tableBudget, handleSubmit, classes } = this.props;
      const { rowsPerPage, page } = this.state;
      const displayCard = {};
      let { readOnly } = this.props;

      let merchantBudget = parseInt(_.get(tableBudget, 'total.merchantBudget'))
      let reservedBudget = parseInt(_.get(tableBudget, 'total.reservedBudget'))
      let availableBudget = parseInt(_.get(tableBudget, 'total.availableBudget'))

      if (formMode === `add`) {
         readOnly = false;
      } else if (formMode === `edit` && !readOnly) {
         readOnly = false;
      } else {
         readOnly = true;
      }

      const columns = [
         {
            label: i18n.t("label.no"),
            options: {
               filter: false,
               sort: false,
               customBodyRender: (value, tableMeta, updateValue) => {
                  const rowSatrt = page * rowsPerPage
                  const rowIndex = rowSatrt + tableMeta.rowIndex;

                  return rowIndex + 1;

               }
            }
         },
         {
            name: "created_at",
            label: "Transaction Date/Time",
            options: {
               customBodyRender: value => {
                  // return moment(value).format('DD-MMM-YYYY')

                  return value ? plugins.datetimeFormat(value, "fulldatetime") : ""
               }
            }
         },
         {
            name: "displayAmount", label: "Amount", options: {
               customBodyRender: value => {
                  return (value < 0) ? <p style={{ color: "red" }} > {plugins.numberFormat(value)} </p> : <p> +{plugins.numberFormat(value)} </p>
               }
            }
         },
         { name: "remark", label: "Remark" }
      ];

      const options = {
         serverSide: true,
         count: _.get(tableBudget, 'total.result') || 0,
         page: page,
         search: false,
         viewColumns: false,
         rowsPerPage: rowsPerPage,
         fixedHeader: true,
         selectableRows: "none",
         print: false,
         download: false,
         filter: false,
         responsive: "scroll",
         rowsPerPageOptions: [1, 5, 10, 20],
         onTableChange: (action, tableState) => {
            let skip,
               limit,
               sortBy,
               sortDir,
               keyword = "";
            if (tableState.page !== null) {
               skip = tableState.page;
            }

            if (tableState.rowsPerPage !== null) {
               limit = tableState.rowsPerPage;
            }

            if (tableState.activeColumn !== null) {
               sortBy = tableState.columns[tableState.activeColumn].name;
            }
            if (tableState.announceText !== null) {
               sortDir =
                  tableState.announceText.search("descending") !== -1
                     ? "desc"
                     : "asc";
            }
            if (tableState.searchText !== null || action === "search") {
               keyword = tableState.searchText;
               this.delayedCallback(skip, limit, sortBy, sortDir, keyword);
            }
            let actionList = ["changePage", "sort", "changeRowsPerPage"];
            if (actionList.indexOf(action) !== -1) {
               this.getData(skip, limit, sortBy, sortDir, keyword);
            }
         }
      };

      return (
         <div style={displayCard}>
            <CardHeader icon>
               <h4 className={classes.cardIconTitle} style={{ marginLeft: "5px", marginBottom: "15px" }}>
                  <b>True money budget</b>
               </h4>
            </CardHeader>

            <CardBody>
               <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                  <GridContainer>
                     <GridItem xs={9} sm={9} md={9}>
                        <GridContainer style={{ marginTop: "30px" }} >
                           <GridItem xs={4} sm={4} md={4}>
                              <b>Merchant Budget: {plugins.numberFormat(merchantBudget)}</b>
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <b>Reserved Budget: {plugins.numberFormat(reservedBudget)}</b>
                           </GridItem>
                           <GridItem xs={4} sm={4} md={4}>
                              <b>Budget Availabled: {plugins.numberFormat(availableBudget)}</b>
                           </GridItem>
                        </GridContainer>
                     </GridItem>
                     <GridItem xs={3} sm={3} md={3}>
                        <GridContainer>
                           <GridItem xs={6} sm={6} md={6}>
                              <Field
                                 name="budget"
                                 component={CustomInput}
                                 type="text"
                                 labelText="Add budget"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, form_validate["budget"])
                                 }}
                                 disabled={readOnly}
                              />
                           </GridItem>
                           <GridItem xs={6} sm={6} md={6}>
                              <Button
                                 type="submit"
                                 color="success"
                              >
                                 ADD BUDGET
                           </Button>
                           </GridItem>
                        </GridContainer>
                     </GridItem>
                  </GridContainer>
               </form>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                     {/* <div id="tableMerchantBudget" > */}
                     <MuiThemeProvider theme={THEME}>
                        <MUIDataTable
                           key={Date.now()}
                           data={tableBudget.data}
                           columns={columns}
                           options={options}
                        />
                     </MuiThemeProvider>

                     {/* </div> */}
                  </GridItem>
               </GridContainer>
               <GridContainer style={{ marginTop: "60px" }}>
                  <GridItem xs={12} sm={12} md={12}>
                     <Button
                        type="button"
                        onClick={this.handleClose}
                        style={{ marginLeft: "10px" }}
                        color="white"
                     >
                        Cancel
                     </Button>
                  </GridItem>
               </GridContainer>
            </CardBody>
         </div >
      );
   }
}

function mapStateToProps(state) {
   const { formOpen, formMode, formInitialValue, tableBudget, tableBudgetReset } = state.merchant;
   const { FORM_MERCHANT } = state.form;
   return {
      formOpen,
      formMode,
      formInitialValue,
      tableBudget,
      tableBudgetReset,
      FORM_MERCHANT
   };
}

const connectMerchantForm = connect(mapStateToProps)(TrueMoneyBudget);

export default reduxForm({
   form: FORM_NAME,
   validate: validate,
   enableReinitialize: true,
   onSubmitFail: (errors, dispatch, props) => {
      crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
   }
})(withStyles({ ...style, ...regularFormsStyle })(connectMerchantForm));

import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import lodash from 'lodash';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// core components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import { adminConstants, swalConstants } from '../../constants';
import { adminActions } from 'actions';
import { plugins, crmValidator } from 'helpers';

import AdminForm from "./AdminForm.jsx";

import i18n from 'i18n';

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};
const moduleName = 'admin'

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme(MTablestyles);

const thaiPhoneFormat = str => {
    str = str.replace(/-/g, '').replace(/\s/g, '');
    return str
}
class TableAdminList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        };

        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload } = this.props

        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + encodeURIComponent(keyword)
            }
        } else {
            reqParams = this.state.reqParams
        }

        dispatch(adminActions.getTableDatas(reqParams));

        this.setState({
            isLoading: true,
            reqParams: reqParams
        });

    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.admins;
        return data[rowIndex];
    }

    componentWillMount() {
        const { dispatch } = this.props;

        dispatch({ type: adminConstants.CLEAR_PAGE_STATE });
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: adminConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    componentDidMount() {
        this.getData();
    }

    openFormAdd = () => {
        const { dispatch } = this.props;

        dispatch({ type: adminConstants.OPEN_FORM_ADD, formInitialValue: {} })
    }

    openFormEdit(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.admins[index]

        dispatch({ type: adminConstants.OPEN_FORM_EDIT, formInitialValue: rowData })
    }

    render() {
        const { classes, dispatch, formInitialValue, tableDatas, tableKey, visiblePersonalData, tableLoader } = this.props;
        const { rowsPerPage, page } = this.state;

        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "displayId", options: { filter: false, display: "excluded" } },
            {
                label: i18n.t("label.no"),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            {
                name: "roles.role", label: i18n.t("label.role"), options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return lodash.get(rowData, "roles[role]")
                    }
                }
            },
            { name: "name", label: i18n.t("label.admin_name") },
            { name: "email", label: i18n.t("label.email") },
            {
                name: "phoneNumber", label: i18n.t("label.tel"), options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return lodash.get(rowData, "phoneNumber") ? thaiPhoneFormat(lodash.get(rowData, "phoneNumber")) : ''
                    }
                }
            },
        ];

        if (plugins.isHasPermission(moduleName, 'read') && visiblePersonalData) {
            columns.push({
                label: i18n.t("label.action"), options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classes.actionDiv}>
                                {plugins.isHasPermission(moduleName, 'update') &&
                                    <TableActionButton
                                        actionType="edit"
                                        onClick={(e) => {
                                            this.openFormEdit(tableMeta.rowIndex)
                                        }}
                                    />
                                }
                                {plugins.isHasPermission(moduleName, 'delete') &&
                                    <TableActionButton
                                        actionType="delete"
                                        onClick={(e) => {
                                            dispatch({
                                                type: swalConstants.SHOW, data: {
                                                    type: "warning",
                                                    title: i18n.t("warning_messages.are_you_sure_delete_admin"),
                                                    message: `${this.getRowData(tableMeta.rowIndex).name}`,
                                                    onConfirm: function () {
                                                        let reqParams = {
                                                            _id: tableMeta.rowData[0]
                                                        }
                                                        dispatch({ type: swalConstants.HIDE })
                                                        dispatch(adminActions.deleteAdmin(reqParams));
                                                    },
                                                    onCancel: function () {
                                                        dispatch({ type: swalConstants.HIDE })
                                                    },
                                                    showCancel: true,
                                                    confirmBtnText: i18n.t("delete"),
                                                    cancelBtnText: i18n.t("cancel")
                                                }
                                            })
                                        }}
                                    />
                                }
                            </div>
                        )
                    }
                }
            })
        }

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        if (plugins.isHasPermission(moduleName, 'create') && visiblePersonalData) {
            options.customToolbar = () => {
                return (
                    <CustomToolbar handleClick={this.openFormAdd} title="Add New" />
                );
            }
        }

        return (
            <div id="tableAdminList">
                <CustomSweetAlert />
                <AdminForm
                    initialValues={formInitialValue}
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                />

                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        title={i18n.t("label.admin_list")}
                        data={tableDatas.admins}
                        columns={columns}
                        options={options}
                    />

                    {tableLoader && <TableLoaderSpinner />}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader } = state.admin;
    const { visiblePersonalData } = state.authentication;

    return {
        formInitialValue, tableReload, tableReset, visiblePersonalData, tableDatas, tableKey, tableLoader
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableAdminList));
import React from "react";
import { Field, change } from 'redux-form';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox.jsx";

import _ from "lodash";



function FormList({ ...props }) {
   const  { setNameValue, setNamekey, setValue, dispatch, isShowAll } = props

   return (
      <>
        { _.map(setValue, (val,key)=> {
            let isShow = val.status === 'show' ? true : false 
            
            return (
                
                <GridContainer key={`${val.fieldName}${key}`}>
                    <GridItem xs={6} sm={4} md={4}>
                        <div
                            style={{ paddingTop: "20px"  }}
                        >
                            <span>{val.fieldName}</span>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4}>
                        <Field
                            name={`tableName[${setNamekey}][${val.fieldName}][type]`}
                            component="input"
                            type="hidden"
                        />
                    
                        <Field
                            name={`tableName[${setNamekey}][${val.fieldName}][displayName]`}
                            component={CustomInput}
                            type="text"
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                    <GridItem xs={6} sm={4} md={4}>
                    <div
                        style={{ paddingTop: "20px"  }}
                    >
                            <Field
                                style={{ }}
                                name={`tableName[${setNamekey}][${val.fieldName}][status]`}
                                component={CustomCheckbox}
                                id={`check_${setNamekey}_${val.fieldName}`}
                                checked={(isShow)}
                                onChange={(e,newValue) => {
                                    dispatch(change('datafilteringList', `tableName[${setNamekey}][${val.fieldName}][status]`, newValue))
                                    
                                }}
                                // labelText={`${val.status}_${isShow}`}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
                
            )
            
        }
        )}
         
      </>
   );
}

export default FormList;

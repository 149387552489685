import { reset } from 'redux-form'
import lodash from 'lodash';

import { winnerApproveConstants } from '../constants';
import { winnerApproveServices } from '../services';
import { loader, alert as customAlert } from 'helpers';

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const winnerApproveActions = {
   getAnnounceDateList,
   getRewardList,
   getTableDatas,
   approveWinner,
   approveWinnerUpload,
   rejectWinner
};

function getAnnounceDateList(params) {
   return dispatch => {
      winnerApproveServices.getAnnounceDateList(params)
         .then(
            response => {
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     dispatch(success(response.data));
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: winnerApproveConstants.GET_DROPDOWN_ANNOUNCE_DATE_LIST_SUCCESS, data }
   }
}

function getRewardList(params) {
   return dispatch => {
      winnerApproveServices.getRewardList(params)
         .then(
            response => {
               if (response.status) {
                  if (lodash.has(response, 'data')) {
                     dispatch(success(response.data));
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            // loader(dispatch, false)
         })
   };

   function success(data) {
      return { type: winnerApproveConstants.GET_DROPDOWN_REWARD_LIST_SUCCESS, data }
   }
}

function getTableDatas(params, tableReset = false) {
   return dispatch => {
      dispatch({ type: winnerApproveConstants.SHOW_LOADER_WINNER_APPROVE })
      winnerApproveServices.getListsWinner(params)
         .then(
            response => {
               if (response.status) {
                  dispatch({ type: winnerApproveConstants.TABLE_GET_LIST_DATAS, data: response.data.data, total: response.data.total, tableFilter: params });
                  if (tableReset) {
                     dispatch({ type: winnerApproveConstants.TABLE_GET_LIST_DATAS_RESET });
                  }
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: winnerApproveConstants.HIDE_LOADER_WINNER_APPROVE })
         })
   };
}

function approveWinner(params, tableReset = false) {
   return dispatch => {
      loader(dispatch, true)
      winnerApproveServices.approveWinner(params)
         .then(
            response => {
               //console.log(response)
               if (response.status) {
                  dispatch({
                     type: winnerApproveConstants.APPROVE_WINNER_SUCCESS
                  });

                  customAlert(dispatch, 'success', i18n.t('warning_messages.winner_was_approved'));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
               }
            },
            error => {
               //console.log(error)
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function approveWinnerUpload(params, tableReset = false) {
   return dispatch => {
      loader(dispatch, true)
      winnerApproveServices.approveWinnerUpload(params)
         .then(
            response => {
               //console.log(response)
               if (response.status) {
                  dispatch({
                     type: winnerApproveConstants.APPROVE_UPLOAD_WINNER_SUCCESS
                  });

                  customAlert(dispatch, 'success', i18n.t('warning_messages.winner_was_approved'));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
               }
            },
            error => {
               //console.log(error)
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}

function rejectWinner(params, tableReset = false) {
   return dispatch => {
      loader(dispatch, true)
      winnerApproveServices.rejectWinner(params)
         .then(
            response => {
               //console.log(response)
               if (response.status) {
                  dispatch({
                     type: winnerApproveConstants.REJECT_WINNER_SUCCESS
                  });

                  customAlert(dispatch, 'success', i18n.t('warning_messages.winner_was_rejected'));
               } else {
                  if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`${response.message.toString()}`))
               }
            },
            error => {
               //console.log(error)
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };
}


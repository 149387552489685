import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import i18n from 'i18n';

import { store } from 'helpers';
import {  loader, alert as customAlert } from "helpers";
import { orderServices } from "services";
// @material-ui/core components
import { Grid, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";

import { orderConstants, swalConstants } from '../../../constants';
import { orderActions } from 'actions';
import { plugins, crmValidator } from 'helpers';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { Field, reduxForm, change } from "redux-form";

import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import { brandAction } from "actions"

import Button from "components/CustomButtons/Button.jsx";

const style = {
    actionDiv: {
        display: "inline-block"
    },
    upperCase: {
        textTransfrom: 'uppercase'
    }
};

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                color: '#666666',
                padding: '4px 24px 4px 15px',
                "&:nth-child(7)": {
                    textAlign: "center"
                }
            },
            body: {
                color: '#666666',
                padding: '4px 15px',
                "&:nth-child(14)": {
                    textAlign: "center"
                }
            }
        }
    }
});

const FORM_NAME = "formFilterOrder";

class tableOrderList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            searchFilter: {},
            csvData: [],
        };


        this.delayedCallback = lodash.debounce(this.getData, 500)

    }

    sumTotal = (value) => {
        let total = 0
        total = value.items.reduce((sum, item) => sum + item.amount, 0)
        if (value.shipment.shippingCost) {
            total = total+value.shipment.shippingCost
        }
        const couponValue = value.promotion ? value.promotion.find(item => "coupon" in item) : null
        if (couponValue) {
            total = total+couponValue.coupon
        }
        return total
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableDatas } = this.props

        let tableFilter = this.state.searchFilter

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)
            if(tableDatas.total !== 0 && tableDatas.total === skip){
                skip = skip - limit
                this.setState({
                    page: this.state.page - 1,
                })
            }
            if(skip >= tableDatas.total){
                skip = 0;
                this.setState({
                    page: 0,
                })
            }

            lodash.set(tableFilter, "skip", skip)
            lodash.set(tableFilter, "limit", limit)

            if (!crmValidator.isEmptyValue(sortBy)) {
                lodash.set(tableFilter, "sortBy", sortBy)
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                lodash.set(tableFilter, "sortDir", sortDir)
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                lodash.set(tableFilter, "keyword", encodeURIComponent(keyword))
            }
        }

        const reqParams = Object.entries(tableFilter).map(e => e.join('=')).join('&');
        dispatch(orderActions.getTableOrderDatas(reqParams));

        this.setState({
            isLoading: true
        });

    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: orderConstants.CLEAR_PAGE_STATE });
    }

    componentDidMount() {
        const {
            dispatch
        } = this.props

        dispatch(brandAction.getBrandLists(''))
        this.getData();
    }

    openEditOrder(index) {
        const { dispatch, tableDatas } = this.props;
        const rowData = tableDatas.order_shipments[index]
        rowData.waitingStatus = !!rowData.shipment.status.waiting
        rowData.inprogressStatus = !!rowData.shipment.status.inProgress
        rowData.doneStatus = !!rowData.shipment.status.done
        dispatch({ type: orderConstants.OPEN_EDIT_ORDER, data: rowData })
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: orderConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas.order_shipments;
        return data[rowIndex];
    }

    getListBrands = (brands) => {
        if (brands !== undefined) {
           return brands.map((brand) => {
              return {
                 value: brand._id,
                 label: brand.name
              }
           })
        }
        return []
    }

    clearFilter = () => {
        const { dispatch } = this.props;
        dispatch(change(FORM_NAME, "createDateFrom", ""));
        dispatch(change(FORM_NAME, "createDateTo", ""));
        dispatch(change(FORM_NAME, "orderId", ""));
        dispatch(change(FORM_NAME, "customerName", ""));
        dispatch(change(FORM_NAME, "customerPhoneNumber", ""));
        dispatch(change(FORM_NAME, "paymentStatus", ""));
        dispatch(change(FORM_NAME, "shipmentStatus", ""));
        dispatch(change(FORM_NAME, "shipmentDelivery", ""));
        dispatch(change(FORM_NAME, "brandId", ""));

        this.setState({
          filter: {
            page: 0,
            rowsPerPage: 10,
            skip: 0,
            limit: 10,
            sortBy: "created_at",
            sortDir: "desc",
            keyword: "",
          }
        })
    };

    handleSubmit(values) {
        let searchFilter = {}

        const momentFormat = 'YYYY-MM-DDTHH:mm:ss'
        if (lodash.size(values) > 0) {

          if (lodash.has(values, "createDateFrom")) {
            lodash.set(searchFilter, "createDateFrom", moment(values.createDateFrom).second(0).format(momentFormat));
          }
          if (lodash.has(values, "createDateTo")) {
            lodash.set(searchFilter, "createDateTo", moment(values.createDateTo).second(59).format(momentFormat));
          }
          if (lodash.has(values, "orderId")) {
            lodash.set(searchFilter, "orderId", values.orderId)
          }
          if (lodash.has(values, "customerName")) {
            lodash.set(searchFilter, "customerName", values.customerName)
          }
          if (lodash.has(values, "customerPhoneNumber")) {
            lodash.set(searchFilter, "customerPhoneNumber", values.customerPhoneNumber)
          }
          if (lodash.has(values, "paymentStatus")) {
            lodash.set(searchFilter, "paymentStatus", values.paymentStatus)
          }
          if (lodash.has(values, "shipmentStatus")) {
            lodash.set(searchFilter, "shipmentStatus", values.shipmentStatus)
          }
          if (lodash.has(values, "shipmentDelivery")) {
            lodash.set(searchFilter, "shipmentDelivery", values.shipmentDelivery)
          }
          if (lodash.has(values, "brandId")) {
            lodash.set(searchFilter, "brandId", values.brandId)
          }
        }

        this.setState({
            searchFilter
        }, function () {
            this.getData(0,
                this.state.limit,
                this.state.sortBy,
                this.state.sortDir,
                this.state.keyword
            )
        })
    }
    exportCSV() {
        const { dispatch } = this.props;
        let tableFilter =   this.state.searchFilter

        tableFilter.skip = 0;
        tableFilter.limit = 1000
            loader(dispatch, true);
            orderServices
                .downloadOrderShipment(tableFilter)
                .then((response) => {
                    loader(dispatch, false);
                })
                .catch((e) => {
                    this.setState({ csvData: [] });
                });
    }

    render() {
        const {
            classes,
            dispatch,
            tableDatas,
            tableKey,
            tableLoader,
            formFilterOrder,
            handleSubmit,
        } = this.props;
        const { rowsPerPage, page } = this.state;

        const storeData = store.getState().authentication
        const isBrandAdminRole = lodash.get(storeData, 'user.isBrandAdminRole', false)
        const superAdmin = !isBrandAdminRole || lodash.get(storeData, 'superAdmin', false)

        let columns = [
            {
                name: "transactionDate", label: "Transaction Date/Time", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.created_at !== undefined || rowData.created_at != null) {
                            return (<span className="text-nowrap">{plugins.datetimeFormat(rowData.created_at, "fulldatetime")}</span>)
                        } else {
                            return "";
                        }
                    }
                }
            },

            {   name: "orderId", label: "Order Id" },

            superAdmin ? {
                name: "brandName", label: "brand Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)

                        const brandId = rowData.brandId || ''
                        if (!brandId) {
                            return ''
                        }

                        const findBrand = (this.props.brands || []).find(brand => brand._id === brandId)
                        if (findBrand) {
                            return findBrand.name
                        }

                        return ''
                    }
                }
            } : null,

            {
                name: "customerName", label: "Customer Name", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return rowData.payment.customerName
                    }
                }
            },

            {
                name: "totalAmount", label: "Total Amount", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return this.sumTotal(rowData)
                    }
                }
            },

            {
                name: "paymentStatus", label: "Payment Status", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return  rowData.payment.status === 'wait' ? 'รออัพโหลดสลิป'
                        : rowData.payment.status === 'checking' ? 'รอยืนยันออเดอร์'
                        : rowData.payment.status === 'paid' ? 'ชำระเงินแล้ว'
                        : rowData.payment.status === 'cancel' ? 'ยกเลิกรายการสั่งซื้อ'
                        : '-'
                    }
                }
            },

            {
                name: "shipmentStatus", label: "Shipment Status", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return rowData.shipment.status.done ? 'ยืนยันการรับสินค้า'
                        : rowData.shipment.status.inProgress ? 'กำลังเตรียมสินค้าและจัดส่ง'
                        : rowData.shipment.status.waiting ? 'กำลังเตรียมสินค้า'
                        : '-'
                    }
                }
            },

            {
                name: "shipmentType", label: "Shipment Type", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return rowData.shipment.delivery ? 'Delivery' : 'Pick Up'
                    }
                }
            },
            {
                name: "payment.customerPhoneNumber", label: "Mobile No.", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return rowData.payment.customerPhoneNumber
                    }
                }
            },

        ];

        columns.push({
            label: "Action", options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.actionDiv}>
                            {
                                <TableActionButton
                                    actionType={"edit"}
                                    onClick={(e) => {
                                        this.openEditOrder(tableMeta.rowIndex)
                                    }}
                                />
                            }
                            <TableActionButton
                                actionType="delete"
                                onClick={(e) => {
                                    dispatch({
                                        type: swalConstants.SHOW, data: {
                                            type: "warning",
                                            title: i18n.t("warning_messages.are_you_sure_delete_order"),
                                            message: `${tableMeta.rowData[1]}`,
                                            onConfirm: function () {
                                                let reqParams = {
                                                    _id: tableMeta.rowData[1]
                                                }
                                                dispatch({ type: swalConstants.HIDE })
                                                dispatch(orderActions.deleteOrder(reqParams));
                                            },
                                            onCancel: function () {
                                                dispatch({ type: swalConstants.HIDE })
                                            },
                                            showCancel: true,
                                            confirmBtnText: i18n.t("delete"),
                                            cancelBtnText: i18n.t("cancel")
                                        }
                                    })
                                }} />
                        </div>
                    )
                }
            }
        })

        columns = columns.filter(c => c)

        let options = {
            serverSide: true,
            count: tableDatas.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            customToolbar: () => {
                return (
                    <>
                        {/*plugins.isHasPermission("report[earn]", "export") && plugins.isHasPackageFeatures('export') &&*/(
                            <Tooltip title='Export CSV'>
                                <IconButton
                                    id='button-earnTable-exportCsv'
                                    className=''
                                    onClick={this.exportCSV.bind(this)}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        let startMaxDate;
        if (lodash.has(formFilterOrder, "values.createDateTo")) {
        startMaxDate = formFilterOrder.values.createDateTo;
        } else {
        startMaxDate = new Date();
        }


        let endMinDate;
        if (lodash.has(formFilterOrder, "values.createDateFrom")) {
            endMinDate = formFilterOrder.values.createDateFrom;
        }

        return (
            <div id="tableOrderList">
                <CustomSweetAlert />
                <h3>
                    <b>ORDER LIST</b>
                </h3>
                <form
                    name={FORM_NAME}
                    onSubmit={handleSubmit(this.handleSubmit.bind(this))}
                >
                    <GridContainer>
                        <GridItem xs={6} sm={3}>
                        <Field
                            name="createDateFrom"
                            component={CustomDatePicker}
                            type="text"
                            maxDate={startMaxDate}
                            labelText={i18n.t("label.start_date")}
                            dateFormat="DD-MMM-YY"
                            timeFormat="HH:mm:ss"
                            formControlProps={{
                                fullWidth: true
                            }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={3}>
                            <Field
                            name="createDateTo"
                            minDate={endMinDate}
                            maxDate={new Date()}
                            component={CustomDatePicker}
                            type="text"
                            labelText={i18n.t("label.end_date")}
                            dateFormat="DD-MMM-YY"
                            timeFormat="HH:mm:ss"
                            formControlProps={{
                                fullWidth: true
                            }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="orderId"
                                component={CustomInput}
                                type="text"
                                labelText="orderId"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                name="customerName"
                                component={CustomInput}
                                type="text"
                                labelText="Customer name"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} md={3} lg={3}>
                            <Field
                                name="customerPhoneNumber"
                                component={CustomInput}
                                type="text"
                                labelText="Mobile No."
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>

                        <GridItem xs={6} md={3} lg={3}>
                            <Field
                                name="paymentStatus"
                                component={CustomSelect}
                                type="text"
                                labelText={"Payment Status"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    {
                                        value: "",
                                        label: "All"
                                    },
                                    {
                                        value: "wait",
                                        label: "รออัพโหลดสลิป"
                                    },
                                    {
                                        value: "checking",
                                        label: "รอยืนยันออเดอร์"
                                    },
                                    {
                                        value: "paid",
                                        label: "ชำระเงินแล้ว"
                                    },
                                    {
                                        value: "cancel",
                                        label: "ยกเลิกรายการสั่งซื้อ"
                                    }
                                ]}
                            />
                        </GridItem>

                        <GridItem xs={6} md={3} lg={3}>
                            <Field
                                name="shipmentStatus"
                                component={CustomSelect}
                                type="text"
                                labelText={"Shipment Status"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    {
                                        value: "",
                                        label: "All"
                                    },
                                    {
                                        value: "waiting",
                                        label: "กำลังเตรียมสินค้า"
                                    },
                                    {
                                        value: "inProgress",
                                        label: "กำลังเตรียมสินค้าและจัดส่ง"
                                    },
                                    {
                                        value: "done",
                                        label: "ยืนยันการรับสินค้า"
                                    }
                                ]}
                            />
                        </GridItem>

                        <GridItem xs={6} md={3} lg={3}>
                            <Field
                                name="shipmentDelivery"
                                component={CustomSelect}
                                type="text"
                                labelText={"Shipment Type"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    {
                                        value: "",
                                        label: "All"
                                    },
                                    {
                                        value: "1",
                                        label: "Delivery"
                                    },
                                    {
                                        value: "0",
                                        label: "Pick Up"
                                    }
                                ]}
                            />
                        </GridItem>

                        { !isBrandAdminRole && <GridItem xs={6} md={3} lg={3}>
                            <Field
                                name="brandId"
                                component={CustomSelect}
                                type="text"
                                labelText={"Brand"}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    {
                                        value: "",
                                        label: "All"
                                    },
                                    ...this.getListBrands(this.props.brands)
                                ]}
                            />
                        </GridItem>
                        }

                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12} sm={4} md={4} lg={3}>
                            <Button
                            onClick={this.clearFilter}
                            style={{
                                marginTop: "25px"
                            }}
                            color="white"
                            >
                            {i18n.t("clear")}
                            </Button>
                            &nbsp;  &nbsp;  &nbsp;
                            <Button
                                type="submit"
                                style={{
                                    marginTop: "25px"
                                }}
                                color="primary"
                                >
                                {i18n.t("submit")}
                            </Button>
                        </GridItem>
                    </GridContainer>
                </form>
                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        data={tableDatas.order_shipments}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner />}

                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader } = state.order;
    const { permission, superAdmin, visiblePersonalData } = state.authentication;
    const { tableData: { brands } } = state.brand;
    const { formFilterOrder } = state.form

    return {
        permission,
        superAdmin,
        visiblePersonalData,
        formInitialValue,
        tableReload,
        tableReset,
        tableDatas,
        tableKey,
        tableLoader,
        formFilterOrder,
        brands
    };
}

const connectContainer = connect(mapStateToProps)(tableOrderList);

export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: true
  })(withStyles(style)(connectContainer));
import { newcondition } from '../constants'

var initialState = {
    formOpen: false,
    formTemplateNewOpen: false,
    
    filtering : []

}

export function df_newcondition(state = initialState, action) {
    switch (action.type) {
        case newcondition.GET_TEMPLATE_CONFIG_LIST_SUCCESS:
            return {
                ...state,
                filtering: action.data.filtering
            }
        case newcondition.OPEN_FORM_TEMPLATE_NAME:
            return {
                ...state,
                formTemplateNewOpen: true
            }
        case newcondition.CLOSE_FORM_TEMPLATE_NAME:
            return {
                ...state,
                formTemplateNewOpen: false
            }
        case newcondition.ADD_NEW_CONDITION_SUCCESS:
            return {
                ...state,
                formTemplateNewOpen: false
            }
        default:
            return state;
    }
}
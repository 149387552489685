import React from "react";
import moment from 'moment';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// material-ui icons
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

//page components
import PromotionReport from "./PromotionReport.jsx";

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

function PromotionReportContainer({ ...props }) {
    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <h4 style={{ fontWeight: '500', fontSize: '1.25rem' }}>PROMOTION REPORT</h4>
                        <GridContainer>
                            <GridItem xs={12}>
                                <PromotionReport initialValues={{ startDate: moment().startOf('day'), endDate: moment().set('hour',23).set('minute',59).set('second',59) }} />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default withStyles(style)(PromotionReportContainer);
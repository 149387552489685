import { earnrulesConstants } from '../constants';
import lodash from 'lodash';

var initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: 0,
    tableData: {
        data: [],
        total: 0
    },
    tableReset: false,
    tableReload: false,
    allProductList: [],
    productList: [],
    selectedProductList: [],
    formProductListLoader: false,
    tableLoader: false,
    formMemberLevellist: [],
    singleProductList: [{_id: 0}],
    joinedProductList: [{_id: 0}],
    joinedProductRows : [
        {
            productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
            point: 0
        },
    ],
    regionList:[],
    regionSelected:[],
    allShopList:[],
    shopList:[],
    allShopListSelected:[],
    shopListSelected:[],
    productListMaster:[],
    formImportOpen: false,
    formImportInitialValue: {},
    showImportResult: false,
    importResult: {}
}

export function earnrules(state = initialState, action) {
    switch (action.type) {
        case earnrulesConstants.CLEAR_PAGE_STATE:
            return initialState
        case earnrulesConstants.OPEN_ADD_NEW_RULES:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.data,
            };
        case earnrulesConstants.OPEN_EDIT_EARN_RULES:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.data,
                selectedProductList: action.selectedProductList,
                allShopListSelected:action.allShopListSelected,
                shopListSelected:action.allShopListSelected,
                regionSelected:action.regionSelected,
                productListMaster:action.productListMaster
            };
        case earnrulesConstants.CLOSE_FORM_EARN_RULES:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                singleProductList: [{_id: 0}],
                joinedProductList: [{_id: 0}],
                joinedProductRows : [
                    {
                        productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                        point: 0
                    },
    ]

            }
        case earnrulesConstants.GET_EARN_RULES_DATA_SUCCESS: {
            return {
                ...state,
                tableData: action.data,
                tableReload: false,
                tableReset: false
            }
        }
        case earnrulesConstants.GET_PRODUCT_LIST_SUCCESS: {
            let selectedProductList = state.selectedProductList;
            let productList = action.productList;

            lodash.each(selectedProductList, function (currentObject) {
                productList = productList.filter(function (obj) {
                    return obj._id !== currentObject._id;
                });
            })

            return {
                ...state,
                allProductList: action.productList,
                productList: productList
            }
        }
        case earnrulesConstants.RULE_TYPECODE_SELECTED_PRODUCT_ITEM: {
            let productData = action.data;
            let newProductList = state.productList;
            let selectedProductList = state.selectedProductList;
            newProductList = newProductList.filter(function (obj) {
                return obj._id !== productData._id;
            });

            // console.log('newProductList :',newProductList);
            selectedProductList.push(productData)

            return {
                ...state,
                productList: newProductList,
                selectedProductList: selectedProductList
            }
        }
        case earnrulesConstants.RULE_TYPECODE_SELECTED_MANY_PRODUCT_ITEM: {
            let productDataList = action.data;
            let selectedProductList = state.selectedProductList;

            return {
                ...state,
                productList: [],
                selectedProductList: lodash.unionBy(productDataList, selectedProductList, '_id')
            }
        }
        case earnrulesConstants.RULE_TYPECODE_DESELECTED_PRODUCT_ITEM: {
            let productData = action.data;
            let productList = state.productList;
            let selectedProductList = state.selectedProductList;

            selectedProductList = selectedProductList.filter(function (obj) {
                return obj._id !== productData._id;
            });

            productList.push(productData)
            productList = lodash.orderBy(productList, ['updated_at'], ['desc']);

            return {
                ...state,
                productList: productList,
                selectedProductList: selectedProductList
            }
        }
        case earnrulesConstants.ON_EARNRULE_TYPE_CHANGE_TO_CODE: { 
            return {
                ...state,
                selectedProductList: []
            }
        }
        case earnrulesConstants.ON_EARNRULE_TYPE_CHANGE_TO_PRODUCT: {
            return {
                ...state,
                singleProductList: [{_id: 0}],
                joinedProductList: [{_id: 0}],
                joinedProductRows : [
                    {
                        productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                        point: 0
                    },
                ]
            }
        }
        case earnrulesConstants.EARNRULES_FORM_PRODUCT_LIST_LOADER: {
            let showLoader = action.showLoader
            return {
                ...state,
                formProductListLoader: showLoader
            }
        }
        case earnrulesConstants.SHOW_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: true
            }
        }
        case earnrulesConstants.HIDE_TABLE_LOADER: {
            return {
                ...state,
                tableLoader: false
            }
        }
        case earnrulesConstants.ADD_EARN_RULES_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                singleProductList: [{_id: 0}],
                joinedProductList: [{_id: 0}],
                joinedProductRows : [
                    {
                        productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                        point: 0
                    },
                ],
            }
        }
        case earnrulesConstants.EDIT_EARN_RULES_SUCCESS: {
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReload: true,
                tableReset: false,
                singleProductList: [{_id: 0}],
                joinedProductList: [{_id: 0}],
                joinedProductRows : [
                    {
                        productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                        point: 0
                    },
                ],
            }
        }
        case earnrulesConstants.TABLE_RELOAD_SUCCESS: {
            return {
                ...state,
                tableReload: false,
                tableReset: false
            }
        }
        case earnrulesConstants.DELETE_EARN_RULES_SUCCESS: {
            return {
                ...state,
                tableReload: true
            }
        }
        case earnrulesConstants.GET_MEMBER_LEVEL_LIST_SUCCESS: {
            return {
                ...state,
                formMemberLevellist: action.tmp
            }
        }
        case earnrulesConstants.EARN_RULE_ADD_JOINED_PRODUCT_ROW: {
            let stateJoinedProductRow = state.joinedProductRows;
            stateJoinedProductRow.push(
                {
                    productWithQty: [{_id: 0, qty: 0}, {_id: 1, qty: 0}],
                    point: 0
                }
            )
            return {
                ...state,
                joinedProductRows: stateJoinedProductRow
            }
        } 
        case earnrulesConstants.EARN_RULE_ADD_JOINED_PRODUCT: {
            let stateJoinedProductRow = state.joinedProductRows;
            stateJoinedProductRow[action.index].productWithQty.push(action.productWithQty)
            return {
                ...state,
                joinedProductRows: stateJoinedProductRow
            }
        } 
        case earnrulesConstants.EARN_RULE_ADD_SINGLE_PRODUCT: {
            let stateSingleProductRow = state.singleProductList;
            stateSingleProductRow.push(action.objSingle)
            return {
                ...state,
                singleProductList: stateSingleProductRow
            }
        } 
        case earnrulesConstants.EARN_RULE_DELETE_JOINED_PRODUCT: {
            let stateJoinedProductRow = state.joinedProductRows;
            stateJoinedProductRow[action.index].productWithQty.pop();
            return {
                ...state,
                joinedProductRows: stateJoinedProductRow
            }
        }
        case earnrulesConstants.EARN_RULE_GET_REGION_LIST: {
            return {
                ...state,
                regionList: action.data 
            }
        } 
        case earnrulesConstants.EARN_RULE_GET_SHOP_LIST: {
            return {
                ...state,
                allShopList: action.data.shopList ,
                shopList:action.data.shopList,
                allShopListSelected:action.data.shopListSelected,
                shopListSelected:action.data.shopListSelected,
            }
        } 
        case earnrulesConstants.EARN_RULE_SELECT_SHOP: {
            return {
                ...state,
                shopList:action.data.shopList,
                allShopList:action.data.allShopList,
                shopListSelected:[...state.shopListSelected,...action.data.shopListSelected],
                allShopListSelected:[...state.allShopListSelected,...action.data.shopListSelected],
            }
        } 
        case earnrulesConstants.EARN_RULE_UNSELECT_SHOP: {
            return {
                ...state,
                shopList:[...action.data.shopList,...state.shopList],
                allShopList:[...action.data.shopList,...state.allShopList],
                shopListSelected:action.data.shopListSelected,
                allShopListSelected:action.data.allShopListSelected,
            }
        } 
        case earnrulesConstants.EARN_RULE_SELECTED_REGION: {
            return {
                ...state,
                regionSelected:action.data,
            }
        } 
        case earnrulesConstants.EARN_RULE_SELECT_ALL_SHOP_LIST: {
            return {
                ...state,
                shopListSelected:[...action.data,...state.shopListSelected],
                shopList:[],
                allShopList:[],
                allShopListSelected:[...action.data,...state.shopListSelected],
            }
        } 
        case earnrulesConstants.EARN_RULE_UNSELECT_ALL_SHOP_LIST: {
            return {
                ...state,
                shopList:[...action.data,...state.shopList],
                shopListSelected:[],
                allShopList:[...action.data,...state.shopList],
                allShopListSelected:[],
            }
        }
        case earnrulesConstants.EARN_RULE_SEARCH_SHOP_LIST: {
            return {
                ...state,
                shopList:action.data,
            }
        }
        case earnrulesConstants.EARN_RULE_SEARCH_SHOP_SELECTED: {
            return {
                ...state,
                shopListSelected:action.data,
            }
        }
        case earnrulesConstants.EARN_RULE_CLEAR_SHOP_LIST: {
            return {
                ...state,
                allShopList:[],
                shopList:[],
                allShopListSelected:[],
                shopListSelected:[],
                regionSelected:[],
            }
        }
        case earnrulesConstants.GET_LUCKY_DRAW_REWARD_SET_SUCCESS: {
            return {
                ...state,
                luckyDrawRewardSet: action.data.rewards
            }
        }
        case earnrulesConstants.OPEN_EARN_RULE_FORM_IMPORT: {
            return {
                ...state,
                formImportOpen: true,
                formImportInitialValue: (action.formImportInitialValue? action.formImportInitialValue:{})
            }
        }     
        case earnrulesConstants.CLOSE_EARN_RULE_FORM_IMPORT: {
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {}
            }
        }   
        case earnrulesConstants.SHOW_EARN_RULE_IMPORT_PRODUCT_RESULT: {
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: true,
                importResult: action.data
            }
        }
        case earnrulesConstants.HIDE_EARN_RULE_IMPORT_PRODUCT_RESULT:
            return {
                ...state,
                showImportResult: false,
                importResult: {}
            }
        default:
            return state;
    }
}
import { luckydrawRewardConstants } from '../constants';
import lodash from 'lodash'
import defaultImage from "assets/img/image_placeholder.jpg";

const initialState = {
   formOpen: false,
   formMode: 'add',
   formInitialValue: {},
   tableKey: new Date(),
   tableReload: false,
   tableReset: false,
   showLoader: false,
   tableDatas: [],
   formImportOpen: false,
   formImportInitialValue: {},
   showImportResult: false,
   importResult: [],
   formSaveImportOpen: false,
   saveImportResult: [],
   tableFilter: {
    page: 0,
    skip: 0,
    limit: 10,
    sortBy: "createdAt",
    sortDir: "desc",
    keyword: "",
    search: {}
   },
   rewardImagePreview: defaultImage,
};

export function luckydraw_reward(state = initialState, action) { 
    switch (action.type) {
        case luckydrawRewardConstants.CLEAR_PAGE_STATE:
            return initialState;
        case luckydrawRewardConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
                rewardImagePreview: defaultImage,
            };
        case luckydrawRewardConstants.OPEN_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue,
                rewardImagePreview: lodash.get(action.formInitialValue, "filePath") ? lodash.get(action.formInitialValue, "filePath") : defaultImage,
            }
        case luckydrawRewardConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formMode: 'add',
                formInitialValue: {},
                rewardImagePreview: defaultImage,
            }
        case luckydrawRewardConstants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case luckydrawRewardConstants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case luckydrawRewardConstants.GET_LUCKYDRAW_REWARD_SUCCESS:
            return {
                ...state,
                tableFilter: action.tableFilter,
                tableDatas: (action.data?action.data:[]),
                tableTotal: (action.total?action.total:0)
            }
        case luckydrawRewardConstants.ADD_NEW_LUCKYDRAW_REWARD_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                formInitialValue: {}
            }
        case luckydrawRewardConstants.EDIT_LUCKYDRAW_REWARD_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case luckydrawRewardConstants.TABLE_RELOAD_SUCCESS:
        case luckydrawRewardConstants.TABLE_RESET:
            return {
                ...state,
                tableReset: false,
                tableReload: false
            }
        case luckydrawRewardConstants.DELETE_LUCKYDRAW_REWARD_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case luckydrawRewardConstants.SET_FORM_PREVIEW_IMAGE_REWARD:
            return {
                ...state,
                rewardImagePreview: action.rewardImagePreview === "default" ? defaultImage : action.rewardImagePreview
            }
        default:
            return state
    }
 }

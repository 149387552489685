import { authConstants } from '../constants';

const initialState = {
   superAdmin: false,
   isFail: false,
   permission: {},
   pathName: "",
   merchantSetting: {},
   merchant: {},
   visiblePersonalData: "",
   routes: [],
   user: {
      email: "",
      name: "",
      defaultMerchantId: false,
      masterAccount: false,
      masterLists: [],
      roleIds: [],
      tokenChatbot: undefined,
      cuaa_id: null,
      shopOwner: false
   },
   showError: false,
   package_features: [],
   overLimitMember: false
};
export function authentication(state = initialState, action) {
   switch (action.type) {
      case authConstants.LOGIN_REQUEST:
      case authConstants.LOGIN_SUCCESS:
         return {
            ...state,
            loggingIn: true,
            isFail: false,
            user: state,
            merchantSetting: action.merchantSetting
         };
      case authConstants.SWITCH_MERCHANT_FAILURE:
      case authConstants.LOGIN_FAILURE:
         return {
            ...state,
            isFail: true
         };

      case authConstants.SWITCH_MERCHANT_SUCCESS:
         return {
            ...state,
            isFail: false,
            loggingIn: true,
         };

      case authConstants.LOGOUT:
         return initialState;
      case authConstants.GETALL_REQUEST:
         return {
            ...state,
            user: state
         };
      case authConstants.AUTH_ROLE_PERMISSION_SUCCESS:
         return {
            ...state,
            user: action.data.user,
            routes: action.data.routes,
            superAdmin: action.data.superAdmin,
            permission: action.data.permission,
            pathName: action.data.pathName,
            merchantSetting: action.data.merchantSetting,
            merchant: action.data.merchant,
            visiblePersonalData: action.data.visiblePersonalData,
            showError: true,
            package_features: action.data.package_features,
            overLimitMember: action.data.overLimitMember
         };
      case authConstants.GET_MERCHANT_SETTING_SUCCESS:
         return {
            ...state,
            merchantSetting: action.merchantSetting
         }
      case authConstants.CREATE_ROUTES:
         return {
            ...state,
            routes: action.data
         };
      case authConstants.GET_ENV_CONFIG_SUCCESS:
         return {
            ...state,
            envConfig: action.envConfig
         }
      default:
         return state
   }
}
import React from "react";
import { connect } from 'react-redux';
import lodash from 'lodash';
import MUIDataTable from "mui-datatables";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import TopUpForm from "./TopUpForm.jsx";
import { tmnTopupConstants } from '../../constants';
import { trueMoneyTopupActions } from 'actions';
import { plugins, crmValidator, loader, alert as customAlert } from 'helpers';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ErrorDetail from "./ErrorDetail";
import Button from 'components/CustomButtons/Button'
import { trueMoneyTopupServices } from 'services'

const style = {
    actionDiv: {
        display: "inline-block"
    },
    upperCase: {
        textTransfrom: 'uppercase'
    },
    herderTable: {
        color: "rgba(0, 0, 0, 0.54)",
        fontWeight: "500",
        fontSize: "9px",
        display: "table-cell",
        padding: "4px 24px 4px 24px",
        textAlign: "left",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        verticalAlign: "inherit"
    },
    iconInfo: {
        margin: "-8px 0px 0px 0px"
    }
};



const moduleName = 'true_money[top_up]'
const pageStyle = { ...style, ...sweetAlertStyle };
const THEME = createMuiTheme(MTablestyles);

class TableTmnRewardtList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            isLoading: false,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: ""
        };

        this.delayedCallback = lodash.debounce(this.getData, 500)

    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: tmnTopupConstants.CLEAR_PAGE_STATE });
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: tmnTopupConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableFilter } = this.props
        let newFilter = tableFilter;
        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                newFilter.skip = 0;
            }

            if (crmValidator.isEmptyValue(limit)) {
                newFilter.limit = 10;
            }
            newFilter.page = skip;
            this.setState({
                rowsPerPage: limit,
            });
            skip = parseInt(limit) * parseInt(skip);

            newFilter.skip = skip;
            newFilter.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                newFilter.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                newFilter.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                newFilter.keyword = keyword;
            } else {
                newFilter.keyword = "";
            }
        } else {
            newFilter = this.state.newFilter;
        }
        dispatch(trueMoneyTopupActions.getTrueMoneyTransactionTopup(newFilter));
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props
        const data = tableDatas;
        return data[rowIndex];
    }

    exportCSV() {
        const { tableDatas, dispatch } = this.props;
        const { total } = tableDatas
        let { tableFilter } = this.props

        tableFilter.skip = 0;
        tableFilter.limit = (total ? total : 100000);

        if (total > 30000) {
            customAlert(dispatch, 'error', "Can not export data more than 30,000 rows")
        } else {
            loader(dispatch, true);
            trueMoneyTopupServices.downloadTrueMoneyTopup(tableFilter)
                .then((response) => {
                    loader(dispatch, false);
                })
                .catch((e) => {
                    this.setState({ csvData: [] });
                });
        }
    }

    handleErrMsg = () => {
        const { dispatch } = this.props
        dispatch({ type: tmnTopupConstants.ERR_DETAIL_IS_OPEN })
    }
    handleRetry = (id) => {
        let setDate = { TMNTransactionId: id }
        const { dispatch } = this.props
        dispatch(trueMoneyTopupActions.RetryTrueMoney(setDate))
    }
    renderIconError = (index) => {
        return (
            <th key={index} style={{fontSize: '0.75rem', color: "rgba(0, 0, 0, 0.54)", borderBottom: "1px solid rgba(224, 224, 224, 1)", fontWeight: "500",}}>
                Error Code
                <IconButton
                    id='button-Info-TmnTransaction'
                    className=''
                    style={style.iconInfo}
                    onClick={this.handleErrMsg}
                >
                    <InfoOutlinedIcon />
                </IconButton>
            </th>
        )
    }
    renderAction = (times, status, id) => {
        return (
            times >= 3 && !status &&
            <>
                <Button
                    id="retry-btn"
                    variant="contained"
                    bcolor="primary"
                    size="sm"
                    onClick={(e) => {
                        this.handleRetry(id)
                    }}
                    style={{ backgroundColor: "#ff5353" }}
                >
                    <div>Retry</div>

                </Button>
            </>)
    }

    render() {
        const { formInitialValue, tableKey, tableLoader, tableDatas, tableTotal, tableFilter } = this.props;
        const { rowsPerPage } = this.state;
        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            {
                name: "transDate", label: "Trans Date/Time",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return rowData.created_at !== undefined || rowData.created_at != null ?
                            plugins.datetimeFormat(rowData.created_at, "fulldatetime") : ""
                    }
                },
            },
            {
                name: "lastRetryDate", label: "Last Retry Date/Time",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        return rowData.updated_at !== undefined || rowData.updated_at != null ?
                            plugins.datetimeFormat(rowData.updated_at, "fulldatetime") : ""
                    }
                },
            },
            { name: "TMNTransactionId", label: "TMN Trans ID" },
            { name: "burnTransactionId", label: "Burn Trans ID" },
            { name: "amount", label: "Amount" },
            { name: "topupNumber", label: "Topup Mobile No." },
            {
                name: "status", label: "Status",
                options: {
                    customBodyRender: (value) => {
                        return value
                            ? "Success"
                            : "Failed"
                    }
                },
            },
            { name: "failedTimes", label: "Failed Round" },
            {
                name: "responseCode", label: 'Error Code',
                options: {
                    customHeadRender: (value, tableMetat,) => {
                        return this.renderIconError(value.index)
                    }
                }
            },
            // { name: "responseCode", label: "Error Code" },
            {
                label: "Action", options: {
                    filter: false,
                    sort: false,
                    display: plugins.isHasPermission(moduleName, 'update'),
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            plugins.isHasPermission(moduleName, 'update') && this.renderAction(lodash.get(tableMeta.rowData, '8'), lodash.get(tableMeta.rowData, '7'), lodash.get(tableMeta.rowData, '3'))
                        )
                    }
                }
            }

        ];

        let options = {
            serverSide: true,
            count: tableTotal ? tableTotal : 0,
            page: tableFilter.page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            customToolbar: () => {
                return (
                    <>
                        {plugins.isHasPermission(moduleName, 'export') && plugins.isHasPackageFeatures('export') &&
                            <Tooltip title="Export CSV">
                                <IconButton className="" onClick={this.exportCSV.bind(this)} id="export-btn">
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                );
            },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {

                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },

        };

        return (
            <div id="TableTmnRewardtList">
                <CustomSweetAlert />
                <TopUpForm
                    initialValues={formInitialValue}
                    readOnly={!plugins.isHasPermission(moduleName, 'update')}
                />

                <MuiThemeProvider theme={THEME}>

                    <MUIDataTable
                        key={tableKey}
                        title={"Top up List"}
                        data={tableDatas}
                        columns={columns}
                        options={options}
                    />
                    {tableLoader && <TableLoaderSpinner />}
                    <ErrorDetail />
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader, tableFilter, tableTotal } = state.tmnTopup;
    // const { permission, superAdmin } = state.authentication;
    return {
        formInitialValue, tableReload, tableReset, tableDatas, tableKey, tableLoader, tableFilter, tableTotal
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableTmnRewardtList));
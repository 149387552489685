import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'
import { businessWebsiteServices } from '../services';
import { businessWebsiteConstants } from '../constants'
const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const businessWebsiteAction = {
    getBusinessInfomation,
    updateBusinessInfomation,
    getPrivacyNoticeAndTc
};

function getBusinessInfomation() {
    return dispatch => {
        loader(dispatch, true)
        businessWebsiteServices.getBusinessInfomation()
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: businessWebsiteConstants.GET_BUSINESS_INFORMATION, tmp }}
}

function updateBusinessInfomation(params){
    return dispatch => {
        loader(dispatch, true)
        businessWebsiteServices.updateBusinessInfomation(params)
            .then(
                response => {
                    if (response.status) {
                        dispatch({ type: businessWebsiteConstants.UPDATE_BUSINESS_INFORMATION });
                        dispatch({ type: businessWebsiteConstants.SET_BUSINESS_INFORMATION_INITIAL_VALUE, tmp: params });
                        customAlert(dispatch, 'success', i18n.t('warning_messages.business_website_was_updated'));
                    } else {
                        if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', i18n.t(defaultFailedText))
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
}

function getPrivacyNoticeAndTc(value) {
    return dispatch => {
        loader(dispatch, true)
        businessWebsiteServices.getPrivacyAndTc(value)
            .then(
                response => {
                    if (response.status) {
                        dispatch(success(response.data));
                    } else {
                        if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                    }
                },
                error => {
                    customAlert(dispatch, 'error', `Can't not get infomation`)
                }
            ).then(() => {
                loader(dispatch, false)
            })
    };
    function success(tmp) { return { type: businessWebsiteConstants.GET_PRIVACY_NOTICE_AND_TC, tmp }}
}
import _ from 'lodash';

import { roleConstants, dashboardConstants } from '../constants';
import { roleServices } from 'services';
import { loader, plugins, alert as customAlert } from 'helpers';

import i18n from 'i18n';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const roleActions = {
   addNewRole,
   editRole,
   deleteRole,
   getStatisticsRole,
   getListsRole,
   getTableDatas,
   getListsFeatures,
   getRoleByID
};

function addNewRole(params) {
   return dispatch => {
      loader(dispatch, true)
      roleServices.addNewRole(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.role_was_created'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: roleConstants.ADD_NEW_ROLE_SUCCESS, tmp } }
}

function editRole(params) {
   return dispatch => {
      loader(dispatch, true)
      roleServices.editRole(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'success', i18n.t('warning_messages.role_was_updated'));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: roleConstants.EDIT_ROLE_SUCCESS, tmp } }
}

function deleteRole(params) {
   return dispatch => {
      loader(dispatch, true)
      dispatch({ type: roleConstants.DELETE_ROLE_REQUEST })
      roleServices.deleteRole(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
                  customAlert(dispatch, 'error', i18n.t("warning_messages.role_was_deleted"))
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(tmp) { return { type: roleConstants.DELETE_ROLE_SUCCESS, tmp } }
}

function getStatisticsRole() {
   return dispatch => {
      loader(dispatch, true)
      roleServices.getStatisticsRole()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(data) { return { type: dashboardConstants.GET_STATISTICS_SUCCESS, data } }
}

function getListsRole() {
   return dispatch => {
      roleServices.getListsRole()
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         )
   };
   function success(data) { return { type: roleConstants.GET_ROLE_SUCCESS, data } }
}

function getListsFeatures() {
   return dispatch => {
      roleServices.getFeatures()
         .then(
            response => {
               if (response.status) {
                  const roleAndPermission = plugins.setPermission(response.data)

                  dispatch(success(response.data, roleAndPermission));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         )
   };

   function success(data, permission) { return { type: roleConstants.GET_FEATURES_SUCCESS, data, permission } }
}

function getTableDatas(params) {
   return dispatch => {
      dispatch({ type: roleConstants.SHOW_TABLE_LOADER })
      roleServices.getListsRole(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            dispatch({ type: roleConstants.HIDE_TABLE_LOADER })
         })
   };

   function success(tmp) { return { type: roleConstants.TABLE_GET_LIST_DATAS, tmp } }
}

function getRoleByID(params) {
   return dispatch => {
      loader(dispatch, true)
      roleServices.getRoleByID(params)
         .then(
            response => {
               if (response.status) {
                  dispatch(success(response.data));
               } else {
                  if(!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
               }
            },
            error => {
               customAlert(dispatch, 'error', i18n.t(defaultFailedText))
            }
         ).then(() => {
            loader(dispatch, false)
         })
   };

   function success(data) { return { type: roleConstants.OPEN_FORM_EDIT, formInitialValue: data } }
}

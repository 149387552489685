import React from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash';
import i18n from 'i18n';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// core components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx"
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import Badge from "components/Badge/Badge.jsx";

// import ShipmentForm from "views/Shipment/ShipmentForm.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner';
import BlacklistForm from 'views/Blacklist/BlacklistForm.jsx';
import BlacklistUploadForm from 'views/Blacklist/BlacklistUploadForm.jsx';

import { blacklistAction } from 'actions';
import { plugins, loader, crmValidator, alert as customAlert } from 'helpers';
import { blacklistConstants, swalConstants } from '../../constants';
import { blacklistServices } from 'services';


const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const moduleName = 'blacklist';

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "OK": BadgeColor = "success"; break;
        default: BadgeColor = "danger";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(11)": {
                    textAlign: "right"
                }
            },
            body: {
                "&:nth-child(22)": {
                    textAlign: "right"
                }
            }
        }
    }
});

class TableBlacklistListUpload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // readOnly: true,
            // loaderSpiner: false,
            // page: 0,
            // rowsPerPage: 10,
            // count: 100,
            // data: [],
            // reqParams: "",
            // filter: {
            //     skip: 0,
            //     limit: 10,
            //     sortBy: "created_at",
            //     sortDir: "desc",
            //     keyword: ""
            // },
            // refreshTable: true
        }
        // this.openUploadForm = this.openUploadForm.bind(this)
        // this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: blacklistConstants.CLEAR_PAGE_STATE });
    }

    componentDidMount() {
        this.setState({
            refreshTable: true
        })
    }

    render() {
        const { classes, dispatch, importResult, tableKey } = this.props;

        const columns = [
            {
                label: "Line.", name: "line",
                options: {
                    filter: false,
                    sort: false
                }
            },
            { label: "type", name: "type" },
            { label: "value", name: "value" },
            {
                label: "status", name: "status",
                options: {
                    customBodyRender: (value) => {
                        return renderBadgeStatus(value)
                    }
                }
            }
        ];

        const options = {
            filterType: 'dropdown',
            responsive: 'stacked',
            selectableRows: "none",
            print: false,
            download: false,
        };

        return (

            <div id="TableBlacklistListUpload">
                <CustomSweetAlert />
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable

                        data={importResult}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, importResult, formImportInitialValue, formImportOpen, tableKey } = state.blacklist;
    const { visiblePersonalData, merchantSetting } = state.authentication;

    return {
        formInitialValue, tableReload, tableReset, visiblePersonalData, importResult, merchantSetting, formImportInitialValue, formImportOpen, tableKey
    };
}
export default connect(mapStateToProps)(withStyles(pageStyle)(TableBlacklistListUpload));  

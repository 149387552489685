import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import { crmValidator, alert } from 'helpers';
import { authActions } from 'actions';
import { loaderActions } from 'actions';

import i18n from 'i18n';

const FORM_NAME = 'loginForm';
const form_validate = {
  "username": {
    rules: {
      required: true,
      // isEmail: true
    },
    messages: {
      required: 'warning_messages.username_is_required',
      // isEmail: 'warning_messages.email_is_invalid',
    },
    alert: 'warning_messages.username_is_required'
  },
  "password": {
    rules: {
      required: true,
    },
    messages: {
      required: 'warning_messages.password_is_required'
    },
    alert: 'warning_messages.password_is_required'
  }
}

const validate = values => {
  const errors = crmValidator.validateByRulesList(form_validate, values);
  return errors
}

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };

    this.props.dispatch(authActions.logout());
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
    this.getEnvConfig()
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleSubmit = (values) => {
    const username = values.username;
    const password = values.password;
    const { dispatch } = this.props;
    dispatch(authActions.Login(username, password));
  }

  handleSubmitByCUAA = (values) => {
    const { dispatch } = this.props;

    const username = values.username;
    const password = values.password;
    dispatch(authActions.LoginByCUAA(username, password));
  }

  getEnvConfig() {
    const { dispatch } = this.props;
    dispatch(loaderActions.getConfig());
  }

  render() {
    const { handleSubmit, submitting, classes, pristine, dispatch, envConfig } = this.props;
    let appEnv = _.get(envConfig, 'APP_ENV') ? _.get(envConfig, 'APP_ENV') : ""
    return (
      <>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={7} md={4}>
              <form onSubmit={handleSubmit(this.handleSubmit)} name={FORM_NAME}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  {/* <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader> */}
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <img src={"/images/egg-logo-text.svg"} width="80" alt=""></img>
                    <div style={{ textAlign: "center", marginTop: "20px", color: "#999" }}>กรุณา Log-in เพื่อเข้าสู่ระบบ</div>
                  </div>
                  <CardBody style={{ paddingTop: "0px" }}>
                    <Field
                      name="username"
                      component={CustomInput}
                      type="text"
                      labelText={i18n.t("label.username")}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      // inputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <Email className={classes.inputAdornmentIcon} />
                      //     </InputAdornment>
                      //   )
                      // }}
                    />
                    <Field
                      name="password"
                      component={CustomInput}
                      type="password"
                      labelText={i18n.t("label.password")}
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                          </Icon>
                          </InputAdornment>
                        )
                      }}
                    />
                    { appEnv !== 'production' ? 
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <Button id="btn_login" color="primary" type="submit" fullWidth={true} disabled={submitting}>
                          Submit
                      </Button>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Button
                          id="btn_login_cuaa"
                          color="primary"
                          type="button"
                          fullWidth={true}
                          disabled={submitting}
                          onClick={handleSubmit(this.handleSubmitByCUAA)}
                        >
                          CUAA Login
                      </Button>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Button id="btn_clear" color="primary" name="reset" simple type="button" variant="outlined" fullWidth={true} disabled={submitting}
                          onClick={() => {
                            crmValidator.resetForm(FORM_NAME, dispatch, pristine);
                          }}>
                          Clear
                      </Button>
                      </GridItem>
                    </GridContainer>
                    :
                    <GridContainer>
                      <GridItem xs={6} sm={6} md={6}>
                        <Button id="btn_login" color="primary" type="submit" fullWidth={true} disabled={submitting}>
                          Submit
                      </Button>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <Button id="btn_clear" color="primary" name="reset" simple type="button" variant="outlined" fullWidth={true} disabled={submitting}
                          onClick={() => {
                            crmValidator.resetForm(FORM_NAME, dispatch, pristine);
                          }}>
                          Clear
                      </Button>
                      </GridItem>
                    </GridContainer>
                    }

                    {/* <GridContainer justify="center">
                    <GridItem xs={9} sm={9} md={9}>
                      <Button color="primary" simple type="button" fullWidth={true} >
                        Forgot your password?
                      </Button>
                    </GridItem>
                  </GridContainer> */}

                  </CardBody>
                  {/* <CardFooter className={classes.justifyContentCenter}></CardFooter> */}
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

LoginPage = reduxForm({
  form: FORM_NAME,
  validate: validate,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, props) => {
    crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate, i18n.t('warning_messages.username_is_required'));
  }
})(LoginPage);

function mapStateToProps(state) {
  const { loader, envConfig } = state.loader;
  const formValues = getFormValues(FORM_NAME)(state)
  return { loader, formValues, envConfig };
}

const connectApp = connect(mapStateToProps)(withStyles(loginPageStyle)(LoginPage));

export { connectApp as LoginPage }

import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import MUIDataTable from "mui-datatables";
import { pointByPurchaseActions } from "actions";
import { plugins, crmValidator, loader, alert as customAlert } from 'helpers';
import { pointByPurchaseServices } from 'services'

// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'
import Button from "components/CustomButtons/Button.jsx";
import { pointByPurchaseConstants, swalConstants } from '../../constants';
import Badge from "components/Badge/Badge.jsx";
import PointByPurchaseForm from 'views/PointByPurchase/PointByPurchaseForm.jsx';
import i18n from "i18n";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownload from "@material-ui/icons/CloudDownload";

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    actionDiv: {
        display: "inline-flex",
    },
};

const moduleName = "point[point_by_purchase]";

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                padding: '4px 24px 4px 15px',
                "&:nth-child(9)": {
                    textAlign: "center"
                }
            },
            body: {
                padding: '4px 24px 4px 15px',
                "&:nth-child(18)": {
                    textAlign: "center"
                }
            }
        }
    }
});

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case 'success' : BadgeColor = "success"; break;
        case 'voided' : BadgeColor = "danger"; break;
        default: BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}

class TablePointByPurchaseList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            sortBy: "created_at",
            sortDir: "desc",
            reqParams: ""
        }
        this.csvLink = React.createRef();
        this.delayedCallback = lodash.debounce(this.getData, 500)
    };

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: pointByPurchaseConstants.TABLE_RELOAD_SUCCESS })
        }
    };

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: pointByPurchaseConstants.CLEAR_PAGE_STATE })
    };

    getRowData(rowIndex = 0) {
        const { tableData } = this.props;
        const data = tableData.data;
        return data[rowIndex];
    };

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableData, tableFilter } = this.props
        let reqParams = tableFilter;
        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)
            if (tableData.total !== 0 && tableData.total === skip) {
                skip = skip - limit
                this.setState({
                    page: this.state.page - 1,
                })
            }
            if (skip >= tableData.total) {
                skip = 0;
                this.setState({
                    page: 0,
                })
            }
            this.setState({
                reqParams: {
                    skip: skip,
                    limit: limit,
                },
            })
            reqParams.skip = skip;
            reqParams.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams.keyword = keyword;
            }
        } else {
            reqParams = this.state.reqParams
        }
        dispatch(pointByPurchaseActions.getTablePointByPurchaseDatas(reqParams));
        this.setState({
            reqParams: reqParams
        });
    };

    openFormAdd = () => {
        const { dispatch, formInitialValue } = this.props;
        dispatch({ type: pointByPurchaseConstants.OPEN_ADD_POINT_BY_PURCHASE, formInitialValue: { formInitialValue } });
    };

    exportCSV() {
        const { tableFilter, tableData, dispatch } = this.props;
        tableFilter.skip = 0;
        tableFilter.limit = tableData.total ? tableData.total : 100000;
        if (tableData.total > 30000) {
            customAlert(
                dispatch,
                "error",
                "Can not export data more than 30,000 rows"
            );
        } else {
            loader(dispatch, true);
            pointByPurchaseServices
                .downloadPointByPurchaseTransaction(tableFilter)
                .then((response) => {
                    loader(dispatch, false);
                })
                .catch((e) => {
                    this.setState({ csvData: [] });
                })
        }
    }

    render() {
        const { classes, tableKey, tableData, formInitialValue, dispatch, visiblePersonalData, showLoader } = this.props
        const { page, rowsPerPage } = this.state
        let columns = [
            { name: "_id", options: { display: "excluded" } },
            {
                label: i18n.t("label.no"),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowSatrt =
                            tableMeta.tableState.page *
                            tableMeta.tableState.rowsPerPage;
                        const rowIndex = rowSatrt + tableMeta.rowIndex;
                        return rowIndex + 1;
                    },
                },
            },
            {
                name: "created_at", label: "Date/Time", options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        const createdDateStr = (rowData.created_at !== undefined || rowData.created_at != null) ? (<span className="text-nowrap">{plugins.datetimeFormat(rowData.created_at, "datetime")}</span>) : ""
                        return createdDateStr
                    }
                }
            },
            { name: "transactionId", label: "Transaction ID" },
            { name: "memberFullName", label: "Name" },
            { name: "phoneNumber", label: "Mobile No." },
            {
                name: "purchaseDate", label: "Purchase Date", options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        const purchaseDateStr = (rowData.purchaseDate !== undefined || rowData.purchaseDate != null) ? (<span className="text-nowrap">{plugins.datetimeFormat(rowData.purchaseDate, "date")}</span>) : ""
                        return purchaseDateStr
                    }
                }
            },
            { name: "purchaseAmount", label: "Purchase Amount" },
            { name: "earnPoint", label: "Earn Points" },
            { name: "refCode", label: "Reference Code" },
            { name: "remark", label: "Remark" },
            { name: "created_by", label: "Updated By" },
            {
                name: "status", label: "Status", options: {
                    filter: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex);
                        let statusDisplay = rowData.status ? 'success' : 'voided'
                        return renderBadgeStatus(statusDisplay)
                    }
                }
            },
            { name: "source", label: "Source" },
        ];
        if (plugins.isHasPermission(moduleName, 'read') && visiblePersonalData) {
            columns.push({
                label: "",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowIndex = tableMeta.rowIndex;
                        const rowData = this.getRowData(rowIndex);
                        return (
                            <>
                                {plugins.isHasPermission(moduleName, "update") && (
                                    <Button
                                        id='void-btn'
                                        variant='contained'
                                        bcolor='primary'
                                        size='sm'
                                        onClick={() => {
                                            let transId = rowData.transactionId ? <b>'{rowData.transactionId}'</b> : ''
                                            let pointToVoid = lodash.get(rowData, 'earnPoint') ? <><br/>{rowData.earnPoint} Point(s)</> : ''
                                            dispatch({
                                                type: swalConstants.SHOW, data: {
                                                    type: "warning",
                                                    title: i18n.t("warning_messages.are_you_sure_void_point_by_purchase"),
                                                    message: <>{transId} {pointToVoid}</>,
                                                    onConfirm: function () {
                                                        let reqParams = {
                                                            transactionID: rowData.transactionId,
                                                            shopId: "crm_system",
                                                            remark: "void"
                                                        }
                                                        dispatch({ type: swalConstants.HIDE })
                                                        dispatch(pointByPurchaseActions.voidPointByPurchase(reqParams));
                                                    },
                                                    onCancel: function () {
                                                        dispatch({ type: swalConstants.HIDE })
                                                    },
                                                    showCancel: true,
                                                    confirmBtnText: i18n.t("void"),
                                                    cancelBtnText: i18n.t("cancel")
                                                }
                                            })
                                        }}
                                        disabled={!rowData.status}
                                        style={{ backgroundColor: '#f44336' }}>
                                        Void
                                    </Button>
                                )}
                            </>
                        )
                    }
                }
            });
        }

        const options = {
            serverSide: true,
            count: tableData.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
            customToolbar: () => {
                return (
                    <>
                        {plugins.isHasPermission(moduleName, "export") && plugins.isHasPackageFeatures('export') &&(
                            <Tooltip title="Export CSV">
                                <IconButton id="button-point-by-purchase-table-exportCSV" className="" onClick={this.exportCSV.bind(this)}>
                                    <CloudDownload />
                                </IconButton>
                            </Tooltip>
                        )}
                        {plugins.isHasPermission(moduleName, 'create') && (
                            <CustomToolbar handleClick={this.openFormAdd.bind(this)} title="Add New" id='btn_add' />
                        )}

                    </>
                );
            },
        };
        return (
            <div id='tablePointByPurchaseList'>
                <CustomSweetAlert />
                <PointByPurchaseForm initialValues={formInitialValue} />
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                    {showLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { tableKey, tableData, formInitialValue, tableReset, showLoader, tableFilter, tableReload } = state.point_by_purchase;
    const { permission, superAdmin, visiblePersonalData } = state.authentication;
    return {
        permission, superAdmin, visiblePersonalData, tableKey, tableData, formInitialValue, tableReset, showLoader, tableFilter, tableReload
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TablePointByPurchaseList));
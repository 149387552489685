import React from "react";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

//page components
import MerchantForm from "views/Merchant/MerchantForm.jsx";
import SettingForm from "./SettingForm.jsx";
import TrueMoneyBudget from "./TrueMoneyBudget";
import Brand from "./Brand";

import NavPills from "components/NavPills/NavPills.jsx";

import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const pageStyle = { ...style, ...sweetAlertStyle };

class MerchantManagement extends React.Component {
   
   setTab = mode => {
    const { formInitialValue } = this.props
    let set = [
      {
        tabButton: "Profile",
        tabContent: (
          <MerchantForm initialValues={formInitialValue} readOnly={false} />
        )
      }
    ];
    if (mode !== "add") {

      set.push({
        tabButton: "Setting",
        tabContent: <SettingForm initialValues={formInitialValue} readOnly={false} />
      })

      set.push({
        tabButton: "TRUE MONEY BUDGET",
        tabContent: <TrueMoneyBudget />
      })

      set.push({
        tabButton: "BRAND",
        tabContent: <Brand />
      })
    }
    
    return set;
  };

  render() {
    const { formOpen, formMode } = this.props;
    const displayCard = formOpen ? { padding: "15px" } : { display: "none" };
    return (
      <Card style={displayCard}>
        <CardBody>
          <CustomSweetAlert />
          {formOpen &&
            <NavPills
              color="warning"
              tabBtnType="square"
              tabs={this.setTab(formMode) }
            />
          }
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { formInitialValue, formOpen, formMode } = state.merchant;
  return {
    formInitialValue,
    formOpen,
    formMode
  };
}

export default connect(mapStateToProps)(
  withStyles(pageStyle)(MerchantManagement)
);

import React from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables";
import lodash from 'lodash';
// @material-ui/core components
import { MuiThemeProvider, createMuiTheme, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
// core components
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PieChartIcon from '@material-ui/icons/PieChart';

import FormDataVisualization from './FormPopup/FormVisualization';
import FormCampaigns from './FormPopup/FormCampaigns';

import { treemapActions } from 'actions';
import { plugins, crmValidator, alert as customAlert } from 'helpers';
import { treemapConstants } from '../../constants';

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const moduleName = 'luckyDraw[luckydrawWinnerManagement]';

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                "&:nth-child(11)": {
                    textAlign: "right"
                }
            },
            body: {
                "&:nth-child(22)": {
                    textAlign: "right"
                }
            }
        }
    }
});

class TableTreeMapList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readOnly: true,
            page: 0,
            rowsPerPage: 10,
            count: 100,
            data: [],
            reqParams: "",
            filter: {
                skip: 0,
                limit: 10,
                sortBy: "id",
                sortDir: "asc",
                keyword: ""
            },
            csvData: [],
            // refreshTable: true
        };
        this.csvLink = React.createRef()
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableFilter } = this.props
        // if (this.state.refreshTable) {
        //     tableFilter.skip = null
        //     tableFilter.sortBy = "id"
        //     tableFilter.sortDir = "asc"
        //     tableFilter.keyword = ""
        //     tableFilter.search = {}
        //     this.setState({
        //         refreshTable: false
        //     })
        // }

        let reqParams = tableFilter;

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                reqParams.skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                reqParams.limit = 10
            }
            reqParams.page = skip;
            this.setState({
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)

            reqParams.skip = skip;
            reqParams.limit = limit;

            if (!crmValidator.isEmptyValue(sortBy)) {
                reqParams.sortBy = sortBy;
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams.sortDir = sortDir;
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams.keyword = keyword;
            } else {
                reqParams.keyword = ""
            }
        } else {
            reqParams = tableFilter
        }

        dispatch(treemapActions.getTableDatas(reqParams));
        console.log("getTableDatas : ",reqParams);
        console.log("getTableDatas crmValidator : ",crmValidator);
        
        this.setState({
            isLoading: true,
            reqParams: reqParams
        });
    }

    getRowData(rowIndex = 0) {
        const { tableDatas } = this.props;
        const data = tableDatas;
        return data[rowIndex];
    }

    // componentWillMount() {
    //     const { dispatch } = this.props;
    //     dispatch({ type: treemapConstants.CLEAR_PAGE_STATE });
    // }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: treemapConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    componentDidMount() {
        // this.setState({
        //     refreshTable: true
        // })

        // this.getData();
    }

    openEditWinnerDetail = (rowIndex) => {
        const { dispatch, tableDatas } = this.props;
        let rowData = tableDatas[rowIndex]
        dispatch({ type: treemapConstants.OPEN_FORM_EDIT, formInitialValue: { ...rowData } })
    }
    openVisualForm() {
        // const { dispatch, formFilterTreemap } = this.props;
        const { dispatch, tableFilter } = this.props
        let templateType = lodash.get(tableFilter, 'search')
        if(lodash.isEmpty(templateType)){
            customAlert(dispatch, 'error', "Please select segment")
        }else{
            // templateType.push({
            //     "filename": "dcs_sss_ss.json",
            //     "path": "rfm-result",
            // })
            templateType['filename'] ="dcs_sss_ss.json";
            templateType['path'] ="rfm-result";
            dispatch(treemapActions.visualizationData(templateType));
        }
    }

    openCampaignsForm() {
        const { dispatch, tableFilter } = this.props
        let templateType = lodash.get(tableFilter, 'search')
        if(lodash.isEmpty(templateType)){
            customAlert(dispatch, 'error', "Please select segment")
        }else{
            //OPEN_FORM_CAMPAIGNS
        let reqParams = "?&skip=0&limit=10"
        dispatch(treemapActions.getListRFMCampaigns(templateType))
        dispatch(treemapActions.getListCampaigns(reqParams))
            // dispatch({type: treemapConstants.OPEN_FORM_CAMPAIGNS, data: templateType })
            
        }
    }

    render() {
        const { formInitialValue, tableDatas, tableKey, visiblePersonalData, showLoader, tableTotal, tableFilter } = this.props;
        const { rowsPerPage } = this.state;
        let columns = [
            { name: "_id", options: { filter: false, display: "excluded" } },
            { name: "displayId", options: { filter: false, display: "excluded" } },
            {
                label: "No.",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {

                        const rowSatrt = tableMeta.tableState.page * tableMeta.tableState.rowsPerPage
                        const rowIndex = rowSatrt + tableMeta.rowIndex;

                        return rowIndex + 1;
                    }
                }
            },
            {
                name: "segment", label: "Segment", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.segment !== undefined || rowData.segment != null) {
                            return (rowData.segment);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "firstname", label: "Firstname", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.firstname !== undefined || rowData.firstname != null) {
                            return (rowData.firstname);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "lastname", label: "Lastname", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.lastname !== undefined || rowData.lastname != null) {
                            return (rowData.lastname);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "gender", label: "Gender", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.gender !== undefined || rowData.gender != null) {
                            return (rowData.gender);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "birthday", label: "Birthday", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.birthday !== undefined || rowData.birthday != null) {
                            return (rowData.birthday);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "mobile", label: "Mobile", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.mobile !== undefined || rowData.mobile != null) {
                            return (rowData.mobile);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "email", label: "Email", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.email !== undefined || rowData.email != null) {
                            return (rowData.email);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "province", label: "Province", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.province !== undefined || rowData.province != null) {
                            return (rowData.province);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "recency", label: "Recency (days)", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.recency !== undefined || rowData.recency != null) {
                            return (rowData.recency);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "frequency", label: "Frequency (times)", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.frequency !== undefined || rowData.frequency != null) {
                            return (rowData.frequency);
                        } else {
                            return "";
                        }
                    }
                }
            },
            {
                name: "monetary", label: "Monetary (bath)", options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        if (rowData.monetary !== undefined || rowData.monetary != null) {
                            return (rowData.monetary);
                        } else {
                            return "";
                        }
                    }
                }
            },
            
            // {
            //     name: "period.announceDate", label: "Announce Date", options: {
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             const rowData = this.getRowData(tableMeta.rowIndex)

            //             if (rowData.period.announceDate !== undefined || rowData.period.announceDate != null) {
            //                 return (plugins.datetimeFormat(rowData.period.announceDate, "fulldatetime"));
            //             } else {
            //                 return "";
            //             }
            //         }
            //     }
            // },
            // {
            //     name: "period.rewardLuckyDraw.type", label: "Reward Type", options: {
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             const rowData = this.getRowData(tableMeta.rowIndex)

            //             if (rowData.period.rewardLuckyDraw.type !== undefined || rowData.period.rewardLuckyDraw.type != null) {
            //                 return (rowData.period.rewardLuckyDraw.type);
            //             } else {
            //                 return "";
            //             }
            //         }
            //     }
            // },
            // {
            //     name: "period.rewardLuckyDraw.name_en", label: "Reference Name (EN)", options: {
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             const rowData = this.getRowData(tableMeta.rowIndex)

            //             if (rowData.period.rewardLuckyDraw.name_en !== undefined || rowData.period.rewardLuckyDraw.name_en != null) {
            //                 return (rowData.period.rewardLuckyDraw.name_en);
            //             } else {
            //                 return "";
            //             }
            //         }
            //     }
            // },
    
            // {
            //     name: "status", label: "Status", options: {
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             const rowData = this.getRowData(tableMeta.rowIndex)

            //             if (rowData.status !== undefined || rowData.status != null) {
            //                 return (rowData.status);
            //             } else {
            //                 return "";
            //             }
            //         }
            //     }
            // },
        ];

        let options = {
            serverSide: true,
            count: (tableTotal ? tableTotal : 0),
            page: tableFilter.page ? tableFilter.page : 0,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            customToolbar: () => {
                return (
                   <>
                      {/* {plugins.isHasPermission("report[burn]", 'export') && plugins.isHasPackageFeatures('export') && */}
                         <Tooltip title="Visualization">
                            <IconButton className="" onClick={this.openVisualForm.bind(this)}> 
                            {/* onClick={this.exportCSV.bind(this)} */}
                               <PieChartIcon />
                            </IconButton>
                         </Tooltip>
                         <Tooltip title="Campaigns">
                            <Button 
                                type="button"
                                size="sm"
                                color="success"
                                id='btn_campaigns'
                                onClick={this.openCampaignsForm.bind(this)}
                            >
                                    CAMPAIGNS
                            </Button>
                         </Tooltip>
                      {/* } */}
                   </>
                );
             },
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }
                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }
                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };

        return (
            <div id="tableTreeMapList">
                <CustomSweetAlert />
                <FormDataVisualization
                    // readOnly={!plugins.isHasPermission(moduleName, 'update')}
                />
                <FormCampaigns
                    // readOnly={!plugins.isHasPermission(moduleName, 'update')}
                />

                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        data={tableDatas}
                        columns={columns}
                        options={options}
                    />
                    {showLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { formInitialValue, tableReload, tableReset, tableDatas, tableKey, showLoader, tableTotal, tableFilter, treemapList } = state.treemap;
    const { visiblePersonalData, merchantSetting } = state.authentication;

    return {
        formInitialValue, tableReload, tableReset, visiblePersonalData, tableDatas, tableKey, showLoader, merchantSetting, tableTotal, tableFilter, treemapList
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableTreeMapList));  

import React from "react";
import { Field, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import lodash from 'lodash';
import moment from 'moment'
import { plugins } from "helpers";
import qs from 'qs'
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";
import { crmValidator } from 'helpers';
import TablePointAdjustment from "./TablePointAdjustment.jsx";
import { pointAdjustmentActions } from 'actions';
import { pointAdjustmentConstants } from '../../constants';

const style = {}
const FORM_NAME = 'FORM_FILTER_POINT_ADJUSTMENT';
const form_validate = {
    phoneNumber: {
      rules: {
        number: true
      }
    }
};

class PointAdjustment extends React.Component {

    handleSubmit(values) { 
        const { dispatch, tableFilter } = this.props;
        let _tableFilter = {
            page: 0,
            skip: 0,
            limit: tableFilter.limit,
            sortBy: "created_at",
            sortDir: "desc",
        }
        _tableFilter = {
          ..._tableFilter,
          ...values
        }
        const resetTable = true;
        dispatch({ type: pointAdjustmentConstants.SET_FILTER_SEARCH, search: _tableFilter })
        dispatch(pointAdjustmentActions.getTablePointAdjustmentDatas(_tableFilter, resetTable));
    }

    clearFilter = () => {
        const { dispatch, tableFilter } = this.props;
        let _tableFilter = {
            page: 0,
            skip: 0,
            limit: tableFilter.limit,
            sortBy: "created_at",
            sortDir: "desc",
        }
        dispatch({ type: "CLEAR_POINT_ADJUSTMENT_FILTER" })
        dispatch(reset(FORM_NAME))
        dispatch(pointAdjustmentActions.getTablePointAdjustmentDatas(_tableFilter));
    }

    componentDidMount() {
        const { tableFilter, dispatch } = this.props; 
        let _tableFilter = {
            page: 0,
            skip: 0,
            limit: tableFilter.limit,
            sortBy: "created_at",
            sortDir: "desc",
        }
        dispatch(pointAdjustmentActions.getTablePointAdjustmentDatas(_tableFilter));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: "CLEAR_POINT_ADJUSTMENT_FILTER" })
        dispatch(reset(FORM_NAME))
    }

    render() {
        const { handleSubmit, FORM_FILTER_POINT_ADJUSTMENT } = this.props;

        let startMaxDateAdjustment = new Date();
        if (lodash.get(FORM_FILTER_POINT_ADJUSTMENT, 'values.createDateTo')) {
            startMaxDateAdjustment = FORM_FILTER_POINT_ADJUSTMENT.values.createDateTo;
        }
        let endMinDateAdjustment;
        if (lodash.get(FORM_FILTER_POINT_ADJUSTMENT, 'values.createDateFrom')) {
            endMinDateAdjustment = FORM_FILTER_POINT_ADJUSTMENT.values.createDateFrom;
        }
        let startMaxDateApprovedReject = new Date();
        if (lodash.get(FORM_FILTER_POINT_ADJUSTMENT, 'values.updateDateTo')) {
            startMaxDateApprovedReject = FORM_FILTER_POINT_ADJUSTMENT.values.updateDateTo;
        }
        let endMinDateApprovedReject;
        if (lodash.get(FORM_FILTER_POINT_ADJUSTMENT, 'values.updateDateFrom')) {
            endMinDateApprovedReject = FORM_FILTER_POINT_ADJUSTMENT.values.updateDateFrom;
        }
        return (
            <div>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="adjustment-start-date-input"
                                name="createDateFrom"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Adjustment Start Date"
                                maxDate={startMaxDateAdjustment}
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="adjustment-end-date-input"
                                name="createDateTo"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Adjustment End Date"
                                minDate={endMinDateAdjustment}
                                maxDate={new Date()}
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="approved-rejected-start-date-input"
                                name="updateDateFrom"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Approved / Rejected Start Date"
                                maxDate={startMaxDateApprovedReject}
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="approved-rejected-end-date-input"
                                name="updateDateTo"
                                component={CustomDatePicker}
                                type="text"
                                labelText="Approved / Rejected End Date"
                                minDate={endMinDateApprovedReject}
                                maxDate={new Date()}
                                dateFormat="DD-MMM-YY"
                                timeFormat="HH:mm:ss"
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="action-input"
                                name="type"
                                component={CustomSelect}
                                type="text"
                                labelText="Action"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    {
                                        label: 'Add Point',
                                        value: 'add'
                                    }, 
                                    {
                                        label: 'Deduct Point',
                                        value: 'deduct'
                                    },
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="approvement-status-input"
                                name="status"
                                component={CustomSelect}
                                type="text"
                                labelText="Approvement Status"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                optionsList={[
                                    {
                                        label: 'No need to approve',
                                        value: 'no_need_approved'
                                    }, 
                                    {
                                        label: 'Waiting for approval',
                                        value: 'waiting'
                                    },
                                    {
                                        label: 'Approved',
                                        value: 'approved'
                                    }, 
                                    {
                                        label: 'Rejected',
                                        value: 'rejected'
                                    },
                                ]}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Field
                                id="phone-number-input"
                                name="phoneNumber"
                                component={CustomInput}
                                type="text"
                                labelText="Mobile Number"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                normalize={(value) => {
                                    return crmValidator.handleKeyPress(value, form_validate.phoneNumber)
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <Button
                                id="clear-btn"
                                onClick={this.clearFilter}
                                style={{
                                    marginRight: "10px", marginTop: "25px"
                                }}
                                size="sm"
                                color="white">
                                Clear
                            </Button>
                            <Button
                                id="submit-btn"
                                type="submit"
                                style={{
                                    marginTop: "25px"
                                }}
                                size="sm"
                                color="primary">
                                Submit
                            </Button>


                        </GridItem>
                    </GridContainer>
                </form>

                <TablePointAdjustment />

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { tableFilter, allPromotionList } = state.point_adjustment;
    const { FORM_FILTER_POINT_ADJUSTMENT } = state.form;
    const { merchantSetting } = state.authentication;

    return {
        merchantSetting, tableFilter, allPromotionList, FORM_FILTER_POINT_ADJUSTMENT
    }
}

const connectedComponent = connect(mapStateToProps)(PointAdjustment);

export default reduxForm({
    form: FORM_NAME,
})(withStyles(style)(connectedComponent))
import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import lodash from 'lodash';

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";
import { dashboardActions } from "actions/dashboard.actions";


const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    actionDiv: {
        display: "inline-flex"
    }
};

const pageStyle = { ...style };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                textAlign: "right",
                padding: '4px 24px 4px 15px',
                "&:nth-child(1)": {
                    textAlign: "left",
                },
                "&:nth-child(9)": {
                    fontWeight: "bold",
                    color: '#000'
                },
                width: '90px',
            },
            body: {
                textAlign: "right",
                padding: '1px 5px 1px 1px',
                "&:nth-child(2)": {
                    padding: '4px 15px',
                    textAlign: "left",
                    fontWeight: "bold",
                    maxWidth: '75px'
                },
                "&:nth-child(18)": {
                    fontWeight: "bold",
                }
            },
        },
    }
});
const colorSet = ['#FDEDEC', '#FADBD8', '#F5B7B1', '#F1948A', '#EC7063', '#E74C3C']

class TableDashboardEarnByDayAndHour extends React.Component {
    
    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(dashboardActions.getTableEarnTransactionByDayAndHourDatas());
    }

    getRowData(rowIndex = 0) {
        const { earnTransactionByDayHour } = this.props;
        var data = earnTransactionByDayHour.data;
        return data[rowIndex];
    }

    getColor(value) { 
        if(value > -1 && value < 1001){
            return 0; 
        }else if(value > 1000 && value < 5001) {
            return 1; 
        }else if (value > 5000 && value < 10001) { 
            return 2; 
        }else if (value > 10000 && value < 15001) {
            return 3; 
        }else if (value > 15000 && value < 20001) {
            return 4; 
        }else if(value > 20000) {
            return 5; 
        }
    }

    showData(value, tableMeta) {
        const rowData = this.getRowData(tableMeta.rowIndex)
        if(rowData.hour === 'Grand Total'){
            return <b>{value}</b>
        }else{
            if(value > -1){
                let bgColor = this.getColor(value)
                return (<div style={{height: '40px', padding: '10px 10px 0px 0px', backgroundColor: colorSet[bgColor], boxShadow:`0px 0px 3px ${colorSet[bgColor]}` }}>{value}</div>)
            }else{
                return value;
            }
        }
    }
    render() {
        const { earnTransactionByDayHour, showLoader, visiblePersonalData } = this.props;
        let columns = [
            { name: "hour", label: "Hour"},
            { name: "sunday", label: "Sunday" , options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return this.showData(value, tableMeta);
                },
            }},
            { name: "monday", label: "Monday" , options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return this.showData(value, tableMeta);
                },
            }},
            { name: "tuesday", label: "Tuesday" , options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return this.showData(value, tableMeta);
                },
            }},
            { name: "wednesday", label: "Wednesday", options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return this.showData(value, tableMeta);
                },
            }},
            { name: "thursday", label: "Thursday" , options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return this.showData(value, tableMeta);
                },
            }},
            { name: "friday", label: "Friday" , options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return this.showData(value, tableMeta);
                },
            }},
            { name: "saturday", label: "Saturday" , options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return this.showData(value, tableMeta);
                },
            }},
            { name: "grandTotal", label: "Grand Total" },
        ]

        let options = {
            serverSide: false,
            count: false,
            page: false,
            rowsPerPage: 13,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: false,
            search: false,
            pagination: false,
            sort: false,
            sortFilterList: false,
            viewColumns: false,
        };

        return (
            <div id="tableDashboardList">
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={showLoader?0:1}
                        data={lodash.get(earnTransactionByDayHour,'data') ? lodash.get(earnTransactionByDayHour,'data'):[]}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { earnTransactionByDayHour, showLoader, tableTotal } = state.dashboard;
    const { visiblePersonalData } = state.authentication;
    return {
        earnTransactionByDayHour, showLoader, tableTotal, visiblePersonalData
    };
}
export default connect(mapStateToProps)(withStyles(pageStyle)(TableDashboardEarnByDayAndHour));  
import React from 'react';
import { connect } from 'react-redux';
import { Field, change, unregisterField } from 'redux-form';
import { normalize, crmValidator } from 'helpers';
import lodash from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import Loader from 'react-loader-spinner';

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomInputRadio.jsx"

import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from '@material-ui/core/Icon';
import Close from "@material-ui/icons/Close";
import Search from '@material-ui/icons/Search';

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { photoGiveawayRuleConstants } from '../../constants';
import { photoGiveawayRulesActions } from 'actions';

const validateRequired = value => {
  if (value === null || value === undefined || value === "") {
    return "Please enter"//กรุณากรอก
  }
}

const validatePhotoCount = value => {
  if (value === 0) {
    return "Please select at least a photo"//กรุณาเลือกสินค้าอย่างน้อย 1 รายการ
  }
}
const validateIsZero = (values) => {
  if (parseInt(values) === 0) {
    return "Please more than 0"
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  tableHead: {
    backgroundColor: "#eee",
    position: "sticky",
    top: 0,
    padding: "0px 10px",
    zIndex: 10
  },
  tableCell: {
    padding: "0px 10px"
  },
  tableCellLoader: {
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  tableHover: {
    transitionDuration: "0.3s",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#efefef"
    }
  },
  tableSelectPhoto: {
    maxHeight: "300px",
    overflow: "auto",
    marginBottom: "10px"
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  noPaddingTop: {
    "& > div": {
      paddingTop: "0px"
    }
  },
  textUpper: {
    textTransform: 'uppercase'
  },
  haveNoData: {
    color: "#777",
    fontStyle: "italic",
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  tableError: {
    border: "solid 2px red"
  }
});

const validate_field = {
  numberOnly: {
    rules: {
      required: true,
      number: true,
      noSpecialCharacters: true,
    },
  },
}
const FORM_NAME = 'FORM_PHOTOGIVEAWAYRULES';

class RulePhoto extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      keyword: undefined,
      addingPhoto: false
    }
    this.onSearchPhotoChange = lodash.debounce((keyword) => {
      this.searchPhotoList(keyword)
    }, 800)
    this.totalDisplayPhoto = 0
  }

  componentDidMount() {
    const { dispatch, formMode } = this.props;
    this.totalDisplayPhoto = 0
    this.getPhotoList()

    //clear selected PhotoList
    if (formMode === "add") {
      dispatch({ type: photoGiveawayRuleConstants.ON_RULE_TYPE_CHANGE_TO_PHOTO })
    }
  }

  getPhotoList(keyword) {
    // console.log('EarnRuleTypeCode getPhotoList')
    const { dispatch } = this.props;
    let reqParams = "sortBy=updated_at&sortDir=desc&status=Active"
    if (keyword) {
      reqParams += `&keyword=${keyword}`
    }
    dispatch(photoGiveawayRulesActions.getListsPhotoGiveawayPhoto(reqParams))
  }

  searchPhotoList(keyword) {
    if (keyword) {
      this.setState({ keyword: keyword })
    } else {
      this.setState({ keyword: undefined })
    }
  }

  addAllPhotoItem() {
    const { selectedPhotoList, dispatch, allPhotoList } = this.props;
    const _this = this;
    let selected = []
    lodash.each(allPhotoList, function (currentObject) {
      let find = lodash.find(selectedPhotoList, ['_id', currentObject._id])
      let isShow = true
      if (_this.state.keyword) {
        let regex = _this.state.keyword.toLowerCase()
        regex = regex.trim()
        isShow = ((currentObject.name.toLowerCase().indexOf(regex) !== -1) || (currentObject.prefix.toLowerCase().indexOf(regex) !== -1))
      }
      if (find === undefined && isShow) {
        selected.push(currentObject)
        // _this.selectPhotoItem(currentObject);
      }
    })
    dispatch({ type: photoGiveawayRuleConstants.RULE_TYPECODE_SELECTED_MANY_PHOTO_ITEM, data: selected })
    dispatch(change(FORM_NAME, 'selectedPhotoCount', lodash.size(selectedPhotoList) + lodash.size(selected)))
    this.setState({ addingPhoto: false });
  }

  selectPhotoItem(data) {
    const { dispatch, selectedPhotoList } = this.props;
    let find = lodash.find(selectedPhotoList, ['_id', data._id])

    // console.log('selectPhotoItem data:',data);

    if (find === undefined) {
      dispatch({ type: photoGiveawayRuleConstants.RULE_TYPECODE_SELECTED_PHOTO_ITEM, data: data })
      dispatch(change(FORM_NAME, 'selectedPhotoCount', lodash.size(selectedPhotoList) + 1))
    }
  }

  removeSelectedItem(data) {
    const { dispatch, selectedPhotoList } = this.props;
    dispatch({ type: photoGiveawayRuleConstants.RULE_TYPECODE_DESELECTED_PHOTO_ITEM, data: data })
    dispatch(unregisterField(FORM_NAME, `photoLimit[${data._id}]`))
    dispatch(change(FORM_NAME, 'selectedPhotoCount', lodash.size(selectedPhotoList) - 1))
  }

//   setAllPhotoPoint() {
//     const { FORM_PHOTOGIVEAWAYRULES, selectedPhotoList, dispatch } = this.props;

//     if (lodash.has(FORM_PHOTOGIVEAWAYRULES, 'values.setAllPhotoPoint')) {
//       let point = FORM_PHOTOGIVEAWAYRULES.values.setAllPhotoPoint
//       lodash.forEach(selectedPhotoList, function (currentObject) {
//         dispatch(change(FORM_NAME, `photoLimit[${currentObject._id}]`, point))
//       })

//       dispatch(change(FORM_NAME, `setAllPhotoPoint`, ''))
//     }
//   }

  renderPhotoListLoader() {
    const { classes } = this.props
    return (
      <TableRow>
        <TableCell
          colSpan={2}
          className={classes.tableCell + " " + classes.tableCellLoader}>
          <Loader
            type="Oval"
            color="#f4b340"
            height="40"
            width="40"
          />
        </TableCell>
      </TableRow>
    )
  }

  renderHaveNodata(cellProps) {
    const { classes } = this.props;
    let colspan = cellProps.colspan;
    if (!colspan) {
      colspan = 2
    }
    return (
      <TableRow>
        <TableCell
          colSpan={colspan}
          className={classes.tableCell + " " + classes.haveNoData}>
          Have no Data
        </TableCell>
      </TableRow>
    )
  }

  renderTablePhotoList() {
    const { classes, selectedPhotoList, formPhotoListLoader, allPhotoList } = this.props;

    if (formPhotoListLoader || this.state.addingPhoto) {
      this.totalDisplayPhoto = 0
      return this.renderPhotoListLoader()
    } else {
      let total = 0;
      if (lodash.size(allPhotoList) > 0) {
        let template = allPhotoList.map(n => {
          let find = lodash.find(selectedPhotoList, ['_id', n._id])
          let show = true
          if (this.state.keyword) {
            let regex = this.state.keyword.toLowerCase()
            regex = regex.trim()
            show = ((n.name.toLowerCase().indexOf(regex) !== -1))
          }

        //   console.log("n :",n);
          if (show && find === undefined) {
            total += 1;
            return (
              <TableRow key={n._id} className={classes.tableHover} onClick={() => {
                this.selectPhotoItem(n)
              }}>
                <TableCell className={classes.tableCell + " " + classes.textUpper}>{n.name}</TableCell>
              </TableRow>
            );
          } else {
            return null
          }
        })
        this.totalDisplayPhoto = total
        if (total === 0) {
          return this.renderHaveNodata({ colspan: 2 })
        } else {
          return template
        }
      } else {
        this.totalDisplayPhoto = 0
        return this.renderHaveNodata({ colspan: 2 })
      }
    }
  }

  selectedPhotoCount = ({ ...props }) => {
    const { meta } = props
    const error = (meta !== undefined && meta.touched && meta.invalid);
    if (error) {
      return (<div style={{ color: "red", margin: "0px 15px 10px 15px" }}>{meta.error}</div>)
    } else {
      return null
    }
  }

  renderSelectedPhotoList() {
    const { selectedPhotoList, classes, readOnly } = this.props
    const _this = this;
    let template = selectedPhotoList.map(n => {
      return (
        <TableRow key={n._id}>
          <TableCell className={classes.tableCell + " " + classes.textUpper}>{n.name}</TableCell>
          {/* <TableCell className={classes.tableCell}>{n.name}</TableCell>
          <TableCell className={classes.tableCell + " " + classes.textUpper}>{n.prefix}</TableCell>
          <TableCell className={classes.tableCell + " " + classes.textUpper}>{n.skuId}</TableCell> */}
          <TableCell className={classes.tableCell}>
            <Field
              name={`photoLimit[${n._id}]`}
              component={CustomInput}
              type="number"
              formControlProps={{
                fullWidth: true
              }}
              min={0}
              validate={[validateRequired]}
              normalize={(value) => {
                return crmValidator.handleKeyPress(value, validate_field.numberOnly)
              }}
              disabled={readOnly}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            {readOnly === false &&
              <Button
                color={"danger"}
                simple
                className={classes.actionButton}
                onClick={() => {
                  _this.removeSelectedItem(n)
                }}>
                <Close className={classes.Close} />
              </Button>
            }
          </TableCell>
        </TableRow>
      );
    })
    return template
  }

  render() {
    const { classes, selectedPhotoList, FORM_PHOTOGIVEAWAYRULES, readOnly } = this.props
    let photoTemplate = this.renderTablePhotoList()

    return (
      <div style={{ border: "dashed 1px #ccc", padding: "0px 20px" }}>
    

        {readOnly === false &&
          <>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <b>Add Photo</b>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={5}>
                <CustomInput
                  placeholder="Search by Photo name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  input={{
                    onChange: (e) => {
                      this.onSearchPhotoChange(e.target.value)
                    }
                  }}
                  startAdornment={(<InputAdornment position="start"><Search style={{ color: "#ccc" }} /></InputAdornment>)}
                  name="searchPhoto" />
              </GridItem>
              <GridItem xs={12} sm={4} md={7} style={{ textAlign: "right" }}>
                <span style={{ position: "relative", top: "35px", right: "20px" }}>Result: {this.totalDisplayPhoto}</span>
                <Button color="primary" size="sm" onClick={() => {
                  this.setState({
                    addingPhoto: true
                  }, () => {
                    this.addAllPhotoItem()
                  });
                }} style={{ float: "right", marginTop: "30px" }}>Add All</Button>
              </GridItem>

              <GridItem xs={12} sm={12} md={12} className={classes.tableSelectPhoto}>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        Name
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ border: "solid 1px #eee" }}>
                    {photoTemplate}
                  </TableBody>
                </Table>
              </GridItem>

              <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                <Icon fontSize="large">arrow_downward</Icon>
              </GridItem>
            </GridContainer>
          </>
        }

        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.tableSelectPhoto}>
            <Table className={
              lodash.has(FORM_PHOTOGIVEAWAYRULES, 'syncErrors.selectedPhotoCount') === true
                && lodash.has(FORM_PHOTOGIVEAWAYRULES, 'fields.selectedPhotoCount.touched') === true
                ? classes.tableError : null
            }>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>
                    Name
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Limit
                  </TableCell>

                  <TableCell className={classes.tableHead}>

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ border: "solid 1px #eee" }}>
                {
                  (lodash.size(selectedPhotoList) > 0) ?
                    this.renderSelectedPhotoList()
                    :
                    this.renderHaveNodata({ colspan: 4 })
                }
              </TableBody>
            </Table>
          </GridItem>

          <Field
            component={this.selectedPhotoCount}
            // validate={[validatePhotoCount]}
            name="selectedPhotoCount"
            type="hidden"
          />

        </GridContainer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { formOpen, formMode, allPhotoList, photoList, selectedPhotoList, formPhotoListLoader } = state.photoGiveawayRule;
  const { FORM_PHOTOGIVEAWAYRULES } = state.form;
  return {
    formOpen, formMode, allPhotoList, photoList, selectedPhotoList, formPhotoListLoader, FORM_PHOTOGIVEAWAYRULES
  };
}

const connectRulePhoto = connect(mapStateToProps)(RulePhoto);

export default withStyles(styles)(connectRulePhoto);
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputUpload from 'components/CustomUpload/InputUpload.jsx';

import { tagConstants } from '../../constants'
import { tagAction } from 'actions';

import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import { CSVLink } from "react-csv";

const FORM_NAME = 'TAG_FORM_IMPORT'
const form_validate = {
    "fileImport": {
        rules: {
            required: true,
            fileSize: 2097152
        },
        messages: {
            required: 'กรุณาเลือกไฟล์',
            fileSize: 'ไฟล์ที่เลือกต้องมีขนาดน้อยกว่า 2MB'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

class TagFormImport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            csvHeaders: [],
            csvData: [],
            csvFileName: ""
        }
        this.csvLink = React.createRef()
    }

    handleSubmit = (values) => {
        
        const { dispatch } = this.props 
        var reqParams = {
            "tagId": values.tagId,
            "fileImport": values.fileImport
        }
        dispatch(tagAction.importMember(reqParams));
    }

    downloadTemplate() {
        let fileName = `Template Import Tag.csv`
        this.genTemplateImportTag(fileName)
    }

    genTemplateImportTag(fileName) {
        const headers = [{
            label: 'No',
            key: 'no'
        }, {
            label: 'First Name',
            key: 'firstName'
        }, {
            label: 'Last Name',
            key: 'lastName'
        }, {
            label: 'Phone',
            key: 'phone'
        }];

        let data = [{
            no: '1',
            firstName: "สมชาย",
            lastName: 'ใจดี',
            phone: '0899999999',
        }, {
            no: '2',
            firstName: "สมหญิง",
            lastName: 'ดีใจ',
            phone: '0988888888',
        }]

        this.setState({ csvHeaders: headers, csvData: data, csvFileName: fileName }, () => {
            this.csvLink.current.link.click()
        })
    }


    render() {
        const { classes, formImportOpen, handleSubmit } = this.props
        let { readOnly } = this.props

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formImportOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>Add Tag by Uploading Member List</b>
                    </h4>
                    
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-import-member-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "30px" }}
                    >
                        <Field
                            name="tagId"
                            component="input"
                            type="hidden"
                        />

                        { formImportOpen &&
                            <Field
                            name="fileImport"
                            component={InputUpload}
                            type="file"
                            label="File Import"
                            accept=".csv"
                            formControlProps={{
                                fullWidth: true
                            }}
                            disabled={readOnly}
                            />
                        }
                        
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        
                        <Button
                            type="button"
                            color="success"
                            style={{ display: "block", padding: "4px 30px" }}
                            onClick={this.downloadTemplate.bind(this)}>
                            <div>Download</div>
                            <div>Template</div>
                        </Button>
                        <CSVLink
                            headers={this.state.csvHeaders}
                            data={this.state.csvData}
                            filename={this.state.csvFileName}
                            style={{ display: "none" }}
                            ref={this.csvLink}
                            id="btn_download_template"
                            target="_blank"
                        />

                        <Button
                            style={{ marginRight: "10px" }}
                            color="primary"
                            type="submit">
                                Submit
                        </Button>

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: tagConstants.CLOSE_TAG_FORM_IMPORT })
                        }}>
                                Cancel
                        </Button>

                    </DialogActions>

                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formImportOpen } = state.tag;
    const { TAG_FORM_IMPORT } = state.form
    return {
        formImportOpen, TAG_FORM_IMPORT
    };
}

const connectedComponent = connect(mapStateToProps)(TagFormImport);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { crmValidator } from 'helpers';
import lodash from 'lodash'

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import ColorSelect from "components/CustomSelect/ColorSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker.jsx";

import { tagConstants } from '../../constants'
import { tagAction } from 'actions';

import Button from "components/CustomButtons/Button.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import moment from 'moment';


const FORM_NAME = 'TAG_FORM'
const form_validate = {
    "name": {
        rules: {
            required: true,
            noSpecialCharacters: true,
            maxLength: 50
        },
        messages: {
            required: 'กรุณากรอก',
            noSpecialCharacters: 'ไม่สามารถใช้อักขระพิเศษในชื่อ Tag ได้',
            maxLength: 'ไม่สามารถใช้ชื่อ Tag ได้มากกว่า 50 ตัวอักษร'
        }
    },
    "color": {
        rules: {
            required: true
        },
        messages: {
            required: 'กรุณาเลือก'
        }
    }
}

const validate = (values) => {
    const errors = crmValidator.validateByRulesList(form_validate, values);
    return errors
}

const normalizeTagMaxLength = (value) => {
    return value.substr(0, 50);
}

class TagForm extends React.Component {
    handleSubmit = (values) => {
        const { dispatch } = this.props 
        console.log('tag values : ',values)
        var reqParams = { 
            "name": values.name,
            "color": values.color,
            "startDate": values.startDate,
            "endDate": values.endDate,
            "status": values.status
        }

        if (this.props.formMode === "add") {
            dispatch(tagAction.addNewTag(reqParams));
        } else {
            reqParams._id = values._id
            dispatch(tagAction.editTag(reqParams));
        }

    }
    
    render() {
        const { classes, formOpen, formMode, handleSubmit, TAG_FORM, initialValues } = this.props
        let { readOnly } = this.props
        if (formMode === `add`) {
            readOnly = false
        } else if (formMode === `edit` && !readOnly) {
            readOnly = false
        } else {
            readOnly = true
        }
        
        let startDateMinDate = new Date().setHours(0, 0, 0, 0)
        if(initialValues.startDate){
            startDateMinDate = moment(initialValues.startDate)
        }

        let endDateMinDate = startDateMinDate;
        if(lodash.has(TAG_FORM, 'values.startDate')){
            if(TAG_FORM.values.startDate){
                endDateMinDate = moment(TAG_FORM.values.startDate)
            }
        }

        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={formOpen}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                fullWidth={true}
                maxWidth="sm"
                scroll="body"
                disableRestoreFocus={true}
            >
                <DialogTitle
                    id="dialog-form-tag-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>
                        <b>{formMode === 'add' ? 'Add Tag' : (readOnly === true ? 'Tag Info' : 'Edit Tag')}</b>
                    </h4>
                </DialogTitle>
                <form name={FORM_NAME} onSubmit={handleSubmit(this.handleSubmit)}>
                    <DialogContent
                        id="tag-form-dialog"
                        className={classes.modalBody}
                        style={{ paddingTop: "0px" }}
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                {formMode === "edit" && 
                                    <Field
                                        name="_id"
                                        component="input"
                                        type="hidden"
                                        disabled={true}
                                    />
                                }
                                <Field
                                    name="name"
                                    component={CustomInput}
                                    type="text"
                                    labelText="Tag"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                    normalize={normalizeTagMaxLength}
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                                <Field
                                    name="color"
                                    component={ColorSelect}
                                    type="text"
                                    labelText="Color"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <Field
                                    name="startDate"
                                    component={CustomDatePicker}
                                    type="text"
                                    minDate={startDateMinDate}
                                    maxDate={(lodash.has(TAG_FORM, 'values.endDate'))?TAG_FORM.values.endDate:""}
                                    labelText="Start Date"
                                    dateFormat="DD-MMM-YY"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <Field
                                    name="endDate"
                                    minDate={endDateMinDate}
                                    component={CustomDatePicker}
                                    type="text"
                                    labelText="End Date"
                                    dateFormat="DD-MMM-YY"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    disabled={readOnly}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <Field
                                 name="status"
                                 component={CustomSelect}
                                 type="text"
                                 labelText="Status"
                                 formControlProps={{
                                    fullWidth: true
                                 }}
                                 optionsList={[
                                    {
                                       value: "Active",
                                       label: "Active"
                                    },
                                    {
                                       value: "Inactive",
                                       label: "Inactive"
                                    }
                                 ]}
                                 disabled={readOnly}
                              />
                           </GridItem>

                        </GridContainer>

                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter}>
                        
                        {readOnly === false &&
                            <Button
                                style={{ marginRight: "10px" }}
                                color="primary"
                                type="submit">
                                Save
                            </Button>
                        }

                        <Button color="white" onClick={() => {
                            const { dispatch } = this.props
                            dispatch({ type: tagConstants.CLOSE_FORM })
                        }}>
                            Cancel
                        </Button>

                    </DialogActions>

                </form>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { formOpen, formMode } = state.tag;
    const { TAG_FORM } = state.form
    return {
        formOpen, formMode, TAG_FORM
    };
}

const connectedComponent = connect(mapStateToProps)(TagForm);

export default reduxForm({
    form: FORM_NAME,
    validate: validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        // crmValidator.alertFirstInvalid(FORM_NAME, errors, form_validate);
    }
})(withStyles(modalStyle)(connectedComponent))
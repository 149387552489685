import { periodConstants } from '../constants';
import lodash from 'lodash'

const initialState = {
    formOpen: false,
    formMode: 'add',
    formInitialValue: {},
    tableKey: new Date(),
    tableReload: false,
    tableReset: false,
    showLoader: false,
    tableDatas: [],
    formImportOpen: false,
    formImportInitialValue: {},
    showImportResult: false,
    importResult: [],
    formSaveImportOpen: false,
    saveImportResult: [],
    tableFilter: {
        page: 0,
        skip: 0,
        limit: 10,
        sortBy: "createdAt",
        sortDir: "desc",
        keyword: "",
        search: {}
    },
};

export function period(state = initialState, action) {
    switch (action.type) {
        case periodConstants.CLEAR_PAGE_STATE:
            return initialState;
        case periodConstants.OPEN_ADD:
            return {
                ...state,
                formOpen: true,
                formMode: 'add',
                formInitialValue: action.formInitialValue,
            };
        case periodConstants.OPEN_EDIT:
            return {
                ...state,
                formOpen: true,
                formMode: 'edit',
                formInitialValue: action.formInitialValue
            }
        case periodConstants.CLOSE_FORM:
            return {
                ...state,
                formOpen: false,
                formMode: 'add',
                formInitialValue: {}
            }
        case periodConstants.SHOW_LOADER:
            return {
                ...state,
                showLoader: true
            }
        case periodConstants.HIDE_LOADER:
            return {
                ...state,
                showLoader: false
            }
        case periodConstants.GET_PERIOD_SUCCESS:
            return {
                ...state,
                tableFilter: action.tableFilter,
                tableDatas: (action.data ? action.data : []),
                tableTotal: (action.total ? action.total : 0)
            }
        case periodConstants.ADD_PERIOD_SUCCESS:
            return {
                ...state,
                formOpen: false,
                tableReload: false,
                tableReset: true,
                tableKey: new Date(),
                formInitialValue: {}
            }
        case periodConstants.EDIT_PERIOD_SUCCESS:
            return {
                ...state,
                formOpen: false,
                formInitialValue: {},
                tableReset: false,
                tableReload: true,
            }
        case periodConstants.TABLE_RELOAD_SUCCESS:
        case periodConstants.TABLE_RESET:
            return {
                ...state,
                tableReset: false,
                tableReload: false
            }
        case periodConstants.DELETE_PERIOD_SUCCESS:
            return {
                ...state,
                tableReload: true
            }
        case periodConstants.GET_DROPDOWN_REWARD_LIST_SUCCESS:
            return {
                ...state,
                periodContainer: {
                    ...state.periodContainer,
                    rewardsList: action.data.rewards
                }
            }
        case periodConstants.OPEN_FORM_UPLOAD_PERIOD:
            return {
                ...state,
                formImportOpen: true,
                formImportInitialValue: (action.formImportInitialValue ? action.formImportInitialValue : {})
            }
        case periodConstants.CLOSE_FORM_UPLOAD_PERIOD:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {}
            }
        case periodConstants.SHOW_UPLOAD_PERIOD_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: true,
                importResult: action.data
            }
        case periodConstants.HIDE_UPLOAD_PERIOD_RESULT:
            return {
                ...state,
                showImportResult: false,
                importResult: {}
            }
        case periodConstants.OPEN_SAVE_UPLOAD_PERIOD_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: false,
                importResult: [],
                formSaveImportOpen: true,
                saveImportResult: action.data
            }
        case periodConstants.CLOSE_SAVE_UPLOAD_PERIOD_RESULT:
            return {
                ...state,
                formImportOpen: false,
                formImportInitialValue: {},
                showImportResult: false,
                importResult: [],
                formSaveImportOpen: false,
                saveImportResult: []
            }
        default:
            return state
    }
}

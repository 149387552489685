export const brandConstants = {
    CLEAR_PAGE_STATE: 'CLEAR_EARN_RULE_STATE',
    OPEN_ADD_NEW_RULES: 'OPEN_ADD_NEW_RULES',
    OPEN_EDIT_EARN_RULES: 'OPEN_EDIT_EARN_RULES',
    CLOSE_FORM_EARN_RULES: 'CLOSE_FORM_EARN_RULES',
    ADD_EARN_RULES_SUCCESS: 'ADD_EARN_RULES_SUCCESS',
    EDIT_EARN_RULES_SUCCESS: 'EDIT_EARN_RULES_SUCCESS',
    TABLE_RELOAD_SUCCESS: 'TABLE_RELOAD_SUCCESS',

    GET_BRANDS_DATA_SUCCESS: 'GET_BRANDS_DATA_SUCCESS',

    SHOW_TABLE_LOADER: 'SHOW_BRAND_TABLE_LOADER',
    HIDE_TABLE_LOADER: 'HIDE_BRAND_TABLE_LOADER',

    OPEN_FORM_ADD_BRAND: 'OPEN_FORM_ADD_BRAND',
    OPEN_FORM_EDIT_BRAND: 'OPEN_FORM_EDIT_BRAND',

    ADD_BRAND_SUCCESS: 'ADD_BRAND_SUCCESS',
    DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
    EDIT_BRAND_SUCCESS: "EDIT_BRAND_SUCCESS",

    SET_FORM_PREVIEW_IMAGE : "SET_FORM_PREVIEW_BRAND_IMAGE",

    CLOSE_FORM_BRAND: 'CLOSE_FORM_BRAND',
}
export const categoryConstants = {
    OPEN_FORM_ADD: 'OPEN_FORM_ADD',
    OPEN_FORM_EDIT_CATEGORY: 'OPEN_FORM_EDIT_CATEGORY',
    CLOSE_FORM_CATEGORY: 'CLOSE_FORM_CATEGORY',
    ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
    GET_CATEGORY_DATA_SUCCESS: 'GET_CATEGORY_DATA_SUCCESS',
    TABLE_GET_LIST_CATEGORY: "TABLE_GET_LIST_CATEGORY",
    TABLE_RELOAD_SUCCESS: "TABLE_RELOAD_SUCCESS",
    TABLE_RESET_SUCCESS: "TABLE_RESET_SUCCESS",
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
    SET_FORM_PREVIEW_IMAGE : "SET_FORM_PREVIEW_IMAGE"
};
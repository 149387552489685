import React from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import MUIDataTable from "mui-datatables";
import { missionActions } from "actions";
// material jss
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

// @material-ui/core components
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import TableActionButton from "components/CustomToolbar/TableActionButton.jsx";
import TableLoaderSpinner from 'components/Table/TableLoaderSpinner'

// page components
import CustomToolbar from "components/CustomToolbar/CustomToolbar.jsx";
import CustomSweetAlert from "components/SweetAlert/CustomSweetAlert.jsx";
import { missionConstants, swalConstants } from '../../constants';
import { plugins, crmValidator } from "helpers";
import i18n from "i18n";
import Badge from "components/Badge/Badge.jsx";

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
    actionDiv: {
        display: "inline-flex",
    },
};

const moduleName = "category";//"mission";

const pageStyle = { ...style, ...sweetAlertStyle };

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {
                padding: '4px 24px 4px 15px',
                "&:nth-child(9)": {
                    textAlign: "center"
                }
            },
            body: {
                padding: '4px 24px 4px 15px',
                "&:nth-child(18)": {
                    textAlign: "center"
                }
            }
        }
    }
});

const renderBadgeStatus = (status) => {
    let BadgeColor
    switch (status) {
        case "Active": BadgeColor = "success"; break;
        case "Expired": BadgeColor = "danger"; break;
        default: BadgeColor = "gray";
    }
    return (<Badge color={BadgeColor}>{status}</Badge>)
}

class TableMission extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            sortBy: "created_at",
            sortDir: "desc",
            reqParams: ""
        }
        this.delayedCallback = lodash.debounce(this.getData, 500)
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        const { tableReload, tableReset, dispatch } = this.props;
        if (tableReload || tableReset) {
            this.getData();
            dispatch({ type: missionConstants.TABLE_RELOAD_SUCCESS })
        }
    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch({ type: missionConstants.CLEAR_PAGE_STATE })
     }

    getRowData(rowIndex = 0) {
        const { tableData } = this.props;
        const data = tableData.data;
        return data[rowIndex];
    }

    getData = (skip, limit, sortBy, sortDir, keyword) => {
        const { dispatch, tableReload, tableData } = this.props
        let reqParams = "";

        if (!tableReload) {
            if (crmValidator.isEmptyValue(skip)) {
                skip = 0
            }

            if (crmValidator.isEmptyValue(limit)) {
                limit = 10
            }

            this.setState({
                page: skip,
                rowsPerPage: limit
            })
            skip = parseInt(limit) * parseInt(skip)
            if (tableData.total !== 0 && tableData.total === skip) {
                skip = skip - limit
                this.setState({
                    page: this.state.page - 1,
                })
            }
            if (skip >= tableData.total) {
                skip = 0;
                this.setState({
                    page: 0,
                })
            }
            this.setState({
                reqParams: {
                    skip: skip,
                    limit: limit,
                },
            })
            reqParams += '&skip=' + skip
            reqParams += '&limit=' + limit

            if (!crmValidator.isEmptyValue(sortBy)) {
                if (sortBy === 'startDateEndDate') {
                    sortBy = 'startDate'
                }
                reqParams += '&sortBy=' + sortBy
            }

            if (!crmValidator.isEmptyValue(sortDir)) {
                reqParams += '&sortDir=' + sortDir
            }

            if (!crmValidator.isEmptyValue(keyword)) {
                reqParams += '&keyword=' + keyword
            }
        } else {
            reqParams = this.state.reqParams
        }
        dispatch(missionActions.getListsMission(reqParams));
        this.setState({
            reqParams: reqParams
        });
    }


    openFormAdd = () => {
        const { dispatch, formInitialValue } = this.props;
        dispatch({ type: missionConstants.OPEN_FORM_ADD, formInitialValue: { formInitialValue } });
    };

    openFormEdit(id) {
        const { dispatch } = this.props;
        dispatch(missionActions.getMissionDetail(id));
    }
    render() {
        const { classes, tableKey, tableData, formInitialValue, dispatch, visiblePersonalData, showLoader } = this.props
        const { page, rowsPerPage } = this.state
        let columns = [
            { name: "_id", options: { display: "excluded" } },
            {
                label: i18n.t("label.no"),
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowSatrt =
                            tableMeta.tableState.page *
                            tableMeta.tableState.rowsPerPage;
                        const rowIndex = rowSatrt + tableMeta.rowIndex;
                        return rowIndex + 1;
                    },
                },
            },
            { name: "displayId", label: "MissionID", },
            { name: "missionName", label: "Mission Name" },
            { name: "missionType", label: "Type" },
            {
                name: "missionPrivilege", label: "Privilage", options: {
                    customBodyRender: (value) => {
                        let privilage = ''
                        if (lodash.get(value, 'point') === true) {
                            privilage = 'Point'
                        } if (lodash.get(value, 'reward') === true) {
                            privilage = privilage + (privilage != '' ? ', ' : '') + 'Reward'
                        } if (lodash.get(value, 'luckyChance') === true) {
                            privilage = privilage + (privilage != '' ? ', ' : '') + 'LuckyDraw Chance'
                        }
                        return privilage
                    }
                }
            },
            {
                name: "startDate", label: "Start Date/Time", options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        const startDateStr = (rowData.startDate !== undefined || rowData.startDate != null) ? (<span className="text-nowrap">{plugins.datetimeFormat(rowData.startDate, "datetime")}</span>) : ""
                        return startDateStr
                    }
                }
            },
            {
                name: "endDate", label: "End Date/Time", options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const rowData = this.getRowData(tableMeta.rowIndex)
                        const endDateStr = (rowData.endDate !== undefined || rowData.endDate != null) ? (<span className="text-nowrap">{plugins.datetimeFormat(rowData.endDate, "datetime")}</span>) : ""
                        return endDateStr
                    }
                }
            },
            {
                name: "status", label: "Status", options: {
                    filter: false,
                    customBodyRender: (value, tableMeta) => {
                        const rowData = this.getRowData(tableMeta.rowIndex);
                        return renderBadgeStatus(rowData.status)
                    }
                }
            }
        ];
        if (plugins.isHasPermission(moduleName, 'read') && visiblePersonalData) {
            columns.push({
                label: "Action",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={classes.actionDiv}>
                                {plugins.isHasPermission(moduleName, 'update') &&
                                    <TableActionButton
                                        id="btn_edit"
                                        actionType="edit"
                                        onClick={e => {
                                            this.openFormEdit(tableMeta.rowData[0]);
                                        }}
                                    />
                                }
                                {plugins.isHasPermission(moduleName, 'delete') && (
                                    <TableActionButton
                                        id="btn_delete"
                                        actionType="delete"
                                        onClick={e => {
                                            dispatch({
                                                type: swalConstants.SHOW,
                                                data: {
                                                    type: "warning",
                                                    title: i18n.t("warning_messages.are_you_sure_delete_mission"),
                                                    message: `${this.getRowData(tableMeta.rowIndex).missionName}`,
                                                    onConfirm: function () {
                                                        let reqParams = {
                                                            _id: tableMeta.rowData[0]
                                                        };
                                                        dispatch({ type: swalConstants.HIDE });
                                                        dispatch(missionActions.deleteMission(reqParams));
                                                    },
                                                    onCancel: function () {
                                                        dispatch({ type: swalConstants.HIDE });
                                                    },
                                                    showCancel: true,
                                                    confirmBtnText: i18n.t("delete"),
                                                    cancelBtnText: i18n.t("cancel")
                                                }
                                            });
                                        }} />)}
                            </div>
                        );
                    }
                }
            });
        }

        let options = {
            serverSide: true,
            count: tableData.total,
            page: page,
            rowsPerPage: rowsPerPage,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: [5, 10, 20],
            onTableChange: (action, tableState) => {
                let skip, limit, sortBy, sortDir, keyword = ""

                if (tableState.page !== null) {
                    skip = tableState.page
                }

                if (tableState.rowsPerPage !== null) {
                    limit = tableState.rowsPerPage
                }

                if (tableState.activeColumn !== null) {
                    sortBy = tableState.columns[tableState.activeColumn].name
                }
                if (tableState.announceText !== null) {
                    sortDir = tableState.announceText.search("descending") !== -1 ? "desc" : "asc"
                }
                if (tableState.searchText !== null || action === 'search') {
                    keyword = tableState.searchText
                    this.delayedCallback(skip, limit, sortBy, sortDir, keyword)
                }
                let actionList = ['changePage', 'sort', 'changeRowsPerPage']
                if (actionList.indexOf(action) !== -1) {
                    this.getData(skip, limit, sortBy, sortDir, keyword)
                }
            },
        };
        if (plugins.isHasPermission(moduleName, 'create')) {
            options.customToolbar = () => {
                return (
                    <CustomToolbar handleClick={this.openFormAdd.bind(this)} title="Add New" id='btn_add' />
                );
            }
        }
        return (
            <div id='tableContentManagementList'>
                <CustomSweetAlert />
                {/* <MissionForm initialValues={formInitialValue}/> */}
                <MuiThemeProvider theme={THEME}>
                    <MUIDataTable
                        key={tableKey}
                        title={"MISSION"}
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                    {showLoader && <TableLoaderSpinner />}
                </MuiThemeProvider>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { tableKey, tableData, formInitialValue, tableReset, showLoader, tableFilter, tableReload } = state.mission;
    const { permission, superAdmin, visiblePersonalData } = state.authentication;
    return {
        permission, superAdmin, visiblePersonalData, tableKey, tableData, formInitialValue, tableReset, showLoader, tableFilter, tableReload
    };
}

export default connect(mapStateToProps)(withStyles(pageStyle)(TableMission));
import React from "react";
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import lodash from 'lodash'
import { plugins } from "helpers";

import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Icon from '@material-ui/core/Icon';

import MTablestyles from "assets/jss/material-dashboard-pro-react/customMTableStyle.jsx";

const THEME = createMuiTheme({
    ...MTablestyles,
    overrides: {
        ...MTablestyles.overrides,
        MuiTableCell: {
            head: {

                textAlign: "center",
                paddingRight: '24px'
    
            },
            body: {
                textAlign: "center",
                paddingRight: '24px'
            }
        }
    }
});

class EarnReportDetail extends React.Component {

    closeDialog() {
        const { dispatch } = this.props;
        dispatch({ type: 'CLOSE_EARN_REPORT_DETAIL' });
    }

    getRowData(rowIndex = 0) {
        const { earnReport } = this.props;
        const { tableData } = earnReport;
        const data = tableData;
        if(rowIndex.length === 0 ){
            rowIndex = 0;   
        }
        return data[rowIndex];
    }

    render() {
        const { classes, earnReport, tableKey } = this.props;
        const earnDetailData = lodash.get(earnReport, 'earnDetail.data')
        var tableDatas = lodash.get(earnDetailData, 'purchaseDetail.purchaseSkuAndQuantity')

        let columns = [
            { name: "skuId", label: "SKU ID" },
            { name: "skuName", label: "SKU NAME" },
            { name: "quantity", label: "Quantity" },
        ]

        let options = {
            serverSide: false,
            count: false,
            page: false,
            rowsPerPage: 100,
            selectableRows: "none",
            print: false,
            download: false,
            filter: false,
            responsive: "scroll",
            rowsPerPageOptions: false,
            search: false,
            pagination: false,
            sort: false,
            sortFilterList: false,
            viewColumns: false,
        }
        return (
            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                open={lodash.get(earnReport, 'earnDetail') && lodash.get(earnReport.earnDetail, 'open') ? earnReport.earnDetail.open : false}
                keepMounted
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
                maxWidth='xs'
                scroll="body"
                disableRestoreFocus={true}
            >
                <Icon 
                    id="btn_close_earn_report_detail"
                    fontSize="small"
                    onClick={() => {
                        this.closeDialog()
                    }}
                    style={{marginTop: '10px', marginLeft: '330px'}}
                >
                    close
                </Icon>
                <DialogContent
                    id="import-result-detail-dialog"
                    // className={classes.modalBody}
                    style={{ paddingTop: "0", textAlign: 'left' }}
                >
                    <h4 className={classes.modalTitle} style={{textAlign: 'left'}}>
                        <b>Purchase Detail</b>
                    </h4>

                    Purchase Date/Time : {plugins.datetimeFormat(lodash.get(earnDetailData, 'purchaseDetail.purchaseDate'), "fulldatetime")}
                    <br/>
                    Purchase Transaction ID : {lodash.get(earnDetailData, 'purchaseDetail.purchaseTransactionId')}
                    <br/>
                    Cart Price : {lodash.get(earnDetailData, 'purchaseDetail.cartPrice')}
                    <br/>
                    Shipping Cost : {lodash.get(earnDetailData, 'purchaseDetail.shippingCost')}
                    <br/>
                    Discount : {lodash.get(earnDetailData, 'purchaseDetail.discount')}
                    <br/>
                    Total Before vat : {lodash.get(earnDetailData, 'purchaseDetail.totalBeforeVat')}
                    <br/>
                    Vat : {lodash.get(earnDetailData, 'purchaseDetail.vat')}
                    <br/>
                    Total Amount : {lodash.get(earnDetailData, 'purchaseDetail.purchaseAmount')}
                    <br/>
                    Calculate Price : {lodash.get(earnDetailData, 'purchaseDetail.calculatePrice')}
                    <br/>
                    Payment Channel : {lodash.get(earnDetailData, 'purchaseDetail.paymentChannel')}
                    <br/>
                    Shop ID : {lodash.get(earnDetailData, 'purchaseDetail.shopId')}
                    <br/>
                    Purchase SKU : 
                    <br/>
                    <MuiThemeProvider theme={THEME}>
                        <MUIDataTable
                            key={tableKey}
                            data={tableDatas}
                            columns={columns}
                            options={options}
                            style={{width: '330px'}}
                        />
                    </MuiThemeProvider>
                    <br/>
                    Remark : {lodash.get(earnDetailData, 'purchaseDetail.remark')}
                    <br/>
                    { lodash.get(earnDetailData ,'remark') === "VOID" ? <>
                        <br/><div style={{ border: 'dashed 1px #ccc', padding: '0px 20px' }}></div>
                        <h4 className={classes.modalTitle} style={{textAlign: 'left'}}>
                            <b>Void Detail</b>
                        </h4>  

                        Void Date/Time : {plugins.datetimeFormat(lodash.get(earnDetailData, 'voidDateTime'), "fulldatetime")}
                        <br/>
                        Void Amount : -{lodash.get(earnDetailData, 'purchaseDetail.purchaseAmount')}
                        <br/>
                        Void Remark : {lodash.get(earnDetailData, 'voidRemark')}
                        <br/>
                        </>
                    : null }

                </DialogContent>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    const { earnReport } = state.report;
    return {
        earnReport
    };
}
const connectedComponent = connect(mapStateToProps)(EarnReportDetail);

export default withStyles(sweetAlertStyle)(connectedComponent);



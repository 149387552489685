import { dfnewConditionService } from '../services'
import { loader, alert as customAlert } from 'helpers';
import i18n from 'i18n';
import lodash from 'lodash'
import moment from 'moment'
import { newcondition } from '../constants';

const defaultFailedText = 'api_messages.SOMETHING_WENT_WRONG';

export const newconditionAction = {
    getTableConfig,
    addNewCondition
}

function getTableConfig(params) {
    return dispatch => {
        dfnewConditionService.getTableConfig(params)
          .then(
             response => {
                if (response.status) {
                   if (lodash.has(response, 'data')) {
                      dispatch(success(response.data));
                   }
                } else {
                   if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
                }
             },
             error => {
                customAlert(dispatch, 'error', i18n.t(defaultFailedText))
             }
          ).then(() => {
             loader(dispatch, false)
          })
    };

    function success(data) {
        return { type: newcondition.GET_TEMPLATE_CONFIG_LIST_SUCCESS, data }
     }
}
function addNewCondition(params){
   return dispatch => {
      loader(dispatch, true)
      dfnewConditionService.addNewCondition(params)
        .then(
           response => {
              if (response.status) {
                 if (lodash.has(response, 'data')) {
                    dispatch(success(response.data));
                 }
              } else {
                 if (!response.unAlert) customAlert(dispatch, 'error', i18n.t(`api_messages.${response.message.toString()}`))
              }
           },
           error => {
              customAlert(dispatch, 'error', i18n.t(defaultFailedText))
           }
        ).then(() => {
           loader(dispatch, false)
        })
   };
   function success(data) {
      return { type: newcondition.ADD_NEW_CONDITION_SUCCESS, data }
   }
}

